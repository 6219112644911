import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NOTIFY_ME_SNACK_BAR } from "rtr-constants";
import $ from "clients/RawClient";
import { updateNotifyMeLocalStorage, getNotifyMeLocalStorage } from "helpers/notify-me-helpers";
import { differenceInDays } from "date-fns";

const API_PATH = "/api/notify-me";

const initialState = {
  notifyMeProducts: null,
  notifyMeFetched: false,
  notifyMeLoading: false,
  notifyMeError: null,
};

export const fetchNotifyMeProducts = createAsyncThunk("notifyMe/fetchProducts", ({ userId }) => {
  const lsParsedObject = getNotifyMeLocalStorage();

  // if we have a recently updated localStorage object, return that instead without resetting the timestamp
  if (lsParsedObject?.timestamp && differenceInDays(Date.now(), lsParsedObject.timestamp) < 1) {
    return new Promise(resolve => resolve(lsParsedObject.existingNotifyMeProducts));
  }

  // otherwise, update localstorage as soon as we get a successful response and update the timestamp
  return $.ajax({
    type: "GET",
    url: `${API_PATH}?userId=${userId}`,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then(res => {
    updateNotifyMeLocalStorage(res);
    return res;
  });
});

export const postNotifyMeProduct = createAsyncThunk("notifyMe/postProduct", ({ userId, style, skus }) =>
  $.ajax({
    type: "POST",
    url: API_PATH,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
    data: JSON.stringify({ userId, style, skus }),
  })
);

export const updateNotifyMeProduct = createAsyncThunk("notifyMe/updateProduct", ({ userId, style, skus }) =>
  $.ajax({
    type: "PATCH",
    url: API_PATH,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
    data: JSON.stringify({ userId, style, skus }),
  })
);

export const notifyMeSlice = createSlice({
  name: "notifyMe",
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchNotifyMeProducts.fulfilled, (state, action) => {
      state.notifyMeProducts = action.payload;
      state.notifyMeFetched = true;
    });
    builder.addCase(fetchNotifyMeProducts.rejected, state => {
      state.notifyMeProducts = null;
      state.notifyMeFetched = true;
    });
    builder.addCase(postNotifyMeProduct.fulfilled, (state, action) => {
      const newNotifyMeProducts = action.payload;
      updateNotifyMeLocalStorage(newNotifyMeProducts);
      state.notifyMeProducts = newNotifyMeProducts;
      state.notifyMeError = null;
      state.notifyMeLoading = false;
    });
    builder.addCase(postNotifyMeProduct.pending, state => {
      state.notifyMeLoading = true;
    });
    builder.addCase(postNotifyMeProduct.rejected, (state, action) => {
      state.notifyMeError = action.error?.message || NOTIFY_ME_SNACK_BAR.ERROR;
      state.notifyMeLoading = false;
    });
    builder.addCase(updateNotifyMeProduct.fulfilled, (state, action) => {
      const newNotifyMeProducts = action.payload;
      updateNotifyMeLocalStorage(newNotifyMeProducts);
      state.notifyMeProducts = newNotifyMeProducts;
      state.notifyMeError = null;
      state.notifyMeLoading = false;
    });
    builder.addCase(updateNotifyMeProduct.pending, state => {
      state.notifyMeLoading = true;
    });
    builder.addCase(updateNotifyMeProduct.rejected, (state, action) => {
      state.notifyMeError = action.error?.message || NOTIFY_ME_SNACK_BAR.ERROR;
      state.notifyMeLoading = false;
    });
  },
});

/**
 * This value isn't used anywhere.
 * It's only defined because ReduxApp expects reducers to have a "types" property
 */
export const types = [
  "notifyMe/fetchProducts/fulfilled",
  "notifyMe/fetchProducts/rejected",
  "notifyMe/postProduct/fulfilled",
  "notifyMe/postProduct/rejected",
  "notifyMe/updateProduct/fulfilled",
  "notifyMe/updateProduct/rejected",
];

export default {
  types: types,
  fn: notifyMeSlice.reducer,
};
