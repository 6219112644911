export const authFormConstants = {
  DisplayStyle: {
    DEFAULT: "DEFAULT",
    PLANS_PAGE: "PLANS_PAGE",
  },
  PageTypes: {
    ABOUT_US: "ABOUT_US",
    ACCOUNT: "ACCOUNT",
    FEATURES: "FEATURES",
    GRID: "GRID",
    MARKETING_REDIRECT: "MARKETING-REDIRECT",
    PAID_UNLIMITED_LP: "PAID-UNLIMITED-LP",
    PRIVACY: "PRIVACY",
    PRIVACY_METRICS: "PRIVACY-METRICS",
    TERMS_OF_SERVICE: "TERMS-OF-SERVICE",
  },
  PageNames: {
    FORGOT_PASSWORD: "forgot-password",
    LOGIN: "login",
    PAID_LANDING: "PAID-LANDING",
    REGISTER: "register",
    RENT_MY_STYLE_LANDING: "RENT-MY-STYLE-LANDING",
    RESET_PASSWORD: "reset-password",
  },
  Views: {
    login: "login",
    registration: "registration",
  },
};

export const AUTH_SESSION_ERRORS = {
  REFRESH_ACCESS_TOKEN_ERROR: "RefreshAccessTokenError",
};

export const OAUTH_GRANT_TYPES = {
  REFRESH_TOKEN: "refresh_token",
};
