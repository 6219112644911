import { createAction } from "redux-actions";

export const dispatchAction = (dispatch, constant, payload) => {
  dispatch(createAction(constant)(payload));
};

export const createAsyncAction = (name, asyncFunc, onDone = () => {}, onFail = () => {}, onAlways = () => {}) => {
  const fnAction = createAction(name + "_BEGIN");
  const fnSuccessAction = createAction(name + "_SUCCESS");
  const fnFailureAction = createAction(name + "_FAILURE");

  return params => {
    return dispatch => {
      dispatch(fnAction(params));

      asyncFunc(params)
        .then(
          data => {
            dispatch(fnSuccessAction(data));
            onDone();
          },
          err => {
            dispatch(fnFailureAction(err));
            onFail();
          }
        )
        .then(() => {
          onAlways();
        });
    };
  };
};

// Functions to extract out window functions, so these actions can be mocked for unit testing
export const redirectToRoute = route => {
  if (!route) {
    return;
  }
  location.assign(route);
};

export const refreshPage = () => {
  location.reload();
};

export const safeNavigation = route => {
  if (window.location.pathname !== route) {
    window.location.href = route;
  }
};

export default {
  dispatchAction,
  createAsyncAction,
  redirectToRoute,
  refreshPage,
  safeNavigation,
};
