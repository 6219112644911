import { CachedServicesClient } from "./CachedServicesClient";

const flagEnvironment = process.env.CONFIG__FF__ENVIRONMENT;
const isEnvironmentEnabled = f => f?.environments?.[flagEnvironment]?.enabled;

export default class FeatureFlagsServiceClient extends CachedServicesClient {
  static CONFIG_HOST = process.env.CONFIG__FF__HOST;

  async getEnabledFlags() {
    return this.get().then(async response => {
      const rawContent = await response.text();

      const flagsData = JSON.parse(rawContent);

      return flagsData.flags.filter(isEnvironmentEnabled).map(f => f.name);
    });
  }
}
