import client from "../clients/RawClient";
import ActionTypes from "./action-types";
import { createAction } from "redux-actions";
import { SmartComponents } from "../routes";

const getSmartNavSuccess = createAction(ActionTypes.SMART_NAV_CONTENT_SUCCESS);
const getSmartNavMobileSuccess = createAction(ActionTypes.SMART_NAV_MOBILE_CONTENT_SUCCESS);

const getSmartNav = () => {
  return (dispatch, getState) => {
    const { browser: { isMobileViewport } = {} } = getState();

    if (isMobileViewport) {
      client.get(SmartComponents.smartNavMenuMobile).then(data => dispatch(getSmartNavMobileSuccess(data)));
    } else {
      client.get(SmartComponents.smartNavMenu).then(data => dispatch(getSmartNavSuccess(data)));
    }
  };
};

export default {
  getSmartNav,
};
