import _ from "underscore";
import { googleAutocompleteFieldEnums } from "rtr-constants";

/*
Returns a callback function that is passed to the onChange or onBlur function
of input / select elements in the subform.
The dispatch param is Redux dispatch
The actionCreator is an actionCreator function that returns a Redux action object
e.g., { type: "SHIPPING_STEP_UPDATE_ELEMENT, payload: { value: "10014", isValid: "true", name: "zipCode", error: "" } }

The specific use case for this callback is for inputs that have a regex validation
that will return false as soon as the user enters a single character (e.g, zip codes, emails).
We don't want to show the user errors until she is finished entering text in the input.
However, if the whole subform is valid and ready to submit, we DO want to show the user
her errors as soon as she makes a keystroke that invalidates the input and, along with it, the form.
(For example, she deletes a digit from a valid zip code when all other inputs are completed and valid.)

At a high level, the logic of this function is: If the user's entry is invalid,
only show the error if the whole subform was valid before the user made the change.

*/
export const onChangeWithSuppressedErrors = (dispatch, actionCreator) => {
  return function (value, isValid, name, inputObj, formIsValid) {
    const payload = {
      name,
      value,
      isValid,
      error: "",
    };

    // only show the error message on change if the error will render the form invalid
    // (e.g., if the form is currently in a valid state and the user deletes the last digit from zip code)
    // we don't want to spam the user with error messages if she hasn't finished typing her email, zip code, etc.
    // (e.g., she types "10" and gets an error before she can finish typing "10014")
    if (!isValid && formIsValid) {
      payload.error = inputObj.validationErrorMsg;
    }

    dispatch(actionCreator(payload));
  };
};

/*
Returns a callback function that is passed to the onChange or onBlur function
of input / select elements in the subform.
The dispatch param is Redux dispatch
The actionCreator is an actionCreator function that returns a Redux action object
e.g., { type: "SHIPPING_STEP_UPDATE_ELEMENT, payload: { value: "10014", isValid: "true", name: "zipCode", error: "" } }
*/
export const updateSubformElement = (dispatch, actionCreator) => {
  return (value, isValid, name, inputObj) => {
    // Pass the state of the element up to the reducers
    const payload = {
      name,
      value,
      isValid,
    };

    if (isValid) {
      payload.error = "";
    } else {
      payload.error = inputObj.validationErrorMsg;
    }
    dispatch(actionCreator(payload));
  };
};

/*
Helper function that updates the state of a subform element
It will update the value and also update the error object for the subform
And also updates the overall state of the subform
e.g., if all elements are now valid, the subform is valid;
if one or more elements are now valid, the subform is invalid.
This functionality is tested through tests of reducers that require it.
*/
export const updateElementInReducer = (nextShippingState, action) => {
  // update the state for the specfic element...
  const elementState = nextShippingState.elements[action.payload.name];
  if (elementState) {
    elementState.isValid = action.payload.isValid;
    elementState.value = action.payload.value;

    // update the error if it exists
    // if the error is an empty string, we want to update it
    // but we want to ignore an error that is undefined (this means the action has no new info about an error)
    if (!_.isUndefined(action.payload.error)) {
      nextShippingState.errors[action.payload.name] = action.payload.error;
    }
  }

  // ... then update the overall state of the step
  nextShippingState.isValid = allElementsValid(nextShippingState);
};

export const validateAllElementsInSubform = nextSubformState => {
  /*
   * In Exp. 419, treatment 2 (Smart Checkout Active CTAS), a user
   * can 'save' the shipping or billing form before the form has
   * become valid. In this event, instead of submitting the form, we
   * update the open form with input-level error messages. (These are
   * default validation error messages that come from each step's initial
   * state, in shipping-step-reducer.js and billing-step-reducer.js.)
   */
  const { elements, errors } = nextSubformState;

  _.each(elements, (inputObj, name) => {
    if (!inputObj.isValid && inputObj.isRequired) {
      /*
       * An empty string will not appear as an error on the form.
       * If you do want an error displayed for a given input, the
       * input object must have a defaultErrorMsg key.
       */
      errors[name] = inputObj.defaultErrorMsg || "";
    }
  });
};

/*
 * Assign keys coming back from from Godmother models to elements in the form. This involves a translation of
 * the Godmother data in "action" to form field names from "formElementNames". We also need to specify which
 * keys are editable and which are not. In certain casel, like a credit card, we do not want the user to be able
 * to edit their credit card number — that's a new credit card. In that case, we need to remove the element backing
 * the card from the form state so it will not be marked invalid, thus disabling the form.
 *
 * This is currently not being called because we need to determine how to validate here rather than in the element itself.
 */
export const updateElementForEdit = (nextFormState, action, formElementNames, editableKeys, removeUneditableKeys) => {
  const {
      payload: { values },
    } = action,
    editableStateKeys = _.select(_.values(formElementNames), key => {
      return _.contains(editableKeys, key);
    });

  _.each(editableStateKeys, key => {
    if (nextFormState.elements[key]) {
      nextFormState.elements[key].value = values[key];
    }
  });

  if (removeUneditableKeys) {
    nextFormState.elements = _.pick(nextFormState.elements, editableStateKeys);
  }

  nextFormState.isValid = allElementsValid(nextFormState);
  return nextFormState;
};

const findAddressTypeHelper = (addressComponents, type) => {
  return _.find(addressComponents, component => {
    return _.contains(component.types, type);
  });
};

export const setElementsFromAutocomplete = (nextFormState, addressComponents) => {
  const elements = nextFormState.elements;

  // Google has documentation about what each of these 'types' signifies. Please check the documentation at
  // https://developers.google.com/maps/documentation/geocoding/intro#Results for more information.
  let streetNumber = findAddressTypeHelper(addressComponents, googleAutocompleteFieldEnums.streetNumber);
  const streetName = findAddressTypeHelper(addressComponents, googleAutocompleteFieldEnums.streetName);
  const postalCode = findAddressTypeHelper(addressComponents, googleAutocompleteFieldEnums.postalCode);
  const state = findAddressTypeHelper(addressComponents, googleAutocompleteFieldEnums.state);
  // In some cases, a locality, like "New York", is not returned for an address, rather a sublocality, like "Brooklyn", is returned
  // and sometimes both are returned. Let's give preference to locality and then search for a sublocality if no locality is present.
  const city =
    findAddressTypeHelper(addressComponents, googleAutocompleteFieldEnums.locality) ||
    findAddressTypeHelper(addressComponents, googleAutocompleteFieldEnums.sublocality);

  if (postalCode) {
    elements.postalCode.value = postalCode.short_name;
  }

  if (city) {
    elements.city.value = city.long_name;
  }

  if (state) {
    elements.zoneCode.value = state.short_name;
  }

  if (streetName) {
    // In the case that a streetNumber is not present, ensure that we do not encode "undefined" plus the street name
    streetNumber = streetNumber?.long_name || "";
    elements.street1.value = `${streetNumber} ${streetName.long_name}`.trim();
  }
  // Reset Apt/Suite if address is selected
  elements.street2.value = "";
};

export const allElementsValid = nextFormState => {
  return _.every(_.keys(nextFormState.elements), key => {
    const element = nextFormState.elements[key];
    const isOptionalElementWithDefaultValue = !element.isRequired && element.value === element.defaultValue;
    return element.isValid === true || isOptionalElementWithDefaultValue;
  });
};
