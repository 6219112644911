import { isSSR } from "./client-server-helper";
import fs from "fs";

let version;

export function isStorefrontNext() {
  let next;

  if (isSSR()) {
    next = process.env.NEXT_PUBLIC_IS_NEXT_APP;
  } else {
    ({ next } = window);
  }

  return Boolean(next);
}

export function isTest() {
  return process.env.NODE_ENV === "test";
}

export function isCI() {
  return !!process.env.RTR_IS_CI;
}

export function isProduction() {
  if (isSSR()) {
    // environment variable set
    return process.env.ENV === "production" && !isTest();
  } else {
    // rely on URL
    const host = window.location.host;
    const subdomain = host.split(".")[0];
    return ["www", "m"].includes(subdomain) || host === "storefront-next.renttherunway.com";
  }
}

export function getEnvironmentName() {
  if (isSSR()) {
    // environment variable set
    return process.env.ENV || "development";
  } else {
    const host = window.location.host;
    // rely on URL
    if (/gcp\.rtr\.cloud$/.test(host)) {
      // if gcp url (non-CDN), base it on the second dot-delimited entry
      const envName = host.split(".")[1];
      if (envName.includes("qa")) {
        return "qa";
      } else if (envName.includes("stage")) {
        return "stage";
      } else if (envName.includes("prod")) {
        return "production";
      }
    } else if (/renttherunway\.com$/.test(host)) {
      const envName = host.split(".")[0];
      if (envName.includes("qa")) {
        return "qa";
      } else if (envName.includes("stage")) {
        return "stage";
      } else if (envName === "www" || envName === "m") {
        return "production";
      }
    }
    return "development";
  }
}

if (!isSSR()) {
  // specifically for GTM etc. to know what the environment name is
  window.getEnvironmentName = getEnvironmentName;
}

export function getVersion() {
  if (version) {
    return version;
  }

  if (isSSR()) {
    if (getEnvironmentName() === "development") {
      version = "v1970.01.01.0001";
    } else {
      version = fs.readFileSync("/app/VERSION", "utf-8").trim();
    }
    return version;
  } else {
    return "";
  }
}
