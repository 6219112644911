// by using universal-cookie, you can expect that this code will only work
// client-side. to make it work server-side, have your component pass the
// cookies object provided to it by the `withCookies` HOC.
import Cookies from "universal-cookie";
import { discountTypeCodes } from "rtr-constants";
import { LocalStorage, SessionStorage } from "../site/localStorage";
import { usdPriceIntoInt } from "helpers/PricingHelper";
import { pluralize } from "helpers/FormattingUtils";

const cookies = new Cookies();

const REFERRAL_HELPER_NAMESPACE = "ReferralHelper";

const COOKIE_REFERRAL_CODE = "referral_code";
const COOKIE_REFERRAL_PROGRAM = "referral_program";
const COOKIE_REFERRAL_SHARE_ID = "share_id";

const REFERRAL_PROGRAM_CLASSIC = "classic";
const REFERRAL_PROGRAM_PAY_PER_SWAP_STACKING = "PPS_STACKING";

const PPS_ACTIVE_ADVOCATE_AWARD_MODAL_SHOW_KEY = "dismissed";
const PPS_ACTIVE_ADVOCATE_AWARD_MODAL_STORE_KEY = "pps_referrals_advocate_award_unlocked_";
const UNLIMITED_REWARD_ADVOCATE_PROGRAM_STATUS_FETCHED = "unlimited_reward_advocate_program_status_fetched_";
const REFERRAL_ADVOCATE_REWARD_MODAL_STORE_KEY = "referral_advocate_reward";

const referralRewardTypes = {
  SUBSCRIPTION_MOD: "SUBSCRIPTION_MOD",
  BASE_SLOT: "BASE_SLOT",
  ITEM: "ITEM",
  PROMO_CODE: "PROMO_CODE",
};

const referralRewardStatuses = {
  REDEEMED: "REDEEMED",
  REDEEMABLE: "REDEEMABLE",
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
};

const referralPageTypes = {
  ADVOCATE: "ADVOCATE",
  FRIEND: "FRIEND",
};

const pixels = {
  actions: {
    CLICK_MENU_ITEM: "click_menu_item",
    EXTRA_SPOT: "extra_spot",
    FAILURE: "failure",
    LOAD: "load",
    REFERRAL_CODE_TOOLTIP: "referral_code_tooltip",
    SELECT: "select",
    SHARE: "share",
    SHARE_AGAIN_CTA: "share_again_cta",
    SUCCESS: "success",
    TEXT_REFERRAL: "text_referral",
  },
  categoryNames: {
    REFER_AND_EARN: "refer_&_earn",
  },
  objectTypes: {
    EMAIL_SHARE: "email_share",
    HOW_TO_EARN: "how_to_earn",
    MOBILE_NAV: "mobile_nav",
    PUBLIC_LINK_SHARE: "publicLink_share",
    REFERRAL: "referral",
    YOUR_REWARDS: "your_rewards",
  },
  placements: {
    SHARE_PAGE: "referral_page",
    MODAL: "referral_modal",
  },
  statuses: {
    CLOSE: "close",
    COMPLETED: "completed",
    VIEW: "view",
  },
};

const sessionStorageClientForReferralHelper = new SessionStorage(REFERRAL_HELPER_NAMESPACE);

function getUserAppendedStorageKey(key, userId) {
  if (!key) {
    throw new Error("Key param cannot be falsy");
  }

  return key + userId;
}

function deleteReferralCookies() {
  cookies.remove(COOKIE_REFERRAL_CODE, { path: "/" });
  cookies.remove(COOKIE_REFERRAL_PROGRAM, { path: "/" });
  cookies.remove(COOKIE_REFERRAL_SHARE_ID, { path: "/" });
}

function getReferralCode() {
  return cookies.get(COOKIE_REFERRAL_CODE);
}

function getReferralProgram() {
  return cookies.get(COOKIE_REFERRAL_PROGRAM);
}

// ADVOCATE AWARD HELPERS //
function getReferralAwardModalStorageInformation({ storeKey, showKey, userId }) {
  const storageKey = getUserAppendedStorageKey(storeKey, userId);
  const localStorageClient = new LocalStorage(storageKey);
  const localStorageValue = localStorageClient.get(showKey);

  return {
    storageKey,
    localStorageClient,
    localStorageValue,
  };
}

// Advocate award complete helpers
function hasPPSReferralAwardBeenSeen(awardId, userId) {
  const { localStorageValue } = ReferralHelper.getReferralAwardModalStorageInformation({
    storeKey: PPS_ACTIVE_ADVOCATE_AWARD_MODAL_STORE_KEY,
    showKey: PPS_ACTIVE_ADVOCATE_AWARD_MODAL_SHOW_KEY,
    userId,
  });
  const awardModalsUserHasSeen = localStorageValue || {};

  return awardModalsUserHasSeen && !!awardId && !!awardModalsUserHasSeen[awardId];
}

function hasAwardModalBeenSeen(award) {
  const localStorageClient = new LocalStorage(REFERRAL_ADVOCATE_REWARD_MODAL_STORE_KEY);
  return localStorageClient.get(award.referralId);
}

function shouldAwardCelebrationModalDisplay(awards, ppsReferralMessagingStrategyFF) {
  if (!awards.length || !ppsReferralMessagingStrategyFF) {
    return false;
  }

  return true;
}

function findUnseenAwards(awards) {
  return awards.filter(
    award =>
      award.state !== referralRewardStatuses.ACTIVE &&
      award.state !== referralRewardStatuses.EXPIRED &&
      !hasAwardModalBeenSeen(award)
  );
}

function setReferralAdvocateAwardsViewed(awards) {
  const upcomingAwards = awards.filter(
    award => award.state !== referralRewardStatuses.ACTIVE && award.state !== referralRewardStatuses.EXPIRED
  );
  const unseenAward = upcomingAwards.find(award => !hasAwardModalBeenSeen(award));

  if (unseenAward) {
    const localStorageClient = new LocalStorage(REFERRAL_ADVOCATE_REWARD_MODAL_STORE_KEY);
    localStorageClient.set(unseenAward.referralId, true);
  }
}

function setPPSReferralAwardAsSeen(awardId, userId) {
  const { localStorageValue, localStorageClient } = ReferralHelper.getReferralAwardModalStorageInformation({
    storeKey: PPS_ACTIVE_ADVOCATE_AWARD_MODAL_STORE_KEY,
    showKey: PPS_ACTIVE_ADVOCATE_AWARD_MODAL_SHOW_KEY,
    userId,
  });

  const awardModalsUserHasSeen = localStorageValue || {};

  if (awardId) {
    awardModalsUserHasSeen[awardId] = true;
  }

  if (Object.keys(awardModalsUserHasSeen).length) {
    localStorageClient.set(PPS_ACTIVE_ADVOCATE_AWARD_MODAL_SHOW_KEY, awardModalsUserHasSeen);
  }
}

function getAdvocateProgramStatusFetched(userId) {
  return (
    sessionStorageClientForReferralHelper.get(
      getUserAppendedStorageKey(UNLIMITED_REWARD_ADVOCATE_PROGRAM_STATUS_FETCHED, userId)
    ) || false
  );
}

function getMultiMonthPromoMonths(subscriptionModPlan) {
  const { totalTermsApplied } = subscriptionModPlan || {};

  return `${totalTermsApplied} ${pluralize("month", totalTermsApplied)}`;
}

function getMultiMonthPromoDiscount(subscriptionModPlan) {
  const { discountType, amountPretax } = subscriptionModPlan || {};
  /* NW [EXPLANATION] 12/21/21: example values: 
  / discountType: "DOLLARS_OFF",
  / amountPretax: "50.00"*/

  if (discountTypeCodes[discountType] === discountTypeCodes.PERCENT_OFF) {
    const formattedDiscountAmount = usdPriceIntoInt(amountPretax);
    return `${formattedDiscountAmount}%`;
  }
}

function getSingleMonthPromoDiscount(promotionRule) {
  const { discountAmount, discountTypeCode } = promotionRule || {};

  if (discountTypeCode === discountTypeCodes.PERCENT_OFF) {
    return `${discountAmount}%`;
  } else return "40%"; // NW [EXPLANATION] 12/22/21: fall back to 40% discount when no promotion rule is provided
}

function getFriendIncentiveCopy(share, pageType) {
  const isMultiMonthPromo =
    share?.promotion?.subscriptionModPlan && Object.keys(share.promotion.subscriptionModPlan).length;

  const discount = isMultiMonthPromo
    ? getMultiMonthPromoDiscount(share.promotion.subscriptionModPlan)
    : getSingleMonthPromoDiscount(share?.promotion?.rules?.[0]);

  const membershipTerms = isMultiMonthPromo ? getMultiMonthPromoMonths(share.promotion.subscriptionModPlan) : "month";
  const pronoun = pageType === referralPageTypes.ADVOCATE ? "their" : "your";

  return `${discount} off ${pronoun} first ${membershipTerms} of a Rent the Runway membership`;
}

const ReferralHelper = {
  getUserAppendedStorageKey,
  getReferralCode,
  deleteReferralCookies,
  getReferralProgram,
  setPPSReferralAwardAsSeen,
  getReferralAwardModalStorageInformation,
  hasPPSReferralAwardBeenSeen,
  getAdvocateProgramStatusFetched,
  shouldAwardCelebrationModalDisplay,
  setReferralAdvocateAwardsViewed,
  findUnseenAwards,
  hasAwardModalBeenSeen,
  getMultiMonthPromoDiscount,
  getMultiMonthPromoMonths,
  getSingleMonthPromoDiscount,
  getFriendIncentiveCopy,
  referralPageTypes,
  referralRewardTypes,
  referralRewardStatuses,
  pixels,
  REFERRAL_PROGRAM_CLASSIC,
  REFERRAL_PROGRAM_PAY_PER_SWAP_STACKING,
  PPS_ACTIVE_ADVOCATE_AWARD_MODAL_SHOW_KEY,
  PPS_ACTIVE_ADVOCATE_AWARD_MODAL_STORE_KEY,
  UNLIMITED_REWARD_ADVOCATE_PROGRAM_STATUS_FETCHED,
  COOKIE_REFERRAL_CODE,
  COOKIE_REFERRAL_PROGRAM,
  COOKIE_REFERRAL_SHARE_ID,
};

export default ReferralHelper;
