import React from "react";
import classNames from "classnames";
import Popper from "popper.js";
import PropTypes from "prop-types";
import { tooltipPlacementPropType } from "components/propTypes/tooltip-placement-prop-type";
import { htmlElementPropType } from "components/propTypes";

/* NW [EXPLANATION] 6/11/21: *How is this different from MoleculeTooltip?*
// MoleculeTooltip uses a portal to render inner content. It also contains internal logic for hover and click-away events.
// When migrating legacy tooltips from Backbone to React, complex children of MoleculeTooltip were not re-rendering as expected when passed-down props updated in the parent.
// After spending a few days investigating, the alternative was chosen to implement another wrapper for PopperJS that is smaller in scope, to expose PopperJS's
// positioning ability without any additional logic.
// *Which tooltip should I use?*
// If MoleculeTooltip works well for your purposes, use that one.
*/
export default class MoleculeInlinePositionedTooltip extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isClosedByOutsideClick: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    placement: tooltipPlacementPropType,
    target: htmlElementPropType.isRequired,
  };

  static defaultProps = {
    isClosedByOutsideClick: true,
    onClose: () => {},
  };

  popper = null;
  $node = null;

  componentDidMount() {
    this.createPopper();
  }

  componentDidUpdate() {
    this.popper?.scheduleUpdate();
  }

  componentWillUnmount() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
    document.removeEventListener("click", this.hideOnClickOutside);
  }

  createPopper() {
    const { isClosedByOutsideClick, target, placement } = this.props;

    if (!target || !this.$node) {
      return;
    }

    this.popper = new Popper(target, this.$node, {
      placement,
      eventsEnabled: false,
      modifiers: {
        preventOverflow: {
          boundariesElement: window.document.body,
          priority: ["left", "right"],
          padding: 0,
        },
      },
    });

    if (isClosedByOutsideClick) {
      document.addEventListener("click", this.hideOnClickOutside);
    }
  }

  hideOnClickOutside = event => {
    if (!this.$node?.contains(event.target)) {
      this.props.onClose();
    }
  };

  render() {
    const className = classNames("molecule-tooltip", {
      "molecule-tooltip--visible": true,
      [this.props.className]: this.props.className,
    });
    return (
      <div className={className} ref={el => (this.$node = el)}>
        <div className="molecule-tooltip__inner">
          {this.props.children}
          <div className="molecule-tooltip__arrow" />
        </div>
      </div>
    );
  }
}
