import $ from "clients/RawClient";
import { createAction } from "redux-actions";
import ActionTypes from "./action-types";
import { parseISOWithoutTime } from "helpers/date-helpers";
import { LocalStorage } from "../site/localStorage";
import { isSameDay, isAfter, addDays } from "date-fns";

const USER_MENU_TOOLTIP_LOCAL_STORAGE_KEY = "userMenuTooltip";
const actions = {
  fetchPendingReviewsSuccess: createAction(ActionTypes.FETCH_PENDING_REVIEWS_SUCCESS),
  fetchSSEActiveOrdersSuccess: createAction(ActionTypes.FETCH_SSE_ACTIVE_ORDERS_SUCCESS),

  fetchPendingReviews: function () {
    return function (dispatch) {
      return $.ajax({
        url: "/header/review_products",
        headers: {
          Accept: "application/json",
        },
      }).then(data => dispatch(actions.fetchPendingReviewsSuccess(data)));
    };
  },
  fetchSSEActiveOrders: function () {
    return function (dispatch) {
      return $.ajax({
        url: "/header/active_orders",
        headers: {
          Accept: "application/json",
        },
      }).then(data => dispatch(actions.fetchSSEActiveOrdersSuccess(data)));
    };
  },
  /* NW [EXPLANATION] 6/11/21: The following actions have no reason to persist to Redux right now, but in the future they can if needed */
  getUserMenuTooltipDismissed: function (tooltipName) {
    if (!tooltipName) {
      return false;
    }
    const localStorageClient = new LocalStorage(USER_MENU_TOOLTIP_LOCAL_STORAGE_KEY);
    const userMenuTooltipDismissalExpiry = localStorageClient.get(tooltipName);
    if (!userMenuTooltipDismissalExpiry) {
      return false;
    }
    const parsedExpiry = parseISOWithoutTime(userMenuTooltipDismissalExpiry);
    return isSameDay(parsedExpiry, new Date()) || isAfter(parsedExpiry, new Date());
  },
  setUserMenuTooltipDismissed: function (tooltipName) {
    if (tooltipName) {
      const localStorageClient = new LocalStorage(USER_MENU_TOOLTIP_LOCAL_STORAGE_KEY);
      const expiration = addDays(new Date(), 1);
      localStorageClient.set(tooltipName, expiration);
    }
  },
};

export default actions;

export const {
  fetchPendingReviewsSuccess,
  fetchSSEActiveOrdersSuccess,
  fetchPendingReviews,
  fetchSSEActiveOrders,
  getUserMenuTooltipDismissed,
  setUserMenuTooltipDismissed,
} = actions;
