import { isSSR } from "helpers/client-server-helper";

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

let upgradeSuggestionSummary = {};
export function logUpgradeSuggestion(message) {
  const [, , callingMethod, sourceLocation] = new Error().stack.split("\n");

  if (!(callingMethod in upgradeSuggestionSummary)) {
    upgradeSuggestionSummary[callingMethod] = {};
  }
  if (!(message in upgradeSuggestionSummary[callingMethod])) {
    upgradeSuggestionSummary[callingMethod][message] = {
      message,
      sourceLocations: new Set(),
    };
  }

  upgradeSuggestionSummary[callingMethod][message].sourceLocations.add(sourceLocation);

  printToConsole();
}

let debounceTimer;
function printToConsole() {
  // client-side only
  if (isSSR()) return;
  if (!Object.keys(upgradeSuggestionSummary).length) return;

  window.clearTimeout(debounceTimer);
  debounceTimer = window.setTimeout(() => {
    const logs = Object.values(upgradeSuggestionSummary).flatMap(o => Object.values(o));
    // clean up this warning

    logs.forEach(({ message, sourceLocations }) => {
      const uniqueSourceLocations = [...sourceLocations];
      console.warn(message);
      // multiple log messages here has the benefit of creating links in your
      // browser console, so you can click into the source file. printing the
      // array, or a concatenated string, would not produce the links
      uniqueSourceLocations.map(m => m.replace(/.*\((.*)\)/, "$1")).forEach(m => console.warn(m));
    });
    upgradeSuggestionSummary = {};
  }, 2500);
}
