export function getCdnProductImageHostFromConfig() {
  if (typeof window !== "undefined" && window.RTR?.config?.cdn && typeof window.RTR.config.cdn.imageHost === "string") {
    return window.RTR.config.cdn.imageHost;
  }
  return "";
}

// Existing functionality default exports this as a singleton. Exporting the named function here for testing purposes.
export function getCdnProductImageOrigin() {
  // Always fallback to production image CDN
  let imageOrigin = "https://pc-ap.rtrcdn.com/";

  if (getCdnProductImageHostFromConfig()) {
    imageOrigin = getCdnProductImageHostFromConfig();
    // We wish to force https, but currently the urls are mounted protocol-relative by Ruby.
    if (imageOrigin.startsWith("//")) {
      imageOrigin = `https:${imageOrigin}`;
    }
    // Ruby typically provides the url without the trailing slash
    if (!imageOrigin.endsWith("/")) {
      imageOrigin = `${imageOrigin}/`;
    }
  }

  return imageOrigin;
}

export default getCdnProductImageOrigin();
