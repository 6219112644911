import PropTypes from "prop-types";
import { connect, shallowEqual, useSelector } from "react-redux";
import { getDisplayName } from "./utils";

// a convenience export, since you will almost always need the featureFlags
// constant when using this HOC/Hook
export { featureFlags as Flags } from "rtr-constants";

export const featureFlagsPropType = PropTypes.objectOf(PropTypes.bool);

const flagsSelector = ({ enabledFlags }) => enabledFlags;

function getFeatureFlagState(enabledFlags, requestedFlag) {
  return enabledFlags?.includes(requestedFlag);
}

/////////////////////////////////
//                             //
//  Selectors                  //
//  for functional Components  //
//                             //
/////////////////////////////////

export const requestedFlagSelector = requestedFlag => {
  return state => {
    const { flags } = flagsSelector(state) || {};

    return getFeatureFlagState(flags, requestedFlag) || false;
  };
};

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useFeatureFlag(requestedFlag) {
  const { flags } = useSelector(flagsSelector, shallowEqual) || {};

  return getFeatureFlagState(flags, requestedFlag);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

export function withFeatureFlags(...requestedFlags) {
  const mapStateToProps = state => {
    const { flags } = flagsSelector(state) || {};

    const featureFlags = requestedFlags.reduce((memo, flag) => {
      memo[flag] = getFeatureFlagState(flags, flag);
      return memo;
    }, {});

    return { featureFlags };
  };

  return WrappedComponent => {
    const C = connect(mapStateToProps)(WrappedComponent);

    C.displayName = `withFeatureFlags(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}
