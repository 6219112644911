import { LocalStorage } from "../site/localStorage";
import analyticsClient from "./analytics-client";

const persistence = new LocalStorage("infer");
const cacheKey = "logs";

/**
 * Add a log entry to the global infer log.
 * Global Infer Logs are attribute and page agnostic. Any
 * hash passed in will be written to local storage and logged
 * upon the next page load.
 * @param logData the log entry to add
 */
export function globalInferLog(logData) {
  if (logData) {
    const inferLogs = persistence.get(cacheKey) || [];
    inferLogs.push(logData);
    persistence.set(cacheKey, inferLogs);
  }
}

/**
 * send the global infer log to the server, and clear it
 */
export function sendGlobalInferLog() {
  const inferLogs = persistence.get(cacheKey) || [];
  // log each entry
  inferLogs.forEach(log => {
    try {
      analyticsClient.log(log);
    } catch (err) {
      /* noop */
    }
  });
  persistence.remove(cacheKey);
}
