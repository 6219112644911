import PropTypes from "prop-types";
import { applePayButtonPropType } from "./apple-pay-button-prop-type";

/**
 * Line Items must be of the following format:
 * PropTypes.arrayOf({
 *   label: PropTypes.string.isRequired,
 *   amount: PropTypes.string.isRequired,
 *   type: PropTypes.oneOf(["deferred", "total"]),
 *   }),
 *
 * Types:
 * deferred -> flags to apple pay request object that this needs a deferred message
 * total -> flags to the apple pay request object to filter out the total from the line items
 */
export const applePayLineItemsForMembershipPropTypes = {
  getLineItems: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  applePayStyle: applePayButtonPropType,
  nextBillingDate: PropTypes.string,
};
