import MediaQueries from "constants/mediaQueries";
import UAParser from "ua-parser-js";

export function hasTouchEventSupport() {
  if (typeof window === "undefined") return;

  return "ontouchstart" in window;
}

export function isMobileViewport(userAgent) {
  // the matchMedia piece is mostly for tests
  if (typeof window === "undefined" || !window.matchMedia) {
    if (!userAgent) return true; // default to mobile if we can't figure it out

    // server side. Make a best guess based on the UA string. The client side will give an authoritive answer later
    const uaParser = new UAParser(userAgent);
    const device = uaParser.getDevice();

    return device.type === "mobile" || device.type === "tablet";
  }

  const { MOBILE_AND_TABLET_BREAKPOINT: breakpoint } = MediaQueries.breakpoints;

  return window.matchMedia(`(max-width: ${breakpoint}px)`).matches;
}

export function isIPhone() {
  return navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i);
}

export function isIPad() {
  return navigator.userAgent.match(/(iPad)/i);
}

export function windowIsNarrow() {
  return detectWindowSize() === "mobile";
}

export function windowIsMedium() {
  return detectWindowSize() === "tablet";
}

export function windowIsWide() {
  return detectWindowSize() === "desktop";
}

function detectWindowSize() {
  const sizeString = window.getComputedStyle(document.querySelector("body"), ":before").getPropertyValue("content");
  // Browsers return the content differently, so we must strip any quotes from the returned string
  return sizeString.replace(/['"\s]+/g, "");
}

export function checkIfTouchDevice() {
  const touchEnabled = "ontouchstart" in document.documentElement;

  if (touchEnabled) {
    document.documentElement.classList.add("mobile");

    if (document.querySelectorAll("meta[name='viewport'][content*='0.7']").length > 0) {
      document.documentElement.classList.add("mobile-scaled");
    }
  } else {
    document.documentElement.classList.add("no-touch");
  }
}
