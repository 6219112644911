import client from "clients/RawClient";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";
import ActionTypes from "./action-types.js";
import { createAction } from "redux-actions";
import { getUserReviewSummaryFromLocalStorage, saveUserReviewSummaryToLocalStorage } from "helpers/userProfile-helpers";
import { getUserId } from "../components/source/hoc/with-user-data";

const actions = {
  updateUserProfileRequest: createAction(ActionTypes.UPDATE_USER_PROFILE),
  updateUserProfileDone: createAction(ActionTypes.UPDATE_USER_PROFILE_DONE),
  updateUserProfileFail: createAction(ActionTypes.UPDATE_USER_PROFILE_FAIL),
  userHistoryStats: createAction(ActionTypes.USER_HISTORY_STATS),
  userHistoryStatsLoading: createAction(ActionTypes.USER_HISTORY_STATS_LOADING),
  userHistoryStatsFailure: createAction(ActionTypes.USER_HISTORY_STATS_FAILURE),
  fetchUserReviewSummaryFailure: createAction(ActionTypes.FETCH_USER_REVIEW_SUMMARY_FAILURE),
  userReviewSummary: createAction(ActionTypes.USER_REVIEW_SUMMARY),

  fetchUserStats: () => {
    return dispatch => {
      dispatch(actions.userHistoryStatsLoading(true));
      client
        .get("/userStats")
        .then(data => {
          dispatch(actions.userHistoryStats(data));
        })
        .catch(() => {
          dispatch(actions.userHistoryStatsFailure(true));
        })
        .finally(() => {
          dispatch(actions.userHistoryStatsLoading(false));
        });
    };
  },

  updateUserProfile: (userProfile, onFailure = () => {}) => {
    return dispatch => {
      dispatch(actions.updateUserProfileRequest(userProfile));
      return createAjaxAuthRetry({
        url: "/userProfiles/me",
        type: "PATCH",
        data: JSON.stringify(userProfile),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(response => dispatch(actions.updateUserProfileDone(response.data)))
        .catch(exception => {
          dispatch(actions.updateUserProfileFail(exception.errorThrown));
          onFailure(exception.jqXHR.status);
        });
    };
  },

  fetchUserReviewSummary: () => {
    return (dispatch, getState) => {
      const { userData } = getState();
      const uid = getUserId(userData);
      if (!uid) return;

      const userReviewSummary = getUserReviewSummaryFromLocalStorage(uid);

      if (userReviewSummary) {
        return Promise.resolve(dispatch(actions.userReviewSummary(userReviewSummary)));
      }

      return client
        .get("/header/reviews")
        .then(data => {
          const sanitizedData = data || {};
          dispatch(actions.userReviewSummary(sanitizedData));
          saveUserReviewSummaryToLocalStorage(sanitizedData, uid);
        })
        .catch(err => {
          dispatch(actions.fetchUserReviewSummaryFailure(err));
        });
    };
  },

  redirectTo: url => {
    if (!url) {
      return;
    }
    window.location.href = url;
  },
};

export default actions;

export const {
  updateUserProfileRequest,
  updateUserProfileDone,
  updateUserProfileFail,
  userHistoryStats,
  userHistoryStatsLoading,
  userHistoryStatsFailure,
  userReviewSummary,
  fetchUserStats,
  updateUserProfile,
  fetchUserReviewSummary,
  fetchUserReviewSummaryFailure,
  redirectTo,
} = actions;
