import { getProductInformationByStyleNames } from "actions/post-shipment-actions";
import { LocalStorage } from "../site/localStorage";
import { isMasquerading } from "../components/source/hoc/with-user-data";

// Local Storage Keys
const VIEWED_PRODUCTS = "viewedProductsLocalStorageClient";
const VIEWED_PRODUCTS_LIST = "viewedProductsList";

const viewedProductsListThreshold = 10; // the max number of products we store in a given time

const ViewedProductsHelpers = {
  localStorageClient: function () {
    if (typeof window === "undefined" || LocalStorage.disabled) {
      return null;
    }

    return new LocalStorage(VIEWED_PRODUCTS);
  },

  // failure cases returns an empty array
  getViewedProductList: function () {
    const localStorageClient = this.localStorageClient();
    if (!localStorageClient) {
      return [];
    }

    const viewedProducts = localStorageClient.get(VIEWED_PRODUCTS_LIST);
    if (!viewedProducts) {
      return [];
    }

    return viewedProducts;
  },

  fetchViewedProductData: function (dispatch) {
    // getViewedProducts is guaranteed to return an array
    const styleNames = this.getViewedProductList();

    // MH Explanation 10-4-19: we need to fetch the actual product information for the
    // styleNames we want to display
    getProductInformationByStyleNames(styleNames)(dispatch);
  },

  addViewedProductToList: function (styleName, userData) {
    if (isMasquerading(userData) || !styleName) {
      return;
    }

    const localStorageClient = this.localStorageClient();
    let viewedProductsList = this.getViewedProductList();

    // first item viewed
    if (viewedProductsList.length === 0) {
      localStorageClient.set(VIEWED_PRODUCTS_LIST, [styleName]);
      return;
    }

    // don't add a product if its already on the list
    if (viewedProductsList.includes(styleName)) {
      return;
    }

    // we only store a set number of products in this list, if the user views more than that,
    // we remove the oldest item in the list before adding the new item
    if (viewedProductsList.length >= viewedProductsListThreshold) {
      viewedProductsList = viewedProductsList.slice(0, viewedProductsListThreshold - 1);
    }

    localStorageClient.set(VIEWED_PRODUCTS_LIST, [styleName, ...viewedProductsList]);
  },

  // should be called when an item is hearted, or added to bag
  removeViewedProductFromList: function (styleName, userData) {
    if (isMasquerading(userData)) {
      return;
    }

    const localStorageClient = this.localStorageClient();
    const viewedProductsList = this.getViewedProductList();

    const listWithoutProduct = viewedProductsList.filter(style => style !== styleName);
    localStorageClient.set(VIEWED_PRODUCTS_LIST, listWithoutProduct);
  },

  clearViewedProducts: function () {
    const localStorageClient = this.localStorageClient();
    if (!localStorageClient) {
      return;
    }
    localStorageClient.remove(VIEWED_PRODUCTS_LIST);
  },
};

export default ViewedProductsHelpers;

export const {
  localStorageClient,
  getViewedProductList,
  fetchViewedProductData,
  addViewedProductToList,
  removeViewedProductFromList,
  clearViewedProducts,
} = ViewedProductsHelpers;
