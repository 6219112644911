import ActionTypes from "actions/action-types";

export const types = [
  ActionTypes.CHECK_HOME_PICKUP_ELIGIBILITY_ERROR,
  ActionTypes.CHECK_HOME_PICKUP_ELIGIBILITY_SUCCESS,
  ActionTypes.DISPLAY_HOME_PICKUP,
  ActionTypes.SCHEDULE_HOME_PICKUP_ERROR,
  ActionTypes.SCHEDULE_HOME_PICKUP_SUCCESS,
  ActionTypes.SET_HOME_PICKUP_ELIGIBILITY,
  ActionTypes.SET_IS_HOME_PICKUP_SCHEDULER_LOADING,
  ActionTypes.DISPLAY_HOME_PICKUP_NO_ACTIVE_ORDERS,
  ActionTypes.DISPLAY_HOME_PICKUP_ALREADY_SCHEDULED,
];

const setInitialState = function (state) {
  return {
    ...state,
    homePickup: {
      displayHomePickup: false,
      homePickupDetails: {},
      homePickupEligibility: {},
      homePickupBookingIds: [],
      homePickupRentBeginDate: "",
      isSchedulerLoading: false,
    },
    homePickupEligibleOrders: [],
  };
};

export function fn(state, action) {
  let newState = { ...state };

  if (!newState.homePickup) {
    newState = setInitialState(newState);
  }

  switch (action.type) {
    case ActionTypes.CHECK_HOME_PICKUP_ELIGIBILITY_SUCCESS:
      return {
        ...newState,
        homePickup: {
          ...newState.homePickup,
          displayHomePickup: true,
          homePickupBookingIds: action.payload.bookingIds,
          homePickupEligibility: action.payload.eligibilityData,
          homePickupRentBeginDate: action.payload.rentBeginDate,
          isSchedulerLoading: false,
        },
      };
    case ActionTypes.CHECK_HOME_PICKUP_ELIGIBILITY_ERROR:
      return {
        ...newState,
        homePickup: {
          ...newState.homePickup,
          displayHomePickup: true,
          homePickupBookingIds: [],
          homePickupEligibility: {},
          homePickupRentBeginDate: "",
          isSchedulerLoading: false,
          schedulerError: action.payload,
        },
      };
    case ActionTypes.DISPLAY_HOME_PICKUP:
      return {
        ...newState,
        homePickup: {
          ...newState.homePickup,
          displayHomePickup: action.payload,
          isSchedulerLoading:
            typeof action.payload === "boolean" && !action.payload ? false : newState.homePickup.isSchedulerLoading,
        },
      };
    case ActionTypes.SET_HOME_PICKUP_ELIGIBILITY:
      return {
        ...newState,
        homePickup: {
          ...newState.homePickup,
          homePickupEligibility: {
            ...newState.homePickup.homePickupEligibility,
            ...action.payload,
          },
        },
      };
    case ActionTypes.SET_IS_HOME_PICKUP_SCHEDULER_LOADING:
      return {
        ...newState,
        homePickup: {
          ...newState.homePickup,
          isSchedulerLoading: action.payload,
        },
      };
    case ActionTypes.SCHEDULE_HOME_PICKUP_SUCCESS:
      return {
        ...newState,
        homePickup: {
          ...newState.homePickup,
          homePickupDetails: action.payload,
          displayHomePickup: false,
          isSchedulerLoading: false,
        },
        homePickupEligibleOrders: [],
      };
    case ActionTypes.SCHEDULE_HOME_PICKUP_ERROR:
      return {
        ...newState,
        homePickup: {
          ...newState.homePickup,
          homePickupDetails: {},
          isSchedulerLoading: false,
          schedulerError: action.payload,
        },
      };
    case ActionTypes.DISPLAY_HOME_PICKUP_NO_ACTIVE_ORDERS:
      return {
        ...newState,
        displayHomePickupNoActiveOrders: action.payload,
        homePickup: {
          ...newState.homePickup,
          isSchedulerLoading: false,
        },
      };
    case ActionTypes.DISPLAY_HOME_PICKUP_ALREADY_SCHEDULED:
      return {
        ...newState,
        displayHomePickupAlreadyScheduled: action.payload,
        homePickup: {
          ...newState.homePickup,
          isSchedulerLoading: false,
        },
      };
    default:
      return newState;
  }
}

export default {
  types: types,
  fn: fn,
};
