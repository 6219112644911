import $ from "clients/RawClient";
import ActionTypes from "./action-types.js";
import { createAction } from "redux-actions";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";
import { requestedFlagSelector, Flags } from "components/source/hoc/with-feature-flags";

var actions = {
  membershipTierLoadFailure: createAction(ActionTypes.MEMBERSHIP_TIER_LOAD_FAILURE),
  membershipTierLoadSuccess: createAction(ActionTypes.MEMBERSHIP_TIER_LOAD_SUCCESS),
  membershipTierLoading: createAction(ActionTypes.MEMBERSHIP_TIER_LOADING),
  membershipTiersLoadFailure: createAction(ActionTypes.MEMBERSHIP_TIERS_LOAD_FAILURE),
  membershipTiersLoadSuccess: createAction(ActionTypes.MEMBERSHIP_TIERS_LOAD_SUCCESS),
  membershipTiersLoading: createAction(ActionTypes.MEMBERSHIP_TIERS_LOADING),
  membershipChangePlanPreviewLoadSuccess: createAction(ActionTypes.MEMBERSHIP_CHANGE_PLAN_PREVIEW_LOAD_SUCCESS),
  membershipChangePlanPreviewAllLoadSuccess: createAction(ActionTypes.MEMBERSHIP_CHANGE_PLAN_PREVIEW_ALL_LOAD_SUCCESS),
  membershipChangePlanPreviewLoading: createAction(ActionTypes.MEMBERSHIP_CHANGE_PLAN_PREVIEW_LOADING),
  membershipChangePlanPreviewFail: createAction(ActionTypes.MEMBERSHIP_CHANGE_PLAN_PREVIEW_FAIL),
  immediateUpgradePlanPreviewLoading: createAction(ActionTypes.IMMEDIATE_UPGRADE_PLAN_PREVIEW_LOADING),
  immediateUpgradePlanPreview: createAction(ActionTypes.IMMEDIATE_UPGRADE_PLAN_PREVIEW),
  immediateUpgradePlanPreviewFail: createAction(ActionTypes.IMMEDIATE_UPGRADE_PLAN_PREVIEW_FAIL),
  memberProgramChangeInfo: createAction(ActionTypes.MEMBER_PROGRAM_CHANGE_INFO),
  memberProgramChangeInfoFail: createAction(ActionTypes.MEMBER_PROGRAM_CHANGE_INFO_FAIL),
  memberProgramChangeInfoLoading: createAction(ActionTypes.MEMBER_PROGRAM_CHANGE_INFO_LOADING),
  setFormerUnlimited: createAction(ActionTypes.SET_FORMER_UNLIMITED),
  setFormerUnlimitedLoaded: createAction(ActionTypes.SET_FORMER_UNLIMITED_LOADED),

  getMembershipTier: function (tierId) {
    return function (dispatch) {
      dispatch(actions.membershipTierLoading(true));
      $.ajax({
        url: `/membershipTier/${tierId}`,
        type: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then(
          data => {
            dispatch(actions.membershipTierLoadSuccess(data));
          },
          () => {} // prevent an uncaught rejection
        )
        .finally(() => {
          dispatch(actions.membershipTierLoading(false));
        });
    };
  },

  getMembershipTiers: function (onSuccessCallBack, onErrorCallBack) {
    return function (dispatch) {
      dispatch(actions.membershipTiersLoading(true));
      return $.ajax({
        url: "/membershipTiers",
        type: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then(
          data => {
            dispatch(actions.membershipTiersLoadSuccess(data));
            if (typeof onSuccessCallBack === "function") {
              onSuccessCallBack(data);
            }
          },
          function (xhr, _statusText, errorThrown) {
            let error = errorThrown;
            if (xhr.status === 400) {
              error = xhr.responseText;
            }

            dispatch(actions.membershipTiersLoadFailure(error));

            if (typeof onErrorCallBack === "function") {
              onErrorCallBack();
            }
          }
        )
        .finally(() => {
          dispatch(actions.membershipTiersLoading(false));
        });
    };
  },

  getImmediateUpgradePlanPreview: function ({
    tierId,
    promoCode = null,
    effectiveOn = null,
    includeChangeCharge = false,
  }) {
    return function (dispatch) {
      dispatch(actions.immediateUpgradePlanPreviewLoading(true));
      return createAjaxAuthRetry({
        url: `/membershipChangePlanPreview/${tierId}`,
        type: "GET",
        headers: {
          Accept: "application/json",
        },
        data: {
          ...(effectiveOn && { effectiveOn }),
          ...(promoCode && { promoCode }),
          ...(includeChangeCharge && { included: "membershipTierChangeCharges" }),
        },
      })
        .then(resp => {
          // there is no reason why this wouldn't be an array, but just in case
          if (Array.isArray(resp.data)) {
            dispatch(actions.immediateUpgradePlanPreview(resp.data[0]));
            dispatch(actions.immediateUpgradePlanPreviewFail(false));
          }
        })
        .catch(() => {
          dispatch(actions.immediateUpgradePlanPreviewFail(true));
        })
        .finally(() => {
          dispatch(actions.immediateUpgradePlanPreviewLoading(false));
        });
    };
  },

  getMembershipPausedChangePlanPreview: function (tierRevisionId, includes, promoCode = null) {
    // GN [EXPLANATION] 2/02/21: the membershipChangePlanPreview endpoint takes either a tierRevisionId or the new proposed tier id
    // We provide the tierRevisionId for Paused users in membership-summary to get pricing for a specific tier revision
    return function (dispatch) {
      dispatch(actions.membershipChangePlanPreviewLoading(true));
      createAjaxAuthRetry({
        url: `/membershipPausedChangePlanPreview/${tierRevisionId}`,
        type: "GET",
        headers: {
          Accept: "application/json",
        },
        data: {
          ...(promoCode && { promoCode }),
          ...(includes && { includes }),
        },
      })
        .then(resp => {
          // there is no reason why this wouldn't be an array, but just in case
          if (Array.isArray(resp.data)) {
            dispatch(actions.membershipChangePlanPreviewLoadSuccess(resp.data[0]));
            dispatch(actions.membershipChangePlanPreviewFail(false));
          } else {
            dispatch(actions.membershipChangePlanPreviewFail(true));
          }
        })
        .catch(() => {
          dispatch(actions.membershipChangePlanPreviewFail(true));
        })
        .finally(() => {
          dispatch(actions.membershipChangePlanPreviewLoading(false));
        });
    };
  },

  getMembershipChangePlanPreviewAll: function (effectiveOn) {
    return function (dispatch) {
      createAjaxAuthRetry({
        url: `/membershipChangePlanPreviewAll`,
        type: "GET",
        headers: {
          Accept: "application/json",
        },
        data: {
          effectiveOn: effectiveOn,
          slotCountDifference: 0,
        },
      })
        .then(resp => {
          // there is no reason why this wouldn't be an array, but just in case
          if (Array.isArray(resp.data)) {
            dispatch(actions.membershipChangePlanPreviewAllLoadSuccess(resp.data));
          }
        })
        .catch(() => {
          /* Don't need to do anything here, just catching a thrown error so we don't hit the .then block on a network call error */
        });
    };
  },

  getMemberProgramChangeInfo: function () {
    return function (dispatch, getState) {
      dispatch(actions.memberProgramChangeInfoLoading(true));
      return $.ajax({
        url: `/memberProgramChangeInfo`,
        type: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then(
          data => {
            dispatch(actions.memberProgramChangeInfo(data));
            // we used to set formerUnlimited state server side and put it in base redux state
            // the data we need to set formerUnlimited also comes back in this dispatch
            // which gets called on every page load if a user is not anonymous
            const state = getState();
            const isFlagEnabled = requestedFlagSelector(Flags.FORMER_UNLIMITED_INSIDER)(state);
            const isFormerUnlimited = data.wasActiveMemberPreviouslyUnlimited;
            dispatch(actions.setFormerUnlimited(!!(isFlagEnabled && isFormerUnlimited))); // coerce to boolean
          },
          () => {
            dispatch(actions.memberProgramChangeInfoFail(true));
          }
        )
        .finally(() => {
          dispatch(actions.memberProgramChangeInfoLoading(false));
          dispatch(actions.setFormerUnlimitedLoaded(true));
        });
    };
  },
};

export default actions;

export const {
  membershipTierLoadFailure,
  membershipTierLoadSuccess,
  membershipTierLoading,
  membershipTiersLoadFailure,
  membershipTiersLoadSuccess,
  membershipTiersLoading,
  membershipChangePlanPreviewLoadSuccess,
  membershipChangePlanPreviewAllLoadSuccess,
  membershipChangePlanWithSlotsPreviewLoadSuccess,
  membershipChangePlanPreviewLoading,
  membershipChangePlanPreviewFail,
  membershipTierRecommendationSuccess,
  membershipTierRecommendationLoading,
  membershipTierEducationSuccess,
  immediateUpgradePlanPreviewLoading,
  immediateUpgradePlanPreview,
  immediateUpgradePlanPreviewFail,
  memberProgramChangeInfo,
  memberProgramChangeInfoFail,
  memberProgramChangeInfoLoading,
  getMembershipTier,
  getMembershipTiers,
  getImmediateUpgradePlanPreview,
  getMembershipPausedChangePlanPreview,
  getMembershipChangePlanPreviewAll,
  getMemberProgramChangeInfo,
  getMembershipChangePlanPreview,
  getMembershipTierRecommendation,
  setFormerUnlimited,
} = actions;
