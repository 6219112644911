import $ from "clients/RawClient";
import ActionTypes from "./action-types";
import { createAction } from "redux-actions";
import { createAsyncAction } from "./action-utils";
import { SmartComponents } from "../routes";
import SharedActions from "actions/shared-actions";
import { RENT_MY_STYLE_QUIZ_MODAL_NAME } from "rtr-constants";

const getSkinnyBannerContentClient = () => {
  return $.get(SmartComponents.skinnyBanner);
};

const getTopRightContentClient = () => {
  return $.get(SmartComponents.topRight);
};

const getReferralsReminderModalClient = () => {
  return $.get(SmartComponents.referralsReminderModal);
};

const actions = {
  getPDPContentSuccess: createAction(ActionTypes.SMART_PDP_CONTENT_SUCCESS),
  getPDPContentFailure: createAction(ActionTypes.SMART_PDP_CONTENT_FAILURE),
  getPDPContent: () => {
    return function (dispatch) {
      $.get(SmartComponents.smartPDPContent).then(
        data => {
          dispatch(actions.getPDPContentSuccess(data));
        },
        () => dispatch(actions.getPDPContentFailure("Failed to fetch CMS content for PDP"))
      );
    };
  },

  getSmartCheckoutBannerSuccess: createAction(ActionTypes.SMART_CHECKOUT_BANNER_SUCCESS),
  getSmartCheckoutBannerFailure: createAction(ActionTypes.SMART_CHECKOUT_BANNER_FAILURE),
  getSmartCheckoutBanner: () => {
    return dispatch => {
      $.get(SmartComponents.checkoutBanner).then(
        data => dispatch(actions.getSmartCheckoutBannerSuccess(data)),
        () => dispatch(actions.getSmartCheckoutBannerFailure("Failed to fetch banner for Checkout"))
      );
    };
  },

  getSmartSFNextCheckoutBanner: checkoutId => {
    return dispatch => {
      $.get(
        `${SmartComponents.checkoutBannerSFNext}?filter=${JSON.stringify({ context: `checkoutId:${checkoutId}` })}`
      ).then(
        data => dispatch(actions.getSmartCheckoutBannerSuccess(data)),
        () => dispatch(actions.getSmartCheckoutBannerFailure("Failed to fetch banner for Checkout"))
      );
    };
  },

  getSkinnyBannerContent: createAsyncAction(ActionTypes.SMART_SKINNY_BANNER_CONTENT, getSkinnyBannerContentClient),

  getTopRightContent: createAsyncAction(ActionTypes.SMART_TOP_RIGHT_CONTENT, getTopRightContentClient),

  getReferralsReminderModal: createAsyncAction(
    ActionTypes.GET_SMART_REFERRALS_REMINDER_MODAL,
    getReferralsReminderModalClient
  ),

  getReferralsSaleGtmModalModalSuccess: createAction(ActionTypes.SMART_REFERRALS_SALE_GTM_MODAL_SUCCESS),
  getReferralsSaleGtmModalModal: () => {
    return dispatch => {
      $.get(SmartComponents.referralsSaleGtmModalModal).then(data =>
        dispatch(actions.getReferralsSaleGtmModalModalSuccess(data))
      );
    };
  },

  getRentMyStyleQuizSuccess: createAction(ActionTypes.SMART_RENT_MY_STYLE_QUIZ_SUCCESS),
  getRentMyStyleQuiz: triggerModalOnLoad => {
    return (dispatch, getState) => {
      const triggerModal = () => dispatch(SharedActions.displayModal(RENT_MY_STYLE_QUIZ_MODAL_NAME));
      const { smartComponentState: { rentMyStyleQuizContent } = {} } = getState();

      if (rentMyStyleQuizContent) {
        return triggerModalOnLoad ? triggerModal() : void 0;
      }

      $.get(SmartComponents.rentMyStyleQuiz).then(data => {
        dispatch(actions.getRentMyStyleQuizSuccess(data));

        if (triggerModalOnLoad) {
          triggerModal();
        }
      });
    };
  },
};

export default actions;
