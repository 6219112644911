import PropTypes from "prop-types";
import { idPropType } from "./id-prop-type";
import { ProductImageOrientation, ProductImageSize } from "../../helpers/product-image-helpers";

export const productImageOrientationPropType = PropTypes.shape({
  [ProductImageSize.x1080.path]: PropTypes.string,
  [ProductImageSize.x183.path]: PropTypes.string,
  [ProductImageSize.x270.path]: PropTypes.string,
  [ProductImageSize.x309.path]: PropTypes.string,
  [ProductImageSize.x360.path]: PropTypes.string,
  [ProductImageSize.x480.path]: PropTypes.string,
  [ProductImageSize.x600.path]: PropTypes.string,
  [ProductImageSize.x70.path]: PropTypes.string,
  [ProductImageSize.x800.path]: PropTypes.string,
  [ProductImageSize.ORIGINAL.path]: PropTypes.string,
});
export const productImagesPropType = PropTypes.shape({
  [ProductImageOrientation.BACK.path]: productImageOrientationPropType,
  [ProductImageOrientation.EDITORIAL.path]: productImageOrientationPropType,
  [ProductImageOrientation.FRONT.path]: productImageOrientationPropType,
  [ProductImageOrientation.NO_MODEL.path]: productImageOrientationPropType,
  [ProductImageOrientation.SIDE.path]: productImageOrientationPropType,
});

export const productPropType = PropTypes.shape({
  id: idPropType,
  displayName: PropTypes.string,
  designer: PropTypes.shape({
    id: idPropType,
    displayName: PropTypes.string,
  }),
  purchasePrice: PropTypes.string,
  retailPrice: PropTypes.string, // "USD 123.00"
  retailPriceFormatted: PropTypes.string, // TODO: delete this legacy Storefront.Ruby datum
  price: PropTypes.object, // pricePropType
  prices: PropTypes.array,
  // these do not feel like product propTypes
  annotationSuffix: PropTypes.string,
  upgradeTooltipText: PropTypes.string,
  onClickUpgradeTooltip: PropTypes.func,
  hidePrice: PropTypes.bool,
  images: productImagesPropType,
});

export const carouselPropType = PropTypes.shape({});
