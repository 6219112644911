import $ from "clients/RawClient";

import AuthActions from "actions/auth-actions";
import { HEAP_AUTH_TRIGGER_TYPES } from "./heap-helpers";

let store = null;

export function parseError(err, defaultText) {
  if (err.getResponseHeader("Content-Type") === "application/json") {
    try {
      return JSON.parse(err.responseText);
    } catch (e) {
      return defaultText;
    }
  }

  return err.responseText || defaultText;
}

export function initializeStoreForAjaxHelper(applicationStore) {
  if (store) return;

  store = applicationStore;
}

/**
 * @param {JQuery<TElement = HTMLElement>.AjaxSettings<any>} settings {@see https://api.jquery.com/Jquery.ajax/#jQuery-ajax-settings}
 * @param {Function|null} replacementCallback A callback function to fire instead of trying the AJAX request again
 * @return {Promise<{ data: any }>}
 * @throws {{ jqXHR: JQuery.jqXHR<any> }}
 * @see https://api.jquery.com/Jquery.ajax/
 */
export function createAjaxAuthRetry(settings, replacementCallback = null) {
  const ajaxFn = () =>
    new Promise((resolve, reject) => {
      return $.ajax(settings).then(
        data => resolve({ data }),
        error => reject({ jqXHR: error })
      );
    });

  return ajaxFn().catch(error => {
    if (error.jqXHR.status === 401) {
      return new Promise((resolve, reject) => {
        /**
         * Since ajaxFn resolves/rejects with an object, we can just pass in resolve and reject as callbacks here.
         * if replacementCallback is passed, fire that instead of the default callback.
         */
        const callback = replacementCallback
          ? () => {
              replacementCallback();
              resolve();
            }
          : () => ajaxFn().then(resolve).catch(reject);

        if (store && typeof store.dispatch === "function") {
          store.dispatch(AuthActions.showAuthModal({ callback, triggeredBy: HEAP_AUTH_TRIGGER_TYPES.AUTH_PROMPT }));
        } else if (store && typeof store.dispatch !== "function") {
          throw new TypeError("store.dispatch is not a function");
        } else if (!store) {
          throw new ReferenceError("store is not defined");
        }
      });
    }

    throw error;
  });
}

export function augmentConsoleErrorWithJqueryXHR(message, jqXHR) {
  return `${message} status: ${jqXHR?.status}, statusText: ${jqXHR?.statusText}, responseText: ${jqXHR?.responseText}`;
}
