import ActionTypes from "actions/action-types";

export const types = [
  ActionTypes.UPCOMING_ORDER_AVAILABLE_SIZES_FOR_SKUS_RESET,
  ActionTypes.UPCOMING_ORDER_AVAILABLE_SIZES_FOR_SKUS_SUCCESS,
  ActionTypes.UPCOMING_ORDER_CHANGE_SIZE_BOOKING_IDS_TO_REMOVE_SUCCESS,
  ActionTypes.UPCOMING_ORDER_CHANGE_SIZE_NEW_HOLD_IDS_SUCCESS,
  ActionTypes.UPCOMING_ORDER_CHANGE_SIZE_RESET,
  ActionTypes.UPCOMING_ORDER_GET_CANCELLATION_FEE_SUCCESS,
  ActionTypes.UPCOMING_ORDER_MODIFICATION_FAILURE,
  ActionTypes.UPCOMING_ORDER_MODIFICATION_LOADING,
  ActionTypes.UPCOMING_ORDER_MODIFICATION_PRICE_CHANGE,
  ActionTypes.UPCOMING_ORDER_MODIFICATION_SUCCESS,
];

const getUpcomingOrderModification = (state, action) => {
  const { upcomingOrderModification = {} } = state;
  const { payload, type } = action;

  switch (type) {
    case ActionTypes.UPCOMING_ORDER_AVAILABLE_SIZES_FOR_SKUS_RESET: {
      delete upcomingOrderModification.availableSizesForSkus;

      return upcomingOrderModification;
    }
    case ActionTypes.UPCOMING_ORDER_AVAILABLE_SIZES_FOR_SKUS_SUCCESS: {
      const { originalSku, availableSizes } = payload;

      const availableSizesForSkus = upcomingOrderModification.availableSizesForSkus || {};
      availableSizesForSkus[originalSku] = availableSizes;

      return {
        ...upcomingOrderModification,
        availableSizesForSkus,
      };
    }
    case ActionTypes.UPCOMING_ORDER_CHANGE_SIZE_BOOKING_IDS_TO_REMOVE_SUCCESS: {
      return {
        ...upcomingOrderModification,
        changeSizeBookingIdsToRemove: payload,
      };
    }
    case ActionTypes.UPCOMING_ORDER_CHANGE_SIZE_NEW_HOLD_IDS_SUCCESS: {
      return {
        ...upcomingOrderModification,
        changeSizeNewHoldIds: payload,
      };
    }
    case ActionTypes.UPCOMING_ORDER_CHANGE_SIZE_RESET: {
      delete upcomingOrderModification.changeSizeBookingIdsToRemove;
      delete upcomingOrderModification.changeSizeNewHoldIds;

      return upcomingOrderModification;
    }
    case ActionTypes.UPCOMING_ORDER_GET_CANCELLATION_FEE_SUCCESS: {
      return {
        ...upcomingOrderModification,
        cancellationFee: payload,
      };
    }
    case ActionTypes.UPCOMING_ORDER_MODIFICATION_FAILURE: {
      return {
        ...upcomingOrderModification,
        failure: payload,
      };
    }
    case ActionTypes.UPCOMING_ORDER_MODIFICATION_LOADING: {
      return {
        ...upcomingOrderModification,
        loading: payload,
      };
    }
    case ActionTypes.UPCOMING_ORDER_MODIFICATION_PRICE_CHANGE: {
      return {
        ...upcomingOrderModification,
        priceChange: payload,
      };
    }
    case ActionTypes.UPCOMING_ORDER_MODIFICATION_SUCCESS: {
      return {
        ...upcomingOrderModification,
        success: payload,
      };
    }
    default:
      return upcomingOrderModification;
  }
};

export const fn = (state, action) => {
  const upcomingOrderModification = getUpcomingOrderModification(state, action);

  return { ...state, upcomingOrderModification };
};

export default {
  types: types,
  fn: fn,
};
