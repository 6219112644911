import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node,
  tabName: PropTypes.string.isRequired,
};

export default function AtomTabPanel({ tabName, children }) {
  return (
    <div data-tabName={tabName} data-test-id="atom-tab-panel">
      {children}
    </div>
  );
}

AtomTabPanel.propTypes = propTypes;
