import { ThirdPartyScript } from "./thirdPartyScript";
import { shallowEqual, useSelector } from "react-redux";

const facebookSdk = () => {
  const publicEnv = useSelector(state => state.publicEnv, shallowEqual);
  if (publicEnv?.facebookAppId) {
    // @TODO: clean this up, had to hard code the channelUrl for the Plans page
    // since we don't have the same concept as Ruby's app_context.app_path
    return (
      <>
        <ThirdPartyScript
          id="facebook-connect-script"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
              try {
                window.fbAsyncInit = function() {
                  FB.init({
                    appId: "${publicEnv.facebookAppId}", // App ID
                    channelUrl: "//www.renttherunway.com/home/public/templates/channel.html", // Channel File
                    status: true, // check login status
                    cookie: true, // enable cookies to allow the server to access the session
                    xfbml: true  // parse XFBML
                  });
                
                  if (typeof fbSDKfinishedLoading !== 'undefined') { fbSDKfinishedLoading(); }
                };
                // Load Facebook SDK async
                (function(d, s, id) {
                  var js, fjs = d.getElementsByTagName(s)[0];
                  if (d.getElementById(id)) return;
                  js = d.createElement(s); js.id = id;
                  js.src = "//connect.facebook.net/en_US/all.js";
                  fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
              } catch (e) {
                if (window.Sentry && window.Sentry.captureException) { window.Sentry.captureException(e, {
                  tags: {
                    thirdparty: true,
                    thirdparty_name: "facebook-connect"
                  }
                }); }
              }
            `,
          }}></ThirdPartyScript>
      </>
    );
  } else {
    return <></>;
  }
};

export default facebookSdk;
