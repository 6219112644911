import { isMobileViewport as getIsMobileViewport } from "../device-helpers";
import { DeviceTypes } from "../../constants";
import { sanitize } from "helpers/sanitize";

export function getDeviceTypeHeaderFromRequestContext(context) {
  // destructuring the header like this is case-sensitive, unlike req.getHeader()
  const { req: { headers: { ["x-rtr-device-type"]: deviceType } = {} } = {} } = context;
  return deviceType;
}

export function getRefererFromRequest(req) {
  // destructuring the header like this is case-sensitive, unlike req.getHeader()
  const { headers: { ["referer"]: referer } = {} } = req || {};
  return sanitize(referer);
}

export function getUserAgentFromRequest(req) {
  // destructuring the header like this is case-sensitive, unlike req.getHeader()
  const { headers: { ["user-agent"]: userAgent } = {} } = req || {};
  return sanitize(userAgent);
}

/**
 * Get the canonical device type of the client that made the request
 * @param {Context} context The context object associated with the Next.js request
 * @returns {keyof typeof DeviceTypes} The device type of the request
 */
export function getRequestDeviceType(context) {
  const deviceType = getDeviceTypeHeaderFromRequestContext(context);
  const userAgent = getUserAgentFromRequest(context.req);

  // if an explicit device type header is sent, use that
  // otherwise fall back to UA
  if (deviceType) {
    return deviceType;
  } else {
    const isMobile = getIsMobileViewport(userAgent);
    return isMobile ? DeviceTypes.MOBILE : DeviceTypes.DESKTOP;
  }
}
