import RawClient from "clients/RawClient";
import { trace } from "@opentelemetry/api";
const PIXEL_URL = "/pixel/p.php";
const beaconSupported = () => !!navigator.sendBeacon;

/**
 * This file contains the specific client code to emit events to our various analytics platforms
 * In a typescript world we would define a type here:
 *
 * type AnalyticsPipe: (data: Record<string, any>) => void
 *
 * Add a new analytics integration here if the integration needs a stream of events from the entire application, and add it to analyticsIntegrationPipes
 */

/**
 * Send data to RTR pixel
 * @param data The data to send
 */
export function sendPixel(data) {
  let beaconQueuedSuccessfully = false;

  if (beaconSupported()) {
    // FormData causes the browser to send Content-Type: multipart/form-data which our pixel server does not like.
    // URLSearchParams, however, correctly sets Content-Type: application/x-www-form-urlencoded
    const paramsData = new URLSearchParams();
    paramsData.append("sendbeacon_status", "success"); // This object is only used by beacon, so mark as success. We'll override this key if it fails.
    Object.keys(data).forEach(function (key) {
      paramsData.append(key, data[key]);
    });
    // navigator.sendBeacon is present in all modern browsers except IE. It makes a POST request (with cookies) much
    // like $.post, with the major exception that (a) the requests are of the LOWEST priority and (b) are guaranteed to
    // fire even in the event of a page unload.
    // navigator.sendBeacon returns true/false based on whether or not the browser successfully QUEUED the request.
    // @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon

    // Chrome does not correctly set the payload content-type, so we need to manually control via Blob
    const beaconPayload = new Blob([paramsData.toString()], {
      type: "application/x-www-form-urlencoded;charset=UTF-8",
    });
    beaconQueuedSuccessfully = window.navigator.sendBeacon(PIXEL_URL, beaconPayload);
  }

  if (!beaconQueuedSuccessfully) {
    if (!beaconSupported()) {
      data["sendbeacon_status"] = "unsupported";
    } else {
      data["sendbeacon_status"] = "failure";
    }

    RawClient.post({
      url: PIXEL_URL,
      body: JSON.stringify(data),
    });
  }
}

export function sendSplunkRUMEvent(data) {
  const sendEvent = () => {
    const tracer = trace.getTracer("CustomEventLogger");
    const attributesNoAction = Object.assign({}, data);
    delete attributesNoAction["action"];

    const span = tracer.startSpan(data.action, {
      attributes: {
        "workflow.name": data.action,
        ...attributesNoAction,
      },
    });
    span.end();

    window.removeEventListener("splunkRumLoadEvent", sendEvent);
  };

  if (window.SplunkRum) {
    sendEvent();
  } else {
    // if RUM is not loaded, wait for the load event to fire. If RUM is never loaded, this will never trigger.
    window.addEventListener("splunkRumLoadEvent", sendEvent);
  }
}

const analyticsIntegrationPipes = [sendPixel, sendSplunkRUMEvent];

export default analyticsIntegrationPipes;
