export function formatPhoneNumber(value) {
  return value.replace(/(\(|\)|-| )/g, "");
}

export function getDigitsFromVisibleNumber(formattedNumber) {
  if (!formattedNumber) return "";
  let formattedPhoneNumber = formattedNumber.replace(/[^\d]/g, "");
  if (formattedPhoneNumber.charAt(0) === "1") {
    formattedPhoneNumber = formattedPhoneNumber.slice(1);
  }
  return formattedPhoneNumber;
}

export function getVisiblePhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  const doesStartsWithOne = phoneNumber.charAt(0) === "1";

  if (doesStartsWithOne) {
    if (phoneNumberLength < 5) return phoneNumber;

    if (phoneNumberLength < 8) {
      return `${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4)}`;
    } else {
      return `${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(
        7,
        11
      )}`;
    }
  } else {
    if (phoneNumberLength < 4) {
      return phoneNumber;
    } else if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }
  }
}
