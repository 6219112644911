import { useState, useEffect } from "react";
import { flagsAndExperimentNames, StandardCheckoutConstants } from "rtr-constants";
import { navigateTo } from "helpers/location-helpers";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";
import AtomPrimaryButton from "components/source/atoms/atom-primary-button";
import AtomSecondaryButton from "components/source/atoms/atom-secondary-button";
import { LocalStorage } from "../../../../site/localStorage";
import Close from "images/close.svg";
import { useFlagOrExperiment } from "components/source/hoc/with-flags-and-experiments";
import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";

const StickyReturnToCheckoutCTA = () => {
  const dispatch = useDispatch();
  const dacFlagEnabled = useFlagOrExperiment(flagsAndExperimentNames.SF_CE_DELAYED_ACCOUNT_CREATION);
  const [returnToCheckoutId, setReturnToCheckoutId] = useState(null);

  useEffect(() => {
    const localStorageClient = new LocalStorage(StandardCheckoutConstants.LOCALSTORAGE_CONTEXT);
    const checkoutId = localStorageClient.get(StandardCheckoutConstants.RETURN_TO_CHECKOUT_LOCALSTORAGE_KEY);

    if (dacFlagEnabled && checkoutId) {
      setReturnToCheckoutId(checkoutId);
    }

    dispatch(FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.SF_CE_DELAYED_ACCOUNT_CREATION));
  }, [dacFlagEnabled]);

  const onClickClose = () => {
    const localStorageClient = new LocalStorage(StandardCheckoutConstants.LOCALSTORAGE_CONTEXT);
    localStorageClient.remove(StandardCheckoutConstants.RETURN_TO_CHECKOUT_LOCALSTORAGE_KEY);
    setReturnToCheckoutId(null);
  };

  const onClickCta = () => {
    navigateTo(`/ce-checkout/${returnToCheckoutId}`);
  };

  if (!returnToCheckoutId) {
    return null;
  }

  return (
    <div className={styles["banner"]}>
      <button onClick={onClickClose} className={styles["close-button"]}>
        <Close role="img" />
      </button>

      <div className={styles["text-holder"]}>
        <div className={styles["header"]}>Hey! Looks like you forgot something...</div>
        <p className={styles["p"]}>Your membership is waiting in your bag. Checkout now to start renting today.</p>
      </div>

      <div className={styles["buttons-holder"]}>
        <AtomPrimaryButton
          className={styles["cta"]}
          onClick={onClickCta}
          buttonText="Return to Checkout"
          dataTestId={"sticky-return-to-checkout-cta-button"}
        />
        <AtomSecondaryButton
          className={`${styles["cta"]} ${styles["cta-dismiss"]}`}
          onClick={onClickClose}
          buttonText="Dismiss"
          dataTestId={"sticky-return-to-checkout-cta-dismiss-button"}
        />
      </div>
    </div>
  );
};

export default StickyReturnToCheckoutCTA;
