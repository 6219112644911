import React from "react";
import PropTypes from "prop-types";
import ActionLogger from "action-logger";
import FlagsAndExperimentsActions from "../../../actions/flags-and-experiments-actions";
import { flagsAndExperimentNames } from "rtr-constants";
import { compose } from "redux";
import { connect } from "react-redux";
import { onEnterPress } from "helpers/a11y-helper";
import { flagsAndExperimentsPropType, withFlagsAndExperiments } from "../hoc/with-flags-and-experiments";
import { sanitize } from "../../../helpers/sanitize";
import { getCategoryPath } from "helpers/category-helpers";
import PromptIcon from "./prompt-icon";
import typeaheadHelpers from "helpers/typeahead-helpers";
import { isStorefrontNext } from "helpers/environment-helpers";

class SearchBar extends React.Component {
  static propTypes = {
    analyticsObjectType: PropTypes.string,
    formClassNameAppend: PropTypes.string,
    inputFocusHidesNav: PropTypes.bool, // NW [EXPLANATION] 1/21/20: this prop determines whether the input expands to cover the nav when it receives focus
    inputId: PropTypes.string,
    placeholder: PropTypes.string,
    toggleSearchExpanded: PropTypes.func.isRequired,
    fetchFlagTreatment: PropTypes.func,
    fetchTypeaheadFlag: PropTypes.func,
    flagsAndExperiments: flagsAndExperimentsPropType,
    aiSearchPrompts: PropTypes.any,
    discoHost: PropTypes.string,
  };

  static defaultProps = {
    analyticsObjectType: "top_nav",
    formClassNameAppend: "",
    inputFocusHidesNav: true,
    inputId: "search-bar-input",
    placeholder: "Search",
    toggleSearchExpanded: () => {}, // NOSONAR
  };

  state = {
    searchQuery: "",
    blurred: true,
    isExecuting: false,
    typeaheadPrompts: [],
  };

  componentDidMount() {
    if (typeof this.props.flagsAndExperiments?.[flagsAndExperimentNames.AI_SEARCH_V_1_STOREFRONT_UX] === "undefined") {
      this.props.fetchFlagTreatment();
    }
    if (
      typeof this.props.flagsAndExperiments?.[flagsAndExperimentNames.CATALOG_SERVIES_STOREFRONT_ENABLE_TYPEAHEAD] ===
      "undefined"
    ) {
      this.props.fetchTypeaheadFlag();
    }
  }

  handleFocus = () => {
    this.toggleSearchInput(true);
    this.setState({ blurred: false });
  };

  handleBlur = () => {
    this.toggleSearchInput(false);
    this.setState({ blurred: true });
  };

  handleChange = async event => {
    const query = sanitize(event.target.value);

    this.setState({
      searchQuery: query,
    });

    // Clear typeahead results
    this.setState({ typeaheadPrompts: [] });

    // Typeahead only returns results for terms with more than 3 characters
    const prompts = await typeaheadHelpers.executeTypeaheadSearch(
      query,
      this.props.flagsAndExperiments?.[flagsAndExperimentNames.CATALOG_SERVIES_STOREFRONT_ENABLE_TYPEAHEAD],
      isStorefrontNext(),
      this.props.discoHost
    );
    this.setState({ typeaheadPrompts: prompts });
  };

  handleSubmit = () => {
    this.setState({ isExecuting: true });
    ActionLogger.inferAction({
      objectType: this.props.analyticsObjectType,
      action: "execute_search",
      search_text: this.state.searchQuery,
    });
  };

  renderPrompts() {
    if (
      this.props.flagsAndExperiments?.[flagsAndExperimentNames.AI_SEARCH_V_1_STOREFRONT_UX] &&
      !this.props.flagsAndExperiments?.[flagsAndExperimentNames.CATALOG_SERVIES_STOREFRONT_ENABLE_TYPEAHEAD] &&
      this.props.aiSearchPrompts
    ) {
      const promptsArr = Array.from(this.props.aiSearchPrompts).slice(0, 6);
      return (
        <div hidden={this.state.blurred} className="expanded_search">
          <span
            className="expanded_search_bar_close"
            hidden={this.props.formClassNameAppend !== "mobile"}
            onClick={this.handleBlur}
            role="presentation"
            onKeyDown={onEnterPress(() => this.handleBlur())}>
            x
          </span>
          <span className="expanded_search_bar_prompt_title">WHY NOT TRY</span>
          <ul className="expanded_search_bar_menu">
            {promptsArr.map(item => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
              <li
                className="expanded_search_bar_prompt"
                key={item}
                onMouseDown={e => e.preventDefault()}
                onClick={() => this.queryPrompt(item)}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      );
    } else if (
      this.state.typeaheadPrompts?.length > 0 &&
      this.props.flagsAndExperiments?.[flagsAndExperimentNames.CATALOG_SERVIES_STOREFRONT_ENABLE_TYPEAHEAD]
    ) {
      const promptsArr = this.state.typeaheadPrompts;
      return (
        <div hidden={this.state.blurred} className="expanded_search">
          <span
            className="expanded_search_bar_close"
            hidden={this.props.formClassNameAppend !== "mobile"}
            onClick={this.handleBlur}
            role="presentation"
            onKeyDown={onEnterPress(() => this.handleBlur())}>
            x
          </span>
          <ul className="expanded_search_bar_menu">
            <li className="expanded_search_bar_prompt_title">SUGGESTIONS</li>
            {promptsArr.map(item =>
              this.executeTypeaheadSearch(item) ? (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                <li key={item.id} onMouseDown={e => e.preventDefault()}>
                  <a
                    className="expanded_search_bar_prompt typeahead-prompt"
                    href={this.executeTypeaheadSearch(item)}
                    data-heap-id={`typeahead-prompt-${item.type}`}>
                    <div className="prompt_icon">
                      <PromptIcon type={item.type} />
                    </div>
                    <div className="typeahead_prompt_name">{item.displayName}</div>
                    <div className="prompt_type">{item.type}</div>
                  </a>
                </li>
              ) : (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                <li
                  className="expanded_search_bar_prompt"
                  key={item}
                  onMouseDown={e => e.preventDefault()}
                  onClick={() => this.queryPrompt(item.displayName)}>
                  <span className="prompt_name">{item.displayName}</span>
                  <span className="prompt_type">{item.type}</span>
                </li>
              )
            )}
          </ul>
        </div>
      );
    }
  }

  queryPrompt(term) {
    const form = document.getElementById("search-bar-form");
    this.setState({ searchQuery: term });
    form.elements["filters[searchText]"].value = term;
    form.submit();
  }

  executeTypeaheadSearch(typeaheadResult) {
    const id = typeaheadResult.id;

    if (typeaheadResult.type === "curation") {
      return `/shop/${id}/products`;
    } else if (typeaheadResult.type === "designer") {
      return `/designers/${id}/products`;
    } else if (typeaheadResult.type === "category") {
      const categoryPath = getCategoryPath(id);
      return !categoryPath ? `/products/clothing?filters[searchText]=${typeaheadResult.displayName}` : categoryPath;
    }
  }

  toggleSearchInput = isOpen => {
    if (this.props.inputFocusHidesNav) {
      this.props.toggleSearchExpanded(isOpen);
    }
  };

  render() {
    const { searchQuery } = this.state;
    const inputId = this.props.inputId;
    const formClassName = `search-bar__form ${this.props.formClassNameAppend}`;
    const inputExtensionClass = `search-bar__form__input${this.state.isExecuting ? " mid-execution" : ""}`;

    return (
      <div className="search-bar">
        <form
          className={formClassName}
          id="search-bar-form"
          onSubmit={this.handleSubmit}
          action="/products"
          method="get">
          <label htmlFor={inputId}>Search</label>
          <input
            type="search"
            className={inputExtensionClass}
            name={"filters[searchText]"}
            autoComplete="off"
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            id={inputId}
            value={searchQuery}
            placeholder={this.props.placeholder}
            readOnly={this.state.isExecuting}
          />

          <button className="search-bar__form__submit" type="submit">
            Search
          </button>
          {this.renderPrompts()}
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchFlagTreatment: () => {
    dispatch(FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.AI_SEARCH_V_1_STOREFRONT_UX));
  },
  fetchTypeaheadFlag: () => {
    dispatch(
      FlagsAndExperimentsActions.fetchFlagOrExperiment(
        flagsAndExperimentNames.CATALOG_SERVIES_STOREFRONT_ENABLE_TYPEAHEAD
      )
    );
  },
});

const mapStateToProps = state => ({
  discoHost: state.publicEnv?.discoHost || "",
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFlagsAndExperiments(flagsAndExperimentNames.AI_SEARCH_V_1_STOREFRONT_UX),
  withFlagsAndExperiments(flagsAndExperimentNames.CATALOG_SERVIES_STOREFRONT_ENABLE_TYPEAHEAD)
)(SearchBar);
