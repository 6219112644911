import ActionTypes from "./action-types.js";
import { createAction } from "redux-actions";
import client from "clients/RawClient";

const actions = {
  oneTimeModals: createAction(ActionTypes.ONE_TIME_MODALS_FETCH),
  oneTimeModalsLoading: createAction(ActionTypes.ONE_TIME_MODALS_LOADING),
  oneTimeModalsFailed: createAction(ActionTypes.ONE_TIME_MODALS_FAILED),

  fetchOneTimeModals: async function () {
    return client.get("/oneTimeModals");
  },

  getOneTimeModals: function () {
    actions.oneTimeModalsLoading(true);
    return function (dispatch) {
      actions
        .fetchOneTimeModals()
        .then(data => {
          dispatch(actions.oneTimeModals(data));
          dispatch(actions.oneTimeModalsFailed(false));
        })
        .catch(() => {
          dispatch(actions.oneTimeModalsFailed(true));
        })
        .finally(() => {
          dispatch(actions.oneTimeModalsLoading(false));
        });
    };
  },

  isDismissedHelper: function (statusList, type) {
    if (!statusList) return false;
    const modalStatuses = statusList[0]?.modalStatuses || [];

    return !!modalStatuses.find(item => item.type === type)?.isDismissed;
  },

  lastSeenHelper: function (statusList, type) {
    if (!statusList) return false;
    const modalStatuses = statusList[0]?.modalStatuses || [];

    return modalStatuses.find(item => item.type === type)?.lastSeenUtc;
  },

  updateOneTimeModal: function (modalId, isDismissed) {
    return function (dispatch) {
      client
        .ajax({
          url: `/oneTimeModals`,
          type: "POST",
          data: { modalId, isDismissed },
        })
        .then(() => {
          dispatch(actions.getOneTimeModals());
        });
    };
  },
};

export default actions;

export const {
  oneTimeModals,
  oneTimeModalsLoading,
  oneTimeModalsFailed,
  getOneTimeModals,
  updateOneTimeModal,
  isDismissedHelper,
} = actions;
