import _ from "underscore";
import $ from "clients/RawClient";
import ActionTypes from "actions/action-types";
import ActionLogger from "action-logger";
import { createAction } from "redux-actions";
import { bagTabs, switchBagTab, toggleBag } from "actions/bag-actions";
import * as membershipStateActions from "actions/membership-state-actions";
import sharedActions from "actions/shared-actions";
import happinessSurveyActions from "actions/happiness-survey-actions";
import HomePickupActions from "actions/home-pickup-actions";
import swapCounterActions from "actions/swap-counter-actions";
import filterHelpers from "helpers/filter-helpers";
import MembershipHelpers from "helpers/membership-helpers";
import { skuInfo } from "helpers/sku-helpers";
import { featureFlags, membershipTriggeredModalsCMSKeys } from "rtr-constants";
import FirstShipmentReferralsModal from "components/source/membership/modals/PayPerSwapReferrals/FirstShipmentReferralsModal";
import { CrossShipReturnByDateForToday } from "@rtr/godmother";
import { updateOneTimeModal } from "actions/one-time-modals-actions";
import ReviewNudgeModal from "components/source/reviews/ReviewNudgeModal.jsx";
import CEBagActions from "actions/ce-bag-actions";
import HeapHelpers from "helpers/heap-helpers";
import { selectFeatureFlagEnabled } from "../selectors/featureFlagSelectors";

const UNABLE_TO_CHARGE_CARD = "Unable to charge card";
const UNKNOWN_ERROR = "Unknown error processing order";

const actions = {
  bagLoading: createAction(ActionTypes.MEMBERSHIP_BAG_LOADING),
  bagLoadSuccess: createAction(ActionTypes.MEMBERSHIP_BAG_LOAD_SUCCESS),
  removeFromCartSuccess: createAction(ActionTypes.MEMBERSHIP_REMOVE_FROM_CART_SUCCESS),
  removeFromCartFailure: createAction(ActionTypes.MEMBERSHIP_REMOVE_FROM_CART_FAILURE),
  addToCartLoading: createAction(ActionTypes.MEMBERSHIP_ADD_TO_CART_LOADING),
  addToCartSuccess: createAction(ActionTypes.MEMBERSHIP_ADD_TO_CART_SUCCESS),
  addToCartFailure: createAction(ActionTypes.MEMBERSHIP_ADD_TO_CART_FAILURE),
  ceBagLoading: createAction(ActionTypes.CE_BAG_LOADING),
  checkoutInProgress: createAction(ActionTypes.MEMBERSHIP_CHECKOUT_IN_PROGRESS),
  checkoutFailure: createAction(ActionTypes.MEMBERSHIP_CHECKOUT_FAILURE),
  clearBagErrors: createAction(ActionTypes.MEMBERSHIP_BAG_CLEAR_ERRORS),
  fetchCrossShipReturnByDateForTodaySuccess: createAction(
    ActionTypes.FETCH_CROSS_SHIP_RETURN_BY_DATE_FOR_TODAY_SUCCESS
  ),

  /**
   * @returns {function} - returns a higher-order-function that returns a jQuery deferred object to chain subsequent functions
   */
  fetchBag() {
    return function (dispatch, getState) {
      const membershipState = MembershipHelpers.getMembershipState(getState());
      const orderType = MembershipHelpers.getOrderTypeFromMembershipState(membershipState);

      // NW [EXPLANATION] 4/2/20: inactive memberships will have an undefined orderType
      if (!orderType) {
        return;
      }

      dispatch(actions.bagLoading(true));
      return $.get(`/membership/cart/full/${orderType}`)
        .then(
          function (data) {
            // MH Explanation 07-19-2019: We need this to quickly fix a bug where items
            // are being displayed twice when they should not.
            // This change should be removed as soon as the root cause is fixed
            const { items } = data;

            data.items = _.uniq(items, item => item.itemId);
            dispatch(actions.bagLoadSuccess(data));

            // clear the promo state if the bag is empty
            if (_.isEmpty(data.items)) {
              dispatch(actions.clearPromoErrors());
            }
          },
          () => {}
        )
        .finally(function () {
          dispatch(actions.bagLoading(false));
        });
    };
  },

  /**
   * Removes an item to the membership bag
   * @param {String} itemId - The item to be removed, the id is unique
   * @param {Object} additionalLogData - The additional data being sent to our internal event tracking pixels
   * @returns {function()} - returns a wrapper around `dispatch` and the fetch
   */
  remove(itemId) {
    return function (dispatch) {
      dispatch(actions.bagLoading(true));
      dispatch(actions.clearBagErrors());
      $.ajax({
        url: `/membership/cart/${itemId}`,
        type: "DELETE",
      }).then(
        function () {
          dispatch(actions.fetchBag());
          /* we do not dispatch actions.bagLoading(false) here - actions.fetchBag() will do that.
           * otherwise, the bag will flash with outdated items before being updated. */
        },
        function (xhr, _statusText, errorThrown) {
          let error = errorThrown;

          if (xhr.status === 400) {
            error = xhr.responseText;
          }

          dispatch(actions.removeFromCartFailure(error));
          dispatch(actions.bagLoading(false));
        }
      );
    };
  },

  /**
   * Adds an item to the membership bag
   * @param {String} sku - The item to be added, ex. MK12_5
   * @param {Boolean} openBag - Determines if we should open bag or not upon ajax success
   * @param {String} orderType - The orderType of the order being created or to be added to
   * @param {String} subType - The subType of the item being added
   * @param {Object} additionalLogData - The additional data being sent to our internal event tracking pixels
   * @param {function()} [onFailure=()=>{}] - function to be called after ajax fails
   * @returns {function()} - returns a wrapper around `dispatch` and the fetch
   */
  add(sku, openBag, orderType, subType, additionalLogData = {}, onFailure = () => {}) {
    return function (dispatch) {
      dispatch(actions.addToCartLoading(true));
      dispatch(actions.bagLoading(true));

      const body = {
        attributes: {
          sku: sku,
          orderType: orderType,
          subType: subType,
        },
      };

      const loggingData = {
        object_type: "membership",
        sub_type: "PICK",
        style_name: skuInfo(sku).id,
        ...additionalLogData,
      };

      return $.ajax({
        url: "/membership/cart",
        type: "POST",
        data: JSON.stringify(body),
        headers: {
          Accept: "application/json",
        },
      })
        .then(
          function () {
            if (openBag) {
              dispatch(membershipStateActions.fetchMembershipState());
              dispatch(toggleBag(true));
              dispatch(switchBagTab(bagTabs.MEMBERSHIP_TAB));
            }

            dispatch(actions.addToCartSuccess());

            const action = ActionLogger.RezoAddToCart;
            ActionLogger.logAction({ ...loggingData, action });
          },
          function (xhr, _statusText, errorThrown) {
            let error = errorThrown;
            if (xhr.status === 400) {
              error = xhr.responseText;
            }

            dispatch(actions.addToCartFailure(error));

            ActionLogger.logAction({
              ...loggingData,
              action: ActionLogger.RezoAddToCartFailure,
              error,
            });

            if (_.isFunction(onFailure)) {
              onFailure();
            }
          }
        )
        .finally(function () {
          dispatch(actions.bagLoading(false));
          dispatch(actions.addToCartLoading(false));
          dispatch(actions.fetchBag());
        });
    };
  },

  /**
   * Checkout the current membership order
   * @param {Number|Number[]} paymentProfileIds - The payments used to pay for the order (required even if there is no charge, we need to validate cards)
   * @param {Number} orderId - The order you want to checkoutgo
   * @param {Object} homePickupDetails - homePickupFlagEnabled flag, the items we are returning, the rentalBeginDate for the new items that we are swapping and the home pickup logger.
   * @param {function()} bagScrollToTopCallback - If the home pickup scheduler needs to be shown, bag container should scroll back to the top
   * @returns {function()} - returns a wrapper around `dispatch` and the fetch
   */
  checkout(paymentProfileIds, orderId, homePickupDetails, bagScrollToTopCallback) {
    return function (dispatch, getState) {
      dispatch(actions.bagLoading(true));
      dispatch(actions.checkoutInProgress(true));

      const state = getState();
      const { userData, workingFilters, membershipBag } = state;

      // Check here if the membershipState contains slots with experiment number tied to them
      const membershipState = MembershipHelpers.getMembershipState(state);

      let checkoutUrl;
      let checkoutData;

      return $.ajax({
        url: checkoutUrl,
        type: "POST",
        data: checkoutData,
      })
        .then(
          function () {
            const logData = {
              object_type: "shopping_bag",
              action: "confirm_shipment",
              order_id: orderId,
              membership: MembershipHelpers.getMembershipTypeAnalytics(userData.memberships, membershipState),
              latest_view: filterHelpers.pixelFilterParam(workingFilters, userData),
            };
            dispatch(actions.checkoutInProgress(false));

            const cachedMembershipState = false;
            dispatch(membershipStateActions.fetchMembershipState(cachedMembershipState));

            /* NOTE
             * The uses of `membershipState` below represent the membership state _prior_ to checkout.
             * Why is this the case? Two parts:
             * - The reducer for `membershipState` uses the lodash `extend` function which shallowly copies the
             *   previous membershipState to a new object so the reference we're working with _has not_ been changed
             * - Because of the above we need to call `getState` to get the updated `membershipState`
             * Thus, it is fine to think of membershipState below as the state prior to checkout.
             */

            const isFirstShipmentOfFirstTerm = MembershipHelpers.isFirstShipmentOfFirstTerm(membershipState);
            if (isFirstShipmentOfFirstTerm) {
              dispatch(swapCounterActions.swapCounterTooltipTriggered(true));
            }

            ActionLogger.logAction(logData);

            HeapHelpers.trackCheckoutAttempt(membershipBag.orderInvoice, membershipBag.promoCode);
            // if the homePickupFlag is enabled, and there's more than one item ready to be returned and there's a rental begin date,
            // go through the Home Pickup flow, else do the regular post checkout flow
            if (
              homePickupDetails?.homePickupFlagEnabled &&
              homePickupDetails?.returningItems?.length > 0 &&
              homePickupDetails?.rentalBeginDate
            ) {
              dispatch(
                HomePickupActions.checkHomePickup(
                  membershipState,
                  homePickupDetails.returningItems,
                  homePickupDetails.rentalBeginDate,
                  homePickupDetails.actionLogger
                )
              );
              if (typeof bagScrollToTopCallback === "function") {
                bagScrollToTopCallback();
              }
            } else {
              dispatch(actions.showPostCheckoutModals(membershipState));
            }

            dispatch(updateOneTimeModal(ReviewNudgeModal.oneTimeModalId, false));
            dispatch(CEBagActions.get());
          },
          function (xhr, _statusText, errorThrown) {
            let error = errorThrown;
            if (xhr.status === 400) {
              error = xhr.responseText;
            }

            if (xhr.status === 424) {
              error = UNABLE_TO_CHARGE_CARD;
            }

            if (xhr.status === 500) {
              error = UNKNOWN_ERROR;
            }

            dispatch(actions.checkoutFailure(error));
            dispatch(actions.bagLoading(false));
            dispatch(actions.checkoutInProgress(false));

            ActionLogger.logAction({
              object_type: "rtr_update",
              action: "confirm_failure",
              reason: error,
            });
          }
        )
        .finally(function () {
          dispatch(actions.fetchBag());
        });
    };
  },

  showPostCheckoutModals(membershipState) {
    const buildPostCheckoutModal = (membershipState, ppsReferralMessagingStrategyFF) => {
      const { firstOrderConfirmation, orderConfirmation } = membershipTriggeredModalsCMSKeys;
      const isFirstShipment = !MembershipHelpers.hasOrderHistory(membershipState);

      if (isFirstShipment && ppsReferralMessagingStrategyFF) {
        return FirstShipmentReferralsModal.MODAL_NAME;
      } else if (isFirstShipment) {
        return firstOrderConfirmation;
      } else {
        return orderConfirmation;
      }
    };

    return function (dispatch, getState) {
      const state = getState();
      const ppsReferralMessagingStrategyFF = selectFeatureFlagEnabled(featureFlags.PPS_REFERRAL_MESSAGING_STRATEGY)(
        state
      );
      dispatch(toggleBag(false));
      dispatch(HomePickupActions.displayHomePickup(false));

      const { displayModal } = sharedActions;
      const shouldFetchHappinessSurveyForSwapFlow =
        MembershipHelpers.inSwapFlow(membershipState) && MembershipHelpers.hasAtLeastOneReturnPromise(membershipState);
      if (shouldFetchHappinessSurveyForSwapFlow) {
        dispatch(happinessSurveyActions.fetchHappinessSurveys(() => {}, false, true));
      } else {
        const postCheckoutModal = buildPostCheckoutModal(membershipState, ppsReferralMessagingStrategyFF);
        dispatch(displayModal(postCheckoutModal));
      }
    };
  },

  clearPromoErrors() {
    return function (dispatch) {
      dispatch(
        sharedActions.receivePartialProps({
          promo: {},
        })
      );
    };
  },

  fetchCrossShipReturnByDateForToday() {
    return function (dispatch, getState) {
      if (getState().membershipBag?.crossShipReturnByDateForToday) {
        return;
      }
      CrossShipReturnByDateForToday.list().then(jsonApiData => {
        if (jsonApiData && jsonApiData[0]?.date && typeof jsonApiData[0].date === "string") {
          dispatch(actions.fetchCrossShipReturnByDateForTodaySuccess(jsonApiData[0].date));
        }
      });
    };
  },
};

export default actions;

export const {
  bagLoading,
  bagLoadSuccess,
  removeFromCartSuccess,
  removeFromCartFailure,
  addToCartLoading,
  addToCartSuccess,
  addToCartFailure,
  checkoutInProgress,
  checkoutFailure,
  clearBagErrors,
  fetchCrossShipReturnByDateForTodaySuccess,
  fetchBag,
  remove,
  add,
  checkout,
  clearPromoErrors,
  fetchCrossShipReturnByDateForToday,
  showPostCheckoutModals,
} = actions;
