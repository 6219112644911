import _ from "underscore";
import ActionTypes from "../actions/action-types.js";
var fitSummaryMethods, fn;

var getInitialState = function () {
  return {
    fitCounts: { "Large": 0, "True to Size": 0, "Small": 0 },
    reviewCount: 0,
    momentCount: 0,
    error: null,
  };
};

var setInitialState = function (state) {
  return _.extend({}, state, {
    fitSummary: getInitialState(),
  });
};

var clearFitSummary = function (state) {
  return _.extend({}, state, getInitialState());
};

var updateFitSummary = function (state, action) {
  return _.extend({}, state, action.payload);
};

var updateFitSummaryErrors = function (state, action) {
  return _.extend({}, state, getInitialState(), {
    error: action.payload.statusText,
  });
};

fitSummaryMethods = {};
fitSummaryMethods[ActionTypes.FETCH_FIT_SUMMARY] = clearFitSummary;
fitSummaryMethods[ActionTypes.FETCH_FIT_SUMMARY_SUCCESS] = updateFitSummary;
fitSummaryMethods[ActionTypes.FETCH_FIT_SUMMARY_ERROR] = updateFitSummaryErrors;

fn = function (state, action) {
  var newState = state;

  if (_.isUndefined(newState.fitSummary)) {
    newState = setInitialState(newState);
  }

  var fitSummaryMethod = fitSummaryMethods[action.type];

  var newFitSummary = !_.isUndefined(fitSummaryMethod)
    ? fitSummaryMethod(newState.fitSummary, action)
    : _.extend({}, newState.fitSummary);

  return _.extend({}, newState, { fitSummary: newFitSummary });
};

export default {
  types: Object.keys(fitSummaryMethods),
  fn: fn,
};
