// @see https://community.cookiepro.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca?topicId=0TO1Q000000ssbSWAQ

export const ONETRUST_CONSENT_MANAGEMENT = {
  BASE_CLASS: "optanon-category",
  COOKIE_CATEGORIES: {
    STRICTLY_NECESSARY: "C0001",
    PERFORMANCE: "C0002",
    FUNCTIONAL: "C0003",
    TARGETING: "C0004",
    SOCIAL_MEDIA: "C0005",
  },
};

// @returns {string} "text/plain" | "text/javascript"
export function scriptTagAttributeForCookieConsentManagement(flags) {
  if (flags && flags.includes("onetrust_consent_management")) {
    return "text/plain";
  } else {
    return "text/javascript";
  }
}

/**
 * @param {string[]} categories An array of cookie categories defined in ONETRUST_CONSENT_MANAGEMENT[:COOKIE_CATEGORIES]
 * @returns {string} Base class name with concatenated cookie categories
 */
export function classNameForCookieConsentManagement(categories) {
  if (!Array.isArray(categories) || categories.length <= 0) {
    return "";
  }

  return `${ONETRUST_CONSENT_MANAGEMENT["BASE_CLASS"]}-${categories.join("-")}`;
}
