import ActionTypes from "../actions/action-types.js";

const types = [
  ActionTypes.UPDATE_USER_PROFILE,
  ActionTypes.UPDATE_USER_PROFILE_DONE,
  ActionTypes.UPDATE_USER_PROFILE_FAIL,
  ActionTypes.SUBMIT_QUIZ_DATA_SUCCESS,
];

/**
 * Explicitly pass falsy fields for users who have opted out of maternity
 * Once falsy, these fields won't come back on the response to the user profile update.
 * This ensures we overwrite data in the store while on the same page.
 */
const withClearedMaternityData = (previousProfile, updatedProfile) => {
  const updatedFields = {};

  //I don't see how inMaternity would be falsy but dueDate would not, but to be safe we'll handle separately.
  if (previousProfile.inMaternity && !updatedProfile.inMaternity) {
    updatedFields.inMaternity = false;
  }

  if (previousProfile.dueDate && !updatedProfile.dueDate) {
    updatedFields.dueDate = "";
  }

  return updatedFields;
};

function fn(state, action) {
  const userData = { ...state.userData };
  const userProfile = { ...userData.userProfile };
  let profiles = { ...userProfile.profiles };

  switch (action.type) {
    case ActionTypes.UPDATE_USER_PROFILE:
      profiles.isLoading = true;
      break;

    case ActionTypes.UPDATE_USER_PROFILE_DONE:
      profiles.isLoading = false;
      profiles.isSuccessfulUpdate = true;

      profiles = { ...profiles, ...action.payload, ...withClearedMaternityData(profiles, action.payload) };
      break;

    case ActionTypes.UPDATE_USER_PROFILE_FAIL:
      profiles.isLoading = false;
      profiles.isSuccessfulUpdate = false;
      profiles.error = "Unable to update user profile at this time. Please try again.";
      break;

    case ActionTypes.SUBMIT_QUIZ_DATA_SUCCESS:
      break;
  }

  userProfile.profiles = profiles;
  userData.userProfile = userProfile;

  const stateAdditions = { userData };

  return { ...state, ...stateAdditions };
}

export default {
  types,
  fn,
};
