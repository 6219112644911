import { createAction } from "redux-actions";
import ActionTypes from "actions/action-types";
import { LocalStorage } from "../site/localStorage";

const IS_TOOLTIP_DISMISSED = "isTooltipDismissed";
const ADD_A_SHIPMENT_LOCAL_STORAGE_KEY = "swapCounterImmediateUpgradeTooltip";

const actions = {
  // NW [EXPLANATION] 7/29/20: action `swapCounterTooltipDismissed` is only exported for testing purposes - only action `dismissSwapCounterTooltip`, which updates local storage and then dispatches this action, should be dispatched by consumers
  swapCounterTooltipDismissed: createAction(ActionTypes.SWAP_COUNTER_TOOLTIP_DISMISSED),
  swapCounterTooltipTriggered: createAction(ActionTypes.SWAP_COUNTER_TOOLTIP_TRIGGERED),

  /**
   * @deprecated do not reuse this pattern for other one-time experiences - use OneTimeExperienceActions.getIsOneTimeExperienceDismissed
   */
  initAddAShipmentTooltip: () => {
    // GN [EXPLANATION] 12/7/20: If the Add a Shipment tooltip has displayed in the past we set swapCounterTooltipTriggered to false in Redux
    return function (dispatch) {
      const localStorageClient = new LocalStorage(ADD_A_SHIPMENT_LOCAL_STORAGE_KEY);
      const immediateUpgradeTooltipHasAppeared = !!localStorageClient.get(IS_TOOLTIP_DISMISSED);

      dispatch(actions.swapCounterTooltipDismissed(immediateUpgradeTooltipHasAppeared));
    };
  },

  /**
   * @deprecated do not reuse this pattern for other one-time experiences - use OneTimeExperienceActions.dismissOneTimeExperience
   */
  dismissSwapCounterTooltip: () => {
    // set the tooltip as dismissed in local storage and then in Redux
    return function (dispatch) {
      const localStorageClient = new LocalStorage(ADD_A_SHIPMENT_LOCAL_STORAGE_KEY);
      localStorageClient.set(IS_TOOLTIP_DISMISSED, true);
      dispatch(actions.swapCounterTooltipDismissed(true));
      dispatch(actions.swapCounterTooltipTriggered(false));
    };
  },
};

export default actions;

export const {
  swapCounterTooltipDismissed,
  swapCounterTooltipTriggered,
  initAddAShipmentTooltip,
  dismissSwapCounterTooltip,
} = actions;
