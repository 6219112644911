// gets the featured flags from a state or props object
// returns a default object/values for any part of the state/props object that is undefined

export default function getFlagsFromObj(initialState) {
  const enabledFlags = {
    flags: [],
    setByServer: [],
    setByClient: [],
  };

  const { enabledFlags: initialEnabledFlags } = initialState || {};

  if (Array.isArray(initialEnabledFlags?.flags)) {
    enabledFlags.flags = initialEnabledFlags.flags;
  }

  if (Array.isArray(initialEnabledFlags?.setByServer)) {
    enabledFlags.setByServer = initialEnabledFlags.setByServer;
  }

  if (Array.isArray(initialEnabledFlags?.setByClient)) {
    enabledFlags.setByClient = initialEnabledFlags.setByClient;
  }

  return { enabledFlags };
}
