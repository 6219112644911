import $ from "clients/RawClient";
import ActionTypes from "./action-types.js";
import { createAction } from "redux-actions";

const actions = {
  fetchFavoritesSuccess: createAction(ActionTypes.FETCH_FAVORITES_SUCCESS),
  fetchFavoritesError: createAction(ActionTypes.FETCH_FAVORITES_ERROR),
  favoriteProductError: createAction(ActionTypes.FAVORITE_PRODUCT_ERROR),
  removeProductRatingError: createAction(ActionTypes.REMOVE_PRODUCT_RATING_ERROR),

  favoriteProduct(styleId) {
    return dispatch => {
      $.ajax({
        type: "POST",
        url: `/favorite/${styleId}`,
      }).then(
        function () {
          dispatch(actions.loadUserProductRatings());
        },
        () => {}
      );
    };
  },

  removeProductRating(styleName) {
    return function (dispatch) {
      $.ajax({
        type: "DELETE",
        url: `/rating/${styleName}`,
      }).then(
        function () {
          dispatch(actions.loadUserProductRatings());
        },
        function () {}
      );
    };
  },

  // MH Explanation 2020-01-09: Under the hood, hearts are treated as
  // "user product ratings", so we can use one GET method to fetch hearts after the request finishes
  loadUserProductRatings() {
    return function (dispatch) {
      $.ajax({
        url: "/all_ratings",
        type: "GET",
        headers: {
          Accept: "application/json",
        },
        cache: false,
      }).then(
        function (data) {
          const heartedItems = data.filter(item => item.action === "HEARTED").map(item => item.product.id);

          dispatch(actions.fetchFavoritesSuccess(heartedItems));
        },
        function () {}
      );
    };
  },
};

export default actions;

export const {
  fetchFavoritesSuccess,
  fetchFavoritesError,
  favoriteProductError,
  removeProductRatingError,
  favoriteProduct,
  removeProductRating,
  loadUserProductRatings,
} = actions;
