import $ from "clients/RawClient";
import ActionTypes from "actions/action-types";
import ActionLogger from "action-logger";
import { HomePickupPostalCode, HomePickupPostalCodeGroup } from "@rtr/godmother";
import { createAction } from "redux-actions";
import { dispatchAction } from "actions/action-utils";
import { displayModal } from "actions/shared-actions";
import happinessSurveyActions from "actions/happiness-survey-actions";
import * as bagActions from "actions/bag-actions";
import * as membershipStateActions from "actions/membership-state-actions";
import * as NotificationCenterActions from "actions/subscription-notification-center-actions";
import MembershipBagActions from "actions/membership-bag-actions";
import {
  analytics,
  checkout,
  clientSideErrorMessages,
  dateFnsFormats,
  formElementNames,
  HOME_PICKUP_METHOD_KEY,
  HOME_PICKUP_SPECIAL_INSTRUCTIONS_KEY,
  HOME_PICKUP_UNAVAILABLE_MODAL_NAME,
  HomePickupOrderType,
  HomePickupPostalCodeGroupType,
  HPUConfirmationModalName,
  HPUConfirmationModalTypes,
  HPUEditAddressConfirmHandlerTypes,
} from "rtr-constants";
import { format } from "date-fns";
import { LocalStorage } from "site/localStorage";
import { formatPhoneNumber } from "helpers/format-phone-number-helper";
import { parseSuggestedAddress } from "helpers/address-helpers";
import { ShippingAddressesValidate } from "routes";

export const HOME_PICKUP_STORAGE_NAME_SPACE = "HOME_PICKUP_STORAGE_NAME_SPACE";

// Going to create a specific errors here to help differentiate behavior by class name, and use message as a display value
class HomePickupPostalCodeError extends Error {
  constructor(message) {
    super(message);
    this.name = "HomePickupPostalCodeError";
  }
}

const actions = {
  checkHomePickupEligibilityError: createAction(ActionTypes.CHECK_HOME_PICKUP_ELIGIBILITY_ERROR),
  checkHomePickupEligibilitySuccess: createAction(ActionTypes.CHECK_HOME_PICKUP_ELIGIBILITY_SUCCESS),
  displayHomePickup: createAction(ActionTypes.DISPLAY_HOME_PICKUP),
  displayHomePickupOverlay: createAction(ActionTypes.DISPLAY_HOME_PICKUP_OVERLAY),
  displayHomePickupServerError: createAction(ActionTypes.DISPLAY_HOME_PICKUP_SERVER_ERROR),
  displayHomePickupAlreadyScheduled: createAction(ActionTypes.DISPLAY_HOME_PICKUP_ALREADY_SCHEDULED),
  displayHomePickupNoActiveOrders: createAction(ActionTypes.DISPLAY_HOME_PICKUP_NO_ACTIVE_ORDERS),
  scheduleHomePickupLoading: createAction(ActionTypes.SCHEDULE_HOME_PICKUP_LOADING),
  scheduleHomePickupSuccess: createAction(ActionTypes.SCHEDULE_HOME_PICKUP_SUCCESS),
  scheduleHomePickupError: createAction(ActionTypes.SCHEDULE_HOME_PICKUP_ERROR),
  setHomePickupPostalCodes: createAction(ActionTypes.SET_HOME_PICKUP_POSTAL_CODES),
  setHomePickupPostalCodeGroups: createAction(ActionTypes.SET_HOME_PICKUP_POSTAL_CODE_GROUPS),
  setHomePickupAddress: createAction(ActionTypes.SET_HOME_PICKUP_ADDRESS),
  setHomePickupEligibleOrders: createAction(ActionTypes.SET_HOME_PICKUP_ELIGIBLE_ORDERS),
  setHomePickupEligibility: createAction(ActionTypes.SET_HOME_PICKUP_ELIGIBILITY),
  setIsHomePickupSchedulerLoading: createAction(ActionTypes.SET_IS_HOME_PICKUP_SCHEDULER_LOADING),
  setUpcomingHomePickupDate: createAction(ActionTypes.SET_UPCOMING_HOME_PICKUP_DATE),

  /**
   * Check if this user and postal code are eligible for home pickup for membership orders. If they
   * are: store their home pickup date and time and display the home pickup
   * screen after checkout.
   * @param {Object[]} membershipState - the membershipState to read what zipcode we are checking for eligiblity **we should change this to zipcode and have the object extraction within the function call**
   * @param {Object[]} returningItems - the items we are returning
   * @param {Object[]} rentalBeginDate - the rentalBeginDate
   * @param {Object[]} actionLogger - the actionLogger callback we call when the check is succesful
   * @returns  wrapper around dispatch and the fetch statement
   */
  checkHomePickup(membershipState, returningItems, rentalBeginDate, actionLogger) {
    return function (dispatch) {
      dispatch(actions.setIsHomePickupSchedulerLoading(true));

      $.get("/homePickupEligibility", {
        postalCode: membershipState?.address?.postalCode,
        rentalBeginDate: rentalBeginDate,
      }).then(
        eligibilityData => {
          if (eligibilityData[0]?.isEligible) {
            dispatch(
              actions.checkHomePickupEligibilitySuccess({
                bookingIds: returningItems.map(item => item.bookingId),
                eligibilityData: eligibilityData[0],
                rentBeginDate: rentalBeginDate,
              })
            );
            actionLogger();
          } else {
            dispatch(MembershipBagActions.showPostCheckoutModals(membershipState));
          }
        },
        () => {
          // treat user as ineligible for pickup if error is thrown
          dispatch(MembershipBagActions.showPostCheckoutModals(membershipState));
        }
      );
    };
  },

  /**
   * Check for user orders that are eligble for home pickup. When we fetch the orders successfully, we set the first order's address as the home pickup address with our zipcode validation
   * @param {string[]} orderTypes - ENUMS filters for homepickup eligbile orders when calling /homePickupGroups**
   * @returns home pickup eligible orders
   */
  checkHomePickupEligibleOrders(
    orderTypes = [HomePickupOrderType.STANDALONE_MEMBERSHIP],
    useHomePickupPostalCodeGroups = false
  ) {
    return function (dispatch, getState) {
      dispatch(actions.setIsHomePickupSchedulerLoading(true));

      return $.get("/homePickupGroups", {
        homePickupOrderType: orderTypes.join(), // filtering with a list
      })
        .then(eligibilityData => {
          let ordersGroups = eligibilityData?.length
            ? eligibilityData.map(order => {
                // reformatting address to fit address form key names
                const newOrder = {
                  ...order,
                  address: {
                    ...order.address,
                    [formElementNames.street]: order.address.addressLine1,
                    [formElementNames.apt]: order.address.addressLine2,
                    [formElementNames.state]: order.address.state,
                  },
                };
                delete newOrder.address.addressLine1;
                delete newOrder.address.addressLine2;
                delete newOrder.address.state;

                return newOrder;
              })
            : [];

          const { membershipState } = getState();
          const membershipPostalCode = !membershipState?.address?.postalCode
            ? null
            : membershipState?.address?.postalCode;

          const logData = {
            object_type: analytics.OBJECT_TYPE.HOME_PICKUP_STANDALONE_SCHEDULER,
            action: analytics.ACTION_TYPE.NO_ACTIVE_HOME_PICKUP_ORDERS,
            membership_zip_code: membershipPostalCode,
          };

          // TODO: setup a different action for displaying homePickupAlreadyAction screen to use
          // HPU reducer in DR-760
          if (ordersGroups.length) {
            // filter out HOME_PICKUP_SCHEDULED orders
            const filteredOrdersGroups = ordersGroups.filter(group => !group.pickupDate);

            // user only has HOME_PICKUP_SCHEDULED orders
            if (!filteredOrdersGroups.length) {
              ActionLogger.logAction({ ...logData, action: analytics.ACTION_TYPE.HOME_PICKUP_ALREADY_SCHEDULED });
              dispatch(actions.setUpcomingHomePickupDate(ordersGroups[0].pickupDate));
              dispatch(actions.displayHomePickupAlreadyScheduled(true));
              return;
            }

            ordersGroups = filteredOrdersGroups;
          } else {
            ActionLogger.logAction({ ...logData, action: analytics.ACTION_TYPE.NO_ACTIVE_HOME_PICKUP_ORDERS });
            dispatch(actions.displayHomePickupNoActiveOrders(true));
            return;
          }

          dispatch(actions.setHomePickupEligibleOrders(ordersGroups));

          const { homePickupPostalCodes, homePickupPostalCodeGroups } = getState();

          // For now, we are going to do a call here to determine the first order address's hpu validity
          // but the goal is to create a hpu reducer that handles this data, and checks it with the redux state's
          // eligibleHomePickupPostalCodes to handle the homePickupAddress and homePickupEligibleOrders
          if (!ordersGroups[0]?.zipCode) {
            throw new HomePickupPostalCodeError("no zip code associated with first order");
          }

          //  if USE_HOME_PICKUP_POSTAL_CODE_GROUPS flag is true,
          //  get postal code groups and then pass next action as part of onSuccess method
          if (useHomePickupPostalCodeGroups) {
            //  make sure we have the right type of postal code group in redux
            //  if we have any membership orders, check that postal code against
            //  the list of SUBSCRIPTION eligible postal codes
            //  otherwise, use the RESERVE list
            const subscriptionOrder = ordersGroups.find(
              group => group.pickupOrderType === HomePickupOrderType.STANDALONE_MEMBERSHIP
            );

            const postalCodeGroupType = subscriptionOrder
              ? HomePickupPostalCodeGroupType.SUBSCRIPTION
              : HomePickupPostalCodeGroupType.RESERVE;

            const relevantOrder = subscriptionOrder ? subscriptionOrder : ordersGroups[0];

            if (!homePickupPostalCodeGroups?.[postalCodeGroupType]) {
              dispatch(
                actions.getHomePickupPostalCodeGroups(
                  postalCodeGroupType,
                  () => {
                    dispatch(actions.checkHomePickupOrderAddress(postalCodeGroupType, relevantOrder));
                    dispatch(actions.setIsHomePickupSchedulerLoading(false));
                  },
                  () => {
                    dispatch(actions.onHomePickupErrors());
                  }
                )
              );
            } else {
              dispatch(actions.checkHomePickupOrderAddress(postalCodeGroupType, relevantOrder));
              dispatch(actions.setIsHomePickupSchedulerLoading(false));
            }
            return null;
          }

          //  if USE_HOME_PICKUP_POSTAL_CODE_GROUPS flag is false,
          //  get postalCodes (not grouped), and continue in chain
          if (!homePickupPostalCodes) {
            return HomePickupPostalCode.list().then(homePickupPostalCodes => {
              if (!homePickupPostalCodes?.length) {
                throw new HomePickupPostalCodeError("No eligible postal codes returned");
              }
              const formattedZips = homePickupPostalCodes.map(model => model.id);
              dispatch(actions.setHomePickupPostalCodes(formattedZips));
              return {
                homePickupPostalCodes: formattedZips,
                ordersGroups,
              };
            });
          }

          return {
            homePickupPostalCodes,
            ordersGroups,
          };
        })
        .then(res => {
          //  this 'then' block can be removed when USE_HOME_PICKUP_POSTAL_CODE_GROUPS
          //  flag is true and removed
          //  logic is duplicated in checkHomePickupOrderAddress action
          if (useHomePickupPostalCodeGroups) {
            return;
          }
          if (!res) {
            return;
          }
          const { homePickupPostalCodes, ordersGroups } = res;
          if (!homePickupPostalCodes?.includes(ordersGroups[0].zipCode)) {
            // if the first order's address postalCode isn't in our list of eligble zipcodes, make the user change it in the unavaible/edit address modal
            const errorAddress = {
              ...ordersGroups[0].address,
              editType: HPUEditAddressConfirmHandlerTypes.EDIT_ADDRESS_FROM_STANDALONE_ZIP_ERROR, // this editType will change according to what we want to do after the address has been edited to an eligble hpu address
            };
            const logData = {
              object_type: analytics.OBJECT_TYPE.HOME_PICKUP_STANDALONE_SCHEDULER,
              action: analytics.ACTION_TYPE.HOME_PICKUP_NOT_AVAILABLE_MARKET,
              zip_code: ordersGroups[0].zipCode,
            };
            ActionLogger.logAction(logData);
            dispatch(actions.displayHomePickupUnavailableModal(errorAddress));
          } else {
            // set the address as the home pickup address
            dispatch(actions.setHomePickupAddress(ordersGroups[0].address));
          }
        })
        .catch(() => {
          dispatch(actions.displayHomePickupServerError(true));
          dispatch(actions.setHomePickupEligibleOrders([]));
          dispatch(actions.setIsHomePickupSchedulerLoading(false));
        })
        .finally(() => {
          if (!useHomePickupPostalCodeGroups) {
            dispatch(actions.setIsHomePickupSchedulerLoading(false));
          }
        });
    };
  },

  //  this action gets dispatched only if the
  //  USE_HOME_PICKUP_POSTAL_CODE_GROUPS flag is true
  checkHomePickupOrderAddress(postalCodeGroupType, relevantOrder) {
    return function (dispatch, getState) {
      const { homePickupPostalCodeGroups } = getState();
      if (homePickupPostalCodeGroups[postalCodeGroupType].includes(relevantOrder.zipCode)) {
        // set the address as the home pickup address
        dispatch(actions.setHomePickupAddress(relevantOrder.address));
      } else {
        // if the relevant order's zipcode isn't in our list of eligble zipcodes, make the user change it in the unavaible/edit address modal
        const errorAddress = {
          ...relevantOrder.address,
          editType: HPUEditAddressConfirmHandlerTypes.EDIT_ADDRESS_FROM_STANDALONE_ZIP_ERROR, // this editType will change according to what we want to do after the address has been edited to an eligble hpu address
        };
        const logData = {
          object_type: analytics.OBJECT_TYPE.HOME_PICKUP_STANDALONE_SCHEDULER,
          action: analytics.ACTION_TYPE.HOME_PICKUP_NOT_AVAILABLE_MARKET,
          zip_code: relevantOrder.zipCode,
        };
        ActionLogger.logAction(logData);
        dispatch(actions.displayHomePickupUnavailableModal(errorAddress));
      }
    };
  },

  onHomePickupErrors() {
    return function (dispatch) {
      dispatch(actions.displayHomePickupServerError(true));
      dispatch(actions.setHomePickupEligibleOrders([]));
      dispatch(actions.setIsHomePickupSchedulerLoading(false));
    };
  },

  /**
   * Check if this user and postal code are eligible for home pickup. If they
   * are: store their home pickup date and time and display the home pickup
   * screen after checkout
   * @param {Object[]} postalCode - the postal code we are checking eligibility for
   * @param {Object[]} bookingIds - the booking ids of the orders we are returning
   * @param {Object[]} rentalBeginDate - the rentalBeginDate
   * @param {Object[]} onSuccessCallBack - the function we call once request has been fulfilled
   * @param {Object[]} onErrorCallBack - the function we call once request has been rejected
   * @returns  wrapper around dispatch and the fetch statement
   */
  checkStandaloneHomePickupEligibility(postalCode, bookingIds, rentalBeginDate, onSuccessCallBack, onErrorCallBack) {
    return function (dispatch) {
      dispatch(actions.setIsHomePickupSchedulerLoading(true));

      return $.get("/homePickupEligibility", {
        postalCode: postalCode,
        rentalBeginDate: rentalBeginDate,
      })
        .then(eligibilityData => {
          if (eligibilityData[0]?.isEligible) {
            dispatch(
              actions.checkHomePickupEligibilitySuccess({
                bookingIds: bookingIds,
                eligibilityData: eligibilityData[0],
                rentBeginDate: rentalBeginDate,
              })
            );
            if (typeof onSuccessCallBack === "function") {
              onSuccessCallBack(eligibilityData[0]);
            }
          } else {
            throw new Error("Postal code or rental begin date are not eligible");
          }
        })
        .catch(error => {
          if (typeof onErrorCallBack === "function") {
            onErrorCallBack(error);
          }
          dispatch(actions.checkHomePickupEligibilityError(error));
        });
    };
  },

  closeHomePickupConfirmationModal() {
    return function (dispatch) {
      dispatch(displayModal(null));
      dispatchAction(dispatch, ActionTypes.DISPLAY_HOME_PICKUP_CONFIRMATION_MODAL, null);
    };
  },

  closeHomePickupStandaloneScheduler() {
    return function (dispatch) {
      dispatch(actions.displayHomePickup(false));
      dispatch(actions.displayHomePickupOverlay(false));
      dispatch(actions.displayHomePickupServerError(false));
      dispatch(actions.displayHomePickupAlreadyScheduled(false));
      dispatch(actions.displayHomePickupNoActiveOrders(false));
    };
  },

  confirmHomePickupAddress: function (
    payload,
    validationSuccessCallback = function () {}, // required
    validationFailureCallback = function () {}, //required
    confirmAddressCallback
  ) {
    return function () {
      payload.vals.phone = formatPhoneNumber(payload.vals.phone);
      $.ajax({
        url: ShippingAddressesValidate,
        type: "POST",
        data: {
          address: payload.vals,
        },
      }).then(
        response => {
          if (response?.valid) {
            validationSuccessCallback(payload.vals);
          } else if (response?.address_response[0]) {
            confirmAddressCallback(parseSuggestedAddress(response.address_response[0]));
          } else {
            validationFailureCallback({
              errorCopy: "Something went wrong. Please try again.",
              errorCTA: "",
              errorURL: "",
            });
          }
        },
        err => {
          let errorObject;
          /*
           * There are two different cases handled in the fail block:
           * 1. If we get a "NON_SHIPPABLE_ADDRESS" error from Ruby, we want to
           * keep the shipping step open and display the error on the form
           * 2. If the error is anything else (e.g., Address not recognized by UPS),
           * we want to pop the modal and prompt the user to confirm.
           */
          if (err.responseText === checkout.errorTypes.NON_SHIPPABLE_ADDRESS) {
            errorObject = {
              errorCopy: clientSideErrorMessages.addressSuggestions.nonShippable,
              errorCTA: "",
              errorURL: "",
            };
          } else {
            errorObject = {
              errorCopy: clientSideErrorMessages.addressSuggestions.notRecognized,
              errorCTA: "",
              errorURL: "",
            };
          }

          validationFailureCallback(errorObject);
        }
      );
    };
  },

  displayHomePickupCrossShipConfirmationModal() {
    return function (dispatch) {
      dispatch(displayModal(HPUConfirmationModalName));
      dispatchAction(
        dispatch,
        ActionTypes.DISPLAY_HOME_PICKUP_CONFIRMATION_MODAL,
        HPUConfirmationModalTypes.CROSS_SHIP
      );
    };
  },

  displayHomePickupStandaloneConfirmationModal(pickupOrderType) {
    return function (dispatch) {
      if (pickupOrderType === HomePickupOrderType.STANDALONE_MEMBERSHIP) {
        //rerender membershipState to update item statuses, and clear homePickupEligible orders in redux to force a recall of /homePickupOrderGroups
        dispatch(membershipStateActions.fetchMembershipState());
      }
      dispatch(displayModal(HPUConfirmationModalName));
      dispatchAction(
        dispatch,
        ActionTypes.DISPLAY_HOME_PICKUP_CONFIRMATION_MODAL,
        HPUConfirmationModalTypes.STANDALONE
      );
    };
  },

  displayHomePickupUnavailableModal(address) {
    return function (dispatch) {
      dispatch(displayModal(HOME_PICKUP_UNAVAILABLE_MODAL_NAME));
      dispatch(actions.closeHomePickupStandaloneScheduler());
      if (address) {
        dispatch(actions.setHomePickupAddress(address));
      }
    };
  },

  //  This action should be removed when the USE_HOME_PICKUP_POSTAL_CODE_GROUPS
  //  flag is true and then removed
  getHomePickupPostalCodes(onSuccessCallBack, onErrorCallback) {
    return function (dispatch) {
      HomePickupPostalCode.list()
        .then(models => {
          const homePickupPostalCodes = models.map(model => model.id);
          if (typeof onSuccessCallBack === "function") {
            onSuccessCallBack();
          }
          dispatch(actions.setHomePickupPostalCodes(homePickupPostalCodes));
        })
        .catch(() => {
          if (typeof onErrorCallback === "function") {
            onErrorCallback();
          }
        });
    };
  },

  getHomePickupPostalCodeGroups(groups, onSuccessCallback = null, onErrorCallback = null) {
    return function (dispatch) {
      HomePickupPostalCodeGroup.list({
        filter: {
          homePickupPostalCodeGroup: groups,
        },
      })
        .then(data => {
          if (Array.isArray(data) && data.length > 0) {
            dispatch(actions.setHomePickupPostalCodeGroups(data));
          }

          if (typeof onSuccessCallback === "function") {
            onSuccessCallback();
          }
        })
        .catch(() => {
          if (typeof onErrorCallback === "function") {
            onErrorCallback();
          }
        });
    };
  },

  /**
   * Schedule home pickup details to odmother
   * @param {String[]} bookingIds - the booking ids of the orders
   * @param {String} email - user email
   * @param {func} errorCallback - any in-component-state error handling to be done
   * @param {Object} homePickupEligibility - our preferredPickup date, window start, window end and carrier name info
   * @param {String} orderId - order id (absolete now)
   * @param {String} phoneNumber - user phone number
   * @param {String} pickupAddress - address of where our carriers will pick up the items
   * @param {String} pickupMethod - pickup method
   * @param {String} pickupOrderType - differntiates what we will do after we have a successful hpu order scheduled (reserve vs crossship vs standalone)
   * @param {String} pickupType - differntiates between liveswap vs hpu only orders
   * @param {String} specialInstructions - special instructions for carrier
   * @returns  wrapper around dispatch and the fetch statement
   */
  scheduleHomePickup(
    bookingIds,
    email,
    errorCallback,
    homePickupEligibility,
    orderId,
    orderGroupId,
    phoneNumber,
    pickupAddress,
    pickupMethod,
    pickupOrderType,
    pickupType,
    specialInstructions,
    successCallBack
  ) {
    const { preferredPickupDate, pickupWindowStart, pickupWindowEnd, carrierName } = homePickupEligibility;

    const localStorageClient = new LocalStorage(HOME_PICKUP_STORAGE_NAME_SPACE);
    localStorageClient.set(HOME_PICKUP_METHOD_KEY, pickupMethod);
    localStorageClient.set(HOME_PICKUP_SPECIAL_INSTRUCTIONS_KEY, specialInstructions);

    return function (dispatch) {
      dispatch(actions.setIsHomePickupSchedulerLoading(true));

      const homePickupDetails = {
        itemBookingIds: bookingIds,
        orderDate: format(new Date(), dateFnsFormats.YYYY_MM_DD),
        orderId: orderId, // we should probably remove this or make null for when we do multiple orders, standalone orders or reserve orders
        orderGroupId: orderGroupId, // this should be populated only for reserve orders
        pickupAddress: {
          firstName: pickupAddress.firstName,
          lastName: pickupAddress.lastName,
          addressLine1: pickupAddress.street1,
          addressLine2: pickupAddress.street2,
          city: pickupAddress.city,
          state: pickupAddress.zoneCode,
          postalCode: pickupAddress.postalCode,
          phone: phoneNumber,
          email: email,
          country: "USA", // don't offer service outside the us
        },
        pickupCarrierName: carrierName,
        pickupDate: preferredPickupDate,
        pickupMethod,
        pickupType,
        pickupOrderType,
        pickupWindow: {
          pickupWindowStart: pickupWindowStart,
          pickupWindowEnd: pickupWindowEnd,
        },
        specialInstructions,
      };
      $.ajax({
        type: "POST",
        url: "/homePickup",
        data: homePickupDetails,
      }).then(
        function () {
          dispatch(actions.scheduleHomePickupSuccess(homePickupDetails));
          if (typeof successCallBack === "function") {
            successCallBack(); // this will close the standalone scheduler and reset the local state within standalone scheduler
          }
          // will show different confirmation modals and dispatch different actions depending on the pickupOrderType
          dispatch(actions.showPostHomePickupModals(pickupOrderType));

          dispatch(NotificationCenterActions.fetchNotification());
        },
        e => {
          if (typeof errorCallback === "function") {
            errorCallback();
          }
          dispatch(actions.scheduleHomePickupError(e));
        }
      );
    };
  },

  showPostHomePickupModals(pickupOrderType) {
    // should ONLY be called after successfully calling home pickup to show happiness surveys
    return function (dispatch) {
      if (pickupOrderType === HomePickupOrderType.CROSS_SHIP) {
        // call happiness survey, close bag and get the updated membership state
        dispatch(bagActions.toggleBag(false));
        dispatch(happinessSurveyActions.fetchHappinessSurveys(() => {}, false, true));
        dispatch(membershipStateActions.fetchMembershipState());
      } else {
        //show standalone confirmation modal
        dispatch(actions.displayHomePickupStandaloneConfirmationModal(pickupOrderType));
      }
    };
  },

  /**
   * update home pickup date details
   * @param {Object} selectedDate - the new date we are going to change
   * @returns {function()} - returns a wrapper around `dispatch` and reduxState
   */
  updateHomePickupDateDetails(selectedDate) {
    return function (dispatch) {
      dispatch(
        actions.setHomePickupEligibility({
          preferredPickupDate: selectedDate.pickupDate,
          pickupWindowStart: selectedDate.pickupWindowStart,
          pickupWindowEnd: selectedDate.pickupWindowEnd,
        })
      );
    };
  },
};

export default actions;
