import { isProduction } from "../../../helpers/environment-helpers";
import {
  classNameForCookieConsentManagement,
  ONETRUST_CONSENT_MANAGEMENT,
  scriptTagAttributeForCookieConsentManagement,
} from "../../../helpers/onetrust-helper";
import { useFeatureFlags } from "../FeatureFlagsContext";
import { ThirdPartyScript } from "./thirdPartyScript";

const GTM = () => {
  const flags = useFeatureFlags();
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            function loadGoogleTagManager() {
              window.dispatchEvent(new CustomEvent("googleTagManagerLoadEvent"));
            }
          `,
        }}></script>

      {isProduction() ? (
        <>
          <noscript>
            {/*How do we want to handle these if JS is not enabled?*/}
            <iframe
              title="gtm"
              data-src="//www.googletagmanager.com/ns.html?id=GTM-TZS348"
              className={classNameForCookieConsentManagement([
                ONETRUST_CONSENT_MANAGEMENT["COOKIE_CATEGORIES"]["TARGETING"],
              ])}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}></iframe>
          </noscript>
          <ThirdPartyScript
            id="google-tag-manager-script"
            strategy="afterInteractive"
            className={classNameForCookieConsentManagement([
              ONETRUST_CONSENT_MANAGEMENT["COOKIE_CATEGORIES"]["TARGETING"],
            ])}
            type={scriptTagAttributeForCookieConsentManagement(flags)}
            dangerouslySetInnerHTML={{
              __html: `
                try {
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.onload=loadGoogleTagManager;
                    j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-TZS348');
                } catch (e) {
                  if (window.Sentry && window.Sentry.captureException) { window.Sentry.captureException(e, {
                    tags: {
                      thirdparty: true,
                      thirdparty_name: "google-tag-manager"
                    }
                  }); }
                }
              `,
            }}
          />
        </>
      ) : (
        <>
          <noscript>
            {/*How do we want to handle these if JS is not enabled?*/}
            <iframe
              title="gtm"
              className={classNameForCookieConsentManagement([
                ONETRUST_CONSENT_MANAGEMENT["COOKIE_CATEGORIES"]["TARGETING"],
              ])}
              data-src="//www.googletagmanager.com/ns.html?id=GTM-TZS348&gtm_auth=mdy2i134XKGQC2JfwfyuxA&gtm_preview=env-175&gtm_cookies_win=x"
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}></iframe>
          </noscript>
          <ThirdPartyScript
            id="google-tag-manager-script"
            strategy="afterInteractive"
            className={classNameForCookieConsentManagement([
              ONETRUST_CONSENT_MANAGEMENT["COOKIE_CATEGORIES"]["TARGETING"],
            ])}
            type={scriptTagAttributeForCookieConsentManagement(flags)}
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.onload=loadGoogleTagManager;
                  j.src='//www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=mdy2i134XKGQC2JfwfyuxA&gtm_preview=env-175&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-TZS348');
              `,
            }}
          />
        </>
      )}
    </>
  );
};

export default GTM;
