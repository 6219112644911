import React, { createContext, useContext, useEffect, useState } from "react";
import { isSSR } from "helpers/client-server-helper";
import { isDevelopment } from "helpers/next-helpers";
import { childrenPropType } from "../propTypes";
import { shallowEqual, useSelector } from "react-redux";

const URLContext = createContext(null);

// redux state selector
const initialUrlSelector = ({ initialUrl }) => initialUrl;

export function useUrl() {
  return useContext(URLContext);
}

async function getInitialReduxState(context) {
  if (!isSSR) {
    return Promise.resolve({
      initialUrl: window.location.href,
    });
  }
  const { req, asPath } = context.ctx;

  const protocol = isDevelopment() ? "http" : "https";
  const baseUrl = `${protocol}://${req.headers.host}`;

  return Promise.resolve({
    initialUrl: `${baseUrl}${asPath}`,
  });
}

const stringToURL = urlString => {
  try {
    return new URL(urlString);
  } catch {
    return null;
  }
};

export function URLProvider({ children }) {
  const initialUrl = useSelector(initialUrlSelector, shallowEqual);
  const [url, setUrl] = useState(stringToURL(initialUrl));

  const onUrlChanged = () => {
    setUrl(stringToURL(window.location.href));
  };
  useEffect(() => {
    window.addEventListener("locationchange", onUrlChanged);

    return () => {
      window.removeEventListener("locationchange", onUrlChanged);
    };
  }, []);

  return <URLContext.Provider value={url}>{children}</URLContext.Provider>;
}

URLProvider.getInitialReduxState = getInitialReduxState;

URLProvider.propTypes = {
  children: childrenPropType,
};

export default {
  ...URLContext,
  Provider: URLProvider,
};
