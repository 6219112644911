import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import AtomTab from "components/source/atoms/atom-tab";
import AtomTabPanel from "components/source/atoms/atom-tab-panel";
import { childrenPropType } from "components/propTypes";

/*
 * A generic tabs component. Implemented together with AtomTabPanel.
 * Example:
 * <MoleculeTabs tabNames=["Reserve", "Update", "Unlimited"] >
 *   <AtomTabPanel tabName="Reserve">
 *     { your content here }
 *   </AtomTabPanel>
 *
 *   <AtomTabPanel tabName="Update">
 *     { your content here }
 *   </AtomTabPanel>
 *   ...
 * </MoleculeTabs>
 *
 * Notice that the tabName prop given to AtomTabPanel must match one
 * of the tab names in the tabNames prop in MoleculeTabs.
 *
 * @param tabNames the names displayed in each tab.
 * @param selectedTabName (optional) name of the tab to be displayed open to start (first tab is default)
 * @param onTabSelect (optional) callback function that is called when a user selects a new tab
 */

export default class MoleculeTabs extends React.Component {
  static propTypes = {
    children: childrenPropType,
    tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    tabDisplayNames: PropTypes.object,
    selectedTabName: PropTypes.string,
    onTabSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.handleTabClick = this.handleTabClick.bind(this);

    this.state = {
      selectedTabName: this.getSelectedTabName(),
    };
  }

  getSelectedTabName() {
    return this.props.selectedTabName || _.first(this.props.tabNames);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedTabName) {
      this.setState({
        selectedTabName: nextProps.selectedTabName,
      });
    }
  }

  handleTabClick(name) {
    this.setState({
      selectedTabName: name,
    });

    if (this.props.onTabSelect) {
      // execute the callback on tab selection (if there is one)
      this.props.onTabSelect(name);
    }
  }

  renderSelectedTabPanel() {
    const children = React.Children.toArray(this.props.children);
    const selectedTabPanel = _.find(children, child => {
      return child.type === AtomTabPanel && child.props.tabName === this.state.selectedTabName;
    });
    return selectedTabPanel;
  }

  render() {
    const { tabNames } = this.props;

    return (
      <div className="molecule-tabs-wrapper">
        <ul className="molecule-tabs">
          {_.map(tabNames, tabName => {
            const tabDisplayName = this.props.tabDisplayNames ? this.props.tabDisplayNames[tabName] : tabName;
            return (
              <AtomTab
                tabName={tabName}
                tabDisplayName={tabDisplayName}
                key={tabName}
                onClick={this.handleTabClick}
                selected={this.state.selectedTabName === tabName}
              />
            );
          })}
        </ul>
        {this.renderSelectedTabPanel()}
      </div>
    );
  }
}
