import ActionTypes from "../actions/action-types.js";

const types = [
  ActionTypes.MEMBERSHIP_STATE_CHANGE_TIER_SUCCESS,
  ActionTypes.PAUSE_CTA_LOADING,
  ActionTypes.MEMBERSHIP_STATE_LOAD_SUCCESS,
  ActionTypes.UPDATE_MEMBERSHIP_STATE,
  ActionTypes.UPDATE_MEMBERSHIP_STATE_FAIL,
  ActionTypes.MEMBERSHIP_STATE_SUBMITTING_ACTIONS,
];

const getMembershipState = function (state, action) {
  let membershipState = state;

  switch (action.type) {
    case ActionTypes.MEMBERSHIP_STATE_LOAD_SUCCESS:
      membershipState = action.payload;
      break;

    case ActionTypes.PAUSE_CTA_LOADING:
      membershipState = { ...membershipState, pauseCtaLoading: action.payload };
      break;

    case ActionTypes.MEMBERSHIP_STATE_CHANGE_TIER_SUCCESS:
    case ActionTypes.UPDATE_MEMBERSHIP_STATE:
      membershipState = action.payload;
      break;

    case ActionTypes.UPDATE_MEMBERSHIP_STATE_FAIL:
      membershipState = { ...membershipState, error: action.payload.error };
      break;
    case ActionTypes.MEMBERSHIP_STATE_SUBMITTING_ACTIONS:
      membershipState.isSubmittingMembershipActions = action.payload;
      break;
  }

  return membershipState;
};

const fn = function (state, action) {
  const membershipState = getMembershipState(state.membershipState, action);

  return {
    ...state,
    membershipState: membershipState,
  };
};

export default {
  types: types,
  fn: fn,
};
