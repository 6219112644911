import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";

const actions = {
  setKifahAvailableItems: createAction(ActionTypes.KIFAH_AVAILABLE_ITEMS),
  setKifahTrigger: createAction(ActionTypes.KIFAH_TRIGGER),
};

export default actions;

export const { setKifahAvailableItems, setKifahTrigger } = actions;
