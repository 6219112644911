import _ from "underscore";
import { format, isDate, parseISO } from "date-fns";
import { dateFnsFormats } from "rtr-constants";
import { parseISOWithoutTime } from "./date-helpers";

var FormattingUtils = {
  capitalize: function (string) {
    return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
  },

  formatHeight: function (inches) {
    return Math.floor(inches / 12) + "' " + (inches % 12) + '"';
  },

  formatHeightWithLetters: function (inches) {
    return inches ? Math.floor(inches / 12) + " ft " + (inches % 12) + " in" : "";
  },

  formatDate: function (date, formatString) {
    if (!date) return "";
    if (isDate(date)) return format(date, formatString);
    if (typeof date === "number") return format(new Date(date), formatString);
    return format(parseISO(date), formatString);
  },

  longDate: function (date) {
    let dateObj = date;
    dateObj = isDate(date) ? date : parseISOWithoutTime(date);
    if (!isDate(dateObj)) return "";
    return format(dateObj, dateFnsFormats.monthDayYearLong);
  },

  pluralize: function (noun, count) {
    // Only works with normal nouns
    return count === 1 ? noun : noun + "s";
  },

  parseAndSetTypeMatches: function (type) {
    var typeArray = type.toString().split("");
    return _.object(_.zip(["usStandardSize", "birthday", "heightInches", "bustSize"], typeArray));
  },

  preNumericIndefiniteArticle: function (number) {
    if (!number) {
      return;
    }
    const numberStartsWithEight = number.toString().split("")[0] === "8";
    if (number === 11 || number === 18 || numberStartsWithEight) {
      return "an";
    }
    return "a";
  },

  appendOrdinalSuffix: function (number) {
    var lastNum = number % 10,
      lastTwoNums = number % 100;
    if (lastNum === 1 && lastTwoNums !== 11) {
      return number + "st";
    }
    if (lastNum === 2 && lastTwoNums !== 12) {
      return number + "nd";
    }
    if (lastNum === 3 && lastTwoNums !== 13) {
      return number + "rd";
    }
    return number + "th";
  },
};

export default FormattingUtils;

export const {
  capitalize,
  formatDate,
  formatHeight,
  formatHeightWithLetters,
  longDate,
  pluralize,
  parseAndSetTypeMatches,
  preNumericIndefiniteArticle,
  appendOrdinalSuffix,
} = FormattingUtils;
