import { isValid } from "date-fns";
import Constants from "rtr-constants";

function isDateString(str) {
  return isValid(new Date(str));
}

function isHexColor(str) {
  return Constants.validations.hexColor.test(str);
}

function withRequiredCheck(validate) {
  function checkType(isRequired, props, propName, componentName, location) {
    if (!props[propName]) {
      if (isRequired) {
        return new Error(
          "The prop `" +
            propName +
            "` is marked as required in " +
            ("`" + componentName ?? "ANONYMOUS" + "`, but its value is `" + props[propName] + "`")
        );
      }
      return null;
    } else {
      return validate(props, propName, componentName, location);
    }
  }

  const chainedCheckType = checkType.bind(null, false);
  chainedCheckType.isRequired = checkType.bind(null, true);

  return chainedCheckType;
}

function checkDateString(props, propName, component) {
  const componentName = component || "ANONYMOUS";
  const value = props[propName];

  if (value === void 0) return;

  if (!(typeof value === "string" && isDateString(value))) {
    return new Error(`${propName} in ${componentName} is not a valid date string (${value})`);
  }
}

function checkHexColor(props, propName, component) {
  const componentName = component || "ANONYMOUS";
  const value = props[propName];

  if (value === void 0) return;

  if (!(typeof value === "string" && isHexColor(value))) {
    return new Error(`${propName} in ${componentName} is not a valid hex color (${value})`);
  }
}

const PropTypesHelper = {
  dateString: withRequiredCheck(checkDateString),
  hexColor: withRequiredCheck(checkHexColor),
  requiredIf(propType, isRequired) {
    return (props, ...rest) => {
      const propTypeCheck = isRequired(props) ? propType.isRequired : propType;

      return propTypeCheck(props, ...rest);
    };
  },
};

export default PropTypesHelper;

export const { dateString, hexColor, requiredIf } = PropTypesHelper;
