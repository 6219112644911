import { addDays, differenceInDays, isAfter, isPast, isToday, isValid, format, parseISO, isSameDay } from "date-fns";
import _ from "underscore";
// by using universal-cookie, you can expect that this code will only work
// client-side. to make it work server-side, have your component pass the
// cookies object provided to it by the `withCookies` HOC.
import Cookies from "universal-cookie";
import ActionLogger from "action-logger";
import constants from "rtr-constants";
import { parseISOWithoutTime } from "helpers/date-helpers";
import MaternityHelpers from "helpers/maternity-helper";
import MembershipHelpers from "helpers/membership-helpers";
import { getUserLocationZip } from "helpers/dfc-ux-helper";
import StorageHelperApi from "helpers/storage-helper";
import { dateFnsFormats } from "../constants";

const CLASSIC_FILTERS = ["zip_code", "duration", "date", "canonicalSizes", constants.filters.maternity];
const DEFAULT_DURATION = 4;
const RTR_UPDATE_FILTERS = ["zip_code", "canonicalSizes", "rtrUpdateMinAvailability", constants.filters.maternity];
const NY_3_PM_HOUR_UTC = 20;
const UNLIMITED_FILTERS = ["zip_code", "canonicalSizes", "unlimitedMinAvailability", constants.filters.maternity];
const ZIP_CODE_REQUIRED_TIMEFRAME_DAYS = 2;
const ZIP_REGEX = /^\d{5}$/;

const StorageHelper = StorageHelperApi.getApi("availability", ["unlimitedMinAvailability", "rtrUpdateMinAvailability"]);
const cookies = new Cookies();

const filterHelpers = {
  getDiff: function (newObj = {}, oldObj = {}) {
    // These parameter values could be null on the PDP when we make the call to clear and submit the filters.
    let changedFilters = [];
    if (_.isEqual(newObj, oldObj)) {
      return changedFilters;
    }

    changedFilters = _.union(
      changedFilters,
      _.difference(_.keys(newObj), _.keys(oldObj)),
      _.difference(_.keys(oldObj), _.keys(newObj))
    );

    changedFilters = _.union(
      changedFilters,
      _.keys(
        _.pick(newObj, function (value, key) {
          return !_.isEqual(value, oldObj[key]);
        })
      )
    );

    return changedFilters;
  },

  getSizesFromUserProfile: function (userProfile) {
    const sizes = [];
    let profilePrefs;

    if (userProfile?.profiles) {
      profilePrefs = userProfile.profiles;
    } else {
      return sizes;
    }

    if (profilePrefs.primarySize) {
      sizes.push(profilePrefs.primarySize);
    }

    if (profilePrefs.backupSize) {
      sizes.push(profilePrefs.backupSize);
    }

    // TODO: check that primary and secondary sizes are different
    // when user changes her sizes in user profile

    return _.uniq(sizes);
  },

  cleanAvailabilityFilters: function (filters) {
    const cleanFilters = _.clone(filters);
    if (cleanFilters.date && !cleanFilters.duration) {
      cleanFilters.duration = DEFAULT_DURATION;
    }

    return cleanFilters;
  },

  validateAvailability: function (filters) {
    const errors = {};
    let zipCodeRequired = false;
    const todaysDate = new Date();

    // zip code only matters if we are within two days of delivery
    // instead of always requiring zip w/date, only require it if the
    // delivery date is within that window

    // when DFC++ launches, zip code will always matter.
    // we still cannot have a date filter without a zip filter.

    zipCodeRequired =
      filters.date &&
      differenceInDays(parseISOWithoutTime(filters.date), todaysDate) < ZIP_CODE_REQUIRED_TIMEFRAME_DAYS;

    if ((zipCodeRequired && !filters.zip_code) || (filters.zip_code && !filters.zip_code.match(ZIP_REGEX))) {
      _.extend(errors, { "filters[zip_code]": "Select your delivery zip code" });
    }

    if (!filters.date && filters.zip_code) {
      _.extend(errors, { "filters[date]": "Select your delivery date" });
    }

    return errors;
  },

  // Helper function to remove availability filters from a
  // list of filters. Could be expanded in future to remove
  // any filters that are also present in an error object.
  // as of April 2019, zip_code should remain, even if availability is stripped out
  removeAvailabilityFilters: function (filters) {
    return _.omit(filters, ["date"]);
  },

  cleanQueryFilters: function (queryFilters) {
    const filtersInQuery = {};

    // Write these values to localStorage in order to persist
    // And add them to the filtersInQuery object
    _.map(queryFilters, function (value, key) {
      if (_.isUndefined(value) || _.isNull(value) || _.isEmpty(value)) {
        return;
      }

      // Date needs to be validated (i.e. not in the past)
      if (_.isEqual(key, "date") && !filterHelpers.savedDateIsValid(value)) {
        return;
      }

      filtersInQuery[key] = value;
      StorageHelper.setItem(key, value);
    });

    return filtersInQuery;
  },

  cleanSavedFilters: function ({
    listOfKeys,
    gridView = {},
    isAccessoryGrid = false,
    isSaleablesGrid = false,
    isMembershipPDP = false,
  }) {
    const storedFilters = {};

    const isPersonalizedGrid = this.isGridType(gridView, "PersonalizedGrid");
    const isClearanceGrid = this.isGridType(gridView, "Clearance");
    const isUnlimitedGrid = this.isGridType(gridView, "Unlimited");
    const isRtrUpdateGrid = this.isGridType(gridView, "RtrUpdate");
    const isKidsGrid = this.isGridType(gridView, "Kids");

    const isMembershipGrid = isUnlimitedGrid || isRtrUpdateGrid;

    // See if we have corresponding values in storage.
    _.each(listOfKeys, function (key) {
      const value = StorageHelper.getItem(key);

      // availability can be 0 (all items)
      if (_.isEmpty(value) && !_.isNumber(value)) {
        return;
      }

      // Date needs to be validated (i.e. not in the past)
      if (_.isEqual(key, "date") && filterHelpers.savedDateIsValid(value)) {
        storedFilters[key] = value;
      }

      // Alan Chen 4/29/2019 - Do not persist sizes for Kids until our user profile can support kids sizes
      // Sizes need to be split into an array
      if (_.isEqual(key, "canonicalSizes") && !isKidsGrid) {
        storedFilters[key] = value.split(",");
      }

      if (_.contains(["zip_code", "duration"], key)) {
        storedFilters[key] = value;
      }

      // NW [EXPLANATION] 11/14/19: what does unlimitedMinAvailability/rtrUpdateMinAvailability filter do on a Membership PDP?
      // it filters results for the Related Products carousel.
      if (
        (isMembershipGrid || isMembershipPDP) &&
        ["unlimitedMinAvailability", "rtrUpdateMinAvailability"].includes(key)
      ) {
        storedFilters[key] = value;
      }

      // Remove maternity filters if we are on a carousel type grid or clearance grid or accessory grid
      const gridsToExcludeFromMaternity = isPersonalizedGrid || isClearanceGrid || isAccessoryGrid || isSaleablesGrid;
      if (!gridsToExcludeFromMaternity && _.isEqual(key, constants.filters.maternity)) {
        storedFilters[key] = value.split(",");
      }
    });
    return storedFilters;
  },

  isGridType: function (gridView, type) {
    const key = `is${type}`;
    return gridView?.[key] ?? false;
  },

  getPrimarySizeFitRecommendationFilter: function (userData) {
    return userData?.userProfile?.profiles?.primarySize ?? null;
  },

  initializeFilters: function (
    queryParams,
    userData,
    potentialZipCodes,
    gridView = {},
    isAccessoryGrid = false,
    isSaleablesGrid = false,
    isPDP = false,
    isGrid = false
  ) {
    let userProfileFilters = {};
    let queryFilters = {};
    let savedFilters = {};
    let filtersForSubmit = {};
    let returningMaternityFilters = {};
    let suggestedZipFilter = {};
    const userProfile = userData?.userProfile;
    const isUnlimitedLens = userData?.isUnlimitedLens ?? false;
    const isRTRUpdateLens = userData?.isRTRUpdateLens ?? false;
    const preferredSizes = this.getSizesFromUserProfile(userProfile);
    const primarySize = this.getPrimarySizeFitRecommendationFilter(userData);
    let notInQuery;
    let zipSource;

    if (queryParams?.filters) {
      queryFilters = this.cleanQueryFilters(queryParams.filters);
    }

    if (isRTRUpdateLens) {
      // For any RTR_UPDATE_FILTERS not in query, look in localStorage
      notInQuery = _.without(RTR_UPDATE_FILTERS, _.allKeys(queryFilters));
    } else if (isUnlimitedLens) {
      // For any UNLIMITED_FILTERS not in query, look in localStorage
      notInQuery = _.without(UNLIMITED_FILTERS, _.allKeys(queryFilters));
    } else {
      // For any CLASSIC_FILTERS not in query, look in localStorage
      notInQuery = _.without(CLASSIC_FILTERS, _.allKeys(queryFilters));

      if (potentialZipCodes.defaultShippingZip) {
        zipSource = "shipping_addresses";
      } else if (potentialZipCodes.userProfileZip) {
        zipSource = "user_profile";
      } else if (potentialZipCodes.predictedZip) {
        zipSource = "geolocated_zip";
      }
    }

    const isMembershipPDP = (isRTRUpdateLens || isUnlimitedLens) && isPDP;
    savedFilters = this.cleanSavedFilters({
      listOfKeys: notInQuery,
      gridView,
      isAccessoryGrid,
      isSaleablesGrid,
      isMembershipPDP,
    });

    // If a non sub is browsing a grid and doesn't have classic or unlimited availability filters
    // stored in local storage, their default inventory will be All Items or unlimitedMinAvailability = 0
    const isMissingClassicFilters = (!savedFilters.date || !savedFilters.duration) && !gridView.isUnlimited;
    const isMissingUnlimitedAvailabilityFilters = !savedFilters.unlimitedMinAvailability && gridView.isUnlimited;
    const isClearanceGrid = this.isGridType(gridView, "Clearance");

    if (
      !MembershipHelpers.isSubscriptionMember(userData) &&
      isGrid &&
      !isClearanceGrid &&
      (isMissingClassicFilters || isMissingUnlimitedAvailabilityFilters)
    ) {
      savedFilters.unlimitedMinAvailability = "0";
    }

    if (savedFilters.zip_code) {
      zipSource = "persisted_zip";
    }
    if (queryFilters.zip_code) {
      zipSource = "grid_search";
    }

    const canonicalSizeFilter = preferredSizes.length ? { canonicalSizes: preferredSizes } : {};
    const primarySizeFilter = primarySize ? { primarySize } : {};
    // Alan Chen 4/29/2019 - Do not persist sizes for Kids until our user profile can support kids sizes
    const isKidsGrid = this.isGridType(gridView, "Kids");
    userProfileFilters = isKidsGrid ? {} : { ...canonicalSizeFilter, ...primarySizeFilter };

    const { defaultShippingZip, userProfileZip, predictedZip } = potentialZipCodes;
    const suggestedZip = getUserLocationZip(defaultShippingZip, userProfileZip, predictedZip);
    suggestedZipFilter = { zip_code: suggestedZip };

    if (isGrid) {
      const lens = MembershipHelpers.getUserDataLens(userData);

      ActionLogger.logAction({
        object_type: "grid",
        action: "zip_code_populate",
        method_populated: zipSource,
        lens: lens,
        zip: queryFilters.zip_code || savedFilters.zip_code || suggestedZipFilter.zip_code,
      });
    }

    returningMaternityFilters = MaternityHelpers.applyReturningMaternityFilters(userData, savedFilters);

    filtersForSubmit = _.extend(
      suggestedZipFilter,
      userProfileFilters,
      savedFilters,
      queryFilters,
      returningMaternityFilters
    );

    return filtersForSubmit;
  },

  parseSearchPreferences: function () {
    return cookies.get("search_preferences", { doNotParse: false });
  },

  savedDateIsValid: function (date) {
    if (!date) return false;
    const todaysDate = new Date();
    const savedDate = parseISO(date);

    if (!isValid(savedDate)) return false;

    // If saved date is today's date, it is valid
    // until 3 pm EST (using UTC hours below)
    if (isToday(savedDate)) {
      return todaysDate.getUTCHours() < NY_3_PM_HOUR_UTC;
    }

    // If saved date is in the future, it is valid
    return !isPast(savedDate);
  },

  isSelectableDate: function (selectedDate, blackoutDates, deliveryDate) {
    // guard against parseISO throwing
    if (!selectedDate || !deliveryDate) {
      return false;
    }
    // A selectable date:
    // 1. Is not a blackout day
    // 2. Is on or after deliveryDate
    const isBlackoutDay = _.contains(blackoutDates, selectedDate);
    const isOnSelectableRange = deliveryDate
      ? isSameDay(parseISO(selectedDate), parseISO(deliveryDate)) ||
        isAfter(parseISO(selectedDate), parseISO(deliveryDate))
      : true;

    return !isBlackoutDay && isOnSelectableRange;
  },

  pixelFilterParam: function (filters, userData) {
    if (!filters || !userData) {
      return "";
    }

    if ("duration" in filters && "date" in filters) {
      return "specific_dates";
    }

    // We are using toString here because we don't know if these filters will be a boolean or a string.
    // At some point, we may need to investigate and make sure we stick to one.
    const isUnlimitedAllStyles =
      userData.isUnlimitedLens && (filters?.unlimitedMinAvailability ?? 1).toString() === "0";
    const isRTRUpdateAllStyles =
      userData.isRTRUpdateLens && (filters?.rtrUpdateMinAvailability ?? 1).toString() === "0";

    if (isUnlimitedAllStyles || isRTRUpdateAllStyles) {
      return "all_styles";
    }

    return "ships_standard";
  },

  convertSkuToSize: function (product, sku) {
    if (!product || !sku) {
      return;
    }
    const productSkus = product.skus;
    return _.findWhere(productSkus, { id: sku })?.size;
  },

  activeFilters: function (filters) {
    return _.chain(filters)
      .keys()
      .map(function (filterKey) {
        const filterValue = filters[filterKey];

        if (_.isArray(filterValue)) {
          return _.map(filterValue, function (value) {
            return { filterKey: filterKey, filterValue: value };
          });
        } else if (filterValue) {
          return { filterKey: filterKey, filterValue: filterValue };
        }
      })
      .compact()
      .flatten()
      .value();
  },

  filterToString: function (filterValue, filters, filterOptions, filterKey) {
    const makeFilterString = {
      canonicalSizes: function () {
        return "Size " + filterValue;
      },
      date: function () {
        const startDate = parseISOWithoutTime(filterValue);
        const endDate = addDays(startDate, filters["duration"] - 1);
        const dateString = format(startDate, dateFnsFormats.MM_DD) + "-" + format(endDate, dateFnsFormats.MM_DD);
        return dateString + (filters.zip_code ? " • " + filters.zip_code : "");
      },
      duration: function () {
        return;
      },
      zip_code: function () {
        return;
      },
      searchText: function () {
        if (!filterValue) {
          return;
        }

        return "“" + filterValue + "”";
      },
      defaultString: function () {
        if (!filterOptions?.[filterKey]) {
          if (filterKey === "hearts") {
            return "All Hearts";
          }

          if (filterKey === "previouslyRentedStyles") {
            return "Previously Rented";
          }
          return;
        }

        const filter = _.findWhere(filterOptions[filterKey].values, { id: filterValue });

        if (filterOptions?.productCollections?.values.includes(filter) && filters.hearts?.[0] === "true") {
          return;
        }

        if (!filter) {
          return;
        }
        return filter.displayName;
      },
    };

    if (_.has(makeFilterString, filterKey) && filterValue) {
      return makeFilterString[filterKey]();
    } else {
      return makeFilterString["defaultString"]();
    }
  },
};

export default filterHelpers;

export const {
  getDiff,
  getSizesFromUserProfile,
  cleanAvailabilityFilters,
  validateAvailability,
  removeAvailabilityFilters,
  cleanQueryFilters,
  cleanSavedFilters,
  isGridType,
  getPrimarySizeFitRecommendationFilter,
  initializeFilters,
  parseSearchPreferences,
  savedDateIsValid,
  isSelectableDate,
  pixelFilterParam,
  convertSkuToSize,
  activeFilters,
  filterToString,
} = filterHelpers;
