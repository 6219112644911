import $ from "jquery";
import { isSSR } from "helpers/client-server-helper";

const skinnyBannerClassName = "beneath-skinny-banner";

const bannerStates = {};

// given that the code allows for any number of Banners to be present, but we
// know that there are business "rules" to prevent this from happening, this
// method checks to see if any banners are present. once again, fml.
function toggleBannerClass() {
  if (isSSR()) return;

  const shouldShow = Object.values(bannerStates).some(v => v);

  $(".sticky-top-container").toggleClass(skinnyBannerClassName, shouldShow);
}

const actions = {
  show(bannerId) {
    return function () {
      bannerStates[bannerId] = true;

      toggleBannerClass();
    };
  },

  notApplicable(bannerId) {
    return function () {
      bannerStates[bannerId] = null;

      toggleBannerClass();
    };
  },

  hide(bannerId) {
    return function () {
      bannerStates[bannerId] = false;

      toggleBannerClass();
    };
  },
};

export default actions;

export const { show, notApplicable, hide } = actions;
