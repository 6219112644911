import $ from "clients/RawClient";
import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";
import { Carousel } from "@rtr/godmother";

function paramsWithoutSearchText(originalParams) {
  const filters = originalParams?.filters;

  if (!filters || !("searchText" in filters)) {
    return originalParams;
  }
  const params = { ...originalParams };
  params.filters = Object.fromEntries(Object.entries(filters).filter(([key]) => !key.includes("searchText")));
  params.sort = "recommended";
  return params;
}

function getCarouselFromResponse(carouselResponse) {
  if (!carouselResponse) {
    return null;
  }

  let carousel;
  if (carouselResponse.included) {
    carousel = Carousel.fromResponse(carouselResponse);
  } else {
    const { data: carouselData } = carouselResponse;
    carousel = new Carousel(carouselData.id, carouselData.attributes, carouselData.relationships);
  }
  carousel.gridQueryUrlString = `/carousel/${carousel.id}/products`;

  if (carousel.products.length) {
    return carousel;
  }

  return null;
}

const actions = {
  fetchCarouselById(carouselId, params = {}) {
    return function (dispatch) {
      dispatch(actions.fetchCarousel());

      const url = `/carousel/${carouselId}`;
      const data = {
        ...paramsWithoutSearchText(params),
      };

      return $.get({ url, headers: { Accept: "application/json" }, data }).then(
        carouselResponse => {
          const carousel = getCarouselFromResponse(carouselResponse);
          if (carousel) {
            dispatch(actions.fetchCarouselSuccess({ carousel }));
          } else {
            dispatch(actions.fetchCarouselFailure({ error: "carousel not found" }));
          }
        },
        function (error) {
          console.error(`couldn't get the carousel from ${url}`, error);
          dispatch(actions.fetchCarouselFailure({ error }));
        }
      );
    };
  },

  fetchCarousel: createAction(ActionTypes.FETCH_CAROUSEL),
  fetchCarouselSuccess: createAction(ActionTypes.FETCH_CAROUSEL_SUCCESS),
  fetchCarouselFailure: createAction(ActionTypes.FETCH_CAROUSEL_FAILURE),
};

export default actions;

export const { fetchCarouselById, fetchCarousel, fetchCarouselSuccess, fetchCarouselFailure } = actions;
