// Intentionally excluding these headers from import into constants.js because they contain security headers that
// should not be exposed to client-side code.
const HTTP_HEADERS = {
  AUTHORIZATION: "Authorization",
  COOKIE: "Cookie",
  REFERER: "Referer",
  SET_COOKIE: "Set-Cookie",
  SURROGATE_KEY: "Surrogate-Key",
  USER_AGENT: "User-Agent",
  X_RTR_MASQUERADE_ENABLED: "X-Rtr-Masquerade-Enabled",
  X_CASTLE_REQUEST_TOKEN: "X-Castle-Request-Token",
  X_RTR_AUTH_ENABLED: "X-Rtr-Auth-Enabled",
  X_RTR_AUTH_VERSION: "X-Rtr-Auth-Version",
  X_RTR_DEVICE_ID: "X-Rtr-Device-Id",
  X_RTR_IP_ADDRESS: "X-Rtr-Ip-Address",
  X_RTR_DEVICE_TYPE: "X-RTR-Device-Type",
  X_RTR_USER_ID: "X-Rtr-User-Id",
  X_RTR_ZIP_CODES: "X-Rtr-Zip-Codes",
  X_FORWARDED_FOR: "X-Forwarded-For",
};
export default HTTP_HEADERS;
