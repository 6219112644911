import _ from "underscore";

var membershipActionsConstants = _.reduce(
  [
    "START",
    "PAYMENT",
    "CANCEL",
    "UNDO_CANCEL",
    "NEVER_PICKED_CANCEL",
    "PAUSE",
    "UNDO_PAUSE",
    "ITEM_ORDERED",
    "ITEM_ADDON_ORDERED",
    "ITEM_SHIPPED",
    "ITEM_PROBLEM",
    "ITEM_CANCELED",
    "ITEM_RETURNED",
    "ITEM_BOUGHT",
    "ITEM_SWAP",
    "PAYMENT_FAILED",
    "PAYMENT_FINAL_FAILED",
    "RETURN_DATE_CHANGE",
    "RESUME",
    "OPEN_BONUS_SLOT",
    "CLOSE_BONUS_SLOT",
    "UPGRADE_BASE_SLOTS",
    "DOWNGRADE_BASE_SLOTS",
    "SUBMIT_ONBOARDING_SURVEY",
    "SHIPMENT_ADDRESS_CHANGE",
    "START_SWAP",
  ],
  function (memo, v) {
    memo[v] = v;
    return memo;
  },
  {}
);

export default membershipActionsConstants;
