import { getEnvironmentName } from "helpers/environment-helpers";

// All the root domain and subdomain values that we allow-list. Most of these values were pulled from the CMS. We should
// continue to update this list as integrations and links are added and removed. Please keep domain and subdomains
// grouped together and try to keep the list sorted by root domain.
//
// This list should be used before initiating a url redirect and to sanitize links
// @see {https://cwe.mitre.org/data/definitions/601.html}
// @todo: expand this list when we use to sanitize script tags and hard-coded anchor tags (this list doesn't include all
// of the hard-coded Storefront urls
export const URL_DOMAIN_ALLOW_LIST = [
  "aboutads.info",
  "www.aboutads.info",
  "aclu.org",
  "www.aclu.org",
  "acuityscheduling.com",
  "app.acuityscheduling.com",
  "adr.org",
  "www.adr.org",
  "allaboutcookies.org",
  "www.allaboutcookies.org",
  "apple.com",
  "itunes.apple.com",
  "aspireiq.com",
  "community.aspireiq.com",
  "attn.tv",
  "renttherunway.attn.tv",
  "birchbox.com",
  "join.birchbox.com",
  "bit.ly",
  "www.bit.ly",
  "bloomberg.com",
  "www.bloomberg.com",
  "booker.com",
  "go.booker.com",
  "businessinsider.com",
  "www.businessinsider.com",
  "businessoffashion.com",
  "www.businessoffashion.com",
  "cbsnews.com",
  "www.cbsnews.com",
  "cdc.gov",
  "www.cdc.gov",
  "cj.com",
  "signup.cj.com",
  "cloudfront.net",
  "d3psuuw44e2v38.cloudfront.net",
  "cnbc.com",
  "www.cnbc.com",
  "cnn.com",
  "www.cnn.com",
  "copenhagenfashionsummit.com",
  "www.copenhagenfashionsummit.com",
  "copyright.gov",
  "www.copyright.gov",
  "coveringbases.com",
  "www.coveringbases.com",
  "coveteur.com",
  "www.coveteur.com",
  "dfspowerpiece.org",
  "www.dfspowerpiece.org",
  "digiday.com",
  "www.digiday.com",
  "ad.doubleclick.net",
  "doubleclick.net",
  "dressforsuccess.org",
  "www.dressforsuccess.org",
  "epa.gov",
  "www.epa.gov",
  "developers.facebook.com",
  "facebook.com",
  "www.facebook.com",
  "fashionourfuture2020.com",
  "www.fashionourfuture2020.com",
  "fastcompany.com",
  "www.fastcompany.com",
  "forbes.com",
  "www.forbes.com",
  "fortune.com",
  "www.fortune.com",
  "geistm.com",
  "i.geistm.com",
  "gillettevenus.com",
  "www.gillettevenus.com",
  "girlsintech.org",
  "www.girlsintech.org",
  "givebackbox.com",
  "www.givebackbox.com",
  "globalfundforwomen.org",
  "www.globalfundforwomen.org",
  "docs.google.com",
  "drive.google.com",
  "google.com",
  "tools.google.com",
  "www.google.com",
  "googleapis.com",
  "maps.googleapis.com",
  "goop.com",
  "www.goop.com",
  "boards.greenhouse.io",
  "greenhouse.io",
  "headcount.org",
  "www.headcount.org",
  "ijeomakola.com",
  "www.ijeomakola.com",
  "instagram.com",
  "www.instagram.com",
  "jennakutcherblog.com",
  "www.jennakutcherblog.com",
  "jira.com",
  "renttherunway.jira.com",
  "keepandshare.com",
  "www.keepandshare.com",
  "lizziefortunato.com",
  "www.lizziefortunato.com",
  "lorealparisusa.com",
  "www.lorealparisusa.com",
  "marriott.com",
  "w-hotels.marriott.com",
  "mastersofscale.com",
  "www.mastersofscale.com",
  "motherjones.com",
  "www.motherjones.com",
  "msnbc.com",
  "www.msnbc.com",
  "networkadvertising.org",
  "optout.networkadvertising.org",
  "www.networkadvertising.org",
  "newyorker.com",
  "www.newyorker.com",
  "npr.org",
  "www.npr.org",
  "nytimes.com",
  "nytlive.nytimes.com",
  "www.nytimes.com",
  "bumble.onelink.me",
  "onelink.me",
  "onetrust.com",
  "privacyportal-cdn.onetrust.com",
  "a.pgtb.me",
  "pgtb.me",
  "psa.pgtb.me",
  "rtrkids.pgtb.me",
  "rtrxwestelm.pgtb.me",
  "pleaserent.org",
  "www.pleaserent.org",
  "m.popkey.co",
  "popkey.co",
  "projectrenewal.org",
  "www.projectrenewal.org",
  "purewow.com",
  "www.purewow.com",
  "recode.net",
  "www.recode.net",
  "refinery29.com",
  "www.refinery29.com",
  "blog.renttherunway.com",
  "cdn.renttherunway.com",
  "dresscode.renttherunway.com",
  "help.renttherunway.com",
  "investors.renttherunway.com",
  "link.renttherunway.com",
  "renttherunway.com",
  "www.renttherunway.com",
  "qa.renttherunway.com",
  "cdn.rtrcdn.com",
  "rtrcdn.com",
  "rtrshift.com",
  "www.rtrshift.com",
  "satyatwena.com",
  "www.satyatwena.com",
  "schema.org",
  "www.schema.org",
  "app.secure-booker.com",
  "secure-booker.com",
  "www.secure-booker.com",
  "self.com",
  "www.self.com",
  "sheshouldrun.org",
  "www.sheshouldrun.org",
  "staples.com",
  "stores.staples.com",
  "stitcher.com",
  "www.stitcher.com",
  "stuartweitzman.com",
  "www.stuartweitzman.com",
  "thecut.com",
  "www.thecut.com",
  "theglamourai.com",
  "www.theglamourai.com",
  "thenewgroup.org",
  "www.thenewgroup.org",
  "theodysseyonline.com",
  "www.theodysseyonline.com",
  "thredup.com",
  "www.thredup.com",
  "today.com",
  "www.today.com",
  "tripadvisor.com",
  "www.tripadvisor.com",
  "truecostmovie.com",
  "www.truecostmovie.com",
  "tryrenttherunway.com",
  "www.tryrenttherunway.com",
  "twitter.com",
  "www.twitter.com",
  "rtrteam.typeform.com",
  "typeform.com",
  "untilwedoit.org",
  "www.untilwedoit.org",
  "ups.com",
  "www.ups.com",
  "usatoday.com",
  "www.usatoday.com",
  "vogue.com",
  "www.vogue.com",
  "vox.com",
  "www.vox.com",
  "washingtonpost.com",
  "www.washingtonpost.com",
  "westelm.com",
  "www.westelm.com",
  "en.wikipedia.org",
  "wikipedia.org",
  "xojasmine.com",
  "www.xojasmine.com",
  "youtu.be",
  "www.youtu.be",
  "youtube.com",
  "www.youtube.com",
  "renttherunway.zendesk.com",
  "zendesk.com",
  "www.zendesk.com",
];

// add the ruby and SFnext domains to the allow list, but only if local
if (getEnvironmentName() === "development") {
  URL_DOMAIN_ALLOW_LIST.push(
    "localhost",
    "qa.renttherunway.com", // for proxying traffic via nginx when testing apple pay
    "qa.renttherunway.com:444" // same as above, with the port specified for local HTTPS on SFNext
  );
}

// for window.postMessage()
export const URL_ORIGIN_ALLOW_LIST = [
  "https://www.renttherunway.com",
  "https://qa.renttherunway.com",
  "https://qa.renttherunway.com:444",
  "https://stage.renttherunway.com",
  // TODO: use isDevelopment() check here instead?
  "http://localhost:3000",
  "http://localhost:8080",
];

export const URL_PROTOCOLS = {
  HTTP: "http:",
  HTTPS: "https:",
  MAILTO: "mailto:",
  RTR: "rtr:", // a custom protocol used for RTR deep links
  SMS: "sms:",
  TEL: "tel:",
};

export const URL_PROTOCOL_ALLOW_LIST = [
  URL_PROTOCOLS.HTTP,
  URL_PROTOCOLS.HTTPS,
  URL_PROTOCOLS.MAILTO,
  URL_PROTOCOLS.RTR,
  URL_PROTOCOLS.SMS,
  URL_PROTOCOLS.TEL,
];
