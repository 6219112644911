import ActionLogger from "action-logger";
import { parseISOWithoutTime } from "helpers/date-helpers";
import { regionalToCanonical, skuInfo } from "helpers/sku-helpers";
import { dateFnsFormats } from "rtr-constants";
import { addDays, format } from "date-fns";

/**
 * @param {string} actionName - the name of the action to be logged
 * @param {object} reservation - the current pending reservation, if any
 * @param {object} product - the current product being viewed
 * @param {bool} infer - whether the action should be logged synchronously or inferred after the next page load
 * @returns void
 */
export function logReservationAction(actionName, reservation, product, infer = false) {
  const { backupSku, date, duration, primarySku, zipCode } = reservation || {};

  const formattedBeginDate = date ? format(parseISOWithoutTime(date), dateFnsFormats.MM_DD_YYYY) : date;
  const formattedEndDate = date
    ? format(parseISOWithoutTime(addDays(parseISOWithoutTime(date), (duration || 1) - 1)), dateFnsFormats.MM_DD_YYYY)
    : date;

  const primarySize = skuInfo(primarySku?.id)?.size;
  const backupSize = skuInfo(backupSku?.id)?.size;
  const primaryCanonical = regionalToCanonical(product, primarySize);
  const backupCanonical = regionalToCanonical(product, backupSize);
  const sizeData = {
    primarySizeData: {
      canonicalSize: Array.isArray(primaryCanonical) ? primaryCanonical[0] : primaryCanonical,
      size: primarySize,
    },
    backupSizeData: {
      canonicalSize: Array.isArray(backupCanonical) ? backupCanonical[0] : backupCanonical,
      size: backupSize,
    },
  };

  const payload = {
    action: actionName,
    object_type: "node",
    deliveryDate: formattedBeginDate,
    eventDate: formattedBeginDate,
    endDate: formattedEndDate,
    style_name: product?.id,
    duration: duration,
    destinationZip: zipCode,
    zip: zipCode,
    pSize: sizeData.primarySizeData.size,
    pSizeCanonical: sizeData.primarySizeData.canonicalSize,
    bSize: sizeData.backupSizeData.size,
    bSizeCanonical: sizeData.backupSizeData.canonicalSize,
  };

  if (infer) {
    ActionLogger.inferAction(payload);
  } else {
    ActionLogger.logAction(payload);
  }
}
