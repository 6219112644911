import $ from "clients/RawClient";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";
import ActionTypes from "./action-types.js";
import { createAction } from "redux-actions";
import UserProfileActions from "actions/userProfile-actions";
import * as castleHelper from "helpers/castle-helper";
import { EVENTS_CUSTOM_CASTLE } from "rtr-constants";
import { personalize } from "../site/personalization";
import { setUxUserData, updateUserData } from "./on-initialization-actions";

const actions = {
  updateUserRequest: createAction(ActionTypes.UPDATE_USER),
  updateUserDone: createAction(ActionTypes.UPDATE_USER_DONE),
  updateUserFail: createAction(ActionTypes.UPDATE_USER_FAIL),
  fetchUserCreditsSuccess: createAction(ActionTypes.FETCH_USER_CREDITS_SUCCESS),

  updateUser: userProfile => {
    return function (dispatch) {
      dispatch(actions.updateUserRequest());
      return createAjaxAuthRetry({
        url: "/user",
        type: "PATCH",
        data: JSON.stringify(userProfile),
      })
        .then(({ data }) => dispatch(actions.updateUserDone(data)))
        .catch(({ errorThrown }) => dispatch(actions.updateUserFail(errorThrown)));
    };
  },

  updatePassword: (data, onSuccess, onFail) => {
    return () => {
      $.ajax({
        url: "/user/password",
        type: "POST",
        data,
      }).then(
        () => onSuccess(),
        jqXHR => onFail(jqXHR)
      );
    };
  },

  fetchProfile: () => {
    return dispatch =>
      createAjaxAuthRetry({
        url: "/user/fit_profile",
        type: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then(({ data }) => {
          const { dateOfBirth = "", height = "", weight = "", bustSize = "", bodyType = "" } = data || {};
          const { primarySize = "" } = data?.profiles || {};

          dispatch(UserProfileActions.updateUserProfileDone({ primarySize }));
          dispatch(actions.updateUserDone({ dateOfBirth, height, weight, bustSize, bodyType }));
        })
        .catch(error => {
          dispatch(UserProfileActions.updateUserProfileFail(error.errorThrown));
          dispatch(actions.updateUserFail(error.errorThrown));
        });
  },

  updateProfile: (data, callBack, errorCallBack) => {
    return () =>
      createAjaxAuthRetry({
        url: "/user/profile",
        type: "POST",
        headers: {
          Accept: "application/json",
        },
        data,
      })
        .then(({ data }) => {
          castleHelper.logCustomEvent({ name: EVENTS_CUSTOM_CASTLE.UPDATE_USER_PROFILE });
          callBack(data);
        })
        .catch(err => {
          if (typeof errorCallBack === "function") {
            errorCallBack(err);
          }
        });
  },

  fetchUserCredits() {
    return function (dispatch) {
      $.ajax({
        url: "/user/credits",
        type: "GET",
        headers: {
          Accept: "application/json",
        },
      }).then(data => dispatch(actions.fetchUserCreditsSuccess(data)));
    };
  },

  /* Fetch a user object and apply it as the parameter to the success callback function
   *
   * opts : {
   *   // any standard jquery ajax properties may be passed as well.
   *   // `url`, `method`, `dataType`, and `cache` will be overwritten
   * }
   */
  fetchLegacyUserData() {
    return function (dispatch) {
      $.ajax({
        url: "/personalization",
        type: "GET",
        headers: {
          Accept: "application/json",
        },
        cache: false,
      }).then(
        data => {
          RTR.UX.init(data);
          dispatch(setUxUserData());
          personalize();
        },
        jqXHR => {
          dispatch(
            updateUserData({ userProfileHasLoaded: true, userProfileError: jqXHR.responseText || jqXHR.responseJSON })
          );
        }
      );
    };
  },
};

export default actions;

export const {
  fetchLegacyUserData,
  fetchProfile,
  fetchUserCredits,
  fetchUserCreditsSuccess,
  updateProfile,
  updateUser,
  updateUserDone,
  updateUserFail,
} = actions;
