import React from "react";
import PropTypes from "prop-types";
import { oneOfRequired } from "components/propTypes/one-of-required";

class AtomHighlightTextButton extends React.Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    customClass: PropTypes.string,
    disabled: PropTypes.bool,
    href: oneOfRequired("href", "onClick"),
    onClick: oneOfRequired("href", "onClick"),
  };

  static defaultProps = {
    // Assume that by default, users of this component do not want the CTA to be
    // disabled.
    disabled: false,
    customClass: "",
  };

  renderAsAnchor() {
    return (
      <a
        className={`atom-highlight-text-button ${this.props.customClass}`}
        onClick={this.props.onClick}
        href={this.props.href}>
        {this.props.buttonText}
      </a>
    );
  }

  renderAsButton() {
    return (
      <button
        className={`atom-highlight-text-button ${this.props.customClass}`}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        type="button">
        {this.props.buttonText}
      </button>
    );
  }

  render() {
    const { href } = this.props;

    if (href) {
      return this.renderAsAnchor();
    }

    return this.renderAsButton();
  }
}

export default AtomHighlightTextButton;
