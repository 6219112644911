import { useMemo } from "react";
import thunkMiddleware from "redux-thunk";
import { createStore, compose, applyMiddleware } from "redux";
import ReduxApp from "../assets/javascripts/redux-app";
import { getEnvironmentName } from "../assets/javascripts/helpers/environment-helpers";

let store;

export function getReduxDevTools(options) {
  // If the user has Redux DevTools Extension installed,
  // this returns the function needed to configure the extension in the store.
  // We check on `window.parent` because Storybook runs in an iframe and we want the devtools there too.
  // (Inspired by storybook's implementation of React devtools: https://github.com/storybooks/storybook/issues/102

  if (typeof window === "undefined") {
    return function (f) {
      return f;
    };
  } else if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    return window.__REDUX_DEVTOOLS_EXTENSION__(options);
  } else if (window.parent?.__REDUX_DEVTOOLS_EXTENSION__) {
    // window.parent contains the iframe where our storybook components render
    // so we want the redux store within the iframe to have the parent's devtools
    return window.parent.__REDUX_DEVTOOLS_EXTENSION__(options);
  } else {
    // If the extension isn't on the window or the parent window, we return a dummy noop function
    return function (f) {
      return f;
    };
  }
}

function initStore(preloadedState = {}) {
  const finalCreateStore = compose(
    applyMiddleware(thunkMiddleware),
    getReduxDevTools({ trace: getEnvironmentName() === "development" })
  )(createStore);
  return finalCreateStore(ReduxApp, preloadedState);
}

export const initializeStore = preloadedState => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = null;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
