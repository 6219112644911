import { membershipLens } from "rtr-constants";
import MembershipHelpers from "helpers/membership-helpers";

const UNLIMITED_MIN_AVAILABILTY_FILTER_KEY = "unlimitedMinAvailability";
const RTR_UPDATE_MIN_AVAILABILITY_FILTER_KEY = "rtrUpdateMinAvailability";
const ALL_AVAILABLE_FILTER_VALUE = 1;
const ALL_STYLES_FILTER_VALUE = 0;

const availabilityFilterHelpers = {
  prospectingAvailabilityFilterCopy: function (userData, workingFilters, lens) {
    if (this.membershipMinAvailability(userData, workingFilters)) {
      return "Available Now";
    }

    if (lens === membershipLens.unlimited) {
      return "All RTR Unlimited";
    }

    if (lens === membershipLens.RTRUpdate) {
      return "All RTR Update";
    }

    return "All Styles";
  },

  membershipMinAvailability: function (userData = {}, workingFilters = {}) {
    if (userData?.isUnlimitedLens) {
      const minAvailability = workingFilters?.unlimitedMinAvailability ?? 1;
      return parseInt(minAvailability);
    }

    if (userData?.isRTRUpdateLens) {
      const minAvailability = workingFilters?.rtrUpdateMinAvailability ?? 1;
      return parseInt(minAvailability);
    }
  },

  getRentalBeginDate(membershipState) {
    if (MembershipHelpers.isActiveMembership(membershipState)) {
      return MembershipHelpers.getRentBeginDate(membershipState);
    }
  },

  getRtrUpdateAvailableNow: function (workingFilters) {
    //
    // NOTE default is to show users the styles we have available
    //
    const defaultMinAvailability = 1;
    const rtrUpdateMinAvailability = workingFilters?.rtrUpdateMinAvailability ?? defaultMinAvailability;

    return Number(rtrUpdateMinAvailability) === defaultMinAvailability;
  },

  unlimitedMinAvailabilityKey: UNLIMITED_MIN_AVAILABILTY_FILTER_KEY,

  rtrUpdateMinAvailabilityFilterKey: RTR_UPDATE_MIN_AVAILABILITY_FILTER_KEY,

  allAvailableFilterValue: ALL_AVAILABLE_FILTER_VALUE,

  allStylesAvailabilityFilterValue: ALL_STYLES_FILTER_VALUE,
};

export default availabilityFilterHelpers;
