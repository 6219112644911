import PropTypes from "prop-types";
import { forwardRef } from "react";

import { useLazyImageContext } from "../../layout/LazyImageContext";

/**
 * This wrapper component passes all props through to the image
 */
const RtrImage = forwardRef((props, ref) => {
  const lazyLoadImage = useLazyImageContext();

  let loadingStrategy = "eager";
  if (props.loading) {
    loadingStrategy = props.loading;
  } else if (lazyLoadImage) {
    loadingStrategy = "lazy";
  }
  // eslint-disable-next-line react/jsx-props-no-spreading, react/forbid-elements
  return <img data-test-id="image" {...props} alt={props.alt} loading={loadingStrategy} ref={ref} />;
});

RtrImage.displayName = "RtrImage";

RtrImage.propTypes = {
  // HTML "img" attributes
  alt: PropTypes.string,
  crossOrigin: PropTypes.oneOf(["", "anonymous", "use-credentials"]),
  decoding: PropTypes.oneOf(["", "async", "auto", "sync"]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.oneOf(["", "eager", "lazy"]),
  referrerPolicy: PropTypes.oneOf([
    "",
    "no-referrer",
    "no-referrer-when-downgrade",
    "origin",
    "origin-when-cross-origin",
    "same-origin",
    "strict-origin",
    "strict-origin-when-cross-origin",
    "unsafe-url",
  ]),
  sizes: PropTypes.string,
  src: PropTypes.string,
  srcSet: PropTypes.string,
  useMap: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Some generic HTML attributes
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  // Fastly-specific props
  quality: PropTypes.string,
  // Other
  itemProp: PropTypes.oneOf(["image"]),
  ref: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    // https://stackoverflow.com/questions/48007326/what-is-the-correct-proptype-for-a-ref-in-react/51127130#51127130
    // > "Note about server side rendering"
    PropTypes.shape({ current: PropTypes.instanceOf(typeof Element === "undefined" ? function () {} : Element) }),
  ]),
};

export default RtrImage;
