import React from "react";

import AtomRemoveAddressCard from "components/source/atoms/atom-remove-address-card.jsx";
import AtomManageAddressCardWithStatusActions from "components/source/atoms/atom-manage-address-card-with-status-actions.jsx";
import AtomAddressCardsList from "components/source/atoms/atom-address-cards-list";

import AtomFullWidthButton from "components/source/atoms/atom-full-width-button";
import AtomHighlightTextButton from "components/source/atoms/atom-highlight-text-button";
import { getDefaultAddressId, addressesSortedByDefaultAddress } from "../../../helpers/address-helpers";

import PropTypes from "prop-types";
import { addressPropType } from "../../propTypes/address-prop-type";

const propTypes = {
  addressBookTitle: PropTypes.string,
  addresses: PropTypes.arrayOf(addressPropType).isRequired,
  handleClose: PropTypes.func,
  hideNewAddressButton: PropTypes.bool,
  hideUseSelectedAddressButton: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  triggerAddressForm: PropTypes.func,
  updateDefaultAndRemoveAddress: PropTypes.func,
  writeMembershipShipmentAddress: PropTypes.func,
};

export const MoleculeManageAddressCards = props => {
  const originalDefaultAddressId = getDefaultAddressId(props.addresses);
  const [removedAddresses, setRemovedAddresses] = React.useState({});
  const [selectedAddressId, setSelectedAddressId] = React.useState(originalDefaultAddressId);

  const toggleAddressMethodRemoval = id =>
    setRemovedAddresses(previousState => ({ ...previousState, [id]: !previousState[id] }));

  const submitAddressChanges = () => {
    const clearRemovedAddresses = () => setRemovedAddresses({});
    const newDefaultAddressId = selectedAddressId;

    const onSuccess = () => {
      props.writeMembershipShipmentAddress(newDefaultAddressId);
      props.handleClose();
    };

    const onFailure = () => {
      setSelectedAddressId(null);
      clearRemovedAddresses();
    };

    props.updateDefaultAndRemoveAddress({
      values: {
        newDefaultAddressId,
        originalDefaultAddressId,
        updateData: {
          url: "/shippingAddresses",
          vals: {
            id: newDefaultAddressId,
            default: true,
          },
        },
        removeData: {
          vals: {
            ids: Object.keys(removedAddresses).filter(key => removedAddresses[key]),
          },
        },
      },
      onSuccess,
      onFailure,
    });
  };

  const isButtonDisabled = () => {
    const isButtonDisabled =
      !Object.keys(removedAddresses || {}).length && selectedAddressId === originalDefaultAddressId;

    return isButtonDisabled || props.isSubmitting;
  };

  const renderCards = () => {
    const sortedAddresses = addressesSortedByDefaultAddress(props.addresses);
    return sortedAddresses.map(address => {
      const id = address?.id;

      // if it's a manage address list
      if (removedAddresses[id]) {
        return (
          <AtomRemoveAddressCard key={id} address={address} toggleAddressMethodRemoval={toggleAddressMethodRemoval} />
        );
      }

      return (
        <AtomManageAddressCardWithStatusActions
          key={id}
          address={address}
          selectDefaultProfileId={id => setSelectedAddressId(id)}
          selectedAddressId={selectedAddressId}
          toggleAddressMethodRemoval={toggleAddressMethodRemoval}
        />
      );
    });
  };

  return (
    <div className="molecule-address-select__wrapper">
      <div className="molecule-address-select__header">
        Select Default Address
        {!props.hideNewAddressButton && (
          <div className="subform__select-existing-address">
            Or
            <AtomHighlightTextButton buttonText="Add new address" onClick={() => props.triggerAddressForm()} />
          </div>
        )}
      </div>
      {props.addressBookTitle && <div className="molecule-address-select__header">{props.addressBookTitle}</div>}
      {props.addresses && <AtomAddressCardsList addressCards={renderCards()} />}
      {!props.hideUseSelectedAddressButton && (
        <div className="molecule-address-select__add">
          <AtomFullWidthButton
            buttonText={props.isSubmitting ? "Saving..." : "Save Changes"}
            onClick={submitAddressChanges}
            disabled={isButtonDisabled()}
          />
        </div>
      )}
    </div>
  );
};

MoleculeManageAddressCards.propTypes = propTypes;

export default MoleculeManageAddressCards;
