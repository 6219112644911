import _ from "underscore";
/**
 * @method componentClassName
 * @example componentClassName("component-name", "title", "title--alert")
 *          > "component-name__title component-name__title--alert"
 * @param  {String} baseClassName the root for all classes
 * @return {String} 1) baseClassName if no suffixes are passed
 *                  2) a space-separated list of strings which combine the base
 *                     with each of the suffixes, separated by "__"
 *
 * ***********************************************************
 * *** this is (likely) not the method you are looking for ***
 * *** (it is exported just in case you need it)           ***
 * ***********************************************************
 */
export function componentClassName(baseClassName, ...suffixes) {
  if (!suffixes || !suffixes.length) {
    return baseClassName;
  }

  return _.chain(suffixes)
    .filter(_.identity) // filter out empty suffixes
    .map(s => `${baseClassName}__${s}`) // concatenate base + suffix
    .value() // (execute the chain)
    .join(" "); // put it all together!
}

/**
 * @method fnComponentClassName
 * @example fnComponentClassName("component-name")
 *          > function(...suffixes) { ... }
 * @param  {String} baseClassName the root for all classes
 * @return {Function} a clone of componentClassName with the first parameter set
 *
 * ***********************************************************
 * Use this function when defining your Component to simplify
 * setting classNames with a common prefix.
 * const componentClassName = fnComponentClassName("component-base");
 * componentClassName("element") => "component-base__element";
 * ***********************************************************
 */
export function fnComponentClassName(baseClassName) {
  return _.partial(componentClassName, baseClassName);
}
