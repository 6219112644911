import PropTypes from "prop-types";
import { dateString } from "../../helpers/PropTypesHelper";
import { addressPropType } from "./address-prop-type";

export const exchangeGroupPropType = PropTypes.shape({
  address: addressPropType,
  bagCreated: dateString,
  bagCutoffIsToday: PropTypes.bool.isRequired,
  /**
   * 2022-10-22T18:00:00.000Z
   */
  bagCutoffTime: dateString.isRequired,
  bookings: PropTypes.array,
  /**
   * 2022-10-22T18:00:00.000Z
   */
  exchangeCutoffTime: dateString.isRequired,
  groupId: PropTypes.number.isRequired,
  hadPO: PropTypes.bool.isRequired,
  newCustomer: PropTypes.bool.isRequired,
  /**
   * 2022-10-22T18:00:00.000Z
   */
  newRentBegin: dateString.isRequired,
  orderId: PropTypes.number.isRequired,
  rentalStartIsToday: PropTypes.bool.isRequired,
});
