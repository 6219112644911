import _ from "underscore";
import ActionTypes from "./action-types";
import { createAction } from "redux-actions";
import $ from "clients/RawClient";

const actions = {
  setLoadingPhotos: createAction(ActionTypes.LOADING_REVIEW_PHOTOS),
  submitReviewPhotoSuccess: createAction(ActionTypes.SUBMIT_REVIEW_PHOTO_SUCCESS),
  deleteReviewPhotoSuccess: createAction(ActionTypes.DELETE_REVIEW_PHOTO_SUCCESS),
  updateReviewPhotoFailure: createAction(ActionTypes.UPDATE_REVIEW_PHOTO_FAILURE),
  rotateReviewPhotoSuccess: createAction(ActionTypes.ROTATE_REVIEW_PHOTO_SUCCESS),
  setLoadingRotatedPhoto: createAction(ActionTypes.LOADING_ROTATED_REVIEW_PHOTO),
  setLoadingCropPhoto: createAction(ActionTypes.CROP_REVIEW_PHOTO_LOADING),
  clearReviewPhotos: createAction(ActionTypes.CLEAR_REVIEW_PHOTOS),
  cropReviewPhotoFailure: createAction(ActionTypes.CROP_REVIEW_PHOTO_FAILURE),
  cropReviewPhotoSuccess: createAction(ActionTypes.CROP_REVIEW_PHOTO_SUCCESS),

  submitReviewPhoto: function (productId, photo) {
    return function (dispatch) {
      dispatch(actions.setLoadingPhotos(true));
      // convert base64/URLEncoded data component to raw binary data held in a string
      const photoDataURI = photo.data,
        photoBinary = atob(photoDataURI.split(",")[1]),
        photoBinaryArray = _.map(photoBinary, function (c) {
          return c.charCodeAt(0);
        }),
        photoFile = new Blob([new Uint8Array(photoBinaryArray)], { type: photo.type }),
        formData = new FormData();

      formData.append("file", photoFile, photo.filename);

      return $.ajax({
        type: "post",
        url: "/user/moments/" + productId + "/photo",
        data: formData,
        isMultipartFormData: true,
      }).then(
        function (data) {
          dispatch(actions.setLoadingPhotos(false));
          dispatch(actions.submitReviewPhotoSuccess({ photoId: data._id, photoUrl: data.urls.imageThumb }));
          dispatch(actions.updateReviewPhotoFailure(null));
        },
        function () {
          dispatch(actions.setLoadingPhotos(false));
          dispatch(
            actions.updateReviewPhotoFailure(
              "Unable to upload review photo - please use a JPEG or PNG photo that is less than 3 MB."
            )
          );
        }
      );
    };
  },

  deleteReviewPhoto: function (photoId, onSuccess, onFailure) {
    return function (dispatch) {
      return $.ajax({
        type: "post",
        url: "/moments/photo/" + photoId + "/delete",
      }).then(
        function (data) {
          dispatch(actions.deleteReviewPhotoSuccess(data._id));
          dispatch(actions.updateReviewPhotoFailure(null));
          if (typeof onSuccess === "function") {
            onSuccess(data);
          }
        },
        function (response) {
          dispatch(actions.updateReviewPhotoFailure("Unable to delete review photo. Please try again."));
          if (typeof onFailure === "function") {
            onFailure(response);
          }
          return response;
        }
      );
    };
  },

  rotateReviewPhoto: function (photoId) {
    return function (dispatch) {
      dispatch(actions.setLoadingRotatedPhoto(photoId));
      return $.ajax({
        type: "post",
        url: "/moments/photo/" + photoId + "/rotate",
        data: { rotation: 90 },
      }).then(
        function () {
          dispatch(actions.setLoadingRotatedPhoto(false));
          dispatch(actions.rotateReviewPhotoSuccess(photoId));
        },
        function (response) {
          dispatch(actions.setLoadingRotatedPhoto(false));
          dispatch(actions.updateReviewPhotoFailure("Unable to rotate review photo. Please try again."));
          return response;
        }
      );
    };
  },

  cropReviewPhoto: function (photoId, formData) {
    return function (dispatch) {
      dispatch(actions.setLoadingCropPhoto(true));
      dispatch(actions.cropReviewPhotoFailure(null)); // clear any preexisting error message
      return $.ajax({
        type: "POST",
        url: `/moments/photo/${photoId}/crop`,
        data: formData,
      })
        .then(
          function () {
            dispatch(actions.cropReviewPhotoSuccess());
          },
          function (response) {
            dispatch(actions.cropReviewPhotoFailure(response.message));
          }
        )
        .finally(function () {
          dispatch(actions.setLoadingCropPhoto(false));
        });
    };
  },
};

export default actions;

export const {
  clearReviewPhotos,
  cropReviewPhoto,
  cropReviewPhotoFailure,
  cropReviewPhotoSuccess,
  deleteReviewPhoto,
  deleteReviewPhotoSuccess,
  rotateReviewPhoto,
  rotateReviewPhotoSuccess,
  setLoadingCropPhoto,
  setLoadingPhotos,
  setLoadingRotatedPhoto,
  submitReviewPhoto,
  submitReviewPhotoSuccess,
  updateReviewPhotoFailure,
} = actions;
