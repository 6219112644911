import ActionTypes from "../actions/action-types.js";

export const types = [
  ActionTypes.CE_CHECKOUT_LOADING,
  ActionTypes.CE_CHECKOUT_SUBMITTING,
  ActionTypes.CE_CHECKOUT_SUCCESS,
  ActionTypes.CE_CHECKOUT_ERROR,
  ActionTypes.CE_CHECKOUT_PROMO_ERROR,
  ActionTypes.CE_CHECKOUT_PROMO_LOADING,
  ActionTypes.CE_CHECKOUT_KIFAH_SUCCESS,
  ActionTypes.CE_CHECKOUT_USER_PAYMENT_METHODS_SUCCESS,
  ActionTypes.CE_CHECKOUT_ID_CURRENTLY_CREATING,
  ActionTypes.CE_CHECKOUT_PLACE_ORDER_DISABLED,
  ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_LOADING,
  ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_SUCCESS,
  ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_ERROR,
  ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_EXISTS,
  ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_CHALLENGED,
];

export function fn(state, action) {
  let {
    ceCheckout,
    ceCheckoutLoading,
    ceCheckoutSubmitting,
    ceCheckoutError,
    ceCheckoutIdCurrentlyCreating,
    ceCheckoutKifah,
    ceCheckoutPlaceOrderDisabled,
    ceCheckoutPromoError,
    ceCheckoutPromoLoading,
    userPaymentMethods,
    ceLimitedAccountCheckoutEmail,
    ceLimitedAccountCheckoutEmailError,
    ceLimitedAccountCheckoutEmailLoading,
    ceLimitedAccountCheckoutEmailExists,
    ceLimitedAccountCheckoutEmailChallenged,
  } = state;

  switch (action.type) {
    case ActionTypes.CE_CHECKOUT_ERROR:
      ceCheckoutError = action.payload;
      ceCheckoutLoading = false;
      break;

    case ActionTypes.CE_CHECKOUT_LOADING:
      ceCheckoutLoading = action.payload;
      break;

    case ActionTypes.CE_CHECKOUT_SUBMITTING:
      ceCheckoutSubmitting = action.payload;
      break;

    case ActionTypes.CE_CHECKOUT_SUCCESS:
      ceCheckout = action.payload || {};
      ceCheckoutError = null;
      break;

    case ActionTypes.CE_CHECKOUT_PROMO_LOADING:
      ceCheckoutPromoLoading = action.payload;
      break;

    case ActionTypes.CE_CHECKOUT_PROMO_ERROR:
      ceCheckoutPromoError = action.payload;
      ceCheckoutPromoLoading = false;
      break;

    case ActionTypes.CE_CHECKOUT_KIFAH_SUCCESS:
      ceCheckoutKifah = action.payload || {};
      break;

    case ActionTypes.CE_CHECKOUT_USER_PAYMENT_METHODS_SUCCESS:
      userPaymentMethods = action.payload || [];
      break;

    case ActionTypes.CE_CHECKOUT_ID_CURRENTLY_CREATING:
      ceCheckoutIdCurrentlyCreating = action.payload;
      break;

    case ActionTypes.CE_CHECKOUT_PLACE_ORDER_DISABLED:
      ceCheckoutPlaceOrderDisabled = action.payload;
      break;

    case ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_LOADING:
      ceLimitedAccountCheckoutEmailLoading = action.payload;
      break;

    case ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_SUCCESS:
      ceLimitedAccountCheckoutEmail = action.payload;
      break;

    case ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_ERROR:
      ceLimitedAccountCheckoutEmailError = action.payload;
      break;

    case ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_EXISTS:
      ceLimitedAccountCheckoutEmailExists = action.payload;
      break;

    case ActionTypes.CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_CHALLENGED:
      ceLimitedAccountCheckoutEmailChallenged = action.payload;
      break;

    default:
      return state;
  }

  return {
    ...state,
    ceCheckout,
    ceCheckoutLoading,
    ceCheckoutSubmitting,
    ceCheckoutError,
    ceCheckoutIdCurrentlyCreating,
    ceCheckoutKifah,
    ceCheckoutPlaceOrderDisabled,
    ceCheckoutPromoLoading,
    ceCheckoutPromoError,
    userPaymentMethods,
    ceLimitedAccountCheckoutEmail,
    ceLimitedAccountCheckoutEmailError,
    ceLimitedAccountCheckoutEmailLoading,
    ceLimitedAccountCheckoutEmailExists,
    ceLimitedAccountCheckoutEmailChallenged,
  };
}

export default {
  types: types,
  fn: fn,
};
