import ActionTypes from "actions/action-types";
import { flagsAndExperimentServerError } from "rtr-constants";

const types = [
  ActionTypes.FETCH_FLAG_OR_EXPERIMENT_SUCCESS,
  ActionTypes.FETCH_FLAG_OR_EXPERIMENT_ERROR,
  ActionTypes.FETCH_FLAG_OR_EXPERIMENT_LOADING,
];

const flagsAndExperimentsModification = function (state, action) {
  const flagsAndExperimentsStateSlice = { ...state.flagsAndExperiments };
  const loadingFlagsAndExperiments = [...(state.loadingFlagsAndExperiments || [])];
  const removeLoadingFlag = flag => {
    const flagIndex = loadingFlagsAndExperiments.indexOf(flag);
    loadingFlagsAndExperiments.splice(flagIndex, 1);
  };

  switch (action.type) {
    case ActionTypes.FETCH_FLAG_OR_EXPERIMENT_LOADING:
      if (!loadingFlagsAndExperiments.includes(action.payload)) {
        loadingFlagsAndExperiments.push(action.payload);
      }
      break;
    case ActionTypes.FETCH_FLAG_OR_EXPERIMENT_SUCCESS:
      if (action.payload?.length) {
        // NW [EXPLANATION] 5/19/22: flags must be requested one-by-one for Launch Darkly to capture evaluation metrics, but an array of one item is returned from Godmother.
        const newFlagState = action.payload[0];
        flagsAndExperimentsStateSlice[newFlagState.id] = newFlagState.value;

        removeLoadingFlag(newFlagState.id);
      }
      break;
    case ActionTypes.FETCH_FLAG_OR_EXPERIMENT_ERROR:
      if (action.payload?.status >= 500) {
        flagsAndExperimentsStateSlice[action.payload.newFlagKey] = flagsAndExperimentServerError;

        removeLoadingFlag(action.payload.newFlagKey);
      }
      break;
    default:
      break;
  }

  return { flagsAndExperimentsStateSlice, loadingFlagsAndExperiments };
};

const fn = function (state, action) {
  const { flagsAndExperimentsStateSlice, loadingFlagsAndExperiments } = flagsAndExperimentsModification(state, action);
  return {
    ...state,
    flagsAndExperiments: flagsAndExperimentsStateSlice,
    loadingFlagsAndExperiments: loadingFlagsAndExperiments || [],
  };
};

export default {
  types: types,
  fn: fn,
};
