import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import AuthActions from "actions/auth-actions";
import { browserPropType, membershipStatePropType, userDataPropType } from "components/propTypes";
import { analytics, assetBaseUrls } from "rtr-constants";
import { Insider, User } from "routes";
import { isActiveMembership, isCurrentOrFormerSubscriber, isSubscriptionMember } from "helpers/membership-helpers";
import HomePickupActions from "actions/home-pickup-actions";
import ActionLogger from "action-logger";
import { authFormConstants } from "rtr-constants/auth";
import { withFeatureFlags, featureFlagsPropType, Flags } from "components/source/hoc/with-feature-flags";
import { HEAP_AUTH_TRIGGER_TYPES } from "helpers/heap-helpers";
import { hasProfile } from "../hoc/with-user-data";
import {
  experimentsNamesValues,
  flagsAndExperimentNames,
  flagsAndExperimentsPropType,
  withOnLoadFlagsAndExperiments,
} from "../hoc/with-flags-and-experiments";
import RtrImage from "../shared/rtr-image";

const { Views } = authFormConstants;

// NW [EXPLANATION] 4/13/21: a template encapsulating shared implementations between consumers (UserMenu and UserMenuMobile)
function withUserMenuTemplate(WrappedComponent) {
  return class UserMenuTemplate extends React.Component {
    static displayName = `withUserMenuTemplate(${WrappedComponent.displayName || WrappedComponent.name})`;

    static propTypes = {
      browser: browserPropType,
      credits: PropTypes.shape({
        totalDollarAmountCredits: PropTypes.string,
      }),
      flagsAndExperiments: flagsAndExperimentsPropType,
      displayHomePickupOverlay: PropTypes.func,
      featureFlags: featureFlagsPropType,
      memberProgramChangeInfo: PropTypes.object,
      membershipState: membershipStatePropType,
      showAuthModal: PropTypes.func,
      userData: userDataPropType,
      fetchFlagOrExperimentValue: PropTypes.func,
    };

    static defaultProps = {
      flagsAndExperiments: {},
    };

    renderAccountCredits = () => {
      const { credits: { totalDollarAmountCredits } = {} } = this.props;

      if (!totalDollarAmountCredits || !parseInt(totalDollarAmountCredits.replace("$", ""), 10)) {
        return null;
      }

      return <span className="credits-label credits-active pill">{totalDollarAmountCredits}</span>;
    };

    renderAccountGreeting = () => {
      const { memberships, userProfile } = this.props.userData || {};
      const isProMember = memberships?.isProMember;
      const firstName = userProfile?.firstName;

      const nameString = firstName ? `Hi ${firstName}` : "My Account";

      if (isProMember) {
        return (
          <>
            <span className="account-name-span account-name-wpro-span">{nameString}</span>
            <span className="pill pro">PRO</span>
          </>
        );
      } else {
        return <span className="account-name-span">{nameString}</span>;
      }
    };

    logInviteAFriend = () => {
      const {
        browser: { isMobileViewport },
        membershipState: { id, membershipId, membershipTermNumber },
      } = this.props;
      const objectType = isMobileViewport ? analytics.OBJECT_TYPE.MOBILE_NAV : analytics.OBJECT_TYPE.TOP_NAV;

      ActionLogger.inferAction({
        object_type: objectType,
        action: analytics.ACTION_TYPE.CLICK_MENU_ITEM,
        category_name: analytics.CATEGORY_NAME.INVITE_A_FRIEND,
        term: membershipTermNumber,
        membership_id: membershipId,
        user_id: id,
      });
    };

    renderSubscriberReferralAdvocateLink() {
      if (!this.props.featureFlags?.[Flags.PPS_REFERRALS] || !isSubscriptionMember(this.props.userData)) {
        return null;
      }

      return (
        <a
          className="pps-referrals-advocate-cta promoted-cta"
          href="/invite_a_friend?ref_medium=onsite&ref_source=account_dropdown"
          onClick={this.logInviteAFriend}>
          <span className="universal-small--semibold">Invite a Friend</span>
        </a>
      );
    }

    renderRtrInsiderLink() {
      const { membershipState } = this.props;
      if (!isActiveMembership(membershipState)) {
        return null;
      }

      return (
        <a
          className="rtr-insider-link promoted-cta"
          href={`${Insider.index}?ref_medium=onsite&ref_source=account_dropdown`}>
          <span className="universal-small--semibold">RTR Insider</span>
        </a>
      );
    }

    renderProSettingsMenuOption() {
      const { isFormerProMember, isProMember } = this.props.userData?.memberships || {};

      if (isProMember || isFormerProMember) {
        return (
          <a
            href={`${User.ProSettings}?nav_location=user_account&amp;action=click_proSettings&amp;object_type=top_nav`}>
            PRO Settings
          </a>
        );
      }
    }

    renderClassicReferralsMenuOption() {
      const { membershipState } = this.props;

      if (
        (this.props.featureFlags?.[Flags.CLASSIC_REFERRAL_FOR_ACTIVE_MEMBER] && isActiveMembership(membershipState)) ||
        (this.props.featureFlags?.[Flags.CLASSIC_REFERRAL_FOR_INACTIVE_MEMBER] && !isActiveMembership(membershipState))
      ) {
        return (
          <a id="get-30" href="/shares/new?ref_medium=onsite&ref_source=account_dropdown" className="get-30">
            Get $30
          </a>
        );
      }

      return null;
    }

    renderSettingsMenuOption() {
      if (isCurrentOrFormerSubscriber(this.props.membershipState)) {
        return (
          <a href="/user/settings?nav_location=user_account&amp;action=click_rtrUpdateSettings&amp;object_type=top_nav">
            Your Membership
          </a>
        );
      } else {
        return <a href="/user/settings/reserve">Settings</a>;
      }
    }

    clickSchedulePickup = () => {
      const logData = {
        object_type: analytics.OBJECT_TYPE.MY_RTR_MENU,
        action: analytics.ACTION_TYPE.CLICK_SCHEDULE_PICKUP,
      };
      ActionLogger.logAction(logData);
      this.props.displayHomePickupOverlay(true);
    };

    renderSchedulePickupMenuOption() {
      if (
        isSubscriptionMember(this.props.userData) ||
        (this.props.featureFlags?.[Flags.ENABLE_RESERVE_SCHEDULER] &&
          hasProfile(this.props.userData) &&
          !isSubscriptionMember(this.props.userData))
      ) {
        return (
          <button className="schedule-pickup" id="schedule-pickup" onClick={this.clickSchedulePickup}>
            Schedule Pickup
            {this.props.flagsAndExperiments?.[flagsAndExperimentNames.ENABLE_HOME_PICKUP_NEW_TAG] && (
              <RtrImage
                className="new-tag-icon"
                alt="new tag"
                src={`${assetBaseUrls.DESIGNER_IMAGES}/03302023_New_Tag.svg`}
              />
            )}
          </button>
        );
      }
    }

    renderAccountSubMenuOptions = () => {
      return (
        <>
          {this.renderRtrInsiderLink()}
          {this.renderSubscriberReferralAdvocateLink()}
          <a href="/user/orders/upcoming?nav_location=user_account&amp;action=click_rentalHistory&amp;object_type=top_nav">
            Upcoming Order
          </a>
          <a href="/user/orders?nav_location=user_account&amp;action=click_rentalHistory&amp;object_type=top_nav#rental_history">
            Order History
          </a>
          {this.renderSchedulePickupMenuOption()}
          {this.renderSettingsMenuOption()}
          {this.renderProSettingsMenuOption()}
          <a id="usr-reviews" href="/user/reviews" className="hd-reviews hd-write-review">
            {"Your Reviews"}
          </a>
          {this.renderClassicReferralsMenuOption()}
          <a href="/user/profile?nav_location=user_account&amp;action=click_myProfile&amp;object_type=top_nav#profile">
            Profile
          </a>
          <a id="credits" href="/user/credits">
            Credits
            {this.renderAccountCredits()}
          </a>
        </>
      );
    };

    openSignInModal = event => {
      const { browser: { isMobileViewport } = {}, showAuthModal } = this.props;

      if (isMobileViewport) {
        ActionLogger.logAction({
          objectType: "mobile_nav",
          action: "click_menu_item",
          category_name: "sign_in",
          category_depth: "1",
        });
      } else {
        ActionLogger.logAction({
          objectType: "top_nav",
          action: "click_nav_icon",
          icon_name: "sign_in",
        });
      }

      showAuthModal({
        callback: () => {},
        source: "myaccount-dropdown",
        view: Views.login,
        triggeredBy: HEAP_AUTH_TRIGGER_TYPES.CLICK_NAV_SIGN_IN,
      });

      // NW [EXPLANATION] 4/12/21: stop navigation to Login page
      event?.preventDefault();
    };

    renderSignInLink = () => {
      const { userData: { userProfileHasLoaded } = {} } = this.props;

      if (hasProfile(this.props.userData) || !userProfileHasLoaded) {
        return null;
      }

      return (
        <li id="user-menu-item-sign-in" className="user-menu-item">
          <a
            className="user-menu-item-title sign-in pop-lr-signin usr-signin"
            href="/account/login"
            onClick={this.openSignInModal}>
            <span className="user-menu-item-title-label">Sign in</span>
          </a>
        </li>
      );
    };

    render() {
      const templateFunctions = {
        renderAccountCredits: this.renderAccountCredits,
        renderAccountGreeting: this.renderAccountGreeting,
        renderAccountSubMenuOptions: this.renderAccountSubMenuOptions,
        renderSignInLink: this.renderSignInLink,
      };
      const templateProps = {
        ...this.props,
        hasProfile: hasProfile(this.props.userData),
        isSubscriber: isSubscriptionMember(this.props.userData),
      };
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...templateFunctions} {...templateProps} />;
    }
  };
}

function withConnectedUserMenuTemplate(WrappedComponent) {
  function mapStateToProps(state) {
    return {
      browser: state.browser,
      credits: state.credits,
      membershipState: state.membershipState,
      userData: state.userData,
      memberProgramChangeInfo: state.memberProgramChangeInfo,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      showAuthModal: options => dispatch(AuthActions.showAuthModal(options)),
      displayHomePickupOverlay: overlayType => dispatch(HomePickupActions.displayHomePickupOverlay(overlayType)),
    };
  }

  return compose(
    withFeatureFlags(
      Flags.PPS_REFERRALS,
      Flags.CLASSIC_REFERRAL_FOR_ACTIVE_MEMBER,
      Flags.CLASSIC_REFERRAL_FOR_INACTIVE_MEMBER,
      Flags.ENABLE_RESERVE_SCHEDULER
    ),
    withOnLoadFlagsAndExperiments(
      flagsAndExperimentNames.ENABLE_HOME_PICKUP_NEW_TAG,
      flagsAndExperimentNames.DISCO_SEARCH_AI_MVP_STOREFRONT_UX,
      flagsAndExperimentNames.SF_SACT_250_NOTIFY_ME,
      ...experimentsNamesValues // for now all experiments in this array are being tracked, will need to update once we move away from hardcoding these values
    ),
    connect(mapStateToProps, mapDispatchToProps),
    withUserMenuTemplate
  )(WrappedComponent);
}

export default withConnectedUserMenuTemplate;
export { withUserMenuTemplate as unconnectedWithUserMenuTemplate };
