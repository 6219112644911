// As we migrate to Next, I'd love to force all server-side query params to use this constant,
// so we can also use it to invalidate any unexpected query params (i.e. return a 400).
const QUERY_PARAMS = {
  CACHE_BUSTER: "_",
  DESTINATION: "destination",
  LOGIN: "login",
  LOGOUT: "logout",
  REGISTRATION: "registration",
  HELP_CENTER: {
    ANSWER: "a",
    INDEX: "index",
    LEGACY_HELP: "legacy_help",
    SEARCH_TERM: "s",
    SECTION: "g",
  },
  ACTION: "action",
};

export default QUERY_PARAMS;
