import _ from "underscore";
import ActionTypes from "actions/action-types.js";

const types = [ActionTypes.ADDRESS_ADD_ADDRESS, ActionTypes.ADDRESS_REPLACE_ADDRESSES];

const fn = function (state, action) {
  const addresses = state.addresses || []; // default state is [];

  switch (action.type) {
    case ActionTypes.ADDRESS_ADD_ADDRESS: {
      const newAddress = action.payload.address;
      return _.extend({}, state, { addresses: addresses.concat(newAddress) });
    }

    case ActionTypes.ADDRESS_REPLACE_ADDRESSES: {
      const newAddresses = action.payload.addresses;
      return _.extend({}, state, { addresses: newAddresses });
    }

    default:
      return _.extend({}, state, { addresses: addresses });
  }
};

export default {
  types: types,
  fn: fn,
};
