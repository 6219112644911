import React from "react";
import MoleculeAddress from "components/source/molecules/molecule-address";
import { submitShippingAddressAndReplaceAll } from "actions/shipping-step-actions";

export default class ExchangeAddressChangeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAddressId: this.props.initialAddressId,
    };
  }

  onAddressSelect = addressId => {
    this.setState({ selectedAddressId: addressId });
  };

  // returns new address object
  onSubmitAddressFormSuccess = address => {
    const { firstName, lastName, street1, street2, city, zoneCode, postalCode, phone } = address;

    // construct payload object to match expect format
    const payload = {
      vals: {
        firstName,
        lastName,
        street1,
        street2,
        city,
        zoneCode,
        postalCode,
        phone,
        default: false,
      },
    };

    this.props.dispatch(
      submitShippingAddressAndReplaceAll(payload, address => {
        this.props.useAddress(address.id);
      })
    );
  };

  render() {
    return (
      <div className="exchange-address-change-view">
        <MoleculeAddress
          addresses={this.props.addresses}
          onSelectAddress={this.onAddressSelect}
          useAddress={this.props.useAddress}
          showMakeDefaultCheckbox={false}
          selectedAddressId={this.state.selectedAddressId}
          onSubmitAddressFormSuccess={this.onSubmitAddressFormSuccess}
          sortBySelectedFirst={true}
          initialAddressId={this.props.initialAddressId}
        />
      </div>
    );
  }
}
