import { membershipFocusedFlows } from "rtr-constants";
import { setWithExpiry, readPromoValue } from "./url-param-reader";

export function configurePDPFlow(queryParams, expiryTime) {
  const membershipFlow = readPromoValue(
    membershipFocusedFlows.URL_PARAMETER_MEMBERSHIP_FLOW,
    membershipFocusedFlows.PDP_STORAGE_PARAM_NAME,
    queryParams
  );

  if (membershipFlow) {
    setWithExpiry(
      membershipFocusedFlows.URL_PARAMETER_MEMBERSHIP_FLOW,
      membershipFlow,
      expiryTime,
      membershipFocusedFlows.PDP_STORAGE_PARAM_NAME
    );
  }
}
