import classnames from "classnames";
import PropTypes from "prop-types";

const Price = ({ amount, className, currencyCode = "USD", currencyCodeClassName, showDecimal = true }) => {
  const config = {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "narrowSymbol",
  };
  if (!showDecimal) {
    config["minimumFractionDigits"] = 0;
    config["maximumFractionDigits"] = 0;
  }
  return (
    <p suppressHydrationWarning={true} className={className}>
      {`${new Intl.NumberFormat(void 0, config).format(parseFloat(amount))}`}
      <span className={classnames("ml-1 inline", currencyCodeClassName)}>{`${currencyCode}`}</span>
    </p>
  );
};

Price.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  currencyCode: PropTypes.string,
  currencyCodeClassName: PropTypes.string,
  showDecimal: PropTypes.bool,
};

export default Price;
