import _ from "underscore";
import ActionTypes from "actions/action-types.js";
import { checkout } from "rtr-constants";

const types = [
  ActionTypes.UPDATE_ORDER_PAYMENT_SUBMITTING,
  ActionTypes.UPDATE_ORDER_PAYMENT_SUCCESS,
  ActionTypes.UPDATE_ORDER_PAYMENT_FAILURE,
];

const fn = function (state, action) {
  const appliedPaymentMethodsState = state?.paymentMethodsForOrder ?? [];
  // TODO: I'm pretty sure this line is unnecessary.
  // we do not edit appliedPaymentMethodsState
  let nextAppliedPaymentMethodsState = appliedPaymentMethodsState.slice();
  let applyPaymentMethodError = state?.applyPaymentMethodError;

  switch (action.type) {
    case ActionTypes.UPDATE_ORDER_PAYMENT_SUBMITTING:
      // TODO: Handle this in a future high-level
      // checkout reducer, because it will affect
      // the UI of the page.
      applyPaymentMethodError = null;
      break;

    case ActionTypes.UPDATE_ORDER_PAYMENT_FAILURE: {
      // TODO: Handle this in a future high-level
      // checkout reducer, because it will affect
      // the UI of the page.
      const errors = action.payload?.responseText ?? [checkout.errorMessages.billingStep];

      applyPaymentMethodError = errors;

      break;
    }

    case ActionTypes.UPDATE_ORDER_PAYMENT_SUCCESS: {
      const nextOrder = action.payload;
      nextAppliedPaymentMethodsState = nextOrder.paymentMethods;
      break;
    }

    default:
      return state;
  }

  return _.extend({}, state, {
    paymentMethodsForOrder: nextAppliedPaymentMethodsState,
    applyPaymentMethodError,
  });
};

export default {
  types: types,
  fn: fn,
};
