import React from "react";
// library / vendor modules
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AtomPrimaryButton from "components/source/atoms/atom-primary-button";
import AtomHighlightTextButton from "components/source/atoms/atom-highlight-text-button";

// default functions for handling input in the subform
import { onChangeWithSuppressedErrors, updateSubformElement } from "helpers/subform-elements-helper";
import WarningIcon from "components/source/atoms/warning-icon";

// propTypes
const propTypesFromParent = {
  stateKey: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  additionalClassName: PropTypes.string,
  subformTitle: PropTypes.string,
  addressTooltip: PropTypes.string,
  hideErrors: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  submitButtonText: PropTypes.shape({
    default: PropTypes.string, // What the CTA button says in its base state
    submitting: PropTypes.string, // What the button says while subform is submitting
  }),
  updateElementAction: PropTypes.func.isRequired, // action creator called on user input
  submitSubformAction: PropTypes.func.isRequired, // action creator called on submit form
  clearFieldsAction: PropTypes.func.isRequired,
  shouldClearFieldsOnMount: PropTypes.bool,
  shouldClearFieldsOnUnmount: PropTypes.bool,
  optionalPreFieldContent: PropTypes.element,
  optionalPostFieldContent: PropTypes.element,
  onBackButton: PropTypes.func,
};

const propTypesFromMapStateToProps = {
  formIsValid: PropTypes.bool,
  errors: PropTypes.shape({
    form: PropTypes.string,
    message: PropTypes.string,
  }),
  isSubmitting: PropTypes.bool,
  // ex: { firstName: { isValid: true, value: "Mike" }, phone: { isValid: false, value: "" }, ... }
  elements: PropTypes.objectOf(
    PropTypes.shape({
      isValid: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      defaultValue: PropTypes.string,
    })
  ),
};

const propTypesFromMapDispatchToProps = {
  updateElement: PropTypes.func.isRequired,
  onChangeWithSuppressedErrors: PropTypes.func.isRequired,
  submitSubform: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
};

const propTypes = {
  ...propTypesFromParent,
  ...propTypesFromMapStateToProps,
  ...propTypesFromMapDispatchToProps,
};

function GenericSubform(FormFieldsComponent) {
  class GenericSubformComponent extends React.Component {
    constructor(props) {
      super(props);
      this.baseClassName = "subform";
    }

    subformClassName() {
      return this.props.additionalClassName
        ? `${this.baseClassName} ${this.props.additionalClassName}`
        : this.baseClassName;
    }

    subformTitle() {
      if (this.props.subformTitle) {
        return <h2 className="subform-title">{this.props.subformTitle}</h2>;
      }
    }

    addressTooltip() {
      if (this.props.addressTooltip) {
        return (
          <div className="address-tooltip">
            <WarningIcon />
            <p>{this.props.addressTooltip}</p>
          </div>
        );
      }
    }

    formLevelError() {
      const className = `${this.props.additionalClassName || this.baseClassName}__error`;
      const { errors: { form: formLevelError } = {} } = this.props;

      if (formLevelError && !this.props.hideErrors) {
        return (
          <div className={className}>
            <p>{formLevelError}</p>
          </div>
        );
      }
    }

    renderButtons() {
      return (
        <div className="generic-subform__buttons">
          {this.submitButton()}
          {this.backButton()}
        </div>
      );
    }

    backButton() {
      const { onBackButton } = this.props;

      if (onBackButton) {
        return (
          <AtomHighlightTextButton
            buttonText="Back"
            onClick={() => {
              onBackButton();
            }}
          />
        );
      }
    }

    submitButton() {
      if (!this.props.hideSubmitButton) {
        return (
          <AtomPrimaryButton
            buttonText={this.submitButtonText()}
            disabled={false}
            onClick={this.handleSubmit}
            dataTestId="submit-button"
          />
        );
      }
    }

    submitButtonText() {
      const {
        isSubmitting,
        submitButtonText: {
          submitting: submittingText = "Saving...",
          ["default"]: defaultText = "Save and Continue",
        } = {},
      } = this.props;

      return isSubmitting ? submittingText : defaultText;
    }

    formElementValues(elements) {
      const vals = {};
      Object.keys(elements).forEach(key => {
        vals[key] = elements[key].value;
      });

      // do additional work on the values before submit
      // e.g. put them in a format, set "company" to empty string, etc.
      let refinedValues;
      if (this.props.handleFormElementValuesCallback) {
        refinedValues = this.props.handleFormElementValuesCallback(vals);
      } else {
        refinedValues = vals;
      }

      return refinedValues;
    }

    handleSubmit = e => {
      e.preventDefault();

      const { action: url, elements, formIsValid, submitSubform, validateSubformBeforeSubmit } = this.props;

      const vals = this.formElementValues(elements);

      if (!formIsValid) {
        validateSubformBeforeSubmit();
      } else {
        submitSubform({
          url,
          vals,
        });
      }
    };

    optionalPreFieldContent() {
      return this.props.optionalPreFieldContent;
    }

    optionalPostFieldContent() {
      return this.props.optionalPostFieldContent;
    }

    UNSAFE_componentWillMount() {
      if (this.props.shouldClearFieldsOnMount) {
        this.props.clearFields();
      }
    }

    componentWillUnmount() {
      if (this.props.shouldClearFieldsOnUnmount) {
        this.props.clearFields();
      }
    }

    render() {
      return (
        <form className={this.subformClassName()}>
          {this.subformTitle()}

          {this.addressTooltip()}

          {this.formLevelError()}

          {this.optionalPreFieldContent()}

          <FormFieldsComponent
            stateKey={this.props.stateKey}
            updateElement={this.props.updateElement}
            onChangeWithSuppressedErrors={this.props.onChangeWithSuppressedErrors}
            elements={this.props.elements}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
          />

          {this.optionalPostFieldContent()}

          {this.renderButtons()}
        </form>
      );
    }
  }

  const mapStateToProps = (state, ownProps) => {
    const { [ownProps.stateKey]: subformState = {} } = state;

    return {
      formIsValid: subformState.isValid,
      errors: subformState.errors,
      isSubmitting: subformState.isSubmitting,
      elements: subformState.elements,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      submitSubform: values => {
        // submitSubformAction will be a callback that is dispatched in most cases
        ownProps.submitSubformAction(values);
      },
      validateSubformBeforeSubmit: () => {
        ownProps.validateBeforeSubmitAction();
      },
      onChangeWithSuppressedErrors: onChangeWithSuppressedErrors(dispatch, ownProps.updateElementAction),
      updateElement: updateSubformElement(dispatch, ownProps.updateElementAction),
      clearFields: () => {
        dispatch(ownProps.clearFieldsAction());
      },
    };
  };

  GenericSubformComponent.propTypes = propTypes;

  return connect(mapStateToProps, mapDispatchToProps)(GenericSubformComponent);
}

export default GenericSubform;
