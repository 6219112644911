import React from "react";

const WarningIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        key="path1"
        d="M22 20C22.4142 20 22.75 20.3358 22.75 20.75L22.75 26.25C22.75 26.6642 22.4142 27 22 27C21.5858 27 21.25 26.6642 21.25 26.25L21.25 20.75C21.25 20.3358 21.5858 20 22 20Z"
        fill="black"
      />
      <path
        key="path2"
        d="M23 17.75C23 18.3023 22.5523 18.75 22 18.75C21.4477 18.75 21 18.3023 21 17.75C21 17.1977 21.4477 16.75 22 16.75C22.5523 16.75 23 17.1977 23 17.75Z"
        fill="black"
      />
      <path
        key="path3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C16.4772 12 12 16.4772 12 22C12 27.5228 16.4772 32 22 32C27.5228 32 32 27.5228 32 22C32 16.4772 27.5228 12 22 12ZM22 13.5C17.3056 13.5 13.5 17.3056 13.5 22C13.5 26.6944 17.3056 30.5 22 30.5C26.6944 30.5 30.5 26.6944 30.5 22C30.5 17.3056 26.6944 13.5 22 13.5Z"
        fill="black"
      />
    </svg>
  );
};

export default WarningIcon;
