// REFACTOR TICKET: https://renttherunway.jira.com/browse/QU-10870
import $ from "clients/RawClient";
import { createAction } from "redux-actions";

import ActionTypes from "./action-types";
import { analytics, membershipActions, membershipTriggeredModalsCMSKeys } from "rtr-constants";
import { displayModal } from "actions/shared-actions";
import { fetchNotification } from "actions/subscription-notification-center-actions";
import ItemsThatAreUpdatingActions from "actions/items-that-are-updating-actions";
import MembershipUpgradesHelpers from "helpers/membership-upgrades-helpers";
import MembershipHelpers from "helpers/membership-helpers";
import MembershipStateActionsHelpers from "helpers/membership-state-actions-helpers";
import ActionLogger from "action-logger";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";
import CEBagActions from "actions/ce-bag-actions";

// Use the appropriate actions method to update membership state.
// This `update` is the reusable ajax request that takes the action.
const update = (actionType, params = {}, onDone = () => {}, onFail = () => {}, onAlways = () => {}) => {
  const action = {
    ...params,
    action: actionType,
  };
  const actionListData = { actions: [action] };

  return dispatch => {
    return $.ajax({
      url: "/membership_state",
      type: "PATCH",
      data: JSON.stringify(actionListData),
      contentType: "application/json",
    })
      .then(
        data => {
          onDone();
          dispatch(updateMembershipState(data));
          dispatch(fetchNotification());
        },
        () => {
          onFail();
        }
      )
      .finally(() => {
        onAlways();
      });
  };
};

// Clean these two methods up they do the same thing under the hood.
const updateMultiple = (actionList, onDone = () => {}, onFail = () => {}, onAlways = () => {}) => {
  const actionListData = { actions: actionList };
  return dispatch => {
    dispatch(submittingMembershipActions(true));
    $.ajax({
      url: "/membership_state",
      type: "PATCH",
      data: JSON.stringify(actionListData),
      contentType: "application/json",
    })
      .then(
        membershipState => {
          dispatch(
            updateMembershipState({
              ...membershipState,
              lastActions: actionList,
            })
          );
          onDone();
        },
        (_jqXHR, _textStatus, errorThrown) => {
          dispatch(
            updateMembershipStateFail({
              error: errorThrown,
            })
          );
          onFail();
        }
      )
      .finally(() => {
        dispatch(submittingMembershipActions(false));
        onAlways();
      });
  };
};

export const membershipStateChangeTierSuccess = createAction(ActionTypes.MEMBERSHIP_STATE_CHANGE_TIER_SUCCESS);
export const membershipStateLoadSuccess = createAction(ActionTypes.MEMBERSHIP_STATE_LOAD_SUCCESS);
export const pauseCtaLoading = createAction(ActionTypes.PAUSE_CTA_LOADING);
export const cancelPostPending = createAction(ActionTypes.CANCEL_POST_PENDING);
export const updateMembershipState = createAction(ActionTypes.UPDATE_MEMBERSHIP_STATE);
export const updateMembershipStateFail = createAction(ActionTypes.UPDATE_MEMBERSHIP_STATE_FAIL);
export const submittingMembershipActions = createAction(ActionTypes.MEMBERSHIP_STATE_SUBMITTING_ACTIONS);

export function fetchMembershipState(cached = true) {
  return dispatch => {
    $.ajax({
      url: `/membership_state/${cached}`,
      type: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then(data => {
      dispatch(membershipStateLoadSuccess(data || {}));
    });
  };
}

export function submitStartSwap(onDone, onFail, onAlways) {
  const actions = [
    {
      action: membershipActions.START_SWAP,
    },
  ];
  return updateMultiple(actions, onDone, onFail, onAlways);
}

export function submitOnboardingSurvey(onDone) {
  return update(membershipActions.SUBMIT_ONBOARDING_SURVEY, {}, onDone);
}

export function updateMembershipShipmentAddress(shipmentAddressId, onDone) {
  const params = { shipmentAddressId };
  return update(membershipActions.SHIPMENT_ADDRESS_CHANGE, params, onDone);
}

export function upgradeMembershipBaseSlots(membershipType, futureBaseSlotCount, effectiveOn, membershipTierRevisionId) {
  const params = MembershipUpgradesHelpers.getMembershipUpgradeActionAttributes(
    membershipType,
    futureBaseSlotCount,
    effectiveOn,
    membershipTierRevisionId
  );
  return update(membershipActions.UPGRADE_BASE_SLOTS, params);
}

export function downgradeMembershipBaseSlots(
  membershipType,
  futureBaseSlotCount,
  effectiveOn,
  membershipTierRevisionId
) {
  const params = MembershipUpgradesHelpers.getMembershipUpgradeActionAttributes(
    membershipType,
    futureBaseSlotCount,
    effectiveOn,
    membershipTierRevisionId
  );
  return update(membershipActions.DOWNGRADE_BASE_SLOTS, params);
}

export function undoFutureCancel(onDone, onFail, onAlways) {
  return update(membershipActions.UNDO_CANCEL, {}, onDone, onFail, onAlways);
}

export function undoFuturePause(onDone) {
  return update(membershipActions.UNDO_PAUSE, {}, onDone);
}

export function futurePause(params, onDone, onFail, onAlways) {
  return update(membershipActions.PAUSE, params, onDone, onFail, onAlways);
}

export function futureCancel(params, onDone, onFail, onAlways) {
  return update(membershipActions.CANCEL, params, onDone, onFail, onAlways);
}

/*
 * Mark items as RETURN_PROMISED
 * Other side effects include updating the `itemsThatAreUpdating` state with the bookingIds of the marked items.
 * This is to let other parts of the app know these items are being processed somewhere so don't send more actions
 * regarding those items.
 * On network failure it triggers the Oops modal
 *
 * @param {Number|Number[]} bookingIds - The items to be marked
 * @returns {function()} - returns a wrapper around `dispatch` and the fetch
 * @throws Will throw if input is invalid (i.e. null/undefined)
 */
export function markReturnPromise(bookingIds, actionLoggerData) {
  if (!bookingIds) {
    throw new Error("Invalid argument to markReturnPromise");
  }

  return (dispatch, getState) => {
    const bookingIdsToPost = MembershipStateActionsHelpers.validateItemBookingIds(bookingIds);
    const { oops } = membershipTriggeredModalsCMSKeys;
    const state = getState();
    const membershipState = MembershipHelpers.getMembershipState(state);
    const { id, membershipId } = membershipState;

    ActionLogger.logAction({
      object_type: analytics.OBJECT_TYPE.SHOPPING_BAG,
      action: analytics.SWAP.RETURN_PROMISE,
      membership_id: membershipId,
      user_id: id,
      url: window.location.href,
      state: analytics.MEMBERSHIP_LOCATIONS.PICK,
      page_type: actionLoggerData.pageType,
    });

    dispatch(ItemsThatAreUpdatingActions.addUpdateItems(bookingIdsToPost));

    return $.ajax({
      url: "/item_return_promise_collections",
      type: "POST",
      data: {
        bookingIds: bookingIdsToPost,
      },
    })
      .then(
        data => {
          dispatch(updateMembershipState(data));
          dispatch(CEBagActions.get());
          return data;
        },
        () => dispatch(displayModal(oops))
      )
      .finally(() => dispatch(ItemsThatAreUpdatingActions.removeUpdateItems(bookingIdsToPost)));
  };
}

/**
 * Mark items as AT_HOME with item kept.
 * Other side effects include updating the `itemsThatAreUpdating` state with the bookingIds of the marked items.
 * This is to let other parts of the app know these items are being processed somewhere so don't send more actions
 * regarding those items.
 * On network failure it triggers the Oops modal
 *
 * @param {Number} bookingId - The item to be marked
 * @returns {function()} - returns a wrapper around `dispatch` and the fetch
 * @throws Will throw if input is invalid (i.e. null/undefined)
 */
export function markItemKept(bookingId) {
  if (!bookingId) {
    throw new Error("Invalid argument to markItemKept");
  }

  return dispatch => {
    const updatingItems = [bookingId];

    dispatch(ItemsThatAreUpdatingActions.addUpdateItems(updatingItems));

    const { oops } = membershipTriggeredModalsCMSKeys;
    return $.ajax({
      url: "/membership_kept_items",
      type: "POST",
      data: { bookingId },
    })
      .then(
        data => {
          dispatch(updateMembershipState(data));
          dispatch(CEBagActions.get());
        },
        () => dispatch(displayModal(oops))
      )
      .finally(() => dispatch(ItemsThatAreUpdatingActions.removeUpdateItems(updatingItems)));
  };
}

export function selfServiceCancel(params, onDone) {
  return dispatch => {
    dispatch(cancelPostPending(true));
    return $.ajax({
      url: "/membership_state/membership_cancel",
      type: "POST",
      headers: {
        Accept: "application/json",
      },
      data: params,
    })
      .then(
        data => {
          dispatch(updateMembershipState(data));
          onDone(data);
        },
        () => dispatch(displayModal(membershipTriggeredModalsCMSKeys.oops))
      )
      .finally(() => {
        dispatch(cancelPostPending(false));
      });
  };
}

export function changeMembershipTier(effectiveOn, endsOn, futureTierId) {
  return function (dispatch) {
    return createAjaxAuthRetry({
      url: "/membership_state/change_tier",
      type: "POST",
      headers: {
        Accept: "application/json",
      },
      contentType: "application/json",
      data: JSON.stringify({
        effectiveOn,
        endsOn,
        futureTierId,
      }),
    }).then(
      response => {
        dispatch(membershipStateChangeTierSuccess(response?.data));

        return response;
      },
      error => {
        dispatch(displayModal(membershipTriggeredModalsCMSKeys.oops));

        throw error;
      }
    );
  };
}

export function undoFutureChangeMembershipTier() {
  return function (dispatch) {
    return createAjaxAuthRetry({
      type: "DELETE",
      url: "/membership_state/change_tier",
    }).then(
      response => {
        dispatch(membershipStateChangeTierSuccess(response?.data));

        return response;
      },
      error => {
        dispatch(displayModal(membershipTriggeredModalsCMSKeys.oops));

        throw error;
      }
    );
  };
}

export function pauseCtaButtonLoading(cta = null) {
  return function (dispatch) {
    dispatch(pauseCtaLoading(cta));
  };
}

// changes the shipments per month/slot count in membership settings
export function changeMembershipPlan(membershipTierId, newSlotCount, nextBillingDate, promoCode) {
  return function (dispatch) {
    return createAjaxAuthRetry({
      url: "/membership_state/change_plan",
      type: "POST",
      data: {
        membershipTierId,
        newSlotCount,
        effectiveOn: nextBillingDate,
        promoCode: promoCode,
      },
    }).then(
      response => {
        dispatch(fetchMembershipState(false));

        return response;
      },
      error => {
        dispatch(displayModal(membershipTriggeredModalsCMSKeys.oops));

        throw error;
      }
    );
  };
}
