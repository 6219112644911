import React, { useMemo } from "react";
import { connect } from "react-redux";
import { ThirdPartyScript } from "./third-party-scripts/thirdPartyScript";
import PropTypes from "prop-types";
import BranchIo from "./third-party-scripts/branchIo";
import OneTrust from "./third-party-scripts/oneTrust";
import Signifyd from "./third-party-scripts/signifyd";
import Tatari from "./third-party-scripts/tatari";
import Usablenet from "./third-party-scripts/usablenet";
import { findPageMetadata } from "helpers/page-metadata-helper";
import { getVideoPosterLinks } from "helpers/cms-container-page-helpers";
import { default as NextHead } from "next/head";
import { useCms } from "./CmsContext";
import { useFeatureFlags } from "./FeatureFlagsContext";
import { pageTypes } from "rtr-constants";
import Heap from "./third-party-scripts/heap";
import FacebookSDK from "./third-party-scripts/facebookSdk";
import Attentive from "./third-party-scripts/attentive";
import CdnEmbeds from "./third-party-scripts/cdnEmbeds";
import GTM from "./third-party-scripts/gtm";
import { ConvergePixel } from "./third-party-scripts/converge";

const ImagePreloads = ({ links = [] }) => {
  return (
    <>
      {links.map((link, i) => {
        return <link rel="preload" href={link} as="image" key={i} />;
      })}
    </>
  );
};

ImagePreloads.propTypes = {
  links: PropTypes.arrayOf(PropTypes.string),
};

const PreFetch = ({ cdnHost, cdnImageHost }) => {
  const cdns = [cdnHost, cdnImageHost].filter(cdn => typeof cdn === "string" && cdn.length > 0) || [];

  return (
    <>
      {cdns.map((cdn, i) => (
        <link key={i} rel="dns-prefetch" href={cdn}></link>
      ))}
    </>
  );
};

PreFetch.propTypes = {
  cdnHost: PropTypes.string,
  cdnImageHost: PropTypes.string,
};

const Head = ({ componentHeadData, disableAnalytics = false, preloadImageSrcs, publicEnv = {} }) => {
  const cms = useCms();
  const flags = useFeatureFlags() || [];
  const metadata = useMemo(() => findPageMetadata(componentHeadData, cms), [componentHeadData, cms]);

  const shouldShowAppleBanner =
    metadata.pageName !== pageTypes.PAID_LANDING &&
    metadata.pageName !== pageTypes.NEW_CHECKOUT &&
    flags.includes("apple_smart_banner");

  //Adding to header to ensure that the organization details are available on every page for search engines to recognize consistently
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Rent The Runway",
    "url": "https://www.renttherunway.com/",
    "logo": "https://www.renttherunway.com/images/sprites/reb/svg/rtr_logo.svg",
  };

  return (
    <>
      <NextHead>
        <title>{metadata.siteTitle}</title>
        <meta name="description" content={metadata.siteDescription}></meta>
        <meta charSet="utf-8" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/mobile-icons/apple/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="192x192" href="/images/mobile-icons/apple/apple-touch-icon-192x192.png" />
        <link rel="apple-touch-icon" sizes="512x512" href="/images/mobile-icons/apple/apple-touch-icon-512x512.png" />

        <meta name="keywords" content={metadata.keywords || ""} />
        <meta name="facebook-domain-verification" content="wzp8ahbv04gsh1x52x97evab84j9rl" />
        <meta name="google-site-verification" content="9LS9sK1fnS0eCUgAQzVRbEQHoSiby2DwPn_SkHksVhU" />

        {metadata.robots ? <meta name="robots" content={metadata.robots}></meta> : <></>}
        {metadata.canonicalLink ? <link rel="canonical" href={metadata.canonicalLink}></link> : <></>}

        <meta property="og:site_name" content="Rent the Runway" />
        <meta property="og:type" content="website" />

        {metadata.openGraphImage}

        {metadata.openGraphTitle ? <meta property="og:title" content={metadata.openGraphTitle} /> : <></>}
        {metadata.openGraphDescription ? (
          <meta property="og:description" content={metadata.openGraphDescription} />
        ) : (
          <></>
        )}
        {metadata.openGraphUrl ? <meta property="og:url" content={metadata.openGraphUrl} /> : <></>}

        <meta property="fb:app_id" content={publicEnv.oauthFacebookAppId} />
        <meta property="fb:pages" content={publicEnv.fbPagesId} />

        {metadata.sailthru}

        <meta name="uid" content="0" />
        {shouldShowAppleBanner ? (
          <meta
            name="apple-itunes-app"
            content={`app-id=${publicEnv.mobileIphoneAppId}, affiliate-data="pt=451666&ct=mweb-home&mt=8”`}></meta>
        ) : (
          <></>
        )}

        {metadata.structuredData.length && metadata.structuredData}

        {componentHeadData.isErrorPage && <link rel="stylesheet" href="/error.css" type="text/css" />}

        <PreFetch cdnImageHost={publicEnv.cdnImageHost} cdnHost={publicEnv.cdnHost} />

        <ImagePreloads links={preloadImageSrcs} />

        {metadata.branchDeeplinkUrl && (
          <meta name="branch:deeplink:$deeplink_path" content={metadata.branchDeeplinkUrl} />
        )}

        {metadata.extraHead}
      </NextHead>

      <CdnEmbeds />

      {/* Script and these 3rd party scripts will all be appended to the Head via using the Next.js Script tag
          When I tried to embed them inside NextHead above, our custom hooks were returning null (e.g. useFeatureFlags)
          I think because putting them inside the head took them out of the <Provider> we set in _app
      */}
      <ThirdPartyScript
        name="buggy"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        (function() {function buggy() { function detect() { var a = [0, 1]; a.reverse(); return a[0] === 0; } return detect() || detect(); } if(!buggy()) return; Array.prototype._reverse = Array.prototype.reverse; Array.prototype.reverse = function reverse() {if (Array.isArray(this)) this.length = this.length;return Array.prototype._reverse.call(this);};var nonenum = {enumerable: false};Object.defineProperties(Array.prototype, {_reverse: nonenum,reverse: nonenum,});})();
        `,
        }}
      />

      {flags.includes("onetrust_consent_management") && <OneTrust />}

      {/* <!-- Attentive Script --> */}
      <Attentive />

      {/* JSON-LD Schema for Organization */}
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationSchema) }} />

      {!disableAnalytics && (
        <>
          <GTM />

          {/* <!-- Heap tag  --> */}
          <Heap />

          {/* <!-- Signifyd tag  --> */}
          {flags.includes("signifyd_javascript_tag") && <Signifyd />}
          {/* <!-- Converge Integration --> */}
          <ConvergePixel />
          {/* <!-- Branch io Integration --> */}
          {flags.includes("branch_io_integration") && <BranchIo />}

          {/* <!-- Tatari Integration --> */}
          {flags.includes("tatari") && <Tatari />}

          {flags.includes("accessibility_script_useablenet") && <Usablenet />}

          {/* <!-- Facebook SDK --> */}
          <FacebookSDK />
        </>
      )}
    </>
  );
};

Head.propTypes = {
  componentHeadData: PropTypes.object,
  disableAnalytics: PropTypes.bool,
  preloadImageSrcs: PropTypes.arrayOf(PropTypes.string),
  publicEnv: PropTypes.object,
};

const mapStateToProps = state => ({
  preloadImageSrcs: getVideoPosterLinks(state.contentModules, state.browser?.isMobileViewport),
  publicEnv: state.publicEnv,
});

export default connect(mapStateToProps)(Head);
