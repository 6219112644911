import { differenceInCalendarDays, format, parseISO } from "date-fns";
import { dateFnsFormats } from "rtr-constants";

const getPurchaseTypeFromGroupInvoice = groupInvoice => {
  const firstOrderItem = groupInvoice.lineItems?.[0].orderItem;

  if (!firstOrderItem) {
    return "other";
  }

  const mappings = {
    rtrUpdateSubscription: "subscription",
    classic: "rental",
    clearance: "retail",
    electiveSale: "retail",
    bulk: "retail",
    rtrUpdate: "membership_items",
  };

  return mappings[firstOrderItem.subType] ?? "other";
};

const getTierIdFromInvoice = groupInvoice => {
  return groupInvoice.lineItems[0].orderItem.membershipTierId;
};

const getRentalDurationFromInvoice = groupInvoice => {
  const groupDetails = groupInvoice.orderGroup ?? {};
  const { startDate, endDate } = groupDetails;

  if (!startDate || !endDate) {
    return "";
  }

  const parsedRentBegin = parseISO(startDate);
  const parsedRentEnd = parseISO(endDate);
  const duration = differenceInCalendarDays(parsedRentEnd, parsedRentBegin) + 1;

  return `${duration} days`;
};

const getRetailPurchaseTypeFromInvoice = groupInvoice => {
  const subType = groupInvoice.lineItems?.[0].orderItem.subType;

  //We want to pass KIF for electiveSale type, otherwise just use the subtype (clearance/bulk)
  if (subType === "electiveSale") {
    return "KIF";
  } else {
    return subType;
  }
};

const getSlotUpgradeDetailsFromInvoice = groupInvoice => {
  const subtotal = groupInvoice.totals?.find(t => t.type === "sub")?.amount;

  //If a user has upgraded slot count there will be a charge on the order
  const hasUpgrade = subtotal !== "USD 0.00";

  return hasUpgrade ? "spot_upgrade" : "no_upgrade";
};

const getGroupPurchaseDetails = groupInvoice => {
  const purchaseType = getPurchaseTypeFromGroupInvoice(groupInvoice);
  let purchaseTypeDetail;

  switch (purchaseType) {
    case "subscription":
      purchaseTypeDetail = getTierIdFromInvoice(groupInvoice);
      break;
    case "rental":
      purchaseTypeDetail = getRentalDurationFromInvoice(groupInvoice);
      break;
    case "retail":
      purchaseTypeDetail = getRetailPurchaseTypeFromInvoice(groupInvoice);
      break;
    case "membership_items":
      purchaseTypeDetail = getSlotUpgradeDetailsFromInvoice(groupInvoice);
      break;
    default:
      purchaseTypeDetail = "";
  }

  return { purchaseType, purchaseTypeDetail };
};

/**
 * Parse order preview invoices into an array of logging data for Heap
 * @param previewInvoice
 * @param promo - a promo code string
 * @param optionalPurchaseDetails - purchase details for non-standard orders (i.e. ad hoc membership payments)
 * @returns - an array of logging hashes to be sent to Heap
 */
export const buildPurchaseEvents = (previewInvoice, promo, optionalPurchaseDetails) => {
  //If there are no group invoices (this should not happen), assume there is nothing to log and return an empty list
  if (!Array.isArray(previewInvoice?.groups)) {
    return [];
  }

  const getTotalLineItemFromInvoice = (invoice, totalType) => {
    if (!invoice || !Array.isArray(invoice.totals)) {
      return "";
    }

    return invoice.totals.find(total => total.type === totalType)?.amount ?? "";
  };

  const orderId = previewInvoice.orderId;
  const date = format(Date.now(), dateFnsFormats.YYYY_MM_DD);
  const orderSubtotal = getTotalLineItemFromInvoice(previewInvoice, "discountedSub");
  const discountAmount = getTotalLineItemFromInvoice(previewInvoice, "promo");
  const orderTotal = getTotalLineItemFromInvoice(previewInvoice, "total");
  //We get whatever the user entered in the UI, which can have any casing
  const sanitizedPromo = promo ? promo.toUpperCase() : "";

  return previewInvoice.groups.map(g => {
    const purchaseDetails = optionalPurchaseDetails ?? getGroupPurchaseDetails(g);
    return {
      orderId,
      date,
      orderSubtotal,
      discountCode: sanitizedPromo,
      discountAmount,
      orderTotal,
      ...purchaseDetails,
    };
  });
};
