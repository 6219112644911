import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
/*
 * A dropdown whose label text first appears as though placeholder text in the input, the rises up when the user focuses on the element
 * This element can be in the following states:
 * 1. starting state: state on page load; user has not interacted with the input or label
 * 2. focus state: user has tapped/clicked on input or label and there are no errors
 * 3. valid state: input no longer has focus and there is a selected option
 * 4. error state: input no longer has focus and there is an error
 *
 */
class AtomAnimatedDropdown extends React.Component {
  static propTypes = {
    additionalClassName: PropTypes.string,
    autoComplete: PropTypes.string,
    error: PropTypes.string,
    id: PropTypes.string.isRequired,
    isValid: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    placeholderOption: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.className = "animated-input-wrapper";
  }

  selectClass() {
    if (this.props.error) {
      return "error";
    } else if (this.props.isValid) {
      return "valid";
    } else {
      return "";
    }
  }

  labelText() {
    // In a few cases where we have an error but no
    // error text associated with it, let's render an
    // error state without an error label
    if (this.props.error) {
      return this.props.error;
    } else {
      return this.props.label;
    }
  }

  placeholderOption() {
    // Check specifically for undefined so that we can still create a placeholder option with a blank label
    // (by passing empty string as the value for this.props.placeholderOption)
    if (typeof this.props.placeholderOption === "undefined") {
      return <option value="" hidden disabled />;
    } else {
      return <option value="">{this.props.placeholderOption}</option>;
    }
  }

  render() {
    const wrapperClass = classnames(this.className, {
      [this.props.additionalClassName]: this.props.additionalClassName,
      disabled: this.props.disabled,
    });

    return (
      <div className={wrapperClass}>
        <select
          className={this.selectClass()}
          id={this.props.id}
          aria-label={this.props.name}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          autoComplete={this.props.autoComplete}
          disabled={this.props.disabled}>
          {this.placeholderOption()}

          {this.props.options.map((option, i) => {
            return (
              <option value={option.value} key={i} disabled={option.disabled}>
                {option.label}
              </option>
            );
          })}
        </select>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={this.props.id} placeholder={this.labelText()} />
      </div>
    );
  }
}

export default AtomAnimatedDropdown;
