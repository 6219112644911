import _ from "underscore";
import ActionTypes from "actions/action-types.js";

const types = [
  ActionTypes.APPLY_PROMO_SUBMITTING,
  ActionTypes.APPLY_PROMO_SUCCESS,
  ActionTypes.APPLY_PROMO_FAILURE,
  ActionTypes.REMOVE_PROMO_SUBMITTING,
  ActionTypes.REMOVE_PROMO_SUCCESS,
  ActionTypes.REMOVE_PROMO_FAILURE,
];

/*
 * Reducer for adding and remove promos for an order.
 * This object is on the `promo` key of the Redux store (state.promo).
 * {
 *   isSubmitting: <boolean>,
 *   appliedCode: <string or "">,
 *   error: {
 *     hasError: <boolean>,
 *     message: <string or "">
 *   }
 * }
 */

// Ignoring jshint's opinion re "regular parameters should not come after default parameters."
// This is The Redux Way to write these functions, so we should follow the library's docs.
const isSubmitting = (state = false, action) => {
  // jshint ignore: line
  if (action.type === ActionTypes.APPLY_PROMO_SUBMITTING || action.type === ActionTypes.REMOVE_PROMO_SUBMITTING) {
    return true;
  } else if (_.contains(types, action.type)) {
    return false;
  } else {
    return state;
  }
};

const appliedCode = (state = "", action) => {
  // jshint ignore:line
  switch (action.type) {
    case ActionTypes.APPLY_PROMO_SUCCESS:
    case ActionTypes.APPLY_PROMO_FAILURE:
    case ActionTypes.REMOVE_PROMO_FAILURE:
      return action?.payload?.code ?? "";
    case ActionTypes.REMOVE_PROMO_SUCCESS:
      return "";
    default:
      return state;
  }
};

const error = (state = { hasError: false, message: "" }, action) => {
  // jshint ignore: line
  switch (action.type) {
    case ActionTypes.APPLY_PROMO_FAILURE: // falls-through
    case ActionTypes.REMOVE_PROMO_FAILURE: {
      const message = action?.payload?.message ?? "Something went wrong. Please try again.";
      return {
        hasError: true,
        message,
      };
    }
    case ActionTypes.APPLY_PROMO_SUCCESS: // falls-through
    case ActionTypes.REMOVE_PROMO_SUCCESS:
      return {
        hasError: false,
        message: "",
      };
    default:
      return state;
  }
};

const fn = function (state, action) {
  const promoState = state.promo || {};

  const nextPromoState = {
    isSubmitting: isSubmitting(promoState.isSubmitting, action),
    appliedCode: appliedCode(promoState.appliedCode, action),
    error: error(promoState.error, action),
  };

  return _.extend({}, state, {
    promo: nextPromoState,
  });
};

export default {
  types: types,
  fn: fn,
};
