import { forwardRef, useState } from "react";
import PropTypes from "prop-types";

import {
  getFastlyOptimizedProductImageUrl,
  getProductImageAltText,
  getProductImageUrl,
  ProductImageSize,
} from "../../../helpers/product-image-helpers";
import RtrImage from "./rtr-image";
import { productImageOrientationPropType } from "../../propTypes/product-prop-type";
import { FetchPriority } from "rtr-constants";

const ProductImage = forwardRef(
  (
    {
      altText,
      className,
      imageGroup,
      imageSize,
      fallbackSrc,
      onClick,
      product,
      sizes,
      srcSetSizes,
      style,
      loading = "eager",
      fetchPriority,
      draggable = true,
    },
    ref
  ) => {
    const [hasErrorLoading, setErrorLoading] = useState(false);
    const alt = altText || getProductImageAltText(product?.displayName, product?.designer?.displayName);

    if (!imageGroup && !fallbackSrc) return null;

    const x1080ImagePath = imageGroup?.[ProductImageSize.x1080.path];
    const queryOptimizedImage = Boolean(x1080ImagePath);

    const fastlyOptimizedImageHref = getFastlyOptimizedProductImageUrl({
      src: x1080ImagePath || fallbackSrc,
      width: imageSize?.xSize,
    })?.href;

    const responsiveFastlyOptimizedImageHrefs = () => {
      if (!Array.isArray(srcSetSizes) || srcSetSizes.length === 0) return null;

      return srcSetSizes
        .map(
          imageSize =>
            `${
              getFastlyOptimizedProductImageUrl({
                src: x1080ImagePath || fallbackSrc,
                width: imageSize?.xSize,
              })?.href
            } ${imageSize?.xSize}w`
        )
        .join(", ");
    };

    const fastlyUnoptimizedImageHref = getProductImageUrl(imageGroup?.[imageSize.path] || fallbackSrc)?.href;

    const responsiveFastlyUnoptimizedImageHrefs = () => {
      if (!Array.isArray(srcSetSizes) || srcSetSizes.length === 0) return null;

      return srcSetSizes
        .map(
          imageSize => `${getProductImageUrl(imageGroup?.[imageSize.path] || fallbackSrc)?.href} ${imageSize?.xSize}w`
        )
        .join(", ");
    };

    if (queryOptimizedImage) {
      return (
        <RtrImage
          alt={alt}
          className={className}
          itemProp={"image"}
          onClick={onClick}
          onError={() => setErrorLoading(true)}
          ref={ref}
          src={hasErrorLoading ? fastlyUnoptimizedImageHref : fastlyOptimizedImageHref}
          srcSet={hasErrorLoading ? responsiveFastlyUnoptimizedImageHrefs() : responsiveFastlyOptimizedImageHrefs()}
          sizes={sizes}
          style={style}
          loading={loading}
          fetchpriority={fetchPriority}
          draggable={draggable}
        />
      );
    }

    return (
      <RtrImage
        alt={alt}
        className={className}
        itemProp={"image"}
        onClick={onClick}
        ref={ref}
        src={fastlyUnoptimizedImageHref}
        style={style}
        loading={loading}
        fetchpriority={fetchPriority}
        draggable={draggable}
      />
    );
  }
);

ProductImage.displayName = "ProductImage";

ProductImage.propTypes = {
  altText: PropTypes.string,
  className: PropTypes.string,
  draggable: PropTypes.bool,
  imageGroup: productImageOrientationPropType,
  imageSize: PropTypes.instanceOf(ProductImageSize),
  fallbackSrc: PropTypes.string,
  onClick: PropTypes.func,
  product: PropTypes.object,
  sizes: PropTypes.string,
  srcSetSizes: PropTypes.arrayOf(PropTypes.object),
  style: PropTypes.object,
  loading: PropTypes.string,
  fetchPriority: PropTypes.oneOf(Object.values(FetchPriority)),
};

export default ProductImage;
