import { SessionStorage } from "site/localStorage";

export function setWithExpiry(key, value, expiryTime, storageName) {
  const sessionStorageClient = new SessionStorage(storageName);

  const now = new Date();

  const paramObj = {
    value: value,
    expiry: now.getTime() + expiryTime * 6000,
  };
  sessionStorageClient.set(key, paramObj);
}

export function getLocalParam(key, storageName) {
  const sessionStorageClient = new SessionStorage(storageName);

  if (sessionStorageClient.get(key)) {
    const storedParam = sessionStorageClient.get(key);
    const now = new Date();
    if (now.getTime() > storedParam.expiry) {
      sessionStorageClient.remove(key);
      return;
    }

    return storedParam.value;
  }
}

export function readPromoValue(key, storageName, queryParams) {
  if (queryParams) {
    return queryParams[key] || getLocalParam(key, storageName) || "";
  }
}
