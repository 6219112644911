import { specialPrices, costTypes, Annotations, discountCode } from "rtr-constants";
import { usdPriceIntoInt } from "./PricingHelper";

/*
 * A few helper functions for turning data
 * in the previewInvoice object into front-end gold.
 */

const findAmountForType = (listOfCharges, costType) => {
  const costObject = listOfCharges.find(charge => charge?.type === costType);
  return costObject?.amount;
};

const formattedPrice = (price, replaceZeroAmount = true) => {
  if (!price) {
    return null;
  }
  if (replaceZeroAmount && price === "USD 0.00") {
    return specialPrices.free;
  }
  if (typeof price === "number") {
    return `$${price.toFixed(2)}`;
  }
  if (price.indexOf("USD -") > -1) {
    return price.replace("USD -", "- $");
  }
  return price.replace("USD ", "$");
};

export const formatAbsolutePriceAsFloat = price => {
  if (!price) {
    return null;
  }
  if (price === "USD 0.00") {
    return parseFloat(0.0).toFixed(2);
  }

  if (price.indexOf("USD -") > -1) {
    return parseFloat(price.replace("USD -", "")).toFixed(2);
  }

  return parseFloat(price.replace("USD ", "")).toFixed(2);
};

const priceStringIntoFloat = price => {
  if (!price) {
    return;
  }

  const priceAsString = price.replace("USD ", "");
  return parseFloat(priceAsString);
};

// used for large, 4+ figure usd values, formatted as a string
const priceTotalValue = price => {
  if (!price) {
    return;
  }

  const priceAsInt = usdPriceIntoInt(price);
  return "$" + priceAsInt.toLocaleString("en-US");
};

const getSumOfLineItemCharges = (lineItems, chargeType) => {
  if (!lineItems) {
    return 0;
  }
  const items = lineItems.map(item => item?.charges);
  const lineItemCharges = items.reduce((ray, x) => ray.concat(x), []);

  return lineItemCharges
    .filter(charge => charge.type === chargeType)
    .map(charge => priceStringIntoFloat(charge?.amount))
    .reduce((sumOfItemCharges, nextItemCharge) => sumOfItemCharges + nextItemCharge, 0)
    .toFixed(2);
};

const baseSlotUpgradeTotal = membershipBag => {
  const { lineItems } = membershipBag || {};
  const upgradeTotal = getSumOfLineItemCharges(lineItems, costTypes.baseSlotUpgrade);

  return "USD " + upgradeTotal;
};

const priceStringIntoFormattedNumber = price => {
  if (!price) {
    return null;
  }

  const parsedFloat = priceStringIntoFloat(price);
  const parsedInt = usdPriceIntoInt(price);

  return parsedFloat === parsedInt ? parsedInt.toString() : parsedFloat.toFixed(2);
};

const merchCreditsApplied = membershipBag => {
  if (!membershipBag || membershipBag.isLoading === true) {
    return;
  }

  const orderInvoiceCharges = membershipBag?.orderInvoice?.charges;
  if (!orderInvoiceCharges) return null;

  const credits = orderInvoiceCharges.find(charge => charge?.type === "credits");
  const creditAmount = credits?.amount;
  if (creditAmount) {
    // NW [EXPLANATION] 1/25/19: return the absolute value of credits applied
    return creditAmount.replace("-", "");
  }

  return creditAmount;
};
/*
 * Sometimes we want to show discounts as non-negative numbers, for example
 * when we have annotations that say "You saved ${amount}!", etc.
 */
const formatSavingsAsPositive = price => {
  if (!price) {
    return null;
  }
  if (price.indexOf("USD -") > -1) {
    return price.replace("USD -", "$");
  }
  return price.replace("- ", "");
};

const itemSecondStyleDiscount = charges => {
  const secondStyleDiscount = findAmountForType(charges, costTypes.secondStyleDiscount);
  return secondStyleDiscount ? formatSavingsAsPositive(secondStyleDiscount) : false;
};

const itemBackupStyleDiscount = charges => {
  const backupStyleDiscount = findAmountForType(charges, costTypes.backupStyleDiscount);
  return backupStyleDiscount ? formatSavingsAsPositive(backupStyleDiscount) : false;
};

const itemPromoDiscount = totals => {
  const promoDiscount = findAmountForType(totals, costTypes.promoItem);
  return promoDiscount !== "USD 0.00" ? formatSavingsAsPositive(promoDiscount) : false;
};

const itemPromoPercentageDiscount = charges => {
  const percentOff = charges.find(charge => charge?.type === "percentOffPromoDiscount");
  return percentOff ? `${percentOff?.percentage}%` : false;
};

const hasMembershipPerkApplied = charges => {
  const membershipPerk = charges.find(charge => charge?.code === discountCode.membershipPerk);
  return !!membershipPerk;
};

const itemAnnotation = (charges, totals, hasReserveRentals, isMember) => {
  const secondStyleAmount = itemSecondStyleDiscount(charges);
  const promoSavings = itemPromoPercentageDiscount(charges);
  const promoSavingsForSecondStyle = itemPromoDiscount(totals);
  const backupStyleAmount = itemBackupStyleDiscount(charges);

  if (secondStyleAmount && promoSavingsForSecondStyle) {
    return Annotations.secondStyleAndPromo(promoSavingsForSecondStyle);
  }

  if (promoSavings && hasReserveRentals && isMember && hasMembershipPerkApplied(charges)) {
    return Annotations.promoItemSavingsWithReserveCheckout(promoSavings);
  }

  if (promoSavings) {
    return Annotations.promoItemSavings(promoSavings);
  }

  if (secondStyleAmount) {
    return Annotations.secondStyleDiscount;
  }

  if (backupStyleAmount) {
    return Annotations.backupStyleDiscount;
  }

  return "";
};

const isZeroAmount = amount => {
  return amount === "USD 0.00";
};

export {
  baseSlotUpgradeTotal,
  findAmountForType,
  formattedPrice,
  formatSavingsAsPositive,
  itemSecondStyleDiscount,
  itemBackupStyleDiscount,
  itemPromoDiscount,
  itemAnnotation,
  isZeroAmount,
  merchCreditsApplied,
  priceStringIntoFloat,
  priceStringIntoFormattedNumber,
  priceTotalValue,
  itemPromoPercentageDiscount,
  hasMembershipPerkApplied,
};
