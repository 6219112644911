export function convertCamelCaseToSentenceCase(str) {
  return str.replace(/([A-Z])/g, " $1").trim();
}

//this function returns if possible 2, or else 1 category names with highest count received from informedFilters
export function getTop2HighestCountCategories(categoryInformedFilters) {
  if (!categoryInformedFilters || Object.keys(categoryInformedFilters).length < 1) {
    return [];
  }

  const sortedCategoryInformedFilters = categoryInformedFilters.values.toSorted((a, b) => b.count - a.count);
  return sortedCategoryInformedFilters.slice(0, 2).map(o => convertCamelCaseToSentenceCase(o.id)) || [];
}

// this function generates the page title and the meta description for the designer page
// note - interestingly the site adds "| Rent the Runway" if title exists
export function getPageTitleAndDescription(gridMetaData, informedFilters) {
  let title, metaDescription;
  const designerName = gridMetaData?.title;
  const categories = getTop2HighestCountCategories(informedFilters.find(o => o.id === "Category"));

  if (!designerName) {
    // in case designer name does not flow through
    title = "Rent the Runway";
    metaDescription = "";
  } else if (!Array.isArray(categories) || categories.length < 1) {
    // in case category does not exist
    title = `Rent ${designerName}`;
    metaDescription = `${designerName} clothing at Rent the Runway. Rent ${designerName} and refresh your style. Shop ${designerName} now.`;
  } else {
    // the ideal case, when everythign works as expected
    title = `Rent ${designerName} | ${designerName} ${categories[0]}`;
    metaDescription = `${designerName} clothing at Rent the Runway. Rent ${designerName} ${
      //There can be either one or two categories, hence this clause
      categories.length > 1 ? categories[0] + " or " + categories[1] : categories[0]
    } and refresh your style. Shop ${designerName} now.`;
  }
  return [title, metaDescription];
}
