import _ from "underscore";
import { formElementNames } from "rtr-constants";
import { formatPhoneNumber } from "helpers/format-phone-number-helper";

export function generateBillingPayload(formData, userId) {
  const formattedData = _.extend({}, formData);

  if (formData[formElementNames.ccFirstName]) {
    delete formattedData[formElementNames.ccFirstName];
    formattedData.firstName = formData[formElementNames.ccFirstName];
  }

  if (formData[formElementNames.ccLastName]) {
    delete formattedData[formElementNames.ccLastName];
    formattedData.lastName = formData[formElementNames.ccLastName];
  }

  if (formData[formElementNames.phoneNumber]) {
    delete formattedData[formElementNames.phoneNumber];
    formattedData.phone = formatPhoneNumber(formData[formElementNames.phoneNumber]);
  }

  return { ...formattedData, userId };
}
