import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ActionLogger from "action-logger";
import { browserPropType, htmlElementPropType, userDataPropType } from "components/propTypes";
import { connect } from "react-redux";
import { analytics, TOOLTIP } from "rtr-constants";
import { User } from "routes";
import { getLocationPathname } from "helpers/location-helpers";
import UserMenuReviewsTooltip from "./user-menu-reviews-tooltip";
import UserMenuActiveOrderTooltip from "./user-menu-active-order-tooltip";
import UserMenuTooltipActions from "actions/user-menu-tooltip-actions";
import MoleculeInlinePositionedTooltip from "components/source/molecules/tooltip/molecule-inline-positioned-tooltip";
import ReviewActions from "actions/reviews-actions";
import { proPaymentFailed } from "../hoc/with-user-data";
import { onEnterPress } from "../../../helpers/a11y-helper";

class UserMenuTooltips extends React.Component {
  static USER_MENU_TOOLTIPS = {
    ACTIVE_ORDER: "active-order",
    CREDITS_AVAILABLE: "credits-available-alert",
    PRO_PAYMENT_FAILED: "pro-payment-failed-alert",
    REVIEWS: "ss-reviews-tooltip",
  };

  static TOOLTIP_NAME_TO_ACTION_MAP = {
    [UserMenuTooltips.USER_MENU_TOOLTIPS.PRO_PAYMENT_FAILED]: "pro_payment_failed_alert_shown",
    [UserMenuTooltips.USER_MENU_TOOLTIPS.REVIEWS]: "review_notif",
    [UserMenuTooltips.USER_MENU_TOOLTIPS.ACTIVE_ORDER]: "notify_show",
  };

  static propTypes = {
    browser: browserPropType,
    credits: PropTypes.shape({
      totalDollarAmountCredits: PropTypes.string,
    }),
    didFetchSmartTopRight: PropTypes.bool,
    displayReviewModal: PropTypes.func, // (styleName, popupSource) => {}
    fetchReviewsData: PropTypes.func.isRequired,
    fetchSSEActiveOrders: PropTypes.func.isRequired,
    pendingReviews: PropTypes.array,
    smartTopRightIsDisplayed: PropTypes.bool,
    sseActiveOrders: PropTypes.array,
    target: htmlElementPropType,
    userData: userDataPropType,
  };

  state = {
    displayedTooltip: null,
  };

  componentDidMount() {
    this.props.fetchSSEActiveOrders();
  }

  tooltipsShouldRenderOnCurrentPage() {
    return ["/order", "/complete"].every(route => getLocationPathname().indexOf(route) < 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sseActiveOrders !== this.props.sseActiveOrders) {
      const activeOrderGroup = this.props.sseActiveOrders?.[0];
      if (activeOrderGroup) {
        this.displayTooltip(this.constructor.USER_MENU_TOOLTIPS.ACTIVE_ORDER);
      } else if (proPaymentFailed(this.props.userData)) {
        this.displayTooltip(this.constructor.USER_MENU_TOOLTIPS.PRO_PAYMENT_FAILED);
      } else if (!UserMenuTooltipActions.getUserMenuTooltipDismissed(this.constructor.USER_MENU_TOOLTIPS.REVIEWS)) {
        // NW [EXPLANATION] 6/15/21: fetch reviews data so that reviews tooltip will launch in a subsequent update
        this.props.fetchReviewsData();
      }
    } else if (
      this.props.credits !== prevProps.credits &&
      !!this.props.credits?.totalDollarAmountCredits &&
      this.props.credits.totalDollarAmountCredits !== "$0" &&
      !this.state.displayedTooltip
    ) {
      this.displayTooltip(this.constructor.USER_MENU_TOOLTIPS.CREDITS_AVAILABLE);
    } else if (prevProps.pendingReviews !== this.props.pendingReviews && !this.state.displayedTooltip) {
      this.displayTooltip(this.constructor.USER_MENU_TOOLTIPS.REVIEWS);
    }
  }

  displayTooltip(tooltipName) {
    if (tooltipName) {
      if (!UserMenuTooltipActions.getUserMenuTooltipDismissed(tooltipName)) {
        this.setState({
          displayedTooltip: tooltipName,
        });
        UserMenuTooltipActions.setUserMenuTooltipDismissed(tooltipName);
        ActionLogger.logAction({
          objectType: analytics.OBJECT_TYPE.TOP_NAV,
          action: this.constructor.TOOLTIP_NAME_TO_ACTION_MAP[tooltipName],
          sub_type1: UserMenuActiveOrderTooltip.getActiveOrderTooltipSubType(this.props.sseActiveOrders),
        });
      }
    }
  }

  closeTooltip = () => {
    this.setState({
      displayedTooltip: null,
    });
  };

  renderProPaymentFailedAlert() {
    return (
      <>
        <div className="reb-PRO_icon pro-alert-header"></div>
        <h2>Your renewal didn&#39;t go through.</h2>
        <p>
          Don&#39;t miss out on free shipping
          <br />
          and insurance for another year.
        </p>
        <p>
          <a href={User.ProSettings} className="btn">
            Renew PRO
          </a>
        </p>
      </>
    );
  }

  renderCreditsAvailableAlert() {
    return (
      <>
        <h3 className="dek-one">
          You have a <span className="accent">{this.props.credits.totalDollarAmountCredits}</span> credit
        </h3>
        <br />
        <p>
          Fill your bag with rentals, beauty products, shapewear and more. Your credit will be automatically applied to
          your order.
        </p>
      </>
    );
  }

  renderTooltipContent() {
    if (!this.state.displayedTooltip) {
      return null;
    }

    if (
      this.state.displayedTooltip === this.constructor.USER_MENU_TOOLTIPS.REVIEWS &&
      !this.props.browser?.isMobileViewport
    ) {
      return (
        <UserMenuReviewsTooltip
          pendingReviews={this.props.pendingReviews}
          displayReviewModal={this.props.displayReviewModal}
        />
      );
    } else if (this.state.displayedTooltip === this.constructor.USER_MENU_TOOLTIPS.PRO_PAYMENT_FAILED) {
      return this.renderProPaymentFailedAlert();
    } else if (this.state.displayedTooltip === this.constructor.USER_MENU_TOOLTIPS.CREDITS_AVAILABLE) {
      return this.renderCreditsAvailableAlert();
    } else if (this.state.displayedTooltip === this.constructor.USER_MENU_TOOLTIPS.ACTIVE_ORDER) {
      return <UserMenuActiveOrderTooltip sseActiveOrders={this.props.sseActiveOrders} />;
    }

    return null;
  }

  render() {
    if (
      this.props.browser?.isMobileViewport ||
      !this.state.displayedTooltip ||
      !this.tooltipsShouldRenderOnCurrentPage() ||
      // NW [EXPLANATION] 6/22/21: smart-top-right content will cause the target element to shift,
      // and the tooltip position implementation is not smart enough re-position in that event.
      // so the tooltip will not render until smart-top-right has rendered and the target is in its final position.
      !this.props.didFetchSmartTopRight
    ) {
      return null;
    }

    const className = classNames("user-menu-alert", {
      [this.state.displayedTooltip]: this.state.displayedTooltip,
      "smart-top-right-adjacent": this.props.smartTopRightIsDisplayed,
    });

    return (
      <MoleculeInlinePositionedTooltip
        target={this.props.target}
        placement={TOOLTIP.PLACEMENTS.TOP}
        className={className}
        onClose={this.closeTooltip}>
        <div className="ss-tooltip-wrapper">
          <div
            role="presentation"
            onKeyPress={onEnterPress(this.closeTooltip)}
            className="ss-tooltip-close"
            title="Close"
            onClick={this.closeTooltip}>
            Close
          </div>
          {this.renderTooltipContent()}
        </div>
      </MoleculeInlinePositionedTooltip>
    );
  }
}

function mapStateToProps(state) {
  return {
    credits: state.credits,
    didFetchSmartTopRight: state.smartComponentState?.didFetchSmartTopRight,
    pendingReviews: state.pendingReviews,
    smartTopRightIsDisplayed: !!state.smartComponentState?.smartTopRightCMS?.userMenu,
    sseActiveOrders: state.sseActiveOrders,
    userData: state.userData,
    browser: state.browser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    displayReviewModal: (styleName, popupSource) => {
      dispatch(ReviewActions.displayReviewUploadModal({ styleName, popupSource }));
    },
    fetchReviewsData: () => {
      dispatch(UserMenuTooltipActions.fetchPendingReviews());
    },
    fetchSSEActiveOrders: () => dispatch(UserMenuTooltipActions.fetchSSEActiveOrders()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenuTooltips);
export { UserMenuTooltips as UnconnectedUserMenuTooltips };
