import PropTypes from "prop-types";

export const userProblemsPropType = PropTypes.shape({
  problemGroup: PropTypes.shape({
    goodBookings: PropTypes.arrayOf(PropTypes.object),
    orderType: PropTypes.string,
    problemBookings: PropTypes.arrayOf(PropTypes.object),
  }),

  swapsContent: PropTypes.shape({
    modal: PropTypes.shape({
      cxLink: PropTypes.string,
      cxMessage: PropTypes.string,
      goodMessage: PropTypes.string,
      goodTitle: PropTypes.string,
      problemMessage: PropTypes.string,
      problemTitle: PropTypes.string,
      subTitle: PropTypes.string,
    }),
  }),
});
