import PropTypes from "prop-types";

/**
 * The PropTypes for the snack bar.
 * Including them here in case other components will need to extend it.
 */
const propTypes = {
  bagContext: PropTypes.bool,
  copyPrimary: PropTypes.string,
  isVisible: PropTypes.bool,
  classNames: PropTypes.string,
  copySecondary: PropTypes.string,
  ctaFunc: PropTypes.func,
  ctaText: PropTypes.string,
  duration: PropTypes.number,
  hasCloseBtn: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  extraClassName: PropTypes.string,
};

export const defaultSnackBarConfig = {
  bagContext: false,
  classNames: "",
  copyPrimary: "",
  copySecondary: "",
  ctaFunc: () => void 0,
  ctaText: "",
  duration: 0,
  hasCloseBtn: false,
  isVisible: true,
  icon: "",
  extraClassName: "",
};

/**
 * Validates the snack bar config by checking for the mandatory properties.
 * This is more to help developers when creating snack bars.
 * Default config showed below and in `defaultSnackBarConfig`
 *
 * @param {Object} config - Snack bar configuration
 * @param {Bool} bagContext - determines if this snackbar should only display in the context of the bag
 * @param {String} [config.classNames = ""] - for custom styling
 * @param {String} [config.copyPrimary = ""] - the primary message
 * @param {String} [config.copySecondary = ""] - additional copy
 * @param {String} [config.ctaFunc = noop] - what the CTA should do
 * @param {String} [config.ctaText = ""] - text of the CTA
 * @param {Number} [config.duration = 0] - duration of the snack bar
 *   (will set timeout if 0 < x < Infinity)
 * @param {Bool} [config.duration = false] - should close button render
 * @param {Bool} [config.isVisible = true] - determines whether to animate in or out
 * @param {String} [config.icon = ""] - to display an icon
 * @param {Bool} [config.extraClassName = ""] - determines whether to add a class for mobile
 * @throws Will throw an error if the config object lacks a 'copyPrimary' property
 * @returns {String} Classes for snack bar
 */
export const validateSnackBarConfig = config => {
  if (!config.copyPrimary) {
    throw new Error("Snack bar config is missing 'copyPrimary' prop");
  }

  return { ...defaultSnackBarConfig, ...config };
};

export default {
  propTypes,
  validateSnackBarConfig,
};
