import _ from "underscore";
import ActionTypes from "../actions/action-types.js";
var openFilters, types, fn;

openFilters = function (openFilters, action) {
  var _openFilters = openFilters;

  if (action.type !== ActionTypes.TOGGLE_OPEN_FILTERS) {
    return _openFilters;
  }

  if (!_.isArray(_openFilters)) {
    _openFilters = [];
  }

  if (action.payload.open) {
    _openFilters.push(action.payload.filterGroupKey);
    return _.uniq(_openFilters);
  }

  return _.without(_openFilters, action.payload.filterGroupKey);
};

types = [ActionTypes.TOGGLE_OPEN_FILTERS];

fn = function (state, action) {
  return _.extend({}, state, {
    openFilters: openFilters(state.openFilters, action),
  });
};

export default {
  types: types,
  fn: fn,
};
