import { v4 as uuidV4 } from "uuid";
import { isDevelopment } from "helpers/next-helpers";

export const rtrIdCookieKey = "RTR_ID";
export const rtrSessionIdCookieKey = "RTR_SESS";

function newRtrId() {
  return `${uuidV4()}-${new Date().getTime()}`;
}

function newRtrSessionId() {
  return `${newRtrId()}.${new Date().getTime()}`;
}

export function setRtrIdCookie(fnSetCookie) {
  const date = new Date();
  const roughlyTwoYearsFromNow = date.getTime() + 2 * 365 * 24 * 60 * 60 * 1000;
  date.setTime(roughlyTwoYearsFromNow);
  fnSetCookie(rtrIdCookieKey, newRtrId(), {
    path: "/",
    expires: date,
    secure: !isDevelopment(),
  });
}

export function setRtrSessionIdCookie(fnSetCookie) {
  fnSetCookie(rtrSessionIdCookieKey, newRtrSessionId(), { path: "/", expires: null, secure: !isDevelopment() });
}
