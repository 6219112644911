// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.

import React, { createContext, useContext, useDebugValue, useEffect, useCallback } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { UserReviewSummary } from "@rtr/godmother";
import { childrenPropType } from "../propTypes";
import { getDisplayName } from "../source/hoc/utils";
import { userReviewSummary as updateUserReviewSummary } from "actions/userProfile-actions";
import { currentUserIdReplacementToken } from "rtr-constants";
import { useUser } from "./UserContext";

const UserReviewSummaryContext = createContext({ userReviewSummary: {}, hasLoaded: false });
const stateKey = "userReviewSummary";

// plucks userReviewSummary from the Redux Context
function userReviewSummarySelector(state) {
  const {
    userReviewSummary: {
      [stateKey]: userReviewSummary,
      [`${stateKey}HasLoaded`]: hasLoaded,
      [`${stateKey}Error`]: error,
    } = {},
  } = state;

  return { userReviewSummary, error, hasLoaded };
}

/////////////////////////////////
//                             //
//  Utility functions          //
//  for use with the data      //
//  returned                   //
//                             //
/////////////////////////////////

export function hasRented(userReviewSummary, productId) {
  const { rentalsOrdering = [] } = userReviewSummary || {};

  return rentalsOrdering.includes(productId);
}

export function hasRentedAnything(userReviewSummary) {
  const { rentalsOrdering = [] } = userReviewSummary || {};

  return rentalsOrdering.length > 0;
}

export function hasReviewed(userReviewSummary, productId) {
  const { styles = {} } = userReviewSummary || {};

  return Object.keys(styles).includes(productId);
}

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useUserReviewSummary() {
  return useContext(UserReviewSummaryContext);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

export function withUserReviewSummary() {
  return WrappedComponent => {
    const C = connect(userReviewSummarySelector)(WrappedComponent);

    C.displayName = `withUserReviewSummary(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

/**
 * @param {object} props
 * @param {function} props.children a ***function*** that takes userProfile as a
 * parameter. used to render your experimental content
 * @param {function} props.onInitialRender a function called only once, when
 * data fetching is complete
 * @param {function} props.fallback a function which returns the content you
 * wish to display while fetching
 * @description Wrap your experimental content in this component to render once
 * user has been loaded. `children` should be a function
 */
export function UserReviewSummarySuspense({ fallback, onInitialRender, children: fnRenderChildren }) {
  let content;
  const { userReviewSummary, hasLoaded, error } = useUserReviewSummary();

  // call the onInitialRender method only once, when hasLoaded is true
  // useCallback to guard against a case where hasLoaded toggles more than once
  const handleInitialRender = useCallback(() => hasLoaded && onInitialRender?.(userReviewSummary), [hasLoaded]);

  // call the memoized function each time hasLoaded changes (useCallback ensures it
  // is only called once per hasLoaded value)
  useEffect(() => {
    handleInitialRender();
  }, [hasLoaded]);

  if (hasLoaded) {
    // happy path
    content = fnRenderChildren?.(userReviewSummary, error);
  } else if (fallback) {
    // fallback
    content = fallback();
  }

  return content ?? null;
}

export function UserReviewSummaryProvider({ children }) {
  // This doesn't work - 'user' needs to be destructured from useUser first and then you can grab the id. However,
  // we need to investigate whether we even want this code anymore, and if we do it needs to go through some vetting
  // first. I believe this code is somewhat leftover from when the PDP was the initial target for rolling out Next.
  const { id: userId } = useUser();
  const dispatch = useDispatch();
  const { userReviewSummary = {}, hasLoaded = false, error } = useSelector(userReviewSummarySelector, shallowEqual);

  useEffect(() => {
    if (!userId) {
      return;
    }
    UserReviewSummary.find(currentUserIdReplacementToken)
      .then(gmUserReviewSummary => {
        dispatch(
          updateUserReviewSummary({
            [stateKey]: gmUserReviewSummary,
            [`${stateKey}HasLoaded`]: true,
            [`${stateKey}Error`]: null,
          })
        );
      })
      .catch(e => {
        console.log("Failed to load UserReviewSummary " + JSON.stringify({ error: e.message, userId }));

        dispatch(updateUserReviewSummary({ [`${stateKey}HasLoaded`]: true, [`${stateKey}Error`]: e }));
      });
  }, [userId]);

  useDebugValue(hasLoaded ? "Loaded" : "Pending");

  return (
    <UserReviewSummaryContext.Provider value={{ userReviewSummary, error, hasLoaded }}>
      {children}
    </UserReviewSummaryContext.Provider>
  );
}

UserReviewSummaryProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...UserReviewSummaryContext,
  Provider: UserReviewSummaryProvider,
};
