import { merchandiseCategories } from "rtr-constants";

export function isBulk(price) {
  return price?.merchandiseCategory === merchandiseCategories.bulk;
}

export function isClearance(price) {
  return price?.merchandiseCategory === merchandiseCategories.clearance;
}

export function isRental(price) {
  return price?.merchandiseCategory === merchandiseCategories.rental;
}

export function showDecimal(amount) {
  // avoid showing decimals if that amount is an INT
  return !Number.isInteger(amount);
}
