import ActionTypes from "./action-types.js";
import { createAction } from "redux-actions";

export const toggleBag = createAction(ActionTypes.BAG_IS_OPEN);
export const switchBagTab = createAction(ActionTypes.BAG_SWITCH_TAB);
export const setBagTabId = createAction(ActionTypes.BAG_TAB_ID);

export const bagTabs = {
  CLASSIC_TAB: "CLASSIC_TAB",
  KIFAH_SELECT_TAB: "KIFAH_SELECT_TAB",
  KIFAH_TAB: "KIFAH_TAB",
  MEMBERSHIP_TAB: "MEMBERSHIP_TAB",
};
