import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  secondaryButtonStyle: PropTypes.bool,
  fullWidthButtonStyle: PropTypes.bool,
  type: PropTypes.string,
};

const AtomButton = ({
  buttonText,
  className,
  disabled,
  onClick = () => {},
  secondaryButtonStyle,
  fullWidthButtonStyle,
  type = "submit",
}) => {
  const buttonSecondaryStyle = `${secondaryButtonStyle ? "btn btn-secondary" : "btn btn-primary"}`;
  const buttonWidthStyle = `${fullWidthButtonStyle ? "btn-full-width" : ""}`;

  return (
    <button
      className={`${buttonSecondaryStyle} ${buttonWidthStyle} ${className || ""}`}
      onClick={onClick}
      disabled={disabled}
      type={type}>
      {buttonText}
    </button>
  );
};

AtomButton.propTypes = propTypes;

export default AtomButton;
