import Constants from "rtr-constants";
// TODO: use this in all places - https://renttherunway.jira.com/browse/SELF-4252
function skuInfo(sku) {
  if (!sku) {
    return {};
  }
  const [id, size] = sku.split("_");
  return { id, size };
}

function createSelectedSku(productId, selectedSize, skusMap = []) {
  if (!productId || !selectedSize || !skusMap || !Array.isArray(skusMap)) {
    return null;
  }
  const skuString = `${productId}_${selectedSize}`.toUpperCase();

  if (skusMap.length > 0 && skusMap.find(sku => sku.id.toUpperCase() === skuString)) {
    return skusMap.find(sku => sku.id.toUpperCase() === skuString);
  } else {
    return {
      type: "sku",
      id: skuString,
    };
  }
}

// SJK added functions to get sku and sku availability 08/03/2022
function getSelectedSku(reservation, skus) {
  if (reservation?.primarySku) {
    return skus.find(sku => sku.id === reservation.primarySku.id);
  }
}

function getSkuCount(sku, product) {
  if (sku) {
    switch (product?.price?.merchandiseCategory) {
      case "RENTAL":
        return sku.rentalAvailabilities?.[0]?.count;
      case "BULK":
        return sku.bulkAvailabilities?.[0]?.count;
      case "CLEARANCE":
        return sku.clearanceAvailabilities?.[0]?.count;
    }
  }
}

/**
 * Method used to check if any SKU is available from product object for all requested sizes and available lengths (if applicable)
 * @param {array} canonicalSizes array of standardized sizes, as one integer strings.
 * @param {object} product contains product info like sizes, prices, availability, sku's from backend services.
 * @returns {(object|null)} lightweight sku object (type and id fields) or null if none are available.
 */
function getAvailableSku(canonicalSizes, product) {
  // check to see if another requested size is available for switch size component boolean
  const getAvailableSkuHelper = (canonicalSizes, length = "R") => {
    if (canonicalSizes.length > 0) {
      const sku = getFilteredSku(product, canonicalSizes, length);
      return getSkuCount(sku, product)
        ? { type: "sku", id: sku.id }
        : getAvailableSkuHelper(canonicalSizes.slice(1), length);
    }
  };
  let availableSku = getAvailableSkuHelper(canonicalSizes);
  if (!availableSku) {
    // check all lengths if applicable
    const lengthOptions = getSkuLengthOptions(product);
    if (lengthOptions) {
      for (let i = 0; i < lengthOptions.length; i++) {
        if (lengthOptions[i] !== "R") {
          availableSku = getAvailableSkuHelper(canonicalSizes, lengthOptions[i]);
          // return the first available SKU
          if (availableSku) {
            return availableSku;
          }
        }
      }
    }
  }
  return availableSku || null;
}

function getSkuLengthOptions(product) {
  if (product?.sizeScale?.hasLengths) {
    const sizes = product.sizeScale.sizes;
    const lengthSet = new Set();
    // remove integers from string size
    sizes.forEach(size => {
      lengthSet.add(size.replace(/[0-9]/g, ""));
    });
    return Array.from(lengthSet);
  }
  return null;
}

// SJK moved from its own file to join other sku-helpers 08/03/2022
function getFilteredSku(product, canonicalSizeFilters, defaultLength = "R") {
  const DEFAULT_SIZE_SELECTION = "";
  const US_STANDARD = "us_standard";
  const DEFAULT_LENGTH = defaultLength;
  let defaultSizeSelection = DEFAULT_SIZE_SELECTION;

  const firstFilteredSize = function (product, canonicalSizeFilters) {
    let filteredSizes = [];

    if (!isUSAStandardSizes(product)) {
      filteredSizes = regionalSizeFilters(product, canonicalSizeFilters);
    } else {
      filteredSizes = canonicalSizeFilters;
    }

    const sizes = filteredSizes.flat();
    const firstAvailableSize = sizes.find(size => {
      const sku = product.skus.find(sku => sku.size === size);
      return getSkuCount(sku, product);
    });
    // if availability data is present, return first available size
    // else first size
    return firstAvailableSize || sizes[0];
  };

  const regionalSizeFilters = function (product, canonicalSizeFilters) {
    return canonicalSizeFilters.map(size => {
      if (hasLengths(product)) {
        return sizeListWithDefaultLength(regionalSizeList(product, size));
      } else {
        return regionalSizeList(product, size);
      }
    });
  };

  const regionalSizeList = function (product, canonicalSize) {
    const canonicalToRegional = product?.sizeScale?.canonicalToRegional;
    return canonicalToRegional?.[canonicalSize] || [];
  };

  const sizeListWithDefaultLength = function (listOfSizes) {
    // Due to some "interesting" size scales e.g.
    // ["00P", "00R", "00L", "00XL", "0P", "0R", "0L", "0XL"],
    // ["XXSP", "XXSR", "XXSL", "XXSXL", "XSP", "XSR", "XSL", "XSXL"]
    // We can match for the defaultLength "R" but also check for the count to preselect the correct one.
    return listOfSizes.filter(size => size.match(DEFAULT_LENGTH));
  };

  const isUSAStandardSizes = function (product) {
    return product?.sizeScale?.id === US_STANDARD;
  };

  const hasLengths = function (product) {
    return product?.sizeScale?.hasLengths;
  };

  if (canonicalSizeFilters) {
    defaultSizeSelection = firstFilteredSize(product, canonicalSizeFilters);
  }

  return product.skus.find(sku => sku.size === defaultSizeSelection);
}

function regionalToCanonical(product, regionalSize) {
  const canonicalToRegionalMap = product?.sizeScale?.canonicalToRegional;
  if (!regionalSize || !canonicalToRegionalMap) {
    return null;
  }
  return Object.keys(canonicalToRegionalMap).filter(key => canonicalToRegionalMap[key].includes(regionalSize));
}

function regionalListToCanonicalList(product, regionalList) {
  if (!product?.sizeScale?.canonicalToRegional || !Array.isArray(regionalList)) {
    return null;
  }

  // Duplicate values will arrise when an item's regional size includes lenth variations
  // i.e. [S,M] => [4,6,8] but [SR, SL, MR, ML] => [4,4,6,6,8,8]
  const removeDupes = array => {
    if (Array.isArray(array)) {
      return [...new Set(array)];
    }
    return null;
  };

  const canonicalList = regionalList.flatMap(size => regionalToCanonical(product, size));
  return removeDupes(canonicalList);
}

function isSubscriptionSku(skuId) {
  const { membershipStyles } = Constants.specialSkus;
  return Object.values(membershipStyles).includes(skuId);
}

const mySelectedCanonicalSizesAsGivenProductsRegionalSizes = (
  myCanonicalSizes = [],
  givenProductsRegionalSizes = {}
) => {
  // Get an array of the keys from givenProductsRegionalSizes
  const regionalSizesKeys = Object.keys(givenProductsRegionalSizes);

  // Filter myCanonicalSizes to only include sizes that are keys in givenProductsRegionalSizes
  const matchedSizes = myCanonicalSizes
    .filter(size => regionalSizesKeys.includes(size))
    .flatMap(size => givenProductsRegionalSizes[size]);

  // Return the array of matched sizes
  return [...new Set(matchedSizes)];
};

export {
  createSelectedSku,
  skuInfo,
  regionalToCanonical,
  regionalListToCanonicalList,
  getSelectedSku,
  getSkuCount,
  getAvailableSku,
  getSkuLengthOptions,
  getFilteredSku,
  isSubscriptionSku,
  mySelectedCanonicalSizesAsGivenProductsRegionalSizes,
};
