import _ from "underscore";

export function normalizePrice(price) {
  if (_.isNull(price) || _.isUndefined(price)) return "";

  let priceString;

  if (_.isNumber(price)) {
    priceString = "$" + price.toFixed(2);
  } else if (!isNaN(price * 1) && price[0] !== "$") {
    priceString = "$" + price;
  }

  if (!priceString) return "";

  return priceString.replace(/\.00$/, "");
}

export function retailPriceFormatted(price) {
  if (_.isNull(price) || _.isUndefined(price)) return price;

  const priceAsInt = usdPriceIntoInt(price);

  if (!_.isNaN(priceAsInt)) return `$${priceAsInt}`;

  return price;
}

export function percentOff(originalPrice, discountedPrice) {
  return Math.round(100 * (1 - discountedPrice / originalPrice));
}

export function usdPriceIntoFloat(price) {
  if (_.isNull(price) || _.isUndefined(price)) return price;

  return parseFloat(getPriceValueAsString(price));
}

export function usdPriceIntoInt(price) {
  if (_.isNull(price) || _.isUndefined(price)) return price;

  return parseInt(getPriceValueAsString(price), 10);
}

export function getPriceValueAsString(price) {
  if (_.isNull(price) || _.isUndefined(price)) return price;

  const regex = new RegExp(/(?:^USD\s*)|(?:^\$\s*)/, "gi");
  return String(price).replace(regex, "").trim();
}
