import client from "clients/RawClient";
import { createAction } from "redux-actions";

import ActionTypes from "actions/action-types";
import ActionLogger from "action-logger";
import MembershipHelpers from "helpers/membership-helpers";
import { Notification } from "routes";

const actions = {
  subscriptionNotificationSuccess: createAction(ActionTypes.SUBSCRIPTION_NOTIFICATION_SUCCESS),
  fetchNotification() {
    return function (dispatch) {
      return client.get(Notification.fetch).then(function (data) {
        dispatch(actions.subscriptionNotificationSuccess({ data: data, stateKey: "notification" }));
        dispatch(actions.logNotificationAction("display"));
      });
    };
  },

  logNotificationAction(action, isLink = false) {
    return function (_dispatch, getState) {
      const state = getState();
      const membershipState = MembershipHelpers.getMembershipState(state);
      const membershipTypeForAnalytics = MembershipHelpers.getMembershipTypeAnalytics(state.userData, membershipState);
      const logData = {
        object_type: "control_center",
        module_type: "notification_center",
        action: action,
        homepage_type: membershipTypeForAnalytics,
        membership: membershipTypeForAnalytics,
        pro: state?.userData?.memberships?.isProMember,
        name: state?.notification?.name,
        cta: state?.notification?.CTA,
        text: state?.notification?.text,
        cta_link: state?.notification?.URL || state?.notification?.action,
      };

      if (isLink) {
        ActionLogger.inferAction(logData);
      } else {
        ActionLogger.logAction(logData);
      }
    };
  },
};

export default actions;

export const { subscriptionNotificationSuccess, fetchNotification, logNotificationAction } = actions;
