import { ApplePayConstants, CEBagItemTypes } from "rtr-constants";
import { isZeroAmount, priceStringIntoFloat } from "helpers/invoice-helper";

//////////////////////////
//   Helpers for the    //
//   Apple Pay Wrapper  //
//   functionality      //
//////////////////////////

export const formatRequestObject = (checkout, includeShipping) => {
  const basicRequest = {
    currencyCode: "USD",
    countryCode: "US",
    lineItems: getLineItems(checkout),
    total: {
      label: ApplePayConstants.merchantDisplay,
      amount: getTotalAmount(checkout),
    },
    merchantCapabilities: ApplePayConstants.merchantCapabilities,
    supportedNetworks: ApplePayConstants.supportedNetworks,
    requiredBillingContactFields: ["postalAddress"],
  };

  return !includeShipping
    ? basicRequest
    : {
        ...basicRequest,
        requiredShippingContactFields: ["postalAddress", "name", "phone"],
      };
};

export const createCompletionObject = (checkout, addressIsValid) => {
  return {
    newTotal: {
      label: ApplePayConstants.merchantDisplay,
      amount: getTotalAmount(checkout),
      type: "final",
    },
    newLineItems: getLineItems(checkout),
    errors: addressIsValid ? [] : [constructApplePayError(ApplePayConstants.APPLE_PAY_ERRORS.SHIPPING_CONTACT_INVALID)],
    status: window.ApplePaySession.STATUS_SUCCESS,
  };
};

export const createValidationDataObject = (url, merchantIdentifier, displayName, domain) => {
  return {
    url,
    body: {
      merchantIdentifier,
      displayName,
      initiative: ApplePayConstants.applePayInitiative,
      initiativeContext: domain,
    },
  };
};

export const constructApplePayError = error => {
  const { ApplePayError } = window;

  switch (error) {
    case ApplePayConstants.APPLE_PAY_ERRORS.BILLING_CONTACT_INVALID:
      return new ApplePayError(
        ApplePayConstants.APPLE_PAY_ERRORS.BILLING_CONTACT_INVALID,
        ApplePayConstants.APPLE_PAY_ERROR_FIELDS.POSTAL_ADDRESS,
        "Billing address is invalid."
      );
    case ApplePayConstants.APPLE_PAY_ERRORS.SHIPPING_CONTACT_INVALID:
      return new ApplePayError(
        ApplePayConstants.APPLE_PAY_ERRORS.SHIPPING_CONTACT_INVALID,
        ApplePayConstants.APPLE_PAY_ERROR_FIELDS.POSTAL_ADDRESS,
        "Shipping address is invalid."
      );
    default:
      return new ApplePayError(
        ApplePayConstants.APPLE_PAY_ERRORS.UNKNOWN,
        null,
        "Please refresh the page and try again."
      );
  }
};

const getLineItems = checkout => {
  const source = checkout;
  const result = source.bagItems?.map(bagItem => {
    const priceToParse = bagItem.pricing?.price || bagItem.retailPrice;
    const lineItem = { label: bagItem.displayName, amount: priceStringIntoFloat(priceToParse) };

    if (bagItem.type === CEBagItemTypes.SUBSCRIPTION) {
      lineItem.label = "RTR Membership";
      lineItem.paymentTiming = "recurring";
      lineItem.recurringPaymentStartDate = new Date();
      lineItem.recurringPaymentIntervalUnit = "month";
      lineItem.recurringPaymentIntervalCount = 1;
    } else if (bagItem.primaryItemId) {
      // a bag item having a primary item id means that it's a backup size
      lineItem.label += " (Backup Size)";
    }

    return lineItem;
  });

  // only show charge line items if they're not $0
  if (source.charges?.totals?.promoItem && !isZeroAmount(source.charges.totals.promoItem)) {
    result.push({ label: "Promo Savings", amount: priceStringIntoFloat(source.charges.totals.promoItem) });
  }

  if (!isZeroAmount(source?.charges?.totals?.shipping)) {
    result.push({ label: "Shipping", amount: priceStringIntoFloat(source.charges.totals.shipping) });
  }

  if (!isZeroAmount(source?.charges?.totals?.insurance)) {
    result.push({ label: "Rental Coverage", amount: priceStringIntoFloat(source.charges.totals.insurance) });
  }

  result.push({ label: "Tax", amount: priceStringIntoFloat(source?.charges?.totals?.tax) });

  if (source?.expectedPayments?.["GIFT_CARD"]) {
    result.push({
      label: "Applied Gift Card",
      amount: priceStringIntoFloat(source.expectedPayments["GIFT_CARD"]),
    });
  }

  return result;
};

const getTotalAmount = checkout => {
  return priceStringIntoFloat(checkout?.expectedPayments?.["CREDIT_CARD"]);
};
