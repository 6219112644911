import client from "clients/RawClient";
import ActionTypes from "./action-types.js";
import { createAction } from "redux-actions";
import Constants from "rtr-constants";

const actions = {
  addStyleToShortlistSuccess: createAction(ActionTypes.ADD_TO_SHORTLIST_SUCCESS),
  addStyleToShortlistFailure: createAction(ActionTypes.ADD_TO_SHORTLIST_FAILURE),
  fetchShortlistsSuccess: createAction(ActionTypes.FETCH_SHORTLISTS_SUCCESS),
  fetchShortlistsError: createAction(ActionTypes.FETCH_SHORTLISTS_ERROR),
  createShortlistSuccess: createAction(ActionTypes.CREATE_SHORTLIST_SUCCESS),
  createShortlistError: createAction(ActionTypes.CREATE_SHORTLIST_ERROR),
  cloneShortlistBegin: createAction(ActionTypes.CLONE_SHORTLIST_BEGIN),
  cloneShortlistSuccess: createAction(ActionTypes.CLONE_SHORTLIST_SUCCESS),
  cloneShortlistError: createAction(ActionTypes.CLONE_SHORTLIST_ERROR),
  deleteShortlistSuccess: createAction(ActionTypes.DELETE_SHORTLIST_SUCCESS),
  deleteShortlistFailure: createAction(ActionTypes.DELETE_SHORTLIST_FAILURE),
  removeStyleFromShortlistSuccess: createAction(ActionTypes.REMOVE_FROM_SHORTLIST_SUCCESS),
  removeStyleFromShortlistFailure: createAction(ActionTypes.REMOVE_FROM_SHORTLIST_FAILURE),

  /**
   * @description Add a style id to the shortlist
   * @param {string} shortlistId Shortlist id
   * @param {string} styleId Style id
   * @param {function} onSuccessCallback callback for triggering success side effects
   * @param {function} onFailureCallback callback for triggering failure side effects
   */
  addStyleToShortlist: function (shortlistId, styleId, onSuccessCallback, onFailureCallback) {
    /**
     * @param {function} dispatch
     * @returns {Promise}
     */
    return function (dispatch) {
      return client
        .ajax({
          type: "POST",
          url: `/shortlist/${shortlistId}/member`,
          data: { styleId },
        })
        .then(
          function () {
            dispatch(
              actions.addStyleToShortlistSuccess({
                shortlistId,
                styleId,
              })
            );

            typeof onSuccessCallback === "function" && onSuccessCallback();
          },
          function () {
            dispatch(actions.addStyleToShortlistFailure());

            typeof onFailureCallback === "function" && onFailureCallback();
          }
        );
    };
  },

  /**
   * @description Remove a style id from the shortlist
   * @param {string} shortlistId Shortlist id
   * @param {string} styleId Style id
   * @param {function} onSuccessCallback callback for triggering success side effects
   * @param {function} onFailureCallback callback for triggering failure side effects
   */
  removeStyleFromShortlist: function (shortlistId, styleId, onSuccessCallback, onFailureCallback) {
    /**
     * @param {function} dispatch
     * @returns {Promise}
     */
    return function (dispatch) {
      return client
        .ajax({
          type: "DELETE",
          url: `/shortlist/${shortlistId}/member/${styleId}`,
        })
        .then(
          function () {
            dispatch(
              actions.removeStyleFromShortlistSuccess({
                shortlistId,
                styleId,
              })
            );

            typeof onSuccessCallback === "function" && onSuccessCallback();
          },
          function () {
            dispatch(actions.removeStyleFromShortlistFailure());

            typeof onFailureCallback === "function" && onFailureCallback();
          }
        );
    };
  },

  loadShortlists: function (favorites, bypassCache = false) {
    return function (dispatch) {
      client
        .ajax({
          type: "GET",
          url: "/shortlist",
          headers: bypassCache ? { "Cache-Control": "no-cache" } : {},
        })
        .then(function (data) {
          data.unshift({
            id: Constants.ALL_HEARTS_SHORTLIST_ID,
            name: "My Hearts",
            styleNames: favorites,
          });

          const shortlists = data.map(shortlist => {
            if (!Array.isArray(shortlist.styleNames)) {
              shortlist.styleNames = [];
            }

            return shortlist;
          });

          dispatch(actions.fetchShortlistsSuccess(shortlists));
        });
    };
  },

  createShortlist: function (postData) {
    return function (dispatch) {
      client
        .ajax({
          type: "POST",
          url: "/shortlist",
          data: JSON.stringify(postData),
        })
        .then(
          function (data) {
            dispatch(
              actions.createShortlistSuccess({
                status: "success",
                id: data.id,
              })
            );
          },
          function () {
            dispatch(actions.createShortlistError({ status: "failure" }));
          }
        );
    };
  },

  cloneShortlist: function (shortlistId, onSuccess = () => {}, onFailure = () => {}) {
    return function (dispatch) {
      dispatch(actions.cloneShortlistBegin);
      client.get("/shortlist/" + shortlistId + "/clone").then(
        function () {
          onSuccess();
          dispatch(actions.cloneShortlistSuccess);
        },
        function () {
          onFailure();
          dispatch(actions.cloneShortlistError);
        }
      );
    };
  },
};

export default actions;

export const {
  addStyleToShortlistSuccess,
  addStyleToShortlistFailure,
  fetchShortlistsSuccess,
  fetchShortlistsError,
  createShortlistSuccess,
  createShortlistError,
  cloneShortlistBegin,
  cloneShortlistSuccess,
  cloneShortlistError,
  deleteShortlistSuccess,
  deleteShortlistFailure,
  removeStyleFromShortlistSuccess,
  removeStyleFromShortlistFailure,
  addStyleToShortlist,
  removeStyleFromShortlist,
  loadShortlists,
  createShortlist,
  cloneShortlist,
} = actions;
