import _ from "underscore";
import {
  analytics,
  billingStatuses,
  checkout,
  discounts,
  inventoryEligibilities,
  membershipLens,
  membershipLensForAvailability,
  membershipStatuses,
  membershipTypes,
  membershipItemStates,
  shipmentActions,
  RENTAL_INTENT,
} from "rtr-constants";
import { formatDistanceToNow } from "date-fns";
import { parseISOWithoutTime } from "./date-helpers";
import { isFuturePausedWithoutItems, isFuturePausedWithoutItemsWithinThreeDays } from "./membership-plan-helpers";

const MembershipHelpers = {
  // How to check pick eligibility after the launch of RTR Update 2.0
  // PICK is for swap flow
  isInPickMode(membershipState) {
    const action = membershipState?.shipmentAction;
    return action === shipmentActions.PICK;
  },

  // PICK_ONLY is for first pick, resume from pause or membership states when
  // they have no membershipItems.
  isInPickOnlyMode(membershipState) {
    const action = membershipState?.shipmentAction;
    return action === shipmentActions.PICK_ONLY;
  },

  canInitiateSwapFlow(membershipState = {}) {
    return membershipState.shipmentAction === shipmentActions.CAN_INITIATE_CROSS_SHIP;
  },

  canInitiateReturnFlow(membershipState) {
    return (
      MembershipHelpers.inGoodStanding(membershipState) &&
      MembershipHelpers.hasNoShipmentAction(membershipState) &&
      MembershipHelpers.hasShipmentsCount(membershipState) &&
      MembershipHelpers.allItemsWithUser(membershipState)
    );
  },

  canInitiateSwapWithoutReturnFlow(membershipState = {}) {
    return (
      MembershipHelpers.inGoodStanding(membershipState) &&
      MembershipHelpers.hasNoShipmentAction(membershipState) &&
      MembershipHelpers.hasShipmentsCount(membershipState) &&
      (MembershipHelpers.hasAllItemsPurchased(membershipState) ||
        MembershipHelpers.hasAtLeastOneItemReturned(membershipState))
    );
  },

  /**
   * @function calculateExpectedReturnDate
   *
   *    Look over all membershipItems in membershipState,
   *    sort through all pickupScheduledFor dates and
   *    pick up the most recent date
   *
   * @param {membershipState} membershipState - Membership State
   * @return {string} the most recent pickupScheduledFor date
   */

  calculateExpectedReturnDate(membershipState = {}) {
    return membershipState?.membershipItems
      ?.reduce((acc, curr) => {
        if (!curr.pickupScheduledFor || acc.includes(curr.pickupScheduledFor)) {
          return acc;
        }
        acc.push(curr.pickupScheduledFor);
        return acc;
      }, [])
      .sort((a, b) => new Date(b) - new Date(a))[0];
  },

  // After return flow, the user enters the swap flow if they have a shipment still at home
  inPickFlow(membershipState = {}) {
    return MembershipHelpers.inGoodStanding(membershipState), MembershipHelpers.isInPickMode(membershipState);
  },

  /**
   * Checks if a user is mid swap under the redesign
   * @param {Object} membershipState
   * @returns {Bool}
   */
  inSwapFlow(membershipState = {}) {
    return (
      membershipState.crossShipEligible &&
      MembershipHelpers.inPickFlow(membershipState) &&
      MembershipHelpers.isEligibleToPick(membershipState)
    );
  },

  isProspectWithNoIntent(membershipState, currentIntent) {
    return !MembershipHelpers.isActiveMembership(membershipState) && currentIntent === RENTAL_INTENT.NO_INTENT;
  },

  isProspectWithSubscriptionIntent(membershipState, currentIntent) {
    return !MembershipHelpers.isActiveMembership(membershipState) && currentIntent === RENTAL_INTENT.MEMBERSHIP;
  },

  hasNoShipmentAction(membershipState = {}) {
    return membershipState.shipmentAction === shipmentActions.NONE;
  },

  inGoodStanding(membershipState = {}) {
    return membershipState.billingStatus === billingStatuses.GOOD_STANDING;
  },

  hasTermNumber(membershipState = {}) {
    return Boolean(membershipState.termNumber);
  },

  hasShipmentsCount(membershipState = {}) {
    return membershipState.shipmentsCount > 0;
  },

  hasOrderHistory(membershipState = {}) {
    return Boolean(membershipState.hasOrderHistory);
  },

  // NW [EXPLANATION] 7/29/20: what is the difference between hasOrderHistory and isFirstShipmentOfFirstTerm?
  // hasOrderHistory *is not* reset when a subscriber goes inactive and rejoins.
  // but membershipTermNumber *is* reset when a subscriber goes inactive and rejoins.
  // this helper should be used to include rejoined subscribers in a first-shipment determination.
  isFirstShipmentOfFirstTerm(membershipState = {}) {
    const { membershipTermNumber, monthlyShipmentLimit, shipmentsCount } = membershipState;
    const isFirstTerm = membershipTermNumber === 0;
    const isFirstShipmentOfTerm = monthlyShipmentLimit === shipmentsCount;

    return isFirstTerm && isFirstShipmentOfTerm;
  },

  isT0User(membershipState = {}) {
    const { membershipTermNumber } = membershipState;
    const isFirstTerm = membershipTermNumber === 0;

    return isFirstTerm;
  },

  allItemsWithUser(membershipState = {}) {
    const items = membershipState?.membershipItems || [];
    const states = _.pluck(items, "state");
    return _.every(states, state => {
      return (
        state === membershipItemStates.AT_HOME ||
        state === membershipItemStates.PURCHASED ||
        state === membershipItemStates.SHIPPED
      );
    });
  },

  filterQualifiedHappinessSurveyItems(membershipState = {}) {
    const items = membershipState?.membershipItems || [];
    return _.filter(items, item => item.state === membershipItemStates.AT_HOME);
  },

  hasAllItemsPurchased(membershipState = {}) {
    const items = membershipState?.membershipItems || [];
    const states = _.pluck(items, "state");
    return _.every(states, state => state === membershipItemStates.PURCHASED);
  },

  hasAllItemsAtHome(membershipState = {}) {
    const items = membershipState?.membershipItems || [];
    const allAtHome = items.every(item => item.state === membershipItemStates.AT_HOME);
    return items.length > 0 && allAtHome;
  },

  hasAtLeastOneReturnPromise(membershipState = {}) {
    const items = membershipState?.membershipItems || [];
    const states = _.pluck(items, "state");
    return states.includes(membershipItemStates.RETURN_PROMISE);
  },

  hasAtLeastOneItemReturned(membershipState = {}) {
    const items = membershipState?.membershipItems || [];
    const states = _.pluck(items, "state");
    return _.contains(states, membershipItemStates.RETURNED);
  },

  hasAtHomeItem(membershipState = {}) {
    const items = membershipState?.membershipItems || [];

    if (_.isEmpty(items)) {
      return false;
    }

    return _.some(items, item => {
      return item.state === membershipItemStates.AT_HOME;
    });
  },

  countOfItemTypes(membershipState, itemState) {
    const items = membershipState?.membershipItems || [];
    if (_.isEmpty(items)) {
      return 0;
    }
    const states = _.pluck(items, "state");
    const filteredStates = _.filter(states, state => state === itemState);
    return _.size(filteredStates);
  },

  getOpenSlotsFromMembershipState(membershipState = {}) {
    return membershipState?.slots?.length || 0;
  },

  isBagFull(membershipState = {}, membershipBag = {}) {
    const numOpenSlots = MembershipHelpers.fetchNumOpenSlots(membershipState);
    const itemsInBag = MembershipHelpers.fetchItemsInBag(membershipBag);
    return itemsInBag > 0 && itemsInBag >= numOpenSlots;
  },

  // The above version of this function is using an old piece of state, "membershipBag", to look at bag count.
  // In that case, count will always be 0 because that object no longer holds bag count state since BagCe was introduced.
  // Below function uses a more up-to-date bag count source.
  // This new function is a stopgap since the changes needed to update the old functions would break scope of this ticket.
  // Larger clean up ticket to delete this and fix original functions & their dependencies: https://renttherunway.jira.com/browse/CE-1690
  isBagFullCe(membershipState = {}, ceBagCount = {}) {
    const numOpenSlots = MembershipHelpers.fetchNumOpenSlots(membershipState);
    const itemsInBag = MembershipHelpers.fetchItemsInBagCe(ceBagCount);
    return itemsInBag > 0 && itemsInBag >= numOpenSlots;
  },

  isBagEmpty(membershipBag = {}) {
    const itemsInBag = MembershipHelpers.fetchItemsInBag(membershipBag);
    return itemsInBag === 0;
  },

  isActiveMembership(membershipState) {
    const { membershipStatus } = membershipState || {};
    return membershipStatus === membershipStatuses.ACTIVE;
  },

  /**
   * @function isLiveSwap
   *
   *    Find the most recent HomePickUpCrossShipConfirmationBody's pickupScheduledFor
   *    and orderSwap's rentalBeginDate in membershipState and
   *    check if they are equal (a liveswap order)
   *
   * @param {membershipState} membershipState - Membership State
   * @return {boolean} whether or not mostRecentSwapOrder.returnByDate === mostRecentHomePickupOrder.pickupScheduledFor
   */

  isLiveSwap(membershipState = {}) {
    const mostRecentHomePickupOrder = MembershipHelpers.findMostRecentOrdersByItemStates(
      membershipState?.membershipItems,
      [membershipItemStates.HOME_PICKUP_SCHEDULED]
    );

    const mostRecentSwapOrder = MembershipHelpers.findMostRecentOrdersByItemStates(membershipState?.membershipItems, [
      membershipItemStates.PREPARING,
      membershipItemStates.SHIPPED,
    ]);

    if (!mostRecentHomePickupOrder?.[0]?.pickupScheduledFor || !mostRecentSwapOrder?.[0]?.rentalBeginDate) {
      return false;
    }

    return mostRecentHomePickupOrder[0]?.pickupScheduledFor === mostRecentSwapOrder[0]?.rentalBeginDate;
  },

  /**
   * @param {string} membershipStartedOn
   * @returns {string} "2 years" or "3 months"
   */
  membershipLength(membershipStartedOn) {
    if (!membershipStartedOn) return "";
    const dateString = formatDistanceToNow(parseISOWithoutTime(membershipStartedOn));
    // this method can return "almost 2 years" or "about 3 months" or "over 1 year", etc.
    // we want to strip the "almost" or "about" or "over" from the string because the text
    // that will be used will say `here's a summary of your last ${membershipLength}`
    return dateString.replace(/almost|about|over|1 /gi, "").trim();
  },

  /**
   * When accessing membershipState from Redux, use this selector
   * @param {Object} state - Redux State
   */
  getMembershipState(state) {
    return state.membershipState;
  },

  /**
   * Filter membershipItems by RETURN_PROMISE
   * @param {Object[]} membershipItems - membership items that we iterate over
   */
  findReturnPromiseItems(membershipItems = []) {
    return membershipItems
      .filter(item => item.state === membershipItemStates.RETURN_PROMISE)
      .sort((a, b) => {
        return new Date(a.stateLastModified) - new Date(b.stateLastModified);
      });
  },

  /**
   * Filter orders by state and sort them by stateLastModified
   * @param {Object[]} membershipItems - membership items that we iterate over
   * @param {strings[]} desiredItemStates - order statuses that we will use to filter over any other membership items
   */
  findMostRecentOrdersByItemStates(membershipItems = [], desiredItemStates = []) {
    // this FILTERS the membershipState, and then sorts the last modified version items
    return membershipItems
      .reduce((acc, curr) => {
        // check if an order is in the desiredItemState
        if (!desiredItemStates.includes(curr?.state)) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, [])
      .sort((a, b) => new Date(b.stateLastModified) - new Date(a.stateLastModified));
  },

  /**
   * finds the most recent stateLastModified, search for items with matching date and filter them by a given state
   * @param {Object[]} membershipItems - membership items that we iterate over
   * @param {strings[]} desiredItemStates - order statuses that we will use to filter over any other membership items
   */
  checkMostRecentModifiedOrdersByItemStates(membershipItems, filteredStates) {
    // find the date of the most recent modified order
    if (!membershipItems || membershipItems.length < 1) return [];
    const mostRecentDate = membershipItems.sort(
      (a, b) => new Date(b.stateLastModified) - new Date(a.stateLastModified)
    )[0].stateLastModified;

    // return the membership items that are newly modified AND in the desired state
    return membershipItems.reduce((acc, curr) => {
      if (curr.stateLastModified === mostRecentDate && filteredStates.includes(curr.state)) acc.push(curr);
      return acc;
    }, []);
  },

  // How to check if RTR Update member after the launch of RTR Update 2.0
  isRtrUpdateMember(userData) {
    return userData?.memberships?.isRtrUpdateMember || false;
  },

  isUnlimitedMember(userData) {
    return userData?.memberships?.isUnlimitedMember || false;
  },

  isRtrUpdateMembershipType(membershipType) {
    return membershipType === membershipTypes.RTRUPDATE;
  },

  isMember(userData) {
    const membershipStatuses = userData?.memberships?.membershipStatuses?.length ?? 0;
    const membershipState = userData?.memberships?.membershipState?.membershipStatus ?? false;
    return Boolean(membershipStatuses) || Boolean(membershipState);
  },

  isFormerRtrUpdateMember(userData) {
    return userData?.memberships?.isFormerRtrUpdateMember;
  },

  isFormerUnlimitedMember(userData) {
    return userData?.memberships?.isFormerUnlimitedMember;
  },

  // BEGIN DELETE AFTER UNLIMITED MIGRATION
  hasMembershipStateMember(userData) {
    return (
      isRtrUpdateMember(userData) ||
      isFormerRtrUpdateMember(userData) ||
      isUnlimitedMember(userData) ||
      isFormerUnlimitedMember(userData)
    );
  },
  // END DELETE AFTER UNLIMITED MIGRATION

  /**
   * @deprecated Will be gone soon
   */
  isUnlimitedMembershipType(membershipType) {
    return membershipType === membershipTypes.UNLIMITED;
  },

  // Deprecated, this is for old unlimited
  // BEGIN DELETE AFTER UNLIMITED MIGRATION, be sure to remove all instances of this in JSX files
  isQueueMember(userData) {
    return userData?.memberships?.isQueueMember || false;
  },
  // END DELETE AFTER UNLIMITED MIGRATION

  //
  // NOTE includes all subscriptions/memberships except pro
  //
  isSubscriptionMember(userData) {
    return userData?.memberships?.isSubscriptionMember || false;
  },

  isUpdateOrUnlimitedSubscriptionMember(userData) {
    /*
      cleanup item of code that was repeated in certain modules...
      brought over comments from repeated code as well as it seems it is important...
    */

    // BEGIN DELETE AFTER UNLIMITED MIGRATION, remove MembershipHelpers.isQueueMember
    const isUnlimitedMember =
      MembershipHelpers.isUnlimitedMember(userData) || MembershipHelpers.isQueueMember(userData);
    // END DELETE AFTER UNLIMITED MIGRATION
    const isRTRUpdateMember = MembershipHelpers.isRtrUpdateMember(userData);

    return isUnlimitedMember || isRTRUpdateMember;
  },

  //
  // FIXME remove
  // FIXME change all instances to `isRtrUpdateMember`
  //
  isLegacyOrCurrentRtrUpdateMember(userData) {
    return userData?.memberships?.isFuzzyGiraffeMember || userData?.memberships?.isRtrUpdateMember;
  },

  rtrUpdateLegacyMembershipData(userData) {
    return userData?.memberships?.rtrUpdate || null;
  },

  getDiscountObject(price, membershipPricing) {
    const pricingId = MembershipHelpers.getPricingId(price);
    if (!pricingId) {
      return;
    }
    return MembershipHelpers.pricingIdGetDiscountObject(pricingId, membershipPricing);
  },

  // Classic without dates and Unlimited currently do not have a price object in the backend
  pricingIdGetDiscountObject(pricingId, membershipPricing) {
    return membershipPricing?.find(p => p.pricing_id === pricingId);
  },

  getPricingId(price) {
    const priceDiscounts = price?.discounts;
    // Check for specific discount and match it with an existing membershipPricing
    if (!priceDiscounts || _.isEmpty(priceDiscounts)) return;

    const [{ id }] = priceDiscounts;
    return id;
  },

  hasMembershipDiscount(price) {
    const pricingId = MembershipHelpers.getPricingId(price);
    const membershipDiscounts = [
      discounts.MEMBERSHIP_ITEM,
      discounts.MEMBERSHIP_UPGRADE_SLOT,
      discounts.RTR_UPDATE_ITEM,
      discounts.RTR_UPDATE_UPGRADE_SLOT,
      discounts.RTR_UNLIMITED_ITEM,
      discounts.RTR_UNLIMITED_UPGRADE_SLOT,
    ];

    return membershipDiscounts.some(id => id === pricingId);
  },

  getMembershipPickState(membershipState) {
    if (!membershipState) {
      return;
    }
    const { shipments } = membershipState;
    return _.pick(shipments, shipment => shipment[0].shipmentAction === shipmentActions.PICK);
  },

  getMembershipReturningShipment(membershipState) {
    if (!membershipState) {
      return;
    }
    const shipments = membershipState.shipments.CURRENT;
    return shipments.map(shipment =>
      shipment.membershipItems.filter(item => item.state === membershipItemStates.RETURN_PROMISE)
    );
  },

  // Return the active membership for Analytics
  // We are deprecating this after UNLIMITED migration launch.
  // Keep only the membershipState check
  getMembershipTypeAnalytics(memberships = {}, membershipState = {}) {
    if (memberships.isQueueMember) {
      return analytics.membershipType.UNLIMITED;
    }

    // This will future proof analytics work by always sending back membershipType versus
    // customizing a string for each membership.
    if (membershipState) {
      return membershipState.membershipType;
    }
  },

  getMembershipLocationForAnalytics(membershipState = {}, membershipBag = {}) {
    if (MembershipHelpers.isInPickOnlyMode(membershipState)) {
      return analytics.MEMBERSHIP_LOCATIONS.PICK_ONLY;
    } else if (MembershipHelpers.inPickFlow(membershipState)) {
      if (MembershipHelpers.isBagFull(membershipState, membershipBag)) {
        return analytics.MEMBERSHIP_LOCATIONS.CONFIRM;
      }
      return analytics.MEMBERSHIP_LOCATIONS.PICK;
    }

    return analytics.MEMBERSHIP_LOCATIONS.AT_HOME;
  },

  // Returning undefined means user has no intent
  getUserDataLens(userData) {
    if (MembershipHelpers.isUnlimitedLens(userData)) {
      return membershipLens.unlimited;
    }

    if (MembershipHelpers.isRtrUpdateLens(userData)) {
      return membershipLens.RTRUpdate;
    }

    if (MembershipHelpers.isClassicLens(userData)) {
      return membershipLens.classic;
    }
  },

  // current carousles on the PDP use 'rental' for non subscriber users
  getUserDataLensForAvailability(userData) {
    if (MembershipHelpers.isUnlimitedLens(userData)) {
      return membershipLensForAvailability.unlimited;
    }
    if (MembershipHelpers.isRtrUpdateLens(userData)) {
      return membershipLensForAvailability.RTRUpdate;
    }
    // defaulting to rental availability
    return membershipLensForAvailability.classic;
  },

  isSubscriptionLens(userData) {
    return MembershipHelpers.isRtrUpdateLens(userData) || MembershipHelpers.isUnlimitedLens(userData);
  },

  isNoIntentLens(userData) {
    return typeof MembershipHelpers.getUserDataLens(userData) === "undefined";
  },

  isClassicLens(userData = {}) {
    return !!userData?.isClassicLens;
  },

  isRtrUpdateLens(userData = {}) {
    return !!userData?.isRTRUpdateLens;
  },

  isUnlimitedLens(userData = {}) {
    return !!userData?.isUnlimitedLens;
  },

  getRentBeginDate(membershipState = {}) {
    const membershipRentalBegin = _.first(membershipState.membershipRentalBegin);
    return membershipRentalBegin?.rentBegin;
  },

  isFuzzyGiraffeMember(userData) {
    return userData?.memberships?.isFuzzyGiraffeMember || false;
  },

  fuzzyGiraffeLegacyMembershipData(userData) {
    return userData?.memberships?.fuzzyGiraffe || null;
  },

  // Shipment Action has three different states: PICK, PICK_ONLY, and NONE
  hasShipmentActionChanged(props, nextProps) {
    const { membershipState } = props;
    const shipmentAction = membershipState?.shipmentAction;

    const nextMembershipState = nextProps.membershipState;
    const nextShipmentAction = nextMembershipState?.shipmentAction;

    return shipmentAction !== nextShipmentAction;
  },

  fetchNextPickDate(membershipState = {}) {
    return membershipState.nextBillingDate;
  },

  fetchNumOpenSlots(membershipState = {}) {
    return membershipState?.slots?.length || 0;
  },

  /**
   * Returns number of membership items in a specific state
   * @param {Object} membershipState - The MembershipState containing all relevant information for membership experience
   * @param {String} state - Various states that the membership item can be in
   * @return {Number} Number of items in the specified state
   */
  fetchNumOfItemInSpecificState(membershipState, state) {
    if (!membershipState) {
      return 0;
    }

    return membershipState.membershipItems.filter(membershipItem => {
      return membershipItem.state === state;
    }).length;
  },

  fetchNumBaseSlots(membershipState = {}) {
    return membershipState?.baseSlotCount || 0;
  },

  fetchItemsInBag(membershipBag = {}) {
    return membershipBag?.items?.length || 0;
  },

  // The above version of this function is using an old piece of state, "membershipBag", to look at bag count.
  // In that case, count will always be 0 because that object no longer holds bag count state since BagCe was introduced.
  // Below function uses a more up-to-date bag count source.
  // This new function is a stopgap since the changes needed to update the old functions would break scope of this ticket.
  // Larger clean up ticket to delete this and fix original functions & their dependencies: https://renttherunway.jira.com/browse/CE-1690
  fetchItemsInBagCe(ceBagCount = {}) {
    return ceBagCount?.members || 0;
  },

  isShipmentActionPickOrPickOnly(membershipState = {}) {
    return MembershipHelpers.isInPickMode(membershipState) || MembershipHelpers.isInPickOnlyMode(membershipState);
  },

  getAvailabilityFromMembershipState(membershipState = {}, sku = {}) {
    if (membershipState.membershipStatus !== membershipStatuses.ACTIVE) {
      return;
    }

    if (MembershipHelpers.hasInventoryEligibility(membershipState, inventoryEligibilities.rtr_update)) {
      return sku.rtrUpdateAvailabilities?.[0];
    } else if (MembershipHelpers.hasInventoryEligibility(membershipState, inventoryEligibilities.rtr_unlimited)) {
      return sku.unlimitedAvailabilities?.[0];
    }
  },

  getAvailabilityForBuyGrid(sku = {}) {
    return sku?.unlimitedAvailabilities?.[0];
  },

  getOrderTypeFromMembershipState(membershipState = {}) {
    if (membershipState.membershipStatus !== membershipStatuses.ACTIVE) {
      return;
    }

    if (MembershipHelpers.isRtrUpdateMembershipType(membershipState.membershipType)) {
      return checkout.orderTypes.UPDATE;
    } else if (MembershipHelpers.isUnlimitedMembershipType(membershipState.membershipType)) {
      return checkout.orderTypes.UNLIMITED;
    }
  },

  getOrderItemTypeFromMembershipState(membershipState = {}) {
    if (membershipState.membershipStatus !== membershipStatuses.ACTIVE) {
      return;
    }

    if (MembershipHelpers.isRtrUpdateMembershipType(membershipState.membershipType)) {
      return checkout.orderItemTypes.UPDATE;
    } else if (MembershipHelpers.isUnlimitedMembershipType(membershipState.membershipType)) {
      return checkout.orderItemTypes.UNLIMITED;
    }
  },

  getShipmentZip(membershipState) {
    // TODO: this should be updated to be the address of the membership bag, not the membershipState address
    // when we build out the ability to change the address of bag without changing the default address
    return membershipState?.shipmentPostalCode;
  },

  isSpecificExperimentSlot(membershipState, experiment) {
    const slots = membershipState?.slots || [];

    return slots.find(slot => slot.experiment === experiment);
  },

  getSwappableBookingIds(membershipItems) {
    return membershipItems.filter(obj => obj.state === membershipItemStates.AT_HOME).map(obj => obj.bookingId);
  },

  getMonthlyShipmentLimit(membershipState) {
    if (!membershipState) {
      return;
    }

    // monthlyShipmentLimit is can be 1, 2, null
    return membershipState.monthlyShipmentLimit || "Unlimited";
  },

  // This is not pick state, this checks whether the user's membership status allows for potential pick.
  // You can be eligibleToPick even if you don't have slots. Users in Pause-With-Items tier are not eligible to pick.
  isEligibleToPick(membershipState) {
    return membershipState?.eligibleToPick;
  },

  isEligibleForImmediateSwapUpgrade(membershipState) {
    return Boolean(membershipState?.membershipEligibleForImmediateUpgrades?.eligible);
  },

  /**
   * Checks whether the membership state is a fixed-swap (non-Unlimited and non-Paused With Items) membership state
   * @param {object} membershipState - The MembershipState containing all relevant information for membership experience
   * @returns {boolean} true if the membership state is fixed-swap (non-Unlimited and non-Paused With Items)
   */
  isFixedSwaps(membershipState) {
    if (membershipState) {
      return membershipState.monthlyShipmentLimit > 0;
    }

    return false;
  },

  /**
   * Returns number of membership items in a specific state
   * @param {Object} membershipState - The MembershipState containing all relevant information for membership experience
   * @param {String} desiredInventory - inventory we want to check against
   * @return {Bool} true if the desired inventory exists
   */
  hasInventoryEligibility(membershipState, desiredInventory) {
    const eligibilities = membershipState?.inventoryEligibilities || [];
    return eligibilities.includes(desiredInventory);
  },

  /**
   * Returns whether two membership states or tiers will have differing inventory
   * @param {Object} oldState - The MembershipState or tier object containing all relevant information for membership experience
   * @param {Object} newState - The MembershipState or tier object containing all relevant information for membership experience
   * @return {Bool} true if the desired inventory exists
   */
  willInventoryEligibilityChange(oldState, newState) {
    const isNewUnlimited = MembershipHelpers.hasInventoryEligibility(newState, inventoryEligibilities.rtr_unlimited);
    const isOldUnlimited = MembershipHelpers.hasInventoryEligibility(oldState, inventoryEligibilities.rtr_unlimited);

    return isNewUnlimited !== isOldUnlimited;
  },

  /**
   * Checks the tier of a user matches the given tier name
   * @returns {Bool}
   */
  checkTier(membershipState, tierName) {
    const { membershipTierName } = membershipState;
    return membershipTierName === tierName;
  },

  /**
   * Gets the most recent order made
   * @param {*} membershipState
   * @returns string
   */
  getMostRecentOrderId(membershipState) {
    return membershipState?.shipmentIds?.length > 0
      ? membershipState.shipmentIds[membershipState.shipmentIds.length - 1]
      : "";
  },

  /**
   * Checks whether or not a user is out of shipments
   * @param {*} membershipState
   * @returns bool
   */
  isOutOfShipments(membershipState) {
    return (
      !MembershipHelpers.fetchNumOpenSlots(membershipState) &&
      (!isFuturePausedWithoutItems(membershipState?.membershipPausedOn) ||
        !isFuturePausedWithoutItemsWithinThreeDays(membershipState?.membershipPausedOn)) &&
      MembershipHelpers.isEligibleForImmediateSwapUpgrade(membershipState)
    );
  },

  isCurrentOrFormerSubscriber(membershipState = {}) {
    return Boolean(membershipState.membershipStatus);
  },
};

export default MembershipHelpers;

export const {
  isInPickMode,
  isInPickOnlyMode,
  canInitiateSwapFlow,
  canInitiateReturnFlow,
  canInitiateSwapWithoutReturnFlow,
  calculateExpectedReturnDate,
  inPickFlow,
  inSwapFlow,
  hasNoShipmentAction,
  inGoodStanding,
  hasTermNumber,
  hasShipmentsCount,
  hasOrderHistory,
  isFirstShipmentOfFirstTerm,
  isT0User,
  isUnlimitedTier,
  unlimitedMemberMustPickANewPlan,
  allItemsWithUser,
  filterQualifiedHappinessSurveyItems,
  hasAllItemsPurchased,
  hasAllItemsAtHome,
  hasAtLeastOneReturnPromise,
  hasAtLeastOneItemReturned,
  hasAtHomeItem,
  countOfItemTypes,
  getOpenSlotsFromMembershipState,
  isBagFull,
  isBagFullCe,
  isBagEmpty,
  isActiveMembership,
  isLiveSwap,
  isOutOfShipments,
  hasFutureUnlimitedDowngradeToFixedSwap,
  canUnlimitedDowngradeToFixedSwap,
  membershipLength,
  getMembershipState,
  isRtrUpdateMember,
  isUnlimitedMember,
  isRtrUpdateMembershipType,
  isUnlimitedMembershipType,
  isQueueMember,
  isSubscriptionMember,
  isUpdateOrUnlimitedSubscriptionMember,
  isLegacyOrCurrentRtrUpdateMember,
  rtrUpdateLegacyMembershipData,
  getDiscountObject,
  pricingIdGetDiscountObject,
  getPricingId,
  hasMembershipDiscount,
  getMembershipPickState,
  getMembershipReturningShipment,
  getMembershipTypeAnalytics,
  getMembershipLocationForAnalytics,
  getUserDataLens,
  getUserDataLensForAvailability,
  isSubscriptionLens,
  isRtrUpdateLens,
  isUnlimitedLens,
  isNoIntentLens,
  isClassicLens,
  getRentBeginDate,
  isFuzzyGiraffeMember,
  fuzzyGiraffeLegacyMembershipData,
  hasShipmentActionChanged,
  fetchNextPickDate,
  fetchNumOpenSlots,
  fetchNumOfItemInSpecificState,
  fetchNumBaseSlots,
  fetchItemsInBag,
  fetchItemsInBagCe,
  isShipmentActionPickOrPickOnly,
  getAvailabilityFromMembershipState,
  getOrderTypeFromMembershipState,
  getOrderItemTypeFromMembershipState,
  getShipmentZip,
  isSpecificExperimentSlot,
  getSwappableBookingIds,
  getMonthlyShipmentLimit,
  isEligibleToPick,
  isEligibleForImmediateSwapUpgrade,
  isFixedSwaps,
  hasInventoryEligibility,
  willInventoryEligibilityChange,
  checkTier,
  getMostRecentOrderId,
  isFormerRtrUpdateMember,
  isFormerUnlimitedMember,
  hasMembershipStateMember,
  isMember,
  isProspectWithNoIntent,
  isProspectWithSubscriptionIntent,
  getAvailabilityForBuyGrid,
  isCurrentOrFormerSubscriber,
} = MembershipHelpers;
