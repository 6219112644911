import $ from "clients/RawClient";
import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";

const actions = {
  getProductInfoByStyleNameBegin: createAction(ActionTypes.FETCH_PRODUCTS_BY_STYLES_BEGIN),
  getProductInfoByStyleNameSuccess: createAction(ActionTypes.FETCH_PRODUCTS_BY_STYLES_SUCCESS),
  getProductInfoByStyleNameFailure: createAction(ActionTypes.FETCH_PRODUCTS_BY_STYLES_FAILURE),

  getProductInformationByStyleNames: styleNames => {
    return function (dispatch) {
      const params = { styleNames };

      dispatch(actions.getProductInfoByStyleNameBegin());
      $.ajax({
        url: "/viewed_products",
        type: "GET",
        data: params,
        headers: {
          Accept: "application/json",
        },
      }).then(
        function (data) {
          dispatch(actions.getProductInfoByStyleNameSuccess(data));
        },
        function (err) {
          dispatch(actions.getProductInfoByStyleNameFailure(err));
        }
      );
    };
  },
};

export default actions;

export const {
  getProductInfoByStyleNameBegin,
  getProductInfoByStyleNameSuccess,
  getProductInfoByStyleNameFailure,
  getProductInformationByStyleNames,
} = actions;
