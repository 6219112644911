import $ from "clients/RawClient";
import _ from "underscore";
import ActionTypes from "actions/action-types.js";
import { createAction } from "redux-actions";
import sharedActions from "actions/shared-actions";
import ReservationValidator from "helpers/reservation-validator";
import { getFilteredSku } from "helpers/sku-helpers";
let currentRequest;
import TatariHelper from "helpers/tatari-helper";
import { getUserId } from "../components/source/hoc/with-user-data";

const reservationObject = function (reservation, payload, product, isRentable) {
  const updatedReservation = _.extend({}, reservation, payload);
  // This is necessary in order to allow us to check for availability for single
  // sized rental items. There is a hidden field which holds the value but no
  // onChange event fires for a selection so it is not stored on the reservation
  // object initially;
  if (isRentable && product.skus.length === 1) {
    updatedReservation.primarySku = {
      id: product.skus[0].id,
    };
  }
  return updatedReservation;
};

// This function is abstracted out because it's probably something we'll want to
// use in the future! It takes a "working" object (i.e. the raw values out of a
// form), removes empty values (null, "", undefined, 0), and slims specified
// relationships down to just an ID. This function also strips user information,
// because the common use case is to manipulate an object that has a user coming
// *from* the server but will just be associated with the current user going
// *back* to the server.
const slimResource = function (data, rels) {
  return _.chain(data)
    .pairs()
    .map(function (pair) {
      const [key, value] = pair;

      // Do not persist user data
      if (key === "user") {
        return;
      }

      // Do not persist blank data
      if (_.isNull(value) || ((_.isArray(value) || _.isObject(value)) && _.isEmpty(value))) {
        return;
      }

      // Preserve anything other than the relationships
      if (!_.contains(rels, key)) {
        return [key, value];
      }

      // Do not persist non-object relationships
      if (!_.isObject(value)) {
        return;
      }

      // Do not persist relationships without an ID
      if (!value.id || value.id === "") {
        return;
      }

      // Okay you're good, but just your ID
      return [key, _.pick(value, "id")];
    })
    .compact()
    .object()
    .value();
};

const actions = {
  updateWorkingReservation: createAction(ActionTypes.UPDATE_RESERVATION),
  updateReservationErrors: createAction(ActionTypes.UPDATE_RESERVATION_ERRORS),
  cancelReservationSubmission: createAction(ActionTypes.CANCEL_RESERVATION_SUBMISSION),
  submittingReservation: createAction(ActionTypes.SUBMITTING_RESERVATION),
  setBaseError: createAction(ActionTypes.RESERVATION_BASE_ERROR),

  // Optional setLocation parameter is used to override the current location, i.e. when
  // the user is on a grid page but calls for the "pdp page" reservation data from the drawer.
  updateReservation: function (payload, setLocation = null) {
    return function (dispatch, getState) {
      const storeValue = getState();
      const { reservation, product, isRentable, isBuyNow } = storeValue;
      const previousStateErrorFields = _.keys(storeValue.errors?.reservation);

      const newReservation = reservationObject(reservation, payload, product, isRentable || product?.price?.isRental);
      const errors = ReservationValidator.validate(newReservation, product, isRentable || product?.price?.isRental);

      // Only show errors for those fields which already have errors set,
      // wait until submit to paint initial errors
      const remainingErrorKeys = _.intersection(previousStateErrorFields, _.keys(errors));
      const newErrorObject = _.pick(errors, remainingErrorKeys);

      dispatch(actions.updateReservationErrors(newErrorObject));
      dispatch(actions.updateWorkingReservation(newReservation));

      // is this updating before we post?
      // make errors trigger here.
      // TODO: Send timestamp with request and only update store on
      // most recent timestamp. abort(); does not prevent server-side
      // requests and often only tells the client to not accept responses.
      const controller = new AbortController();
      const signal = controller.signal;
      if (currentRequest) {
        controller.abort();
      }

      // Only make a request if we have a valid reservation
      if (!ReservationValidator.canMakeRequest(newReservation).isValid) {
        return;
      }

      currentRequest = $.ajax({
        type: "GET",
        data: {
          reservation: slimResource(newReservation, ["primarySku", "backupSku"]),
          state: "availability",
          buynow: isBuyNow ? true : null,
        },
        headers: {
          Accept: "application/json",
        },
        url: setLocation ? setLocation : location.pathname,
        cache: false,
        signal,
      }).then(function (data) {
        currentRequest = null;
        // Rely on client-side errors as source of truth on form
        dispatch(sharedActions.receivePartialProps(_.omit(data, "errors")));
      });
    };
  },

  paintEmptyReservation: function (payload) {
    return function (dispatch) {
      let sku;
      const { product, reservation, pdpUrl = null } = payload;
      const preferences = payload.workingFilters || {};

      const shouldGetFilteredSku = Boolean(
        preferences.canonicalSizes &&
          preferences.canonicalSizes.length > 0 &&
          product.legacyProductType !== "SaleableProduct"
      );

      if (shouldGetFilteredSku) {
        sku = getFilteredSku(product, preferences.canonicalSizes);
      }

      if (sku) {
        reservation.primarySku = { id: sku.id };
      }

      reservation.zipCode = preferences.zip_code;
      reservation.date = preferences.date;

      // Don't overwrite duration unless we definitely have one
      // stored -- otherwise reservation query will not be valid
      if (_.has(preferences, "duration")) {
        reservation.duration = preferences.duration;
      }

      dispatch(actions.updateReservation(reservation, pdpUrl));
    };
  },

  clientSideError: function (errorObject) {
    return function (dispatch) {
      dispatch(actions.updateReservationErrors(errorObject));
    };
  },

  // ok for now as these actions run client-side
  addUpsell: function (sku, reservationId, onSuccessCallback, onFailureCallback, errorObject) {
    return function (dispatch, getState) {
      dispatch(actions.submittingReservation());
      const { userData } = getState();
      $.ajax({
        url: "/reservations/" + reservationId + "/upsells",
        type: "POST",
        data: { sku_id: sku },
      }).then(
        function () {
          TatariHelper.inferAddToCart({
            userId: getUserId(userData),
            orderType: TatariHelper.ORDER_TYPES.CLASSIC,
          });
          if (_.isFunction(onSuccessCallback)) {
            onSuccessCallback();
          }
        },
        function (xhr, _statusText, errorThrown) {
          dispatch(actions.cancelReservationSubmission());
          dispatch(actions.updateReservationErrors(errorObject));

          if (_.isFunction(onFailureCallback)) {
            const error = xhr.status === 400 ? xhr.responseText : errorThrown;
            onFailureCallback(error);
          }
        }
      );
    };
  },
};

export default actions;

export const {
  updateWorkingReservation,
  updateReservationErrors,
  cancelReservationSubmission,
  submittingReservation,
  updateReservation,
  setReservation,
  paintEmptyReservation,
  clientSideError,
  addUpsell,
  setBaseError,
} = actions;
