import ActionTypes from "../actions/action-types.js";

export const types = [
  ActionTypes.CE_BAG_ERROR,
  ActionTypes.CE_BAG_LOADING,
  ActionTypes.CE_BAG_SUCCESS,
  ActionTypes.CE_BAG_COUNT,
];

export function fn(state, action) {
  let { ceBag, ceBagError, ceBagLoading, ceBagCount } = state;

  switch (action.type) {
    case ActionTypes.CE_BAG_ERROR:
      ceBagError = !!action.payload;
      break;

    case ActionTypes.CE_BAG_LOADING:
      ceBagLoading = action.payload;
      break;

    case ActionTypes.CE_BAG_SUCCESS:
      ({ ceBag } = action.payload || {});
      ceBagError = null;
      break;

    case ActionTypes.CE_BAG_COUNT:
      ceBagCount = action.payload || { total: 0 };
      break;

    default:
      return state;
  }

  return {
    ...state,
    ceBag,
    ceBagError,
    ceBagLoading,
    ceBagCount,
  };
}

export default {
  types: types,
  fn: fn,
};
