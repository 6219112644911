export class HttpError extends Error {
  constructor(message, status, details = {}, rawResponse = null) {
    super(message);
    // Set the error name to the class
    this.name = this.constructor.name;
    // Http status code
    this.status = status;
    // Parsed error details from the response (i.e. calling response.json())
    this.details = details;
    // Raw response object, can be useful if we need to re-use response.json which will consume the body
    this.rawResponse = rawResponse;
    // Maintain the stack trace
    Error.captureStackTrace(this, this.constructor);
  }
}
