import React from "react";
import MembershipUpdateApplePayWrapper from "components/source/membership/membership-update-apple-pay-wrapper";
import styles from "./styles.module.scss";
import { applePayLineItemsForMembershipPropTypes } from "components/propTypes/apple-pay-lineitem-prop-types";

const ApplePaySectionMembership = ({ getLineItems, onSuccess, nextBillingDate, disabled, text }) => {
  return (
    <div className={styles["apple-pay-section-membership"]}>
      <MembershipUpdateApplePayWrapper
        getLineItems={getLineItems}
        onSuccess={onSuccess}
        applePayStyle={{ style: "black", type: "continue" }}
        nextBillingDate={nextBillingDate}
        disabled={disabled}
      />
      {text && <p className="sticky-modal-cta-legal universal-xsmall--secondary">{text}</p>}
    </div>
  );
};

ApplePaySectionMembership.propTypes = applePayLineItemsForMembershipPropTypes;

export default ApplePaySectionMembership;
