// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.

import React, { createContext, useContext, useDebugValue, useEffect, useCallback, useState } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { MembershipState } from "@rtr/godmother";
import { childrenPropType } from "../propTypes";
import { getDisplayName } from "../source/hoc/utils";
import { membershipStateLoadSuccess } from "actions/membership-state-actions";
import { currentUserIdReplacementToken } from "rtr-constants";
import { isIdentified, isMasquerading, useUserData } from "components/source/hoc/with-user-data";
import { useAuth } from "components/layout/AuthContext";

const MembershipStateContext = createContext({ membershipState: {}, hasLoaded: false });
const stateKey = "membershipState";

// plucks membershipState from the Redux Context
function membershipStateSelector(state) {
  const { [stateKey]: membershipState } = state;

  return { membershipState };
}

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useMembershipState() {
  return useContext(MembershipStateContext);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

export function withMembershipState() {
  return WrappedComponent => {
    const C = connect(membershipStateSelector)(WrappedComponent);

    C.displayName = `withMembershipState(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

/**
 * @param {object} props
 * @param {function} props.children a ***function*** that takes userProfile as a
 * parameter. used to render your experimental content
 * @param {function} props.onInitialRender a function called only once, when
 * data fetching is complete
 * @param {function} props.fallback a function which returns the content you
 * wish to display while fetching
 * @description Wrap your experimental content in this component to render once
 * user has been loaded. `children` should be a function
 */
export function MembershipStateSuspense({ fallback, onInitialRender, children: fnRenderChildren }) {
  let content;
  const { membershipState, hasLoaded, error } = useMembershipState();

  // call the onInitialRender method only once, when hasLoaded is true
  // useCallback to guard against a case where hasLoaded toggles more than once
  const handleInitialRender = useCallback(() => hasLoaded && onInitialRender?.(membershipState), [hasLoaded]);

  // call the memoized function each time hasLoaded changes (useCallback ensures it
  // is only called once per hasLoaded value)
  useEffect(() => {
    handleInitialRender();
  }, [hasLoaded]);

  if (hasLoaded) {
    // happy path
    content = fnRenderChildren?.(membershipState, error);
  } else if (fallback) {
    // fallback
    content = fallback();
  }

  return content ?? null;
}

export function MembershipStateProvider({ children }) {
  const { authData, hasLoaded: authDataHasLoaded } = useAuth();
  const { id: userId } = authData || {};
  const userData = useUserData();
  // Seems appropriate to keep this info in the Redux Store alongside
  // membershipState, but the Redux action doesn't support that (yet?)
  const [error, setError] = useState();
  const [hasLoaded, setHasLoaded] = useState(false);
  const dispatch = useDispatch();
  const { membershipState = {} } = useSelector(membershipStateSelector, shallowEqual);

  useEffect(() => {
    if (!authDataHasLoaded) {
      return;
    }

    if (!(isIdentified(userData) || isMasquerading(userData))) {
      return;
    }

    let isMounted = true;
    const filter = {
      "membershipStates.products.published": "true,false",
      "cached": false,
      "userId": currentUserIdReplacementToken, // seems unnecessary, but copied from Ruby
    };
    const fields = { itemPurchaseCharge: "purchasePrice,purchaseItems,bookingId" };
    const include =
      "products,address,itemPurchaseCharge,membershipRentalBegin,membershipEligibleForImmediateUpgrades,loyaltyAwards";

    MembershipState.find(currentUserIdReplacementToken, {
      data: {
        filter,
        include,
        fields,
      },
    })
      .then(gmMembershipState => {
        dispatch(membershipStateLoadSuccess(gmMembershipState));
        if (isMounted) {
          setError(null);
          setHasLoaded(true);
        }
      })
      .catch(e => {
        console.log("Failed to load MembershipState " + JSON.stringify({ error: e.message, userId }));
        if (isMounted) {
          setError(e);
          setHasLoaded(true);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [authDataHasLoaded, isIdentified(userData), isMasquerading(userData), userId]);

  useDebugValue(hasLoaded ? "Loaded" : "Pending");

  return (
    <MembershipStateContext.Provider value={{ membershipState, error, hasLoaded }}>
      {children}
    </MembershipStateContext.Provider>
  );
}

MembershipStateProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...MembershipStateContext,
  Provider: MembershipStateProvider,
};
