import ActionTypes from "./action-types";
import { createAction } from "redux-actions";

const actions = {
  suppressSkinnyBanner: createAction(ActionTypes.SUPPRESS_SKINNY_BANNER),
  toggleMobileNav: createAction(ActionTypes.TOGGLE_MOBILE_NAV),
  toggleMobileNavAccountMenu: createAction(ActionTypes.TOGGLE_MOBILE_NAV_ACCOUNT_MENU),
  toggleSearchExpanded: createAction(ActionTypes.TOGGLE_SEARCH_EXPANDED),
  toggleSneakyHeaderVisible: createAction(ActionTypes.TOGGLE_SNEAKY_HEADER_VISIBLE),
};

export default actions;

export const {
  suppressSkinnyBanner,
  toggleMobileNav,
  toggleMobileNavAccountMenu,
  toggleSearchExpanded,
  toggleSneakyHeaderVisible,
} = actions;
