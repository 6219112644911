import React from "react";
import { format } from "date-fns";
import { parseISOWithoutTime } from "helpers/date-helpers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { analytics, dateFnsFormats } from "rtr-constants";
import { Faq } from "routes";
import ActionLogger from "action-logger";
import ClosableModal from "components/source/shared/closable-modal";
import { displayModal } from "actions/shared-actions";
import { browserPropType, membershipStatePropType } from "components/propTypes";
import { getMembershipState, isEligibleForImmediateSwapUpgrade } from "helpers/membership-helpers";
import {
  isFuturePausedWithItems,
  isFuturePausedWithoutItems,
  pausedBeginAndResumeDate,
} from "helpers/membership-plan-helpers";
import AtomPrimaryButton from "components/source/atoms/atom-primary-button";
import MembershipProgressIndicator from "components/source/membership/onboarding/MembershipProgressIndicator";
import AddShipmentModal from "./AddShipmentModal/AddShipmentModalWrapper";

export class SwapCounterModal extends React.Component {
  static propTypes = {
    browser: browserPropType.isRequired,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    membershipState: membershipStatePropType.isRequired,
    onAddShipmentModal: PropTypes.func,
    onDismissModal: PropTypes.func,
  };

  static defaultProps = {
    membershipState: {},
  };

  static modalName = "SWAP_COUNTER_MODAL";

  logClickNeedHelp = () => {
    const {
      membershipState: { id, membershipId, shipmentsCount },
    } = this.props;
    ActionLogger.logAction({
      object_type: analytics.OBJECT_TYPE.MEMBERSHIP_SWAP_COUNTER_MODAL,
      action: analytics.SWAP.CLICK_NEED_HELP,
      membership_id: membershipId,
      user_id: id,
      n_swaps: shipmentsCount,
    });
  };

  getDisplayMonthlyLimit() {
    const {
      membershipState: { monthlyShipmentLimit, futureMembershipTierRevision, shipmentsCount },
      membershipState,
    } = this.props;

    const monthlyShipmentLimitDisplay =
      futureMembershipTierRevision && !shipmentsCount && !isFuturePausedWithItems(membershipState)
        ? futureMembershipTierRevision.monthlyShipmentLimit
        : monthlyShipmentLimit;

    return monthlyShipmentLimitDisplay;
  }

  renderHeading() {
    const { membershipState } = this.props;
    const { nextBillingDate, shipmentsCount, termEnd, membershipPausedOn } = membershipState;
    const displayMonthlyLimit = this.getDisplayMonthlyLimit();
    const hasMultipleSwaps = displayMonthlyLimit > 1;
    const hasShipmentsRemaining = shipmentsCount > 0;
    const futurePausedMember = pausedBeginAndResumeDate(membershipState);
    const isPlural = shipmentsCount > 1 ? "s" : "";

    const dateDisplayed = () => {
      if (isFuturePausedWithoutItems(membershipPausedOn)) {
        return futurePausedMember.datePaused;
      } else if (isFuturePausedWithItems(membershipState)) {
        return futurePausedMember.dateResume;
      } else {
        return nextBillingDate || termEnd;
      }
    };

    let lineOne;
    if (!hasShipmentsRemaining) {
      if (hasMultipleSwaps) {
        lineOne = `Your next ${displayMonthlyLimit} shipments unlock`;
      } else {
        lineOne = "Your next shipment unlocks";
      }
    } else if (hasMultipleSwaps) {
      lineOne = `You have ${shipmentsCount} shipment${isPlural} available.`;
    } else {
      lineOne = "You can swap anytime";
    }

    const formattedDate = dateDisplayed() ? format(parseISOWithoutTime(dateDisplayed()), dateFnsFormats.day) : "";
    const lineTwo = `${hasShipmentsRemaining ? "Swap before" : ""} ${formattedDate}.`;

    return (
      <h4 className="swap-counter-modal__copy">
        {lineOne}
        <br />
        {lineTwo}
      </h4>
    );
  }

  renderSwapsTracker() {
    const {
      membershipState: { shipmentsCount },
    } = this.props;

    const displayMonthlyLimit = this.getDisplayMonthlyLimit();
    const hasMultipleSwaps = displayMonthlyLimit > 1;

    if (!hasMultipleSwaps) {
      return null;
    }

    return (
      <div className="swap-counter-modal__multi-swap-tracker">
        <div className="swap-counter-modal__multi-swap-tracker__label proxima-small-copy">
          <span>Shipments Available</span>
          <span>
            {shipmentsCount} of {displayMonthlyLimit}
          </span>
        </div>
        <MembershipProgressIndicator
          currentIndex={shipmentsCount}
          strokeWidth={7}
          totalSteps={displayMonthlyLimit}
          mixedLinecaps={true}
          paddingPercentageBetweenLines={1}
        />
      </div>
    );
  }

  clickAddShipment = () => {
    const { id, membershipId, monthlyShipmentLimit, membershipTierRevisionId } = this.props.membershipState;

    ActionLogger.logAction({
      object_type: analytics.OBJECT_TYPE.SWAP_COUNTER_MODAL,
      action: analytics.ACTION_TYPE.CLICK_ADD_SHIPMENT,
      user_id: id,
      membership_id: membershipId,
      monthly_shipment_limit: monthlyShipmentLimit,
      membership_tier_revision_id: membershipTierRevisionId,
    });

    this.props.onAddShipmentModal();
  };

  renderModalContents() {
    const { membershipState } = this.props;

    return (
      <div className="swap-counter-modal">
        {this.renderSwapsTracker()}
        {this.renderHeading()}
        {isEligibleForImmediateSwapUpgrade(membershipState) && (
          <AtomPrimaryButton buttonText="+ Add a shipment" onClick={this.clickAddShipment} />
        )}
        <a
          className="proxima-small-copy"
          href={Faq.membershipSwap}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.logClickNeedHelp}>
          How to Swap
        </a>
      </div>
    );
  }

  render() {
    const {
      displayedModal,
      browser: { isMobileViewport },
      onDismissModal,
    } = this.props;
    const isOpen = displayedModal === this.constructor.modalName;

    // NW [EXPLANATION] 7/27/20: typically modals appear on the top of screen for mobile web, but this will appear on the bottom of screen
    const additionalStyles = {
      content: {
        bottom: 0,
      },
    };

    return (
      <ClosableModal
        isOpen={isOpen}
        onRequestClose={onDismissModal}
        additionalStyles={isMobileViewport ? additionalStyles : {}}
        optionalClass="swap-counter-modal-container">
        {this.renderModalContents()}
      </ClosableModal>
    );
  }
}

const mapStateToProps = state => {
  const { browser, displayedModal } = state;
  const membershipState = getMembershipState(state);

  return {
    browser,
    displayedModal,
    membershipState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDismissModal: () => {
      dispatch(displayModal(false));
    },
    onAddShipmentModal: () => {
      dispatch(displayModal(AddShipmentModal.modalName));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwapCounterModal);
