// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.

import React, { createContext, useDebugValue, useEffect, useCallback } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { AUTH_STATES } from "rtr-constants";
import { childrenPropType } from "../propTypes";
import { getDisplayName } from "../source/hoc/utils";
import NextUserActions from "actions/next-user-actions";

export const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

const AuthContext = createContext({ authData: {}, hasLoaded: false });
const stateKey = "authData";

// plucks auth from the Redux Context
// authState is a special case here, it pre-dated Storefront.Next.
// once all components that use authState are on SF.Next, we can eliminate it
// and instead, point those components to authData.authState instead.
// that said, this is likely stale data and should not be used!
export function authSelector(state) {
  const {
    userData: {
      authState,
      [stateKey]: authData,
      [`${stateKey}HasLoaded`]: hasLoaded,
      [`${stateKey}Error`]: error,
    } = {},
  } = state;

  return { authData, authState, error, hasLoaded };
}

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useAuth() {
  return useSelector(authSelector, shallowEqual);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

export function withAuth() {
  return WrappedComponent => {
    const C = connect(authSelector)(WrappedComponent);

    C.displayName = `withAuth(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

/**
 * @param {object} props
 * @param {function} props.children a ***function*** that takes userProfile as a
 * parameter. used to render your experimental content
 * @param {function} props.onInitialRender a function called only once, when
 * data fetching is complete
 * @param {function} props.fallback a function which returns the content you
 * wish to display while fetching
 * @description Wrap your experimental content in this component to render once
 * user has been loaded. `children` should be a function
 */
export function AuthSuspense({ fallback, onInitialRender, children: fnRenderChildren }) {
  let content;
  const { authData, hasLoaded, error } = useAuth();

  // call the onInitialRender method only once, when hasLoaded is true
  // useCallback to guard against a case where hasLoaded toggles more than once
  const handleInitialRender = useCallback(() => hasLoaded && onInitialRender?.(authData), [hasLoaded]);

  // call the memoized function each time hasLoaded changes (useCallback ensures it
  // is only called once per hasLoaded value)
  useEffect(() => {
    handleInitialRender();
  }, [hasLoaded]);

  if (hasLoaded) {
    // happy path
    content = fnRenderChildren?.(authData, error);
  } else if (fallback) {
    // fallback
    content = fallback();
  }

  return content ?? null;
}

export function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const { authState = AUTH_STATES.ANONYMOUS, authData = {}, hasLoaded = false, error } = useSelector(
    authSelector,
    shallowEqual
  );

  useEffect(() => {
    let timeoutInterval;
    if (!hasLoaded) {
      dispatch(NextUserActions.fetchAuthState());

      // refresh auth state on an interval for security best practices
      timeoutInterval = setTimeout(() => {
        dispatch(NextUserActions.fetchAuthState());
      }, REFRESH_INTERVAL);
    }
    return () => clearTimeout(timeoutInterval);
  }, [hasLoaded]);

  useDebugValue(hasLoaded ? "Loaded" : "Pending");

  return <AuthContext.Provider value={{ authState, authData, error, hasLoaded }}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...AuthContext,
  Provider: AuthProvider,
};
