import _ from "underscore";
import ActionTypes from "../actions/action-types.js";

const types = [
  ActionTypes.ITEM_PURCHASE_CHARGE_CANCEL,
  ActionTypes.ITEM_PURCHASE_CHARGE_DONE,
  ActionTypes.ITEM_PURCHASE_CHARGE_FAIL,
  ActionTypes.ITEM_PURCHASE_CHARGE_INIT,
  ActionTypes.ITEM_PURCHASE_CHARGE_INIT_FAIL,
  ActionTypes.ITEM_PURCHASE_CHARGE_LOADING,
];

const modifyMatchingItem = function (matchPath, matchingId, replacement) {
  return item => {
    const id = item?.[matchPath];
    return id === matchingId ? _.extend({}, item, replacement) : item;
  };
};

const membershipStateModification = function (state, action) {
  if (action.type === ActionTypes.ITEM_PURCHASE_CHARGE_INIT) {
    const { bookingId, data } = action?.payload ?? {};
    const purchasePriceWithTax = data?.purchasePriceWithTax;

    const membershipItems = state.membershipState.membershipItems.map(
      modifyMatchingItem("bookingId", bookingId, { purchasePriceWithTax })
    );
    const itemPurchaseCharge = state.membershipState.itemPurchaseCharge.map(
      modifyMatchingItem("bookingId", bookingId, data)
    );

    return { ...state.membershipState, membershipItems, itemPurchaseCharge };
  }

  return state.membershipState;
};

const itemPurchaseChargeModification = function (state, action) {
  var itemPurchaseCharge = _.extend({}, state.itemPurchaseCharge);

  switch (action.type) {
    case ActionTypes.ITEM_PURCHASE_CHARGE_CANCEL:
      itemPurchaseCharge = null;
      break;

    case ActionTypes.ITEM_PURCHASE_CHARGE_DONE:
      itemPurchaseCharge = null;
      break;

    case ActionTypes.ITEM_PURCHASE_CHARGE_FAIL:
      itemPurchaseCharge.error = action.payload;
      break;

    case ActionTypes.ITEM_PURCHASE_CHARGE_INIT:
      itemPurchaseCharge = _.omit(action.payload, "data");
      break;

    case ActionTypes.ITEM_PURCHASE_CHARGE_INIT_FAIL:
      itemPurchaseCharge.priceError = action.payload;
      break;

    case ActionTypes.ITEM_PURCHASE_CHARGE_LOADING:
      itemPurchaseCharge.isLoading = action.payload;
      break;
  }

  return itemPurchaseCharge;
};

const fn = function (state, action) {
  const itemPurchaseCharge = itemPurchaseChargeModification(state, action);
  const membershipState = membershipStateModification(state, action);

  return _.extend({}, state, {
    itemPurchaseCharge: itemPurchaseCharge,
    membershipState: membershipState,
  });
};

export default {
  types: types,
  fn: fn,
};
