import _ from "underscore";
import ActionTypes from "../actions/action-types";

const types = [
  ActionTypes.SMART_SKINNY_BANNER_CONTENT_SUCCESS,
  ActionTypes.SMART_TOP_RIGHT_CONTENT_SUCCESS,
  ActionTypes.SMART_PDP_CONTENT_SUCCESS,
  ActionTypes.SMART_REFERRALS_SALE_GTM_MODAL_SUCCESS,
  ActionTypes.SMART_CHECKOUT_BANNER_SUCCESS,
  ActionTypes.GET_SMART_REFERRALS_REMINDER_MODAL_SUCCESS,
  ActionTypes.SMART_RENT_MY_STYLE_QUIZ_SUCCESS,
];

const smartComponentModification = (state, action) => {
  var smartComponentState = _.extend({}, state);
  switch (action.type) {
    case ActionTypes.SMART_SKINNY_BANNER_CONTENT_SUCCESS:
      smartComponentState.smartSkinnyBannerCMS = action.payload?.attributes?.content ?? null;
      break;
    case ActionTypes.SMART_TOP_RIGHT_CONTENT_SUCCESS:
      smartComponentState.smartTopRightCMS = action.payload?.attributes?.content ?? null;
      smartComponentState.didFetchSmartTopRight = true;
      break;
    case ActionTypes.SMART_PDP_CONTENT_SUCCESS:
      smartComponentState.PDPContent = action.payload?.attributes?.content ?? null;
      break;
    case ActionTypes.SMART_CHECKOUT_BANNER_SUCCESS:
      smartComponentState.smartCheckoutBanner = action.payload?.attributes?.content ?? null;
      break;
    case ActionTypes.GET_SMART_REFERRALS_REMINDER_MODAL_SUCCESS:
      smartComponentState.referralsReminderModal = action.payload?.attributes?.content ?? null;
      smartComponentState.didFetchSmartReferralsReminderModal = true;
      break;
    case ActionTypes.SMART_REFERRALS_SALE_GTM_MODAL_SUCCESS:
      smartComponentState.referralsGtmModalContent = action.payload?.attributes?.content ?? null;
      break;
    case ActionTypes.SMART_RENT_MY_STYLE_QUIZ_SUCCESS:
      smartComponentState.rentMyStyleQuizContent = action.payload?.attributes?.content ?? null;
      break;
  }
  return smartComponentState;
};

const fn = (state, action) => {
  return _.extend({}, state, { smartComponentState: smartComponentModification(state.smartComponentState, action) });
};

export default {
  types: types,
  fn: fn,
};
