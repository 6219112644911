import { shallowEqual, useSelector } from "react-redux";
import { getDisplayName } from "./utils";

export const stateKey = "csrfProtection";

export function csrfProtectionSelector(state) {
  return state[stateKey] || {};
}

export function useCsrfProtection() {
  return useSelector(csrfProtectionSelector, shallowEqual);
}

export function withCsrfProtection() {
  return WrappedComponent => {
    const C = props => {
      const csrfProtection = useCsrfProtection();
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...props} csrfProtection={csrfProtection} />;
    };

    C.displayName = `withCsrfProtection(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}
