import { createAction } from "redux-actions";
import ActionTypes from "./action-types";
import SnackBarHelpers from "helpers/snack-bar-helpers";

// NOTE See `validateSnackBarConfig` for snack bar defaults
const actions = {
  addSnackBar: createAction(ActionTypes.SET_SNACK_BAR, SnackBarHelpers.validateSnackBarConfig),
  dismissSnackBar: createAction(ActionTypes.DISMISS_SNACK_BAR),
};

export default actions;

export const { addSnackBar, dismissSnackBar } = actions;
