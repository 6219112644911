import $ from "clients/RawClient";
import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";
import { isStorefrontNext } from "helpers/environment-helpers";

const actions = {
  fetchFlagOrExperimentSuccess: createAction(ActionTypes.FETCH_FLAG_OR_EXPERIMENT_SUCCESS),
  fetchFlagOrExperimentLoading: createAction(ActionTypes.FETCH_FLAG_OR_EXPERIMENT_LOADING),
  fetchFlagOrExperimentError: createAction(ActionTypes.FETCH_FLAG_OR_EXPERIMENT_ERROR),

  fetchFlagOrExperiment: flagName => {
    return (dispatch, getState) => {
      //If the flag is already loading, do nothing
      //It is not uncommon for multiple instances of the same component to request a flag in rapid succession
      if (getState().loadingFlagsAndExperiments?.includes(flagName)) {
        return;
      }

      dispatch(actions.fetchFlagOrExperimentLoading(flagName));
      const urlPreface = isStorefrontNext() ? "/api/godmother" : "";
      return $.get({ url: `${urlPreface}/flagsAndExperiments/${flagName}` }).then(
        response => {
          dispatch(actions.fetchFlagOrExperimentSuccess(response));
        },
        err => {
          const errorObject = {
            ...err,
            newFlagKey: flagName,
          };

          dispatch(actions.fetchFlagOrExperimentError(errorObject));
        }
      );
    };
  },
};

export default actions;
