import _ from "underscore";
import ActionTypes from "actions/action-types.js";

const types = [
  ActionTypes.PRODUCT_DRAWER_OPEN,
  ActionTypes.PRODUCT_DRAWER_CLOSE,
  ActionTypes.PRODUCT_DRAWER_FETCH_FIT_SUMMARY_LOADING,
  ActionTypes.PRODUCT_DRAWER_FETCH_FIT_SUMMARY_SUCCESS,
  ActionTypes.PRODUCT_DRAWER_FETCH_FIT_SUMMARY_ERROR,
  ActionTypes.PRODUCT_DRAWER_FETCH_FIT_SUMMARY_DONE,
  ActionTypes.PRODUCT_DRAWER_FETCH_CUSTOMER_REVIEWS_SUCCESS,
  ActionTypes.PRODUCT_DRAWER_FETCH_CUSTOMER_REVIEWS_ERROR,
];

const setInitialState = function (state) {
  return _.extend({}, state, {
    productDrawer: {
      carouselId: null,
      displayed: false,
      productId: null,
      productIndex: null,
      reviews: {
        fitCounts: { "Large": 0, "True to Size": 0, "Small": 0 },
        reviewCount: 0,
        momentCount: 0,
      },
    },
  });
};

const drawerModification = function (state, action) {
  var newState = state;

  if (_.isUndefined(newState.productDrawer)) {
    newState = setInitialState(newState);
  }

  let productDrawer = _.extend({}, newState.productDrawer);

  switch (action.type) {
    case ActionTypes.PRODUCT_DRAWER_OPEN: {
      // Clearing product to be sure no shallow pointers stick.
      const clearedDrawer = _.omit(productDrawer, "product");

      productDrawer = _.extend({}, clearedDrawer, {
        carouselId: action.payload.carouselId,
        displayed: action.payload.displayed,
        product: action.payload.product,
        productId: action.payload.productId,
        productIndex: action.payload.productIndex,
        reviews: setInitialState(newState).productDrawer.reviews,
        parentElement: action.payload.parentElement,
      });
      break;
    }

    case ActionTypes.PRODUCT_DRAWER_CLOSE: {
      const reset = setInitialState();
      productDrawer = reset.productDrawer;
      break;
    }

    case ActionTypes.PRODUCT_DRAWER_FETCH_FIT_SUMMARY_SUCCESS:
      productDrawer = _.extend({}, newState.productDrawer, {
        reviews: action.payload,
      });
      break;

    case ActionTypes.PRODUCT_DRAWER_FETCH_FIT_SUMMARY_ERROR:
      productDrawer.reviews = _.extend({}, newState.productDrawer.reviews, {
        error: action.payload.statusText,
      });
      break;

    case ActionTypes.PRODUCT_DRAWER_FETCH_CUSTOMER_REVIEWS_SUCCESS:
      productDrawer = _.extend({}, newState.productDrawer, {
        reviews: action.payload,
      });
      break;

    case ActionTypes.PRODUCT_DRAWER_FETCH_CUSTOMER_REVIEWS_ERROR:
      // NW [EXPLANATION] 1/22/19: we don't get a descriptive error message from the server, so returning the existing productDrawer object with default reviews field.
      productDrawer = _.extend({}, newState.productDrawer);
      break;

    default:
      return newState;
  }

  return productDrawer;
};

const fn = function (state, action) {
  const productDrawer = drawerModification(state, action);

  return _.extend({}, state, { productDrawer });
};

export default { types, fn };
