import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import AddShipmentModalPropTypes from "./AddShipmentModalPropTypes";
import { flagsAndExperimentNames, PaymentMethods, APPLE_PAY_STRING } from "rtr-constants";
import { withFlagsAndExperiments } from "components/source/hoc/with-flags-and-experiments";

import StickyCtaModal from "components/source/membership/modals/StickyCtaModal";
import SummaryItem from "components/source/checkout/summary/summary-item";

import AtomCreditCardIcon from "components/source/atoms/atom-credit-card-icon";
import ExpressBagPromoCode from "components/source/checkout/bag/membership/express-bag-promo-code";
import ApplePaySectionMembership from "components/source/membership/apple-pay-section-membership";
import AtomFullWidthButton from "components/source/atoms/atom-full-width-button";

import MembershipHelpers from "helpers/membership-helpers";
import { paymentProfileDisplayString } from "helpers/payment-method-helpers";
import { formattedPrice, priceStringIntoFloat, isZeroAmount } from "helpers/invoice-helper";
import { retailPriceFormatted } from "helpers/PricingHelper";
import { evaluateApplePayOptions, PAYMENT_OPTION_RULE } from "helpers/payment-options-helper";
import { User } from "routes";

import { fetchBag } from "actions/membership-bag-actions";
import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";
import ExclamationIcon from "images/sprites/reb/svg/exclamation_icon.svg";

export class AddShipmentModal extends React.Component {
  static propTypes = {
    ...AddShipmentModalPropTypes,
  };

  state = {
    planInfoSectionIsExpanded: false,
    upgradeTier: null,
    isCtaEnabled: true,
    isApplePayEnabled: false,
    evaluationComplete: false,
    ctaText: "Confirm",
    disableCtaBtn: false,
    displayCtaInternal: false,
  };

  componentDidMount() {
    const {
      getMembershipTiers,
      membershipBag,
      membershipTiers = [],
      membershipState,
      oopsModal,
      fetchLegalText,
      fetchMembershipBag,
      fetchFlags,
    } = this.props;

    // Don't make any calls if ineligible
    if (!MembershipHelpers.isEligibleForImmediateSwapUpgrade(membershipState)) {
      return;
    } else {
      fetchLegalText();
    }

    if (!membershipBag?.paymentProfiles) {
      fetchMembershipBag();
    }

    if (membershipTiers.length > 0) {
      this.fetchNewPlan();
    } else {
      // Fetch membershipTiers if they have not yet been populated
      getMembershipTiers().then(
        () => {
          this.fetchNewPlan();
        },
        () => {
          oopsModal();
        }
      );
    }
    fetchFlags();
  }

  componentDidUpdate(prevProps) {
    const {
      immediateUpgradePlanPreviewLoading,
      membershipState,
      membershipState: { monthlyShipmentLimit } = {},
    } = this.props;

    if (prevProps.immediateUpgradePlanPreviewLoading && !immediateUpgradePlanPreviewLoading) {
      this.processPrices();
    }

    if (
      prevProps.membershipState.monthlyShipmentLimit !== monthlyShipmentLimit &&
      MembershipHelpers.isEligibleForImmediateSwapUpgrade(membershipState)
    ) {
      this.fetchNewPlan();
    }
  }

  getUpgradeTier = () => {
    const { membershipState: { monthlyShipmentLimit } = {}, membershipTiers = [] } = this.props;
    const newTier = membershipTiers.filter(tier => tier.monthlyShipmentLimit === monthlyShipmentLimit + 1);

    if (newTier.length === 1 && newTier[0].id) {
      return newTier[0];
    }

    return null;
  };

  fetchNewPlan() {
    const { getChangePlanPreview } = this.props;

    const upgradeTier = this.getUpgradeTier();
    this.setState({ upgradeTier: upgradeTier });
    if (upgradeTier?.id) {
      getChangePlanPreview(upgradeTier.id);
    }
  }

  processPrices = () => {
    const { immediateUpgradePlanPreview } = this.props;
    const invoice = immediateUpgradePlanPreview?.membershipTierChangeCharges?.[0]?.totals;
    if (invoice) {
      const pricing = {};
      invoice
        .filter(
          total =>
            total.type === "sub" ||
            total.type === "preTax" ||
            total.type === "tax" ||
            total.type === "total" ||
            total.type === "promo"
        )
        .forEach(total => (pricing[total.type] = total.amount));

      pricing.promoTermCount = immediateUpgradePlanPreview?.promoTermCount;
      pricing.prePromo = pricing.promo
        ? `USD ${priceStringIntoFloat(pricing.preTax) - priceStringIntoFloat(pricing.promo)}.00`
        : null;
      this.setState({ ...pricing, invoiceIncludeFailed: false });
    } else {
      this.setState({ invoiceIncludeFailed: true });
    }
  };

  renderHeading = () => {
    const {
      membershipState: { monthlyShipmentLimit },
    } = this.props;
    const heading = monthlyShipmentLimit > 1 ? "Add a Shipment to Your Plan" : "Upgrade Your Plan";
    const subHeading =
      monthlyShipmentLimit > 1 ? (
        <span>
          You can downgrade anytime from <br></br>your Membership Settings.
        </span>
      ) : (
        "Contact us to downgrade anytime."
      );

    return (
      <>
        <h3 className="modal-hed">{heading}</h3>
        <p className="modal-sub-hed universal-small">{subHeading}</p>
      </>
    );
  };

  togglePlanInfoExpandedState = () => {
    const planIsExpanding = !this.state.planInfoSectionIsExpanded;

    this.setState({
      planInfoSectionIsExpanded: planIsExpanding,
    });
  };

  renderExpandedDetail(content) {
    const baseClass = "membership-footer__new-plan__expanded";
    const openClass = ` ${baseClass}--open`;
    const className = baseClass + (this.state.planInfoSectionIsExpanded ? openClass : "") + " body-copy";

    return (
      <div className={className}>
        <p>{content}</p>
      </div>
    );
  }

  renderNewPlan = () => {
    const {
      membershipState: { monthlyShipmentLimit },
    } = this.props;

    const oneSwapUser = monthlyShipmentLimit === 1;

    const shipmentPerMonthText = oneSwapUser
      ? "2 shipments + full closet access"
      : `${monthlyShipmentLimit + 1} shipments per month`;

    const tooltipContent = !oneSwapUser
      ? `Your 1 extra shipment will bring your bill to ${retailPriceFormatted(this.state.sub)}/month.`
      : `Upgrading to 2 or more shipments gets you access to our full inventory, including eventwear & premium styles with a retail value up to $3500. Your new bill will be ${retailPriceFormatted(
          this.state.sub
        )}/month.`;

    return (
      <div className="section new-plan">
        <p className="sub-section-hed">New Plan</p>
        <div>
          <p>
            <div>
              <span className="shipments-per-month">{shipmentPerMonthText}</span>
              <button
                className="membership-footer__tooltip-question"
                onClick={this.togglePlanInfoExpandedState}
                aria-label="Click to view more details about your new plan"></button>
            </div>
          </p>
          <span className="new-plan__tooltip-content">{this.renderExpandedDetail(tooltipContent)}</span>
        </div>
      </div>
    );
  };

  renderBilling = () => {
    const { membershipBag, flagsAndExperiments } = this.props;

    if (membershipBag?.isLoading) {
      return (
        <div className="section">
          <p className="sub-section-hed">Billing</p>
          <div className="credit-card">
            <span className="universal-xsmall">Just a moment...</span>
          </div>
        </div>
      );
    }
    const paymentProfiles = membershipBag?.paymentProfiles;
    let billingMethod;

    // check for FE and BE flag, BE responsible for setting and returning the recurring property
    if (flagsAndExperiments?.[flagsAndExperimentNames.COMET_APPLE_PAY_SUBSCRIPTION]) {
      billingMethod = paymentProfiles?.find(profile => profile?.recurring === true);
    } else {
      billingMethod = paymentProfiles?.find(profile => profile?.default === true);
    }

    let ccString = paymentProfileDisplayString(billingMethod);
    let cardSubType = PaymentMethods.subType.CREDIT_CARD;

    let showPaymentUnsupportedMessage = false;
    if (billingMethod?.subType === PaymentMethods.subType.APPLE_PAY) {
      ccString = APPLE_PAY_STRING;
      cardSubType = PaymentMethods.subType.APPLE_PAY;

      /**
       * if the default card is Apple Pay and the browser doesn't support it, we should
       * show the user a prompt to send them to the membership billing page so they can
       * update their card.
       */
      if (!window.ApplePaySession) {
        showPaymentUnsupportedMessage = true;
      }
    }

    const billingInfoCopy = billingMethod ? ccString : "Please Update Your Billing Information";
    // https://renttherunway.jira.com/browse/COMETS-4913 to update AtomCreditCardIcon to handle Apple Pay
    const creditCardIcon = billingMethod ? (
      <AtomCreditCardIcon cardType={billingMethod.cardType} cardSubType={cardSubType} />
    ) : null;

    return (
      <div className="section">
        <p className="sub-section-hed">Billing</p>
        <div className="credit-card">
          {creditCardIcon}
          <span className={`universal-xsmall ${showPaymentUnsupportedMessage ? "error" : ""}`}>{billingInfoCopy}</span>

          {showPaymentUnsupportedMessage && <ExclamationIcon className="error-icon" />}
        </div>

        {showPaymentUnsupportedMessage && (
          <p className="universal-xsmall error payment-unsupported">
            The device or browser you&rsquo;re using does not currently support this payment method. Please change your
            payment method to proceed. <a href={User.Settings}>Manage Membership Billing</a>
          </p>
        )}
      </div>
    );
  };

  renderPromoCodeError() {
    const {
      immediateUpgradePlanPreview,
      immediateUpgradePlanPreviewLoading,
      immediateUpgradePlanPreviewFail,
    } = this.props;

    if (immediateUpgradePlanPreview && !immediateUpgradePlanPreviewLoading && immediateUpgradePlanPreviewFail) {
      return <div className="section promoError universal-xsmall">Whoops! Invalid promo code.</div>;
    }
    return null;
  }

  renderPromoCodeInput() {
    const { getChangePlanPreview, immediateUpgradePlanPreview, immediateUpgradePlanPreviewLoading } = this.props;
    const appliedPromoCode = immediateUpgradePlanPreview?.promoCode;

    return (
      <ExpressBagPromoCode
        value={appliedPromoCode}
        isLoading={immediateUpgradePlanPreviewLoading}
        onSubmitCode={promoCode => getChangePlanPreview(this.state.upgradeTier.id, promoCode)}
        clearableCode={true}
      />
    );
  }

  renderDue = () => {
    return (
      <div className="section split">
        <div className="seperator">
          <div className="line-item">
            <p className="sub-section-hed">Due Today</p>
            {!this.state.promoFieldOpen && (
              <button
                onClick={() => this.setState({ promoFieldOpen: true })}
                className="membership-footer__promo-action">
                Add Promo Code
              </button>
            )}
          </div>
        </div>
        {this.state.promoFieldOpen && (
          <div className="membership-footer__promo-code seperator">
            <span className="universal-small--semibold">Promo Code</span>
            {this.renderPromoCodeInput()}
          </div>
        )}
        <div className="seperator">
          <SummaryItem
            amountCharged={formattedPrice(this.state.prePromo ? this.state.prePromo : this.state.preTax)}
            displayText="Extra Shipment"
          />
          {this.state.promo && !isZeroAmount(this.state.promo) && (
            <SummaryItem
              additionalClassName="promo-item emphasize-promo"
              amountCharged={`${formattedPrice(this.state.promo)}${this.state.promoTermCount > 1 ? "/month" : ""}`}
              displayText="Promo Savings"
            />
          )}
          {this.state.promoTermCount > 1 && (
            <div className="sub-line">
              <p>Applied to your next {this.state.promoTermCount} months!</p>
            </div>
          )}

          <SummaryItem amountCharged={formattedPrice(this.state.tax)} displayText="Tax" />
        </div>
        <SummaryItem
          additionalClassName="membership-item"
          amountCharged={formattedPrice(this.state.total)}
          displayText="Total"
        />
      </div>
    );
  };

  onDismissModal = () => {
    const { dismissModal } = this.props;
    dismissModal();
    this.setState({ planInfoSectionIsExpanded: false });
  };

  onConfirm = () => {
    const { submitPlanChange, membershipState, immediateUpgradePlanPreview } = this.props;

    const inventoryEligibilityChange = MembershipHelpers.willInventoryEligibilityChange(
      membershipState,
      this.state.upgradeTier
    );

    submitPlanChange({
      tierId: this.state.upgradeTier.id,
      promoCode: immediateUpgradePlanPreview?.promoCode,
      inventoryEligibilityChange,
    });
    this.onDismissModal();
  };

  getApplePayLineItems = () => {
    if (!this.state?.total) return null;

    return [
      {
        label: "Subtotal",
        amount: priceStringIntoFloat(this.state.promo) < 0 ? this.state.prePromo : this.state?.preTax,
      },
      { label: "Promo", amount: priceStringIntoFloat(this.state.promo) < 0 ? this.state.promo : "USD 0.00" },
      { label: "Tax", amount: this.state.tax },
      { label: "Total", amount: this.state.total, type: "total" },
    ];
  };

  renderApplePay = () => {
    if (!this.state?.total) return null;

    return (
      <div className="sticky-modal-cta-section">
        <ApplePaySectionMembership getLineItems={this.getApplePayLineItems} onSuccess={this.onConfirm} />
        {this.state.displayCtaInternal && (
          <>
            <div className="or"></div>
            <div>
              <AtomFullWidthButton
                buttonText={this.state.ctaText}
                disabled={false}
                onClick={this.onConfirm}></AtomFullWidthButton>
              <p className="sticky-modal-cta-legal universal-xsmall--secondary">
                {this.props.immediateUpgradeLegalText?.text}
              </p>
            </div>
          </>
        )}
        <p className="sticky-modal-cta-legal universal-xsmall--secondary">{this.state.applePayText}</p>
      </div>
    );
  };

  evaluatePaymentOptions() {
    const evaluation = evaluateApplePayOptions(this.props.membershipBag?.paymentProfiles, !!window?.ApplePaySession);
    if (evaluation === PAYMENT_OPTION_RULE.DISPLAY_APPLE_PAY) {
      this.setState({
        isCtaEnabled: false,
        isApplePayEnabled: true,
        applePayText: this.props.immediateUpgradeLegalText?.text,
        evaluationComplete: true,
      });
      return;
    }

    if (evaluation === PAYMENT_OPTION_RULE.UPDATE_BILLING) {
      this.setState({
        isCtaEnabled: true,
        isApplePayEnabled: false,
        ctaText: "UPDATE BILLING METHOD",
        disableCtaBtn: true,
        evaluationComplete: true,
      });
      return;
    }

    if (evaluation === PAYMENT_OPTION_RULE.DISPLAY_BOTH) {
      this.setState({
        isCtaEnabled: false,
        isApplePayEnabled: true,
        evaluationComplete: true,
        ctaText: "Continue with Card on File",
        displayCtaInternal: true,
      });
      return;
    }

    if (evaluation === PAYMENT_OPTION_RULE.DISPLAY_COF) {
      this.setState({
        isCtaEnabled: true,
        isApplePayEnabled: false,
        evaluationComplete: true,
      });
    }
  }

  render() {
    const {
      membershipState,
      oopsModal,
      immediateUpgradePlanPreview,
      immediateUpgradePlanPreviewLoading,
      immediateUpgradePlanPreviewFail,
      immediateUpgradeLegalText,
      membershipBag,
      flagsAndExperiments,
    } = this.props;

    // Don't render if ineligible
    if (!MembershipHelpers.isEligibleForImmediateSwapUpgrade(membershipState)) {
      return null;
    }

    // If data is unavailable, wait till user tries to open before showing error
    if (
      (!immediateUpgradePlanPreview && !immediateUpgradePlanPreviewLoading && immediateUpgradePlanPreviewFail) ||
      (immediateUpgradePlanPreview && !immediateUpgradePlanPreviewLoading && this.state.invoiceIncludeFailed)
    ) {
      oopsModal();
      return null;
    }

    if (flagsAndExperiments?.[flagsAndExperimentNames.COMET_APPLE_PAY_SUBSCRIPTION]) {
      if (!!membershipBag?.paymentProfiles && !this.state.evaluationComplete) {
        this.evaluatePaymentOptions();
      }
    }

    return (
      <StickyCtaModal
        onRequestClose={this.onDismissModal}
        optionalClass="add-shipment-modal"
        ctaText={this.state.ctaText}
        legal={immediateUpgradeLegalText?.text}
        disabled={!this.state.total || this.state.disableCtaBtn}
        onClick={this.onConfirm}
        isOpen={true}
        isCtaEnabled={this.state.isCtaEnabled}>
        {this.renderHeading()}
        {this.renderPromoCodeError()}
        {this.renderNewPlan()}
        {this.renderBilling()}
        {this.renderDue()}
        {this.state.isApplePayEnabled && this.renderApplePay()}
      </StickyCtaModal>
    );
  }
}

const mapStateToProps = state => {
  return {
    membershipBag: state.membershipBag,
    paymentMethods: state.paymentProfiles,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMembershipBag: () => dispatch(fetchBag()),
    fetchFlags: () => {
      dispatch(FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.COMET_APPLE_PAY_SUBSCRIPTION));
    },
  };
};
export default compose(
  withFlagsAndExperiments(flagsAndExperimentNames.COMET_APPLE_PAY_SUBSCRIPTION),
  connect(mapStateToProps, mapDispatchToProps)
)(AddShipmentModal);
