import React from "react";
import PropTypes from "prop-types";
import exchangeActions from "actions/exchange-actions";
import happinessSurveyActions from "actions/happiness-survey-actions";
import ExchangeButton from "components/source/exchange/ExchangeButton";
import { directToOrderDetails } from "helpers/tracking-helpers";
import { addressPropType } from "components/propTypes";
import { User } from "routes";
import { withFeatureFlags, featureFlagsPropType, Flags } from "components/source/hoc/with-feature-flags";
import { getUserId } from "components/source/hoc/with-user-data";

// To be removed as part of https://renttherunway.jira.com/browse/RSV-989
export class ExchangeBannerComponent extends React.Component {
  static propTypes = {
    addresses: PropTypes.arrayOf(addressPropType),
    children: PropTypes.object,
    dispatch: PropTypes.func,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    featureFlags: featureFlagsPropType,
    happinessSurvey: PropTypes.object,
    selfServiceExchange: PropTypes.object,
    userData: PropTypes.shape({
      memberships: PropTypes.object,
    }),
    userProblems: PropTypes.object,
  };

  static defaultProps = {
    featureFlags: {},
  };

  componentDidMount() {
    this.fetchComponentData();
  }

  checkForExpiredSurveys(props) {
    const { itemSurveys = [] } = props.happinessSurvey || {};
    const { groupEligibility, exchangeGroups } = props.selfServiceExchange || {};

    // get all active happiness surveys
    const surveysToCheck = itemSurveys.filter(survey => survey.inProgress);
    let bookingIdsToCheck = [];

    // filter for just ineligible exchange groups, and gather booking ids from those groups
    Object.keys(groupEligibility || {}).forEach(groupId => {
      const isEligible = groupEligibility[groupId];
      if (!isEligible) {
        const group = exchangeGroups[groupId];
        const bookingIds = group?.bookings?.map(booking => booking?.id);
        bookingIdsToCheck = bookingIdsToCheck.concat(bookingIds);
      }
    });

    // compare booking ids from the active surveys to the ineligible booking ids
    // complete any survey for a booking ID with an ineligible group
    surveysToCheck.forEach(survey => {
      const bookingId = survey?.bookingId?.toString();
      if (bookingIdsToCheck.includes(bookingId)) {
        happinessSurveyActions.completeSurvey(bookingId);
      }
    });
  }

  componentDidUpdate(prevProps) {
    const oldUserId = getUserId(prevProps.userData);
    const newUserId = getUserId(this.props.userData);

    if (oldUserId !== newUserId) {
      this.fetchComponentData();
    }

    if (this.props.happinessSurvey && this.props.selfServiceExchange) {
      this.checkForExpiredSurveys(this.props);
    }
  }

  fetchComponentData() {
    const { dispatch, featureFlags, userData } = this.props;

    if (!getUserId(userData) || !featureFlags[Flags.SELF_SERVICE_EXCHANGE]) return;

    exchangeActions.fetchSelfServiceExchanges()(dispatch);
    happinessSurveyActions.fetchCompletedHappinessSurveys()(dispatch);
  }

  orderId() {
    const { exchangeGroups = [] } = this.props.selfServiceExchange || {};
    const firstGroup = Object.values(exchangeGroups || {})[0];
    return firstGroup?.orderId;
  }

  eligibleExchanges() {
    const groupEligibility = this.props.selfServiceExchange?.groupEligibility || {};
    const exchanges = this.props.selfServiceExchange?.exchanges || [];
    return exchanges.filter(exchange => groupEligibility[exchange.id]);
  }

  hasMultipleEligibleOrders() {
    return this.eligibleExchanges().length > 1;
  }

  hasOrderArrivingToday() {
    const { exchangeGroups = [] } = this.props.selfServiceExchange || {};
    return exchangeGroups.some(group => group?.rentalStartIsToday);
  }

  renderTopLine() {
    const copy = "Yay! Your order has shipped.";

    return (
      <p>
        <span className="bold">{copy}</span>
        <button onClick={() => directToOrderDetails("tracking", this.orderId())}>Track Order</button>
      </p>
    );
  }

  renderBottomLine() {
    if (this.props.featureFlags[Flags.RESERVE_913_PROBLEM_ORDER_FLOW]) {
      const contactLink = "https://www.renttherunway.com/help/faq?_=1&customerpromise=true&placement=banner";
      return (
        <p>
          Don&apos;t love a size or style?{" "}
          <a href={contactLink} target="_blank" rel="noopener noreferrer" className={"btn-link"}>
            Contact Us
          </a>
        </p>
      );
    }

    let link = (
      <ExchangeButton
        banner={true}
        optionalClass="exchange-cta"
        addresses={this.props.addresses}
        dispatch={this.props.dispatch}
        displayedModal={this.props.displayedModal}
        happinessSurvey={this.props.happinessSurvey}
        selfServiceExchange={this.props.selfServiceExchange}
        userData={this.props.userData}
      />
    );
    if (this.hasMultipleEligibleOrders()) {
      link = <a href={User.OrderHistory}>Get Help</a>;
    }

    return <p>Tried it on and didn&apos;t love it? {link}.</p>;
  }

  render() {
    /* we want to suppress the SSE banner if any of these conditions are met:
     * 1. The exchange feature flag is disabled
     * 2. The "showExchangeBanner" field from the backend is false
     * 3. The user has completed the exchange flow for all eligible groups
     */
    if (
      this.props.userProblems?.problemGroupLoading ||
      this.props.userProblems?.problemGroup ||
      !this.props.featureFlags[Flags.SELF_SERVICE_EXCHANGE] ||
      !this.props.selfServiceExchange?.showExchangeBanner
    ) {
      return this.props.children || null;
    }

    return (
      <div className="exchange-banner">
        {this.renderTopLine()}
        {this.renderBottomLine()}
      </div>
    );
  }
}

export default withFeatureFlags(
  Flags.SELF_SERVICE_EXCHANGE,
  Flags.RESERVE_913_PROBLEM_ORDER_FLOW
)(ExchangeBannerComponent);
