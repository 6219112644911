import PropTypes from "prop-types";

import PromptForAuth from "./auth_form/prompt-for-auth";
import VisibleToUser from "./VisibleToUser";
import { authModalPropType } from "../../propTypes/authentication-prop-types";
import { childrenPropType } from "../../propTypes";
import { isAnonymous, isAuthorized, isIdentified, useUserData } from "../hoc/with-user-data";
import { AUTH_STATES } from "rtr-constants";

/**
 * This is a helper layout component to check for a user's authorization state before rendering, otherwise fallback to
 * the PromptForAuth component that dispatches action to launch the authentication modal.
 * @param authModalProps {object} props that get dispatched to auth modal state
 * @param children {ReactNode} React children
 * @param isReadyToPromptForAuth {boolean} boolean to determine if we are ready to dispatch the action to launch the
 * auth modal. Defaults to false.
 * @param requiredAuthState {'authorized' | 'identified' | 'anonymous'} function that takes an object with userData
 * state object as a param that determines if a user can view the child component. Defaults to "authorized".
 * @returns {ReactNode}
 */

const VerifyAuthState = ({
  authModalProps,
  children,
  isReadyToPromptForAuth = false,
  requiredAuthState = AUTH_STATES.AUTHORIZED,
}) => {
  const userData = useUserData({});
  const hasRequiredAuthState = {
    [AUTH_STATES.AUTHORIZED]: isAuthorized(userData),
    [AUTH_STATES.IDENTIFIED]: isIdentified(userData),
    [AUTH_STATES.ANONYMOUS]: isAnonymous(userData),
  }[requiredAuthState];

  return (
    <VisibleToUser
      renderFallbackView={() => (
        <PromptForAuth
          authModalProps={authModalProps}
          isReadyToPromptForAuth={isReadyToPromptForAuth && userData.authDataHasLoaded}
        />
      )}
      userData={userData}
      when={() => hasRequiredAuthState}>
      {children}
    </VisibleToUser>
  );
};

VerifyAuthState.propTypes = {
  authModalProps: authModalPropType.isRequired,
  isReadyToPromptForAuth: PropTypes.bool,
  children: childrenPropType.isRequired,
  requiredAuthState: PropTypes.oneOf([AUTH_STATES.AUTHORIZED, AUTH_STATES.IDENTIFIED, AUTH_STATES.ANONYMOUS])
    .isRequired,
};

export default VerifyAuthState;
