import _ from "underscore";

export default class HistoryState {
  constructor(handleState) {
    this.states = {};

    if (_.isFunction(handleState)) {
      this.handleState = handleState;
    } else {
      this.handleState = _.noop;
    }

    this.initListen();
  }

  initListen() {
    window.addEventListener("popstate", event => {
      const lastState = this.getState(event.state);

      if (lastState) {
        this.handleState(lastState);
      }
    });
  }

  saveState(state) {
    const key = new Date().valueOf() + Math.random();
    this.states[key] = state;
    return key;
  }

  getState(key) {
    return this.states[key];
  }

  replaceState(state, title, path) {
    this.changeState("replaceState", state, title, path);
  }

  pushState(state, title, path) {
    this.changeState("pushState", state, title, path);
  }

  changeState(historyFunctionName, state, title, path) {
    if (_.isFunction(window?.history?.[historyFunctionName])) {
      const key = this.saveState(state);
      window.history[historyFunctionName](key, title, path);
    }

    this.handleState(state);
  }
}
