import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gridProductDrawer: null,
  gridProductDrawerLoadedCss: null,
};

const gridProductDrawerSlice = createSlice({
  name: "gridProductDrawer",
  initialState,
  reducers: {
    setGridProductDrawer: (state, action) => {
      state.gridProductDrawer = action.payload;
    },
    setGridProductDrawerCSSLoaded: (state, action) => {
      state.gridProductDrawerLoadedCss = action.payload;
    },
  },
});

export const closeGridProductDrawer = () => {
  return setGridProductDrawer(null);
};

export const { setGridProductDrawer, setGridProductDrawerCSSLoaded } = gridProductDrawerSlice.actions;
export const actionTypes = {
  SET_GRID_PRODUCT_DRAWER: `${gridProductDrawerSlice.name}/setGridProductDrawer`,
  SET_GRID_PRODUCT_DRAWER_CSS: `${gridProductDrawerSlice.name}/setGridProductDrawerCSSLoaded`,
};

export default {
  types: actionTypes,
  fn: gridProductDrawerSlice.reducer,
};
