const FitRecHelpers = {
  sortedFitRates(fitRates) {
    return [...fitRates]?.sort((a, b) => (a.fitRate < b.fitRate ? 1 : -1));
  },

  isRecommendedFitByPriority(fitRates) {
    const topFit = this.sortedFitRates(fitRates)[0];
    return topFit?.priority === 1 || topFit?.priority === 2;
  },

  recommendedFitType(fitRates, primarySize) {
    if (fitRates?.length === 0) {
      return false;
    }

    const topFit = this.sortedFitRates(fitRates)[0];
    if (this.isGoodFit(topFit, primarySize)) {
      return "Good";
    }

    if (this.isGreatFit(topFit, primarySize)) {
      return "Great";
    }

    return false;
  },

  allRecommended(fitRates, primarySize) {
    return sortedFitRates(fitRates).filter(
      fitRate => isGreatFit(fitRate, primarySize) || isGoodFit(fitRate, primarySize)
    );
  },

  isGoodFit(fitRate, primarySize) {
    return fitRate?.priority === 2 && fitRate?.userSize === primarySize;
  },

  isGreatFit(fitRate, primarySize) {
    return fitRate?.priority === 1 && fitRate?.userSize === primarySize;
  },
};

export default FitRecHelpers;

export const { isGoodFit, isGreatFit, sortedFitRates } = FitRecHelpers;
