import React from "react";
import PropTypes from "prop-types";
import { childrenPropType } from "components/propTypes";
import Modal from "components/source/shared/modal";
import ModalCloseButton from "components/source/shared/modal-close-button";
import AtomFullWidthButton from "components/source/atoms/atom-full-width-button";
import sharedActions from "actions/shared-actions";

class StickyCtaModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    isCloseHidden: PropTypes.bool,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    optionalClass: PropTypes.string,
    optionalWrapperClass: PropTypes.string,
    children: childrenPropType,
    ctaText: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    legal: PropTypes.string,
    isCtaEnabled: PropTypes.bool,
  };

  static defaultProps = {
    isCloseHidden: false,
    legal: null,
    disabled: false,
    optionalWrapperClass: "",
    isCtaEnabled: true,
  };

  close = () => {
    const { dispatch, onRequestClose } = this.props;
    if (dispatch) {
      dispatch(sharedActions.displayModal(false));
    }
    if (onRequestClose && typeof onRequestClose === "function") {
      onRequestClose();
    }
  };

  render() {
    const {
      children,
      isCloseHidden,
      isOpen,
      ctaText,
      onClick,
      optionalClass,
      disabled,
      legal = null,
      isCtaEnabled,
    } = this.props;

    return (
      <Modal className={optionalClass} isOpen={isOpen}>
        <>
          {!isCloseHidden && <ModalCloseButton onClick={this.close} />}
          <div className={`modal-wrapper ${this.props.optionalWrapperClass}`}>{children}</div>
          {isCtaEnabled && (
            <div className="sticky-modal-cta-section">
              <AtomFullWidthButton buttonText={ctaText} disabled={disabled} onClick={onClick}></AtomFullWidthButton>
              {legal && <p className="sticky-modal-cta-legal universal-xsmall--secondary">{legal}</p>}
            </div>
          )}
        </>
      </Modal>
    );
  }
}

export default StickyCtaModal;
