import ActionTypes from "../actions/action-types.js";
import { stateKey as csrfProtection } from "../components/source/hoc/with-csrf-token";

// The actions specified by keys in this object will replace the state key
// specified by the corresponding value in this object with `action.payload`.
// In other words: if `action.type` is `ActionTypes.SUBMIT_FILTERS`,
// `state.filters` will be replaced with `action.payload`
const stateReplacementActions = {
  [ActionTypes.ADDRESS_BOOK_IS_LOADING]: "isAddressBookLoading",
  [ActionTypes.ADDRESS_BOOK_LOADED]: "addresses",
  [ActionTypes.ADDRESS_IS_UPDATING]: "isAddressUpdating",
  [ActionTypes.ADDRESS_UPDATED]: "updatedShippingAddress",
  [ActionTypes.ANALYTICS_PREVIOUS_LOCATION_CONTEXT_SET]: "analyticsPreviousLocationContext",
  [ActionTypes.BAG_IS_OPEN]: "bagIsOpen",
  [ActionTypes.BAG_SWITCH_TAB]: "bagTab",
  [ActionTypes.BAG_TAB_ID]: "bagTabId",
  [ActionTypes.BAG_PRO_DISMISSED]: "isProDismissed",
  [ActionTypes.CART_ABANDONMENT_STATE_SET]: "cartAbandonmentStateTimestamp",
  [ActionTypes.CANCEL_POST_PENDING]: "cancelPostPending",
  [ActionTypes.CHANGE_CATEGORY]: "hierarchy",
  [ActionTypes.CHANGE_FILTERS]: "workingFilters",
  [ActionTypes.CHANGE_SORT]: "sort",
  [ActionTypes.COLLECTION_CAROUSEL_SUCCESS]: "collectionCarouselSuccess",
  [ActionTypes.CREATE_SHORTLIST_ERROR]: "searchByDateModal",
  [ActionTypes.CREATE_SHORTLIST_SUCCESS]: "createShortlistSuccess",
  [ActionTypes.CROP_REVIEW_PHOTO_LOADING]: "cropReviewPhotoLoading",
  [ActionTypes.CROP_REVIEW_PHOTO_FAILURE]: "cropReviewPhotoErrorMessage",
  [ActionTypes.DISPLAY_HOME_PICKUP_OVERLAY]: "displayHomePickupOverlay",
  [ActionTypes.DISPLAY_HOME_PICKUP_SERVER_ERROR]: "displayHomePickupServerError",
  [ActionTypes.DISPLAY_HOME_PICKUP_CONFIRMATION_MODAL]: "displayHomePickupConfirmationModal",
  [ActionTypes.DISPLAY_HOME_PICKUP_UNAVAILABLE_MODAL]: "homePickupAddress",
  [ActionTypes.DISPLAY_MODAL]: "displayedModal",
  [ActionTypes.DISPLAY_HIGHLIGHTED_COMPONENT]: "highlightedComponent",
  [ActionTypes.FETCH_FAVORITES_SUCCESS]: "favorites",
  [ActionTypes.FETCH_USER_CREDITS_SUCCESS]: "credits",
  [ActionTypes.FETCH_MEMBERSHIP_RENTAL_BEGIN_SUCCESS]: "membershipRentalBeginDate",
  [ActionTypes.FETCH_PENDING_REVIEWS_SUCCESS]: "pendingReviews",
  [ActionTypes.FETCH_PRO_MEMBERSHIP_SUCCESS]: "proStatus",
  [ActionTypes.FETCH_SHORTLISTS_SUCCESS]: "shortlists",
  [ActionTypes.FETCH_SSE_ACTIVE_ORDERS_SUCCESS]: "sseActiveOrders",
  [ActionTypes.FETCH_USER_REVIEW_SUMMARY_FAILURE]: "fetchUserReviewSummaryFailure",
  [ActionTypes.FETCHING_DEFAULT_SHIPPING_ADDRESS]: "fetchingDefaultShippingAddress",
  [ActionTypes.HAS_FILE_SIZE_ERROR]: "hasFileSizeError",
  [ActionTypes.HAPPINESS_SURVEY_FETCH]: "isHappinessSurveyFetched",
  [ActionTypes.HEART_EDUCATION_TOOLTIP_DISMISSED]: "heartEducationTooltipDismissed",
  [ActionTypes.LOADING]: "loading",
  [ActionTypes.LOADING_MEMBERSHIP_RENTAL_BEGIN]: "loadingMembershipRentalBeginDate",
  [ActionTypes.LOADING_REVIEW_PHOTOS]: "loadingPhotos",
  [ActionTypes.LOADING_ROTATED_REVIEW_PHOTO]: "loadingRotatedPhoto",
  [ActionTypes.LOYALTY_AWARDS_LOADING]: "loyaltyAwardsLoading",
  [ActionTypes.LOYALTY_AWARDS_FAILED]: "loyaltyAwardsFailed",
  [ActionTypes.MEMBERSHIP_CHANGE_PLAN_PREVIEW_LOAD_SUCCESS]: "membershipChangePlanPreview",
  [ActionTypes.MEMBERSHIP_CHARGE_EXECUTE_PENDING]: "membershipChargeExecutePending",
  [ActionTypes.MEMBER_PROGRAM_CHANGE_INFO]: "memberProgramChangeInfo",
  [ActionTypes.MEMBER_PROGRAM_CHANGE_INFO_FAIL]: "memberProgramChangeInfoFail",
  [ActionTypes.MEMBER_PROGRAM_CHANGE_INFO_LOADING]: "memberProgramChangeInfoLoading",
  [ActionTypes.MEMBERSHIP_CHANGE_PLAN_PREVIEW_ALL_LOAD_SUCCESS]: "membershipChangePlanPreviewAll",
  [ActionTypes.MEMBERSHIP_CHANGE_PLAN_PREVIEW_LOADING]: "membershipChangePlanPreviewLoading",
  [ActionTypes.IMMEDIATE_UPGRADE_PLAN_PREVIEW_LOADING]: "immediateUpgradePlanPreviewLoading",
  [ActionTypes.IMMEDIATE_UPGRADE_PLAN_PREVIEW]: "immediateUpgradePlanPreview",
  [ActionTypes.IMMEDIATE_UPGRADE_PLAN_PREVIEW_FAIL]: "immediateUpgradePlanPreviewFail",
  [ActionTypes.IN_SSC_FLOW]: "inSSCFlow",
  [ActionTypes.MEMBERSHIP_CHANGE_PLAN_PREVIEW_FAIL]: "membershipChangePlanPreviewFail",
  [ActionTypes.MEMBERSHIP_CREATE_CHANGE_TIER_PAYMENT_ORDER_PENDING]: "membershipCreateChangeTierPaymentOrderPending",
  [ActionTypes.MEMBERSHIP_TIER_LOAD_SUCCESS]: "membershipTier",
  [ActionTypes.MEMBERSHIP_TIER_LOADING]: "membershipTierLoading",
  [ActionTypes.MEMBERSHIP_TIERS_LOAD_SUCCESS]: "membershipTiers",
  [ActionTypes.MEMBERSHIP_TIERS_LOAD_FAILURE]: "membershipTiersFailure",
  [ActionTypes.MEMBERSHIP_TIERS_LOADING]: "membershipTiersLoading",
  [ActionTypes.MID_GRID_CONTENT_FAILURE]: "midGridContentFailure",
  [ActionTypes.MID_GRID_CONTENT_LOADING]: "midGridContentLoading",
  [ActionTypes.MID_GRID_CONTENT_SUCCESS]: "midGridContent",
  [ActionTypes.MODAL_HASH_CHANGE]: "modalHash",
  [ActionTypes.ORDER_HISTORY_SELECT_SELF_SERVICE_ORDER_GROUP]: "orderHistorySelectedSelfServiceOrderGroup",
  [ActionTypes.ORDER_HISTORY_TOTAL_COUNT_SUCCESS]: "orderHistoryTotalCount",
  [ActionTypes.ONE_TIME_MODALS_FETCH]: "oneTimeModals",
  [ActionTypes.ONE_TIME_MODALS_LOADING]: "oneTimeModalsLoading",
  [ActionTypes.ONE_TIME_MODALS_FAILED]: "oneTimeModalsFailed",
  [ActionTypes.PAYMENT_METHODS_UPDATING]: "updatingPaymentMethods",
  [ActionTypes.PAYMENT_PROFILES_IS_LOADING]: "isPaymentProfilesLoading",
  [ActionTypes.PAYMENT_PROFILES_LOADED]: "paymentProfiles",
  [ActionTypes.PICK_UP_IN_STORE_ERROR]: "pickUpInStoreError",
  [ActionTypes.RECEIVE_REVIEWS]: "reviews",
  [ActionTypes.RECEIVE_REVIEWS_COVER_PHOTOS]: "reviewsCoverPhotos",
  [ActionTypes.RECOMMENDATION_CAROUSEL_SUCCESS]: "recommendationCarouselSuccess",
  [ActionTypes.RENT_THE_LOOK_LOADING]: "rentTheLookLoading",
  [ActionTypes.RENT_THE_LOOK_SUCCESS]: "rentTheLookSuccess",
  [ActionTypes.SEARCH_PRODUCTS_RESULTS_RECEIVED]: "searchProductsResults",
  [ActionTypes.SEARCH_TYPEAHEADS_RESULTS_RECEIVED]: "searchTypeaheadsResults",
  [ActionTypes.SET_ADDRESS_ID]: "selectedAddressId",
  [ActionTypes.SET_CSRF_PROTECTION]: csrfProtection,
  [ActionTypes.SET_FILTERS_OPEN]: "mobileFiltersOpen",
  [ActionTypes.SET_FORMER_UNLIMITED]: "formerUnlimited",
  [ActionTypes.SET_FORMER_UNLIMITED_LOADED]: "formerUnlimitedLoaded",
  [ActionTypes.SET_HOME_PICKUP_ADDRESS]: "homePickupAddress",
  [ActionTypes.SET_HOME_PICKUP_ELIGIBLE_ORDERS]: "homePickupEligibleOrders",
  [ActionTypes.SET_NEW_CUSTOMER_OFFER_TEXT]: "newCustomerOfferText",
  [ActionTypes.SET_PAGE_NAME]: "pageName",
  [ActionTypes.SET_PAGE_TYPE]: "pageType",
  [ActionTypes.SET_PRODUCT]: "product",
  [ActionTypes.SET_UPCOMING_HOME_PICKUP_DATE]: "upcomingHomePickupDate",
  [ActionTypes.SET_USER_STYLE_PREFERENCES]: "userStylePreferences",
  [ActionTypes.SHOPPABLE_IMAGES]: "shoppableImages",
  [ActionTypes.SHOW_MODAL]: "modalIsOpen",
  [ActionTypes.SHOW_SIZE_FILTER_PROMPT]: "isSizeFilterPrompt",
  [ActionTypes.SIMILAR_STYLES_LOADING]: "similarStylesLoading",
  [ActionTypes.SIMILAR_STYLES_SUCCESS]: "similarStylesSuccess",
  [ActionTypes.SUPPRESS_SKINNY_BANNER]: "isSkinnyBannerSuppressedOnPage",
  [ActionTypes.SWAP_COUNTER_TOOLTIP_DISMISSED]: "swapCounterTooltipDismissed",
  [ActionTypes.SWAP_COUNTER_TOOLTIP_TRIGGERED]: "swapCounterTooltipTriggered",
  [ActionTypes.TOGGLE_MOBILE_NAV]: "isMobileNavExpanded",
  [ActionTypes.TOGGLE_MOBILE_NAV_ACCOUNT_MENU]: "isMobileAccountMenuExpanded",
  [ActionTypes.TOGGLE_SEARCH_EXPANDED]: "isNavSearchExpanded",
  [ActionTypes.TOGGLE_SNEAKY_HEADER_VISIBLE]: "ensureSneakyHeaderVisible",
  [ActionTypes.TRIGGER_ERROR]: "errors",
  [ActionTypes.UPDATE_REVIEW_PHOTO_FAILURE]: "reviewPhotoError",
  [ActionTypes.USER_HISTORY_STATS]: "userHistoryStats",
  [ActionTypes.USER_HISTORY_STATS_LOADING]: "userHistoryStatsLoading",
  [ActionTypes.USER_HISTORY_STATS_FAILURE]: "userHistoryStatsFailure",
  [ActionTypes.USER_REVIEW_SUMMARY]: "userReviewSummary",
  [ActionTypes.YOU_MAY_ALSO_LIKE_LOADING]: "youMayAlsoLikeLoading",
  [ActionTypes.YOU_MAY_ALSO_LIKE_SUCCESS]: "youMayAlsoLikeSuccess",
  [ActionTypes.UPDATE_CURRENT_PAGE]: "currentPage",
};

const fn = function (state, action) {
  const stateReplacementAction = stateReplacementActions[action.type];

  return {
    ...state,
    [stateReplacementAction]: action.payload,
  };
};

export default {
  types: Object.keys(stateReplacementActions),
  fn,
};
