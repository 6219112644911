import { ThirdPartyScript } from "./thirdPartyScript";
import { shallowEqual, useSelector } from "react-redux";
import { useContentfulContent } from "../ContentfulContext";

// OneTrust Cookies Consent Notice start for renttherunway.com
const oneTrust = () => {
  const publicEnv = useSelector(state => state.publicEnv, shallowEqual);
  //Suppress the OneTrust banner when previewing via Contentful
  //It has to be dismissed every time the editor is loaded and is cumbersome
  //Only internal users would see this experience
  const { isPreview } = useContentfulContent() || {};

  if (publicEnv?.onetrustDataDomainId && !isPreview) {
    return (
      <>
        <ThirdPartyScript
          id="onetrust-script"
          strategy="afterInteractive"
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-language="en"
          data-domain-script={publicEnv.onetrustDataDomainId}></ThirdPartyScript>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              function OptanonWrapper() {
                window.dispatchEvent(new CustomEvent("oneTrustLoadEvent"));
              }
            `,
          }}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default oneTrust;
