import ActionTypes from "actions/action-types";
import { membershipTriggeredModalsCMSKeys, CMS_PRICE_FILLER_KEY, TRIGGERED_MODALS_KEY } from "rtr-constants";
import { findAmountForType, formattedPrice } from "helpers/invoice-helper";

const changeModal = (state, action, changesFn) => {
  const modalName = action?.payload?.modalName ?? "";
  const cmsContent = state.cmsContent || {};
  const triggeredModals = cmsContent[TRIGGERED_MODALS_KEY] || {};
  const currModal = triggeredModals[modalName];

  //  The resumeIntentConfirmation modal is updated with data fetched from
  //  membership-tier-actions.getMembershipPausedChangePlanPreview, and
  //  the CMS should not be altered here
  //  The string substitution is handled directly in the ResumeIntentConfirmationModal
  if (modalName === membershipTriggeredModalsCMSKeys.resumeIntentConfirmation) {
    return {
      ...state,
      cmsContent: {
        ...cmsContent,
        [TRIGGERED_MODALS_KEY]: {
          ...triggeredModals,
          [modalName]: { ...currModal, loading: false },
        },
      },
    };
  }

  return {
    ...state,
    cmsContent: {
      ...cmsContent,
      [TRIGGERED_MODALS_KEY]: {
        ...triggeredModals,
        [modalName]: { ...currModal, ...changesFn(currModal) },
      },
    },
  };
};

const stateWithModalPricePlaceholderReplaced = (actualPrice, state, action) => {
  return changeModal(state, action, currModal => ({
    dek: (currModal?.dek ?? "").replace(CMS_PRICE_FILLER_KEY, actualPrice),
    loading: false,
  }));
};

const types = [
  ActionTypes.MEMBERSHIP_CHARGE_GET_AMOUNT_PENDING,
  ActionTypes.MEMBERSHIP_CHARGE_GET_AMOUNT_SUCCESS,
  ActionTypes.MEMBERSHIP_CHARGE_GET_CHANGE_TIER_AMOUNT_SUCCESS,
];

const fn = (state, action) => {
  switch (action.type) {
    case ActionTypes.MEMBERSHIP_CHARGE_GET_AMOUNT_PENDING: {
      return changeModal(state, action, () => ({ loading: true }));
    }

    case ActionTypes.MEMBERSHIP_CHARGE_GET_AMOUNT_SUCCESS: {
      const memberPrice = action?.payload?.price ?? "";

      return stateWithModalPricePlaceholderReplaced(memberPrice, state, action);
    }
    case ActionTypes.MEMBERSHIP_CHARGE_GET_CHANGE_TIER_AMOUNT_SUCCESS: {
      if (action.payload?.totals) {
        const totalPrice = findAmountForType(action.payload.totals, "total");
        const totalPriceFormatted = formattedPrice(totalPrice, false);

        return stateWithModalPricePlaceholderReplaced(totalPriceFormatted, state, action);
      }
      return state;
    }

    default: {
      return state;
    }
  }
};

export default { types, fn };
