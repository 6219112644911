import { hexSha256Hash } from "helpers/hashing-helper";
import { checkout } from "rtr-constants";
import { priceStringIntoFloat, findAmountForType } from "helpers/invoice-helper";
import { calculatePriceBeforeTax } from "helpers/ce-checkout-helpers";
import { specialSkus, CEBagItemTypes } from "../constants";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const { orderItemTypes } = checkout;

const subscriptionSubTypes = [
  orderItemTypes.PRO_SUBSCRIPTION,
  orderItemTypes.UPDATE_SUBSCRIPTION,
  orderItemTypes.UNLIMITED_SUBSCRIPTION,
];

const rtr_id = () => cookies.get("RTR_ID");
const rtr_sess = () => cookies.get("RTR_SESS");

export const cvg = (...args) => {
  function cvg() {
    cvg.process ? cvg.process.apply(cvg, arguments) : cvg.queue.push(arguments);
  }
  cvg.queue = [];
  window.cvg = window.cvg || cvg;
  return window.cvg(...args);
};

const removePunctuation = str => str?.replace(/[^A-Za-z0-9 ]/, "");

const hashEmail = async email => hexSha256Hash(email?.trim()?.toLowerCase());

const hashName = async firstName => hexSha256Hash(removePunctuation(firstName?.trim()?.toLowerCase()));

const hashDateOfBirth = async dob => hexSha256Hash(dob?.split("T")[0]?.replace("-", ""));

const hashPhoneNumber = async ph => {
  if (!ph) return;
  let phNormalized = ph.replace(/\D+/g, ""); // only keep digits
  if (phNormalized.length === 10) phNormalized = "1" + phNormalized; // US area code
  return hexSha256Hash(phNormalized);
};

const hashZipcode = async zc => {
  if (!zc) return;
  const zcNormalized = zc.replace(/[^a-z0-9]/gi, ""); // alphanumeric only
  return hexSha256Hash(zcNormalized.trim().toLowerCase());
};

const hashCity = async city => {
  if (!city) return;
  const cityNormalized = city.replace(/[^a-z0-9]/gi, ""); // alphanumeric only
  return hexSha256Hash(cityNormalized.trim().toLowerCase());
};

const hashState = async state => {
  if (!state) return;
  const stateNormalized = state.replace(/[^a-z0-9]/gi, ""); // alphanumeric only
  return hexSha256Hash(stateNormalized.trim().toLowerCase());
};

const hashCountry = async country => {
  return hexSha256Hash(country.trim().toLowerCase());
};

const processUserData = async userData => {
  if (!userData?.userProfile)
    return {
      eventProperties: { is_logged_in: false, is_member: false },
    };
  const { id, email, cellPhoneNumber, firstName, lastName, dateOfBirth } = userData.userProfile;
  const email_sha256 = await hashEmail(email);
  const hashedUserId = await hexSha256Hash(id?.toString());
  let profileProperties = {
    // these properties must start with '$' to be recognized by Converge
    $customer_id: hashedUserId,
    $email_sha256: email_sha256,
    $phone_number_sha256: await hashPhoneNumber(cellPhoneNumber),
    $date_of_birth_sha256: await hashDateOfBirth(dateOfBirth),
    $first_name_sha256: await hashName(firstName),
    $last_name_sha256: await hashName(lastName),
  };
  if (userData.memberships?.membershipState?.address) {
    profileProperties = {
      ...profileProperties,
      ...(await processAddress(userData.memberships.membershipState.address)),
    };
  }
  const aliases = [];
  if (email) aliases.push(`urn:email_sha256:${email_sha256}`);
  if (hashedUserId) aliases.push(`urn:rtr:user_id_sha256:${hashedUserId}`);
  return {
    aliases,
    profileProperties,
    eventProperties: {
      is_logged_in: true,
      is_member: !!userData.memberships?.isSubscriptionMember,
    },
  };
};

const getPrice = (type, price, purchasePrice) => {
  if (type === "rent" || type === "swap") {
    return price?.adjusted || 0.0;
  }
  return priceStringIntoFloat(purchasePrice);
};

const processProduct = (product, type) => {
  const { colors, category, formality, id, price, purchasePrice, displayName, eligibleFor, designer } = product;
  return {
    product_id: id,
    name: displayName,
    price: getPrice(type, price, purchasePrice),
    currency: "USD",
    colors,
    formality,
    category: category?.id,
    designer: designer?.id,
    eligible_for: eligibleFor,
    type,
  };
};

const processMembership = membership => {
  return {
    product_id: specialSkus.membershipStyles.UPDATE,
    name: "RTR Membership",
    price: priceStringIntoFloat(membership?.basePrice ? membership?.basePrice : ""),
    currency: "USD",
    membership_tier: membership.id,
    monthly_shipment_limit: membership.monthlyShipmentLimit,
    slot_count: membership.slotCount,
  };
};

const processInvoice = invoice => {
  const { groups, totals } = invoice;
  const lineItems = groups?.map(group => group.lineItems).flat();
  if (!totals) throw Error("Invalid invoice object, does not contain totals");
  if (!lineItems) throw Error("Invalid invoice object, does not contain line items");
  const subTypes = lineItems.map(lineItem => lineItem.orderItem.subType);
  return {
    id: invoice.orderId.toString(),
    total_price: priceStringIntoFloat(findAmountForType(totals, "preTax")) || 0,
    total_discount: Math.abs(priceStringIntoFloat(findAmountForType(totals, "promo")) || 0),
    total_shipping: priceStringIntoFloat(findAmountForType(totals, "discountedShipping")) || 0,
    total_insurance: priceStringIntoFloat(findAmountForType(totals, "discountedInsurance")) || 0,
    currency: "USD",
    items: lineItems.map(lineItem => {
      const discountedSub = priceStringIntoFloat(findAmountForType(lineItem.totals, "discountedSub")) || 0;
      const discount = Math.abs(priceStringIntoFloat(findAmountForType(lineItem.totals, "promo")) || 0);
      const insurance = priceStringIntoFloat(findAmountForType(lineItem.totals, "discountedInsurance")) || 0;
      return {
        id: lineItem.id,
        product_id: lineItem.sku.styleName,
        name: lineItem.orderItem.product?.displayName,
        price: discountedSub + insurance,
        discount,
        insurance,
        currency: "USD",
        quantity: 1,
        category: lineItem.orderItem.product?.category?.id,
        membership_tier: lineItem.orderItem.membershipTierId,
        sub_type: lineItem.orderItem.subType,
      };
    }),
    contains_membership: subscriptionSubTypes.some(subType => subTypes.includes(subType)),
    contains_rental: subTypes.includes(orderItemTypes.CLASSIC),
    contains_clearance: subTypes.includes(orderItemTypes.CLEARANCE),
    contains_kif: subTypes.includes(orderItemTypes.KIF_FROM_RACK),
  };
};

const processCheckout = (checkout, orderId = null) => {
  return {
    id: orderId,
    checkout_id: checkout?.id,
    total_price: calculatePriceBeforeTax(checkout?.charges) ?? 0,
    total_discount: Math.abs(priceStringIntoFloat(checkout?.charges?.totals?.promoItem ?? 0)),
    total_shipping: priceStringIntoFloat(checkout?.charges?.totals?.shipping ?? 0),
    total_insurance: priceStringIntoFloat(checkout?.charges?.totals?.insurance ?? 0),
    currency: "USD",
    items: checkout?.bagItems?.map(item => {
      const discount = Math.abs(priceStringIntoFloat(item.totals?.promoItem ?? 0));
      const insurance = priceStringIntoFloat(item.totals?.insurance ?? 0);
      return {
        product_id: item.styleName,
        name: item.displayName,
        price: priceStringIntoFloat(item.totals?.grand),
        discount,
        insurance,
        currency: "USD",
        quantity: 1,
        category: item.category ?? null,
        membership_tier: item.membershipTierId ?? null,
        sub_type: item.type ?? null,
      };
    }),
    contains_membership: checkout.bagItems?.[0]?.type === CEBagItemTypes.SUBSCRIPTION,
    contains_rental: checkout.bagItems?.[0]?.type === CEBagItemTypes.RESERVE,
    contains_clearance: checkout.bagItems?.[0]?.type === CEBagItemTypes.CLEARANCE,
    contains_kif: checkout.bagItems?.[0]?.type === CEBagItemTypes.KIF_FROM_RACK,
  };
};

const processAddress = async address => {
  const { firstName, lastName, phone, postalCode, zoneCode, city } = address;
  return {
    // these properties must start with '$' to be recognized by Converge
    $phone_number_sha256: await hashPhoneNumber(phone),
    $first_name_sha256: await hashName(firstName),
    $last_name_sha256: await hashName(lastName),
    $city_sha256: await hashCity(city),
    $state_sha256: await hashState(zoneCode),
    $zip_code_sha256: await hashZipcode(postalCode),
    $country_code_sha256: await hashCountry("US"),
  };
};

const checkoutFromItems = (items, id, currency) => {
  return {
    id,
    total_price: items.reduce((acc, item) => acc + item.price, 0),
    total_discount: items.reduce((acc, item) => acc + item.discount, 0),
    total_insurance: items.reduce((acc, item) => acc + item.insurance, 0),
    total_shipping: 0, // shipping is not included on filtered (secondary) conversions
    currency,
    items,
  };
};

const addAliasesIfNotPresent = (aliasArray = []) => {
  const rtrId = rtr_id();
  const rtrSess = rtr_sess();
  const browser_id = `urn:rtr:browser_id:${rtrId}`;
  const session_id = `urn:rtr:session_id:${rtrSess}`;
  const aliases = aliasArray;

  if (!aliases.includes(browser_id) && !aliases.includes(session_id)) {
    aliases.push(browser_id, session_id);
  }

  return aliases;
};

const track = ({ eventName, properties, eventID, profileProperties, aliasArray }) => {
  const aliases = addAliasesIfNotPresent(aliasArray);
  cvg({
    method: "track",
    eventName,
    properties,
    eventID,
    profileProperties,
    aliases,
  });
};

const forward = ({ eventName, properties, eventID, profileProperties }) => {
  const aliases = addAliasesIfNotPresent();
  cvg({
    method: "forward",
    eventName,
    properties,
    eventID,
    profileProperties,
    aliases,
  });
};

const set = ({ properties, aliasArray, eventProperties }) => {
  const aliases = addAliasesIfNotPresent(aliasArray);
  cvg({
    method: "set",
    properties,
    aliases,
    eventProperties,
  });
};

const handleException = error => {
  window.Sentry?.captureException(error, {
    tags: {
      thirdparty: true,
      thirdparty_name: "converge",
    },
  });
};

const logTrackingError = callback => (...args) => {
  try {
    return callback(...args);
  } catch (error) {
    handleException(error);
  }
};

const setUserData = async userData => {
  try {
    const { aliases, profileProperties, eventProperties } = await processUserData(userData);
    set({ properties: profileProperties, aliasArray: aliases, eventProperties });
  } catch (error) {
    handleException(error);
  }
};

const setAddressData = async address => {
  try {
    const profileProperties = await processAddress(address);
    set({ properties: profileProperties });
  } catch (error) {
    handleException(error);
  }
};

export const ConvergeEvents = {
  VIEWED_PAGE: "$page_load",
  VIEWED_PRODUCT: "Viewed Product",
  VIEWED_SUBSCRIPTION_PLANS: "Viewed Subscription Plans",
  SELECTED_PRODUCT: "Selected Product",
  ADDED_TO_CART: "Added To Cart",
  STARTED_CHECKOUT: "Started Checkout",
  ADDED_CONTACT_INFO: "Added Contact Info",
  ADDED_PAYMENT_INFO: "Added Payment Info",
  PLACED_ORDER: "Placed Order",
  STARTED_SUBSCRIPTION: "Started Subscription",
  PLACED_RESERVE_ORDER: "Placed Reserve Order",
  PLACED_KIF_ORDER: "Placed Kif Order",
  PLACED_CLEARANCE_ORDER: "Placed Clearance Order",
};

const trackViewedPage = () => track({ eventName: ConvergeEvents.VIEWED_PAGE });
const trackViewedProduct = (product, isRentable) => {
  const type = isRentable ? "rent" : "clearance";
  track({
    eventName: ConvergeEvents.VIEWED_PRODUCT,
    properties: processProduct(product, type),
  });
};
const trackViewedSubscriptionPlans = () => track({ eventName: ConvergeEvents.VIEWED_SUBSCRIPTION_PLANS });
const trackSelectedProduct = product =>
  track({
    eventName: ConvergeEvents.SELECTED_PRODUCT,
    properties: processProduct(product, "rent"),
  });
const trackAddedToCart = (
  product,
  type // rent / swap / clearance / kiffr
) =>
  track({
    eventName: ConvergeEvents.ADDED_TO_CART,
    properties: processProduct(product, type),
  });
const trackAddedMembershipToCart = membership =>
  track({
    eventName: ConvergeEvents.ADDED_TO_CART,
    properties: {
      ...processMembership(membership),
      type: "membership",
    },
  });
const trackStartedCheckout = invoice =>
  track({
    eventName: ConvergeEvents.STARTED_CHECKOUT,
    properties: processInvoice(invoice),
  });
const trackStartedStandardCheckout = checkout =>
  track({
    eventName: ConvergeEvents.STARTED_CHECKOUT,
    properties: processCheckout(checkout),
  });
const trackAddedContactInfo = invoice =>
  track({
    eventName: ConvergeEvents.ADDED_CONTACT_INFO,
    properties: processInvoice(invoice),
  });
const trackAddedPaymentInfo = invoice =>
  track({
    eventName: ConvergeEvents.ADDED_PAYMENT_INFO,
    properties: processInvoice(invoice),
  });
const trackConversion = invoice => {
  const processedInvoice = processInvoice(invoice);
  const membershipItems = processedInvoice.items.filter(item => subscriptionSubTypes.includes(item.sub_type));
  const reserveItems = processedInvoice.items.filter(item => item.sub_type === orderItemTypes.CLASSIC);
  const clearanceItems = processedInvoice.items.filter(item => item.sub_type === orderItemTypes.CLEARANCE);
  const kifItems = processedInvoice.items.filter(item => item.sub_type === orderItemTypes.KIF_FROM_RACK);

  forward({
    eventName: ConvergeEvents.PLACED_ORDER,
    eventID: processedInvoice.id,
    properties: processedInvoice,
  });

  const subEvents = [
    { eventName: ConvergeEvents.STARTED_SUBSCRIPTION, items: membershipItems },
    { eventName: ConvergeEvents.PLACED_CLEARANCE_ORDER, items: clearanceItems },
    { eventName: ConvergeEvents.PLACED_RESERVE_ORDER, items: reserveItems },
    { eventName: ConvergeEvents.PLACED_KIF_ORDER, items: kifItems },
  ];

  for (const { eventName, items } of subEvents) {
    if (items.length > 0) {
      forward({
        eventName,
        eventID: processedInvoice.id,
        properties: checkoutFromItems(items, processedInvoice.id, processedInvoice.currency),
      });
    }
  }
};

const trackCheckoutConversion = (checkout, orderId) => {
  const processedCheckout = processCheckout(checkout, orderId);

  forward({
    eventName: ConvergeEvents.PLACED_ORDER,
    eventID: processedCheckout.id,
    properties: processedCheckout,
  });

  const eventMap = {
    SubscriptionBagItem: ConvergeEvents.STARTED_SUBSCRIPTION,
    ClearanceBagItem: ConvergeEvents.PLACED_CLEARANCE_ORDER,
    ReserveBagItem: ConvergeEvents.PLACED_RESERVE_ORDER,
    ElectiveSaleBagItem: ConvergeEvents.PLACED_KIF_ORDER,
  };

  const items = checkout?.bagItems;
  // the new checkout items are limited to a single type, so we can infer
  // the secondary conversion type based on the first element and pass the same properties
  forward({
    eventName: eventMap[items[0].type],
    eventID: processedCheckout.id,
    properties: processedCheckout,
  });
};

export default {
  setUserData,
  setAddressData,
  trackViewedPage: logTrackingError(trackViewedPage),
  trackViewedProduct: logTrackingError(trackViewedProduct),
  trackViewedSubscriptionPlans: logTrackingError(trackViewedSubscriptionPlans),
  trackSelectedProduct: logTrackingError(trackSelectedProduct),
  trackAddedToCart: logTrackingError(trackAddedToCart),
  trackAddedMembershipToCart: logTrackingError(trackAddedMembershipToCart),
  trackStartedCheckout: logTrackingError(trackStartedCheckout),
  trackAddedContactInfo: logTrackingError(trackAddedContactInfo),
  trackAddedPaymentInfo: logTrackingError(trackAddedPaymentInfo),
  trackConversion: logTrackingError(trackConversion),
  trackCheckoutConversion: logTrackingError(trackCheckoutConversion),
  trackStartedStandardCheckout: logTrackingError(trackStartedStandardCheckout),
};
