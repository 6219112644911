import { sendSplunkRUMEvent } from "./analytics-integrations";

function generateNodeDescriptor(node) {
  return node.tagName
    ? `<${node.tagName.toLowerCase()} ${node
        .getAttributeNames()
        .map(n => n + '="' + node.getAttribute(n) + '"')
        .join(" ")}>`
    : "unknown";
}

export function registerPerformanceMonitoring() {
  const observer = new PerformanceObserver(list => {
    for (const entry of list.getEntries()) {
      // Count 'significant' layout shifts without recent user input only
      if (!entry.hadRecentInput && entry.value >= 0.001) {
        if (entry.sources) {
          /** @type {Array<LayoutShiftAttribution>} */
          const sources = entry.sources;
          const nodes = sources
            .filter(s => !!s.node?.tagName)
            .map(s => generateNodeDescriptor(s.node))
            .join(",");
          sendSplunkRUMEvent({
            action: "layout-shift",
            nodes: nodes ?? "unknown",
            value: entry.value,
            startTime: entry.startTime,
          });
        }
      }
    }
  });

  // specifically observe layout shifts
  observer.observe({ type: "layout-shift", buffered: true });
}
