import MembershipHelpers from "helpers/membership-helpers";
import UAParser from "ua-parser-js";
import HeapHelpers from "helpers/heap-helpers";
import { formatAbsolutePriceAsFloat } from "helpers/invoice-helper";
import { differenceInCalendarDays, parseISO } from "date-fns";

// kifah
export const fireCEHeapEvent = (eventName, data = {}) => {
  return function (_dispatch, getState) {
    const { membershipState, userData } = getState();
    const membershipTypeForAnalytics = MembershipHelpers.getMembershipTypeAnalytics(userData, membershipState);

    const parser = new UAParser(navigator.userAgent);

    const payload = {
      user: {
        browser: `${parser.getOS().name} ${parser.getBrowser().name}`,
        membershipTermInMonths: membershipState?.membershipTermNumber,
        membershipType: membershipTypeForAnalytics,
        id: userData?.userProfile?.id,
      },
      data,
    };

    HeapHelpers.fireHeapEvent(eventName, payload);
  };
};

const withCEMetadata = bagFunction => (...args) => {
  const parser = new UAParser(navigator.userAgent);
  const metadata = {
    browser: parser.getBrowser().name,
    platform: parser.getOS().name,
    device: parser.getDevice().type,
  };

  const [productType, productTypeDetail, options = {}] = args;
  const updatedOptions = { ...options, ...metadata };
  return bagFunction(productType, productTypeDetail, updatedOptions);
};

// Wrap trackEvent with metadata from state
export const addToBagCEEvent = withCEMetadata(HeapHelpers.trackAddToBag);
export const removeFromBagCEEvent = withCEMetadata(HeapHelpers.trackRemoveFromBag);

export const applePayEventData = checkout => {
  if (!checkout) return;

  const subTotal = formatAbsolutePriceAsFloat(checkout.charges?.totals?.sub);
  const productIds = JSON.stringify(checkout.bagItems?.map(item => item.productId)) ?? [];
  const promoValue = formatAbsolutePriceAsFloat(checkout.charges?.totals?.promoItem) ?? null;
  const grandTotal = formatAbsolutePriceAsFloat(checkout.charges?.totals?.grand);
  const promoCode = checkout.promoCode ?? null;

  return {
    id: checkout.id,
    promoCode,
    subTotal,
    productIds,
    promoValue,
    grandTotal,
  };
};

/**
 * The following functionality is to replace the heap-invoice-helpers
 * They rely on previewInvoice which is no longer accessible in standard checkout
 * The data is obtained from the first bagItem in the checkout
 */
export const getPurchaseDetails = checkout => {
  const purchaseType = getType(checkout);
  const purchaseTypeDetail = getTypeDetail(purchaseType, checkout);
  return { purchaseType, purchaseTypeDetail };
};

const checkBagItems = bagItems => {
  if (!bagItems || !bagItems.length) return null;

  return bagItems.reduce((acc, item) => {
    if (acc === item.type) {
      return item.type;
    } else {
      return "MixedBag";
    }
  }, bagItems[0].type);
};

const getType = checkout => {
  const type = checkBagItems(checkout?.bagItems);

  const mappings = {
    SubscriptionBagItem: "subscription",
    ProSubscriptionBagItem: "subscription",
    ReserveBagItem: "rental",
    ClearanceBagItem: "clearance",
    ElectiveSaleBagItem: "KIF",
    BulkBagItem: "bulk",
    KIFAHBagItem: "KIFAH",
    MembersBagItem: "membership_items",
    MixedBag: "mixed",
  };
  return mappings[type] ?? "other";
};

const getTypeDetail = (purchaseType, checkout) => {
  switch (purchaseType) {
    case "subscription":
      return checkout?.bagItems[0].membershipTierId;
    case "rental":
      return getRentalDurationFromBagItem(checkout?.bagItems[0]);
    case "membership_items":
      return getSlotUpgradeDetailsFromCheckout(checkout);
    default:
      return "";
  }
};

const getRentalDurationFromBagItem = bagItem => {
  const startDate = bagItem?.beginDate ?? null;
  const endDate = bagItem?.endDate ?? null;
  if (!startDate || !endDate) {
    return "";
  }

  const parsedRentBegin = parseISO(startDate);
  const parsedRentEnd = parseISO(endDate);
  const duration = differenceInCalendarDays(parsedRentEnd, parsedRentBegin) + 1;

  return `${duration} days`;
};

const getSlotUpgradeDetailsFromCheckout = checkout => {
  const subtotal = checkout?.charges?.checkoutTotals?.find(t => t.type === "sub")?.amount;
  //If a user has upgraded slot count there will be a charge on the order
  const hasUpgrade = subtotal !== "USD 0.00";
  return hasUpgrade ? "spot_upgrade" : "no_upgrade";
};
