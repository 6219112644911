// Inspiration: https://redux.js.org/docs/recipes/ImplementingUndoHistory.html

import ActionTypes from "actions/action-types";

export const types = [
  ActionTypes.REBOOK_ORDER_GROUP_SUCCESS,
  ActionTypes.REBOOK_ORDER_GROUP_FAILURE,
  ActionTypes.REBOOK_ORDER_GROUP_REVERT,
  ActionTypes.REBOOK_MULTIPLE_ORDER_GROUPS_SUCCESS,
  ActionTypes.REBOOK_MULTIPLE_ORDER_GROUPS_FAILURE,
  ActionTypes.REBOOK_MULTIPLE_ORDER_GROUPS_REVERT,
];

// This reducer is to be called after modifications to an order. It captures a
// change set which will allow us to revert this change if the user so chooses.
// This is useful when a user updates her address, for example, which causes a
// rebooking error (e.g., delivery for tomorrow outside of NYC).
export const fn = function (state, action) {
  let isMultiGroupModification, previousOrderGroupModification;

  switch (action.type) {
    case ActionTypes.REBOOK_ORDER_GROUP_FAILURE: // falls-through
    case ActionTypes.REBOOK_MULTIPLE_ORDER_GROUPS_FAILURE:
      // nothing to do here.

      return state;

    case ActionTypes.REBOOK_ORDER_GROUP_SUCCESS: // falls-through
    case ActionTypes.REBOOK_MULTIPLE_ORDER_GROUPS_SUCCESS:
      isMultiGroupModification = action.type === ActionTypes.REBOOK_ORDER_GROUP_SUCCESS;
      previousOrderGroupModification = action.payload?.previousOrderGroupModification;

      break;

    case ActionTypes.REBOOK_ORDER_GROUP_REVERT: // falls-through
    case ActionTypes.REBOOK_MULTIPLE_ORDER_GROUPS_REVERT:
      previousOrderGroupModification = null;

      break;
  }

  return { ...state, isMultiGroupModification, previousOrderGroupModification };
};

export default {
  types: types,
  fn: fn,
};
