import ActionTypes from "actions/action-types.js";
var types, fn;

types = [
  ActionTypes.HOME_HERO_UNIT_SUCCESS,
  ActionTypes.HOMEPAGE_CAROUSEL_PRODUCTS_SUCCESS,
  ActionTypes.HOMEPAGE_CAROUSEL_PRODUCTS_FAILURE,
  ActionTypes.FETCH_HOMEPAGE_SUCCESS,
  ActionTypes.FETCH_HOMEPAGE_ERROR,
  ActionTypes.SMART_MEMBERSHIP_HOMEPAGE_HEADER_SUCCESS,
];

fn = function (state, action) {
  switch (action.type) {
    case ActionTypes.HOME_HERO_UNIT_SUCCESS:
      return {
        ...state,
        homeHeroUnit: action.payload,
      };
    case ActionTypes.FETCH_HOMEPAGE_SUCCESS:
      return {
        ...state,
        contentModules: action.payload,
      };
    case ActionTypes.FETCH_HOMEPAGE_ERROR:
      return {
        ...state,
        contentModules: [],
      };
    case ActionTypes.SMART_MEMBERSHIP_HOMEPAGE_HEADER_SUCCESS:
      return {
        ...state,
        smartMembershipHomepageHeader: action.payload,
      };
    case ActionTypes.HOMEPAGE_CAROUSEL_PRODUCTS_SUCCESS: {
      const { id, productCarousel } = action.payload;
      const updatedContentModules = state.contentModules.map(module => {
        if (module.id === id) {
          return {
            ...module,
            attributes: {
              ...module.attributes,
              productCarousels: [...(module.attributes?.productCarousels || []), productCarousel],
            },
          };
        } else return module;
      });
      return {
        ...state,
        contentModules: updatedContentModules,
      };
    }
    case ActionTypes.HOMEPAGE_CAROUSEL_PRODUCTS_FAILURE:
      console.error("Error fetching carousel products", action.payload);
      return {
        ...state,
      };
    default:
      return {
        ...state,
        contentModules: [],
      };
  }
};

export default {
  types: types,
  fn: fn,
};
