/**
 * This module is mocked in (Jest) tests.
 * Make sure it's imported via the path alias "action-logger" or this won't work (in tests).
 */
import _ from "underscore";
import analyticsClient from "./analytics/analytics-client";
import { globalInferLog } from "./analytics/global-infer-log";

const ActionLogger = {
  RezoAddToCart: "rezo_add_to_cart",
  RezoAddToCartFailure: "rezo_add_to_cart_failure",

  /**
   * There is likely a more elegant way of doing this, but this is used for pixel logging.
   * Maintaining existing code in order to prevent issues with our (complex) pixel ingestion code.
   */
  getReferrer() {
    let referrer;

    if (document.referrer.search("#") >= 0) {
      [referrer] = document.referrer.split("#");
    } else if (document.referrer.search("/?") >= 0) {
      [referrer] = document.referrer.split("?");
    } else {
      referrer = "";
    }

    return referrer;
  },

  logAction(options) {
    analyticsClient.log(options);
  },

  inferAction(options) {
    // Creating this model saves this information to localStorage, and some
    // other javascript will make sure it's saved to the server on the next
    // page load. Sure, fine.
    globalInferLog(options); // NOSONAR
  },

  logOnce(callback) {
    const loggingCallback = _.once(callback);

    return {
      sync: function (...args) {
        if (typeof window === "undefined") return;
        if (!window?.document) return;

        loggingCallback(args);
      },
    };
  },
};

export default ActionLogger;

export const { RezoAddToCart, RezoAddToCartFailure, logAction, inferAction, logOnce, getReferrer } = ActionLogger;
