const exported = {
  // If you're reading this file, and want to use it, you should use
  // `helpers/get-filtered-sku` instead. This file was accidentally introduced,
  // and does almost exactly the same thing as the latter.

  constants: {
    defaultSizeSelection: "",
    usaStandardSizes: "us_standard",
    oneSizeFitsAll: "one_size_fits_all",
    defaultLength: "R",
  },

  hasProductSizes: function (product) {
    return product?.sizes?.length > 1;
  },

  hasWorkingSizeFilters: function (workingFilters) {
    return Boolean(workingFilters?.canonicalSizes ?? false);
  },

  hasLengths: function (product) {
    return Boolean(product.sizeScale?.hasLengths);
  },

  isOneSizeFitsAll: function (product) {
    return Boolean(product.sizeScale?.id === this.constants.oneSizeFitsAll);
  },

  isUSAStandardSizes: function (product) {
    return Boolean(product.sizeScale?.id === this.constants.usaStandardSizes);
  },

  regionalSizeList: function (product, canonicalSize) {
    if (product.sizeScale?.canonicalToRegional) {
      return product.sizeScale.canonicalToRegional[canonicalSize];
    }
  },
};

export default exported;

export const {
  constants,
  hasProductSizes,
  hasWorkingSizeFilters,
  hasLengths,
  isOneSizeFitsAll,
  isUSAStandardSizes,
  regionalSizeList,
} = exported;
