import { differenceInDays, parseISO } from "date-fns";
import { CEBagItemTypes, PaymentMethods } from "rtr-constants";

const priceStringIntoFloat = price => {
  if (!price) {
    return;
  }

  const priceAsString = price.replace("USD ", "");
  return parseFloat(priceAsString);
};

const CECheckoutHelpers = {
  isAddressFormFilled: formFields => {
    return !["firstName", "lastName", "street1", "city", "state", "postalCode", "phone"].some(
      field => !formFields[field]
    );
  },

  setBillingAddressSameAsShipping: (billingValues, shippingValues) => {
    return {
      ...billingValues,
      firstName: shippingValues.firstName,
      lastName: shippingValues.lastName,
      street1: shippingValues.street1,
      street2: shippingValues.street2,
      city: shippingValues.city,
      state: shippingValues.state,
      zoneCode: shippingValues.zoneCode,
      postalCode: shippingValues.postalCode,
      phone: shippingValues.phone,
    };
  },

  getDaysBetweenDates: (date1, date2) => {
    const days = differenceInDays(parseISO(date2 || ""), parseISO(date1 || ""));
    return days + 1;
  },

  calculatePriceBeforeTax: charges => {
    const total = priceStringIntoFloat(charges?.totals?.grand);
    const tax = priceStringIntoFloat(charges?.totals.tax);
    return parseFloat(total - tax);
  },

  isSubscriptionCheckout: checkout => {
    return checkout?.bagItems?.[0]?.type === CEBagItemTypes.SUBSCRIPTION;
  },

  getAppliedGiftCard: checkout => {
    return checkout?.paymentMethods?.find(
      paymentMethod => paymentMethod.type === PaymentMethods.bagMethodType.GIFT_CARD
    );
  },
};

export default CECheckoutHelpers;

export const {
  calculatePriceBeforeTax,
  getAppliedGiftCard,
  getDaysBetweenDates,
  isAddressFormFilled,
  isSubscriptionCheckout,
  setBillingAddressSameAsShipping,
} = CECheckoutHelpers;
