import { membershipOrderException } from "rtr-constants";
import { HELP } from "routes";
import { addCacheBusterToUrl } from "./location-helpers";

const paymentFailureCopy = {
  copy: "Your billing information is invalid. Please update your credit card to place your order.",
  cta: membershipOrderException.cta.UPDATE_BILLING,
  ctaAction: membershipOrderException.ctaAction.OPEN_WALLET,
};

const exceptionMap = {
  "This item is not available for rental for your current membership": {
    copy: "This item is not included in your membership. Rent it today using your RTR Reserve discount.",
  },
  "You cannot add this item because you don't have any more free item slots for your current membership": {
    copy: "Your bag is currently full. Please remove an item to add this item to your shipment.",
  },
  "Failed to reserve item": {
    copy: "The item you selected is no longer available. Please select a different size or try a different style.",
  },
  "You cannot add item because of your current membership standing": {
    copy: "Something's not right with your membership. Please contact us to fix your account",
    cta: membershipOrderException.cta.CONTACT_CONCIERGE,
    ctaUrl: `${addCacheBusterToUrl(HELP.CONTACT)}`,
  },
  "Chargeable payment method required to place order": paymentFailureCopy,
  "Failed to charge payment method": paymentFailureCopy,
  "Cannot check out with more than 8 items.": {
    copy: "Sorry! You can't add more than 4 extra spots just yet. Please contact us if you have any questions.",
  },
  "You cannot use this credit card because it is expired": {
    copy: "Your billing information is invalid. Please update your credit card to place your order.",
    cta: membershipOrderException.cta.UPDATE_BILLING,
    ctaAction: membershipOrderException.ctaAction.OPEN_WALLET,
  },
};

const defaultException = {
  copy: "Something's not right. Please refresh and try again.",
};

export default function helper(exception) {
  return exceptionMap[exception] || defaultException;
}
