const AddressHelpers = {
  /**
   * Sorts addresses by placing the default address first
   * @param {addressPropType[]} addresses - List of addresses we are sorting
   * @returns {addressPropType[]} the newly sorted addresses with the default address first
   */
  addressesSortedByDefaultAddress(addresses) {
    return [...addresses].sort((a, b) => b.default - a.default);
  },

  /**
   * Return array of addresses where the first address is the selectedAddress
   * @param {Number} selectedAddressId - addressId of which we want to bring to the front
   * @param {addressPropType[]} addresses - List of addresses we are sorting
   * @returns {addressPropType[]} the newly sorted addresses with the selected address first
   */
  addressesSortedBySelectedFirst(selectedAddressId, addresses = []) {
    const previouslySelected = selectedAddressId.toString();
    const selectedAddress = addresses.find(a => a.id === previouslySelected);
    const filteredAddresses = addresses.filter(a => a.id !== previouslySelected);

    return [selectedAddress, ...filteredAddresses];
  },

  /**
   * Return array of addresses by id
   * @param {addressPropType[]} addresses - List of addresses we are sorting
   * @returns {addressPropType[]} the newly sorted addresses by id
   */
  addressesSortedById(addresses) {
    return addresses.sort((a, b) => b.id - a.id);
  },

  /**
   * Look for the first (and only) default address in an array returned from GET /shipmentAddress
   * @param {addressPropType[]} addresses - List of addresses we are iterating through
   * @returns {addressPropType|null} default address
   */
  getDefaultAddressId(addresses = []) {
    const sortedAddresses = addressesSortedByDefaultAddress(addresses);
    const originalDefaultAddress = sortedAddresses.find(address => address.default);

    return originalDefaultAddress?.id || null;
  },

  /**
   * Return full name string from address
   * @param {addressPropType} address - address we are reading the first and last name from
   * @returns {String|null} full name string
   */
  returnFullName(address) {
    const { firstName, lastName } = address;

    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }

    return null;
  },

  /**
   * Return city address string from address
   * @param {addressPropType} address - address we are reading the city, zipcode, state code from
   * @returns {String|null} full city address string
   */
  returnCityAddress(address) {
    const { city, zoneCode, postalCode } = address;

    if (city && zoneCode && postalCode) {
      return `${city}, ${zoneCode} ${postalCode}`;
    }

    return null;
  },

  /**
   * Return parsed suggested address to be used through address components
   * @param {object} suggestedAddress - Suggested address that we parsing
   * @returns {addressPropType[]} - the newly parsed suggested address to bne read throughout our address components
   */
  parseSuggestedAddress(suggestedAddress) {
    return {
      street1: suggestedAddress.street || "",
      street2: suggestedAddress.unit || "",
      city: suggestedAddress.city || "",
      zoneCode: suggestedAddress.state || "",
      postalCode: suggestedAddress.zip || "",
    };
  },
};

export const {
  addressesSortedByDefaultAddress,
  addressesSortedBySelectedFirst,
  addressesSortedById,
  getDefaultAddressId,
  returnFullName,
  returnCityAddress,
  parseSuggestedAddress,
} = AddressHelpers;
