// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.
import React, { createContext, useCallback, useContext, useDebugValue, useEffect, useMemo } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import _ from "underscore";

import { isMobileViewport as getIsMobileViewport } from "helpers/device-helpers";
import onInitializationActions from "actions/on-initialization-actions";
import { childrenPropType } from "../propTypes";
import { getDisplayName } from "../source/hoc/utils";
import { DeviceTypes } from "rtr-constants";
import { getRequestDeviceType } from "../../helpers/server-side-only/request-helpers";

const BrowserContext = createContext(null);

// plucks browser from the Redux Context
// sometimes, in NextJS Development, this does not trigger a re-render of this
// Context, resulting in the page showing the default Browser view (mobile). I
// cannot figure out why! (this usually points to us editing state, instead of
// replacing it, in a reducer, but we _do_ replace it!)
const browserSelector = ({ browser } = {}) => browser;

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useBrowser() {
  return useContext(BrowserContext);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

const mapStateToProps = ({ browser } = {}) => ({ browser });

export function withBrowser() {
  return WrappedComponent => {
    const C = connect(mapStateToProps)(WrappedComponent);

    C.displayName = `withBrowser(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

// If deviceType is passed as a header, use it, otherwise (e.g. in dev) fallback to the userAgent header.
function browserProps(deviceType) {
  return {
    isMobileViewport: deviceType !== DeviceTypes.DESKTOP,
    deviceType,
  };
}

// this is not called automatically by Next, you must call it explicitly,
// probably in _app.js
async function getInitialReduxState({ ctx: context = {} }) {
  const deviceType = getRequestDeviceType(context);

  // check that the CDN-provided X-RTR-SupportedCacheFeatures header has the
  // viewport-specific feature. without it, choose a reasonable default. with
  // it, honor the value provided by the isMobileViewport header

  return Promise.resolve({
    browser: {
      isSuggestion: true,
      ...browserProps(deviceType),
    },
  });
}

// Allows setting explicit context values
export const BaseProvider = BrowserContext.Provider;

export function BrowserProvider({ children }) {
  const dispatch = useDispatch();
  const browser = useSelector(browserSelector, shallowEqual);

  const handleResize = useCallback(() => {
    const action = onInitializationActions.writeBrowserData({
      ...browser,
      isSuggestion: false,
      isMobileViewport: getIsMobileViewport(),
    });

    dispatch(action);
  }, [browser]);

  const debouncedHandleResize = useMemo(() => _.debounce(handleResize, 1000), [browser]);

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", debouncedHandleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      debouncedHandleResize.cancel();
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []); // componentDidMount

  useDebugValue(browser.isMobileViewport ? "Mobile" : "Desktop");

  return <BrowserContext.Provider value={browser}>{children}</BrowserContext.Provider>;
}

BrowserProvider.getInitialReduxState = getInitialReduxState;

BrowserProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...BrowserContext,
  Provider: BrowserProvider,
};
