import React from "react";
import AnimatedTextInputWrapper from "components/source/shared/animated-text-input-wrapper";
import constants from "rtr-constants";
import PropTypes from "prop-types";

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  additionalClassName: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  onBlurCallback: PropTypes.func,
  error: PropTypes.string,
  value: PropTypes.string,
  autoComplete: PropTypes.string, // autoComplete, the browser feature
  enableAutocomplete: PropTypes.bool, // refers to Google Maps Auto complete (not the browser feature)
  handleAutocompleteSelect: PropTypes.func, // refers to Google Maps Auto complete (not the browser feature)
};

class AnimatedStreetAddressInput extends React.Component {
  constructor(props) {
    super(props);

    this.validate = this.validate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.defaultLabel = constants.formLabels.streetAddress;

    const validationMsgs = constants.clientSideErrorMessages.formValidation;
    this.blankInputValidationMsg = validationMsgs.streetAddressEmpty;
    this.invalidInputValidationMsg = validationMsgs.streetAddress;

    this.state = {
      validationErrorMsg: this.blankInputValidationMsg,
    };
  }

  validate(value) {
    const validator = constants.validations.poBox;
    const containsPoBox = value.match(validator);
    return !containsPoBox;
  }

  handleChange(value, isValid, name, inputObj) {
    this.handleInput(value, isValid, name, inputObj);

    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, isValid, name, inputObj);
    }
  }

  handleBlur(value, isValid, name, inputObj) {
    this.handleInput(value, isValid, name, inputObj);

    if (this.props.onBlurCallback) {
      this.props.onBlurCallback(value, isValid, name, inputObj);
    }
  }

  handleInput(value, isValid, name, inputObj) {
    // set the error validation message based on input received
    // if element is required and value is blank, use a "input is required" messsage
    // if there is a value and it fails the validation, use a validation error message
    if (!isValid) {
      if (this.props.required && !value) {
        this.setState({
          validationErrorMsg: this.blankInputValidationMsg,
        });
        inputObj.validationErrorMsg = this.blankInputValidationMsg; // because setState can happen after callbacks fire
      } else if (!this.validate(value)) {
        this.setState({
          validationErrorMsg: this.invalidInputValidationMsg,
        });
        inputObj.validationErrorMsg = this.invalidInputValidationMsg;
      }
    }
  }

  render() {
    return (
      <AnimatedTextInputWrapper
        id={this.props.id}
        name={this.props.name}
        type="text"
        label={this.props.label || this.defaultLabel}
        additionalClassName={this.props.additionalClassName}
        required={this.props.required}
        validateInput={this.validate}
        validationErrorMsg={this.state.validationErrorMsg}
        onChangeCallback={this.handleChange}
        onBlurCallback={this.handleBlur}
        error={this.props.error}
        value={this.props.value}
        autoComplete={this.props.autoComplete}
        enableAutocomplete={this.props.enableAutocomplete}
        handleAutocompleteSelect={this.props.handleAutocompleteSelect}
        disabled={this.props.disabled}
      />
    );
  }
}

AnimatedStreetAddressInput.propTypes = propTypes;

export default AnimatedStreetAddressInput;
