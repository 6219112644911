import React from "react";
import PropTypes from "prop-types";

import AnimatedTextInputWrapper from "components/source/shared/animated-text-input-wrapper";
import constants from "rtr-constants";

const defaultValidationErrorMsg = constants.clientSideErrorMessages.formValidation.phoneNumber;
const defaultLabel = constants.formLabels.phoneNumber;

const NON_DIGITS_AND_LEADING_ONE = /^1|\D/g;

class AnimatedPhoneInput extends React.Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onBlurCallback: PropTypes.func.isRequired, // (value: string, isValid: ?boolean, name: string, inputObj: Object) => void,
    onChangeCallback: PropTypes.func.isRequired, // (value: string, isValid: ?boolean, name: string, inputObj: Object) => void,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    validationErrorMsg: PropTypes.string.isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    value: "",
  };

  validateInput(value = "") {
    // take out anything that isn't a digit and the leading 1 if included. Form only accepts a US formatted phone number.
    const phoneDigits = value.replace(NON_DIGITS_AND_LEADING_ONE, "");

    return phoneDigits.length === 10;
  }

  render() {
    return (
      <AnimatedTextInputWrapper
        id={this.props.id}
        name={this.props.name}
        type={"tel"}
        validateInput={this.validateInput}
        label={this.props.label || defaultLabel}
        validationErrorMsg={this.props.validationErrorMsg || defaultValidationErrorMsg}
        error={this.props.error}
        value={this.props.value}
        onBlurCallback={this.props.onBlurCallback}
        onChangeCallback={this.props.onChangeCallback}
        required={this.props.required}
        autoComplete={this.props.autoComplete}
        tabIndex={this.props.tabIndex}
        alternateMasker={"atomPhoneInput"}
        disabled={this.props.disabled}
      />
    );
  }
}

export default AnimatedPhoneInput;
