import _ from "underscore";
import $ from "clients/RawClient";
import ActionTypes from "./action-types";
import { createAction } from "redux-actions";
import { formatPhoneNumber } from "helpers/format-phone-number-helper";
import { ShippingAddresses, ShippingAddressesValidate, DefaultShippingAddress } from "routes";
import constants from "rtr-constants";
import { geocodeByAddress } from "react-places-autocomplete";
const { checkout, clientSideErrorMessages } = constants;
const { addressSuggestions } = clientSideErrorMessages;
const { NON_SHIPPABLE_ADDRESS, REQUIRES_VALIDATION } = checkout.errorTypes;
const DEFAULT_SHIPPING_ADDRESS_ZIP_STORE_KEY = "defaultShippingZip";

const errorPayload = (err, errorType = "") => {
  return {
    errors: {
      errorType: errorType,
      form: `${err.responseText || checkout.errorMessages.shippingStep}`,
    },
  };
};

const ajaxPostConfig = (url, data) => {
  return {
    url,
    type: "POST",
    data,
  };
};

const actions = {
  addressBookIsLoading: createAction(ActionTypes.ADDRESS_BOOK_IS_LOADING),
  updateAddressBook: createAction(ActionTypes.ADDRESS_BOOK_LOADED),
  addressIsUpdating: createAction(ActionTypes.ADDRESS_IS_UPDATING),
  updateElement: createAction(ActionTypes.SHIPPING_STEP_UPDATE_ELEMENT),
  shippingStepSetAutocompleteValues: createAction(ActionTypes.SHIPPING_STEP_SET_AUTOCOMPLETE_VALUES),
  shippingStepSubmitting: createAction(ActionTypes.SHIPPING_STEP_SUBMITTING),
  shippingStepSubmittingReset: createAction(ActionTypes.SHIPPING_STEP_SUBMITTING_RESET),
  shippingStepValidationConfirmationRequired: createAction(ActionTypes.SHIPPING_STEP_VALIDATION_CONFIRMATION_REQUIRED),
  submitShippingStepSuccess: createAction(ActionTypes.SHIPPING_STEP_SUCCESS),
  submitShippingStepFailure: createAction(ActionTypes.SHIPPING_STEP_FAILURE),
  replaceAddresses: createAction(ActionTypes.ADDRESS_REPLACE_ADDRESSES),
  addAddress: createAction(ActionTypes.ADDRESS_ADD_ADDRESS),
  validateShippingFormBeforeSubmit: createAction(ActionTypes.SHIPPING_STEP_VALIDATE_BEFORE_SUBMIT),
  initValues: createAction(ActionTypes.SHIPPING_STEP_INIT_VALUES),
  fetchingDefaultShippingAddress: createAction(ActionTypes.FETCHING_DEFAULT_SHIPPING_ADDRESS),

  /*
   * Submits a shipping address to our Ruby layer to be created in Godmother.
   * On success, receives the returned data (the newly created entity) and passes it to an optional callback.
   * On failure, receives the error and passes it to an optional callback.
   * Multiple components will be dispatching this action so it is important to leave it clean,
   * and to make sure that this action file is not coupled to other action files.
   * ===> PLEASE DO NOT ADD ADDITIONAL ACTIONS OR LOGIC TO THIS ACTION <===
   * ===> WHATEVER ELSE YOU NEED TO DO ON SUCCESS / FAILURE SHOULD HAPPEN IN THE CALLBACKS <===
   */
  saveAddress: function (payload, onSuccess, onFailure) {
    return function (dispatch) {
      dispatch(actions.shippingStepSubmitting());
      $.ajax(ajaxPostConfig(ShippingAddresses, JSON.stringify(payload.vals))).then(
        function (data) {
          dispatch(actions.submitShippingStepSuccess());
          if (onSuccess) {
            onSuccess(data);
          }
        },
        function (err) {
          /* We've done some (but not all) of the work to get useful
           * error messages from Commerce via Godmother. Until that work is
           * finished, we'll show a generic message for each step.
           * The error must be in this shape for the GenericSubform component
           * to display the message (see formLevelError() in generic-subform.jsx) */
          dispatch(actions.submitShippingStepFailure(errorPayload(err)));
          if (onFailure) {
            onFailure(err);
          }
        }
      );
    };
  },

  loadAddressBook: function () {
    return function (dispatch) {
      dispatch(actions.addressBookIsLoading(true));
      $.get(ShippingAddresses)
        .then(function (addresses) {
          dispatch(actions.addressBookLoaded(addresses));
        })
        .finally(function () {
          dispatch(actions.addressBookIsLoading(false));
        });
    };
  },

  addressBookLoaded: function (addressBook) {
    return function (dispatch) {
      dispatch(actions.updateAddressBook(addressBook));

      const defaultShippingZip = _.findWhere(addressBook, { default: true })?.postalCode;
      if (defaultShippingZip) {
        dispatch(actions.cacheDefaultShippingAddressZip(defaultShippingZip));
      }
    };
  },

  confirmAddress: function (payload, validationSuccessCallback, validationFailureCallback) {
    return function (dispatch) {
      dispatch(actions.shippingStepSubmitting());
      dispatch(actions.addressBookIsLoading(true));

      payload.vals.phone = formatPhoneNumber(payload.vals.phone);
      $.ajax({
        url: ShippingAddressesValidate,
        type: "POST",
        data: {
          write_if_valid: true, // This means that the address will be saved by Ruby if it doesn't need validation
          return_all_addresses: true,
          address: payload.vals,
        },
      })
        .then(
          response => {
            if (response.valid) {
              // when return_all_addresses is true (as above), the address_response takes the shape:
              // { address: <the created address>, address_book: <all of the user's addresses> }
              if (validationSuccessCallback) {
                validationSuccessCallback(response.address_response.address);
              }
              dispatch(actions.submitShippingStepSuccess());
              dispatch(actions.addressBookLoaded(response.address_response.address_book));
            } else {
              if (validationFailureCallback) {
                validationFailureCallback(REQUIRES_VALIDATION);
              }
              dispatch(actions.submitShippingStepSuccess());
              dispatch(
                actions.shippingStepValidationConfirmationRequired({
                  suggestedAddresses: response.address_response,
                  userSubmittedAddress: payload.vals,
                })
              );
            }
          },
          err => {
            /*
             * There are two different cases handled in the fail block:
             * 1. If we get a "NON_SHIPPABLE_ADDRESS" error from Ruby, we want to
             * keep the shipping step open and display the error on the form -- so
             * we dispatch a shippingStepFailure() with the appropriate messaging.
             * We don't clear the form in this case, so that the user can make adjustments
             * and resubmit.
             * 2. If the error is anything else (e.g., Address not recognized by UPS),
             * we want to pop the modal and prompt the user to confirm. In this case,
             * we do clear the shipping form values.
             */
            if (err.responseText === NON_SHIPPABLE_ADDRESS) {
              const errorMsg = addressSuggestions.nonShippable;
              dispatch(
                actions.submitShippingStepFailure(errorPayload({ responseText: errorMsg }, NON_SHIPPABLE_ADDRESS))
              );
            } else {
              const defaultErrorMsg = addressSuggestions.notRecognized;
              // this isn't perfect, but it's ok for now
              const respondTextContainsHtml = (err.responseText || "")[0] === "<";

              dispatch(actions.shippingStepSubmittingReset()); // clear the address form
              dispatch(
                actions.shippingStepValidationConfirmationRequired({
                  suggestedAddresses: [],
                  userSubmittedAddress: payload.vals,
                  addressValidationError: respondTextContainsHtml ? defaultErrorMsg : err.responseText,
                })
              );
            }

            if (validationFailureCallback) {
              validationFailureCallback(err);
            }
          }
        )
        .finally(function () {
          dispatch(actions.addressBookIsLoading(false));
        });
    };
  },

  submitShippingAddressAndReplaceAll: function (payload, optionalSuccessCallback) {
    // submits a shipping address, receives ALL OF USER'S ADDRESSES as a return
    // and replaces all of the addresses
    return function (dispatch) {
      dispatch(actions.shippingStepSubmitting());
      const url = `${ShippingAddresses}?returnAll=true`;
      $.ajax(ajaxPostConfig(url, JSON.stringify(payload.vals))).then(
        function (data) {
          dispatch(actions.submitShippingStepSuccess());
          dispatch(actions.replaceAddresses({ addresses: data.address_book }));

          // be sure to call the success callback _after_ the address book has
          // been updated.
          if (optionalSuccessCallback) {
            optionalSuccessCallback(data.address);
          }
        },
        function (err) {
          dispatch(actions.submitShippingStepFailure(errorPayload(err)));
        }
      );
    };
  },

  updateShippingAddress: function (payload, onSuccess, onFailure) {
    return function (dispatch) {
      dispatch(actions.shippingStepSubmitting());
      // In the case that we set a default address, we only pass the
      // default flag.
      if (payload.vals.phone) {
        payload.vals.phone = formatPhoneNumber(payload.vals.phone);
      }

      $.ajax({
        url: ShippingAddresses,
        type: "PATCH",
        data: JSON.stringify(payload.vals),
      }).then(
        function (data) {
          dispatch(actions.submitShippingStepSuccess());
          dispatch(actions.loadAddressBook());
          if (onSuccess) {
            onSuccess(data);
          }
        },
        function (err) {
          if (onFailure) {
            onFailure(err);
          }
          dispatch(actions.submitShippingStepFailure(errorPayload(err)));
        }
      );
    };
  },

  geocodeAutocompleteResult(address) {
    return function (dispatch) {
      // This API requires a div to be attached to and for this reason, we create an element
      // in memory for it to reference. The `google` namespace is created by a script loading
      // and this function is unable to be called until that script is loaded.
      // 3rd-party integration
      new google.maps.places.PlacesService(document.createElement("div"));
      try {
        geocodeByAddress(address).then(geocodedAddress => {
          if (geocodedAddress?.length > 0) {
            dispatch(
              actions.shippingStepSetAutocompleteValues({
                address: geocodedAddress[0],
              })
            );
          }
        });
      } catch (e) {
        // In this case, we won't geocode the address, but the user will still be able to add their
        // other information.
      }
    };
  },

  /*
   * Log errors from Google autocomplete to Splunk.
   */
  onAutocompleteError(googleAutoCompleteStatus) {
    return function () {
      $.ajax({
        url: "/shippingAddresses/autocomplete_error",
        type: "POST",
        data: JSON.stringify({ status: googleAutoCompleteStatus }),
      });
    };
  },

  fetchDefaultShippingAddress(onSuccess = _.noop) {
    return function (dispatch) {
      dispatch(actions.fetchingDefaultShippingAddress(true));
      $.get(DefaultShippingAddress)
        .then(function (address) {
          const zipCode = address?.postalCode;
          if (zipCode) {
            dispatch(actions.cacheDefaultShippingAddressZip(zipCode));
            onSuccess(zipCode);
          }
        })
        .finally(function () {
          dispatch(actions.fetchingDefaultShippingAddress(false));
        });
    };
  },

  cacheDefaultShippingAddressZip(zipCode) {
    return function () {
      if (typeof window === "undefined") {
        return;
      }
      if (!window?.localStorage) {
        return;
      }
      localStorage.setItem(DEFAULT_SHIPPING_ADDRESS_ZIP_STORE_KEY, zipCode);
    };
  },
};

export default actions;

export const {
  addressBookIsLoading,
  updateAddressBook,
  addressIsUpdating,
  updateElement,
  shippingStepSetAutocompleteValues,
  shippingStepSubmitting,
  shippingStepSubmittingReset,
  shippingStepValidationConfirmationRequired,
  submitShippingStepSuccess,
  submitShippingStepFailure,
  replaceAddresses,
  addAddress,
  validateShippingFormBeforeSubmit,
  initValues,
  fetchingDefaultShippingAddress,
  saveAddress,
  loadAddressBook,
  addressBookLoaded,
  confirmAddress,
  submitShippingAddressAndReplaceAll,
  updateShippingAddress,
  geocodeAutocompleteResult,
  onAutocompleteError,
  fetchDefaultShippingAddress,
  cacheDefaultShippingAddressZip,
} = actions;
