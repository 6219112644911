import _ from "underscore";
import ActionTypes from "actions/action-types.js";
var types, fn;

types = [
  ActionTypes.FETCH_DELIVERY_CUTOFF_SUCCESS,
  ActionTypes.FETCH_DELIVERY_CUTOFF_ERROR,
  ActionTypes.FETCH_DELIVERY_CUTOFF_LOADING,
  ActionTypes.CLEAR_DELIVERY_CUTOFF_VALUE,
  ActionTypes.FETCH_BLACKOUT_DAYS_SUCCESS,
  ActionTypes.FETCH_BLACKOUT_DAYS_ERROR,
];

fn = function (state, action) {
  switch (action.type) {
    case ActionTypes.FETCH_DELIVERY_CUTOFF_SUCCESS:
      return _.extend({}, state, {
        deliveryCutoffs: action.payload,
      });
    case ActionTypes.CLEAR_DELIVERY_CUTOFF_VALUE:
      return _.extend({}, state, {
        deliveryCutoffs: null,
      });
    case ActionTypes.FETCH_BLACKOUT_DAYS_SUCCESS: {
      const newDateOptions = _.extend({}, state.dateOptions);
      newDateOptions.blackoutDays = action.payload;
      return _.extend({}, state, {
        dateOptions: newDateOptions,
      });
    }
    case ActionTypes.FETCH_BLACKOUT_DAYS_ERROR: {
      return state;
    }
    default:
      return _.extend({}, state, {
        deliveryCutoffs: [],
      });
  }
};

export default {
  types: types,
  fn: fn,
};
