import AtomPlacesAutocompleteInput from "components/source/atoms/atom-places-autocomplete-input";
import { onAutocompleteError } from "actions/shipping-step-actions";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    googleMapsLoaded: state.config.googleMapsLoaded,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logError: status => {
      dispatch(onAutocompleteError(status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AtomPlacesAutocompleteInput);
