// eventually, render images using NextImage
// while we are in Storefront.Ruby transition, this import fails (process is not
// defined), so we do it ourselves
// import NextImage from "next/image";

// need to figure this out... importing next/image requires that `process` be
// defined, so we may need to do some webpack magic to render images the way we
// currently do
export function renderImportedImage(Img, props = {}) {
  const isStorefrontNextImage = Boolean(Img.src);

  if (isStorefrontNextImage) {
    // eslint-disable-next-line react/jsx-props-no-spreading, react/forbid-elements
    return <img alt={props.alt} {...Img} {...props} />;
    // return <NextImage {...Img} {...props} />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Img {...props} />;
}
