import _ from "underscore";
import { clientSideErrorMessages } from "rtr-constants";

const ReservationValidator = (function () {
  const errorMessages = clientSideErrorMessages.inlineReservationForm;

  const validReservationResponse = function () {
    return { isValid: true };
  };

  const invalidReservationResponse = function (errors) {
    const response = { isValid: false };
    if (!errors) {
      return response;
    }
    return _.extend(response, errors);
  };

  const hasFields = function (reservation) {
    const isValid = !!(reservation.zipCode && reservation.date && reservation.duration);

    return !!(isValid && reservation.primarySku?.id);
  };

  const isPresent = function (reservation) {
    if (hasFields(reservation)) {
      return validReservationResponse();
    } else {
      const validationResponse = invalidReservationResponse();
      _.each(["date", "zipCode"], function (key) {
        if (!reservation[key]) {
          validationResponse[key] = errorMessages.isRequired;
        }
      });
      if (!reservation.primarySku?.id) {
        validationResponse.primarySku = errorMessages.isRequired;
      }

      return validationResponse;
    }
  };

  const distinctSizes = function (reservation) {
    if (reservation.primarySku && reservation.backupSku) {
      // Ensure that a backupSku is not the same as a primarySku
      const isValid = reservation.primarySku.id !== reservation.backupSku.id;
      if (isValid) {
        return validReservationResponse();
      }

      return invalidReservationResponse({
        primarySku: errorMessages.selectDistinctSizes,
        backupSku: true,
      });
    }
    // If there is not backupSku and possibly no primarySku, do
    // not show this validation error.
    return validReservationResponse();
  };

  const isValidSaleable = function (reservation, skus, isRentable) {
    if (isRentable) {
      return false;
    }
    // Currently if there is one sku, this is saved as a form
    if (skus.length === 1) {
      return true;
    }
    // If the saleable has sizes, check to make sure an primarySku is set
    return !!reservation?.primarySku?.id;
  };

  const canMakeRequest = function (reservation) {
    // No zipCode set yet
    if (_.isUndefined(reservation.zipCode) || _.isNull(reservation.zipCode)) {
      return validReservationResponse();
    }
    // zipCode is set and either (5 or more) or 0(empty string) in length
    if (reservation.zipCode && (reservation.zipCode.length >= 5 || reservation.zipCode.length === 0)) {
      return validReservationResponse();
    } else {
      return invalidReservationResponse();
    }
  };

  /*
    Single function to validate reservation requests on PDP
    must return an isValid key as well as optional error messages.
  */
  const validate = function (reservation, skus, isRentable) {
    // If we have a saleable reservation, bail if this is valid
    if (isValidSaleable(reservation, skus, isRentable)) {
      return validReservationResponse();
    }
    // Otherwise, check to make sure all fields are present and
    // that a primary and backup sku are distinct if they are both present
    const presenceCheck = isPresent(reservation),
      distinctSizesCheck = distinctSizes(reservation),
      isValid = presenceCheck.isValid && distinctSizesCheck.isValid;

    return _.extend(presenceCheck, distinctSizesCheck, { isValid: isValid });
  };

  const api = {
    validate: validate,
    canMakeRequest: canMakeRequest,
  };

  /* Exported for test */
  api._forTests = {
    isPresent: isPresent,
    distinctSizes: distinctSizes,
    isValidSaleable: isValidSaleable,
  };
  /* Exported for test */

  return api;
})();

export default ReservationValidator;
