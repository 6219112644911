import React from "react";
import { cmsPromoBanners, pageTypes } from "rtr-constants";
import PropTypes from "prop-types";
import SkinnyBannerContainer from "./skinny-banner-container";
import {
  addressPropType,
  bannerPropType,
  browserPropType,
  userDataPropType,
  userProblemsPropType,
} from "components/propTypes";
import { loadCMSContent } from "actions/shared-actions";
import { connect } from "react-redux";

// NW [EXPLANATION] 10/5/21: a connected wrapper for SkinnyBannerContainer (which expects to receive and pass down a ton of fields from Redux, but is not connected itself)
// After ff react_nav is enabled permanently (MA-1072) the SkinnyBannerContainer implementation can be combined with this implementation
export class SkinnyBannerComponent extends React.Component {
  static propTypes = {
    addresses: PropTypes.arrayOf(addressPropType),
    browser: browserPropType,
    dispatchForSkinnyBannerContainer: PropTypes.func,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    fetchCMSPromos: PropTypes.func.isRequired,
    happinessSurvey: PropTypes.object,
    isSkinnyBannerSuppressedOnPage: PropTypes.bool,
    pageType: PropTypes.string,
    ppsReferralPromo: bannerPropType,
    referralPromo: bannerPropType,
    selfServiceExchange: PropTypes.object,
    smartComponentState: PropTypes.object,
    userData: userDataPropType,
    userProblems: userProblemsPropType,
  };

  componentDidMount() {
    if (!this.props.ppsReferralPromo || !this.props.referralPromo) {
      this.props.fetchCMSPromos();
    }
  }

  render() {
    if (this.props.pageType === pageTypes.ABOUT_US) {
      return null;
    }
    return (
      <SkinnyBannerContainer
        addresses={this.props.addresses}
        browser={this.props.browser}
        dispatch={this.props.dispatchForSkinnyBannerContainer}
        displayedModal={this.props.displayedModal}
        happinessSurvey={this.props.happinessSurvey}
        isSkinnyBannerSuppressedOnPage={this.props.isSkinnyBannerSuppressedOnPage}
        ppsPromo={this.props.ppsReferralPromo}
        referralPromo={this.props.referralPromo}
        selfServiceExchange={this.props.selfServiceExchange}
        smartComponentState={this.props.smartComponentState}
        userData={this.props.userData}
        userProblems={this.props.userProblems}
      />
    );
  }
}

const mapStateToProps = state => {
  const promoBannerCMS = state.cmsContent?.[cmsPromoBanners.STATE_KEY];

  return {
    addresses: state.addresses,
    browser: state.browser,
    displayedModal: state.displayedModal,
    happinessSurvey: state.happinessSurvey,
    isSkinnyBannerSuppressedOnPage: state.isSkinnyBannerSuppressedOnPage,
    pageType: state.pageType,
    ppsReferralPromo: promoBannerCMS?.ppsReferral,
    referralPromo: promoBannerCMS?.referral,
    selfServiceExchange: state.selfServiceExchange,
    smartComponentState: state.smartComponentState,
    userData: state.userData,
    userProblems: state.userProblems,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchForSkinnyBannerContainer: dispatch,
    fetchCMSPromos: () =>
      dispatch(loadCMSContent(cmsPromoBanners.CMS_PATH, cmsPromoBanners.STATE_KEY, cmsPromoBanners.RESPONSE_FIELD)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkinnyBannerComponent);
