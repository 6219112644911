import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CmsAttributesHelper from "helpers/cms-attributes-helper";
import { atomTextAttributesPropType, browserPropType } from "components/propTypes";

class AtomText extends React.Component {
  static propTypes = {
    browser: browserPropType,
    text: PropTypes.node,
    textAttributes: atomTextAttributesPropType,
    customClass: PropTypes.string,
    mobileText: PropTypes.node,
    mobileTextAttributes: atomTextAttributesPropType,
  };

  static className = "customizable-atom";

  constructAttributeClasses = attributes => {
    if (!attributes || !Object.keys(attributes).length) {
      return "";
    }

    return CmsAttributesHelper.constructAttributeClasses(this.constructor.className, attributes);
  };

  renderText = (text, textAttributes) => {
    if (!text) {
      return;
    }

    const classNames = `${this.constructAttributeClasses(textAttributes)} ${this.constructor.className}`;

    // If the text is a string, treat as raw HTML
    if (typeof text === "string") {
      return <div className={classNames} dangerouslySetInnerHTML={{ __html: text }}></div>;
    }

    return <div className={classNames}>{text}</div>;
  };

  render() {
    const { browser, customClass, text, mobileText, textAttributes, mobileTextAttributes } = this.props;

    // If both texts do not exist then do not render this at all.
    if (!text && !mobileText) {
      return null;
    }

    return (
      <div className={customClass ?? ""} data-test-id="atom-text">
        {!browser.isMobileViewport && this.renderText(text, textAttributes)}
        {browser.isMobileViewport &&
          this.renderText(
            mobileText,
            mobileTextAttributes && Object.keys(mobileTextAttributes).length ? mobileTextAttributes : textAttributes
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    browser: state.browser || {},
  };
};

export default connect(mapStateToProps)(AtomText);
