import _ from "underscore";
import ActionTypes from "../actions/action-types.js";
var types, fn;

types = [ActionTypes.CHANGE_PAGE];

fn = function (state, action) {
  return _.extend({}, state, {
    page: action.payload,
    nextPage: action.payload < state.totalPages,
    previousPage: action.payload > 1,
  });
};

export default {
  types: types,
  fn: fn,
};
