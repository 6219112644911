import PropTypes from "prop-types";
import { Referrals } from "routes";
import ReferralAdvocateShareIcons from "images/referrals/referral_advocate_share_icons.svg";
import ReferralHelper from "helpers/referral-helper";

const propTypes = {
  friendRewards: PropTypes.arrayOf(
    PropTypes.shape({
      promotion: PropTypes.shape({
        rules: PropTypes.arrayOf(
          PropTypes.shape({
            discountAmount: PropTypes.number,
            discountTypeCode: PropTypes.number,
          })
        ),
      }),
    })
  ).isRequired,
};
const FirstShipmentReferralsModal = props => {
  const friendIncentive = ReferralHelper.getFriendIncentiveCopy(
    props.friendRewards[0],
    ReferralHelper.referralPageTypes.ADVOCATE
  );

  return (
    <>
      <ReferralAdvocateShareIcons className="pps_referrals_gtm__share-icons" />
      <h3>Renting is better with friends</h3>
      <p>Treat your friends to {friendIncentive}. For each friend that joins, you’ll get a FREE extra spot.</p>
      <a className="btn btn-primary" href={Referrals.inviteAFriend}>
        Spread The Word
      </a>
    </>
  );
};

FirstShipmentReferralsModal.MODAL_NAME = "PPS_REFERRALS_GTM";
FirstShipmentReferralsModal.propTypes = propTypes;
export default FirstShipmentReferralsModal;

export const { MODAL_NAME } = FirstShipmentReferralsModal;
