/* eslint-disable react/sort-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import _ from "underscore";
import AnimatedTextInputWrapper from "components/source/shared/animated-text-input-wrapper";
import AnimatedZipInput from "components/source/shared/animated_inputs/animated-zip-input";
import AnimatedStreetAddressInput from "components/source/shared/animated_inputs/animated-street-address-input";
import AnimatedStatesDropdown from "components/source/shared/animated_inputs/animated-states-dropdown";
import AnimatedPhoneInput from "components/source/shared/animated_inputs/animated-phone-input";
import CheckboxInputPair from "components/source/shared/checkbox-input-pair";
import {
  clientSideErrorMessages,
  formAutoCompleteCategories,
  formAutoCompleteSettings,
  formLabels,
  validations,
} from "rtr-constants";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const propTypes = {
  // props from parent component
  stateKey: PropTypes.string.isRequired, // which part of the redux store to read / write
  additionalClassName: PropTypes.string,
  // names used for HTML name attribute on input and Redux store keys
  formElementNames: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    apt: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    makeDefault: PropTypes.string,
  }).isRequired,
  elements: PropTypes.objectOf(
    PropTypes.shape({
      isValid: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      defaultValue: PropTypes.string,
      isRequired: PropTypes.bool,
    })
  ),
  showMakeDefaultCheckbox: PropTypes.bool,
  subformDek: PropTypes.node,
  updateElement: PropTypes.func.isRequired, // callback when inputs change/blur
  onChangeWithSuppressedErrors: PropTypes.func.isRequired, // special callback for inputs with regex validation

  // props from mapStateToProps
  formIsValid: PropTypes.bool,
  errors: PropTypes.shape({
    form: PropTypes.string,
  }),
  geocodeAddress: PropTypes.func,
  enableAutocomplete: PropTypes.bool,
};

class AddressFormFields extends React.Component {
  constructor(props) {
    super(props);

    this.baseClassName = "subform-form-fields address-form-fields";

    this.state = {};
  }

  className() {
    return this.props.additionalClassName
      ? `${this.baseClassName} ${this.props.additionalClassName}`
      : this.baseClassName;
  }

  getValueFromElements(elementName) {
    return this.props?.elements?.[elementName]?.value ?? "";
  }

  getErrorFromState(elementName) {
    return this.props?.errors?.[elementName] ?? "";
  }

  getRequiredFormElements(elementName) {
    return this.props?.elements?.[elementName]?.isRequired ?? false;
  }

  inputData(name) {
    const tabIndex = this.tabIndex()[name];
    return {
      name,
      tabIndex,
      id: `shipping-${name}`,
      required: this.getRequiredFormElements(name),
      value: this.getValueFromElements(name),
      error: this.getErrorFromState(name),
      autoComplete: formAutoCompleteSettings(name, formAutoCompleteCategories.Shipping),
    };
  }

  checkboxIsChecked() {
    const { makeDefault } = this.props.formElementNames;
    const isChecked = this.getValueFromElements(makeDefault); // returns "" if no value

    switch (isChecked) {
      case true:
      case false:
        return isChecked;
      default:
        return true;
    }
  }

  makeDefaultCheckbox() {
    // Create a "Save as default" checkbox if the `showMakeDefaultCheckbox` prop exists.
    // Like the other inputs, this is "controlled" by the values in the elements prop
    // (which will usually come down to this component via the Redux store).
    // This box will be checked to start.
    if (this.props.showMakeDefaultCheckbox) {
      const { makeDefault } = this.props.formElementNames;

      return (
        <CheckboxInputPair
          checked={this.checkboxIsChecked()}
          id={makeDefault}
          name={makeDefault}
          label={formLabels.defaultAddress}
          value={true}
          onChange={this.onCheckboxClick}
        />
      );
    }
  }

  handleAutocompleteSelect = address => {
    this.props.geocodeAddress(address);
    // Focus on the street2(apt) field.
    this.setState({
      focusAfterAutocomplete: true,
    });
  };

  // In the case when we have just autocompleted an address, let's change the tabIndex for the form
  // so that "phone" will be the next element to focus on. Once we onBlur calls from the street2
  // field, we return to a regular form ordering for tabIndex.
  tabIndex() {
    if (this.state.focusAfterAutocomplete) {
      return {
        street2: 1,
        phone: 2,
      };
    }
    return {};
  }

  onCheckboxClick = ({ target }) => {
    const { formElementNames = {}, updateElement = _.noop } = this.props;

    const { makeDefault } = formElementNames;
    const { checked: value = false } = target;

    updateElement(value, true, makeDefault, {});
  };

  render() {
    const { firstName, lastName, street, apt, city, state, zipCode, phone } = this.props.formElementNames;

    return (
      <div className={this.className()} data-test-id="address-form-fields">
        {this.props.subformDek}
        <div className="subform-field-pair">
          <AnimatedTextInputWrapper
            {...this.inputData(firstName)}
            label={formLabels.firstName}
            validationErrorMsg={clientSideErrorMessages.formValidation.firstName}
            onChangeCallback={this.props.updateElement}
            onBlurCallback={this.props.updateElement}
          />

          <AnimatedTextInputWrapper
            {...this.inputData(lastName)}
            label={formLabels.lastName}
            validationErrorMsg={clientSideErrorMessages.formValidation.lastName}
            onChangeCallback={this.props.updateElement}
            onBlurCallback={this.props.updateElement}
          />
        </div>
        <AnimatedStreetAddressInput
          {...this.inputData(street)}
          onChangeCallback={this.props.updateElement}
          onBlurCallback={this.props.updateElement}
          handleAutocompleteSelect={this.handleAutocompleteSelect}
          enableAutocomplete={this.props.enableAutocomplete}
        />
        <AnimatedTextInputWrapper
          {...this.inputData(apt)}
          label={formLabels.apt}
          validationErrorMsg={clientSideErrorMessages.formValidation.streetAddress}
          validateInput={value => !value.match(validations.poBox)}
          onChangeCallback={this.props.updateElement}
          onBlurCallback={_.bind(function () {
            this.props.updateElement.apply(null, arguments);
            // Reset focus/tabIndex after user tabs to phone
            if (this.state.focusAfterAutocomplete) {
              this.setState({
                focusAfterAutocomplete: false,
              });
            }
          }, this)}
          focus={this.state.focusAfterAutocomplete}
        />
        <AnimatedZipInput
          {...this.inputData(zipCode)}
          onChangeCallback={_.partial(this.props.onChangeWithSuppressedErrors, _, _, _, _, this.props.formIsValid)}
          onBlurCallback={this.props.updateElement}
        />
        <div className="subform-field-pair">
          <AnimatedTextInputWrapper
            {...this.inputData(city)}
            label={formLabels.city}
            validationErrorMsg={clientSideErrorMessages.formValidation.city}
            onChangeCallback={this.props.updateElement}
            onBlurCallback={this.props.updateElement}
          />

          <AnimatedStatesDropdown
            {...this.inputData(state)}
            autofill={true}
            onChangeCallback={this.props.updateElement}
            onBlurCallback={this.props.updateElement}
            validationErrorMsg={clientSideErrorMessages.formValidation.selectState}
          />
        </div>
        <AnimatedPhoneInput
          {...this.inputData(phone)}
          onChangeCallback={this.props.updateElement}
          onBlurCallback={this.props.updateElement}
        />
        <div className="subform__mobile-usage">
          Your number is used to send transactional text messages about your order.
        </div>
        {this.makeDefaultCheckbox()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const stateKey = ownProps.stateKey || "shippingStep";
  const subformState = state[stateKey] || {};
  return {
    formIsValid: subformState.isValid,
    errors: subformState.errors,
  };
};

AddressFormFields.propTypes = propTypes;

export default connect(mapStateToProps)(AddressFormFields);
