import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { UPSELL_CURATION_ID } from "./upsell-bonus-style";

export default function UpsellStickyCta({ viewBag }) {
  return (
    <div className={styles["sticky-ctas"]}>
      <button onClick={viewBag} className="btn btn-secondary">
        View Bag
      </button>
      <a href={`/shop/${UPSELL_CURATION_ID}/products?upsell_source=modal_cta`} className="btn">
        Browse Styles
      </a>
    </div>
  );
}

UpsellStickyCta.propTypes = {
  viewBag: PropTypes.object.isRequired,
};
