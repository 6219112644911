// RE: SF.Next migration
// it appears that all flags referenced in this file have been turned off since
// 2020 and Splunk suggests synchronization with the server is no longer
// happening. I don't know the feature well, so it is possible _all_ of this
// can be deleted
// by using universal-cookie, you can expect that this code will only work
// client-side. to make it work server-side, have your component pass the
// cookies object provided to it by the `withCookies` HOC.
import Cookies from "universal-cookie";

const cookies = new Cookies();

const COOKIE_NAME = "user_selections";
const USER_SELECTION_SOURCES = {
  RENT_MY_STYLE_QUIZ_LANDING: "Rent My Style Quiz",
  RENT_MY_STYLE: "Rent My Style LP + Quiz",
  RENT_MY_STYLE_HP_LANDING: "Rent My Style Homepage",
  HOMEPAGE_SBD: "HP SBD",
  HOMEPAGE_QUIZ: "HP Rent My Style Quiz",
  GRID_QUIZ: "Grid Rent My Style Quiz",
};
const INELIGIBLE_USER_SELECTION_SOURCES = [
  USER_SELECTION_SOURCES.RENT_MY_STYLE_QUIZ_LANDING,
  USER_SELECTION_SOURCES.RENT_MY_STYLE,
  USER_SELECTION_SOURCES.RENT_MY_STYLE_HP_LANDING,
  USER_SELECTION_SOURCES.HOMEPAGE_QUIZ,
  USER_SELECTION_SOURCES.GRID_QUIZ,
];

function getUserSelectionCookie() {
  // Current array of UserSelection objects
  return cookies.get(COOKIE_NAME, { doNotParse: false });
}

export default {
  getUserSelectionCookie,
  USER_SELECTION_SOURCES,
  INELIGIBLE_USER_SELECTION_SOURCES,
};
