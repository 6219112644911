import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";

const propTypes = {
  inline: PropTypes.bool,
  labelProps: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.node,
  wrapperClass: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inputBefore: PropTypes.bool,
};

class InputWrapper extends React.Component {
  render() {
    var wrapperClass = this.props.inline ? "input-wrapper--inline" : "input-wrapper",
      label,
      errorMessage,
      children;

    label = (
      <label key="label" {...this.props.labelProps} htmlFor={this.props.id}>
        {this.props.label}
      </label>
    );

    if (this.props.wrapperClass) {
      wrapperClass += " " + this.props.wrapperClass;
    }

    if (this.props.error) {
      wrapperClass += " error";
      if (_.isBoolean(this.props.error)) {
        // ie. Display the input as invalid but sans error
        // This is done because of backwards compat
      } else {
        errorMessage = (
          <p key="error" className="error-message color-alert">
            {this.props.error}
          </p>
        );
      }
    }

    if (this.props.inputBefore) {
      children = [this.props.children, label, errorMessage];
    } else {
      children = [label, this.props.children, errorMessage];
    }

    return <div className={wrapperClass}>{children}</div>;
  }
}

InputWrapper.propTypes = propTypes;
export default InputWrapper;
