import _ from "underscore";
import ActionTypes from "actions/action-types.js";
var types, fn, getShowroom;

types = [
  ActionTypes.FETCH_NEAREST_STORE_LOADING,
  ActionTypes.FETCH_NEAREST_STORE_SUCCESS,
  ActionTypes.FETCH_NEAREST_STORE_ERROR,
];

fn = function (state, action) {
  switch (action.type) {
    case ActionTypes.FETCH_NEAREST_STORE_SUCCESS:
      return _.extend({}, state, {
        nearestStore: getShowroom(action.payload),
      });
    case ActionTypes.FETCH_NEAREST_STORE_ERROR:
      return _.extend({}, state, {
        // safely set undefined
        nearestStore: _.noop(),
      });
    default:
      return _.extend({}, state, {});
  }
};

getShowroom = function (storeData) {
  var showroom = storeData.showroom;

  // we want to differentiate between a failed request and a successful
  // request that returned non-retail geo
  if (!showroom || !showroom.showroomAddress) {
    return null;
  }

  var showroomKey = showroom.showroomAddress.zipcode;

  return _.extend({}, showroom, storeData[showroomKey], storeData.distanceFromShowroom);
};

export default {
  types: types,
  fn: fn,
};
