import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { compose } from "redux";
import { DiscoSearchServiceClient } from "../clients/DiscoSearchServiceClient";
import {
  mapGridQueryToDiscoQuery,
  setFilter,
  mapWorkingFiltersToGridQuery,
  addUserId,
  removeFilterWhen,
} from "../helpers/disco-filter-helpers";
import {
  isProspectWithNoIntent,
  isProspectWithSubscriptionIntent,
  isSubscriptionMember,
} from "helpers/membership-helpers";
import { getRentalIntent } from "helpers/rental-intent-helper";

const initialStateProducts = {
  gridProducts: [],
  gridCount: 0,
  gridLoading: true,
  error: null,
  // differentiating filters that disco retruns from static filters
  informedFilters: [],
};

const determineAvailableOnly = state => {
  // Show prospects with sub intent all items
  if (isProspectWithSubscriptionIntent(state.membershipState, getRentalIntent(state?.userData))) {
    if (state.flagsAndExperiments["sact-sf-sact-98-available-now-items-in-sub-lens"] === true) {
      return true;
    }
    return false;
  } else if (isSubscriptionMember(state.userData)) {
    // Subscribers can manually set what avbailability they see
    return state.workingFilters?.availableOnly;
  }
  return true;
};

const initialStateByStyleName = {
  products: [],
  error: null,
};

export const fetchProductsFns = {
  clearance: (query, page, limit) => DiscoSearchServiceClient.getInstance().clearanceSearch(query, page, limit),
  curation: (query, page, limit, lens) =>
    DiscoSearchServiceClient.getInstance().curationSearch(query, page, limit, lens),
  designer: (query, page, limit, lens) =>
    DiscoSearchServiceClient.getInstance().designerSearch(query, page, limit, lens),
  category: (query, page, limit, lens) =>
    DiscoSearchServiceClient.getInstance().categorySearch(query, page, limit, lens),
  view_all: (query, page, limit, lens) =>
    DiscoSearchServiceClient.getInstance().viewAllSearch(query, page, limit, lens),
};

export const fetchProducts = createAsyncThunk("products/fetchProducts", async (page, { getState }) => {
  const state = getState();

  const lens = state.userData?.lens;

  const showSizeFilters = state.flagsAndExperiments["sact-sf-sact-39-size-location-filter-for-sub-lens"] === true;

  const showFilters = showSizeFilters
    ? () => isProspectWithNoIntent(state.membershipState, getRentalIntent(state?.userData))
    : () =>
        isProspectWithSubscriptionIntent(state.membershipState, getRentalIntent(state?.userData)) ||
        isProspectWithNoIntent(state.membershipState, getRentalIntent(state?.userData));

  const discoQuery = compose(
    removeFilterWhen("canonicalSizes", showFilters),
    setFilter("availableOnly", determineAvailableOnly(state)),
    addUserId(state.userData?.userProfile?.id),
    filters => mapGridQueryToDiscoQuery(filters, state.nextGridType),
    setFilter("sort", state.sort || state.workingSort),
    mapWorkingFiltersToGridQuery
  )(state.workingFilters);
  return fetchProductsFns[state.nextGridType](discoQuery, page, void 0, lens);
});

export const fetchProductsByStyleNames = createAsyncThunk("products/fetchProductsByStyleNames", async params => {
  const clientResponse = DiscoSearchServiceClient.getInstance().productsDataByStyleNames(params);
  return clientResponse;
});

export const productsSlice = createSlice({
  name: "products",
  initialState: initialStateProducts,
  extraReducers: builder => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.gridProducts = action.payload.items; // Assign action.payload directly to gridProducts
      state.gridCount = action.payload.count.total;
      state.informedFilters = action.payload.filters;
      state.assortment = action.payload?.meta?.assortment || [];
    });
  },
});

export const productsSliceByStyleName = createSlice({
  name: "products",
  initialState: initialStateByStyleName,
  extraReducers: builder => {
    builder.addCase(fetchProductsByStyleNames.fulfilled, (state, action) => {
      state.products = action.payload.items;
    });
  },
});

export const productSliceReducer = {
  // ReduxApp expects reducers to have a "types" property
  types: ["products/fetchProducts/fulfilled"],
  fn: productsSlice.reducer,
};

export const productSliceByStyleNames = {
  // ReduxApp expects reducers to have a "types" property
  types: ["products/fetchProductsByStyleNames/fulfilled"],
  fn: productsSliceByStyleName.reducer,
};
