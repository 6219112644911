import _ from "underscore";
import ActionTypes from "actions/action-types.js";
import {
  allElementsValid,
  updateElementInReducer,
  validateAllElementsInSubform,
} from "helpers/subform-elements-helper";
import { clientSideErrorMessages, formElementNames } from "rtr-constants";

const { formValidation } = clientSideErrorMessages;

const types = [
  ActionTypes.BILLING_STEP_SUBMITTING,
  ActionTypes.BILLING_STEP_SUCCESS,
  ActionTypes.BILLING_STEP_FAILURE,
  ActionTypes.BILLING_STEP_UPDATE_ELEMENT,
  ActionTypes.BILLING_STEP_SET_VALUES_FOR_EDIT,
  ActionTypes.BILLING_STEP_CLEAR_ELEMENT_VALUES,
  ActionTypes.BILLING_STEP_CLEAR_ADDRESS_FIELDS,
  ActionTypes.BILLING_STEP_USE_SHIPPING_ADDRESS,
  ActionTypes.BILLING_STEP_VALIDATE_BEFORE_SUBMIT,
];

const { street, apt, city, state, zipCode, phone } = formElementNames;
const addressFields = [street, apt, city, state, zipCode, phone];

// const editableKeys = [
//   "firstName",
//   "lastName",
//   "street1",
//   "street2",
//   "zoneCode",
//   "phone",
//   "zoneCode",
//   "postalCode",
//   "default",
//   "city",
//   "expiration"
// ];

const initialState = {
  isValid: false,
  isSubmitting: false,
  errors: {},
  elements: {
    [formElementNames.ccFirstName]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.firstName,
    },
    [formElementNames.ccLastName]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.lastName,
    },
    [formElementNames.street]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.enterValidStreetAddress,
    },
    [formElementNames.apt]: {
      isValid: false,
      value: "",
      isRequired: false,
    },
    [formElementNames.city]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.city,
    },
    [formElementNames.state]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.selectState,
    },
    [formElementNames.zipCode]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.zipCode,
    },
    [formElementNames.phone]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.phoneNumber,
    },
  },
};

// firstName and lastName have different keys (for the off chance that
// both are on the page at the same time, we don't want multiple elements
// with the same input name and/or id)
const billingShippingNameVariationMap = {
  [formElementNames.ccFirstName]: formElementNames.firstName,
  [formElementNames.ccLastName]: formElementNames.lastName,
};

const fn = function (state, action) {
  const { stateKey } = action.payload;

  // get initial state if state is undefined
  const billingState = state?.[stateKey] ?? initialState;

  // deep copy initial state to a new object
  const nextBillingState = _.extend({}, billingState);

  nextBillingState.errors = _.extend({}, nextBillingState.errors);

  nextBillingState.elements = _.extend({}, nextBillingState.elements);

  _.each(nextBillingState.elements, (elementState, element) => {
    nextBillingState.elements[element] = _.extend({}, elementState);
  });

  // handle actions
  switch (action.type) {
    case ActionTypes.BILLING_STEP_SUBMITTING:
      nextBillingState.isSuccess = false;
      if (nextBillingState.errors?.form) {
        nextBillingState.errors.form = "";
      }
      nextBillingState.isSubmitting = true;
      break;

    case ActionTypes.BILLING_STEP_VALIDATE_BEFORE_SUBMIT:
      validateAllElementsInSubform(nextBillingState);
      break;

    case ActionTypes.BILLING_STEP_SUCCESS:
      nextBillingState.isSubmitting = false;
      nextBillingState.isSuccess = true;
      //  Reset elements and values.
      nextBillingState.isValid = false;
      nextBillingState.errors = {};
      _.each(nextBillingState.elements, (_val, key) => {
        nextBillingState.elements[key] = initialState.elements[key];
      });
      break;

    case ActionTypes.BILLING_STEP_FAILURE: {
      const { errors, checkIsValid = false } = action.payload;

      nextBillingState.isSuccess = false;
      nextBillingState.isSubmitting = false;
      nextBillingState.isValid = checkIsValid && allElementsValid(nextBillingState);
      nextBillingState.errors = errors;
      break;
    }

    case ActionTypes.BILLING_STEP_CLEAR_ELEMENT_VALUES:
      nextBillingState.isValid = false;
      nextBillingState.billingIsSameAsShipping = false;
      nextBillingState.errors = {};
      _.each(nextBillingState.elements, (_val, key) => {
        nextBillingState.elements[key] = initialState.elements[key];
      });
      break;

    case ActionTypes.BILLING_STEP_CLEAR_ADDRESS_FIELDS:
      nextBillingState.isValid = false;
      nextBillingState.billingIsSameAsShipping = false;

      // Only clear errors related to address fields.
      nextBillingState.errors = _.omit(nextBillingState.errors, addressFields);

      _.each(addressFields, elementName => {
        // We only want to clear address fields, so that
        // a user can fill in CC information and then decide to use
        // a different address.
        nextBillingState.elements[elementName] = initialState.elements[elementName];
      });
      break;

    case ActionTypes.BILLING_STEP_SET_VALUES_FOR_EDIT:
      /*
       * This will be uncommented when we answer a few questions regarding updating elements and validation.
       * nextBillingState = updateElementForEdit(nextBillingState, action, formElementNames, editableKeys, true);
       *
       */
      break;

    case ActionTypes.BILLING_STEP_USE_SHIPPING_ADDRESS:
      nextBillingState.billingIsSameAsShipping = true;

      // Only clear errors related to address fields.
      nextBillingState.errors = _.omit(nextBillingState.errors, addressFields);

      // The payload here contains the shipping address fields and values, but will
      // also have a "stateKey" field, so we check to make sure the key exists in
      // nextBillingState.elements before referring to elements[key].value and .isValid.
      _.chain(action.payload)
        .pick((val, key) => _.has(nextBillingState.elements, key))
        .each((val, key) => {
          nextBillingState.elements[key].value = val;
          nextBillingState.elements[key].isValid = true;
        });

      _.each(billingShippingNameVariationMap, (shippingKey, billingKey) => {
        // underscore swaps key & val!
        nextBillingState.elements[billingKey].value = action.payload[shippingKey];
        nextBillingState.elements[billingKey].isValid = true;
      });

      // Set the overall isValid state of the form
      nextBillingState.isValid = allElementsValid(nextBillingState);
      break;

    case ActionTypes.BILLING_STEP_UPDATE_ELEMENT:
      updateElementInReducer(nextBillingState, action);
      break;

    default:
      break;
  }

  const newState = {};
  newState[stateKey] = nextBillingState;
  return _.extend({}, state, newState);
};

export default {
  types,
  fn,
  initialState,
};
