import { stringify as qsStringify } from "qs";
import { StatusCodes } from "http-status-codes";

/**
 * Stringify params into a query string
 * @param {any} params The object to stringify
 * @param  {import("qs").IStringifyOptions} queryStringConfig The config to use when turning the params into a query string
 * @returns {string} The query string representation of the params
 */
function stringify(params, queryStringConfig) {
  return qsStringify(params, queryStringConfig);
}

// Emulate Protected methods (invisible externally, callable in children) using Symbols
export const PROTECTED_METHODS = {
  url: Symbol(),
};

export const BASE_FETCH_OPTIONS = {
  // TODO: we should remove this, some ajax should be (short-term) cacheable in the browser
  cache: "no-cache", // https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
  method: "GET",
  timeout: 30000,
};

export default class BaseServicesClient {
  #host;
  #queryStringConfig;

  constructor(host, queryStringConfig = { arrayFormat: "brackets", encodeValuesOnly: true }) {
    this.#host = host;
    this.#queryStringConfig = queryStringConfig;
  }

  static _instance;
  static getInstance() {
    if (!this._instance) {
      if (!this.CONFIG_HOST) throw new Error(`Expected CONFIG_HOST to be defined in ${this.name}`);

      this._instance = new this(this.CONFIG_HOST);
    }

    return this._instance;
  }

  static setHost(newHostUrl) {
    if (this.CONFIG_HOST) {
      this.CONFIG_HOST = newHostUrl + this.CONFIG_HOST;
    } else {
      this.CONFIG_HOST = newHostUrl;
    }
  }

  async get(path, params, options) {
    return this.#request(this[PROTECTED_METHODS.url](path, params), {
      ...options,
      method: "GET",
    });
  }

  async getJson(path, params, options) {
    const response = await this.get(path, params, options);
    if (response.status === StatusCodes.NO_CONTENT) {
      return "";
    }
    return response.json();
  }

  async post(path, params, options) {
    let { body } = options;

    if (!body) {
      if (params instanceof FormData) {
        body = params; // fetch will handle form data natively for us
      } else if (typeof params === "string") {
        // check for stringified data
        // if stringified, let's skip the uri encoding from stringify
        body = params;
      } else {
        body = stringify(params, this.#queryStringConfig);
      }
    }

    return this.#request(this[PROTECTED_METHODS.url](path), {
      ...options,
      body,
      method: "POST",
    });
  }

  async put(path, params, options) {
    let { body } = options;

    if (!body) {
      if (params instanceof FormData) {
        body = params; // fetch will handle form data natively for us
      } else if (typeof params === "string") {
        // check for stringified data
        // if stringified, let's skip the uri encoding from stringify
        body = params;
      } else {
        body = stringify(params, this.#queryStringConfig);
      }
    }

    return this.#request(this[PROTECTED_METHODS.url](path), {
      ...options,
      body,
      method: "PUT",
    });
  }

  async patch(path, params, options) {
    let { body } = options;

    if (!body) {
      if (params instanceof FormData) {
        body = params; // fetch will handle form data natively for us
      } else if (typeof params === "string") {
        // check for stringified data
        // if stringified, let's skip the uri encoding from stringify
        body = params;
      } else {
        body = stringify(params, this.#queryStringConfig);
      }
    }

    return this.#request(this[PROTECTED_METHODS.url](path), {
      ...options,
      body,
      method: "PATCH",
    });
  }

  delete(path, params, options) {
    return this.#request(this[PROTECTED_METHODS.url](path, params), {
      ...options,
      method: "DELETE",
    });
  }

  /*
   * Protected/Internal Methods
   */

  [PROTECTED_METHODS.url](path, params) {
    let url = /https?:\/\//.test(path)
      ? path
      : [
          this.#host,
          path?.replace(/^\/+/, ""), // Removing the leading slash '/' from path since we will join with '/'
        ]
          .filter(t => t)
          .join("/");

    if (params) {
      const separator = url.includes("?") ? "&" : "?";
      url += separator + stringify(params, this.#queryStringConfig);
    }

    return url;
  }

  #request(url, options) {
    if (!url) {
      const response = {
        error: "BaseClient Error: Bad Request",
      };

      return Promise.reject(response);
    }

    // This is the base implementation of RawClient
    // eslint-disable-next-line no-restricted-globals
    return fetch(url, {
      ...BASE_FETCH_OPTIONS,
      ...options,
    });
  }
}
