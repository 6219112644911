import { isProduction } from "helpers/environment-helpers";
import { fireWithUserData, getUserId, hasUserData } from "../components/source/hoc/with-user-data";

export const identifyUserIdWithUserData = userData => {
  if (hasUserData(userData)) {
    identifyUserId(getUserId(userData));
  } else {
    fireWithUserData(userData, () => identifyUserId(getUserId(userData)));
  }
};

const identifyUserId = userId => {
  // Set userId if there is one
  if (userId) {
    setUserId(userId);
  } else {
    resetIdentifyUserId();
  }
};

const setUserId = userId => {
  if (!window.SplunkRum) {
    return;
  }
  if (!isProduction()) {
    console.debug("[SplunkRum] set userId", userId);
  }
  window.SplunkRum.setGlobalAttributes({
    "enduser.id": userId,
  });
};

const resetIdentifyUserId = () => {
  if (!window.SplunkRum) {
    return;
  }
  if (!isProduction()) {
    console.debug("[SplunkRum] reset userId");
  }
  window.SplunkRum.setGlobalAttributes({
    "enduser.id": "anonymous",
  });
};
