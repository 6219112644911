import _ from "underscore";
import ActionTypes from "actions/action-types.js";
import {
  updateElementInReducer,
  setElementsFromAutocomplete,
  validateAllElementsInSubform,
} from "helpers/subform-elements-helper";
import { parseSuggestedAddress } from "helpers/address-helpers";
import { clientSideErrorMessages, formElementNames } from "rtr-constants";
const { formValidation } = clientSideErrorMessages;

const types = [
  ActionTypes.SHIPPING_STEP_SET_ISVALID,
  ActionTypes.SHIPPING_STEP_SUBMITTING,
  ActionTypes.SHIPPING_STEP_SUBMITTING_RESET,
  ActionTypes.SHIPPING_STEP_SUCCESS,
  ActionTypes.SHIPPING_STEP_FAILURE,
  ActionTypes.SHIPPING_STEP_UPDATE_SHIPPING_TYPE,
  ActionTypes.SHIPPING_STEP_UPDATE_ELEMENT,
  ActionTypes.SHIPPING_STEP_VALIDATION_CONFIRMATION_REQUIRED,
  ActionTypes.SHIPPING_STEP_VALIDATION_SUBMITTED,
  ActionTypes.SHIPPING_STEP_SET_AUTOCOMPLETE_VALUES,
  ActionTypes.SHIPPING_STEP_VALIDATE_BEFORE_SUBMIT,
  ActionTypes.SHIPPING_STEP_INIT_VALUES,
];

const initialState = {
  shippingType: "ship",
  isValid: false,
  isSubmitting: false,
  errors: {},
  elements: {
    [formElementNames.firstName]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.firstName,
    },
    [formElementNames.lastName]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.lastName,
    },
    [formElementNames.street]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.enterValidStreetAddress,
    },
    [formElementNames.apt]: {
      isValid: false,
      defaultValue: "",
      value: "",
      isRequired: false,
    },
    [formElementNames.city]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.city,
    },
    [formElementNames.state]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.selectState,
    },
    [formElementNames.zipCode]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.zipCode,
    },
    [formElementNames.phone]: {
      isValid: false,
      value: "",
      isRequired: true,
      defaultErrorMsg: formValidation.phoneNumber,
    },
    [formElementNames.makeDefault]: {
      isValid: true,
      value: true,
      isRequired: true,
    },
  },
};

const fn = function (state, action) {
  // get initial state if state is undefined
  const shippingState = state?.shippingStep ?? initialState;

  // deep copy initial state to a new object
  const nextShippingState = _.extend({}, shippingState);

  nextShippingState.errors = _.extend({}, nextShippingState.errors);

  nextShippingState.elements = _.extend({}, nextShippingState.elements);

  _.each(nextShippingState.elements, (elementState, element) => {
    nextShippingState.elements[element] = _.extend({}, elementState);
  });

  // handle actions
  switch (action.type) {
    case ActionTypes.SHIPPING_STEP_SET_ISVALID:
      nextShippingState.isValid = action.payload.isValid;
      break;

    case ActionTypes.SHIPPING_STEP_VALIDATE_BEFORE_SUBMIT:
      validateAllElementsInSubform(nextShippingState);
      break;

    case ActionTypes.SHIPPING_STEP_SUBMITTING:
      if (nextShippingState.errors?.form) {
        nextShippingState.errors.form = "";
      }
      nextShippingState.isSubmitting = true;
      break;

    case ActionTypes.SHIPPING_STEP_INIT_VALUES:
      nextShippingState.isSubmitting = false;
      nextShippingState.isValid = false;
      nextShippingState.isSubmitting = false;
      nextShippingState.errors = {};
      _.each(nextShippingState.elements, (_val, key) => {
        if (action.payload[key]) {
          nextShippingState.elements[key].value = action.payload[key];
        }
      });
      break;

    // These actions should be named different names but do the same thing.
    case ActionTypes.SHIPPING_STEP_SUBMITTING_RESET:
    case ActionTypes.SHIPPING_STEP_SUCCESS:
      nextShippingState.isSubmitting = false;
      //  Reset elements and values.
      nextShippingState.isValid = false;
      nextShippingState.isSubmitting = false;
      nextShippingState.errors = {};
      _.each(nextShippingState.elements, (_val, key) => {
        nextShippingState.elements[key] = initialState.elements[key];
      });
      break;

    case ActionTypes.SHIPPING_STEP_FAILURE:
      nextShippingState.isSubmitting = false;
      nextShippingState.isValid = false;
      nextShippingState.errors = action.payload.errors;
      break;

    case ActionTypes.SHIPPING_STEP_UPDATE_SHIPPING_TYPE:
      nextShippingState.shippingType = action.payload.shippingType;
      break;

    case ActionTypes.SHIPPING_STEP_UPDATE_ELEMENT:
      updateElementInReducer(nextShippingState, action);
      break;

    case ActionTypes.SHIPPING_STEP_VALIDATION_CONFIRMATION_REQUIRED:
      nextShippingState.suggestedAddresses = _.map(action.payload.suggestedAddresses, suggestedAddress => {
        return parseSuggestedAddress(suggestedAddress);
      });

      nextShippingState.userSubmittedAddress = action.payload.userSubmittedAddress;
      nextShippingState.addressValidationError = action.payload.addressValidationError;
      break;

    case ActionTypes.SHIPPING_STEP_SET_AUTOCOMPLETE_VALUES: {
      const addressComponents = action.payload.address.address_components;
      setElementsFromAutocomplete(nextShippingState, addressComponents);
      break;
    }
    case ActionTypes.SHIPPING_STEP_VALIDATION_SUBMITTED:
      updateElementInReducer(nextShippingState, action);
      break;

    default:
      break;
  }

  return _.extend({}, state, {
    shippingStep: nextShippingState,
  });
};

export default {
  types,
  fn,
  initialState,
};
