import { getBillingPaymentMethod } from "helpers/payment-method-helpers";
import { PaymentMethods } from "rtr-constants";

export const PAYMENT_OPTION_RULE = {
  DISPLAY_APPLE_PAY: "displayApplePay",
  DISPLAY_COF: "displayCOF",
  UPDATE_BILLING: "updateBilling",
  DISPLAY_BOTH: "displayBoth",
};

export const evaluateApplePayOptions = (paymentMethods, isSupportedDevice) => {
  const recurringMethodType = getBillingPaymentMethod(paymentMethods)?.subType ?? PaymentMethods.subType.CREDIT_CARD;

  if (recurringMethodType === PaymentMethods.subType.APPLE_PAY) {
    // if device is not supported then customer prompted to update their billing method (to a credit card)
    return isSupportedDevice ? PAYMENT_OPTION_RULE.DISPLAY_APPLE_PAY : PAYMENT_OPTION_RULE.UPDATE_BILLING;
  } else {
    // if on a supported device show both options (we can assume cof in this case)
    // otherwise just show confirmation cta
    return isSupportedDevice ? PAYMENT_OPTION_RULE.DISPLAY_BOTH : PAYMENT_OPTION_RULE.DISPLAY_COF;
  }
};

export const membershipIsBilledToApplePay = paymentMethods => {
  if (!paymentMethods) return false;
  return paymentMethods?.some(
    paymentMethod => paymentMethod.subType === PaymentMethods.subType.APPLE_PAY && paymentMethod.recurring === true
  );
};
