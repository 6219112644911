import React from "react";
import PropTypes from "prop-types";

export default class AtomPlusInCircleIcon extends React.PureComponent {
  static propTypes = {
    radius: PropTypes.number,
  };

  static defaultProps = {
    radius: 45,
  };

  render() {
    const { radius } = this.props;

    return (
      <svg className="atom-plus-in-circle-icon" viewBox="0 0 100 100" data-test-id={"atom-plus-circle-icon"}>
        <circle cx="50" cy="50" r={radius} strokeWidth="0"></circle>
        <line x1="32.5" y1="50" x2="67.5" y2="50"></line>
        <line x1="50" y1="32.5" x2="50" y2="67.5"></line>
      </svg>
    );
  }
}
