import otel from "@opentelemetry/api";
import getComponentDisplayName from "../assets/javascripts/get-component-display-name";
import { SFLogger } from "../assets/javascripts/logger/logger";

const logger = SFLogger("AppObserver");

/**
 * "Observer" that handles enriching the current telemetry span with Next-specific attributes and logging information about the request
 * @param {AppProps} appProps The props provided to _app.jsx as part of rendering the page
 */
export default function AppObserver(appProps) {
  const { Component, router } = appProps;

  logger.debug(`Serving route=${router.asPath}, component=${getComponentDisplayName(Component)}`);

  const span = otel.trace.getSpan(otel.context.active());

  if (span) {
    // span will be null if telemetry is off (e.g. development)
    span.setAttributes({
      "next.component": getComponentDisplayName(Component),
      "next.route": router.route,
      "next.query.json": JSON.stringify(router.query),
    });
  }

  // allow for further processing
  return appProps;
}
