// if your component requires that one of several props must be provided,
// `oneOfRequired` is for you.
// usage:
//   static propTypes = {
//     name: oneOfRequired("name", "company"),
//     company: oneOfRequired("name", "company")
//   }
// valid:
//   <Component name="abc" />
//   <Component company="def" />
// invalid:
//   <Component />
export function oneOfRequired(...propNames) {
  return (props, _propName, componentName) => {
    if (propNames.some(n => props[n])) return;

    const last = propNames.pop();
    let propsList = propNames.map(p => `'${p}'`).join(", ");
    if (propsList) {
      if (propNames.length > 1) propsList += ","; // oxford comma
      propsList += " or ";
    }
    propsList += `'${last}'`;

    return new Error(`One of ${propsList} is required by '${componentName}' component.`);
  };
}
