import React from "react";
import PropTypes from "prop-types";

import AtomButton from "components/source/atoms/atom-button";
import ProductImage from "../shared/product-image";
import {
  getProductImageAltText,
  getProductImageUrlsByOrientation,
  mapLegacyProductImageBySizeToOrientation,
  ProductImageOrientation,
  ProductImageSize,
} from "../../../helpers/product-image-helpers";

const ANALYTICS_SOURCE = "notif_header";
export default class UserMenuReviewsTooltip extends React.Component {
  static propTypes = {
    displayReviewModal: PropTypes.func.isRequired, // (styleName, popupSource) => {}
    pendingReviews: PropTypes.arrayOf(
      PropTypes.shape({
        review: PropTypes.shape({
          photoCount: 1,
        }),
        reviewCount: PropTypes.number, // NW [EXPLANATION] 6/22/21: this is the number of reviews the user has completed
        style: PropTypes.shape({
          designerName: PropTypes.string,
          displayName: PropTypes.string,
          imgThumbnailFrontUrl: PropTypes.string,
          styleName: PropTypes.string,
        }),
      })
    ),
  };

  onClickReviewButton = (e, styleName) => {
    // NW [EXPLANATION] 7/29/21: this is an anchor tag, and we want to stop navigation to the href because a modal will open
    e?.preventDefault();
    e?.stopPropagation();
    this.props.displayReviewModal(styleName, ANALYTICS_SOURCE);
  };

  renderReviews() {
    const { pendingReviews } = this.props;

    if (pendingReviews && pendingReviews.length > 0) {
      return (
        <ul className="tooltip-list">
          {pendingReviews.map(reviewItem => {
            const incomplete = reviewItem?.review && reviewItem.review.photoCount === 0;
            const style = reviewItem?.style;
            if (style) {
              return (
                <li key={style.styleName}>
                  <div className="tooltip-review-item">
                    <ProductImage
                      altText={getProductImageAltText(style.displayName, style.designerName)}
                      imageGroup={getProductImageUrlsByOrientation(
                        mapLegacyProductImageBySizeToOrientation(style.imagesBySize),
                        [ProductImageOrientation.FRONT.path]
                      )}
                      imageSize={ProductImageSize.x70}
                      fallbackSrc={style.imgThumbnailFrontUrl}
                    />
                    <AtomButton
                      className="btn-secondary new-review-popup"
                      onClick={eventArgs => this.onClickReviewButton(eventArgs, style.styleName)}
                      buttonText={incomplete ? "Add Photo" : "Review It"}></AtomButton>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      );
    }
  }

  render() {
    const { pendingReviews } = this.props;

    let title = "Welcome";
    let subtitle = "You have no rentals to review.";
    const reviewsArePending = pendingReviews && pendingReviews.length > 0;

    if (reviewsArePending && pendingReviews[0].reviewCount > 3) {
      title = "Top Contributor";
      subtitle = "Thanks for being part of our community!";
    } else if (reviewsArePending) {
      title = "Got a minute?";
      subtitle = "Share your review + photo";
    }

    return (
      <>
        <div className="dek-one">{title}</div>
        <p className="ss-tooltip-subtitle">{subtitle}</p>
        {this.renderReviews()}
        <p>
          <a href="/user/orders" className="tooltip-link">
            See All My Orders
          </a>
        </p>
      </>
    );
  }
}
