import React from "react";
import PropTypes from "prop-types";
import { getDisplayName } from "./utils";
import { useSelector, useDispatch } from "react-redux";
import SharedActions from "actions/shared-actions";
import withWindowResize from "./with-window-resize";
import HighlightedComponentStyles from "../shared/highlighted_component/styles.module.scss";

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

const activeOverlayClass = HighlightedComponentStyles["highlighted-component-target"];
export const useHighlight = () => {
  const windowSize = withWindowResize();
  const dispatch = useDispatch();

  const highlightedComponent = useSelector(state => state.highlightedComponent);
  const isMobile = useSelector(state => state.browser?.isMobileViewport);

  const [toolTipStyles, setToolTipStyles] = React.useState({});
  const [toolTipArrowStyles, setToolTipArrowStyles] = React.useState({});

  // rerender background overlay, tooltip and highlight overlay when window adjusts
  React.useEffect(() => {
    if (highlightedComponent?.componentId) {
      highlightElement(highlightedComponent.componentId);
    }
  }, [windowSize]);

  // highlight component when redux state is fulfilled
  React.useEffect(() => {
    if (highlightedComponent?.componentId && highlightedComponent?.toolTipContent) {
      highlightElement(highlightedComponent.componentId);
    }
  }, [highlightedComponent]);

  const highlightElement = highlightElementId => {
    if (!highlightElementId) {
      return;
    }
    const elem = document.getElementById(highlightElementId);
    if (elem && windowSize?.width) {
      const rect = elem.getBoundingClientRect();
      const isInView =
        rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowSize.height && rect.right <= windowSize.width;
      const scrollOptions = {
        top: window.scrollY + rect.top - 290,
        behavior: "smooth",
      };
      if (!isInView) {
        elem.scrollIntoView({ block: "center" });
      }
      if (highlightElementId === "spotlight-initial-control-center") {
        scrollOptions.top = window.scrollY + rect.top - 190;
        isMobile && window.scrollTo(scrollOptions);
      }
      if (highlightElementId === "spotlight-initial-grids") {
        isMobile && window.scrollTo(scrollOptions);
        scrollOptions.top = window.scrollY + rect.top - 250;
        !isMobile && window.scrollTo(scrollOptions);
      }
      addOverlayClass(elem);
      document.body.style.overflow = "hidden";
    }
  };

  const addOverlayClass = targetElem => {
    const rect = targetElem.getBoundingClientRect();
    const x = rect.left;
    const y = rect.top;
    const w = rect.width;
    const h = rect.height;

    /* TOOLTIP DIRECTIONS */
    /*
     * top: under the component, so y coordinate + height of target component + height of arrow
     * bottom: above the component, so -1 * (y coordinate + height + height of arrow)
     * left: tooltip should always be on left hand side of element. It will only be on the right hand side if the right hand side passes the window
     */

    const toolTipWidth = 355;
    const toolTipLat = highlightedComponent?.toolTipDirection ?? "top";
    const toolTipLon = x + toolTipWidth > windowSize.width ? "right" : "left";

    const arrowHeight = 9;
    const arrowTargetGap = 10;
    const arrowPadding = 20;

    // positioning the tooltip
    const bodyLatOffset = y + h + arrowHeight + arrowTargetGap;
    const bodyLonOffset = x + toolTipWidth > windowSize.width ? x + w - (toolTipWidth - arrowPadding) : x;

    const topPosition =
      isMobile && highlightedComponent.componentId === "spotlight-initial-control-center" ? 425 : bodyLatOffset;

    setToolTipStyles({
      top: `${toolTipLat === "top" ? topPosition : 0}px`,
      left: isMobile ? "0" : `${bodyLonOffset}px`,
    });

    const arrowLonOffset = toolTipLon === "left" ? 20 : 335 - 20 - 10;

    if (highlightedComponent?.toolTipDirection === "top") {
      // positioning the tooltip arrow
      setToolTipArrowStyles({
        // for mobile, we want the arrow to be in the middle of the targeted component
        // other than that, place it at the edge of the of either window size
        left: isMobile ? `${w / 2 + x - 10}px` : `${arrowLonOffset}px`,
      });
    } else {
      // positioning the tooltip arrow - invert the arrow if the tooltip is above the highlighted component
      const topPosition =
        isMobile && highlightedComponent.componentId === "spotlight-initial-control-center" ? "0px" : "227px";

      setToolTipArrowStyles({
        left: isMobile ? `${w / 2 + x - 10}px` : "10px",
        top: toolTipLat === "top" ? `${bodyLatOffset}px` : topPosition,
        transform: "scaleY(-1)",
      });
    }
    targetElem.classList.add(activeOverlayClass);
  };

  const removeHighlightElement = () => {
    const elements = document.getElementsByClassName(activeOverlayClass);
    Array.from(elements).forEach(highlightedElement => {
      highlightedElement.classList.remove(activeOverlayClass);
    });

    setToolTipStyles({});
    document.body.style.overflow = "scroll";
    dispatch(SharedActions.displayHighlightedComponent({}));
  };

  return {
    toolTipStyles,
    toolTipArrowStyles,
    removeHighlightElement,
  };
};

export const withHighlightHOC = WrappedComponent => {
  const propTypes = {
    removeHighlightElement: PropTypes.func,
    toolTipStyles: PropTypes.object,
    toolTipArrowStyles: PropTypes.object,
  };

  const C = props => {
    const { toolTipStyles, toolTipArrowStyles, removeHighlightElement } = useHighlight();

    return (
      <WrappedComponent
        // Reason: keep all of the original component's props in tact
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        toolTipStyles={toolTipStyles}
        toolTipArrowStyles={toolTipArrowStyles}
        removeHighlightElement={removeHighlightElement}
      />
    );
  };

  C.propTypes = { ...C.propTypes, ...propTypes };
  C.displayName = `withHighlightHOC(${getDisplayName(WrappedComponent)})`;

  return C;
};
