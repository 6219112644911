import PropTypes from "prop-types";

export const userReviewSummaryPropType = PropTypes.shape({
  reviewCount: PropTypes.number,
  photoCount: PropTypes.number,
  commentCount: PropTypes.number,
  complimentCount: PropTypes.number,
  rentalsOrdering: PropTypes.arrayOf(PropTypes.string),
  styles: PropTypes.any, // not sure!
});
