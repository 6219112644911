import { LocalStorage } from "../site/localStorage";

// Do not use the ls* functions for localStorage!
// Create an instance of the LocalStorage class instead.
export function lsSetKeyValue(key, value) {
  if (LocalStorage.disabled) {
    return;
  }
  localStorage[key] = value;
}

export function lsValueForKey(key) {
  if (LocalStorage.disabled) {
    return null;
  }
  return localStorage[key];
}
