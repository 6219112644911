import { isAfter, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { contact, dateFnsFormats } from "rtr-constants";
import actionUtils from "actions/action-utils";
import exchangeActions from "actions/exchange-actions";
import { fetchUserProblemGroup } from "actions/user-problems-actions";

export const VIEW_STATES = {
  ENTRY: "entry",
  ORDER_RECEIVED: "order_received",
  ORDER_NOT_RECEIVED: "order_not_received",
  CREDITS: "credits",
  CONTACT: "contact",
};

export const numberOfReplacements = numProblemBookings => {
  return numProblemBookings >= 2 ? numProblemBookings : 2;
};

// Times must be in UTC in order to use this util
export const easternTimeAdjust = dateString => {
  if (!dateString) return toZonedTime(new Date(), dateFnsFormats.timezones.nyc);
  return toZonedTime(dateString, dateFnsFormats.timezones.nyc);
};

export const handleCall = isMobile => {
  if (isMobile) {
    window.location.href = `tel:${contact.phoneNumber}`;
  }
};

export const handleEmail = () => {
  window.location.href = `mailto:${contact.help}`;
};

export const handleExpiredCutoffs = (selfServiceExchange, problemGroup, dispatch) => {
  const { exchangeGroups } = selfServiceExchange;
  const { groupId } = problemGroup;
  const group = exchangeGroups[groupId];
  const { exchangeCutoffTime, bagCutoffTime } = group;
  const now = new Date();

  // new Date() uses UTC, and both exchangeCutoffTime and bagCutoffTime are in UTC
  if (isAfter(now, parseISO(exchangeCutoffTime || ""))) {
    actionUtils.redirectToRoute("/");
  } else if (isAfter(now, parseISO(bagCutoffTime || ""))) {
    exchangeActions.fetchSelfServiceExchanges(true)(dispatch);
    dispatch(fetchUserProblemGroup(true, true, groupId));
  }
};

export const getExchangeBookings = exchanges => {
  return exchanges.flatMap(e => e.bookings);
};
