import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
class ExpressBagPromoCode extends React.Component {
  static propTypes = {
    onRemoveCode: PropTypes.func,
    onSubmitCode: PropTypes.func,
    orderId: PropTypes.string,
    value: PropTypes.string,
    isLoading: PropTypes.bool,
    clearableCode: PropTypes.bool, // Clear a code instead of removing
    isDisabled: PropTypes.bool,
  };

  state = {
    value: this.props.value,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value === "") {
      this.setState({
        value: this.props.value,
      });
    }
  }

  onChange = event => {
    const value = event.currentTarget.value;
    this.setState({
      value: value,
      updated: true,
    });
  };

  onSubmit = () => {
    const code = this.state.value;

    if (!_.isEmpty(code)) {
      this.props.onSubmitCode(code, this.props.orderId);
      this.setState({
        updated: false,
      });
    }
  };

  onRemove = () => {
    const appliedPromo = this.props.value;
    this.props.onRemoveCode(appliedPromo, this.props.orderId);
  };

  promoIsApplied() {
    return !_.isEmpty(this.props.value);
  }

  ctaCopy() {
    if (this.props.isLoading) {
      return "Applying...";
    } else if (this.promoIsApplied() && this.props.clearableCode && !this.state.updated) {
      return "Clear";
    } else if (this.promoIsApplied() && !this.props.clearableCode) {
      return "Remove";
    } else {
      return "Apply";
    }
  }

  onClick = () => {
    if (this.promoIsApplied() && this.props.clearableCode && !this.state.updated) {
      this.setState({
        value: "",
        updated: false,
      });
    } else if (this.promoIsApplied() && !this.props.clearableCode) {
      return this.onRemove();
    } else {
      return this.onSubmit();
    }
  };

  render() {
    return (
      <div className="express-bag-promo-code">
        <input
          aria-label="promo code"
          className="express-bag-promo-code__input"
          type="text"
          id="promoCode"
          name="promoCode"
          onChange={this.onChange}
          placeholder="Optional"
          readOnly={this.promoIsApplied() && !this.props.clearableCode}
          value={this.state.value}></input>
        <button className="express-bag-promo-code__button" onClick={this.onClick} disabled={this.props.isDisabled}>
          {this.ctaCopy()}
        </button>
      </div>
    );
  }
}

export default ExpressBagPromoCode;
