import PropTypes from "prop-types";
import { idPropType } from "./id-prop-type";
import { membershipStatePropType } from "./membership-state-prop-type";

// TODO: specify the keys found on userData

export const userProfilePropType = PropTypes.shape({
  email: PropTypes.string,
  dateOfBirth: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  id: idPropType,
  zipCode: PropTypes.string,
});

export const userDataPropType = PropTypes.shape({
  authState: PropTypes.string,
  isRTRUpdateLens: PropTypes.bool,
  isUnlimitedLens: PropTypes.bool,
  isClassicLens: PropTypes.bool,
  memberships: PropTypes.shape({
    isFormerProMember: PropTypes.bool,
    isFormerRtrUpdateMember: PropTypes.bool,
    isFormerUnlimitedMember: PropTypes.bool,
    isProMember: PropTypes.bool,
    isRtrUpdateMember: PropTypes.bool,
    isSubscriptionMember: PropTypes.bool,
    isUnlimitedMember: PropTypes.bool,
    membershipState: membershipStatePropType,
    membershipStatuses: PropTypes.array,
    pro: PropTypes.object,
    proMembershipStatus: PropTypes.func,
    proPaymentFailed: PropTypes.func,
    rtrUpdate: membershipStatePropType,
    onboardingQuizResponse: PropTypes.object,
    userSelection: PropTypes.string,
  }),
  userProfile: userProfilePropType,
});
