// Helper file to add random utility functions

import { TimeoutError } from "../exceptions";

/**
 * Gets a random key from an object
 * @param object
 * @returns {string}
 */
export function getRandomObjectKey(object) {
  const keys = Object.keys(object || {});
  return keys[Math.floor(Math.random() * keys.length)];
}

/**
 * Function to asynchronously wait for a function to return a value that is defined (i.e. not 'undefined')
 * @param {function(): any} getValue Function that returns a value to be waited on. Passing a function is key here so
 * that the value is not statically undefined.
 * @param {number} timeoutMs Wait timeout in ms
 * @returns {Promise<any>}
 */
export function waitToBeDefined(getValue, timeoutMs = 5000) {
  return new Promise((resolve, reject) => {
    const check = () => {
      const value = getValue();
      if (typeof value === "undefined") return;
      clearInterval(interval);
      resolve(value);
    };
    const interval = setInterval(check, 100);
    check();

    if (!timeoutMs) return;

    setTimeout(() => {
      clearInterval(interval);
      reject(new TimeoutError(`Failed to retrieve global in ${timeoutMs}ms`));
    }, timeoutMs);
  });
}

/**
 * just a debounce function
 * @param function - callback function
 * @param number - wait time in ms
 * @returns {function} - debounced function
 */
export function debounce(callback, wait = 1000) {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
}

/**
 * Function to add a timeout to promise execution
 * @param {Function: Promise} promiseFn - Fn that returns a promise to execute with timeout set
 * @param {number} ms - timeout
 * @returns {Promise} resolves or rejects if promise executes within timeout
 */
export function withTimeout(promiseFn, ms = 5000) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new TimeoutError("Promise did not resolve in time"));
    }, ms);

    promiseFn()
      .then(resolve)
      .catch(reject)
      .finally(() => clearTimeout(timer)); // Clear the timeout if the promise resolves or rejects
  });
}
