import React from "react";
class HeartIcon extends React.Component {
  render() {
    return (
      <svg
        className="heart-icon"
        width="20px"
        height="17px"
        viewBox="0 0 20 17"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-labelledby="title">
        <title>Heart</title>
        <path d="M10.0473476,15.9286957 C9.99170475,15.9286957 9.93606189,15.9113043 9.88856189,15.8765217 L9.16452618,15.3450435 C4.90377618,12.2208696 1.22388332,9.52243478 1.00945475,5.83895652 C0.932776177,4.50817391 1.32363332,3.3346087 2.14063332,2.44626087 C2.96102618,1.55373913 4.20416903,1 5.38624046,1 C7.27538332,1 8.94399046,1.95026087 10.1213119,3.68591304 C10.4164905,3.27686957 10.6682405,2.95478261 10.8975976,2.69321739 C11.8930619,1.55373913 13.1395976,1 14.7077762,1 C15.8898476,1 17.133669,1.55373913 17.9533833,2.44626087 C18.7703833,3.33530435 19.1612405,4.50817391 19.0838833,5.83895652 C18.8701333,9.52243478 15.190919,12.2208696 10.9294905,15.3450435 L10.2054547,15.8765217 C10.1579547,15.9113043 10.1029905,15.9286957 10.0473476,15.9286957"></path>
      </svg>
    );
  }
}

export default HeartIcon;
