import _ from "underscore";
import activeOrdersReducer from "reducers/active-orders-reducer";
import billingStepReducer from "reducers/billing-step-reducer";
import cmsReducer from "reducers/cms-reducer";
import classicOrderReducer from "reducers/classic-order-reducer";
import classicOrderGroupReducer from "reducers/classic-order-group-reducer";
import checkoutPageStateReducer from "reducers/checkout-page-state-reducer";
import carouselReducer from "reducers/carousel-reducer";
import contentfulReducer from "reducers/contentful-reducer";
import flagsAndExperimentsReducer from "reducers/flags-and-experiments-reducer";
import homepageReducer from "reducers/homepage-reducer";
import homePickupPostalCodeReducer from "reducers/home-pickup-postal-code-reducer";
import homePickupReducer from "reducers/home-pickup-reducer";
import genericPayloadReducer from "reducers/generic-payload-reducer";
import happinessSurveyReducer from "reducers/happiness-survey-reducer";
import itemPurchaseChargeReducer from "reducers/item-purchase-charge-reducer";
import membershipStateReducer from "reducers/membership-state-reducer";
import ceBagReducer from "reducers/ce-bag-reducer";
import smBagReducer from "reducers/sm-bag-reducer";
import kifahReducer from "reducers/kifah-reducer";
import ceCheckoutReducer from "reducers/ce-checkout-reducer";
import openFiltersReducer from "reducers/open-filters-reducer";
import pageReducer from "reducers/page-reducer";
import partialProductsReducer from "reducers/partial-products-reducer";
import authReducer from "reducers/auth-reducer";
import replacementsReducer from "reducers/replacements-reducer";
import reservationReducer from "reducers/reservation-reducer";
import returnFlowReducer from "reducers/return-flow-reducer";
import reviewsReducer from "reducers/reviews-reducer";
import reviewPhotoReducer from "reducers/review-photo-reducer";
import simpleStateReplacementReducer from "reducers/simple-state-replacement-reducer";
import membershipBagReducer from "reducers/membership-bag-reducer";
import loyaltyAwardsReducer from "reducers/loyalty-awards-reducer";
import reservationFormReducer from "reducers/reservation-form-reducer";
import productDrawerReducer from "reducers/product-drawer-reducer";
import personalizedCarouselsReducer from "reducers/personalized-carousels-reducer";
import windowReducer from "reducers/window-reducer";
import onInitializationReducer from "reducers/on-initialization-reducer";
import holdPreferencesReducer from "reducers/hold-preferences-reducer";
import replacementsProductDrawerReducer from "reducers/replacements-product-drawer-reducer";
import fitSummaryReducer from "reducers/fit-summary-reducer";
import deliveryCutoffReducer from "reducers/delivery-cutoff-reducer";
import userReducer from "reducers/user-reducer";
import userProfileReducer from "reducers/userProfile-reducer";
import storesReducer from "reducers/stores-reducer";
import userProblemsReducer from "reducers/user-problems-reducer";
import giftCardsReducer from "reducers/gift-cards-reducer";
import smartComponentReducer from "reducers/smart-component-reducer";
import shippingStepReducer from "reducers/shipping-step-reducer";
import subscriptionNotificationReducer from "reducers/subscription-notification-reducer";
import giftCardReducer from "reducers/gift-card-reducer";
import addressReducer from "reducers/address-reducer";
import orderPaymentMethodsReducer from "reducers/order-payment-methods-reducer";
import subscriptionSkusReducer from "reducers/subscription-skus-reducer";
import promoReducer from "reducers/promo-reducer";
import exchangeReducer from "reducers/exchange-reducer";
import membershipChargeReducer from "reducers/membership-charge-reducer";
import postShipmentReducer from "reducers/post-shipment-reducer";
import itemsThatAreUpdatingReducer from "reducers/items-that-are-updating-reducer";
import shortlistsReducer from "reducers/shortlists-reducer";
import snackBarReducer from "reducers/snack-bar-reducer";
import tooltipReducer from "reducers/tooltip-reducer";
import oneTimeExperienceReducer from "reducers/one-time-experience-reducer";
import customerChannelAttributionSurveyReducer from "reducers/customer-channel-attribution-survey-reducer";
import upcomingOrderReducer from "reducers/upcoming-order-reducer";
import smartNavReducer from "reducers/smart-nav-reducer";
import sailthruMarketingReducer from "./reducers/sailthru-marketing-reducer";
import applePayReducer from "./reducers/apple-pay-reducer";
import contentModuleContainerReducer from "./reducers/content-module-container-reducer";
import { productSliceReducer, productSliceByStyleNames } from "./slices/products-slice";
import reviewSliceReducer from "./slices/reviews-slice";
import reviewCoverPhotoSliceReducer from "./slices/reviews-cover-photo-slice";
import reviewThumbnailCarouselSliceReducer from "./slices/reviews-thumbnail-carousel-slice";
import reviewsByReviewIdSliceReducer from "./slices/reviews-slice-by-reviewId";
import midGridContentSliceReducer from "./slices/midGrid-slice";
import notifyMeSliceReducer from "./slices/notifyMe-slice";
import actionSliceReducer from "./slices/action-slice";
import gridProductDrawerSliceReducer from "./slices/gridProductDrawer-slice";
import {
  availabilitySliceReducer,
  reservationSliceReducer,
  bonusStyleUpsellModalSliceReducer,
} from "./slices/reservation-slice";
import { membershipHomepageCarouselsReducer } from "./slices/membershipHomepageCarousels-slice";

const reducers = [
  actionSliceReducer,
  activeOrdersReducer,
  addressReducer,
  applePayReducer,
  authReducer,
  availabilitySliceReducer,
  billingStepReducer,
  bonusStyleUpsellModalSliceReducer,
  carouselReducer,
  ceBagReducer,
  ceCheckoutReducer,
  checkoutPageStateReducer,
  classicOrderGroupReducer,
  classicOrderReducer,
  cmsReducer,
  contentfulReducer,
  contentModuleContainerReducer,
  customerChannelAttributionSurveyReducer,
  deliveryCutoffReducer,
  exchangeReducer,
  fitSummaryReducer,
  flagsAndExperimentsReducer,
  genericPayloadReducer,
  giftCardReducer,
  giftCardsReducer,
  gridProductDrawerSliceReducer,
  happinessSurveyReducer,
  holdPreferencesReducer,
  homepageReducer,
  homePickupPostalCodeReducer,
  homePickupReducer,
  itemPurchaseChargeReducer,
  itemsThatAreUpdatingReducer,
  kifahReducer,
  loyaltyAwardsReducer,
  membershipBagReducer,
  membershipChargeReducer,
  membershipHomepageCarouselsReducer,
  membershipStateReducer,
  notifyMeSliceReducer,
  oneTimeExperienceReducer,
  onInitializationReducer,
  openFiltersReducer,
  orderPaymentMethodsReducer,
  pageReducer,
  partialProductsReducer,
  personalizedCarouselsReducer,
  postShipmentReducer,
  productDrawerReducer,
  promoReducer,
  replacementsProductDrawerReducer,
  replacementsReducer,
  reservationFormReducer,
  reservationReducer,
  reservationSliceReducer,
  returnFlowReducer,
  reviewPhotoReducer,
  reviewsReducer,
  sailthruMarketingReducer,
  shippingStepReducer,
  shortlistsReducer,
  simpleStateReplacementReducer,
  smartComponentReducer,
  smartNavReducer,
  smBagReducer,
  snackBarReducer,
  storesReducer,
  subscriptionNotificationReducer,
  subscriptionSkusReducer,
  tooltipReducer,
  upcomingOrderReducer,
  userProblemsReducer,
  userProfileReducer,
  userReducer,
  windowReducer,
  productSliceReducer,
  productSliceByStyleNames,
  reviewSliceReducer,
  reviewCoverPhotoSliceReducer,
  reviewThumbnailCarouselSliceReducer,
  reviewsByReviewIdSliceReducer,
  midGridContentSliceReducer,
];

function ReduxApp(state, action) {
  const reducer = _.find(reducers, r => _.contains(r.types, action.type));

  if (!reducer) {
    return state;
  }

  return reducer.fn(state, action);
}

export default ReduxApp;
