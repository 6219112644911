import _ from "underscore";
import { hasInventoryEligibility } from "helpers/membership-helpers";
import { inventoryEligibilities } from "rtr-constants";
import { fetchCarousels as fetchPersonalizedCarousels } from "actions/personalized-carousels-actions";
import { fetchMembershipHomepageCarousels } from "../slices/membershipHomepageCarousels-slice";
import { fetchCarouselById } from "actions/carousel-actions";
import { updateProductAvailabilityForCarousels } from "actions/reservation-form-actions";
import { maternityCarouselDueDateParam } from "helpers/maternity-helper";
import { shouldRefreshUpgradePricing } from "helpers/membership-upgrades-helpers";
import { flagsAndExperimentNames } from "../constants";

const HP_CAROUSEL_LIMIT_FOR_PERFORMANCE = 5;

function carouselProductLimitParam(props) {
  return props?.browser?.isMobileViewport
    ? props?.carouselProductLimit?.mobileLimit ?? "20"
    : props?.carouselProductLimit?.desktopLimit ?? "20";
}

function carouselPerformanceLimitParam(hpCarouselLimitFF) {
  if (hpCarouselLimitFF) {
    return {
      limit: HP_CAROUSEL_LIMIT_FOR_PERFORMANCE,
    };
  }
}

function membershipInventoryMinAvailabilityParams(membershipState, workingFilters = {}) {
  if (hasInventoryEligibility(membershipState, inventoryEligibilities.rtr_unlimited)) {
    return {
      unlimitedMinAvailability: workingFilters.unlimitedMinAvailability,
    };
  }

  if (hasInventoryEligibility(membershipState, inventoryEligibilities.rtr_update)) {
    return {
      rtrUpdateMinAvailability: workingFilters.rtrUpdateMinAvailability,
    };
  }
}

function generateParams(props = {}) {
  const { membershipState, userData, workingFilters, hpCarouselLimitFF } = props;

  const genericCarouselParams = {
    dueDate: maternityCarouselDueDateParam(userData),
    eligibleFor: membershipState?.inventoryEligibilities?.[0],
    productLimit: carouselProductLimitParam(props),
    sizes: (workingFilters?.canonicalSizes ?? []).join(","),
    zip_code: workingFilters?.zip_code ?? "",
    ...fitRateFilter(userData),
    ...membershipInventoryMinAvailabilityParams(membershipState, workingFilters),
    ...carouselPerformanceLimitParam(hpCarouselLimitFF),
  };

  return genericCarouselParams;
}

export function updateCarouselStyleAvailability(dispatch, styleName, props) {
  const params = generateParams(props);
  dispatch(updateProductAvailabilityForCarousels(styleName, params));
}

function fitRateFilter(userData) {
  return {
    primarySize: userData?.userProfile?.profiles?.primarySize ?? null,
  };
}

function dispatchFetchPersonalizedCarousels(dispatch = _.noop, params = {}) {
  dispatch(fetchPersonalizedCarousels(params));
}

export function shouldFetchPersonalizedCarousels(dispatch, prevProps = {}, nextProps = {}) {
  //
  // NOTE fail-safe is to ALWAYS load if:
  //
  //        1. `workingFilters` are introduced in `nextProps`
  //        2. price-change expectations (based on bag-fullness)
  //
  //      Otherwise, fallback to ONLY invoke loading if generated parameters
  //      are different
  //
  const isWorkingFiltersIntroduced = !prevProps.workingFilters && nextProps.workingFilters;
  // NW [EXPLANATION] 3/2/20: completing the onboarding survey will cause updated recommendations to be returned to new subscribers on homepage
  const hasOnboardingStatusChanged =
    !(prevProps.membershipState?.onboarded ?? false) && (nextProps.membershipState?.onboarded ?? false);
  const nextParams = generateParams(nextProps);

  if (
    isWorkingFiltersIntroduced ||
    hasOnboardingStatusChanged ||
    shouldRefreshUpgradePricing(prevProps, nextProps) ||
    !_.isEqual(generateParams(prevProps), nextParams)
  ) {
    dispatchFetchPersonalizedCarousels(dispatch, nextParams);
  }
}

export function condenseFetchPersonalizedCarousels(dispatch, props) {
  if (props.flagsAndExperiments?.[flagsAndExperimentNames.SACT_DISCO_ENDPOINTS_CAROUSELS]) {
    dispatch(fetchMembershipHomepageCarousels());
  } else {
    dispatchFetchPersonalizedCarousels(dispatch, generateParams(props));
  }
}

export function dispatchFetchCarouselById(dispatch = () => false, carouselId, params = {}) {
  dispatch(fetchCarouselById(carouselId, params));
}

export default {
  condenseFetchPersonalizedCarousels,
  shouldFetchPersonalizedCarousels,
  updateCarouselStyleAvailability,
  dispatchFetchCarouselById,
};
