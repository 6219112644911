import { stringify } from "qs";

// this function has two features:
// 1) timeout (defaulted to 8 seconds... let's hope that never kicks in!)
// 2) data - native fetch does not support a params/data option for GET params,
//    it expects that you will add those to the URL yourself. the Godmother.ts
//    package does not support explicitly adding URL params to some requests (it
//    should), so to follow the $.ajax pattern that we are used to, we use: data
export async function fetchWithTimeout(resourceUrl, options = {}) {
  let url = resourceUrl;
  const { data, timeout = 8000, ...restOptions } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  if (Object.keys(data || {}).length) {
    const delimiter = url.includes("?") ? "&" : "?";
    const query = stringify(data, { arrayFormat: "brackets", encodeValuesOnly: false });

    url += delimiter + query;
  }

  // Godmother.ts will implement error handling and authentication concerns [PSEC-2891]
  // eslint-disable-next-line no-restricted-globals
  const response = await fetch(url, {
    ...restOptions,
    signal: controller.signal,
  });

  clearTimeout(id);

  return response;
}
