import { createSlice } from "@reduxjs/toolkit";

// Action in this context is the API url to proxy to ruby layer when submitting actions.
// Todo: Rename to something less general than "action"
const initialState = {
  action: null,
};

const actionSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    setAction: (state, action) => {
      state.action = action.payload;
    },
  },
});

export const { setAction } = actionSlice.actions;
export const types = {
  SET_ACTION: `${actionSlice.name}/setAction`,
};

export default {
  types: types,
  fn: actionSlice.reducer,
};
