import ActionTypes from "actions/action-types";
import getFlagsFromObj from "helpers/get-flags-from-obj";

const types = [
  ActionTypes.SET_BROWSER_DATA,
  ActionTypes.SET_UX_USER_DATA,
  ActionTypes.SET_FEATURE_FLAGS_DATA,
  ActionTypes.SET_PERSISTED_FILTERS,
  ActionTypes.UPDATE_MEMBERSHIP_LENS,
  ActionTypes.UPDATE_USER_DATA,
  ActionTypes.GOOGLE_MAPS_LOADED,
];

const fn = function (state, action) {
  switch (action.type) {
    case ActionTypes.SET_BROWSER_DATA: {
      return {
        ...state,
        browser: {
          ...state.browser,
          ...action.payload,
        },
      };
    }

    // userData is a combination of many thing, which I hope to one day unpack.
    // until then, and to keep my PRs small, we provide utility for updating it
    case ActionTypes.UPDATE_USER_DATA: {
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
        },
      };
    }

    case ActionTypes.SET_UX_USER_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ActionTypes.SET_FEATURE_FLAGS_DATA: {
      // This reducer will only be hit client-side so we know that these changes are setByClient.
      // For debugging purposes we also want to preserve which flags were previously set by the server.
      const { setByServer } = getFlagsFromObj(state).enabledFlags;
      return {
        ...state,
        enabledFlags: {
          flags: action.payload, // source of truth - use this for component state / logic
          setByServer, // for debugging only
          setByClient: action.payload, // for debugging only
        },
      };
    }

    case ActionTypes.SET_PERSISTED_FILTERS: {
      const workingFilters = {
        ...state.workingFilters,
        ...action.payload,
      };
      return {
        ...state,
        workingFilters,
      };
    }

    case ActionTypes.GOOGLE_MAPS_LOADED: {
      return {
        ...state,
        config: { googleMapsLoaded: true },
      };
    }

    case ActionTypes.UPDATE_MEMBERSHIP_LENS: {
      const userData = {
        ...state.userData,
        lens: action.payload,
        // TODO: In adding `lens` above, we can remove the `isXLens` values below;
        // there is no need to store the same value as 3 separate booleans.
        isUnlimitedLens: action.payload === "unlimited",
        isRTRUpdateLens: action.payload === "rtrupdate",
        isClassicLens: action.payload === "classic",
      };

      return {
        ...state,
        userData,
      };
    }

    default: {
      return state;
    }
  }
};

export default {
  types,
  fn,
};
