// the Vary header tells our CDN to cache different versions of the same page
// based on the value of the header.
// See more: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Vary

import { COOKIES } from "rtr-constants";
import { withNoCaching } from "./cache-control-helpers";

const RTR_SEGMENTS_HEADER = "X-RTR-Segments";
export const RTR_EXPERIMENTS_HEADER_PREPEND = "X-RTR-Exp-";

/**
 * Add a new value to the Vary header on the response object
 * @param {Response} res HTTP response object https://nodejs.org/api/http.html#http_class_http_serverresponse
 * @param {string} value the value to append to the Vary header
 */
export function appendToVaryHeader(res, value) {
  const varyHeader = res.getHeader("Vary") || "";

  if (!varyHeader.includes(value)) {
    const separator = varyHeader.length > 0 ? ", " : "";
    res.setHeader("Vary", `${varyHeader}${separator}${value}`);
  }
}

/**
 * Add an `X-RTR-Segments` header to the response with `seg` cookie value, and add that header to the `Vary` header so that the response can be cached with variance by segment
 * @param {string} req HTTP request object https://nodejs.org/api/http.html#class-httpclientrequest
 * @param {string} res HTTP response object https://nodejs.org/api/http.html#http_class_http_serverresponse
 */
export function varyBySegments(req, res) {
  // NW [EXPLANATION] 9/28/23: this could use { getRequestSessionSegments } from segments-helpers but this file is imported client-side (see _app.jsx getInitialProps) and segments-helpers is server-side-only
  const segmentsCookieValue = req.cookies[COOKIES.SEG] || "";
  res.setHeader(RTR_SEGMENTS_HEADER, segmentsCookieValue);
  appendToVaryHeader(res, RTR_SEGMENTS_HEADER);
}

/**
 * Set the evaluated experiment value as a header on the HTTP response. Augment the Vary header to indicate that this response varies by the given experiment.
 * @param {string} experimentName Name of the experiment evaluated server-side
 * @param {string} experimentValue Evaluated value of the experiment for this response
 * @param {Response} response Response object
 */
export function setExperimentHeader(experimentName, experimentValue, response) {
  if (!response || typeof response.setHeader !== "function") {
    throw new Error("Response object must be provided when evaluating an experiment server-side.");
  }
  const headerName = `${RTR_EXPERIMENTS_HEADER_PREPEND}${experimentName}`;
  response.setHeader(headerName, experimentValue);
  appendToVaryHeader(response, headerName);
  withNoCaching(response);
}

/**
 * Get the experiment allocations specified in the request by X-RTR-Exp headers
 * @param {string} req HTTP request object https://nodejs.org/api/http.html#class-httpclientrequest
 * @returns {object} the experiment allocation values that the request specified
 */
export function getExperimentAllocationsFromRequestHeaders(req) {
  const headerNames = Object.keys(req.headers);
  const experimentAllocations = {};
  headerNames.forEach(name => {
    const lowerCaseHeaderName = name.toLowerCase();
    if (lowerCaseHeaderName.startsWith(RTR_EXPERIMENTS_HEADER_PREPEND.toLowerCase())) {
      const experimentName = lowerCaseHeaderName.substring(RTR_EXPERIMENTS_HEADER_PREPEND.length);
      const value = req.headers[name];
      experimentAllocations[experimentName] = value;
    }
  });
  return experimentAllocations;
}

/**
 * Check whether an HTTP response contains experiment-specific headers
 * @param {Response} res The HTTP response object
 * @returns {boolean} Whether the response object contains experiment-specific headers
 */
export function hasExperimentHeader(headers) {
  return getExperimentHeaders(headers).length > 0;
}

/**
 * Get any experiment headers provided in the headers object
 * @param {Headers} headers Headers provided to a request or response
 * @returns {object[]} An array of Experiment-related headers from the provided Headers object
 */
export function getExperimentHeaders(headers) {
  const experimentHeaders = [];
  headers.forEach((value, key) => {
    if (key.toLowerCase().startsWith(RTR_EXPERIMENTS_HEADER_PREPEND.toLowerCase())) {
      experimentHeaders.push({ key, value });
    }
  });

  return experimentHeaders;
}
