import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import Price from "components/source/grid/price";
import { showDecimal } from "helpers/price-helper";
import PropTypes from "prop-types";

export default function UpsellGridPrice({ price, bonusPrice }) {
  const originalProduct = useSelector(state => state.product);
  const originalProductAdjustedPrice = originalProduct?.price?.adjusted;
  const adjustedPrice = price?.adjusted;

  if (!adjustedPrice && !originalProductAdjustedPrice) return null;

  // Glossary -
  // adjustedPrice - is the displayed price of a product being displayed on grid
  // bonusPrice - is the 4-day or 8-day rental bonus price for 2nd clothing item
  // originalProductAdjustedPrice - is the display price of the product being added
  if (adjustedPrice < bonusPrice) {
    return (
      <span className={styles.label__new_price}>
        <Price
          amount={adjustedPrice}
          currencyCode={"USD"}
          currencyCodeClassName="hidden @[275px]/label:inline"
          showDecimal={showDecimal(adjustedPrice)}
        />
      </span>
    );
  } else if (adjustedPrice > originalProductAdjustedPrice) {
    return (
      <div className={`${styles.flex} ${styles["label__price_wrapper_space-between"]}`}>
        <span className={styles.label__price}>
          <Price
            amount={adjustedPrice}
            currencyCode={"USD"}
            currencyCodeClassName="hidden @[275px]/label:inline"
            showDecimal={showDecimal(adjustedPrice)}
          />
        </span>
        <span className={styles.label__new_price}>Discount in bag</span>
      </div>
    );
  } else {
    return (
      <div className={styles.flex}>
        <span className={styles.label__price_crossed}>
          <Price
            amount={adjustedPrice}
            currencyCode={"USD"}
            currencyCodeClassName="hidden @[275px]/label:inline"
            showDecimal={showDecimal(adjustedPrice)}
          />
        </span>
        <span className={styles.label__new_price}>
          <Price
            amount={bonusPrice}
            currencyCode={"USD"}
            currencyCodeClassName="hidden @[275px]/label:inline"
            showDecimal={showDecimal(bonusPrice)}
          />
        </span>
      </div>
    );
  }
}

UpsellGridPrice.propTypes = {
  price: PropTypes.object.isRequired,
  bonusPrice: PropTypes.number.isRequired,
};
