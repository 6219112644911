import { DiscoSearchServiceClient } from "clients/DiscoSearchServiceClient";
import RawClient from "clients/RawClient";

export async function executeTypeaheadSearch(query, typeaheadEnabled, isStorefrontNext, discoHost) {
  if (query.length >= 3 && typeaheadEnabled && isStorefrontNext) {
    const typeaheadResponse = await DiscoSearchServiceClient.getInstance().typeaheadSearch(query);
    return typeaheadResponse?.items || [];
  } else if (query.length >= 3 && typeaheadEnabled) {
    const results = await RawClient.get(`${discoHost}/api/v1/typeahead?searchPrompt=${query}`);
    return results.items || [];
  }
}

export default {
  executeTypeaheadSearch,
};
