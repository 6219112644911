import ActionTypes from "actions/action-types";

const updateOneTimeExperiences = function (state, action) {
  const oneTimeExperiences = {
    ...state.oneTimeExperiences,
  };

  if (action.type === ActionTypes.ONE_TIME_EXPERIENCE_DISMISSED) {
    const { experienceName, dismissed } = action.payload;

    if (experienceName) {
      return {
        ...oneTimeExperiences,
        [experienceName]: dismissed,
      };
    }
  }

  return oneTimeExperiences;
};

export const types = [ActionTypes.ONE_TIME_EXPERIENCE_DISMISSED];

export const fn = function (state, action) {
  return {
    ...state,
    oneTimeExperiences: updateOneTimeExperiences(state, action),
  };
};

export default {
  types: types,
  fn: fn,
};
