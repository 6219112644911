import PropTypes from "prop-types";

export const smartTopRightPropType = PropTypes.shape({
  userMenu: PropTypes.shape({
    /**
     * One of title or imageUrl is required
     */
    title: PropTypes.string,
    subTitle: PropTypes.string,
    imageUrl: PropTypes.string,
    retinaImage: PropTypes.string,
    /**
     * URL
     */
    link: PropTypes.string,
    /**
     * Width override, in pixels
     */
    width: PropTypes.number,
    /**
     * Any valid hex color
     */
    borderColor: PropTypes.string,
    /**
     * Any valid hex color
     */
    backgroundColor: PropTypes.string,
    textColor: PropTypes.oneOf(["light", "dark", "gold"]),
    fontFamily: PropTypes.oneOf(["proxima-nova", "genath"]),
    type: PropTypes.oneOf(["button"]),
    color: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
});
