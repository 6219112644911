import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export class SwipeableCarouselItem extends React.Component {
  static propTypes = {
    tabIndex: PropTypes.number,
    onKeyPress: PropTypes.func,
    className: PropTypes.string,
    onClick: PropTypes.func,
    key: PropTypes.string,
    ref: PropTypes.string,
    children: null,
    isMobile: PropTypes.bool,
    root: null,
    logMethod: PropTypes.oneOfType([PropTypes.any, PropTypes.func]),
    index: PropTypes.number,
  };

  static defaultProps = {
    tabIndex: 0,
    onKeyPress: () => {},
    className: null,
    onClick: () => {},
    key: null,
    ref: null,
    children: null,
    isMobile: false,
    root: null,
    logMethod: null,
    index: null,
  };

  constructor(props, context) {
    super(props, context);

    this.carouselItemRef = React.createRef();
    this.observer = React.createRef();
  }
  componentWillUnmount() {
    if (this.observer.current && this.props.root) {
      this.observer.current.unobserve(this.carouselItemRef.current);
    }
  }

  componentDidMount() {
    const { logMethod, root, index } = this.props;

    if (root?.current) {
      this.observer = new IntersectionObserver(
        function (entries) {
          entries.forEach(entry => {
            if (entry.isIntersecting && entry.isVisible) {
              if (typeof logMethod === "function") {
                logMethod(index, 1);
              }
            }
          });
        },
        {
          root: root.current,
          /* this is how you tell an obvserver to intersect horizontally on scroll as opposed to vertically. */
          rootMargin: "4000px 0% 4000px 0%",
          threshold: 0,
          /* required options*/
          trackVisibility: true,
          delay: 100,
        }
      );

      if (typeof this.carouselItemRef?.current === Element) {
        this.observer?.observe(this.carouselItemRef.current);
      }
    }
  }

  render() {
    return (
      <div
        data-test-id="swipeable-carousel-item"
        role={"button"}
        tabIndex={this.props.tabIndex}
        onKeyPress={this.props.onKeyPress}
        className={this.props.className}
        onClick={this.props.onClick}
        key={this.props.key}
        ref={this.props.isMobile ? this.carouselItemRef : this.props.ref}>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.browser?.isMobileViewport,
});

export default connect(mapStateToProps)(SwipeableCarouselItem);
