import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import NavContainer from "./nav-container";
import BranchIOIntegration from "./branch-io-integration";
import EnvironmentBanner from "./environment-banner";
import MasqueradeBanner from "./masquerade-banner";
import EmergencyBanner from "./emergency-banner";
import ConnectedSkinnyBanner from "./connected-skinny-banner";
import PropTypes from "prop-types";
import classNames from "classnames";
import ActionLogger from "action-logger";
import { userDataPropType } from "../hoc/with-user-data";
import {
  flagsAndExperimentNames,
  flagsAndExperimentsPropType,
  withFlagsAndExperiments,
} from "components/source/hoc/with-flags-and-experiments";
import { withQueryParams } from "../hoc/with-query-params";
import { membershipFocusedFlows, aspireInfluencerTransactionData } from "rtr-constants";
import { configurePDPFlow } from "./pdp-option-display-helper";
import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";
import { readPromoValue } from "./url-param-reader";
import { withCookies, Cookies } from "react-cookie";
import HeapHelpers from "helpers/heap-helpers";
import { waitToBeDefined } from "helpers/utility-helpers.js";
import StickyReturnToCheckoutCTA from "./sticky-return-to-checkout-cta/sticky-return-to-checkout-cta";
import ShoppableImagesModal from "../shared/shoppable-images-modal/shoppable-images-modal";

export class Header extends React.Component {
  static propTypes = {
    appContextEnvironment: PropTypes.string,
    ensureSneakyHeaderVisible: PropTypes.bool,
    fetchExperimentTreatment: PropTypes.func,
    headerCollapsedMenuAttribute: PropTypes.oneOf(["account", "full"]),
    isNavSearchExpanded: PropTypes.bool,
    pageName: PropTypes.string,
    pageType: PropTypes.string,
    queryParams: PropTypes.object,
    userData: userDataPropType,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    flagsAndExperiments: flagsAndExperimentsPropType,
    isMobileViewport: PropTypes.bool.isRequired,
  };

  state = {
    hidden: false,
  };

  logHomeClick = () => {
    ActionLogger.inferAction({
      objectType: "top_nav",
      action: "click_nav_icon",
      icon_name: "home",
    });
  };

  hasSneakyBehavior() {
    // SAB [EXPLANATION] 3/2025: desktop navs are sticky, mobile navs are sneaky
    return this.props.isMobileViewport;
  }

  componentDidMount() {
    // NW [EXPLANATION] 10/5/21: add window scroll handler for sneaky header - it is hidden during scroll, but appears after scroll up
    if (this.hasSneakyBehavior()) {
      window?.addEventListener("scroll", this.sneakyHeaderScrollHandler);
    }

    const { queryParams, fetchExperimentTreatment, cookies } = this.props;

    fetchExperimentTreatment();

    //check for url parameters that can determine PDP user experience
    configurePDPFlow(queryParams, 600);

    this.configureAspireTransactionIdWhenAvailable(queryParams, cookies);

    this.handleAttentiveLibraryLoadDelay();
  }

  async handleAttentiveLibraryLoadDelay() {
    const { flagsAndExperiments } = this.props;

    // This allows attentive to control email popups on all pages
    if (flagsAndExperiments?.[flagsAndExperimentNames.MARTECH_ENABLE_EMAIL_POPUP]) {
      const attentiveTrigger = await waitToBeDefined(() => window?.__attentive?.trigger, 2000);
      attentiveTrigger();
      HeapHelpers.fireHeapEvent("modal_rendered", { modal_name: "attentive email_capture/sms" });
    }
  }

  configureAspireTransactionIdWhenAvailable(queryParams, cookies) {
    //check for url paramter to indicate user arrives from influencers promo link.
    const aspireTransactionId = readPromoValue(
      aspireInfluencerTransactionData.TRANSACTION_ID,
      aspireInfluencerTransactionData.ASPIRE_TRANSACTION_ID,
      queryParams
    );

    if (aspireTransactionId) {
      cookies.set(aspireInfluencerTransactionData.ASPIRE_TRANSACTION_ID, aspireTransactionId, { path: "/" });
    }
  }

  componentWillUnmount() {
    // NW [EXPLANATION] 10/5/21: remove window scroll handler for sneaky header
    if (this.hasSneakyBehavior()) {
      window?.removeEventListener("scroll", this.sneakyHeaderScrollHandler);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.headerCollapsedMenuAttribute && !prevProps.headerCollapsedMenuAttribute) {
      document.body.classList.add("no-scroll");
    } else if (!this.props.headerCollapsedMenuAttribute && prevProps.headerCollapsedMenuAttribute) {
      document.body.classList.remove("no-scroll");
    }
  }

  // NW [EXPLANATION] 10/14/21: the height of the header must be calculated to account for banners that are shown/hidden below it.
  // this allows page content to appear beneath the banners, instead of being covered up by them.
  getOffsetHeight() {
    if (!process.env.IS_SSR) {
      const { bottom, top } = this.$headerWrapper?.getBoundingClientRect() || {};
      return bottom - top;
    }
  }

  /*
  Thresholds for scrolling distance in either direction have been added to reduce the sensitivity of toggling header
  visibility, to ensure that the header visibility will only change if the user has scrolled past a given distance.
  The header will disappear when the user scrolls down 150 pixels, and will reappear when the user scrolls
  up 300 pixels. However, if the user starts at the top of the page, then scrolls down 150 pixels to make the header disappear,
  then scrolls up the same distance to the top of the page, then the header will reappear, as it always will do when the scroll
  position reaches 0.
  There is also a timer in place for 500 milliseconds to reset the scrolling state variables, which will get cleared if the
  user continues to scroll.
   */
  sneakyHeaderScrollHandler = () => {
    // ** Math.max: mobile browsers have a cute UI that allows you to scroll
    // into the negative, and then smoothly snap back once you've taken
    // your finger off the screen. this would result in a "scroll down"
    // hiding the navigation. Capping the scroll position to 0 will make
    // sure that doesn't happen
    const scrollPosition = Math.max(0, window?.scrollY);
    const scrollWatcher = this.scrollWatcher || -1;
    const scrollLastPosition = this.scrollLastPosition || 0;
    const scrollDirection = scrollPosition - scrollLastPosition > 0 ? 1 : -1;
    if (
      (!this.scrollStartPosition && this.scrollStartPosition !== 0) ||
      (this.scrollLastDirection && scrollDirection !== this.scrollLastDirection)
    ) {
      this.scrollStartPosition = scrollPosition;
    }
    const scrollDistance = scrollPosition - this.scrollStartPosition;

    this.scrollLastPosition = scrollPosition;
    this.scrollLastDirection = scrollDirection;

    // scrolled to the top
    if (scrollPosition === 0) {
      this.setState({ hidden: false });
    }

    // distance in pixels
    if (scrollDistance > 150 || scrollDistance < -300) {
      this.setState({ hidden: scrollDirection === 1 });
    }

    if (scrollWatcher !== -1) {
      clearTimeout(scrollWatcher);
    }

    // variables tracking the distance scrolled are cleared
    // when the user stops scrolling
    this.scrollWatcher = window.setTimeout(
      () => ["scrollStartPosition", "scrollLastPosition", "scrollLastDirection"].forEach(key => delete this[key]),
      500
    );
  };

  render() {
    const sneakyHeaderClass = classNames("light-background", {
      ["ensure-visible"]: this.props.ensureSneakyHeaderVisible,
      hidden: this.state.hidden,
      ["search-expanded"]: this.props.isNavSearchExpanded,
    });

    const heightOffset = {
      height: this.getOffsetHeight() || 0,
    };

    return (
      <div id="reb-header" data-collapsed-menu={this.props.headerCollapsedMenuAttribute} style={heightOffset}>
        <StickyReturnToCheckoutCTA />
        <ShoppableImagesModal />
        <div id="reb-header-unshaded" className={sneakyHeaderClass} ref={el => (this.$headerWrapper = el)}>
          <BranchIOIntegration
            pageName={this.props.pageName}
            pageType={this.props.pageType}
            userId={this.props.userData?.userProfile?.id}
          />
          <EnvironmentBanner appContextEnvironment={this.props.appContextEnvironment} />
          <MasqueradeBanner />
          <EmergencyBanner />
          <div id="reb-header-wrapper">
            <div id="nav-logo">
              <a className="nav-logo-inner" href="/" onClick={this.logHomeClick}>
                RTR
              </a>
            </div>
            <NavContainer />
          </div>
          <ConnectedSkinnyBanner />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const headerCollapsedMenuAttribute =
    (state.isMobileNavExpanded && "full") || (state.isMobileAccountMenuExpanded && "account");

  return {
    appContextEnvironment: state.appContextEnvironment,
    ensureSneakyHeaderVisible: state.ensureSneakyHeaderVisible,
    headerCollapsedMenuAttribute: headerCollapsedMenuAttribute || null,
    isMobileViewport: state.browser?.isMobileViewport,
    isNavSearchExpanded: state.isNavSearchExpanded,
    pageName: state.pageName,
    userData: state.userData,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchExperimentTreatment: () => {
      dispatch(FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.ENABLE_HOME_PICKUP_NEW_TAG));
    },
  };
}

export default compose(
  withFlagsAndExperiments(flagsAndExperimentNames.MARTECH_ENABLE_EMAIL_POPUP),
  connect(mapStateToProps, mapDispatchToProps),
  withQueryParams(membershipFocusedFlows.URL_PARAMETER_MEMBERSHIP_FLOW, aspireInfluencerTransactionData.TRANSACTION_ID),
  withCookies
)(Header);
