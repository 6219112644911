//Blocked by MA-3010, ticketed for followup on CE-2207
//eslint-disable-next-line no-restricted-imports
import $ from "clients/RawClient";
import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";
import ActionLogger from "action-logger";
import MembershipHelpers from "helpers/membership-helpers";
import { isCanceled } from "helpers/membership-plan-helpers";
import filterHelpers from "helpers/filter-helpers";
import {
  clientSideErrorMessages,
  CEBagErrors,
  CECheckout,
  EVENTS_CUSTOM_CASTLE,
  KIFAHTriggerTypes,
} from "rtr-constants";
import swapCounterActions from "actions/swap-counter-actions";
import CEBagActions from "actions/ce-bag-actions";
import { setKifahAvailableItems, setKifahTrigger } from "actions/kifah-actions";
import SMBagActions from "actions/sm-bag-actions";
import { updateOneTimeModal } from "actions/one-time-modals-actions";
import ReviewNudgeModal from "components/source/reviews/ReviewNudgeModal.jsx";
import HomePickupActions from "actions/home-pickup-actions";
import membershipBagActions from "actions/membership-bag-actions";
import * as membershipStateActions from "actions/membership-state-actions";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";
import { loadPaymentProfiles } from "actions/payment-method-actions";
import { fireCEHeapEvent } from "helpers/heap-ce-helpers.js";
import { addSnackBar } from "actions/snack-bar-actions";
import GTMHelper from "helpers/gtm";
import { bagTabs, switchBagTab } from "actions/bag-actions";
import * as castleHelper from "helpers/castle-helper";

const errorFromResponse = res => {
  let error;

  if (!res) {
    error = CECheckout.errors.generic;
  } else if (res?.jqXHR?.status === 424) {
    // a payment failed error may return HTTP 424, check for that first
    error = CECheckout.errors["payment-failed"];
  } else if (res?.jqXHR?.responseJSON?.errors?.[0]?.code) {
    error = CECheckout.errorCodeToCopy(res.jqXHR.responseJSON.errors[0].code);
  } else {
    error = CECheckout.errors.orderSubmitGeneric;
  }

  return error;
};

const actions = {
  checkoutLoading: createAction(ActionTypes.CE_CHECKOUT_LOADING),
  checkoutLoadSuccess: createAction(ActionTypes.CE_CHECKOUT_SUCCESS),
  checkoutError: createAction(ActionTypes.CE_CHECKOUT_ERROR),
  checkoutSubmitting: createAction(ActionTypes.CE_CHECKOUT_SUBMITTING),
  promoLoading: createAction(ActionTypes.CE_CHECKOUT_PROMO_LOADING),
  promoError: createAction(ActionTypes.CE_CHECKOUT_PROMO_ERROR),

  getOrCreate() {
    return function (dispatch) {
      dispatch(actions.checkoutError(null));
      dispatch(actions.checkoutLoading(true));

      return $.get("/checkout").then(
        res => {
          dispatch(actions.checkoutLoading(false));
          // if the checkout includes a passive promo code error, set it to redux
          if (res.passivePromoError) {
            dispatch(actions.promoError(res.passivePromoError));
          }

          dispatch(actions.checkoutLoadSuccess(res));

          // statuses will soon be kebab case, so check for both cases for now. when CE-1455 is done, remove the toUpperCase
          if (res.checkoutStatus && res.checkoutStatus.toLowerCase() !== CECheckout.CheckoutStatuses.VALIDATED) {
            dispatch(actions.checkoutError(CECheckout.errorCodeToCopy(res.checkoutStatus)));
          }
        },
        () => {
          dispatch(actions.checkoutLoading(false));
          dispatch(actions.checkoutError(CECheckout.errors.generic));
        }
      );
    };
  },

  clear() {
    return function (dispatch) {
      dispatch(actions.checkoutLoadSuccess(null));
    };
  },

  placeOrder(checkout, homePickupDetails = null) {
    return function (dispatch) {
      dispatch(actions.checkoutSubmitting(true));

      return createAjaxAuthRetry(
        {
          url: "/checkout/submit",
          type: "POST",
          data: { id: checkout.id },
        },
        () => {
          dispatch(actions.checkoutSubmitting(false));

          /**
           * on 401, refetch checkout. do NOT place the order
           * also refresh bag, membership state, and payment methods in case the relogin
           * caused users to change (unlikely, but let's be safe)
           */
          dispatch(CEBagActions.get()); // checkout will be inherently refreshed if bag is checkout ready
          dispatch(membershipStateActions.fetchMembershipState());
          dispatch(loadPaymentProfiles());
        }
      )
        .then(res => {
          dispatch(actions.afterPlacedMembershipOrder(res.id, homePickupDetails));
          return Promise.resolve(res.data);
        })
        .catch(res => {
          dispatch(actions.checkoutSubmitting(false));

          const error = errorFromResponse(res);

          dispatch(actions.checkoutError(error));
          dispatch(CEBagActions.bagLoading(false));

          ActionLogger.logAction({
            object_type: "rtr_update",
            action: "confirm_failure",
            reason: error,
          });
        });
    };
  },

  afterPlacedMembershipOrder(orderId, homePickupDetails = null) {
    return function (dispatch, getState) {
      dispatch(actions.checkoutSubmitting(false));
      const state = getState();
      const { userData, workingFilters } = state;

      const membershipState = MembershipHelpers.getMembershipState(state);

      ActionLogger.logAction({
        object_type: "shopping_bag",
        action: "confirm_shipment",
        order_id: orderId,
        membership: MembershipHelpers.getMembershipTypeAnalytics(userData.memberships, membershipState),
        latest_view: filterHelpers.pixelFilterParam(workingFilters, userData),
      });

      const isFirstShipmentOfFirstTerm = MembershipHelpers.isFirstShipmentOfFirstTerm(membershipState);
      if (isFirstShipmentOfFirstTerm) {
        dispatch(swapCounterActions.swapCounterTooltipTriggered(true));
      }

      if (homePickupDetails && homePickupDetails?.returningItems?.length > 0 && homePickupDetails?.rentalBeginDate) {
        dispatch(
          HomePickupActions.checkHomePickup(
            membershipState,
            homePickupDetails.returningItems,
            homePickupDetails.rentalBeginDate,
            homePickupDetails.actionLogger
          )
        );
      } else {
        dispatch(membershipBagActions.showPostCheckoutModals(membershipState));
      }

      dispatch(updateOneTimeModal(ReviewNudgeModal.oneTimeModalId, false));
      dispatch(SMBagActions.fetchSMBag(true));
      dispatch(membershipStateActions.fetchMembershipState());
    };
  },

  placeOrderKifah(checkoutId) {
    return function (dispatch, getState) {
      dispatch(actions.checkoutSubmitting(true));
      dispatch(fireCEHeapEvent("placingKifahOrder", { checkoutId }));

      return createAjaxAuthRetry(
        {
          url: "/checkout/submit",
          type: "POST",
          data: { id: checkoutId },
        },
        () => {
          dispatch(actions.checkoutSubmitting(false));

          // on 401, refetch the kifah checkout. do NOT place the order.
          dispatch(actions.getKifah(checkoutId));
          dispatch(membershipStateActions.fetchMembershipState());

          dispatch(fireCEHeapEvent("placeKifahOrderFailure", { checkoutId, error: "401, throwing auth modal" }));
        }
      )
        .then(res => {
          /**
           * on successful KIFAH checkout, close the bag and refresh the membership state
           * so the user can see their newly opened slot.
           *
           * if the user does not have an active membership, or if the user is out of shipments
           * for the month, display that the purchase is confirmed rather than a slot is opened.
           */
          const { ceCheckoutKifah, membershipState, userData, kifahAvailableItems, kifahTrigger } = getState();
          const toastCopy =
            isCanceled(membershipState) || MembershipHelpers.isOutOfShipments(membershipState)
              ? "Purchase confirmed"
              : "Spot opened";
          dispatch(addSnackBar({ copyPrimary: toastCopy, duration: 5000, bagContext: true }));

          dispatch(membershipStateActions.fetchMembershipState());

          // check for kifah available items. If there are items left, reopen the bag tab
          if (kifahAvailableItems?.length > 0 && ceCheckoutKifah?.bagItems?.length > 0 && kifahTrigger) {
            const purchasedBookingIds = ceCheckoutKifah.bagItems.map(item => item.originalBookingId);
            const kifahItems = kifahAvailableItems.reduce((prev, curr) => {
              const item = curr;
              if (purchasedBookingIds.includes(item.bookingId)) {
                item.wasPurchased = true;
              }
              return [...prev, item];
            }, []);
            dispatch(setKifahTrigger(KIFAHTriggerTypes.THIRTY_DAYS));
            dispatch(setKifahAvailableItems(kifahItems));
          } else {
            dispatch(setKifahTrigger(null));
            dispatch(switchBagTab(bagTabs.MEMBERSHIP_TAB));
          }
          dispatch(fireCEHeapEvent("placeKifahOrderSuccess", { checkoutId }));

          GTMHelper.purchaseEventFromCheckout(ceCheckoutKifah, res.data?.id, userData?.userProfile);
        })
        .catch(res => {
          const error = errorFromResponse(res);

          dispatch(actions.checkoutError(error));

          ActionLogger.logAction({
            object_type: "kifah",
            action: "confirm_failure",
            reason: error,
          });

          dispatch(fireCEHeapEvent("placeKifahOrderFailure", { checkoutId, error }));
        })
        .finally(() => {
          dispatch(actions.checkoutSubmitting(false));
          dispatch(CEBagActions.bagLoading(false));
        });
    };
  },

  /**
   * at this time, this is only needed in the event of an auth error happening on placeOrderKifah().
   * it'll run on successful auth and will refresh the current KIFAH checkout.
   * if there's still an error at this point, just display the error.
   */
  getKifah(checkoutId) {
    return function (dispatch) {
      dispatch(actions.checkoutError(null));
      dispatch(actions.checkoutLoading(true));
      dispatch(CEBagActions.bagLoading(true));

      return $.get(`/checkout/${checkoutId}`).then(
        res => {
          dispatch(CEBagActions.bagLoading(false));
          dispatch(actions.checkoutLoading(false));
          dispatch(CEBagActions.checkoutKifahSuccess(res));

          if (res.checkoutStatus && res.checkoutStatus.toLowerCase() !== CECheckout.CheckoutStatuses.VALIDATED) {
            dispatch(actions.checkoutError(CECheckout.errorCodeToCopy(res.checkoutStatus)));
          }

          if (res.passivePromoError) {
            dispatch(actions.promoError(res.passivePromoError));
          }
        },
        res => {
          dispatch(CEBagActions.bagLoading(false));
          dispatch(actions.checkoutLoading(false));
          dispatch(actions.checkoutError(errorFromResponse(res)));
        }
      );
    };
  },

  applyPromoCode(checkoutId, promoCode, isKifah = false) {
    return function (dispatch) {
      const heapData = { checkoutId, promoCode, isKifah };
      dispatch(actions.promoLoading(true));

      return $.post("/checkout/promocode", { checkoutId, promoCode }).then(
        res => {
          dispatch(actions.promoLoading(false));
          dispatch(isKifah ? CEBagActions.checkoutKifahSuccess(res) : actions.checkoutLoadSuccess(res));

          // there may be a promo code error even on 200 response, so set it if so
          if (res.passivePromoError) {
            dispatch(actions.promoError(res.passivePromoError));
            heapData.hasPassiveError = res.passivePromoError;
          }

          dispatch(fireCEHeapEvent("promoCodeAdded", heapData));
          castleHelper.logCustomEvent({
            name: EVENTS_CUSTOM_CASTLE.APPLY_PROMO_CODE,
            properties: {
              checkout_id: checkoutId,
            },
          });
        },
        xhr => {
          dispatch(actions.promoLoading(false));
          const error = xhr.status === 400 ? xhr.responseText : CEBagErrors.PROMO_CODE_APPLY_GENERIC_ERROR(promoCode);
          dispatch(actions.promoError(error));
          heapData.error = error;
          dispatch(fireCEHeapEvent("promoCodeAddError", heapData));
        }
      );
    };
  },

  removePromoCode(checkoutId, isKifah = false) {
    return function (dispatch) {
      const heapData = { checkoutId, isKifah };
      dispatch(actions.promoLoading(true));

      return $.ajax({ url: "/checkout/promocode", type: "DELETE", data: { checkoutId } }).then(
        res => {
          dispatch(isKifah ? CEBagActions.checkoutKifahSuccess(res) : actions.checkoutLoadSuccess(res));
          dispatch(actions.clearPromoErrors());
          dispatch(fireCEHeapEvent("promoCodeRemoved", heapData));
          castleHelper.logCustomEvent({
            name: EVENTS_CUSTOM_CASTLE.REMOVE_PROMO_CODE,
            properties: {
              checkout_id: checkoutId,
            },
          });
        },
        () => {
          dispatch(actions.promoLoading(false));
          dispatch(actions.promoError(CEBagErrors.PROMO_CODE_REMOVAL_GENERIC_ERROR));
          heapData.error = CEBagErrors.PROMO_CODE_REMOVAL_GENERIC_ERROR;
          dispatch(fireCEHeapEvent("promoCodeRemovalError", heapData));
        }
      );
    };
  },

  clearPromoErrors() {
    return function (dispatch) {
      dispatch(actions.promoError(null));
    };
  },

  setPaymentMethod(checkoutId, paymentMethodId, isKifah = false) {
    return function (dispatch) {
      dispatch(actions.checkoutError(null));
      dispatch(actions.checkoutLoading(true));
      dispatch(CEBagActions.bagLoading(true));

      return createAjaxAuthRetry({
        url: "/checkout/paymentMethod",
        type: "PATCH",
        data: { checkoutId, paymentMethodId: paymentMethodId },
      })
        .then(res => {
          dispatch(isKifah ? CEBagActions.checkoutKifahSuccess(res.data) : actions.checkoutLoadSuccess(res.data));
          return res;
        })
        .catch(err => {
          dispatch(actions.checkoutError(clientSideErrorMessages.changeDefaultPaymentMethod.defaultError));
          throw err;
        })
        .finally(() => {
          dispatch(actions.checkoutLoading(false));
          dispatch(CEBagActions.bagLoading(false));
        });
    };
  },
};

export default actions;

export const {
  checkoutLoading,
  checkoutSubmitting,
  checkoutLoadSuccess,
  checkoutError,
  getOrCreate,
  clear,
  placeOrder,
  applyPromoCode,
  removePromoCode,
  clearPromoErrors,
  setPaymentMethod,
  getKifah,
  promoLoading,
  promoError,
  afterPlacedMembershipOrder,
} = actions;
