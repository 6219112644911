// Intentionally excluding these files from import into constants.js because they contain sensitive information that
// should not be exposed to client-side code.
/* eslint no-restricted-imports: ["error", { "paths": ["./constants/http-headers", "rtr-constants/http-headers"] }] */

import _ from "underscore";

// TODO migrate these files here (i.e., stop requiring these files)
import usStateAbbreviations from "./constants/us-state-abbreviations";
export { default as usStateAbbreviations } from "./constants/us-state-abbreviations";

import madlibsOptions from "./constants/madlibs-options";
export { default as madlibsOptions } from "./constants/madlibs-options";

import membershipActions from "./constants/membership-actions";
export { default as membershipActions } from "./constants/membership-actions";
import happinessSurveyValues from "./constants/happiness-survey-values";
export { default as happinessSurveyValues } from "./constants/happiness-survey-values";
import ctaText from "./constants/cta";
export { default as ctaText } from "./constants/cta";
import ctaPauseStates from "./constants/cta-pause-states";
export { default as ctaPauseStates } from "./constants/cta-pause-states";
import mediaQueries from "./constants/mediaQueries";
import { pageTypes, pageNames } from "rtr-constants/pageMetaData";
export { pageTypes, pageNames } from "rtr-constants/pageMetaData";
import EVENTS_CUSTOM_DOM from "rtr-constants/events-custom-dom";
export { default as EVENTS_CUSTOM_DOM } from "rtr-constants/events-custom-dom";
import { EVENTS_CUSTOM_CASTLE } from "rtr-constants/events-analytics";
export { EVENTS_CUSTOM_CASTLE } from "rtr-constants/events-analytics";
export { default as flagsAndExperimentNames } from "rtr-constants/flags-and-experiments-names";

import COOKIES from "./constants/cookies";
export { default as COOKIES } from "./constants/cookies";
import QUERY_PARAMS from "./constants/query-params";
export { default as QUERY_PARAMS } from "./constants/query-params";
import {
  URL_DOMAIN_ALLOW_LIST,
  URL_ORIGIN_ALLOW_LIST,
  URL_PROTOCOL_ALLOW_LIST,
  URL_PROTOCOLS,
} from "./constants/url-allow-list";
export {
  URL_DOMAIN_ALLOW_LIST,
  URL_ORIGIN_ALLOW_LIST,
  URL_PROTOCOL_ALLOW_LIST,
  URL_PROTOCOLS,
} from "./constants/url-allow-list";

// Instead of using reduce in 3 places, dry it up.
export const reduceListToObject = list => {
  return _.reduce(
    list,
    (memo, v) => {
      memo[v] = v;
      return memo;
    },
    {}
  );
};

// SFF = swap for fit
// QI = quality issue
export const membershipSlotTypes = reduceListToObject([
  "BASE",
  "BONUS",
  "SFF",
  "QI",
  "OPEN_SLOT",
  "UPGRADE_CTA_SLOT",
  "REPLACEMENT",
]);
export const membershipSlotSource = reduceListToObject(["SYSTEM"]);

export const membershipOpenSlotTypes = reduceListToObject([
  membershipSlotTypes.OPEN_SLOT,
  membershipSlotTypes.UPGRADE_CTA_SLOT,
]);

// Please see confluence documentation for explanation of all
// the membership item states:
// https://renttherunway.jira.com/wiki/spaces/MEM/pages/1082785930/MembershipItemStates
export const membershipItemStates = reduceListToObject([
  "AT_HOME",
  "AT_HOME_SFF",
  "AT_HOME_TTB",
  "HOME_PICKUP_SCHEDULED",
  "IN_BAG",
  "IN_TRANSIT_TO_RTR",
  "OVERDUE",
  "PREPARING",
  "PURCHASED",
  "RETURN_PROMISE",
  "RETURNED",
  "RETURNING",
  "SHIPPED",
]);

export const membershipTriggeredModalsCMSKeys = reduceListToObject([
  "delinquent",
  "futureCanceled",
  "futureCanceledCancelConfirmation",
  "futurePauseMembershipConfirmation",
  "futurePaused",
  "futurePausedCancelConfirmation",
  "futurePauseMembershipConfirmation",
  "futurePauseWithItemsConfirmation",
  "invalidBilling",
  "oneSwapFirstOrderConfirmation",
  "oops",
  "orderConfirmation",
  "orderConfirmationSwap",
  "pauseExtendedConfirmation",
  "pauseWithItemsExtendedConfirmation",
  "paused",
  "resumeIntentConfirmation",
  "purchaseConfirmed",
  "retry",
  "retryCardInvalid",
  "retryConfirmation",
  "returnBeginWithoutPick",
  "returnBeginWithPick",
  "returnConfirmed",
  "returnPurchaseConfirmed",
  "selfServiceCancelComplete",
  "startSwap",
  "firstOrderConfirmation",
]);

export const CONTACT_US_URL =
  "https://help.renttherunway.com/en_us/how-can-i-contact-rtr--Skr2sxhN8?action_type=footer_link";

export const MODAL = Object.freeze({
  FREEZE_SCROLL: "ReactModal__Body--freeze",
  SCROLL: "ReactModal__Body--scroll",
});

export const POPOVER = Object.freeze({
  TOP: "top",
  BOTTOM: "bottom",
});

export const TOOLTIP = {
  MODE: {
    CLICK: "click",
    HOVER: "hover",
  },
  PLACEMENTS: {
    "AUTO": "auto",
    "TOP": "top",
    "BOTTOM": "bottom",
    "RIGHT": "right",
    "LEFT": "left",
    "AUTO-START": "auto-start",
    "TOP-START": "top-start",
    "BOTTOM-START": "bottom-start",
    "RIGHT-START": "right-start",
    "LEFT-START": "left-start",
    "AUTO-END": "auto-end",
    "TOP-END": "top-end",
    "BOTTOM-END": "bottom-end",
    "RIGHT-END": "right-end",
    "LEFT-END": "left-end",
  },
};

export const formAutoCompleteCategories = {
  Shipping: "shipping",
  Billing: "billing",
};

export const membershipStyles = {
  PRO: "XX8",
  UPDATE: "XX75",
  UNLIMITED: "XX77",
  UNLIMITED_OLD: "XX13",
  UNLIMITED_WAITLIST: "XX26",
  UNLIMITED_CONDE_NAST: "XX49",
  UNLIMITED_LEGACY_MONTHLY_PAYMENT: "XX14",
  UNLIMITED_LEGACY_TWELVE_MONTH_PAYMENT: "XX46",
};

export const FORCE_PICKUP_SCHEDULER_PARAM = "pickupScheduler";
export const FORCE_ADD_SHIPMENT_PARAM = "addShipment";

export const giftCardSkus = {
  FIFTY: "XX79_ONE SIZE",
  HUNDRED: "XX80_ONE SIZE",
  ONE_FIFTY: "XX81_ONE SIZE",
  TWO_HUNDRED: "XX103_ONE SIZE",
  TWO_FIFTY: "XX82_ONE SIZE",
  UPDATE_MONTH: "XX104_ONE SIZE",
  UPDATE_NCO: "XX101_ONE SIZE",
  UNLIMITED_MONTH: "XX83_ONE SIZE",
  UNLIMITED_NCO: "XX102_ONE SIZE",
};

//
// TODO migrate `constants/logging.js` into this object
//
// NOTE leaf-nodes of objects should be `lowercase_underscore`-type
//      as this is what is being expected by analytics back-end
//

export const EPROTECT_OBJECT_TYPE = "eProtect";

export const ACTION_LOGGER = {
  GRID: {
    SIZE_SEARCH: {
      action: "size_search",
      object_type: "grid",
    },
  },
  EPROTECT: {
    NOT_LOADED: {
      object_type: EPROTECT_OBJECT_TYPE,
      action: "script_not_loaded",
    },
    ERROR: {
      object_type: EPROTECT_OBJECT_TYPE,
      action: "error_generating_token",
    },
    TIMEOUT: {
      object_type: EPROTECT_OBJECT_TYPE,
      action: "timeout_generating_token",
    },
    TOKEN_GENERATED: {
      object_type: EPROTECT_OBJECT_TYPE,
      action: "token_generated",
    },
  },
};

export const GA4_EVENTS = {
  GA4_BEGIN_CHECKOUT: "begin_checkout",
  GA4_REMOVE_FROM_CART: "remove_from_cart",
  GA4_ADD_TO_CART: "add_to_cart",
  GA4_PURCHASE: "purchase",
  GA4_PROMOTION: "select_promotion",
  GA4_ITEM_SELECT: "view_item",
  GA4_VIEW_PLANS: "view_plans",
};

export const SAILTHRU_EVENTS = {
  SAILTHRU_ADD_TO_CART: "add_to_cart",
  SAILTHRU_CHECKOUT_BAG_CONTENTS: "checkout_bag_contents",
  SAILTHRU_RESERVE_PDP: "view_reserve_pdp",
  SAILTHRU_MEMBERSHIP_PDP: "view_membership_pdp",
};

export const cxPhoneNumber = "800-509-0842";
export const cxPhoneNumberWithCountryCode = "1-800-509-0842";
export const cxPhoneNumberForLink = "tel:18005090842";
export const membershipDirectPhoneNumber = "866-741-3599";

export const ONETRUST_CONSENT_MANAGEMENT = {
  COOKIE_CATEGORIES: {
    STRICTLY_NECESSARY: "C0001",
    PERFORMANCE: "C0002",
    FUNCTIONAL: "C0003",
    TARGETING: "C0004",
    SOCIAL_MEDIA: "C0005",
  },
};

export const allMaternityFilters = {
  epicMaternity: ["truematernity", "bumpfriendly"],
};
export const Annotations = {
  secondStyleAndPromo: amount => `You save ${amount} on your Second Style`,
  secondStyleDiscount: "Reduced Second Style",
  backupStyleDiscount: "Backup Size",
  promoItemSavings: amount => `(${amount} off)`,
  promoItemSavingsWithReserveCheckout: amount => `(${amount} off - Member Perk)`,
  rebookingError: "Not available",
  recurringPaymentWithPromo: (discount, term) => {
    return `${discount}/${term} after`;
  },
};
export const AUTH_STATES = {
  ANONYMOUS: "anonymous",
  IDENTIFIED: "identified",
  AUTHORIZED: "authorized",
};
export const CanonicalSizeValues = _.map(_.range(0, 24, 2), function (size) {
  return { displayName: size.toString(), id: size.toString() };
});
export const CarouselTypes = {
  CLEARANCE: "CLEARANCE",
};

export const CMSHedTypes = {
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
};
export const DeviceTypes = {
  MOBILE: "mobile",
  DESKTOP: "desktop",
  TABLET: "tablet",
};

export const ScreenMinWidths = {
  TABLET_SMALL: 481,
  TABLET: 769,
  DESKTOP: 1025,
  DESKTOP_LARGE: 1531,
};

export const getAppleDeviceType = () => {
  if (/iPhone/i.test(navigator.userAgent)) return DeviceTypes.MOBILE;
  if (/iPad/i.test(navigator.userAgent)) return DeviceTypes.TABLET;
  if (/Mac/i.test(navigator.userAgent)) return DeviceTypes.DESKTOP;
};

export const KidsCanonicalSizeYearValues = _.map(_.range(3, 15, 1), function (size) {
  return { displayName: size.toString(), id: size.toString() };
});
export const HOME_PICKUP_METHOD_KEY = "homePickupMethod";
export const HOME_PICKUP_SPECIAL_INSTRUCTIONS_KEY = "homePickupSpecialInstructions";
export const HOME_PICKUP_NEW_TAG_OTE = "HOME_PICKUP_NEW_TAG_OTE";
export const HomePickupMethod = {
  NONE_SELECTED: "NONE_SELECTED",
  LEAVE_ON_DOORSTEP: "LEAVE_ON_DOORSTEP",
  DIRECT_HANDOFF: "DIRECT_HANDOFF",
};
export const HomePickupOrderType = {
  CROSS_SHIP: "CROSS_SHIP",
  RESERVE: "RESERVE",
  STANDALONE_MEMBERSHIP: "STANDALONE_MEMBERSHIP",
};
export const HomePickupType = {
  HOME_PICKUP_ONLY: "HOME_PICKUP_ONLY",
  LIVE_SWAP_ELIGIBLE: "LIVE_SWAP_ELIGIBLE",
};
export const HomePickupPostalCodeGroupType = {
  RESERVE: "RESERVE",
  SUBSCRIPTION: "SUBSCRIPTION",
  TEMP_DISABLED: "TEMP_DISABLED",
};
export const HPUConfirmationModalName = "HPU-CONFIRMATION";
export const HPUConfirmationModalTypes = {
  STANDALONE: "HPU_STANDALONE_CONFIRMATION_MODAL",
  CROSS_SHIP: "HPU_CROSS_SHIP_CONFIRMATION_MODAL",
};
export const HPUEditAddressConfirmHandlerTypes = {
  EDIT_ADDRESS_FROM_SCHEDULER_CARD: "EDIT_ADDRESS_FROM_SCHEDULER_CARD",
  EDIT_ADDRESS_FROM_STANDALONE_ZIP_ERROR: "EDIT_ADDRESS_FROM_STANDALONE_ZIP_ERROR",
};
export const HOME_PICKUP_UNAVAILABLE_MODAL_NAME = "homePickupUnavailableModal";
export const HPUEditAddressModalName = "HPU-EDIT-ADDRESS";
export const PdpImageZoomModalName = "PDP_IMAGE_ZOOM_MODAL";
export const ChangeAddressModalNames = {
  changeAddressFromBag: "changeAddressFromBag",
  changeAddressFromGrid: "changeAddressFromGrid",
  changeAddressFromHomeHeader: "changeAddressFromHomeHeader",
  changeAddressFromAvailabilityModal: "changeAddressFromAvailabilityModal",
  changeAddressFromMembershipFormFields: "changeAddressFromMembershipFormFields",
  changeAddressFromSettings: "changeAddressFromSettings",
};
export const ChangePaymentMethodModalNames = {
  changePaymentMethodFromBag: "changePaymentMethodFromBag",
  changePaymentMethodFromReturnFlow: "changePaymentMethodFromReturnFlow",
  changePaymentMethodFromSettings: "changePaymentMethodFromSettings",
  changePaymentMethodFromTTBModal: "changePaymentMethodFromTTBModal",
  changePaymentMethodFromProductFormFields: "changePaymentMethodFromProductFormFields",
  changePaymentMethodFromSettingsPrimary: "changePaymentMethodFromSettingsPrimary",
  changeBillingMethodFromSettingsBilling: "changeBillingMethodFromSettingsBilling",
};
export const clientSideErrorMessages = {
  reservationForm: {
    isRequired: "is required",
    selectDistinctSizes: "Please select two different sizes.",
  },
  inlineReservationForm: {
    isRequired: "Required",
    selectDistinctSizes: "Select different sizes",
  },
  formValidation: {
    zipCode: "Enter a valid ZIP code",
    streetAddress: "We do not ship to PO boxes",
    streetAddressEmpty: "Address cannot be blank",
    enterValidStreetAddress: "Enter a valid street address",
    phoneNumber: "Enter a mobile number",
    dateEmpty: "Please select a date",
    creditCardNumber: "Enter a valid credit card number",
    expirationDate: "Enter a valid expiration",
    cvv: "Enter a valid card CVV",
    zipCodeEmpty: "Please enter a ZIP code",
    firstName: "Enter a first name",
    lastName: "Enter a last name",
    city: "Enter a city",
    selectState: "Select a state",
    email: "Invalid email address",
    password: "Please enter a password",
    birthday: "Enter a valid birthday",
    minorBirthday: "Privacy regulations prevent us from offering you access (minimum age is 14)",
    dateInvalid: "Please enter a valid date",
    dateInvalidFormat: "Please enter a date MM/DD/YYYY",
    height: "Enter a valid height",
    weight: "Enter a number between 50 and 400",
    futureDate: "Please enter a date in the future",
    minAge: "Members must be at least 18 years old",
  },
  firstName: {
    pattern: /^[\p{L} ,.'-]{1,100}$/u,
    error: "Please enter a valid first name",
  },
  lastName: {
    pattern: /^[\p{L} ,.'-]{1,100}$/u,
    error: "Please enter a valid last name",
  },
  passwordErrorMessage: {
    weakPasswordError: "Your password must be 8 characters or more",
    mismatchPasswordError: "Your passwords must match",
    resetPasswordError: "Please reset your password",
    pwdedPasswordError: "Looks like this is a common password! Please try again.",
  },
  billingSubmission: {
    badRequest: "Your billing information is invalid. Please try again.",
    serverError: "Something went wrong. Please try again.",
  },
  changeDefaultPaymentMethod: {
    defaultError: "Your default card could not be changed. Please select a different credit card.",
  },
  removePaymentMethod: {
    defaultError: "You cannot remove your default payment method.",
    removeError: "Your payment method(s) could not be removed.",
  },
  addressSuggestions: {
    addressTooLong: "Address line 1 or line 2 exceeds the 60-character maximum",
    notRecognized: "Your address cannot be validated. Please check and try again.",
    nonShippable: "Unfortunately, we do not ship to this address. Please provide a valid shipping address.",
  },
  changeDefaultAddress: {
    defaultError: "Your default address could not be changed. Try a different address.",
  },
  removeAddress: {
    defaultError: "Your address could not be removed.",
  },
  reviews: {
    defaultError: "Oops... we hit a snag and are unable to submit your review. Please try again.",
    unauthenticated: "You are not logged in or your session timed out. Please log in and try again.",
  },
  creditCard: {
    notFilled: "Please fill out all required credit card fields.",
  },
};
export const currentUserIdReplacementToken = "~me~";
export const immediateUpgradeMessages = {
  success: "Extra shipment confirmed!",
};
export const CMS_PRICE_FILLER_KEY = "$monthlyCharge";
export const costTypes = {
  baseCost: "cost",
  insurance: "insurance",
  preTax: "preTax",
  tax: "tax",
  total: "total",
  subTotal: "sub",
  discountedSubTotal: "discountedSub",
  secondStyleDiscount: "secondStyleDiscount",
  backupStyleDiscount: "backupStyleDiscount",
  promoItem: "promoItem",
  membershipItem: "membershipItem",
  baseSlotUpgrade: "baseSlotUpgrade",
  baseSlotProration: "baseSlotProration",
};
export const courierTime = "3";
// unfortunately, these keys are hard-coded in the backend
export const defaultFitCounts = {
  "Small": 0,
  "True to Size": 0,
  "Large": 0,
};
export const exchangeBasePrice = 12.95;
export const fallbackZip = "10014";
export const formAutoCompleteSettings = function (name, providedCategory) {
  // providedCategory vs category:
  // for some fields, it always makes sense to have a category (e.g., address
  // fields), for others, there are times where it may not (e.g., name fields)
  // for times where it always makes sense, use `category`
  // for times where it sometimes makes sense, use `providedCategory`
  const category = providedCategory || formAutoCompleteCategories.Shipping;
  const isBilling = category === formAutoCompleteCategories.Billing;

  switch (name) {
    case "ccFirstName": // falls-through
    case "firstName":
      return `${providedCategory} ${isBilling ? "cc-given-name" : "given-name"}`;
    case "ccLastName": // falls-through
    case "lastName":
      return `${providedCategory} ${isBilling ? "cc-family-name" : "family-name"}`;
    case "street":
      return `${category} street-address`;
    case "street1":
      return `${category} address-line1`;
    case "street2":
      return `${category} address-line2`;
    case "city":
      return `${category} address-level2`;
    case "state": // falls-through
    case "zoneCode":
      return `${category} address-level1`;
    case "zipCode": // falls-through
    case "postalCode":
      return `${category} postal-code`;
    case "phone":
    case "phoneNumber":
      return `${providedCategory} tel`;
    case "cardName":
      return `${category} cc-name`;
    case "ccNumber": // falls-through
    case "cardNumber":
      return `${category} cc-number`;
    case "cardCsc": // falls-through
    case "cvv": // falls-through
    case "cvv2": // falls-through
    case "securityCode":
      return `${category} cc-csc`;
    case "cardExpirationYear": // falls-through
    case "expirationDate": // falls-through
    case "expiration":
      return `${category} cc-exp`;
  }
};
// these names are important. if you need to change any of them, please be
// sure that browser auto fill on checkout (in Chrome, FF, & Safari) continues
// to work.
// in addition, if any of these input names change and no longer match what is
// expected by Godmother, please add mappings in billing-form-helpers.js
export const formElementNames = {
  firstName: "firstName",
  lastName: "lastName",
  // on checkout, we sometimes present _both_ the billing and shipping forms.
  // for browser autocomplete to work, these input names must be unique
  ccFirstName: "ccFirstName",
  ccLastName: "ccLastName",
  street: "street1",
  apt: "street2",
  city: "city",
  state: "zoneCode",
  zipCode: "postalCode",
  phone: "phone",
  phoneNumber: "phone",
  ccNumber: "cardNumber",
  expirationDate: "cardExpirationYear", // for Safari, this auto-fills m/d/yyyy :shrug:. for all others, this name is ignored in favor of the autocomplete attribute
  cvv: "cardCsc",
  makeDefault: "default",
};
const defaultFormParams = {
  defaultValue: "",
  errorMessage: "",
  isRequired: true,
  isValid: false,
  value: "",
};
export const defaultFormErrorMessageObject = {
  errorCopy: "",
  errorCTA: "",
  errorURL: "",
};
// This is almost the same as shipping-step-reducer's initial state, but strictly for form objects, with all values being non required
export const defaultAddressForm = {
  [formElementNames.firstName]: {
    ...defaultFormParams,
    defaultErrorMsg: clientSideErrorMessages.formValidation.firstName,
  },
  [formElementNames.lastName]: {
    ...defaultFormParams,
    defaultErrorMsg: clientSideErrorMessages.formValidation.lastName,
  },
  [formElementNames.street]: {
    ...defaultFormParams,
    defaultErrorMsg: clientSideErrorMessages.formValidation.enterValidStreetAddress,
  },
  [formElementNames.apt]: {
    ...defaultFormParams,
    isRequired: false,
  },
  [formElementNames.city]: {
    ...defaultFormParams,
    defaultErrorMsg: clientSideErrorMessages.formValidation.city,
  },
  [formElementNames.state]: {
    ...defaultFormParams,
    defaultErrorMsg: clientSideErrorMessages.formValidation.selectState,
  },
  [formElementNames.zipCode]: {
    ...defaultFormParams,
    defaultErrorMsg: clientSideErrorMessages.formValidation.zipCode,
  },
  [formElementNames.phone]: {
    ...defaultFormParams,
    defaultErrorMsg: clientSideErrorMessages.formValidation.phoneNumber,
  },
};

export const formLabels = {
  zipCode: "ZIP Code",
  streetAddress: "Address",
  apt: "Apt / Unit / Suite (Optional)",
  state: "State",
  phoneNumber: "Mobile Number",
  creditCardNumber: "Credit Card #",
  expirationDate: "MM / YY",
  defaultPayment: "Set as default billing information",
  defaultAddress: "Set as default shipping address",
  firstName: "First Name",
  lastName: "Last Name",
  city: "City",
  email: "Email Address",
  password: "Password",
  birthday: "Birthday",
};
export const GodmotherErrorMap = {
  "elective-sale-item-not-allowed-in-reserve-bag": "Please checkout with your rental before purchasing this item",
  "reserve-item-not-allowed-in-kif-bag": "Please checkout with your purchase before reserving this item",
  "defaultAddItemToOrder": "Error adding item to order",
  "UNAVAILABLE": "We're sorry, the item is unavailable.",
};
export const HappinessSurveyTypes = {
  RESERVE: "reserve",
};
export const insiderPerksList = {
  CMS_PATH: "/insider_perks_list",
  STATE_KEY: "insiderPerksList",
};
export const cancelReasons = {
  CMS_PATH: "/self_service_cancel_v2",
  STATE_KEY: "cancelReasons",
};
export const cmsPromoBanners = {
  CMS_PATH: "promo_all_users_promo_json",
  STATE_KEY: "cmsPromoBanners",
  RESPONSE_FIELD: "header-piece-promo",
};
export const cmsReferralPostCheckoutBanner = {
  CMS_PATH: "referrals-confirmation-banner",
  STATE_KEY: "referralPostCheckoutBanner",
  RESPONSE_FIELD: "top-page-banner",
};
export const membershipControlCenterData = {
  CMS_PATH: "/membership/control_center",
  STATE_KEY: "membershipControlCenterData",
};
export const emergencyBanner = {
  CMS_PATH: "/",
  STATE_KEY: "emergencyBanner",
  RESPONSE_FIELD: "emergency-banner",
};
export const immediateUpgradeLegalText = {
  CMS_PATH: "/immediate_upgrade_legal_text",
  STATE_KEY: "immediateUpgradeLegalText",
};
export const merchandiseCategories = {
  bulk: "BULK",
  rental: "RENTAL",
  clearance: "CLEARANCE",
  kids: "kids_apparel",
};
export const membershipModalsCMSPathsByMembershipType = {
  RTRUPDATE: "/rtrUpdateRentalPeriodStates",
  UNLIMITED: "/unlimitedRentalPeriodStates",
};
export const membershipLogoDisplayByMembershipType = {
  RTRUPDATE: "Update",
  UNLIMITED: "Unlimited",
};
export const membershipPlanChangeRules = {
  CMS_PATH: "/sf_self_service_plan_change_rules",
  STATE_KEY: "membershipPlanChangeRules",
};
export const membershipHomePageGuidedTourContent = {
  CMS_PATH: "/guided_tour/membership_home_page",
  STATE_KEY: "membershipHomePageGuidedTourContent",
};
export const membershipPauseFlowContentCms = {
  CMS_PATH: "/membership_pause_flow",
  STATE_KEY: "membershipPauseFlow",
};
export const membershipOnboardingContentCms = {
  CMS_PATH: "/membership_onboarding_content",
  STATE_KEY: "membershipOnboardingContent",
  STEP_NAME_ONE: "welcomeScreenOne",
  STEP_NAME_TWO: "welcomeScreenTwo",
};
export const membershipHomeFAQContentCms = {
  CMS_PATH: "/homepage_faq",
  STATE_KEY: "homepageFAQs",
};
//  The below names must match the stepName field in the CMS under the Membership Pause Flow component
export const membershipPauseFlowStepNames = {
  SAVE_OFFER: "saveOffer",
  PWI_OFFER: "pwiOffer",
  PWI_CONFIRMATION: "pwiConfirmation",
  PAUSE_WITHOUT_ITEMS_CONFIRMATION: "pauseWithoutItemsConfirmation",
};
export const membershipManagePlanBannerStates = {
  CANCELED: "canceled",
  CANCELED_WITH_ITEMS: "canceledWithItems",
  FUTURE_CANCEL: "futureCancel",
  FUTURE_PAUSE: "futurePause",
  FUTURE_PAUSE_WITH_ITEMS: "futurePauseWithItems",
  NONE: "none",
  PAUSED: "paused",
  PAUSED_WITH_ITEMS: "pausedWithItems",
};
export const reserveUpsellPromo = {
  CMS_PATH: "/reserve_upsell_promo",
  STATE_KEY: "upsellPromo",
};

export const aiSearchV1Prompts = {
  CMS_PATH: "/ai-search-v1-search-prompts",
  STATE_KEY: "v1SearchPrompts",
};
export const emailCaptureModalCMSContent = {
  SESSION_STORAGE_KEY: "anonymous_user_email",
  SESSION_STORAGE_VALUE: "USER_EMAIL",
};

export const fullBleedFiftyFiftyCMSContent = {
  CMS_PATH: "/content-modules/full-bleed-50-50",
  STATE_KEY: "fullBleedFiftyFifty",
};

export const smBagPromoBannerCMSContent = {
  CMS_PATH: "/bag-promo-banner",
  STATE_KEY: "smBagPromoBanner",
};

export const bagUpsellCMSContent = {
  CMS_PATH: "/bag-upsell",
  STATE_KEY: "bagUpsell",
};

export const gridStickyCtaCMSContent = {
  CMS_PATH: "/grid_sticky_cta",
  STATE_KEY: "gridStickyCta",
};

export const reserveExchangeSource = "RESERVE_EXCHANGE";
export const reserveExchangeVersion = "3";
export const membershipHappinessSurveyItemReturnedPromiseSource = "ITEM_RETURNED_PROMISE";
export const membershipHappinessSurveyItemReturned = "ITEM_RETURNED";
export const SecondStylePrice = {
  FourDay: "32.50",
  EightDay: "52",
};
export const RENT_MY_STYLE_QUIZ_MODAL_NAME = "rent-my-style-quiz";
export const TRIGGERED_MODALS_KEY = "triggeredModals";

export const userStylePreferences = {
  CMS_PATH: "/user_preferences",
  STATE_KEY: "userPreferences",
  STYLE_ID: "styleTypes",
  EVENT_ID: "eventTypes",
  GODMOTHER_TYPE: "userStylePreferences",
};

export const validationStates = {
  VALID: "valid",
  INVALID: "invalid",
  NOT_DETERMINED: "notDetermined",
};
export const validations = {
  zipCode: {
    zipCodeValidator: /^(\d{5})$/,
    zipPlusFourValidator: /^(\d{5}([-]\d{4})?)$/,
  },
  poBox: /p\.?\s?o\.?\s?box/gi,
  cvv: /^\d{3,4}$/,
  hexColor: /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

//
// TODO sort below objects into above
//
export const creditCardTypes = {
  VISA: "VISA",
  AMERICAN_EXPRESS: "AMERICAN_EXPRESS",
  JCB: "JCB",
  MASTER_CARD: "MASTER_CARD",
  DISCOVER: "DISCOVER",
  APPLE_PAY: "APPLE_PAY",
};
export const EprotectErrors = {
  "871": "Enter a valid credit card number",
  "872": "Enter a valid credit card number",
  "873": "Enter a valid credit card number",
  "874": "Enter a valid credit card number",
  "875": `There was an error processing your request. Please try again later or contact Customer Service ${cxPhoneNumber}.`,
  "876": "Enter a valid credit card number",
  "881": "Enter a valid CVV",
  "882": "Enter a valid CVV",
  "883": "Enter a valid CVV",
  "889": `There was an error processing your request. Please try again later or contact Customer Service ${cxPhoneNumber}.`,
  "default": `There was an error processing your request. Please try again later or contact Customer Service ${cxPhoneNumber}.`,
};
export const filters = {
  canonicalSizes: "canonicalSizes",
  colors: "colors",
  designer: "designer",
  embellishments: "embellishments",
  formality: "formality",
  length: "length",
  maternity: "epicMaternity",
  neckline: "neckline",
  occasions: "occasions",
  season: "season",
  sleeve: "sleeve",
};

/**
 * Filters that don't count as "user-applied" on grid when we display the number of applied filters in the UI.
 */
export const nonAttributeFilterKeys = [
  "duration",
  "canonicalSizes",
  "zip_code",
  "date",
  "unlimitedMinAvailability",
  "rtrUpdateMinAvailability",
  "primarySize",
  "searchText",
];

export const superbarFilterKeys = [
  "duration",
  "date",
  "zip_code",
  "canonicalSizes",
  "unlimitedMinAvailability",
  "fuzzyGiraffeMinAvailability",
];

export const unlimitedAnnotation = {
  annotationText: "$0 with Unlimited",
  annotationColor: "black",
};
export const proBirthdayCredit = "30";
export const proPrice = "29.95";
export const proSummerDiscount = "0.15";
export const defaultSameDayZip = {
  NYC: "10014",
};
export const showroomIds = {
  flagship_nyc: "29",
};
export const specialPrices = {
  free: "FREE",
};
export const eventTypeId = {
  bridal: 30,
};

//
// TODO look into which components/helpers are using `membershipTypes`,
//      and `membershipNames`
//
// FIXME? not sure of their need
//
export const MEMBERSHIP_TYPES_TO_TRIGGERED_MODALS_PATH = {
  RTRUPDATE: "/rtrUpdateRentalPeriodStates",
  UNLIMITED: "/unlimitedRentalPeriodStates",
};
export const membershipTypes = {
  FUZZYGIRAFFE: "FUZZYGIRAFFE",
  RTRUPDATE: "RTRUPDATE",
  UNLIMITED: "UNLIMITED",
};
export const membershipNames = {
  RTRUpdate: "RTR Update",
  RTRUnlimited: "RTR Unlimited",
};
export const membershipTypesToNames = {
  RTRUPDATE: "RTR Update",
  UNLIMITED: "RTR Unlimited",
};
export const membershipBaseSlots = {
  MINIMUM: 4,
};
export const membershipStatuses = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  PAUSED: "PAUSED",
};
export const snackBarMessages = {
  AUTHENTICATION_ERROR: "An error occurred registering or signing in.",
  CCAS: "Great! Thanks for letting us know.",
};
export const referralPPSAwardStates = {
  EXPIRED: "EXPIRED",
  UPCOMING: "UPCOMING",
  ACTIVE: "ACTIVE",
};
export const slotsFromRewardTypes = {
  REFERRAL: "REFERRAL",
  LOYALTY: "LOYALTY",
};
export const shipmentActions = {
  PICK: "PICK",
  PICK_ONLY: "PICK_ONLY",
  NONE: "NONE",
  CAN_INITIATE_CROSS_SHIP: "CAN_INITIATE_CROSS_SHIP",
};
export const membershipNamesEligibleFor = {
  RTRUpdate: "rtr_update",
  RTRUnlimited: "rtr_unlimited",
  RTRReserve: "rtr_reserve",
};
export const billingStatuses = {
  GOOD_STANDING: "GOOD_STANDING",
  DELINQUENT: "DELINQUENT",
  RETRY: "RETRY",
  CANCELED: "CANCELED",
  CHURNED: "CHURNED",
  PAUSED: "PAUSED",
};
export const inventoryEligibilities = {
  rtr_update: "rtr_update",
  rtr_unlimited: "rtr_unlimited",
};
export const membershipStyleNames = {
  update: ["XX75"],
  unlimited: ["XX13", "XX14", "XX26", "XX44", "XX45", "XX46", "XX49", "XX77"],
};
export const membershipLens = {
  classic: "classic",
  unlimited: "unlimited",
  RTRUpdate: "rtrupdate",
};

// current carousels on the PDP use 'rental' for non subscribers - AS 6/2/23
export const membershipLensForAvailability = {
  classic: "rental",
  unlimited: "unlimited",
  RTRUpdate: "rtrupdate",
};

export const availabilityFilter = {
  shipsDefault: "ships_default",
  allStyles: "all_styles",
  specificDates: "specific_dates",
};
export const membershipTypeToLensPathMapper = {
  RTRUPDATE: "isRTRUpdateLens",
  UNLIMITED: "isUnlimitedLens",
};
export const membershipTypeToOrderTypeCopyMapper = {
  RTRUPDATE: "Update",
  UNLIMITED: "Unlimited",
};
export const orderModificationTypes = {
  ADDRESS: "ADDRESS",
  DATE: "DATE",
};
export const orderModificationRequestTypes = {
  PRICING: "price",
  PERFORM: "perform",
};
export const returnPolicyModalCMSPath = {
  CMS_PATH: "/return-policy-modal",
  STATE_KEY: "returnPolicyModal",
};
export const checkout = {
  errorMessages: {
    billingStep:
      "Your card couldn't be saved. Please double check that the information matches your bank records and try again.",
    shippingStep: "There was an error adding your shipping address. Please verify and try again.",
    pickUpInStore: "There was an error requesting pick up in store. Please try again.",
    addPro: "We're sorry, but we were unable to add PRO. Please try again.",
  },
  errorTypes: {
    NON_SHIPPABLE_ADDRESS: "NON_SHIPPABLE_ADDRESS",
    REQUIRES_VALIDATION: "REQUIRES_VALIDATION",
  },
  informationalMessages: {
    giftCardShippingAddress:
      "Our system requires a shipping address for all purchases. We will only deliver your gift card using the method you have selected.",
  },
  fulfillmentTypes: {
    INVENTORY_REQUEST: "inventoryRequest",
    PICK_UP_IN_STORE: "pickUpInStore",
    SHIPPED: "shipped",
    VIRTUAL: "virtual",
    WALK_OUT: "walkOut",
  },
  orderTypes: {
    UPDATE: "RTR_UPDATE",
    CLASSIC: "CLASSIC",
    UNLIMITED: "QUEUE",
    STORE_PICKUP: "STORE_PICKUP",
    STORE_PICKUP_WEB: "STORE_PICKUP_WEB",
    CLASSIC_POSH: "CLASSIC_POSH",
    STORE_INVENTORY_REQUEST: "STORE_INVENTORY_REQUEST",
    MIXED: "MIXED",
    PURCHASE_AT_HOME: "PURCHASE_AT_HOME",
  },
  orderItemTypes: {
    BULK: "bulk",
    CLASSIC: "classic",
    CLEARANCE: "clearance",
    UNLIMITED: "unlimited",
    UPDATE: "rtrUpdate",
    PRO_SUBSCRIPTION: "proSubscription",
    UPDATE_SUBSCRIPTION: "rtrUpdateSubscription",
    UNLIMITED_SUBSCRIPTION: "unlimitedSubscription",
    GIFT_CARD: "giftCard",
    KIF_FROM_RACK: "electiveSale",
    RESERVATION: "reserve",
    MEMBERSHIP_PLAN: "subscription",
  },
  paymentMethodSubTypes: {
    CREDIT_CARD: "creditCard",
    APPLE_PAY: "applePay",
    GIFT_CARD: "giftCard",
    CREDITS: "credits",
  },
  summary: {
    cardEndingIn: cardNumber => `Ending in ${cardNumber.replace(/X/g, "")}`,
    shippingTo: address => {
      if (!address) {
        return;
      }

      return `${address.street1.toLowerCase()}, ${address.city.toLowerCase()}, ${address.zoneCode} ${_.first(
        address.postalCode.split("-")
      )}`;
    },
  },
  modalNames: {
    EMPTY_BAG_REDIRECT_MODAL: "EMPTY_BAG_REDIRECT_MODAL",
    TIME_ON_ORDER_PAGE_EXCEEDED_MODAL: "TIME_ON_ORDER_PAGE_EXCEEDED_MODAL",
  },
  pickupInStore: {
    pickupTimeMessage: pickupInStoreWindow => {
      // In our current checkout, we receive store info that includes pickup time in the format "00 - 00 AM/PM"
      return `Your order will arrive between ${pickupInStoreWindow} on your delivery date. We will email you when it is ready.`;
    },
    defaultPickupTimeMessage: "We will email you on your delivery date when your order is ready.",
  },
};

export const PaymentMethods = {
  subType: {
    CREDIT_CARD: "creditCard",
    APPLE_PAY: "applePay",
    GIFT_CARD: "giftCard",
    CREDITS: "credits",
  },
  bagMethodType: {
    CREDIT_CARD: "CreditCardPaymentMethod",
    APPLE_PAY: "ApplePayPaymentMethod",
    APPLE_PAY_PLACEHOLDER: "ApplePayPlaceholderPaymentMethod",
    GIFT_CARD: "GiftCardPaymentMethod",
  },
  paymentType: {
    DEFAULT: "default",
    RECURRING: "recurring",
  },
};

// TODO: find where this is used
// and replace so it can be removed
export const orderType = {
  RTRUpdate: "RTR_UPDATE",
};
export const orderTracking = {
  carrierNumbers: {
    "TForce Final Mile": "(855) 396-2639",
    "Breakaway": "(212) 947-7777",
  },
  statusDisplayStrings: {
    AT_HOME: "At Home",
    DELAYED: "Delayed",
    IN_THE_WORKS: "In the Works",
    IN_TRANSIT: "In Transit",
    OUT_FOR_DELIVERY: "Out for Delivery",
    SHIPPED: "Shipped",
  },
  statuses: {
    AT_HOME: "AT_HOME",
    DELAYED: "DELAYED",
    IN_THE_WORKS: "IN_THE_WORKS",
    IN_TRANSIT: "IN_TRANSIT",
    OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
    SHIPPED: "SHIPPED",
  },
};
export const invoiceOrderTypesToMembershipNames = {
  QUEUE: "RTR Unlimited",
  CLASSIC: "RTR Reserve",
  RTR_UPDATE: "RTR Update",
};
export const smartTopRightTextColors = {
  light: "#FFFFFF",
  gold: "#965E31",
  dark: "#000000",
};

export const contact = {
  concierge: "concierge@renttherunway.com",
  pause: "pause@renttherunway.com",
  cancel: "cancel@renttherunway.com",
  help: "help@renttherunway.com",
  phoneNumber: cxPhoneNumber,
  membershipDirectPhoneNumber,
};
export const paymentProfileMessages = {
  chargeMessage:
    "If we can't charge your preferred payment method, we will try to charge another stored credit card so your benefits are not interrupted.",
};
export const ReviewsSort = {
  WOMEN_LIKE_ME: "wlm",
  FEATURED: "featured",
  NEWEST: "newest",
  RATING: "rating",
};

export const NEXT_GRID_TYPES = {
  clearance: "clearance",
  designer: "designer",
  category: "category",
  curation: "curation",
  view_all: "view_all",
};

export const FILTERS_TO_PRESERVE = [
  "duration",
  "date",
  "zip_code",
  "availableOnly",
  "canonicalSizes",
  "depotId",
  "includeEarlyAccess",
  "programEligibility",
  "epicMaternity",
];

export const sortOptionsReviewsUserSpecific = [
  {
    label: "Newest",
    value: ReviewsSort.NEWEST,
  },
  {
    label: "Rating",
    value: ReviewsSort.RATING,
  },
];
export const sortKeys = {
  RECOMMENDED: "recommended",
  PRICE_ASC: "priceAsc",
  PRICE_DESC: "priceDesc",
  NEWEST: "newest",
  HIGHEST_MSRP: "HIGHEST_MSRP",
  LOWEST_MSRP: "LOWEST_MSRP",
};

export const nextGridSortKeys = {
  clearance: {
    recommended: "Recommended",
    newest: "Newest",
    newest_clearance: "New Markdowns",
    highest_discount: "Best Savings",
    lowest_price: "Buy Price: Low - High",
    highest_price: "Buy Price: High - Low",
    highest_msrp: "Retail Value: High - Low",
    lowest_msrp: "Retail Value: Low - High",
  },
  curation: {
    curation: "Recommended",
    newest: "Newest",
    highest_msrp: "Retail Value: High - Low",
    lowest_msrp: "Retail Value: Low - High",
  },
  designer: {
    newest: "Newest",
    highest_msrp: "Retail Value: High - Low",
    lowest_msrp: "Retail Value: Low - High",
  },
  category: {
    newest: "Newest",
    highest_msrp: "Retail Value: High - Low",
    lowest_msrp: "Retail Value: Low - High",
  },
  view_all: {
    newest: "Newest",
    highest_msrp: "Retail Value: High - Low",
    lowest_msrp: "Retail Value: Low - High",
  },
};

export const nextGridSortKeysMembership = {
  clearance: {
    recommended: "Recommended",
    newest: "Newest",
    newest_clearance: "New Markdowns",
    highest_discount: "Best Savings",
    lowest_price: "Buy Price: Low - High",
    highest_price: "Buy Price: High - Low",
    highest_msrp: "Retail Value: High - Low",
    lowest_msrp: "Retail Value: Low - High",
  },
  curation: {
    curation: "Recommended",
    newest: "Newest",
    highest_msrp: "Retail Value: High - Low",
    lowest_msrp: "Retail Value: Low - High",
    lowest_price: "Buy Price: Low - High",
    highest_price: "Buy Price: High - Low",
  },
  designer: {
    newest: "Newest",
    highest_msrp: "Retail Value: High - Low",
    lowest_msrp: "Retail Value: Low - High",
    lowest_price: "Buy Price: Low - High",
    highest_price: "Buy Price: High - Low",
  },
  category: {
    newest: "Newest",
    highest_msrp: "Retail Value: High - Low",
    lowest_msrp: "Retail Value: Low - High",
    lowest_price: "Buy Price: Low - High",
    highest_price: "Buy Price: High - Low",
  },
  view_all: {
    newest: "Newest",
    highest_msrp: "Retail Value: High - Low",
    lowest_msrp: "Retail Value: Low - High",
    lowest_price: "Buy Price: Low - High",
    highest_price: "Buy Price: High - Low",
  },
};

export const nextGridProgramEligibility = {
  rtr_update: "BASIC_CLOSET",
  rtr_unlimited: "FULL_CLOSET",
};

export const nextGridProgramEligibilityToLens = {
  BASIC_CLOSET: "rtrupdate",
  FULL_CLOSET: "unlimited",
};

export const specialSkus = {
  membershipStyles: membershipStyles,
  // NOTE: When using these skus, be sure to do a case-insensitive comparison!
  membershipSkus: _.mapObject(membershipStyles, style => `${style}_One Size`),
  unlimitedStyles: _.chain(membershipStyles)
    .pick((_val, key) => key.indexOf("UNLIMITED") > -1)
    .values()
    .value(),
  updateStyles: _.chain(membershipStyles)
    .pick((_val, key) => key.indexOf("UPDATE") > -1)
    .values()
    .value(),
  giftCards: giftCardSkus,
};

export const discounts = {
  PRICE_DROP: "CLASSIC+PRICE_DROP",
  MEMBER: "MEMBER_20",
  PRO_SUMMER: "PRO+SUMMER_15",
  MEMBERSHIP_ITEM: "MEMBERSHIP_ITEM",
  MEMBERSHIP_UPGRADE_SLOT: "MEMBERSHIP_UPGRADE_SLOT",
  RTR_UPDATE_ITEM: "RTR_UPDATE_ITEM",
  RTR_UPDATE_UPGRADE_SLOT: "RTR_UPDATE_UPGRADE_SLOT",
  RTR_UNLIMITED_ITEM: "RTR_UNLIMITED_ITEM",
  RTR_UNLIMITED_UPGRADE_SLOT: "UNLIMITED_UPGRADE_SLOT",
};

export const discountTypeCodes = {
  DOLLARS_OFF: 1,
  PERCENT_OFF: 2,
  FREE_SHIPPING: 3,
  FLAT_PRICE: 4,
  HIGHEST_COST: 5,
  FREE_INSURANCE: 6,
};

export const membershipOrderException = {
  cta: {
    UPDATE_BILLING: "Update Billing",
    CONTACT_CONCIERGE: "Contact Concierge",
  },
  ctaAction: {
    OPEN_WALLET: "openWallet",
  },
};

export const CartItemStatus = {
  HOLD: "HOLD", // in cart and available for rent
  CANCELLED: "CANCELLED", // in cart, but no longer available for rent (typically: outside NYC for tomorrow delivery)
  UNAVAILABLE: "UNAVAILABLE", // in cart, but no longer available for rent (typically: hold expired and rented by someone else)
};

// string include for fetching resume from pause invoice API call
export const ResumeFromPauseInvoiceIncludes = {
  PAUSED: "membershipInvoiceCharges",
  PWI: "membershipTierChangeCharges",
};

export const analytics = {
  ANON_RESERVE_RENTAL: "anon_reserve_rental",
  NONSUB_RESERVE_RENTAL: "nonsub_reserve_rental",
  KIFFR: "kiffr",
  KIFAH: "KIFAH",
  RESERVE: "reserve",
  RENT: "rent",
  ONE_TIME_RENTAL: "one time rental",
  CLEARANCE: "clearance",
  RETAIL: "retail",
  SUBSCRIPTION: "subscription",
  SWAP_ORDER: "subscriptionSwap",
  SELF_SERVICE_EXCHANGES: "sse_funnel",
  membershipType: {
    RTR_UPDATE: "rtr_update",
    UNLIMITED: "unlimited",
  },
  RETURN_FLOW: "return_flow",
  SHIPMENT: "shipment",
  ADD_ONS: "add_on",
  NOTIFICATION_ACTIONS: {
    CLICK_START_SWAP: "click_start_swap",
  },
  MEMBERSHIP_RETURN_FLOW: {
    KEEP: "keep",
    DONT_KEEP: "dont_keep",
  },
  MEMBERSHIP_LOCATIONS: {
    PICK_ONLY: "pick_only",
    PICK: "pick",
    CONFIRM: "confirm",
    AT_HOME: "at_home",
  },
  MODULE_TYPE: {
    QUICKVIEW: "quickview",
    CAROUSEL_GRID: "carousel_grid",
    PRODUCT_CAROUSEL: "product_carousel",
  },
  OBJECT_TYPE: {
    BONUS_ITEM_2022_MODAL: "2022_bonus_item_modal",
    BUY_PDP: "buy_pdp",
    SUB_KIFAH: "sub_kifah",
    SUB_KIFFR: "sub_kiffr",
    SUB_RENTAL: "sub_rental",
    ANON_RESERVE_RENTAL: "anon_reserve_rental",
    NONSUB_RESERVE_RENTAL: "nonsub_reserve_rental",
    AUTUMN: "autumn",
    ADD_ON: "addon",
    CROSS_SHIP_CONFIRMATION_MODAL: "cross_ship_confirmation_modal",
    GRID: "grid",
    SECOND_STYLE_GRID: "second_style_grid",
    NODE: "node",
    MEMBERSHIP: "membership",
    HEART_REC: "heart_rec",
    MEMBERSHIP_CANCELLATION_REQUEST_FORM: "cancel_form",
    MEMBERSHIP_SETTINGS: "membership_settings",
    MEMBERSHIP_SWAP_COUNTER: "swap_counter",
    MEMBERSHIP_SWAP_COUNTER_MODAL: "swap_counter_modal",
    MY_RTR_MENU: "my_rtr_menu",
    SHOPPING_BAG: "shopping_bag",
    FIRST_SWAP_TIPS: "first_swap_tips",
    FIRST_PICK_TIPS: "first_pick_tips",
    PDP: "pdp",
    PLANS_MODAL: "plans_modal",
    PLAN_SETTINGS: "plan_settings",
    RETURN_POLICY: "return_policy",
    SWAP_COUNTER_MODAL: "swap_counter_modal",
    LOYALTY_MODAL: "loyalty_modal",
    INSIDER_PAGE: "insider_page",
    MOBILE_NAV: "mobile_nav",
    TOP_NAV: "top_nav",
    REFERRAL: "referral",
    REVIEWS: "reviews",
    REVIEW_NUDGE_ITEMS_AT_HOME: "review_nudge_items_at_home",
    REFERRAL_MESSAGING_MODAL: "referral_messaging_modal",
    WAYS_TO_RETURN_TIPS: "ways_to_return_tips",
    CCAS_MODAL: "ccas_modal",
    TREATMENT_HERO: "treatment_hero",
    SMART_GRID_MESSAGING: "smart_grid_messaging",
    FIT_LP_NAV_SECTION: "fit_lp_nav_section",
    PDP_TILE_SWAP: "pdp_tile_swap",
    PDP_CAROUSEL: "pdp_carousel",
    HOME_PICKUP_NOT_AVAILABLE_MODAL: "home_pickup_not_available_modal",
    HOME_PICKUP_SCHEDULER: "home_pickup_scheduler",
    HOME_PICKUP_STANDALONE_SCHEDULER: "home_pickup_standalone_scheduler",
    ORDER_CONFIRMATION_UPSELL_COMPONENT: "order_confirmation_upsell_component",
    EARLY_RETURN: "early_return",
    ORDER_TRACKING_LINK: "order_tracking_link",
    RETURN_LABEL_REQUEST: "return_label_request",
    ORDER_TRACKING_SKINNY_BANNER_LINK: "order_tracking_skinny_banner_link",
    STICKY_CTA: "sticky_cta",
    MIDGRID_COMPONENT: "midgrid_component",
  },
  ACTION_TYPE: {
    CLICK: "click",
    CLICK_CANCEL: "click_cancel",
    CLICK_ADD_SHIPMENT: "click_add_shipment",
    CLICK_DROPOFF_FINDER: "dropoff_click",
    CLICK_DIRECT_HANDOFF: "click_direct_handoff",
    CLICK_CONTACTLESS: "click_contactless",
    CLICK_TIER: "click_tier",
    CLICK_TRY_NOW: "click_try_now",
    CLICK_HEART: "click_heart",
    CLICK_MENU_ITEM: "click_menu_item",
    CLICK_UNHEART: "click_unheart",
    CLICK_REFERRAL_BANNER: "click_referral_banner",
    CLICK_RESERVE_CTA: "click_reserve_edu_cta",
    CLICK_SCHEDULE_PICKUP: "click_schedule_pickup",
    CLICK_SUBSCRIPTION_CTA: "click_subscription_edu_cta",
    CLICK_SWAP_TUTORIAL: "click_swap_tutorial",
    CLICK_TO_PLANS: "click_to_plans",
    CLICK_TO_SUBSCRIPTION: "click_to_subscription",
    CLICK_TO_RESERVE_INTENT: "click_to_reserve_intent",
    CLICK_TO_DATE_SELECT: "click_to_date_select",
    CONFIRM: "confirm",
    ERROR: "error",
    EXPERIMENT_APPLIED: "experiment_applied",
    LIST: "list",
    VISIBLE_PRODUCTS: "visible_products",
    EDIT_ADDRESS: "edit_address",
    DRAWER_OPEN: "drawer_open",
    DRAWER_PDP: "drawer_pdp",
    SELECT: "select",
    SHARE_AGAIN_CTA: "share_again_cta",
    START: "start",
    SWITCH_TO_RESERVE_LENS: "switch_to_reserve_lens",
    RESERVE_JUMP_TO_ATB: "reserve_jump_to_atb",
    VIEW: "view",
    CLICK_CTA: "click_cta",
    COPY_CODE: "copy_code",
    SCHEDULE_HOME_PICKUP_CLICK: "schedule_home_pickup_click",
    SCHEDULE_HOME_PICKUP_ERROR: "schedule_home_pickup_error",
    HOME_PICKUP_VISIBLE: "home_pickup_visible",
    NO_THANKS_HOME_PICKUP_CLICK: "no_thanks_home_pickup_click",
    NO_ACTIVE_HOME_PICKUP_ORDERS: "no_active_home_pickup_orders",
    HOME_PICKUP_ALREADY_SCHEDULED: "home_pickup_already_scheduled",
    HOME_PICKUP_NOT_AVAILABLE_MARKET: "home_pickup_not_available_market",
    HOME_PICKUP_SCHEDULER_EMAIL_LINK: "home_pickup_scheduler_email_link",
  },
  CATEGORY_NAME: {
    INVITE_A_FRIEND: "invite_a_friend",
    BONUS_ITEM: "bonus_item",
    EARLY_ACCESS: "early_access",
  },
  ACTION_LOCATIONS: {
    CHECKOUT: "checkout",
    SSE_BANNER: "SSE Banner",
    PDP: "pdp",
    MEMBERSHIP_SETTINGS: "membership_settings",
    CONTROL_CENTER: "control_center",
    CAROUSEL: "carousel",
    GRID: "grid",
    DRAWER: "drawer",
    POST_SHIP_MODAL: "post_ship_modal",
  },
  // Alan Chen 11/04/19 - Please use LOCATION instead of ACTION_LOCATIONS for Amplitude.
  // The string values for LOCATION are more accurate.
  LOCATION: {
    BAG: "Bag",
    CONTROL_CENTER: "Control Center",
    DRAWER: "Drawer",
    HOMEPAGE_CAROUSEL: "Homepage Carousel",
    ORDER_PAGE: "Order Page",
    PDP: "PDP",
  },
  ADD_ON_ACTIONS: {
    TOOLTIP: "tooltip",
    ADD_SLOTS: "add_slots",
  },
  HEARTS_ACTIONS: {
    ENGAGE_HEART: "engage_heart",
    ENGAGE_SHORTLIST_MODAL_0: "engage_shortlist_modal0",
    ENGAGE_SHORTLIST_MODAL_1: "engage_shortlist_modal1",
    ENGAGE_SHORTLIST_MODAL_2: "engage_shortlist_modal2",
    CANCEL: "cancel",
    CREATE: "create",
    CLOSE: "close",
    DONE: "done",
    VIEW_HEART_TOOLTIP: "view_heart_edu",
    HEARTED_ITEM: "hearted_item",
    MODAL_CLOSE: "modal_close",
  },
  ONBOARDING: {
    VIEWED_WELCOME: "viewed_onboarding_welcome",
    START: "started_onboarding",
    SUBMIT_BASICS: "submits_onboarding_basics",
    SUBMIT_SIZES: "submits_onboarding_size",
    SUBMIT_MATERNITY: "submits_onboarding_maternity",
    SUBMIT_HEIGHT: "submits_onboarding_height",
    SUBMIT_WEIGHT: "submits_onboarding_weight",
    CLOSE: "closes_onboarding_survey",
  },
  HAPPINESS_SURVEY: {
    STARTED_SURVEY: "Started Survey",
    COMPLETED_HAPPINESS_SURVEY: "Completed Happiness Survey",
  },
  MEMBERSHIP_BAG: {
    ADDED_TO_BAG: "Added to Bag",
    SELECTED_ADD_ON_TILE: "Selected Add-On Tile",
    SELECTED_REMOVE: "Selected Remove",
    PLACED_SUBSCRIPTION_ORDER: "Placed Subscription Order",
  },
  SWAP: {
    SELECTED_KEEP: "Selected Keep",
    SELECTED_SWAP_ALL: "Selected Swap All",
    SELECTED_SWAP_ITEM: "Selected Swap Item",
    SELECTED_START_SWAP: "Selected Start Swap",
    CLICK_SWAP_COUNTER: "click_swap_counter",
    CLICK_NEED_HELP: "click_need_help",
    RETURN_PROMISE: "mode",
  },
  PLAN_EDIT: {
    SHIPMENT_EDIT: "click_edit_shipments",
    SHIPMENT_CHOOSE: "click_new_shipment",
    SPOT_EDIT: "click_edit_spots ",
    SPOT_CHOOSE: "click_new_spot_count",
  },
  TRACKING: {
    CLICKED_INVOICE_TAB: "Clicked Invoice Tab",
    CLICKED_TRACKING_TAB: "Clicked Tracking Tab",
    EXPANDED_TRACKING: "Expanded Tracking",
    MINIMIZED_TRACKING: "Minimized Tracking",
    ORDER_DETAILS_TAB: "Order Details",
    TRACKING_DETAILS_TAB: "Tracking Details",
    TRACK_ORDER: "Track Order",
    VIEWED_AT_HOME_TILES_WITH_TRACKING_OR_VIEW_ORDER_LINK: "Viewed At Home Tiles With Tracking or View Order Link",
    VIEWED_INVOICE: "Viewed Invoice",
    VIEWED_ORDER_PAGE: "Viewed Order Page",
    EXCEPTION_VIEW: "Exceptions - Viewed Order Page",
    EXCEPTION_CLICK: "Exceptions - Clicked View Notice",
  },
  TRY_TO_BUY: {
    SELECTED_BUY_NOW: "Selected Buy Now",
    BOUGHT_AT_HOME_ITEM: "Bought At Home Item",
  },
  USER_ORDERS: {
    VIEWED_ORDER_HISTORY_PAGE: "Viewed Order History Page",
  },
};

export const facebookShareMethods = {
  SHARE: "share", // share via facebook post
  SEND: "send", // share via facebook messenger
};

export const ALL_HEARTS_SHORTLIST_ID = "ALL_HEARTS"; // This is the "shortlist id" we give to items in All Hearts, which is not technically a shortlist
export const googleAutocompleteFieldEnums = {
  streetNumber: "street_number",
  streetName: "route",
  postalCode: "postal_code",
  state: "administrative_area_level_1",
  locality: "locality",
  sublocality: "sublocality",
};
export const gridTypes = {
  clearance: "clearance",
  unlimited: "unlimited",
  rtrupdate: "rtrupdate",
  membership: "membership",
  classic: "classic",
  saleables: "saleables",
  newArrivals: "New Arrivals",
};
export const CCAS = {
  localStorageStoreKey: "orderInformation",
  localStorageSourceKey: "orderId",
};
export const hierarchyIds = {
  accessory: "accessory",
  kids: "kids",
  homegood: "homegood",
};

/**
 * sbenedict 6/6/2024
 * Do not use these, they are moment formats.
 * They remain here to support legacy tests that still rely on moment.
 * Moment is no longer in use in our application code.
 */
export const dateFormats = {
  YYYY_MM_DD: "YYYY-MM-DD", // 2018-07-06 – Used for dates in UserProfile, e.g. dueDate and weddingDate
  MM__DD__YYYY: "MM / DD / YYYY", // 07 / 06 / 2018 - the spaces are intentional and used for inputs
  MM_DD_YY: "MM/DD/YY", // 07/06/18
  MM_DD: "MM/DD", // 07/06
  M_D: "M/D", // 7/6
  M_DD: "M/DD", // 7/06
  MMMM_D: "MMMM D", // July 6
  MMM_D: "MMM D", // Jul 6
  dddd_MMMM_D_YYYY: "dddd MMMM D, YYYY", // Friday July 6, 2018
  MMMM_D_YYYY: "MMMM D, YYYY", // July 6, 2018
  MMM_D_YYYY: "MMM D, YYYY", // Jul 6, 2018 - used in express bag for plan change effective date
  MMM_D_YYYY_TIME12: "MMM D, YYYY H:mm:SS A", // Jul 6, 2018 1:23:45 PM - used in "moments" (photo reviews)
  MMMM_YYYY: "MMMM YYYY", // July 2018
  dddd: "dddd", // Friday
  ddd: "ddd", // Fri,
  ddd_MMM_D: "ddd, MMM D", // Fri, Jul 6
  dddd_MMM_D: "dddd, MMM D", // Friday, Jul 6
  dayNumber: "D", // 6
  day: "MMMM Do", // July 6th
  dayWithComma: "MMMM, Do", // July, 6th,
  month: "MMMM", // July
  shortMonth: "MMM",
  updateToCurrentYear: "____-MM-DD", // Good for birth date comparison
  // ************** BEWARE USING THE FORMAT BELOW ************** //
  // It will add a period after the month of May (e.g. May 6th)  //
  // Consider using formatTruncatedDate in date-helpers.js       //
  dayWithTruncatedMonth: "MMM. Do", // Jul. 6th
  dayShortenedWithTruncatedMonth: "MMM. D", // Jul. 6
  hourAndMinutes: "h:mm a",
  hourAndMinutesCondensed: "h:mma",
  hour: "h a",
  hourCondensed: "hA",
  hourMinSec: "HH:mm:ss",
  full: "YYYY-MM-DDTHH:mm:ss.SSSZ",
  localTime: "LT", // 8:00 AM, 11:00 AM, 1:00 PM (no leading zeros)
  timezones: {
    nyc: "America/New_York",
  },
};

/**
 * sbenedict 6/6/2024
 * Use these - we are fully on date-fns.
 * Formats for use with date-fns
 * These may or may not match the tokens used by moment.js
 */
export const dateFnsFormats = {
  MMMM: "MMMM", // July
  MMM_D: "MMM d", // Jul 6
  MMMM_D: "MMMM d", // July 6
  MM__DD__YYYY: "MM / dd / yyyy", // 07 / 06 / 2018 - the spaces are intentional and used for inputs
  MMMM_YYYY: "MMMM yyyy", // July 2018
  EEEE_MMMM_d_YYYY: "EEEE MMMM d, yyyy", // Thursday July 6th, 2022
  EEEE_MMM_d: "EEEE, MMM d", //Friday, Feb 6
  MMMM_D_YYYY: "MMMM d, yyyy", // July 6th, 2018
  MMMM_d_yyyy: "MMMM d, yyyy", // July 6, 2018
  MMM_D_YYYY: "MMM d, yyyy", // Jul 6, 2018 - used in express bag for plan change effective date
  YYYY_MM_DD: "yyyy-MM-dd", // 2018-07-06 – Used for dates in UserProfile, e.g. dueDate and weddingDate
  ddd_MMM_D: "E, MMM d", // Fri, Jul 6,
  dddd_MMMM_D_YYYY: "EEEE MMMM d, yyyy", //Friday July 6, 2018
  dddd_MMM_D: "EEEE MMM d",
  dayOfWeekShort: "eee", // Thu
  EEEE: "EEEE", // Thursday
  monthDayYearLong: "PPP", // April 29th, 1453
  monthDayShortYearLong: "PP", // Apr 29, 1453
  day: "MMMM do", // July 6th,
  day_MD: "EEE, M/d",
  hourCondensed: "ha", // 2PM,
  progressTrackerDateFormat: "h:mm aaa, EEEE MMM d", // 2:00 pm, Tuesday Jan 24
  hoursAndMinutes: "h:mm aaa", // 2:00 pm
  shortMonthAndDay: "MMM d", // Jan 24
  hour: "h aaa", // 2 pm
  MM_DD: "MM/dd", // 01/24,
  M_DD: "M/dd", // 1/24,
  M_D: "M/d", // 1/9,
  monthDay: "MMMM d", // January 24
  MMM_Do: "MMM do", // Jan 24th
  shortMonth: "MMM",
  shortMonthAndShortDayWithPeriod: "MMM. d", // Jan. 24
  shortMonthAndDayWithPeriod: "MMM. do", // Jan 24th
  MM_DD_YYYY: "MM/dd/yyyy", // 01/31/2023
  MM_DD_YY: "MM/dd/yy", // 01/31/23
  dayOfMonth: "d", // 24
  M_D_YY: "M/d/yy", // 1/9/23
  ISO: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
  timezones: {
    nyc: "America/New_York",
  },
};

/**
 * Semi-standard format we use within text inputs that require a date.
 */
export const DATE_INPUT_HINT_TEXT = "MM / DD / YYYY";
export const flagsAndExperimentsRequestor = "STOREFRONT";
export const flagsAndExperimentServerError = "FLAGS_AND_EXPERIMENTS_SERVER_ERROR";
export const featureFlags = {
  ADVOCATE_CUSTOM_LP_FLAG: "advocate_event_referral",
  BONUS_ITEM_TEST_2022: "bonus_item_test_2022",
  CLEARANCE_CAROUSEL_STOREFRONT: "clearance_carousel_storefront",
  FIRST_SWAP_TUTORIAL: "first_swap_tutorial_sf",
  FORMER_UNLIMITED_INSIDER: "former_unlimited_insider",
  BRANCH_IO_INTEGRATION: "branch_io_integration",
  BRANCH_APP_INTERSTITIAL: "branch_app_interstitial",
  EARLY_ACCESS_NEW_ARRIVALS: "insider_early_access_new_arrivals",
  EARLY_ACCESS_PDP: "insider_early_access_pdp",
  HEAP_ANALYTICS: "heap_analytics",
  INSIDER_BONUS_ITEM_MODAL: "insider_bonus_item_avail_modal",
  EMISSIONS_MSG: "emissions_message",
  ENROUTE_MODAL: "enroute_modal",
  FORMER_UNLIM_EARLY_ACCESS: "former_unlim_early_access",
  HP_CAROUSEL_LIMIT: "hp_carousel_limit_storefront",
  SMART_MEMBERSHIP_HOMEPAGE_HEADER_ENABLED: "smart_membership_homepage_header_enabled",
  SELF_SERVICE_EXCHANGE: "self_service_exchange",
  RESERVE_913_PROBLEM_ORDER_FLOW: "reserve-storefront-rsv-913-PO-order-flow-feature-flag",
  UNLIMITED_ALWAYS_ON_ADD_ONS: "unlimited_always_on_add_ons",
  GM_MEMBERSHIP_PRICING_DISCOUNT_40: "gm_membership_pricing_discount_40", // Godmother discount is hardcoded
  REFERRALS_GTM_MODAL: "sf_referrals_gtm_modal",
  RENTAL_REWIND_2021: "rental_rewind_2021",
  POST_SHIPMENT_HEART_PROMPT_MODAL: "post_shipment_heart_prompt_modal",
  PPS_REFERRALS: "pps_referrals", // Pay-per-swap subscriber referrals
  USER_DELETE_PAYMENT_METHODS: "user_delete_payment_methods",
  USER_DELETE_ADDRESS_METHODS: "user_delete_address_methods",
  VISUALLY_SIMILAR_TO_FLAG: "visually_similar_to_flag",
  KEEP_IT_FOREVER_RETURNS: "keep_it_forever_returns",
  CLASSIC_REFERRAL_FOR_ACTIVE_MEMBER: "classic_referral_for_active_member",
  CLASSIC_REFERRAL_FOR_INACTIVE_MEMBER: "classic_referral_for_inactive_member",
  RTR_STORE_PASS: "rtr_update_view_pass",
  PPS_REFERRAL_MESSAGING_STRATEGY: "pps_referral_messaging_strategy", // on-site messaging for pay-per-swap referrals
  FETCH_LOYALTY_AWARDS: "fetch_loyalty_awards",
  ONETRUST_CONSENT_MANAGEMENT: "onetrust_consent_management",
  CE_BAG: "ce_bag",
  CE_CHECKOUT: "ce_checkout",
  GOOGLE_OAUTH: "google_oauth",
  GOOGLE_ONE_TAP: "google_one_tap",
  DEFAULT_SHIPPING_ADDRESS: "default_shipping_address",
  HOME_PICKUP_AFTER_CHECKOUT: "home_pickup_after_checkout",
  PDP_HOW_WE_STYLED_IT: "pdp_how_we_styled_it",
  PDP_CAROUSEL_RTL_REORDER: "pdp_carousel_rtl_reorder",
  RESERVE_REFERRAL_NEW_UX: "reserve_referral_new_ux",
  SUPPRESS_SKINNY_BANNER_FOR_CHECKOUT: "suppress_skinny_banner_for_checkout",
  TATARI: "tatari",
  HIDE_SHARE_OPTIONS: "hide_share_options",
  HOME_PICKUP_MULTIPLE_DATES: "home_pickup_multiple_dates",
  IS_OPERATION_SWAP_COPY: "is_operation_swap_copy",
  ENABLE_RESERVE_SCHEDULER: "enable_reserve_scheduler",
  ENABLE_500_STATUS_ERROR_CODE: "enable_500_status_error_code", //temporary for qa testing
  IS_RETURN_LABEL_LINK_ACTIVE: "is_return_label_link_active", //temporary for qa testing
  PCI_MASQUERADE: "pci_masquerade",
  ENABLE_EPIC_OCCASIONS_FACET_INJECTION: "enable_epic_occasions_facet_injection",
  ENABLE_EPIC_STYLE_FACET_INJECTION: "enable_epic_style_facet_injection",
  ENABLE_EPIC_MATERNITY_FACET_INJECTION: "enable_epic_maternity_facet_injection",
  ENABLE_EPIC_COLOR_FACET_INJECTION: "enable_epic_color_facet_injection",
  ENABLE_EPIC_EMBELLISHMENT_FACET_INJECTION: "enable_epic_embellishment_facet_injection",
  ENABLE_EPIC_WEATHER_FACET_INJECTION: "enable_epic_weather_facet_injection",
  ENABLE_EPIC_PRINT_FACET_INJECTION: "enable_epic_print_facet_injection",
  ENABLE_EPIC_FORMALITY_FACET_INJECTION: "enable_epic_formality_facet_injection",
  ENABLE_EPIC_SLEEVE_FACET_INJECTION: "enable_epic_sleeve_facet_injection",
  ENABLE_EPIC_LENGTH_FACET_INJECTION: "enable_epic_length_facet_injection",
  ENABLE_EPIC_NECKLINE_FACET_INJECTION: "enable_epic_neckline_facet_injection",
  VALIDATE_SHIPPING_ADDRESS: "validate_shipping_address",
};
export const referralProgramTypes = {
  classic: "CLASSIC",
  altruisticUnlimited: "ALTRUISTIC_UNLIMITED",
  unlimitedReward: "UNLIMITED_REWARD",
  ppsStacking: "PPS_STACKING",
};
export const shareSuccessMessage = {
  default: "Successfully Shared!",
  publicLink: "Copied to Clipboard",
};
export const shareCreateStatuses = {
  ERROR: "error",
  PENDING: "pending",
  SUCCESS: "success",
};
export const shareTypes = {
  FACEBOOK: "facebook",
  FACEBOOK_MESSENGER: "facebookMsg",
  EMAIL: "email",
  EMAIL_LINK: "emailLink",
  PUBLIC_LINK: "publicLink",
  SMS: "sms",
};
export const prospectingProgramLabels = {
  RESERVE: "One-Time Rental",
  UNLIMITED: "Unlimited Rentals",
  RTR_UPDATE: "Monthly Shipment",
};
export const gridFilterProspectingProgramLabels = {
  RESERVE: "One-Time Rental",
  UNLIMITED: "Membership",
  RTR_UPDATE: "Membership",
};
export const middleTierProspectingProgramLabels = {
  RESERVE: "One-Time Rental",
  UNLIMITED: "2+ Swaps",
  RTR_UPDATE: "1 Swap",
};
export const membershipTierNames = {
  oneSwap: "1 Swap",
  twoSwaps: "2 Swaps",
  unlimitedSwaps: "Unlimited Swaps",
};
export const middleTierSubscriberProgramLabels = {
  // Another set of labels for Reserve after joining membership
  RESERVE: "One-Time Rentals",
  UNLIMITED: "Your Membership",
  RTR_UPDATE: "Your Membership",
};
export const loyalty = {
  types: { BONUS_ITEM: "BONUS_ITEM", BIRTHDAY: "BIRTHDAY", NEW_ARRIVALS: "NEW_ARRIVALS" },
  states: {
    EARNING: "EARNING",
    GRANTED: "GRANTED",
    REDEEMED: "REDEEMED",
    UNLOCKED: "UNLOCKED",
    USED: "USED",
    ALWAYS_ON: "ALWAYS_ON",
  },
};
export const discountCode = {
  unlimitedReserve: {
    code: "UNLRESERVE25",
    amount: "25%",
  },
  membershipPerk: "UPDATE25",
};
export const partialPropsStateKeys = {
  LENS_SWAP: "lens_swap",
  MOBILE_APPEND: "mobile_append",
  DESKTOP_APPEND: "desktop_append",
  SUBSCRIBER_UPGRADE_PRICING_TRUE: "subscriber_upgrade_pricing_true",
  SUBSCRIBER_UPGRADE_PRICING_FALSE: "subscriber_upgrade_pricing_false",
  ADD_TO_BAG_FAILURE: "add_to_bag_failure",
};
export const assetDomains = {
  RENTTHERUNWAY: "renttherunway",
  RTRCDN: "rtrcdn",
};
export const assetBaseUrls = {
  //  Todo: All IMAGES paths should be replaceable with the IMAGES_REWRITE path; see DR-809
  IMAGES: "https://cdn.rtrcdn.com/sites/default/files/imgs",
  IMAGES_REWRITE: "https://cdn.rtrcdn.com/files/images",
  ASSETS: "https://cdn.rtrcdn.com/sites/default/files/assets",
  DESIGNER_IMAGES: "https://cdn.rtrcdn.com/assets/imgs",
};
export const SizeScale = {
  ONE_SIZE_ID: "one_size_fits_all",
};
export const iframeActions = {
  PARENT_COMPONENT_PROPS_UPDATED: "parentComponentPropsUpdated",
  SAVE_PAYMENT_METHOD_USING_EPROTECT: "savePaymentMethodUsingEprotect",
  SAVE_APPLE_PAY_PAYMENT_TOKEN_USING_EPROTECT: "saveApplePayPaymentTokenUsingEprotect",
  ON_SAVE_APPLE_PAY_PAYMENT_METHOD_SUCCESS: "onApplePayMethodSuccess",
  ON_SAVE_APPLE_PAY_PAYMENT_METHOD_FAILURE: "onApplePayMethodFailure",
  LOG_ACTION: "logAction",
  ON_CHANGE_CALLBACK: "onChangeCallback",
  ON_BLUR_CALLBACK: "onBlurCallback",
  HookNames: {
    BEFORE_EPROTECT_TOKEN_GENERATION: "beforeEprotectTokenGeneration",
    ON_SAVE_PAYMENT_METHOD_SUCCESS: "onSavePaymentMethodSuccess",
    ON_SAVE_PAYMENT_METHOD_FAILURE: "onSavePaymentMethodFailure",
    ON_EPROTECT_ERROR: "onEprotectError",
    ON_EPROTECT_TIMEOUT: "onEprotectTimeout",
    ON_EPROTECT_NOT_LOADED_ERROR: "onEprotectNotLoadedError",
  },
};
export const UNKNOWN_USER_NAME = "RTR Member";
export const HS_REVIEW_LEGAL_DISCLAIMER =
  "Fit Feedback\nMembers complete a questionnaire to rate the item’s overall fit";
export const iframeRemoteKeys = {
  PARENT: "parent",
  SECURE_CREDIT_CARD: "secureCreditCard",
  SECURE_APPLE_PAY: "secureApplePay",
};
export const segments = {
  inRtrUpdate: "inRtrUpdate",
  inCustomer: "inCustomer",
  inNonCustomer: "inNonCustomer",
};
export const sizeFilterLabels = {
  kids: "What size does your kid (or kids) wear? Sizes are in years",
  inMaternity: "We recommend renting maternity styles in your usual size and other styles 1-2 sizes up.",
  default: "Select your usual sizes",
};
export const validCcExpRegex = /^\d{1,2} ?\/ ?\d{2}(?:\d{2})?$/;
export const productCategoryToSizePlaceHolderMapper = {
  kids_apparel: "Size (Years)",
};
export const BLOCK_PICK_GRACE_PERIOD_LENGTH = 3;
export const DEPOT_MAP = {
  12: "Washington, DC",
  13: "Secaucus, NJ",
  15: "Chicago, IL",
  26: "Topanga, CA",
  28: "San Francisco, CA",
  29: "NYC, NY",
  52: "Arlington, TX",
};
export const DEFAULT_DEPOT_ID = 52;

export const termNumToInferUserHasSwapped = 2;
export const unifiedMembershipName = "RTR Unlimited";
export const paidPromotions = {
  URL_PARAMETER_PAID_PROMOTION: "cpn",
  URL_PARAMETER_PAID_PROMOTION_DISCOUNT: "dsc",
  URL_PAID_PROMOTION_REFERRAL_PROGRAM: "paidPromo",
  DEFAULT_PAID_PROMO_TEXT: "Limited time: Try RTR now and save big with code ",
  PAID_STORAGE_PARAM_NAME: "smartComponents",
  LEGAL_DISCLAIMER: ". Terms Apply.",
};

export const membershipFocusedFlows = {
  URL_PARAMETER_MEMBERSHIP_FLOW: "pdp_focused_flow",
  PDP_STORAGE_PARAM_NAME: "pdpDisplayParam",
};

export const aspireInfluencerTransactionData = {
  ASPIRE_TRANSACTION_ID: "aspireTransactionId",
  TRANSACTION_ID: "transaction_id",
};

export const ThisWasntMeViews = {
  MAIN: "main",
  SUCCESS: "success",
  NEVERMIND: "nevermind",
};

export const loggingPageTypes = {
  HOME: "rtr_home",
  GRID: "grid",
  PDP: "node",
};

export const ReturnLabelStatusTypes = {
  LOADING: "loading",
  SUCCESS: "success",
  REPEATED_REQUEST: "repeated_request",
  ERROR: "error",
};

export const CEBagTypes = {
  MEMBERS: "MEMBERS",
  MIXED: "MIXED",
};

export const CEBagGroupTypes = {
  SUBSCRIPTION: "SUBSCRIPTION",
  PURCHASES: "PURCHASES",
  RENTALS: "RENTALS",
};

export const CEBagItemTypes = {
  RESERVE: "ReserveBagItem",
  SUBSCRIPTION: "SubscriptionBagItem",
  KIF_FROM_RACK: "ElectiveSaleBagItem",
  CLEARANCE: "ClearanceBagItem",
};

// the item types made in requests are different from the item types in responses
export const CEBagItemTypesWhenAdding = {
  RESERVE: "reserve",
  KIFFR: "electiveSale",
  CLEARANCE: "clearance",
  SUBSCRIPTION: "subscription",
};

export const CEBagErrors = {
  "BAG_GENERIC_ERROR": "There was a problem loading your bag. Please refresh and try again.",
  "PROMO_CODE_APPLY_GENERIC_ERROR": promoCode => `An error occurred applying ${promoCode}.`,
  "PROMO_CODE_REMOVAL_GENERIC_ERROR": "An error occurred removing the promo code.",
  "ATB_GENERIC_ERROR": "There was a problem adding this item to your bag. Please refresh and try again.",
  "UNAVAILABLE": "We're sorry, but this item is no longer available.",
  "elective-sale-item-not-allowed-in-reserve-bag": "Please checkout with your rental before purchasing this item.",
  "reserve-item-not-allowed-in-elective-sale-bag": "Please checkout with your purchase before reserving this item.",
  "reserve-item-not-allowed-in-kif-bag": "Please checkout with your purchase before reserving this item.",
};

export const UrlSignifiers = {
  CAROUSEL: "/carousel",
  ACCESSORY_ONLY: {
    ACCESSORY: "/accessory",
    ACCESSORIES: "/accessories",
    SINGLE_CATEGORY: {
      EPIC: "/browse/womens-accessories",
      JEWELRY: "/jewelry",
      HANDBAG: "/handbag",
      SUNGLASSES: "/sunglasses",
    },
  },
  CLOTHING_ONLY: {
    CLOTHING: "/clothing",
    SINGLE_CATEGORY: {
      EPIC: "/browse/womens-apparel",
      DRESSES: "/dresses",
      TOP: "/top",
      KNIT: "/knit",
      JACKET_COAT: "/jacket_coat",
      BOTTOM: "/bottom",
      SKIRT: "/skirt",
      GOWNS: "/gowns",
      JUMPSUIT_ROMPER: "/jumpsuit_romper",
      ACTIVEWEAR: "/activewear",
      MATERNITY_CLOTHING: "/maternity_clothing",
    },
  },
  OMIT: "omit",
};

export const colorFilterMapping = {
  offwhite: "cream",
  clear: "crystal_clear",
  rosegold: "rose_gold",
};

export const CECheckout = {
  CheckoutStatuses: {
    FAILED_ITEM_REFRESH: "failed-item-refresh",
    FAILED_PROMO_APPLICATION: "failed-promo-application",
    FIRST_TERM_SLOT_LIMIT_EXCEEDED: "first-term-slot-limit-exceeded",
    MINIMUM_SLOTS_NOT_FILLED: "minimum-slots-not-filled",
    NO_ITEMS: "no-items",
    NO_PAYMENT_METHOD: "no-payment-method",
    NO_SHIPPING_ADDRESS: "no-shipping-address",
    PARTIAL_SHIPPING_ADDRESS: "partial-shipping-address",
    SLOT_LIMIT_EXCEEDED: "slot-limit-exceeded",
    VALIDATED: "validated",
  },
  LocalStorageNameSpace: "CheckoutAnalyticsData",
  errors: {
    "expired-credit-card": "The credit card provided has expired. Please update your payment information.",
    "failed-item-refresh": "Something's not right. Please refresh and try again.",
    "first-term-slot-limit-exceeded":
      "Sorry! You can't add more than 4 extra spots just yet. Please contact us if you have any questions.",
    "generic": "Something went wrong. Please refresh and try again.",
    "invalid-billing-address":
      "We could not validate your billing address. Please check your billing address and try again.",
    "invalid-shipping-address":
      "We could not validate your shipping address. Please check your shipping address and try again.",
    "minimum-slots-not-filled": "All available slots have not been filled. Please add items and try again.",
    "no-items": "There are no items in your order. Please add items and return to place your order.",
    "no-payment-method": "Something went wrong placing your order. Please try again.",
    "no-shipping-address": "A valid shipping address is not available. Please enter and try again.",
    "orderSubmitGeneric": "There was a problem placing your order. Please refresh and try again.",
    "payment-failed": "Your card didn't go through.",
    "slot-limit-exceeded":
      "The maximum amount of items allowed has been exceeded. Please remove some items and try again.",
    "limited-acc-completion-timeout":
      "It looks like your session has expired. Please check your email to finish setting up your account.",
    "invalid-request": "Missing required information. Please refresh and try again.",
    "gift-card-insufficient-balance": "Insufficient balance. Please use another card.",
    "gift-card-pin-required": "Please enter your gift card pin.",
    "gift-card-pin-malformed": "Invalid pin number. Please enter another pin.",
    "gift-card-not-found-or-wrong-pin": "Invalid pin number. Please enter another pin.",
    "gift-card-not-found": "Gift card not found. Please try a different card.",
  },
  errorCodes: {
    LIMITED_ACCOUNT_COMPLETION_TIMEOUT: "limited-acc-completion-timeout",
    INVALID_REQUEST: "invalid-request",
  },
  errorCodeToCopy: errorCode => {
    if (!errorCode || typeof errorCode !== "string") {
      return CECheckout.errors.generic;
    }

    // if the error code mapping exists, return it
    if (CECheckout.errors?.[errorCode]) {
      return CECheckout.errors[errorCode];
    }

    // the passed error might be constant case, convert it to kebab case and then see if an error matches.
    // and if not, just return the generic error. this can be removed once CE-1455 is done
    const errorCodeKebabCase = errorCode?.replace(/_/g, "-").toLowerCase();
    return CECheckout.errors?.[errorCodeKebabCase] || CECheckout.errors.generic;
  },
};

export const CECheckoutTypes = {
  STANDARD: "STANDARD",
  KIFAH: "KIFAH",
  MEMBERS: "MEMBERS",
};

export const KIFAHTriggerTypes = {
  THIRTY_DAYS: "THIRTY_DAYS",
};

export const promoCodeErrorToCopy = (errorCode, promoCode) => {
  const verifyCodePrompt = "Please verify the code and try again.";
  const verifyTermsPrompt = "Please verify the terms and try again.";

  const map = {
    "promo-not-found": `We are unable to find a promotion matching ${promoCode}. ${verifyCodePrompt}`,
    "promo-not-yet-active": `The code ${promoCode} is not yet active. ${verifyCodePrompt}`,
    "promo-inactive": `The code ${promoCode} is no longer active.`,
    "promo-expired": `The code ${promoCode} has ended.`,
    "promo-no-items-match": `The items in your order do not qualify for ${promoCode}.`,
    "promo-min-item-not-met": `Please add more items to your order to qualify for ${promoCode}.`,
    "promo-min-subtotal-not-met": `Your subtotal must meet the minimum value to qualify for ${promoCode}. ${verifyTermsPrompt}`,
    "promo-max-uses-exceeded": `You have exceeded the maximum number of redemptions for ${promoCode}.`,
    "promo-not-pro-member": `${promoCode} is valid for PRO users only. ${verifyTermsPrompt}`,
    "promo-not-rtrupdate-member": `${promoCode} is valid for RTR Update users only. ${verifyTermsPrompt}`,
    "promo-not-unlimited-member": `${promoCode} is valid for RTR Unlimited users only. ${verifyTermsPrompt}`,
    "promo-invalid-user": `Your order does not qualify for ${promoCode}.`,
    "promo-invalid-email-domain": `Your order does not qualify for ${promoCode}.`,
    "promo-count-not-equal": `Your order does not qualify for ${promoCode}.`,
    "promo-minimum-not-met": `Your subtotal must meet the minimum value to qualify for ${promoCode}. ${verifyTermsPrompt}`,
    "promo-not-equals": `Your order does not qualify for ${promoCode}.`,
    "no-discount-applied": `Your order does not qualify for ${promoCode}.`,
    "promo-unknown-error": `An error occurred applying ${promoCode}. ${verifyCodePrompt}`,
  };

  return map[errorCode] || map["promo-unknown-error"];
};

export const SemanticSearchBarPlaceholderOptions = ["cozy sweaters", "fancy fall wedding", "refined workwear"];

/**
 * Intent and pdp types for Split the Business work.
 */
export const RENTAL_INTENT = {
  MEMBERSHIP: "MEMBERSHIP",
  NO_INTENT: "NO_INTENT",
  RESERVE: "RESERVE",
};

export const PDP_TYPE = {
  MEMBERSHIP_PDP: "membership_pdp",
  RESERVE_PDP: "reserve_pdp",
  UNKNOWN_PDP: "unknown_pdp",
  MEMBERSHIP_PDP_STICKY_CTA: "membership_pdp_sticky_cta",
  RESERVE_PDP_STICKY_CTA: "reserve_pdp_sticky_cta",
  UNKNOWN_PDP_STICKY_CTA: "unknown_pdp_sticky_cta",
};

export const HIDDEN_FILTERS = {
  designer: ["designer"],
  category: ["epicCategories"],
  curation: ["curation"],
};

export const AccessoryCategoryIds = [
  "ring",
  "crossbody_bag",
  "hat",
  "tote",
  "cuff",
  "jewelry",
  "sunglasses",
  "belt",
  "veil",
  "bucket_bag",
  "comb",
  "satchel",
  "clutch",
  "pierced",
  "minaudiere",
  "bridal_accessory",
  "hand_chain",
  "lariat",
  "pendant",
  "hair_piece",
  "earring",
  "mini_bag",
  "shoulder_bag",
  "bracelet",
  "necklace",
  "soft_bracelet",
  "handbag",
  "bangle",
  "watch",
  "backpack",
  "bib",
  "accessory",
  "clip_on",
  "collar",
];

export const AccessoryRootCategoryIdDisco = "WomensAccessories";

export const FitSummaryLabel = {
  "Small": "Runs Small",
  "True to Size": "True to Size",
  "Large": "Runs Large",
};

export const APPLE_PAY_STRING = "Apple Pay";

export const ApplePayConstants = {
  PaymentStatus: { SUCCESS: 0, FAILURE: 1, CANCEL: 2 },
  APPLE_PAY_MERCHANT_PRELIVE: "merchant.com.renttherunway.prelive",
  APPLE_PAY_MERCHANT_PROD: "merchant.com.renttherunway.prod",
  APPLE_PAY_ERRORS: {
    BILLING_CONTACT_INVALID: "billingContactInvalid",
    SHIPPING_CONTACT_INVALID: "shippingContactInvalid",
    UNKNOWN: "unknown",
  },
  APPLE_PAY_ERROR_FIELDS: {
    POSTAL_ADDRESS: "postalAddress",
  },
  supportedNetworks: ["visa", "masterCard", "amex", "discover"],
  merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
  merchantDisplay: "Rent The Runway",
  applePayInitiative: "web",
  countryCode: "US",
  validCountryCode: "US",
  VERSION_NUMBER: 3,
};

/**
 * Roughly how many components on the page do we expect to show above the fold.
 * This excludes components with minimal height like spacers.
 */
export const ContainerPageAboveTheFoldContentModuleCount = {
  DESKTOP: 4,
  MOBILE: 3,
};

export const FetchPriority = {
  AUTO: "auto",
  HIGH: "high",
  LOW: "low",
};

export const NOTIFY_ME_MODAL_VIEWS = {
  FORM: "form",
  SUCCESS: "success",
};

export const NOTIFY_ME_SNACK_BAR = {
  ERROR: "Something went wrong. Please try again.",
  UPDATE: "Your changes have been saved.",
  TURN_OFF: "You will no longer be notified for this item.",
};

export const StandardCheckoutConstants = {
  BILLING_ADDRESS_SAME_AS_SHIPPING: "billing-address-same-as-shipping",
  BILLING_ADDRESS_USE_EXISTING: "billing-address-use-existing",
  LEAVING_CHECKOUT_MODAL_NAME: "LEAVING_CHECKOUT_MODAL",
  OPEN_BAG_TO_QUERY_PARAM: "openBagTo",
  OPEN_BAG_TO_MIXED: "mixed",
  LOCALSTORAGE_CONTEXT: "standardCheckout",
  RETURN_TO_CHECKOUT_LOCALSTORAGE_KEY: "returnToCheckout",
  SHIP_TO_CURRENT_ADDRESS: "ship-to-current",
  SHIP_TO_EXISTING_ADDRESS_ID: "ship-to-existing-address-id",
  SHIP_TO_NEW_ADDRESS: "ship-to-new-address",
  SHIP_WITH_APPLE_PAY: "ship-with-apple-pay",
  FINISH_LIMITED_ACCOUNT: "finish-limited-account",
  FINISH_LIMITED_ACCOUNT_PROMPT:
    "We've sent a verification link to your email. Finish your account setup to complete checkout.",
};

export const DiscoSortOptionsMap = {
  priceAsc: "LOWEST_PRICE",
  priceDesc: "HIGHEST_PRICE",
  newest: "NEWEST",
};

export const FilterMap = {
  epicUseCaseEvents: "useCaseEvent",
  epicCategories: "category",
  epicEmbellishments: "embellishment",
  epicPrint: "print",
  epicSleeve: "sleeve",
  epicMaternity: "maternity",
  epicNeckline: "neckline",
  colors: "color",
  formalityscore: "formality",
  epicSeasonality: "seasonality",
  epicLength: "length",
  epicStylePersonas: "stylePersona",
};

export const DelayedAccountCreation = {
  CHECKOUT_URL: "/ce-checkout/membershipTierId",
  ANON_CHECKOUT_COOKIE_NAME: "anon_checkout",
  USER_ALREADY_SUBSCRIBER_ERROR_CODE: "user-already-subscriber",
};

export const ACCOUNT_TYPES = {
  LIMITED: "LIMITED",
  FULL: "FULL",
};

export const DEFAULT_GRID_FOOTER_ROUTE = "/default_grid_footer_content";

export const ASSORTMENTS = {
  WOMENS_APPAREL: "WomensApparel",
  WOMENS_ACCESSORIES: "WomensAccessories",
};

export const ShoppableImages = {
  modalName: "SHOPPABLE_IMAGES_MODAL",
};

export const RoktFlagStates = {
  DISABLED: "disabled",
  SANDBOX: "sandbox",
  ENABLED: "enabled",
};

export const UserCredits = {
  modalName: "USER_CREDITS_MODAL",
};

export const ONE_TIME_MODALS = {
  CUSTOMER_PROMISE_MODAL: "CUSTOMER_PROMISE_MODAL",
};

export const MEMBERSHIP_QUESTIONS = {
  IS_CUSTOMER_PROMISE_ELIGIBLE: "IS_CUSTOMER_PROMISE_ELIGIBLE",
};

const Constants = {
  AccessoryCategoryIds,
  ACTION_LOGGER,
  ALL_HEARTS_SHORTLIST_ID,
  allMaternityFilters,
  analytics,
  Annotations,
  ApplePayConstants,
  assetBaseUrls,
  assetDomains,
  AuthState: AUTH_STATES,
  availabilityFilter,
  billingStatuses,
  BLOCK_PICK_GRACE_PERIOD_LENGTH,
  breakpoints: mediaQueries.breakpoints,
  cancelReasons,
  CanonicalSizeValues,
  CarouselTypes,
  CartItemStatus,
  CCAS,
  CEBagErrors,
  CEBagTypes,
  CECheckout,
  ChangeAddressModalNames,
  ChangePaymentMethodModalNames,
  checkout,
  clientSideErrorMessages,
  CMS_PRICE_FILLER_KEY,
  CMSHedTypes,
  cmsPromoBanners,
  colorFilterMapping,
  contact,
  COOKIES,
  costTypes,
  courierTime,
  creditCardTypes,
  ctaPauseStates,
  ctaText,
  EVENTS_CUSTOM_CASTLE,
  EVENTS_CUSTOM_DOM,
  dateFnsFormats,
  dateFormats,
  defaultSameDayZip,
  DelayedAccountCreation,
  DEPOT_MAP,
  DeviceTypes,
  discountCode,
  discounts,
  discountTypeCodes,
  DiscoSortOptionsMap,
  emailCaptureModalCMSContent,
  emergencyBanner,
  EprotectErrors,
  eventTypeId,
  exchangeBasePrice,
  facebookShareMethods,
  fallbackZip,
  featureFlags,
  FetchPriority,
  filters,
  FORCE_PICKUP_SCHEDULER_PARAM,
  formAutoCompleteCategories,
  formAutoCompleteSettings,
  formElementNames,
  formLabels,
  getAppleDeviceType,
  GodmotherErrorMap,
  googleAutocompleteFieldEnums,
  gridFilterProspectingProgramLabels,
  gridTypes,
  HappinessSurveyTypes,
  happinessSurveyValues,
  hierarchyIds,
  HOME_PICKUP_METHOD_KEY,
  iframeActions,
  iframeRemoteKeys,
  immediateUpgradeMessages,
  insiderPerksList,
  inventoryEligibilities,
  invoiceOrderTypesToMembershipNames,
  KidsCanonicalSizeYearValues,
  loggingPageTypes,
  loyalty,
  madlibsOptions,
  mediaQueries: mediaQueries.mediaQueries,
  MEMBERSHIP_TYPES_TO_TRIGGERED_MODALS_PATH,
  membershipActions,
  membershipBaseSlots,
  membershipFocusedFlows,
  membershipHappinessSurveyItemReturned,
  membershipHappinessSurveyItemReturnedPromiseSource,
  membershipItemStates,
  membershipLens,
  membershipLogoDisplayByMembershipType,
  membershipModalsCMSPathsByMembershipType,
  membershipNames,
  membershipNamesEligibleFor,
  membershipOpenSlotTypes,
  membershipOrderException,
  membershipPlanChangeRules,
  membershipSlotTypes,
  membershipStatuses,
  membershipStyleNames,
  membershipTierNames,
  membershipTriggeredModalsCMSKeys,
  membershipTypes,
  membershipTypesToNames,
  membershipTypeToLensPathMapper,
  membershipTypeToOrderTypeCopyMapper,
  merchandiseCategories,
  middleTierProspectingProgramLabels,
  middleTierSubscriberProgramLabels,
  MODAL,
  nonAttributeFilterKeys,
  ONETRUST_CONSENT_MANAGEMENT,
  orderModificationRequestTypes,
  orderModificationTypes,
  orderTracking,
  orderType,
  pageNames,
  pageTypes,
  paidPromotions,
  partialPropsStateKeys,
  paymentProfileMessages,
  POPOVER,
  proBirthdayCredit,
  productCategoryToSizePlaceHolderMapper,
  promoCodeErrorToCopy,
  proPrice,
  prospectingProgramLabels,
  proSummerDiscount,
  QUERY_PARAMS,
  referralPPSAwardStates,
  referralProgramTypes,
  RENTAL_INTENT,
  reserveExchangeSource,
  reserveExchangeVersion,
  ResumeFromPauseInvoiceIncludes,
  returnPolicyModalCMSPath,
  RoktFlagStates,
  ScreenMinWidths,
  SecondStylePrice,
  segments,
  SemanticSearchBarPlaceholderOptions,
  shareCreateStatuses,
  shareSuccessMessage,
  shareTypes,
  shipmentActions,
  ShoppableImages,
  showroomIds,
  sizeFilterLabels,
  SizeScale,
  slotsFromRewardTypes,
  smartTopRightTextColors,
  snackBarMessages,
  sortKeys,
  nextGridSortKeys,
  nextGridSortKeysMembership,
  specialPrices,
  specialSkus,
  StandardCheckoutConstants,
  superbarFilterKeys,
  termNumToInferUserHasSwapped,
  TOOLTIP,
  TRIGGERED_MODALS_KEY,
  unifiedMembershipName,
  unlimitedAnnotation,
  URL_DOMAIN_ALLOW_LIST,
  URL_ORIGIN_ALLOW_LIST,
  URL_PROTOCOL_ALLOW_LIST,
  URL_PROTOCOLS,
  UrlSignifiers,
  usStateAbbreviations,
  validations,
  validationStates,
  validCcExpRegex,
  FilterMap,
  ASSORTMENTS,
  NEXT_GRID_TYPES,
  ONE_TIME_MODALS,
  MEMBERSHIP_QUESTIONS,
};

export { authFormConstants } from "./constants/auth";

export default Constants;
