import $ from "jquery";
// by using universal-cookie, you can expect that this code will only work
// client-side. to make it work server-side, have your component pass the
// cookies object provided to it by the `withCookies` HOC.
import Cookies from "universal-cookie";

const BRANCH_BANNER_FIXED = "fixed";
const BRANCH_BANNER_ABSOLUTE = "absolute";

const BRANCH_BANNER_ID = "#branch-banner-iframe";

const PAGEVIEW_CACHE_KEY = "pagesViewed";
const cookies = new Cookies();

function getPagesViewed() {
  const pagesViewed = cookies.get(PAGEVIEW_CACHE_KEY);
  if (!pagesViewed) {
    return 0;
  }
  return parseInt(pagesViewed, 10);
}
/*
    Problem: There are two #branch-banner-iframes with different heights. One is fixed and one is not.
    (NOTE: The body has a class but it doesn't distinguish between the two banners.)

    These helpers will hopefully be useful in determining actions for the various sticky / fixed / absolute
    things that we have on our site.
*/

const branchBannerHelpers = {
  hasAnyBranchBanner: function () {
    $(BRANCH_BANNER_ID).length > 0;
  },

  hasFixedBranchBanner: function () {
    return this.getBranchBannerType() === BRANCH_BANNER_FIXED;
  },

  hasAbsoluteBranchBanner: function () {
    return this.getBranchBannerType() === BRANCH_BANNER_ABSOLUTE;
  },

  getBranchBannerType: function () {
    return $(BRANCH_BANNER_ID).css("position");
  },

  getBranchBannerHeight: function () {
    const height = $(BRANCH_BANNER_ID).height();

    if (height) {
      return height;
    }

    return 0;
  },

  incrementPagesViewed() {
    cookies.set(PAGEVIEW_CACHE_KEY, getPagesViewed() + 1, { path: "/" });
  },
};

export default branchBannerHelpers;

export const {
  hasAnyBranchBanner,
  hasFixedBranchBanner,
  hasAbsoluteBranchBanner,
  getBranchBannerType,
  getBranchBannerHeight,
  incrementPagesViewed,
} = branchBannerHelpers;
