/**
 * Helper function to validate a value passes a Zod defined schema. Returns true when the passed in value matches the
 * schema definition.
 * @param {*} value Value to be tested
 * @param schema Zod defined schema
 * @returns {boolean}
 * @see {https://zod.dev/?id=parseasync}
 */
export function isValid(value, schema) {
  try {
    schema.parse(value);
    return true;
  } catch (e) {
    if (process.env.NODE_ENV !== "test") {
      console.error("Schema validation failed", e);
    }
    return false;
  }
}
