import { ThirdPartyScript } from "./thirdPartyScript";

const usablenet = () => {
  return (
    <ThirdPartyScript
      id="usablenet-script"
      strategy="afterInteractive"
      src="https://a40.usablenet.com/pt/c/renttherunway/cs-start?color=dark&size=default&position=bottom-left&breakpoint=600&mobile-color=dark&mobile-position=bottom-right&mobile-size=small"
    />
  );
};

export default usablenet;
