import { isSSR } from "./client-server-helper";
import { coerceStringToValue, queryStringDecode } from "./query-string-decoder-helper";
import { QUERY_PARAMS, URL_DOMAIN_ALLOW_LIST, URL_PROTOCOL_ALLOW_LIST, URL_PROTOCOLS } from "rtr-constants";

// This module was made primarily to give us the ability to mock out calls to window.location in our tests
// in Storefront.Next do not use these! useRouter() is your friend!
export const getLocationHref = () => (!isSSR() && window.location?.href) || "";
export const getLocationSearch = () => (!isSSR() && window.location?.search) || "";
export const getLocationPathname = () => (!isSSR() && window.location?.pathname) || "";
export const isSameOrigin = path => Boolean(path) && path.startsWith("/") && !path.startsWith("//");

/**
 * Function to change current location with additional url sanitization. See RedirectHelpers and AuthHelper for
 * additional server-side equivalent.
 * @param {string} destination Relative url, to which to redirect
 * @throws {TypeError} Throws a TypeError if the destination is in an incorrect format or does not pass validation
 */
export const navigateTo = destination => {
  // This will throw a TypeError if the destination is in a format that can't construct a proper URL
  const urlInstance = new URL(destination, window.location.origin);
  const url = urlInstance.toString();

  if (!URL_PROTOCOL_ALLOW_LIST.includes(urlInstance.protocol)) {
    throw new TypeError(`Destination url does not contain allowed protocol url=${url}`);
  }
  // Prevent open redirects to untrusted origins (i.e. https://foo.bar and reflected XSS attacks attempting execution
  // via url (i.e. ?destination=javascript:alert(...) or ?destination=data://...). We first have to check that the
  // protocol is type http or https so other protocols like mailto and tel don't throw for this scenario.
  if (
    [URL_PROTOCOLS.HTTP, URL_PROTOCOLS.HTTPS].includes(urlInstance.protocol) &&
    urlInstance.origin !== window.location.origin &&
    !URL_DOMAIN_ALLOW_LIST.includes(urlInstance.hostname)
  ) {
    throw new TypeError(`Destination url does not contain allowed hostname url=${url}`);
  }

  window.location.assign(url);
};

/**
 * Function to merge existing query params with new query params
 * @param {URLSearchParams} baseParams URLSearchParams instance with base query params
 * @param {URLSearchParams} newParams URLSearchParams instance with new query params to add to the current params
 * @returns {URLSearchParams} URLSearchParams instance of baseParams merged with newParams
 */
export function mergeQueryParams(baseParams = new URLSearchParams(), newParams = new URLSearchParams()) {
  if (!(baseParams instanceof URLSearchParams)) {
    console.warn("baseParams is not an instance of URLSearchParams");

    if (newParams instanceof URLSearchParams) return newParams;

    return new URLSearchParams();
  }

  if (!(newParams instanceof URLSearchParams)) {
    console.warn("newParams is not an instance of URLSearchParams");
    return baseParams;
  }

  // Combine existing search params with new params i.e. adding login=true to /?foo=bar => /?foo=bar&login=true
  // @see {https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams#parameters}
  for (const [key, val] of newParams) {
    baseParams.append(key, val);
  }

  return baseParams;
}

export function addQueryParam(path, param) {
  if (!path) {
    return;
  }

  if (!param) {
    return path;
  }

  const isQuestionMarkPresent = path.indexOf("?") !== -1;

  const joiner = isQuestionMarkPresent ? "&" : "?";

  return `${path}${joiner}${param}`;
}

export function addCacheBusterToUrl(path, cacheBusterValue = "1") {
  // Return undefined if no path is provided
  if (!path) {
    return;
  }

  // Ensure the URL is fully qualified, using a placeholder for relative paths
  const urlObject = new URL(path, "http://placeholder.com");

  // Set or update the cache buster query parameter
  urlObject.searchParams.set(QUERY_PARAMS.CACHE_BUSTER, cacheBusterValue);

  // Return the URL as a string, removing the base URL placeholder if used
  const updatedUrl = urlObject.toString();
  return updatedUrl.replace("http://placeholder.com", "");
}

export function getFragment() {
  const hash = !isSSR() && window.location?.hash?.substring(1);
  return hash ? queryStringDecode(decodeURIComponent(hash), true) : {};
}

function readQueryParam(url, paramName) {
  return coerceStringToValue(url.searchParams.get(paramName));
}

// basic read
export function getQueryParam(paramName) {
  return !isSSR() && readQueryParam(new URL(window.location?.href || document?.URL), paramName);
}

export function getQueryParamFromUrl(url, paramName) {
  let urlObj;
  if (typeof url === "string") {
    // convert the string to a URL object
    if (url.startsWith("http")) {
      urlObj = new URL(url);
    } else if (!isSSR()) {
      // if in the browser, use the current location to supplement the relative URL
      urlObj = new URL(url, window.location);
    } else {
      // relative URL in SSR, no choice but to null as URL requires an absolute
      return null;
    }
  } else {
    urlObj = url;
  }
  return readQueryParam(urlObj, paramName);
}

// get all read
export function getAllQueryParams(url) {
  const search = url?.search?.substring(1);
  return search ? queryStringDecode(decodeURIComponent(search), true) : {};
}

// surely there is a better solution than rolling your own
export function extractQueryObjectFromUrl(relativeOrAbsoluteUrl) {
  // we don't care about the host, so the default is fine
  const url = new URL(relativeOrAbsoluteUrl, "http://www.renttherunway.com");
  const pattern = /^([^[]+)\[(.+)\]/;
  return [...url.searchParams]
    .map(([k, v]) => [pattern.exec(k), k, v])
    .reduce((memo, [reTokens, originalKey, value]) => {
      if (!reTokens) {
        memo[originalKey] = value;
      } else {
        const [, key, nestedKey] = reTokens;
        if (!(key in memo)) memo[key] = {};

        memo[key][nestedKey] = value;
      }

      return memo;
    }, {});
}
