import $ from "clients/RawClient";
import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";
import { bagTabs, switchBagTab, toggleBag } from "actions/bag-actions";
import membershipBagActions from "actions/membership-bag-actions";
import ceCheckoutActions from "actions/ce-checkout-actions";
import * as membershipStateActions from "actions/membership-state-actions";
import { skuInfo } from "helpers/sku-helpers";
import ActionLogger from "action-logger";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";
import { loadPaymentProfiles } from "actions/payment-method-actions";
import { condenseFetchPersonalizedCarousels } from "helpers/carousel-helper";
import MembershipHelpers from "helpers/membership-helpers";
import { isCanceled } from "helpers/membership-plan-helpers";
import HeapHelpers from "helpers/heap-helpers";
import { fireCEHeapEvent } from "helpers/heap-ce-helpers";
import { AccessoryCategoryIds, analytics, CEBagErrors, CECheckout, EVENTS_CUSTOM_CASTLE } from "rtr-constants";
import * as castleHelper from "../helpers/castle-helper";
import ReservationsActions from "actions/reservations-actions";
import { logReservationAction } from "helpers/reservation-pixel-helper";
import { getUserId } from "components/source/hoc/with-user-data";
import { sendSailthruAddToCartEvent, configureSailthru } from "helpers/third-party/sailthru-helpers";
import GTMHelper from "helpers/gtm";
import TatariHelper from "helpers/tatari-helper";
import RentalOptionIntent from "helpers/rental-option-intent-helper";
import Converge from "helpers/converge";
import { getLocationHref } from "helpers/location-helpers";
import { showBonusStyleUpsellModal, fetchUpsellModalCuration } from "slices/reservation-slice";
import {
  requestedFlagAndExperimentSelector,
  flagsAndExperimentNames,
} from "components/source/hoc/with-flags-and-experiments";
import { UPSELL_CURATION_ID } from "components/source/reservations/upsell-bonus-style/upsell-bonus-style";

const MEMBERS_BAG_TYPE = "MEMBERS";
const MIXED_BAG_TYPE = "MIXED";

const actions = {
  bagCount: createAction(ActionTypes.CE_BAG_COUNT),
  bagLoading: createAction(ActionTypes.CE_BAG_LOADING),
  bagLoadSuccess: createAction(ActionTypes.CE_BAG_SUCCESS),
  bagError: createAction(ActionTypes.CE_BAG_ERROR),
  checkoutKifahSuccess: createAction(ActionTypes.CE_CHECKOUT_KIFAH_SUCCESS),
  membershipAddToCartLoading: createAction(ActionTypes.MEMBERSHIP_ADD_TO_CART_LOADING),
  membershipAddToCartSuccess: createAction(ActionTypes.MEMBERSHIP_ADD_TO_CART_SUCCESS),
  membershipAddToCartFailure: createAction(ActionTypes.MEMBERSHIP_ADD_TO_CART_FAILURE),

  /**
   * the withCheckout param decides whether a Checkout is fetched after the bag
   */
  get(withCheckout = true) {
    return function (dispatch) {
      dispatch(actions.bagLoading(true));
      dispatch(actions.bagError(null));
      dispatch(ceCheckoutActions.checkoutError(null));
      dispatch(membershipBagActions.clearBagErrors());

      return $.get("/bag")
        .then(
          res => {
            dispatch(actions.bagLoadSuccess({ ceBag: setBags(res.bags) }));
            dispatch(actions.bagCount(res.count));

            const membersBag = res?.bags?.find(bag => bag.bagType === MEMBERS_BAG_TYPE);

            if (membersBag && membersBag.checkoutReady) {
              dispatch(membershipStateActions.fetchMembershipState());
              if (withCheckout) {
                dispatch(ceCheckoutActions.getOrCreate());
                dispatch(loadPaymentProfiles());
              }
            } else {
              dispatch(ceCheckoutActions.clear());
            }
          },
          function (xhr, _statusText, errorThrown) {
            let error = errorThrown;

            if (xhr.status === 400) {
              error = xhr.responseText;
            }

            dispatch(actions.bagError(error));
          }
        )
        .finally(() => {
          dispatch(actions.bagLoading(false));
          dispatch(actions.bagError(CEBagErrors.BAG_GENERIC_ERROR));
        });
    };
  },

  setBags(data) {
    const bags = {
      members: {},
      mixed: {},
    };

    if (!data || !data.length) return bags;

    data.forEach(bag => {
      if (bag.bagType === MEMBERS_BAG_TYPE) {
        bags.members = bag?.bagGroups?.[0];
        bags.members.checkoutReady = bag?.checkoutReady;
      } else if (bag.bagType === MIXED_BAG_TYPE) {
        bags.mixed = bag;
      }
    });

    return bags;
  },

  getCount() {
    return function (dispatch) {
      return $.get("/bag/count").then(data => {
        dispatch(actions.bagCount(data));
      });
    };
  },

  logAdd(reservation = {}, isRentable) {
    return function (_dispatch, getState) {
      const state = getState();
      const { product = {}, publicEnv = {}, userData = {} } = state;
      const userId = getUserId(userData);
      const email = userData.userProfile?.email;
      const sailthruConfig = {
        customerId: publicEnv.sailthruCustomerId,
        url: getLocationHref(),
      };

      configureSailthru(sailthruConfig);
      GTMHelper.pushToDataLayer({
        pdp_price_adjusted: product?.price?.adjusted ?? "",
      });

      logReservationAction("click_add_to_cart", reservation, product, false);

      TatariHelper.inferAddToCart({
        userId: userId,
        orderType: TatariHelper.ORDER_TYPES.CLASSIC,
      });
      RentalOptionIntent.setLastIntent(
        RentalOptionIntent.RentalOptions.RESERVE,
        RentalOptionIntent.ActionsTaken.RESERVE_ATC
      );
      GTMHelper.addToCartEvent(product, reservation.primarySku.id, false, userId, email);
      Converge.trackAddedToCart(product, isRentable ? analytics.RENT : analytics.CLEARANCE);
      sendSailthruAddToCartEvent(product, email, reservation, isRentable ? analytics.RESERVE : analytics.CLEARANCE);
      if (isRentable) {
        HeapHelpers.trackAddToBag(
          analytics.ONE_TIME_RENTAL,
          reservation.duration ? `${reservation.duration}-day` : null,
          {
            type: userId ? analytics.NONSUB_RESERVE_RENTAL : analytics.ANON_RESERVE_RENTAL,
          }
        );
      } else {
        HeapHelpers.trackAddToBag(analytics.RETAIL, analytics.CLEARANCE, { type: analytics.CLEARANCE });
      }
    };
  },

  addPostAuthentication(item, analyticsData = {}, reservation, isRentable) {
    return function (dispatch) {
      dispatch(ReservationsActions.submittingReservation());
      dispatch(ReservationsActions.updateWorkingReservation(reservation));

      dispatch(actions.logAdd(reservation, isRentable));

      const onSuccess = () => {
        dispatch(ReservationsActions.cancelReservationSubmission());
      };

      const onFailure = error => {
        dispatch(ReservationsActions.cancelReservationSubmission());
        dispatch(ReservationsActions.setBaseError(error));
      };
      dispatch(actions.add(item, false, analyticsData, onSuccess, onFailure));
    };
  },

  add(item, openBag = false, analyticsData = {}, onSuccess = null, onFailure = null) {
    return function (dispatch, getState) {
      dispatch(actions.bagLoading(true));
      dispatch(actions.membershipAddToCartLoading(true));
      const { productId, retailPriceInteger, ...loggingProperties } = analyticsData;

      const loggingData = {
        style_name: skuInfo(item.sku).id,
        ...loggingProperties,
      };

      return $.post("/bag/bagItem", { item }).then(
        res => {
          dispatch(actions.membershipAddToCartLoading(false));
          dispatch(actions.membershipAddToCartSuccess());
          dispatch(actions.bagCount(res.count));

          ActionLogger.logAction({ ...loggingData, action: ActionLogger.RezoAddToCart });
          castleHelper.logCustomEvent({
            name: EVENTS_CUSTOM_CASTLE.ADD_TO_BAG,
            properties: {
              product_id: analyticsData?.productId,
              retail_price: analyticsData?.retailPriceInteger,
            },
          });

          // Only show the upsell modal if the product is not an accessory
          // and if the current bag group is empty. This logic will likely evolve
          // as our APIs change to return some "eligibilities" value. e.g. the bagGroup
          // is "eligible for a backup style" so show the backup style upsell.
          const canShowUpsellModal = (bagGroups, product) => {
            const currentGroupId = `RENTALS|${item.beginDate.replace(/-/g, "")}|${item.endDate.replace(/-/g, "")}|${
              item.zipCode
            }`;
            const currentGroup = bagGroups?.find(group => group.id === currentGroupId);

            return (
              product?.category.id &&
              !AccessoryCategoryIds.includes(product.category.id) &&
              (!currentGroup || currentGroup?.bagItems?.length === 0)
            );
          };

          if (item.type !== "members") {
            // in all other cases if a mixed item was added, then always open the mixed bag
            dispatch(switchBagTab(bagTabs.CLASSIC_TAB));

            const state = getState();

            if (
              item?.type === "reserve" &&
              requestedFlagAndExperimentSelector(flagsAndExperimentNames.RSV_UPSELL_MODAL)(state)
            ) {
              if (canShowUpsellModal(state.ceBag?.mixed?.bagGroups, state.product)) {
                dispatch(fetchUpsellModalCuration(UPSELL_CURATION_ID));
                dispatch(showBonusStyleUpsellModal(item));

                // If the user simply closes the upsell modal without viewing bag
                // we'll never fetch the bag with the newly added item. Fetch here
                // so that we stay in sync after interrupting the flow. Without this
                // the user can dismiss the modal, add to bag again, and see the modal _again_.
                dispatch(actions.get());
              } else {
                dispatch(toggleBag(true));
              }
            } else {
              dispatch(toggleBag(true));
            }
          }

          if (openBag) {
            dispatch(toggleBag(true));

            const state = getState();

            // if we're moving to members checkout, fetch the personalized carousels
            // so their upgrade prices can stay accurate
            condenseFetchPersonalizedCarousels(dispatch, state);

            dispatch(membershipStateActions.fetchMembershipState());
            dispatch(switchBagTab(bagTabs.MEMBERSHIP_TAB));
          }

          if (onSuccess) {
            onSuccess();
            // EXPLANATION [DV] 6/15/2023 this is breaking KIF ATBs from product drawers, removing this temporarily until fixed
            // GTMHelper.setGA4CartData(item);
          }
        },
        error => {
          /**
           * CE-2037 was made to make this hot mess unnecessary
           */
          let friendlyError = CEBagErrors.ATB_GENERIC_ERROR;
          if (error?.responseJSON) {
            friendlyError = CEBagErrors?.[error.responseJSON?.errors?.[0]?.code] ?? CEBagErrors.ATB_GENERIC_ERROR;
          } else if (error?.responseText) {
            friendlyError = CEBagErrors?.[error.responseText] ?? CEBagErrors.ATB_GENERIC_ERROR;
          }

          dispatch(actions.bagError(friendlyError));
          dispatch(actions.bagLoading(false));
          dispatch(actions.membershipAddToCartLoading(false));
          dispatch(actions.membershipAddToCartFailure(true));

          ActionLogger.logAction({
            ...loggingData,
            action: ActionLogger.RezoAddToCartFailure,
            error,
          });

          if (onFailure) {
            onFailure(friendlyError);
          }
        }
      );
    };
  },

  remove(id, holdId = null, bagType = null, analyticsData = {}) {
    return function (dispatch) {
      dispatch(actions.bagLoading(true));
      dispatch(actions.membershipAddToCartLoading(true));

      return createAjaxAuthRetry({
        url: "/bag/bagItem",
        type: "DELETE",
        data: {
          id,
          holdId,
          bagType,
        },
      })
        .then(() => {
          dispatch(actions.membershipAddToCartLoading(false));
          dispatch(actions.get());
          HeapHelpers.fireHeapEvent("remove_from_bag_success", { id });
          castleHelper.logCustomEvent({
            name: EVENTS_CUSTOM_CASTLE.REMOVE_FROM_BAG,
            properties: {
              product_id: analyticsData?.productId,
            },
          });
        })
        .catch(() => {
          dispatch(actions.membershipAddToCartLoading(false));
          dispatch(actions.bagError(true));
          dispatch(actions.membershipAddToCartFailure());
        });
    };
  },

  kifah(sku, orderId, bookingId) {
    return function (dispatch) {
      dispatch(ceCheckoutActions.checkoutError(null));
      dispatch(toggleBag(true));
      dispatch(switchBagTab(bagTabs.KIFAH_TAB));
      dispatch(bagLoading(true));
      dispatch(ceCheckoutActions.clearPromoErrors());

      const data = { sku, orderId, bookingId };
      return $.post("/bag/keepAtHome", data).then(
        res => {
          dispatch(actions.checkoutKifahSuccess(res));
          dispatch(bagLoading(false));
          dispatch(fireCEHeapEvent("addedKifahItemToCheckout", data));

          if (res.checkoutStatus && res.checkoutStatus.toLowerCase() !== CECheckout.CheckoutStatuses.VALIDATED) {
            dispatch(ceCheckoutActions.checkoutError(CECheckout.errorCodeToCopy(res.checkoutStatus)));
          }

          if (res.passivePromoError) {
            dispatch(ceCheckoutActions.promoError(res.passivePromoError));
          }
        },
        () => {
          dispatch(bagLoading(false));
          dispatch(ceCheckoutActions.checkoutError(CECheckout.errors.generic));
        }
      );
    };
  },

  closeBag() {
    return function (dispatch, getState) {
      /**
       * I don't love this either, but it's currently the cleanest way to keep the KIFAH
       * bag tab only visible when the user clicks on a KIFAH CTA. we should think about
       * abstracting out control of the bag tab states.
       */
      const { bagTab, membershipState } = getState();

      dispatch(toggleBag(false));

      if (
        bagTab === bagTabs.KIFAH_TAB &&
        MembershipHelpers.isActiveMembership(membershipState) &&
        !isCanceled(membershipState)
      ) {
        dispatch(switchBagTab(bagTabs.MEMBERSHIP_TAB));
      }
    };
  },
};

export default actions;

export const {
  bagCount,
  bagLoading,
  bagLoadSuccess,
  get,
  setBags,
  remove,
  kifah,
  logAdd,
  checkoutKifahSuccess,
  closeBag,
  add,
  addPostAuthentication,
} = actions;
