import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { applePayButtonPropType } from "components/propTypes/apple-pay-button-prop-type";
import classNames from "classnames";
import styles from "./styles.module.scss";

const ApplePayButton = ({
  handleApplePaySession,
  buttonStyle = { type: "plain", style: "black" },
  disableButton = false,
}) => {
  const applePayButton = useRef();

  useEffect(() => {
    if (applePayButton && applePayButton.current) {
      applePayButton.current.addEventListener("click", handleApplePaySession);

      return () => {
        applePayButton?.current?.removeEventListener("click", handleApplePaySession);
      };
    }
  }, [applePayButton]);

  const containerClassNames = classNames({
    "apple-pay-button": true,
    [styles["apple-pay-button-disabled"]]: disableButton,
  });

  return (
    <div className={containerClassNames} data-test-id="apple-pay-button">
      <apple-pay-button
        ref={applePayButton}
        buttonstyle={buttonStyle.style}
        type={buttonStyle.type}
        locale="en-US"
        role="button"></apple-pay-button>
    </div>
  );
};

ApplePayButton.propTypes = {
  handleApplePaySession: PropTypes.func,
  buttonStyle: applePayButtonPropType,
  disableButton: PropTypes.bool,
};

export default ApplePayButton;
