// eslint-disable-next-line no-restricted-imports
import Script from "next/script";
import { ThirdPartyScript } from "components/layout/third-party-scripts/thirdPartyScript";
import { getStructuredDataElements } from "./seo-helpers/contentful-metadata-helper";

const SITE_NAME = "Rent the Runway";

const PAGE_METADATA_KEY = "page-metadata";

// All acceptable keys
const ACCEPTABLE_ATTRIBUTE_KEYS = [
  "canonical",
  "title",
  "description",
  "keywords",
  "robots",
  "og:title",
  "og:description",
  "og:image",
  "og:image:width",
  "og:image:height",
  "og:url",
];

const filterMetadataProperties = metadata =>
  Object.fromEntries(
    Object.entries(metadata).filter(
      ([key, value]) => ACCEPTABLE_ATTRIBUTE_KEYS.includes(key) && typeof value === "string" && value.trim().length > 0
    )
  );

const filterOpenGraphMetadata = metadata =>
  Object.fromEntries(
    Object.entries(metadata)
      .filter(
        ([key, value]) =>
          /^og:/.test(key) &&
          ACCEPTABLE_ATTRIBUTE_KEYS.includes(key) &&
          typeof value === "string" &&
          value.trim().length > 0
      )
      .map(([k, v]) => [k.replace(/^og:/, ""), v])
  );

const escapeHTML = s => s?.replace(/&/g, "&amp;").replace(/"/g, "&quot;").replace(/</g, "&lt;").replace(/>/g, "&gt;");

const generateCanonicalUrl = (url, query) => {
  if (!url) {
    return "";
  }

  // this page is accessible on A LOT of subdomains.
  const urlObj = url.startsWith("/")
    ? new URL(decodeURIComponent(url), "https://www.renttherunway.com")
    : new URL(decodeURIComponent(url));

  // one less redirect for Google
  urlObj.protocol = "https:";
  // user may specify
  urlObj.search = query;
  // get outta here fragment
  urlObj.hash = "";

  return urlObj.toString();
};

export class PageMetadata {
  constructor(pageHeadData, metadata) {
    this.pageHeadData = pageHeadData;
    this.metadata = filterMetadataProperties(metadata);
    this.ogMetadata = filterOpenGraphMetadata(metadata);
    this.structuredData = getStructuredDataElements(pageHeadData.structuredData);
  }

  get openGraphMetadata() {
    return this.ogMetadata;
  }

  get siteTitle() {
    let { title } = this.metadata;

    if (!title) {
      title = [this.pageHeadData.titleAddition ? this.pageHeadData.titleAddition : "", SITE_NAME]
        .filter(s => s && s.length > 0)
        .join(" | ");
    }

    if (typeof this.pageHeadData.paginationTitleContent === "function") {
      title = `${this.pageHeadData.paginationTitleContent()}${title}`;
    }

    return escapeHTML(title);
  }

  get siteDescription() {
    let { description } = this.metadata;
    description = this.pageHeadData.paginationDescription
      ? `${this.pageHeadData.paginationDescription}${description}`
      : description;

    return escapeHTML(description);
  }

  get canonicalLink() {
    if (this.metadata.canonical) {
      return this.metadata.canonical;
    }
    const canonical = this.pageHeadData.canonical ?? "";
    const query = this.pageHeadData.canonicalQuery ?? "";
    return generateCanonicalUrl(canonical, query);
  }

  get robots() {
    return this.metadata.robots;
  }

  get keywords() {
    return escapeHTML(this.metadata.keywords);
  }

  get openGraphImage() {
    let { image } = this.ogMetadata;
    let imageWidth;
    let imageHeight;
    if (!image) {
      image = "https://cdn.rtrcdn.com/sites/default/files/images/rtr_logo.svg.png";
      imageWidth = 543;
      imageHeight = 729;
    } else {
      imageWidth = this.ogMetadata["image:width"];
      imageHeight = this.ogMetadata["image:height"];
    }

    return (
      <>
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content={imageWidth} />
        <meta property="og:image:height" content={imageHeight} />
      </>
    );
  }

  get openGraphTitle() {
    return this.ogMetadata.title;
  }

  get openGraphDescription() {
    return this.ogMetadata.description;
  }

  get openGraphUrl() {
    const query = this.pageHeadData.canonicalQuery ?? "";
    return generateCanonicalUrl(this.ogMetadata.url, query);
  }

  get pageName() {
    return this.pageHeadData.pageName;
  }

  get branchDeeplinkUrl() {
    return this.pageHeadData.branchDeeplinkUrl;
  }

  get extraHead() {
    return typeof this.pageHeadData.extraHead === "function" ? this.pageHeadData.extraHead() : <></>;
  }

  get sailthru() {
    return typeof this.pageHeadData.sailthru === "function" ? this.pageHeadData.sailthru() : <></>;
  }

  // The callback defined here, gMapsLoaded, fires an event which allows input elements
  // to render using the Google Maps API.
  // 3rd-party integration - something weird going on here w/ OneTrust and maps, getting error:
  // Google Maps JavaScript API error: InvalidKeyMapError
  // https://developers.google.com/maps/documentation/javascript/error-messages#invalid-key-map-error
  get googleMapsScript() {
    return (
      <ThirdPartyScript
        id="google-maps-script"
        type="text/javascript"
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.google_maps_key}&libraries=places&callback=gMapsLoaded`}></ThirdPartyScript>
    );
  }

  // This method returns a script tag that is used for Vantiv's eProtect
  // feature on Checkout.
  // 3rd-party integration - this dynamically gets rendered when an iframe tag's src attribute calls the endpoint that
  // returns an .erb template with this script, at which point OneTrust isn't able to reactive this tag. It's a necessary
  // one anyways so going to leave it alone.
  get eProtectScriptUrl() {
    return <Script id="eprotect-script" type="text/javascript" src={process.env.vantiv.eprotect_script}></Script>;
  }
}

export const findPageMetadata = (componentHeadData, pageContent = null) => {
  let cmsMetadata = (pageContent && pageContent[PAGE_METADATA_KEY]) || "{}";

  cmsMetadata = JSON.parse(cmsMetadata);

  // grab the page metadata the page provides
  const componentMetadata = componentHeadData?.metadata ?? {};

  // Page metadata takes precedence over CMS metadata, so create a canonical set of metadata applying the component meta on top
  const metadata = Object.assign({}, cmsMetadata, componentMetadata);

  // the one exception (😖) to the rule above is canonical links, so set that from CMS
  // Exception to the exception - if metadata is sourced from Contentful, continue to use that and not RTR CMS
  if (cmsMetadata.canonical && !componentHeadData.isContentfulMetadata) {
    metadata.canonical = cmsMetadata.canonical;
  }

  return new PageMetadata(componentHeadData, metadata);
};
