import React from "react";
import PropTypes from "prop-types";
import ActionLogger from "action-logger";
import classNames from "classnames";
import { isAdmin, withUserData } from "components/source/hoc/with-user-data";
import { parseISOWithoutTime } from "helpers/date-helpers";
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  endOfMonth,
  endOfWeek,
  format as formatDate,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import { dateFnsFormats } from "rtr-constants";

/**
 * This class aims to operate interally on instances of Date, rather than strings, to make using date-fns easier.
 * These values should only be re-stringified/formatted as needed when rendering/logging/etc.
 */
export class DatepickerComponent extends React.Component {
  static propTypes = {
    blackoutDays: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.string,
    dateEnd: PropTypes.string,
    dateStart: PropTypes.string.isRequired,
    deliveryCutoffs: PropTypes.arrayOf(
      PropTypes.shape({
        deliveryDate: PropTypes.string,
      })
    ),
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.any,
    idSuffix: PropTypes.string,
    logging: PropTypes.shape({
      maternity: PropTypes.arrayOf(PropTypes.string),
      objectType: PropTypes.string,
      sizes: PropTypes.array,
      zipCode: PropTypes.string,
    }),
    loggingObjectType: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    userData: PropTypes.object,
  };

  state = {
    /**
     * A reference instance of Date to which we pin the current calendar view.
     * Functionally, any date that is part of the current, in-view month will work.
     * This prevents the need to convert strings (the month itself) to instances of Date and back.
     */
    referenceDate: this.props.date ? parseISOWithoutTime(this.props.date) : new Date(),
    inputName: "filter-" + Math.random(),
  };

  componentDidMount() {
    const { dateStart, deliveryCutoffs } = this.props;
    const [firstDeliveryCutoff] = deliveryCutoffs || [];
    const { deliveryDate } = firstDeliveryCutoff || {};

    this.setState({
      firstSelectableDay: this.getFirstSelectableDate(dateStart, deliveryDate),
    });
  }

  componentDidUpdate(prevProps) {
    const { date: newDate, deliveryCutoffs: newDeliveryCutoffs } = this.props;
    const { date: previousDate, deliveryCutoffs: previousDeliveryCutoffs } = prevProps;
    const { referenceDate } = this.state;

    if (newDate !== previousDate || previousDeliveryCutoffs !== newDeliveryCutoffs) {
      this.setState({ firstSelectableDay: this.getFirstSelectableDate() });
    }

    if (!newDate || previousDate === newDate) {
      return;
    }

    const parsedNewDate = parseISOWithoutTime(newDate);

    //Update the reference date to match what's passed in as a prop, if it changes.
    if (!isSameDay(parsedNewDate, referenceDate)) {
      this.setState({ referenceDate: parsedNewDate });
    }
  }

  /**
   * @returns {Date}
   */
  getFirstSelectableDate = () => {
    const dateStart = this.getEarliestRentalStartDate();
    const deliveryDate = this.getEarliestDeliveryDate();
    //this is a workaround for the CX team that need as
    //first selectable day the start date
    if (isAdmin(this.props.userData)) {
      return dateStart;
    }

    if (dateStart && deliveryDate) {
      return isAfter(dateStart, deliveryDate) ? dateStart : deliveryDate;
    } else {
      return dateStart ? dateStart : deliveryDate;
    }
  };

  /**
   * @returns {Date}
   */
  getEarliestDeliveryDate = () => {
    const { deliveryCutoffs } = this.props;
    const [firstDeliveryCutoff] = deliveryCutoffs || [];
    const { deliveryDate } = firstDeliveryCutoff || {};

    return deliveryDate ? parseISOWithoutTime(deliveryDate) : deliveryDate;
  };

  /**
   * @returns {Date}
   */
  getSelectedDate = () => {
    const { date } = this.props;

    return date ? parseISOWithoutTime(date) : date;
  };

  /**
   * @returns {Date}
   */
  getEarliestRentalStartDate = () => {
    const { dateStart } = this.props;

    return dateStart ? parseISOWithoutTime(dateStart) : dateStart;
  };

  /**
   * @returns {Date}
   */
  getLatestRentalStartDate = () => {
    const { dateEnd } = this.props;

    return dateEnd ? parseISOWithoutTime(dateEnd) : dateEnd;
  };

  /**
   * The first date in view is the Sunday of the week that contains the first day of the month.
   * @returns {Date}
   */
  getFirstDayOfCalendar = () => {
    const { referenceDate } = this.state;
    const monthStart = startOfMonth(referenceDate);
    //Sunday is default week start but let's be explicit here
    const weekStart = startOfWeek(monthStart, { weekStartsOn: 0 });

    return weekStart;
  };

  /**
   * If the latest possible rental end date is in the current month, end of the last week of the month.
   * Else, end of the week in which the latest rental staring in this month ends.
   * @returns {Date}
   */
  getLastDayOfCalendar = () => {
    const { referenceDate } = this.state;
    const { duration } = this.props;

    const endOfCurrentMonth = endOfMonth(referenceDate);
    const latestRentalEndStartingThisMonth = addDays(endOfCurrentMonth, duration - 1);
    const latestPossibleRentalEndDate = addDays(this.getLatestRentalStartDate(), duration - 1);

    const latestDate = isSameMonth(referenceDate, latestPossibleRentalEndDate)
      ? endOfMonth(referenceDate)
      : latestRentalEndStartingThisMonth;

    //Sunday is default week start but let's be explicit here
    const weekEnd = endOfWeek(latestDate, { weekStartsOn: 0 });

    return weekEnd;
  };

  getDays = () => {
    const days = [];
    const lastPossibleDay = this.getLastDayOfCalendar();
    let day = this.getFirstDayOfCalendar();

    while (isBefore(day, lastPossibleDay)) {
      days.push(day);
      day = addDays(day, 1);
    }

    return days;
  };

  canPageBack = () => {
    const earliestDate = this.getEarliestRentalStartDate();

    return !earliestDate || isBefore(earliestDate, this.getFirstDayOfCalendar());
  };

  canPageForward = () => {
    const { referenceDate } = this.state;
    const latestDate = this.getLatestRentalStartDate();
    const endOfCurrentMonth = endOfMonth(referenceDate);

    return !latestDate || isAfter(latestDate, endOfCurrentMonth);
  };

  getPreviousMonthButton = () => {
    if (!this.canPageBack()) {
      return null;
    }

    const { referenceDate } = this.state;
    const newReferenceDate = subMonths(referenceDate, 1);
    return this.getMonthButton(newReferenceDate, "previous");
  };

  getNextMonthButton = () => {
    if (!this.canPageForward()) {
      return null;
    }

    const { referenceDate } = this.state;
    const newReferenceDate = addMonths(referenceDate, 1);
    return this.getMonthButton(newReferenceDate, "next");
  };

  getMonthButton = (date, direction) => {
    return (
      <button
        aria-label={`${direction} month`}
        className="datepicker-month-button pagination-nav"
        id={`${direction}-month`}
        onClick={e => this.changeReferenceDate(date, e)}
        value={formatDate(date, dateFnsFormats.YYYY_MM_DD)}>
        {formatDate(date, dateFnsFormats.MMMM)}
      </button>
    );
  };

  changeReferenceDate = (date, event) => {
    event.stopPropagation();
    event.preventDefault();

    // @todo: please resolve
    // loggingObjectType here
    // logging.objectType below
    if (this.props.loggingObjectType) {
      ActionLogger.logAction({
        object_type: this.props.loggingObjectType,
        action: "scroll_calendar",
        // Javascript months are zero-based! Nobody else does that!
        calMonth: date.getMonth() + 1,
        calYear: date.getFullYear(),
      });
    }

    this.setState({ referenceDate: date });
  };

  logDateChange = date => {
    const { logging } = this.props;
    const loggingObject = {
      object_type: logging.objectType,
      action: "rezo_select",
      rentBegin: formatDate(date, dateFnsFormats.YYYY_MM_DD),
      zip: logging.zipCode,
      itemSizes: logging.sizes.join(","),
      duration: this.props.duration,
    };

    if (logging.maternity) {
      loggingObject.trimester_filter = Boolean(logging.maternity.length);
    }

    ActionLogger.logAction(loggingObject);
  };

  changeDate = date => {
    const { logging, onChange } = this.props;

    if (logging) {
      this.logDateChange(date);
    }

    onChange({
      filterGroupKey: "date",
      name: formatDate(date, dateFnsFormats.YYYY_MM_DD),
    });
  };

  datepickerClassNames = () => {
    const { duration, error } = this.props;

    return classNames("datepicker", `duration-${duration}`, {
      "datepicker-error": error,
    });
  };

  isWithinDurationWindow = date => {
    const { duration } = this.props;
    const selectedDate = this.getSelectedDate();
    if (!selectedDate) {
      return;
    }

    const diff = differenceInCalendarDays(date, selectedDate);

    return diff >= 0 && diff < duration;
  };

  isLastDateOfDurationWindow = date => {
    const { duration } = this.props;
    const selectedDate = this.getSelectedDate();
    if (!selectedDate) {
      return;
    }

    return isSameDay(date, addDays(selectedDate, duration - 1));
  };

  isBlackoutDay = date => {
    const { blackoutDays = [] } = this.props;
    const asString = formatDate(date, dateFnsFormats.YYYY_MM_DD);

    return blackoutDays.includes(asString);
  };

  isSelectableDay = date => {
    const { firstSelectableDay } = this.state;

    const isOnOrAfterEarliestDate = isSameDay(date, firstSelectableDay) || isAfter(date, firstSelectableDay);
    const latestDate = this.getLatestRentalStartDate();
    //In some cases, we don't pass in an end date (i.e. all future date are valid)
    const isOnOrBeforeLatestDate = !latestDate || isSameDay(date, latestDate) || isBefore(date, latestDate);

    return !this.isBlackoutDay(date) && isOnOrAfterEarliestDate && isOnOrBeforeLatestDate;
  };

  render() {
    const datepickerClassNames = this.datepickerClassNames();
    const { date, idSuffix, name } = this.props;
    const { inputName, referenceDate } = this.state;

    return (
      <div className={datepickerClassNames} data-test-id="datepicker">
        <div className="datepicker-header">
          <div className="datepicker-header-piece">{this.getPreviousMonthButton()}</div>
          <p className="datepicker-header-piece dek-one">{formatDate(referenceDate, dateFnsFormats.MMMM_YYYY)}</p>
          <div className="datepicker-header-piece">{this.getNextMonthButton()}</div>
        </div>
        <div className="week-days">
          {["S", "M", "T", "W", "T", "F", "S"].map((d, i) => (
            <p key={i}>{d}</p>
          ))}
        </div>
        <div className="month-days">
          {this.getDays().map((date, index) => {
            const isChecked = isSameDay(date, this.getSelectedDate());
            const selectable = this.isSelectableDay(date);

            const asString = formatDate(date, dateFnsFormats.YYYY_MM_DD);
            const inputId = "date-" + asString + inputName + index + idSuffix;
            const className = classNames("datepicker-input-wrapper", {
              "selectable": selectable,
              "blackout": !selectable,
              "checked": isChecked,
              "display-checked": this.isWithinDurationWindow(date),
              "display-checked--last": this.isLastDateOfDurationWindow(date),
            });

            return (
              <div className={className} key={asString} data-test-id="datepicker-date">
                <input
                  onChange={() => this.changeDate(date)}
                  type="radio"
                  name="date"
                  id={inputId}
                  checked={isChecked}
                  value={asString}
                  key={asString + inputName + index}
                  disabled={!selectable}
                />
                <label className="datepicker-date-label" htmlFor={inputId} data-test-id="datepicker-date-label">
                  {date.getDate()}
                </label>
              </div>
            );
          })}
        </div>
        <input type="hidden" value={date} name={name} />
      </div>
    );
  }
}

export default withUserData(DatepickerComponent);
