import React from "react";
import PropTypes from "prop-types";
import { childrenPropType } from "components/propTypes";
import ReactModal from "react-modal";
import classNames from "classnames";
import { MODAL } from "rtr-constants";
import CustomModalStyles from "./custom-modal-styles";

const { FREEZE_SCROLL, SCROLL } = MODAL;
class Modal extends React.Component {
  static propTypes = {
    additionalStyles: PropTypes.object,
    backgroundScrollable: PropTypes.bool,
    children: childrenPropType,
    className: PropTypes.string,
    hasBlurredBackground: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    isOpen: PropTypes.bool,
    overlayClassName: PropTypes.string,
    onRequestClose: PropTypes.func,
  };

  static defaultProps = {
    onRequestClose: () => {},
  };

  render() {
    const { overlay, content } = CustomModalStyles;
    const { additionalStyles } = this.props;

    let styles = CustomModalStyles;

    if (additionalStyles) {
      styles = {
        overlay: {
          ...overlay,
          ...additionalStyles.overlay,
        },
        content: {
          ...content,
          ...additionalStyles.content,
        },
      };
    }

    const {
      backgroundScrollable,
      children,
      className,
      hasBlurredBackground,
      isFullScreen,
      isOpen,
      onRequestClose,
      overlayClassName,
      ...otherProps
    } = this.props;
    const bodyClasses = classNames({
      [SCROLL]: backgroundScrollable,
      [FREEZE_SCROLL]: !backgroundScrollable,
    });
    const contentClasses = classNames(className, {
      "ReactModal__Content--after-open--full-screen": isFullScreen,
    });
    const overlayClasses = classNames(overlayClassName, { "blurred-background": hasBlurredBackground });

    return (
      <ReactModal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        isOpen={isOpen}
        bodyOpenClassName={bodyClasses}
        className={contentClasses}
        style={styles}
        overlayClassName={overlayClasses}
        onRequestClose={onRequestClose}>
        {children}
      </ReactModal>
    );
  }
}

export default Modal;
