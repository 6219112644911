import BaseServicesClient from "./BaseServicesClient";
import { isSSR } from "../helpers/client-server-helper";
import { OAUTH_GRANT_TYPES } from "rtr-constants/auth";
import { HttpError } from "../exceptions/http";
import { navigateTo } from "../helpers/location-helpers";
import { getTime, addSeconds, isAfter } from "date-fns";
import { LocalStorage } from "../site/localStorage";

const TOKEN_REFRESH_IN_PROGRESS_TTL_SECONDS = 10;

export class ConsumerAuthServiceClientError extends HttpError {}

export default class ConsumerAuthServiceClient extends BaseServicesClient {
  static CLIENT_SIDE_TOKEN_REFRESH_IN_PROGRESS_BROWSER_STORAGE_NAME = "tokenRefreshInProgress";

  /**@type {Promise | null} */
  refreshRequestInProgress = null;

  static get CONFIG_HOST() {
    if (isSSR()) {
      return process.env.CONFIG__CAS__HOST;
    }

    return "/api/auth";
  }

  static get JWKS_PATH() {
    if (isSSR()) {
      return process.env.CONFIG__CAS__HOST + "/oauth2/jwks";
    }

    return "";
  }

  static get WELL_KNOWN_OPEN_ID_CONFIGURATION_PATH() {
    if (isSSR()) {
      return process.env.CONFIG__CAS__HOST + "/.well-known/openid-configuration";
    }

    return "";
  }

  async logout(params = {}, options = {}) {
    const response = await this.post("/logout", params, options);
    if (!response.ok) {
      return this.#handleErrors(response, "Failed to log user out");
    }
    // The body of a 204 response will be empty and throw an error for .json()
    // https://github.com/whatwg/fetch/issues/113#issuecomment-409922366
    if (response.ok && response.status === 204) return {};

    return await response.json();
  }

  async refreshTokenServerSide(params = {}, options = {}) {
    // On the server we will actually inject the service client credentials and the refresh token from the session
    // cookie required to refresh the access token.
    if (!isSSR()) return;

    let _params = {};
    let _options = {};

    _params = {
      client_id: process.env.CAS_SF_CLIENT_ID,
      client_secret: process.env.CAS_SF_CLIENT_SECRET,
      grant_type: OAUTH_GRANT_TYPES.REFRESH_TOKEN,
      refresh_token: params.refresh_token || "",
      ...params,
    };
    _options = {
      ...options,
      headers: { "Content-Type": "application/x-www-form-urlencoded", ...(options.headers || {}) },
    };

    const response = await this.post("/oauth2/token", _params, _options);
    if (!response.ok) {
      return this.#handleErrors(response, "Failed to refresh token on the server");
    }
    // The body of a 204 response will be empty and throw an error for .json()
    // https://github.com/whatwg/fetch/issues/113#issuecomment-409922366
    if (response.status === 204) return {};

    const data = await response.json();
    return data;
  }

  removeClientSideTokenRefreshInProgress() {
    const localStorage = new LocalStorage();
    localStorage.remove(ConsumerAuthServiceClient.CLIENT_SIDE_TOKEN_REFRESH_IN_PROGRESS_BROWSER_STORAGE_NAME);
  }

  setClientSideTokenRefreshInProgress() {
    const localStorage = new LocalStorage();
    const ttl = getTime(addSeconds(new Date(), TOKEN_REFRESH_IN_PROGRESS_TTL_SECONDS));
    localStorage.set(ConsumerAuthServiceClient.CLIENT_SIDE_TOKEN_REFRESH_IN_PROGRESS_BROWSER_STORAGE_NAME, ttl);
  }

  refreshTokenClientSide(params = {}, options = {}) {
    if (isSSR()) return;

    const localStorage = new LocalStorage();
    // this check needs to happen as close to the request as possible. If there are any `await`s between this check
    // and the request, another context may be loaded in at run time and a second token request triggered
    const ttl = localStorage.get(ConsumerAuthServiceClient.CLIENT_SIDE_TOKEN_REFRESH_IN_PROGRESS_BROWSER_STORAGE_NAME);

    if (ttl && isAfter(new Date(ttl), new Date())) {
      // A refresh request is already in progress, need to give the caller something to `await` so they can chain behaviour when
      // we get a new token
      if (this.refreshRequestInProgress) {
        // being refreshed in current tab, just re-use the original promise
        return this.refreshRequestInProgress;
      } else {
        // not refreshing in the current tab, need to listen for the event that the refresh is complete
        return new Promise(resolve => {
          window.addEventListener("storage", event => {
            if (
              event.key === ConsumerAuthServiceClient.CLIENT_SIDE_TOKEN_REFRESH_IN_PROGRESS_BROWSER_STORAGE_NAME &&
              !event.newValue
            ) {
              resolve();
            }
          });
        });
      }
    }

    // this will set a new TTL if one did not exist, or if one did but it was old
    this.setClientSideTokenRefreshInProgress();

    this.refreshRequestInProgress = async () => {
      try {
        const response = await this.post("/oauth2/token", null, {
          signal: AbortSignal.timeout(5000),
          body: JSON.stringify(params),
          ...options,
        });
        // remove the flag that shows we are refreshing the token (will also signal other tabs)
        this.removeClientSideTokenRefreshInProgress();

        if (!response.ok) {
          return this.#handleErrors(response, "Failed to refresh token on the client");
        }

        // The body of a 204 response will be empty and throw an error for .json()
        // https://github.com/whatwg/fetch/issues/113#issuecomment-409922366
        if (response.status === 204) return {};
        if (response.redirected) {
          return navigateTo(response.url);
        }
        await response.json();

        return;
      } catch (error) {
        this.removeClientSideTokenRefreshInProgress();
        throw error;
      }
    };
    return this.refreshRequestInProgress();
  }

  async #handleErrors(response, customMessage = "") {
    let errorDetails = {};
    const responseClone = response.clone();

    try {
      errorDetails = await response.json();
    } catch (_e) {
      // Ignore any parsing errors
    }

    throw new ConsumerAuthServiceClientError(
      (customMessage || "ConsumerAuthServiceClient request failed") + " " + `status=${response.status}`,
      response.status,
      errorDetails,
      responseClone
    );
  }
}
