import _ from "underscore";
import $ from "clients/RawClient";
import { createAction } from "redux-actions";

import * as membershipStateActions from "actions/membership-state-actions";
import ActionTypes from "actions/action-types";
import { clientSideErrorMessages } from "rtr-constants";

var actions = {
  addressBookIsLoading: createAction(ActionTypes.ADDRESS_BOOK_IS_LOADING),
  addressBookLoaded: createAction(ActionTypes.ADDRESS_BOOK_LOADED),
  addressIsDeleting: createAction(ActionTypes.ADDRESS_IS_DELETING),
  addressIsUpdating: createAction(ActionTypes.ADDRESS_IS_UPDATING),
  addressUpdateFailure: createAction(ActionTypes.ADDRESS_UPDATE_FAILURE),
  addressUpdated: createAction(ActionTypes.ADDRESS_UPDATED),
  deleteAddressFailure: createAction(ActionTypes.DELETE_ADDRESS_FAILURE),
  deleteAddressSuccess: createAction(ActionTypes.DELETE_ADDRESS_SUCCESS),
  deleteAddressRequest: createAction(ActionTypes.DELETE_ADDRESS_REQUEST),
  showSizeFilterPrompt: createAction(ActionTypes.SHOW_SIZE_FILTER_PROMPT),

  loadAddressBook: function () {
    return function (dispatch) {
      dispatch(actions.addressBookIsLoading(true));
      return $.get("/shippingAddresses")
        .then(function (addresses) {
          dispatch(actions.addressBookLoaded(addresses));
        })
        .finally(function () {
          dispatch(actions.addressBookIsLoading(false));
        });
    };
  },

  updateAddress: function (address) {
    return function (dispatch) {
      dispatch(actions.addressIsUpdating(true));
      dispatch(actions.addressUpdated(null));

      return $.ajax({
        url: "/shippingAddresses",
        type: "PATCH",
        data: JSON.stringify(address),
      })
        .then(function (updatedAddress) {
          dispatch(actions.addressUpdated(updatedAddress));
          dispatch(membershipStateActions.fetchMembershipState());
        })
        .finally(function () {
          dispatch(actions.addressIsUpdating(false));
        });
    };
  },

  deleteAddress: function ({ data, onSuccess, onFailure }) {
    return function (dispatch) {
      const ids = data?.vals?.ids;
      const mappedAddressIds = ids.map(function (id) {
        return $.ajax({
          url: `/shippingAddresses/${id}`,
          type: "DELETE",
        });
      });

      if (!Array.isArray(ids) || ids.length === 0) {
        return Promise.resolve();
      }

      dispatch(actions.deleteAddressRequest());

      return Promise.all(...mappedAddressIds).then(
        function () {
          dispatch(actions.deleteAddressSuccess());
          onSuccess();
        },
        function (id) {
          dispatch(actions.deleteAddressFailure());
          onFailure(id);
        }
      );
    };
  },

  updateDefaultAndRemoveAddress: function ({ values, onSuccess, onFailure }) {
    return function (dispatch) {
      const { newDefaultAddressId, originalDefaultAddressId, updateData, removeData } = values || {};

      const { vals } = removeData || {};

      const shouldCallRemoveMethod = vals?.ids?.length ?? 0 > 0;
      const shouldCallUpdateMethod = newDefaultAddressId !== originalDefaultAddressId;

      const optionalSuccessCallback = function () {
        if (!shouldCallRemoveMethod) {
          onSuccess();
        }
      };

      const optionalFailureCallback = function () {
        onFailure(clientSideErrorMessages.changeDefaultAddress.defaultError);
      };

      const onFailureMessage = function (id) {
        let clientErrorMessage = "";
        if (id === newDefaultAddressId) {
          clientErrorMessage = clientSideErrorMessages.changeDefaultAddress.defaultError;
        } else {
          clientErrorMessage = clientSideErrorMessages.removeAddress.defaultError;
        }
        return onFailure(clientErrorMessage);
      };

      dispatch(actions.addressIsUpdating(true));

      let updateAddressMethod = Promise.resolve();
      if (shouldCallUpdateMethod) {
        updateAddressMethod = dispatch(
          actions.updateShippingAddress(updateData, optionalSuccessCallback, optionalFailureCallback)
        );
      }
      return updateAddressMethod
        .then(function () {
          if (shouldCallRemoveMethod) {
            return dispatch(
              actions.deleteAddress({
                data: removeData,
                onSuccess,
                onFailureMessage,
              })
            );
          }
          return Promise.resolve();
        })
        .then(function () {
          dispatch(actions.addressIsUpdating(false));

          return dispatch(actions.loadAddressBook());
        });
    };
  },

  updateShippingAddress: function (payload, onSuccess = _.noop, onFailure = _.noop) {
    return function (dispatch) {
      dispatch(actions.addressIsUpdating());

      return $.ajax({
        url: payload.url,
        type: "PATCH",
        data: JSON.stringify(payload.vals),
      }).then(
        function (data) {
          onSuccess(data);
          dispatch(actions.addressUpdated());
        },
        function (err) {
          onFailure(err);
        }
      );
    };
  },
};

export default actions;

export const {
  addressBookIsLoading,
  addressBookLoaded,
  addressIsDeleting,
  addressIsUpdating,
  addressUpdateFailure,
  addressUpdated,
  deleteAddressFailure,
  deleteAddressSuccess,
  deleteAddressRequest,
  showSizeFilterPrompt,
  loadAddressBook,
  updateAddress,
  deleteAddress,
  updateDefaultAndRemoveAddress,
  updateShippingAddress,
} = actions;
