import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";

import { bagTabs, switchBagTab, toggleBag } from "actions/bag-actions";
import { fetchUserCredits as fetchUserCreditsAction } from "actions/user-actions";
import ActionLogger from "action-logger";
import AuthActions from "actions/auth-actions";
import ShortlistActions from "actions/shortlists-actions";
import {
  browserPropType,
  membershipBagPropType,
  membershipStatePropType,
  ordersPropType,
  userDataPropType,
} from "components/propTypes";
import HeartIcon from "components/source/user_product_ratings/heart-icon";
import { ALL_HEARTS_SHORTLIST_ID, analytics, pageTypes, StandardCheckoutConstants } from "rtr-constants";
import withUserMenuTemplate from "./user-menu-template";
import UserMenuTooltips from "./user-menu-tooltips";
import { getMemberProgramChangeInfo } from "../../../actions/membership-tier-actions";
import ceBagActions from "actions/ce-bag-actions";
import { isAnonymous } from "components/source/hoc/with-user-data";
import HeapHelpers, { HEAP_AUTH_TRIGGER_TYPES } from "helpers/heap-helpers";
import { isActiveMembership } from "helpers/membership-helpers";
import {
  flagsAndExperimentNames,
  flagsAndExperimentsPropType,
  experimentsSelector,
} from "../hoc/with-flags-and-experiments";
import { withFireWithUserData } from "components/source/hoc/with-fire-with-user-data";
import Converge from "../../../helpers/converge";
import LogoutButton from "../shared/auth_form/logout";
import Routes from "routes";
import { getLocationPathname } from "../../../helpers/location-helpers";

const noop = () => {}; // NOSONAR

class UserMenu extends React.Component {
  static propTypes = {
    activeOrders: ordersPropType,
    browser: browserPropType,
    ceBagCount: PropTypes.object,
    fetchCEBagCount: PropTypes.func,
    fetchMemberProgramChangeInfo: PropTypes.func.isRequired,
    fetchShortlists: PropTypes.func.isRequired,
    fetchUserCredits: PropTypes.func.isRequired,
    fireWithUserData: PropTypes.func,
    flagsAndExperiments: flagsAndExperimentsPropType,
    hasProfile: PropTypes.bool, // populated by UserMenuTemplate
    isMobileAccountMenuExpanded: PropTypes.bool, // populated by NavContainer
    isMobileNavExpanded: PropTypes.bool, // populated by NavContainer
    isSubscriber: PropTypes.bool, // populated by UserMenuTemplate
    isWideWidth: PropTypes.bool, // populated by NavContainer
    launchDarklyExperiments: flagsAndExperimentsPropType,
    membershipBag: membershipBagPropType,
    membershipState: membershipStatePropType,
    pageName: PropTypes.string,
    // NW [EXPLANATION] 4/12/21: render functions implemented by UserMenuTemplate
    renderAccountCredits: PropTypes.func.isRequired,
    renderAccountGreeting: PropTypes.func.isRequired,
    renderAccountSubMenuOptions: PropTypes.func.isRequired,
    renderSignInLink: PropTypes.func.isRequired,
    shortlists: PropTypes.arrayOf(PropTypes.object),
    showAuthModal: PropTypes.func,
    openBagWithDispatch: PropTypes.func,
    switchBagTab: PropTypes.func,
    toggleMobileNavAccountMenu: PropTypes.func.isRequired, // implemented by NavContainer
    toggleMobileNav: PropTypes.func.isRequired, // implemented by NavContainer
    userData: userDataPropType,
  };

  static defaultProps = {
    renderAccountCredits: noop,
    renderAccountGreeting: noop,
    renderAccountSubMenuOptions: noop,
    renderSignInLink: noop,
  };

  state = {
    hasFetchedUserCredits: false,
    hasFetchedMemberProgramChangeInfo: false,
    hasFetchedShortlists: false,
  };

  componentDidMount() {
    const { userData } = this.props;

    // in Storefront.Ruby, userData will be set by the time this renders. not
    // necessarily true of Storefront.Next
    this.fetchComponentData();

    if (userData?.userProfileHasLoaded) {
      Converge.setUserData(userData).then(() => Converge.trackViewedPage());
    }
  }

  componentDidUpdate(prevProps) {
    const { ceBagCount, membershipState, userData, launchDarklyExperiments } = this.props;
    this.fetchComponentData();

    if (prevProps.ceBagCount?.members !== ceBagCount?.members) {
      HeapHelpers.setMembershipBagProperties(ceBagCount?.members);
    }

    if (
      (userData?.userProfile && prevProps.userData?.userProfile !== userData?.userProfile) ||
      (!userData?.userProfile && prevProps.userData?.authState !== userData?.authState)
    ) {
      Converge.setUserData(userData).then(() => Converge.trackViewedPage());
    }

    if (userData?.userProfileHasLoaded && userData?.authDataHasLoaded) {
      HeapHelpers.setPropertiesOnPageLoad(membershipState, userData, launchDarklyExperiments);
    }

    // only call these after the heap initialize on page load if LD experment values change (rare)
    // or if membershipState updates with new values that would effect heap metrics
    if (HeapHelpers.getPageLoadPropertiesAreSet()) {
      if (
        JSON.stringify(HeapHelpers.getExperimentsProperties(launchDarklyExperiments)) !==
        JSON.stringify(HeapHelpers.getExperimentsProperties(prevProps?.launchDarklyExperiments))
      ) {
        HeapHelpers.setExperimentsPropertiesInHeap(launchDarklyExperiments);
      }

      if (
        JSON.stringify(HeapHelpers.getMembershipPropertiesForHeap(membershipState)) !==
        JSON.stringify(HeapHelpers.getMembershipPropertiesForHeap(prevProps?.membershipState))
      ) {
        HeapHelpers.setMembershipStatePropertiesInHeap(membershipState);
      }
    }
  }

  fetchComponentData() {
    const {
      fetchCEBagCount,
      fetchMemberProgramChangeInfo,
      fetchShortlists,
      fetchUserCredits,
      membershipState,
      pageName,
      userData,
      isSubscriber,
    } = this.props;

    // do not fetch bag if the user is not logged in
    if (isAnonymous(userData)) return;

    if (!this.state.hasFetchedUserCredits) {
      fetchUserCredits();
      this.setState({ hasFetchedUserCredits: true });
    }

    if (isSubscriber && !this.state.hasFetchedMemberProgramChangeInfo) {
      fetchMemberProgramChangeInfo();
      this.setState({ hasFetchedMemberProgramChangeInfo: true });
    }

    if (!this.state.hasFetchedShortlists) {
      fetchShortlists();
      this.setState({ hasFetchedShortlists: true });
    }

    if (!this.state.hasFetchedCEBagCount) {
      // don't fetch the bag count if the user is a member loading the homepage.
      // the membership homepage, on load, will fetch the full bag (needed for the item card carousel) including count, so this is redundant
      if (!(pageName === pageTypes.HOME && isActiveMembership(membershipState))) {
        fetchCEBagCount();
      }

      this.setState({ hasFetchedCEBagCount: true });
    }

    if (!this.state.hasOpenedBagFromUrl) {
      const urlParams = new URLSearchParams(window.location.search);
      const openBagTo = urlParams.get(StandardCheckoutConstants.OPEN_BAG_TO_QUERY_PARAM);

      if (openBagTo === StandardCheckoutConstants.OPEN_BAG_TO_MIXED) {
        this.openBag();
        this.props.switchBagTab(bagTabs.CLASSIC_TAB);

        this.setState({ hasOpenedBagFromUrl: true });
      }
    }
  }

  getCartCount() {
    const { activeOrders, membershipBag } = this.props;

    let classicBagCount;
    if (!activeOrders) {
      classicBagCount = 0;
    } else {
      classicBagCount = activeOrders
        .flatMap(({ orderGroups }) => orderGroups)
        .reduce((count, { items }) => count + items.length, 0);
    }

    if (this.props.isSubscriber) {
      return classicBagCount + (membershipBag?.items?.length || 0);
    }

    return classicBagCount;
  }

  logMenuItemClick = menuItemName => {
    ActionLogger.logAction({
      objectType: "top_nav",
      action: "click_nav_icon",
      icon_name: menuItemName,
    });
  };

  onUserMenuIconClick = e => {
    if (this.props.isWideWidth) {
      return;
    }
    e?.preventDefault();

    this.props.toggleMobileNavAccountMenu();
    this.logMenuItemClick("user_account");

    return false;
  };

  renderAccountMenu() {
    const { hasProfile, isSubscriber, renderAccountCredits, renderAccountGreeting } = this.props;

    if (!hasProfile) {
      return null;
    }

    const accountLink = isSubscriber
      ? "/user/settings?nav_location=user_account&amp;action=click_membershipSettings&amp;object_type=top_nav#membership_settings"
      : "/user/orders?nav_location=user_account&amp;action=click_rentalHistory&amp;object_type=top_nav#rental_history";

    return (
      <li
        id="user-menu-item-account"
        className="user-menu-item with-menu"
        ref={el => {
          this.$accountMenu = el;
        }}
        data-test-id="user-menu-account-icon">
        <a className="user-menu-item-title hd-reviews" href={accountLink} onClick={this.onUserMenuIconClick}>
          <span className="user-menu-item-title-label">{renderAccountGreeting()}</span>
          {renderAccountCredits()}
        </a>
        <div className="sub-menu">
          <a
            className="sub-menu-account"
            href="/user/orders?nav_location=user_account&amp;action=click_rentalHistory&amp;object_type=top_nav#rental_history">
            {this.props.renderAccountGreeting()}
          </a>
          {this.props.renderAccountSubMenuOptions()}
          <LogoutButton
            actionData={{
              referring_url: getLocationPathname(),
              objectType: "top_nav",
              action: "click_menu_item",
              category_depth: "1",
              category_name: "sign_out",
            }}
            className="logout"
            bold={false}
            destination={Routes.Home}
            id="sign-out"
            searchParams={
              new URLSearchParams({ nav_location: "user_account", action: "click_sign_out", object_type: "top_nav" })
            }
            underline={false}>
            Sign Out
          </LogoutButton>
        </div>
      </li>
    );
  }

  renderShortlistsMenuOptions() {
    const { shortlists = [] } = this.props;

    const nonDefaultShortlists = shortlists.filter(shortlist => shortlist.id !== ALL_HEARTS_SHORTLIST_ID);
    if (!this.props.hasProfile) {
      return null;
    }
    const shortlistOptions = nonDefaultShortlists.slice(0, 5).map(shortlist => {
      return (
        <a
          key={`shortlist-id-${shortlist.id}`}
          href={`/shortlist/${shortlist.id}?nav_location=my_hearts&amp;action=click_heartSubmenu&amp;object_type=top_nav`}>
          {shortlist.name}
        </a>
      );
    });
    //Entering view All hearts from dropdown lands the user on a view all grid filtered by their hearts with the Visual Nav suppressed. Suppressing the Visual Nav is dependent on the action click_heartSubmenu
    return (
      <div id="user-menu-item-sub-menu-shortlists" className="sub-menu">
        <a
          key="all-hearts"
          href="/products?filters[hearts][]=true&nav_location=my_hearts&amp;action=click_heartSubmenu&amp;object_type=top_nav">
          Hearts
        </a>
        <a key="hearts-on-final-sale" href="/clearance/products?filters[hearts][]=true&amp;source=top_nav">
          Hearts on Sale
        </a>
        {shortlistOptions}
        <a
          key="all-shortlists"
          href="/shortlist?nav_location=my_hearts&amp;action=click_heartSubmenu&amp;object_type=top_nav">
          All Shortlists
        </a>
      </div>
    );
  }

  renderShortlistsMenu() {
    const href = "/shortlist?nav_location=my_hearts&amp;action=click_heartCount&amp;object_type=top_nav";
    return (
      <li id="user-menu-item-hearts" className="user-menu-item" data-test-id="user-menu-hearts-icon">
        <a className="user-menu-item-title" href={href} onClick={e => this.onShortlistHeartClick(e, href)}>
          <span className="user-menu-item-title-label with-heart">
            <HeartIcon />
          </span>
        </a>
        {this.renderShortlistsMenuOptions()}
      </li>
    );
  }

  onShortlistHeartClick = (eventArgs, href) => {
    const { userData, showAuthModal } = this.props;

    if (isAnonymous(userData)) {
      eventArgs.preventDefault();
      showAuthModal({
        destination: href,
        triggeredBy: HEAP_AUTH_TRIGGER_TYPES.CLICK_NAV_HEART_ICON,
      });

      return false;
    }
    ActionLogger.inferAction({
      objectType: "top_nav",
      action: "click_nav_icon",
      icon_name: "hearts",
    });
  };

  onExpressBagClick = () => {
    const { userData, showAuthModal, fireWithUserData } = this.props;

    if (isAnonymous(userData)) {
      showAuthModal({
        callback: () => {
          /**
           * DV [EXPLANATION] 9/14/23: CE-1376 This solves an issue where a logged-out user clicks the bag icon,
           * gets the auth modal and signs in, but the bag doesn't load - this is because at the time of the bag
           * opening, userData was still showing as anonymous.
           *
           * Here, we attach opening the bag to the fireWithUserData hook to ensure the user login is instantiated
           * before the bag opens.
           */
          fireWithUserData(() => {
            this.openBag();
          });
        },
        triggeredBy: HEAP_AUTH_TRIGGER_TYPES.CLICK_NAV_BAG_ICON,
      });
    } else {
      this.openBag();
    }
    this.logMenuItemClick("bag");
  };

  openBag = () => {
    const { openBagWithDispatch } = this.props;

    ActionLogger.logAction({
      object_type: analytics.OBJECT_TYPE.SHOPPING_BAG,
      action: "click_shopping_bag",
      nav_location: "user_account",
    });

    openBagWithDispatch();
  };

  renderCart() {
    const { ceBagCount } = this.props;
    const count = ceBagCount?.total;

    return (
      <li className="user-menu-item" id="menu-item-my-bag">
        <button
          className="user-menu-item-title user-menu-item-cart"
          data-test-id="menu-item-my-bag"
          onClick={this.onExpressBagClick}>
          <div className="cart">{count > 0 && <span className="cart-count">{count}</span>}</div>
        </button>
      </li>
    );
  }

  logClickUserMenuLink = eventArgs => {
    const linkText = eventArgs?.target?.textContent;

    if (linkText) {
      const loggableString = linkText.trim().replace(/\s+/g, "_").toLowerCase();
      ActionLogger.inferAction({
        object_type: "top_nav",
        action: "click_menu_item",
        category_depth: "2",
        category_name: loggableString,
      });
    }
  };

  logKeyDownUserMenuLink = keyboardEventArgs => {
    if (keyboardEventArgs?.key?.toLowerCase() === "enter") {
      this.logClickUserMenuLink(keyboardEventArgs);
    }
  };

  renderUserMenuTooltips() {
    if (this.props.hasProfile) {
      return <UserMenuTooltips target={this.$accountMenu} />;
    }
  }

  shouldHideBagAndShortlistIcons() {
    const { browser, userData } = this.props;

    return isAnonymous(userData) && browser?.isMobileViewport;
  }

  toggleMobileNav = () => {
    const closeMobileAccountMenu = this.props.isMobileAccountMenuExpanded;
    const nextState = !(this.props.isMobileNavExpanded || this.props.isMobileAccountMenuExpanded);
    this.props.toggleMobileNav(nextState);

    if (closeMobileAccountMenu) {
      this.props.toggleMobileNavAccountMenu(false);
    }

    ActionLogger.logAction({
      objectType: "mweb_header_search_icon",
      action: "click_header_search_icon",
      icon_name: "mweb_header_search_icon",
      state: closeMobileAccountMenu ? "close" : "open",
    });
  };

  shouldRenderAuthMobileSemanticSearchIcon() {
    // Render ILO user menu icon
    const { browser, flagsAndExperiments, hasProfile } = this.props;

    return (
      flagsAndExperiments?.[flagsAndExperimentNames.DISCO_SEARCH_AI_MVP_STOREFRONT_UX] &&
      hasProfile &&
      browser?.isMobileViewport
    );
  }

  shouldRenderAnonMobileSemanticSearchIcon() {
    // Render ILO sign-in button
    const { browser, flagsAndExperiments, hasProfile } = this.props;
    return (
      flagsAndExperiments?.[flagsAndExperimentNames.DISCO_SEARCH_AI_MVP_STOREFRONT_UX] &&
      !hasProfile &&
      browser?.isMobileViewport
    );
  }

  renderMobileSemanticSearchIcon() {
    return (
      <li id="user-menu-item-search" className="user-menu-item">
        <button className="user-menu-item-search" onClick={this.toggleMobileNav}></button>
      </li>
    );
  }

  render() {
    return (
      <>
        {/* NW [EXPLANATION] 4/19/22: the event handlers on this <ul> are intended to bubble up from click/key events on child elements */}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <ul id="user-menu" onClick={this.logClickUserMenuLink} onKeyDown={this.logKeyDownUserMenuLink}>
          {this.shouldRenderAuthMobileSemanticSearchIcon()
            ? this.renderMobileSemanticSearchIcon()
            : this.renderAccountMenu()}
          {this.shouldRenderAnonMobileSemanticSearchIcon()
            ? this.renderMobileSemanticSearchIcon()
            : this.props.renderSignInLink()}
          {!this.shouldHideBagAndShortlistIcons() && this.renderShortlistsMenu()}
          {!this.shouldHideBagAndShortlistIcons() && this.renderCart()}
        </ul>
        {this.renderUserMenuTooltips()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeOrders: state.activeOrders,
    activeOrdersLoading: state.activeOrdersLoading,
    browser: state.browser,
    ceBagCount: state.ceBagCount,
    launchDarklyExperiments: experimentsSelector(state),
    membershipBag: state.membershipBag,
    membershipState: state.membershipState,
    pageName: state.pageName,
    shortlists: state.shortlists,
    userData: state.userData,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchCEBagCount: () => dispatch(ceBagActions.getCount()),
    fetchShortlists: () => {
      dispatch(ShortlistActions.loadShortlists(ownProps.favorites, true));
    },
    fetchUserCredits: () => dispatch(fetchUserCreditsAction()),
    fetchMemberProgramChangeInfo: () => {
      dispatch(getMemberProgramChangeInfo());
    },
    openBagWithDispatch: () => dispatch(toggleBag(true)),
    showAuthModal: options => dispatch(AuthActions.showAuthModal(options)),
    switchBagTab: bagTab => dispatch(switchBagTab(bagTab)),
  };
}

export { UserMenu as UntemplatedUserMenu };
export default compose(
  withFireWithUserData,
  connect(mapStateToProps, mapDispatchToProps)
)(withUserMenuTemplate(UserMenu));
