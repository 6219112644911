import ActionTypes from "../actions/action-types.js";
import { CECheckout } from "rtr-constants";
import actions from "actions/shared-actions.js";

export const types = [
  ActionTypes.SM_BAG_ERROR,
  ActionTypes.SM_BAG_IS_LOADING,
  ActionTypes.SM_BAG_REMOVE_UNAVAILABLE_ITEM,
  ActionTypes.SM_BAG_SUCCESS,
];

const setInitialState = function (state) {
  return {
    ...state,
    smBag: {
      smBagError: "",
      smBagLoading: false,
      failedToRefreshItems: [],
      items: {
        arriving: [],
        keeping: [],
        returning: [],
      },
    },
  };
};

export function fn(state, action) {
  let newState = { ...state };
  if (!newState.smBag) {
    newState = setInitialState(newState);
  }

  const { smBag } = newState;

  switch (action.type) {
    case ActionTypes.SM_BAG_ERROR:
      newState.smBag = {
        ...smBag,
        smBagError: action.payload,
        smBagLoading: false,
      };
      break;
    case ActionTypes.SM_BAG_IS_LOADING:
      newState.smBag = {
        ...smBag,
        smBagError: "",
        smBagLoading: action.payload,
      };
      break;

    case ActionTypes.SM_BAG_SUCCESS:
      {
        const checkoutStatus = action.payload?.checkout?.checkoutStatus || "";
        newState.smBag = {
          ...action.payload,
          smBagError: "",
          smBagLoading: false,
        };
        if (
          actions.payload?.checkout &&
          checkoutStatus.toLowerCase() !== CECheckout.CheckoutStatuses.VALIDATED &&
          !action.payload?.checkout?.passivePromoError
        ) {
          newState.smBag.smBagError = CECheckout.errorCodeToCopy(checkoutStatus);
        }
      }
      break;

    case ActionTypes.SM_BAG_REMOVE_UNAVAILABLE_ITEM:
      newState.smBag = {
        ...smBag,
        failedToRefreshItems: [...smBag.failedToRefreshItems].reduce((prev, curr) => {
          if (curr?.bagItemId !== action.payload) {
            prev.push(curr);
          }
          return prev;
        }, []),
      };

      break;

    default:
      return newState;
  }

  return newState;
}

export default {
  types: types,
  fn: fn,
};
