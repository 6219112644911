import React, { createContext, useContext, useEffect } from "react";
import { childrenPropType } from "../propTypes";
import { useCookies } from "react-cookie";
import {
  rtrIdCookieKey,
  rtrSessionIdCookieKey,
  setRtrIdCookie,
  setRtrSessionIdCookie,
} from "helpers/storefront-migration-helpers";
import { useDispatch } from "react-redux";
import { writeUxUserData } from "../../actions/on-initialization-actions";

const RtrSessionContext = createContext({
  rtrId: null,
  rtrSessionId: null,
});

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useRtrSession() {
  return useContext(RtrSessionContext);
}

// I'd love to default rtrId and rtrSessionId here, but we have to consider
// full page caching
// async function getInitialReduxState() {}

// Allows setting explicit context values
export const BaseProvider = RtrSessionContext.Provider;

export function RtrSessionProvider({ children }) {
  const [cookies, setCookie] = useCookies([rtrIdCookieKey, rtrSessionIdCookieKey]);
  const { [rtrIdCookieKey]: rtrId, [rtrSessionIdCookieKey]: rtrSessionId } = cookies;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!rtrId) setRtrIdCookie(setCookie);
    if (!rtrSessionId) setRtrSessionIdCookie(setCookie);

    dispatch(
      writeUxUserData({
        rtrSession: {
          rtrId,
          rtrSessionId,
        },
      })
    );
  }, []); // should this update ever?

  return <RtrSessionContext.Provider value={{ rtrId, rtrSessionId }}>{children}</RtrSessionContext.Provider>;
}

// RtrSessionProvider.getInitialReduxState = getInitialReduxState;

RtrSessionProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...RtrSessionContext,
  Provider: RtrSessionProvider,
};
