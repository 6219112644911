import _ from "underscore";
var objectToQueryString = function (obj, prefix) {
  return _.map(obj, function (value, key) {
    var _key = key;

    if (prefix) {
      if (_.isNumber(key)) {
        _key = "";
      }

      _key = prefix + "[" + _key + "]";
    }

    if (_.isObject(value)) {
      return objectToQueryString(value, _key);
    }

    return encodeURIComponent(_key) + "=" + encodeURIComponent(value);
  }).join("&");
};

export default objectToQueryString;
