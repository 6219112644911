import _ from "underscore";
import ActionTypes from "../actions/action-types.js";
var types, fn;

types = [ActionTypes.WINDOW_RESIZE];

fn = function (state, action) {
  var browserState = _.extend({}, state.browser);
  browserState.isMobileViewport = action.payload;

  if (action.type === ActionTypes.WINDOW_RESIZE) {
    return _.extend({}, state, {
      browser: browserState,
    });
  }

  return state;
};

export default {
  types: types,
  fn: fn,
};
