import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listSerializationHelper, objectSerializationHelper } from "helpers/next-helpers";
import $ from "clients/RawClient";

const initialState = {
  reviewsThumbnailPhotos: [],
};

export const fetchReviewsThumbnailPhotos = createAsyncThunk(
  "reviewsThumbnailPhotos/fetchReviewsThumbnailPhotos",
  async params => {
    try {
      const productId = params.productId;
      delete params.productId;

      const reviewsThumbnailPhotos = await $.ajax({
        type: "GET",
        url: `/api/products/${productId}/cover-photos`,
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
        data: { ...params },
      });

      return Promise.resolve(reviewsThumbnailPhotos);
    } catch (e) {
      return Promise.reject([]);
    }
  }
);

export const reviewThumbnailPhotoSlice = createSlice({
  name: "reviewsThumbnailPhotos",
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchReviewsThumbnailPhotos.fulfilled, (state, action) => {
      const newReviewThumbnailPhotosObj = {};
      const deepCopiedPayload = objectSerializationHelper(action.payload);
      if (state.reviewsThumbnailPhotos?.moments?.[0]?.styleName === deepCopiedPayload?.moments?.[0]?.styleName) {
        newReviewThumbnailPhotosObj.moments = [
          ...listSerializationHelper(state.reviewsThumbnailPhotos?.moments || []),
          ...(deepCopiedPayload?.moments || []),
        ];

        newReviewThumbnailPhotosObj.totalCount = deepCopiedPayload.totalCount;
        state.reviewsThumbnailPhotos = newReviewThumbnailPhotosObj;
      } else {
        state.reviewsThumbnailPhotos = deepCopiedPayload;
      }
    });
  },
});

/**
 * This value isn't used anywhere.
 * It's only defined because ReduxApp expects reducers to have a "types" property
 */
export const types = ["reviewsThumbnailPhotos/fetchReviewsThumbnailPhotos/fulfilled"];

export default {
  types: types,
  fn: reviewThumbnailPhotoSlice.reducer,
};
