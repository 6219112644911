import _ from "underscore";
import ActionTypes from "actions/action-types.js";

var types = [
  ActionTypes.ADD_GIFT_CARD_TO_BAG_FAILURE,
  ActionTypes.ADD_GIFT_CARD_TO_BAG_BEGIN,
  ActionTypes.ADD_GIFT_CARD_TO_BAG_SUCCESS,
];

function modifyGiftCard(state, action) {
  const nextState = _.extend({}, state.giftCardStatus);
  switch (action.type) {
    case ActionTypes.ADD_GIFT_CARD_TO_BAG_BEGIN:
      nextState.sending = true;
      break;

    case ActionTypes.ADD_GIFT_CARD_TO_BAG_SUCCESS:
      nextState.sending = false;
      nextState.submitSuccess = true;
      break;

    case ActionTypes.ADD_GIFT_CARD_TO_BAG_FAILURE:
      nextState.sending = false;
      nextState.submitSuccess = false;
      break;
  }
  return nextState;
}

const fn = (state, action) => {
  return _.extend({}, state, { giftCardStatus: modifyGiftCard(state, action) });
};

export default {
  types: types,
  fn: fn,
};
