import _ from "underscore";
import ActionTypes from "../actions/action-types.js";
var types, preservedKeys, fn;

// RECEIVE_PROPS replaces the whole state with the one passed in the action.
// TODO: Use prop presets to remove the hard-coded preservedKeys here.

types = [
  ActionTypes.RECEIVE_PARTIAL_PROPS,
  ActionTypes.RECEIVE_PROPS,
  ActionTypes.HIDE_GENERIC_MODAL,
  ActionTypes.TRIGGER_GENERIC_MODAL,
];

preservedKeys = ["displayedModal", "errors", "favorites", "openFilters", "shortlists", "workingFilters"];

fn = function (state, action) {
  var modalNamespaceHash = {};

  switch (action.type) {
    case ActionTypes.RECEIVE_PROPS:
      // query params should be sourced from the HoC
      delete action.payload?.queryParams;
      return _.extend({}, action.payload, _.pick(state, preservedKeys));

    case ActionTypes.RECEIVE_PARTIAL_PROPS:
      // query params should be sourced from the HoC
      delete action.payload?.queryParams;
      return _.extend({}, state, action.payload);

    case ActionTypes.HIDE_GENERIC_MODAL:
      modalNamespaceHash[action.payload.modalKeyName] = false;
      return _.extend({}, state, { genericModal: modalNamespaceHash });

    case ActionTypes.TRIGGER_GENERIC_MODAL:
      modalNamespaceHash[action.payload.modalKeyName] = true;

      return _.extend({}, state, { genericModal: modalNamespaceHash });
  }
};

export default {
  types: types,
  fn: fn,
};
