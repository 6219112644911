import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addDays, isSameDay, isPast, isToday, max } from "date-fns";

import ActionLogger from "action-logger";
import { getOneTimeModals, updateOneTimeModal, isDismissedHelper } from "actions/one-time-modals-actions";
import ReviewActions from "actions/reviews-actions";
import { displayModal } from "actions/shared-actions";
import { membershipStatePropType } from "components/propTypes";
import AtomFullWidthButton from "components/source/atoms/atom-full-width-button";
import SwipeableCarousel from "components/source/shared/carousels/swipeable-carousel";
import RtrImage from "components/source/shared/rtr-image";
import ClosableModal from "components/source/shared/closable-modal";
import { parseISOWithoutTime } from "helpers/date-helpers";
import { analytics } from "rtr-constants";
import { MODAL_NAMES } from "rtr-constants/modalNames";
import MembershipHelpers from "helpers/membership-helpers";

export class ReviewNudgeModal extends React.Component {
  static propTypes = {
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    dismissModal: PropTypes.func,
    showSelf: PropTypes.func,
    reviewItems: PropTypes.array,
    modalHeader: PropTypes.string,
    modalSubHeader: PropTypes.string,
    displayReviewModal: PropTypes.func.isRequired,
    membershipState: membershipStatePropType,
    fetchOneTimeModals: PropTypes.func,
    oneTimeModals: PropTypes.arrayOf(PropTypes.object),
    postOneTimeModal: PropTypes.func,
  };

  static defaultProps = {
    modalHeader: "How’s your shipment going?",
    modalSubHeader: "Review it to help others find their best fit.",
  };

  static modalName = MODAL_NAMES.REVIEW_NUDGE;
  static oneTimeModalId = "REVIEW_AFTER_ITEMS_AT_HOME";
  static ANALYTICS_SOURCE = "review_nudge_modal";

  state = {
    setIndex: 0,
    index: 0,
    keepModalOpen: false,
    shouldCallReviewNudgeModal: true,
  };

  componentDidMount() {
    const { fetchOneTimeModals, membershipState } = this.props;

    //sbenedict 7/2024
    //We need this for Ruby pages, since they render SubscriptionSitewideComponents for all users
    //Otherwise we'll try to fetch /oneTimeModals for anonymous traffic, which will return a 401
    if (!MembershipHelpers.isActiveMembership(membershipState)) {
      return;
    }

    fetchOneTimeModals();
    this.callReviewNudgeModal();
  }

  modalViewLogData() {
    const { membershipState } = this.props;
    return {
      object_type: analytics.OBJECT_TYPE.REVIEW_NUDGE_ITEMS_AT_HOME,
      action: analytics.ACTION_TYPE.VIEW,
      user_id: membershipState?.id,
      membership_id: membershipState?.membershipId,
      booking_ids: membershipState?.membershipItems?.map(item => item.bookingId),
    };
  }

  callReviewNudgeModal() {
    const { membershipState = {}, oneTimeModals, displayedModal, showSelf } = this.props;

    if (oneTimeModals && !displayedModal && !isDismissedHelper(oneTimeModals, this.constructor.oneTimeModalId)) {
      if (membershipState?.membershipItems?.length > 0) {
        const rentalBeginDates = membershipState.membershipItems.map(item => parseISOWithoutTime(item.rentalBeginDate));
        const mostRecentDate = max(rentalBeginDates);
        const futureDate = addDays(mostRecentDate, 4);

        if (isToday(futureDate) || isPast(futureDate)) {
          const recentRentedItems = membershipState.membershipItems
            .filter(item => {
              return isSameDay(parseISOWithoutTime(item.rentalBeginDate), mostRecentDate);
            })
            .slice(0, 6);

          if (recentRentedItems.length > 0) {
            showSelf(recentRentedItems);
            ActionLogger.logAction(this.modalViewLogData());
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { oneTimeModals, displayedModal } = this.props;

    if (prevProps.displayedModal !== this.constructor.modalName && displayedModal === this.constructor.modalName) {
      // This is to keep the modal open under the reviews modal
      this.setState({
        ...this.state,
        keepModalOpen: true,
      });
    }

    if (this.state.shouldCallReviewNudgeModal && oneTimeModals && !displayedModal) {
      this.callReviewNudgeModal();
      this.setState({
        ...this.state,
        shouldCallReviewNudgeModal: false,
      });
    }
  }

  getCarouselIndex = carouselData => {
    this.setState({
      index: carouselData.currentIndex,
      totalPages: carouselData.totalPages,
    });
  };

  canMoveForward = () => (this.state.index ? this.state.index < this.state.totalPages - 1 : true);

  moveForward = () => {
    this.setState({
      ...this.state,
      setIndex: this.state.setIndex + 1,
    });
  };

  renderItemSlides() {
    const { reviewItems } = this.props;

    return reviewItems
      .map((item, i) => {
        if (!item?.imgSrc) {
          return null;
        }

        return (
          <div key={i}>
            <RtrImage src={item.imgSrc} alt={`${item.designerName} - ${item.displayName}`} />
          </div>
        );
      })
      .filter(slide => !!slide);
  }

  onReviewClick() {
    const { displayReviewModal, reviewItems, membershipState } = this.props;
    const reviewItem = reviewItems?.[this.state.index];

    ActionLogger.logAction({
      object_type: analytics.OBJECT_TYPE.REVIEW_NUDGE_ITEMS_AT_HOME,
      action: analytics.ACTION_TYPE.CLICK_CTA,
      user_id: membershipState?.id,
      membership_id: membershipState?.membershipId,
      booking_id: reviewItem?.bookingId,
    });
    displayReviewModal(reviewItem?.styleName, this.constructor.ANALYTICS_SOURCE);
  }

  onDismissModal = () => {
    const { dismissModal, postOneTimeModal } = this.props;

    this.setState({
      ...this.state,
      keepModalOpen: false,
    });

    postOneTimeModal(this.constructor.oneTimeModalId, true);

    dismissModal();
  };

  render() {
    const { displayedModal, modalHeader, modalSubHeader, reviewItems, membershipState } = this.props;
    const isOpen = displayedModal === this.constructor.modalName;

    if (!reviewItems?.find(item => item?.designerName)) {
      return null;
    }

    if (membershipState?.membershipTermNumber <= 1) {
      return;
    }

    return (
      <ClosableModal
        isOpen={isOpen || this.state.keepModalOpen}
        onRequestClose={() => this.onDismissModal()}
        optionalClass="review-nudge-modal"
        overlayClassName="review-nudge-modal-overlay">
        <h3>{modalHeader}</h3>
        <p className="universal-medium">{modalSubHeader}</p>
        {
          <SwipeableCarousel
            setIndex={this.state.setIndex}
            getCarouselIndex={this.getCarouselIndex}
            pageSize={1}
            mobilePageSize={1}
            displayCarouselProgressDots={true}>
            {this.renderItemSlides()}
          </SwipeableCarousel>
        }
        <AtomFullWidthButton onClick={() => this.onReviewClick()} buttonText="Review Now" />
      </ClosableModal>
    );
  }
}

const mapStateToProps = state => {
  return {
    displayedModal: state.displayedModal,
    reviewItems: state.reviewNudgeModal?.items,
    modalHeader: state.reviewNudgeModal?.header,
    modalSubHeader: state.reviewNudgeModal?.subHeader,
    oneTimeModals: state.oneTimeModals,
    membershipState: state.membershipState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dismissModal: () => {
      dispatch(displayModal(false));
    },
    displayReviewModal: (styleName, popupSource) => {
      dispatch(ReviewActions.displayReviewUploadModal({ styleName, popupSource }));
    },
    showSelf: reviewItems => {
      dispatch(ReviewActions.reviewNudgeModal({ items: reviewItems }));
    },
    fetchOneTimeModals: () => {
      dispatch(getOneTimeModals());
    },
    postOneTimeModal: (modalId, isDismissed) => {
      dispatch(updateOneTimeModal(modalId, isDismissed));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewNudgeModal);
