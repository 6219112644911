import ClosableModal from "components/source/shared/closable-modal";
import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import { useBrowser } from "components/layout/BrowserContext";
import useDrawerDrag from "./useDrawerDrag";
import LoadingSpinner from "components/source/new_taxonomy/loading-spinner";
import styles from "./modal-drawer.module.scss";
import { HEAP_DRAWER_TRIGGERS } from "helpers/heap-helpers";

export default function ModalDrawer({
  isOpen = false,
  peekHeight = 0,
  handleClose = () => {},
  children = null,
  setIsFullScreen = () => {},
  desktopWidth = "68%",
  showLoadingState = false,
}) {
  const browser = useBrowser();
  const [drawerHeight, setDrawerHeight] = useState(0); // Initial height
  const drawerRef = useRef(null);

  const { isFullScreen, animationFinished } = useDrawerDrag(
    drawerRef,
    drawerHeight,
    setDrawerHeight,
    handleClose,
    browser
  );

  useEffect(() => {
    if (isOpen) {
      setDrawerHeight(peekHeight);
    }
  }, [isOpen, peekHeight]);

  useEffect(() => {
    if (isFullScreen) {
      setIsFullScreen(true);
    }
  }, [isFullScreen]);

  // When the drawerHeight gets set from the child component on mWeb, is taken account BEFORE the animation.
  // The animation that happens from actions like swiping up on mWeb could trigger a different height from the view port
  // (like showing/hiding url bar) and could potentially cut the height off of the drawer content like the top of a product image
  // so let's update the max height to be recorded AFTER the animation is finished. This would mean a slight animation
  // that adjusts the drawer content height, but necessary to show the full drawer content
  const getMaxHeight = () => {
    if (browser?.isMobileViewport) {
      if (!isFullScreen && !animationFinished) {
        return typeof drawerHeight === "string" && drawerHeight?.includes("vh") ? drawerHeight : drawerHeight + "px";
      }
      return window.innerHeight + "px";
    }
    return "unset";
  };

  const getOverFlowY = () => {
    if (!browser?.isMobileViewport) {
      return "unset";
    } else if (!isFullScreen && !animationFinished) {
      return "hidden";
    } else {
      return "auto";
    }
  };

  return (
    <>
      <ClosableModal
        isOpen={isOpen}
        isMobileViewport={browser?.isMobileViewport}
        optionalClass={`${styles["modal-drawer"]} ${isFullScreen ? "modal-close-circle" : ""} `}
        optionalWrapperClass={styles["modal-drawer-wrapper"]}
        onRequestClose={() => handleClose(HEAP_DRAWER_TRIGGERS.CLOSE)}
        additionalStyles={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: browser?.isMobileViewport ? "unset" : "center",
          },
          content: {
            top: "auto",
            left: "auto",
            MozTransform: "none",
            MsTransform: "none",
            WebkitTransform: "none",
            transform: "none",
            margin: "auto",
            padding: 0,
            width: browser?.isMobileViewport ? "100%" : `${desktopWidth}`,
            height: browser?.isMobileViewport ? "unset" : "100%",
            right: 0,
            overflowY: getOverFlowY(),
            bottom: browser?.isMobileViewport ? 0 : "unset",
            borderRadius: browser?.isMobileViewport && !isFullScreen ? "10px 10px 0 0" : "0",
          },
        }}>
        <div
          id="modal-drawer"
          data-test-id="modal-drawer"
          className={styles["modal-drawer-content"]}
          ref={drawerRef}
          style={{
            maxHeight: getMaxHeight(),
            overflowY: getOverFlowY(),
          }}>
          {browser?.isMobileViewport && (
            <div
              data-test-id="drag-handle"
              className={`${styles["drag-handle"]} ${isFullScreen ? styles["drag-handle-hide"] : ""}`}>
              <div className={styles["drag-handle-inner"]}></div>
            </div>
          )}
          {children}
        </div>
      </ClosableModal>
      {showLoadingState && (
        <div className={styles.spinner}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

ModalDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  setIsFullScreen: PropTypes.func.isRequired,
  peekHeight: PropTypes.number,
  desktopWidth: PropTypes.string,
  showLoadingState: PropTypes.bool,
};
