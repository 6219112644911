export const EVENTS_CUSTOM_CASTLE = {
  ADD_TO_BAG: "Add to Bag",
  APPLY_GIFT_CARD: "Apply Gift Card",
  APPLY_PROMO_CODE: "Apply Promo Code",
  PAYMENT_METHOD_UPDATE: "Payment Method Update",
  PLACE_ORDER: "Place Order",
  REMOVE_FROM_BAG: "Remove from Bag",
  REMOVE_PROMO_CODE: "Remove Promo Code",
  SHIPPING_ADDRESS_UPDATE: "Shipping Address Update",
  USER_PROFILE_UPDATE: "User Profile Update",
};
