import { parse as qsParse } from "qs";
import { listSerializationHelper } from "helpers/next-helpers";
import { ContainerPageAboveTheFoldContentModuleCount, fallbackZip } from "rtr-constants";

/**
 * Visible for testing.
 */
export const RENTAL_CAROUSEL_INCLUDES = "skus,skus.rentalAvailabilities,price";
/**
 * Visible for testing.
 */
export const CLEARANCE_CAROUSEL_INCLUDES = "skus,skus.clearanceAvailabilities,price";
export const BUY_NOW_CAROUSEL_INCLUDES = "skus,skus.unlimitedAvailabilities,price";
const CAROUSEL_PRODUCT_FIELDS = "displayName,images,designer,retailPrice";
const CAROUSEL_BUY_NOW_PRODUCT_FIELDS = "displayName,images,designer,retailPrice,purchasePrice";
/**
 * Visible for testing.
 */
export const CAROUSEL_DEFAULT_SORT = "contextualRecommended";

/**
 * Product payloads are large and affect performance
 * Limit the serverside payload to 7 products (the minimum number for the carousel to render).
 * We will defer the loading of remaining products or below-the-fold carousels to the client.
 */
const SERVERSIDE_CAROUSEL_PRODUCT_LIMIT = 7;

export const parseQueryIntoGodmotherRequest = (queryData, isServerside, shouldRequestFullProductsList) => {
  if (!queryData) {
    return;
  }

  const parsedQuery = mapQueryStringFiltersToGodmotherFilters(queryData);
  const isBuyNow = queryData?.buyNow === "true";

  let limit, include;

  if (isServerside) {
    limit = SERVERSIDE_CAROUSEL_PRODUCT_LIMIT;
  } else if (shouldRequestFullProductsList) {
    limit = queryData.limit;
  } else {
    limit = queryData.limit - SERVERSIDE_CAROUSEL_PRODUCT_LIMIT;
  }

  if (isBuyNow) {
    include = BUY_NOW_CAROUSEL_INCLUDES;
  } else if (queryData.clearance) {
    include = CLEARANCE_CAROUSEL_INCLUDES;
  } else {
    include = RENTAL_CAROUSEL_INCLUDES;
  }

  const offset = isServerside || shouldRequestFullProductsList ? 0 : SERVERSIDE_CAROUSEL_PRODUCT_LIMIT;

  return {
    clearance: queryData.clearance,
    fields: {
      product: isBuyNow ? CAROUSEL_BUY_NOW_PRODUCT_FIELDS : CAROUSEL_PRODUCT_FIELDS,
    },
    filter: {
      categories: queryData.category,
      ...parsedQuery.filters,
      zipCode: isBuyNow ? fallbackZip : void 0,
    },
    include,
    page: {
      limit,
      offset,
    },
    sort: CAROUSEL_DEFAULT_SORT,
    ...parsedQuery,
  };
};

export const formatServersideProductResponse = (products, queryData) => {
  return {
    products: serializableProductProps(products),
    title: queryData.title,
    gridQueryUrlString: queryData.gridUrl,
    extraPixelData: queryData.extraPixelData ?? {},
    alternateCarouselId: queryData.alternateCarouselId ?? null,
    extraKey: queryData.key ?? null,
    priceDisplayOverrides: queryData.priceDisplayOverrides ?? {},
  };
};

/**
 * Use a slimmer products DTO when sent in the initial payload from the server.
 * Godmother returns some data like skus that we don't currently use.
 */
const serializableProductProps = products => {
  return listSerializationHelper(
    products.map(p => {
      const { designer, displayName, id, images, price, retailPrice } = p;

      return { designer, displayName, id, images, price, retailPrice };
    })
  );
};

/**
 * Storefront Ruby had special (and complex) logic to handle filter mapping on query params.
 * The CMS entries for Grid Query Carousels expect to be able to use these.
 * All of the below filters could be used in isolation in Ruby but require products be appended against Godmother.
 * @example "?filter[designer]=badgley_mischka" => "?filter[products.designer]=badgley_mischka"
 */
const FACET_FILTER_VALUES = [
  "colors",
  "designer",
  "formality",
  "occasions",
  "embellishments",
  "bodyTypes",
  "length",
  "neckline",
  "sleeve",
];

const appendFacetFilterPrefix = filter => {
  return FACET_FILTER_VALUES.includes(filter) ? `products.${filter}` : filter;
};

const mapQueryStringFiltersToGodmotherFilters = query => {
  const parsedQuery = typeof query.query === "object" ? query.query : qsParse(query.query, { ignoreQueryPrefix: true });

  const cleanedFilters = parsedQuery.filters ?? {};

  for (const [key, value] of Object.entries(cleanedFilters)) {
    const cleanedKey = appendFacetFilterPrefix(key);

    if (cleanedKey !== key) {
      cleanedFilters[cleanedKey] = value;
      delete cleanedFilters[key];
    }
  }

  parsedQuery.filters = cleanedFilters;

  return parsedQuery;
};

/**
 * A list of components to ignore when determining the index from which we will defer loading to the client.
 * These are components that take up minimal height on the page.
 */
const MINIMAL_HEIGHT_COMPONENTS = ["AtomSpacer"];

export const getLazyLoadIndex = (contentModules, deviceType) => {
  const applicableIndex =
    deviceType === "desktop"
      ? ContainerPageAboveTheFoldContentModuleCount.DESKTOP
      : ContainerPageAboveTheFoldContentModuleCount.MOBILE;

  //This needs to be done recursively because when we bump the index, we might include more minimal height components.
  const getExcludedComponents = currentExcludedCount => {
    const newExcludedCount = contentModules
      .slice(0, applicableIndex + currentExcludedCount)
      .filter(cm => MINIMAL_HEIGHT_COMPONENTS.includes(cm.component)).length;

    return currentExcludedCount === newExcludedCount ? newExcludedCount : getExcludedComponents(newExcludedCount);
  };

  return applicableIndex + getExcludedComponents(0);
};

/**
 * Get a list of any poster image srcs for above-the-fold CMS components.
 * We use these to preload the images in Head.jsx.
 * @param {Array<any>} contentModules
 * @param {Boolean} isMobile
 * @returns {string[]} a list of device-specific image SRCs to preload
 */
export const getVideoPosterLinks = (contentModules, isMobile) => {
  if (!contentModules?.length) {
    return [];
  }

  const preloadIndex = getLazyLoadIndex(contentModules, isMobile);

  return contentModules
    .slice(0, preloadIndex)
    .map(cm => {
      const { attributes = {} } = cm;

      //A fairly common paradigm in CMS components is to render a collection of 2-3 elements, which may each contain video.
      //In that case, we want to extract those objects and check them for video keys.
      const nestedObjects = Object.values(attributes).filter(Array.isArray).flat();

      return [attributes, ...nestedObjects];
    })
    .flat()
    .filter((obj = {}) => {
      return obj.videoSrcMp4 || obj.videoSrcWebM;
    })
    .map(obj => {
      const link = isMobile
        ? obj.mobileVideoSrcMp4Poster ?? obj.mobileVideoSrcWebmPoster
        : obj.videoSrcMp4Poster ?? obj.videoSrcWebmPoster;

      return link;
    })
    .filter(link => link);
};
