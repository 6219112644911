import _ from "underscore";
import tryCatchLog from "helpers/try-catch";
import { URL_ORIGIN_ALLOW_LIST } from "rtr-constants";

let remoteEnds = {};
const messageHandlers = {};

if (typeof window !== "undefined") {
  window.addEventListener("message", function (e) {
    if (
      !Object.values(remoteEnds).includes(e.source) ||
      e.origin !== window.location.origin ||
      !URL_ORIGIN_ALLOW_LIST.includes(e.origin)
    ) {
      // Source isn't from other side, so exit out.
      // @see {https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns}
      return;
    }

    const { remoteKey, action, payload } = e.data;

    // There needs to be a handler(s) for an action.
    if (messageHandlers[remoteKey]?.[action]) {
      // Iterate through handler callback function(s), and attempt to invoke each one.
      _.each(messageHandlers[remoteKey][action], handler => {
        tryCatchLog(_.partial(handler, action, payload));
      });
    }
  });
}

const exported = {
  addHandler(remoteKey, action, cb) {
    if (!messageHandlers[remoteKey]) {
      messageHandlers[remoteKey] = {};
    }

    if (!messageHandlers[remoteKey][action]) {
      messageHandlers[remoteKey][action] = [];
    }

    messageHandlers[remoteKey][action].push(cb);
  },

  registerRemote(remoteKey, value) {
    remoteEnds = { ...remoteEnds, [remoteKey]: value };
  },

  removeHandlers(remoteKey, action) {
    if (messageHandlers[remoteKey]?.[action]?.length) {
      messageHandlers[remoteKey][action] = [];
    }
  },

  send(remoteKey, action, payload) {
    // This method should only be invoked client-side, so exit out early if the
    // `window` isn't available.
    if (typeof window === "undefined") {
      return;
    }

    const msg = { action, payload, remoteKey };
    const targetOrigin = window.location.origin;
    remoteEnds[remoteKey].postMessage(msg, targetOrigin);
  },
};

export default exported;

export const { addHandler, registerRemote, removeHandlers, send } = exported;
