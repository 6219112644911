import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RawClient from "clients/RawClient";

const initialState = {
  reviews: { totalCount: 0, moments: [] },
  error: null,
};

export const fetchReviews = createAsyncThunk("reviews/fetchReviews", async params => {
  params.status = "approved";
  try {
    const res = await RawClient.get("/api/reviews/user?" + new URLSearchParams(params || {}).toString());
    if (!res) {
      // apparently MomentWithCount returns 204 which PhotoReviewsClient
      // treats as failure, hence need this bit to show totalCount as 0
      return Promise.resolve({ totalCount: 0, moments: [] });
    }
    return res;
  } catch (e) {
    return Promise.reject([]);
  }
});

export const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchReviews.fulfilled, (state, action) => {
      state.reviews = action.payload;
    });
  },
});

/**
 * This value isn't used anywhere.
 * It's only defined because ReduxApp expects reducers to have a "types" property
 */
export const types = ["reviews/fetchReviews/fulfilled"];

export default {
  types: types,
  fn: reviewSlice.reducer,
};
