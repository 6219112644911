import ActionTypes from "../actions/action-types";

const types = [ActionTypes.SMART_NAV_CONTENT_SUCCESS, ActionTypes.SMART_NAV_MOBILE_CONTENT_SUCCESS];

const getSmartNav = (state, action) => {
  let content;
  const { siteNavigation } = state;
  switch (action.type) {
    case ActionTypes.SMART_NAV_CONTENT_SUCCESS:
      content = action.payload?.attributes?.content;
      return { ...content, isSmart: true };
    default:
      return siteNavigation || null;
  }
};

const getSmartNavMobile = (state, action) => {
  let content;
  const { siteNavigationMobile } = state;
  switch (action.type) {
    case ActionTypes.SMART_NAV_MOBILE_CONTENT_SUCCESS:
      content = action.payload?.attributes?.content;
      return { ...content, isSmart: true };
    default:
      return siteNavigationMobile || null;
  }
};

const fn = (state, action) => {
  const siteNavigation = getSmartNav(state, action);
  const siteNavigationMobile = getSmartNavMobile(state, action);
  return { ...state, siteNavigation, siteNavigationMobile };
};

export default {
  types: types,
  fn: fn,
};
