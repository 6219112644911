import { Base, ModelFactory, Unknown } from "@rtr/godmother";
import { fetchWithTimeout } from "helpers/api-helpers";
import { handle401Response } from "helpers/auth-helpers";

const accessTokenRefreshMiddleware = function (_ModelClass, _method, fetch) {
  return (url, options) => {
    return fetch(url, options).then(async response => {
      if (response.status === 401) {
        const getRetryRequest = () => fetch(url, options);
        return handle401Response(response, url, getRetryRequest);
      }

      return response;
    });
  };
};

export function configure(optionOverrides = {}) {
  Base.configureAdapter({
    baseUrl: process.env.CONFIG__GODMOTHER__HOST,
    fetch: fetchWithTimeout,
    defaultOptions: {
      timeout: 30000, // process.env.CONFIG__GODMOTHER__TIMEOUT
    },
    queryStringOptions: {
      arrayFormat: "brackets",
      // Note: CAG handles string encoding
      encode: false,
    },
    ...optionOverrides,
  });
}

export function configureClientSide() {
  configure({
    baseUrl: "/api/godmother", // proxy through Next until GM supports cors
    middleware: { accessTokenRefreshMiddleware },
  });
}

// For any models provided by Godmother that do not yet had a JS/TS model
// defined, use Unknown. Otherwise, an error will be thrown.
ModelFactory.FallbackType = Unknown;
