import { SemanticSearchBarPlaceholderOptions } from "rtr-constants";

const semanticSearchPlaceholderText = aiRotatingPrompts => {
  const rotatingPrompts =
    Array.isArray(aiRotatingPrompts) && aiRotatingPrompts?.length > 0
      ? aiRotatingPrompts
      : SemanticSearchBarPlaceholderOptions;
  return `Try "${rotatingPrompts[Math.floor(Math.random() * rotatingPrompts.length)]}"`;
};

export default semanticSearchPlaceholderText;
