import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import ActionLogger from "action-logger";
import withUserMenuTemplate from "./user-menu-template";
import { withFeatureFlags, featureFlagsPropType, Flags } from "components/source/hoc/with-feature-flags";
import Routes, { HELP } from "routes";
import { addCacheBusterToUrl, getLocationPathname } from "../../../helpers/location-helpers";
import LogoutButton from "../shared/auth_form/logout";

class UserMenuMobile extends React.Component {
  static propTypes = {
    featureFlags: featureFlagsPropType,
    hasProfile: PropTypes.bool.isRequired,
    isMobileAccountMenuExpanded: PropTypes.bool,
    // NW [EXPLANATION] 4/12/21: render functions implemented by UserMenuTemplate
    renderAccountCredits: PropTypes.func.isRequired,
    renderAccountGreeting: PropTypes.func.isRequired,
    renderAccountSubMenuOptions: PropTypes.func.isRequired,
    renderSignInLink: PropTypes.func.isRequired,
  };

  static defaultProps = {
    renderAccountCredits: () => {},
    renderAccountGreeting: () => {},
    renderAccountSubMenuOptions: () => {},
    renderSignInLink: () => {},
  };

  state = {
    helpMenuExpanded: false,
    accountMenuExpanded: false,
  };

  componentDidUpdate(prevProps) {
    // NW [EXPLANATION] 9/27/21: the user menu account icon (part of an external component) can toggle the mobile account menu via Redux
    if (this.props.isMobileAccountMenuExpanded !== prevProps.isMobileAccountMenuExpanded) {
      this.setState({
        accountMenuExpanded: this.props.isMobileAccountMenuExpanded,
      });
    }
  }

  renderInvites() {
    return (
      <a href="/user/invites?nav_location=user_account&amp;action=click_myInvites&amp;object_type=top_nav#invites">
        Invites
      </a>
    );
  }

  renderViewPass() {
    if (!this.props.featureFlags?.[Flags.RTR_STORE_PASS]) {
      return null;
    }

    return (
      <a href="/user/view_pass?nav_location=user_account&amp;action=click_myViewPass&amp;object_type=top_nav">
        RTR Pass
      </a>
    );
  }

  renderAccountMenu() {
    const { hasProfile } = this.props;
    const { accountMenuExpanded } = this.state;
    if (!hasProfile) {
      return null;
    }

    const accountMenuClassName = classNames("user-menu-item with-menu", {
      open: accountMenuExpanded,
    });

    return (
      <li id="user-menu-item-account-collapsed" className={accountMenuClassName}>
        <button onClick={this.toggleAccountMenuExpanded} className="user-menu-item-title hd-reviews">
          <span className="user-menu-item-title-label">
            {this.props.renderAccountGreeting()}
            {this.props.renderAccountCredits()}
          </span>
        </button>
        <div className="sub-menu">
          {this.props.renderAccountSubMenuOptions()}
          {this.renderInvites()}
          {this.renderViewPass()}
        </div>
      </li>
    );
  }

  renderSignOut() {
    if (!this.props.hasProfile) {
      return null;
    }

    return (
      <li id="user-menu-item-sign-out" className="user-menu-item">
        <LogoutButton
          actionData={{
            referring_url: getLocationPathname(),
            objectType: "mobile_nav",
            action: "click_menu_item",
            category_depth: "1",
            category_name: "sign_out",
          }}
          bold={false}
          className="user-menu-item-title-standalone"
          destination={Routes.Home}
          id="sign-out-collapsed"
          innerClassName="user-menu-item-title-label"
          searchParams={
            new URLSearchParams({ nav_location: "user_account", action: "click_sign_out", object_type: "top_nav" })
          }
          underline={false}>
          Sign Out
        </LogoutButton>
      </li>
    );
  }

  toggleAccountMenuExpanded = () => {
    this.setState({
      accountMenuExpanded: !this.state.accountMenuExpanded,
      helpMenuExpanded: false,
    });
  };

  toggleHelpMenuExpanded = () => {
    this.setState({
      accountMenuExpanded: false,
      helpMenuExpanded: !this.state.helpMenuExpanded,
    });

    ActionLogger.logAction({
      objectType: "top_nav",
      action: "click_nav_icon",
      icon_name: "help",
    });
  };

  renderHelpMenu() {
    const helpMenuClassName = classNames("user-menu-item with-menu", {
      open: this.state.helpMenuExpanded,
    });

    return (
      <li className={helpMenuClassName}>
        <button className="user-menu-item-title" onClick={this.toggleHelpMenuExpanded}>
          <span className="user-menu-item-title-label">Help</span>
        </button>
        <div className="sub-menu">
          <a href="/how_it_works?nav_location=user_account&amp;action=click_how_it_works&amp;object_type=top_nav">
            How it Works
          </a>
          <a
            href={`${addCacheBusterToUrl(
              HELP.CONTACT
            )}&amp;nav_location=user_account&amp;action=click_contact_us&amp;object_type=top_nav`}
            suppressHydrationWarning={true}>
            Contact Us
          </a>
          <a
            href={`${addCacheBusterToUrl(
              HELP.FAQ
            )}&amp;nav_location=user_account&amp;action=click_faqs&amp;object_type=top_nav`}
            suppressHydrationWarning={true}>
            FAQs
          </a>
        </div>
      </li>
    );
  }

  render() {
    return (
      <ul id="user-menu-collapsed">
        {this.renderAccountMenu()}
        {this.renderHelpMenu()}
        {this.renderSignOut()}
        {this.props.renderSignInLink()}
      </ul>
    );
  }
}

export { UserMenuMobile as UntemplatedUserMenuMobile };
export default compose(withFeatureFlags(Flags.RTR_STORE_PASS), withUserMenuTemplate)(UserMenuMobile);
