// NOTE: SF ruby uses a raw config in an erb, see _sentry.erb

const _sentry_should_log_error =
  !/HeadlessChrome/.test(window.navigator.userAgent) && !/Trident\/|MSIE/.test(window.navigator.userAgent);

function extractError(event) {
  if (event && event.exception && event.exception.values && event.exception.values.length >= 1) {
    return event.exception.values[0];
  }
  return null;
}

// various prefilters
// https://github.com/getsentry/sentry-javascript/issues/2175
// sentry issue # 1487708110
function isHoneyIssue(event) {
  if (!event.breadcrumbs) return false;

  for (var i = 0; i < event.breadcrumbs.length; i++) {
    var breadcrumb = event.breadcrumbs[i];
    if (breadcrumb.message && breadcrumb.message.includes("div#honeyContainer")) return true;
  }

  return false;
}

// filter out UnhandledRejection errors that have no information
// based on https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-828834651
// sentry issue # 2460426431 (for example, exists under a few different issue tags)
function isObjectIdNotFoundIssue(event) {
  const e = extractError(event);
  if (
    e &&
    e.type === "UnhandledRejection" &&
    /Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+/.test(e.value)
  ) {
    return true;
  }

  return false;
}

function isKnownIssue(event) {
  return isObjectIdNotFoundIssue(event) || isHoneyIssue(event);
}

/** @type {import('@sentry/nextjs').NextjsOptions} */
const SentryCommonConfig = {
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,

    // RTR custom
    /cdn\.heapanalytics\.com\/js\/replay\/libs\/latest\/auryc\.lib\.js/i,
    /googleads\.g\.doubleclick\.net\/pagead\/viewthroughconversion/i,
    /js\.go2sdk\.com\/v2\/tune\.js/i,
  ],
  beforeSend: function (event, _hint) {
    if (!_sentry_should_log_error) {
      return null;
    }
    // known issue filters
    if (isKnownIssue(event)) {
      return null;
    }
    return event;
  },
};

export default SentryCommonConfig;
