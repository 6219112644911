import ReferralHelper from "helpers/referral-helper";
import { pageTypes } from "rtr-constants";

const DEFAULT_PROPS = {
  offer: "$25 Off",
  subtitle: "First Order",
  registrationSubtitle: "Get $25 Off Your First Order",
  mobileCopy: "$25 off your 1st order with ",
  desktopCopy: "Get $25 off your first order or first month of Unlimited with ",
  details:
    "Valid on first time purchase at Rent the Runway only. Offer valid on rentals or your first month of Unlimited only with a subtotal (before tax and shipping) of $75+. Cannot be used on existing orders or combined with other offers or gift cards. Cannot be used on clearance, gift cards, gift credits, annual Pro Membership, in-store appointments or items for sale. Use promo code at checkout. Offer subject to end without notice.",
  promoCode: "FIRSTRTR25P",
};

function getPropsObjFromRtrUx() {
  try {
    return JSON.parse(RTR.UX.getAnonymousUserPromoMarkup());
  } catch (e) {
    return DEFAULT_PROPS;
  }
}

// this to help with testing... shhh!
function getPathName() {
  return window.location.pathname;
}

function getFullPathName() {
  // window.location.search is used for pages such
  // the standalone reg form page which has a path like
  // /account/register?destination=/queue/join
  // so CMS copy can be specific with url params
  return window.location.pathname + window.location.search;
}

function applyUrlSpecificProps(propsObj, pageType) {
  let pathName = getPathName();

  if (pageType === pageTypes.ACCOUNT) {
    pathName = decodeURIComponent(getFullPathName());
  }

  if (!propsObj.urls) {
    return propsObj;
  }

  const urlSpecificProps = propsObj.urls?.find(function (obj) {
    if (!obj.pathRegExp) {
      return false;
    }

    const re = new RegExp(obj.pathRegExp, "i");

    return pathName.match(re);
  });

  if (!urlSpecificProps) {
    return propsObj;
  }

  return Object.assign({}, propsObj, urlSpecificProps);
}

function referralRecipientPropsObj(baseObj = null) {
  const referralCookieProgram = ReferralHelper.getReferralProgram();
  const referralRegCopy =
    referralCookieProgram === ReferralHelper.REFERRAL_PROGRAM_CLASSIC ? "Redeem Your $30 Gift" : "";
  return Object.assign(baseObj || getPropsObjFromRtrUx(), {
    registrationSubtitle: referralRegCopy,
    registrationSubtitleMobile: referralRegCopy,
  });
}

function isReferralRecipient() {
  return Boolean(ReferralHelper.getReferralCode());
}

/**
 * sbenedict 8/2024
 * As of writing, this code determines the copy on the auth/login form.
 * The referral portions don't work on Next pages, as they rely on the RTR.UX global.
 * This can probably be simplified into basic CMS content called by the auth form component, but I'll leave for now.
 * @link {https://renttherunway.jira.com/wiki/spaces/SF/pages/284098562/Auth+Form+Copy+via+CMS} Legacy Documentation
 */
export function getNewCustomerOfferText({ basePropsObj, pageType } = {}) {
  let propsObj;

  if (isReferralRecipient()) {
    propsObj = referralRecipientPropsObj(basePropsObj);
  } else {
    // use the given props object if one is passed
    propsObj = basePropsObj || getPropsObjFromRtrUx();
    propsObj = applyUrlSpecificProps(propsObj, pageType);
  }

  return propsObj;
}
