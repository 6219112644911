import React from "react";
import { getUnixTime } from "date-fns";
import PropTypes from "prop-types";
import ActionLogger from "action-logger";
import ClosableModal from "components/source/shared/closable-modal";
import ShortlistList from "../new_taxonomy/shortlist-list";
import HeartButton from "./heart-button";
import CSSTransition from "react-transition-group/CSSTransition";
import AtomPlusInCircleIcon from "components/source/atoms/atom-plus-in-circle-icon";
import { analytics } from "rtr-constants";
import classNames from "classnames";
import { connect } from "react-redux";
import shortlistsActions from "actions/shortlists-actions";
import sharedActions from "actions/shared-actions";
import { userDataPropType } from "components/propTypes";

class UserProductRatingsContainer extends React.Component {
  state = {
    showModalTrigger: false,
    ratingValue: null,
  };

  static propTypes = {
    // from mapStateToProps
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isFavorited: PropTypes.bool.isRequired,
    isMobileViewport: PropTypes.bool,
    shortlists: PropTypes.array.isRequired,
    userData: userDataPropType,

    // from mapDispatchToProps
    fetchShortlists: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    deadEndCarousel: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,

    // passed in from parent component (required)
    product: PropTypes.object.isRequired,
    productIndex: PropTypes.number,
    location: PropTypes.oneOf([
      analytics.ACTION_LOCATIONS.CAROUSEL,
      analytics.ACTION_LOCATIONS.DRAWER,
      analytics.ACTION_LOCATIONS.GRID,
      analytics.ACTION_LOCATIONS.PDP,
      analytics.ACTION_LOCATIONS.POST_SHIP_MODAL,
    ]).isRequired,

    // passed in from parent component (optional)
    customHeartLoggingOptions: PropTypes.object,
    hidden: PropTypes.bool,
    isInCarousel: PropTypes.bool,
    children: PropTypes.node,
    deadEndSearchData: PropTypes.object,
    deadEndAPIAndCarouselData: PropTypes.object,
  };

  static defaultProps = {
    hidden: false,
    isInCarousel: false,
    customHeartLoggingOptions: {},
    shortlists: [],
  };

  // Need to handle various ways the id is passed
  // props.product.productId (grid powered by disco)
  // props.product.id        (grid)
  productId = () => {
    return this.props.product.id || this.props.product.productId;
  };

  componentDidMount() {
    this.setRatingValueFromReduxState();
  }

  componentDidUpdate(prevProps) {
    const { isFavorited } = this.props;

    if (isFavorited !== prevProps.isFavorited) {
      this.setRatingValueFromReduxState();
    }
  }

  setRatingValueFromReduxState() {
    const { isFavorited } = this.props;

    if (isFavorited) {
      this.updateRatingValue(1);
    } else {
      this.updateRatingValue(0);
    }
  }

  getModalName = () => {
    return "add_to_shortlist_" + this.productId();
  };

  openModal = (shouldLogPixel = false) => {
    if (shouldLogPixel) {
      ActionLogger.logAction({
        ...this.heartEventPixelData(),
        action: analytics.HEARTS_ACTIONS.ENGAGE_SHORTLIST_MODAL_0,
      });
    }

    this.setState({ showModalTrigger: false });
    this.props.openModal(this.getModalName());
  };

  heartEventPixelData = () => {
    const {
      userData,
      product: { id },
      isInCarousel,
    } = this.props;
    const userId = userData?.userProfile?.id;

    return {
      object_type: analytics.OBJECT_TYPE.HEART_REC,
      user_id: userId,
      style: id,
      timestamp: getUnixTime(new Date()),
      location: isInCarousel ? analytics.ACTION_LOCATIONS.CAROUSEL : analytics.ACTION_LOCATIONS.GRID,
    };
  };

  triggerLinkToModal = () => {
    // for mobile web on the PDP and Product Drawer, we want to pop the modal immediately and not
    // show the Add To Shortlist button
    if (
      this.props.isMobileViewport &&
      (this.props.location === analytics.ACTION_LOCATIONS.DRAWER ||
        this.props.location === analytics.ACTION_LOCATIONS.PDP)
    ) {
      this.openModal();
    } else {
      // We want the Add to a Shortlist button to appear and then slide out after
      // five seconds
      this.setState({ showModalTrigger: true });
      setTimeout(() => {
        this.setState({ showModalTrigger: false });
      }, 5000);
    }
  };

  shortlistsContainingStyle() {
    const {
      shortlists,
      product: { id },
    } = this.props;

    return shortlists
      .filter(list => {
        return list?.styleNames?.includes(id);
      })
      .map(list => {
        return list.id;
      });
  }

  isStylePresentOnShortlists() {
    return this.shortlistsContainingStyle().length > 0;
  }

  logModalPixel = action => {
    const shortlists = this.shortlistsContainingStyle();

    if (action === analytics.HEARTS_ACTIONS.CANCEL || action === analytics.HEARTS_ACTIONS.CREATE) {
      ActionLogger.logAction({
        ...this.heartEventPixelData(),
        action: analytics.HEARTS_ACTIONS.ENGAGE_SHORTLIST_MODAL_2,
        exit_modal: action,
      });
    } else if (action === analytics.HEARTS_ACTIONS.CLOSE) {
      ActionLogger.logAction({
        ...this.heartEventPixelData(),
        action: analytics.HEARTS_ACTIONS.MODAL_CLOSE,
      });
    } else {
      ActionLogger.logAction({
        ...this.heartEventPixelData(),
        action: analytics.HEARTS_ACTIONS.ENGAGE_SHORTLIST_MODAL_1,
        exit_modal: action,
        checked_shortlist_count: shortlists.length,
        checked_shortlist_id: shortlists,
      });
    }
  };

  updateRatingValue = value => {
    this.setState({ ratingValue: value });

    // when a user is un-hearting a product while the Add To Shortlist
    // button is visible, animate it out immediately
    if (value === 0 && this.state.showModalTrigger) {
      this.setState({ showModalTrigger: false });
    }
  };

  closeModal = location => {
    this.logModalPixel(location);
    this.props.closeModal();
  };

  renderShortlistButton = () => {
    let innerContent;
    let onClick;

    const classnames = classNames("add-to-shortlist-button");

    if (this.props.location === analytics.ACTION_LOCATIONS.POST_SHIP_MODAL) {
      innerContent = (
        <label htmlFor="btn-add-to-shortlist">
          Added to
          <br />
          <i>All Hearts</i>
        </label>
      );
      onClick = () => {};
    } else {
      innerContent = [
        <AtomPlusInCircleIcon radius={50} key="icon" />,
        <label htmlFor="btn-add-to-shortlist" key="label">
          Add to a Shortlist
        </label>,
      ];
      onClick = () => {
        this.openModal(true);
      };
    }

    return (
      <button id="btn-add-to-shortlist" key="add-to-shortlist" className={classnames} onClick={onClick}>
        {innerContent}
      </button>
    );
  };

  render = () => {
    const {
      deadEndCarousel,
      deadEndSearchData,
      deadEndAPIAndCarouselData,
      product: { isEarlyAccess, programUnlocked },
    } = this.props;

    if (this.props.hidden) {
      return <div>{this.props.children}</div>;
    }

    let modal;
    if (this.props.displayedModal === this.getModalName()) {
      modal = (
        <ClosableModal
          isOpen={true}
          onRequestClose={() => {
            this.closeModal(analytics.HEARTS_ACTIONS.CLOSE);
          }}>
          <ShortlistList
            fetchShortlists={this.props.fetchShortlists}
            onClose={() => {
              this.closeModal(analytics.HEARTS_ACTIONS.DONE);
            }}
            productId={this.productId()}
            shortlists={this.props.shortlists}
            logModalPixel={this.logModalPixel}
          />
        </ClosableModal>
      );
    }

    // on desktop in the product drawer we want a slower animation speed
    const shouldSlowAnimation =
      !this.props.isMobileViewport &&
      (this.props.location === analytics.ACTION_LOCATIONS.DRAWER ||
        this.props.location === analytics.ACTION_LOCATIONS.PDP);

    const enterTimeout = shouldSlowAnimation ? 500 : 300;
    const exitTimeout = shouldSlowAnimation ? 400 : 200;

    const imageGroupClassNames = classNames("primary-area");

    return (
      <div className="product-rating-container">
        <HeartButton
          product={this.props.product}
          deadEndCarousel={deadEndCarousel}
          triggerLinkToModal={this.triggerLinkToModal}
          openModal={this.openModal}
          customHeartLoggingOptions={this.props.customHeartLoggingOptions}
          deadEndSearchData={deadEndSearchData}
          deadEndAPIAndCarouselData={deadEndAPIAndCarouselData}
          productIndex={this.props.productIndex}
          location={this.props.location}
          isStylePresentOnShortlists={this.isStylePresentOnShortlists()}
          heartEventPixelData={this.heartEventPixelData()}
          displayedModal={this.props.displayedModal}
          isMobileViewport={this.props.isMobileViewport}
          ratingValue={this.state.ratingValue}
          updateRatingValue={this.updateRatingValue}
        />
        {this.props.children && <div className={imageGroupClassNames}>{this.props.children}</div>}
        <CSSTransition
          in={this.state.showModalTrigger}
          unmountOnExit
          classNames="shortlist-button"
          timeout={{ enter: enterTimeout, exit: exitTimeout }}>
          {this.renderShortlistButton()}
        </CSSTransition>
        {isEarlyAccess && <p className="product-badge">Early Access</p>}
        {programUnlocked && <p className="product-badge">Just Added</p>}
        {modal}
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const favorites = state.favorites || [];
  const anonymousFavorites = state.anonymousFavorites || [];
  const shortlists = state.shortlists || [];
  const { browser, displayedModal, userData } = state;
  const isMobileViewport = browser?.isMobileViewport || false;

  // Need to handle various ways the id is passed
  // props.product.productId (grid powered by disco)
  // props.product.id        (grid)
  const productId = ownProps.product.id || ownProps.product.productId;

  return {
    userData,
    favorites,
    shortlists,
    isFavorited: favorites.includes(productId) || anonymousFavorites.includes(productId),
    isMobileViewport,
    displayedModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchShortlists: () => {
      dispatch(shortlistsActions.loadShortlists(ownProps.favorites, true));
    },
    openModal: modalName => {
      dispatch(sharedActions.displayModal(modalName));
    },
    closeModal: () => {
      dispatch(sharedActions.displayModal(false));
    },
  };
};

export { UserProductRatingsContainer as UnconnectedUserProductRatingsContainer };
export default connect(mapStateToProps, mapDispatchToProps)(UserProductRatingsContainer);
