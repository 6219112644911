import React from "react";
/*
 * An input whose placeholder text rises up to be a label when the user starts entering information
 * Used for anything where letters or numbers are typed (not dropdowns, not checkboxes, not buttons etc.)
 * This input element can be in the following states:
 * 1. starting state: state on page load; user has not interacted with the input or label
 * 2. focus state: user has tapped/clicked on input or label and there are no errors
 * 3. focus state with error: previous input was invalid and user has tapped/clicked on input or label
 * 4. valid state: input no longer has focus and the input has been validated
 * 5. error state: input no longer has focus and the input is invalid / has errors
 *
 * This input also supports switching out an HTML <input> element for an InputElement component
 * which allows for a mask to be applied to that input. Specify the character that is used
 * as the placeholder by passing in the maskChar prop. To show the mask even when there is no value
 * in the input, pass through the alwaysShowMask as true.
 * For example, to add a "-" in a phone number as the user is typing, pass through a maskChar of "-".
 * For placeholder-like helper text that appears before the user enters text ('MM/DD/YYYY'), pass in the helperText prop.
 * In this case the handleBeforeMaskedValueChange callback will replace the empty masked value with the helperText.
 */

import _ from "underscore";
import InputElement from "react-input-mask";
import AtomConnectedPlacesAutocompleteInput from "components/source/atoms/atom-connected-places-autocomplete-input";
import PropTypes from "prop-types";
import classNames from "classnames";
import AtomPhoneInput from "components/source/atoms/atom-phone-input";
import { IMaskInput } from "react-imask";
import AtomCreditCardExpirationInput from "components/source/atoms/atom-credit-card-expiration-input";

const propTypes = {
  additionalClassName: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  autoComplete: PropTypes.string, // autoComplete, the browser feature
  beforeMaskedValueChange: PropTypes.func,
  enableAutocomplete: PropTypes.bool, // refers to Google Maps Auto complete (not the browser feature)
  error: PropTypes.string,
  focus: PropTypes.bool,
  onFocus: PropTypes.func,
  handleAutocompleteSelect: PropTypes.func, // refers to Google Maps Auto complete (not the browser feature)
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelCoversInput: PropTypes.bool,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  maskedInput: PropTypes.bool,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  tabIndex: PropTypes.number, // it is NOT recommended to set tabIndex. if you want to skip elements, set _them_ to tabIndex=-1
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setParentReference: PropTypes.func,
  disabled: PropTypes.bool,
  alternateMasker: PropTypes.oneOf(["atomPhoneInput", "atomCreditCardExpirationInput", "iMaskJS"]),
};

const defaultProps = {
  beforeMaskedValueChange: _.identity,
};

class AtomAnimatedTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteOnChangeShim = this.autocompleteOnChangeShim.bind(this);
    this.handleBeforeMaskedValueChange = this.handleBeforeMaskedValueChange.bind(this);
  }

  componentDidMount() {
    // We only focus on <input /> elements, not imported <InputElement> components
    // as we are currently unable to force a focus on the imported element.
    // Therefore, any check in the render that could cause a non-<input/> to render should
    // be added here.
    if (this.props.focus && !this.props.maskedInput && !this.props.enableAutocomplete) {
      window.requestAnimationFrame(() => {
        if (!this.input || typeof this.input.focus !== "function") {
          return;
        }
        this.input.focus();
      });
    }
  }

  // Provide event mock to consuming wrapper component.
  autocompleteOnChangeShim(val) {
    const mockEvent = {
      target: {
        value: val,
      },
    };
    this.props.onChange(mockEvent);
  }

  handleBeforeMaskedValueChange = (newState, oldState, userInput, maskOptions) => {
    const { beforeMaskedValueChange, helperText, labelCoversInput, mask, maskChar } = this.props;

    const newMaskedValue = beforeMaskedValueChange(newState, oldState, userInput, maskOptions);

    // 'newMaskedValue.value' represents the masked value that was set by the underlying component.
    let { value } = newMaskedValue;
    const { selection } = newMaskedValue;

    // We only need to intercept this change if we are replacing an empty masked value with helperText.
    if (value && helperText && maskChar) {
      const valueChars = value.split("");
      const characterIsMaskChar = c => c === maskChar;
      const characterIsPartOfMask = c => {
        /* The mask will include formatChars ('99 / 99 / 9999')- we need to scrub those to ensure a user-entered '9' is not considered part of the mask.
         * The default formatChars are [9a*] (see https://github.com/sanniassin/react-input-mask/tree/2.0.3)
         */
        const scrubbedMask = mask.replace(/[9a*]/g, maskChar);

        return scrubbedMask.indexOf(c) > -1;
      };
      const userHasNotEnteredValue = _.every(valueChars, c => {
        // the value is not user-entered when all characters are either part of the mask, or are the maskChar.
        return characterIsPartOfMask(c) || characterIsMaskChar(c);
      });

      if (userHasNotEnteredValue) {
        /* When this.props.labelCoversInput is true, a label is covering the input box.
         * If the masked value is longer than the label text, it is visible behind the label, which is bad.
         * For example, when label is 'Due Date' and masked value is 'MM / DD / YYYY', the user sees 'Due Date' followed by 'YYYY'.
         * To work around this, the masked value will be empty until the label stops covering the input box.
         */
        if (labelCoversInput) {
          value = "";
        } else {
          value = helperText;
        }
      }
    }

    return {
      value,
      selection,
    };
  };

  getInputElement() {
    const {
      error,
      isValid,
      disabled,
      type,
      id,
      name,
      onClick,
      onBlur,
      onChange,
      onFocus,
      maxLength,
      value,
      readOnly,
      autoComplete,
      alternateMasker,
    } = this.props;
    const inputClass = classNames({
      error: error,
      valid: isValid,
      disabled: disabled,
    });
    const tabIndex = this.props.tabIndex ? this.props.tabIndex : null;

    const defaultInput = (
      <input
        aria-label={name}
        ref={input => this.setInputReference(input)}
        className={inputClass}
        tabIndex={tabIndex}
        type={type}
        id={id}
        name={name}
        onClick={onClick}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        maxLength={maxLength}
        value={value}
        readOnly={readOnly}
        autoComplete={autoComplete}
        disabled={disabled}
        data-heap-redact-text="true"
        data-heap-redact-attributes="value"
        data-test-id={id}
      />
    );

    if (alternateMasker === "atomPhoneInput") {
      return (
        <AtomPhoneInput
          originalPhoneNumber={value}
          ariaLabel={name}
          className={inputClass}
          tabIndex={tabIndex}
          type={type}
          id={id}
          name={name}
          onClick={onClick}
          onBlur={onBlur}
          onInputCallback={onChange}
          onFocus={onFocus}
          readOnly={readOnly}
          autoComplete={autoComplete}
          disabled={disabled}
        />
      );
    } else if (alternateMasker === "iMaskJS") {
      return (
        <IMaskInput
          id={id}
          name={name}
          aria-label={name}
          className={inputClass}
          tabIndex={tabIndex}
          type={type}
          mask={this.props.mask}
          value={value ?? ""}
          onClick={onClick}
          onBlur={onBlur}
          onAccept={onChange}
          onFocus={onFocus}
          readOnly={readOnly}
          autoComplete={autoComplete}
          disabled={disabled}
          data-heap-redact-text="true"
          data-heap-redact-attributes="value"
        />
      );
    }

    if (alternateMasker === "atomCreditCardExpirationInput") {
      return (
        <AtomCreditCardExpirationInput
          id={id}
          ariaLabel={name}
          name={name}
          tabIndex={tabIndex}
          className={inputClass}
          onInputCallback={onChange}
          value={value ?? ""}
          onClick={onClick}
          onBlur={onBlur}
          readOnly={readOnly}
          autoComplete={autoComplete}
          disabled={disabled}
        />
      );
    }

    if (this.props.maskedInput && this.props.mask) {
      return (
        <InputElement
          aria-label={name}
          className={inputClass}
          mask={this.props.mask}
          maskChar={this.props.maskChar || null}
          alwaysShowMask={this.props.alwaysShowMask || false}
          tabIndex={tabIndex}
          beforeMaskedValueChange={this.handleBeforeMaskedValueChange}
          type={type}
          id={id}
          name={name}
          onClick={onClick}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          maxLength={maxLength}
          value={value}
          readOnly={readOnly}
          autoComplete={autoComplete}
          disabled={disabled}
          data-heap-redact-text="true"
          data-heap-redact-attributes="value"
        />
      );
    } else if (this.props.enableAutocomplete) {
      return (
        <AtomConnectedPlacesAutocompleteInput
          placeholderInput={defaultInput}
          inputClass={inputClass}
          handleAutocompleteSelect={this.props.handleAutocompleteSelect}
          onChange={this.autocompleteOnChangeShim}
          tabIndex={tabIndex}
          type={type}
          id={id}
          name={name}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={maxLength}
          value={value}
          readOnly={readOnly}
          autoComplete={autoComplete}
          disabled={disabled}
        />
      );
    } else {
      return defaultInput;
    }
  }

  setInputReference(input) {
    const { setParentReference } = this.props;
    if (typeof setParentReference === "function" && input) {
      setParentReference(input);
    } else {
      this.input = input;
    }
  }

  // This allows parent wrapper to focus on arbitrary elements within a form. If the element was not previously
  // focused but now is, ensure that we move the cursor to that input.
  componentDidUpdate(prevProps) {
    if (!prevProps.focus && this.props.focus && this.input) {
      this.input.focus();
    }
  }

  render() {
    const { additionalClassName, error, label, icon, id, disabled } = this.props;

    const className = classNames("animated-input-wrapper", {
      [additionalClassName]: additionalClassName,
      error: error,
      disabled: disabled,
    });
    const labelClass = classNames({ "icon-label": icon });
    const placeholder = error ? error : label;
    const inputElement = this.getInputElement();

    return (
      <div className={className}>
        {icon && <div className="input-icon">{icon}</div>}
        {inputElement}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={id} placeholder={placeholder} className={labelClass} />
      </div>
    );
  }
}

AtomAnimatedTextInput.propTypes = propTypes;
AtomAnimatedTextInput.defaultProps = defaultProps;

export default AtomAnimatedTextInput;
