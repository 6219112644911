import _ from "underscore";
import { CartItemStatus, checkout, membershipStyleNames, specialSkus, ApplePayConstants } from "rtr-constants";
import { Flags } from "../components/source/hoc/with-feature-flags";
import { isMasquerading } from "../components/source/hoc/with-user-data";
import { isProduction } from "./environment-helpers";

export const blockForMasqueradeSelector = state => {
  return state.featureFlags?.[Flags.PCI_MASQUERADE] && isMasquerading(state.userData);
};

const membershipOrGiftCardSkus = [
  ...Object.values(specialSkus.membershipSkus),
  ...Object.values(specialSkus.giftCards),
];
const lowercasedMembershipOrGiftCardSkus = membershipOrGiftCardSkus.map(s => s.toLowerCase());
const lowercasedMembershipSkus = Object.values(specialSkus.membershipSkus).map(s => s.toLowerCase());
const lowercasedGiftCardSkus = Object.values(specialSkus.giftCards).map(s => s.toLowerCase());

const { fulfillmentTypes, orderItemTypes } = checkout;

const membershipOrderItemTypes = [
  orderItemTypes.PRO_SUBSCRIPTION,
  orderItemTypes.UPDATE_SUBSCRIPTION,
  orderItemTypes.UNLIMITED_SUBSCRIPTION,
];

export function getOrderGroupsWithItems(previewInvoice) {
  if (!previewInvoice) {
    return;
  }
  const { groups } = previewInvoice;

  if (!groups) {
    return;
  }

  return _.select(groups, g => g.lineItems?.length);
}

// An Order-Level address is a front-end concept to describe multiple groups
// which are all shipping to the same address.
// even though, at the time of this writing, Checkout does not support shipping
// groups to multiple addresses, I figured it is as good a time as any to
// introduce this concept (it feels better than simply picking the first group's
// address anyway)
// returns false when no addresses are present
// returns undefined when more than one address exists (including when one group
// has an address, but another does not)
export function getOrderLevelAddressId(previewInvoice) {
  if (!previewInvoice) {
    return false;
  }

  const groups = getOrderGroupsWithItems(previewInvoice);

  if (!groups) {
    return false;
  }

  const addressIds = _.chain(groups)
    .map(g => g?.orderGroup?.addressId)
    .uniq()
    .value();

  // in an effort to distinguish between "no order-level address" and
  // "no addresses at all", we return false
  if (addressIds.every(id => id === undefined)) return false; // eslint-disable-line no-undefined

  // we do not have an order-level address if groups have different addresses
  if (addressIds.length !== 1) {
    return;
  }

  return addressIds[0];
}

export function getOrderLevelAddress(previewInvoice, addresses) {
  let addressId = getOrderLevelAddressId(previewInvoice);

  if (!addressId) {
    return addressId;
  }

  addressId = String(addressId);

  return _.find(addresses, a => a.id === addressId);
}

export function getOrderGroupAddress(group, addresses) {
  if (!group) {
    return;
  }

  const addressId = String(group.addressId);

  return _.find(addresses, a => a.id === addressId);
}

// returns an array of all of the items in an order
export function skusInOrder(previewInvoice) {
  return _.chain(previewInvoice.groups)
    .map(g => _.map(g.lineItems, i => i.sku.id)) // get an array of arrays of all order items
    .flatten() // flatten it into a single array
    .value();
}

// returns an array of special skus (memberships or gift cards) found in the user's order
export function specialSkusInOrder(previewInvoice) {
  return skusInOrder(previewInvoice).filter(skuId => lowercasedMembershipOrGiftCardSkus.includes(skuId.toLowerCase()));
}

export function isMembershipOrGiftCardItem(lineItem) {
  return lowercasedMembershipOrGiftCardSkus.includes(lineItem.sku.id.toLowerCase());
}

export function isSubscriptionItem(lineItem) {
  return lowercasedMembershipSkus.includes(lineItem.sku.id.toLowerCase());
}

export function isGiftCardItem(lineItem) {
  return lowercasedGiftCardSkus.includes(lineItem.sku.id.toLowerCase());
}

export function isBulk(orderItem) {
  return orderItem?.subType === orderItemTypes.BULK;
}

export function isClearance(orderItem) {
  return orderItem?.subType === orderItemTypes.CLEARANCE;
}

export function isKeepItForever(orderItem) {
  return orderItem?.subType === orderItemTypes.KIF_FROM_RACK;
}

export function isReserveRental(orderItem) {
  return orderItem?.subType === orderItemTypes.CLASSIC;
}

export function hasReserveRentals(invoice) {
  const heldItems = getHeldOrderItems(invoice);

  if (!heldItems) return;

  return heldItems.some(isReserveRental);
}

export function hasSubscriptionItem(invoice) {
  const heldItems = getHeldItems(invoice);

  if (!heldItems) return;

  return heldItems.some(isSubscriptionItem);
}

// returns true if user's group contains all subscription skus
export function isSubscriptionOnlyGroup(invoiceOrderGroup) {
  const { lineItems } = invoiceOrderGroup || {};

  if (!lineItems) {
    return false;
  }

  return lineItems.every(isSubscriptionItem);
}

// returns true if user's group contains only gift card skus
export function isGiftCardOnlyGroup(invoiceOrderGroup) {
  const { lineItems } = invoiceOrderGroup || {};

  if (!lineItems) {
    return false;
  }

  return lineItems.every(isGiftCardItem);
}

export function isKeepItForeverGroup(invoiceOrderGroup) {
  const { orderGroup: { items } = {} } = invoiceOrderGroup || {};

  if (!items) {
    return false;
  }

  return items.filter(i => i).some(({ subType }) => subType === "electiveSale");
}

export function isRentalGroup(invoiceOrderGroup) {
  return invoiceOrderGroup.orderGroup.startDate && invoiceOrderGroup.orderGroup.endDate;
}

export function isClearanceGroup(invoiceOrderGroup) {
  return (
    !isSubscriptionOnlyGroup(invoiceOrderGroup) &&
    !isGiftCardOnlyGroup(invoiceOrderGroup) &&
    !isKeepItForeverGroup(invoiceOrderGroup) &&
    !isRentalGroup(invoiceOrderGroup)
  );
}

/* This returns an array of subscription ** order item types **
 * in the group (not to be confused with subscription skus).
 * In the vast majority of cases I think we will only have one
 * subscription order item in a group (at time of writing).
 */
export function subscriptionOrderItemsInGroup(invoiceOrderGroup) {
  const orderItemTypesInGroup = _.map(invoiceOrderGroup.lineItems, i => i.orderItem?.subType);

  return _.intersection(orderItemTypesInGroup, membershipOrderItemTypes);
}

// TODO: remove once we no longer assume Checkout is for a single group
export function getFirstGroupFromOrder(previewInvoice) {
  const groups = getOrderGroupsWithItems(previewInvoice);

  if (!groups || groups.length === 0) {
    return null;
  }

  return _.first(groups);
}

export function getGroupIdFromOrder(previewInvoice) {
  // This will need updating for multi-group orders
  // For now we are only solving for the case of a single group order
  // where a user MIGHT also be adding pro to her bag.
  const firstGroup = getFirstGroupFromOrder(previewInvoice);
  return firstGroup?.id || null;
}

export function getAddressIdFromOrder(previewInvoice) {
  // This will need updating for multi-group orders
  // For now we are only solving for the case of a single group order
  // where a user MIGHT also be adding pro to her bag.
  const firstGroup = getFirstGroupFromOrder(previewInvoice);
  return firstGroup?.orderGroup?.addressId || null;
}

// returns held Items from all groups of an invoice
export function getHeldItems(invoice) {
  if (!invoice || !invoice.groups) {
    return null;
  }

  return _.chain(invoice.groups)
    .map(group => {
      return _.filter(group.lineItems, function (lineItem) {
        const status = lineItem?.orderItem?.bookingStatus;
        return status === CartItemStatus.HOLD;
      });
    })
    .flatten(i => i.sku)
    .value();
}

export function getHeldOrderItems(invoice) {
  const lineItems = getHeldItems(invoice);

  if (!lineItems) return null;

  return lineItems.map(l => l.orderItem);
}

// returns unheld Items from all groups of an invoice
export function getUnheldItems(invoice) {
  if (!invoice || !invoice.groups) {
    return null;
  }

  return _.chain(invoice.groups)
    .map(group => {
      return _.filter(group.lineItems, function (lineItem) {
        const status = lineItem?.orderItem?.bookingStatus;
        return status && status !== CartItemStatus.HOLD;
      });
    })
    .flatten(i => i.sku)
    .value();
}

export function hasUnheldItems(invoice) {
  return _.any(getUnheldItems(invoice));
}

// extracts the postalCodes used to create the reservations/holds
export function getReservationPostalCodesForShippableItems(invoice) {
  if (!invoice || !invoice.groups) {
    return;
  }

  // eliminate any groups with no classic items, which will have a placeholder
  // zip code, simply because they have to
  const orderGroups = _.select(invoice.groups, group => {
    if (!group) {
      return false;
    }
    return _.chain(group.lineItems)
      .compact()
      .map("orderItem")
      .compact()
      .any(i => i?.subType === orderItemTypes.CLASSIC)
      .value();
  });

  return _.map(orderGroups, group => group?.orderGroup?.zipCode);
}

// Use this method to decide whether to display the Pick Up In Store banner
// Rules as of 2018-12-20:
// All items must be classic rentals, with the exception of digital products
// (subscriptions or gift cards).
// digital-only orders are not eligible
export function isPickUpInStoreEligible(previewInvoice) {
  if (!previewInvoice || !previewInvoice.groups) {
    return;
  }

  const nonDigitalOrderItems = _.chain(previewInvoice.groups)
    .map("lineItems")
    .flatten()
    .compact() // eliminate any null data
    .select("sku") // extra null-checking
    .reject(isMembershipOrGiftCardItem)
    .map("orderItem")
    .compact() // filter out bad data
    .value();

  // check for a subscription-only order
  if (!nonDigitalOrderItems.length) {
    return false;
  }

  return _.all(nonDigitalOrderItems, i => i?.subType === orderItemTypes.CLASSIC);
}

export function isOrderFulfilledInStore(previewInvoice) {
  if (!previewInvoice || !previewInvoice.groups) {
    return;
  }

  const fulfillmentMethods = _.chain(previewInvoice.groups)
    .map("orderGroup")
    .compact() // eliminate any null groups
    .map("fulfillmentMethod") // map to fulfillmentMethods
    .reject(
      fulfillmentMethod => !fulfillmentMethod || fulfillmentMethod.type === fulfillmentTypes.VIRTUAL // missing fulfillmentMethods should not be filtered out
    )
    .value();

  if (!fulfillmentMethods.length) {
    return;
  }

  return fulfillmentMethods.every(({ type }) => type === fulfillmentTypes.PICK_UP_IN_STORE);
}

export function getGroupContainingOrderItem(previewInvoice, orderItem) {
  if (!previewInvoice || !previewInvoice.groups) {
    return;
  }

  return _.chain(previewInvoice.groups)
    .map("orderGroup")
    .filter(g => g?.items)
    .find(g => _.find(g.items, i => i.id === orderItem.id))
    .value();
}

export function getFirstFulfilledGroup(previewInvoice) {
  if (!previewInvoice || !previewInvoice.groups) {
    return;
  }

  return _.find(previewInvoice.groups, group => {
    const fulfillmentMethod = group?.orderGroup?.fulfillmentMethod || {};

    return fulfillmentMethod.firstName || fulfillmentMethod.lastName || fulfillmentMethod.phoneNumber;
  });
}

export function getOrderGroupShowroomAddress(orderGroup, showrooms) {
  if (!orderGroup || !showrooms) {
    return;
  }

  const { fulfillmentMethod = {} } = orderGroup;
  const { shipToDepotId } = fulfillmentMethod;

  const showroomWithAddress = _.find(showrooms, s => s.id === shipToDepotId);

  if (!showroomWithAddress) {
    return;
  }

  return showroomWithAddress.showroomAddress;
}

export function classicActiveOrders(activeOrders) {
  return activeOrders?.filter(o => o?.orderType === checkout.orderTypes.CLASSIC);
}

export function hasClassicItems(activeOrders) {
  const classicOrders = classicActiveOrders(activeOrders);

  return classicOrders?.some(o => o?.groups?.some(g => g.items?.length));
}

export function hasClassicItemsInInvoice(activeOrders) {
  const classicOrders = classicActiveOrders(activeOrders);

  return classicOrders?.some(o => o?.previewInvoice?.groups?.some(g => g.lineItems?.length));
}

export function getOrderItems(activeOrders) {
  if (!activeOrders) return [];

  return activeOrders.flatMap(o => o.groups).flatMap(g => g.items);
}

function containsAnyOfTheseStyleNames(activeOrders, styleNames) {
  const items = getOrderItems(activeOrders);

  return _.intersection(styleNames, items).length > 0;
}

export function containsUnlimited(activeOrders) {
  return containsAnyOfTheseStyleNames(activeOrders, membershipStyleNames.unlimited);
}

export function containsUpdate(activeOrders) {
  return containsAnyOfTheseStyleNames(activeOrders, membershipStyleNames.update);
}

export function getApplePayMerchantIdentifier() {
  const { APPLE_PAY_MERCHANT_PRELIVE, APPLE_PAY_MERCHANT_PROD } = ApplePayConstants;

  if (isProduction()) return APPLE_PAY_MERCHANT_PROD;

  return APPLE_PAY_MERCHANT_PRELIVE;
}
