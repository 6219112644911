import React, { useState } from "react";
import { useContentfulContent } from "../../../layout/ContentfulContext";
import styles from "./styles.module.scss";

const ContentfulBanner = () => {
  const { isPreview } = useContentfulContent() || {};
  const [shouldDisplay, setShouldDisplay] = useState(true);

  return (
    isPreview &&
    shouldDisplay && (
      <div className={styles["contentful-banner"]}>
        <button
          title="Click to dismiss"
          onClick={e => {
            e.preventDefault();
            setShouldDisplay(false);
          }}>
          Content Preview
        </button>
      </div>
    )
  );
};

export default ContentfulBanner;
