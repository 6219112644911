// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.

import React, { createContext, useContext } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import { SFLogger } from "../../logger/logger";
import FeatureFlagsServiceClient from "clients/FeatureFlagsServiceClient";
import { childrenPropType } from "../propTypes";
import { getDisplayName } from "../source/hoc/utils";

const logger = SFLogger("FeatureFlagsContext");
const FeatureFlagsContext = createContext();

// plucks featureFlags from the Redux Context
const featureFlagsSelector = ({ enabledFlags }) => enabledFlags?.flags || [];

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

const mapStateToProps = ({ featureFlags } = {}) => ({ featureFlags });

export function withFeatureFlags() {
  return WrappedComponent => {
    const C = connect(mapStateToProps)(WrappedComponent);

    C.displayName = `withFeatureFlags(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

function applyOverrides(featureFlags, overrideOn, overrideOff) {
  // sets ensure entries are unique
  return Array.from(
    new Set([
      ...featureFlags.filter(ff => !overrideOff.includes(ff)), // remove any flags that are overriden off
      ...overrideOn, // merge in the opted on flags
    ])
  );
}

// this is not called automatically by Next, you must call it explicitly,
// probably in _app.js
async function getInitialReduxState({ ctx: context = {} }) {
  const { query = {} } = context;
  const overrideOn = [];
  const overrideOff = [];
  if (query.ff_overrides) {
    try {
      const [k, v] = query.ff_overrides.split(",");

      if (v === "false") {
        overrideOff.push(k);
      } else {
        overrideOn.push(k);
      }
    } catch (e) {
      logger.error("Error caught trying to apply FF overrides", e);
    }
  }
  return FeatureFlagsServiceClient.getInstance()
    .getEnabledFlags()
    .then(featureFlags => {
      const result = applyOverrides(featureFlags, overrideOn, overrideOff);

      return {
        enabledFlags: { flags: result },
        enabledFlagsHasLoaded: true,
      };
    });
}

// Allows setting explicit context values
export const BaseProvider = FeatureFlagsContext.Provider;
export function FeatureFlagsProvider({ children }) {
  const featureFlags = useSelector(featureFlagsSelector, shallowEqual);

  return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>;
}

FeatureFlagsProvider.getInitialReduxState = getInitialReduxState;

FeatureFlagsProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...FeatureFlagsContext,
  Provider: FeatureFlagsProvider,
};
