import ActionTypes from "actions/action-types";

export const types = [ActionTypes.SET_HOME_PICKUP_POSTAL_CODES, ActionTypes.SET_HOME_PICKUP_POSTAL_CODE_GROUPS];

export const fn = function (state, action) {
  let { homePickupPostalCodes, homePickupPostalCodeGroups } = state;
  switch (action.type) {
    case ActionTypes.SET_HOME_PICKUP_POSTAL_CODES:
      homePickupPostalCodes = action.payload;
      break;

    case ActionTypes.SET_HOME_PICKUP_POSTAL_CODE_GROUPS:
      action.payload.forEach(group => {
        homePickupPostalCodeGroups = {
          ...homePickupPostalCodeGroups,
          [group.id]: group.postalCodes,
        };
        homePickupPostalCodeGroups[group.id] = group.postalCodes;
      });
      break;

    default:
      return state;
  }
  return {
    ...state,
    homePickupPostalCodes,
    homePickupPostalCodeGroups,
  };
};

export default {
  types: types,
  fn: fn,
};
