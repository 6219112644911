import _ from "underscore";
import ActionTypes from "../actions/action-types.js";

const types = [ActionTypes.SET_ITEM_KEPT, ActionTypes.UNSET_ITEM_KEPT, ActionTypes.SET_ITEM_PURCHASED];

const returnFlowModification = function (state, action) {
  const returnFlow = _.extend({}, state.returnFlow);
  const promised = returnFlow.promised || [];
  const purchased = returnFlow.purchased || [];
  const kept = returnFlow.kept || [];

  returnFlow.promised = promised.slice();
  returnFlow.purchased = purchased.slice();
  returnFlow.kept = kept.slice();

  switch (action.type) {
    case ActionTypes.SET_ITEM_KEPT:
      returnFlow.kept.push(action.payload);
      returnFlow.promised = _.reject(returnFlow.promised, item => item.bookingId === action.payload.bookingId);
      break;
    case ActionTypes.UNSET_ITEM_KEPT:
      returnFlow.kept = _.reject(returnFlow.kept, item => item.bookingId === action.payload.bookingId);
      break;
    case ActionTypes.SET_ITEM_PURCHASED:
      returnFlow.purchased.push(action.payload);
      returnFlow.promised = _.reject(returnFlow.promised, item => item.bookingId === action.payload.bookingId);
      returnFlow.kept = _.reject(returnFlow.kept, item => item.bookingId === action.payload.bookingId);
      break;
  }

  return returnFlow;
};

const fn = function (state, action) {
  const returnFlow = returnFlowModification(state, action);

  return _.extend({}, state, {
    returnFlow: returnFlow,
  });
};

export default {
  types: types,
  fn: fn,
};
