// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.

import React, { createContext, useContext, useEffect, useState } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import CmsServiceClient from "clients/CmsServiceClient";
import { childrenPropType } from "../propTypes";
import { getDisplayName } from "../source/hoc/utils";
import { cmsContentUpdated } from "actions/shared-actions";
import smartComponentActions from "actions/smart-nav-actions";

const CmsContext = createContext(null);
const stateKey = "cmsContent";

// plucks cms from the Redux Context
const cmsSelector = ({ [stateKey]: cmsContent } = {}) => cmsContent;

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useCms() {
  return useContext(CmsContext);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

const mapStateToProps = ({ [stateKey]: cmsContent } = {}) => ({ [stateKey]: cmsContent });

export function withCms() {
  return WrappedComponent => {
    const C = connect(mapStateToProps)(WrappedComponent);

    C.displayName = `withCms(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

// this is not called automatically by Next, you must call it explicitly,
// probably in _app.js
async function getInitialReduxState({ ctx: context = {} }) {
  const { asPath } = context;

  const CmsInstance = CmsServiceClient.getInstance();

  // siteNavigation is required for the top nav on all pages
  // but it isn't fetched in any of our content containers (returned via getAllContent()), so we need to
  // explicitly fetch it on every page load. In SF.Ruby, siteNavigation is fetched
  // as part of initial redux base state. But because it's just extra CMS content, I don't think
  // it needs its own context provider. So, here we just fetch siteNav at the same time as the rest of
  // CMS content and save it to redux
  return Promise.all([
    CmsInstance.getAllContent(asPath),
    CmsInstance.getSiteNav(),
    CmsInstance.getSiteNavMobile(),
  ]).then(([cmsContent, siteNavigation, siteNavigationMobile]) => ({
    [stateKey]: cmsContent,
    [`${stateKey}HasLoaded`]: true,
    [`${stateKey}Path`]: asPath,
    siteNavigation,
    siteNavigationMobile,
  }));
}

// Allows setting explicit context values
export const BaseProvider = CmsContext.Provider;

export function CmsProvider({ children }) {
  const { asPath } = useRouter();
  const [currentPath, setCurrentPath] = useState(asPath);
  const dispatch = useDispatch();
  const cms = useSelector(cmsSelector, shallowEqual);

  //Fetch the user-specific smart nav entries client side
  //In Next, the server provides the default value, which may differ
  //This is not necessary in Ruby, where we SSR the correct view
  useEffect(() => {
    dispatch(smartComponentActions.getSmartNav());
  }, []);

  useEffect(() => {
    if (currentPath === asPath) {
      return;
    }
    // do this immediately to avoid duplicate calls
    setCurrentPath(asPath);
    CmsServiceClient.getInstance()
      .getAllContent(asPath)
      .then(content => {
        dispatch(cmsContentUpdated({ content, stateKey }));
      })
      .catch(e => {
        console.log("Failed to load CMS Content " + JSON.stringify({ error: e.message, asPath }));
      });
  }, [asPath, currentPath]); // any time asPath updates, re-fetch CMS

  return <CmsContext.Provider value={cms}>{children}</CmsContext.Provider>;
}

CmsProvider.getInitialReduxState = getInitialReduxState;

CmsProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...CmsContext,
  Provider: CmsProvider,
};
