import ActionTypes from "actions/action-types";

const types = [ActionTypes.SET_SNACK_BAR, ActionTypes.DISMISS_SNACK_BAR];

/**
 * Snack bar reducer
 * See snack-bar-helpers.js for default config
 *
 * @param {Object} state - The global state/redux store
 * @param {Object} action - The redux action
 * @param {String} action.type
 * @param {Object} action.payload - The snack bar configuration
 */
const getSnackBar = (state, action) => {
  const { snackBar } = state;
  const { payload, type } = action;

  switch (type) {
    case ActionTypes.SET_SNACK_BAR:
      return { ...payload, isVisible: true };
    case ActionTypes.DISMISS_SNACK_BAR:
      return snackBar ? { ...snackBar, isVisible: false } : null;
    default:
      return snackBar || null;
  }
};

const fn = (state, action) => {
  const snackBar = getSnackBar(state, action);

  return { ...state, snackBar };
};

export default {
  types,
  fn,
};
