import { ThirdPartyScript } from "./thirdPartyScript";
import {
  scriptTagAttributeForCookieConsentManagement,
  classNameForCookieConsentManagement,
  ONETRUST_CONSENT_MANAGEMENT,
} from "helpers/onetrust-helper";
import { useFeatureFlags } from "../FeatureFlagsContext";
import { flagsAndExperimentNames } from "../../../constants";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useFlagOrExperiment } from "../../source/hoc/with-flags-and-experiments";
import FlagsAndExperimentsActions from "../../../actions/flags-and-experiments-actions";
import { cvg } from "../../../../javascripts/helpers/converge";

export const ConvergeInit = () => {
  const dispatch = useDispatch();
  const publicEnv = useSelector(state => state.publicEnv, shallowEqual);
  const convergeFacebookEnabled = useFlagOrExperiment(flagsAndExperimentNames.CONVERGE_FACEBOOK_ENABLED);
  useEffect(() => {
    if (typeof convergeFacebookEnabled !== "undefined") return;
    dispatch(FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.CONVERGE_FACEBOOK_ENABLED));
  }, []);

  useEffect(() => {
    // Wait for the Converge feature flag to be fetched
    if (typeof convergeFacebookEnabled === "undefined") return;

    const ga4Config = {
      app: "ga4",
      measurement_id: publicEnv?.ga4MeasurementId,
      internal_converge_data_layer: true,
    };

    const tiktokConfig = {
      app: "tiktok",
      pixel_code: publicEnv?.tiktokPixelCode,
      content_id_type: "product_id",
      tiktok_custom_events: [
        { converge_event_name: "Viewed Subscription Plans", tiktok_event_name: "ViewSubscriptionPlans" },
        { converge_event_name: "Placed Clearance Order", tiktok_event_name: "ClearancePurchase" },
        { converge_event_name: "Placed Reserve Order", tiktok_event_name: "ReservePurchase" },
        { converge_event_name: "Placed Kif Order", tiktok_event_name: "KifPurchase" },
      ],
    };

    const fbConfig = {
      app: "fb",
      pixel_id: publicEnv?.fbPixelId,
      content_id_type: "product_id",
      custom_events: [
        { converge_event_name: "Viewed Subscription Plans", fb_event_name: "ViewSubscriptionPlans" },
        { converge_event_name: "Placed Clearance Order", fb_event_name: "Clearance Purchase" },
        { converge_event_name: "Placed Reserve Order", fb_event_name: "Reserve Purchase" },
        { converge_event_name: "Placed Kif Order", fb_event_name: "Kif Purchase" },
      ],
      fb_custom_properties: [
        { converge_property: "type", fb_property: "type" },
        { converge_property: "contains_membership", fb_property: "contains_membership" },
        { converge_property: "contains_rental", fb_property: "contains_rental" },
        { converge_property: "contains_clearance", fb_property: "contains_clearance" },
        { converge_property: "contains_kif", fb_property: "contains_kif" },
      ],
    };

    const pinterestConfig = {
      app: "pinterest",
      tag_id: publicEnv?.pinterestTagId,
      pinterest_content_id_type: "product_id",
    };

    // the `connection_id` is an internal Converge ID that is used to identify the connection
    // it is part of the internal configuration of the Converge script
    const connections = [];
    connections.push({
      config: ga4Config,
      connection_id: publicEnv?.ga4ConnectionId,
    });
    connections.push({
      config: tiktokConfig,
      connection_id: publicEnv?.tiktokConnectionId,
    });
    if (convergeFacebookEnabled) {
      connections.push({
        config: fbConfig,
        connection_id: publicEnv?.fbPixelConnectionId,
      });
    }
    connections.push({
      config: pinterestConfig,
      connection_id: publicEnv?.pinterestConnectionId,
    });

    cvg({
      method: "init",
      publicToken: publicEnv?.convergePublicToken,
      connections,
      consent: false,
      logLevel: "off",
    });
  }, [convergeFacebookEnabled]);

  return <></>;
};

export const ConvergePixel = () => {
  // Fetch feature flags
  const flags = useFeatureFlags();
  return (
    <>
      <ThirdPartyScript
        id="converge-script"
        class={classNameForCookieConsentManagement([ONETRUST_CONSENT_MANAGEMENT["COOKIE_CATEGORIES"]["TARGETING"]])}
        type={scriptTagAttributeForCookieConsentManagement(flags)}
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.cvg||(c=window.cvg=function(){c.process?c.process.apply(c,arguments):c.queue.push(arguments)},c.queue=[]);
            cvg({method: "consent", consent: true});
            `,
        }}
      />
      <ConvergeInit />
    </>
  );
};
