import React from "react";
import PropTypes from "prop-types";

import ReferralPromoBanner from "components/source/navigation/referral-promo-banner";
import SwapsBanner from "components/source/swaps/SwapsBanner";
import ExchangeBanner from "components/source/exchange/ExchangeBanner";
import skinnyBannerActions from "actions/skinny-banner-actions";
import { addressPropType, bannerPropType, browserPropType, userDataPropType } from "components/propTypes";

export default class SkinnyBannerContainer extends React.Component {
  static propTypes = {
    addresses: PropTypes.arrayOf(addressPropType),
    browser: browserPropType,
    dispatch: PropTypes.func.isRequired,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    happinessSurvey: PropTypes.object,
    isSkinnyBannerSuppressedOnPage: PropTypes.bool,
    ppsPromo: bannerPropType,
    referralPromo: bannerPropType,
    selfServiceExchange: PropTypes.object,
    smartComponentState: PropTypes.object,
    userData: userDataPropType,
    userProblems: PropTypes.object,
  };

  handleShow = bannerId => {
    this.props.dispatch(skinnyBannerActions.show(bannerId));
  };

  handleHide = bannerId => {
    this.props.dispatch(skinnyBannerActions.hide(bannerId));
  };

  handleNotApplicable = bannerId => {
    this.props.dispatch(skinnyBannerActions.notApplicable(bannerId));
  };

  renderSkinnyBanners() {
    // SmartSkinnyBanner is rendered in ReferralPromoBanner if user is not eligible to redeem referrals
    // TODO: ReferralPromoBanner has the logic to delete the referral cookie if it exists and the user is ineligible.
    // If/When that logic is decoupled from the ReferralPromoBanner, I think it makes more sense to render the SmartSkinnyBanner
    // in here, the SkinnyBannerContainer.
    return (
      <div className="promo-banner">
        <ReferralPromoBanner
          dispatch={this.props.dispatch}
          classicPromo={this.props.referralPromo}
          ppsPromo={this.props.ppsPromo}
          onShow={this.handleShow}
          onHide={this.handleHide}
          onNotApplicable={this.handleNotApplicable}
          smartComponentState={this.props.smartComponentState}
          userData={this.props.userData}
        />
      </div>
    );
  }

  render() {
    if (this.props.isSkinnyBannerSuppressedOnPage) {
      return null;
    }

    // If the swaps banner is empty, it will render the skinny banner instead
    // Swaps banner as part of Problem Order flow will be depricated in favour of a new problem order solution
    // it is To be removed as part of https://renttherunway.jira.com/browse/RSV-989
    return (
      <SwapsBanner
        dispatch={this.props.dispatch}
        userData={this.props.userData}
        userProblems={this.props.userProblems}
        browser={this.props.browser}
        displayedModal={this.props.displayedModal}>
        <ExchangeBanner
          addresses={this.props.addresses}
          dispatch={this.props.dispatch}
          displayedModal={this.props.displayedModal}
          happinessSurvey={this.props.happinessSurvey}
          selfServiceExchange={this.props.selfServiceExchange}
          userData={this.props.userData}
          userProblems={this.props.userProblems}>
          {this.renderSkinnyBanners()}
        </ExchangeBanner>
      </SwapsBanner>
    );
  }
}
