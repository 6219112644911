import React from "react";
import PropTypes from "prop-types";

export default class MembershipProgressIndicator extends React.PureComponent {
  static propTypes = {
    //  the progressBarRef allows the parent component to scroll
    //  to the top when the CTA is clicked
    progressBarRef: PropTypes.object,
    currentIndex: PropTypes.number.isRequired,
    lineCap: PropTypes.string,
    mixedLinecaps: PropTypes.bool, // when this is true, outer linecaps will be round and interior linecaps will be butt
    paddingPercentageBetweenLines: PropTypes.number, // padding between each line, as a percentage of total length
    strokeWidth: PropTypes.number,
    totalSteps: PropTypes.number.isRequired,
    /**
     * If true, we display a progress count of the current step/total steps, i.e. 2/6
     */
    showStepCount: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    lineCap: "round",
    paddingPercentageBetweenLines: 8,
    strokeWidth: 4,
    showStepCount: false,
  };

  getLineClass(lineIndex) {
    const oneBasedIndex = lineIndex + 1; // NW [EXPLANATION] 6/26/19: currentIndex is 1-based but lineIndex is 0-based
    if (oneBasedIndex <= this.props.currentIndex) {
      return "completed";
    }
  }

  getXOffsetAtLine(lineIndex, lineLength) {
    const { mixedLinecaps, paddingPercentageBetweenLines } = this.props;

    const paddingMultiplier = mixedLinecaps ? 1 : 2;
    return (paddingMultiplier * paddingPercentageBetweenLines + lineLength) * lineIndex;
  }

  renderLines() {
    const { lineCap, mixedLinecaps, paddingPercentageBetweenLines, strokeWidth, totalSteps } = this.props;
    const stepPaddingMultiplier = paddingPercentageBetweenLines * (mixedLinecaps ? 1 : totalSteps);
    const aggregatePadding = paddingPercentageBetweenLines * (totalSteps + stepPaddingMultiplier); // padding for each slide, plus additional padding at end for round linecap
    const lineLength = (100 - aggregatePadding) / totalSteps;
    const yPosition = strokeWidth / 2;
    const segments = [];
    for (let lineIndex = 0; lineIndex < totalSteps; lineIndex++) {
      const xOffset = this.getXOffsetAtLine(lineIndex, lineLength);
      const xStart = paddingPercentageBetweenLines + xOffset;
      const xEnd = xStart + lineLength;
      const isFirstLineWithMixedLinecap = mixedLinecaps && lineIndex === 0;
      const isLastLineWithMixedLinecap = mixedLinecaps && lineIndex === totalSteps - 1;
      if (isFirstLineWithMixedLinecap) {
        segments.push(
          <line
            data-test-id={"line-round-start"}
            key={`line-${lineIndex}-rounded-edge-start`}
            className={this.getLineClass(lineIndex)}
            x1={`${xStart}%`}
            y1={yPosition}
            x2={`${xStart}%`}
            y2={yPosition}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        );
      }
      segments.push(
        <line
          data-test-id={`line-${lineIndex}`}
          key={`line-${lineIndex}`}
          className={this.getLineClass(lineIndex)}
          x1={`${xStart}%`}
          y1={yPosition}
          x2={`${xEnd}%`}
          y2={yPosition}
          strokeWidth={strokeWidth}
          strokeLinecap={mixedLinecaps ? "butt" : lineCap}
        />
      );
      if (isLastLineWithMixedLinecap) {
        segments.push(
          <line
            data-test-id={"line-round-end"}
            key={`line-${lineIndex}-rounded-edge-end`}
            className={this.getLineClass(lineIndex)}
            x1={`${xEnd}%`}
            y1={yPosition}
            x2={`${xEnd}%`}
            y2={yPosition}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        );
      }
    }
    return segments;
  }

  renderProgressCountMarkup() {
    const { currentIndex, showStepCount, totalSteps } = this.props;

    if (!showStepCount) {
      return;
    }

    return <div className="step-counter">{`${currentIndex}/${totalSteps}`}</div>;
  }

  render() {
    const { totalSteps, progressBarRef } = this.props;

    if (totalSteps < 1) {
      return null;
    }

    return (
      <div className="membership-progress-indicator" ref={progressBarRef}>
        <svg data-test-id={"membership-progress-indicator"}>{this.renderLines()}</svg>
        {this.renderProgressCountMarkup()}
      </div>
    );
  }
}
