import ActionTypes from "actions/action-types";

const types = [ActionTypes.FETCH_RTR_CONTENT_MODULE_SUCCESS];

const fn = (state, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_RTR_CONTENT_MODULE_SUCCESS: {
      const {
        payload: { cmsUrl, content },
      } = action;
      const { rtrContentModules = {} } = state;

      return {
        ...state,
        rtrContentModules: {
          ...rtrContentModules,
          [cmsUrl]: content,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default {
  types: types,
  fn: fn,
};
