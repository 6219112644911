import React from "react";
import URLContext, { useUrl } from "components/layout/URLContext";
import PropTypes from "prop-types";

import { getDisplayName } from "./utils";
import { getAllQueryParams, getQueryParamFromUrl } from "../../../helpers/location-helpers";

/* React Context Setup */
const { Consumer } = URLContext;

/* URL Handling / HOC */
function computeParamsObj(url, params) {
  // map the params array so that it's a single map of keys and values read from the current URL
  if (params?.length > 0) {
    return params.reduce((acc, val) => {
      acc[val] = url ? getQueryParamFromUrl(url, val) : null;
      return acc;
    }, {});
  } else {
    // no params were passed, so return all query params
    return getAllQueryParams(url);
  }
}

export function initLocationChangeEvent() {
  // not supported in SSR, URLs should not change server side anyway when SSRing
  if (typeof window === "undefined") return;

  // wraps the native pushState and replaceState methods so that they emit events
  // source: https://stackoverflow.com/questions/6390341/how-to-detect-if-url-has-changed-after-hash-in-javascript/52809105#52809105
  history.pushState = (f =>
    function pushState() {
      const ret = f.apply(this, arguments);
      window.dispatchEvent(new Event("pushstate"));
      window.dispatchEvent(new Event("locationchange"));
      return ret;
    })(history.pushState);

  history.replaceState = (f =>
    function replaceState() {
      const ret = f.apply(this, arguments);
      window.dispatchEvent(new Event("replacestate"));
      window.dispatchEvent(new Event("locationchange"));
      return ret;
    })(history.replaceState);

  // also dispatch when the regular "popstate" event is triggered elsewhere
  window.addEventListener("popstate", () => {
    window.dispatchEvent(new Event("locationchange"));
  });
}

/**
 * The HOC for reading query parameters
 * @param params The query params that the wrapped component cares about (note: can use multiple params, array etc.).
 * <br>
 * <b>NOTE:</b> Not specifying a `params` argument will result in all query params being passed to your component
 * @returns func(Component) A curried function, which accepts a component and sets the requested query params on the `queryParams` prop.
 * The original URL is also passed under the `rawUrl` prop
 */
export function withQueryParams(...params) {
  return WrappedComponent => {
    /**
     * This nested component serves 2 purposes:
     *
     * 1) to act as a wrapper for the component passed into this HOC (i.e. WrappedComponent) to make it easy
     * to consume the context above from class components
     *
     * 2) To take the raw url provided by the context (as a string) and map it to props key-value pairs (by extension, it
     * also contains the logic to ensure that the WrappedComponent is only re-rendered on param changes it cares about)
     */
    class URLWrapper extends React.PureComponent {
      static displayName = `withQueryParams(${getDisplayName(WrappedComponent)})`;
      static propTypes = {
        url: PropTypes.instanceOf(URL),
      };

      render() {
        // map the array of param names to a param object
        const queryParams = computeParamsObj(this.props.url, params);

        const propsToPass = Object.assign({}, this.props);
        delete propsToPass.url;
        // pass through given additional props
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <WrappedComponent rawUrl={this.props.url} queryParams={queryParams} {...propsToPass} />;
      }
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    const C = props => <Consumer>{url => <URLWrapper url={url} {...props} />}</Consumer>;

    C.displayName = `URLConsumer(${getDisplayName(WrappedComponent)})`;
    C.WrappedComponent = URLWrapper.WrappedComponent;

    return C;
  };
}

// This hook mimics the above HOC functionality by supplying the raw url and a query params object to the component
export function useQueryParams(...params) {
  const url = useUrl();
  const queryParams = computeParamsObj(url, params);

  return { rawUrl: url, queryParams };
}

export function updateUrlQueryParams(param, newValue) {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.set(param, newValue);

  const newUrl = window.location.pathname + "?" + urlParams.toString();
  history.replaceState(null, null, newUrl); // Update URL without reloading the page
}
