import _ from "underscore";
import $ from "clients/RawClient";
import ActionTypes from "./action-types";
import { createAction } from "redux-actions";
import SharedActions from "./shared-actions";
import { ReviewsSort, clientSideErrorMessages } from "rtr-constants";
import { isStorefrontNext } from "helpers/environment-helpers";
import analyticsClient from "../analytics/analytics-client";
import { MODAL_NAMES } from "../../../assets/javascripts/constants/modalNames";

const actions = {
  // changePage, changeSort, and setLoading are identical to productsActions.
  // Three one-line actions seems like few enough to duplicate, but creating
  // `commonActions` or something should probably happen soon.

  changePage: createAction(ActionTypes.CHANGE_PAGE),
  changeSort: createAction(ActionTypes.CHANGE_SORT),
  receiveComment: createAction(ActionTypes.COMMENT_SUBMIT_SUCCESS),
  receiveReviews: createAction(ActionTypes.RECEIVE_REVIEWS),
  receiveReviewsCoverPhotos: createAction(ActionTypes.RECEIVE_REVIEWS_COVER_PHOTOS),
  reviewUploadDataLoading: createAction(ActionTypes.REVIEW_UPLOAD_DATA_LOADING),
  getReviewUploadPendingReviewSuccess: createAction(ActionTypes.REVIEW_UPLOAD_GET_PENDING_REVIEW_SUCCESS),
  getReviewUploadProductSuccess: createAction(ActionTypes.REVIEW_UPLOAD_GET_PRODUCT_SUCCESS),
  getReviewUploadRentedSizesSuccess: createAction(ActionTypes.REVIEW_UPLOAD_GET_RENTED_SIZES_SUCCESS),
  reviewUploadPopupSource: createAction(ActionTypes.REVIEW_UPLOAD_POPUP_SOURCE),
  reviewUploadSubmitLoading: createAction(ActionTypes.REVIEW_UPLOAD_SUBMIT_LOADING),
  reviewUploadSubmitError: createAction(ActionTypes.REVIEW_UPLOAD_SUBMIT_ERROR),
  reviewUploadSubmitSuccess: createAction(ActionTypes.REVIEW_UPLOAD_SUBMIT_SUCCESS),
  clearReviewsCoverPhotos: createAction(ActionTypes.CLEAR_REVIEWS_COVER_PHOTOS),
  clearReviewUploadState: createAction(ActionTypes.REVIEW_UPLOAD_CLEAR_STATE),
  clearReviewUploadPhotos: createAction(ActionTypes.REVIEW_UPLOAD_CLEAR_PHOTOS),
  setLoading: createAction(ActionTypes.LOADING),
  updateWorkingReviewsFilters: createAction(ActionTypes.UPDATE_WORKING_REVIEWS_FILTERS),
  openSpecificReviewPhoto: createAction(ActionTypes.OPEN_SPECIFIC_REVIEW_PHOTO),
  reviewNudgeModalData: createAction(ActionTypes.REVIEW_NUDGE_MODAL_DATA),

  requestReviews(styleName, params, loggingContext, { onSuccess } = {}) {
    let url;

    if (isStorefrontNext()) {
      url = `/api/products/${styleName}/reviews`;
    } else {
      url = `/pdp/products/${styleName}/reviews`;
    }

    return function (dispatch) {
      // TODO: Figure out how to optimistically set these props within the
      // `reviews` object

      // if (params.sort) {
      //   dispatch(actions.changeSort, params.sort);
      // }

      // dispatch(actions.setLoading, true);
      // dispatch(actions.changePage, (params.page || 1));
      return $.ajax({
        type: "GET",
        url,
        data: params,
      }).then(
        function (data) {
          ReviewsActionLogging.logWlm(data, params, loggingContext);
          dispatch(actions.receiveReviews(data));

          if (onSuccess) onSuccess(data);
        },
        e => console.log("Reviews Fetch Failed", e)
      );
    };
  },

  requestReviewsCoverPhotos(styleName, params) {
    return function (dispatch) {
      return $.ajax({
        type: "GET",
        url: `/pdp/products/${styleName}/cover_photos`,
        data: params,
      }).then(function (data) {
        dispatch(actions.receiveReviewsCoverPhotos(data));
      });
    };
  },

  submitComment(reviewId, content) {
    return function (dispatch) {
      return $.ajax({
        type: "POST",
        url: `/reviews/${reviewId}/comments`,
        data: { content: content },
      }).then(function (comment) {
        dispatch(actions.receiveComment({ reviewId, comment }));
      });
    };
  },

  getReviewUploadProduct(styleName) {
    return function (dispatch) {
      return $.ajax({
        type: "GET",
        url: `/data/items?sn[]=${styleName}`,
      }).then(data => dispatch(actions.getReviewUploadProductSuccess(data)));
    };
  },

  getReviewUploadPendingReview(styleName) {
    return function (dispatch) {
      return $.ajax({
        type: "GET",
        url: `/moments/${styleName}/review`,
      }).then(data => dispatch(actions.getReviewUploadPendingReviewSuccess(data)));
    };
  },

  getReviewUploadRentedSizes(styleName) {
    return function (dispatch) {
      return $.ajax({
        type: "GET",
        url: `/products/${styleName}/reviews/sizes_rented`,
      }).then(data => dispatch(actions.getReviewUploadRentedSizesSuccess(data)));
    };
  },

  displayReviewUploadModal({ styleName, popupSource }) {
    return function (dispatch) {
      dispatch(actions.reviewUploadDataLoading(true));
      dispatch(actions.reviewUploadPopupSource(popupSource));
      dispatch(SharedActions.displayModal(MODAL_NAMES.REVIEW_UPLOAD));
      return dispatch(actions.getReviewUploadProduct(styleName)).then(function () {
        return dispatch(actions.getReviewUploadPendingReview(styleName)).then(function () {
          return dispatch(actions.getReviewUploadRentedSizes(styleName)).then(function () {
            dispatch(actions.reviewUploadDataLoading(false));
          });
        });
      });
    };
  },

  closeReviewUploadModal() {
    return function (dispatch) {
      dispatch(SharedActions.displayModal(false));
      dispatch(actions.clearReviewUploadState());
      dispatch(actions.clearReviewUploadPhotos());
    };
  },

  reviewNudgeModal(data) {
    return function (dispatch) {
      dispatch(actions.reviewNudgeModalData(data));
      dispatch(SharedActions.displayModal(MODAL_NAMES.REVIEW_NUDGE));
    };
  },

  submitReview(reviewData) {
    return function (dispatch) {
      dispatch(actions.reviewUploadSubmitLoading(true));
      dispatch(actions.reviewUploadSubmitError(null)); // clear any preexisting error message

      return $.ajax({
        type: "POST",
        url: `/moments/${reviewData.styleName}/review`,
        data: reviewData,
      })
        .then(
          data => {
            dispatch(actions.reviewUploadSubmitSuccess(data));
            if (reviewData.photos && JSON.parse(reviewData.photos)?.length) {
              dispatch(SharedActions.displayModal(MODAL_NAMES.REVIEW_CROP_PHOTOS));
            } else {
              dispatch(SharedActions.displayModal(MODAL_NAMES.REVIEW_SUBMIT_SUCCESS));
            }
          },
          response => {
            if (response["status"] === 401) {
              dispatch(actions.reviewUploadSubmitError(clientSideErrorMessages.reviews.unauthenticated));
            } else {
              dispatch(actions.reviewUploadSubmitError(clientSideErrorMessages.reviews.defaultError));
            }

            return response;
          }
        )
        .finally(() => {
          dispatch(actions.reviewUploadSubmitLoading(false));
        });
    };
  },

  setReviewCoverPhoto(reviewId, photoId) {
    return function () {
      return $.ajax({
        type: "POST",
        url: `/moments/review/${reviewId}/cover`,
        data: {
          coverPhotoId: photoId,
        },
      });
    };
  },

  getIsProductReviewableByCurrentUser(productId) {
    return function () {
      return $.ajax({
        type: "GET",
        url: `/products/${productId}/reviewable`,
      });
    };
  },
};

const ReviewsActionLogging = {
  logWlm(state, params, context) {
    let similarityCount = 0;
    let userType;
    let wlmParams = {};

    if (state.sort !== ReviewsSort.WOMEN_LIKE_ME) {
      return;
    }

    if (state.userType?.canonicalUserTypeHash) {
      userType = state.userType.canonicalUserTypeInt;

      similarityCount = _.select(state.data, function (review) {
        return _.any(review?.user?.canonicalUserTypeHash, function (val, key) {
          return val === state.userType.canonicalUserTypeHash[key];
        });
      }).length;
    }

    if (params?.women_like_me) {
      wlmParams = params.women_like_me;
    }

    analyticsClient.log(
      _.extend(wlmParams, {
        action: "use_wlm_review",
        object_type: "node",
        page: params?.page || 1,
        canonicalUserType: userType,
        review_count: similarityCount,
        save_profile: "false",
        context: context,
        storefrontPlatform: isStorefrontNext() ? "Next" : "Ruby",
      })
    );
  },
};

export default actions;

export const {
  changePage,
  changeSort,
  receiveComment,
  receiveReviews,
  receiveReviewsCoverPhotos,
  reviewUploadDataLoading,
  getReviewUploadPendingReviewSuccess,
  getReviewUploadProductSuccess,
  getReviewUploadRentedSizesSuccess,
  reviewUploadPopupSource,
  reviewUploadSubmitLoading,
  reviewUploadSubmitError,
  reviewUploadSubmitSuccess,
  clearReviewsCoverPhotos,
  clearReviewUploadState,
  clearReviewUploadPhotos,
  setLoading,
  updateWorkingReviewsFilters,
  openSpecificReviewPhoto,
  requestReviews,
  requestReviewsCoverPhotos,
  submitComment,
  getReviewUploadProduct,
  getReviewUploadPendingReview,
  getReviewUploadRentedSizes,
  displayReviewUploadModal,
  closeReviewUploadModal,
  submitReview,
  setReviewCoverPhoto,
  getIsProductReviewableByCurrentUser,
} = actions;
