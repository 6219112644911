import _ from "underscore";
import constants from "rtr-constants";
import ActionTypes from "../actions/action-types";

const INITIALIZED_SURVEY_STATUS = "INITIALIZED";
let type;
let bookingId;
let itemSurveys;
let firstItemSurvey;
let currentSurvey;
let nextItemSurvey;
let surveyToUpdate;
let remainingSurveys;

const happinessSurveyModification = function (state, action) {
  const happinessSurvey = _.extend({}, state.happinessSurvey);

  switch (action.type) {
    case ActionTypes.FETCH_HAPPINESS_SURVEYS_SUCCESS:
      itemSurveys = action.payload;

      // If there are itemSurveys returned then set the first one to be in progress
      // Otherwise we need to return an empty array

      if (itemSurveys.length) {
        firstItemSurvey = _.first(itemSurveys);
        firstItemSurvey.inProgress = true;
        happinessSurvey.itemSurveys = itemSurveys;
      } else {
        happinessSurvey.itemSurveys = [];
      }
      break;

    case ActionTypes.FETCH_HAPPINESS_SURVEYS_COMPLETED_SUCCESS:
      happinessSurvey.completedSurveys = action.payload;
      break;

    case ActionTypes.HAPPINESS_SURVEY_ADD_FEEDBACK:
      type = action.payload.exclusiveFeedbackTypeOption;
      surveyToUpdate = happinessSurvey.itemSurveys.find(survey => {
        return survey.bookingId === action.payload.bookingId;
      });

      if (!surveyToUpdate.surveyFeedbacks) {
        surveyToUpdate.surveyFeedbacks = [];
      }
      /*
       * If an exclusive feedback type is provided,
       * clean out existing feedbacks of this type
       * so we can insert the incoming feedback.
       */
      if (type) {
        surveyToUpdate.surveyFeedbacks = surveyToUpdate.surveyFeedbacks?.filter(f => {
          const feedbackValues = action.payload.feedbackValuesMap ?? constants.happinessSurveyValues[type]; // sometimes, our mapping values are from HS api instead of a local constant
          return !Object.keys(feedbackValues).includes(f.feedback);
        });
      }
      if (action.payload.feedback && surveyToUpdate.surveyFeedbacks) {
        surveyToUpdate.surveyFeedbacks.push({
          bookingId: action.payload.bookingId,
          feedback: action.payload.feedback,
        });
      }
      break;

    case ActionTypes.HAPPINESS_SURVEY_COMPLETED:
      currentSurvey = happinessSurvey.itemSurveys.find(survey => {
        return survey.bookingId === action.payload.bookingId;
      });

      currentSurvey.status = constants.happinessSurveyValues.status.COMPLETE;
      currentSurvey.inProgress = false;

      remainingSurveys = _.select(happinessSurvey.itemSurveys, function (survey) {
        return survey.status === INITIALIZED_SURVEY_STATUS;
      });

      if (remainingSurveys.length) {
        nextItemSurvey = _.first(remainingSurveys);
        nextItemSurvey.inProgress = true;
      }
      break;

    case ActionTypes.HAPPINESS_SURVEY_ADD_COMMENT:
      surveyToUpdate = happinessSurvey.itemSurveys.find(survey => {
        return survey.bookingId === action.payload.bookingId;
      });

      surveyToUpdate.comment = action.payload.comment;
      break;

    case ActionTypes.HAPPINESS_SURVEY_ADD_RATING:
      surveyToUpdate = happinessSurvey.itemSurveys.find(survey => {
        return survey.bookingId === action.payload.bookingId;
      });

      surveyToUpdate.rating = action.payload.rating;
      break;

    case ActionTypes.HAPPINESS_SURVEY_ADD_CAPTION:
      surveyToUpdate = happinessSurvey.itemSurveys.find(survey => {
        return survey.bookingId === action.payload.bookingId;
      });

      surveyToUpdate.caption = action.payload.caption;
      break;

    case ActionTypes.HAPPINESS_SURVEY_ADD_REVIEW_CONTENT:
      surveyToUpdate = happinessSurvey.itemSurveys.find(survey => {
        return survey.bookingId === action.payload.bookingId;
      });

      surveyToUpdate.reviewContent = action.payload.reviewContent;
      break;

    case ActionTypes.SET_HAPPINESS_SURVEY_INFO:
      happinessSurvey.type = action.payload.type;
      happinessSurvey.reserveGroupId = action.payload.reserveGroupId;
      break;
    case ActionTypes.HAPPINESS_SURVEY_REMOVE_FEEDBACK:
      surveyToUpdate = happinessSurvey.itemSurveys.find(survey => {
        return survey.bookingId === action.payload.bookingId;
      });

      surveyToUpdate.surveyFeedbacks = _.reject(surveyToUpdate.surveyFeedbacks, function (f) {
        return f.feedback === action.payload.feedback;
      });
      break;

    case ActionTypes.HAPPINESS_SURVEY_REMOVE_ALL_FEEDBACK:
      surveyToUpdate = happinessSurvey.itemSurveys.find(survey => {
        return survey.bookingId === action.payload;
      });

      surveyToUpdate.surveyFeedbacks = [];
      break;

    case ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_SUCCESS:
      happinessSurvey.completedSurveysByStyle = action.payload;
      happinessSurvey.completedSurveysByStyleLoading = false;
      break;
    case ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_BEGIN:
      happinessSurvey.completedSurveysByStyle = null;
      happinessSurvey.completedSurveysByStyleLoading = true;
      break;
    case ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_FAILURE:
      happinessSurvey.completedSurveysByStyle = null;
      happinessSurvey.completedSurveysByStyleLoading = false;
      break;

    case ActionTypes.HAPPINESS_SURVEY_SET_ITEM_IN_PROGRESS: {
      bookingId = action.payload;
      if (!Array.isArray(happinessSurvey.itemSurveys) || happinessSurvey.itemSurveys.length === 0) {
        break;
      }

      let foundMatch = false;
      happinessSurvey.itemSurveys?.forEach(survey => {
        if (survey.bookingId === bookingId) {
          survey.inProgress = true;
          foundMatch = true;
        } else {
          survey.inProgress = false;
        }
      });
      //  if none match the provided bookingId, mark the first one inProgress
      if (!foundMatch) {
        happinessSurvey.itemSurveys[0].inProgress = true;
      }
      break;
    }

    default:
      break;
  }
  return happinessSurvey;
};

const types = [
  ActionTypes.FETCH_HAPPINESS_SURVEYS_SUCCESS,
  ActionTypes.FETCH_HAPPINESS_SURVEYS_COMPLETED_SUCCESS,
  ActionTypes.HAPPINESS_SURVEY_ADD_FEEDBACK,
  ActionTypes.HAPPINESS_SURVEY_REMOVE_FEEDBACK,
  ActionTypes.HAPPINESS_SURVEY_REMOVE_ALL_FEEDBACK,
  ActionTypes.HAPPINESS_SURVEY_ADD_COMMENT,
  ActionTypes.HAPPINESS_SURVEY_ADD_RATING,
  ActionTypes.HAPPINESS_SURVEY_ADD_CAPTION,
  ActionTypes.HAPPINESS_SURVEY_ADD_REVIEW_CONTENT,
  ActionTypes.HAPPINESS_SURVEY_COMPLETED,
  ActionTypes.HAPPINESS_SURVEY_SET_ITEM_IN_PROGRESS,
  ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_BEGIN,
  ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_SUCCESS,
  ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_FAILURE,
  ActionTypes.SET_HAPPINESS_SURVEY_INFO,
];

const fn = function (state, action) {
  return _.extend({}, state, {
    happinessSurvey: happinessSurveyModification(state, action),
  });
};

export default {
  types: types,
  fn: fn,
};
