import React, { useEffect } from "react";
import { logAction } from "action-logger";
import { shallowEqual, useSelector } from "react-redux";
import { getDisplayName } from "./utils";
import { getUserId, useUserData } from "./with-user-data";

export function useRtrSession() {
  return useSelector(({ rtrSession }) => rtrSession, shallowEqual) || {};
}

// loggingData is a function which allows callers to manipulate the data
export function useLogging(loggingData = d => d) {
  return () => {
    const commonLoggingData = loggingData({});

    logAction(commonLoggingData);
  };
}

export function withLogging({ loggingData = () => ({}), condition = () => true, logOnMount = true } = {}) {
  return WrappedComponent => {
    const C = props => {
      const { userProfileHasLoaded } = useUserData({});
      const userData = useUserData();
      const { rtrId } = useRtrSession();

      const handleLog = (userId, rtrId) => {
        const data = Object.assign({ user_id: userId, rtr_id: rtrId }, loggingData(props));
        logAction(data);
      };
      if (logOnMount) {
        useEffect(() => {
          if (!condition(props)) return;
          if (!userProfileHasLoaded) return;

          const userId = getUserId(userData) || null;
          handleLog(userId, rtrId);
        }, [userProfileHasLoaded]); // once user has loaded
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...props} onLog={handleLog} />;
    };

    C.displayName = `withLogging(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}
