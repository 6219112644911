import _ from "underscore";
import ActionTypes from "actions/action-types.js";

const types = [ActionTypes.CMS_CONTENT_UPDATED];

const cmsContentModification = function (state, action) {
  var cmsContent = _.extend({}, state.cmsContent);

  switch (action.type) {
    case ActionTypes.CMS_CONTENT_UPDATED:
      if (action.payload.stateKey && action.payload.content) {
        cmsContent[action.payload.stateKey] = action.payload.content;
      }
      break;
    default:
      break;
  }

  return cmsContent;
};

const fn = function (state, action) {
  const cmsContent = cmsContentModification(state, action);
  return {
    ...state,
    cmsContent: cmsContent,
  };
};

export default {
  types: types,
  fn: fn,
};
