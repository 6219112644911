import classNames from "classnames";
import AtomAddress from "components/source/atoms/atom-address";

const AtomManageAddressCardWithStatusActions = ({
  address,
  selectDefaultProfileId,
  selectedAddressId,
  toggleAddressMethodRemoval,
}) => {
  const { id } = address || {};
  const isDefault = selectedAddressId === id;

  return (
    <div
      className={classNames("atom-radio-card", "manage-payment-profile-card", {
        "manage-payment-profile-card--default": isDefault,
      })}
      role="listitem">
      <div role="list">
        <AtomAddress address={address} className={"atom-payment-profile-card__info"} />
        {isDefault && (
          <div className="atom-payment-profile-card__default" aria-label="Default address label" role="listitem">
            Default
          </div>
        )}
      </div>
      {!isDefault && (
        <div className="atom-payment-profile-card__actions" aria-label="Address method actions" role="list">
          <button
            className="atom-payment-profile-card__make-default underline"
            onClick={() => selectDefaultProfileId(id)}
            aria-label="Action to make this address the default address"
            role="listitem">
            Make Default
          </button>
          <button
            className="atom-payment-profile-card__remove underline"
            aria-label="Action to remove this address"
            onClick={() => toggleAddressMethodRemoval(id)}
            role="listitem">
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export default AtomManageAddressCardWithStatusActions;
