import ActionTypes from "actions/action-types";

const types = [ActionTypes.LANDING_PAGE_CAROUSEL_PRODUCTS_SUCCESS, ActionTypes.LANDING_PAGE_CAROUSEL_PRODUCTS_SUCCESS];

const fn = (state, action) => {
  switch (action.type) {
    case ActionTypes.LANDING_PAGE_CAROUSEL_PRODUCTS_SUCCESS: {
      const { carouselIndex, id, products } = action.payload;
      const updatedContentModules = state.contentModules.map(module => {
        if (module.id === id) {
          const updatedCarousels = module.attributes?.productCarousels.map((c, i) => {
            if (i !== carouselIndex) {
              return c;
            } else {
              return {
                ...c,
                products: [...(c.products || []), ...products],
              };
            }
          });
          return {
            ...module,
            attributes: {
              ...module.attributes,
              productCarousels: updatedCarousels,
            },
          };
        } else return module;
      });
      return {
        ...state,
        contentModules: updatedContentModules,
      };
    }
    case ActionTypes.LANDING_PAGE_CAROUSEL_PRODUCTS_FAILURE:
      console.error("Error fetching carousel products", action.payload);
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default {
  types: types,
  fn: fn,
};
