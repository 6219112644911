import { isToday, isTomorrow, format, isDate } from "date-fns";
import { pluralize } from "helpers/FormattingUtils";
import { dateFnsFormats } from "rtr-constants";
import { LocalStorage } from "../site/localStorage";
import { parseISOWithoutTime } from "./date-helpers";
const DEFAULT_SHIPPING_ADDRESS_ZIP_STORE_KEY = "defaultShippingZip";

export const ZIP_CODE_MODAL_OBJECT_TYPE = "zip_code_modal";

export function getDefaultShippingZip(addresses = []) {
  if (!addresses || !addresses.length) {
    if (typeof window === "undefined") {
      return;
    }
    if (!window?.localStorage) {
      return;
    }
    return localStorage.getItem(DEFAULT_SHIPPING_ADDRESS_ZIP_STORE_KEY);
  }

  const shippingAddress = addresses.find(function (address) {
    return address.default;
  });

  if (typeof shippingAddress === "undefined") {
    return;
  }

  return shippingAddress.postalCode;
}

export function clearDefaultShippingZip() {
  if (typeof window === "undefined" || LocalStorage.disabled) {
    return null;
  }

  const lsClient = new LocalStorage();
  lsClient.remove(DEFAULT_SHIPPING_ADDRESS_ZIP_STORE_KEY);
}

function validPredictedZipFromAkamai(predictedZip) {
  if (typeof predictedZip !== "string") {
    return false;
  }
  if (predictedZip.length < 5) {
    return false;
  }
  return true;
}

export function getUserLocationZip(defaultShippingZip, userProfileZip, predictedZip) {
  // fallbackZip will be used to filter the grid if none of the above is available
  const fallbackZip = "10014";

  if (defaultShippingZip) {
    return defaultShippingZip;
  }
  if (userProfileZip) {
    return userProfileZip;
  }
  if (validPredictedZipFromAkamai(predictedZip)) {
    // predictedZip is the ZIP we extract from the Akamai header. it's a long string of possible ZIPs, so I am just using the first 5 digits.
    return predictedZip.substring(0, 5);
  }
  return fallbackZip;
}

export function getSizeLabel(selectedSizes, inMaternityExperience, isMobileViewport) {
  const prefix = inMaternityExperience ? "Maternity" : pluralize("Size", selectedSizes?.length || 0);

  if (!selectedSizes || !selectedSizes.length) {
    const text = isMobileViewport ? "Sizes" : "Select Sizes";
    return <span data-test-id="select-sizes">{text}</span>;
  }

  if (selectedSizes.length > 1) {
    const sortedSizes = [...selectedSizes]
      .sort(function (a, b) {
        return a - b;
      })
      .join(", ");

    // If a user has more than 4 sizes selected, we only show a ... after the 4th size
    const sizesArray = sortedSizes.split(",").splice(0, 4);
    if (selectedSizes.length > 4) sizesArray.push(" ...");
    const sortedSizesString = sizesArray.join(",");

    return sortedSizesString;
  }

  return `${prefix} ${selectedSizes[0]}`;
}

export function formattedDeliveryDate(deliveryDate) {
  if (!deliveryDate) return;
  let date = deliveryDate;
  if (!isDate(date)) {
    date = parseISOWithoutTime(deliveryDate);
  }
  if (isToday(date)) return "Today";
  if (isTomorrow(date)) return "Tomorrow";
  return format(date, dateFnsFormats.M_DD);
}
