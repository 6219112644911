import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import AtomAnimatedDropdown from "components/source/atoms/atom-animated-dropdown";

class AnimatedDropdownWrapper extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    additionalClassName: PropTypes.string,
    label: PropTypes.string.isRequired,
    validationErrorMsg: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
      })
    ),
    placeholderOption: PropTypes.string,
    onBlurCallback: PropTypes.func, // (value: string, isValid: boolean, name: string, inputObj: Object) => void,
    onChangeCallback: PropTypes.func, // (value: string, isValid: boolean, name: string, inputObj: Object) => void,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: "",
      error: "",
      isValid: null,
    };
  }

  validationErrorMsg() {
    // Add "Required" to the label if no validationErrorMsg prop is passed
    // This is essentially setting a default prop, but because React defaultProps are a property of the
    // component class they don't have access to this.props.
    // That's why this is done here and not in AnimatedDropdownWrapper.defaultProps
    if (this.props.validationErrorMsg) {
      return this.props.validationErrorMsg;
    } else {
      return `Required: ${this.props.label}`;
    }
  }

  componentDidMount() {
    // Handle value and error props from parent that are received immediately with first set of props
    if (this.props.value) {
      this.handleValueProp(this.props.value);
    }

    if (this.props.error) {
      this.handleErrorProp(this.props.error);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Handle value prop from parent that is received later from ajax request
    if (this.props.value !== nextProps.value) {
      this.handleValueProp(nextProps.value);
    }

    // Overwrite any validation error with error received from parent
    // If the error is null or empty string, we also want to update that here
    // because it means that an existing error has been fixed.
    // However, we do not want to reset the error if the error is undefined.
    // This happens when there is no error key in nextProps. It can also happen when a parent
    // component does not receive an error prop and then passes `this.props.error` to this component.
    // This results in this component receiving a props object with { error: undefined }
    // We are accepting that calling code may intentionally send a null error but we
    // change it to an empty string to keep the types consistent and developers sane.
    if (!_.isUndefined(nextProps.error)) {
      this.setState({
        error: nextProps.error || "", // convert a null error to empty string
      });
    }
  }

  handleChange(e) {
    const value = e.target.value;
    this.setUserValue(value);
    this.validateChange(value);
  }

  handleBlur(e) {
    const value = e.target.value;
    this.validateBlur(value);
  }

  validate(value) {
    // Because this is a dropdown, user input is very constrained so at least this iteration
    // of the wrapper does not take a validation function.
    // So the only case handled here is what to do about empty input when required vs. not required
    // For example, if a placeholderOption with "" value is included
    if (value) {
      return true;
    } else {
      return this.props.required ? false : true;
    }
  }

  setUserValue(value) {
    this.setState({
      value: value,
    });
  }

  selectObject() {
    // additional data on the input object sent to callbacks
    // use _.extend for state to get additional state properties
    // state is source of truth for value, error, & isValid.
    return _.extend([], this.props, this.state);
  }

  validateChange(value) {
    const isValid = this.validate(value);
    if (isValid) {
      this.setValidState();
    }

    if (this.props.onChangeCallback) {
      const onChangeCallback = this.props.onChangeCallback; // Why this was done: https://github.com/facebook/flow/issues/1938
      // a function that does more things on change, e.g., ajax call for availbility, logging
      onChangeCallback(value, isValid, this.props.name, this.selectObject());
    }
  }

  validateBlur(value) {
    const isValid = this.validate(value);
    if (isValid) {
      this.setValidState();
    } else {
      this.setState({
        error: this.validationErrorMsg(),
        isValid: false,
      });
    }

    if (this.props.onBlurCallback) {
      const onBlurCallback = this.props.onBlurCallback; // Why this was done: https://github.com/facebook/flow/issues/1938
      // a function that does more things on blur, e.g., ajax call for availbility, logging
      onBlurCallback(value, isValid, this.props.name, this.selectObject());
    }
  }

  setValidState() {
    this.setState({
      error: "",
      isValid: true,
    });
  }

  handleValueProp(value) {
    // Populate the input with the value and do a blur validation
    this.setState({
      value: value,
    });

    this.validateBlur(value);
  }

  handleErrorProp(error) {
    this.setState({
      error: error,
    });
  }

  render() {
    return (
      <AtomAnimatedDropdown
        id={this.props.id}
        name={this.props.name}
        additionalClassName={this.props.additionalClassName}
        label={this.props.label}
        placeholderOption={this.props.placeholderOption}
        required={this.props.required}
        error={this.state.error}
        isValid={this.state.isValid}
        onChange={e => this.handleChange(e)}
        onBlur={e => this.handleBlur(e)}
        value={this.state.value}
        options={this.props.options}
        autoComplete={this.props.autoComplete}
        disabled={this.props.disabled}
      />
    );
  }
}

export default AnimatedDropdownWrapper;
