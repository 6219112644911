import PropTypes from "prop-types";

export const authModalPropType = PropTypes.shape({
  autoPrompt: PropTypes.bool,
  callback: PropTypes.func,
  isFullScreen: PropTypes.bool,
  isOpen: PropTypes.bool,
  mainClass: PropTypes.string,
  source: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  view: PropTypes.string,
});
