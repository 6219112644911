import ActionTypes from "actions/action-types";

const types = [
  ActionTypes.SAILTHRU_OPT_IN_SUCCESS,
  ActionTypes.SAILTHRU_OPT_IN_FAILURE,
  ActionTypes.SAILTHRU_OPT_IN_LOADING,
];

const initialState = {
  success: false,
  loading: false,
  error: null,
};

const getSailthruOptIn = (state, action) => {
  const sailthruState = state.sailthruOptIn ?? initialState;
  const { payload, type } = action;

  switch (type) {
    case ActionTypes.SAILTHRU_OPT_IN_SUCCESS: {
      const sailthruOptIn = { ...payload, success: true, error: null, loading: false };

      return sailthruOptIn;
    }
    case ActionTypes.SAILTHRU_OPT_IN_FAILURE: {
      const sailthruOptIn = { success: false, error: payload.error, loading: false };

      return sailthruOptIn;
    }
    case ActionTypes.SAILTHRU_OPT_IN_LOADING: {
      const sailthruOptIn = { ...sailthruState, loading: true };

      return sailthruOptIn;
    }
    default:
      return sailthruState;
  }
};

const fn = (state, action) => {
  const sailthruOptIn = getSailthruOptIn(state, action);

  return { ...state, sailthruOptIn };
};

export default {
  types,
  fn,
};
