import React from "react";
import smartComponentActions from "../../../actions/smart-component-actions";
import Banner from "./banner";
import PropTypes from "prop-types";
import { bannerPropType } from "components/propTypes";

export default class SmartSkinnyBanner extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    onHide: PropTypes.func,
    onNotApplicable: PropTypes.func,
    onShow: PropTypes.func,
    smartComponentState: PropTypes.shape({
      smartSkinnyBannerCMS: bannerPropType,
    }),
  };

  static defaultProps = {
    // When desktopCopy is blank, the SmartSkinnyBanner doesn't display anything
    smartComponentState: {
      smartSkinnyBannerCMS: {
        desktopCopy: "",
        backgroundColor: "#FFF",
      },
    },
  };

  componentDidMount() {
    const { dispatch } = this.props;

    smartComponentActions.getSkinnyBannerContent()(dispatch);
  }

  render() {
    const promo = this.props.smartComponentState?.smartSkinnyBannerCMS || {};
    return (
      <Banner
        bannerId="smart-skinny-banner"
        backgroundColor={promo.backgroundColor}
        color={promo.color}
        desktopCopy={promo.desktopCopy}
        desktopCta={promo.desktopCta}
        details={promo.details}
        link={promo.link}
        linkCopy={promo.linkCopy}
        mobileCopy={promo.mobileCopy}
        mobileCta={promo.mobileCta}
        promoCode={promo.promoCode}
        onShow={this.props.onShow}
        onHide={this.props.onHide}
        onNotApplicable={this.props.onNotApplicable}
      />
    );
  }
}
