import React from "react";
import PropTypes from "prop-types";
import ActionLogger from "action-logger";

export default class MobileNavToggle extends React.Component {
  static propTypes = {
    isMobileAccountMenuExpanded: PropTypes.bool,
    isMobileNavExpanded: PropTypes.bool,
    isWideWidth: PropTypes.bool,
    toggleMobileNav: PropTypes.func.isRequired,
    toggleMobileNavAccountMenu: PropTypes.func.isRequired,
  };

  toggleMobileNav = () => {
    const closeMobileAccountMenu = this.props.isMobileAccountMenuExpanded;
    const nextState = !(this.props.isMobileNavExpanded || this.props.isMobileAccountMenuExpanded);
    this.props.toggleMobileNav(nextState);

    if (closeMobileAccountMenu) {
      this.props.toggleMobileNavAccountMenu(false);
    }

    ActionLogger.logAction({
      objectType: "top_nav",
      action: "click_nav_icon",
      icon_name: "menu",
      state: closeMobileAccountMenu ? "close" : "open",
    });
  };

  render() {
    if (this.props.isWideWidth) {
      return null;
    }

    // NW [EXPLANATION] 10/4/21: the legacy implementation of the hamburger menu icon for mobile web nav is 4 empty span tags
    return (
      <button className="collapsed-menu" onClick={this.toggleMobileNav}>
        <span className="menu-icon" id="nav-icon">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
      </button>
    );
  }
}
