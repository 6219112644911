import { creditCardTypes } from "rtr-constants";

// This reads in creditCardTypes from Commerce and returns string names
// which are specified in the _credit_cards.scss partial.

const creditCardClassName = (type, subType = "") => {
  if (subType === "applePay") return "apple_pay";

  switch (type) {
    case creditCardTypes.VISA:
      return "visa";
    case creditCardTypes.MASTER_CARD:
      return "master_card";
    case creditCardTypes.JCB:
      return "jcb";
    case creditCardTypes.DISCOVER:
      return "discover";
    case creditCardTypes.AMERICAN_EXPRESS:
      return "amex";
    default:
      return "";
  }
};

export default creditCardClassName;
