import ActionTypes from "../actions/action-types.js";

const types = [
  ActionTypes.HAS_VIEWED_ATTRIBUTION_SURVEY,
  ActionTypes.FETCH_CUSTOMER_ATTRIBUTION_SURVEY_OPTIONS_SUCCESS,
];

const getCustomerAttributionSurveyState = function (state, action) {
  const newState = state;

  switch (action.type) {
    case ActionTypes.FETCH_CUSTOMER_ATTRIBUTION_SURVEY_OPTIONS_SUCCESS:
      newState.attributionSurveyOptions = action.payload;
      break;
    case ActionTypes.HAS_VIEWED_ATTRIBUTION_SURVEY:
      newState.hasViewedAttributionSurvey = true;
      break;
  }

  return newState;
};

const fn = function (state, action) {
  const newState = getCustomerAttributionSurveyState(state, action);

  return {
    ...state,
    newState,
  };
};

export default {
  types: types,
  fn: fn,
};
