import React from "react";
import Banner from "components/source/navigation/banner";
import { SessionStorage } from "site/localStorage";
import { withQueryParams } from "../hoc/with-query-params";
import { paidPromotions } from "rtr-constants";
import { setWithExpiry, getLocalParam, readPromoValue } from "./url-param-reader";
import PropTypes from "prop-types";

const sessionStorageClient = new SessionStorage(paidPromotions.PAID_STORAGE_PARAM_NAME);

export class PaidPromoBanner extends React.Component {
  static propTypes = {
    // From withQueryParams
    queryParams: PropTypes.shape({
      [paidPromotions.URL_PARAMETER_PAID_PROMOTION]: PropTypes.string,
      [paidPromotions.URL_PARAMETER_PAID_PROMOTION_DISCOUNT]: PropTypes.string,
    }),
  };

  state = {
    paidPromoBannerText: null,
  };

  configurePaidPromo() {
    this.setState({
      paidPromoBannerText: getPaidPromoText(this.props.queryParams),
    });
  }

  componentDidMount() {
    this.configurePaidPromo();
  }

  render() {
    return (
      <Banner
        bannerId="paid-banner"
        backgroundColor="#A0D1CA"
        color="#FFFFFF"
        desktopCopy={this.state.paidPromoBannerText}
        mobileCopy={this.state.paidPromoBannerText}
      />
    );
  }
}

export function isPaidPromoLink(queryParams) {
  const sessionStorageClient = new SessionStorage("smartComponents");
  const couponCode =
    queryParams[paidPromotions.URL_PARAMETER_PAID_PROMOTION] ||
    sessionStorageClient.get(paidPromotions.URL_PARAMETER_PAID_PROMOTION || "");

  //if url loads with the parameters in URL then setState and save values to local storage so paid banner displays
  //while navigating the application
  if (couponCode) {
    return true;
  }
  return false;
}

export default withQueryParams(
  paidPromotions.URL_PARAMETER_PAID_PROMOTION,
  paidPromotions.URL_PARAMETER_PAID_PROMOTION_DISCOUNT
)(PaidPromoBanner);

export function getPaidPromoText(queryParams) {
  //Check if there is already a saved value in session storage so we can check it against a
  //potentially new value in the URL
  const cpnParamter = getLocalParam(
    paidPromotions.URL_PARAMETER_PAID_PROMOTION,
    paidPromotions.PAID_STORAGE_PARAM_NAME
  );
  const dscParamter = getLocalParam(
    paidPromotions.URL_PARAMETER_PAID_PROMOTION_DISCOUNT,
    paidPromotions.PAID_STORAGE_PARAM_NAME
  );

  //Gets cpn and/or dsc paramter value from URL if its present and if not check sessionStorage for them
  const couponCode = readPromoValue(
    paidPromotions.URL_PARAMETER_PAID_PROMOTION,
    paidPromotions.PAID_STORAGE_PARAM_NAME,
    queryParams
  );
  const couponDiscount = readPromoValue(
    paidPromotions.URL_PARAMETER_PAID_PROMOTION_DISCOUNT,
    paidPromotions.PAID_STORAGE_PARAM_NAME,
    queryParams
  );

  if (cpnParamter !== couponCode || dscParamter !== couponDiscount) {
    sessionStorageClient.remove(paidPromotions.URL_PARAMETER_PAID_PROMOTION_DISCOUNT);
    sessionStorageClient.remove(paidPromotions.URL_PARAMETER_PAID_PROMOTION);
  }

  //If url loads with the cpn and/or dsc parameters in URL then setState and save values to local storage so paid banner displays
  //while navigating the application
  if (couponCode) {
    setWithExpiry(paidPromotions.URL_PARAMETER_PAID_PROMOTION, couponCode, 600, paidPromotions.PAID_STORAGE_PARAM_NAME);

    //if there are 2 parameters in the paid promotion link then we use this text
    if (couponDiscount) {
      setWithExpiry(
        paidPromotions.URL_PARAMETER_PAID_PROMOTION_DISCOUNT,
        couponDiscount,
        600,
        paidPromotions.PAID_STORAGE_PARAM_NAME
      );

      return (
        `Limited time offer: Try now and get ${getLocalParam(
          paidPromotions.URL_PARAMETER_PAID_PROMOTION_DISCOUNT,
          "smartComponents"
        )}% off with code ` +
        getLocalParam(paidPromotions.URL_PARAMETER_PAID_PROMOTION, paidPromotions.PAID_STORAGE_PARAM_NAME) +
        paidPromotions.LEGAL_DISCLAIMER
      );
    }

    return (
      paidPromotions.DEFAULT_PAID_PROMO_TEXT +
      getLocalParam(paidPromotions.URL_PARAMETER_PAID_PROMOTION, paidPromotions.PAID_STORAGE_PARAM_NAME) +
      paidPromotions.LEGAL_DISCLAIMER
    );
  }
}
