import React from "react";
import PropTypes from "prop-types";
import { onEnterPress } from "helpers/a11y-helper";

class AtomPrimaryButton extends React.Component {
  static propTypes = {
    ariaLabel: PropTypes.string,
    buttonText: PropTypes.node.isRequired,
    className: PropTypes.string,
    dataHeapId: PropTypes.string,
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: () => {}, // NOSONAR
  };

  render() {
    const { ariaLabel, buttonText, className, dataHeapId, dataTestId, disabled, onClick } = this.props;

    return (
      <button
        className={`btn btn-primary ${className || ""}`}
        onKeyDown={onEnterPress(() => onClick)}
        onClick={onClick}
        data-test-id={dataTestId || ""}
        disabled={disabled}
        aria-label={ariaLabel}
        data-heap-id={dataHeapId || ""}>
        {buttonText}
      </button>
    );
  }
}

export default AtomPrimaryButton;
