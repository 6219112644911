import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import ActionLogger from "action-logger";
import Constants from "rtr-constants";

class Shortlist extends React.Component {
  static propTypes = {
    addStyleToShortlist: PropTypes.func.isRequired,
    favorites: PropTypes.arrayOf(PropTypes.string),
    onClickFavoriteShortlist: PropTypes.func.isRequired,
    productId: PropTypes.string,
    removeStyleFromShortlist: PropTypes.func.isRequired,
    shortlist: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      styleNames: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  state = {
    productInShortlist: _.contains(this.props.shortlist.styleNames, this.props.productId),
    error: false,
  };

  onClickCheck = e => {
    e.preventDefault();

    this.setState({
      productInShortlist: false,
    });

    this.toggleProductInShortlist();
  };

  /**
   * @description on failure, reset the component state to provide user feedback
   * @callback Shortlist~toggleOnFailureCallback
   */
  toggleOnFailureCallback = () => {
    this.setState({
      productInShortlist: !this.state.productInShortlist,
      error: true,
    });
  };

  toggleProductInShortlist = () => {
    if (this.props.shortlist.id === Constants.ALL_HEARTS_SHORTLIST_ID) {
      this.props.onClickFavoriteShortlist();
    } else {
      this.setState(
        {
          productInShortlist: !this.state.productInShortlist,
          error: false,
        },
        () => {
          const logAction = this.state.productInShortlist ? "add_item" : "delete_item";
          ActionLogger.logAction({
            object_type: "shortlist",
            action: logAction,
            styleName: this.props.productId,
            source: "grid",
          });

          if (this.state.productInShortlist) {
            this.props.addStyleToShortlist(
              this.props.shortlist.id,
              this.props.productId,
              null,
              this.toggleOnFailureCallback
            );
          } else {
            this.props.removeStyleFromShortlist(
              this.props.shortlist.id,
              this.props.productId,
              null,
              this.toggleOnFailureCallback
            );
          }
        }
      );
    }
  };

  render() {
    const className =
      this.state.productInShortlist || this.props.shortlist.id === Constants.ALL_HEARTS_SHORTLIST_ID
        ? "checked"
        : "unchecked";

    return (
      <li
        className={`shortlist shortlist--${className}`}
        onClick={this.onClickCheck}
        data-style-name={this.props.productId}
        data-shortlist-name={this.props.shortlist.name}
        data-test-id={this.state.error ? "error" : "no-error"}
        onTouchEnd={this.onClickCheck}>
        <span>{this.props.shortlist.name}</span>
        <div className="shortlist-icon"></div>
      </li>
    );
  }
}

export default Shortlist;
