const WHOOPS_TRY_AGAIN = "Whoops! That didn’t work. Please try again.";
const TRY_AGAIN_LATER = "Something went wrong. Please try again later.";
// These messages correspond to the unique error code passed from sessions controller and users service
const errorCodeToMessageMap = {
  default: WHOOPS_TRY_AGAIN,
  406: TRY_AGAIN_LATER,
  429: TRY_AGAIN_LATER,
};

export const errorMessages = Object.freeze(errorCodeToMessageMap);

/**
 * Determines if the given error code from sessions controller and users service has an associated error message
 * @param {number} errorStatus - The Http error status from the server
 * @returns {boolean}
 */
export function hasAssociatedErrorMessage(errorStatus) {
  return !!errorMessages[errorStatus];
}

/**
 * Gets the client error message for the given error code from sessions controller and users service
 * @param {number} errorStatus - The Http error status from the server
 * @returns {string} - returns the corresponding client error message for the unique error code or the default message
 */
export function getErrorMessage(errorStatus) {
  if (hasAssociatedErrorMessage(errorStatus)) {
    return errorMessages[errorStatus];
  }

  return errorMessages.default;
}
