import React from "react";
import PropTypes from "prop-types";
import ClosableModal from "components/source/shared/closable-modal";
import sharedActions from "actions/shared-actions";
import happinessSurveyActions from "actions/happiness-survey-actions";
import MembershipHelpers from "helpers/membership-helpers";
import { connect } from "react-redux";
import { reserveExchangeSource, reserveExchangeVersion } from "rtr-constants";
import ProductSelection from "./hs_self_service_exchange/ProductSelection";

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  itemSurveys: PropTypes.array,
  userData: PropTypes.object.isRequired,
  atHomeItems: PropTypes.array,
  membershipState: PropTypes.object,
};

// a modal that triggers happiness surveys for Reserve SSE items
class HappinessSurveyTriggerModal extends React.Component {
  static get modalName() {
    return "HappinessSurveyTriggerModal";
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemSurveys && this.props.itemSurveys !== prevProps.itemSurveys) {
      // happiness survey modal will take over now - this one can be closed
      this.closeModal();
    }
  }

  buildItemObject = matchedItem => {
    const item = {};
    item.userId = this.props.userData?.userProfile?.id;
    item.bookingId = matchedItem.bookingId;
    item.styleName = matchedItem?.product?.id;
    item.size = matchedItem.size;
    item.source = reserveExchangeSource;
    item.version = reserveExchangeVersion;

    return item;
  };

  // Initially Callbacks for initializing happiness survey,  but feel free to use them in other ways as well.
  fetchHappinessSurveys = () => {
    const isReserve = true;
    return this.props.dispatch(happinessSurveyActions.fetchHappinessSurveys(() => {}, isReserve));
  };

  initializeReserveHappinessSurvey = reserveItems => {
    const items = reserveItems.map(item => this.buildItemObject(item));
    happinessSurveyActions.initializeHappinessSurvey(items, this.fetchHappinessSurveys);
  };

  closeModal = () => {
    this.props.dispatch(sharedActions.displayModal(false));
  };

  isHappinessSurveyTriggerModalOpen() {
    return this.props.displayedModal === this.constructor.modalName;
  }

  renderModalContents() {
    return <ProductSelection items={this.props.atHomeItems} onSubmit={this.initializeReserveHappinessSurvey} />;
  }

  itemsToReturn() {
    return this.props.atHomeItems || MembershipHelpers.filterQualifiedHappinessSurveyItems(this.props.membershipState);
  }

  render() {
    if (!this.props.userData || !this.itemsToReturn()) {
      return null;
    }

    return (
      <ClosableModal
        isOpen={this.isHappinessSurveyTriggerModalOpen()}
        displayedModal={this.constructor.modalName}
        onRequestClose={this.closeModal}
        optionalClass="exchange-product-selection">
        {this.renderModalContents()}
      </ClosableModal>
    );
  }
}

const mapStateToProps = state => {
  const { displayedModal, userData } = state;
  const membershipState = MembershipHelpers.getMembershipState(state);

  return {
    displayedModal,
    itemSurveys: state.happinessSurvey?.itemSurveys,
    userData,
    membershipState,
  };
};

HappinessSurveyTriggerModal.propTypes = propTypes;

export default connect(mapStateToProps)(HappinessSurveyTriggerModal);
