import React from "react";
import PropTypes from "prop-types";

import AnimatedTextInputWrapper from "components/source/shared/animated-text-input-wrapper";
import constants from "rtr-constants";
const { zipCodeValidator, zipPlusFourValidator } = constants.validations.zipCode;
const defaultValidationErrorMsg = constants.clientSideErrorMessages.formValidation.zipCode;
const defaultLabel = constants.formLabels.zipCode;

class AnimatedZipInput extends React.Component {
  static propTypes = {
    additionalClassName: PropTypes.string,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    focus: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onBlurCallback: PropTypes.func, // (value: string, isValid: ?boolean, name: string, inputObj: Object) => void,
    onChangeCallback: PropTypes.func, // (value: string, isValid: ?boolean, name: string, inputObj: Object) => void,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    validationErrorMsg: PropTypes.string,
    value: PropTypes.string,
    zipPlusFour: PropTypes.bool,
  };

  validateInput(value) {
    const validator = this.props.zipPlusFour ? zipPlusFourValidator : zipCodeValidator;
    return value && !!value.match(validator);
  }

  maxLength() {
    return this.props.zipPlusFour ? 10 : 5;
  }

  render() {
    return (
      <AnimatedTextInputWrapper
        id={this.props.id}
        name={this.props.name}
        additionalClassName={this.props.additionalClassName}
        type={"tel"}
        label={this.props.label || defaultLabel}
        validateInput={value => this.validateInput(value)}
        maxLength={this.maxLength()}
        validationErrorMsg={this.props.validationErrorMsg || defaultValidationErrorMsg}
        error={this.props.error}
        value={this.props.value}
        onBlurCallback={this.props.onBlurCallback}
        onChangeCallback={this.props.onChangeCallback}
        focus={this.props.focus}
        required={this.props.required}
        readOnly={this.props.readOnly}
        autoComplete={this.props.autoComplete}
        disabled={this.props.disabled}
      />
    );
  }
}

export default AnimatedZipInput;
