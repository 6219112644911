import _ from "underscore";
import { createAction } from "redux-actions";
import Cookies from "universal-cookie";

import ActionTypes from "./action-types";
import ActionLogger from "action-logger";
import filterHelpers from "../helpers/filter-helpers";
import userSelections from "../helpers/user-selections";
import productsActions from "../actions/products-actions";

import TatariHelper from "helpers/tatari-helper";
import { loadPaymentProfiles } from "actions/payment-method-actions";
import { loadAddressBook } from "actions/shipping-step-actions";
import { gridTypes, hierarchyIds } from "rtr-constants";
import { getDefaultShippingZip } from "helpers/dfc-ux-helper";
import * as castleHelper from "helpers/castle-helper";
import HeapHelpers from "helpers/heap-helpers";
import { fireWithUserData, getUserId, hasUserData } from "../components/source/hoc/with-user-data";
import { requestedFlagSelector, Flags } from "components/source/hoc/with-feature-flags";
import { hasMembershipStateMember, isMember } from "../helpers/membership-helpers";
import { getNewCustomerOfferText } from "../helpers/new-customer-offer-text-helpers";
import { augmentConsoleErrorWithJqueryXHR } from "../helpers/ajax-helpers";
import { loadCastleSDK } from "helpers/castle-helper";
import { getQueryParam } from "../helpers/location-helpers";
import * as SplunkRumHelpers from "../helpers/splunk-rum-helpers";
import { authLocalStorageClient } from "../helpers/auth-helpers";
import RawClient from "clients/RawClient";
import { executePostAuthenticationClientSideActions } from "actions/post-authentication-actions";

const actions = {
  googleMapsLoaded: createAction(ActionTypes.GOOGLE_MAPS_LOADED),
  setCsrfProtection: createAction(ActionTypes.SET_CSRF_PROTECTION),
  setFlags: createAction(ActionTypes.SET_FEATURE_FLAGS_DATA),
  setNewCustomerOfferText: createAction(ActionTypes.SET_NEW_CUSTOMER_OFFER_TEXT),
  setPageName: createAction(ActionTypes.SET_PAGE_NAME),
  setPageType: createAction(ActionTypes.SET_PAGE_TYPE),
  setPersistedFilters: createAction(ActionTypes.SET_PERSISTED_FILTERS),
  updateUserData: createAction(ActionTypes.UPDATE_USER_DATA),
  writeUxUserData: createAction(ActionTypes.SET_UX_USER_DATA),
  writeBrowserData: createAction(ActionTypes.SET_BROWSER_DATA),

  getCsrfToken: function () {
    return function (dispatch) {
      // There are certain scenarios where the /csrfProtection endpoint could produce an error. The most common would be
      // a cached page that returns without the Set-Cookie response header (these are stripped out at the Fastly edge to
      // prevent caching user-specific cookies in our responses. For a brand-new user that doesn't yet have the
      // storefront.session cookie (that contains the csrf token encrypted within it) stored in their browser.
      let timeoutId;
      let retryCount = 0;
      const retryLimit = 5;

      function ajaxRequest() {
        RawClient.ajax({
          type: "GET",
          url: "/csrfProtection",
        }).then(
          data => {
            const csrfToken = data?.csrfToken;
            dispatch(actions.setCsrfProtection({ csrfToken, hasLoaded: true }));
            RawClient.setCsrfToken(csrfToken);

            if (timeoutId) clearTimeout(timeoutId);
          },
          jqXHR => {
            const errorMessage = augmentConsoleErrorWithJqueryXHR("Failed to retrieve csrf token", jqXHR);
            dispatch(actions.setCsrfProtection({ csrfToken: null, hasLoaded: true }));
            console.error(new Error(errorMessage));

            timeoutId = setTimeout(() => {
              retryCount++;
              if (retryCount < retryLimit) {
                console.debug("Retrying GET /csrfProtection request...");
                ajaxRequest();
              } else {
                if (timeoutId) clearTimeout(timeoutId);
              }
            }, 5000);
          }
        );
      }

      ajaxRequest();
    };
  },
  // ok for now as these actions run client-side
  setUxUserData: function () {
    const cookies = new Cookies();
    return function (dispatch) {
      const userMembershipData = RTR.UX.getMemberships();
      const membershipState = userMembershipData?.membershipState;
      const userProfile = RTR.UX.getProfile();
      const { isUnlimitedLens, isRTRUpdateLens, isClassicLens } = RTR.UX;
      const userSelection = userSelections.getUserSelectionCookie();
      const authState = RTR.UX.auth_status;

      castleHelper.setDeviceUserJWT(userProfile);

      authLocalStorageClient.set("uih", RTR.UX.getUserIdHash());

      const isMasquerading = RTR.UX.isMasquerading();

      RawClient.setIsMasquerading(isMasquerading);

      const payload = {
        membershipState,
        newCustomerOfferText: getNewCustomerOfferText(),
        userData: {
          memberships: userMembershipData,
          userProfile,
          userProfileHasLoaded: true, // aids in the migration to SF.next
          userProfileError: null, // aids in the migration to SF.next
          isUnlimitedLens,
          isRTRUpdateLens,
          isClassicLens,
          isMasquerading,
          userSelection,
          authState,
          authDataHasLoaded: true,
        },
        // rtrSession aids in the migration to SF.next
        rtrSession: {
          rtrId: cookies.get("RTR_ID"),
          rtrSessionId: cookies.get("RTR_SESS"),
        },
      };
      dispatch(actions.writeUxUserData(payload));
      SplunkRumHelpers.identifyUserIdWithUserData(payload.userData);

      if (hasUserData(payload.userData)) {
        fireWithUserData(payload.userData, function () {
          // Classic Checkout will fetch address book and payment profiles on page load
          // Async fetch user address book, user payment profiles,
          // and membershipState if we know they're a RTRUPDATE/UNLIMITED member
          // Please do not remove the condition below for address and payment profiles
          // We need this condition for network capacity reasons.

          // Once we deprecate PRO then isMember and hasMembershipStateMember will mean the same thing.
          // We only need to load payment profiles and addresses for ACTIVE membership states.
          // Move away from the RTR.UX checks and instead use the payload membershipState above.
          // Look into Settings page for INACTIVE membership states and if we need to async fetch.
          if (hasMembershipStateMember(payload.userData)) {
            dispatch(loadPaymentProfiles());
          }

          if (isMember(payload.userData)) {
            dispatch(loadAddressBook());
          }

          // execute post-authentication client side actions, if any are stored on the device
          dispatch(executePostAuthenticationClientSideActions());
        });
      }
    };
  },

  // If there are query params in the url, honor those.
  // (And write them to localStorage in order to persist.)
  // For any of the CLASSIC_FILTERS *not* already in queryParams.filters,
  // see if we have values in localStorage. If so, add these to
  // storedFilters. Update redux state with filtersForSubmit,
  // which will include filters from both sources.
  initializeFilters: function (payload = {}) {
    return function (dispatch, getState) {
      const { isGrid, isPDP, queryParams } = payload;
      const queryParamFilters = queryParams?.filters ?? {};
      const defaultShippingZip = getDefaultShippingZip();
      const userProfileZip = getState()?.userData?.userProfile?.zipCode;
      const { predictedZip } = getState();
      const { userData } = getState();
      const gridView = getState()?.gridView ?? {};
      const gridType = getState()?.gridType;
      const isSaleablesGrid = gridType === gridTypes.saleables;
      const isAccessoryGrid = _.find(getState().hierarchy, hierarchy => hierarchy?.id === hierarchyIds.accessory);
      const potentialZipCodes = { defaultShippingZip, userProfileZip, predictedZip };

      const filtersForSubmit = filterHelpers.initializeFilters(
        queryParams,
        userData,
        potentialZipCodes,
        gridView,
        isAccessoryGrid,
        isSaleablesGrid,
        isPDP,
        isGrid
      );

      dispatch(actions.setPersistedFilters(filtersForSubmit));

      // We check if filtersForSubmit is equal to the queryParamFilters.
      // If they are different, we will fetch all 60 products using productsActions.submitFilters().
      // If they are the same, we only need to productsActions.fetchRemainingProducts().
      if (!_.isEqual(filtersForSubmit, queryParamFilters) && isGrid) {
        dispatch(productsActions.submitFilters());
      } else if (isGrid) {
        dispatch(productsActions.fetchRemainingProducts());
      }

      if (isGrid || isPDP) {
        const filtersForPixel = _.extend({}, filtersForSubmit);
        const objectType = isGrid ? "grid" : "node";

        // Analytics wants a more granular view of the current_view of pixels
        // DO NOT submit current_view to filters
        filtersForPixel["current_view"] = filterHelpers.pixelFilterParam(filtersForSubmit, userData);
        ActionLogger.logAction({
          object_type: objectType,
          action: "initial_filters",
          initialFilters: JSON.stringify(filtersForPixel),
          url: location.pathname + location.search,
        });
      }
    };
  },

  initializeCastleSDK: function (isMasquerading) {
    return async function (_dispatch, getState) {
      const castlePk = getState().publicEnv?.castlePk;

      try {
        if (!castlePk) throw new Error("Unable to initialize Castle SDK without a public key");
      } catch (e) {
        console.error(e);
        return;
      }

      if (isMasquerading) {
        console.debug("Skipping Castle SDK initialization while agents masquerade");
        return;
      }

      try {
        await loadCastleSDK(castlePk);
        console.debug("Loading Castle SDK complete");
      } catch (e) {
        console.error("Unable to initialize Castle SDK because of an unexpected error", e);
      }
    };
  },

  initTatari: function () {
    return function (_, getState) {
      const state = getState();
      const { userData } = state;

      fireWithUserData(userData, function () {
        const userId = getUserId(userData);

        const tatariEnabled = requestedFlagSelector(Flags.TATARI)(state);
        TatariHelper.setIsTatariEnabled(tatariEnabled);
        // Sync any Tatari logs currently in local storage.
        TatariHelper.syncInferTrackingQueue(userId);

        if (userId) {
          // If we know the userId, log it to backfill anonymous users.
          TatariHelper.identifyUser(userId);

          // If a user registers, we'll log it.
          if (getQueryParam("registration") === "new") {
            TatariHelper.trackRegistration(userId);
          }
        }
      });
    };
  },

  setPageTypeAndName(pageType, pageName) {
    return dispatch => {
      dispatch(actions.setPageType(pageType));
      dispatch(actions.setPageName(pageName));

      // https://bit.ly/3AEx49q
      // if (typeof document === "undefined") return;
      if (!document.body) return;

      let { className = "" } = document.body;

      className.replace(/(^| )[\w-]+-(page|domain)( |$)/gi, "");
      className += ` ${pageName || "unspecified"}-page ${pageType || "unspecified"}-domain`.toLowerCase();

      document.body.className = className;
    };
  },

  fireRubyNextTrackingEvents(env) {
    return (dispatch, getState) => {
      HeapHelpers.trackRubyAndNextPageHits(env, getState().initialUrl);
    };
  },
};

export default actions;

export const {
  fireRubyNextTrackingEvents,
  getCsrfToken,
  googleMapsLoaded,
  initializeFilters,
  initializeCastleSDK,
  initTatari,
  setFlags,
  setPageTypeAndName,
  setPersistedFilters,
  setUxUserData,
  updateUserData,
  writeBrowserData,
  writeUxUserData,
} = actions;
