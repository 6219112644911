import PropTypes from "prop-types";
// it can be unsafe to import from index.js (which is importing this file)
// #circularedependency
import { oneOfRequired } from "./one-of-required";
import { idPropType } from "./id-prop-type";

export const addressPropType = PropTypes.shape({
  id: idPropType,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
  city: PropTypes.string,
  zoneCode: PropTypes.string,
  postalCode: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
  default: PropTypes.bool,
});

export const showroomAddressPropType = PropTypes.shape({
  id: idPropType,
  company: PropTypes.string.isRequired,
  street1: PropTypes.string.isRequired,
  street2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zipcode: PropTypes.string.isRequired, // intentionally lowercase
  phone: PropTypes.string,
});

// this propType handles all combination of RTR Addresses.
// for example, we have three different names for zip: zipCode, postalCode, and
// zipcode. for state, there is state and zoneCode. there is name vs company
export const rtrAddressPropType = PropTypes.shape({
  name: PropTypes.string,
  company: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
  city: PropTypes.string,
  state: oneOfRequired("state", "zoneCode"),
  zoneCode: oneOfRequired("zoneCode", "state"),
  zipcode: oneOfRequired("zipcode", "zipCode", "postalCode"),
  zipCode: oneOfRequired("zipCode", "postalCode", "zipcode"),
  postalCode: oneOfRequired("postalCode", "zipcode", "zipCode"),
});
