// this is a grab bag of functionality that Storefront.Ruby used to do in
// react-redux-initializer.js

// on-initialization-actions.js
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch, useSelector, useStore } from "react-redux";
import { parse as qsParse } from "qs";
import { useRouter } from "next/router";
import {
  fireRubyNextTrackingEvents,
  getCsrfToken,
  googleMapsLoaded,
  initializeFilters,
  initTatari,
  setPageTypeAndName,
} from "actions/on-initialization-actions";
import { useUser, UserSuspense } from "./UserContext";
import { useFeatureFlags } from "./FeatureFlagsContext";
import HeapHelpers from "helpers/heap-helpers";
import { identifyUserIdWithUserData as identifySplunkUserId } from "helpers/splunk-rum-helpers";
import setupNavigation from "../../helpers/setup-navigation";
import analyticsClient from "../../analytics/analytics-client";
import { useCsrfProtection } from "../source/hoc/with-csrf-token";
import { initializeStoreForAjaxHelper } from "../../helpers/ajax-helpers";
import ActionLogger from "action-logger";
import { initLocationChangeEvent } from "../source/hoc/with-query-params";
import { refreshSegments } from "../../helpers/segment-refresh-helper";
import PhotoReviewsServiceClient from "clients/PhotoReviewsServiceClient.js";
import { DiscoSearchServiceClient } from "clients/DiscoSearchServiceClient.js";
import { executePostAuthenticationClientSideActions } from "actions/post-authentication-actions";

const miscDataSelector = ({ gridView, product, publicEnv }) => ({ gridView, product, publicEnv });

const propTypes = {
  pageName: PropTypes.string,
  pageType: PropTypes.string,
};

export default function AppInitialization({ pageType, pageName }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { gridView, product, publicEnv } = useSelector(miscDataSelector, shallowEqual);
  // This doesn't work - 'user' needs to be destructured from useUser first and then you can grab the id. However,
  // we need to investigate whether we even want this code anymore, and if we do it needs to go through some vetting
  // first.
  const featureFlags = useFeatureFlags() || [];
  const csrfProtection = useCsrfProtection();
  const user = useUser();

  useEffect(() => {
    if (!csrfProtection?.hasLoaded) dispatch(getCsrfToken());
  }, [csrfProtection?.hasLoaded]);

  // inspired by the _very_ old header.js
  const handleRouteChange = url => {
    analyticsClient.log({
      event: "page_load",
      object_type: "top_nav",
      context: url,
      referrer: ActionLogger.getReferrer(),
      uid: user?.user?.id,
    });
  };

  useEffect(() => {
    if (user?.hasLoaded) {
      handleRouteChange(window.location.href);
    }
  }, [user?.hasLoaded]);

  useEffect(() => {
    if (publicEnv?.gateWayURL) {
      // For client side rendering make sure add the gateWayURL as below
      PhotoReviewsServiceClient.setHost(publicEnv?.gateWayURL);
      DiscoSearchServiceClient.setHost(publicEnv?.gateWayURL);
    }
  }, [publicEnv?.gateWayURL]);

  useEffect(() => {
    // URL change handling
    initLocationChangeEvent();
  }, []);

  useEffect(() => {
    // gMapsLoaded callback
    // This callback is called when the Google Maps script loads. Until this point, we're not able to render
    // an autocomplete component. This function is called when that library is loaded and passes an event to
    // react which calls setState.
    window.gMapsLoaded = () => {
      dispatch(googleMapsLoaded());
    };
    // gMapsLoaded callback.

    if (featureFlags.tatari) {
      dispatch(initTatari());
    }
  }, [dispatch]);

  const queryString = router.asPath.includes("?") ? router.asPath.split("?")[1] : "";
  const queryObject = qsParse(queryString, { ignoreQueryPrefix: true });

  useEffect(() => {
    const existingQuery = {
      queryParams: queryObject,
      // TODO: replace the second API call with a call to the new API
      // temporarily disable as it causes the second fetch which crashes the page
      isGrid: false && typeof gridView === "object",
      isPDP: typeof product === "object",
    };

    dispatch(initializeFilters(existingQuery));
  });

  const store = useStore();

  useEffect(() => setupNavigation(store), [store]);
  useEffect(() => {
    initializeStoreForAjaxHelper(store);
  }, [store]);
  const handleUserData = user => {
    // `?? null` to prevent parameter defaults from kicking in
    const { id = null } = user || {};

    identifySplunkUserId(id);

    if (id) {
      refreshSegments();
      dispatch(executePostAuthenticationClientSideActions());
    }
  };

  useEffect(() => {
    dispatch(setPageTypeAndName(pageType, pageName));
  }, [pageType, pageName]);

  useEffect(() => {
    dispatch(fireRubyNextTrackingEvents("next"));
  }, []);

  useEffect(() => {
    HeapHelpers.trackCasAuthComplete();
  }, []);

  return <UserSuspense onInitialRender={handleUserData} />;
}
AppInitialization.propTypes = propTypes;
