import { isSSR } from "./client-server-helper";

const MIN_SCALE = 0.999;
const MAX_SCALE = 4;

/**
 * Function to asynchronously load hammerjs and pass the Hammer instance to the callback param for the component to
 * initiate the binding of touch gesture event listeners to DOM elements
 * @param callback
 * @returns {Promise<void>}
 */
export async function bindTouchGesturesWithAsyncHammer(callback) {
  // Hammer does some window object manipulation that throws errors in Next server-side rendering
  if (isSSR()) return;

  try {
    // Dynamically imported module (runtime)
    // Importing asynchronously allows us to chunk the asset for a feature that does not need to be immediately
    // available on page-load
    const { default: Hammer } = await import("hammerjs");

    if (typeof Hammer === "function") {
      callback(Hammer);
    } else {
      throw new Error("Unable to load Hammerjs");
    }
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
}

/**
 * Function to asynchronously load vendor/cloudzoom and pass the CloudZoom instance to the callback param for the
 * component to initiate the binding of touch gesture event listeners to DOM elements
 * @param callback
 * @returns {Promise<void>}
 */
export async function bindZoomWithAsyncCloudZoom(callback) {
  // Hammer does some window object manipulation that throws errors in Next server-side rendering
  if (isSSR()) return;

  try {
    // Dynamically imported module (runtime)
    // Importing asynchronously allows us to chunk the asset for a feature that does not need to be immediately
    // available on page-load
    // This will exist in bundles served by Ruby platform via application-pdp.js
    if (!window.CloudZoom) {
      // Dynamically imported module (runtime)
      await import("vendor/cloudzoom");
    }

    // @see {https://www.starplugins.com/cloudzoom/api}
    if (typeof window.CloudZoom === "function") {
      callback(window.CloudZoom);
    } else {
      throw new Error("Unable to load cloudzoom");
    }
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
}

export function setupPinchPanZoom(hammerWrappedElement, el, component) {
  // pinch is disabled by default in hammer
  // because it blocks scrolling on the element.
  // let's enable it so we can pinch zoom!
  hammerWrappedElement.get("pinch").set({
    enable: true,
  });

  let posX = 0;
  let posY = 0;
  let scale = 1;
  let lastScale = 1;
  let lastPosX = 0;
  let lastPosY = 0;
  let maxPosX = 0;
  let maxPosY = 0;
  let transform = "";

  hammerWrappedElement.on("pan pinch panend pinchend", function (ev) {
    // A swipe between product images triggers both a swipe event and a pan event
    // We don't want to handle the pan event if the user was swiping, so we'll return here
    if (component?.state?.isSwiping === true) {
      if (ev.type === "panend") {
        component.setState({ isSwiping: false });
      }
      return;
    }

    // handling pan events
    if (scale !== 1) {
      // while panning, the new position of the element should be the
      // old position plus the delta from the touch event
      // (i.e. how far the user panned)
      posX = lastPosX + ev.deltaX;
      posY = lastPosY + ev.deltaY;

      // Don't let the pan move the element outside the viewport
      maxPosX = Math.ceil(((scale - 1) * el.clientWidth) / 2);
      maxPosY = Math.ceil(((scale - 1) * el.clientHeight) / 2);
      if (posX > maxPosX) {
        posX = maxPosX;
      }
      if (posX < -maxPosX) {
        posX = -maxPosX;
      }
      if (posY > maxPosY) {
        posY = maxPosY;
      }
      if (posY < -maxPosY) {
        posY = -maxPosY;
      }
    }

    if (ev.type === "panend") {
      lastPosX = posX < maxPosX ? posX : maxPosX;
      lastPosY = posY < maxPosY ? posY : maxPosY;
    }

    // handling pinch events
    if (ev.type === "pinch") {
      // Scale the element's size by the amount that the user pinched
      // but don't scale it more than the max scaleable amount
      scale = Math.max(MIN_SCALE, Math.min(lastScale * ev.scale, MAX_SCALE));
    }

    if (ev.type === "pinchend") {
      lastScale = scale;
    }

    // now that we've calculated the scale and the new position,
    // let's transform the element!
    if (scale !== 1) {
      transform = "translate3d(" + posX + "px," + posY + "px, 0) " + "scale3d(" + scale + ", " + scale + ", 1)";
    }
    if (transform) {
      el.style.webkitTransform = transform;
    }
  });
}

export function resetPinchPanZoom(el) {
  // get rid of all the transformations on the element
  el.style.webkitTransform = "";
}
