// found using the following regex in Storefront *.rb files:
// (self\.|@)page_type =
export const pageTypes = {
  ABOUT_US: "ABOUT_US",
  ACCOUNT: "ACCOUNT",
  BLOG: "BLOG",
  BOOKING: "booking",
  CHECKOUT: "CHECKOUT",
  COMPLETE: "COMPLETE",
  DESIGNERS: "DESIGNERS",
  FEATURES: "FEATURES",
  FIT_PROFILE: "FIT_PROFILE",
  GRID: "GRID",
  HELP: "HELP",
  HOME: "HOME",
  INDIVIDUAL_MOMENT: "IndividualMoment",
  JOIN_WAITLIST: "JOIN_WAITLIST",
  MARKETING_REDIRECT: "MARKETING-REDIRECT",
  MOBILE_ORDER: "mobileOrder",
  NEW_CHECKOUT: "NEW_CHECKOUT",
  ORDER: "order",
  PAID_LANDING: "PAID_LANDING",
  PAID_UNLIMITED_LP: "PAID-UNLIMITED-LP",
  PDP: "PDP",
  PLANS: "PLANS",
  PRE_CHECKOUT: "precheckout",
  PRIVACY: "PRIVACY",
  PRIVACY_METRICS: "PRIVACY-METRICS",
  REFERRAL: "REFERRAL",
  REFERRAL_PAGE: "referral_page",
  REPLACEMENT: "REPLACEMENT",
  SELF: "SELF",
  SHORTLIST: "SHORTLIST",
  SHOWROOM: "SHOWROOM",
  STORE: "STORE",
  SUBSCRIPTION_RETURNS: "SUBSCRIPTION_RETURNS",
  TERMS_OF_SERVICE: "TERMS-OF-SERVICE",
  USER: "USER",
  WRITE_REVIEW: "WRITE_REVIEW",
  USER_SPECIFIC_REVIEWS: "USER_SPECIFIC_REVIEWS",
  USER_SELF_REVIEWS: "USER_SELF_REVIEWS",
};

export const pageNames = {
  [pageTypes.HELP]: {
    CONTACT_US: "CONTACT_US",
    FAQ: "FAQ",
  },
};
