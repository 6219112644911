// loadUserProductRatings
// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.

import React, { createContext, useContext, useDebugValue, useEffect, useCallback, useState } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { childrenPropType } from "../propTypes";
import { getDisplayName } from "../source/hoc/utils";
import { loadUserProductRatings } from "actions/product-ratings-actions";
import { loadShortlists } from "actions/shortlists-actions";
import { useUser } from "./UserContext";

const UserProductRatingsContext = createContext({ userProductRatings: {}, hasLoaded: false });
const stateKey = "favorites";

// plucks userProductRatings from the Redux Context
function userProductRatingsSelector(state) {
  const { [stateKey]: userProductRatings } = state;

  return { userProductRatings };
}

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useUserProductRatings() {
  return useContext(UserProductRatingsContext);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

export function withUserProductRatings() {
  return WrappedComponent => {
    const C = connect(userProductRatingsSelector)(WrappedComponent);

    C.displayName = `withUserProductRatings(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

/**
 * @param {object} props
 * @param {function} props.children a ***function*** that takes userProfile as a
 * parameter. used to render your experimental content
 * @param {function} props.onInitialRender a function called only once, when
 * data fetching is complete
 * @param {function} props.fallback a function which returns the content you
 * wish to display while fetching
 * @description Wrap your experimental content in this component to render once
 * user has been loaded. `children` should be a function
 */
export function UserProductRatingsSuspense({ fallback, onInitialRender, children: fnRenderChildren }) {
  let content;
  const { userProductRatings, hasLoaded, error } = useUserProductRatings();

  // call the onInitialRender method only once, when hasLoaded is true
  // useCallback to guard against a case where hasLoaded toggles more than once
  const handleInitialRender = useCallback(() => hasLoaded && onInitialRender?.(userProductRatings), [hasLoaded]);

  // call the memoized function each time hasLoaded changes (useCallback ensures it
  // is only called once per hasLoaded value)
  useEffect(() => {
    handleInitialRender();
  }, [hasLoaded]);

  if (hasLoaded) {
    // happy path
    content = fnRenderChildren?.(userProductRatings, error);
  } else if (fallback) {
    // fallback
    content = fallback();
  }

  return content ?? null;
}

export function UserProductRatingsProvider({ children }) {
  // This doesn't work - 'user' needs to be destructured from useUser first and then you can grab the id. However,
  // we need to investigate whether we even want this code anymore, and if we do it needs to go through some vetting
  // first. I believe this code is somewhat leftover from when the PDP was the initial target for rolling out Next.
  const { id: userId } = useUser();
  // Seems appropriate to keep this info in the Redux Store alongside
  // userProductRatings, but the Redux action doesn't support that (yet?)
  const [error, setError] = useState();
  const [hasLoaded, setHasLoaded] = useState(false);
  const dispatch = useDispatch();
  const { userProductRatings = {} } = useSelector(userProductRatingsSelector, shallowEqual);

  useEffect(() => {
    if (!userId) {
      return;
    }
    const action = loadUserProductRatings(true, {
      onSuccess(userProfileRatings) {
        setHasLoaded(true);

        const heartedProductIds = userProfileRatings
          .filter(r => r.action === "HEARTED" && r.product)
          .map(r => r.product.id);

        dispatch(loadShortlists(heartedProductIds));
      },
      onFailure(e) {
        setError(e);
        setHasLoaded(true);
      },
    });

    dispatch(action);
  }, [userId]);

  useDebugValue(hasLoaded ? "Loaded" : "Pending");

  return (
    <UserProductRatingsContext.Provider value={{ userProductRatings, error, hasLoaded }}>
      {children}
    </UserProductRatingsContext.Provider>
  );
}

UserProductRatingsProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...UserProductRatingsContext,
  Provider: UserProductRatingsProvider,
};
