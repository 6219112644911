import HistoryState from "../history-state";
import productsActions from "../actions/products-actions";

export default function setupNavigation(store) {
  const state = store.getState();

  window.HISTORY_STATE = new HistoryState(historyState => {
    store.dispatch(productsActions.receiveProps(historyState));
    store.dispatch(productsActions.setLoading(false));
  });

  HISTORY_STATE.replaceState(state, "", window.location.pathname + window.location.search + window.location.hash);
}
