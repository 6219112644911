import PropTypes from "prop-types";
import { idPropType } from "./id-prop-type";

export const membershipStatePropType = PropTypes.shape({
  id: idPropType,
  membershipType: PropTypes.string,
  nextBillingDate: PropTypes.string, // date?
  membershipEndedOn: PropTypes.string, // date?
  membershipPausedOn: PropTypes.string, // date?
  membershipTermNumber: PropTypes.number,
  products: PropTypes.array,
  tierBaseSlotCount: PropTypes.number,
  tierChangeStartedOn: PropTypes.string, // date
  previousTierResumedOn: PropTypes.string, // date
});
