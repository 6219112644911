import {
  featureFlags,
  SAILTHRU_EVENTS,
  analytics,
  membershipStyles,
  emailCaptureModalCMSContent,
  membershipLens,
} from "rtr-constants";
import { doNotTrackSailthruIfUserOptsOut } from "../cookie-consent-helper";
import { getLocalParam } from "../../components/source/navigation/url-param-reader";
import { format, addDays } from "date-fns";
import Cookies from "universal-cookie";
import { isProduction } from "./../../helpers/environment-helpers";
import Routes from "routes";

export const ONETRUST_LOAD_EVENT = "oneTrustLoadEvent";
export const SAILTHRU_LOAD_EVENT = "sailthruLoadEvent";
export const SAILTHRU_SCRIPT_ID = "sailthru-spm";
export const SAILTHRU_IDENTIFIER = "sailthru_hid";
const SAILTHRU_SCRIPT_URL = "https://ak.sail-horizon.com/spm/spm.v1.min.js";

const cookies = new Cookies();

const getAttentiveEmail = () => cookies.get("attntv_mstore_email");

const initSailthruTrackingCallback = () => {
  doNotTrackSailthruIfUserOptsOut();
  window?.OneTrust?.OnConsentChanged(() => {
    doNotTrackSailthruIfUserOptsOut();
  });
};

const sailThruCallback = () => {
  initSailthruTrackingCallback();

  window.removeEventListener(ONETRUST_LOAD_EVENT, sailThruCallback);
};

function buildEventContext(product, email, lens, itemType) {
  const anonUserEmail = getLocalParam(
    emailCaptureModalCMSContent.SESSION_STORAGE_KEY,
    emailCaptureModalCMSContent.SESSION_STORAGE_VALUE
  );

  const attentiveEmail = getAttentiveEmail();
  const userEmail = email ? email : attentiveEmail;

  const newEmail = userEmail ? userEmail.replace(/:0$/, "") : null;

  let styleName = null;
  let isSubscriptionItem = false;
  if (lens === "classic" || itemType !== analytics.SUBSCRIPTION) {
    if (product?.skus) {
      const skuWithStyle = product.skus?.find(sku => sku.styleName);
      if (skuWithStyle) {
        styleName = skuWithStyle.styleName;
      }
    }
  } else if (lens === "unlimited" || lens === "rtrupdate" || itemType === analytics.SUBSCRIPTION) {
    styleName = membershipStyles.UPDATE;
    isSubscriptionItem = true;
  }

  const url = styleName ? buildPdpCanonicalUrl(window.location.origin, styleName) : null;

  const viewPdpEvent = () => {
    const baseEvent = {
      name: lens === "classic" ? SAILTHRU_EVENTS.SAILTHRU_RESERVE_PDP : SAILTHRU_EVENTS.SAILTHRU_MEMBERSHIP_PDP,
      email: newEmail,
      vars: {
        url: url,
        image_url: "https:" + process.env.NEXT_PUBLIC_CDN_IMAGE_HOST + "/" + product?.images?.front.original,
        style_name: product?.displayName,
        designer_name: product?.designer?.displayName,
      },
    };

    baseEvent.id = newEmail ?? anonUserEmail;

    // If we have no record of their email, check one last time for Sailthru cookie
    const sailthruHid = cookies.get(SAILTHRU_IDENTIFIER);
    if (!baseEvent.id && sailthruHid) {
      baseEvent.id = sailthruHid;
      baseEvent.key = "cookie";
    }

    return baseEvent;
  };

  return {
    anonUserEmail,
    newEmail,
    url,
    isSubscriptionItem,
    viewPdpEvent,
  };
}

export const initSailthru = flags => {
  if (flags.includes(featureFlags.ONETRUST_CONSENT_MANAGEMENT)) {
    if (window.Sailthru) {
      sailThruCallback();
    } else {
      window.addEventListener(ONETRUST_LOAD_EVENT, sailThruCallback);
    }
  }
};

export function ensureSailthruIsPresent(isNext) {
  return new Promise((resolve, reject) => {
    // added by Ruby on pages that require sailthru
    if (!window.sailthruConfig) {
      resolve(false);
      return;
    }
    // Adding check for next as we need to re aply the Sailthru config
    if (window.sailthruReady && !isNext) {
      // already present
      resolve(true);
      return;
    }
    const script = document.createElement("script");
    script.id = SAILTHRU_SCRIPT_ID;
    script.src = SAILTHRU_SCRIPT_URL;
    script.onload = () => {
      if (typeof window?.Sailthru?.track === "function") {
        window.Sailthru.init({
          customerId: window.sailthruConfig.customerId,
          isCustom: true,
          autoTrackPageview: false,
        });
        window.Sailthru.track("pageview", {
          url: window.sailthruConfig.url,
        });

        window.sailthruReady = true;
        window.dispatchEvent(new Event(SAILTHRU_LOAD_EVENT));
        // resolve the promise now that sailthru has been initialised
        resolve(true);
      }
      reject("Sailthru not available on the window after loading the SPM script");
    };

    document.body.appendChild(script);
  });
}

export function sendSailthruAddToCartEvent(product, email, reservation, itemType) {
  const { anonUserEmail, newEmail, url, isSubscriptionItem } = buildEventContext(product, email, null, itemType);
  const vars = {};

  if (itemType === analytics.SUBSCRIPTION) {
    vars.subscriptionType = product?.name;
  }

  // Calculate new date by adding duration to reservation start date
  const startDate = reservation?.date ? new Date(reservation.date) : null;
  let endDate = null;
  if (startDate && !isNaN(reservation?.duration)) {
    endDate = new Date(startDate);
    endDate = addDays(startDate, reservation.duration);
  }

  const formattedStartDate = startDate ? format(startDate, "yyyy/MM/dd") : null;
  const formattedEndDate = endDate ? format(endDate, "yyyy/MM/dd") : null;

  // Call ensureSailthruIsPresent before integrating custom event
  return ensureSailthruIsPresent(isSubscriptionItem)
    .then(isPresent => {
      if (isPresent && window?.Sailthru && typeof window?.Sailthru.track === "function") {
        vars.url = url;
        vars.type = itemType;

        if (formattedStartDate) {
          vars.start_date = formattedStartDate;
        }
        if (formattedEndDate) {
          vars.end_date = formattedEndDate;
        }

        const baseEvent = {
          name: SAILTHRU_EVENTS.SAILTHRU_ADD_TO_CART,
          vars: vars,
        };

        baseEvent.id = newEmail ?? anonUserEmail;

        // If we have no record of their email, check one last time for Sailthru cookie
        const sailthruHid = cookies.get(SAILTHRU_IDENTIFIER);
        if (!baseEvent.id && sailthruHid) {
          baseEvent.id = sailthruHid;
          baseEvent.key = "cookie";
        }

        window.Sailthru?.integration("customEvent", baseEvent);
      }
    })
    .catch(reportError);
}

/**
 * Note: when executed from a PDP or Next drawers, Sailthru has already been added to the page
 * so we don't get `track("pageview"`) in ensureSailthruIsPresent, however we'll still get the customEvent fired below.
 */
export async function sendSailthruPdpEvent(product, email, lens = membershipLens.classic) {
  return ensureSailthruIsPresent()
    .then(isPresent => {
      if (isPresent) {
        window.Sailthru?.integration("customEvent", buildEventContext(product, email, lens).viewPdpEvent());
      }
    })
    .catch(reportError);
}

/**
 * When we open the drawer from Next grids, we want to fire the pageview
 * event. A pageview event has already fired from the grid (so long as sailthru has loaded!)
 * so `ensureSailthruIsPresent` will simply return early. We then follow up with an explicit
 * pageview event for the product drawer.
 * @param {String} productId
 */
export async function sendSailthruPdpPageView(productId) {
  return ensureSailthruIsPresent()
    .then(isPresent => {
      if (isPresent) {
        window.Sailthru.track("pageview", {
          url: buildPdpCanonicalUrl(window.location.origin, productId),
        });
      }
    })
    .catch(reportError);
}

const reportError = error => {
  if (window?.Sentry && window?.Sentry.captureException) {
    window.Sentry.captureException(error, {
      tags: {
        thirdparty: true,
        thirdparty_name: "sailthru",
      },
    });
  }
};

/**
 * We use the "canonical URL" with sailthru as this gives us consistency with the app. i.e. Storefront uses vanity URLs
 * like `https://www.renttherunway.com/shop/designers..., however these are unique to storefront.
 * The canonical Url is unique key that both Storefront and the App can reference when emitting events.
 * See: https://renttherunway.jira.com/wiki/spaces/MEM/pages/3639279617/Sailthru+Content+Management
 *
 * @param {string} baseUrl
 * @param {string} styleName
 */
export const buildPdpCanonicalUrl = (baseUrl, styleName) => {
  const url = new URL(baseUrl);
  return `${url.origin}${Routes.PDP.RouteById(styleName)}`;
};

export function configureSailthru(sailthruConfig) {
  // Martech-293: Only sending production events as test users are been sent otherwise to production Sailthru
  if (isProduction() && sailthruConfig !== void 0) {
    // This config is picked up once the `oneTrustLoadEvent` event fires
    window.sailthruConfig = sailthruConfig;
  }
}
