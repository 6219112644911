import { connect, shallowEqual, useSelector } from "react-redux";
import Cookies from "universal-cookie";

import { AUTH_STATES, ACCOUNT_TYPES, COOKIES } from "rtr-constants";
import { getDisplayName } from "./utils";
import { isSSR } from "../../../helpers/client-server-helper";

// to help with your component authoring
export { userDataPropType } from "components/propTypes";

//////////////////// IMPORTANT INFORMATION ////////////////////
/**
 * This HOC has traditionally relied on an object passed from the Ruby layer
 * which looks like this:
 * userData: {
 *    userProfile : {
 *      // id, email, etc...
 *    }
 * }
 * However the userData object passed in from Next is the User object from GM
 * This has had the side effect of analytics not being able to find the user data
 * as it was expecting the userProfile nested within.
 * Further investigation is required into what data is potentially missing from User on Next
 * e.g. isMasquerading
 */

export function isMasquerading(userData) {
  return !!userData?.isMasquerading;
}

export function getAccountType(userData) {
  // the "accountType" property isn't backward compatible with existing users
  // so we can default to FULL as long as they have a userProfile
  if (!hasUserData(userData)) return null;
  return userData?.userProfile?.accountType || ACCOUNT_TYPES.FULL;
}

export function getFirstName(userData) {
  return userData?.userProfile?.firstName || userData?.firstName;
}

export function getLastName(userData) {
  return userData?.userProfile?.lastName || userData?.lastName;
}

export function getFullName(userData) {
  return [getFirstName(userData), getLastName(userData)].join(" ").trim();
}

export function getUserEmail(userData) {
  return userData?.userProfile?.email || userData?.email;
}

export function getUserCellPhoneNumber(userData) {
  return userData?.userProfile?.cellPhoneNumber;
}

export function getUserId(userData) {
  return userData?.userProfile?.id || userData?.id;
}

export function hasProfile(userData) {
  return Boolean(userData?.userProfile);
}

// check for a user id for Next user
export function hasUserData(userData) {
  if (!userData) return false;

  return (userData.userProfileHasLoaded && !!userData.userProfile) || userData?.id;
}

export function isAdmin(userData) {
  return userData?.userProfile?.isAdmin || userData?.isAdmin;
}

export function isProMember(userData) {
  return userData?.memberships?.isProMember;
}

export function isAnonymous(userData) {
  const { userProfile, authState } = userData || {};

  return !userProfile || authState === AUTH_STATES.ANONYMOUS;
}

export function isCustomer(userData) {
  return userData?.userProfile?.isCustomer;
}

export function isLimitedAccount(userData) {
  return userData?.userProfile?.accountType === ACCOUNT_TYPES.LIMITED;
}

export function isIdentified(userData) {
  const { authState } = userData || {};

  return [AUTH_STATES.IDENTIFIED, AUTH_STATES.AUTHORIZED].includes(authState);
}

export function isAuthorized(userData) {
  const cookies = new Cookies();
  const isCasV1Enabled = cookies.get(COOKIES.CAS_V1_ENABLED) === "true";

  if (isCasV1Enabled) return isIdentified(userData);

  const { authState } = userData || {};

  return authState === AUTH_STATES.AUTHORIZED;
}

// helpers previously housed in RTR.UX as extensions on the RTR.UX.memberships object
export function proMembershipStatus(userData) {
  return userData?.memberships?.pro;
}

export function proPaymentFailed(userData) {
  const status = proMembershipStatus(userData);
  return status && ["DELINQUENT", "PAYMENT_FAILED"].includes(status.billingStatus);
}

const userDataInitializedEvent = "userDataReady";

/**
 * Fire a callback when user data is available.
 * @param userData The userData object needed for determining if user data is available. If not, wait for the userDataReady event. Typically sourced from useUserData().
 * @param callback The callback to invoke when user data is available (will be invoked immediately if it's already available)
 */
export function fireWithUserData(userData, callback) {
  if (isSSR()) {
    return;
  }
  if (hasUserData(userData)) {
    callback();
  } else {
    document.addEventListener(userDataInitializedEvent, callback);
  }
}

/**
 * The corresponding unbind to bindFireWithUserData. Call this to clean up your code that waits for userData.
 * @param callback The callback you want to remove from the listener queue.
 */
export function unbindFireWithUserData(callback) {
  if (isSSR()) {
    return;
  }
  document.removeEventListener(userDataInitializedEvent, callback);
}

/** this is functionally the same as fireWithUserData, the only difference being
 * that it does not create a new anonymous function, rather is invokes the function
 * passed to as a callback. This is important because we need to clean up and detach listeners
 * in our React components.
 *
 * @param userData The userData object needed for determining if user data is available. If not, wait for the userDataReady event. Typically sourced from useUserData().
 * @param callback The callback to invoke when user data is available (will be invoked immediately if it's already available)
 */
export function bindFireWithUserData(userData, callback) {
  if (isSSR()) {
    return;
  }
  if (hasUserData(userData)) {
    callback();
  } else {
    document.addEventListener(userDataInitializedEvent, callback);
  }
}

export function fireUserEvents() {
  if (isSSR()) {
    return;
  }
  document.dispatchEvent(new Event(userDataInitializedEvent));
}

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

// plucks userData from the Redux Context
export const userDataSelector = ({ userData }) => userData || {};

export function useUserData(defaultValue) {
  return useSelector(userDataSelector, shallowEqual) || defaultValue;
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

const mapStateToProps = ({ userData }) => ({ userData });

export function withUserData(WrappedComponent) {
  const C = connect(mapStateToProps)(WrappedComponent);

  C.displayName = `withUserData(${getDisplayName(WrappedComponent)})`;

  return C;
}
