// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.

import React, { createContext, useContext } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import CmsServiceClient from "clients/CmsServiceClient";
import { childrenPropType } from "../propTypes";
import { getDisplayName } from "../source/hoc/utils";

const AcquisitionMessagingContext = createContext(null);
const stateKey = "acquisitionMessaging";
const cmsLocation = "storefront-json";

// plucks acquisitionMessaging from the Redux Context
function acquisitionMessagingSelector(state) {
  const { [stateKey]: cmsContent, [`${stateKey}HasLoaded`]: hasLoaded, [`${stateKey}Error`]: error } = state;

  return { cmsContent, error, hasLoaded };
}

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useAcquisitionMessaging() {
  return useContext(AcquisitionMessagingContext);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

const mapStateToProps = ({ [stateKey]: cmsContent } = {}) => ({ [stateKey]: cmsContent });

export function withAcquisitionMessaging() {
  return WrappedComponent => {
    const C = connect(mapStateToProps)(WrappedComponent);

    C.displayName = `withAcquisitionMessaging(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

// this is not called automatically by Next, you must call it explicitly,
// probably in _app.js
async function getInitialReduxState() {
  return CmsServiceClient.getInstance()
    .getContent("/acquisition_messaging", cmsLocation)
    .then(cmsContent => ({
      [stateKey]: cmsContent,
    }));
}

// Allows setting explicit context values
export const BaseProvider = AcquisitionMessagingContext.Provider;

export function AcquisitionMessagingProvider({ children }) {
  const cms = useSelector(acquisitionMessagingSelector, shallowEqual);

  // consider providing a client-side refresh if the request fails?

  return <AcquisitionMessagingContext.Provider value={cms}>{children}</AcquisitionMessagingContext.Provider>;
}

AcquisitionMessagingProvider.getInitialReduxState = getInitialReduxState;

AcquisitionMessagingProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...AcquisitionMessagingContext,
  Provider: AcquisitionMessagingProvider,
};
