import _ from "underscore";
import { constants } from "actions/exchange-actions";
import { modalTypes } from "helpers/swaps-helper";

function modifyExchange(state, action) {
  let nextState = state ? _.assign({}, state) : {};
  switch (action.type) {
    case constants.FETCH_SELF_SERVICE_EXCHANGES_SUCCESS:
    case constants.FETCH_SELF_SERVICE_EXCHANGES_FAILURE:
      nextState = _.assign(nextState, action.payload);
      break;
    case constants.FETCH_EXCHANGE_AVAILABILITY_BEGIN:
      nextState.exchangeAvailabilityLoading = true;
      break;
    case constants.FETCH_EXCHANGE_AVAILABILITY_SUCCESS: {
      const exchangeProductsWithAvailability = action.payload.products;
      nextState.exchanges.forEach(function (exchange) {
        exchange.products = exchange.products.map(function (exchangeProduct) {
          const matchingProduct = _.find(
            exchangeProductsWithAvailability,
            product => product.id === exchangeProduct.id
          );
          if (matchingProduct) {
            return matchingProduct;
          } else {
            return exchangeProduct;
          }
        });
      });

      nextState.exchangeAvailabilityLoading = false;
      break;
    }
    case constants.FETCH_EXCHANGE_AVAILABILITY_FAILURE:
      nextState.exchangeAvailabilityLoading = false;
      break;
    case constants.EXCHANGE_CREATE_PAYMENT_PROFILE_BEGIN:
      nextState.creatingPaymentProfile = true;
      break;
    case constants.EXCHANGE_UPDATE_SELECTED_PAYMENT_PROFILE:
    case constants.EXCHANGE_CREATE_PAYMENT_PROFILE_SUCCESS:
      nextState.selectedProfileId = action.payload;
      nextState.creatingPaymentProfile = false;
      nextState.failedToCreatePaymentProfile = false;
      break;
    case constants.EXCHANGE_CREATE_PAYMENT_PROFILE_FAILURE:
      nextState.creatingPaymentProfile = false;
      nextState.failedToCreatePaymentProfile = true;
      break;
    case constants.EXCHANGE_UPDATE_BAG_PRICE:
      // Only update bag price if not null
      if (action.payload) {
        nextState.bagPrice = action.payload;
      }
      break;
    case constants.CONFIRM_SSE_BEGIN:
      nextState.exchanging = true;
      break;
    case constants.CONFIRM_SSE_FAILURE:
      nextState.exchanging = false;
      break;
    case constants.EXCHANGE_UPDATE_ADDRESS:
      nextState.addressId = action.payload;
      break;
    case constants.EXCHANGE_SAME_DAY_SHIPPING_SUCCESS:
      nextState.isSameDayDeliveryEligible = action.payload.sameDayDeliveryEligible;
      nextState.newRentBegin = action.payload.newRentBegin;
      nextState.newBagCutoff = action.payload.newBagCutoff;
  }
  return nextState;
}

const fn = (state, action) => {
  const userProblems = _.assign({}, state.userProblems);
  if (action.type === constants.EXCHANGE_TOGGLE_SUCCESS_MODAL) {
    userProblems.modal = action.payload ? modalTypes.EXCHANGE_SUCCESS : false;
  }
  return _.extend({}, state, {
    userProblems: userProblems,
    selfServiceExchange: modifyExchange(state.selfServiceExchange, action),
  });
};

const getTypes = () => (constants ? Object.keys(constants) : []);

export default {
  types: getTypes(),
  fn,
};
