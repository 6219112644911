import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";
import ActionUtils from "actions/action-utils";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";

const actions = {
  applePaySessionSuccess: createAction(ActionTypes.APPLE_PAY_SESSION_SUCCESS),
  applePaySessionFailure: createAction(ActionTypes.APPLE_PAY_SESSION_FAILURE),
  applePaySessionLoading: createAction(ActionTypes.APPLE_PAY_SESSION_LOADING),

  requestApplePaySession: (data, useNextRoute = false) => {
    return dispatch => {
      return createAjaxAuthRetry(
        {
          url: useNextRoute ? "/api/checkout/applepay" : "/order/applepay/session/create",
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        },
        ActionUtils.refreshPage
      )
        .then(res => {
          dispatch(actions.applePaySessionSuccess(res.data));
          /* Session needs to be returned from Promise on order to complete merchant validation. */
          return res.data;
        })
        .catch(e => {
          dispatch(
            actions.applePaySessionFailure({
              error: `ApplePay session creation failure: ${e?.responseJSON || e?.responseText}`,
            })
          );
        });
    };
  },
};

export default actions;

export const { requestApplePaySession } = actions;
