import React from "react";
import { getUnixTime } from "date-fns";
import ActionLogger from "action-logger";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RatingEducationTooltipsActions from "actions/rating-education-tooltips-actions";
import { analytics, TOOLTIP } from "rtr-constants";
import { RatingEducationTooltip } from "./rating-education-tooltip";
import { refPropType } from "../../propTypes";
import RtrImage from "../shared/rtr-image";

export class HeartEducationTooltip extends React.Component {
  static propTypes = {
    dismissTooltip: PropTypes.func.isRequired,
    isDismissed: PropTypes.bool,
    isMobileViewport: PropTypes.bool,
    isTriggered: PropTypes.bool,
    location: PropTypes.oneOf([
      analytics.ACTION_LOCATIONS.CAROUSEL,
      analytics.ACTION_LOCATIONS.DRAWER,
      analytics.ACTION_LOCATIONS.GRID,
      analytics.ACTION_LOCATIONS.PDP,
    ]),
    membershipId: PropTypes.number,
    productId: PropTypes.string,
    target: refPropType,
  };

  logTooltipView = () => {
    const { location, membershipId, productId } = this.props;

    const logOptions = {
      action: analytics.HEARTS_ACTIONS.VIEW_HEART_TOOLTIP,
      location: location,
      membership_id: membershipId,
      object_type: analytics.OBJECT_TYPE.HEART_REC,
      style: productId,
      timestamp: getUnixTime(new Date()),
    };
    ActionLogger.logAction(logOptions);
  };

  render() {
    const placement =
      this.props.isMobileViewport || this.props.location === analytics.ACTION_LOCATIONS.CAROUSEL
        ? TOOLTIP.PLACEMENTS.BOTTOM
        : TOOLTIP.PLACEMENTS.LEFT;

    return (
      <RatingEducationTooltip
        placement={placement}
        logTooltipView={this.logTooltipView}
        dismissTooltip={this.props.dismissTooltip}
        target={this.props.target}
        isTriggered={this.props.isTriggered}
        isDismissed={this.props.isDismissed}>
        <div className="rating-education-tooltip">
          <div className="rating-education-tooltip__icon">
            <RtrImage
              alt="an animated heart shape"
              src="https://cdn.rtrcdn.com/sites/default/files/imgs/HEART_V2_Cropped.gif"
            />
          </div>
          <div className="rating-education-tooltip__body">
            <h6>Hearted!</h6>
            <p className="proxima-small-copy">Your recommendations just got a little more personalized.</p>
          </div>
        </div>
      </RatingEducationTooltip>
    );
  }
}

const mapStateToProps = state => {
  return {
    isDismissed: state?.heartEducationTooltipDismissed || false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dismissTooltip: () => {
      dispatch(RatingEducationTooltipsActions.setIsHeartEducationTooltipDismissed());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeartEducationTooltip);
