import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Product } from "@rtr/godmother";

const initialReservationState = {
  reservation: null,
};

const initialAvailabilityState = {
  availability: null,
};

const initialBonusStyleUpsellModalState = {
  showBonusStyleUpsellModal: null,
};

// These are set by ruby currently for PDP/PDP drawers during the dynamic data hydration
// Creating this slice so we can access them JS side
const reservationSlice = createSlice({
  name: "reservation",
  initialState: initialReservationState,
  reducers: {
    setReservation: (state, action) => {
      state.reservation = action.payload;
    },
  },
});

const reservationAvailabilitySlice = createSlice({
  name: "availability",
  initialState: initialAvailabilityState,
  reducers: {
    setReservationAvailability: (state, action) => {
      state.availability = action.payload;
    },
  },
});

const bonusStyleUpsellModalSlice = createSlice({
  name: "bonusStyleUpsellModal",
  initialState: initialBonusStyleUpsellModalState,
  reducers: {
    setShowBonusStyleUpsellModal: (state, action) => {
      state.showBonusStyleUpsellModal = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUpsellModalCuration.fulfilled, (state, action) => {
      state.reserveUpsellModalCuration = action.payload;
    });
  },
});

export const { setReservation } = reservationSlice.actions;
export const { setReservationAvailability } = reservationAvailabilitySlice.actions;
export const { setShowBonusStyleUpsellModal } = bonusStyleUpsellModalSlice.actions;

export const fetchUpsellModalCuration = createAsyncThunk(
  `fetchUpsellModalCuration`,
  async (curationId, { getState }) => {
    const state = getState();

    return Product.list({
      filter: {
        "curationId": curationId,
        "skus.rentalAvailabilities.beginDate": state.workingFilters?.date,
        "skus.rentalAvailabilities.duration": state.workingFilters?.duration,
        "skus.rentalAvailabilities.zipCode": state.workingFilters?.zipCode,
        "skus.rentalAvailabilities.minAvailability": 1,
      },
      fields: {
        product: "displayName,canonicalSizes,images,designer.displayName,urlHistory",
      },
      sort: "explicit",
      include: "skus,skus.rentalAvailabilities,price",
      page: {
        limit: 8,
      },
    });
  }
);

export const clearReservation = function () {
  return function (dispatch) {
    dispatch(setReservation(null));
    dispatch(setReservationAvailability(void 0));
  };
};

export const showBonusStyleUpsellModal = function (item) {
  return function (dispatch) {
    dispatch(setShowBonusStyleUpsellModal(item));
  };
};

export const reservationSliceReducer = {
  // ReduxApp expects reducers to have a "types" property
  types: [`${reservationSlice.name}/setReservation`],
  fn: reservationSlice.reducer,
};
export const availabilitySliceReducer = {
  // ReduxApp expects reducers to have a "types" property
  types: [`${reservationAvailabilitySlice.name}/setReservationAvailability`],
  fn: reservationAvailabilitySlice.reducer,
};
export const bonusStyleUpsellModalSliceReducer = {
  types: [`${bonusStyleUpsellModalSlice.name}/setShowBonusStyleUpsellModal`, `fetchUpsellModalCuration/fulfilled`],
  fn: bonusStyleUpsellModalSlice.reducer,
};
