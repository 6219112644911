import $ from "clients/RawClient";
import _ from "underscore";
import ActionTypes from "./action-types.js";
import { createAction } from "redux-actions";
import appendQuery from "append-query";

const actions = {
  cmsContentUpdated: createAction(ActionTypes.CMS_CONTENT_UPDATED),
  hasFileSizeError: createAction(ActionTypes.HAS_FILE_SIZE_ERROR),
  displayModal: createAction(ActionTypes.DISPLAY_MODAL),
  displayHighlightedComponent: createAction(ActionTypes.DISPLAY_HIGHLIGHTED_COMPONENT),
  modalHashChange: createAction(ActionTypes.MODAL_HASH_CHANGE),
  receivePartialProps: createAction(ActionTypes.RECEIVE_PARTIAL_PROPS),
  inSSCFlow: createAction(ActionTypes.IN_SSC_FLOW),
  clearFitSummary: createAction(ActionTypes.FETCH_FIT_SUMMARY),
  fetchFitSummarySuccess: createAction(ActionTypes.FETCH_FIT_SUMMARY_SUCCESS),
  fetchFitSummaryError: createAction(ActionTypes.FETCH_FIT_SUMMARY_ERROR),

  // Optional setLocation parameter is used to override the current location, i.e. when
  // the user is on a grid page but calls for the "pdp page" reservation data from the drawer.
  fetchPartialProps: function (stateKey, loggingFn, workingFilters = {}, setLocation = null) {
    return function (dispatch) {
      let location = setLocation ? setLocation : window.location.pathname + window.location.search;

      if (stateKey) {
        const stateParam = "state=" + stateKey;
        location = appendQuery(location, stateParam);
      }
      if (!_.isEmpty(workingFilters)) {
        // NW [EXPLANATION] 11/12/19: in this context, workingFilters will filter down related products results.
        location = appendQuery(location, workingFilters);
      }

      $.ajax({
        url: location,
        headers: {
          Accept: "application/json",
        },
      }).then(function (data) {
        dispatch(actions.receivePartialProps(data));
        loggingFn();
      });
    };
  },

  loadCMSContent: function (
    path,
    stateKey,
    responseField = "storefront-json",
    isJSON = true,
    callback = null,
    cmsContent = null
  ) {
    return function (dispatch) {
      // check if cmsContent is already loaded server-side
      if (cmsContent?.[responseField]) {
        const content = isJSON ? JSON.parse(cmsContent?.[responseField]) : cmsContent?.[responseField];
        dispatch(actions.cmsContentUpdated({ content, stateKey }));
        return;
      }

      $.get(`/CMS/content?path=${encodeURIComponent(path)}`).then(data => {
        try {
          const rawContent = data[responseField];
          if (rawContent) {
            const content = isJSON ? JSON.parse(rawContent) : rawContent;
            dispatch(actions.cmsContentUpdated({ content, stateKey }));
          }
          if (typeof callback === "function") {
            callback();
          }
          // eslint-disable-next-line no-empty
        } catch (err) {}
      });
    };
  },
};

export default actions;

export const {
  cmsContentUpdated,
  displayModal,
  displayHighlightedComponent,
  hasFileSizeError,
  modalHashChange,
  receivePartialProps,
  inSSCFlow,
  clearFitSummary,
  fetchFitSummarySuccess,
  fetchFitSummaryError,
  fetchPartialProps,
  getFitSummary,
  loadCMSContent,
} = actions;
