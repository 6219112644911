const AtomRemoveAddressCard = ({ toggleAddressMethodRemoval, address }) => {
  const addressId = address?.id;

  return (
    <div className="atom-radio-card manage-payment-profile-card-removal" role="listitem">
      <div
        className="manage-payment-profile-card-removal__notification"
        aria-label="Notification for payment method removal">
        Your address will be removed.
      </div>
      <div
        className="manage-payment-profile-card-removal__undo-action"
        role="button"
        aria-label="Action to undo address removal"
        onClick={() => toggleAddressMethodRemoval(addressId)}>
        Cancel change
      </div>
    </div>
  );
};

export default AtomRemoveAddressCard;
