import React from "react";
import creditCardIconHelper from "../../../helpers/credit-card-icon-helper";
import { creditCardTypes, PaymentMethods } from "rtr-constants";
import PropTypes from "prop-types";

const propTypes = {
  cardType: PropTypes.string.isRequired,
  cardSubType: PropTypes.oneOf([PaymentMethods.subType.CREDIT_CARD, PaymentMethods.subType.APPLE_PAY]),
  optionalClassName: PropTypes.string,
};

class AtomCreditCardIcon extends React.Component {
  verifyKnownCreditCard() {
    return Object.values(creditCardTypes)?.includes(this.props.cardType);
  }

  getCardClassName() {
    return `atom-payment-profile-card__icon ${creditCardIconHelper(this.props.cardType, this.props.cardSubType)}`;
  }

  getOptionalClassName() {
    return this.props.optionalClassName || "";
  }

  render() {
    if (this.verifyKnownCreditCard()) {
      return (
        <div
          className={`${this.getOptionalClassName()} ${this.getCardClassName()}`}
          data-test-id="atom-credit-card-icon"></div>
      );
    } else {
      return null;
    }
  }
}

AtomCreditCardIcon.propTypes = propTypes;

export default AtomCreditCardIcon;
