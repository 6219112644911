import _ from "underscore";
import ActionTypes from "actions/action-types";

/*
 * On arrival to the checkout page, the initial state
 * of the page and individual steps is determined by the
 * CheckoutPageState class (checkout_page_state.rb).
 *
 * CheckoutPageState takes the current order in checkout as
 * a param. It looks at whether the order and orderGroups have
 * a payment method and shipping address, respectively, to
 * determine initial state.
 *
 * Then, as the customer moves through the steps on the page,
 * the state of the steps is updated in this reducer.
 *
 */

export const types = [
  ActionTypes.CHECKOUT_SHIPPING_STEP_SUBMITTING,
  ActionTypes.CHECKOUT_SHIPPING_STEP_SUCCESS,
  ActionTypes.CHECKOUT_SHIPPING_STEP_FAILURE,
  ActionTypes.CHECKOUT_BILLING_STEP_SUBMITTING,
  ActionTypes.CHECKOUT_BILLING_STEP_SUCCESS,
  ActionTypes.CHECKOUT_BILLING_STEP_FAILURE,
];

export const fn = function (state, action) {
  const previousCheckoutPageState = state.checkoutPageState;
  const checkoutPageState = _.extend({}, previousCheckoutPageState);

  switch (action.type) {
    case ActionTypes.CHECKOUT_SHIPPING_STEP_SUBMITTING:
      checkoutPageState.shipping.isSubmitting = true;
      break;

    case ActionTypes.CHECKOUT_SHIPPING_STEP_SUCCESS:
      checkoutPageState.shipping.isSubmitting = false;
      checkoutPageState.shipping.isValid = true;
      checkoutPageState.shipping.isOpen = false;
      /* We don't want to reopen billing in the case that
       * that step has already been completed (i.e., user
       * completed billing step and then returned to shipping
       * step to edit.) */
      checkoutPageState.billing.isOpen = !checkoutPageState.billing.isValid;
      checkoutPageState.summary.isValid = Boolean(
        checkoutPageState.shipping.isValid && checkoutPageState.billing.isValid
      );
      break;

    case ActionTypes.CHECKOUT_SHIPPING_STEP_FAILURE:
      // TODO: Revisit this in future form errors PR
      checkoutPageState.shipping.isSubmitting = false;
      checkoutPageState.shipping.isOpen = true;
      checkoutPageState.shipping.isValid = false;
      checkoutPageState.summary.isValid = Boolean(
        checkoutPageState.shipping.isValid && checkoutPageState.billing.isValid
      );
      break;

    case ActionTypes.CHECKOUT_BILLING_STEP_SUBMITTING:
      checkoutPageState.billing.isSubmitting = true;
      break;

    case ActionTypes.CHECKOUT_BILLING_STEP_SUCCESS:
      // On success, close billing step and reevaluate summary.isValid
      checkoutPageState.billing.isSubmitting = false;
      checkoutPageState.billing.isOpen = false;
      checkoutPageState.billing.isValid = true;
      checkoutPageState.summary.isValid = Boolean(
        checkoutPageState.shipping.isValid && checkoutPageState.billing.isValid
      );
      break;

    case ActionTypes.CHECKOUT_BILLING_STEP_FAILURE:
      // TODO: Revisit this in future form errors PR
      checkoutPageState.billing.isSubmitting = false;
      checkoutPageState.billing.isOpen = true;
      checkoutPageState.billing.isValid = false;
      checkoutPageState.summary.isValid = Boolean(
        checkoutPageState.shipping.isValid && checkoutPageState.billing.isValid
      );
      break;

    default:
      break;
  }

  return _.extend({}, state, {
    checkoutPageState,
  });
};

export default {
  types: types,
  fn: fn,
};
