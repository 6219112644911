import BaseServicesClient from "./BaseServicesClient";
import { isSSR } from "../helpers/client-server-helper";

const photoReviewClientHost = (() => {
  if (typeof process !== "undefined" && process.env) {
    return isSSR() ? process.env.CONFIG__PHOTO_REVIEWS__HOST : process.env.NEXT_PUBLIC__REVIEWS_URL;
  } else {
    return "";
  }
})();

export default class PhotoReviewsServiceClient extends BaseServicesClient {
  static CONFIG_HOST = photoReviewClientHost;

  async fetchReviewSummary(productId) {
    const url = `v3/momentSummary/styleName/${encodeURIComponent(productId)}`;

    return this.getJson(url);
  }

  async fetchReviewIdsWithSimilarity(productId, sort, limit = 100, page = 1, sizes = []) {
    const url = `v3/reviewIds/styleName/${encodeURIComponent(productId)}`;
    const offset = (page - 1) * limit;
    const params = { sort, offset, limit, size: sizes };
    const queryString = this._createQueryString(params);
    return this.getJson(`${url}?${queryString}`);
  }

  async fetchReviewIdsWithSimilarityUnPaginated(productId, sort, sizes = []) {
    const url = `v3/reviewIds/styleName/${encodeURIComponent(productId)}`;
    const params = { sort, size: sizes };
    const queryString = this._createQueryString(params);
    return this.getJson(`${url}?${queryString}`);
  }

  async fetchMomentWithCount(params) {
    const url = `v3/momentsWithCount?${this._createQueryString(params)}`;
    return this.getJson(url);
  }

  async fetchMomentWithCountWithReviewId(params) {
    const reviewId = params?.reviewId;
    delete params["reviewId"];
    const url = `v3/userMomentsWithCount/reviewId/${reviewId}?${this._createQueryString(params)}`;
    return this.getJson(url);
  }

  async fetchMomentWithPhotos(params) {
    params["hasPhotos"] = true;
    params["status"] = "approved";

    const url = `v3/momentsWithCount?${this._createQueryString(params)}`;
    return this.getJson(url);
  }

  _createQueryString(params) {
    const queryParts = [];
    for (const key in params) {
      if (Array.isArray(params[key])) {
        params[key].forEach(value => {
          queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        });
      } else {
        queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
      }
    }
    return queryParts.join("&");
  }
}
