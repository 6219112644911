import React from "react";
import _ from "underscore";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addressPropType, childrenPropType, idPropType } from "components/propTypes";
import MoleculeSuggestAddress from "components/source/molecules/molecule-suggest-address";
import MoleculeAddressSelect from "components/source/molecules/molecule-address-select";
import MoleculeManageAddressCards from "components/source/molecules/molecule-manage-address-cards";
import { EVENTS_CUSTOM_CASTLE, checkout, formElementNames, featureFlags } from "rtr-constants";
const { NON_SHIPPABLE_ADDRESS } = checkout.errorTypes;
import {
  submitShippingAddressAndReplaceAll,
  initValues,
  updateElement,
  confirmAddress,
  geocodeAutocompleteResult,
  shippingStepSubmittingReset,
  validateShippingFormBeforeSubmit,
} from "actions/shipping-step-actions";
import { displayModal } from "actions/shared-actions.js";
import { updateDefaultAndRemoveAddress } from "actions/shipping-address-actions.js";
import AtomHighlightTextButton from "components/source/atoms/atom-highlight-text-button";
import AddressFormFields from "components/source/checkout/forms/address-form-fields";
import GenericSubform from "components/source/checkout/forms/generic-subform";
import MoleculeTabs from "./molecule-tabs";
import AtomTabPanel from "../atoms/atom-tab-panel";
import * as castleHelper from "helpers/castle-helper";
import { selectFeatureFlagEnabled } from "selectors/featureFlagSelectors";
import { findWhere } from "helpers/underscore-js-migration-helper";

export const AddEditAddressForm = GenericSubform(AddressFormFields);
const displayStates = {
  ADD_ADDRESS: "ADD_ADDRESS",
  CONFIRM_ADDRESS: "CONFIRM_ADDRESS",
  EDIT_ADDRESS: "EDIT_ADDRESS",
  SELECT_ADDRESS: "SELECT_ADDRESS",
  MANAGE_ADDRESS: "MANAGE_ADDRESS",
};

/*
 * This component serves as the smart wrapper to determine whether to show either a:
 * Address Form or
 * Confirm Address selection or
 * Select Address or
 * Manage (remove/make default) address or
 * Pick Up In Store Form
 * component. It handles all of the callbacks around the display state for which of these
 * to show and also passes down actions for these components to dispatch.
 */

const noop = () => {}; // NOSONAR

export class MoleculeAddress extends React.Component {
  static propTypes = {
    addAddressWrapper: PropTypes.func,
    addressAlternatives: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        children: childrenPropType,
      })
    ),
    addressBookTitle: PropTypes.string,
    addressBookDek: PropTypes.string,
    confirmAddressWrapper: PropTypes.func,
    displayAddForm: PropTypes.bool,
    displayManageAddressCards: PropTypes.bool,
    enableAutocomplete: PropTypes.bool,
    handleFormElementValuesCallback: PropTypes.func,
    hideNewAddressButton: PropTypes.bool,
    hideUseSelectedAddressButton: PropTypes.bool,
    initialAddressId: PropTypes.string,
    isGiftCardOnly: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    onAddressValidationFailure: PropTypes.func,
    onAlternativeTabSelect: PropTypes.func,
    onBackButtonFlowCallback: PropTypes.func,
    onRequestCloseConfirmAddressModal: PropTypes.func,
    onSelectAddress: PropTypes.func.isRequired,
    onSelectExistingAddress: PropTypes.func,
    onSubmitAddressFormSuccess: PropTypes.func,
    returnToAddressBookAfterSavingNew: PropTypes.bool,
    selectAddressWrapper: PropTypes.func,
    selectedAddressId: idPropType,
    selectedAlternativeTabName: PropTypes.string,
    shippingStepIsOpen: PropTypes.bool,
    shippingStepIsValid: PropTypes.bool,
    showFormMargin: PropTypes.bool,
    showMakeDefaultCheckbox: PropTypes.bool,
    showSelectExistingAddressCTA: PropTypes.bool,
    sortBySelectedFirst: PropTypes.bool,
    subformTitle: PropTypes.string,
    useAddress: PropTypes.func.isRequired,
    useSelectedAddressButtonText: PropTypes.string,
    userSubmittedAddress: PropTypes.object,
    writeMembershipShipmentAddress: PropTypes.func,

    // assigned by `connect` at end of file
    /* eslint-disable react/sort-prop-types */
    addresses: PropTypes.arrayOf(addressPropType).isRequired,
    addressValidationError: PropTypes.string,
    errorType: PropTypes.string,
    displayModal: PropTypes.func,
    geocodeAddress: PropTypes.func,
    initValues: PropTypes.func,
    isAddressBookLoading: PropTypes.bool,
    onConfirmAddressLoggingFn: PropTypes.func,
    onSuggestAddressLogging: PropTypes.func,
    resetValues: PropTypes.func,
    submitShippingAddress: PropTypes.func.isRequired,
    suggestedAddresses: PropTypes.array,
    updateDefaultAndRemoveAddress: PropTypes.func, // This comes from mapDispatchToProps
    validate: PropTypes.func.isRequired,
    validateBeforeSubmitAction: PropTypes.func, // This comes from mapDispatchToProps
    userDeleteAddressMethodsFF: PropTypes.bool,
    validateShippingAddressFF: PropTypes.bool,
    /* eslint-enable react/sort-prop-types */
  };

  // Initially render the component in select view (address book view)
  state = {
    display: this.initialDisplayState(),
    /* We set `hasMakeDefaultCheckbox` in initial state because
     * each instance of the form either has the checkbox or not;
     * we don't want this to change throughout the use of the form.
     * I.e., if we only show the checkbox when the user has >= 1 address(es),
     * it doesn't appear on page load for a new user but then appears momentarily
     * once the new address is created.
     */
    hasMakeDefaultCheckbox: this.shouldShowMakeDefaultCheckbox(),
    wasInSelectDisplay: false,
  };

  static defaultProps = {
    // These wrapper functions exist as there are cases where we would like to put this component within
    // other pieces of markup which themselves must reflect the internal state of this component. In this
    // instance, this component determines the displayState and wraps the component appropriately. If no
    // wrapper is passed in, we use an _.identity function to return the elements without a wrapper.
    addAddressWrapper: _.identity,
    confirmAddressWrapper: _.identity,
    selectAddressWrapper: _.identity,
    displayManageAddressCards: false,
  };

  shouldDisplayLoading() {
    return this.props.isAddressBookLoading;
  }

  shouldShowTabs() {
    const { addressAlternatives = [] } = this.props;

    return addressAlternatives.length;
  }

  defaultAddressProfile = () => {
    return findWhere(this.props.addresses, { default: true });
  };

  selectedAddressProfile = () => {
    return this.props.selectedAddressId || this.defaultAddressProfile()?.id;
  };

  handleClose = () => {
    this.props.displayModal(false);
  };

  initialDisplayState() {
    if (this.shouldDisplayAddForm()) {
      return displayStates.ADD_ADDRESS;
    }

    if (this.props.displayManageAddressCards && this.props.userDeleteAddressMethodsFF) {
      return displayStates.MANAGE_ADDRESS;
    }

    // Render SELECT_ADDRESS in all other states
    return displayStates.SELECT_ADDRESS;
  }

  shouldShowMakeDefaultCheckbox() {
    /* If showMakeDefaultCheckbox has been explicitly
     * specified -- either true or false --
     * we should honor that.
     */
    if (_.isBoolean(this.props.showMakeDefaultCheckbox)) {
      return this.props.showMakeDefaultCheckbox;
    }

    /* If showMakeDefaultCheckbox isn't explicitly specified in props,
     * don't show the checkbox if the user has no addresses;
     * the first will be default by ... default
     */
    return !_.isEmpty(this.props.addresses);
  }

  shouldShowSelectExistingAddressCTA() {
    const { addresses, showSelectExistingAddressCTA, shippingStepIsValid } = this.props;

    return !_.isEmpty(addresses) && showSelectExistingAddressCTA && !shippingStepIsValid;
  }

  optionalClasses() {
    return this.props.showFormMargin ? "" : "molecule-address-select--no-margin";
  }

  shouldDisplayAddForm() {
    const { addresses = [], displayAddForm = false } = this.props;

    return displayAddForm || !addresses.length;
  }

  /*
   * Validate the address and pass valid and ambiguous address callbacks.
   * If the address is valid, it will be written and the submitSuccess
   * callback will be executed. Otherwise we will display the CONFIRM_ADDRESS
   * view and ask the user to confirm their address.
   */
  onSaveAddress = values => {
    // Due to issues with zip+4, we're going to flag the validation step until we can properly iron out the issues and QA them.
    if (this.props.validateShippingAddressFF) {
      this.triggerConfirmation(values);
    } else {
      this.triggerCreate(values);
    }
    castleHelper.logCustomEvent({ name: EVENTS_CUSTOM_CASTLE.SHIPPING_ADDRESS_UPDATE });
  };

  onValidationFailure = err => {
    const errorMessage = err ? err.responseText : "ambiguous";
    if (this.props.onAddressValidationFailure) {
      this.props.onAddressValidationFailure(errorMessage);
    }

    /*
     * We set the display state to CONFIRM_ADDRESS here,
     * but there are certain cases where we want to stay on
     * the form and show the user an error. Currently the use
     * case for this is an address with errorType NON_SHIPPABLE_ADDRESS.
     * This case is handled in content().
     */
    this.setState({
      display: displayStates.CONFIRM_ADDRESS,
    });
  };

  // wrap the provided onSubmitAddressFormSuccess prop to both call the method
  // and to inform the parent Component that the selected address id has changed
  handleSubmitAddressFormSuccess = address => {
    if (_.isFunction(this.props.onSubmitAddressFormSuccess)) {
      this.props.onSubmitAddressFormSuccess(address);
    }

    if (!address) {
      return;
    }

    this.props.onSelectAddress(address.id);

    if (this.props.returnToAddressBookAfterSavingNew) {
      this.triggerAddressBookView();
    }
  };

  // Move to onSaveAddress once flag: validate_shipping_address is deleted.
  triggerConfirmation(values) {
    this.props.validate(values, this.handleSubmitAddressFormSuccess, this.onValidationFailure);
  }

  // Remove once flag: validate_shipping_address is deleted.
  triggerCreate(values) {
    this.props.submitShippingAddress(values, this.handleSubmitAddressFormSuccess);
  }

  onConfirmAddress = (values, validatedAddress) => {
    // Currently coupled to shippingStepReducer shape.
    this.triggerCreate({ vals: values });

    if (this.props.onConfirmAddressLoggingFn) {
      this.props.onConfirmAddressLoggingFn(validatedAddress);
    }
  };

  triggerAddressForm(address) {
    // NOTE don't get it, this should be forced as `let`
    // TODO look into why lint wants this
    const nextState = {};

    const { display: currentDisplay, wasInSelectDisplay } = this.state;
    const { initValues: initializeValues, resetValues } = this.props;

    if (address) {
      initializeValues(address);
      nextState.display = displayStates.EDIT_ADDRESS;
    } else {
      resetValues();
      nextState.display = displayStates.ADD_ADDRESS;
    }

    nextState.wasInSelectDisplay = wasInSelectDisplay || currentDisplay === displayStates.SELECT_ADDRESS;

    this.setState(nextState);
  }

  triggerAddressBookView = () => {
    this.setState({
      display: displayStates.SELECT_ADDRESS,
    });
  };

  onRequestCloseConfirmAddressModal = () => {
    if (this.props.onRequestCloseConfirmAddressModal) {
      this.props.onRequestCloseConfirmAddressModal();
    } else {
      this.onBack();
    }
  };

  renderSelectExistingAddressCTA() {
    /*
     * This is for the case in which the user has existing addresses,
     * but none of them have been associated with the orderGroup at checkout
     * page load. This happens when a customer has no existing address
     * with the same zip as the booking zip.
     */
    const { onSelectExistingAddress } = this.props;
    const showSelectExistingAddressCTA = this.shouldShowSelectExistingAddressCTA();

    if (!showSelectExistingAddressCTA || !onSelectExistingAddress) {
      return;
    }

    return (
      <div className="subform__select-existing-address">
        Or
        <AtomHighlightTextButton onClick={onSelectExistingAddress} buttonText={"select an existing address"} />
      </div>
    );
  }

  renderSubformDek() {
    const giftCardOnlyAddressCopy = this.props.isGiftCardOnly ? (
      <div className="subform__top-dek">{checkout.informationalMessages.giftCardShippingAddress}</div>
    ) : (
      ""
    );

    return [this.renderSelectExistingAddressCTA(), giftCardOnlyAddressCopy];
  }

  renderAddressForm(showTitle) {
    const { hasMakeDefaultCheckbox, wasInSelectDisplay } = this.state;
    const onBackButton = wasInSelectDisplay ? this.triggerAddressBookView : this.props.onBackButtonFlowCallback;
    const addressTooltip = "Note: Item availability is subject to change based on location.";

    return this.props.addAddressWrapper(
      <AddEditAddressForm
        name="addEditAddressModal"
        action="/shippingAddresses"
        stateKey="shippingStep"
        subformTitle={showTitle ? this.props.subformTitle : ""}
        addressTooltip={addressTooltip}
        subformDek={this.renderSubformDek()}
        submitButtonText={{
          default: "Save",
          submitting: "Saving...",
        }}
        formElementNames={formElementNames}
        onBackButton={onBackButton}
        showMakeDefaultCheckbox={hasMakeDefaultCheckbox}
        updateElementAction={updateElement}
        handleFormElementValuesCallback={this.props.handleFormElementValuesCallback}
        submitSubformAction={this.onSaveAddress}
        validateBeforeSubmitAction={this.props.validateBeforeSubmitAction}
        enableAutocomplete={this.props.enableAutocomplete}
        geocodeAddress={this.props.geocodeAddress}
      />
    );
  }

  renderSelectAddress(showTitle) {
    /*
     * In the case that the user arrives at the checkout page and clicks
     * the "Select Existing Address" from the open / incomplete shipping step,
     * we render the "Select Address" modal while the shipping step
     * is still open (i.e., the form is showing on the page behind the modal).
     * In that case, we want to hide the "Add New Address" button to
     * avoid having two open, empty forms on the page.
     */
    const { hideNewAddressButton, shippingStepIsOpen } = this.props;
    const isSelectState = this.state.display === displayStates.SELECT_ADDRESS;

    if (isSelectState) {
      return this.props.selectAddressWrapper(
        <MoleculeAddressSelect
          addressBookTitle={showTitle ? this.props.addressBookTitle : ""}
          addressBookDek={showTitle ? this.props.addressBookDek : ""}
          addresses={this.props.addresses}
          hideNewAddressButton={hideNewAddressButton || shippingStepIsOpen}
          hideUseSelectedAddressButton={this.props.hideUseSelectedAddressButton}
          initialAddressId={this.props.initialAddressId}
          isSubmitting={this.props.isSubmitting}
          onAddressSelect={this.props.onSelectAddress}
          onBackButtonFlowCallback={this.props.onBackButtonFlowCallback}
          selectedAddressId={this.props.selectedAddressId}
          sortBySelectedFirst={this.props.sortBySelectedFirst}
          triggerAddressForm={address => this.triggerAddressForm(address)}
          useAddress={this.props.useAddress}
          useSelectedAddressButtonText={this.props.useSelectedAddressButtonText}
        />
      );
    } else {
      return this.props.selectAddressWrapper(
        <MoleculeManageAddressCards
          addressBookTitle={showTitle ? this.props.addressBookTitle : ""}
          addresses={this.props.addresses}
          handleClose={this.handleClose}
          hideNewAddressButton={hideNewAddressButton || shippingStepIsOpen}
          hideUseSelectedAddressButton={this.props.hideUseSelectedAddressButton}
          isSubmitting={this.props.isSubmitting}
          triggerAddressForm={address => this.triggerAddressForm(address)}
          updateDefaultAndRemoveAddress={this.props.updateDefaultAndRemoveAddress}
          writeMembershipShipmentAddress={this.props.writeMembershipShipmentAddress}
        />
      );
    }
  }

  renderSuggestAddress() {
    const {
      addressValidationError,
      confirmAddressWrapper,
      onSuggestAddressLogging,
      suggestedAddresses = [],
      userSubmittedAddress,
    } = this.props;

    return confirmAddressWrapper(
      <MoleculeSuggestAddress
        addressValidationError={addressValidationError}
        onBackButtonFlowCallback={() => this.triggerAddressForm(userSubmittedAddress)}
        onConfirmAddress={this.onConfirmAddress}
        onSuggestAddressLogging={onSuggestAddressLogging}
        suggestedAddress={suggestedAddresses[0]}
        userSubmittedAddress={userSubmittedAddress}
      />,
      this.onRequestCloseConfirmAddressModal
    );
  }

  getAddressHeader() {
    if (this.state.display === displayStates.SELECT_ADDRESS) {
      return this.props.addressBookTitle;
    } else if (this.state.display === displayStates.CONFIRM_ADDRESS && this.props.errorType !== NON_SHIPPABLE_ADDRESS) {
      return this.props.addressBookTitle;
    } else {
      return this.props.subformTitle;
    }
  }

  renderAddressContent(showTitle) {
    // select or manage address cards
    if (this.state.display === displayStates.SELECT_ADDRESS || this.state.display === displayStates.MANAGE_ADDRESS) {
      return this.renderSelectAddress(showTitle);
      /*
       * If we have suggested addresses returned and need to confirm,
       * show the confirmation step. In this case, only show the first address
       * and allow the user to either select this address or the one
       * they entered.
       *
       * In the case of an unshippable address, we want to stay on
       * the form and show the user an error. (Soon we will probably
       * want to put displayState logic into Redux.)
       */
    }

    if (this.state.display === displayStates.CONFIRM_ADDRESS && this.props.errorType !== NON_SHIPPABLE_ADDRESS) {
      return this.renderSuggestAddress();
    }

    // Editing or adding an address
    return this.renderAddressForm(showTitle);
  }

  renderTabsContent() {
    const tabNames = ["Ship to me"];
    const { addressAlternatives = [], selectedAlternativeTabName } = this.props;

    const tabPanels = [
      <AtomTabPanel key="tab-panel" tabName={tabNames[0]}>
        {this.renderAddressContent(false)}
      </AtomTabPanel>,
    ];

    _.each(addressAlternatives, alternative => {
      tabNames.push(alternative.name);

      tabPanels.push(<AtomTabPanel tabName={alternative.name}>{alternative.children}</AtomTabPanel>);
    });

    return (
      <div className="molecule-address-tabs">
        <div className="molecule-address-select__header">{this.getAddressHeader()}</div>

        <MoleculeTabs
          tabNames={tabNames}
          selectedTabName={selectedAlternativeTabName}
          onTabSelect={this.props.onAlternativeTabSelect}>
          {tabPanels}
        </MoleculeTabs>
      </div>
    );
  }

  renderLoading() {
    return <div className="loading" />;
  }

  render() {
    let content;

    if (this.shouldDisplayLoading()) {
      content = this.renderLoading();
    } else if (this.shouldShowTabs()) {
      content = this.renderTabsContent();
    } else {
      content = this.renderAddressContent(true);
    }

    return <div className={`molecule-address-select ${this.optionalClasses()}`}>{content}</div>;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initValues(values) {
      dispatch(initValues(values));
    },
    resetValues() {
      dispatch(shippingStepSubmittingReset());
    },
    validate(values, validationSuccessCallback, validationFailureCallback) {
      dispatch(confirmAddress(values, validationSuccessCallback, validationFailureCallback));
    },
    validateBeforeSubmitAction() {
      dispatch(validateShippingFormBeforeSubmit());
    },
    submitShippingAddress(values, createSuccessCallback) {
      dispatch(submitShippingAddressAndReplaceAll(values, createSuccessCallback));
    },
    geocodeAddress(address) {
      dispatch(geocodeAutocompleteResult(address));
    },
    displayModal: values => {
      dispatch(displayModal(values));
    },
    updateDefaultAndRemoveAddress: ({ values, onSuccess = noop, onFailure = noop }) => {
      dispatch(updateDefaultAndRemoveAddress({ values, onSuccess, onFailure }));
    },
  };
};

const mapStateToProps = state => {
  const props = {
    addresses: state.addresses || state.userData?.userProfile?.addresses,
    isAddressBookLoading: state.isAddressBookLoading,
    userDeleteAddressMethodsFF: selectFeatureFlagEnabled(featureFlags.USER_DELETE_ADDRESS_METHODS)(state),
    validateShippingAddressFF: selectFeatureFlagEnabled(featureFlags.VALIDATE_SHIPPING_ADDRESS)(state),
  };

  if (state.shippingStep) {
    props.suggestedAddresses = state.shippingStep.suggestedAddresses;
    props.userSubmittedAddress = state.shippingStep.userSubmittedAddress;
    props.addressValidationError = state.shippingStep.addressValidationError;
    props.errorType = state.shippingStep.errors.errorType;
  }

  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(MoleculeAddress);
