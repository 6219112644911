// by using universal-cookie, you can expect that this code will only work
// client-side. to make it work server-side, have your component pass the
// cookies object provided to it by the `withCookies` HOC.
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const TrafficSource = {
  FACEBOOK_AD_FUNNEL: "facebook_ad_funnel",
  RESERVE_INTENDING: "reserve_intending",

  setSession: function (trafficName) {
    cookies.set(trafficName, true, { path: "/" });
  },

  isPaidSession: function () {
    return cookies.get(this.FACEBOOK_AD_FUNNEL);
  },

  // Check whether we've set a cookie that identifies this
  // user as reserve-intending paid traffic
  isReserveIntendingTraffic: function () {
    return cookies.get(this.RESERVE_INTENDING) || false;
  },
};

export default TrafficSource;

export const {
  FACEBOOK_AD_FUNNEL,
  RESERVE_INTENDING,
  setSession,
  isPaidSession,
  isReserveIntendingTraffic,
} = TrafficSource;
