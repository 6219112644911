import $ from "clients/RawClient";
import { createAction } from "redux-actions";
import { v4 as uuidv4 } from "uuid";

import ActionLogger from "action-logger";
import ActionTypes from "actions/action-types";
import AuthActions from "actions/auth-actions";
import { getUserId } from "components/source/hoc/with-user-data";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";
import { generateBillingPayload } from "helpers/billing-form-helpers";
import { iframeActions, iframeRemoteKeys, clientSideErrorMessages } from "rtr-constants";
import { HEAP_AUTH_TRIGGER_TYPES } from "helpers/heap-helpers";

const PAYMENT_METHOD_BILLING_STEP_STATE_KEY = "billingStep";

/*
 * This method adds the stateKey to the payload as it allows us to have a
 * generic reducer, which represents the form, be hooked up to different
 * actions which do different things.
 */
const payloadStateKeyAddition = (payload = {}) => {
  const errorMessage = payload?.errorResponse?.responseText;

  payload.stateKey = PAYMENT_METHOD_BILLING_STEP_STATE_KEY;

  if (errorMessage) {
    payload.errors = {
      form: errorMessage,
    };
  }

  return payload;
};

const actions = {
  clearFields: createAction(ActionTypes.BILLING_STEP_CLEAR_ELEMENT_VALUES, payloadStateKeyAddition),
  setExistingValues: createAction(ActionTypes.BILLING_STEP_SET_VALUES_FOR_EDIT, payloadStateKeyAddition),
  updateElement: createAction(ActionTypes.BILLING_STEP_UPDATE_ELEMENT, payloadStateKeyAddition),
  billingStepSubmitting: createAction(ActionTypes.BILLING_STEP_SUBMITTING, payloadStateKeyAddition),
  submitBillingStepSuccess: createAction(ActionTypes.BILLING_STEP_SUCCESS, payloadStateKeyAddition),
  submitBillingStepFailure: createAction(ActionTypes.BILLING_STEP_FAILURE, payloadStateKeyAddition),
  paymentProfilesIsLoading: createAction(ActionTypes.PAYMENT_PROFILES_IS_LOADING),
  paymentProfilesLoaded: createAction(ActionTypes.PAYMENT_PROFILES_LOADED),
  paymentProfilesLoadedError: createAction(ActionTypes.PAYMENT_PROFILES_LOADED_ERROR),
  paymentMethodsDeleteRequest: createAction(ActionTypes.PAYMENT_METHODS_DELETE_REQUEST),
  paymentMethodsDeleteSuccess: createAction(ActionTypes.PAYMENT_METHODS_DELETE_SUCCESS),
  paymentMethodsDeleteFailure: createAction(ActionTypes.PAYMENT_METHODS_DELETE_FAILURE),
  paymentMethodsUpdating: createAction(ActionTypes.PAYMENT_METHODS_UPDATING),

  buildEProtectHooks: (dispatch, onSuccess, onFailure) => {
    // Callbacks that hook into various steps of the token generation process.
    const hooks = {
      // Handler for iframeActions.HookNames.BEFORE_EPROTECT_TOKEN_GENERATION
      beforeEprotectTokenGeneration() {
        dispatch(actions.billingStepSubmitting());
      },
      // Handler for iframeActions.HookNames.ON_SAVE_PAYMENT_METHOD_SUCCESS
      onSavePaymentMethodSuccess(response) {
        if (onSuccess) {
          onSuccess(response);
        }
        dispatch(actions.submitBillingStepSuccess({ response }));
      },
      // Handler for iframeActions.HookNames.ON_SAVE_PAYMENT_METHOD_FAILURE
      onSavePaymentMethodFailure(err) {
        if (err?.status === 401) {
          dispatch(AuthActions.showAuthModal({ triggeredBy: HEAP_AUTH_TRIGGER_TYPES.AUTH_PROMPT }));
          return;
        }

        const errorPayload = {
          errorResponse: err,
        };
        if (onFailure) {
          onFailure(err);
        }
        dispatch(actions.submitBillingStepFailure(errorPayload));
      },
      // Handler for iframeActions.HookNames.ON_EPROTECT_ERROR
      onEprotectError(errorMessage) {
        if (onFailure) {
          onFailure({ eProtectErrorText: errorMessage });
        }

        dispatch(
          actions.submitBillingStepFailure({
            errorResponse: { responseText: errorMessage },
          })
        );
      },
      // Handler for iframeActions.HookNames.ON_EPROTECT_TIMEOUT
      onEprotectTimeout(errorMessage) {
        dispatch(
          actions.submitBillingStepFailure({
            errorResponse: { responseText: errorMessage },
          })
        );
      },
      // Handler for iframeActions.HookNames.ON_EPROTECT_NOT_LOADED_ERROR
      onEprotectNotLoadedError(errorMessage) {
        dispatch(
          actions.submitBillingStepFailure({
            errorResponse: { responseText: errorMessage },
          })
        );
      },
    };

    // The following code sets up listeners for messages, about the various
    // hooks, that are sent by the iframe. For consistency, the name of the
    // action listened for is the name of the `hook`.
    Object.keys(hooks).forEach(hookName => {
      window.iframeMessenger.addHandler(iframeRemoteKeys.PARENT, hookName, (action, payload) => {
        hooks[hookName](payload);
      });
    });

    window.iframeMessenger.addHandler(iframeRemoteKeys.PARENT, iframeActions.LOG_ACTION, (action, payload) => {
      ActionLogger.logAction(payload);
    });
  },

  destroyEProtectHooks: () => {
    Object.values(iframeActions.HookNames).forEach(hookName => {
      window.iframeMessenger.removeHandlers(iframeRemoteKeys.PARENT, hookName);
    });
  },

  submitPaymentMethodWithToken: function (payload, onSuccess, onFailure) {
    return function (dispatch, getState) {
      const { url: paymentMethodUrl } = payload;

      const userId = getUserId(getState().userData);
      const billingPayloadValues = generateBillingPayload(payload.vals, userId);
      // This action is used for the cases where we have to save a
      // paymentMethod, but there isn't an ``orderId` associated with it, e.g.
      // updating a paymentMethod for Subscription. Vantiv's maximum orderId
      // length is 25, so we're going to use a uuid trimmed to 24 characters to
      // accommodate the `-` for the `id`.
      const MAX_ORDER_ID_LENGTH = 24;
      const orderId = uuidv4().substring(0, MAX_ORDER_ID_LENGTH);
      // const timestamp = moment().unix();
      // const orderId = `${timestamp}`;
      const id = `-${orderId}`;

      actions.buildEProtectHooks(dispatch, onSuccess, onFailure);

      window.iframeMessenger.addHandler(iframeRemoteKeys.PARENT, iframeActions.LOG_ACTION, (action, payload) => {
        ActionLogger.logAction(payload);
      });

      window.iframeMessenger.send(
        iframeRemoteKeys.SECURE_CREDIT_CARD,
        iframeActions.SAVE_PAYMENT_METHOD_USING_EPROTECT,
        {
          orderId,
          id,
          paymentMethodUrl,
          billingPayloadValues,
        }
      );
    };
  },

  submitPaymentMethodWithTokenForStandardCheckout: function (payload, onSuccess, onFailure) {
    return function (dispatch) {
      const { url: paymentMethodUrl } = payload;
      const billingPayloadValues = generateBillingPayload(payload.billingPayloadValues, payload.userId);

      const orderId = uuidv4().substring(0, 24);
      const id = `-${orderId}`;

      actions.buildEProtectHooks(dispatch, onSuccess, onFailure);

      window.iframeMessenger.send(
        iframeRemoteKeys.SECURE_CREDIT_CARD,
        iframeActions.SAVE_PAYMENT_METHOD_USING_EPROTECT,
        {
          orderId,
          id,
          paymentMethodUrl,
          billingPayloadValues,
        }
      );
    };
  },

  submitPaymentMethod: function (payload, isPatch) {
    return async function (dispatch) {
      let valuesToSubmit;
      if (isPatch) {
        valuesToSubmit = generateBillingPayload(payload.vals);
      } else {
        // Only supporting PATCH requests as they don't submit credit card information
        // this should probably be refactored now that eProtect is the only supported route
        return dispatch(actions.submitBillingStepFailure({ errorResponse: "Invalid state" }));
      }

      // Change body when we begin to support additional params
      const type = "PATCH";

      dispatch(actions.billingStepSubmitting());

      try {
        const response = await createAjaxAuthRetry({
          url: payload.url,
          type: type,
          data: JSON.stringify(valuesToSubmit),
          headers: {
            "Content-Type": "application/json",
          },
        });

        dispatch(actions.submitBillingStepSuccess({ response: response?.data }));

        return response;
      } catch (error) {
        const errorPayload = {
          errorResponse: error?.jqXHR,
        };

        dispatch(actions.submitBillingStepFailure(errorPayload));

        throw error;
      }
    };
  },

  removePaymentMethods: function (data) {
    return async function (dispatch) {
      const ids = data?.vals?.ids || [];
      const route = data?.url;

      if (!Array.isArray(ids) || ids.length === 0) {
        return;
      }

      dispatch(actions.paymentMethodsDeleteRequest());

      for (const id of ids) {
        try {
          await createAjaxAuthRetry({
            url: `${route}/${id}`,
            type: "DELETE",
          });
        } catch {
          dispatch(actions.paymentMethodsDeleteFailure());
          throw id;
        }
      }

      dispatch(actions.paymentMethodsDeleteSuccess());
    };
  },

  updateDefaultAndRemovePaymentMethods: function (values) {
    return async function (dispatch) {
      const { newDefaultPaymentMethodID, originalDefaultPaymentMethodID, updateData, removeData } = values || {};
      const { vals } = removeData || {};
      const hasPaymentMethodsToDelete = vals?.ids?.length > 0;
      const hasNewDefaultPaymentMethod = newDefaultPaymentMethodID !== originalDefaultPaymentMethodID;
      const isPatch = true;

      dispatch(actions.paymentMethodsUpdating(true));

      if (hasNewDefaultPaymentMethod) {
        try {
          await dispatch(actions.submitPaymentMethod(updateData, isPatch));
        } catch {
          dispatch(actions.paymentMethodsUpdating(false));
          // We want to stop the process if we fail to update the default payment method
          throw clientSideErrorMessages.changeDefaultPaymentMethod.defaultError;
        }
      }

      if (hasPaymentMethodsToDelete) {
        try {
          await dispatch(actions.removePaymentMethods(removeData));
        } catch (id) {
          let clientErrorMessage = "";
          if (id === newDefaultPaymentMethodID) {
            clientErrorMessage = clientSideErrorMessages.removePaymentMethod.defaultError;
          } else {
            clientErrorMessage = clientSideErrorMessages.removePaymentMethod.removeError;
          }

          dispatch(actions.paymentMethodsUpdating(false));
          dispatch(actions.loadPaymentProfiles());

          throw clientErrorMessage;
        }
      }

      dispatch(actions.paymentMethodsUpdating(false));
      dispatch(actions.loadPaymentProfiles());
    };
  },

  loadPaymentProfiles: function () {
    return function (dispatch) {
      dispatch(actions.paymentProfilesIsLoading(true));

      $.get({
        url: "/paymentMethods",
      }).then(
        res => {
          if (res) {
            dispatch(actions.paymentProfilesLoaded(res));
            dispatch(actions.paymentProfilesIsLoading(false));
          }
        },
        err => {
          dispatch(actions.paymentProfilesLoadedError(err));
          dispatch(actions.paymentProfilesIsLoading(false));
        }
      );
    };
  },
};

export default actions;

export const {
  clearFields,
  setExistingValues,
  updateElement,
  billingStepSubmitting,
  submitBillingStepSuccess,
  submitBillingStepFailure,
  paymentProfilesIsLoading,
  paymentProfilesLoaded,
  paymentProfilesLoadedError,
  paymentMethodsDeleteRequest,
  paymentMethodsDeleteSuccess,
  paymentMethodsDeleteFailure,
  paymentMethodsUpdating,
  submitPaymentMethodWithTokenForStandardCheckout,
  submitPaymentMethodWithToken,
  submitPaymentMethod,
  removePaymentMethods,
  updateDefaultAndRemovePaymentMethods,
  loadPaymentProfiles,
  destroyEProtectHooks,
} = actions;
