// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.

import { createContext, useContext } from "react";

const LazyImageContext = createContext(false);

export const LazyImageProvider = LazyImageContext.Provider;

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useLazyImageContext() {
  return useContext(LazyImageContext);
}

export default LazyImageContext;
