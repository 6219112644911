// Error codes from sessions controller and users service
const APPLE_SIGN_IN_ERROR = "apple-sign-in-error";
const CHALLENGED = "challenged";
const UNEXPECTED_ERROR = "unexpected-error";

// These messages correspond to the unique error code passed from sessions controller and users service
const errorCodeToMessageMap = {
  default: "Whoops! That didn’t work. Please try again.",
  [APPLE_SIGN_IN_ERROR]: "There was an error signing in with Apple. Please try again.",
  [UNEXPECTED_ERROR]: "Something went wrong. Please try again later.",
};

export const errorCodes = Object.freeze({
  APPLE_SIGN_IN_ERROR,
  CHALLENGED,
  UNEXPECTED_ERROR,
});

export const errorMessages = Object.freeze(errorCodeToMessageMap);

/**
 * Determines if the given error code from sessions controller and users service has an associated error message
 * @param {string} errorCode - The unique error code from the server
 * @returns {boolean}
 */
export function hasAssociatedErrorMessage(errorCode) {
  return !!errorMessages[errorCode];
}

/**
 * Gets the client error message for the given error code from sessions controller and users service
 * @param {string} errorCode - The unique error code from the server
 * @returns {string} - returns the corresponding client error message for the unique error code or the default message
 */
export function getErrorMessage(errorCode) {
  if (hasAssociatedErrorMessage(errorCode)) {
    return errorMessages[errorCode];
  }

  return errorMessages.default;
}
