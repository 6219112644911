import { dateFnsFormats } from "rtr-constants";
import {
  parseISO as dateFnsParseISO,
  isDate,
  isValid,
  differenceInCalendarYears,
  format,
  set,
  addHours,
  fromUnixTime,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";

export function isDateValid(date) {
  try {
    if (!date) return false;
    if (!Number.isSafeInteger(date) && isNaN(Date.parse(date))) return false;
    return isValid(new Date(date));
  } catch {
    return false;
  }
}

export function parseUtcDateForFormatting(d) {
  return toZonedTime(d, "UTC");
}

// parseISO from date-fns can subtract a day from the date if a time is also passed
// ex: dateFns.parseISO("2023-12-03T00:00:00.000Z") => 2023-12-02T19:00:00.000Z
// this is because it assumes the date is in UTC and converts it to local time
// Most of our date functions don't need a time so this is fine for those cases
export function parseISOWithoutTime(date) {
  if (!date) return new Date();
  if (isDate(date)) return date;
  const regex = /\d{4}-\d{2}-\d{2}/; // regex for YYYY-MM-DD
  if (typeof date === "string" && date.match(regex)) {
    return dateFnsParseISO(date.split("T")[0]);
  }
  return new Date(date);
}

export function formatTruncatedDate(date, shortenDay) {
  if (!date) return "";
  const dateObj = parseISOWithoutTime(date);
  if (dateObj.getMonth() === 4) {
    if (shortenDay) {
      return formatDateMonthDayCondensed(dateObj);
    }
    return format(dateObj, dateFnsFormats.day);
  }

  if (shortenDay) {
    return format(dateObj, dateFnsFormats.shortMonthAndShortDayWithPeriod);
  }
  return format(dateObj, dateFnsFormats.shortMonthAndDayWithPeriod);
}

export function isoToYyyyMmDd(date) {
  if (!date) return date;
  const dateObj = parseISOWithoutTime(date);
  if (!isValid(dateObj)) return date;
  return format(dateObj, dateFnsFormats.YYYY_MM_DD);
}

export function getAge(date) {
  if (!date) return;
  const birthDate = parseISOWithoutTime(date);
  return differenceInCalendarYears(new Date(), birthDate);
}

/**
 * Formats a date notated as a `"YYYY-MM-DD"` string to the given string format.
 * @TODO: replace this to use `date-fns` once it's available.
 *
 * @param {string} date The date as a `"YYYY-MM-DD"` string.
 * @param {string} formatStr The format to return the date in.
 * @returns string
 */
export function formatYYYYMMDD(date, formatStr) {
  if (typeof date !== "string") {
    return null;
  }

  const match = date.match(/^(\d{4})-(\d{2})-(\d{2})$/);

  if (!match) {
    return null;
  }

  return format(new Date(match[1], match[2] - 1, match[3]), formatStr); // JS zero-indexes months (but not days) for some reason
}

/**
 * Formats a date notated in seconds to the given string format in within UTC.
 *
 * @param {string} date The date given in seconds
 * @param {string} desiredFormat The format to return the date in.
 * @returns string
 */
export function formatUTCSeconds(date, desiredFormat) {
  if (typeof date !== "number" || !desiredFormat) {
    return null;
  }
  const parsedDate = parseUtcDateForFormatting(date * 1000);

  return format(parsedDate, desiredFormat);
}

/**
 * Formats a date notated as a `"YYYY-MM-DD"` string to "Month DDth".
 *
 * @param {string} date The date as a `"YYYY-MM-DD"` string.
 * @returns string
 */
export function formatDateMonthDay(date) {
  if (!date) return "";
  return format(parseISOWithoutTime(date), dateFnsFormats.day);
}

/**
 * Formats a date notated as a `"YYYY-MM-DD"` string to "Month DD".
 *
 * @param {string} date The date as a `"YYYY-MM-DD"` string.
 * @returns string
 */
export function formatDateFullMonthDay(date) {
  if (!date) return "";
  return format(parseISOWithoutTime(date), dateFnsFormats.monthDay);
}

/**
 * Formats a date notated as a `"YYYY-MM-DD"` string to "Month DD".
 *
 * @param {string} date The date as a `"YYYY-MM-DD"` string.
 * @returns string
 */
export function formatDateMonthDayCondensed(date) {
  if (!date) return "";
  return format(parseISOWithoutTime(date), dateFnsFormats.shortMonthAndDay);
}

/**
 * Formats a time noted as "00:00:00" string to "00AM/PM".
 *
 * @param {string} time The time as a `00:00:00"` string.
 * @param {boolean} roundUp boolean that controls if we round up the hour
 * @returns string
 */
export function formatTimeCondensedHours(time, roundUp) {
  if (!time) return "";
  const [hours, minutes, seconds] = time.split(":").map(t => parseInt(t, 10));
  let dateAtTime = set(new Date(), { hours, minutes, seconds });
  if (roundUp && minutes > 0) {
    dateAtTime = addHours(dateAtTime, 1);
  }

  return format(dateAtTime, dateFnsFormats.hourCondensed);
}

/**
 * Formats a date notated as `"YYYY-MM-DD"` string to the first 3 letters of the day
 *
 * @param {string} date The date as a `"YYYY-MM-DD"` string.
 * @returns string
 */
export function returnDayShortened(date) {
  return format(parseISOWithoutTime(date), dateFnsFormats.dayOfWeekShort);
}

/**
 * Returns a JS date object that is always the same YYYY-MM-DD agnostic of timezone.
 * Works for dates returned from the backend that don't include a time or timezone.
 * e.g. input => new Date("2023-26-01") returns => 2023-26-01T00:00:00 regards of TZ.
 */
export function getLocalDate(date) {
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
}

/**
 * In some cases, the backend returns dates (i.e. rent start) as Unix timestamps marking midnight eastern time.
 * This ensures that if they are formatted to a date string, we are consistent across timezones.
 * @param {*} unixTimestamp
 * @param {*} format
 * @returns
 */
export const safeFormatUnixDate = (unixTimestamp, dateFormat) => {
  return format(getLocalDate(fromUnixTime(unixTimestamp)), dateFormat);
};

/**
 * Formats a day number noted as "YYYY-MM-DD" string to "D".
 *
 * @param {string} date The date as a `"YYYY-MM-DD"` string.
 * @returns string
 */
export function returnDateShortened(date) {
  return format(parseISOWithoutTime(date), dateFnsFormats.dayOfMonth);
}

/**
 * We often use the form MM / DD / YYYY (with spaces) in form inputs, where the format is enforced by the UI.
 * While date-fns can parse it, it requires the (fairly large) parse() method, so let's just do it manually.
 * @param {string} dateString
 */
export const parseFormDate = dateString => {
  const [month, date, year] = dateString.split(" / ");
  return parseISOWithoutTime(`${year}-${month}-${date}`);
};

export function addYearsToDate(date = new Date(), years = 0) {
  date.setFullYear(date.getFullYear() + years);
  return date;
}

// Initialise date options
export function getDateOptions(intialDateOptions) {
  // Create a new Date object for today
  const startDateObj = new Date();

  // Calculate the end date, 120 days ahead
  const endDateObj = new Date();
  endDateObj.setDate(startDateObj.getDate() + 120);

  // Format the start date and end date
  const dateStart = format(startDateObj, "yyyy-MM-dd");
  const dateEnd = format(endDateObj, "yyyy-MM-dd");

  return { ...intialDateOptions, dateStart, dateEnd };
}

export default {
  addYearsToDate,
  isDateValid,
  formatTruncatedDate,
  formatYYYYMMDD,
  formatUTCSeconds,
  getDateOptions,
  parseUtcDateForFormatting,
};
