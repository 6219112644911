import { constants } from "../actions/user-problems-actions";
import { modalTypes } from "helpers/swaps-helper";

function modifyUserProblems(state, action) {
  const nextState = state ? { ...state } : {};
  switch (action.type) {
    case constants.SWAPS_FETCH_CMS_SUCCESS: {
      let selector = nextState.problemGroup.orderType.toLowerCase() || "rental";
      if (nextState.problemGroup.newGroup) {
        selector = "newGroup";
      }
      nextState.swapsContent = action.payload[selector];
      break;
    }
    case constants.SWAPS_INITIAL_GRID_LOADING:
      nextState.initialGridLoading = action.payload;
      break;
    case constants.SWAPS_FETCH_USER_PROBLEM_GROUP_BEGIN:
      nextState.replacementsLoading = true;
      nextState.problemGroupLoading = true;
      break;
    case constants.SWAPS_FETCH_USER_PROBLEM_GROUP_SUCCESS:
      nextState.problemGroupLoading = false;
      nextState.problemGroup = action.payload;
      break;
    case constants.SWAPS_TOGGLE_BANNER_EXPANSION:
      nextState.expanded = action.payload;
      break;
    case constants.SWAPS_TOGGLE_BAG_EXPANSION:
      nextState.bagExpansion = action.payload;
      break;
    case constants.SWAPS_SET_BASE_GRID:
      nextState.userFilters = action.payload.baseFilter;
      nextState.gridPage = 1;
      nextState.categoryMap = action.payload.baseCategories;
      nextState.filterExpansion = {};
      nextState.mobileFilterExpansion = false;
      nextState.productsLoading = true;
      nextState.replacements = state?.replacements || [];
      break;
    case constants.SWAPS_REMOVE_SIMILAR_FILTER:
      if (nextState?.userFilters?.filter?.productsSimilarTo) {
        delete nextState.userFilters.filter.productsSimilarTo;
      }
      break;
    case constants.SWAPS_TOGGLE_FILTER_EXPANSION:
      nextState.filterExpansion[action.payload.id] = action.payload.state;
      break;
    case constants.SWAPS_TOGGLE_MOBILE_FILTER_EXPANSION:
      nextState.mobileFilterExpansion = action.payload;
      break;
    case constants.SWAPS_FETCH_PRODUCTS_BEGIN:
      nextState.productsLoading = true;
      nextState.resultsCount = null; // This is to avoid confusion when changing categories
      break;
    case constants.SWAPS_FETCH_PRODUCTS_SUCCESS:
      nextState.products = action.payload.products;
      if (nextState.products.length > 0) {
        const { canonicalSizes, ...gridFilters } = action.payload.gridFilters ?? {};
        nextState.gridFilters = gridFilters;
      }
      nextState.resultsCount = action.payload.resultsCount;
      nextState.productsLoading = false;
      nextState.productLoadError = null;
      break;
    case constants.SWAPS_FETCH_PRODUCTS_FAILURE:
      nextState.productsLoading = false;
      nextState.productLoadError = action.payload;
      break;
    case constants.SWAPS_SET_MAIN_CATEGORY: {
      nextState.mainCategory = action.payload.category;
      const similarId = action.payload.similar;
      nextState.similarStyle = state?.problemGroup?.problemBookings?.find?.(x => x?.styleName === similarId);
      break;
    }
    case constants.SWAPS_SET_FILTER:
      nextState.userFilters = action.payload;
      break;
    case constants.SWAPS_SET_GRID_PAGE:
      nextState.gridPage = action.payload;
      break;
    case constants.SWAPS_TOGGLE_EXIT_MODAL:
      nextState.modal = modalTypes.EXIT;
      break;
    case constants.SWAPS_TOGGLE_ERROR_MODAL:
      nextState.modal = modalTypes.ERROR;
      break;
    case constants.SWAPS_TOGGLE_ADD_TO_BAG_ERROR_MODAL:
      nextState.modal = modalTypes.ADD_TO_BAG_ERROR;
      break;
    case constants.SWAPS_TOGGLE_BTS_MODAL:
      nextState.modal = modalTypes.BTS;
      break;
    case constants.SWAPS_TOGGLE_SUCCESS_MODAL:
      nextState.modal = modalTypes.SUCCESS;
      break;
    case constants.SWAPS_TOGGLE_PAYMENT_MODAL:
      nextState.modal = modalTypes.PAYMENT;
      break;
    case constants.SWAPS_FETCH_REPLACEMENTS_BEGIN:
      nextState.replacementsLoading = true;
      break;
    case constants.SWAPS_FETCH_REPLACEMENTS_SUCCESS:
    case constants.SWAPS_ADD_REPLACEMENT_SUCCESS:
    case constants.SWAPS_REMOVE_REPLACEMENT_SUCCESS:
      nextState.replacementsLoading = false;
      nextState.replacements = action.payload;
      break;
    case constants.SWAPS_PERFORM_SWAP_BEGIN:
      nextState.performingSwap = true;
      break;
    case constants.SWAPS_PERFORM_SWAP_SUCCESS:
    case constants.SWAPS_PERFORM_SWAP_FAILURE:
      nextState.performingSwap = false;
      break;
    case constants.EXCHANGE_CREATE_BAG_BEGIN:
      nextState.loadingBag = true;
      break;
    case constants.EXCHANGE_CREATE_BAG_SUCCESS:
    case constants.EXCHANGE_CREATE_BAG_FAILURE:
      nextState.loadingBag = false;
      break;
  }
  return { ...nextState };
}

const fn = (state, action) => {
  return {
    ...state,
    userProblems: modifyUserProblems(state.userProblems, action),
  };
};

const getTypes = () => (constants ? Object.keys(constants) : []);

export default {
  types: getTypes(),
  fn,
};
