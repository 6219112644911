import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApplePayButton from "components/source/common/apple-pay-button/ApplePayButton";
// eslint-disable-next-line no-restricted-imports
import Script from "next/script";

import { format } from "date-fns";
import { parseISOWithoutTime } from "helpers/date-helpers";
import { ApplePayConstants, iframeActions, iframeRemoteKeys, dateFnsFormats } from "rtr-constants";

import { requestApplePaySession } from "actions/apple-pay-actions";

import { getApplePayMerchantIdentifier } from "helpers/checkout-helpers";
import { createValidationDataObject } from "helpers/apple-pay-payment-helpers";
import { priceStringIntoFloat, isZeroAmount } from "helpers/invoice-helper";
import { applePayLineItemsForMembershipPropTypes } from "components/propTypes/apple-pay-lineitem-prop-types";

/**
 * Wrapper for membership updates using Apple Pay Button
 * Can handle different membership up/downgrade actions: Immediate shipment upgrade, Future tier changes
 * Does not depend on checkout
 * Does not depend on bag
 * Does not require shipping
 */
const MembershipUpdateApplePayWrapper = ({ getLineItems, onSuccess, applePayStyle, nextBillingDate }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state?.userData?.userProfile);
  const secureApplePayEntryRef = useRef(null);

  useEffect(() => {
    if (window.iframeMessenger && secureApplePayEntryRef.current?.contentWindow) {
      window.iframeMessenger.registerRemote(
        iframeRemoteKeys.SECURE_APPLE_PAY,
        secureApplePayEntryRef.current.contentWindow
      );
    }
  }, [secureApplePayEntryRef.current]);

  const performValidation = (url, merchantIdentifier, displayName, domain) => {
    const data = createValidationDataObject(url, merchantIdentifier, displayName, domain);
    return dispatch(requestApplePaySession(data, true));
  };

  const getOnValidateMerchant = session => {
    return event => {
      const merchantIdentifier = getApplePayMerchantIdentifier();
      performValidation(
        event.validationURL,
        merchantIdentifier,
        ApplePayConstants.merchantDisplay,
        window.location.hostname
      )
        .then(response => {
          session.completeMerchantValidation(response);
        })
        .catch(() => {
          session.abort();
        });
    };
  };

  const performApplePayDebit = payment => {
    const { billingContact, token } = payment;
    const { paymentData } = token;

    return new Promise(function (resolve, reject) {
      if (billingContact.countryCode !== ApplePayConstants.validCountryCode)
        return reject(ApplePayConstants.APPLE_PAY_ERRORS.BILLING_CONTACT_INVALID);

      // add handlers for success/fail. These events will be called in eprotect-helper.js
      window.iframeMessenger.addHandler(
        iframeRemoteKeys.PARENT,
        iframeActions.ON_SAVE_APPLE_PAY_PAYMENT_METHOD_SUCCESS,
        (action, payload) => {
          return resolve(payload);
        }
      );
      window.iframeMessenger.addHandler(
        iframeRemoteKeys.PARENT,
        iframeActions.ON_SAVE_APPLE_PAY_PAYMENT_METHOD_FAILURE,
        (action, payload) => {
          return reject(payload);
        }
      );

      window.iframeMessenger.send(
        iframeRemoteKeys.SECURE_APPLE_PAY,
        iframeActions.SAVE_APPLE_PAY_PAYMENT_TOKEN_USING_EPROTECT,
        {
          orderId: `VO-${user?.id}`, // not available
          id: `VID-${user?.id}`, // not available
          PKPaymentToken: paymentData,
          paymentMethodUrl: "/paymentMethods/applePay", // using Next
          billingPayloadValues: {
            phone: user?.cellPhoneNumber,
            city: billingContact.locality,
            firstName: billingContact.givenName,
            lastName: billingContact.familyName,
            postalCode: billingContact.postalCode,
            street1: billingContact.addressLines[0],
            street2: billingContact.addressLines[1] || "",
            zoneCode: billingContact.administrativeArea,
            userId: user?.id,
            default: false,
            recurring: true, // will always update the recurring method
          },
        }
      );
    });
  };

  /**
   * Function that fires when the user authorizes the Apple Pay payment
   * (so, once the user successfully authenticates with TouchID / FaceID)
   *
   * @param {*} resolve
   * @param {*} reject
   * @param {*} session
   * @returns
   */
  const getOnPaymentAuthorized = (resolve, reject, session) => {
    return event => {
      return performApplePayDebit(event.payment)
        .then(() => {
          session.completePayment({
            status: window.ApplePaySession.STATUS_SUCCESS,
          });
          onSuccess();
          return resolve(ApplePayConstants.PaymentStatus.SUCCESS);
        })
        .catch(e => {
          const result = { status: window.ApplePaySession.STATUS_FAILURE };
          session.completePayment(result);
          return reject(e);
        });
    };
  };

  const onCancelSession = resolve => {
    return () => {
      resolve(ApplePayConstants.PaymentStatus.CANCEL);
    };
  };

  const formatLineItems = () => {
    return getLineItems()
      ?.filter(item => !isZeroAmount(item.amount) && item.type !== "total")
      .map(nonZeroItem => {
        const lineItem = { label: nonZeroItem.label, amount: priceStringIntoFloat(nonZeroItem.amount) };
        if (nonZeroItem.type === "deferred") {
          lineItem.paymentTiming = nonZeroItem.type;
          lineItem.deferredPaymentDate = nextBillingDate;
        }
        return lineItem;
      });
  };

  const getTotalAmount = () => {
    return priceStringIntoFloat(getLineItems()?.find(item => item.type === "total").amount);
  };

  const formatRequestObject = () => {
    const payLabel = nextBillingDate
      ? `amount due on ${format(parseISOWithoutTime(nextBillingDate), dateFnsFormats.day)} (excluding tax)`
      : ApplePayConstants.merchantDisplay;
    return {
      currencyCode: "USD",
      countryCode: "US",
      lineItems: formatLineItems(),
      total: {
        label: payLabel,
        amount: getTotalAmount(),
      },
      merchantCapabilities: ApplePayConstants.merchantCapabilities,
      supportedNetworks: ApplePayConstants.supportedNetworks,
      requiredBillingContactFields: ["postalAddress"],
    };
  };

  const handleApplePaySession = () => {
    if (window.ApplePaySession?.canMakePayments()) {
      const { ApplePaySession } = window;

      return new Promise((resolve, reject) => {
        const requestObject = formatRequestObject();

        const session = new ApplePaySession(ApplePayConstants.VERSION_NUMBER, requestObject);

        session.onvalidatemerchant = getOnValidateMerchant(session);
        session.onpaymentauthorized = getOnPaymentAuthorized(resolve, reject, session);

        session.oncancel = onCancelSession(resolve);

        session.begin();
      });
    }
  };

  return (
    <>
      <Script src="https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js" />
      <iframe title="secure-apple-pay-entry" src="/secureApplePayEntry" ref={secureApplePayEntryRef} hidden />
      <ApplePayButton handleApplePaySession={handleApplePaySession} buttonStyle={applePayStyle} />
    </>
  );
};
MembershipUpdateApplePayWrapper.propTypes = applePayLineItemsForMembershipPropTypes;

export default MembershipUpdateApplePayWrapper;
