import React from "react";
import { Provider } from "react-redux";
import { useStore } from "../util/store";
import PropTypes from "prop-types";
import App from "next/app";

import { configureClientSide } from "config/godmother";
import Head from "components/layout/Head";
import { isSSR } from "helpers/client-server-helper";
import DefaultLayout from "components/layout/DefaultLayout";
import { BrowserProvider } from "components/layout/BrowserContext";
import { CookieProvider } from "components/layout/CookieContext";
import AppInitialization from "components/layout/AppInitialization";
import { RtrSessionProvider } from "components/layout/RtrSessionContext";
import openTelemetry from "@opentelemetry/api";

import analyticsClient from "analytics/analytics-client";
import { sendGlobalInferLog } from "analytics/global-infer-log";
import { appendToVaryHeader, getExperimentAllocationsFromRequestHeaders } from "helpers/vary-header-helpers";
import { incrementPagesViewed } from "../assets/javascripts/helpers/branch-banner-helpers";
import { cleanUpLocalStorageOnLogout } from "../assets/javascripts/helpers/logout-helpers";
import AuthActions from "../assets/javascripts/actions/auth-actions";
import GTMHelper from "helpers/gtm";
import { getEnvironmentName } from "helpers/environment-helpers";
import { initSailthru } from "../assets/javascripts/helpers/third-party/sailthru-helpers";
import { selectFeatureFlags } from "../assets/javascripts/selectors/featureFlagSelectors";
import AppObserver from "../next/app-observer";
import NextBanner from "../assets/javascripts/components/source/next/banner";
import ContentfulBanner from "components/source/contentful/banner/index";
// Used for communicating with the credit card form, using eProtect, that's loaded via an iframe.
import iframeMessenger from "helpers/iframe-messenger";
import { registerStorageListenerForAuthChanges } from "../assets/javascripts/helpers/auth-helpers";
import { sanitize } from "../assets/javascripts/helpers/sanitize";
import { registerPerformanceMonitoring } from "../assets/javascripts/analytics/performance-monitoring";
import HTTP_HEADERS from "../assets/javascripts/constants/http-headers";

// Setup
// delete these once downstream code gets its data from the appropriate Provider

// From feature_flags.erb
// NOTE: the flags are normally templated in to the `flags` variable below
global.RTR = global.RTR || {};
///// delete everything above asap! /////

const defaultInitialReduxState = {
  appContextEnvironment: sanitize(process.env.RACK_ENV, { ALLOWED_TAGS: ["#text"] }),
  displayedModal: false,
  persistedFilters: {},
  config: {},
  isLocal: process.env.RACK_ENV === "development",
  membershipState: {},
  oneTap: {
    clientId: JSON.stringify(sanitize(process.env.ONETAP_CLIENT_ID, { ALLOWED_TAGS: ["#text"] })),
  },
  publicEnv: {
    branchKey: sanitize(process.env.BRANCHIO_BRANCHKEY, { ALLOWED_TAGS: ["#text"] }),
    castlePk: sanitize(process.env.CASTLE_IO_PUBLISHED_KEY, { ALLOWED_TAGS: ["#text"] }),
    cdnHost: sanitize(process.env.NEXT_PUBLIC_CDN_HOST, { ALLOWED_TAGS: ["#text"] }),
    cdnImageHost: sanitize(process.env.NEXT_PUBLIC_CDN_IMAGE_HOST, { ALLOWED_TAGS: ["text"] }),
    facebookAppId: sanitize(process.env.FACEBOOK_APP_ID, { ALLOWED_TAGS: ["#text"] }),
    fbPagesId: sanitize(process.env.NEXT_PUBLIC_FB_PAGES_ID, { ALLOWED_TAGS: ["#text"] }),
    heapAppId: sanitize(process.env.HEAP_APP_ID, { ALLOWED_TAGS: ["#text"] }),
    mobileIphoneAppId: sanitize(process.env.NEXT_PUBLIC_MOBILE_IPHONE_APP_ID, { ALLOWED_TAGS: ["#text"] }),
    oauthFacebookAppId: sanitize(process.env.NEXT_PUBLIC_OAUTH_FACEBOOK_APP_ID, { ALLOWED_TAGS: ["#text"] }),
    onetrustDataDomainId: sanitize(process.env.ONETRUST_DATA_DOMAIN_ID, { ALLOWED_TAGS: ["#text"] }),
    sailthruCustomerId: sanitize(process.env.SAILTHRU_CUSTOMER_ID, { ALLOWED_TAGS: ["#text"] }),
    signifydSessionPrefix: sanitize(process.env.SIGNIFYD_SESSION_PREFIX, { ALLOWED_TAGS: ["#text"] }),
    tatariKey: sanitize(process.env.TATARI_KEY, { ALLOWED_TAGS: ["#text"] }),
    gateWayURL: sanitize(process.env.CAG_PUBLIC_HOST, { ALLOWED_TAGS: ["#text"] }),
    convergePublicToken: sanitize(process.env.NEXT_PUBLIC_CONVERGE_PUBLIC_TOKEN, { ALLOWED_TAGS: ["#text"] }),
    fbPixelId: sanitize(process.env.NEXT_PUBLIC_CONVERGE_FACEBOOK_PIXEL_ID, { ALLOWED_TAGS: ["#text"] }),
    fbPixelConnectionId: sanitize(process.env.NEXT_PUBLIC_CONVERGE_FACEBOOK_CONNECTION_ID, { ALLOWED_TAGS: ["#text"] }),
    ga4MeasurementId: sanitize(process.env.NEXT_PUBLIC_CONVERGE_GA4_MEASUREMENT_ID, { ALLOWED_TAGS: ["#text"] }),
    ga4ConnectionId: sanitize(process.env.NEXT_PUBLIC_CONVERGE_GA4_CONNECTION_ID, { ALLOWED_TAGS: ["#text"] }),
    tiktokPixelCode: sanitize(process.env.NEXT_PUBLIC_CONVERGE_TIKTOK_PIXEL_CODE, { ALLOWED_TAGS: ["#text"] }),
    tiktokConnectionId: sanitize(process.env.NEXT_PUBLIC_CONVERGE_TIKTOK_CONNECTION_ID, { ALLOWED_TAGS: ["#text"] }),
    pinterestTagId: sanitize(process.env.NEXT_PUBLIC_CONVERGE_PINTEREST_TAG_ID, { ALLOWED_TAGS: ["#text"] }),
    pinterestConnectionId: sanitize(process.env.NEXT_PUBLIC_CONVERGE_PINTEREST_CONNECTION_ID, {
      ALLOWED_TAGS: ["#text"],
    }),
    helpCenter: {
      gladlyAppId: sanitize(process.env.GLADLY_APP_ID, { ALLOWED_TAGS: ["#text"] }),
      gladlyApiBaseUrl: sanitize(process.env.GLADLY_API_BASE_URL, { ALLOWED_TAGS: ["#text"] }),
      gladlyCdnUrl: sanitize(process.env.GLADLY_CDN_URL, { ALLOWED_TAGS: ["#text"] }),
      gladlyOrgId: sanitize(process.env.GLADLY_ORG_ID, { ALLOWED_TAGS: ["#text"] }),
      gladlyBrandId: sanitize(process.env.GLADLY_BRAND_ID, { ALLOWED_TAGS: ["#text"] }),
    },
    roktAccountId: sanitize(process.env.NEXT_PUBLIC_ROKT_ACCOUNT_ID, { ALLOWED_TAGS: ["#text"] }),
  },
};

// there's debate about whether App should have any providers at all (since) it
// only renders a Component and a Layout, but in an effort to avoid forgetting
// a non-obvious provider, like RtrSession, which is important for logging, we
// use this set.
const AppProviders = [BrowserProvider, CookieProvider, RtrSessionProvider];

// combines the Providers required by the App, globally, the Layout, and the
// Component. This gives developers full control of what is fetched on each Page
function getProviders(Layout, Component) {
  const LayoutProviders = Layout.getProviders?.() || [];
  const ComponentProviders = Component.getProviders?.() || [];

  const combinedProviders = [...AppProviders, ...LayoutProviders, ...ComponentProviders];

  return [...new Set(combinedProviders)];
}

const noop = () => Promise.resolve();

function getCdnEmbeds() {
  if (isSSR()) {
    // will be added when the page is served by the CDN
    return {};
  } else {
    const data = document.getElementById("cdn-script");
    return data ? JSON.parse(data.innerText) : {};
  }
}

// exported for testing coverage only - Next.js looks for this as a property on the exported functional component
export async function getInitialProps(context) {
  const { Component, ctx } = context;

  if (ctx.req) {
    // pass reference to Component in order to be used in _document.jsx
    ctx.req.Component = Component;
  }

  // consider removing the default... if a Page were to provide its own Layout,
  // we might be serving more JavaScript than needed by importing DefaultLayout
  const Layout = Component.getLayout?.({}) || DefaultLayout;
  const Providers = getProviders(Layout, Component);

  // parallelize the data requests for this page
  const [appProps, ...providerRequests] = await Promise.allSettled([
    App.getInitialProps(context),
    ...Providers.map(P => P.getInitialReduxState || noop).map(f => f(context)),
  ]);

  const { value: nextJsAppProps } = appProps;

  // Vary on device type (mobile vs desktop vs tablet)
  // X-RTR-Device-Type is set by the CDN
  // Note: app level getInitialProps should only run on the server but
  // somehow we see occaisional errors popping up client side trying to run
  // this function in the browser 🤷
  if (isSSR()) {
    appendToVaryHeader(context.ctx.res, HTTP_HEADERS.X_RTR_DEVICE_TYPE);
  }
  // experiment allocations passed from Compute to SF
  const experimentsFromRequestHeaders = {
    flagsAndExperiments: getExperimentAllocationsFromRequestHeaders(context.ctx.req),
  };

  const initialReduxState = providerRequests.reduce(
    (memo, result) => {
      return {
        ...memo,
        ...result.value,
      };
    },
    {
      ...experimentsFromRequestHeaders,
      ...defaultInitialReduxState,
    }
  );

  return {
    initialReduxState,
    // helmet: Helmet.renderStatic(),
    ...nextJsAppProps,
  };
}

function displayLightRegModal(store, event) {
  const { followQueryParams, source, subtitle, heapTriggeredBy, view, href } = event;

  let callback;
  if (followQueryParams) {
    callback = queryParam => {
      let newLoc;
      const qParam = queryParam.replace(/^\?/, "");
      if (href.indexOf("?") !== -1) {
        newLoc = href.slice(0, href.indexOf("?") + 1) + qParam + "&" + href.slice(href.indexOf("?") + 1);
      } else {
        newLoc = href + "?" + qParam;
      }
      window.location.href = newLoc;
    };
  }

  store.dispatch(AuthActions.showAuthModal({ callback, source, subtitle, view, triggeredBy: heapTriggeredBy }));
}

export default function RentTheRunwayApp(appProps) {
  const { Component, initialReduxState, pageProps, router } = appProps;
  // Use PageType and PageName as part of the initial store state, as there are components that try to access it more-or-less immediately
  const { pageType: componentPageType, pageName: componentPageName } = Component;
  let pageType, pageName;
  if (typeof componentPageType === "function") {
    pageType = componentPageType(router.asPath);
  } else {
    pageType = componentPageType;
  }

  if (typeof componentPageName === "function") {
    pageName = componentPageName(router.asPath);
  } else {
    pageName = componentPageName;
  }

  const cdnData = getCdnEmbeds();
  const store = useStore({
    ...{ pageName, pageType },
    ...initialReduxState,
    ...pageProps.initialReduxState,
    ...cdnData,
  });

  // Core, set for code that still depends on the global value
  if (isSSR()) {
    AppObserver(appProps);
    global.page_type = pageType;
  } else {
    window.page_type = pageType;
    window.globalStoreOnlyToUseForDebugging = store;

    // TODO: only instantiate on pages that actually uses it
    // BEFORE REMOVING THIS, make sure that it's instantiated on assets/javascripts/components/source/checkout/standard-checkout/index.jsx
    window.iframeMessenger = iframeMessenger;

    analyticsClient.set("pageType", pageType);
    registerStorageListenerForAuthChanges();
    window.addEventListener("siteHeaderRegModalEvent", event => displayLightRegModal(store, event));

    import("site/header"); // required for random query param handling, needs to be after the above event handler
    import("vendor/converge/main");

    GTMHelper.pushToDataLayer({ rtrEnvironment: getEnvironmentName() });
    GTMHelper.pushToDataLayer({ rtrPageType: pageType });

    initSailthru(selectFeatureFlags(store.getState()));
    sendGlobalInferLog();
    incrementPagesViewed();
    cleanUpLocalStorageOnLogout();
    registerPerformanceMonitoring();
  }

  // Use the layout defined at the page level, if available
  const Layout = Component.getLayout?.(pageProps) || DefaultLayout;
  const Providers = getProviders(Layout, Component);

  const componentHeadData = Component.getHeadData ? Component.getHeadData(pageProps, router.asPath) : {};

  const componentWithLayout = (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Layout {...(Component?.layoutProps || {})}>
      <Head componentHeadData={componentHeadData} disableAnalytics={Component.disableAnalytics} />
      {!Component?.hideNextBanner && <NextBanner />}
      {!Component?.hideNextBanner && <ContentfulBanner />}
      <AppInitialization pageType={pageType} pageName={pageName} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
    </Layout>
  );

  // wrap the Layout/Component in all requested providers
  const pageContent = Providers.reverse().reduce((c, P) => <P>{c}</P>, componentWithLayout);

  // wrap it all up in the Redux Store
  return <Provider store={store}>{pageContent}</Provider>;
}

RentTheRunwayApp.getInitialProps = isSSR()
  ? async ctx =>
      openTelemetry.trace.getTracer("RentTheRunwayApp").startActiveSpan("getInitialProps", async span => {
        const result = await getInitialProps(ctx);
        span.end();
        return result;
      })
  : getInitialProps;

RentTheRunwayApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]),
  initialReduxState: PropTypes.object,
  pageProps: PropTypes.object,
};

export function reportWebVitals(metric) {
  if (metric.label === "custom" && window.SplunkRum) {
    const tracer = openTelemetry.trace.getTracer();
    const now = Date.now();
    let span;
    if (typeof metric.value === "number") {
      span = tracer.startSpan(metric.name, {
        startTime: now - metric.value,
        attributes: {
          "workflow.name": metric.name,
          ...metric,
        },
      });
    } else {
      span = tracer.startSpan(metric.name, {
        attributes: {
          "workflow.name": metric.name,
          ...metric,
        },
      });
    }
    span.end(now);
  }
}

// doing this here instead of with useEffect to be sure it is set up before any
// component tries to use Godmother.
if (isSSR()) {
  import("helpers/server-side-only/godmother-server-side").then(config => config.configureServerSide());
} else {
  configureClientSide();
}
