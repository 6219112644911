export const hexSha256Hash = async str => {
  if (!str || typeof window?.crypto?.subtle?.digest !== "function" || typeof window?.TextEncoder !== "function") {
    return;
  }

  const encodedString = new window.TextEncoder().encode(str);
  const digest = await window.crypto.subtle.digest("SHA-256", encodedString);
  const hashArray = Array.from(new Uint8Array(digest)); // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
  return hashHex;
};
