/**
 * A function to ensure an array of bookingIds for items is returned.
 * A bookingId is the id of an item.
 * If a single number is given, it is wrapped in an array.
 * Else, throw an error.
 *
 * @param {Number|Number[]} bookingIds
 * @return {Number[]} bookingIds
 */
const validateItemBookingIds = bookingIds => {
  const isArray = Array.isArray(bookingIds);
  const isValidArray = isArray && bookingIds.every(id => !Number.isNaN(Number(id)));
  const isInputInvalid = !bookingIds || (!isArray && Number.isNaN(Number(bookingIds))) || (isArray && !isValidArray);

  if (isInputInvalid) {
    throw new Error("Invalid input.");
  }

  return isArray ? bookingIds : [bookingIds];
};

export default {
  validateItemBookingIds,
};
