import _ from "underscore";
import ActionTypes from "actions/action-types.js";
var types, fn;

types = [
  ActionTypes.UPDATE_RESERVATION,
  ActionTypes.UPDATE_RESERVATION_ERRORS,
  ActionTypes.UPDATE_INCLUDE_PRO,
  ActionTypes.CANCEL_RESERVATION_SUBMISSION,
  ActionTypes.SUBMITTING_RESERVATION,
  ActionTypes.RESERVATION_BASE_ERROR,
];

fn = function (state, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_INCLUDE_PRO:
      return _.extend({}, state, { includePro: action.payload });

    case ActionTypes.UPDATE_RESERVATION_ERRORS:
      return _.extend({}, state, { errors: { reservation: action.payload } });

    case ActionTypes.CANCEL_RESERVATION_SUBMISSION:
      // If the server-side post failed or is hanging,
      // let's provide a hook to change the state.

      return _.extend({}, state, { isSubmitting: false });

    case ActionTypes.SUBMITTING_RESERVATION:
      // This is triggered alongside a server-side post
      // but in the meantime let's show a loading button.

      return _.extend({}, state, { isSubmitting: true });

    case ActionTypes.UPDATE_RESERVATION:
      var reservation = _.extend({}, state.reservation, action.payload);
      return _.extend({}, state, { reservation: reservation });

    case ActionTypes.RESERVATION_BASE_ERROR:
      return {
        ...state,
        errors: { base: action.payload },
      };

    default:
      return _.extend({}, state, {
        reservation: _.extend({}, state.reservation, action.payload),
      });
  }
};

export default {
  types: types,
  fn: fn,
};
