import React from "react";
import InputWrapper from "./input-wrapper";
import PropTypes from "prop-types";

class RadioInputPair extends React.Component {
  static propTypes = {
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    id: PropTypes.string,
    inline: PropTypes.bool,
    inputProps: PropTypes.object,
    label: PropTypes.node,
    labelProps: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    wrapperClass: PropTypes.string,
  };

  render() {
    return (
      <div data-test-id="radio-input-pair">
        <InputWrapper
          error={this.props.error}
          id={this.props.id}
          inline={this.props.inline}
          inputBefore={true}
          label={this.props.label}
          labelProps={this.props.labelProps}
          wrapperClass={this.props.wrapperClass}>
          <input
            /* input props passed in from parent */
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...this.props.inputProps}
            checked={this.props.checked}
            defaultChecked={this.props.defaultChecked}
            id={this.props.id}
            data-test-id={this.props.id}
            key="input"
            name={this.props.name}
            onChange={this.props.onChange}
            type="radio"
            disabled={this.props.disabled}
            value={this.props.value}
          />
        </InputWrapper>
        {this.props.description}
      </div>
    );
  }
}

export default RadioInputPair;
