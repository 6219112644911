import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";
import constants, { RENTAL_INTENT, emailCaptureModalCMSContent } from "rtr-constants";
import { SailthruMarketing } from "@rtr/godmother";
import { setWithExpiry } from "./../components/source/navigation/url-param-reader";

const actions = {
  optInSuccess: createAction(ActionTypes.SAILTHRU_OPT_IN_SUCCESS),
  optInFailure: createAction(ActionTypes.SAILTHRU_OPT_IN_FAILURE),
  optInLoading: createAction(ActionTypes.SAILTHRU_OPT_IN_LOADING),
  /**
   * Signs an email up for Sailthru marketing emails regardless of whether they have an account.
   */
  optInEmail: (email, intent, onSuccess) => {
    return dispatch => {
      const emailValidator = constants.validations.email;
      const isValid = email && !!email.match(emailValidator);

      if (!isValid) {
        dispatch(actions.optInFailure({ error: "Please enter a valid email address." }));

        return;
      }

      dispatch(actions.optInLoading());

      let marketingIntent;
      //Only pass intent if a user has indicated membership or reserve
      if ([RENTAL_INTENT.MEMBERSHIP, RENTAL_INTENT.RESERVE].includes(intent)) {
        marketingIntent = intent;
      }

      // add email to session storage here. annonymus_user_email
      setWithExpiry(
        emailCaptureModalCMSContent.SESSION_STORAGE_KEY,
        email,
        600,
        emailCaptureModalCMSContent.SESSION_STORAGE_VALUE
      );

      SailthruMarketing.create({ id: email, marketingIntent })
        .then(payload => {
          dispatch(actions.optInSuccess(payload));

          if (typeof onSuccess === "function") {
            onSuccess();
          }
        })
        .catch(() => {
          dispatch(actions.optInFailure({ error: "Sorry, something went wrong." }));
        });
    };
  },
};

export default actions;

export const { optInEmail } = actions;
