import _ from "underscore";
import ActionLogger from "action-logger";
import { LocalStorage } from "../site/localStorage";
import { isSSR } from "./client-server-helper";

const NONE = null;
const RESERVE = "reserve";
const MEMBERSHIP = "membership";
const KIFFR = "kiffr";

export const RentalOptions = { NONE, RESERVE, MEMBERSHIP, KIFFR };
const RentalOptionValues = Object.values(RentalOptions);

// List of possible actions a user could have taken when a rental intent is set.
// This is used for pixel logging.
export const ActionsTaken = {
  DATE_FILTER_GRID: "date_filter_grid",
  DATE_FILTER_PDP: "date_filter_pdp",
  DATE_FILTER_QUIZ: "date_filter_quiz",
  RESERVE_HOMEPAGE_TAB: "reserve_homepage_tab",
  UNLIMITED_HOMEPAGE_TAB: "unlimited_homepage_tab",
  UPDATE_HOMEPAGE_TAB: "update_homepage_tab",
  HOMEPAGE_RESERVE_ESCAPE_HATCH: "homepage_reserve_escape_hatch",
  RESERVE_ATC: "reserve_atc",
  RESERVE_RADIO: "reserve_radio",
  EDIT_RESERVE_ATC: "edit_reserve_atc",
  KIFFR_RADIO: "kiffr_radio",
  MEMBERSHIP_RADIO: "membership_radio",
  UNLIMITED_PREVIEW_SUPERBAR: "unlimited_preview_superbar",
  UPDATE_PREVIEW_SUPERBAR: "update_preview_superbar",
  RESERVE_SUPERBAR: "reserve_superbar",
  RESERVE_WAYFINDER: "reserve_wayfinder",
  UPDATE_CTA: "rtr_update_cta",
  UNLIMITED_CTA: "rtr_unlimited_cta",
  DRAWER_GRID_UNLIMITED_CTA: "drawer_grid_unlimited_cta",
  DRAWER_GRID_UPDATE_CTA: "drawer_grid_update_cta",
  PDP_UPDATE_CTA: "pdp_update_cta",
  PDP_UNLIMITED_CTA: "pdp_unlimited_cta",
  NO_ACTION: "no_action",
};

const CACHE = {
  NAMESPACE: "rentalOptions",
  INTENT: "lastIntent",
  ACTION: "lastAction",
};

const fireIntentPixel = (intent, actionTaken, objectType) => {
  ActionLogger.logAction({
    object_type: objectType,
    // `action` refers to the `action` for the pixel
    action: "last_intent",
    // When the intent is `null`, the intended value is "none". `null` can't
    // actually be a value for a pixel, so let's use "none" instead.
    membership_intent: intent || "none",
    action_taken: actionTaken,
  });
};

// Sets the last intent, and the action associated with that intent, in local
// storage. Also fires a pixel.
const setLastIntent = (intent, actionTaken = ActionsTaken.NO_ACTION, objectType = "node") => {
  if (isSSR()) return;

  if (!_.contains(RentalOptionValues, intent)) {
    return;
  }
  const localStorageClient = new LocalStorage(CACHE.NAMESPACE);

  localStorageClient.set(CACHE.INTENT, intent);
  localStorageClient.set(CACHE.ACTION, actionTaken);
  fireIntentPixel(intent, actionTaken, objectType);
};

// Returns the last rental option we believe the user wants. All rental options
// are in `RentalOptions`
const getLastIntent = () => {
  if (isSSR()) return;

  const localStorageClient = new LocalStorage(CACHE.NAMESPACE);
  return localStorageClient.get(CACHE.INTENT) || RentalOptions.NONE;
};

// Returns the last action a user took. All the actions we care about are in
// `ActionsTaken`
const getLastAction = () => {
  if (isSSR()) return;

  const localStorageClient = new LocalStorage(CACHE.NAMESPACE);
  return localStorageClient.get(CACHE.ACTION) || ActionsTaken.NO_ACTION;
};

export default {
  setLastIntent,
  getLastIntent,
  getLastAction,
  RentalOptions,
  ActionsTaken,
};
