import { isGridType, savedDateIsValid } from "helpers/filter-helpers";
import { filters } from "rtr-constants";

import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";

import StorageHelperApi from "helpers/storage-helper";

const StorageHelper = StorageHelperApi.getApi("availability", ["unlimitedMinAvailability", "rtrUpdateMinAvailability"]);

var actions = {
  writeHoldPreferencesSuccess: createAction(ActionTypes.WRITE_HOLD_PREFERENCES_SUCCESS),
  fetchHoldPreferencesSuccess: createAction(ActionTypes.FETCH_HOLD_PREFERENCES_SUCCESS),
  clearHoldPreferencesSuccess: createAction(ActionTypes.CLEAR_HOLD_PREFERENCES_SUCCESS),
  holdPreferencesError: createAction(ActionTypes.HOLD_PREFERENCES_ERROR),

  getPersistedFilters: function (gridView = {}) {
    const persistedFilters = {};
    const storageKeys = ["zip_code", "duration", "unlimitedMinAvailability", "rtrUpdateMinAvailability"];
    storageKeys.forEach(key => {
      const fetchedValue = StorageHelper.getItem(key);
      if (fetchedValue) {
        persistedFilters[key] = fetchedValue;
      }
    });

    const fetchedDate = StorageHelper.getItem("date");
    if (fetchedDate && savedDateIsValid(fetchedDate)) {
      persistedFilters["date"] = fetchedDate;
    }

    // Alan Chen 4/29/2019 - Do not persist sizes for Kids until our user profile can support kids sizes
    const fetchedSizes = StorageHelper.getItem("canonicalSizes");
    const isKidsGrid = isGridType(gridView, "Kids");
    if (fetchedSizes && !isKidsGrid) {
      persistedFilters["canonicalSizes"] = fetchedSizes.split(",");
    }

    // fetchMaternity will be a string with each of the maternity filters seperated by commas
    const fetchedMaternity = StorageHelper.getItem(filters.maternity);
    if (fetchedMaternity) {
      persistedFilters[filters.maternity] = fetchedMaternity.split(",");
    }

    return persistedFilters;
  },

  writeHoldPreferences: function (preferences) {
    return function (dispatch, getState) {
      const { gridView } = getState();
      const results = {};
      let hasErrors = false;
      const preferenceTypes = [
        "zip_code",
        "date",
        "duration",
        "unlimitedMinAvailability",
        "rtrUpdateMinAvailability",
        filters.maternity,
      ];

      // Stephen Benedict 4/1/20 - Only persist sizes if this is not a kids grid
      const isKidsGrid = isGridType(gridView, "Kids");
      if (!isKidsGrid) {
        preferenceTypes.push("canonicalSizes");
      }

      const persistedPreferences = Object.fromEntries(
        Object.entries(preferences).filter(([key]) => preferenceTypes.includes(key))
      );

      Object.entries(persistedPreferences).forEach(([key, val]) => {
        if (key === "canonicalSizes") {
          results[key] = StorageHelper.setItemWithCookie(key, val);
        } else {
          results[key] = StorageHelper.setItem(key, val);
        }

        // StorageHelper.setItem() will return undefined when successful.
        // If localStorage is not supported by the browser,
        // it will return false.
        hasErrors = hasErrors || results[key] === false;
      });

      if (hasErrors) {
        dispatch(actions.holdPreferencesError(results));
      } else {
        dispatch(actions.writeHoldPreferencesSuccess(persistedPreferences));
      }
    };
  },

  fetchHoldPreferences: function () {
    return function (dispatch, getState) {
      const { gridView } = getState();
      const persistedFilters = actions.getPersistedFilters(gridView);

      if (persistedFilters && Object.keys(persistedFilters).length > 0) {
        dispatch(actions.fetchHoldPreferencesSuccess(persistedFilters));
      }
    };
  },

  clearHoldPreferences: function (preferenceKeys) {
    return function (dispatch) {
      const results = {};
      let hasErrors = false;

      preferenceKeys.forEach(key => {
        results[key] = StorageHelper.removeItem(key);

        // StorageHelper.removeItem() will return undefined when successful.
        // If localStorage is not supported by the browser,
        // it will return false.
        hasErrors = hasErrors || results[key] === false;
      });

      if (hasErrors) {
        dispatch(actions.holdPreferencesError(results));
      } else {
        dispatch(actions.clearHoldPreferencesSuccess(preferenceKeys));
      }
    };
  },
};

export default actions;

export const {
  writeHoldPreferencesSuccess,
  fetchHoldPreferencesSuccess,
  clearHoldPreferencesSuccess,
  holdPreferencesError,
  getPersistedFilters,
  writeHoldPreferences,
  fetchHoldPreferences,
  clearHoldPreferences,
} = actions;
