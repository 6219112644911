import { createAction } from "redux-actions";
import ActionTypes from "./action-types";
import { isStorefrontNext } from "helpers/environment-helpers";
import { fireUserEvents } from "components/source/hoc/with-user-data";
import { User } from "@rtr/godmother";
import { AUTH_STATES, currentUserIdReplacementToken } from "rtr-constants";
import analyticsClient from "../analytics/analytics-client";
import GTMHelper from "helpers/gtm";
import * as SplunkRumHelpers from "../helpers/splunk-rum-helpers";
import * as castleHelper from "../helpers/castle-helper";
import AuthServiceClient from "../clients/AuthServiceClient";
import { authLocalStorageClient } from "helpers/auth-helpers";
import { updateUserData } from "actions/on-initialization-actions";
import RawClient from "clients/RawClient";

var actions = {
  updateUserData: createAction(ActionTypes.UPDATE_USER_DATA),

  fetchAuthState() {
    if (!isStorefrontNext()) {
      throw new Error("Cannot call Next API from Ruby");
    }

    return async dispatch => {
      const stateKey = "authData";

      return AuthServiceClient.getInstance()
        .getMe()
        .then(authDataResponse => {
          const { authState: authStateResponse = AUTH_STATES.ANONYMOUS } = authDataResponse || {};
          const isMasquerading = Boolean(authDataResponse?.masqueradingUserId);

          RawClient.setIsMasquerading(isMasquerading);
          analyticsClient.setIsMasquerading(isMasquerading);
          authLocalStorageClient.set("uih", authDataResponse?.uih || "");

          dispatch(
            updateUserData({
              isMasquerading,
              authState: authStateResponse,
              [stateKey]: authDataResponse,
              [`${stateKey}HasLoaded`]: true,
              [`${stateKey}Error`]: null,
            })
          );

          return authDataResponse;
        })
        .catch(e => {
          dispatch(updateUserData({ [`${stateKey}HasLoaded`]: true, [`${stateKey}Error`]: e }));
          console.log("Failed to load Auth Data " + JSON.stringify({ error: e.message }));
          analyticsClient.setIsMasquerading(false);
        });
    };
  },

  fetchUserFromGodmother() {
    if (!isStorefrontNext()) {
      throw new Error("Cannot call Next API from Ruby");
    }
    return async dispatch => {
      User.find(currentUserIdReplacementToken, { data: { include: "profiles,memberships,addresses" } }) // Storefront.Ruby also included `memberships` here, but I'm hopeful the MembershipContext will handle that
        .then(gmUser => {
          const updatedUserData = {
            ["userProfile"]: gmUser,
            [`userProfileHasLoaded`]: true,
            [`userProfileError`]: null,
          };
          dispatch(actions.updateUserData(updatedUserData));
          analyticsClient.setUserInformation(gmUser);
          GTMHelper.pushToDataLayer({ rtrUser: { basicUserProfile: gmUser } });
          SplunkRumHelpers.identifyUserIdWithUserData(updatedUserData);
          fireUserEvents();
        })
        .catch(e => {
          console.log("Failed to load User " + JSON.stringify({ error: e.message }));

          dispatch(actions.updateUserData({ [`userProfileHasLoaded`]: true, [`userProfileError`]: e }));
        });

      // This is not ideal, but we need the legacy memberships prop in the userData object to unblock all other progress
      // and the fetch above does not currently work for grabbing it
      RawClient.ajax({
        url: "/personalization",
        type: "GET",
        headers: {
          Accept: "application/json",
        },
      }).then(
        payload => {
          // @todo: move this line above after deviceUserJWT is added to godmother.ts model in PSEC-2565
          // https://renttherunway.jira.com/browse/PSEC-2565
          if (payload.user?.basicUserProfile?.deviceUserJWT) {
            castleHelper.setDeviceUserJWT(payload.user.basicUserProfile);
          }

          if (payload.memberships) {
            dispatch(
              actions.updateUserData({
                ["memberships"]: payload.memberships,
              })
            );
          }
        },
        error => {
          console.error("Personalization proxied call for userData memberships failed", error);
        }
      );
    };
  },
};

export default actions;

export const { fetchAuthState, fetchUserFromGodmother } = actions;
