import { z } from "zod";
import { validations } from "rtr-constants";

/**
 * Simple schema to validate an email based on Zod email + legacy RTR email regex test
 * @see {https://zod.dev/?id=strings}
 * @see {https://zod.dev/?id=refine}
 */
export const DefaultEmailSchema = z
  .string()
  .email()
  .refine(value => validations.email.test(value ?? ""));

/**
 * Schema to validate Gladly.setUser options object
 * @see {https://developer.gladly.com/glad-app/Gladly.html#~setUser()}
 * @see {https://zod.dev/?id=refine}
 */
export const GladlySetUserSchema = z
  .object({
    name: z.string().optional(),
    email: DefaultEmailSchema.optional(),
    phoneNumber: z.string().optional(),
    jwt: z.union([z.string(), z.function()]).optional(),
  })
  .superRefine((value, ctx) => {
    if (!value.jwt && !value.name) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["name"],
        message: "'name' property is required when jwt is undefined",
      });
    }
    if (!value.phoneNumber && !value.jwt && !value.email) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["email"],
        message: "'email' property is required when phoneNumber and jwt are undefined",
      });
    }
    if (!value.email && !value.jwt && !value.phoneNumber) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["phoneNumber"],
        message: "'phoneNumber' property is required when email and jwt are undefined",
      });
    }
    if (!value.email && !value.phoneNumber && !value.jwt) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["jwt"],
        message: "'jwt' property is required when email and phoneNumber are undefined",
      });
    }
  });
