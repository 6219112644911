import PropTypes from "prop-types";

import { userDataPropType } from "../hoc/with-user-data";
import { childrenPropType } from "../../propTypes";

/**
 * This is a helper layout component that will allow developers to conditionally render components with a fallback view.
 * An example of this is to check for a user's authorization state before rendering, otherwise fallback to the
 * PromptForAuth component that dispatches action to launch the authentication modal.
 * @param children {ReactNode} React children
 * @param renderFallbackView {function(): ReactNode} fallback view if when returns false (i.e. user can't view)
 * @param when {function({ userData: object }): boolean} function that takes an object with userData state object as a
 * param that determines if a user can view the child component
 * @param userData {object} userData state object
 * @returns {ReactNode}
 */
const VisibleToUser = ({ children, renderFallbackView = () => null, when, userData }) => {
  // Passing as an object so if anyone needs to pass additional values in the future it will be fairly easy to expose
  // that to the "when" function without having to consider sequential params
  const isVisibleToUser = when({ userData });

  return isVisibleToUser ? children : renderFallbackView();
};

VisibleToUser.propTypes = {
  children: childrenPropType.isRequired,
  renderFallbackView: PropTypes.func.isRequired,
  when: PropTypes.func.isRequired,
  userData: userDataPropType.isRequired,
};

export default VisibleToUser;
