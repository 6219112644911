import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";

/**
 * Sets the RTR_ID and RTR_SESS cookies if they are not already set
 */
export function createRTRAnalyticsCookies(existingCookies = null) {
  let cookies = existingCookies;
  if (!cookies) {
    cookies = new Cookies();
  }

  // Set the RTR_ID and RTR_SESS in case this is not set
  let RTR_ID = cookies.get("RTR_ID");
  let RTR_SESS = cookies.get("RTR_SESS");
  if (!RTR_ID) {
    const date = new Date();
    const expirationTime = date.getTime() + 2 * 365 * 24 * 60 * 60 * 1000;
    date.setTime(expirationTime);
    RTR_ID = `${uuidv4()}-${new Date().getTime()}`;
    cookies.set("RTR_ID", RTR_ID, { path: "/", expires: date });
  }
  if (!RTR_SESS) {
    // mirrors the properties set originally in R.js
    RTR_SESS = `${uuidv4()}-${new Date().getTime()}.${new Date().getTime()}`;
    cookies.set("RTR_SESS", RTR_SESS, { path: "/" });
  }

  return { RTR_ID, RTR_SESS };
}
