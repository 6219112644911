import { getContentType, safeGetNestedProps } from "helpers/contentful/contentful-helpers";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

const parseFaqData = (data, index) => {
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": data.questions.map(safeGetNestedProps).map(q => ({
      "@type": "Question",
      "name": q.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": documentToHtmlString(q.answer),
      },
    })),
  });

  return (
    <script type="application/ld+json" id="faq-schema" key={index}>
      {structuredData}
    </script>
  );
};

const CONTENT_TYPE_TO_PARSER_MAP = {
  faq: parseFaqData,
};

const parseStructuredDataElement = (structuredDataElement, index) => {
  const dataType = getContentType(structuredDataElement);
  const data = safeGetNestedProps(structuredDataElement);
  const parser = CONTENT_TYPE_TO_PARSER_MAP[dataType];

  if (!data || !parser) {
    return;
  }

  return parser(data, index);
};

export const getStructuredDataElements = (structuredData = []) => {
  return <>{structuredData.map(parseStructuredDataElement).filter(el => Boolean(el))}</>;
};
