import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RawClient from "clients/RawClient";

const initialState = {
  midGridContent: [],
};

export const fetchCmsMidGrid = createAsyncThunk("midGridContent/fetchCmsMidGrid", async path => {
  try {
    return await RawClient.get("/api/cms/midgrid?path=" + (path || ""));
  } catch (e) {
    return Promise.reject([]);
  }
});

export const midGridContentSlice = createSlice({
  name: "midGridContent",
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchCmsMidGrid.fulfilled, (state, action) => {
      state.midGridContent = action.payload;
    });
  },
});

/**
 * This value isn't used anywhere.
 * It's only defined because ReduxApp expects reducers to have a "types" property
 */
export const types = ["midGridContent/fetchCmsMidGrid/fulfilled"];

export default {
  types: types,
  fn: midGridContentSlice.reducer,
};
