import PropTypes from "prop-types";
import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import { COOKIES } from "rtr-constants";
import { logout } from "../../../../actions/auth-actions";
import AtomLinkButton from "../../atoms/atom-link-button";

function LogoutButton({
  actionData,
  bold,
  children,
  className,
  destination,
  id,
  innerClassName,
  searchParams,
  underline,
}) {
  const dispatch = useDispatch();
  const [cookies] = useCookies([COOKIES.CAS_V1_ENABLED]);
  const { [COOKIES.CAS_V1_ENABLED]: casV1EnabledCookie } = cookies;
  const isCasAuthenticationEnabled = casV1EnabledCookie === "true";
  const dispatchLogout = useCallback(
    () => dispatch(logout({ actionData, destination, isCasAuthenticationEnabled, searchParams })),
    [actionData, dispatch, isCasAuthenticationEnabled, destination, searchParams]
  );

  return (
    <AtomLinkButton
      bold={bold}
      className={className}
      id={id}
      innerClassName={innerClassName}
      onClick={dispatchLogout}
      underline={underline}>
      {children}
    </AtomLinkButton>
  );
}

LogoutButton.propTypes = {
  actionData: PropTypes.object,
  bold: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  destination: PropTypes.string,
  id: PropTypes.string,
  innerClassName: PropTypes.string,
  searchParams: PropTypes.instanceOf(URLSearchParams),
  underline: PropTypes.bool,
};

export default LogoutButton;
