import ActionTypes from "../actions/action-types.js";

export const types = [ActionTypes.LOYALTY_AWARDS_FETCH, ActionTypes.LOYALTY_AWARDS_UPDATE];

export function fn(state, action) {
  const { membershipState } = state;

  if (!membershipState) {
    return state;
  }

  switch (action.type) {
    case ActionTypes.LOYALTY_AWARDS_FETCH:
      membershipState["loyaltyAwards"] = action.payload;
      break;

    case ActionTypes.LOYALTY_AWARDS_UPDATE:
      if (membershipState["loyaltyAwards"]) {
        const newloyaltyAwards = membershipState["loyaltyAwards"].filter(
          award => award.rewardType !== action.payload.rewardType
        );
        membershipState["loyaltyAwards"] = [...newloyaltyAwards, action.payload];
      }
      break;

    default:
      return state;
  }

  return {
    ...state,
    membershipState: { ...membershipState, loyaltyAwards: membershipState["loyaltyAwards"] },
  };
}

export default {
  types: types,
  fn: fn,
};
