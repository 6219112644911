import PropTypes from "prop-types";
import classNames from "classnames";

/*
 * Renders each item in the Order Summary at checkout.
 * Logic surrounding which amounts to display and the styles
 * applied to each item is handled in SummaryItemHOC.
 */

const propTypes = {
  additionalClassName: PropTypes.string,
  amountCharged: PropTypes.string,
  displayText: PropTypes.string,
  originalTotal: PropTypes.string,
  toolTip: PropTypes.node,
  hasPromoValue: PropTypes.bool,
  totalTypeKey: PropTypes.string,
};

const className = "summary-line";
const amountChargedSpan = `${className}__amount`;
const originalTotalSpan = `${className}__strikethrough`;

function SummaryItem({
  displayText,
  originalTotal = "",
  amountCharged,
  additionalClassName = "",
  toolTip,
  hasPromoValue,
  totalTypeKey,
}) {
  let content;

  // if toolTip and displayText, set the toolTip's label
  // hacky? maybe. http://bit.ly/2OJMuhh
  if (toolTip) {
    content = (
      <span>
        {displayText}
        {toolTip}
      </span>
    );
  } else {
    content = displayText;
  }

  const valueClass = classNames(amountChargedSpan, {
    strikethrough: hasPromoValue,
  });

  const summaryItem = totalTypeKey !== "promoItem" && totalTypeKey !== "total";
  const summaryItemClass = classNames("", {
    "with-tooltip": toolTip,
    "summary-item": summaryItem,
  });

  return (
    <div className={classNames(className, additionalClassName)} data-test-id="summary-item">
      <span className={summaryItemClass}>{content}</span>
      <span className={valueClass} aria-label={displayText}>
        {amountCharged}
      </span>
      <span className={originalTotalSpan}>{originalTotal}</span>
    </div>
  );
}

SummaryItem.propTypes = propTypes;

export default SummaryItem;
