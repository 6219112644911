// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.

import React, { createContext, useEffect, useState } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import CmsServiceClient from "clients/CmsServiceClient";
import { childrenPropType } from "../propTypes";
import { getDisplayName } from "../source/hoc/utils";
import { getNewCustomerOfferText } from "../../helpers/new-customer-offer-text-helpers";
import OnInitializationActions from "../../actions/on-initialization-actions";

const NewCustomerOfferTextContext = createContext(null);
const stateKey = "newCustomerOfferText";
const cmsLocation = "header-piece-promo";

// plucks newCustomerOfferText from the Redux Context
const selectNewCustomerOfferText = state => state.newCustomerOfferText;

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////

export function useNewCustomerOfferText() {
  return useSelector(selectNewCustomerOfferText, shallowEqual);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

const mapStateToProps = ({ [stateKey]: cmsContent } = {}) => ({ [stateKey]: cmsContent });

export function withNewCustomerOfferText() {
  return WrappedComponent => {
    const C = connect(mapStateToProps)(WrappedComponent);

    C.displayName = `withNewCustomerOfferText(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

// this is not called automatically by Next, you must call it explicitly,
// probably in _app.js
async function getInitialReduxState() {
  return CmsServiceClient.getInstance()
    .getContent("promo_anon_user_promo", cmsLocation)
    .then(cmsContent => ({
      [stateKey]: cmsContent,
    }));
}

// Allows setting explicit context values
export const BaseProvider = NewCustomerOfferTextContext.Provider;

/**
 * sbenedict 8/2024
 * Note - this is the auth/login form copy, and it's essentially static CMS content.
 * It probably doesn't need to be a provider like this, and could just be fetched from the component itself.
 * Given the migration to Contentful, leaving as-is for now.
 */
export function NewCustomerOfferTextProvider({ children }) {
  const cms = useNewCustomerOfferText();
  const pageType = useSelector(s => s.pageType, shallowEqual);
  const [content, setContent] = useState(cms);
  const dispatch = useDispatch();

  useEffect(() => {
    const newContent = getNewCustomerOfferText({
      basePropsObj: cms,
      pageType,
    });
    dispatch(OnInitializationActions.setNewCustomerOfferText(newContent));
    setContent(newContent);
  }, []);

  return <NewCustomerOfferTextContext.Provider value={content}>{children}</NewCustomerOfferTextContext.Provider>;
}

NewCustomerOfferTextProvider.getInitialReduxState = getInitialReduxState;

NewCustomerOfferTextProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...NewCustomerOfferTextContext,
  Provider: NewCustomerOfferTextProvider,
};
