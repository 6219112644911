import { addMonths, isBefore, format, isAfter, set, differenceInDays } from "date-fns";
import {
  dateFnsFormats,
  billingStatuses,
  BLOCK_PICK_GRACE_PERIOD_LENGTH,
  ResumeFromPauseInvoiceIncludes,
} from "rtr-constants";
import { usdPriceIntoInt } from "helpers/PricingHelper";
import { isActiveMembership, inGoodStanding } from "helpers/membership-helpers";
import { getPauseWithItemsTierSpotPrice, isPauseWithItemsTier } from "helpers/membership-tier-helpers";
import { parseISOWithoutTime } from "helpers/date-helpers";
import { priceStringIntoFloat } from "./invoice-helper";

const canSelfServiceCancel = membershipState => {
  if (!membershipState) {
    return false;
  }
  const goodStanding = inGoodStanding(membershipState);
  const pausedWithoutItems = isPausedWithoutItems(membershipState);
  const futureCancel = isFutureCanceled(membershipState.membershipEndedOn);
  return (goodStanding || pausedWithoutItems) && !futureCancel;
};

const getEstimatedFuturePauseWithItemsCount = membershipState => {
  if (!membershipState) {
    return 0;
  }

  return membershipState.baseSlotCount;
};

const getEstimatedPauseWithItemsCount = membershipState => {
  if (!membershipState || !isPausedWithItems(membershipState) || !membershipState.previousMembershipTierRevision) {
    return 0;
  }

  return membershipState.previousMembershipTierRevision?.slotCount ?? 0;
};

// We are using term end here because it is a better source of truth. nextBillingDate can be in various states including null.
const getMultiMonthPauseAttributes = (membershipState, numOfMonths = 1) => {
  // Term end is being used by default as the time when PAUSE takes effect
  let effectiveOn = membershipState?.termEnd;
  // We then add a month to the PAUSE taking effect in order to determine the length of pause
  const endsOn = effectiveOn
    ? format(addMonths(parseISOWithoutTime(effectiveOn), numOfMonths), dateFnsFormats.YYYY_MM_DD)
    : "";

  // If we're extending our pause, we want to keep the effectiveOn as the original PAUSE date.
  if (isPausedWithoutItems(membershipState)) {
    effectiveOn = membershipState.membershipPausedOn;
  }

  return {
    effectiveOn,
    endsOn,
  };
};

// when fetching invoice for resume from pause godmother call needs a includes based off of if a user is paused or PWI - AS 4/23/21
const getPauseInvoiceIncludes = membershipState => {
  return isPausedWithoutItems(membershipState)
    ? ResumeFromPauseInvoiceIncludes.PAUSED
    : ResumeFromPauseInvoiceIncludes.PWI;
};

/**
 * @function getPauseWithItemsEstimatedPrice
 *
 *    Get's the current pause tier's spot price and multiplies it
 *    by the number of spots from their previous tier revision if
 *    the user is currently paused with items, or their current
 *    base slot count if they have a future pause with items tier
 *
 * @param {membershipTiers} membershipTiers - Array of membership tiers available
 * @param {membershipState} membershipState - Membership State
 * @return {number} Pause with items estimated price
 */
const getPauseWithItemsEstimatedPrice = (membershipTiers, membershipState) => {
  const itemCount = isPausedWithItems(membershipState)
    ? getEstimatedPauseWithItemsCount(membershipState)
    : getEstimatedFuturePauseWithItemsCount(membershipState);

  // for future PWI, show them their upcoming price in case PWI price has been changed in between their decision to pause and their bill date
  if (membershipState?.futureMembershipTierRevision?.baseSlotUpgradePrice) {
    return (
      itemCount * priceStringIntoFloat(membershipState.futureMembershipTierRevision.baseSlotUpgradePrice)
    ).toFixed(2);
  }

  // for current PWI, get the price from the current membership tier
  const spotPrice = getPauseWithItemsTierSpotPrice(membershipTiers);
  if (spotPrice) {
    return (itemCount * spotPrice).toFixed(2);
  }
};

const getSpotAddOnPrice = (spotCount, membershipState, selectedTier = {}) => {
  const { baseSlotUpgradePrice, tierBaseSlotCount } = membershipState;
  const minSpotCountOption = selectedTier?.slotCount || tierBaseSlotCount;
  const spotUpgradePrice = usdPriceIntoInt(selectedTier?.baseSlotUpgradePrice || baseSlotUpgradePrice);

  if (Number.isInteger(minSpotCountOption)) {
    const upgradeCount = spotCount - minSpotCountOption;
    const upgradePrice = spotUpgradePrice * upgradeCount;
    if (upgradePrice < 0) {
      return 0;
    } else {
      return upgradePrice;
    }
  }
};

const isFutureCanceled = membershipEndedOn => {
  if (!membershipEndedOn) {
    return false;
  }
  return isAfter(parseISOWithoutTime(membershipEndedOn), new Date());
};

const isFuturePausedWithoutItems = membershipPausedOn => {
  if (!membershipPausedOn) {
    return false;
  }
  return isAfter(parseISOWithoutTime(membershipPausedOn), new Date());
};

// this only checks for if a user is in a future paused with or without items state,
// use isFuturePausedWithItems/isFuturePausedWithoutItems for more specific behavior
const isFuturePaused = membershipState => {
  return isFuturePausedWithItems(membershipState) || isFuturePausedWithoutItems(membershipState?.membershipPausedOn);
};

const isFuturePausedWithItems = membershipState => {
  if (!membershipState || !membershipState.futureMembershipTierRevision) {
    return false;
  }
  return isPauseWithItemsTier(membershipState.futureMembershipTierRevision);
};

const inLastMonthOfPause = membershipState => {
  if (!isPaused(membershipState)) {
    return false;
  }

  const { membershipResumedOn, previousTierResumesOn } = membershipState;
  const oneMonthFromNow = addMonths(new Date(), 1);

  const resumeDate = isPausedWithItems(membershipState) ? previousTierResumesOn : membershipResumedOn;

  return isBefore(parseISOWithoutTime(resumeDate), oneMonthFromNow);
};

const inPauseState = membershipState => isFuturePaused(membershipState) || isPaused(membershipState);

const isCanceled = (membershipState = {}) => {
  return (
    (membershipState.membershipStatus && !isActiveMembership(membershipState)) ||
    (membershipState.billingStatus === billingStatuses.CANCELED && !isFutureCanceled(membershipState.membershipEndedOn))
  );
};

const isFutureCanceledOrPaused = membershipState => {
  if (!membershipState) {
    return false;
  }

  return (
    isFuturePausedWithItems(membershipState) ||
    isFuturePausedWithoutItems(membershipState?.membershipPausedOn) ||
    isFutureCanceled(membershipState?.membershipEndedOn) ||
    isCanceled(membershipState)
  );
};

const isFuturePausedWithoutItemsWithinThreeDays = membershipPausedOn => {
  // Get the beginning of today (i.e. midnight) instead of the today + w/e hours/minutes
  const today = set(new Date(), { hours: 0 });
  const daysUntilPause = differenceInDays(parseISOWithoutTime(membershipPausedOn), today);
  // We're not putting a bottom constraint because the difference between today and the paused day may be less than
  // 24 hours which date-fns will report as a difference of 0 days
  return isFuturePausedWithoutItems(membershipPausedOn) && daysUntilPause <= BLOCK_PICK_GRACE_PERIOD_LENGTH;
};

const isPausedWithoutItems = (membershipState = {}) => {
  return membershipState.billingStatus === billingStatuses.PAUSED;
};

// this only checks for if a user is in a paused with or without state,
// use isPausedWithItems/isPausedWithoutItems for more specific behavior
const isPaused = membershipState => {
  return isPausedWithItems(membershipState) || isPausedWithoutItems(membershipState);
};

const isPausedWithItems = membershipState => {
  if (!membershipState) return false;

  return membershipState.monthlyShipmentLimit === 0 && membershipState.tierBaseSlotCount === 0;
};

const pausedBeginAndResumeDate = membershipState => {
  if (!membershipState) return false;

  const { previousTierResumesOn, tierChangeStartedOn, membershipPausedOn, membershipResumedOn } = membershipState;
  if (isPausedWithItems(membershipState) || isFuturePausedWithItems(membershipState)) {
    return {
      datePaused: tierChangeStartedOn,
      dateResume: previousTierResumesOn,
    };
  } else if (isPausedWithoutItems(membershipState) || isFuturePausedWithoutItems(membershipPausedOn)) {
    return {
      datePaused: membershipPausedOn,
      dateResume: membershipResumedOn,
    };
  }
  return false;
};

// tier revision id is different for paused vs PWI users
const resumeFromPauseTierId = membershipState => {
  const { membershipTierId, previousMembershipTierRevision } = membershipState;
  return isPausedWithoutItems(membershipState) ? membershipTierId : previousMembershipTierRevision?.membershipTierId;
};

const tierHasPromo = (chosenTier, tierList) => {
  if (!tierList || !chosenTier) {
    return false;
  }

  const previewTier = tierList.filter(previewTier => {
    const tierId = chosenTier.id || chosenTier.membershipTierId;
    return (
      parseInt(tierId) === parseInt(previewTier.id) &&
      (previewTier.promoDiscountValue || previewTier.subscriptionDiscountValue)
    );
  })[0];

  return previewTier ? previewTier : false;
};

export {
  canSelfServiceCancel,
  getEstimatedFuturePauseWithItemsCount,
  getEstimatedPauseWithItemsCount,
  getMultiMonthPauseAttributes,
  getPauseInvoiceIncludes,
  getPauseWithItemsEstimatedPrice,
  getSpotAddOnPrice,
  inLastMonthOfPause,
  inPauseState,
  isCanceled,
  isFutureCanceled,
  isFutureCanceledOrPaused,
  isFuturePaused,
  isFuturePausedWithItems,
  isFuturePausedWithoutItems,
  isFuturePausedWithoutItemsWithinThreeDays,
  isPaused,
  isPausedWithItems,
  isPausedWithoutItems,
  pausedBeginAndResumeDate,
  resumeFromPauseTierId,
  tierHasPromo,
};
