import { isSubscriptionLens, isClassicLens } from "./membership-helpers";
import { RENTAL_INTENT } from "rtr-constants";

export const getRentalIntent = userData => {
  if (!userData) {
    return;
  }

  if (isSubscriptionLens(userData)) {
    return RENTAL_INTENT.MEMBERSHIP;
  } else if (isClassicLens(userData)) {
    return RENTAL_INTENT.RESERVE;
  } else {
    return RENTAL_INTENT.NO_INTENT;
  }
};

export const getCurrentIntent = lens => {
  if (lens === "unlimited" || lens === "rtrupdate") {
    return RENTAL_INTENT.MEMBERSHIP;
  } else if (lens === "classic") {
    return RENTAL_INTENT.RESERVE;
  }
  return RENTAL_INTENT.NO_INTENT;
};
