import React from "react";
import { isDevelopment } from "../../../helpers/next-helpers";

/**
 * Responsible for giving a location for the CDN to embed data as it serves a page from cache
 */
const cdnEmbeds = () => {
  return isDevelopment() ? (
    <div style={{ display: "none" }}>
      <script
        type="application/json"
        id="cdn-script"
        dangerouslySetInnerHTML={{
          __html: `{ "predictedZip": "10014" }`,
        }}
      />
    </div>
  ) : (
    // NOTE: Needed to wrap the scripts in this way as SWC fails with an error if you try to use namespaced tags (e.g. esi:include) directly in JSX
    // there is an option to allow those tags in your code, but as of April 2023 Next does not expose that option for SWC (https://github.com/vercel/next.js/discussions/30940)
    // therefore, I added the script as raw HTML to a hidden div, so that it is not evaluated as JSX

    // IF YOU UPDATE THE TAG ABOVE WITH NEW FIELDS
    // you will need to update the CDN code that handles the esi tag below to also return that value in QA, stage, and production

    /** picks up on embedded values in the CDN
     * relevant code:
     * https://github.com/RentTheRunway/fastly/blob/main/stage/vcl/stage.renttherunway.com.vcl#L316 - handler for /__cdn/geo_info in fastly
     * https://github.com/RentTheRunway/fastly/blob/main/stage/vcl/stage.renttherunway.com.vcl#L784 - synthetic response that generates script with predictedZip field
     *
     * The content of the second link will replace the esi tag below when fastly processes the response at the edge
     * */
    <div
      style={{ display: "none" }}
      dangerouslySetInnerHTML={{
        __html: `<esi:include src="/__cdn/geo_info"/>`,
      }}
    />
  );
};

export default cdnEmbeds;
