import ActionTypes from "actions/action-types";

export const types = [ActionTypes.ADD_UPDATE_ITEMS, ActionTypes.REMOVE_UPDATE_ITEMS];

/**
 * The function that updates the relevant piece of state.
 *
 * @param {Number[]} state - The current redux state (the whole state)
 * @param {Object} action - The redux action. Payload should be an array of numbers
 */
const getItemsThatAreUpdating = (state, action) => {
  const itemsState = state.itemsThatAreUpdating || []; // [] is our default

  switch (action.type) {
    case ActionTypes.ADD_UPDATE_ITEMS:
      return action.payload ? [...itemsState, ...action.payload] : itemsState;
    case ActionTypes.REMOVE_UPDATE_ITEMS:
      return action.payload ? itemsState.filter(id => !action.payload.includes(id)) : itemsState;
    default:
      return itemsState;
  }
};

/**
 * Reducer function for the itemsThatAreUpdating piece of the store.
 *
 * @param {Number[]} state - The current redux state (the whole state)
 * @param {Object} action - The redux action. Payload should be an array of numbers
 * @return {Number[]} - The bookingIds of items that are being updated on the backend
 */
export const fn = (state, action) => {
  const itemsThatAreUpdating = getItemsThatAreUpdating(state, action);

  return {
    ...state,
    itemsThatAreUpdating,
  };
};

export default {
  types,
  fn,
};
