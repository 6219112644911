import _ from "underscore";
import { skuInfo } from "helpers/sku-helpers";

import ActionTypes from "../actions/action-types";
import membershipOrderExceptionHelper from "../helpers/membership-order-exception-helper";
import { ProductImageSize } from "../helpers/product-image-helpers";

const productTypes = {
  DRESS: "Dress",
  ACCESSORY: "Accessory",
};

function lightWeightItem(item) {
  const { imagesBySize, itemId, displayName, designerName, sku, type } = item;
  const hasSizes = type === productTypes.DRESS;
  const { id: styleName, size } = skuInfo(sku);
  const imgSrc = imagesBySize[ProductImageSize.x183.path][0].url;
  const thumbnailImgSrc = imagesBySize[ProductImageSize.x70.path][0].url;

  return {
    imgSrc,
    thumbnailImgSrc,
    displayName,
    designerName,
    hasSizes,
    sku,
    size,
    styleName,
    itemId,
    imagesBySize,
  };
}

function bagModification(state, action) {
  let bag = _.extend({}, state.membershipBag);

  bag.errors = _.extend({}, bag.errors);
  switch (action.type) {
    case ActionTypes.MEMBERSHIP_PICK_ELIGIBILITY_LOAD_SUCCESS:
      bag.numEligibleToPick = action.payload;
      break;

    case ActionTypes.MEMBERSHIP_BAG_LOADING:
      bag.isLoading = action.payload;
      break;

    case ActionTypes.MEMBERSHIP_BAG_LOAD_SUCCESS:
      bag.group = action.payload.group;
      bag.justSuccessfullyAddedItem = false;

      bag.paymentProfiles = action.payload.paymentProfiles;
      bag.orderInvoice = action.payload.orderInvoice;
      bag.lineItems = action.payload.lineItems;
      bag.items = _.map(action.payload.items, function (item) {
        return lightWeightItem(item);
      });
      bag.promoCode = action.payload.promoCode;
      break;

    case ActionTypes.MEMBERSHIP_ADD_TO_CART_SUCCESS:
      bag.justSuccessfullyAddedItem = true;
      break;

    case ActionTypes.MEMBERSHIP_ADD_TO_CART_LOADING:
      bag.isAddingItem = action.payload;
      break;

    case ActionTypes.MEMBERSHIP_ADD_TO_CART_FAILURE:
      bag.errors = membershipOrderExceptionHelper(action.payload);
      break;

    case ActionTypes.MEMBERSHIP_REMOVE_FROM_CART_SUCCESS: {
      bag = { ...state.membershipBag };
      const items = [...bag.items]; // since we're splicing, make a copy

      const removedItemIndex = _.findLastIndex(items, i => i.sku === action.payload);

      items.splice(removedItemIndex, 1);
      bag.items = items;
      break;
    }

    case ActionTypes.MEMBERSHIP_REMOVE_FROM_CART_FAILURE:
      bag.errors = membershipOrderExceptionHelper(action.payload);
      break;

    case ActionTypes.MEMBERSHIP_CHECKOUT_IN_PROGRESS:
      bag.checkoutInProgress = action.payload;
      break;

    case ActionTypes.MEMBERSHIP_CHECKOUT_FAILURE:
      bag.errors = membershipOrderExceptionHelper(action.payload);
      bag.checkoutInProgress = false;
      break;

    case ActionTypes.MEMBERSHIP_BAG_CLEAR_ERRORS:
      bag.errors = {};
      break;

    case ActionTypes.FETCH_CROSS_SHIP_RETURN_BY_DATE_FOR_TODAY_SUCCESS:
      bag.crossShipReturnByDateForToday = action.payload;
      break;
  }

  return bag;
}

export const types = [
  ActionTypes.MEMBERSHIP_ADD_TO_CART_SUCCESS,
  ActionTypes.MEMBERSHIP_ADD_TO_CART_FAILURE,
  ActionTypes.MEMBERSHIP_ADD_TO_CART_LOADING,
  ActionTypes.MEMBERSHIP_BAG_LOADING,
  ActionTypes.MEMBERSHIP_BAG_LOAD_SUCCESS,
  ActionTypes.MEMBERSHIP_CHECKOUT_IN_PROGRESS,
  ActionTypes.MEMBERSHIP_CHECKOUT_FAILURE,
  ActionTypes.MEMBERSHIP_PICK_ELIGIBILITY_LOAD_SUCCESS,
  ActionTypes.MEMBERSHIP_REMOVE_FROM_CART_SUCCESS,
  ActionTypes.MEMBERSHIP_REMOVE_FROM_CART_FAILURE,
  ActionTypes.MEMBERSHIP_BAG_CLEAR_ERRORS,
  ActionTypes.MEMBERSHIP_BAG_SCROLL_TO_BOTTOM,
  ActionTypes.FETCH_CROSS_SHIP_RETURN_BY_DATE_FOR_TODAY_SUCCESS,
];

export function fn(state, action) {
  const membershipBag = bagModification(state, action);
  const membershipBagCount = membershipBag.items ? membershipBag.items.length : 0;

  return {
    ...state,
    membershipBag,
    membershipBagCount,
  };
}

export default {
  types,
  fn,
};
