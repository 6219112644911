import ActionTypes from "actions/action-types";
import { authFormConstants } from "rtr-constants/auth";

const { DisplayStyle, Views } = authFormConstants;
export const types = [ActionTypes.UPDATE_AUTH_MODAL, ActionTypes.REFRESH_ALL_PROPS_SUCCESS];
export const getInitialState = () => ({
  displayStyle: DisplayStyle.DEFAULT,
  errorMessage: "",
  isFullScreen: false,
  isOpen: false,
  mainClass: "",
  source: "",
  subtitle: "",
  title: "",
  triggeredBy: "",
  view: Views.registration,
});

export function fn(state, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_AUTH_MODAL: {
      const authModal = state?.authModal || getInitialState();
      const newAuthModal = Object.assign({}, authModal, action.payload);

      return Object.assign({}, state, { authModal: newAuthModal });
    }

    // Fired on login to refresh any user-specific props
    case ActionTypes.REFRESH_ALL_PROPS_SUCCESS: {
      return Object.assign({}, state, action.payload);
    }

    default:
      return state;
  }
}

export default { fn, getInitialState, types };
