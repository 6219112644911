import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import Routes from "routes";
import ProductImage from "components/source/shared/product-image";
import {
  ProductImageOrientation,
  ProductImageSize,
  getProductImageAltText,
  getProductImageUrlsByOrientation,
} from "helpers/product-image-helpers";
import ProductImagePath from "helpers/product-image-path";
import UpsellGridPrice from "./upsell-grid-pricing";
import PropTypes from "prop-types";

export default function UpsellGrid({ bonusPrice }) {
  const similarStyles = useSelector(state => state.reserveUpsellModalCuration);

  const fallBackThumbnailImgSrc = thumbnail =>
    thumbnail?.images?.[ProductImageOrientation.NO_MODEL.path]
      ? ProductImagePath + thumbnail.images[ProductImageOrientation.NO_MODEL.path]?.[ProductImageSize.x480.path]
      : ProductImagePath + thumbnail?.images?.[ProductImageOrientation.FRONT.path]?.[ProductImageSize.x480.path];

  return (
    <div className={styles["upsell-grid"]}>
      <p className={`semibold ${styles["upsell-grid-title"]}`}>Similar Styles</p>
      <div className={styles["upsell-grid-items"]} data-test-id="upsell-grid-items">
        {similarStyles.map((product, index) => {
          return (
            <a href={`${Routes.PDP.RouteById(product.id)}?upsell_source=modal_grid`} key={index}>
              <ProductImage
                className={styles["upsell-grid-item"]}
                altText={getProductImageAltText(product.displayName, product.designer.displayName)}
                imageGroup={getProductImageUrlsByOrientation(product.images, [])}
                imageSize={ProductImageSize.x800}
                fallbackSrc={fallBackThumbnailImgSrc(product)}
              />
              <div className={styles.label__wrapper}>
                <p>{product.designer.displayName}</p>
                <p className="universal-xsmall--secondary">{product.displayName}</p>
                <p className="universal-xsmall--secondary">
                  <UpsellGridPrice price={product?.price} bonusPrice={bonusPrice} />
                </p>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
}

UpsellGrid.propTypes = {
  bonusPrice: PropTypes.number.isRequired,
};
