import PropTypes from "prop-types";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDisplayName } from "./utils";
import FlagActions from "actions/flags-and-experiments-actions";
import { useEffect, useState } from "react";
import { flagNames, experimentNames } from "constants/flags-and-experiments-names";

// a convenience export, since you will almost always need the featureFlags
// constant when using this HOC/Hook
export { flagsAndExperimentNames } from "rtr-constants";

export const flagsAndExperimentsPropType = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object, PropTypes.number])
);

export const flagNamesValues = Object.values(flagNames);
export const experimentsNamesValues = Object.values(experimentNames);

function getFlagsAndExperimentsState(flagsAndExperiments, requestedFlagOrExperiment) {
  return flagsAndExperiments?.[requestedFlagOrExperiment];
}

/////////////////////////////////
//                             //
//  Selectors                  //
//  for functional Components  //
//                             //
/////////////////////////////////

export const requestedFlagAndExperimentSelector = requestedFlagOrExperiment => {
  return state => {
    const flagsAndExperiments = flagsAndExperimentsSelector(state);

    return getFlagsAndExperimentsState(flagsAndExperiments, requestedFlagOrExperiment);
  };
};

export const flagsAndExperimentsSelector = ({ flagsAndExperiments }) => flagsAndExperiments;
export const flagsSelector = ({ flagsAndExperiments = {} }) => requestedFlagsObject(flagsAndExperiments, flagNames);
export const experimentsSelector = ({ flagsAndExperiments = {} }) =>
  requestedFlagsObject(flagsAndExperiments, experimentNames);

/**
 *  Helper function for the selectors above as this returns an object of all requested flags from givenFlagsAndExperimentsValue
 * @param {*} givenFlagsAndExperiments // object of all fetched flags
 * @param {*} requestedFlagsOrExperiments // object of all flags we want are requesting
 * @return {*} object with structure:
 * {
 *   ...[requestedFlagsOrExperiments.value]: givenFlagsAndExperiments[requestedFlagsOrExperiments.value]
 * }
 *
 */
function requestedFlagsObject(givenFlagsAndExperiments, requestedFlagsOrExperiments) {
  return Object.keys(requestedFlagsOrExperiments).reduce((prev, curr) => {
    const selectedFlagName = requestedFlagsOrExperiments[curr];
    /*
     * [DZ] 11/2024
     * If we null and undefined check here instead of a truthy evaluation,
     * we'll be able to view false experiment values in state
     */
    if (
      typeof givenFlagsAndExperiments[selectedFlagName] !== "undefined" &&
      givenFlagsAndExperiments[selectedFlagName] !== null
    ) {
      prev[selectedFlagName] = givenFlagsAndExperiments[selectedFlagName];
    }
    return prev;
  }, {});
}

/////////////////////////////////
//                             //
//  Hooks                      //
//  for functional Components  //
//                             //
/////////////////////////////////

/**
 * sbenedict 8/2024
 * This does not fetch the selected flag, it just provides it if it's already in Redux.
 * You probably want to be using useOnLoadFlagOrExperiment, which also requests the flag.
 */
export function useFlagOrExperiment(requestedFlagOrExperiment) {
  const flagsAndExperiments = useSelector(flagsAndExperimentsSelector, shallowEqual) || {};

  return getFlagsAndExperimentsState(flagsAndExperiments, requestedFlagOrExperiment);
}

/**
 * For the extremely common paradigm where you need to fetch a flag, then update once it's populated in Redux.
 * This also prevents unnecessary requests for flags that already exist in Redux.
 */
export function useOnLoadFlagOrExperiment(requestedFlagOrExperiment) {
  const dispatch = useDispatch();
  const fetchFlag = () => dispatch(FlagActions.fetchFlagOrExperiment(requestedFlagOrExperiment));

  const flagValue = useFlagOrExperiment(requestedFlagOrExperiment);
  const [flagHasLoaded, setFlagHasLoaded] = useState(false);

  const hasLoaded = () => typeof flagValue !== "undefined";

  useEffect(() => {
    if (!hasLoaded()) {
      fetchFlag();
    } else {
      setFlagHasLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (hasLoaded()) {
      setFlagHasLoaded(true);
    }
  }, [flagValue]);

  return [flagValue, flagHasLoaded];
}

/////////////////////////////////
//                             //
//  HOCs                       //
//  for class Components       //
//                             //
/////////////////////////////////

const mapFlags = requestedFlagsAndExperiments => state => {
  const flagsAndExperimentsFromState = flagsAndExperimentsSelector(state) || [];

  const flagsAndExperiments = requestedFlagsAndExperiments.reduce((memo, flag) => {
    memo[flag] = getFlagsAndExperimentsState(flagsAndExperimentsFromState, flag);
    return memo;
  }, {});

  return { flagsAndExperiments };
};

/**
 * sbenedict 8/2024
 * This does not fetch flags, it just provides them if they're already in Redux.
 * You probably want to be using withOnLoadFlagsAndExperiments, which also requests the flag(s).
 */
export function withFlagsAndExperiments(...requestedFlagsAndExperiments) {
  const mapStateToProps = mapFlags(requestedFlagsAndExperiments);

  return WrappedComponent => {
    const C = connect(mapStateToProps)(WrappedComponent);

    C.displayName = `withFlagsAndExperiments(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

export function withOnLoadFlagsAndExperiments(...requestedFlagsAndExperiments) {
  return WrappedComponent => {
    const C = props => {
      //useOnLoadFlagOrExperiment prevents requests for flags that are already populated
      const flagHooks = requestedFlagsAndExperiments.map(useOnLoadFlagOrExperiment);
      const loadStates = flagHooks.map(f => f[1]);
      const [allLoaded, setAllLoaded] = useState(false);

      useEffect(() => {
        const allFlagsLoaded = loadStates.every(Boolean);

        if (allFlagsLoaded) {
          setAllLoaded(true);
        }
      }, loadStates);

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...props} flagsAndExperimentsLoaded={allLoaded} />;
    };

    const mapStateToProps = mapFlags(requestedFlagsAndExperiments);

    C.displayName = `withOnLoadFlagsAndExperiments(${getDisplayName(WrappedComponent)})`;

    return connect(mapStateToProps)(C);
  };
}
