import PropTypes from "prop-types";

export const bannerPropTypeShape = {
  bannerId: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  desktopCopy: PropTypes.string,
  desktopCta: PropTypes.string,
  details: PropTypes.string,
  link: PropTypes.string,
  linkCopy: PropTypes.string,
  mobileCopy: PropTypes.string,
  mobileCta: PropTypes.string,
  promoCode: PropTypes.string,
};

export const bannerPropType = PropTypes.shape(bannerPropTypeShape);
