import ActionTypes from "../actions/action-types.js";

export const types = [ActionTypes.TOOLTIP_SETTINGS];

export function fn(state, action) {
  if (!state || !action) return state;

  const { tooltipSettings } = state;

  return {
    ...state,
    tooltipSettings: {
      ...tooltipSettings,
      ...action.payload,
    },
  };
}

export default {
  types: types,
  fn: fn,
};
