import $ from "clients/RawClient";
import _ from "underscore";
import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";
import { Carousel } from "@rtr/godmother";
import Routes from "routes";
import { stringify } from "qs";

//
// NOTE internal counter to ensure only latest fetch is pushed to state
//
let counter = 0;

const actions = {
  fetchCarousels(args) {
    const params = _.omit(args, a => a !== 0 && !a);
    const url = Routes.Carousels.Personalized + "?" + stringify(params);
    const thisCount = ++counter;

    return function (dispatch) {
      dispatch(actions.fetchPersonalizedCarousels());

      $.get(url).then(
        carouselsJsonApi => {
          if (thisCount !== counter) return;

          const carousels = Carousel.fromResponse(carouselsJsonApi);

          dispatch(actions.fetchPersonalizedCarouselsSuccess({ carousels }));
        },
        function () {
          if (thisCount !== counter) return;

          dispatch(actions.fetchPersonalizedCarouselsFailure());
        }
      );
    };
  },

  fetchPersonalizedCarousels: createAction(ActionTypes.FETCH_PERSONALIZED_CAROUSELS),
  fetchPersonalizedCarouselsSuccess: createAction(ActionTypes.FETCH_PERSONALIZED_CAROUSELS_SUCCESS),
  fetchPersonalizedCarouselsFailure: createAction(ActionTypes.FETCH_PERSONALIZED_CAROUSELS_FAILURE),
};

export default actions;

export const {
  fetchCarousels,
  fetchPersonalizedCarousels,
  fetchPersonalizedCarouselsSuccess,
  fetchPersonalizedCarouselsFailure,
} = actions;
