import { Base, Product } from "@rtr/godmother";

export function godmotherModelAsPojo(model) {
  if (!(model instanceof Base)) return model ?? null;

  return Object.entries(model).reduce((pojo, [key, val]) => {
    if (Array.isArray(val)) {
      pojo[key] = val.map(godmotherModelAsPojo);
    } else {
      pojo[key] = godmotherModelAsPojo(val);
    }
    return pojo;
  }, {});
}

// handle known issue with serialization in this version of Next - https://github.com/vercel/next.js/issues/11993
export function listSerializationHelper(val) {
  try {
    return JSON.parse(JSON.stringify(val));
  } catch {
    return [];
  }
}
export function objectSerializationHelper(val) {
  try {
    return JSON.parse(JSON.stringify(val));
  } catch {
    return {};
  }
}

// added this to next-helpers because I'm not positive process.env.NODE_ENV is
// accessible in non-Next environments
export function isDevelopment() {
  return process.env.NODE_ENV === "development";
}

// copying this piece over from PDP-ssr
export async function fetchProduct(productId) {
  if (!productId) return Promise.reject(null);

  try {
    // I am reluctant to introduce the use of `include` as it should _never_ be
    // used for non-static data. In this case, sizeScale should not change on a
    // Product, so I think it is ok.
    // with that in mind, _never_ include availability! (or anything else dynamic)
    // (price, with no filters, _is_ static, typically returning a price range)
    return Product.find(productId, {
      data: {
        include: "price,skus,sizeScale", // fyi, price doesn't work yet
        // Commenting these out as the CAG products call is currently having issues with the field filtering strings.
        // There is currently a ticket to fix this https://renttherunway.jira.com/browse/CONCO-1043
        // fields: {
        //   product:
        //     "designer,displayName,eligibleFor,images,purchasePrice,purchasePriceDiscountFromRetailPrice,retailPrice",
        // },
        filter: {
          "filterLoyaltyAccess": false,
          "products.published": false, // seems like we ought to give Admins a way to view unpublished products
        },
      },
    });
  } catch (error) {
    console.error(`Failed to fetch product for: '${productId}'`, { error });
    return Promise.reject(null);
  }
}
