import React from "react";
import FooterComponent from "../source/shared/footer";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export function Footer({ footerCms }) {
  return <FooterComponent footer={footerCms} />;
}

Footer.propTypes = {
  footerCms: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    footerCms: JSON.parse(state.cmsContent?.footer || "{}"),
  };
}

export default connect(mapStateToProps)(Footer);
