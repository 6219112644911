import React from "react";
import AtomRadioCard from "components/source/atoms/atom-radio-card";
import { returnFullName, returnCityAddress } from "helpers/address-helpers";

import PropTypes from "prop-types";
import { addressPropType } from "../../propTypes/address-prop-type";

const propTypes = {
  address: addressPropType.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  optionalCardStyle: PropTypes.object,
};

const AtomAddressCard = ({ address, isChecked, onChange, optionalCardStyle }) => {
  const { id } = address;

  const handleChange = e => {
    e.stopPropagation();
    onChange(id);
  };

  return (
    <AtomRadioCard
      checked={isChecked}
      onChange={handleChange}
      value={id}
      id={id}
      name="address"
      optionalCardStyle={optionalCardStyle}>
      <div data-heap-redact-text="true">
        {address.default && <div className="atom-address-card__default">Default</div>}
        <div className="atom-address-card__info">
          {returnFullName(address)}, {address.street1}, {address.street2 ? `${address.street2}, ` : ""}
          {returnCityAddress(address)}
        </div>
      </div>
    </AtomRadioCard>
  );
};

AtomAddressCard.propTypes = propTypes;

export default AtomAddressCard;
