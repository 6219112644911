import React from "react";
import PropTypes from "prop-types";
import AtomAddressCard from "components/source/atoms/atom-address-card";
import AtomPrimaryButton from "components/source/atoms/atom-primary-button";
import AtomSecondaryButton from "components/source/atoms/atom-secondary-button";
import { clientSideErrorMessages } from "rtr-constants";

const addressSuggestion = {
  USER_ENTERED: "USER_ENTERED",
  SUGGESTED: "SUGGESTED",
};

/*
 * In the case of an ambiguous address, this component presents the user with
 * the ability to either choose their entered address or select a suggested and
 * possibly corrected address.
 */
export default class MoleculeSuggestAddress extends React.Component {
  static propTypes = {
    addressValidationError: PropTypes.string,
    headerText: PropTypes.string,
    onBackButtonFlowCallback: PropTypes.func,
    onConfirmAddress: PropTypes.func.isRequired,
    onSuggestAddressLogging: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
    suggestedAddress: PropTypes.object.isRequired,
    userSubmittedAddress: PropTypes.object.isRequired,
  };

  static defaultProps = {
    headerText: "Confirm Shipping Address",
    submitButtonText: "Confirm Address",
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedAddressType: props.suggestedAddress ? addressSuggestion.SUGGESTED : addressSuggestion.USER_ENTERED,
      submitting: false,
    };
  }

  componentDidMount() {
    const { onSuggestAddressLogging } = this.props;

    if (onSuggestAddressLogging) {
      onSuggestAddressLogging();
    }
  }

  selectAddress(type) {
    this.setState({
      selectedAddressType: type,
    });
  }

  selectedAddress() {
    if (this.state.selectedAddressType === addressSuggestion.SUGGESTED) {
      // Should we use this address, we still need to pass non-UPS validated
      // values to our backend like phone, name, etc.
      return { ...this.props.userSubmittedAddress, ...this.props.suggestedAddress };
    } else {
      return this.props.userSubmittedAddress;
    }
  }

  renderCard(address, addressType) {
    const isChecked = this.state.selectedAddressType === addressType;

    return (
      <AtomAddressCard
        key={address.id}
        address={address}
        optionalCardStyle={{ marginBottom: "0px" }}
        isChecked={isChecked}
        onChange={() => {
          this.selectAddress(addressType);
        }}
      />
    );
  }

  handleConfirm = () => {
    // validatedAddress is that returned from UPS and logged via pixels.
    const validatedAddress = this.state.selectedAddressType === addressSuggestion.SUGGESTED;
    this.props.onConfirmAddress(this.selectedAddress(), validatedAddress);
    this.setState({ submitting: true });
  };

  renderUserEnteredAddress(address) {
    return this.renderCardWithHeader(address, "You Entered", addressSuggestion.USER_ENTERED);
  }

  renderSuggestedAddress(address) {
    return this.renderCardWithHeader(address, "We Suggest", addressSuggestion.SUGGESTED);
  }

  renderCardWithHeader(address, header, addressSuggestionType) {
    if (!address) {
      return null;
    }
    return (
      <div className="molecule-suggest-address__address">
        <p className="molecule-suggest-address-subheader">{header}</p>
        {this.renderCard(address, addressSuggestionType)}
      </div>
    );
  }

  renderButtons() {
    const backButton = this.props.onBackButtonFlowCallback ? (
      <AtomSecondaryButton onClick={this.props.onBackButtonFlowCallback} buttonText="Back" />
    ) : null;
    return (
      <div className="generic-subform__buttons">
        {backButton}
        <AtomPrimaryButton
          onClick={this.handleConfirm}
          disabled={
            this.state.submitting ||
            this.props.addressValidationError === clientSideErrorMessages.addressSuggestions.addressTooLong
          }
          buttonText={this.props.submitButtonText}
        />
      </div>
    );
  }

  suggestAddressCopy() {
    const { suggestedAddress, addressValidationError } = this.props;

    // TODO: move all this copy to constants
    const sharedDetails = "Entering an accurate address will help us deliver your order on time.";

    /* If suggestedAddresses is an empty array,
     * that means we received no suggestions
     * from UPS. In this case, we should show a
     * different message. */
    if (!suggestedAddress && addressValidationError) {
      return {
        subHeader: addressValidationError,
        details: `${sharedDetails} Do you want to proceed with this address or go back to edit?`,
      };
    }

    return {
      subHeader: "We found this suggestion for your address.",
      details: sharedDetails,
    };
  }

  renderExplanation() {
    const baseClass = "molecule-suggest-address";
    const { subHeader, details } = this.suggestAddressCopy();

    return (
      <div className={`${baseClass}__explanation`}>
        <div className={`${baseClass}__result`}>{subHeader}</div>
        <div className={`${baseClass}__details`}>{details}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="molecule-suggest-address">
        <div className="molecule-suggest-address__header">{this.props.headerText}</div>
        {this.renderExplanation()}
        <div>
          {this.renderUserEnteredAddress(this.props.userSubmittedAddress)}
          {this.renderSuggestedAddress(this.props.suggestedAddress)}
        </div>
        {this.renderButtons()}
      </div>
    );
  }
}
