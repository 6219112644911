import { getEnvironmentName } from "helpers/environment-helpers";

/**
 * Extract individual fields from a linked Contentful entry passed as a prop to a custom component.
 * @example const { imageSrc, imageAlt, assetRatio } = safeGetNestedProps(desktopImage);
 */
export const safeGetNestedProps = contentfulProp => {
  return contentfulProp?.fields ? contentfulProp.fields : {};
};

export const getContentType = entry => {
  return entry?.sys?.contentType?.sys?.id;
};

/**
 * Preview can be enabled via query param, only in development (to prevent end users from enabling it).
 * Otherwise, it can only be enabled via headers set exclusively by Fastly.
 */
export const isPreviewEnvironment = request => {
  const { query: { isPreview } = {} } = request;
  const isLocalPreview = getEnvironmentName().toLowerCase() === "development" && isPreview === "true";
  const isFastlyFrontedPreview = request.headers["x-rtr-contentful-preview"] === "true";

  return isLocalPreview || isFastlyFrontedPreview;
};

export const parseSeoMetadata = seoMetadata => {
  const {
    title,
    description,
    canonical,
    keywords,
    noIndex,
    ogTitle = title,
    ogDescription = description,
    ogKeywords = keywords,
    ogUrl = canonical,
    ogImage,
    ogImageWidth,
    ogImageHeight,
    structuredData = [],
  } = safeGetNestedProps(seoMetadata);

  const metadata = {
    title,
    description,
    canonical,
    keywords,
    "robots": noIndex ? "noindex" : "",
    "og:title": ogTitle,
    "og:description": ogDescription,
    "og:keywords": ogKeywords,
    "og:url": ogUrl,
    "og:image": ogImage,
    "og:image:width": ogImageWidth,
    "og:image:height": ogImageHeight,
  };

  const headData = {
    metadata,
    structuredData,
    isContentfulMetadata: true,
  };

  return headData;
};
