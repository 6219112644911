import ActionTypes from "actions/action-types";

const types = [
  ActionTypes.APPLE_PAY_SESSION_LOADING,
  ActionTypes.APPLE_PAY_SESSION_SUCCESS,
  ActionTypes.APPLE_PAY_SESSION_FAILURE,
];

const initialState = {
  success: false,
  loading: false,
  session: null,
  error: null,
};

const getApplePaySession = (state, action) => {
  const applePayInitialState = initialState;
  const { payload, type } = action;

  switch (type) {
    case ActionTypes.APPLE_PAY_SESSION_SUCCESS: {
      return { session: payload, success: true, error: null, loading: false };
    }
    case ActionTypes.APPLE_PAY_SESSION_FAILURE: {
      return { success: false, error: payload.error, loading: false };
    }
    case ActionTypes.APPLE_PAY_SESSION_LOADING: {
      return { ...applePayInitialState, loading: true };
    }
    default:
      return applePayInitialState;
  }
};

const fn = (state, action) => {
  const applePaySession = getApplePaySession(state, action);

  return { ...state, applePaySession };
};

export default {
  types,
  fn,
};
