import $ from "clients/RawClient";
import _ from "underscore";
import { isSameDay, differenceInDays, format, fromUnixTime, parseISO } from "date-fns";

import { dispatchAction, redirectToRoute } from "./action-utils";
import { skuInfo } from "helpers/sku-helpers";
import { SWAPS_CONTAINER_MODAL } from "helpers/swaps-helper";
import { getExchangeBookings } from "helpers/exchange-helper";
import { dateFnsFormats } from "rtr-constants";
import { Exchanges, Swaps } from "routes";
import { fetchUserProblemGroup, toggleErrorModal, togglePaymentModal } from "./user-problems-actions";
import sharedActions from "actions/shared-actions";
import { loadPaymentProfiles } from "actions/payment-method-actions";

export const constants = {
  FETCH_SELF_SERVICE_EXCHANGES: "FETCH_SELF_SERVICE_EXCHANGES",
  FETCH_SELF_SERVICE_EXCHANGES_BEGIN: "FETCH_SELF_SERVICE_EXCHANGES_BEGIN",
  FETCH_SELF_SERVICE_EXCHANGES_SUCCESS: "FETCH_SELF_SERVICE_EXCHANGES_SUCCESS",
  FETCH_SELF_SERVICE_EXCHANGES_FAILURE: "FETCH_SELF_SERVICE_EXCHANGES_FAILURE",
  FETCH_EXCHANGE_AVAILABILITY: "FETCH_EXCHANGE_AVAILABILITY",
  FETCH_EXCHANGE_AVAILABILITY_BEGIN: "FETCH_EXCHANGE_AVAILABILITY_BEGIN",
  FETCH_EXCHANGE_AVAILABILITY_SUCCESS: "FETCH_EXCHANGE_AVAILABILITY_SUCCESS",
  FETCH_EXCHANGE_AVAILABILITY_FAILURE: "FETCH_EXCHANGE_AVAILABILITY_FAILURE",
  EXCHANGE_CREATE_PAYMENT_PROFILE: "EXCHANGE_CREATE_PAYMENT_PROFILE",
  EXCHANGE_CREATE_PAYMENT_PROFILE_BEGIN: "EXCHANGE_CREATE_PAYMENT_PROFILE_BEGIN",
  EXCHANGE_CREATE_PAYMENT_PROFILE_SUCCESS: "EXCHANGE_CREATE_PAYMENT_PROFILE_SUCCESS",
  EXCHANGE_CREATE_PAYMENT_PROFILE_FAILURE: "EXCHANGE_CREATE_PAYMENT_PROFILE_FAILURE",
  CONFIRM_SSE: "CONFIRM_SSE",
  CONFIRM_SSE_BEGIN: "CONFIRM_SSE_BEGIN",
  CONFIRM_SSE_SUCCESS: "CONFIRM_SSE_SUCCESS",
  CONFIRM_SSE_FAILURE: "CONFIRM_SSE_FAILURE",
  EXCHANGE_SAME_DAY_SHIPPING: "EXCHANGE_SAME_DAY_SHIPPING",
  EXCHANGE_SAME_DAY_SHIPPING_BEGIN: "EXCHANGE_SAME_DAY_SHIPPING_BEGIN",
  EXCHANGE_SAME_DAY_SHIPPING_SUCCESS: "EXCHANGE_SAME_DAY_SHIPPING_SUCCESS",
  EXCHANGE_SAME_DAY_SHIPPING_FAILURE: "EXCHANGE_SAME_DAY_SHIPPING_FAILURE",
  EXCHANGE_UPDATE_SELECTED_PAYMENT_PROFILE: "EXCHANGE_UPDATE_SELECTED_PAYMENT_PROFILE",
  EXCHANGE_TOGGLE_SUCCESS_MODAL: "EXCHANGE_TOGGLE_SUCCESS_MODAL",
  EXCHANGE_UPDATE_BAG_PRICE: "EXCHANGE_UPDATE_BAG_PRICE",
  EXCHANGE_UPDATE_ADDRESS: "EXCHANGE_UPDATE_ADDRESS",
};
const confirmExchangeClient = params => {
  return $.post(Exchanges.confirm, params);
};
export const confirmExchange = params => {
  return dispatch => {
    dispatchAction(dispatch, constants.CONFIRM_SSE_BEGIN);
    confirmExchangeClient(params).then(
      () => {
        const zipCodeParam = params.zipCode ? `&zipCode=${params.zipCode}` : "";
        redirectToRoute(Exchanges.success + params.groupId + zipCodeParam);
      },
      err => {
        dispatchAction(dispatch, constants.CONFIRM_SSE_FAILURE, err);
        toggleErrorModal(true)(dispatch);
        // Refresh bag just in case the error was a hold expiration
        // or a cutoff issue
        dispatch(fetchUserProblemGroup(true, true, params.groupId));
      }
    );
  };
};

const fetchSelfServiceExchangesClient = () => {
  return $.get(Exchanges.exchangeObjects);
};

const fetchNonCachedSelfServiceExchangesClient = () => {
  return $.get(Exchanges.nonCachedExchangeObjects);
};

export const fetchSelfServiceExchanges = nonCached => {
  return dispatch => {
    dispatchAction(dispatch, constants.FETCH_SELF_SERVICE_EXCHANGES_BEGIN);
    const client = nonCached ? fetchNonCachedSelfServiceExchangesClient : fetchSelfServiceExchangesClient;

    client().then(
      response => {
        const { exchanges, bags } = response;

        const incompleteBags = _.filter(bags, bag => bag.status !== "COMPLETED");
        const groupEligibility = {};
        let showExchangeBanner = false;
        const now = new Date();
        const exchangeGroups = {};

        // to account for the case where a user has multiple orders with eligible groups,
        // the backend call returns an array
        _.each(exchanges, function (entry) {
          const {
            exchangeCutoffTime,
            bagCutoffTime,
            newRentBegin,
            hadPO,
            eligible,
            newCustomer,
            orderGroupSummary: { orderId, groupId, rentStart, address },
          } = entry;
          const rentalStartIsToday = isSameDay(now, fromUnixTime(rentStart));
          const bagCutoffIsToday = isSameDay(now, parseISO(bagCutoffTime || ""));
          const bagCreated = _.find(incompleteBags, bag => bag.id === groupId);

          if (eligible) {
            showExchangeBanner = true;
            groupEligibility[groupId] = true;
          } else {
            groupEligibility[groupId] = false;
          }

          const filteredBookings = _.filter(entry.bookings, booking => {
            return booking.groupId === groupId && booking.status === "ACTIVE";
          });

          const fullBookings = _.map(filteredBookings, booking => {
            const product = _.find(entry.products, ({ id }) => id === booking.styleName);
            const { size } = skuInfo(booking?.sku?.id || "");
            const bookingId = booking.id;
            return Object.assign({}, booking, { product, size, bookingId });
          });

          exchangeGroups[groupId] = {
            bookings: fullBookings,
            address,
            exchangeCutoffTime,
            bagCutoffTime,
            newRentBegin,
            rentalStartIsToday,
            bagCutoffIsToday,
            groupId,
            orderId,
            bagCreated,
            hadPO,
            newCustomer,
          };
        });

        dispatchAction(dispatch, constants.FETCH_SELF_SERVICE_EXCHANGES_SUCCESS, {
          showExchangeBanner,
          groupEligibility,
          exchanges,
          exchangeGroups,
        });

        if (!_.isEmpty(exchanges)) {
          fetchExchangeAvailability(exchanges)(dispatch);
        }
      },
      err => dispatchAction(dispatch, constants.FETCH_SELF_SERVICE_EXCHANGES_FAILURE, err)
    );
  };
};

export const fetchExchangeAvailability = exchanges => {
  return dispatch => {
    dispatchAction(dispatch, constants.FETCH_EXCHANGE_AVAILABILITY_BEGIN);

    const exchangeBookings = getExchangeBookings(exchanges);
    const styleNames = _.map(exchangeBookings, booking => booking.styleName);
    const booking = _.first(exchangeBookings);
    const originalRentBegin = parseISO(booking.rentBegin || "");
    const originalRentEnd = parseISO(booking.rentEnd || "");
    // ensure we always have a positive duration
    const duration = Math.abs(differenceInDays(originalRentEnd, originalRentBegin)) + 1;
    const newRentBegin = parseISO(_.first(exchanges).newRentBegin || "");
    const zip = booking.zipCode;

    const params = {
      include: "skus.rentalAvailabilities,price,category,reviewSummary",
      filter: {
        "skus.rentalAvailabilities.beginDate": format(newRentBegin, dateFnsFormats.YYYY_MM_DD),
        "skus.rentalAvailabilities.duration": duration,
        "skus.rentalAvailabilities.zipCode": zip,
        "skus.rentalAvailabilities.admin": true,
        "styleNames": styleNames,
      },
    };

    fetchExchangeAvailabilityClient(params).then(
      products => {
        dispatchAction(dispatch, constants.FETCH_EXCHANGE_AVAILABILITY_SUCCESS, products);
      },
      err => dispatchAction(dispatch, constants.FETCH_EXCHANGE_AVAILABILITY_FAILURE, err)
    );
  };
};

const fetchExchangeAvailabilityClient = params => {
  return $.get(Swaps.products, params);
};

export const updateSelectedProfile = id => {
  return dispatch => {
    dispatchAction(dispatch, constants.EXCHANGE_UPDATE_SELECTED_PAYMENT_PROFILE, id);
    togglePaymentModal(false)(dispatch);
  };
};

const fetchUpdatedSameDayEligibilityClient = (postalCode, groupId) => {
  return $.get(Exchanges.sameDayDeliveryEligibility, { postalCode, groupId });
};

export const fetchUpdatedSameDayEligibility = (postalCode, groupId) => {
  return dispatch => {
    dispatchAction(dispatch, constants.EXCHANGE_SAME_DAY_SHIPPING_BEGIN);

    fetchUpdatedSameDayEligibilityClient(postalCode, groupId).then(
      response => {
        dispatchAction(dispatch, constants.EXCHANGE_SAME_DAY_SHIPPING_SUCCESS, response);
      },
      err => {
        dispatchAction(dispatch, constants.EXCHANGE_SAME_DAY_SHIPPING_FAILURE, err);
      }
    );
  };
};

export const createPaymentProfileSuccess = paymentMethodId => {
  return dispatch => {
    dispatchAction(dispatch, constants.EXCHANGE_CREATE_PAYMENT_PROFILE_SUCCESS, paymentMethodId);
    dispatch(loadPaymentProfiles());
    togglePaymentModal(false)(dispatch);
  };
};

export const createPaymentProfileFailure = () => {
  return dispatch => {
    dispatchAction(dispatch, constants.EXCHANGE_CREATE_PAYMENT_PROFILE_FAILURE);
  };
};

export const toggleSuccessModal = state => {
  return dispatch => {
    if (state) {
      dispatch(sharedActions.displayModal(SWAPS_CONTAINER_MODAL));
    } else {
      dispatch(sharedActions.displayModal(false));
    }
    dispatchAction(dispatch, constants.EXCHANGE_TOGGLE_SUCCESS_MODAL, state);
  };
};

export const updateAddress = addressId => {
  return dispatch => {
    dispatchAction(dispatch, constants.EXCHANGE_UPDATE_ADDRESS, addressId);
  };
};

export default {
  constants,
  fetchExchangeAvailability,
  fetchSelfServiceExchanges,
  updateSelectedProfile,
  createPaymentProfileFailure,
  createPaymentProfileSuccess,
  toggleSuccessModal,
  confirmExchange,
  updateAddress,
  fetchUpdatedSameDayEligibility,
};
