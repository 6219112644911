import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const propTypes = {
  arrowStyles: PropTypes.object,
  additionalContainerClassName: PropTypes.string,
  additionalBodyClassName: PropTypes.string,
  children: PropTypes.object,
  position: PropTypes.object,
};
const ToolTip = props => {
  const { children, additionalBodyClassName, additionalContainerClassName, position, arrowStyles } = props;

  const toolTipArrowClass = `${styles[`highlighted-component-tool-tip-arrow-${arrowStyles?.direction ?? "up"}`]}`;
  const toolTipContainerClass = `${styles[`highlighted-component-tool-tip-container`]} ${
    additionalContainerClassName ? ` ${additionalContainerClassName}` : ""
  }`;
  const toolTipBodyClass = `${styles["highlighted-component-tool-tip-body"]}${
    additionalBodyClassName ? ` ${additionalBodyClassName}` : ""
  }`;
  return (
    <div className={toolTipContainerClass} style={position} data-test-id="highlighted-component-tool-tip-container">
      <div className={toolTipArrowClass} style={arrowStyles} data-test-id="highlighted-component-tool-tip-arrow" />
      <div className={toolTipBodyClass} data-test-id="highlighted-component-tool-tip-body">
        {children}
      </div>
    </div>
  );
};

ToolTip.propTypes = propTypes;
export default ToolTip;
