// by using universal-cookie, you can expect that this code will only work
// client-side. to make it work server-side, have your component pass the
// cookies object provided to it by the `withCookies` HOC.
import Cookies from "universal-cookie";
import { ONETRUST_CONSENT_MANAGEMENT } from "rtr-constants";
import { ensureSailthruIsPresent, SAILTHRU_IDENTIFIER } from "./third-party/sailthru-helpers";

const cookies = new Cookies();

export function isCookieCategoryActivated(category) {
  return window?.OnetrustActiveGroups?.includes(category) || false;
}

export function isSailthruSetToDoNotTrack() {
  return cookies.get(SAILTHRU_IDENTIFIER) === "do-not-track";
}

export async function doNotTrackSailthruIfUserOptsOut() {
  try {
    const sailthruPresent = await ensureSailthruIsPresent(false);
    if (!sailthruPresent) {
      return;
    }
    // Make the call if user has opted out and the cookie does not already exist
    if (
      !isCookieCategoryActivated(ONETRUST_CONSENT_MANAGEMENT.COOKIE_CATEGORIES.TARGETING) &&
      !isSailthruSetToDoNotTrack()
    ) {
      // This will drop the sailthru_hid=do-not-track cookie that prevents any of their calls from tracking personal information
      try {
        window?.Sailthru?.track("cookiesDoNotTrack");
        window?.Sailthru?.track("gdprDoNotTrack");
      } catch (e) {
        console.error("Error tracking with sailthru: " + e?.message);
        window?.Sentry?.captureException(e);
      }
    }
  } catch (e) {
    // some error occurred, not much we can do at this point
    console.error("An error ocurred launching Sailthru", e);
    window?.Sentry?.captureException(e);
  }
}
