export default {
  Category: {
    displayName: "Categories",
    values: [
      {
        id: "KidsApparel",
        value: "Kids Apparel",
      },
      {
        id: "fauxFur_1",
        value: "Fur Coat",
      },
      {
        id: "KidsJumpsuitAndRompers",
        value: "Jumpsuits and Rompers",
      },
      {
        id: "KidsJumpsuits",
        value: "Jumpsuits",
      },
      {
        id: "KidsOveralls",
        value: "Overalls",
      },
      {
        id: "KidsRompers",
        value: "Rompers",
      },
      {
        id: "KidsBottoms",
        value: "Bottoms",
      },
      {
        id: "KidsPants",
        value: "Pants",
      },
      {
        id: "KidsJeansDenim",
        value: "Jeans & Denim",
      },
      {
        id: "KidsShorts",
        value: "Shorts",
      },
      {
        id: "KidsSkirts",
        value: "Skirts",
      },
      {
        id: "KidsOuterwear",
        value: "Outerwear",
      },
      {
        id: "KidsCoats",
        value: "Coats",
      },
      {
        id: "KidsJackets",
        value: "Jackets",
      },
      {
        id: "KidsDress",
        value: "Dress",
      },
      {
        id: "KidsCasualDresses",
        value: "Casual Dresses",
      },
      {
        id: "KidsCocktailDresses",
        value: "Cocktail Dresses",
      },
      {
        id: "KidsGowns",
        value: "Gowns",
      },
      {
        id: "KidsTopsKnits",
        value: "Tops/Knits",
      },
      {
        id: "KidsTops",
        value: "Tops",
      },
      {
        id: "KidsKnits",
        value: "Knits",
      },
      {
        id: "Shoes",
        value: "Shoes",
      },
      {
        id: "Sneakers",
        value: "Sneakers",
      },
      {
        id: "Heels",
        value: "Heels",
      },
      {
        id: "BootsBooties",
        value: "Boots & Booties",
      },
      {
        id: "Sandals",
        value: "Sandals",
      },
      {
        id: "ShoeAccessories",
        value: "Shoe Accessories",
      },
      {
        id: "Flats",
        value: "Flats",
      },
      {
        id: "Beauty",
        value: "Beauty",
      },
      {
        id: "ToolsBrushes",
        value: "Tools & Brushes",
      },
      {
        id: "Skincare",
        value: "Skincare",
      },
      {
        id: "Makeup",
        value: "Makeup",
      },
      {
        id: "Hair",
        value: "Hair",
      },
      {
        id: "Home",
        value: "Home",
      },
      {
        id: "Bedding",
        value: "Bedding",
      },
      {
        id: "DuvetCoversShams",
        value: "Duvet Covers & Shams",
      },
      {
        id: "SheetSetsPillowcases",
        value: "Sheet Sets & Pillowcases",
      },
      {
        id: "BeddingBundles",
        value: "Bedding Bundles",
      },
      {
        id: "PillowInserts",
        value: "Pillow Inserts",
      },
      {
        id: "QuiltsCoverletsComforters",
        value: "Quilts, Coverlets & Comforters",
      },
      {
        id: "Blankets",
        value: "Blankets",
      },
      {
        id: "PillowsDecor",
        value: "Pillows & Decor",
      },
      {
        id: "DecorativePillows",
        value: "Decorative Pillows",
      },
      {
        id: "DecorativePillowInserts",
        value: "Decorative Pillow Inserts",
      },
      {
        id: "Throws",
        value: "Throws",
      },
      {
        id: "PillowThrowSets",
        value: "Pillow & Throw Sets",
      },
      {
        id: "WomensApparel",
        value: "Women's Apparel",
      },
      {
        id: "Dress",
        value: "Dress",
      },
      {
        id: "CocktailDresses",
        value: "Cocktail Dresses",
      },
      {
        id: "Gowns",
        value: "Gowns",
      },
      {
        id: "CasualDresses",
        value: "Casual Dresses",
      },
      {
        id: "JumpsuitsAndRompers",
        value: "Jumpsuits and Rompers",
      },
      {
        id: "Overalls",
        value: "Overalls",
      },
      {
        id: "Jumpsuits",
        value: "Jumpsuits",
      },
      {
        id: "Rompers",
        value: "Rompers",
      },
      {
        id: "Bottoms",
        value: "Bottoms",
      },
      {
        id: "Pants",
        value: "Pants",
      },
      {
        id: "JeansDenim",
        value: "Jeans & Denim",
      },
      {
        id: "Skirts",
        value: "Skirts",
      },
      {
        id: "Shorts",
        value: "Shorts",
      },
      {
        id: "Outerwear",
        value: "Outerwear",
      },
      {
        id: "Coats",
        value: "Coats",
      },
      {
        id: "Jackets",
        value: "Jackets",
      },
      {
        id: "IntimatesShapewear",
        value: "Intimates & Shapewear",
      },
      {
        id: "Shapewear",
        value: "Shapewear",
      },
      {
        id: "Underwear",
        value: "Underwear",
      },
      {
        id: "Bras",
        value: "Bras",
      },
      {
        id: "Hosiery",
        value: "Hosiery",
      },
      {
        id: "LingerieAccessories",
        value: "Lingerie Accessories",
      },
      {
        id: "TopsKnits",
        value: "Tops/Knits",
      },
      {
        id: "Knits",
        value: "Knits",
      },
      {
        id: "Tops",
        value: "Tops",
      },
      {
        id: "SleepwearLounge",
        value: "Sleepwear & Lounge",
      },
      {
        id: "WomensAccessories",
        value: "Women's Accessories",
      },
      {
        id: "ScarvesWraps",
        value: "Scarves & Wraps",
      },
      {
        id: "Handbags",
        value: "Handbags",
      },
      {
        id: "Jewelry",
        value: "Jewelry",
      },
      {
        id: "Rings",
        value: "Rings",
      },
      {
        id: "Bracelets",
        value: "Bracelets",
      },
      {
        id: "Necklaces",
        value: "Necklaces",
      },
      {
        id: "Watches",
        value: "Watches",
      },
      {
        id: "Earrings",
        value: "Earrings",
      },
      {
        id: "Hats",
        value: "Hats",
      },
      {
        id: "Belts",
        value: "Belts",
      },
      {
        id: "Sunglasses",
        value: "Sunglasses",
      },
      {
        id: "FaceMasks",
        value: "Face Masks",
      },
      {
        id: "HairAccessories",
        value: "Hair Accessories",
      },
      {
        id: "Miscellaneous",
        value: "Miscellaneous",
      },
      {
        id: "GiftWithPurchase",
        value: "Gift with Purchase",
      },
      {
        id: "GiftCards",
        value: "Gift Cards",
      },
      {
        id: "Services",
        value: "Services",
      },
      {
        id: "MarketingInserts",
        value: "Marketing Inserts",
      },
      {
        id: "Membership",
        value: "Membership",
      },
      {
        id: "RtrGarmentBags",
        value: "RTR Garment Bags",
      },
      {
        id: "ClutchesMinaudiere",
        value: "Clutches and Minaudiere",
      },
      {
        id: "DressesParty",
        value: "Party Dresses",
      },
      {
        id: "DressesMaxi",
        value: "Maxi Dresses",
      },
      {
        id: "DressesShirt",
        value: "Shirt Dresses",
      },
      {
        id: "DressesSweater",
        value: "Sweater Dresses",
      },
      {
        id: "DressesVacation",
        value: "Vacation Dresses",
      },
      {
        id: "DressesWeddingGuest",
        value: "Wedding Guest Dresses",
      },
      {
        id: "DressesWork",
        value: "Work Dresses",
      },
      {
        id: "DressesWrap",
        value: "Wrap Dresses",
      },
      {
        id: "HandbagsCasual",
        value: "Casual Handbags",
      },
      {
        id: "HandbagsSpecialOccasion",
        value: "Special Occasion Handbags",
      },
      {
        id: "HandbagsCasual",
        value: "Casual Handbags",
      },
      {
        id: "HandbagsWork",
        value: "Work Handbags",
      },
      {
        id: "Jeans",
        value: "Jeans",
      },
      {
        id: "Vests",
        value: "Vests",
      },
    ],
  },
  UseCaseEvents: {
    displayName: "Where to Wear It",
    values: [
      {
        value: "Active/Sport",
        id: "ActiveSport",
      },
      {
        value: "Bridesmaid",
        id: "Bridesmaid",
      },
      {
        value: "Date Night",
        id: "DateNight",
      },
      {
        value: "Daytime Event",
        id: "DaytimeEvent",
      },
      {
        value: "For the Bride",
        id: "ForTheBride",
      },
      {
        value: "Formal Affair",
        id: "FormalAffair",
      },
      {
        value: "Fourth of July",
        id: "FourthOfJuly",
      },
      {
        value: "Labor Day Weekend",
        id: "LaborDayWeekend",
      },
      {
        value: "Lounge",
        id: "Lounge",
      },
      {
        value: "Night Out",
        id: "NightOut",
      },
      {
        value: "Party",
        id: "Party",
      },
      {
        value: "Vacation",
        id: "Vacation",
      },
      {
        value: "Wedding Guest",
        id: "WeddingGuest",
      },
      {
        value: "Weekend",
        id: "Weekend",
      },
      {
        value: "Work",
        id: "Work",
      },
    ],
  },
  StylePersona: {
    displayName: "Styles",
    values: [
      {
        value: "BOHO CHIC",
        id: "BohoChic",
      },
      {
        value: "CASUAL/LAID BACK",
        id: "CasualLaidBack",
      },
      {
        value: "CLASSIC/PREPPY",
        id: "ClassicPreppy",
      },
      {
        value: "ECLECTIC",
        id: "Eclectic",
      },
      {
        value: "EDGY",
        id: "Edgy",
      },
      {
        value: "FEMININE/ROMANTIC",
        id: "FeminineRomantic",
      },
      {
        value: "MINIMALIST",
        id: "Minimalist",
      },
    ],
  },
  PrimaryColor: {
    displayName: "Colors",
    values: [
      {
        id: "Black",
        value: "Black",
      },
      {
        id: "Blue",
        value: "Blue",
      },
      {
        id: "Brown",
        value: "Brown",
      },
      {
        id: "Clear",
        value: "Clear",
      },
      {
        id: "Gold",
        value: "Gold",
      },
      {
        id: "Green",
        value: "Green",
      },
      {
        id: "Grey",
        value: "Grey",
      },
      {
        id: "Multicolored",
        value: "Multicolored",
      },
      {
        value: "Off-White",
        id: "OffWhite",
      },
      {
        id: "Orange",
        value: "Orange",
      },
      {
        id: "Pink",
        value: "Pink",
      },
      {
        id: "Purple",
        value: "Purple",
      },
      {
        id: "Red",
        value: "Red",
      },
      {
        value: "Rose Gold",
        id: "RoseGold",
      },
      {
        id: "Silver",
        value: "Silver",
      },
      {
        id: "White",
        value: "White",
      },
      {
        id: "Yellow",
        value: "Yellow",
      },
    ],
  },
  Print: {
    displayName: "Print",
    values: [
      {
        value: "Abstract",
        id: "Abstract",
      },
      {
        value: "Animal Print",
        id: "AnimalPrint",
      },
      {
        value: "Camouflage",
        id: "Camouflage",
      },
      {
        value: "Colorblock",
        id: "ColorBlock",
      },
      {
        value: "Floral",
        id: "Floral",
      },
      {
        value: "Geometric",
        id: "Geometric",
      },
      {
        value: "Gingham",
        id: "Gingham",
      },
      {
        value: "Graphic",
        id: "Graphic",
      },
      {
        value: "Jacquard/Brocade",
        id: "JacquardBrocade",
      },
      {
        value: "Logo",
        id: "Logo",
      },
      {
        value: "None",
        id: "None",
      },
      {
        value: "Ombre",
        id: "Ombre",
      },
      {
        value: "Paisley",
        id: "Paisley",
      },
      {
        value: "Plaid & Check",
        id: "PlaidCheck",
      },
      {
        value: "Polka Dots",
        id: "PolkaDots",
      },
      {
        value: "Stripe",
        id: "Stripe",
      },
      {
        value: "Tie-Dye",
        id: "TieDye",
      },
    ],
  },
  Seasonality: {
    displayName: "Weather",
    values: [
      {
        value: "Fall/Winter",
        id: "FallWinter",
      },
      {
        id: "Seasonless",
        value: "Seasonless",
      },
      {
        value: "Spring/Summer",
        id: "SpringSummer",
      },
    ],
  },
  Length: {
    displayName: "Length",
    values: [
      {
        value: "Above Hip",
        id: "AboveHip",
      },
      {
        value: "Ankle",
        id: "Ankle",
      },
      {
        value: "Below Hip",
        id: "BelowHip",
      },
      {
        value: "Calf",
        id: "Calf",
      },
      {
        value: "Crop",
        id: "Crop",
      },
      {
        value: "Floor",
        id: "Floor",
      },
      {
        value: "High-Low",
        id: "HighLow",
      },
      {
        value: "Hip",
        id: "Hip",
      },
      {
        value: "Knee",
        id: "Knee",
      },
      {
        value: "Micro",
        id: "Micro",
      },
      {
        value: "Micro Mini",
        id: "MicroMini",
      },
      {
        value: "Mid-Thigh",
        id: "MidThigh",
      },
      {
        value: "Midi",
        id: "Midi",
      },
      {
        value: "Mini",
        id: "Mini",
      },
      {
        value: "Short",
        id: "Short",
      },
    ],
  },
  Sleeve: {
    displayName: "Sleeve",
    values: [
      {
        value: "3/4 Sleeve",
        id: "34Sleeve",
      },
      {
        value: "Cap Sleeve",
        id: "CapSleeve",
      },
      {
        value: "Long Sleeve",
        id: "LongSleeve",
      },
      {
        value: "None",
        id: "None",
      },
      {
        value: "Short Sleeve",
        id: "ShortSleeve",
      },
      {
        value: "Sleeveless",
        id: "Sleeveless",
      },
      {
        value: "Strapless",
        id: "Strapless",
      },
    ],
  },
  Maternity: {
    displayName: "Maternity",
    values: [
      {
        value: "Bump Friendly",
        id: "BumpFriendly",
      },
      {
        value: "Not Maternity",
        id: "NotMaternity",
      },
      {
        id: "Nursing",
        value: "Nursing",
      },
      {
        value: "True Maternity",
        id: "TrueMaternity",
      },
    ],
  },
  Embellishment: {
    displayName: "Embellishments",
    values: [
      {
        id: "Applique",
        value: "Applique",
      },
      {
        id: "Bandage",
        value: "Bandage",
      },
      {
        id: "Beaded",
        value: "Beaded",
      },
      {
        id: "Beading",
        value: "Beading",
      },
      {
        id: "Belt",
        value: "Belt",
      },
      {
        id: "Burnout",
        value: "Burnout",
      },
      {
        id: "Buttons",
        value: "Buttons",
      },
      {
        id: "Collar",
        value: "Collar",
      },
      {
        value: "Crystals & Jewels",
        id: "CrystalsJewels",
      },
      {
        id: "Cuff",
        value: "Cuff",
      },
      {
        value: "Cut-Outs",
        id: "CutOuts",
      },
      {
        value: "Denim Trim",
        id: "DenimTrim",
      },
      {
        id: "Distressed",
        value: "Distressed",
      },
      {
        id: "Drawstring",
        value: "Drawstring",
      },
      {
        id: "Embossed",
        value: "Embossed",
      },
      {
        id: "Embroidered",
        value: "Embroidered",
      },
      {
        id: "Embroidery",
        value: "Embroidery",
      },
      {
        id: "Feathers",
        value: "Feathers",
      },
      {
        id: "Flocked",
        value: "Flocked",
      },
      {
        value: "Frayed Edging",
        id: "FrayedEdging",
      },
      {
        id: "Fringe",
        value: "Fringe",
      },
      {
        value: "Fringe/Tassels",
        id: "FringeTassles",
      },
      {
        value: "Fur Trim",
        id: "FurTrim",
      },
      {
        id: "Glitter",
        value: "Glitter",
      },
      {
        id: "Grommets",
        value: "Grommets",
      },
      {
        id: "Hardware",
        value: "Hardware",
      },
      {
        id: "Hood",
        value: "Hood",
      },
      {
        id: "Jacquard",
        value: "Jacquard",
      },
      {
        id: "Lace",
        value: "Lace",
      },
      {
        value: "Lace & Eyelet",
        id: "LaceEyelet",
      },
      {
        value: "Layers/Extra Fabric",
        id: "LayersExtraFabric",
      },
      {
        value: "Leather Trim",
        id: "LeatherTrim",
      },
      {
        value: "Lurex/Metallic Threading",
        id: "LurexMetallicThreading",
      },
      {
        value: "Mesh Overlay",
        id: "MeshOverlay",
      },
      {
        value: "Metal Detailing",
        id: "MetalDetailing",
      },
      {
        value: "Neck Strap",
        id: "NeckStrap",
      },
      {
        id: "None",
        value: "None",
      },
      {
        id: "Pearls",
        value: "Pearls",
      },
      {
        id: "Perforations",
        value: "Perforations",
      },
      {
        id: "Pleats",
        value: "Pleats",
      },
      {
        id: "Pockets",
        value: "Pockets",
      },
      {
        value: "Pom Poms",
        id: "PomPoms",
      },
      {
        id: "Quilted",
        value: "Quilted",
      },
      {
        value: "Raw Hem",
        id: "RawHem",
      },
      {
        id: "Rhinestones",
        value: "Rhinestones",
      },
      {
        id: "Ruffles",
        value: "Ruffles",
      },
      {
        id: "Sash",
        value: "Sash",
      },
      {
        id: "Sequins",
        value: "Sequins",
      },
      {
        id: "Slip",
        value: "Slip",
      },
      {
        id: "Smocking",
        value: "Smocking",
      },
      {
        id: "Studded",
        value: "Studded",
      },
      {
        id: "Studs",
        value: "Studs",
      },
      {
        id: "Tassels",
        value: "Tassels",
      },
      {
        id: "Tentacles",
        value: "Tentacles",
      },
      {
        value: "Vintage Faded",
        id: "VintageFaded",
      },
      {
        id: "Yarndye",
        value: "Yarndye",
      },
    ],
  },
  Neckline: {
    displayName: "Neckline",
    values: [
      {
        id: "Asymmetric",
        value: "Asymmetric",
      },
      {
        value: "Boat Neck",
        id: "BoatNeck",
      },
      {
        value: "Cold Shoulder",
        id: "ColdShoulder",
      },
      {
        id: "Collared",
        value: "Collared",
      },
      {
        value: "Cowl Neck",
        id: "CowlNeck",
      },
      {
        value: "Crew Neck",
        id: "CrewNeck",
      },
      {
        value: "Halter Neck",
        id: "HalterNeck",
      },
      {
        id: "Henley",
        value: "Henley",
      },
      {
        value: "High Neck",
        id: "HighNeck",
      },
      {
        value: "Mock Neck",
        id: "MockNeck",
      },
      {
        id: "None",
        value: "None",
      },
      {
        value: "Off the Shoulder",
        id: "OffTheShoulder",
      },
      {
        value: "One Shoulder",
        id: "OneShoulder",
      },
      {
        value: "Plunge Neck",
        id: "PlungeNeck",
      },
      {
        value: "Quarter Zip",
        id: "QuarterZip",
      },
      {
        value: "Scoop Neck",
        id: "ScoopNeck",
      },
      {
        value: "Split Neck",
        id: "SplitNeck",
      },
      {
        value: "Square Neck",
        id: "SquareNeck",
      },
      {
        id: "Strapless",
        value: "Strapless",
      },
      {
        id: "Sweetheart",
        value: "Sweetheart",
      },
      {
        value: "Tie Neck",
        id: "TieNeck",
      },
      {
        id: "Turtleneck",
        value: "Turtleneck",
      },
      {
        value: "V-Neck",
        id: "VNeck",
      },
    ],
  },
  Designer: {
    displayName: "Designer",
    values: [
      {
        value: "1901",
        id: "1901",
      },
      {
        value: "1 Single Thread",
        id: "1_single_thread",
      },
      {
        value: "1 by O2nd",
        id: "1_by_o2nd",
      },
      {
        value: "3.1 Phillip Lim",
        id: "31_phillip_lim",
      },
      {
        value: "3.1 Phillip Lim Accessories",
        id: "31_phillip_lim_accessories",
      },
      {
        value: "3.1 Phillip Lim x RTR",
        id: "31_phillip_lim_x_rtr",
      },
      {
        value: "360 SWEATER",
        id: "360_sweater",
      },
      {
        value: "3x1",
        id: "3x1",
      },
      {
        value: "4SI3NNA",
        id: "4si3nna",
      },
      {
        value: "4.collective",
        id: "4collective",
      },
      {
        value: "525 America",
        id: "525_america",
      },
      {
        value: "7 For All Mankind",
        id: "7_for_all_mankind",
      },
      {
        value: "A Collective Story",
        id: "a_collective_story",
      },
      {
        value: "ADAM",
        id: "adam",
      },
      {
        value: "ADEAM",
        id: "adeam",
      },
      {
        value: "AFRM",
        id: "afrm",
      },
      {
        value: "AG",
        id: "ag",
      },
      {
        value: "AGAIN Collection",
        id: "again_collection",
      },
      {
        value: "AGOLDE",
        id: "agolde",
      },
      {
        value: "AIIFOS",
        id: "aiifos",
      },
      {
        value: "AKNVAS",
        id: "aknvas",
      },
      {
        value: "A.L.C.",
        id: "alc",
      },
      {
        value: "ALEXACHUNG",
        id: "alexachung",
      },
      {
        value: "ALIÉTTE",
        id: "aliétte",
      },
      {
        value: "ALÉMAIS",
        id: "alémais",
      },
      {
        value: "ALONOVA",
        id: "alonova",
      },
      {
        value: "AMO",
        id: "amo",
      },
      {
        value: "AMSALE",
        id: "amsale",
      },
      {
        value: "AMUR",
        id: "amur",
      },
      {
        value: "A.P.C.",
        id: "apc",
      },
      {
        value: "A.P.C. Accessories",
        id: "apc_accessories",
      },
      {
        value: "A Peace Treaty",
        id: "a_peace_treaty",
      },
      {
        value: "A Pea in the Pod",
        id: "a_pea_in_the_pod",
      },
      {
        value: "AQ/AQ",
        id: "aqaq",
      },
      {
        value: "ARIAS NEW YORK",
        id: "arias_new_york",
      },
      {
        value: "ASHLEY PARK x RTR",
        id: "ashley_park_x_rtr",
      },
      {
        value: "ASKK NY",
        id: "askk_ny",
      },
      {
        value: "ASTR",
        id: "astr",
      },
      {
        value: "AS by DF",
        id: "as_by_df",
      },
      {
        value: "ATM Anthony Thomas Melillo",
        id: "atm_anthony_thomas_melillo",
      },
      {
        value: "ATOÍR",
        id: "atoír",
      },
      {
        value: "ATP Atelier",
        id: "atp_atelier",
      },
      {
        value: "AUDEN",
        id: "auden",
      },
      {
        value: "AV Max",
        id: "av_max",
      },
      {
        value: "AZULU",
        id: "azulu",
      },
      {
        value: "Aalto",
        id: "aalto",
      },
      {
        value: "Acler",
        id: "acler",
      },
      {
        value: "Adam Lippes",
        id: "adam_lippes",
      },
      {
        value: "Adam Lippes x RTR",
        id: "adam_lippes_x_rtr",
      },
      {
        value: "Adelyn Rae",
        id: "adelyn_rae",
      },
      {
        value: "Adia Kibur",
        id: "adia_kibur",
      },
      {
        value: "Adrianna Papell",
        id: "adrianna_papell",
      },
      {
        value: "Adrienne Landau",
        id: "adrienne_landau",
      },
      {
        value: "Agua by Agua Bendita",
        id: "agua_by_agua_bendita",
      },
      {
        value: "Aidan AIDAN MATTOX",
        id: "aidan_aidan_mattox",
      },
      {
        value: "Aidan Mattox",
        id: "aidan_mattox",
      },
      {
        value: "Aijek",
        id: "aijek",
      },
      {
        value: "Aikutzi",
        id: "aikutzi",
      },
      {
        value: "Aisling Camps",
        id: "aisling_camps",
      },
      {
        value: "Aje",
        id: "aje",
      },
      {
        value: "Alaïa",
        id: "alaïa",
      },
      {
        value: "Alberta Ferretti",
        id: "alberta_ferretti",
      },
      {
        value: "Alcoolique",
        id: "alcoolique",
      },
      {
        value: "Aldomartins",
        id: "aldomartins",
      },
      {
        value: "Alexandra Koumba",
        id: "alexandra_koumba",
      },
      {
        value: "Alexia Admor",
        id: "alexia_admor",
      },
      {
        value: "Alexis",
        id: "alexis",
      },
      {
        value: "Alexis Bittar",
        id: "alexis_bittar",
      },
      {
        value: "Ali & Jay",
        id: "ali_jay",
      },
      {
        value: "Ali Ro",
        id: "ali_ro",
      },
      {
        value: "Alice D",
        id: "alice_d",
      },
      {
        value: "Alice + Olivia",
        id: "alice_olivia",
      },
      {
        value: "Alice by Temperley",
        id: "alice_by_temperley",
      },
      {
        value: "Alison Lou",
        id: "alison_lou",
      },
      {
        value: "AllSaints",
        id: "allsaints",
      },
      {
        value: "AllSaints Jewelry",
        id: "allsaints_jewelry",
      },
      {
        value: "All Things Mochi",
        id: "all_things_mochi",
      },
      {
        value: "Allen Schwartz",
        id: "allen_schwartz",
      },
      {
        value: "Alpha Industries",
        id: "alpha_industries",
      },
      {
        value: "Altuzarra",
        id: "altuzarra",
      },
      {
        value: "Amanda Uprichard",
        id: "amanda_uprichard",
      },
      {
        value: "Andamane",
        id: "andamane",
      },
      {
        value: "Andrew Marc",
        id: "andrew_marc",
      },
      {
        value: "Andrés Otálora",
        id: "andrés_otálora",
      },
      {
        value: "Angel Sanchez",
        id: "angel_sanchez",
      },
      {
        value: "Anine Bing",
        id: "anine_bing",
      },
      {
        value: "Ann Mashburn",
        id: "ann_mashburn",
      },
      {
        value: "Anna October",
        id: "anna_october",
      },
      {
        value: "Anna Scholz",
        id: "anna_scholz",
      },
      {
        value: "Anna Sui",
        id: "anna_sui",
      },
      {
        value: "Annabel Ingall",
        id: "annabel_ingall",
      },
      {
        value: "Anndra Neen",
        id: "anndra_neen",
      },
      {
        value: "Anni Lu",
        id: "anni_lu",
      },
      {
        value: "Another Tomorrow",
        id: "another_tomorrow",
      },
      {
        value: "Antik Batik",
        id: "antik_batik",
      },
      {
        value: "Anton Heunis",
        id: "anton_heunis",
      },
      {
        value: "Antonino Valenti",
        id: "antonino_valenti",
      },
      {
        value: "Antonio Berardi",
        id: "antonio_berardi",
      },
      {
        value: "Antonio Grimaldi",
        id: "antonio_grimaldi",
      },
      {
        value: "Anya Hindmarch",
        id: "anya_hindmarch",
      },
      {
        value: "Apiece Apart",
        id: "apiece_apart",
      },
      {
        value: "Apparis",
        id: "apparis",
      },
      {
        value: "Area Stars",
        id: "area_stars",
      },
      {
        value: "Arthur Apparel",
        id: "arthur_apparel",
      },
      {
        value: "Asilio",
        id: "asilio",
      },
      {
        value: "Atlein",
        id: "atlein",
      },
      {
        value: "Atlein x RTR",
        id: "atlein_x_rtr",
      },
      {
        value: "Auguste",
        id: "auguste",
      },
      {
        value: "Autumn Adeigbo",
        id: "autumn_adeigbo",
      },
      {
        value: "Avec Les Filles",
        id: "avec_les_filles",
      },
      {
        value: "Aya Muse",
        id: "aya_muse",
      },
      {
        value: "Azaara",
        id: "azaara",
      },
      {
        value: "Aztech Mountain",
        id: "aztech_mountain",
      },
      {
        value: "BAACAL",
        id: "baacal",
      },
      {
        value: "BAOBAB",
        id: "baobab",
      },
      {
        value: "BASK",
        id: "bask",
      },
      {
        value: "BB Dakota",
        id: "bb_dakota",
      },
      {
        value: "BCBGMAXAZRIA",
        id: "bcbgmaxazria",
      },
      {
        value: "BCBGeneration",
        id: "bcbgeneration",
      },
      {
        value: "B Collection by Bobeau",
        id: "b_collection_by_bobeau",
      },
      {
        value: "BEMBIEN",
        id: "bembien",
      },
      {
        value: "BLANKNYC",
        id: "blanknyc",
      },
      {
        value: "BLAQUE LABEL",
        id: "blaque_label",
      },
      {
        value: "BOGNER FIRE + ICE",
        id: "bogner_fire_ice",
      },
      {
        value: "BOSS",
        id: "boss",
      },
      {
        value: "BOUTIQUE MOSCHINO",
        id: "boutique_moschino",
      },
      {
        value: "BROWN ALLAN",
        id: "brown_allan",
      },
      {
        value: "BUMPSUIT",
        id: "bumpsuit",
      },
      {
        value: "Baciano",
        id: "baciano",
      },
      {
        value: "Bacon",
        id: "bacon",
      },
      {
        value: "Badgely Mischka Shoes",
        id: "badgely_mischka_shoes",
      },
      {
        value: "Badgley Mischka",
        id: "badgley_mischka",
      },
      {
        value: "Badgley Mischka Handbags",
        id: "badgley_mischka_handbags",
      },
      {
        value: "Badgley Mischka Jewelry",
        id: "badgley_mischka_jewelry",
      },
      {
        value: "Bagatelle",
        id: "bagatelle",
      },
      {
        value: "Bailey 44",
        id: "bailey_44",
      },
      {
        value: "Baja East",
        id: "baja_east",
      },
      {
        value: "Balenciaga",
        id: "balenciaga",
      },
      {
        value: "Band of Outsiders",
        id: "band_of_outsiders",
      },
      {
        value: "Banjanan",
        id: "banjanan",
      },
      {
        value: "Barbara Bui",
        id: "barbara_bui",
      },
      {
        value: "Barbara Bui Handbags",
        id: "barbara_bui_handbags",
      },
      {
        value: "Barbour",
        id: "barbour",
      },
      {
        value: "Bardot",
        id: "bardot",
      },
      {
        value: "Bassike",
        id: "bassike",
      },
      {
        value: "Baum und Pferdgarten",
        id: "baum_und_pferdgarten",
      },
      {
        value: "Beare Park",
        id: "beare_park",
      },
      {
        value: "Beatrice .b",
        id: "beatrice_b",
      },
      {
        value: "Beaufille",
        id: "beaufille",
      },
      {
        value: "Bec & Bridge",
        id: "bec_bridge",
      },
      {
        value: "Becken",
        id: "becken",
      },
      {
        value: "BedHead Pajamas",
        id: "bedhead_pajamas",
      },
      {
        value: "Ben-Amun",
        id: "benamun",
      },
      {
        value: "Benjamin Benmoyal",
        id: "benjamin_benmoyal",
      },
      {
        value: "Bernadette",
        id: "bernadette",
      },
      {
        value: "Bibhu Mohapatra",
        id: "bibhu_mohapatra",
      },
      {
        value: "Bibhu Mohapatra x RTR",
        id: "bibhu_mohapatra_x_rtr",
      },
      {
        value: "Black Halo",
        id: "black_halo",
      },
      {
        value: "Blugirl",
        id: "blugirl",
      },
      {
        value: "Blumarine",
        id: "blumarine",
      },
      {
        value: "Boden",
        id: "boden",
      },
      {
        value: "Borgo de Nor",
        id: "borgo_de_nor",
      },
      {
        value: "Botkier",
        id: "botkier",
      },
      {
        value: "Bottega Veneta",
        id: "bottega_veneta",
      },
      {
        value: "Bounkit",
        id: "bounkit",
      },
      {
        value: "Brandon Maxwell",
        id: "brandon_maxwell",
      },
      {
        value: "Braza",
        id: "braza",
      },
      {
        value: "Brian Reyes",
        id: "brian_reyes",
      },
      {
        value: "Brochu Walker",
        id: "brochu_walker",
      },
      {
        value: "Brock Collection",
        id: "brock_collection",
      },
      {
        value: "Brogger",
        id: "brogger",
      },
      {
        value: "Bronx and Banco",
        id: "bronx_and_banco",
      },
      {
        value: "Busayo x RTR",
        id: "busayo_x_rtr",
      },
      {
        value: "By Malene Birger",
        id: "by_malene_birger",
      },
      {
        value: "CAARA",
        id: "caara",
      },
      {
        value: "CALYPSO St. Barth",
        id: "calypso_st_barth",
      },
      {
        value: "CAMI NYC",
        id: "cami_nyc",
      },
      {
        value: "CAPULET",
        id: "capulet",
      },
      {
        value: "CAROLINA K",
        id: "carolina_k",
      },
      {
        value: "CAROLINE CONSTAS",
        id: "caroline_constas",
      },
      {
        value: "CATHERINE DEANE",
        id: "catherine_deane",
      },
      {
        value: "CC Skye",
        id: "cc_skye",
      },
      {
        value: "CHIMI Eyewear",
        id: "chimi_eyewear",
      },
      {
        value: "CÂLLAS",
        id: "câllas",
      },
      {
        value: "C/MEO COLLECTIVE",
        id: "cmeo_collective",
      },
      {
        value: "CRĀS",
        id: "crās",
      },
      {
        value: "CYNTHIA STEFFE",
        id: "cynthia_steffe",
      },
      {
        value: "Ca and Lou",
        id: "ca_and_lou",
      },
      {
        value: "Calvin Klein Collection",
        id: "calvin_klein_collection",
      },
      {
        value: "Campbell",
        id: "campbell",
      },
      {
        value: "Candela",
        id: "candela",
      },
      {
        value: "Capucci",
        id: "capucci",
      },
      {
        value: "Cara Accessories",
        id: "cara_accessories",
      },
      {
        value: "Cara Cara New York",
        id: "cara_cara_new_york",
      },
      {
        value: "Carlos Falchi",
        id: "carlos_falchi",
      },
      {
        value: "Carlos Miele",
        id: "carlos_miele",
      },
      {
        value: "Carmen Marc Valvo",
        id: "carmen_marc_valvo",
      },
      {
        value: "Carolina Herrera",
        id: "carolina_herrera",
      },
      {
        value: "Carolina Ritzler",
        id: "carolina_ritzler",
      },
      {
        value: "Carolina Santo Domingo",
        id: "carolina_santo_domingo",
      },
      {
        value: "Carolina Santo Domingo Accessories",
        id: "carolina_santo_domingo_accessories",
      },
      {
        value: "Carven",
        id: "carven",
      },
      {
        value: "Carven Accessories",
        id: "carven_accessories",
      },
      {
        value: "Caslon",
        id: "caslon",
      },
      {
        value: "Catherine Malandrino",
        id: "catherine_malandrino",
      },
      {
        value: "CeCe",
        id: "cece",
      },
      {
        value: "Cecilie Bahnsen",
        id: "cecilie_bahnsen",
      },
      {
        value: "Cedric Charlier",
        id: "cedric_charlier",
      },
      {
        value: "Cedric Charlier Accessories",
        id: "cedric_charlier_accessories",
      },
      {
        value: "Central Park West",
        id: "central_park_west",
      },
      {
        value: "Chamak by Priya Kakkar",
        id: "chamak_by_priya_kakkar",
      },
      {
        value: "Champion",
        id: "champion",
      },
      {
        value: "Chan Luu",
        id: "chan_luu",
      },
      {
        value: "Charli",
        id: "charli",
      },
      {
        value: "Charo Ruiz",
        id: "charo_ruiz",
      },
      {
        value: "Chelsea28",
        id: "chelsea28",
      },
      {
        value: "Chelsea and Walker",
        id: "chelsea_and_walker",
      },
      {
        value: "Chinti & Parker",
        id: "chinti_parker",
      },
      {
        value: "Chloé",
        id: "chloé",
      },
      {
        value: "Chloé Kids",
        id: "chloé_kids",
      },
      {
        value: "Chris Benz",
        id: "chris_benz",
      },
      {
        value: "Christian Cota",
        id: "christian_cota",
      },
      {
        value: "Christian Pellizzari",
        id: "christian_pellizzari",
      },
      {
        value: "Christian Siriano",
        id: "christian_siriano",
      },
      {
        value: "Christian Siriano Handbags",
        id: "christian_siriano_handbags",
      },
      {
        value: "Christophe Sauvat",
        id: "christophe_sauvat",
      },
      {
        value: "Christy Lynn",
        id: "christy_lynn",
      },
      {
        value: "Chufy",
        id: "chufy",
      },
      {
        value: "Ciao Lucia",
        id: "ciao_lucia",
      },
      {
        value: "Cinder & Charm",
        id: "cinder_charm",
      },
      {
        value: "Ciner",
        id: "ciner",
      },
      {
        value: "Cinq à Sept",
        id: "cinq_à_sept",
      },
      {
        value: "Citizens of Humanity",
        id: "citizens_of_humanity",
      },
      {
        value: "Citrine by the Stones",
        id: "citrine_by_the_stones",
      },
      {
        value: "City Chic",
        id: "city_chic",
      },
      {
        value: "Clare V.",
        id: "clare_v",
      },
      {
        value: "Cleobella",
        id: "cleobella",
      },
      {
        value: "Cleobella Handbags",
        id: "cleobella_handbags",
      },
      {
        value: "Céline",
        id: "céline",
      },
      {
        value: "Clover Canyon",
        id: "clover_canyon",
      },
      {
        value: "Clu",
        id: "clu",
      },
      {
        value: "Club Monaco",
        id: "club_monaco",
      },
      {
        value: "Co",
        id: "co",
      },
      {
        value: "Coach",
        id: "coach",
      },
      {
        value: "Coach Handbags",
        id: "coach_handbags",
      },
      {
        value: "Coco and Breezy",
        id: "coco_and_breezy",
      },
      {
        value: "Cole Haan Accessories",
        id: "cole_haan_accessories",
      },
      {
        value: "Colette Malouf",
        id: "colette_malouf",
      },
      {
        value: "Color Me Courtney",
        id: "color_me_courtney",
      },
      {
        value: "Colovos",
        id: "colovos",
      },
      {
        value: "Commando",
        id: "commando",
      },
      {
        value: "Commission",
        id: "commission",
      },
      {
        value: "Contrarian",
        id: "contrarian",
      },
      {
        value: "Cooper & Ella",
        id: "cooper_ella",
      },
      {
        value: "Cooper Street",
        id: "cooper_street",
      },
      {
        value: "Coperni",
        id: "coperni",
      },
      {
        value: "Coralia Leets",
        id: "coralia_leets",
      },
      {
        value: "Corey",
        id: "corey",
      },
      {
        value: "Cornelia Webb",
        id: "cornelia_webb",
      },
      {
        value: "Cosmopolitan X DTP",
        id: "cosmopolitan_x_dtp",
      },
      {
        value: "Courréges",
        id: "courréges",
      },
      {
        value: "Courtney Lee",
        id: "courtney_lee",
      },
      {
        value: "Crewcuts by J.Crew",
        id: "crewcuts_by_jcrew",
      },
      {
        value: "Crislu",
        id: "crislu",
      },
      {
        value: "Cristina V",
        id: "cristina_v",
      },
      {
        value: "Crystal Haze Jewelry",
        id: "crystal_haze_jewelry",
      },
      {
        value: "Cult Gaia",
        id: "cult_gaia",
      },
      {
        value: "Cult Gaia Accessories",
        id: "cult_gaia_accessories",
      },
      {
        value: "Current/Elliott",
        id: "currentelliott",
      },
      {
        value: "Cushnie",
        id: "cushnie",
      },
      {
        value: "Cut 25",
        id: "cut_25",
      },
      {
        value: "Cynthia Rowley",
        id: "cynthia_rowley",
      },
      {
        value: "Cynthia Rowley x RTR",
        id: "cynthia_rowley_x_rtr",
      },
      {
        value: "Cynthia Rowley Tights",
        id: "cynthia_rowley_tights",
      },
      {
        value: "DAYDREAMER",
        id: "daydreamer",
      },
      {
        value: "DELFI x RTR",
        id: "delfi_x_rtr",
      },
      {
        value: "DEMARSON",
        id: "demarson",
      },
      {
        value: "DEMARSON Accessories",
        id: "demarson_accessories",
      },
      {
        value: "DEREK LAM",
        id: "derek_lam",
      },
      {
        value: "DEREK LAM Sunglasses",
        id: "derek_lam_sunglasses",
      },
      {
        value: "DESTREE",
        id: "destree",
      },
      {
        value: "D&G by Dolce and Gabbana",
        id: "dg_by_dolce_and_gabbana",
      },
      {
        value: "DL1961",
        id: "dl1961",
      },
      {
        value: "D Mondaine",
        id: "d_mondaine",
      },
      {
        value: "DOMA",
        id: "doma",
      },
      {
        value: "DONNI.",
        id: "donni",
      },
      {
        value: "DRAE",
        id: "drae",
      },
      {
        value: "DREW",
        id: "drew",
      },
      {
        value: "DREYDEN",
        id: "dreyden",
      },
      {
        value: "DRKSHDW",
        id: "drkshdw",
      },
      {
        value: "DSQUARED2",
        id: "dsquared2",
      },
      {
        value: "DUNST",
        id: "dunst",
      },
      {
        value: "DVF x Rockets of Awesome Kids",
        id: "dvf_x_rockets_of_awesome_kids",
      },
      {
        value: "Daniel Vosovic",
        id: "daniel_vosovic",
      },
      {
        value: "Danielle Nicole",
        id: "danielle_nicole",
      },
      {
        value: "Dannijo",
        id: "dannijo",
      },
      {
        value: "Danse Lente",
        id: "danse_lente",
      },
      {
        value: "Daughter",
        id: "daughter",
      },
      {
        value: "David Koma",
        id: "david_koma",
      },
      {
        value: "David Meister",
        id: "david_meister",
      },
      {
        value: "De La Vali",
        id: "de_la_vali",
      },
      {
        value: "DeMellier",
        id: "demellier",
      },
      {
        value: "Dea Kudibal",
        id: "dea_kudibal",
      },
      {
        value: "Deborah Lyons",
        id: "deborah_lyons",
      },
      {
        value: "Deby Debo",
        id: "deby_debo",
      },
      {
        value: "Decades Vintage",
        id: "decades_vintage",
      },
      {
        value: "Deepa Gurnani",
        id: "deepa_gurnani",
      },
      {
        value: "Denim & Bone",
        id: "denim_bone",
      },
      {
        value: "Denimist",
        id: "denimist",
      },
      {
        value: "Derek Lam 10 Crosby",
        id: "derek_lam_10_crosby",
      },
      {
        value: "Derek Lam 10 Crosby Accessories",
        id: "derek_lam_10_crosby_accessories",
      },
      {
        value: "Derek Lam 10 Crosby x RTR",
        id: "derek_lam_10_crosby_x_rtr",
      },
      {
        value: "Devotion",
        id: "devotion",
      },
      {
        value: "Diane Cotton Jewelry",
        id: "diane_cotton_jewelry",
      },
      {
        value: "Diane von Furstenberg",
        id: "diane_von_furstenberg",
      },
      {
        value: "Diane von Furstenberg Handbags",
        id: "diane_von_furstenberg_handbags",
      },
      {
        value: "Dina Agam",
        id: "dina_agam",
      },
      {
        value: "Diotima",
        id: "diotima",
      },
      {
        value: "Divine Heritage",
        id: "divine_heritage",
      },
      {
        value: "Dodo Bar Or",
        id: "dodo_bar_or",
      },
      {
        value: "Dodo Bar Or Kids",
        id: "dodo_bar_or_kids",
      },
      {
        value: "Donna Karan New York",
        id: "donna_karan_new_york",
      },
      {
        value: "Donna Morgan",
        id: "donna_morgan",
      },
      {
        value: "Donni Charm",
        id: "donni_charm",
      },
      {
        value: "Doo.ri",
        id: "doori",
      },
      {
        value: "Draper James",
        id: "draper_james",
      },
      {
        value: "Draper James Accessories",
        id: "draper_james_accessories",
      },
      {
        value: "Draper James X ELOQUII",
        id: "draper_james_x_eloquii",
      },
      {
        value: "Dress The Population",
        id: "dress_the_population",
      },
      {
        value: "Dries Van Noten",
        id: "dries_van_noten",
      },
      {
        value: "Dsquared Accessories",
        id: "dsquared_accessories",
      },
      {
        value: "Dualist",
        id: "dualist",
      },
      {
        value: "Dubgee Forum by Whoopi",
        id: "dubgee_forum_by_whoopi",
      },
      {
        value: "E2E-Test15f7",
        id: "e2etest15f7",
      },
      {
        value: "E2E-Test29e6",
        id: "e2etest29e6",
      },
      {
        value: "E2E-Test4044",
        id: "e2etest4044",
      },
      {
        value: "E2E-Test88ae",
        id: "e2etest88ae",
      },
      {
        value: "E2E-Testa4af",
        id: "e2etesta4af",
      },
      {
        value: "E2E-Testb031",
        id: "e2etestb031",
      },
      {
        value: "EB Denim",
        id: "eb_denim",
      },
      {
        value: "EILEEN FISHER",
        id: "eileen_fisher",
      },
      {
        value: "ELLERY",
        id: "ellery",
      },
      {
        value: "ELLERY Accessories",
        id: "ellery_accessories",
      },
      {
        value: "ELLIATT",
        id: "elliatt",
      },
      {
        value: "ELOQUII",
        id: "eloquii",
      },
      {
        value: "EMMA COOK",
        id: "emma_cook",
      },
      {
        value: "ENA PELLY",
        id: "ena_pelly",
      },
      {
        value: "ERIN erin fetherston",
        id: "erin_erin_fetherston",
      },
      {
        value: "E.STOTT",
        id: "estott",
      },
      {
        value: "ETRO",
        id: "etro",
      },
      {
        value: "EUDON CHOI",
        id: "eudon_choi",
      },
      {
        value: "EVIDNT",
        id: "evidnt",
      },
      {
        value: "Echo",
        id: "echo",
      },
      {
        value: "Echo Accessories",
        id: "echo_accessories",
      },
      {
        value: "Eddie Borgo",
        id: "eddie_borgo",
      },
      {
        value: "Elie Tahari",
        id: "elie_tahari",
      },
      {
        value: "Elise M.",
        id: "elise_m",
      },
      {
        value: "Eliza Faulkner",
        id: "eliza_faulkner",
      },
      {
        value: "Elizabeth Cole",
        id: "elizabeth_cole",
      },
      {
        value: "Elizabeth and James",
        id: "elizabeth_and_james",
      },
      {
        value: "Elizabeth and James Accessories",
        id: "elizabeth_and_james_accessories",
      },
      {
        value: "Elk",
        id: "elk",
      },
      {
        value: "Ella Carter",
        id: "ella_carter",
      },
      {
        value: "Ellen Tracy",
        id: "ellen_tracy",
      },
      {
        value: "Ellie Mae",
        id: "ellie_mae",
      },
      {
        value: "Emanuel Ungaro",
        id: "emanuel_ungaro",
      },
      {
        value: "Emilia George",
        id: "emilia_george",
      },
      {
        value: "Emily Lovelock",
        id: "emily_lovelock",
      },
      {
        value: "Emporio Armani",
        id: "emporio_armani",
      },
      {
        value: "En Saison",
        id: "en_saison",
      },
      {
        value: "Endless Rose",
        id: "endless_rose",
      },
      {
        value: "English Factory",
        id: "english_factory",
      },
      {
        value: "Equipment",
        id: "equipment",
      },
      {
        value: "Erickson Beamon",
        id: "erickson_beamon",
      },
      {
        value: "Escada",
        id: "escada",
      },
      {
        value: "Especia",
        id: "especia",
      },
      {
        value: "Essentiel Antwerp",
        id: "essentiel_antwerp",
      },
      {
        value: "Esteban Cortazar",
        id: "esteban_cortazar",
      },
      {
        value: "Esteban Cortazar x RTR",
        id: "esteban_cortazar_x_rtr",
      },
      {
        value: "Et Ochs",
        id: "et_ochs",
      },
      {
        value: "Ettika",
        id: "ettika",
      },
      {
        value: "Eudon Choi x RTR",
        id: "eudon_choi_x_rtr",
      },
      {
        value: "Eugenia Kim",
        id: "eugenia_kim",
      },
      {
        value: "Eva Franco",
        id: "eva_franco",
      },
      {
        value: "Ever by Happily Ever Borrowed",
        id: "ever_by_happily_ever_borrowed",
      },
      {
        value: "Eylure",
        id: "eylure",
      },
      {
        value: "FAHERTY BRAND",
        id: "faherty_brand",
      },
      {
        value: "FARM Rio",
        id: "farm_rio",
      },
      {
        value: "FAVIANA",
        id: "faviana",
      },
      {
        value: "FILA",
        id: "fila",
      },
      {
        value: "FINDERS KEEPERS",
        id: "finders_keepers",
      },
      {
        value: "FOR 2 by Ramy Brook",
        id: "for_2_by_ramy_brook",
      },
      {
        value: "Faith Connexion",
        id: "faith_connexion",
      },
      {
        value: "Fallon",
        id: "fallon",
      },
      {
        value: "Fallon Accessories",
        id: "fallon_accessories",
      },
      {
        value: "Fame & Partners",
        id: "fame_partners",
      },
      {
        value: "Favorite Daughter",
        id: "favorite_daughter",
      },
      {
        value: "Fe Noel",
        id: "fe_noel",
      },
      {
        value: "Felipe Oliveira Baptista",
        id: "felipe_oliveira_baptista",
      },
      {
        value: "Felix Rey",
        id: "felix_rey",
      },
      {
        value: "Fendi Kids",
        id: "fendi_kids",
      },
      {
        value: "Fifteen Twenty",
        id: "fifteen_twenty",
      },
      {
        value: "Figue",
        id: "figue",
      },
      {
        value: "Fleur du Mal",
        id: "fleur_du_mal",
      },
      {
        value: "Fleurtine",
        id: "fleurtine",
      },
      {
        value: "Flynn Skye",
        id: "flynn_skye",
      },
      {
        value: "Foley + Corinna",
        id: "foley_corinna",
      },
      {
        value: "Foley and Corinna",
        id: "foley_and_corinna",
      },
      {
        value: "For Love and Lemons",
        id: "for_love_and_lemons",
      },
      {
        value: "Fragments",
        id: "fragments",
      },
      {
        value: "Francesco Scognamiglio",
        id: "francesco_scognamiglio",
      },
      {
        value: "Francesco Scognamiglio Accessories",
        id: "francesco_scognamiglio_accessories",
      },
      {
        value: "Franchi",
        id: "franchi",
      },
      {
        value: "Frank Tell",
        id: "frank_tell",
      },
      {
        value: "Free People",
        id: "free_people",
      },
      {
        value: "Frends",
        id: "frends",
      },
      {
        value: "Furla",
        id: "furla",
      },
      {
        value: "Fuzzi",
        id: "fuzzi",
      },
      {
        value: "GABRIELA CADENA",
        id: "gabriela_cadena",
      },
      {
        value: "GALVAN",
        id: "galvan",
      },
      {
        value: "GANNI",
        id: "ganni",
      },
      {
        value: "GAUGE81",
        id: "gauge81",
      },
      {
        value: "GIAQUINTO",
        id: "giaquinto",
      },
      {
        value: "GILNER FARRAR",
        id: "gilner_farrar",
      },
      {
        value: "GOOD AMERICAN",
        id: "good_american",
      },
      {
        value: "Gabriele Colangelo",
        id: "gabriele_colangelo",
      },
      {
        value: "Garrett Leight",
        id: "garrett_leight",
      },
      {
        value: "Gas Bijoux",
        id: "gas_bijoux",
      },
      {
        value: "GeBe Maternity",
        id: "gebe_maternity",
      },
      {
        value: "Gemma Redux",
        id: "gemma_redux",
      },
      {
        value: "Generation Love",
        id: "generation_love",
      },
      {
        value: "Genny",
        id: "genny",
      },
      {
        value: "Gerard Darel",
        id: "gerard_darel",
      },
      {
        value: "Gerard Yosca",
        id: "gerard_yosca",
      },
      {
        value: "Giamba",
        id: "giamba",
      },
      {
        value: "Giambattista Valli",
        id: "giambattista_valli",
      },
      {
        value: "Gigi New York",
        id: "gigi_new_york",
      },
      {
        value: "Giles",
        id: "giles",
      },
      {
        value: "Giles & Brother",
        id: "giles_brother",
      },
      {
        value: "Gillian Steinhardt",
        id: "gillian_steinhardt",
      },
      {
        value: "Giovanna Nicolai",
        id: "giovanna_nicolai",
      },
      {
        value: "Goen. J",
        id: "goen_j",
      },
      {
        value: "Golden Goose Deluxe Brand",
        id: "golden_goose_deluxe_brand",
      },
      {
        value: "Good Charma",
        id: "good_charma",
      },
      {
        value: "Gorjana",
        id: "gorjana",
      },
      {
        value: "Gorjana Accessories",
        id: "gorjana_accessories",
      },
      {
        value: "Grace Willow The Label",
        id: "grace_willow_the_label",
      },
      {
        value: "Great Jones",
        id: "great_jones",
      },
      {
        value: "Greenbeads Couture",
        id: "greenbeads_couture",
      },
      {
        value: "Grey Ant",
        id: "grey_ant",
      },
      {
        value: "Greylin",
        id: "greylin",
      },
      {
        value: "Gryphon",
        id: "gryphon",
      },
      {
        value: "Gucci",
        id: "gucci",
      },
      {
        value: "HAH",
        id: "hah",
      },
      {
        value: "HALSTON",
        id: "halston",
      },
      {
        value: "HALSTON Handbags",
        id: "halston_handbags",
      },
      {
        value: "HANEY",
        id: "haney",
      },
      {
        value: "HARSHMAN",
        id: "harshman",
      },
      {
        value: "HATCH",
        id: "hatch",
      },
      {
        value: "HEARTLOOM",
        id: "heartloom",
      },
      {
        value: "HEROINE SPORT",
        id: "heroine_sport",
      },
      {
        value: "HVN",
        id: "hvn",
      },
      {
        value: "Habitual",
        id: "habitual",
      },
      {
        value: "Haider Ackermann",
        id: "haider_ackermann",
      },
      {
        value: "Halfdays",
        id: "halfdays",
      },
      {
        value: "Halogen",
        id: "halogen",
      },
      {
        value: "Hanii Y",
        id: "hanii_y",
      },
      {
        value: "Hanky Panky",
        id: "hanky_panky",
      },
      {
        value: "Harlyn",
        id: "harlyn",
      },
      {
        value: "Harris Wharf London",
        id: "harris_wharf_london",
      },
      {
        value: "Harrison by Hunter Bell",
        id: "harrison_by_hunter_bell",
      },
      {
        value: "Harvey Faircloth",
        id: "harvey_faircloth",
      },
      {
        value: "Haute Hippie",
        id: "haute_hippie",
      },
      {
        value: "Hed Mayner",
        id: "hed_mayner",
      },
      {
        value: "Helmut Lang",
        id: "helmut_lang",
      },
      {
        value: "Helmut Lang Handbags",
        id: "helmut_lang_handbags",
      },
      {
        value: "Hemant & Nandita",
        id: "hemant_nandita",
      },
      {
        value: "Henri Bendel",
        id: "henri_bendel",
      },
      {
        value: "Hereu",
        id: "hereu",
      },
      {
        value: "Hervé By Hervé Léger",
        id: "hervé_by_hervé_léger",
      },
      {
        value: "Hervé Léger",
        id: "hervé_léger",
      },
      {
        value: "Heurueh",
        id: "heurueh",
      },
      {
        value: "Hill House Home",
        id: "hill_house_home",
      },
      {
        value: "Hofmann Copenhagen",
        id: "hofmann_copenhagen",
      },
      {
        value: "Holden",
        id: "holden",
      },
      {
        value: "Hollywood Fashion Secrets",
        id: "hollywood_fashion_secrets",
      },
      {
        value: "Holzweiler",
        id: "holzweiler",
      },
      {
        value: "Honor",
        id: "honor",
      },
      {
        value: "Horn Please!",
        id: "horn_please",
      },
      {
        value: "Hosbjerg",
        id: "hosbjerg",
      },
      {
        value: "House of Harlow 1960",
        id: "house_of_harlow_1960",
      },
      {
        value: "House of Sunny",
        id: "house_of_sunny",
      },
      {
        value: "Hudson",
        id: "hudson",
      },
      {
        value: "Hue",
        id: "hue",
      },
      {
        value: "Hunter",
        id: "hunter",
      },
      {
        value: "Hunter Bell",
        id: "hunter_bell",
      },
      {
        value: "Hunter Handbags",
        id: "hunter_handbags",
      },
      {
        value: "Hutch",
        id: "hutch",
      },
      {
        value: "INA",
        id: "ina",
      },
      {
        value: "INTER-PRET.US",
        id: "interpretus",
      },
      {
        value: "Ieena for Mac Duggal",
        id: "ieena_for_mac_duggal",
      },
      {
        value: "Igigi",
        id: "igigi",
      },
      {
        value: "Il Gufo Kids",
        id: "il_gufo_kids",
      },
      {
        value: "Illesteva",
        id: "illesteva",
      },
      {
        value: "Indulgems",
        id: "indulgems",
      },
      {
        value: "Inge Christopher",
        id: "inge_christopher",
      },
      {
        value: "Ingrid & Isabel",
        id: "ingrid_isabel",
      },
      {
        value: "Iro",
        id: "iro",
      },
      {
        value: "Isabella Oliver",
        id: "isabella_oliver",
      },
      {
        value: "I.scenery",
        id: "iscenery",
      },
      {
        value: "Isharya",
        id: "isharya",
      },
      {
        value: "Issa",
        id: "issa",
      },
      {
        value: "JADEtribe",
        id: "jadetribe",
      },
      {
        value: "J BRAND",
        id: "j_brand",
      },
      {
        value: "J.Crew",
        id: "jcrew",
      },
      {
        value: "J.Crew Accessories",
        id: "jcrew_accessories",
      },
      {
        value: "JENNY BIRD",
        id: "jenny_bird",
      },
      {
        value: "JET John Eshaya",
        id: "jet_john_eshaya",
      },
      {
        value: "J. Mendel",
        id: "j_mendel",
      },
      {
        value: "J.O.A.",
        id: "joa",
      },
      {
        value: "JOSEPH",
        id: "joseph",
      },
      {
        value: "JS Collection",
        id: "js_collection",
      },
      {
        value: "JUNAROSE",
        id: "junarose",
      },
      {
        value: "JW Anderson",
        id: "jw_anderson",
      },
      {
        value: "JW Anderson Accessories",
        id: "jw_anderson_accessories",
      },
      {
        value: "Janis Savitt",
        id: "janis_savitt",
      },
      {
        value: "Jason Wu",
        id: "jason_wu",
      },
      {
        value: "Jason Wu Accessories",
        id: "jason_wu_accessories",
      },
      {
        value: "Jason Wu Collection",
        id: "jason_wu_collection",
      },
      {
        value: "Jason Wu x RTR",
        id: "jason_wu_x_rtr",
      },
      {
        value: "Jason Wu x ELOQUII",
        id: "jason_wu_x_eloquii",
      },
      {
        value: "Jay Godfrey",
        id: "jay_godfrey",
      },
      {
        value: "Jenni Kayne",
        id: "jenni_kayne",
      },
      {
        value: "Jenny Packham",
        id: "jenny_packham",
      },
      {
        value: "Jenny Yoo",
        id: "jenny_yoo",
      },
      {
        value: "Jerome Dreyfuss",
        id: "jerome_dreyfuss",
      },
      {
        value: "Jerome Dreyfuss Accessories",
        id: "jerome_dreyfuss_accessories",
      },
      {
        value: "Jewel x Badgley Mischka",
        id: "jewel_x_badgley_mischka",
      },
      {
        value: "Jil Sander Navy",
        id: "jil_sander_navy",
      },
      {
        value: "Jil Sander Navy Handbags",
        id: "jil_sander_navy_handbags",
      },
      {
        value: "Jill Jill Stuart",
        id: "jill_jill_stuart",
      },
      {
        value: "Jill Stuart",
        id: "jill_stuart",
      },
      {
        value: "Joanna Laura Constantine",
        id: "joanna_laura_constantine",
      },
      {
        value: "Jocelyn",
        id: "jocelyn",
      },
      {
        value: "Jocelyn Outerwear",
        id: "jocelyn_outerwear",
      },
      {
        value: "Joe's Jeans",
        id: "joes_jeans",
      },
      {
        value: "John + Jenn",
        id: "john_jenn",
      },
      {
        value: "Joie",
        id: "joie",
      },
      {
        value: "Jordache",
        id: "jordache",
      },
      {
        value: "Josh Goot",
        id: "josh_goot",
      },
      {
        value: "Josie",
        id: "josie",
      },
      {
        value: "Josie Natori",
        id: "josie_natori",
      },
      {
        value: "Josie by Natori",
        id: "josie_by_natori",
      },
      {
        value: "Juan Carlos Obando",
        id: "juan_carlos_obando",
      },
      {
        value: "Judith & Charles",
        id: "judith_charles",
      },
      {
        value: "Judith Leiber",
        id: "judith_leiber",
      },
      {
        value: "Jules Smith",
        id: "jules_smith",
      },
      {
        value: "Julia Jordan",
        id: "julia_jordan",
      },
      {
        value: "Juliet & Company",
        id: "juliet_company",
      },
      {
        value: "Just BEE Queen",
        id: "just_bee_queen",
      },
      {
        value: "Just Cavalli",
        id: "just_cavalli",
      },
      {
        value: "Just Female",
        id: "just_female",
      },
      {
        value: "KASSL EDITIONS",
        id: "kassl_editions",
      },
      {
        value: "KAUFMANFRANCO",
        id: "kaufmanfranco",
      },
      {
        value: "KENDALL + KYLIE",
        id: "kendall_kylie",
      },
      {
        value: "KF/KaufmanFranco x RTR",
        id: "kfkaufmanfranco_x_rtr",
      },
      {
        value: "KINLY",
        id: "kinly",
      },
      {
        value: "KIVARI",
        id: "kivari",
      },
      {
        value: "K.NGSLEY",
        id: "kngsley",
      },
      {
        value: "KULE",
        id: "kule",
      },
      {
        value: "KULT",
        id: "kult",
      },
      {
        value: "Kahindo",
        id: "kahindo",
      },
      {
        value: "Kara Ross",
        id: "kara_ross",
      },
      {
        value: "Karen Millen",
        id: "karen_millen",
      },
      {
        value: "Karen Walker",
        id: "karen_walker",
      },
      {
        value: "Karen Zambos",
        id: "karen_zambos",
      },
      {
        value: "Karina Grimaldi",
        id: "karina_grimaldi",
      },
      {
        value: "Karolina Zmarlak",
        id: "karolina_zmarlak",
      },
      {
        value: "Kate Ford",
        id: "kate_ford",
      },
      {
        value: "Kate Spade Sunglasses",
        id: "kate_spade_sunglasses",
      },
      {
        value: "Katherine Kwei",
        id: "katherine_kwei",
      },
      {
        value: "Katie May",
        id: "katie_may",
      },
      {
        value: "Kay Unger",
        id: "kay_unger",
      },
      {
        value: "Keepsake",
        id: "keepsake",
      },
      {
        value: "Kendra Scott",
        id: "kendra_scott",
      },
      {
        value: "Kenneth Ize",
        id: "kenneth_ize",
      },
      {
        value: "Kenneth Jay Lane",
        id: "kenneth_jay_lane",
      },
      {
        value: "Khaite",
        id: "khaite",
      },
      {
        value: "Kiki de Montparnasse",
        id: "kiki_de_montparnasse",
      },
      {
        value: "Kimberly Ovitz",
        id: "kimberly_ovitz",
      },
      {
        value: "Kobi Halperin",
        id: "kobi_halperin",
      },
      {
        value: "Koché",
        id: "koché",
      },
      {
        value: "Koché x RTR",
        id: "koché_x_rtr",
      },
      {
        value: "Kooreloo",
        id: "kooreloo",
      },
      {
        value: "Koral",
        id: "koral",
      },
      {
        value: "Kotur",
        id: "kotur",
      },
      {
        value: "Ksubi",
        id: "ksubi",
      },
      {
        value: "LAMARQUE",
        id: "lamarque",
      },
      {
        value: "LANCÔME",
        id: "lancôme",
      },
      {
        value: "LAPOINTE",
        id: "lapointe",
      },
      {
        value: "L'Academie",
        id: "lacademie",
      },
      {
        value: "LDT",
        id: "ldt",
      },
      {
        value: "LEE",
        id: "lee",
      },
      {
        value: "LE JEAN",
        id: "le_jean",
      },
      {
        value: "L'IDÉE",
        id: "lidée",
      },
      {
        value: "LIDO",
        id: "lido",
      },
      {
        value: "LIKELY",
        id: "likely",
      },
      {
        value: "L.K. Bennett",
        id: "lk_bennett",
      },
      {
        value: "L.K. Bennett Accessories",
        id: "lk_bennett_accessories",
      },
      {
        value: "LM Collection",
        id: "lm_collection",
      },
      {
        value: "LNA",
        id: "lna",
      },
      {
        value: "LNDR",
        id: "lndr",
      },
      {
        value: "LOST INK",
        id: "lost_ink",
      },
      {
        value: "LVIR",
        id: "lvir",
      },
      {
        value: "La DoubleJ",
        id: "la_doublej",
      },
      {
        value: "La Maison Talulah",
        id: "la_maison_talulah",
      },
      {
        value: "La Petite Robe di Chiara Boni",
        id: "la_petite_robe_di_chiara_boni",
      },
      {
        value: "LaQuan Smith",
        id: "laquan_smith",
      },
      {
        value: "LaROK",
        id: "larok",
      },
      {
        value: "La Vie Rebecca Taylor",
        id: "la_vie_rebecca_taylor",
      },
      {
        value: "Labo.Art",
        id: "laboart",
      },
      {
        value: "Lacausa",
        id: "lacausa",
      },
      {
        value: "Lady Grey",
        id: "lady_grey",
      },
      {
        value: "Lafayette 148 New York",
        id: "lafayette_148_new_york",
      },
      {
        value: "L'agence",
        id: "lagence",
      },
      {
        value: "Laila Azhar",
        id: "laila_azhar",
      },
      {
        value: "Landero",
        id: "landero",
      },
      {
        value: "Laundry by Shelli Segal",
        id: "laundry_by_shelli_segal",
      },
      {
        value: "Lauren Merkin",
        id: "lauren_merkin",
      },
      {
        value: "Lauren Ralph Lauren",
        id: "lauren_ralph_lauren",
      },
      {
        value: "Lauren Ralph Lauren Jewelry",
        id: "lauren_ralph_lauren_jewelry",
      },
      {
        value: "Lavand.",
        id: "lavand",
      },
      {
        value: "Laveer",
        id: "laveer",
      },
      {
        value: "Lavish Alice",
        id: "lavish_alice",
      },
      {
        value: "Le Specs",
        id: "le_specs",
      },
      {
        value: "Le Specs x Adam Selman",
        id: "le_specs_x_adam_selman",
      },
      {
        value: "Le Superbe",
        id: "le_superbe",
      },
      {
        value: "Lee Angel",
        id: "lee_angel",
      },
      {
        value: "Lela Rose",
        id: "lela_rose",
      },
      {
        value: "Lele Sadoughi",
        id: "lele_sadoughi",
      },
      {
        value: "Leota",
        id: "leota",
      },
      {
        value: "Les Petits Joueurs",
        id: "les_petits_joueurs",
      },
      {
        value: "Les Reveries",
        id: "les_reveries",
      },
      {
        value: "Levi's",
        id: "levis",
      },
      {
        value: "Liebeskind",
        id: "liebeskind",
      },
      {
        value: "Lilly Pulitzer",
        id: "lilly_pulitzer",
      },
      {
        value: "Lilly Pulitzer Accessories",
        id: "lilly_pulitzer_accessories",
      },
      {
        value: "Lilly Pulitzer Handbags",
        id: "lilly_pulitzer_handbags",
      },
      {
        value: "Lilly Pulitzer Kids",
        id: "lilly_pulitzer_kids",
      },
      {
        value: "Linda Farrow",
        id: "linda_farrow",
      },
      {
        value: "Linda Farrow x Matthew Williamson",
        id: "linda_farrow_x_matthew_williamson",
      },
      {
        value: "Linda Richards",
        id: "linda_richards",
      },
      {
        value: "Line + Dot",
        id: "line_dot",
      },
      {
        value: "Lingua Franca",
        id: "lingua_franca",
      },
      {
        value: "Lioness",
        id: "lioness",
      },
      {
        value: "Lisa Freede",
        id: "lisa_freede",
      },
      {
        value: "Lisa Says Gah",
        id: "lisa_says_gah",
      },
      {
        value: "Little Goodall",
        id: "little_goodall",
      },
      {
        value: "Liffner",
        id: "liffner",
      },
      {
        value: "Little Marc Jacobs",
        id: "little_marc_jacobs",
      },
      {
        value: "Liv Foster",
        id: "liv_foster",
      },
      {
        value: "Lizzie Fortunato",
        id: "lizzie_fortunato",
      },
      {
        value: "LoboRosa",
        id: "loborosa",
      },
      {
        value: "Loeffler Randall",
        id: "loeffler_randall",
      },
      {
        value: "Loren Olivia",
        id: "loren_olivia",
      },
      {
        value: "Louna",
        id: "louna",
      },
      {
        value: "Love Moschino Accessories",
        id: "love_moschino_accessories",
      },
      {
        value: "LoveShackFancy",
        id: "loveshackfancy",
      },
      {
        value: "LoveShack Girls",
        id: "loveshack_girls",
      },
      {
        value: "Love, Whit by Whitney Port",
        id: "love_whit_by_whitney_port",
      },
      {
        value: "Love by Theia",
        id: "love_by_theia",
      },
      {
        value: "Lovers + Friends",
        id: "lovers_friends",
      },
      {
        value: "Luiny",
        id: "luiny",
      },
      {
        value: "Lulu Frost",
        id: "lulu_frost",
      },
      {
        value: "Lululemon",
        id: "lululemon",
      },
      {
        value: "Luv Aj",
        id: "luv_aj",
      },
      {
        value: "MADDERSON LONDON",
        id: "madderson_london",
      },
      {
        value: "MADE BY RTR",
        id: "made_by_rtr",
      },
      {
        value: "MAISON ETNAD",
        id: "maison_etnad",
      },
      {
        value: "MAJESTIC FILATURES",
        id: "majestic_filatures",
      },
      {
        value: "MARCOBOLOGNA",
        id: "marcobologna",
      },
      {
        value: "MARYLING",
        id: "maryling",
      },
      {
        value: "MARYSIA",
        id: "marysia",
      },
      {
        value: "MASON HOSKER",
        id: "mason_hosker",
      },
      {
        value: "MDS Stripes",
        id: "mds_stripes",
      },
      {
        value: "MIAOU",
        id: "miaou",
      },
      {
        value: "MICHAEL KORS",
        id: "michael_kors",
      },
      {
        value: "MICHI",
        id: "michi",
      },
      {
        value: "MILLE",
        id: "mille",
      },
      {
        value: "MINKPINK",
        id: "minkpink",
      },
      {
        value: "MISA Los Angeles",
        id: "misa_los_angeles",
      },
      {
        value: "MLM Label",
        id: "mlm_label",
      },
      {
        value: "ML Monique Lhuillier",
        id: "ml_monique_lhuillier",
      },
      {
        value: "MM6 Maison Margiela",
        id: "mm6_maison_margiela",
      },
      {
        value: "M.M.LaFleur",
        id: "mmlafleur",
      },
      {
        value: "M Missoni",
        id: "m_missoni",
      },
      {
        value: "MONROW",
        id: "monrow",
      },
      {
        value: "MOTHER",
        id: "mother",
      },
      {
        value: "MOUSSY VINTAGE",
        id: "moussy_vintage",
      },
      {
        value: "MSGM",
        id: "msgm",
      },
      {
        value: "MSGM Handbags",
        id: "msgm_handbags",
      },
      {
        value: "MUGLER",
        id: "mugler",
      },
      {
        value: "Machete",
        id: "machete",
      },
      {
        value: "Mackage",
        id: "mackage",
      },
      {
        value: "Mackage Handbags",
        id: "mackage_handbags",
      },
      {
        value: "Mad Jewels",
        id: "mad_jewels",
      },
      {
        value: "Madewell",
        id: "madewell",
      },
      {
        value: "Maggy London",
        id: "maggy_london",
      },
      {
        value: "Maia Bergman",
        id: "maia_bergman",
      },
      {
        value: "Maison Margiela",
        id: "maison_margiela",
      },
      {
        value: "Maison de Papillon",
        id: "maison_de_papillon",
      },
      {
        value: "Maje",
        id: "maje",
      },
      {
        value: "Manning Cartell",
        id: "manning_cartell",
      },
      {
        value: "Mansur Gavriel Accessories",
        id: "mansur_gavriel_accessories",
      },
      {
        value: "Mara Hoffman",
        id: "mara_hoffman",
      },
      {
        value: "Marc Jacobs Handbags",
        id: "marc_jacobs_handbags",
      },
      {
        value: "Marc Jacobs Sunglasses",
        id: "marc_jacobs_sunglasses",
      },
      {
        value: "Marc New York",
        id: "marc_new_york",
      },
      {
        value: "Marchesa Handbags",
        id: "marchesa_handbags",
      },
      {
        value: "Marchesa Jewelry",
        id: "marchesa_jewelry",
      },
      {
        value: "Marchesa Notte",
        id: "marchesa_notte",
      },
      {
        value: "Marchesa Notte Bridesmaid",
        id: "marchesa_notte_bridesmaid",
      },
      {
        value: "Marchesa Rosa",
        id: "marchesa_rosa",
      },
      {
        value: "Marchesa Voyage",
        id: "marchesa_voyage",
      },
      {
        value: "Margaret Elizabeth",
        id: "margaret_elizabeth",
      },
      {
        value: "Marge Sherwood",
        id: "marge_sherwood",
      },
      {
        value: "Margo Morrison",
        id: "margo_morrison",
      },
      {
        value: "Maria Cher",
        id: "maria_cher",
      },
      {
        value: "Marie-Chantal",
        id: "mariechantal",
      },
      {
        value: "Marie Oliver",
        id: "marie_oliver",
      },
      {
        value: "Marie Turnor",
        id: "marie_turnor",
      },
      {
        value: "Marimekko",
        id: "marimekko",
      },
      {
        value: "Marina Moscone",
        id: "marina_moscone",
      },
      {
        value: "Marina Moscone x RTR",
        id: "marina_moscone_x_rtr",
      },
      {
        value: "Marina Rinaldi",
        id: "marina_rinaldi",
      },
      {
        value: "Marine Layer",
        id: "marine_layer",
      },
      {
        value: "Marissa Webb",
        id: "marissa_webb",
      },
      {
        value: "Marissa Webb x RTR",
        id: "marissa_webb_x_rtr",
      },
      {
        value: "Mark & James by Badgley Mischka",
        id: "mark_james_by_badgley_mischka",
      },
      {
        value: "Marni",
        id: "marni",
      },
      {
        value: "Marni Accessories",
        id: "marni_accessories",
      },
      {
        value: "Marni Kids",
        id: "marni_kids",
      },
      {
        value: "Marques' Almeida",
        id: "marques_almeida",
      },
      {
        value: "Marques' Almeida Handbags",
        id: "marques_almeida_handbags",
      },
      {
        value: "Martin Grant",
        id: "martin_grant",
      },
      {
        value: "Matison Stone",
        id: "matison_stone",
      },
      {
        value: "Matthew Bruch",
        id: "matthew_bruch",
      },
      {
        value: "Matthew Williamson",
        id: "matthew_williamson",
      },
      {
        value: "Mawi",
        id: "mawi",
      },
      {
        value: "Meadows",
        id: "meadows",
      },
      {
        value: "Melanie Auld",
        id: "melanie_auld",
      },
      {
        value: "Melissa Masse",
        id: "melissa_masse",
      },
      {
        value: "Mia Vesper",
        id: "mia_vesper",
      },
      {
        value: "Michael Costello",
        id: "michael_costello",
      },
      {
        value: "Michael Stars",
        id: "michael_stars",
      },
      {
        value: "Midheaven Denim",
        id: "midheaven_denim",
      },
      {
        value: "Mignon",
        id: "mignon",
      },
      {
        value: "Mignonne Gavigan",
        id: "mignonne_gavigan",
      },
      {
        value: "Miguel Ases",
        id: "miguel_ases",
      },
      {
        value: "Miguelina",
        id: "miguelina",
      },
      {
        value: "M.i.h. Jeans",
        id: "mih_jeans",
      },
      {
        value: "Millianna",
        id: "millianna",
      },
      {
        value: "Milly",
        id: "milly",
      },
      {
        value: "Milly Handbags",
        id: "milly_handbags",
      },
      {
        value: "Milly Minis",
        id: "milly_minis",
      },
      {
        value: "Minnie Rose",
        id: "minnie_rose",
      },
      {
        value: "Mira Mikati",
        id: "mira_mikati",
      },
      {
        value: "Miriam Haskell",
        id: "miriam_haskell",
      },
      {
        value: "Misha Collection",
        id: "misha_collection",
      },
      {
        value: "Misook",
        id: "misook",
      },
      {
        value: "Missoma",
        id: "missoma",
      },
      {
        value: "Missoni",
        id: "missoni",
      },
      {
        value: "Missoni Accessories",
        id: "missoni_accessories",
      },
      {
        value: "Missoni Sunglasses",
        id: "missoni_sunglasses",
      },
      {
        value: "Modern Citizen",
        id: "modern_citizen",
      },
      {
        value: "Monique Lhuillier",
        id: "monique_lhuillier",
      },
      {
        value: "Monique Lhuillier Bridesmaid",
        id: "monique_lhuillier_bridesmaid",
      },
      {
        value: "Monse",
        id: "monse",
      },
      {
        value: "Moon River",
        id: "moon_river",
      },
      {
        value: "Mooseknuckles",
        id: "mooseknuckles",
      },
      {
        value: "Moschino",
        id: "moschino",
      },
      {
        value: "Moschino Accessories",
        id: "moschino_accessories",
      },
      {
        value: "Moschino Cheap And Chic",
        id: "moschino_cheap_and_chic",
      },
      {
        value: "Moschino Jeans",
        id: "moschino_jeans",
      },
      {
        value: "Moschino Jeans Accessories",
        id: "moschino_jeans_accessories",
      },
      {
        value: "Moschino Kids",
        id: "moschino_kids",
      },
      {
        value: "Moschino Tech Accessories",
        id: "moschino_tech_accessories",
      },
      {
        value: "Mossi",
        id: "mossi",
      },
      {
        value: "Motherhood Maternity",
        id: "motherhood_maternity",
      },
      {
        value: "Mother of Pearl",
        id: "mother_of_pearl",
      },
      {
        value: "Mott & Bow",
        id: "mott_bow",
      },
      {
        value: "Moutton Collet",
        id: "moutton_collet",
      },
      {
        value: "Munthe",
        id: "munthe",
      },
      {
        value: "N12H",
        id: "n12h",
      },
      {
        value: "NAADAM",
        id: "naadam",
      },
      {
        value: "NAEEM KHAN",
        id: "naeem_khan",
      },
      {
        value: "NEON BLONDE",
        id: "neon_blonde",
      },
      {
        value: "NISSA",
        id: "nissa",
      },
      {
        value: "N Natori",
        id: "n_natori",
      },
      {
        value: "NOIZE",
        id: "noize",
      },
      {
        value: "NOM Maternity",
        id: "nom_maternity",
      },
      {
        value: "NONchalant",
        id: "nonchalant",
      },
      {
        value: "NVLT",
        id: "nvlt",
      },
      {
        value: "Nachiket Barve",
        id: "nachiket_barve",
      },
      {
        value: "Nanette Lepore",
        id: "nanette_lepore",
      },
      {
        value: "Nanushka",
        id: "nanushka",
      },
      {
        value: "Nanushka Accessories",
        id: "nanushka_accessories",
      },
      {
        value: "Narciso Rodriguez",
        id: "narciso_rodriguez",
      },
      {
        value: "Nation LTD",
        id: "nation_ltd",
      },
      {
        value: "Natori",
        id: "natori",
      },
      {
        value: "Nicholas",
        id: "nicholas",
      },
      {
        value: "Nicole Miller",
        id: "nicole_miller",
      },
      {
        value: "Nicole Miller Accessories",
        id: "nicole_miller_accessories",
      },
      {
        value: "Nicole Romano",
        id: "nicole_romano",
      },
      {
        value: "Nigel Xavier",
        id: "nigel_xavier",
      },
      {
        value: "Nightcap",
        id: "nightcap",
      },
      {
        value: "Nike",
        id: "nike",
      },
      {
        value: "Nil & Mon",
        id: "nil_mon",
      },
      {
        value: "Nili Lotan",
        id: "nili_lotan",
      },
      {
        value: "Nina Ricci",
        id: "nina_ricci",
      },
      {
        value: "Nina Ricci Accessories",
        id: "nina_ricci_accessories",
      },
      {
        value: "Nineteen Pieces",
        id: "nineteen_pieces",
      },
      {
        value: "No. 21",
        id: "no_21",
      },
      {
        value: "No. 21 Handbags",
        id: "no_21_handbags",
      },
      {
        value: "No. 21 Kids",
        id: "no_21_kids",
      },
      {
        value: "No.6",
        id: "no6",
      },
      {
        value: "Noam Hanoch",
        id: "noam_hanoch",
      },
      {
        value: "Nocturne",
        id: "nocturne",
      },
      {
        value: "Noir Jewelry",
        id: "noir_jewelry",
      },
      {
        value: "Nomia",
        id: "nomia",
      },
      {
        value: "Nookie",
        id: "nookie",
      },
      {
        value: "Novelti",
        id: "novelti",
      },
      {
        value: "Nubra",
        id: "nubra",
      },
      {
        value: "O2nd",
        id: "o2nd",
      },
      {
        value: "OOF",
        id: "oof",
      },
      {
        value: "OPEN",
        id: "open",
      },
      {
        value: "OPEN DESIGNER",
        id: "open_designer",
      },
      {
        value: "OPEN DESIGNER2",
        id: "open_designer2",
      },
      {
        value: "OPEN DESIGNERR",
        id: "open_designerr",
      },
      {
        value: "OPEN DESIGNERrr",
        id: "open_designerrr",
      },
      {
        value: "Obando x RTR",
        id: "obando_x_rtr",
      },
      {
        value: "Odette New York",
        id: "odette_new_york",
      },
      {
        value: "Of Mercer",
        id: "of_mercer",
      },
      {
        value: "Officine Générale",
        id: "officine_générale",
      },
      {
        value: "Olcay Gulsen",
        id: "olcay_gulsen",
      },
      {
        value: "Olivia Rubin",
        id: "olivia_rubin",
      },
      {
        value: "Oma The Label",
        id: "oma_the_label",
      },
      {
        value: "One33 Social",
        id: "one33_social",
      },
      {
        value: "One Grey Day",
        id: "one_grey_day",
      },
      {
        value: "One Teaspoon",
        id: "one_teaspoon",
      },
      {
        value: "Onia",
        id: "onia",
      },
      {
        value: "Opening Ceremony",
        id: "opening_ceremony",
      },
      {
        value: "Opening Ceremony Accessories",
        id: "opening_ceremony_accessories",
      },
      {
        value: "Orire",
        id: "orire",
      },
      {
        value: "Orly Genger",
        id: "orly_genger",
      },
      {
        value: "Oscar de la Renta",
        id: "oscar_de_la_renta",
      },
      {
        value: "Oscar de la Renta Kids",
        id: "oscar_de_la_renta_kids",
      },
      {
        value: "Osman",
        id: "osman",
      },
      {
        value: "Osman Yousefzada x RTR",
        id: "osman_yousefzada_x_rtr",
      },
      {
        value: "PAIGE",
        id: "paige",
      },
      {
        value: "PASDUCHAS",
        id: "pasduchas",
      },
      {
        value: "P.E Nation",
        id: "pe_nation",
      },
      {
        value: "PIAMITA",
        id: "piamita",
      },
      {
        value: "PIERRE HARDY",
        id: "pierre_hardy",
      },
      {
        value: "PIETRO BRUNELLI MATERNITY",
        id: "pietro_brunelli_maternity",
      },
      {
        value: "PINKO",
        id: "pinko",
      },
      {
        value: "PISTOLA",
        id: "pistola",
      },
      {
        value: "PLACEHOLDER",
        id: "placeholder",
      },
      {
        value: "Page Sargisson",
        id: "page_sargisson",
      },
      {
        value: "Paloma Blue",
        id: "paloma_blue",
      },
      {
        value: "Pam & Gela",
        id: "pam_gela",
      },
      {
        value: "Pamela Love Jewelry",
        id: "pamela_love_jewelry",
      },
      {
        value: "Pamela Love x RTR",
        id: "pamela_love_x_rtr",
      },
      {
        value: "Paper Crown",
        id: "paper_crown",
      },
      {
        value: "Paradised",
        id: "paradised",
      },
      {
        value: "Paris Georgia",
        id: "paris_georgia",
      },
      {
        value: "Parisa Wang",
        id: "parisa_wang",
      },
      {
        value: "Parker",
        id: "parker",
      },
      {
        value: "Pas Pour Toi",
        id: "pas_pour_toi",
      },
      {
        value: "PatBO",
        id: "patbo",
      },
      {
        value: "Patou",
        id: "patou",
      },
      {
        value: "Paul Smith",
        id: "paul_smith",
      },
      {
        value: "Pencey",
        id: "pencey",
      },
      {
        value: "Perfect Moment",
        id: "perfect_moment",
      },
      {
        value: "Perfect Moment Kids",
        id: "perfect_moment_kids",
      },
      {
        value: "Perseverance London",
        id: "perseverance_london",
      },
      {
        value: "Petar Petrov",
        id: "petar_petrov",
      },
      {
        value: "Peter Pilotto",
        id: "peter_pilotto",
      },
      {
        value: "Peter Som",
        id: "peter_som",
      },
      {
        value: "Peter Som x RTR",
        id: "peter_som_x_rtr",
      },
      {
        value: "Peter Soronen",
        id: "peter_soronen",
      },
      {
        value: "Petersyn",
        id: "petersyn",
      },
      {
        value: "Phat Buddha",
        id: "phat_buddha",
      },
      {
        value: "Philosophy Di Alberta Ferretti",
        id: "philosophy_di_alberta_ferretti",
      },
      {
        value: "Philosophy di Lorenzo Serafini",
        id: "philosophy_di_lorenzo_serafini",
      },
      {
        value: "Philosophy di Lorenzo Serafini Accessories",
        id: "philosophy_di_lorenzo_serafini_accessories",
      },
      {
        value: "Philosophy di Lorenzo Serafini Kids",
        id: "philosophy_di_lorenzo_serafini_kids",
      },
      {
        value: "Pietro Brunelli Milano",
        id: "pietro_brunelli_milano",
      },
      {
        value: "Pink Tartan",
        id: "pink_tartan",
      },
      {
        value: "Plan C",
        id: "plan_c",
      },
      {
        value: "Plein Sud",
        id: "plein_sud",
      },
      {
        value: "Ply-Knits",
        id: "plyknits",
      },
      {
        value: "Polo Ralph Lauren",
        id: "polo_ralph_lauren",
      },
      {
        value: "Polo Ralph Lauren Accessories",
        id: "polo_ralph_lauren_accessories",
      },
      {
        value: "Polytima",
        id: "polytima",
      },
      {
        value: "Poolside",
        id: "poolside",
      },
      {
        value: "Ports 1961",
        id: "ports_1961",
      },
      {
        value: "Poupette St Barth",
        id: "poupette_st_barth",
      },
      {
        value: "Prabal Gurung",
        id: "prabal_gurung",
      },
      {
        value: "Prabal Gurung x RTR",
        id: "prabal_gurung_x_rtr",
      },
      {
        value: "Preen",
        id: "preen",
      },
      {
        value: "Privacy Please",
        id: "privacy_please",
      },
      {
        value: "Proenza Schouler",
        id: "proenza_schouler",
      },
      {
        value: "Proenza Schouler Handbags",
        id: "proenza_schouler_handbags",
      },
      {
        value: "Proenza Schouler White Label",
        id: "proenza_schouler_white_label",
      },
      {
        value: "Prose & Poetry",
        id: "prose_poetry",
      },
      {
        value: "Pyer Moss",
        id: "pyer_moss",
      },
      {
        value: "R13",
        id: "r13",
      },
      {
        value: "RACHEL ROY COLLECTION",
        id: "rachel_roy_collection",
      },
      {
        value: "RAGA",
        id: "raga",
      },
      {
        value: "RAHI",
        id: "rahi",
      },
      {
        value: "RAINS",
        id: "rains",
      },
      {
        value: "RE/DONE",
        id: "redone",
      },
      {
        value: "RED Valentino",
        id: "red_valentino",
      },
      {
        value: "REIKO",
        id: "reiko",
      },
      {
        value: "REMAIN Birger Christensen",
        id: "remain_birger_christensen",
      },
      {
        value: "RHODE",
        id: "rhode",
      },
      {
        value: "RICKOWENSLILIES",
        id: "rickowenslilies",
      },
      {
        value: "RJ Graziano",
        id: "rj_graziano",
      },
      {
        value: "ROAM",
        id: "roam",
      },
      {
        value: "ROCOCO SAND",
        id: "rococo_sand",
      },
      {
        value: "ROTATE Birger Christensen",
        id: "rotate_birger_christensen",
      },
      {
        value: "RTR Bridal Accessories",
        id: "rtr_bridal_accessories",
      },
      {
        value: "RTR NOW",
        id: "rtr_now",
      },
      {
        value: "RTR On Campus",
        id: "rtr_on_campus",
      },
      {
        value: "RUMER",
        id: "rumer",
      },
      {
        value: "RVN",
        id: "rvn",
      },
      {
        value: "Rabanne",
        id: "rabanne",
      },
      {
        value: "Rachel Antonoff",
        id: "rachel_antonoff",
      },
      {
        value: "Rachel Comey",
        id: "rachel_comey",
      },
      {
        value: "Rachel Comey Accessories",
        id: "rachel_comey_accessories",
      },
      {
        value: "Rachel Gilbert",
        id: "rachel_gilbert",
      },
      {
        value: "Rachel Leigh",
        id: "rachel_leigh",
      },
      {
        value: "Rachel Pally",
        id: "rachel_pally",
      },
      {
        value: "Rachel Parcell",
        id: "rachel_parcell",
      },
      {
        value: "Rachel Rachel Roy",
        id: "rachel_rachel_roy",
      },
      {
        value: "Rachel Roy",
        id: "rachel_roy",
      },
      {
        value: "Rachel Zoe",
        id: "rachel_zoe",
      },
      {
        value: "Radenroro",
        id: "radenroro",
      },
      {
        value: "Rafé",
        id: "rafé",
      },
      {
        value: "Rafe New York",
        id: "rafe_new_york",
      },
      {
        value: "Rahul Mishra",
        id: "rahul_mishra",
      },
      {
        value: "Rails",
        id: "rails",
      },
      {
        value: "Ralph Lauren Kids",
        id: "ralph_lauren_kids",
      },
      {
        value: "Ramy Brook",
        id: "ramy_brook",
      },
      {
        value: "Ranjana Khan",
        id: "ranjana_khan",
      },
      {
        value: "Raoul",
        id: "raoul",
      },
      {
        value: "Rare London",
        id: "rare_london",
      },
      {
        value: "Re Ona",
        id: "re_ona",
      },
      {
        value: "Rebecca Minkoff",
        id: "rebecca_minkoff",
      },
      {
        value: "Rebecca Minkoff Accessories",
        id: "rebecca_minkoff_accessories",
      },
      {
        value: "Rebecca Taylor NYC",
        id: "rebecca_taylor_nyc",
      },
      {
        value: "Rebecca Vallance",
        id: "rebecca_vallance",
      },
      {
        value: "Recreational Habits",
        id: "recreational_habits",
      },
      {
        value: "Redemption Athletix",
        id: "redemption_athletix",
      },
      {
        value: "Reed Krakoff",
        id: "reed_krakoff",
      },
      {
        value: "Reem Acra",
        id: "reem_acra",
      },
      {
        value: "Reformation",
        id: "reformation",
      },
      {
        value: "Reiss",
        id: "reiss",
      },
      {
        value: "Rejina Pyo",
        id: "rejina_pyo",
      },
      {
        value: "Rejina Pyo Accessories",
        id: "rejina_pyo_accessories",
      },
      {
        value: "Rene Ruiz Collection",
        id: "rene_ruiz_collection",
      },
      {
        value: "Rent the Runway",
        id: "rent_the_runway",
      },
      {
        value: "Rent the Runway PRO",
        id: "rent_the_runway_pro",
      },
      {
        value: "Replica Los Angeles",
        id: "replica_los_angeles",
      },
      {
        value: "Resa",
        id: "resa",
      },
      {
        value: "Róhe",
        id: "róhe",
      },
      {
        value: "Richard Chai",
        id: "richard_chai",
      },
      {
        value: "Rino & Pelle",
        id: "rino_pelle",
      },
      {
        value: "Rita Row",
        id: "rita_row",
      },
      {
        value: "Rivet Utility",
        id: "rivet_utility",
      },
      {
        value: "Rixo",
        id: "rixo",
      },
      {
        value: "Roarke",
        id: "roarke",
      },
      {
        value: "Robert Rodriguez",
        id: "robert_rodriguez",
      },
      {
        value: "Robert Rodriguez Black Label",
        id: "robert_rodriguez_black_label",
      },
      {
        value: "Robert Rodriguez Collection",
        id: "robert_rodriguez_collection",
      },
      {
        value: "Roberto Cavalli Accessories",
        id: "roberto_cavalli_accessories",
      },
      {
        value: "Rochas",
        id: "rochas",
      },
      {
        value: "Rodarte X Universal Standard",
        id: "rodarte_x_universal_standard",
      },
      {
        value: "Rodebjer",
        id: "rodebjer",
      },
      {
        value: "Rodrigo Otazu",
        id: "rodrigo_otazu",
      },
      {
        value: "Roksanda",
        id: "roksanda",
      },
      {
        value: "Roksanda x RTR",
        id: "roksanda_x_rtr",
      },
      {
        value: "Roland Mouret",
        id: "roland_mouret",
      },
      {
        value: "Roller Rabbit",
        id: "roller_rabbit",
      },
      {
        value: "Roller Rabbit Accessories",
        id: "roller_rabbit_accessories",
      },
      {
        value: "Roller Rabbit Kids",
        id: "roller_rabbit_kids",
      },
      {
        value: "Ronny Kobo",
        id: "ronny_kobo",
      },
      {
        value: "Ronny Kobo x RTR",
        id: "ronny_kobo_x_rtr",
      },
      {
        value: "Ro's Garden",
        id: "ros_garden",
      },
      {
        value: "Rosetta Getty",
        id: "rosetta_getty",
      },
      {
        value: "Rosetta Getty x RTR",
        id: "rosetta_getty_x_rtr",
      },
      {
        value: "Rosie Assoulin",
        id: "rosie_assoulin",
      },
      {
        value: "Rosie Pope",
        id: "rosie_pope",
      },
      {
        value: "Roxanne Assoulin",
        id: "roxanne_assoulin",
      },
      {
        value: "RtA",
        id: "rta",
      },
      {
        value: "Rubin Singer",
        id: "rubin_singer",
      },
      {
        value: "Rue Sophie",
        id: "rue_sophie",
      },
      {
        value: "Rumbatime",
        id: "rumbatime",
      },
      {
        value: "Rupert Sanderson",
        id: "rupert_sanderson",
      },
      {
        value: "SALAR",
        id: "salar",
      },
      {
        value: "SALONI",
        id: "saloni",
      },
      {
        value: "SANCIA",
        id: "sancia",
      },
      {
        value: "SER.O.YA",
        id: "seroya",
      },
      {
        value: "SFIZIO",
        id: "sfizio",
      },
      {
        value: "SHASHI",
        id: "shashi",
      },
      {
        value: "SIMKHAI",
        id: "simkhai",
      },
      {
        value: "SJYP",
        id: "sjyp",
      },
      {
        value: "SKIN",
        id: "skin",
      },
      {
        value: "SOIA & KYO",
        id: "soia_kyo",
      },
      {
        value: "SORAYA HENNESSY",
        id: "soraya_hennessy",
      },
      {
        value: "SPYDER",
        id: "spyder",
      },
      {
        value: "STADIUM by Stadium Goods",
        id: "stadium_by_stadium_goods",
      },
      {
        value: "STANMORE",
        id: "stanmore",
      },
      {
        value: "STINE GOYA",
        id: "stine_goya",
      },
      {
        value: "STYLESTALKER",
        id: "stylestalker",
      },
      {
        value: "ST by Olcay Gulsen",
        id: "st_by_olcay_gulsen",
      },
      {
        value: "S/W/F",
        id: "swf",
      },
      {
        value: "SZ Blockprints",
        id: "sz_blockprints",
      },
      {
        value: "Sabina Musayev",
        id: "sabina_musayev",
      },
      {
        value: "Sachin & Babi",
        id: "sachin_babi",
      },
      {
        value: "Sachin & Babi Accessories",
        id: "sachin_babi_accessories",
      },
      {
        value: "Sail to Sable",
        id: "sail_to_sable",
      },
      {
        value: "Saint Laurent",
        id: "saint_laurent",
      },
      {
        value: "Sam Edelman Jewelry",
        id: "sam_edelman_jewelry",
      },
      {
        value: "Samantha Sipos",
        id: "samantha_sipos",
      },
      {
        value: "Samsoe",
        id: "samsoe",
      },
      {
        value: "San Sloane",
        id: "san_sloane",
      },
      {
        value: "Sanctuary",
        id: "sanctuary",
      },
      {
        value: "Sanctuary / DENIM",
        id: "sanctuary_denim",
      },
      {
        value: "Sandro",
        id: "sandro",
      },
      {
        value: "Sandy Hyun",
        id: "sandy_hyun",
      },
      {
        value: "Sandy Liang",
        id: "sandy_liang",
      },
      {
        value: "Sani",
        id: "sani",
      },
      {
        value: "Sara Battaglia",
        id: "sara_battaglia",
      },
      {
        value: "Sarah Magid",
        id: "sarah_magid",
      },
      {
        value: "Sau Lee",
        id: "sau_lee",
      },
      {
        value: "Saunders x RTR",
        id: "saunders_x_rtr",
      },
      {
        value: "Save the Duck",
        id: "save_the_duck",
      },
      {
        value: "Saylor",
        id: "saylor",
      },
      {
        value: "Scotch & Soda",
        id: "scotch_soda",
      },
      {
        value: "Scotch & Soda Kids",
        id: "scotch_soda_kids",
      },
      {
        value: "Sea New York",
        id: "sea_new_york",
      },
      {
        value: "See by Chloé",
        id: "see_by_chloé",
      },
      {
        value: "See by Chloé Accessories",
        id: "see_by_chloé_accessories",
      },
      {
        value: "Self-Portrait",
        id: "selfportrait",
      },
      {
        value: "Selkie",
        id: "selkie",
      },
      {
        value: "SemSem Girls",
        id: "semsem_girls",
      },
      {
        value: "Sensi Studio",
        id: "sensi_studio",
      },
      {
        value: "Sequin",
        id: "sequin",
      },
      {
        value: "Seraphine",
        id: "seraphine",
      },
      {
        value: "Serge Normant",
        id: "serge_normant",
      },
      {
        value: "Sergio Hudson",
        id: "sergio_hudson",
      },
      {
        value: "Serpui Marie",
        id: "serpui_marie",
      },
      {
        value: "Seventy",
        id: "seventy",
      },
      {
        value: "Shana Gulati",
        id: "shana_gulati",
      },
      {
        value: "Shilla",
        id: "shilla",
      },
      {
        value: "Shinola",
        id: "shinola",
      },
      {
        value: "Shoreditch Ski Club",
        id: "shoreditch_ski_club",
      },
      {
        value: "Shoshanna",
        id: "shoshanna",
      },
      {
        value: "Show Me Your Mumu",
        id: "show_me_your_mumu",
      },
      {
        value: "Sid Neigum",
        id: "sid_neigum",
      },
      {
        value: "Significant Other",
        id: "significant_other",
      },
      {
        value: "Silk Laundry",
        id: "silk_laundry",
      },
      {
        value: "SIMONMILLER",
        id: "simonmiller",
      },
      {
        value: "SIMONMILLER Accessories",
        id: "simonmiller_accessories",
      },
      {
        value: "Sister Jane",
        id: "sister_jane",
      },
      {
        value: "Slate & Willow",
        id: "slate_willow",
      },
      {
        value: "Slate & Willow Accessories",
        id: "slate_willow_accessories",
      },
      {
        value: "Smoke X Mirrors",
        id: "smoke_x_mirrors",
      },
      {
        value: "Snider",
        id: "snider",
      },
      {
        value: "Soko",
        id: "soko",
      },
      {
        value: "Solace London",
        id: "solace_london",
      },
      {
        value: "Soleil Soleil",
        id: "soleil_soleil",
      },
      {
        value: "Solid & Striped",
        id: "solid_striped",
      },
      {
        value: "Something Navy",
        id: "something_navy",
      },
      {
        value: "Sondra Roberts",
        id: "sondra_roberts",
      },
      {
        value: "Sonia Rykiel",
        id: "sonia_rykiel",
      },
      {
        value: "Sonia Rykiel Kids",
        id: "sonia_rykiel_kids",
      },
      {
        value: "Sosken",
        id: "sosken",
      },
      {
        value: "Spanx",
        id: "spanx",
      },
      {
        value: "Spiritual Gangster",
        id: "spiritual_gangster",
      },
      {
        value: "Splendid",
        id: "splendid",
      },
      {
        value: "St. John Collection",
        id: "st_john_collection",
      },
      {
        value: "St. Tropez",
        id: "st_tropez",
      },
      {
        value: "Stand Studio",
        id: "stand_studio",
      },
      {
        value: "Staud",
        id: "staud",
      },
      {
        value: "Staud Accessories",
        id: "staud_accessories",
      },
      {
        value: "Stella Jean",
        id: "stella_jean",
      },
      {
        value: "Stella McCartney",
        id: "stella_mccartney",
      },
      {
        value: "Stella McCartney Kids",
        id: "stella_mccartney_kids",
      },
      {
        value: "Stephanie Gottlieb",
        id: "stephanie_gottlieb",
      },
      {
        value: "Stevie May",
        id: "stevie_may",
      },
      {
        value: "Still Here New York",
        id: "still_here_new_york",
      },
      {
        value: "Stowaway",
        id: "stowaway",
      },
      {
        value: "StyleKeepers",
        id: "stylekeepers",
      },
      {
        value: "Stylist Surprise",
        id: "stylist_surprise",
      },
      {
        value: "Suboo",
        id: "suboo",
      },
      {
        value: "Subversive",
        id: "subversive",
      },
      {
        value: "Sundry",
        id: "sundry",
      },
      {
        value: "Suno",
        id: "suno",
      },
      {
        value: "Susan Alexandra",
        id: "susan_alexandra",
      },
      {
        value: "Susan Hanover",
        id: "susan_hanover",
      },
      {
        value: "Susana Monaco",
        id: "susana_monaco",
      },
      {
        value: "Suzanna Dai",
        id: "suzanna_dai",
      },
      {
        value: "Sweaty Betty",
        id: "sweaty_betty",
      },
      {
        value: "Sweet Baby Jamie",
        id: "sweet_baby_jamie",
      },
      {
        value: "Sylvia Toledano",
        id: "sylvia_toledano",
      },
      {
        value: "TART Collections",
        id: "tart_collections",
      },
      {
        value: "T-Bags LosAngeles",
        id: "tbags_losangeles",
      },
      {
        value: "TEIJA",
        id: "teija",
      },
      {
        value: "THEMOIRè",
        id: "themoire",
      },
      {
        value: "THE NEW ARRIVALS By Ilkyaz Ozel",
        id: "the_new_arrivals_by_ilkyaz_ozel",
      },
      {
        value: "THIRD FORM",
        id: "third_form",
      },
      {
        value: "TOCCIN X RTR",
        id: "toccin_x_rtr",
      },
      {
        value: "TOME x RTR",
        id: "tome_x_rtr",
      },
      {
        value: "TRAVE Denim",
        id: "trave_denim",
      },
      {
        value: "TROUBADOUR",
        id: "troubadour",
      },
      {
        value: "TROUVÉ",
        id: "trouvé",
      },
      {
        value: "TULAROSA",
        id: "tularosa",
      },
      {
        value: "TY-LR",
        id: "tylr",
      },
      {
        value: "Tabula Rasa",
        id: "tabula_rasa",
      },
      {
        value: "Tadashi Shoji",
        id: "tadashi_shoji",
      },
      {
        value: "Talk to the Heel by Braza",
        id: "talk_to_the_heel_by_braza",
      },
      {
        value: "Tamara Mellon",
        id: "tamara_mellon",
      },
      {
        value: "Tanya Taylor",
        id: "tanya_taylor",
      },
      {
        value: "Tara Jarmon",
        id: "tara_jarmon",
      },
      {
        value: "Taylor and Tessier",
        id: "taylor_and_tessier",
      },
      {
        value: "T by Alexander Wang",
        id: "t_by_alexander_wang",
      },
      {
        value: "Ted Baker",
        id: "ted_baker",
      },
      {
        value: "Ted Rossi",
        id: "ted_rossi",
      },
      {
        value: "Temperley London",
        id: "temperley_london",
      },
      {
        value: "Terez",
        id: "terez",
      },
      {
        value: "Thakoon",
        id: "thakoon",
      },
      {
        value: "Thakoon Addition",
        id: "thakoon_addition",
      },
      {
        value: "Thakoon x RTR",
        id: "thakoon_x_rtr",
      },
      {
        value: "The Arrivals",
        id: "the_arrivals",
      },
      {
        value: "The Attico",
        id: "the_attico",
      },
      {
        value: "The Cords & Co",
        id: "the_cords_co",
      },
      {
        value: "The East Order",
        id: "the_east_order",
      },
      {
        value: "The Fifth Label",
        id: "the_fifth_label",
      },
      {
        value: "The Great.",
        id: "the_great",
      },
      {
        value: "The Jetset Diaries",
        id: "the_jetset_diaries",
      },
      {
        value: "The Kooples",
        id: "the_kooples",
      },
      {
        value: "The M Jewelers",
        id: "the_m_jewelers",
      },
      {
        value: "The Mighty Company",
        id: "the_mighty_company",
      },
      {
        value: "The Odells",
        id: "the_odells",
      },
      {
        value: "TheOpen Product",
        id: "theopen_product",
      },
      {
        value: "The Wolf Gang",
        id: "the_wolf_gang",
      },
      {
        value: "Thebe Magugu",
        id: "thebe_magugu",
      },
      {
        value: "Theia",
        id: "theia",
      },
      {
        value: "Theodora and Callum",
        id: "theodora_and_callum",
      },
      {
        value: "Theodora and Callum Accessories",
        id: "theodora_and_callum_accessories",
      },
      {
        value: "Theory",
        id: "theory",
      },
      {
        value: "Theyskens Theory",
        id: "theyskens_theory",
      },
      {
        value: "Thierry Lasry",
        id: "thierry_lasry",
      },
      {
        value: "Think Royln",
        id: "think_royln",
      },
      {
        value: "Think Royln Accessories",
        id: "think_royln_accessories",
      },
      {
        value: "Thom Browne",
        id: "thom_browne",
      },
      {
        value: "Thread Social",
        id: "thread_social",
      },
      {
        value: "Threads of PRVLG",
        id: "threads_of_prvlg",
      },
      {
        value: "Three Floor",
        id: "three_floor",
      },
      {
        value: "Thurley",
        id: "thurley",
      },
      {
        value: "Tibi",
        id: "tibi",
      },
      {
        value: "Times Arrow",
        id: "times_arrow",
      },
      {
        value: "Toccin",
        id: "toccin",
      },
      {
        value: "Tom Binns",
        id: "tom_binns",
      },
      {
        value: "Tom Tom",
        id: "tom_tom",
      },
      {
        value: "Tome",
        id: "tome",
      },
      {
        value: "Tome x TDE",
        id: "tome_x_tde",
      },
      {
        value: "Tommy Hilfiger",
        id: "tommy_hilfiger",
      },
      {
        value: "Tory Burch",
        id: "tory_burch",
      },
      {
        value: "Tory Burch Accessories",
        id: "tory_burch_accessories",
      },
      {
        value: "Tory Sport",
        id: "tory_sport",
      },
      {
        value: "Tory Sport Accessories",
        id: "tory_sport_accessories",
      },
      {
        value: "Totême",
        id: "totême",
      },
      {
        value: "Tova",
        id: "tova",
      },
      {
        value: "Tracy Reese",
        id: "tracy_reese",
      },
      {
        value: "Treasure & Bond",
        id: "treasure_bond",
      },
      {
        value: "Triarchy",
        id: "triarchy",
      },
      {
        value: "Trina Turk",
        id: "trina_turk",
      },
      {
        value: "Trudelle Laker",
        id: "trudelle_laker",
      },
      {
        value: "Truss",
        id: "truss",
      },
      {
        value: "Tuleh",
        id: "tuleh",
      },
      {
        value: "Tuleste Market",
        id: "tuleste_market",
      },
      {
        value: "Turkish Delight",
        id: "turkish_delight",
      },
      {
        value: "Twelfth Street by Cynthia Vincent",
        id: "twelfth_street_by_cynthia_vincent",
      },
      {
        value: "Twinset",
        id: "twinset",
      },
      {
        value: "Ugly Holiday Sweaters",
        id: "ugly_holiday_sweaters",
      },
      {
        value: "Ulla Johnson",
        id: "ulla_johnson",
      },
      {
        value: "Ulla Johnson Handbags",
        id: "ulla_johnson_handbags",
      },
      {
        value: "Ultracor",
        id: "ultracor",
      },
      {
        value: "Unfortunate Portrait",
        id: "unfortunate_portrait",
      },
      {
        value: "Unfortunate Portrait x RTR",
        id: "unfortunate_portrait_x_rtr",
      },
      {
        value: "UnitedWood",
        id: "unitedwood",
      },
      {
        value: "Universal Standard",
        id: "universal_standard",
      },
      {
        value: "Unreal Fur",
        id: "unreal_fur",
      },
      {
        value: "Untitled in Motion",
        id: "untitled_in_motion",
      },
      {
        value: "Updated Vendor Name + 5/3/2024",
        id: "updated_vendor_name_532024",
      },
      {
        value: "V.BELLAN",
        id: "vbellan",
      },
      {
        value: "V. Chapman",
        id: "v_chapman",
      },
      {
        value: "VEDA",
        id: "veda",
      },
      {
        value: "VERO MODA",
        id: "vero_moda",
      },
      {
        value: "V. Fraas",
        id: "v_fraas",
      },
      {
        value: "VIGOSS",
        id: "vigoss",
      },
      {
        value: "VINCE.",
        id: "vince",
      },
      {
        value: "VONE",
        id: "vone",
      },
      {
        value: "VOX LUX",
        id: "vox_lux",
      },
      {
        value: "Vanessa Bruno",
        id: "vanessa_bruno",
      },
      {
        value: "Varley",
        id: "varley",
      },
      {
        value: "Vena Cava",
        id: "vena_cava",
      },
      {
        value: "Venessa Arizaga",
        id: "venessa_arizaga",
      },
      {
        value: "Vera Wang",
        id: "vera_wang",
      },
      {
        value: "Vera Wang Accessories",
        id: "vera_wang_accessories",
      },
      {
        value: "Vera Wang Lavender",
        id: "vera_wang_lavender",
      },
      {
        value: "Veronica Beard",
        id: "veronica_beard",
      },
      {
        value: "Veronique Branquinho",
        id: "veronique_branquinho",
      },
      {
        value: "Versace Collection",
        id: "versace_collection",
      },
      {
        value: "Versus by Versace",
        id: "versus_by_versace",
      },
      {
        value: "Via Spiga",
        id: "via_spiga",
      },
      {
        value: "Victor Alfaro",
        id: "victor_alfaro",
      },
      {
        value: "Victor Alfaro x RTR",
        id: "victor_alfaro_x_rtr",
      },
      {
        value: "Victor Glemaud",
        id: "victor_glemaud",
      },
      {
        value: "Victoria Beckham",
        id: "victoria_beckham",
      },
      {
        value: "Victoria / Tomas",
        id: "victoria_tomas",
      },
      {
        value: "Victoria Victoria Beckham",
        id: "victoria_victoria_beckham",
      },
      {
        value: "Vie la V",
        id: "vie_la_v",
      },
      {
        value: "Vie la V Accessories",
        id: "vie_la_v_accessories",
      },
      {
        value: "Viktor & Rolf",
        id: "viktor_rolf",
      },
      {
        value: "Vilshenko",
        id: "vilshenko",
      },
      {
        value: "Vince Camuto",
        id: "vince_camuto",
      },
      {
        value: "Vionnet",
        id: "vionnet",
      },
      {
        value: "Vionnet Accessories",
        id: "vionnet_accessories",
      },
      {
        value: "Viva Aviva",
        id: "viva_aviva",
      },
      {
        value: "Vivienne Westwood Anglomania",
        id: "vivienne_westwood_anglomania",
      },
      {
        value: "Vivienne Westwood Red Label",
        id: "vivienne_westwood_red_label",
      },
      {
        value: "WATTERS",
        id: "watters",
      },
      {
        value: "WEWOREWHAT",
        id: "weworewhat",
      },
      {
        value: "WGACA Vintage",
        id: "wgaca_vintage",
      },
      {
        value: "WILLOW",
        id: "willow",
      },
      {
        value: "WRANGLER",
        id: "wrangler",
      },
      {
        value: "Wai Ming",
        id: "wai_ming",
      },
      {
        value: "Waimari",
        id: "waimari",
      },
      {
        value: "Wales Bonner",
        id: "wales_bonner",
      },
      {
        value: "Warm",
        id: "warm",
      },
      {
        value: "Waverly Grey",
        id: "waverly_grey",
      },
      {
        value: "West Elm",
        id: "west_elm",
      },
      {
        value: "White + Warren",
        id: "white_warren",
      },
      {
        value: "Whiting & Davis",
        id: "whiting_davis",
      },
      {
        value: "Wildfox",
        id: "wildfox",
      },
      {
        value: "Wish",
        id: "wish",
      },
      {
        value: "Wren and Glory",
        id: "wren_and_glory",
      },
      {
        value: "XíRENA",
        id: "xírena",
      },
      {
        value: "Y-3",
        id: "y3",
      },
      {
        value: "YERSE",
        id: "yerse",
      },
      {
        value: "YUMI KIM",
        id: "yumi_kim",
      },
      {
        value: "Year of Ours",
        id: "year_of_ours",
      },
      {
        value: "Yestadt",
        id: "yestadt",
      },
      {
        value: "Yigal Azrouël",
        id: "yigal_azrouël",
      },
      {
        value: "Yigal Azrouël Accessories",
        id: "yigal_azrouël_accessories",
      },
      {
        value: "Yoana Baraschi",
        id: "yoana_baraschi",
      },
      {
        value: "Yosi Samra",
        id: "yosi_samra",
      },
      {
        value: "Yuzefi",
        id: "yuzefi",
      },
      {
        value: "ZAC Zac Posen",
        id: "zac_zac_posen",
      },
      {
        value: "ZAC Zac Posen Handbags",
        id: "zac_zac_posen_handbags",
      },
      {
        value: "ZOË CHICCO",
        id: "zoë_chicco",
      },
      {
        value: "Z Spoke Zac Posen",
        id: "z_spoke_zac_posen",
      },
      {
        value: "Zac Posen",
        id: "zac_posen",
      },
      {
        value: "Zadig & Voltaire",
        id: "zadig_voltaire",
      },
      {
        value: "Zadig & Voltaire Handbags",
        id: "zadig_voltaire_handbags",
      },
      {
        value: "Zero + Maria Cornejo",
        id: "zero_maria_cornejo",
      },
      {
        value: "Zoë Jordan",
        id: "zoë_jordan",
      },
      {
        value: "adidas",
        id: "adidas",
      },
      {
        value: "adidas by Stella McCartney",
        id: "adidas_by_stella_mccartney",
      },
      {
        value: "adidas by WALES BONNER",
        id: "adidas_by_wales_bonner",
      },
      {
        value: "alice McCALL",
        id: "alice_mccall",
      },
      {
        value: "alldressedup",
        id: "alldressedup",
      },
      {
        value: "allison parris",
        id: "allison_parris",
      },
      {
        value: "ba&sh",
        id: "bash",
      },
      {
        value: "blowpro",
        id: "blowpro",
      },
      {
        value: "byTiMo",
        id: "bytimo",
      },
      {
        value: "camilla and marc",
        id: "camilla_and_marc",
      },
      {
        value: "cupcakes and cashmere",
        id: "cupcakes_and_cashmere",
      },
      {
        value: "dRA",
        id: "dra",
      },
      {
        value: "devlin",
        id: "devlin",
      },
      {
        value: "ela Handbags",
        id: "ela_handbags",
      },
      {
        value: "ella moss",
        id: "ella_moss",
      },
      {
        value: "elle zeitoune",
        id: "elle_zeitoune",
      },
      {
        value: "flor et.al",
        id: "flor_etal",
      },
      {
        value: "foxiedox",
        id: "foxiedox",
      },
      {
        value: "ghd Professional",
        id: "ghd_professional",
      },
      {
        value: "glowe Maternity",
        id: "glowe_maternity",
      },
      {
        value: "gu_de",
        id: "gu_de",
      },
      {
        value: "kate spade new york",
        id: "kate_spade_new_york",
      },
      {
        value: "kate spade new york accessories",
        id: "kate_spade_new_york_accessories",
      },
      {
        value: "kourt",
        id: "kourt",
      },
      {
        value: "krisa",
        id: "krisa",
      },
      {
        value: "léRumi",
        id: "lérumi",
      },
      {
        value: "les girls les boys",
        id: "les_girls_les_boys",
      },
      {
        value: "nha khanh",
        id: "nha_khanh",
      },
      {
        value: "(nude)",
        id: "nude",
      },
      {
        value: "open",
        id: "open",
      },
      {
        value: "o.p.t.",
        id: "opt",
      },
      {
        value: "pamella by pamella roland",
        id: "pamella_by_pamella_roland",
      },
      {
        value: "pamella roland",
        id: "pamella_roland",
      },
      {
        value: "rag & bone",
        id: "rag_bone",
      },
      {
        value: "rag & bone Accessories",
        id: "rag_bone_accessories",
      },
      {
        value: "rag & bone JEAN",
        id: "rag_bone_jean",
      },
      {
        value: "retrofête",
        id: "retrofête",
      },
      {
        value: "ripe",
        id: "ripe",
      },
      {
        value: "rokh",
        id: "rokh",
      },
      {
        value: "sita murt",
        id: "sita_murt",
      },
      {
        value: "somedays lovin",
        id: "somedays_lovin",
      },
      {
        value: "soon maternity",
        id: "soon_maternity",
      },
      {
        value: "sophie theallet",
        id: "sophie_theallet",
      },
      {
        value: "stila",
        id: "stila",
      },
      {
        value: "twenty",
        id: "twenty",
      },
      {
        value: "twobirds",
        id: "twobirds",
      },
    ],
  },
};
