import React, { useState } from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onInputCallback: PropTypes.func,
  ariaLabel: PropTypes.string,
  name: PropTypes.string,
  tabIndex: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  autoComplete: PropTypes.string,
  id: PropTypes.string,
};

const AtomCreditCardExpirationInput = ({
  ariaLabel,
  disabled,
  name,
  tabIndex,
  className,
  onInputCallback,
  onClick,
  onBlur,
  readOnly,
  autoComplete,
  id,
}) => {
  const [expirationDate, setExpirationDate] = useState("");

  /**
   * handle pasted in / autofilled inputs. these can come in several different forms. here we'll
   * check for MM/YYYY and M/YY - all of them should resolve to MM / YY. the month value should
   * include a leading zero if it's single-digit, and the year should be the last two digits.
   *
   * so, 3/2022 should resolve to 03 / 22.
   *
   * only do this on receiving a pasted input, so we don't do this regex match on every keystroke
   */
  const formatPastedInput = input => {
    let result = input;

    /**
     * check for a one-or-two-digit month, followed by a slash that may or may not be surrounded
     * by single whitespaces, followed by a two-or-four-digit year
     */
    const found = result.match(/^(\d\d?)\s?\/\s?(\d{2}|\d{4})$/);
    if (found && found[1] && found[2]) {
      if (found[1].length === 1) {
        found[1] = "0" + found[1];
      }

      if (found[2].length > 2) {
        found[2] = found[2].slice(-2);
      }

      result = found[1] + " / " + found[2];
    }

    return result;
  };

  const formatInput = input => {
    let result = input;

    /**
     * the rest of the cases handle inputs made one keystroke at a time. this regex checks for
     * exactly two digits, and if those were entered, we'll add the ' / ' so the user can
     * complete the field
     */
    if (/^\d\d$/.test(result)) {
      result += " / ";
    } else if (result.length > 7) {
      // if the field is at its max length ('MM / YY' = 7 chars) then truncate the last input
      result = result.slice(0, 7);
    } else {
      /**
       * Remove any non-numeric characters from the input and then put the slash back.
       * This also serves to cleanly delete the ' / ' in one keystroke
       */
      result = result.replace(/[^0-9]/g, "");
      if (result.length > 2) {
        const year = result.slice(2);
        result = result.slice(0, 2) + " / " + year.slice(-2);
      }
    }

    return result;
  };

  const handleInput = event => {
    const { value } = event.target;

    // check to see if the input was pasted / autocompleted in
    const formattedExpDate =
      event.nativeEvent.inputType === "insertFromPaste" ? formatPastedInput(value) : formatInput(value);

    setExpirationDate(formattedExpDate);
    onInputCallback(formattedExpDate);
  };

  return (
    <input
      id={id}
      type="tel"
      placeholder="MM / YY"
      value={expirationDate}
      onChange={e => handleInput(e)}
      className={className}
      aria-label={ariaLabel}
      name={name}
      tabIndex={tabIndex}
      onClick={onClick}
      onBlur={onBlur}
      readOnly={readOnly}
      autoComplete={autoComplete}
      disabled={disabled}
      data-heap-redact-text="true"
      data-heap-redact-attributes="value"
    />
  );
};

AtomCreditCardExpirationInput.propTypes = propTypes;

export default AtomCreditCardExpirationInput;
