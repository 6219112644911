import React from "react";
import PropTypes from "prop-types";

class AtomSecondaryButton extends React.Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: () => {}, // NOSONAR
  };

  render() {
    const { className } = this.props;

    return (
      <button
        className={`btn btn-secondary ${className || ""}`}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        data-test-id={this.props.dataTestId || ""}>
        {this.props.buttonText}
      </button>
    );
  }
}

export default AtomSecondaryButton;
