import ActionTypes from "../actions/action-types.js";
import { bagTabs } from "actions/bag-actions";

export const types = [ActionTypes.KIFAH_AVAILABLE_ITEMS, ActionTypes.KIFAH_TRIGGER];

export function fn(state, action) {
  let { kifahAvailableItems, bagTab, kifahTrigger } = state;

  switch (action.type) {
    case ActionTypes.KIFAH_AVAILABLE_ITEMS:
      kifahAvailableItems = action.payload;

      if (action.payload?.length > 0) {
        bagTab = bagTabs.KIFAH_SELECT_TAB;
      }
      break;
    case ActionTypes.KIFAH_TRIGGER:
      kifahTrigger = action.payload;
      break;
    default:
      return state;
  }

  return {
    ...state,
    bagTab,
    kifahAvailableItems,
    kifahTrigger,
  };
}

export default {
  types: types,
  fn: fn,
};
