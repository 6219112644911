import React, { Component } from "react";
import PropTypes from "prop-types";
import { membershipStatePropType, smartTopRightPropType, userDataPropType } from "components/propTypes";
import classNames from "classnames";
import { smartTopRightTextColors, analytics } from "rtr-constants";
import ActionLogger from "action-logger";
import { Referrals } from "routes";
import RtrImage from "../shared/rtr-image";
import styles from "./top-right.module.scss";
import { compose } from "redux";
import {
  flagsAndExperimentsPropType,
  flagsAndExperimentNames,
  withOnLoadFlagsAndExperiments,
} from "components/source/hoc/with-flags-and-experiments";
import HeapHelpers from "helpers/heap-helpers";
import Cookies from "universal-cookie";
import { isIdentified } from "../hoc/with-user-data";

export class TopRight extends Component {
  static propTypes = {
    membershipState: membershipStatePropType,
    smartComponentState: PropTypes.shape({
      didFetchSmartTopRight: PropTypes.bool,
      smartTopRightCMS: smartTopRightPropType,
    }),
    flagsAndExperiments: flagsAndExperimentsPropType,
    userData: userDataPropType,
    flagsAndExperimentsLoaded: PropTypes.bool,
  };

  static defaultProps = {
    smartComponentState: {},
  };

  state = {
    experimentReady: false,
  };

  logImageBanner = () => {
    const { membershipState: { id, membershipId, membershipTermNumber } = {} } = this.props;

    const link = this.props.smartComponentState.smartTopRightCMS?.userMenu?.link;

    if (link === Referrals.inviteAFriend) {
      ActionLogger.inferAction({
        object_type: analytics.OBJECT_TYPE.TOP_NAV,
        action: analytics.ACTION_TYPE.CLICK_REFERRAL_BANNER,
        term: membershipTermNumber,
        membership_id: membershipId,
        user_id: id,
      });
    }

    if (this.isEligibleForPromo()) {
      HeapHelpers.fireHeapEvent("top-right-nco-selected", { componentName: "top-right" });
    }
  };

  componentDidUpdate() {
    const { userData, flagsAndExperiments, flagsAndExperimentsLoaded } = this.props;

    if (
      flagsAndExperimentsLoaded &&
      userData?.userProfileHasLoaded &&
      !this.state.experimentReady &&
      isIdentified(userData) &&
      !userData?.userProfile?.isCustomer
    ) {
      this.setState({ experimentReady: true });

      // Track Experiment
      HeapHelpers.fireHeapEvent("top-right-nco-experiment", {
        isExperiment:
          flagsAndExperiments?.[flagsAndExperimentNames.RSV_1198_NCO_TOP_RIGHT_CHANGE_TEXT_FOR_RESERVE_USERS],
      });
    }
  }

  isEligibleForPromo() {
    const { userData, flagsAndExperiments } = this.props;

    const cookies = new Cookies();
    const lensCookie = cookies.get("membership_lens");

    return (
      flagsAndExperiments?.[flagsAndExperimentNames.RSV_1198_NCO_TOP_RIGHT_CHANGE_TEXT_FOR_RESERVE_USERS] &&
      !userData?.userProfile?.isCustomer &&
      lensCookie === "classic" &&
      isIdentified(userData) &&
      this.state.experimentReady
    );
  }

  renderPromoOffer(userMenu) {
    const displayedTopRightText = this.isEligibleForPromo()
      ? {
          ...userMenu,
          title: `<em>20% OFF FIRST RENTAL</em>`,
          subTitle: "CODE: GETDRESSED",
          link: "/shop/event_rentals/products",
          text: "20% OFF FIRST RENTAL CODE: GETDRESSED",
          textColor: "light",
          fontFamily: "proxima-nova",
          borderColor: "#1C1C1C",
          backgroundColor: "#1C1C1C",
          color: "#FFFFFF",
        }
      : userMenu;

    const {
      title,
      subTitle,
      imageUrl,
      retinaImage,
      link,
      width,
      backgroundColor,
      borderColor,
      textColor,
      fontFamily,
      type,
      altText,
    } = displayedTopRightText;

    const styles = {};
    if (width) {
      styles.width = `${width}px`;
      // remove bounds of max and min width to allow the override to take effect
      styles.maxWidth = "none";
      styles.minWidth = "none";
    }
    if (borderColor) {
      styles.borderColor = borderColor;
    }

    if (backgroundColor) {
      styles.backgroundColor = backgroundColor;
    }

    if (textColor) {
      styles.color = smartTopRightTextColors[textColor] || textColor;
    }

    // either title or imageUrl is required
    if (title) {
      const offerWrapperClass = classNames("offer-wrapper", {
        [`offer-wrapper--${fontFamily}`]: fontFamily,
        "offer-wrapper--button": type === "button",
      });

      const handleClick = () => {
        if (this.isEligibleForPromo()) {
          HeapHelpers.fireHeapEvent("top-right-nco-selected", {
            componentName: "top-right",
          });
        }
      };
      return (
        <a href={link || "#"} className={offerWrapperClass} style={styles} onClick={handleClick}>
          <div className="offer-text" dangerouslySetInnerHTML={{ __html: title }} />
          <div className="subtitle" dangerouslySetInnerHTML={{ __html: subTitle || "" }} />
        </a>
      );
    } else {
      const image = retinaImage || imageUrl;
      const retinaModifier = retinaImage ? "offer-wrapper--retina-image" : "";

      return (
        <a
          href={link || "#"}
          className={`offer-wrapper offer-wrapper--image ${retinaModifier}`}
          style={styles}
          onClick={this.logImageBanner}>
          <RtrImage src={image} alt={altText || ""} />
        </a>
      );
    }
  }

  render() {
    const {
      smartComponentState: { smartTopRightCMS, didFetchSmartTopRight },
      flagsAndExperimentsLoaded,
      userData,
    } = this.props;

    // show loading shimmer if we haven't fetched the smart component yet
    if (!didFetchSmartTopRight || !userData?.userProfileHasLoaded || !flagsAndExperimentsLoaded) {
      return (
        <div data-test-id="shimmer" className={styles.shimmerWrapper}>
          <div className={styles.block1} />
          <div className={styles.block2} />
          <div className={styles.animation} />
        </div>
      );
    }

    // if we've fetched the smart component but it's empty, show empty space
    // so that the top right nav doesn't shift around
    if (!smartTopRightCMS) {
      return <div data-test-id="empty-space" className={styles.emptySpace} />;
    }

    const { userMenu } = smartTopRightCMS;

    return this.renderPromoOffer(userMenu || {});
  }
}

export const TopRightBannerComponent = compose(
  withOnLoadFlagsAndExperiments(flagsAndExperimentNames.RSV_1198_NCO_TOP_RIGHT_CHANGE_TEXT_FOR_RESERVE_USERS)
)(TopRight);

export default TopRightBannerComponent;
