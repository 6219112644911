import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Banner from "components/source/navigation/banner";
import ReferralHelper from "helpers/referral-helper";
import SmartSkinnyBanner from "components/source/navigation/smart-skinny-banner";
import PaidPromoBanner, { isPaidPromoLink } from "components/source/navigation/paid-promo-banner";
import PropTypes from "prop-types";
import { bannerPropType, userDataPropType, userReviewSummaryPropType } from "components/propTypes";
import { withQueryParams } from "../hoc/with-query-params";
import { hasRentedAnything } from "../../layout/UserReviewSummaryContext";
import { paidPromotions } from "rtr-constants";

export class ReferralPromoBanner extends React.Component {
  static propTypes = {
    classicPromo: bannerPropType,
    dispatch: PropTypes.func,
    onHide: PropTypes.func,
    onNotApplicable: PropTypes.func,
    onShow: PropTypes.func,
    ppsPromo: bannerPropType,
    smartComponentState: PropTypes.object,
    queryParams: PropTypes.object,
    userData: userDataPropType,
    userReviewSummary: userReviewSummaryPropType,
  };

  state = {
    referral: null, // initially set to null instead of an empty string to distinguish whether we checked the user's eligibility yet or not
    referralProgram: null,
  };

  configurePromo() {
    const referralPromo = ReferralHelper.getReferralCode() || "";
    const referralProgram = ReferralHelper.getReferralProgram();

    //This check is see if the user has navigated here from a paid promotion link. This banner will supersede all other banners
    //For Users with accounts
    if (isPaidPromoLink(this.props.queryParams)) {
      return;
    }

    if (this.ineligibleForPPSReferral() || this.ineligibleClassicReferral()) {
      if (referralPromo || referralProgram) {
        // Delete the referral promo code from cookies.
        ReferralHelper.deleteReferralCookies();
      }
      this.setState({ referral: "", referralProgram: null });

      return;
    }

    this.setState({ referral: referralPromo, referralProgram: referralProgram });
  }

  ineligibleForPPSReferral() {
    return (
      ReferralHelper.getReferralProgram() === ReferralHelper.REFERRAL_PROGRAM_PAY_PER_SWAP_STACKING &&
      (this.props.userData?.memberships?.isFormerUnlimitedMember ||
        this.props.userData?.memberships?.isFormerRtrUpdateMember ||
        this.props.userData?.memberships?.isRtrUpdateMember)
    );
  }

  ineligibleClassicReferral() {
    // If the referral program is not set or is not a valid program, let's default to classic
    return (
      ReferralHelper.getReferralProgram() !== ReferralHelper.REFERRAL_PROGRAM_PAY_PER_SWAP_STACKING &&
      hasRentedAnything(this.props.userReviewSummary)
    );
  }

  componentDidMount() {
    this.configurePromo();
  }

  render() {
    //only render this banner when the coupon promotion link was loaded.
    if (isPaidPromoLink(this.props.queryParams)) {
      return <PaidPromoBanner />;
    }
    // do not render the SmartSkinnyBanner component until we have determined the user should not see the referral banner.
    // this is to prevent making the call to fetch the smart_skinny_banner content unnecessarily.
    if (this.state.referral === "") {
      return (
        <div className="referral-ineligible">
          <SmartSkinnyBanner
            dispatch={this.props.dispatch}
            smartComponentState={this.props.smartComponentState}
            onShow={this.props.onShow}
            onHide={this.props.onHide}
            onNotApplicable={this.props.onNotApplicable}
          />
        </div>
      );
    }

    const promo =
      this.state.referralProgram === ReferralHelper.REFERRAL_PROGRAM_PAY_PER_SWAP_STACKING
        ? this.props.ppsPromo
        : this.props.classicPromo;

    return (
      <Banner
        bannerId="referral-banner"
        backgroundColor={promo?.backgroundColor}
        color={promo?.color}
        desktopCopy={promo?.desktopCopy}
        desktopCta={promo?.desktopCta}
        details={promo?.details}
        link={promo?.link}
        linkCopy={promo?.linkCopy}
        mobileCopy={promo?.mobileCopy}
        mobileCta={promo?.mobileCta}
        promoCode={this.state.referral}
        onShow={this.props.onShow}
        onHide={this.props.onHide}
        onNotApplicable={this.props.onNotApplicable}
      />
    );
  }
}

const mapStateToProps = state => ({
  userReviewSummary: state.userReviewSummary,
});

export default compose(
  connect(mapStateToProps),
  withQueryParams(paidPromotions.URL_PARAMETER_PAID_PROMOTION)
)(ReferralPromoBanner);
