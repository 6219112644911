import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  tabName: PropTypes.string.isRequired,
  tabDisplayName: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default class AtomTab extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  className() {
    return `atom-tab ${this.props.selected ? "selected" : ""}`;
  }

  handleClick() {
    this.props.onClick(this.props.tabName);
  }

  render() {
    return (
      <li className={this.className()} onClick={this.handleClick}>
        {this.props.tabDisplayName}
      </li>
    );
  }
}

AtomTab.propTypes = propTypes;
