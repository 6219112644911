import ActionTypes from "./action-types";
import { createAction } from "redux-actions";

export const addUpdateItems = createAction(ActionTypes.ADD_UPDATE_ITEMS);

export const removeUpdateItems = createAction(ActionTypes.REMOVE_UPDATE_ITEMS);

export default {
  addUpdateItems,
  removeUpdateItems,
};
