import { format, addDays, isBefore, isFuture } from "date-fns";
import _ from "underscore";
import Constants from "rtr-constants";
import { getMembershipTypeAnalytics } from "helpers/membership-helpers";
import { parseISOWithoutTime } from "helpers/date-helpers";
import StorageHelper from "helpers/storage-helper";
import { LocalStorage } from "../site/localStorage";
import { isIdentified } from "components/source/hoc/with-user-data";

const DAYS_PAST_DUE_FOR_POSTPARTUM = 10;

// Local Storage Keys
const RETURNING_MATERNITY_USER = "returningMaternityUser";
const ALREADY_APPLIED_FILTERS_KEY = "alreadyAppliedFilters";

export function hasMaternityFilters(filters) {
  return filters && _.isEqual(filters.epicMaternity, Constants.allMaternityFilters.epicMaternity);
}

export function formatDueDateForBackend(date) {
  if (_.isUndefined(date)) {
    return null;
  }
  return format(parseISOWithoutTime(date), Constants.dateFnsFormats.YYYY_MM_DD);
}

export function getDueDate(userData) {
  return userData?.userProfile?.profiles?.dueDate;
}

export function getDefaultFormattedDueDate(userData) {
  if (!getDueDate(userData)) return "";
  return format(parseISOWithoutTime(getDueDate(userData)), Constants.dateFnsFormats.MM_DD_YYYY).replace(/\//g, " / ");
}
// inMaternity is a boolean, no need to cast it
// In the case inMaternity does not exist then it will be true
// On the front end, we only care if inMaternity exists and is set to false
// When inMaternity exists and is set to false, we do not show the maternity experience
// Otherwise, we show the maternity experience
// There is tight coupling around inMaternity and dueDate because our Backend does not handle a null dueDate yet
function getInMaternity(userData) {
  const { userProfile: { profiles: { inMaternity = true } = {} } = {} } = userData || {};

  return inMaternity;
}

function hasDueDate(userData) {
  return Boolean(getDueDate(userData));
}

function hasPastDueDate(userData) {
  const dueDate = getDueDate(userData);
  const daysPastDue = addDays(parseISOWithoutTime(dueDate), DAYS_PAST_DUE_FOR_POSTPARTUM);
  return isBefore(daysPastDue, new Date());
}

export function hasMaternityOption(userData = {}) {
  return isIdentified(userData);
}

export function inMaternityExperience(userData) {
  return hasDueDate(userData) && getInMaternity(userData);
}

export function showMaternityBumpImage(userData) {
  return inMaternityExperience(userData) && !hasPastDueDate(userData);
}

function isReturningUserEligibleForAutoApplyMaternityFilters(userData, filters) {
  if (typeof window === "undefined" || LocalStorage.disabled) {
    return {};
  }
  const localStorageClient = new LocalStorage(RETURNING_MATERNITY_USER);
  const alreadyAppliedFilters = localStorageClient.get(ALREADY_APPLIED_FILTERS_KEY);
  return inMaternityExperience(userData) && !hasMaternityFilters(filters) && !alreadyAppliedFilters;
}

// Call this when we set the maternity filters
export function setLocalStorageAutoApplyMaternityFilters() {
  if (typeof window === "undefined" || LocalStorage.disabled) {
    return;
  }
  const localStorageClient = new LocalStorage(RETURNING_MATERNITY_USER);
  localStorageClient.set(ALREADY_APPLIED_FILTERS_KEY, true);
}

export function applyReturningMaternityFilters(userData, filters) {
  if (isReturningUserEligibleForAutoApplyMaternityFilters(userData, filters)) {
    StorageHelper.getApi().setItem(Constants.filters.maternity, Constants.allMaternityFilters.epicMaternity);
    setLocalStorageAutoApplyMaternityFilters();
    return { epicMaternity: Constants.allMaternityFilters.epicMaternity };
  }

  return {};
}

export function inPostPartumExperience(userData) {
  return getInMaternity(userData) && hasPastDueDate(userData);
}

// If user is in the experience then send the due date, if not then send null
export function maternityCarouselDueDateParam(userData) {
  if (!inMaternityExperience(userData)) {
    return null;
  }
  const dueDate = getDueDate(userData);
  return formatDueDateForBackend(dueDate);
}

export function maternityBasePixel(objectType, moduleType, action, userData = {}, dueDate = {}, additionalData = {}) {
  // Send an empty string if the due date is undefined
  const dueDateParam = _.isEmpty(dueDate) ? getDueDate(userData) : dueDate;
  return {
    ...additionalData,
    object_type: objectType,
    module_type: moduleType,
    action: action,
    membership: getMembershipTypeAnalytics(userData.memberships),
    pro: userData.memberships.isProMember,
    due_date: _.isEmpty(dueDateParam)
      ? ""
      : format(parseISOWithoutTime(dueDateParam), Constants.dateFnsFormats.YYYY_MM_DD),
  };
}

export const validateMaternityDueDate = value => {
  let date;
  try {
    date = parseISOWithoutTime(value);
  } catch {
    return false;
  }
  return isFuture(date);
};

export const appendMaternityFilters = workingFilters => {
  const maternityFilters = Constants.allMaternityFilters;
  return {
    ...workingFilters,
    ...maternityFilters,
  };
};

export const removeMaternityFilters = workingFilters => {
  return _.omit(workingFilters, Constants.filters.maternity);
};

export default {
  getDueDate,
  getDefaultFormattedDueDate,
  hasMaternityFilters,
  hasMaternityOption,
  inMaternityExperience,
  showMaternityBumpImage,
  applyReturningMaternityFilters,
  setLocalStorageAutoApplyMaternityFilters,
  inPostPartumExperience,
  maternityCarouselDueDateParam,
  maternityBasePixel,
  appendMaternityFilters,
  removeMaternityFilters,
  validateMaternityDueDate,
};
