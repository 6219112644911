// from R.js
let _sd = {
  r: 163,
};

/**
 * Sd seems to be some method of data persistence that survives a page reload
 */
export function initSd() {
  try {
    const d = JSON.parse(window.name);
    if (typeof d === "object" && d.rtr === 163) {
      _sd = d;
    }
  } catch (e) {
    // noop
  }

  // Save any changes to the Javascript session data upon page unload
  const f = function () {
    window.name = JSON.stringify(_sd);
  };
  window.addEventListener("unload", f, false);
}

/**
 Used to get or set Javascript session variables that persist between page loads

 @function
 @param {String} key The Unique name for the variable
 @param {Object} val The value (object or array, etc) to store
 @return {Object} Returns the value of the key, if no value is given. Returns <i>undefined</i> if the key does not exist
 */
export function jsSession(key, val) {
  if (typeof val === "undefined") {
    return _sd[key];
  } else if (val === null) {
    delete _sd[key];
  } else {
    _sd[key] = val;
  }
}

/**
 * Sets the tabId if it is not already set
 */
export function setTabId() {
  // Set the tabId if it has not already been set
  if (!_sd.tabId) {
    _sd.tabId = Math.round(Math.random() * 0x10000);
  }
}
