import { APPLE_PAY_STRING, PaymentMethods } from "rtr-constants";
import AtomCreditCardIcon from "components/source/atoms/atom-credit-card-icon";

/*
 * Find all of a user's payment methods of a certain subType, e.g., all credits, all credit cards.
 * @param paymentProfiles: a list of payment methods. Each has an id and subType property.
 * @param subType: a string, e.g., "credits, "creditCard"
 */
export const paymentProfilesOfSubType = (paymentProfiles, subType) => {
  if (!paymentProfiles) return;

  return paymentProfiles.filter(payment => payment.subType === subType);
};

export const paymentProfilesOfSubTypes = (paymentProfiles, subTypes) => {
  if (!paymentProfiles || !subTypes?.length) return;

  return paymentProfiles.filter(payment => subTypes.includes(payment.subType));
};

export const getPrimaryPaymentMethod = paymentMethods => {
  if (!paymentMethods) return null;
  return paymentMethods.find(method => method.default === true) ?? null;
};

export const getBillingPaymentMethod = paymentMethods => {
  if (!paymentMethods) return null;
  return paymentMethods.find(method => method.recurring === true) ?? null;
};

export const paymentProfileDisplayString = paymentProfile => {
  let result;
  if (paymentProfile?.subType === PaymentMethods.subType.APPLE_PAY) return APPLE_PAY_STRING;

  if (paymentProfile?.lastFourDigits) {
    result = `Ending in ${paymentProfile.lastFourDigits}`;
  } else if (paymentProfile?.cardNumber) {
    result = `Ending in ${paymentProfile.cardNumber.substr(-4)}`;
  } else {
    result = null;
  }

  return result;
};

export const getFormattedCardDetails = (card = {}) => {
  const { firstName, lastName, subType } = card;
  const isCreditCard = subType === PaymentMethods.subType.CREDIT_CARD;
  const cardName = [firstName, lastName].join(" ").trim();
  const last4Digits = isCreditCard ? card.cardNumber?.substr(-4) : null;
  const ccString = last4Digits ? `Ending in ${last4Digits}` : "";
  const expirationArray = card.expiration ? card.expiration.split("-") : [];
  const expirationYear = expirationArray[0] ? expirationArray[0].substr(-2) : "";
  const expirationDate = expirationArray[1] && expirationYear ? `Exp ${expirationArray[1]} / ${expirationYear}` : "";

  return {
    cardName,
    ccString,
    expirationDate,
    last4Digits,
  };
};

export const hasAPriceDifferenceButUserHasNoPaymentProfiles = (paymentProfiles, priceChange) => {
  if (!priceChange || !priceChange.netDifference) return false;

  const isAPriceDifference =
    parseFloat(priceChange?.netDifference.creditCardDollarDifference) +
      parseFloat(priceChange?.netDifference.userPointsDollarValueDifference) !==
    0;

  return isAPriceDifference && paymentProfiles && paymentProfiles.length === 0;
};

/** Callers: SMKifahFooterComponent and MembershipFooter
 * activeCard: handle for both bag and checkout paymentMethods
 * isMembersBag: default true but set to false in kifah footer
 */
export const getBillingInfo = (activeCard, isMembersBag = true) => {
  let ccString, cardType, cardSubType;

  if (
    activeCard?.subType === PaymentMethods.subType.APPLE_PAY ||
    activeCard?.type === PaymentMethods.bagMethodType.APPLE_PAY_PLACEHOLDER
  ) {
    ccString = APPLE_PAY_STRING;
    cardType = "APPLE_PAY";
    cardSubType = PaymentMethods.subType.APPLE_PAY;
  } else {
    ccString = paymentProfileDisplayString(activeCard);
    cardType = activeCard?.cardType;
    cardSubType = PaymentMethods.subType.CREDIT_CARD;
  }

  const paymentType = isMembersBag ? "Billing" : "Payment";
  const billingInfoCopy = activeCard ? ccString : `Please Update Your ${paymentType} Information`;
  const creditCardIcon = cardType ? (
    <AtomCreditCardIcon cardType={cardType.toUpperCase()} cardSubType={cardSubType} />
  ) : null;

  return {
    billingInfoCopy,
    creditCardIcon,
  };
};
