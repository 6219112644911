import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DiscoSearchServiceClient } from "../clients/DiscoSearchServiceClient";
import { userStylePreferences } from "../constants";

export const fetchMembershipHomepageCarouselsData = async (
  carouselList,
  userPreferences,
  membershipState,
  userProfile,
  canonicalSizes
) => {
  const preferredStyleSet = getPreferenceSet(userStylePreferences.STYLE_ID, userPreferences);
  const preferredEventSet = getPreferenceSet(userStylePreferences.EVENT_ID, userPreferences);
  const eligibility = membershipState?.inventoryEligibilities?.includes("rtr_unlimited")
    ? "FULL_CLOSET"
    : "BASIC_CLOSET";
  let preferredSizes = [];
  if (Array.isArray(canonicalSizes) && canonicalSizes.length > 0) {
    preferredSizes = canonicalSizes;
  } else if (userProfile?.profiles) {
    if (userProfile.profiles.primarySize) {
      preferredSizes.push(userProfile.profiles.primarySize);
    }
    if (userProfile.profiles.backupSize) {
      preferredSizes.push(userProfile.profiles.backupSize);
    }
  }

  const carouselsToRequest = carouselList.filter(carousel =>
    shouldShowCarousel(carousel, preferredStyleSet, preferredEventSet)
  );

  return Promise.allSettled(
    carouselsToRequest.map(carousel =>
      fetchCarousel(carousel, eligibility, membershipState.shipmentPostalCode, preferredSizes)
    )
  );
};

async function fetchCarousel(carousel, eligibility, postalCode, preferredSizes) {
  const query = {
    curationId: [carousel.id],
    programEligibility: eligibility,
    sortOptions: "CURATION",
    ...(postalCode && { zipCode: postalCode }),
    ...(preferredSizes.length > 0 && { canonicalSizes: preferredSizes }),
  };

  return DiscoSearchServiceClient.getInstance()
    .curationSearch(query, 0, 20, "unlimited")
    .then(res => ({
      id: carousel.id,
      displayName: carousel.display_name,
      items: res.items || [],
      meta: res.meta || {},
    }));
}

/**
 * Helper method for fetching carousel data
 * @param {string} preferenceType Will be "styleTypes" or "eventTypes"
 * @param {object} userPreferences containing all user preferences
 * @returns Set of specified preference
 */
function getPreferenceSet(preferenceType, userPreferences) {
  const preferences = userPreferences?.[preferenceType] || null;
  if (preferences && Array.isArray(preferences)) {
    return new Set(preferences);
  }
  return new Set();
}

/**
 * Helper method to parse carousel data against user preferences
 * @param {object} carousel Carousel to parse
 * @param {set} styleSet
 * @param {set} eventSet
 * @returns
 */
function shouldShowCarousel(carousel, styleSet, eventSet) {
  //  if the carousel has no specific eligibilities listed, it should be displayed
  if (!carousel.eligibilities) {
    return true;
  }
  //  if the carousel does contain specific eligibilities,
  //  return true only if that user has those specific style or event preferences
  for (const eligibility of carousel.eligibilities) {
    if (eligibility.startsWith("style_types_")) {
      return styleSet.has(eligibility.slice(12));
    } else if (eligibility.startsWith("event_types_")) {
      return eventSet.has(eligibility.slice(12));
    }
  }
  return false;
}

export function getCarouselListForMembership(carouselLists, membershipState) {
  let carouselList = [];
  if (membershipState.membershipTermNumber === 0) {
    carouselList = carouselLists["T0"] ? carouselLists["T0"] : carouselLists["default"] || [];
  } else {
    carouselList = carouselLists["default"] || [];
  }
  return carouselList;
}

export const fetchMembershipHomepageCarousels = createAsyncThunk(
  "membershipHomepageCarousels/fetchCarousels",
  async (canonicalSizes, { getState }) => {
    const state = getState();
    const { cmsCarouselLists, userPreferences, membershipState, userData } = state;
    const carouselList = getCarouselListForMembership(cmsCarouselLists, membershipState);
    try {
      return fetchMembershipHomepageCarouselsData(
        carouselList,
        userPreferences,
        membershipState,
        userData?.userProfile,
        canonicalSizes
      );
    } catch (e) {
      return Promise.reject([]);
    }
  }
);

export const membershipHomepageCarouselsSlice = createSlice({
  name: "membershipHomepageCarousels",
  initialState: [],
});

export const membershipHomepageCarouselsReducer = {
  types: ["membershipHomepageCarousels/fetchMembershipHomepageCarousels/fulfilled"],
  fn: membershipHomepageCarouselsSlice.reducer,
};
