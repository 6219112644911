import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { emergencyBanner } from "rtr-constants";
import { loadCMSContent } from "actions/shared-actions";
import { isStorefrontNext } from "../../../helpers/environment-helpers";

const propTypes = {
  cmsContent: PropTypes.object, // a string of HTML provided by CMS
  fetchEmergencyBannerFromCMS: PropTypes.func.isRequired,
};

export function EmergencyBannerComponent({ cmsContent, fetchEmergencyBannerFromCMS }) {
  // once we are fully on Storefront.Next, remove useEffect code and use useCms
  // const cmsContent = useCms();

  useEffect(() => {
    // cmsContent will already be present on SF next
    if (!isStorefrontNext() && cmsContent) return;

    fetchEmergencyBannerFromCMS(cmsContent);
  }, []);

  // we don't actually save the emergencyBanner content under the STATE_KEY in the redux store
  // until useEffect runs. But we already have it at this point in redux saved under the RESPONSE_FIELD key.
  // The logic here seems a bit finnicky, so rather than trying to refactor, let's just also check the
  // RESPONSE_FIELD for the content. This way, we can actually SSR the emergency banner content instead
  // of waiting for useEffect to run.
  const bannerContent = cmsContent?.[emergencyBanner.STATE_KEY] || cmsContent?.[emergencyBanner.RESPONSE_FIELD];

  if (!bannerContent) return null;

  const innerHtml = {
    __html: bannerContent,
  };

  return <div id="emergency-banner" dangerouslySetInnerHTML={innerHtml} />;
}
EmergencyBannerComponent.propTypes = propTypes;

const mapStateToProps = ({ cmsContent }) => ({ cmsContent });

const mapDispatchToProps = dispatch => {
  return {
    fetchEmergencyBannerFromCMS(cmsContent) {
      dispatch(
        loadCMSContent(
          emergencyBanner.CMS_PATH,
          emergencyBanner.STATE_KEY,
          emergencyBanner.RESPONSE_FIELD,
          false, // isJSON
          null, // callback,
          cmsContent || null
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyBannerComponent);
