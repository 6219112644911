import stylesLoading from "./loading-state.module.scss";
import styles from "./styles.module.scss";

export default function LoadingStateUpsell() {
  return (
    <div className={`${styles["upsell-grid"]} ${stylesLoading["container"]}`} data-test-id="upsell-loading-state">
      <p className={` ${styles["upsell-grid-title"]} ${stylesLoading["title-shimmer-block"]}`}></p>
      <div className={styles["upsell-grid-items"]} data-test-id="upsell-grid-items">
        {Array.from(Array(8)).map((_, index) => {
          return (
            <div key={index}>
              <div className={`${styles["upsell-grid-item"]} ${stylesLoading["img-shimmer-block"]} `} />
              <div className={styles.label__wrapper}>
                <div className={`${stylesLoading["img-shimmer-block__title"]} `} />
              </div>
            </div>
          );
        })}
      </div>
      <div className={`${stylesLoading["shimmer-animation"]}`} />
    </div>
  );
}
