import _ from "underscore";
import ActionTypes from "../actions/action-types.js";
var replacementsMethods, fn;

var cloneReplacementsState = function (state, bookingId, upgradeSectionName) {
  var newReplacements = _.extend({}, state),
    newGroup,
    newUpgradeSection;

  if (state[bookingId]) {
    newGroup = _.extend({}, state[bookingId]);
    newReplacements[bookingId] = newGroup;

    if (upgradeSectionName && newGroup.upgrades[upgradeSectionName]) {
      newUpgradeSection = _.extend({}, newGroup.upgrades[upgradeSectionName]);
      newGroup.upgrades[upgradeSectionName] = newUpgradeSection;
    }
  }

  return newReplacements;
};

var addReplacement = function (state, action) {
  var newReplacements = cloneReplacementsState(state, action.payload.bookingId);
  var newGroup = newReplacements[action.payload.bookingId];

  if (!_.isUndefined(newGroup)) {
    newGroup.chosenReplacements = newGroup.chosenReplacements.concat(action.payload.replacement);
  }

  return newReplacements;
};

var removeReplacement = function (state, action) {
  var newReplacements = cloneReplacementsState(state, action.payload.bookingId);
  var newGroup = newReplacements[action.payload.bookingId];

  if (!_.isUndefined(newGroup)) {
    var removedReplacement = _.find(newGroup.chosenReplacements, function (chosenReplacement) {
      return chosenReplacement.style === action.payload.replacement.style;
    });

    newGroup.chosenReplacements = _.without(newGroup.chosenReplacements, removedReplacement);
  }

  return newReplacements;
};

var updateReplacementDisplay = function (state, action) {
  var newReplacements = cloneReplacementsState(state);

  _.each(action.payload.displays, function (display) {
    var newDisplayGroup = _.extend({}, state[display.bookingId]);
    newDisplayGroup.display = display.display;
    newReplacements[display.bookingId] = newDisplayGroup;
  });

  return newReplacements;
};

var updateOpenFilters = function (state, action) {
  var newReplacements = cloneReplacementsState(state, action.payload.bookingId, action.payload.sectionName);
  var newGroup = newReplacements[action.payload.bookingId];

  if (!_.isUndefined(newGroup)) {
    var newUpgrade = newGroup.upgrades[action.payload.sectionName];
    var newOpenFilters = newUpgrade.openFilters;

    if (!_.isArray(newOpenFilters)) {
      newOpenFilters = [];
    }

    if (action.payload.open) {
      newOpenFilters.push(action.payload.filterGroupKey);
      newUpgrade.openFilters = _.uniq(newOpenFilters);
    } else {
      newUpgrade.openFilters = _.without(newOpenFilters, action.payload.filterGroupKey);
    }
  }

  return newReplacements;
};

function updateSection(state, action) {
  var newReplacements = cloneReplacementsState(state, action.payload.bookingId, action.payload.sectionName);
  var newGroup = newReplacements[action.payload.bookingId];

  if (!_.isUndefined(newGroup)) {
    newGroup.upgrades[action.payload.sectionName] = action.payload.data;
  }

  return newReplacements;
}

function updateSectionErrors(state, action) {
  var newReplacements = cloneReplacementsState(state, action.payload.bookingId, action.payload.sectionName);
  var newGroup = newReplacements[action.payload.bookingId];

  if (!_.isUndefined(newGroup)) {
    newGroup.upgrades[action.payload.sectionName].errors = action.payload.errors;
  }

  return newReplacements;
}

function changeFilters(state, action) {
  var newReplacements = cloneReplacementsState(state, action.payload.bookingId, action.payload.sectionName);
  var newGroup = newReplacements[action.payload.bookingId];

  if (!_.isUndefined(newGroup)) {
    newGroup.upgrades[action.payload.sectionName].workingFilters = action.payload.workingFilters;
  }

  return newReplacements;
}

function changePage(state, action) {
  var newReplacements = cloneReplacementsState(state, action.payload.bookingId, action.payload.sectionName);
  var newGroup = newReplacements[action.payload.bookingId];

  if (!_.isUndefined(newGroup)) {
    var newSection = newGroup.upgrades[action.payload.sectionName];

    newSection.workingPage = action.payload.workingPage;
    newSection.nextPage = action.payload.workingPage < newSection.totalPages;
    newSection.previousPage = action.payload.workingPage > 1;
  }

  return newReplacements;
}

function changeSort(state, action) {
  var newReplacements = cloneReplacementsState(state, action.payload.bookingId, action.payload.sectionName);
  var newGroup = newReplacements[action.payload.bookingId];

  if (!_.isUndefined(newGroup)) {
    newGroup.upgrades[action.payload.sectionName].workingSort = action.payload.workingSort;
  }
  return state;
}

replacementsMethods = {};
replacementsMethods[ActionTypes.REPLACEMENTS_ADD_REPLACEMENT] = addReplacement;
replacementsMethods[ActionTypes.REPLACEMENTS_REMOVE_REPLACEMENT] = removeReplacement;
replacementsMethods[ActionTypes.REPLACEMENTS_UPDATE_DISPLAY] = updateReplacementDisplay;
replacementsMethods[ActionTypes.REPLACEMENTS_TOGGLE_OPEN_FILTERS] = updateOpenFilters;
replacementsMethods[ActionTypes.REPLACEMENTS_UPDATE_SECTION] = updateSection;
replacementsMethods[ActionTypes.REPLACEMENTS_TRIGGER_SECTION_ERROR] = updateSectionErrors;
replacementsMethods[ActionTypes.REPLACEMENTS_CHANGE_FILTERS] = changeFilters;
replacementsMethods[ActionTypes.REPLACEMENTS_CHANGE_PAGE] = changePage;
replacementsMethods[ActionTypes.REPLACEMENTS_CHANGE_SORT] = changeSort;

fn = function (state, action) {
  var replacementActionMethod = replacementsMethods[action.type];

  var newReplacements = !_.isUndefined(replacementActionMethod)
    ? replacementActionMethod(state.replacements, action)
    : _.extend({}, state.replacements);

  return _.extend({}, state, { replacements: newReplacements });
};

export default {
  types: Object.keys(replacementsMethods),
  fn: fn,
};
