import { useState } from "react";
import { getVisiblePhoneNumber } from "helpers/format-phone-number-helper";
import PropTypes from "prop-types";

const propTypes = {
  ariaLabel: PropTypes.string,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInputCallback: PropTypes.func,
  originalPhoneNumber: PropTypes.string,
  tabIndex: PropTypes.number,
};
PhoneInputComponent.propTypes = propTypes;

export default function PhoneInputComponent({
  ariaLabel,
  autoComplete,
  className,
  disabled,
  name,
  id,
  onBlur,
  onFocus,
  onInputCallback,
  originalPhoneNumber,
  tabIndex,
}) {
  const startingNumber = getVisiblePhoneNumber(originalPhoneNumber);
  const [inputValue, setInputValue] = useState(startingNumber);

  if (startingNumber !== inputValue) {
    setInputValue(startingNumber);
  }

  const handleInput = e => {
    const visiblePhoneNumber = getVisiblePhoneNumber(e.target.value);
    setInputValue(visiblePhoneNumber);
    onInputCallback(visiblePhoneNumber);
  };

  return (
    <input
      onChange={e => handleInput(e)}
      value={inputValue}
      autoComplete={autoComplete ?? null}
      aria-label={ariaLabel ?? null}
      className={className ?? null}
      id={id ?? null}
      name={name ?? null}
      onBlur={onBlur ?? null}
      onFocus={onFocus ?? null}
      tabIndex={tabIndex ?? null}
      type="tel"
      disabled={disabled ?? null}
      data-test-id="phone-input"
    />
  );
}
