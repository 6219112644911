import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import PropTypes from "prop-types";

const propTypes = {
  inputClass: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholderInput: PropTypes.element.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  additionalClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  isValid: PropTypes.bool,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.string,
  logError: PropTypes.func.isRequired,
  googleMapsLoaded: PropTypes.bool.isRequired,
  autoComplete: PropTypes.string, // autoComplete, the browser feature
  handleAutocompleteSelect: PropTypes.func, // refers to Google Maps Auto complete (not the browser feature)
  tabIndex: PropTypes.number, // it is NOT recommended to set tabIndex. if you want to skip elements, set _them_ to tabIndex=-1
};

class AtomPlacesAutocompleteInput extends React.Component {
  state = {
    autocompleteFocused: false,
  };

  toggleFocusClass = bool => {
    this.setState({
      autocompleteFocused: bool,
    });
  };

  // We have to apply a 'force-focus' class here because there are non-adjacent elements which make it difficult to
  // maintain the same behavior as our adjacent input and label using a traditional focus pseudo-selector.
  focusInputClass() {
    return this.state.autocompleteFocused ? "force-focus" : "";
  }

  triggerError = status => {
    // These error statuses come back from Google once loaded. By this time, a request has come back
    // so there is no need to guard as to whether `google` is present.
    if (
      google.maps.places?.PlacesServiceStatus &&
      (status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT ||
        status === google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR ||
        status === google.maps.places.PlacesServiceStatus.INVALID_REQUEST ||
        status === google.maps.places.PlacesServiceStatus.REQUEST_DENIED)
    ) {
      this.props.logError(status);
    }
  };

  // We add a onFocus/onBlur to force a fake class onto the top-level element
  // our css animations assume adjacency that does not exist with this library. The input
  // is wrapped by a div.
  inputProps() {
    // Only show the street address, rather than the entire autocompleted address
    return {
      autoComplete: this.props.autoComplete,
      onFocus: () => {
        this.toggleFocusClass(true);
      },
      onBlur: event => {
        // This ensures that if a user has selected an address using the arrow keys but tabs to the next
        // field, we still pass in the autocompleted address into the rest of the form. In the case that they
        // have not selected an autocompleted address(ie. zero or one commas, as opposed to 3 returned by
        // Google) we opt to not pass the address to be geocoded and instead allow them to tab to the next input.
        const address = event.target.value;
        const match = address.match(/,/g);

        if (match?.length >= 2) {
          this.props.handleAutocompleteSelect(address);
        }
        this.toggleFocusClass(false);
      },
      onError: this.triggerError,
      onChange: this.props.onChange,
      value: this.props.value,
      name: this.props.name,
      id: this.props.id,
    };
  }

  // Wait until three characters have been entered until we begin to suggest using autocomplete.
  shouldFetchSuggestions({ currentTarget, value }) {
    // no need to use the API if the browser autoCompletes the field. With
    // browser autoComplete, the input field is unlikely to have focus
    if (currentTarget !== document.activeElement) {
      return;
    }

    // if the input is too short, don't waste the API call (which we pay for!)
    return value.length >= 3;
  }

  render() {
    if (this.props.googleMapsLoaded) {
      return (
        <PlacesAutocomplete
          inputProps={this.inputProps()}
          onSelect={this.props.handleAutocompleteSelect}
          onEnterKeyDown={this.props.handleAutocompleteSelect}
          googleLogo={false}
          classNames={{
            root: `${this.props.inputClass} ${this.focusInputClass()}`,
            autocompleteContainer: "atom-places-autocomplete-input__dropdown",
          }}
          id={this.props.id}
          type={this.props.type}
          name={this.props.name}
          onClick={this.props.onClick}
          options={{ componentRestrictions: { country: "us" }, types: ["address"] }}
          maxLength={this.props.maxLength}
          tabIndex={this.props.tabIndex}
          shouldFetchSuggestions={this.shouldFetchSuggestions}
        />
      );
    } else {
      return this.props.placeholderInput;
    }
  }
}

AtomPlacesAutocompleteInput.propTypes = propTypes;
export default AtomPlacesAutocompleteInput;
