import ModalDrawer from "components/source/shared/modal-drawer/modal-drawer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showBonusStyleUpsellModal } from "../../../../slices/reservation-slice";
import styles from "./styles.module.scss";
import { toggleBag } from "actions/bag-actions";
import LoadingStateUpsell from "./upsell-loading-state";
import UpsellBonusStyleVisualNav from "./upsell-visual-nav";
import UpsellGrid from "./upsell-grids";
import UpsellStickyCta from "./upsell-sticky-ctas";
import UpsellHeader from "./upsell-header";
import HeapHelpers from "helpers/heap-helpers";

export const UPSELL_CURATION_ID = "event_rentals";

export default function UpsellBonusStyle() {
  const similarStyles = useSelector(state => state.reserveUpsellModalCuration);
  const bonusStyleUpsellModal = useSelector(state => state.showBonusStyleUpsellModal);
  const [bonusPrice, setBonusPrice] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bonusStyleUpsellModal) {
      setBonusPrice(bonusStyleUpsellModal?.duration === 4 ? 32.5 : 52);
      HeapHelpers.fireHeapEvent("upsell_sheet_open", bonusStyleUpsellModal);
    }
  }, [bonusStyleUpsellModal]);

  const viewBag = () => {
    dispatch(toggleBag(true));
    dispatch(showBonusStyleUpsellModal(null));
  };

  return (
    <ModalDrawer
      isOpen={bonusStyleUpsellModal}
      peekHeight={"70vh"}
      desktopWidth="488px"
      handleClose={() => {
        dispatch(showBonusStyleUpsellModal(null));
      }}>
      <div className={styles["upsell-container"]}>
        <UpsellHeader bonusPrice={bonusPrice} />
        <UpsellBonusStyleVisualNav />
        <div className={styles["upsell-grid-container"]}>
          {Array.isArray(similarStyles) && similarStyles.length ? (
            <UpsellGrid bonusPrice={bonusPrice} />
          ) : (
            <LoadingStateUpsell />
          )}
        </div>
        <UpsellStickyCta viewBag={viewBag} />
      </div>
    </ModalDrawer>
  );
}
