import Constants from "rtr-constants";
import ProductImagePath from "./product-image-path";
const { membershipStyles } = Constants.specialSkus;

export class ProductImageSize {
  static x70 = new ProductImageSize("70x", 70, 105);
  static x183 = new ProductImageSize("183x", 183, 275);
  static x270 = new ProductImageSize("270x", 270, 405);
  static x309 = new ProductImageSize("309x", 309, 464);
  static x360 = new ProductImageSize("360x", 360, 540);
  static x480 = new ProductImageSize("480x", 480, 720);
  static x600 = new ProductImageSize("600x", 600, 900);
  static x800 = new ProductImageSize("800x", 800, 1200);
  static x1080 = new ProductImageSize("1080x", 1080, 1620);
  static ORIGINAL = new ProductImageSize("original", 1080, 1620);

  constructor(path, xSize, ySize) {
    this.path = path;
    this.xSize = xSize;
    this.ySize = ySize;

    Object.freeze(this);
  }
}

export class ProductImageOrientation {
  static EDITORIAL = new ProductImageOrientation("editorial", "editorial");
  static NO_MODEL = new ProductImageOrientation("noModel", "nomodel");
  static WITH_MODEL = new ProductImageOrientation("withModel", "withmodel");
  static FRONT = new ProductImageOrientation("front", "front");
  static FRONT_PLUS = new ProductImageOrientation("frontPlus", "front_plus");
  static BACK = new ProductImageOrientation("back", "back");
  static BACK_PLUS = new ProductImageOrientation("backPlus", "back_plus");
  static SIDE = new ProductImageOrientation("side", "side");
  static SIDE_PLUS = new ProductImageOrientation("sidePlus", "side_plus");
  static TOP = new ProductImageOrientation("top", "top");
  static BUMP_FRIENDLY = new ProductImageOrientation("bumpFriendly", "bump_friendly");
  static BUMP_FRONT = new ProductImageOrientation("bumpFront", "bump_front");
  static BUMP_SIDE = new ProductImageOrientation("bumpSide", "bump_side");
  static FLAT = new ProductImageOrientation("flat", "flat");
  static OUTFIT = new ProductImageOrientation("outfit", "outfit");

  constructor(path, legacyPath) {
    this.path = path;
    this.legacyPath = legacyPath;

    Object.freeze(this);
  }
}

/**
 * A helper that uses a list of product image description paths to select the image url from the product image group
 * @param images An object that contains properties equal to a ProductImageDescription.path that contain image urls
 *                by size (i.e.
 *                   { front: { 70x: "/.../...", 1080x: "/.../..." }, back: { 70x: "/.../...", 1080x: "/.../..." } }
 *                ). This is usually pulled from a product object via product.images.
 * @param {ProductImageOrientation.path[]} productImageOrientations An array of image description types.
 *                                                                  (i.e. noModel, front, back, etc.)
 * @returns {{ [ProductImageDescription.path]: string } | null} An object with image urls by image description type.
 */
export function getProductImageUrlsByOrientation(images, productImageOrientations = [], useFallback = true) {
  if (!images) return null;

  for (const productImageOrientation of productImageOrientations) {
    if (images[productImageOrientation]) return images[productImageOrientation];
  }

  const firstAvailableOrientation = Object.keys(images)[0];

  if (useFallback) return images[firstAvailableOrientation] || null;

  return null;
}

export function mapLegacyProductImageBySizeToOrientation(imagesBySize = {}) {
  const images = {};

  for (const [sizeKey, sizeValue] of Object.entries(imagesBySize)) {
    for (const value of sizeValue) {
      if (!images[value.orientation]) images[value.orientation] = {};
      if (!images[value.orientation][sizeKey]) {
        const url = new URL(value.url, ProductImagePath);
        images[value.orientation][sizeKey] = normalizeUrlPath(url?.pathname);
      }
    }
  }

  return images;
}

// from: https://github.com/vercel/next.js/pull/18391/files
export function normalizeUrlPath(src) {
  return src?.[0] === "/" ? src.slice(1) : src;
}

/**
 * The Fastly Image Optimizer (IO) manipulates and transforms images as they pass through the Fastly network, and caches
 * optimized versions of them. This helper generates query params to append to the base image url.
 * @param src Base image url
 * @param width The width parameter enables dynamic width resizing based on pixels and percent values.
 * @param quality The quality parameter enables control over the compression level for lossy file-formatted images.
 * @returns {URL} Full image url, including Fastly IO query params
 * @see {https://developer.fastly.com/reference/io/}
 */
export function getFastlyOptimizedProductImageUrl({ src, width, quality }) {
  const url = getProductImageUrl(src);

  url.searchParams.set("auto", "webp");
  url.searchParams.set("optimize", "medium");
  if (width) {
    url.searchParams.set("width", width.toFixed(0));
  }
  if (quality) {
    url.searchParams.set("quality", quality.toFixed(0));
  }

  return url;
}

export function getProductImageUrl(src) {
  return new URL(normalizeUrlPath(src), ProductImagePath);
}

export function getProductImageAltText(productDisplayName, designerDisplayName) {
  return (productDisplayName || "Item") + " by " + (designerDisplayName || "Designer");
}

export const includesBumpImages = images => {
  const imageKeys = Object.keys(images);
  return imageKeys.some(key => key.includes("bump"));
};

// For clothing, we generally treat the "noModel" image as the main image to display.
// However, accessories generally don't have a "noModel" image--instead, they have "front", "back", etc and "withModel".
// So we will use "noModel" if possible and fall back to "front".
//
// Expects an object like:
// {
//   front: {...},
//   back: {...},
//   noModel: {...},
//   ...
// }
//
// TODO: update usages: https://renttherunway.jira.com/browse/SELF-4218
// Refactor this peice once experiment RSV_CHANGE_DEFAULT_IMAGE_IN_BAG is over
export const selectMainImageGroup = images =>
  getProductImageUrlsByOrientation(images, [
    ProductImageOrientation.NO_MODEL.path,
    ProductImageOrientation.FRONT.path,
    ProductImageOrientation.WITH_MODEL.path,
  ]) || {};

// Refactor this peice once experiment RSV_CHANGE_DEFAULT_IMAGE_IN_BAG is over
export const selectImageWithModel = images =>
  getProductImageUrlsByOrientation(images, [
    ProductImageOrientation.FRONT.path,
    ProductImageOrientation.WITH_MODEL.path,
    ProductImageOrientation.NO_MODEL.path,
  ]) || {};

export const augmentSubscriptionProductWithImages = (product = {}) => ({
  ...product,
  designer: { displayName: "Rent the Runway" },
  displayName: "RTR Membership",
  images: {
    [ProductImageOrientation.FRONT.path]: {
      [ProductImageSize.x70
        .path]: `productimages/${ProductImageOrientation.FRONT.path}/${ProductImageSize.x70.path}/07/${membershipStyles.UPDATE}.jpg`,
      [ProductImageSize.x183
        .path]: `productimages/${ProductImageOrientation.FRONT.path}/${ProductImageSize.x183.path}/07/${membershipStyles.UPDATE}.jpg`,
      [ProductImageSize.x1080
        .path]: `productimages/${ProductImageOrientation.FRONT.path}/${ProductImageSize.x1080.path}/07/${membershipStyles.UPDATE}.jpg`,
    },
  },
});

/**
 * Function to take an array of image urls that were served from Disco and
 * convert them into the orientation -> size -> url structure that ProductImage
 * expects.
 */
export const discoArrayToProductImageStruct = imageUrls => {
  const result = {};

  for (const imageUrl of imageUrls) {
    const fragments = imageUrl.split("/");

    if (!result[fragments[1]]) result[fragments[1]] = {};
    result[fragments[1]][fragments[2]] = imageUrl;
  }

  return result;
};
