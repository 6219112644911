import _ from "underscore";
import ActionTypes from "../actions/action-types.js";
import { removeAvailability, replaceAvailability } from "./partial-products-reducer";

var skusWithSizeAvailability, subscriptionSkusWithSizeAvailability;

export const types = [
  ActionTypes.RESERVATION_FORM_FETCH_AVAILABILITY_SUCCESS,
  ActionTypes.FETCH_PRODUCT_AVAILABILITY_FOR_CAROUSELS_SUCCESS,
];

skusWithSizeAvailability = function (state, action) {
  var skus = _.extend([], state.product.skus);
  switch (action.type) {
    case ActionTypes.RESERVATION_FORM_FETCH_AVAILABILITY_SUCCESS:
      skus = action.payload.skus;
      break;

    default:
      return skus;
  }

  return skus;
};

// This function is used when we try to add to bag from somewhere
// where we don't have the product hash, instead we add these skus
// to subscriptionSkus key in the store.
subscriptionSkusWithSizeAvailability = function (state, action) {
  var skus = action.payload.skus || [];
  switch (action.type) {
    case ActionTypes.RESERVATION_FORM_FETCH_AVAILABILITY_SUCCESS:
      skus = action.payload.skus;
      break;

    default:
      return skus;
  }

  return skus;
};

export function fn(state, action) {
  let skus;
  // switch (action.type) {
  //   case ActionTypes.FETCH_PRODUCT_AVAILABILITY_FOR_CAROUSELS_SUCCESS: {
  if (action.type === ActionTypes.FETCH_PRODUCT_AVAILABILITY_FOR_CAROUSELS_SUCCESS) {
    let productDrawer;
    // carousels shape: an array of carousels
    const { personalizedCarousels: previousCarousels = [], productDrawer: previousProductDrawer } = state;
    const { styleName, product } = action.payload || {};

    const personalizedCarousels = previousCarousels.map(previousCarousel => {
      const { styleNames = [], products: previousProducts } = previousCarousel;

      if (!styleNames.includes(styleName)) {
        return previousCarousel;
      }

      const products = previousProducts.map(previousProduct => {
        if (previousProduct.id !== styleName) {
          return previousProduct;
        }

        if (!product) {
          return removeAvailability(previousProduct);
        }

        return replaceAvailability(previousProduct, product);
      });

      return { ...previousCarousel, products };
    });

    if (previousProductDrawer.productId === styleName) {
      if (product) {
        productDrawer = {
          ...previousProductDrawer,
          product: replaceAvailability(previousProductDrawer.product, product),
        };
      } else {
        productDrawer = {
          ...previousProductDrawer,
          product: removeAvailability(previousProductDrawer.product),
        };
      }
    } else {
      productDrawer = previousProductDrawer;
    }

    return { ...state, personalizedCarousels, productDrawer };

    // TODO: combine the conditional & switch statements from
    // skusWithSizeAvailability and subscriptionSkusWithSizeAvailability to
    // match the pattern of all other reducers.
  }

  if (state.product) {
    skus = skusWithSizeAvailability(state, action);
    const productWithSizeAvailabilities = _.extend({}, state.product, { skus: skus });
    return _.extend({}, state, { product: productWithSizeAvailabilities });
  } else {
    // If we don't have a product hash, go with this instead
    skus = subscriptionSkusWithSizeAvailability(state, action);
    return _.extend({}, state, { subscriptionSkus: skus });
  }
}

export default {
  types: types,
  fn: fn,
};
