import _ from "underscore";
import ActionTypes from "actions/action-types.js";

const types = [ActionTypes.SUBSCRIPTION_NOTIFICATION_SUCCESS];

const emptyNotification = {
  CTA: "",
  URL: "",
  action: "",
  deepLink: "",
  name: "",
  style: "",
  text: "",
};

const notificationModification = function (state, action) {
  let notification;
  switch (action.type) {
    case ActionTypes.SUBSCRIPTION_NOTIFICATION_SUCCESS:
      notification = action.payload.data?.attributes?.content ?? emptyNotification;
      break;
  }
  return notification;
};

const fn = function (state, action) {
  const stateKey = action.payload.stateKey;
  const notification = notificationModification(state[stateKey], action);
  const newState = _.extend({}, state);

  newState[stateKey] = notification;

  return newState;
};

export default {
  types: types,
  fn: fn,
};
