import React from "react";
import InputWrapper from "./input-wrapper";

class CheckboxInputPair extends React.Component {
  render() {
    var hiddenInput;

    if (this.props.uncheckedValue) {
      hiddenInput = <input key="hidden-input" name={this.props.name} type="hidden" value={this.props.uncheckedValue} />;
    }

    return (
      <InputWrapper {...this.props} inputBefore={true}>
        {hiddenInput}
        <input
          {...this.props.inputProps}
          defaultChecked={this.props.defaultChecked}
          checked={this.props.checked}
          id={this.props.id}
          key="input"
          name={this.props.name}
          onChange={this.props.onChange}
          type="checkbox"
          value={this.props.value}
        />
      </InputWrapper>
    );
  }
}

export default CheckboxInputPair;
