import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import smartComponentActions from "actions/smart-component-actions";
import TopRight from "components/source/navigation/top-right";
import { isAnonymous } from "../hoc/with-user-data";
import { smartTopRightPropType, userDataPropType } from "../../propTypes";

export class SmartTopRight extends Component {
  static propTypes = {
    isAnonymous: PropTypes.bool.isRequired,
    isMobileViewport: PropTypes.bool,
    fetchTopRightContent: PropTypes.func.isRequired,
    smartComponentState: PropTypes.shape({
      didFetchSmartTopRight: PropTypes.bool,
      smartTopRightCMS: smartTopRightPropType,
    }),
    userData: userDataPropType,
  };

  static defaultProps = {
    smartComponentState: {},
  };

  componentDidMount() {
    const { fetchTopRightContent, smartComponentState } = this.props;

    if (!smartComponentState?.didFetchSmartTopRight) {
      fetchTopRightContent();
    }
  }

  render() {
    const { isAnonymous, isMobileViewport, smartComponentState, userData } = this.props;

    //Don't display SmartTopRight for logged in users on mobile
    //We need the space it takes for the bag/heart icons
    if (!isAnonymous && isMobileViewport) {
      return null;
    }

    return <TopRight smartComponentState={smartComponentState} userData={userData} />;
  }
}

const mapStateToProps = state => ({
  smartComponentState: state.smartComponentState,
  isAnonymous: isAnonymous(state.userData),
  isMobileViewport: state.browser?.isMobileViewport,
  userData: state.userData,
});

const mapDispatchToProps = {
  fetchTopRightContent: smartComponentActions.getTopRightContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartTopRight);
