import _ from "underscore";
import ActionTypes from "actions/action-types";
var types, fn;

types = [
  ActionTypes.WRITE_HOLD_PREFERENCES_SUCCESS,
  ActionTypes.FETCH_HOLD_PREFERENCES_SUCCESS,
  ActionTypes.CLEAR_HOLD_PREFERENCES_SUCCESS,
  ActionTypes.HOLD_PREFERENCES_ERROR,
];

var setInitialState = function (state) {
  return _.extend({}, state, {
    persistedFilters: {
      duration: 4,
      date: null,
      canonicalSizes: [],
      zip_code: null,
    },
  });
};

fn = function (state, action) {
  var newState = state;

  if (_.isUndefined(newState.persistedFilters)) {
    newState = setInitialState(newState);
  }

  switch (action.type) {
    case ActionTypes.WRITE_HOLD_PREFERENCES_SUCCESS:
      var updatedFilters = _.extend({}, newState.persistedFilters, action.payload);

      return _.extend({}, newState, {
        persistedFilters: updatedFilters,
      });

    case ActionTypes.FETCH_HOLD_PREFERENCES_SUCCESS:
      return _.extend({}, newState, {
        persistedFilters: action.payload,
      });

    case ActionTypes.CLEAR_HOLD_PREFERENCES_SUCCESS:
      var remainingFilters = _.omit(newState.persistedFilters, action.payload);

      return _.extend({}, newState, {
        persistedFilters: remainingFilters,
      });

    case ActionTypes.HOLD_PREFERENCES_ERROR:
      return _.extend({}, newState, {
        persistedFilters: {
          errors: action.payload,
        },
      });

    default:
      return newState;
  }
};

export default {
  types: types,
  fn: fn,
};
