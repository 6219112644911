import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import productRatingsActions from "actions/product-ratings-actions";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "actions/auth-actions";
import HeartIcon from "./heart-icon";
import { analytics, COOKIES, loggingPageTypes } from "rtr-constants";
import ActionLogger from "action-logger";
import HeartEducationTooltip from "./heart-education-tooltip";
import ViewedProductsHelpers from "helpers/viewed-products-helpers";
import MembershipHelpers from "helpers/membership-helpers";
import { getUserId, withUserData, userDataPropType } from "components/source/hoc/with-user-data";
import { idPropType, membershipStatePropType } from "components/propTypes";
import HeapHelpers, { HEAP_AUTH_TRIGGER_TYPES, HEAP_HEART_EVENTS } from "helpers/heap-helpers";
import { setPostAuthenticationClientSideAction } from "actions/post-authentication-actions";
import Cookies from "universal-cookie";

export class HeartButtonComponent extends React.Component {
  static DEAD_END_SEARCH_RESULTS_OBJECT_TYPE = "dead_end_search_results";
  static DEAD_END_SEARCH_MODULE_TYPE = "dead_end_search_carousel";
  state = {
    isHeartEducationTooltipTriggered: false,
  };

  static propTypes = {
    customHeartLoggingOptions: PropTypes.object,
    deadEndCarousel: PropTypes.bool,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    heartEventPixelData: PropTypes.object,
    isMobileViewport: PropTypes.bool,
    isStylePresentOnShortlists: PropTypes.bool,
    location: PropTypes.oneOf([
      analytics.ACTION_LOCATIONS.CAROUSEL,
      analytics.ACTION_LOCATIONS.DRAWER,
      analytics.ACTION_LOCATIONS.GRID,
      analytics.ACTION_LOCATIONS.PDP,
    ]),
    openModal: PropTypes.func.isRequired,
    pageType: PropTypes.string,
    product: PropTypes.object.isRequired,
    productId: idPropType,
    productIndex: PropTypes.number.isRequired,
    ratingValue: PropTypes.number,
    showAuthModal: PropTypes.func.isRequired,
    triggerLinkToModal: PropTypes.func.isRequired,
    updateRatingValue: PropTypes.func,
    userData: userDataPropType,

    /* eslint-disable react/sort-prop-types */
    // from mapStateToProps
    favorites: PropTypes.arrayOf(PropTypes.string).isRequired,
    membershipState: membershipStatePropType,

    // from mapDispatchToProps
    favoriteProduct: PropTypes.func.isRequired,
    removeProductRating: PropTypes.func.isRequired,
    deadEndSearchData: PropTypes.object,
    deadEndAPIAndCarouselData: PropTypes.object,
  };

  // Need to handle various ways the id is passed
  // props.product.productId (grid powered by disco)
  // props.product.id        (grid)
  // props.productId         (PDP)
  productId = () => {
    return this.props.product?.id || this.props.product?.productId || this.props.productId;
  };

  isFavorited = () => {
    return this.props.favorites.includes(this.productId());
  };

  favoriteClick = () => {
    const {
      heartEventPixelData,
      ratingValue,
      isStylePresentOnShortlists,
      openModal,
      favorites,
      triggerLinkToModal,
      userData,
    } = this.props;
    const isFavorited = this.isFavorited();
    ActionLogger.logAction({
      action: analytics.HEARTS_ACTIONS.ENGAGE_HEART,
      direction: isFavorited ? "unheart" : "heart",
      ...heartEventPixelData,
    });

    const eventName = isFavorited ? HEAP_HEART_EVENTS.UNHEART : HEAP_HEART_EVENTS.HEART;
    HeapHelpers.fireHeapEvent(eventName, { style_name: this.productId() });

    if (ratingValue > 0) {
      // Un-hearting behavior
      if (!isStylePresentOnShortlists) {
        this.toggleFavorite(false);
      } else {
        openModal();
      }
    } else {
      // Hearting behavior
      const isFirstHeartAction = (favorites || []).length < 1;
      this.toggleFavorite(true);

      // NW [EXPLANATION] 6/12/19: In shortlist opt-in experience, show tooltip before Shortlist CTA
      if (isFirstHeartAction) {
        this.triggerHeartEducationTooltip();
      }

      triggerLinkToModal();

      // remove hearted item from saved "viewed products" list
      const { productId, product: { id } = {} } = this.props;
      const styleName = id || productId; // handle both grid and drawer cases
      ViewedProductsHelpers.removeViewedProductFromList(styleName, userData);
    }
  };

  // TODO: Refactor: this function should be passed down instead of prop drilling the required params,
  // there is no need for hearts to know about dead-end searches
  triggerDeadEndCarouselHeartPixels() {
    const { deadEndCarousel, deadEndSearchData, deadEndAPIAndCarouselData, productIndex, ratingValue } = this.props;
    if (deadEndCarousel) {
      const dead_end_module_type = this.constructor.DEAD_END_SEARCH_MODULE_TYPE;
      const deadEndPixelData = {
        module_type: dead_end_module_type,
        ...deadEndSearchData,
        ...deadEndAPIAndCarouselData,
        style_name: this.productId(),
        nth_style: productIndex,
      };
      ActionLogger.logAction(
        Object.assign(
          {
            object_type: this.constructor.DEAD_END_SEARCH_RESULTS_OBJECT_TYPE,
            action_type: ratingValue ? "click_unheart" : "click_heart",
          },
          deadEndPixelData
        )
      );
    }
  }

  // :shrug: some legacy thing
  getLoggingPageType(pageType) {
    return loggingPageTypes[pageType] || pageType;
  }

  toggleFavorite(favorited) {
    const { favoriteProduct, removeProductRating, customHeartLoggingOptions } = this.props;
    const productId = this.productId();
    const ratingValue = favorited ? 1 : 0;
    this.props.updateRatingValue(ratingValue);
    if (!this.props.deadEndCarousel) {
      const options = {
        object_type: this.getLoggingPageType(this.props.pageType),
        action: favorited ? analytics.ACTION_TYPE.CLICK_HEART : analytics.ACTION_TYPE.CLICK_UNHEART,
        styleName: productId,
        ...customHeartLoggingOptions,
      };

      ActionLogger.logAction(options);
    }

    if (favorited) {
      favoriteProduct(productId);
    } else {
      removeProductRating(productId);
    }
  }

  onClick = () => {
    const id = getUserId(this.props.userData);
    if (!id) {
      const cookies = new Cookies();
      const isCasV1Enabled = cookies.get(COOKIES.CAS_V1_ENABLED) === "true";
      if (isCasV1Enabled) {
        const isFavorited = this.props.ratingValue > 0;
        const heartClickActionName = isFavorited
          ? productRatingsActions.removeProductRating.name
          : productRatingsActions.favoriteProduct.name;

        setPostAuthenticationClientSideAction(heartClickActionName, 10, {
          styleId: this.productId(),
        });
      }
      this.props.showAuthModal({
        callback: this.favoriteClick,
        triggeredBy: HEAP_AUTH_TRIGGER_TYPES.CLICK_HEART,
      });
    } else {
      this.favoriteClick();
    }
    this.triggerDeadEndCarouselHeartPixels();
  };

  triggerHeartEducationTooltip() {
    this.setState({
      isHeartEducationTooltipTriggered: true,
    });
  }

  isHeartEducationTooltipTriggered() {
    // NW [EXPLANATION] 5/12/19: if shortlist modal is open, it's covering the Heart education tooltip - don't trigger tooltip until it's closed
    return !this.props.displayedModal && this.state.isHeartEducationTooltipTriggered;
  }

  render() {
    const heartButtonClassName = classNames("heart__button heart__button--minimal", {
      "heart__button--active": this.props.ratingValue > 0,
    });

    const label = this.props.ratingValue > 0 ? "Remove from Hearts" : "Add to Hearts";

    return (
      <button
        className={heartButtonClassName}
        data-style-name={this.productId()}
        onClick={this.onClick}
        ref={element => (this.$target = element)}>
        <HeartIcon />
        <label>{label}</label>
        <HeartEducationTooltip
          isMobileViewport={this.props.isMobileViewport}
          isTriggered={this.isHeartEducationTooltipTriggered()}
          location={this.props.location}
          membershipId={this.props.membershipState?.membershipId}
          productId={this.productId()}
          target={this.$target}
        />
      </button>
    );
  }
}

const mapStateToProps = state => {
  return {
    favorites: state.favorites || [],
    membershipState: MembershipHelpers.getMembershipState(state),
    pageType: state.pageType,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    favoriteProduct: styleName => {
      dispatch(productRatingsActions.favoriteProduct(styleName));
    },
    removeProductRating: styleName => {
      dispatch(productRatingsActions.removeProductRating(styleName));
    },
    showAuthModal: options => dispatch(AuthActions.showAuthModal(options)),
  };
};

export { HeartButtonComponent as UncomposedHeartButtonComponent };

export default compose(withUserData, connect(mapStateToProps, mapDispatchToProps))(HeartButtonComponent);
