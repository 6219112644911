import React from "react";
import PropTypes from "prop-types";

export default class LoadingSpinner extends React.PureComponent {
  static propTypes = {
    diameter: PropTypes.number,
    strokeWidth: PropTypes.number,
  };

  static defaultProps = {
    diameter: 65,
    strokeWidth: 6,
  };

  render() {
    const { diameter, strokeWidth } = this.props;
    return (
      <svg
        data-test-id="loading-spinner"
        className="loading-spinner"
        title="loading-spinner"
        width={diameter}
        height={diameter}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          className="loading-spinner__path"
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"></circle>
      </svg>
    );
  }
}
