import _ from "underscore";
import $ from "clients/RawClient";
import ActionTypes from "actions/action-types.js";
import { createAction } from "redux-actions";

function requestSizesAvailable(productId, params, onSuccessCallback) {
  return $.ajax({
    url: "/sizes_available/" + productId,
    type: "GET",
    data: params,
  }).then(data => {
    if (data && _.isFunction(onSuccessCallback)) {
      onSuccessCallback(data);
    }

    return data;
  });
}

const actions = {
  fetchSizeAvailabilitySuccess: createAction(ActionTypes.RESERVATION_FORM_FETCH_AVAILABILITY_SUCCESS),
  fetchSizeAvailabilityFailure: createAction(ActionTypes.RESERVATION_FORM_FETCH_AVAILABILITY_FAILURE),

  getSizeAvailability(productId, params, onSuccessCallback) {
    return function (dispatch) {
      return requestSizesAvailable(productId, params, onSuccessCallback).then(
        data => {
          if (_.isNull(data)) {
            dispatch(actions.fetchSizeAvailabilityFailure("Unable to fetch availabilities for this style"));
          } else {
            dispatch(actions.fetchSizeAvailabilitySuccess(data));
          }
        },
        () => {}
      );
    };
  },

  fetchProductAvailabilityForCarouselsSuccess: createAction(
    ActionTypes.FETCH_PRODUCT_AVAILABILITY_FOR_CAROUSELS_SUCCESS
  ),

  // WARNING: at the time this code was written, refetching availability only
  // takes place in Product Drawers, which are only used in Subscription mode.
  // this endpoint does not take into account Reserve Availability!
  updateProductAvailabilityForCarousels(styleName, params) {
    return dispatch => {
      requestSizesAvailable(styleName, params).then(product => {
        const payload = { styleName, product };
        dispatch(actions.fetchProductAvailabilityForCarouselsSuccess(payload));
      });
    };
  },

  // TODO: create a similar method for Product Carousels and Grids
};

export default actions;

export const {
  fetchSizeAvailabilitySuccess,
  fetchSizeAvailabilityFailure,
  getSizeAvailability,
  fetchProductAvailabilityForCarouselsSuccess,
  updateProductAvailabilityForCarousels,
} = actions;
