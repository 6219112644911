import ActionTypes from "../actions/action-types.js";

export const types = [ActionTypes.ADD_REMAINING_PRODUCTS, ActionTypes.REFRESH_GRID_PRODUCT];

const availabilityKeyRe = /Availabilities$/;
export function removeAvailability(product) {
  const { skus = [] } = product;

  const updatedSkus = skus.map(sku => {
    const availabilities = Object.keys(sku)
      .filter(k => k.match(availabilityKeyRe))
      .reduce((memo, availabilityKey) => {
        memo[availabilityKey] = sku[availabilityKey]?.map(availability => ({ ...availability, count: 0 })) || [];
        return memo;
      }, {});

    return { ...sku, ...availabilities };
  });

  return { ...product, skus: updatedSkus };
}

function generateAvailabilityMap(product) {
  const { skus = [] } = product || {};

  return skus.reduce((memo, sku) => {
    memo[sku.id] = Object.keys(sku)
      .filter(k => k.match(availabilityKeyRe))
      .reduce((memoAvailability, availabilityKey) => {
        memoAvailability[availabilityKey] = sku[availabilityKey].reduce((memoCount, { id, count }) => {
          memoCount[id] = count;

          return memoCount;
        }, {});

        return memoAvailability;
      }, {});

    return memo;
  }, {});
}

export function replaceAvailability(previousProduct, product) {
  if (!product) {
    return removeAvailability(previousProduct);
  }

  const { skus = [] } = previousProduct;
  const updatedAvailabilityMap = generateAvailabilityMap(product);

  const updatedSkus = skus.map(sku => {
    const availabilities = Object.keys(sku)
      .filter(k => k.match(availabilityKeyRe))
      .reduce((memo, availabilityKey) => {
        memo[availabilityKey] = sku[availabilityKey]?.map(availability => {
          const {
            [sku.id]: {
              [availabilityKey]: {
                [availability.id]: count, // important to keep count undefined if it is returned that way. undefined does not mean unavailable
              } = {},
            } = {},
          } = updatedAvailabilityMap;
          return { ...availability, count };
        });

        return memo;
      }, {});

    return { ...sku, ...availabilities };
  });

  return { ...previousProduct, skus: updatedSkus };
}

export function fn(state, action) {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.REFRESH_GRID_PRODUCT: {
      const { products: previousProducts = [] } = state;
      const {
        data: { products: updatedProducts = [] },
        styleName,
      } = payload;

      // there should only be one entry, but we'll #find just in case
      const updatedProduct = updatedProducts.find(p => p.id === styleName);

      // map previous products to a copy of itself with some updates
      const products = previousProducts.map(product => {
        // in most cases, this loop will short-circuit here
        if (product.id !== styleName) {
          return product;
        }

        // if the updated product is missing, it has no availability
        if (!updatedProduct) {
          return removeAvailability(product);
        }

        // lastly, the updated product has availability, so we return it as-is
        return updatedProduct;
      });

      return { ...state, products };
    }

    case ActionTypes.ADD_REMAINING_PRODUCTS: {
      const { products: stateProducts } = state;
      const { products: payloadProducts } = payload;
      const products = [...stateProducts, ...payloadProducts];

      return { ...state, products };
    }
  }

  return state;
}

export default {
  types: types,
  fn: fn,
};
