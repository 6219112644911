/* jshint ignore:start */
/*
  Removing JS Hint in this file as it doesn't like the
  👩‍👦 emoji. The emoji won't show up on some browsers, but
  I chatted with product and they were OK with this.
*/
const madlibsOptions = {
  optionTitles: {
    timeOfDay: "where",
    date: "when",
    companion: "who",
    style: "dress code",
    emotion: "vibes",
  },
  destinationDaytime: [
    {
      value: "home",
      label: "home for the holidays",
    },
    {
      value: "vacation",
      label: "on vacation",
    },
    {
      value: "brunch",
      label: "to brunch",
    },
  ],
  destinationNighttime: [
    {
      value: "night",
      label: "out for the night",
    },
    {
      value: "party",
      label: "to a NYE party",
    },
    {
      value: "dinner",
      label: "to dinner",
    },
    {
      value: "show",
      label: "to a holiday show",
    },
  ],
  companion: [
    {
      value: "significantOther",
      label: "my significant other",
    },
    {
      value: "work",
      label: "work crew",
    },
    {
      value: "oldFriends",
      label: "old friends & flames",
    },
    {
      value: "friends",
      label: "friends",
    },
    {
      value: "family",
      label: "the fam",
    },
    {
      value: "date",
      label: "a romantic prospect",
    },
    {
      value: "solo",
      label: "flying solo",
    },
  ],
  style: [
    {
      value: "jeans",
      label: "a notch above jeans",
    },
    {
      value: "veryCasual",
      label: "super casual",
    },
    {
      value: "semiFormal",
      label: "fancy-ish",
    },
    {
      value: "veryFormal",
      label: "multiple-forks fancy",
    },
    {
      value: "festive",
      label: "crazy festive",
    },
    {
      value: "cold",
      label: "freezing",
    },
    {
      value: "warm",
      label: "80 and sunny",
    },
  ],
  emotion: [
    {
      value: "unicorn",
      label: "one of a kind 🦄",
    },
    {
      value: "boss",
      label: "like a boss 💰",
    },
    {
      value: "chic",
      label: "classic and chic 💄",
    },
    {
      value: "instagram",
      label: "Instagram-ready 📸",
    },
    {
      value: "saucy",
      label: "like a saucy minx 👠",
    },
    {
      value: "lifeOfTheParty",
      label: "like the life of the party 💃",
    },
    {
      value: "familyFriendly",
      label: "family-friendly 👩‍👦",
    },
  ],
  outputGrids: {
    default: "/products/dress",
    casual: "/shop/effortlessly_put_together/products",
    formal: "/shop/dress_to_impress_apparel/products",
    veryFormal: "/shop/dressed_to_thrill/products",
    warmWeather: "/shop/warm_weather_bound/products",
    coldWeather: "/shop/cold_weather_cozy/products",
    daytimeFestive: "/pdp/shop/festive_hits/products?filters[formality][]=daytime",
    nighttimeFestive: "/pdp/shop/festive_hits/products?filters[formality][]=cocktail&filters[formality][]=night_out",
  },
};

export default madlibsOptions;

/* jshint ignore:end */

export const {
  optionTitles,
  destinationDaytime,
  destinationNighttime,
  companion,
  style,
  emotion,
  outputGrids,
} = madlibsOptions;
