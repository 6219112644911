import styles from "./banner.module.css";
import { useState } from "react";
import { isProduction } from "../../../helpers/environment-helpers";

const isProd = isProduction();

export default function NextBanner() {
  const [shouldDisplay, setShouldDisplay] = useState(true);

  // only show in non prod envs, where the user has not already dismissed the banner
  return !isProd && shouldDisplay ? (
    <span id="next-banner" className={styles["next-banner"]}>
      <button
        title="Click to dismiss"
        onClick={e => {
          e.preventDefault();
          setShouldDisplay(false);
        }}>
        Storefront.Next
      </button>
    </span>
  ) : (
    // Still provide an empty span that's not visible to users so that automation tools can reliably find this element
    <span id="next-banner" style={{ display: "none" }}></span>
  );
}
