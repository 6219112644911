import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { childrenPropType } from "components/propTypes";
import Header from "components/source/navigation/header";
import Footer from "./Footer";
import { AcquisitionMessagingProvider } from "./AcquisitionMessagingContext";
import { AuthProvider } from "./AuthContext";
import { CmsProvider } from "./CmsContext";
import { ContentfulProvider } from "./ContentfulContext";
import { FeatureFlagsProvider } from "./FeatureFlagsContext";
import { MembershipStateProvider } from "./MembershipStateContext";
import { NewCustomerOfferTextProvider } from "./NewCustomerOfferTextContext";
import { UserProductRatingsProvider } from "./UserProductRatingsContext";
import { UserProvider } from "./UserContext";
import { UserReviewSummaryProvider } from "./UserReviewSummaryContext";
import { URLProvider } from "./URLContext";
import dynamic from "next/dynamic";
import RtrImage from "../source/shared/rtr-image";
import { checkIfTouchDevice } from "helpers/device-helpers";
import { isActiveMembership } from "helpers/membership-helpers";
import { isCustomer as getIsCustomer } from "components/source/hoc/with-user-data";

const loading = () => <RtrImage src={"/images/loading.gif"} alt={"loading"} />;

const ConnectedBagContainer = dynamic(
  () => import("components/source/checkout/bag/bag-container").then(mod => mod.ConnectedBagContainer),
  { loading }
);
const ConnectedAuthModal = dynamic(() => import("components/source/shared/auth_form/modal-wrapper"), { loading });
const ConnectedCartAbandonmentModal = dynamic(() => import("components/source/acquisition/cart-abandonment-modal"), {
  loading,
});
const ConnectedOnLoadModal = dynamic(() => import("components/source/shared/on-load-modal"), { loading });

//  ReactDOMServer does not yet support Suspense. Once on React 18, lazy load!
// const SiteWideComponents = React.lazy(() => import("./SiteWideComponents"));
// const SubscriptionSiteWideComponents = React.lazy(() => import("./SubscriptionSiteWideComponents"));
const SiteWideComponents = dynamic(() => import("./SiteWideComponents"), {
  loading,
});
const SubscriptionSiteWideComponents = dynamic(() => import("./SubscriptionSiteWideComponents"), {
  loading,
});
const IsCustomerSiteWideComponents = dynamic(() => import("./IsCustomerSiteWideComponents"), {
  loading,
});

const propTypes = {
  children: childrenPropType,
  isCustomer: PropTypes.bool,
  isMember: PropTypes.bool,
};

// sort by priority
const Providers = [
  AuthProvider,
  UserProvider,
  MembershipStateProvider,
  UserProductRatingsProvider,
  UserReviewSummaryProvider, // used by header and PDPs
  CmsProvider, // lower priority because it provides data server-side and refresh client-side
  FeatureFlagsProvider, // lower priority because it provides data server-side and refresh client-side
  NewCustomerOfferTextProvider,
  AcquisitionMessagingProvider,
  URLProvider,
  ContentfulProvider,
  // BagProvider,
  // UserCreditsProvider,
  // MembershipProgramChangeInfo
];

function DefaultLayout({ children, isCustomer, isMember }) {
  // onMount
  useEffect(() => {
    checkIfTouchDevice();
  }, []);

  return (
    <Fragment>
      <Header />
      <div id="page-content">
        <div className="page-content-wrapper">{children}</div>
      </div>

      <Footer />

      {/* feels like this ought to be pushed up to the _app */}
      <SiteWideComponents />

      <ConnectedBagContainer />
      <ConnectedAuthModal />
      <ConnectedCartAbandonmentModal />
      <ConnectedOnLoadModal />
      {isCustomer && <IsCustomerSiteWideComponents />}
      {isMember && <SubscriptionSiteWideComponents />}
    </Fragment>
  );
}

const mapStateToProps = state => ({
  isCustomer: getIsCustomer(state.userData),
  isMember: isActiveMembership(state.membershipState),
});

DefaultLayout.propTypes = propTypes;
DefaultLayout.getProviders = () => Providers;

export default connect(mapStateToProps)(DefaultLayout);
