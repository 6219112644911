import React from "react";
import PropTypes from "prop-types";
import AnimatedDropdownWrapper from "components/source/shared/animated-dropdown-wrapper";
import { formLabels, usStateAbbreviations } from "rtr-constants";

const defaultLabel = formLabels.state;

class AnimatedStatesDropdown extends React.Component {
  static propTypes = {
    additionalClassName: PropTypes.string,
    autoComplete: PropTypes.string,
    autofill: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onBlurCallback: PropTypes.func,
    onChangeCallback: PropTypes.func,
    placeholderOption: PropTypes.string,
    required: PropTypes.bool,
    validationErrorMsg: PropTypes.string,
    value: PropTypes.string,
  };

  options() {
    return Object.keys(usStateAbbreviations).map(state => ({
      label: state,
      value: usStateAbbreviations[state],
    }));
  }

  handleChange = (value, isValid, name, inputObj) => {
    // FEI-1471
    // To enable browser autofill, we set the HTML name attribute of this input to "state."
    // However, Godmother's /shippingAddresses endpoint expects this field to be "zoneCode,"
    // as do our shipping address form reducers, so we set it back to this.props.name here.
    this.props.onChangeCallback(value, isValid, this.props.name, inputObj);
  };

  handleBlur = (value, isValid, name, inputObj) => {
    this.props.onBlurCallback(value, isValid, this.props.name, inputObj);
  };

  htmlNameAttribute() {
    // Just use the name prop unless we specifically want the input's HTML attribute to be "state" (for autofill)
    return this.props.autofill ? "state" : this.props.name;
  }

  render() {
    return (
      <AnimatedDropdownWrapper
        options={this.options()}
        id={this.props.id}
        name={this.htmlNameAttribute()}
        label={this.props.label || defaultLabel}
        placeholderOption={this.props.placeholderOption}
        additionalClassName={this.props.additionalClassName}
        validationErrorMsg={this.props.validationErrorMsg || ""}
        error={this.props.error}
        value={this.props.value}
        onBlurCallback={this.handleBlur}
        onChangeCallback={this.handleChange}
        required={this.props.required}
        autoComplete={this.props.autoComplete}
        disabled={this.props.disabled}
      />
    );
  }
}

export default AnimatedStatesDropdown;
