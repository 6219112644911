import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import AtomAnimatedTextInput from "components/source/atoms/atom-animated-text-input";
import { validationStates } from "rtr-constants";
import classNames from "classnames";

class AnimatedTextInputWrapper extends React.Component {
  static propTypes = {
    additionalClassName: PropTypes.string,
    alwaysShowMask: PropTypes.bool,
    autoComplete: PropTypes.string,
    beforeMaskedValueChange: PropTypes.func, // (newState: Object, oldState: Object, userInput: string, maskOptions: Object) => Object
    enableAutocomplete: PropTypes.bool,
    error: PropTypes.string,
    focus: PropTypes.bool,
    handleAutocompleteSelect: PropTypes.func, // (address: string) => void,
    helperText: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    maskedInput: PropTypes.bool,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    onBlurCallback: PropTypes.func, // (value: string, isValid: boolean, name: string, inputObj: Object) => void,
    onChangeCallback: PropTypes.func, // (value: string, isValid: boolean, name: string, inputObj: Object) => void,
    onClickCallback: PropTypes.func, // (event) => void,
    onErrorCallback: PropTypes.func, // (event) => void,
    onFocusCallback: PropTypes.func, // (event) => void,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    type: PropTypes.oneOf(["text", "email", "password", "tel", "date", "number"]).isRequired,
    validateInput: PropTypes.func, // (value: string) => boolean
    validationErrorMsg: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disabled: PropTypes.bool,
    setParentReference: PropTypes.func,
    alternateMasker: PropTypes.string,
  };

  static defaultProps = {
    type: "text",
    icon: null,
    setParentReference: null,
    additionalClassName: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      value: "",
      error: "",
      userClickedInput: false,
      isValid: this.baseValidationState(),
    };
  }

  baseValidationState() {
    return null;
  }

  componentDidMount() {
    // Handle value and error props from parent that are received immediately with first set of props
    if (this.props.value) {
      this.handleValueProp(this.props.value);
    }

    if (this.props.error) {
      this.handleErrorProp(this.props.error);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Handle value prop received from parent
    // This could simply be what the user typed (coming back down from redux in a controlled form)
    // Or it could be something not entered by user, like a persisted filter in the PDP.
    // Ignore empty string values (e.g., from Redux reducer clearing a form).
    if (this.props.value !== nextProps.value && nextProps.value !== "") {
      this.handleValueProp(nextProps.value);
    }

    // Overwrite any validation error with error received from parent
    // If the error is null or empty string, we also want to update that here
    // because it means that an existing error has been fixed.
    // However, we do not want to reset the error if the error is undefined.
    // This happens when there is no error key in nextProps. It can also happen when a parent
    // component does not receive an error prop and then passes `this.props.error` to this component.
    // This results in this component receiving a props object with { error: undefined }
    // We are accepting that calling code may intentionally send a null error but we
    // change it to an empty string to keep the types consistent and developers sane.
    if (!_.isUndefined(nextProps.error)) {
      this.handleErrorProp(nextProps.error || "");
    }
  }

  handleChange(e) {
    const value = e?.target?.value ?? e;
    this.setUserValue(value);
    this.validateChange(value);
  }

  handleBlur(e) {
    const value = e?.target?.value ?? e;
    this.validateBlur(value);
  }

  handleClick(e) {
    // Set the state so we know if the user has focused on the input
    this.setState({
      userClickedInput: true,
    });

    if (this.props.onClickCallback) {
      this.props.onClickCallback(e);
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleFocus(e) {
    if (this.props.onFocusCallback) {
      this.props.onFocusCallback(e);
    }
    this.setState({
      userClickedInput: true,
    });
  }

  validate(value) {
    const isValid = this.props.validateInput ? this.props.validateInput(value) : true;
    const isOptional = !this.props.required;

    if (value) {
      if (isValid) {
        return validationStates.VALID;
      } else {
        return validationStates.INVALID;
      }
    } else {
      // no value was entered
      if (isOptional) {
        // If no value was entered into an optional input, it should report itself as valid / submittable
        return validationStates.VALID;
      } else {
        return validationStates.INVALID;
      }
    }
  }

  setUserValue(value) {
    this.setState({
      value: value,
    });
  }

  inputObject() {
    // additional data on the input object sent to callbacks
    // use _.extend for state to get additional state properties
    // state is source of truth for value, error, & isValid.

    return _.chain({})
      .extend(this.props, this.state)
      .omit(val => _.isFunction(val))
      .value();
  }

  validateChange(value) {
    // Updates component state after the value of an input has changed.
    // We do not check for the NOT_DETERMINED validation state here because
    // that can only happen in the base state or when the input is empty.
    // It is not possible for that to happen within an onChange function:
    // If the input changed, we're not in the base state and the input can't be empty.
    const isValid = this.validate(value) === validationStates.VALID;
    if (isValid && !this.state.isValid) {
      // don't bother doing this if input is already valid
      this.setState({
        error: "",
        isValid: true,
      });
    }

    if (this.props.onChangeCallback) {
      const { onChangeCallback } = this.props; // Why this was done: https://github.com/facebook/flow/issues/1938
      // a function that does more things on change, e.g., ajax call for availability, logging
      onChangeCallback(value, isValid, this.props.name, this.inputObject());
    }
  }

  validateBlur(value) {
    // Updates component state after the user has exited an input.
    // Sets isValid to false if input is invalid, or null if validation state is
    // not determined -- this happens if input is not required and user didn't enter anything.
    let isValid, error;
    const validationState = this.validate(value);
    switch (validationState) {
      case validationStates.VALID:
        error = "";
        isValid = true;
        break;
      case validationStates.INVALID:
        error = this.props.validationErrorMsg;
        isValid = false;
        break;
      case validationStates.NOT_DETERMINED:
        error = "";
        isValid = this.baseValidationState();
        break;
    }

    this.setState({
      error,
      isValid,
    });

    if (this.props.onBlurCallback) {
      const { onBlurCallback } = this.props; // Why this was done: https://github.com/facebook/flow/issues/1938
      // a function that does more things on blur, e.g., ajax call for availability, logging
      onBlurCallback(value, isValid, this.props.name, this.inputObject());
    }

    if (typeof this.props.onErrorCallback === "function") {
      this.props.onErrorCallback(error);
    }
  }

  handleValueProp(value) {
    // Populate the input with the value and do a blur validation
    this.setState({
      value: value || "",
    });

    if (!this.state.userClickedInput) {
      // In this case, we are receiving a value prop before the user has entered the input or typed anything.
      // This is because the prop is coming from a persisted value (e.g., a persisted filter in the PDP).
      // When this happens, we need to validate the value we've received so that we can pass down `isValid` and
      // `error` props to the input atom, which will cause the label to slide up and not obscure the value in the input.
      this.validateNonUserEntryValue(value);
    }
  }

  validateNonUserEntryValue(value) {
    // Handle a value prop that comes from a parent component but is not user input
    // coming back down from the state as part of a controlled form.
    // If it is user input, is was already validated "on the way up" to the form / application state.
    // If it is not user input, it needs to be validated so that the user can know about any problems
    // with the value and so that the input element goes into a valid/error state and the label slides up.
    this.validateBlur(value);
  }

  handleErrorProp(error) {
    this.setState({
      error: error,
    });
  }

  getAdditionalClassName() {
    // if an icon is passed we provide the needed className
    const additionalClassName = classNames([this.props.additionalClassName], {
      "icon-text-field": this.props.icon,
    });
    return additionalClassName;
  }

  render() {
    return (
      <AtomAnimatedTextInput
        id={this.props.id}
        name={this.props.name}
        additionalClassName={this.getAdditionalClassName()}
        type={this.props.type || "text"}
        label={this.props.label}
        error={this.state.error}
        isValid={this.state.isValid}
        maxLength={this.props.maxLength}
        onChange={e => this.handleChange(e)}
        onBlur={e => this.handleBlur(e)}
        onClick={e => this.handleClick(e)}
        onFocus={e => this.handleFocus(e)}
        value={this.state.value}
        readOnly={this.props.readOnly}
        mask={this.props.mask}
        maskChar={this.props.maskChar}
        helperText={this.props.helperText}
        maskedInput={this.props.maskedInput}
        alwaysShowMask={this.props.alwaysShowMask}
        tabIndex={this.props.tabIndex}
        enableAutocomplete={this.props.enableAutocomplete}
        handleAutocompleteSelect={this.props.handleAutocompleteSelect}
        focus={this.props.focus}
        labelCoversInput={!this.state.userClickedInput}
        autoComplete={this.props.autoComplete}
        beforeMaskedValueChange={this.props.beforeMaskedValueChange}
        icon={this.props.icon}
        setParentReference={this.props.setParentReference}
        disabled={this.props.disabled}
        alternateMasker={this.props.alternateMasker}
      />
    );
  }
}

export default AnimatedTextInputWrapper;
