// Tatari is a third party vendor used for TV spot analytics. It's behind the
// feature flag `tatari`. The global `tatari` variable is loaded from their SDK.

import _ from "underscore";
// by using universal-cookie, you can expect that this code will only work
// client-side. to make it work server-side, have your component pass the
// cookies object provided to it by the `withCookies` HOC.
import Cookies from "universal-cookie";
import { LocalStorage } from "../site/localStorage";

export const ACTIONS = {
  REGISTER: "register",
  ADD_TO_CART: "add_to_cart",
  CHECKOUT: "checkout",
};

const ORDER_TYPES = {
  CLASSIC: "classic",
  UNLIMITED_SUBSCRIPTION: "unlimited_subscription",
  UPDATE_WAITLIST: "update_waitlist",
};

export const CACHE = {
  NAMESPACE: "tatari",
  KEY: "logs",
};

const INVOKED_FUNCTIONS = {
  TRACK_ADD_TO_CART: "trackAddToCart",
  TRACK_CHECKOUT: "trackCheckout",
};

// export for testing only
export function getTimestamp() {
  // Unix timestamp in seconds.
  return Math.round(new Date().getTime() / 1000);
}

// export for testing only
// userId can come from a variety of places, so let's allow it to be passed in.
export function getDetails(userId, extraDetails = {}) {
  const cookies = new Cookies();
  const timestamp = getTimestamp();
  const browserId = cookies.get("RTR_ID");
  let details = {
    timestamp,
    browser_id: browserId,
  };

  if (userId) {
    details = _.extend(details, {
      user_id: userId,
    });
  }

  details = _.extend(details, extraDetails);

  return JSON.stringify(details);
}

function track(action, details) {
  try {
    // eslint-disable-next-line no-inner-declarations
    function callback() {
      window.tatari.track(action, details);

      window.removeEventListener("tatariLoadEvent", callback);
    }

    if (window.tatari) {
      callback();
    } else {
      window.addEventListener("tatariLoadEvent", callback);
    }
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
}

function addToInferTrackingQueue(type, details) {
  // This is similar to `GlobalInferLog`, but it's unique for Tatari
  // tracking pixels. For `GlobalInferLog`, only the `Log` model is
  // synced which causes a `p.php` pixel for fire. For this function, we
  // actually invoke a function from the Tatari SDK on the next page.
  if (!type || !details) {
    return;
  }
  const localStorageClient = new LocalStorage(CACHE.NAMESPACE);

  // We need to keep the `type` so we know whether to invoke a `trackAddToCart`
  // or `trackCheckout` function.
  const newLog = _.extend(details, { type });

  const trackingQueue = localStorageClient.get(CACHE.KEY) || [];
  trackingQueue.push(newLog);
  localStorageClient.set(CACHE.KEY, trackingQueue);
}

function invokeTrackingFunction(log, userId) {
  if (_.isEmpty(log)) {
    return;
  }
  // Depending on the `type` of the log, this will invoke
  // `trackAddToCart`, `trackCheckout`, etc.
  const availableFunctions = {
    trackAddToCart: INVOKED_FUNCTIONS.TRACK_ADD_TO_CART,
    trackCheckout: INVOKED_FUNCTIONS.TRACK_CHECKOUT,
  };
  const trackingType = log.type;
  const trackingFunction = availableFunctions[trackingType];
  let logParams = _.omit(log, "type");

  // If the original log does not have a userId, but we have one now, let's use
  // the userId.
  if (!logParams.userId && userId) {
    logParams = _.extend(log, { userId });
  }

  if (trackingFunction) {
    TatariHelper[trackingFunction](logParams);
  }
}

// this is the actual exported helper
const TatariHelper = {
  isTatariEnabled: null,
  ORDER_TYPES,

  setIsTatariEnabled(isTatariEnabled) {
    this.isTatariEnabled = isTatariEnabled;
  },

  identifyUser(userId) {
    if (!this.isTatariEnabled) return;

    // Used to allow the third-party to tie our users with their cookie so we can
    // backfill data for anonymous users.
    try {
      // eslint-disable-next-line no-inner-declarations
      function callback() {
        window.tatari.identify(userId);

        window.removeEventListener("tatariLoadEvent", callback);
      }

      if (window.tatari) {
        callback();
      } else {
        window.addEventListener("tatariLoadEvent", callback);
      }
    } catch (e) {
      window?.Sentry?.captureException(e);
    }
  },

  trackRegistration(userId) {
    if (!this.isTatariEnabled) return;

    // Used for tracking when a user registers.
    const details = getDetails(userId);
    track(ACTIONS.REGISTER, details);
  },

  trackAddToCart({ userId, orderType }) {
    if (!this.isTatariEnabled) return;

    // Used for tracking on Add to Cart, whether it's Unlimited, Classic, etc.
    const details = getDetails(userId, {
      order_type: orderType,
    });
    track(ACTIONS.ADD_TO_CART, details);
  },

  trackCheckout({ userId, orderType, subTotal }) {
    if (!this.isTatariEnabled) return;

    // Used for tracking on checkout.
    const details = getDetails(userId, {
      order_type: orderType,
      sub_total: subTotal,
    });
    track(ACTIONS.CHECKOUT, details);
  },

  inferAddToCart(params) {
    if (!this.isTatariEnabled) return;

    // Used for tracking Add to Cart behavior when the user navigates to a new
    // page. Just like `ActionLogger.inferAction`, it writes to local storage.
    addToInferTrackingQueue("trackAddToCart", params);
  },

  inferCheckout(params) {
    if (!this.isTatariEnabled) return;

    // Used for tracking Checkout behavior when the user navigates to a new
    // page. Just like `ActionLogger.inferAction`, it writes to local storage.
    addToInferTrackingQueue("trackCheckout", params);
  },

  syncInferTrackingQueue(userId) {
    if (!this.isTatariEnabled) return;

    // This function should only be called once per page load. It will sync any
    // Tatari related pixels stored in local storage. These pixels were added
    // using `addToInferTrackingQueue`.
    const localStorageClient = new LocalStorage(CACHE.NAMESPACE);

    try {
      const trackingQueue = localStorageClient.get(CACHE.KEY);
      if (!_.isEmpty(trackingQueue)) {
        _.each(trackingQueue, function (log, index) {
          // Grab the rest of the queue, and set it in local storage incase an
          // error is raised when calling `invokeTrackingFunction`. This way, we can
          // log the remaining pixels on the next page load.
          const queueRemainder = _.rest(trackingQueue, index + 1);
          localStorageClient.set(CACHE.KEY, queueRemainder);
          invokeTrackingFunction(log, userId);
        });
      }
    } catch (e) {
      window?.Sentry?.captureException(e);
    }
  },
};

// The only public methods are the ones defined in the TatariHelper. Users of this helper
// should not be allowed to call the other private methods directly.
export default TatariHelper;
