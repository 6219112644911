import { createAction } from "redux-actions";
import ActionTypes from "actions/action-types";
import { LocalStorage } from "../site/localStorage";

const HEART_EDUCATION_TOOLTIP_LOCAL_STORAGE_KEY = "heartEducationTooltip";
const IS_TOOLTIP_DISMISSED = "isTooltipDismissed";
const actions = {
  heartEducationTooltipDismissed: createAction(ActionTypes.HEART_EDUCATION_TOOLTIP_DISMISSED),

  /**
   * @deprecated do not reuse this pattern for other one-time experiences - use OneTimeExperienceActions.getIsOneTimeExperienceDismissed
   */
  getIsHeartEducationTooltipDismissed: () => {
    return function (dispatch) {
      const localStorageClient = new LocalStorage(HEART_EDUCATION_TOOLTIP_LOCAL_STORAGE_KEY);
      const isTooltipDismissed = localStorageClient.get(IS_TOOLTIP_DISMISSED);

      // NW [EXPLANATION] 5/20/19: set the status in Redux
      if (isTooltipDismissed) {
        dispatch(actions.heartEducationTooltipDismissed(true));
      } else {
        dispatch(actions.heartEducationTooltipDismissed(false));
      }
    };
  },

  /**
   * @deprecated do not reuse this pattern for other one-time experiences - use OneTimeExperienceActions.dismissOneTimeExperience
   */
  setIsHeartEducationTooltipDismissed: () => {
    return function (dispatch) {
      const localStorageClient = new LocalStorage(HEART_EDUCATION_TOOLTIP_LOCAL_STORAGE_KEY);
      localStorageClient.set(IS_TOOLTIP_DISMISSED, true);
      dispatch(actions.heartEducationTooltipDismissed(true));
    };
  },
};

export default actions;

export const {
  heartEducationTooltipDismissed,
  getIsHeartEducationTooltipDismissed,
  setIsHeartEducationTooltipDismissed,
} = actions;
