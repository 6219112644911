import ActionTypes from "../actions/action-types.js";

export const types = [
  ActionTypes.FETCH_CAROUSEL,
  ActionTypes.FETCH_CAROUSEL_SUCCESS,
  ActionTypes.FETCH_CAROUSEL_FAILURE,
];

const fn = function (state, action) {
  let carousel;

  switch (action.type) {
    case ActionTypes.FETCH_CAROUSEL: // falls-through
    case ActionTypes.FETCH_CAROUSEL_FAILURE:
      carousel = null;
      break;

    case ActionTypes.FETCH_CAROUSEL_SUCCESS:
      carousel = action.payload.carousel;
      break;

    default:
      return state;
  }

  return { ...state, carousel };
};

export default { types, fn };
