// This Provider is intended to follow the Context/Provider pattern, while also
// using the Redux store, already in use. Typically, Contexts contain their own
// state, but in order to avoid large refactors, we support both patterns.

import React, { createContext, useContext, useDebugValue, useEffect, useCallback } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { childrenPropType } from "../propTypes";
import { getDisplayName } from "../source/hoc/utils";
import { fetchUserFromGodmother } from "actions/next-user-actions";
import { updateUserData } from "../../actions/on-initialization-actions";
import { fireUserEvents, isIdentified, isMasquerading, useUserData } from "../source/hoc/with-user-data";
import { useAuth } from "components/layout/AuthContext";

const UserContext = createContext({ user: {}, hasLoaded: false });
// Sadly, userProfile in our Redux State is an instance of User, not UserProfile
const stateKey = "userProfile";

// plucks userProfile from the Redux Context
function userProfileSelector(state) {
  const {
    userData: { [stateKey]: userProfile, [`${stateKey}HasLoaded`]: hasLoaded, [`${stateKey}Error`]: error } = {},
  } = state;

  return { userProfile, error, hasLoaded };
}

/////////////////////////////////
//                             //
//  Hook                       //
//  for functional Components  //
//                             //
/////////////////////////////////
// this is technically a hook but it's not reading redux state, it's reading react context.
// This can cause an issue if the two data sources get misaligned. For example, via shallow routing
// https://nextjs.org/docs/13/pages/building-your-application/routing/linking-and-navigating#shallow-routing
// Suggest using useUserData from "components/source/hoc/with-user-data" instead
export function useUser() {
  return useContext(UserContext);
}

/////////////////////////////////
//                             //
//  HOC                        //
//  for class Components       //
//                             //
/////////////////////////////////

export function withUser() {
  return WrappedComponent => {
    const C = connect(userProfileSelector)(WrappedComponent);

    C.displayName = `withUser(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

/**
 * @param {object} props
 * @param {function} props.children a ***function*** that takes userProfile as a
 * parameter. used to render your experimental content
 * @param {function} props.onInitialRender a function called only once, when
 * data fetching is complete
 * @param {function} props.fallback a function which returns the content you
 * wish to display while fetching
 * @description Wrap your experimental content in this component to render once
 * user has been loaded. `children` should be a function
 */
export function UserSuspense({ fallback, onInitialRender, children: fnRenderChildren }) {
  let content;
  const { user, hasLoaded, error } = useUser();

  // call the onInitialRender method only once, when hasLoaded is true
  // useCallback to guard against a case where hasLoaded toggles more than once
  const handleInitialRender = useCallback(() => hasLoaded && onInitialRender?.(user), [hasLoaded]);

  // call the memoized function each time hasLoaded changes (useCallback ensures it
  // is only called once per hasLoaded value)
  useEffect(() => {
    handleInitialRender();
  }, [hasLoaded]);

  if (hasLoaded) {
    // happy path
    content = fnRenderChildren?.(user, error);
  } else if (fallback) {
    // fallback
    content = fallback();
  }

  return content ?? null;
}

// -> if this data weren't nested under userData in the Redux Store, do this!:
// async function getInitialReduxState() {
//   return Promise.resolve({
//     [stateKey]: void 0,
//     [`${stateKey}HasLoaded`]: false,
//     [`${stateKey}Error`]: null,
//   });
// }

// Allows setting explicit context values
export const BaseProvider = UserContext.Provider;

export function UserProvider({ children }) {
  const dispatch = useDispatch();
  const { userProfile: user = {}, error, hasLoaded = false } = useSelector(userProfileSelector, shallowEqual);
  const userData = useUserData();
  const { hasLoaded: authDataHasLoaded } = useAuth();

  useEffect(() => {
    if (!authDataHasLoaded) return;

    if (isIdentified(userData) || isMasquerading(userData)) {
      dispatch(fetchUserFromGodmother());
    } else {
      dispatch(updateUserData({ userProfileHasLoaded: true, userProfileError: null }));
      fireUserEvents();
    }
  }, [authDataHasLoaded, isIdentified(userData), isMasquerading(userData)]);

  useDebugValue(hasLoaded ? "Loaded" : "Pending");

  // setUserId allows your code to trigger a new fetch for User
  return <UserContext.Provider value={{ user, error, hasLoaded }}>{children}</UserContext.Provider>;
}

// UserProvider.getInitialReduxState = getInitialReduxState;

UserProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...UserContext,
  Provider: UserProvider,
};
