import React from "react";
import { isFuture, format, isToday, differenceInDays, set } from "date-fns";
import PropTypes from "prop-types";
import { analytics, dateFnsFormats } from "rtr-constants";
import { HELP, User } from "routes";
import ActionLogger from "action-logger";
import { addCacheBusterToUrl } from "../../../helpers/location-helpers";

const ORDER_STATUSES = {
  ORDER_PLACED: "ORDER_PLACED",
  PROCESSING: "PROCESSING",
  SHIPPED: "SHIPPED",
};

export default class UserMenuActiveOrderTooltip extends React.Component {
  static propTypes = {
    sseActiveOrders: PropTypes.arrayOf(
      PropTypes.shape({
        groupItems: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
          })
        ),
        rentStart: PropTypes.number, // Unix timestamp
        status: PropTypes.oneOf(Object.keys(ORDER_STATUSES)),
      })
    ),
  };

  static ORDER_ITEM_TYPES = {
    DRESS: "Dress",
  };

  static TOOLTIP_SUBTYPES = {
    PREPARING_ORDER: "preparing_order",
    QUESTIONS: "questions_order",
    UPCOMING_ORDER: "upcoming_order",
    UPSELL_ORDER: "post_add_order",
    SHIPPED_ORDER: "shipped_order",
  };

  static getRentStartFromUnixTimeStamp(rentStartUnix) {
    const date = new Date(rentStartUnix * 1000);
    return set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  }

  // NW [EXPLANATION] 7/27/21: this function is static so that UserMenuTooltips can access it to fire a pixel when this tooltip is triggered
  static getActiveOrderTooltipSubType(activeOrders) {
    if (!activeOrders || !activeOrders.length) {
      return null;
    }

    const [activeOrderGroup] = activeOrders;
    if (!activeOrderGroup) {
      return null;
    }

    const rentStartDate = UserMenuActiveOrderTooltip.getRentStartFromUnixTimeStamp(activeOrderGroup.rentStart);

    if (
      activeOrderGroup.status?.toUpperCase() === ORDER_STATUSES.ORDER_PLACED &&
      UserMenuActiveOrderTooltip.isRentStartFiveDaysAway(activeOrderGroup) &&
      UserMenuActiveOrderTooltip.isEligibleForUpsell(activeOrderGroup)
    ) {
      return UserMenuActiveOrderTooltip.TOOLTIP_SUBTYPES.UPSELL_ORDER;
    } else if (
      activeOrderGroup.status?.toUpperCase() === ORDER_STATUSES.ORDER_PLACED &&
      UserMenuActiveOrderTooltip.isRentStartFiveDaysAway(activeOrderGroup)
    ) {
      return UserMenuActiveOrderTooltip.TOOLTIP_SUBTYPES.UPCOMING_ORDER;
    } else if (
      activeOrderGroup.status?.toUpperCase() === ORDER_STATUSES.PROCESSING &&
      UserMenuActiveOrderTooltip.isRentStartWithinFiveDays(activeOrderGroup)
    ) {
      return UserMenuActiveOrderTooltip.TOOLTIP_SUBTYPES.PREPARING_ORDER;
    } else if (
      activeOrderGroup.status?.toUpperCase() === ORDER_STATUSES.SHIPPED &&
      (isToday(rentStartDate) || isFuture(rentStartDate))
    ) {
      return UserMenuActiveOrderTooltip.TOOLTIP_SUBTYPES.SHIPPED_ORDER;
    } else {
      return UserMenuActiveOrderTooltip.TOOLTIP_SUBTYPES.QUESTIONS;
    }
  }

  static isEligibleForUpsell(activeOrderGroup) {
    return (activeOrderGroup.groupItems || []).some(item => {
      return item?.type?.toUpperCase() === UserMenuActiveOrderTooltip.ORDER_ITEM_TYPES.DRESS.toUpperCase();
    });
  }

  static isRentStartFiveDaysAway(activeOrderGroup) {
    const rentStart = UserMenuActiveOrderTooltip.getRentStartFromUnixTimeStamp(activeOrderGroup.rentStart);
    return differenceInDays(rentStart, new Date()) >= 5;
  }

  static isRentStartWithinFiveDays(activeOrderGroup) {
    const rentStart = UserMenuActiveOrderTooltip.getRentStartFromUnixTimeStamp(activeOrderGroup.rentStart);
    return differenceInDays(rentStart, new Date()) < 5;
  }

  renderUpsellTooltip(activeOrderGroup) {
    const rentStart = this.constructor.getRentStartFromUnixTimeStamp(activeOrderGroup.rentStart);
    const date = format(rentStart, dateFnsFormats.ddd_MMM_D);

    return (
      <>
        <div className="dek-one">You know what goes perfectly with your dress?</div>
        <p className="title">We do. Add an accessory to your order arriving on {date}.</p>
        <p>
          <a
            href={User.UpcomingOrders}
            className="btn"
            onClick={() => this.logCtaClick(this.constructor.TOOLTIP_SUBTYPES.UPSELL_ORDER)}>
            See Suggestions
          </a>
        </p>
      </>
    );
  }

  renderUpcomingOrderTooltip(activeOrderGroup) {
    const rentStart = this.constructor.getRentStartFromUnixTimeStamp(activeOrderGroup.rentStart);
    const date = format(rentStart, dateFnsFormats.MM_DD);

    return (
      <>
        <div className="dek-one">Your upcoming order for {date}</div>
        <p>
          <a
            href={User.UpcomingOrders}
            className="tooltip-link standalone"
            onClick={() => this.logCtaClick(this.constructor.TOOLTIP_SUBTYPES.UPCOMING_ORDER)}>
            Manage and view details
          </a>
        </p>
      </>
    );
  }

  renderPreparingOrderTooltip(activeOrderGroup) {
    const rentStart = this.constructor.getRentStartFromUnixTimeStamp(activeOrderGroup.rentStart);
    const isForToday = isToday(rentStart);
    const day = format(rentStart, dateFnsFormats.EEEE);

    return (
      <>
        <div className="dek-one">
          We&#39;re preparing your order <br />
          {isForToday ? "for Today" : `for ${day}`}
        </div>
        <p>
          <a
            href={User.OrderHistory}
            className="tooltip-link standalone"
            onClick={() => this.logCtaClick(this.constructor.TOOLTIP_SUBTYPES.PREPARING_ORDER)}>
            View Details
          </a>
        </p>
      </>
    );
  }

  renderOrderQuestionsTooltip() {
    return (
      <>
        <div className="dek-one">Questions about your order?</div>
        <p>
          <a
            href={`${addCacheBusterToUrl(HELP.CONTACT)}`}
            className="tooltip-link standalone"
            onClick={() => this.logCtaClick(this.constructor.TOOLTIP_SUBTYPES.QUESTIONS)}>
            Visit our Help Center
          </a>
        </p>
      </>
    );
  }

  renderShippedOrderTooltip(activeOrderGroup) {
    const rentStart = this.constructor.getRentStartFromUnixTimeStamp(activeOrderGroup.rentStart);
    const day = format(rentStart, dateFnsFormats.EEEE);

    return (
      <>
        <div className="dek-one">Your order has shipped</div>
        <p>It&#39;ll arrive by 8PM on {day}.</p>
        <p>
          <a
            href={User.OrderHistory}
            className="tooltip-link standalone"
            onClick={() => this.logCtaClick(this.constructor.TOOLTIP_SUBTYPES.SHIPPED_ORDER)}>
            Track your shipment
          </a>
        </p>
      </>
    );
  }

  logCtaClick = tooltipType => {
    ActionLogger.inferAction({
      object_type: analytics.OBJECT_TYPE.TOP_NAV,
      action: "notify_click",
      sub_type1: tooltipType,
    });
  };

  render() {
    const tooltipSubtype = this.constructor.getActiveOrderTooltipSubType(this.props.sseActiveOrders);

    if (!tooltipSubtype) {
      return null;
    }
    const activeOrderGroup = this.props.sseActiveOrders[0];

    switch (tooltipSubtype) {
      case this.constructor.TOOLTIP_SUBTYPES.UPSELL_ORDER:
        return this.renderUpsellTooltip(activeOrderGroup);
      case this.constructor.TOOLTIP_SUBTYPES.UPCOMING_ORDER:
        return this.renderUpcomingOrderTooltip(activeOrderGroup);
      case this.constructor.TOOLTIP_SUBTYPES.PREPARING_ORDER:
        return this.renderPreparingOrderTooltip(activeOrderGroup);
      case this.constructor.TOOLTIP_SUBTYPES.SHIPPED_ORDER:
        return this.renderShippedOrderTooltip(activeOrderGroup);
      case this.constructor.TOOLTIP_SUBTYPES.QUESTIONS:
        return this.renderOrderQuestionsTooltip();
      default:
        return null;
    }
  }
}
