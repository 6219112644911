export const selectFeatureFlags = state => state?.enabledFlags?.flags ?? [];
export const selectLDFeatureFlags = state => state?.flagsAndExperiments ?? {};

export const selectFeatureFlagEnabled = featureFlag => state =>
  selectFeatureFlagEnabledFromEnabledFlags(featureFlag)(state?.enabledFlags);

export const selectLDFeatureFlagEnabled = featureFlag => state => selectLDFeatureFlags(state)[featureFlag] ?? false;

export const selectFeatureFlagEnabledFromEnabledFlags = featureFlag => enabledFlags =>
  enabledFlags?.flags?.includes(featureFlag) ?? false;
