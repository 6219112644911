import ActionTypes from "../actions/action-types.js";

export const types = [
  ActionTypes.FETCH_PERSONALIZED_CAROUSELS,
  ActionTypes.FETCH_PERSONALIZED_CAROUSELS_SUCCESS,
  ActionTypes.FETCH_PERSONALIZED_CAROUSELS_FAILURE,
];

export function fn(state, action) {
  let personalizedCarousels;

  switch (action.type) {
    case ActionTypes.FETCH_PERSONALIZED_CAROUSELS: // falls-through
    case ActionTypes.FETCH_PERSONALIZED_CAROUSELS_FAILURE:
      personalizedCarousels = null;
      break;

    case ActionTypes.FETCH_PERSONALIZED_CAROUSELS_SUCCESS:
      personalizedCarousels = action.payload.carousels;
      break;

    default:
      return state;
  }

  return { ...state, personalizedCarousels };
}

export default {
  types: types,
  fn: fn,
};
