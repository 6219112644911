import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import AtomText from "components/source/atoms/atom-text";
import { atomTextAttributesPropType, atomTextLinksPropType } from "components/propTypes";
import { getTextWithLinks } from "helpers/cms-attributes-helper";

class AtomDek extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    textLinks: atomTextLinksPropType,
    dekAttributes: atomTextAttributesPropType,
    customClass: PropTypes.string,
    mobileText: PropTypes.string,
    mobileTextLinks: atomTextLinksPropType,
    mobileDekAttributes: atomTextAttributesPropType,
  };

  render() {
    const {
      customClass,
      dekAttributes,
      mobileDekAttributes,
      mobileText,
      mobileTextLinks,
      text,
      textLinks,
    } = this.props;
    const classNames = classnames("atom-dek", { [customClass]: customClass });

    return (
      <AtomText
        customClass={classNames}
        text={getTextWithLinks(text, textLinks)}
        textAttributes={dekAttributes}
        mobileText={getTextWithLinks(mobileText, mobileTextLinks)}
        mobileTextAttributes={mobileDekAttributes}
      />
    );
  }
}

export default AtomDek;
