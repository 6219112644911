import { QUERY_PARAMS } from "rtr-constants";
import { addCacheBusterToUrl } from "./helpers/location-helpers";

const Routes = {
  Account: {
    Login: "/account/login",
    Logout: "/account/logout",
    Register: "/account/register",
  },

  // Next.js only
  AUTH: {
    SIGNIN: "/auth/signin",
  },

  AddToBag: {
    unlimited: "/queue/join",
    update: "/update/join",
  },

  Carousels: {
    Personalized: "/home/personalized_carousels",
  },

  Designers: {
    RouteBySearchableName: function (searchableName) {
      return `/designers/${searchableName}/products`;
    },
  },

  Disco: {
    searchProducts: "/disco-search/api/v1/search/products",
    searchTypeaheads: "/disco-search/api/v1/typeahead",
  },

  Home: "/",
  ReturningHome: "/home/returning",

  Product: "/products",
  Popular: "/shop/most_popular/products",
  Fit: "/fit",

  HappinessSurvey: {
    survey: "/survey",
    completedSurvey: "/survey?status=COMPLETE",
    surveyByStyle: "/survey/surveyByStyle?status=COMPLETE&styleName=",
  },

  HELP: {
    CONTACT: "/help/contact",
    FAQ: "/help/faq",
    FIT_GUARANTEE: "/help/contact?_=1&customerpromise=true",
  },

  AttributionSurveyOptions: {
    getSurveyOptions: "/attribution_survey_options",
    submitSurveyOption: "/submit_attribution_survey_option",
  },

  Referrals: {
    advocateAwardStatus: "/advocate_award_status",
    advocateProgramStatus: "/advocate_program_status",
    friendRewards: "/friend_rewards_pps",
    inviteAFriend: "/invite_a_friend",
    shares: "/shares",
    awards: "/awards_pps",
  },

  Returns: "/returns",

  Swaps: {
    nonCachedProblemGroup: "/swaps/ncProblemGroup",
    problemGroup: "/swaps/problemGroup",
    cms: "/swaps/cms",
    products: "/swaps/products",
    fetchReplacements: "/swaps/getReplacements",
    addReplacement: "/swaps/addReplacement",
    removeReplacement: "/swaps/removeReplacement",
    perform: "/swaps",
    grid: "/swaps",
    success: "/user/orders?swaps=true",
    orderGroups: "/swaps/orderGroups",
    confirm: "/swaps/confirm",
    info: "/swap",
  },

  Exchanges: {
    exchangeBag: "/exchangeBag",
    exchangeObjects: "/exchanges",
    nonCachedExchangeObjects: "/ncExchanges",
    grid: "/swaps?newGroup=true",
    gridWithGroupId: "/swaps?newGroup=true&groupId=",
    confirm: "/exchanges/confirm",
    success: "/user/orders?exchange=",
    sameDayDeliveryEligibility: "/isSameDayDeliveryEligible",
  },

  Faq: {
    membership: `${addCacheBusterToUrl("/help/faq")}&${
      QUERY_PARAMS.HELP_CENTER.LEGACY_HELP
    }=/en_us/categories/memberships-BkgsilnVI`,
    membershipSwap: "/swap",
    pauseWithItems: `${addCacheBusterToUrl("/help/faq")}&${
      QUERY_PARAMS.HELP_CENTER.LEGACY_HELP
    }=/en_us/how-do-i-pause-my-membership-H1FdBDBi`,
    purchasePricing: `${addCacheBusterToUrl("/help/faq")}&${
      QUERY_PARAMS.HELP_CENTER.LEGACY_HELP
    }=/en_us/how-does-rtr-price-items-on-site--rkQ926BdD`,
    referrals: `${addCacheBusterToUrl("/help/faq")}&${
      QUERY_PARAMS.HELP_CENTER.LEGACY_HELP
    }=/en_us/categories/referrals-B1uo3Ydhu`,
    notAvailable: `${addCacheBusterToUrl("/help/faq")}&${
      QUERY_PARAMS.HELP_CENTER.LEGACY_HELP
    }=/en_us/why-are-the-items-i-want-sometimes-not-available-BkAznghEL`,
  },

  GiftCards: {
    submit: "/gift-cards",
    success: "/order",
  },

  Grids: {
    BlackoutDays: "/blackout_days",

    // Main grids
    Accessories: "/products/accessory",
    Clothing: "/products/clothing",
    Dresses: "/products/dress",
    Hearts: "/products?filters[hearts][]=true",
    Products: "/products",
    Unlimited: "/products?lens=unlimited",
    Kids: "/kids/dresses",
    Clearance: "/clearance/products",
    NewArrivals: "pdp/shop/new_arrivals/products",

    // Curated grids
    Curated: {
      BlackTie: "/shop/black_tie_wedding/products",
      Cocktail: "/shop/cocktail_party/products",
      Gala: "/shop/gala/products",
      WeddingGuest: "/shop/wedding_guest_dresses/products",
    },
  },

  HomePickup: "https://rtrshift.com/rtr-home-pickup",

  Insider: {
    index: "/rtr-insider",
    termsOfService: "/pages/insidertermsofservice",
  },

  LP: {
    unlimited: "/unlimited",
    update: "/update",
    plans: "/clothing-subscription",
    reserve: "/content/one-time-rentals",
    memberships: "/content/memberships",
  },

  WaitlistLandingPages: {
    update: "/update",
    rtrUpdate: "/rtrupdate",
    updateSrc: "/content/update",
    rtrUpdateSrc: "/content/rtrupdate",
  },

  // Misc.
  Pages: {
    HowRentingWorks: "/how_renting_works",
    Occasions: "/occasions",
    TermsOfService: "/pages/termsofservice",
    PrivacyPolicy: "/privacy",
    PromotionTermsAndConditions: "https://rtrshift.com/rent-the-runway-promotion-terms-and-conditions/",
  },

  PaymentMethods: {
    giftCard: "/paymentMethods/giftCard",
  },

  PDP: {
    RouteById: function (id) {
      return "/pdp/items/" + encodeURIComponent(id);
    },
  },

  Referral: {
    Shares: "/shares/new",
    UnlimitedAdvocatePage: "/shares/unlimited/new",
  },

  Reviews: {
    fetchProductReviews: productId => `/products/${productId}/reviews`,
  },

  SecureCreditCardEntryPage: "/secureCreditCardEntry",
  SecureApplePayEntryPage: "/secureApplePayEntry",

  ShippingAddresses: "/shippingAddresses",
  ShippingAddressesValidate: "/shippingAddresses/validate",
  DefaultShippingAddress: "/shippingAddresses/default",

  User: {
    OrderHistory: "/user/orders",
    Profile: "/user/profile",
    UpcomingOrders: "/user/orders/upcoming",
    Settings: "/user/settings",
    ProSettings: "/user/settings/pro",
    ManagePlan: "/user/settings/managePlan",
  },

  UserStylePreferencesRuby: {
    fetch: "/userStylePreferences",
    save: "/userStylePreferences/save",
  },

  Queue: {
    Home: "/queue",
  },

  SmartComponents: {
    checkoutBanner: "/user/smart_components/smart_checkout_banner",
    checkoutBannerSFNext: "/user/smart_components/smart_checkout_banner_v2",
    referralsReminderModal: "/user/smart_components/referrals_reminder_modal",
    referralsSaleGtmModalModal: "/user/smart_components/storefront_referrals_sale_gtm_modal",
    rentMyStyleQuiz: "/user/smart_components/smart_rent_my_style_quiz_homepage",
    skinnyBanner: "/user/smart_components/smart_skinny_banner",
    smartNavMenu: "/user/smart_components/smart_nav_menu",
    smartNavMenuMobile: "/user/smart_components/smart_nav_menu_mobile",
    smartPDPContent: "/user/smart_components/smart_pdp_copy",
    topRight: "/user/smart_components/smart_top_right",
    visualNav: "/user/smart_components/smart_quick_nav",
  },

  // TODO: remove the "New" (it won't make sense in 6 months)
  NewCheckout: {
    index: "/order",
    activeOrders: "/order/active",
    refreshOrder: orderId => `/order/${orderId}`,
    removeItem: (orderId, itemId) => `/order/${orderId}/orderItems/${itemId}`,
    updateMultipleGroups: orderId => `/order/${orderId}/orderGroups`,
    updateGroup: (orderId, groupId) => `/order/${orderId}/orderGroups/${groupId}`,
    submitOrder: orderId => `/order/${orderId}`,
    paymentMethods: orderId => `/order/${orderId}/paymentMethods`,
    promoCode: orderId => `/order/${orderId}/promoCode`,
    addPro: orderId => `/order/${orderId}/add_pro`,
    addOrderItem: "/order/orderItems",
  },

  Notification: {
    fetch: "/subscription_notification/rtrupdate",
  },

  UPS: "https://www.ups.com/dropoff/",
};

export default Routes;

export const {
  AddToBag,
  Carousels,
  Contact,
  Designers,
  Home,
  HELP,
  Product,
  HappinessSurvey,
  AttributionSurveyOptions,
  Referrals,
  Swaps,
  Exchanges,
  Faq,
  Fit,
  GiftCards,
  Grids,
  HomePickup,
  Insider,
  LP,
  WaitlistLandingPages,
  Pages,
  PaymentMethods,
  PDP,
  Popular,
  Referral,
  Reviews,
  Returns,
  SecureApplePayEntryPage,
  SecureCreditCardEntryPage,
  ShippingAddresses,
  ShippingAddressesValidate,
  DefaultShippingAddress,
  User,
  Queue,
  SmartComponents,
  NewCheckout,
  Notification,
  UPS,
  UserStylePreferencesRuby,
} = Routes;

if (typeof RTR !== "undefined") {
  RTR.Routes = Routes;
}
