import { getDisplayName } from "./utils";
import React, { useEffect } from "react";
import { bindFireWithUserData, unbindFireWithUserData, userDataPropType, useUserData } from "./with-user-data";
import PropTypes from "prop-types";

export function withFireWithUserData(WrappedComponent) {
  const C = props => {
    let _fireWithUserData;
    if (props.fireWithUserData) {
      // mostly convenience for testing, but does allow components to specify a fireWithUserData implementation
      _fireWithUserData = props.fireWithUserData;
    } else {
      // need to always call this hook for consistency between renders
      // ideally this is:
      // const userData = props.userData || useUserData();
      let userData = useUserData();
      if (props.userData) {
        // some components do have userData passed directly, so prefer that
        userData = props.userData;
      }
      let _callback;
      // on create, bind the fireWithUserData callback
      _fireWithUserData = callback => {
        bindFireWithUserData(userData, callback);
        _callback = callback;
      };

      useEffect(() => {
        // on unmount, unbind the callback
        return () => {
          unbindFireWithUserData(_callback);
        };
      }, [_callback]);
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} fireWithUserData={_fireWithUserData} />;
  };

  C.displayName = `withFireWithUserData(${getDisplayName(WrappedComponent)})`;
  C.propTypes = {
    fireWithUserData: PropTypes.func,
    userData: userDataPropType, // totally optional, if you wish to provide your own
  };
  return C;
}
