import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { navigationPropType } from "components/propTypes";
import { logAction, inferAction } from "action-logger";
import RtrImage from "../shared/rtr-image";

export class SmartNavComponent extends React.Component {
  static CLOSE_SUB_MENU_DELAY = 350;
  static propTypes = {
    isWideWidth: PropTypes.bool,
    navigation: navigationPropType,
    toggleSneakyHeaderVisible: PropTypes.func.isRequired,
  };

  state = {
    expandedNavGroup: null,
  };

  componentDidUpdate(_prevProps, prevState) {
    if (
      !this.props.isWideWidth &&
      this.state.expandedNavGroup &&
      prevState.expandedNavGroup !== this.state.expandedNavGroup
    ) {
      this.scrollToTopOfOpenSection();
    }
  }

  getNavObjectType() {
    return this.props.isWideWidth ? "top_nav" : "mobile_nav";
  }

  isRepeatedTitle(section) {
    return section.blocks.length === 1 && section.blocks.find(block => block.title === section.label);
  }

  getLoggableString(str) {
    if (!str) {
      return "";
    }
    return str.trim().replace(/\s+/g, "_").toLowerCase();
  }

  render() {
    const { navigation } = this.props;
    if (!navigation || !navigation.sections) {
      return null;
    }

    return <>{navigation.sections.map(section => this.renderSection(section))}</>;
  }

  logSectionHover(label) {
    if (!this.props.isWideWidth || !label) {
      return;
    }

    const pixelValue = label.toLowerCase().replace(" ", "_");
    logAction({
      object_type: "normal_nav",
      action: "nav_hover_value",
      value: pixelValue,
    });
  }

  renderSectionMenu(section) {
    if (!section.blocks?.length || (!this.props.isWideWidth && this.state.expandedNavGroup !== section.label)) {
      return null;
    }

    return (
      <div className="nav-menu-wrapper">
        <div className="nav-menu">
          <ul className="nav-menu-blocks">{section.blocks.map(block => this.renderBlock(block, section))}</ul>
        </div>
      </div>
    );
  }

  renderSectionLabel(section) {
    if (section.url) {
      const clearance = section.label === "Clearance";
      const spanClassName = classNames("nav-item-label", {
        clearance,
        [section.extraClass]: section.extraClass,
      });

      return (
        <a className="nav-item" href={section.url}>
          <span className={spanClassName}>{section.label}</span>
        </a>
      );
    }
  }

  /**
   * This adds a delay to showing the header overlays, which keeps them
   * hidden if users scroll quickly past the header without stopping.
   * @param section
   */
  onSectionMouseEnter = section => {
    // SK [EXPLANATION] 9/8/2022: If a user quickly passes from one nav-group <li/> to another on the way to pop-up menu,
    // this will keep the pop-up menu from closing too quickly.
    this.quickPassOver = true;
    this.sectionHoverTimeout = window.setTimeout(() => {
      if (this.props.isWideWidth) {
        this.setState({
          expandedNavGroup: section.label,
        });
        this.props.toggleSneakyHeaderVisible(true);
        this.logSectionHover(section.label);
      }
    }, 100);
  };

  onSectionMouseLeave = section => {
    if (this.sectionHoverTimeout !== -1) {
      clearTimeout(this.sectionHoverTimeout);
      this.quickPassOver = false;
    }

    if (this.props.isWideWidth) {
      setTimeout(() => {
        // NW [EXPLANATION] 10/1/21: if the user already hovered over another section, we do not need to close the nav.
        // but if they left this section without moving to another one, close the nav.
        if (this.state.expandedNavGroup === section.label && !this.quickPassOver) {
          this.setState({
            expandedNavGroup: null,
          });
          this.props.toggleSneakyHeaderVisible(false);
        }
      }, this.constructor.CLOSE_SUB_MENU_DELAY);
    }
  };

  scrollToTopOfOpenSection = () => {
    const openSection = document.querySelector(".nav-group.open");
    if (openSection.offsetTop > 0) {
      const nav = document.querySelector("#nav");
      nav.scrollTo({ top: openSection.offsetTop, behavior: "smooth" });
    }
  };

  onSectionClick = (e, section) => {
    if (this.props.isWideWidth) {
      inferAction({
        referring_url: window.location.pathname,
        objectType: this.getNavObjectType(),
        action: "click_menu_item",
        category_depth: "1",
        category_name: this.getLoggableString(section.label),
      });

      return;
    }

    // GN [EXPLANATION] 4/27/22: on mobile web, if a section has no blocks navigate to the URL and log a pixel
    if (!this.props.isWideWidth && !section.blocks.length) {
      inferAction({
        objectType: this.getNavObjectType(),
        action: "click_nav_icon",
        category_name: this.getLoggableString(section.label),
      });

      return;
    }
    // NW [EXPLANATION] 9/23/21: on mobile web, clicking on a nav group expands/closes the sub-menu instead of navigating to the URL
    e.preventDefault();

    let expanding;
    if (this.state.expandedNavGroup === section.label) {
      this.setState({
        expandedNavGroup: null,
      });
      expanding = false;
    } else {
      this.setState({
        expandedNavGroup: section.label,
      });
      expanding = true;
    }

    logAction({
      objectType: this.getNavObjectType(),
      action: expanding ? "expand_category" : "collapse_category",
      category_depth: "1",
      category_name: this.getLoggableString(section.label),
    });

    return false;
  };

  renderSection(section) {
    const navGroupClassName = classNames("nav-group", {
      "with-menu": section.blocks?.length,
      [section.eligibilityRules]: section.eligibilityRules,
      "active": this.props.isWideWidth && this.state.expandedNavGroup === section.label,
      "open": !this.props.isWideWidth && this.state.expandedNavGroup === section.label,
    });

    return (
      <li
        role="presentation"
        className={navGroupClassName}
        data-test-id="section"
        key={section.label}
        onMouseEnter={() => this.onSectionMouseEnter(section)}
        onMouseLeave={() => this.onSectionMouseLeave(section)}>
        <div role="presentation" className="h2 nav-group-title" onClick={e => this.onSectionClick(e, section)}>
          {this.renderSectionLabel(section)}
        </div>
        {this.renderSectionMenu(section)}
      </li>
    );
  }

  /* NAV BLOCKS (".nav-block") */

  renderBlockWithImage(block) {
    if (!this.props.isWideWidth) {
      return null;
    }

    return (
      <div className="nav-image" key={this.getBlockKey(block)}>
        <a className="nav-menu-block" href={block.url} data-test-id="nav-menu-block">
          <RtrImage
            alt="nav block"
            className="nav-menu-image"
            src={block.image.src}
            width={block.image.width}
            height={block.image.height}
          />
        </a>
        {block.title && (
          <a href={block.url}>
            <div className="nav-menu-block-header">{block.title}</div>
            <p className="nav-menu-image-subtitle">{block.subtitle}</p>
          </a>
        )}
      </div>
    );
  }

  renderBlockTitle(block) {
    if (!block.title) {
      return null;
    }
    const blockTitleClassName = classNames("block-title", {
      "nav-block-link": block.links?.length,
    });
    const blockTitle = <span className={blockTitleClassName}>{block.title}</span>;

    if (block.url) {
      return (
        <a className="nav-menu-block-header" href={block.url} key={this.getBlockKey(block)}>
          {blockTitle}
        </a>
      );
    } else {
      return <div className="nav-menu-block-header">{blockTitle}</div>;
    }
  }

  logLinkClick = (link, block, section) => {
    inferAction({
      referring_url: window.location.pathname,
      objectType: this.getNavObjectType(),
      action: "click_menu_item",
      category_depth: "3",
      category_name: this.getLoggableString(link.label),
      parent_category_name: this.getLoggableString(block.title),
      grandparent_category_name: this.getLoggableString(section.label),
    });
  };

  renderBlockLinks(block, section) {
    if (!block.links?.length) {
      return null;
    }

    return (
      <ul className="nav-block-links">
        {block.links.map(link => {
          if (!link.label) {
            return null;
          } else if (link.label === "spacer") {
            return <li key={link.label} className="nav-block-spacer" />;
          } else {
            return (
              <li key={link.label} data-test-id="nav-block-link">
                <a href={link.url} onClick={() => this.logLinkClick(link, block, section)}>
                  {link.label}
                </a>
              </li>
            );
          }
        })}
      </ul>
    );
  }

  getBlockKey(block) {
    // NW [EXPLANATION] 10/12/21: blocks are not guaranteed to have both a title and a URL, but they should have at least one.
    return `${block.title}_${block.url}`;
  }

  renderBlockWithoutImage(block, section) {
    const blockClassName = classNames("nav-block", {
      "always-open": this.isRepeatedTitle(section),
      [block.extraClass]: block.extraClass,
    });
    return (
      <li className={blockClassName} key={this.getBlockKey(block)}>
        {this.renderBlockTitle(block)}
        {this.renderBlockLinks(block, section)}
      </li>
    );
  }

  renderBlock(block, section) {
    if (block.image) {
      return this.renderBlockWithImage(block);
    } else {
      return this.renderBlockWithoutImage(block, section);
    }
  }
}

export default SmartNavComponent;
