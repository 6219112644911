import { useUserData } from "components/source/hoc/with-user-data";
import { useEffect } from "react";
import { sendSplunkRUMEvent } from "../../../analytics/analytics-integrations";

export default function MasqueradeBanner() {
  const userData = useUserData();

  useEffect(() => {
    return () => {
      if (userData?.isMasquerading) {
        sendSplunkRUMEvent({ action: "masquerading", masquerading: true });
      }
    };
  }, [userData]);

  if (!userData?.isMasquerading) return null;

  const { userProfile: { email } = {} } = userData;

  return (
    <div id="masquerade-banner" data-test-id="masquerade-banner">
      You are masquerading as <span id="masquerading-user">{email}</span>!
      <a href="/account/masquerade/unmask" className="unmask standalone-link">
        switch back
      </a>
    </div>
  );
}
