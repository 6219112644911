import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { authModalPropType } from "../../../propTypes/authentication-prop-types";
import { showAuthModal } from "../../../../actions/auth-actions";
import { HEAP_AUTH_TRIGGER_TYPES } from "../../../../helpers/heap-helpers";
import { displayModal } from "../../../../actions/shared-actions";

/**
 * This is a helper layout component to check for a user's authorization state before rendering, otherwise fallback to
 * the PromptForAuth component that dispatches action to launch the authentication modal.
 * @param authModalProps {object} props that get dispatched to auth modal state
 * @param children {ReactNode} React children
 * @param isReadyToPromptForAuth {boolean} boolean to determine if we are ready to dispatch the action to launch the
 * auth modal
 * @returns {ReactNode}
 */
const PromptForAuth = ({ authModalProps = {}, isReadyToPromptForAuth }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isReadyToPromptForAuth) {
      // Explicitly updating the display modal to false because if we are prompting the auth modal from a modal flow
      // that requires an authorized user AND we close the auth modal without signing-in AND then we take the same
      // action again to open the original modal (i.e. click a button) THEN nothing happens because the
      // isReadyToPromptForAuth prop doesn't change and this effect is not triggered again. Ideally this should go in
      // the showAuthModal action, but at this time I don't know if that would break any existing flows that may involve
      // prompting a user to login in response to an 401 HTTP response made from a user action inside a modal.
      dispatch(displayModal(false));
      dispatch(showAuthModal({ ...authModalProps, triggeredBy: HEAP_AUTH_TRIGGER_TYPES.AUTH_PROMPT }));
    }
  }, [isReadyToPromptForAuth]);

  return null;
};

PromptForAuth.propTypes = {
  authModalProps: authModalPropType,
  isReadyToPromptForAuth: PropTypes.bool,
};

export default PromptForAuth;
