import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PhotoReviewsServiceClient from "clients/PhotoReviewsServiceClient";

const initialState = {
  reviews: { totalCount: 0, moments: [] },
  error: null,
};

export const fetchReviewsWithReviewId = createAsyncThunk("reviews/fetchReviewsWithReviewId", async params => {
  params.status = "approved";
  const clientResponse = PhotoReviewsServiceClient.getInstance().fetchMomentWithCountWithReviewId({ ...params });
  return clientResponse;
});

export const reviewSliceWithId = createSlice({
  name: "reviews",
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchReviewsWithReviewId.fulfilled, (state, action) => {
      state.reviews = action.payload;
    });
  },
});

/**
 * This value isn't used anywhere.
 * It's only defined because ReduxApp expects reducers to have a "types" property
 */
export const types = ["reviews/fetchReviewsWithReviewId/fulfilled"];

export default {
  types: types,
  fn: reviewSliceWithId.reducer,
};
