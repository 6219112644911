import BaseServicesClient from "./BaseServicesClient";
import { isDevelopment } from "../helpers/next-helpers";
import { isStorefrontNext } from "helpers/environment-helpers";
import { getErrorMessage } from "helpers/error-message-maps/generic-http-status";
import { isSameOrigin } from "helpers/location-helpers";
import { handle401Response } from "helpers/auth-helpers";

// https://api.jquery.com/jQuery.ajax/

/** @type {import("qs").IStringifyOptions} */
const JQUERY_QUERY_PARAM_FORMAT = { arrayFormat: "brackets" };

const BASE_JQXHR = {
  status: 0,
  statusText: "error",
  responseText: void 0,
  responseJSON: void 0,
};

export const HTTP_EVENT_TYPES = {
  BEFORE_SEND: "beforeSend",
  ERROR: "error",
};

const JSON_RESPONSE_TYPES = ["application/json", "application/vnd.api+json"];

let HTTP_EVENT_HANDLERS;

// Global Send handlers, we need to store them in a global variable, which changes depending on server-side (global) v client side (window)
if (typeof window === "undefined") {
  if (typeof global.HTTP_EVENT_HANDLERS === "undefined") {
    global.HTTP_EVENT_HANDLERS = [];
  }
  HTTP_EVENT_HANDLERS = global.HTTP_EVENT_HANDLERS;
} else {
  if (typeof window.HTTP_EVENT_HANDLERS === "undefined") {
    window.HTTP_EVENT_HANDLERS = [];
  }
  HTTP_EVENT_HANDLERS = window.HTTP_EVENT_HANDLERS;
}

/**
 * A client that can be used to make raw ajax requests, based on the BaseServicesClient
 * This class is intended as a NON-EXHAUSTIVE replacement for jQuery.ajax, and is not intended to be fully feature complete
 * Only those features used throughout the codebase have been validated to work, YMMV. If you find yourself adding new code with this client,
 * you should probably stop create a specific service client for your service (e.g. CMSServiceClient)
 * This file mostly exists to provide a compat layer for older actions that use jQuery.ajax so that we can remove jQuery from the codebase
 */
class RawClient extends BaseServicesClient {
  static CONFIG_HOST = "/";
  static #csrfToken;
  static #isMasquerading;

  setCsrfToken(value) {
    RawClient.#csrfToken = value;
  }

  setIsMasquerading(value = false) {
    RawClient.#isMasquerading = value;
  }

  constructor(host) {
    super(host, JQUERY_QUERY_PARAM_FORMAT);
  }

  #normalizeInputs(...args) {
    if (!args || (Array.isArray(args) && (args.length <= 0 || args.length > 2))) {
      throw new Error("Invalid arguments");
    }
    let url,
      params,
      options = {};
    if (args.length === 1) {
      if (typeof args[0] === "string") {
        url = args[0];
      } else {
        url = args[0].url;
        options = Array.isArray(args[0]) ? [...args[0]] : Object.assign({}, args[0]);
        params = options.data;
        delete options.url;
        delete options.data;
      }
    } else if (args.length === 2) {
      url = args[0];
      params = args[1];
    }
    return { url, params, options };
  }

  #responseIsJSON(response) {
    // NW [EXPLANATION] 10/8/24: sometimes the response has no Content-Length header, and a JSON body. we want to parse those responses.
    // sometimes the response has Content-Length 0 and Content-Type json - we do NOT want to parse those responses.
    const contentLength = response.headers.get("Content-Length");
    const hasContentLengthZero = parseInt(contentLength, 10) === 0;

    return (
      JSON_RESPONSE_TYPES.some(responseType => response.headers.get("Content-Type")?.includes?.(responseType)) &&
      !hasContentLengthZero
    );
  }

  // can use http-status-codes to parse in and out of numbers to text
  // xhr has a status, statusText, responseText, and responseJSON (that has already been parsed)
  // textStatus a nullable string with values like "error" or "timeout"
  // errorThrown is the text version of the HTTP error (in the case of a http error)
  #createJQueryErrorFromNetworkError(error) {
    const jqXHR = Object.assign({}, BASE_JQXHR, error);
    // the error actually has a statusText set, but jQuery just hard codes it to "error"
    jqXHR.statusText = "error";
    return jqXHR;
  }

  async #createJQueryErrorFromNon2XXResponse(response) {
    const jqXHR = Object.assign({}, BASE_JQXHR);
    jqXHR.status = response.status;
    jqXHR.statusText = response.statusText;

    if ([406, 429].includes(jqXHR.status) || jqXHR.status >= 500) {
      // This is to handle various errors that are returned in an unexpected format. Examples of this
      // are when a backend server returns an ugly 500 error or when one of our edge tools errors
      // out before we even hit Storefront server (i.e. when Fastly / SignalSciences rate limits at the edge with a 406 or
      // a 429 HTML error response).
      jqXHR.responseText = getErrorMessage(jqXHR.status);
      return jqXHR;
    }

    if (response.status > 399) {
      try {
        const result = await this.#getResponseTextAndParsingResults(response);
        if (result.isJson) {
          jqXHR.responseJSON = result.result;
        } else {
          jqXHR.responseText = result.result;
        }
      } catch (error) {
        const jqXHR = this.#createJQueryErrorFromJSONError(error);
        HTTP_EVENT_HANDLERS.filter(e => e.eventName === HTTP_EVENT_TYPES.ERROR).forEach(e => e.handler(jqXHR));
        throw jqXHR;
      }
    }

    return jqXHR;
  }

  #createJQueryErrorFromJSONError(error) {
    const jqXHR = Object.assign({}, BASE_JQXHR, error);
    // the error actually has a statusText set, but jQuery just hard codes it to "error".
    jqXHR.statusText = error.message;
    return jqXHR;
  }

  async #handleResponse(request, requestUrl, getRetryRequest) {
    let response;
    try {
      response = await request;
    } catch (error) {
      const jqXHR = this.#createJQueryErrorFromNetworkError(error);
      HTTP_EVENT_HANDLERS.filter(e => e.eventName === HTTP_EVENT_TYPES.ERROR).forEach(e => e.handler(jqXHR));
      throw jqXHR;
    }

    if (response.status === 401 && !RawClient.#isMasquerading) {
      response = await handle401Response(response, requestUrl, getRetryRequest);
    }

    if (response.status >= 299) {
      const jqXHR = await this.#createJQueryErrorFromNon2XXResponse(response);
      HTTP_EVENT_HANDLERS.filter(e => e.eventName === HTTP_EVENT_TYPES.ERROR).forEach(e => e.handler(jqXHR));
      throw jqXHR;
    }

    try {
      // success case
      const result = await this.#getResponseTextAndParsingResults(response);
      return result.result;
    } catch (error) {
      const jqXHR = this.#createJQueryErrorFromJSONError(error);
      HTTP_EVENT_HANDLERS.filter(e => e.eventName === HTTP_EVENT_TYPES.ERROR).forEach(e => e.handler(jqXHR));
      throw jqXHR;
    }
  }

  async #getResponseTextAndParsingResults(response) {
    let result;
    let isJson = false;
    const resultText = await response.text();
    if (this.#responseIsJSON(response)) {
      try {
        result = JSON.parse(resultText);
        isJson = true;
      } catch (e) {
        // Ruby server inconsistently follows the HTTP spec and it is basically impossible to anticipate whether the response is actually JSON or not
        // Logging these errors so that we can track down the providers of bad JSON
        console.debug(`Failed to parse JSON network response: ${e.message}`);
        window.Sentry?.captureException?.(e);

        result = resultText;
      }
    } else {
      result = resultText;
    }

    return {
      result,
      isJson,
    };
  }

  #toClientOptions(url, params, options) {
    const result = Object.assign({}, options);
    const urlObj = new URL(url || result.url, window.location.origin);

    // $: data, fetch: body
    result.body = result.data || result.body;
    delete result.data;

    if (result.cache === false) {
      urlObj.searchParams.append("_", Date.now());
      delete result.cache;
    }

    let contentType = {};

    // if a content type was specified, send it
    if (result.contentType) {
      contentType = { "Content-Type": result.contentType };
    } else if (!result.headers?.["Content-Type"] && (params || result.body) && !result.isMultipartFormData) {
      // only set content type to the default if there is a body, content-type was not set in headers, and form data is not multi-part
      contentType = { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }; // use the same default as jQuery
    }

    const proxyingToRuby = url.startsWith("/api/storefront");
    const xhrHeaders =
      isStorefrontNext() && !proxyingToRuby
        ? {}
        : {
            "X-Requested-With": "XMLHttpRequest", // Ruby routes, or routes proxying to Ruby, require this header
          };

    let customHeaders = {};
    // Only send custom headers to our internal API
    // Our middleware ignores these request types, so no need to set the token
    // @see {https://www.rubydoc.info/gems/rack-protection/Rack/Protection/AuthenticityToken}
    if (
      !/^(GET|get|HEAD|head|OPTIONS|options|TRACE|trace)$/.test(options.type || options.method || "GET") &&
      isSameOrigin(url)
    ) {
      result.credentials = "same-origin";
      if (RawClient.#csrfToken) {
        customHeaders = {
          ["X-CSRF-Token"]: RawClient.#csrfToken,
        };
      }
    }

    result.headers = {
      ...result.headers,
      ...contentType,
      ...xhrHeaders,
      ...customHeaders,
    };
    result.url = urlObj.toString();

    return result;
  }

  #setupUrlForNext(url) {
    if (!isStorefrontNext()) {
      return url;
    }

    // fully qualified URL
    if (url.startsWith("http")) return url;
    // fully qualified URL (sans scheme)
    if (url.startsWith("//")) return url;
    // Next API requests
    if (url.includes("/api/")) return url;
    //  CSRF requests
    if (url.includes("/csrfProtection")) return url;
    //  Pixel requests - these should not proxy through Ruby as Fastly will route them based on this path
    if (url.includes("/pixel/p.php")) return url;

    if (isDevelopment()) {
      console.debug(`Defaulting to proxying AJAX request to storefront: ${url}`);
    }
    return `/api/storefront${url}`;
  }

  async ajax(...args) {
    const { url, params, options } = this.#normalizeInputs(...args);
    const prependedUrl = this.#setupUrlForNext(url);
    const clientOptions = this.#toClientOptions(prependedUrl, params, options);
    const method = clientOptions.type || clientOptions.method || "GET";

    let clientMethod;
    if (method.toUpperCase() === "GET") {
      clientMethod = super.get;
    } else if (method.toUpperCase() === "POST") {
      clientMethod = super.post;
    } else if (method.toUpperCase() === "PUT") {
      clientMethod = super.put;
    } else if (method.toUpperCase() === "PATCH") {
      clientMethod = super.patch;
    } else if (method.toUpperCase() === "DELETE") {
      clientMethod = super.delete;
    }

    if (!clientMethod) {
      throw new Error(`Invalid method: ${method}`);
    }
    // needed as private variables are accessed in the parent implementation
    clientMethod = clientMethod.bind(this);

    HTTP_EVENT_HANDLERS.filter(e => e.eventName === HTTP_EVENT_TYPES.BEFORE_SEND).forEach(e =>
      e.handler(clientOptions)
    );

    // function that returns a new request, in case a retry is needed due to expired access token
    const getRetryRequest = () => clientMethod(clientOptions.url, params, clientOptions);

    return this.#handleResponse(
      clientMethod(clientOptions.url, params, clientOptions),
      clientOptions.url,
      getRetryRequest
    );
  }

  async get(...args) {
    return this.ajax(...args);
  }

  async patch(...args) {
    const { url, params, options } = this.#normalizeInputs(...args);
    options.url = url;
    options.type = "PATCH";
    options.data = params;

    return this.ajax(options);
  }

  async post(...args) {
    const { url, params, options } = this.#normalizeInputs(...args);
    options.url = url;
    options.type = "POST";
    options.data = params;
    return this.ajax(options);
  }

  async put(...args) {
    const { url, params, options } = this.#normalizeInputs(...args);
    options.url = url;
    options.type = "PUT";
    options.data = params;
    return this.ajax(options);
  }

  async delete(...args) {
    const { url, params, options } = this.#normalizeInputs(...args);
    options.url = url;
    options.type = "DELETE";
    options.data = params;
    return this.ajax(options);
  }
}
const instance = RawClient.getInstance();

/**
 * Adds a handler for a given event type
 * @param {string} eventName The name of the event to invoke this handler for (must be one of HTTP_EVENT_TYPES)
 * @param {(options: any) => void} handler The handler to invoke when this event occurs
 */
export function addEventHandler(eventName, handler) {
  HTTP_EVENT_HANDLERS.push({ eventName, handler });
}

/**
 * Removes a handler for a given event type
 * @param {string} eventName The name of the event this handler was added for (must be one of HTTP_EVENT_TYPES)
 * @param {(options: any) => void} handler The handler to remove
 */
export function removeEventHandler(eventName, handler) {
  const index = HTTP_EVENT_HANDLERS.findIndex(e => e.eventName === eventName && e.handler === handler);
  if (index > -1) {
    HTTP_EVENT_HANDLERS.splice(index, 1);
  }
}

export default instance;
