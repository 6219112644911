import ActionTypes from "actions/action-types";

const types = [ActionTypes.ADD_TO_SHORTLIST_SUCCESS, ActionTypes.REMOVE_FROM_SHORTLIST_SUCCESS];

const getShortlists = (state, action) => {
  const { shortlists } = state;
  const { payload, type } = action;

  switch (type) {
    case ActionTypes.ADD_TO_SHORTLIST_SUCCESS: {
      const { styleId, shortlistId } = payload;

      if (styleId && shortlistId) {
        return (shortlists || []).map(shortlist => {
          if (shortlist.id === shortlistId) {
            return {
              ...shortlist,
              styleNames: [...shortlist.styleNames, styleId],
            };
          } else {
            return shortlist;
          }
        });
      }
      return shortlists;
    }
    case ActionTypes.REMOVE_FROM_SHORTLIST_SUCCESS: {
      const { styleId, shortlistId } = payload;

      if (styleId && shortlistId) {
        return (shortlists || []).map(shortlist => {
          if (shortlist.id === shortlistId) {
            return {
              ...shortlist,
              styleNames: shortlist.styleNames.filter(id => id !== styleId),
            };
          } else {
            return shortlist;
          }
        });
      }

      return shortlists;
    }
    default:
      return shortlists;
  }
};

const fn = (state, action) => {
  const shortlists = getShortlists(state, action);

  return { ...state, shortlists };
};

export default {
  types,
  fn,
};
