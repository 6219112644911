import React, { createContext } from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import { getDisplayName } from "../source/hoc/utils";
import { isPreviewEnvironment } from "helpers/contentful/contentful-helpers";
import { childrenPropType } from "../propTypes";

const ContentfulContext = createContext(null);
const stateKey = "contentfulContent";

const selectContentfulContent = state => state[stateKey];

export const useContentfulContent = () => useSelector(selectContentfulContent, shallowEqual);

const mapStateToProps = ({ [stateKey]: contentfulContent }) => ({ [stateKey]: contentfulContent });

export function withContentfulContent() {
  return WrappedComponent => {
    const C = connect(mapStateToProps)(WrappedComponent);

    C.displayName = `withContentfulContent(${getDisplayName(WrappedComponent)})`;

    return C;
  };
}

// this is not called automatically by Next, you must call it explicitly,
// probably in _app.js
async function getInitialReduxState({ ctx: { req } }) {
  return {
    [stateKey]: {
      isPreview: isPreviewEnvironment(req),
    },
  };
}

export function ContentfulProvider({ children }) {
  const contentfulContent = useContentfulContent();

  return <ContentfulContext.Provider value={contentfulContent}>{children}</ContentfulContext.Provider>;
}

ContentfulProvider.getInitialReduxState = getInitialReduxState;

ContentfulProvider.propTypes = {
  children: childrenPropType.isRequired,
};

export default {
  ...ContentfulContext,
  Provider: ContentfulProvider,
};
