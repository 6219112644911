import PropTypes from "prop-types";

/*
 * A non-sticky primary button with 100% width.
 * 100% width is of course relative to parent container.
 * Used in desktop PDP.
 */

const AtomFullWidthButton = ({
  buttonText,
  className,
  disabled,
  onClick = () => {},
  secondaryButtonStyle,
  type = "submit",
  role,
  dataHeapId = "",
  dataTestId = "atom-full-width-btn",
}) => {
  const buttonClassName = `${secondaryButtonStyle ? "btn-secondary" : "btn"} ${className || ""}`;

  return (
    <button
      data-test-id={dataTestId}
      className={`${buttonClassName} btn-full-width`}
      onClick={onClick}
      disabled={disabled}
      type={type}
      role={role}
      data-heap-id={dataHeapId}>
      {buttonText}
    </button>
  );
};

AtomFullWidthButton.propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  className: PropTypes.string,
  dataHeapId: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  secondaryButtonStyle: PropTypes.bool,
  type: PropTypes.string,
  role: PropTypes.string,
};

export default AtomFullWidthButton;

export const { propTypes } = AtomFullWidthButton;
