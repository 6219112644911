import PropTypes from "prop-types";

const navigationPropType = PropTypes.shape({
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          extraClass: PropTypes.string,
          image: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
            src: PropTypes.string,
          }),
          links: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              url: PropTypes.string,
            })
          ),
          subtitle: PropTypes.string,
          title: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      eligibilityRules: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
      viewAllLabel: PropTypes.string,
    })
  ),
});

export { navigationPropType };
