// these values are used to replace the above log levels in the server side logger in order to adhere to the RTR log format
// it starts with %-6 which means to left justify the token (level) in a 6 character space
// so the space inside the strings below achieves that
const loggingReplacements = {
  debug: "DEBUG ",
  info: "INFO  ",
  warn: "WARN  ",
  error: "ERROR ",
};

const loggingProperties = ["debug", "info", "warn", "error"];

// will be initialised if we're on the server
let executionAsyncId;

function getTelemetryLogMessage(logArgs) {
  return `span_id=${logArgs.span_id || 0} trace_id=${logArgs.trace_id || 0}`;
}

// function to serialize messages in splunk format
const serializeSplunkMessage = logArgs => {
  const { level, message, label, timestamp } = logArgs;
  const telemetryLogMessage = getTelemetryLogMessage(logArgs);

  // capitalize the loglevel (as required by the RTR log format) but preserve the color encoding info that format.colorize has added
  // when colorize runs, it makes the level look like '\u001b[34minfo\u001b[39m', and if you just toUpperCase() that it breaks the output
  const formattedLevel = loggingProperties.reduce(
    (result, logLevel) => result.replace(logLevel, loggingReplacements[logLevel]),
    level
  );

  let stack = "";
  if (logArgs.stack) {
    stack = `, stack: ${logArgs.stack}`;
  }
  /**
   * logFormat: "%-6level [%d{ISO8601,UTC}] [%t] [span_id=%X{signalfx.span_id:-none}, trace_id=%X{signalfx.trace_id:-none}] %logger{6} - %msg %n"
   * From https://renttherunway.jira.com/wiki/spaces/EN/pages/2852028521
   */
  return `${formattedLevel} [${timestamp}] [${executionAsyncId()}] [${telemetryLogMessage}] ns=${label} - ${message}${stack}`;
};

/**
 * The Storefront.next Logger. Use this function to create new instances of the logger
 * @param {*} namespace A string representing the file making the log statement - this should be either the function name, class name, or file name (in that order)
 * @returns An instance of the logger with the configured values, which can be used to log messages
 */
const SFLogger = namespace => {
  if (typeof window !== "undefined") {
    return console;
  } else {
    // NOTE: we typically prefer ESM import syntax here, but making it a CJS require instead means we can preload this file on the command line
    // in next:dev:metrics and run it in debug mode locally
    // NOTE: this must be invoked after the instrumentation has been added, otherwise the OTEL library will not have a chance to insert itself into Winston
    // eslint-disable-next-line import/no-commonjs
    const winston = require("winston");

    if (!executionAsyncId) {
      // eslint-disable-next-line import/no-commonjs
      executionAsyncId = require("async_hooks").executionAsyncId;
    }

    const format = winston.format;

    const formatters = [
      format.label({ label: namespace }), // add the namespace as the label on this message
      format.timestamp(),
      format.errors({ stack: true }),
      format.splat(), // for %s formatting
      format.printf(serializeSplunkMessage), // splunk formatting for final output
    ];

    let level = "info";

    // NOTE: not using isDevelopment() as it would mean converting that to CJS syntax too
    if (process.env.NODE_ENV === "development") {
      formatters.push(format.colorize({ all: true }));
      level = "debug";
    }

    return winston.createLogger({
      level,
      format: format.combine(...formatters),
      defaultMeta: { service: "storefront-next" },
      transports: [new winston.transports.Console()], // "console" in this context being the process console, or stdout / stderr
    });
  }
};

// ditto for this (ESM v CJS, see top of file)
// eslint-disable-next-line import/no-commonjs
module.exports.SFLogger = SFLogger;
