import React from "react";
/**
 * hook for functional components to use when relying on window size being read in hooks
 *
 */
const withWindowResize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: null,
    height: null,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export default withWindowResize;
