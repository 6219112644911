import React from "react";
import { format } from "date-fns";
import $ from "clients/RawClient";
import Datepicker from "./datepicker";
import ActionLogger from "action-logger";
import PropTypes from "prop-types";
import { dateFnsFormats } from "rtr-constants";

class ShortlistForm extends React.Component {
  static defaultProps = {
    logPixel: () => {},
  };

  state = {
    shortlistName: "",
    invalidName: false,
  };

  onDateChange = dateObj => {
    this.setState({
      date: dateObj.name,
    });
  };

  onSubmit = event => {
    let postData;

    event.preventDefault();

    if (this.state.shortlistName.trim().length === 0) {
      this.setState({
        invalidName: true,
      });

      return;
    }

    this.props.logPixel();

    postData = {
      eventDate: this.state.date,
      options: {
        dateFormat: "YYYY-MM-DD",
      },
      name: this.state.shortlistName,
    };

    if (this.props.productId) {
      postData = {
        ...postData,
        styleNames: [this.props.productId],
      };
    }

    $.ajax({
      type: "POST",
      url: "/shortlist",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(postData),
    }).then(
      data => {
        ActionLogger.logAction({
          object_type: "shortlist",
          action: "create",
          sub_type1: "dropdowndefined",
          name: data.name,
          collectionId: data.id,
          rentBegin: data.eventDate,
        });

        this.props.fetchShortlists();
        this.props.toggleForm(false);
      },
      function () {
        console.log("failed to create shortlist", postData); // eslint-disable-line
      }
    );
  };

  onShortlistNameChange = event => {
    const stateHash = {
      shortlistName: event.target.value,
    };
    if (this.state.invalidName) {
      stateHash["invalidName"] = false;
    }
    this.setState(stateHash);
  };

  render() {
    const todayString = format(new Date(), dateFnsFormats.YYYY_MM_DD);

    return (
      <div className="shortlist-form-wrapper">
        <form onSubmit={this.onSubmit} className="shortlist-form" method="post">
          <div className={"input-wrapper input-wrapper-shortlist" + (this.state.invalidName ? " error" : "")}>
            <label htmlFor="shortlist_name">Shortlist Name</label>
            <input
              id="shortlist_name"
              maxLength="64"
              name="shortlist[name]"
              placeholder="i.e. 'My Birthday'"
              type="text"
              onChange={this.onShortlistNameChange}
              value={this.state.shortlistName}
            />
          </div>
          <Datepicker
            duration="1"
            date={this.state.date}
            dateStart={todayString}
            onChange={this.onDateChange}
            name="shortlist[date]"
            idSuffix=""
          />
          <div className="shortlist-form-buttons">
            <button className="shortlist-toggle-form btn-secondary" onClick={this.props.hideShortlistCreateForm}>
              Cancel
            </button>
            <button
              className="shortlist-form-submit btn"
              data-style-name={this.props.productId}
              data-shortlist-name={this.state.shortlistName}
              onClick={this.onSubmit}>
              Create
            </button>
          </div>
        </form>
      </div>
    );
  }
}

ShortlistForm.propTypes = {
  fetchShortlists: PropTypes.func,
  hideShortlistCreateForm: PropTypes.func,
  productId: PropTypes.string,
  logPixel: PropTypes.func,
  toggleForm: PropTypes.func,
};

export default ShortlistForm;

export const { propTypes } = ShortlistForm;
