import _ from "underscore";
import ActionTypes from "../actions/action-types.js";
var productDrawerMethods, fn;

var initialState = {
  productDrawerId: null,
  product: null,
};

var setInitialState = function (state) {
  return _.extend({}, state, { replacementsDrawer: initialState });
};

var displayProductDrawer = function (state, action) {
  return _.extend({}, state, {
    productDrawerId: action.payload.productDrawerId || null,
    product: action.payload.product || null,
  });
};

productDrawerMethods = {};
productDrawerMethods[ActionTypes.DISPLAY_REPLACEMENTS_PRODUCT_DRAWER] = displayProductDrawer;

fn = function (state, action) {
  var newState = state;

  if (_.isUndefined(newState.productDrawer)) {
    newState = setInitialState(newState);
  }

  var productDrawerActionMethod = productDrawerMethods[action.type];

  var newProductDrawerState = !_.isUndefined(productDrawerActionMethod)
    ? productDrawerActionMethod(newState.productDrawer, action)
    : _.extend({}, newState.productDrawer);

  return _.extend({}, newState, { replacementsDrawer: newProductDrawerState });
};

export default {
  types: Object.keys(productDrawerMethods),
  fn: fn,
};
