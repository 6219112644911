/**
 * Text attributes that can be applied to our text atoms.
 * They reference a defined set of classes in _atom-customizable.scss.
 */
import PropTypes from "prop-types";

const fonts = ["genath", "proxima-nova", "proxima-nova-semibold", "proxima-nova-bold", "wulkan-display"];
const fontSizes = [12, 13, 14, 16, 18, 20, 24, 26, 28, 32, 36, 38, 44];
const textAlign = ["left", "center", "right"];
/**
 * Text color
 */
const theme = ["white", "gray"];
const textTreatment = ["bold", "italic"];
const letterSpacing = ["subtitle-spacing", "title-spacing"];

export const atomTextAttributesPropType = PropTypes.shape({
  font: PropTypes.oneOf(fonts),
  fontSizes: PropTypes.oneOf(fontSizes),
  textAlign: PropTypes.oneOf(textAlign),
  theme: PropTypes.oneOf(theme),
  textTreatment: PropTypes.oneOf(textTreatment),
  letterSpacing: PropTypes.oneOf(letterSpacing),
});

//Same as above plus a separator option.
//Only separator needs to be a unique property key
export const atomHedAttributesPropType = PropTypes.shape({
  font: PropTypes.oneOf(fonts),
  fontSizes: PropTypes.oneOf(fontSizes),
  textAlign: PropTypes.oneOf(textAlign),
  theme: PropTypes.oneOf(theme),
  textTreatment: PropTypes.oneOf(textTreatment),
  letterSpacing: PropTypes.oneOf(letterSpacing),
  separator: PropTypes.bool,
});

const atomTextLinkPropType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const atomTextLinksPropType = PropTypes.arrayOf(atomTextLinkPropType);
