import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import ActionLogger from "action-logger";
import Constants from "rtr-constants";
import { skuInfo } from "helpers/sku-helpers";
import {
  getProductImageAltText,
  getProductImageUrlsByOrientation,
  ProductImageSize,
} from "helpers/product-image-helpers";
import FormattingUtils from "helpers/FormattingUtils";
import SwipeableCarousel from "components/source/shared/carousels/swipeable-carousel";
import ProductImage from "components/source/shared/product-image";

class ProductSelection extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    onSubmit: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const itemsByStyle = _.groupBy(this.props.items, item => item?.product?.id ?? "None");
    const skuSelection = this.skuMappings(itemsByStyle),
      skuSizes = this.skuMappings(itemsByStyle, "size");

    // these state fields are used so i'm not sure why there's a linting error
    /* eslint-disable react/no-unused-state */
    this.state = {
      itemsByStyle,
      skuSelection,
      skuSizes,
    };
    /* eslint-enable react/no-unused-state */
  }

  skuMappings(itemsByStyle, param = null) {
    return _.chain(itemsByStyle)
      .keys()
      .map(sku => {
        if (param) {
          return [sku, _.map(itemsByStyle[sku], param)];
        } else {
          return [sku, false];
        }
      })
      .object()
      .value();
  }

  handleNextButtonClick = () => {
    const selectedBookings = _.filter(this.props.items, i => this.state.skuSelection[skuInfo(i.sku.id).id]);
    if (selectedBookings.length < 1) {
      return;
    }

    const itemsSelected = selectedBookings.map(booking => {
      return { selected_sku: booking.sku.id, booking_id: booking.id };
    });

    ActionLogger.logAction({
      action: "item_selection_complete",
      object_type: Constants.analytics.SELF_SERVICE_EXCHANGES,
      displayed_sku_count: this.props.items.length,
      selected_sku_count: selectedBookings.length,
      items_selected: itemsSelected,
    });

    this.props.onSubmit(selectedBookings);
  };

  handleItemSelection = sku => {
    const newSelection = Object.assign({}, this.state.skuSelection);
    newSelection[sku] = !this.state.skuSelection[sku];
    this.setState({ skuSelection: newSelection });
  };

  renderItem = sku => {
    const { skuSelection, skuSizes, itemsByStyle } = this.state || {};

    if (!skuSelection || sku === "None") {
      return null;
    }

    const item = _.first(itemsByStyle[sku]);
    const product = item?.product;
    const baseItemClass = "product-selection__item";
    const selectedClass = `${baseItemClass}--selected`;
    const selected = skuSelection[sku];
    const multiple = skuSizes[sku].length > 1;
    const multipleClass = `${baseItemClass}--multiple`;
    const itemClass = `${baseItemClass} ${multiple ? multipleClass : ""} ${selected ? selectedClass : ""}`;
    const formattedSizesCopy = FormattingUtils.pluralize("Size", skuSizes[sku].length);

    return (
      <button
        className={itemClass}
        onClick={() => this.handleItemSelection(sku)}
        key={sku}
        data-test-id="product-selection-item">
        <ProductImage
          altText={getProductImageAltText(product?.displayName, product?.designer?.displayName)}
          className="product-selection__image"
          imageSize={ProductImageSize.x183}
          imageGroup={getProductImageUrlsByOrientation(product?.images, [])}
        />
        <section className="product-selection__info">
          <p className="product-selection__designer">{product?.designer?.displayName}</p>
          <p className="product-selection__name">{product?.displayName}</p>
          <p className="product-selection__size">
            {formattedSizesCopy} {skuSizes[sku].join(" and ")}
          </p>
        </section>
      </button>
    );
  };

  render() {
    const { skuSelection } = this.state || {};
    if (!skuSelection) {
      return null;
    }

    const carouselItems = _.chain(this.state.skuSelection)
      .keys()
      .map(sku => this.renderItem(sku))
      .value();

    const selectionIsEmpty = !_.reduce(this.state.skuSelection, (x, y) => {
      return x || y;
    });

    return (
      <div className="product-selection" data-test-id="product-selection">
        <h4 className="product-selection__main-title">What do you want to replace?</h4>
        <h5 className="product-selection__subtitle">Select all that apply.</h5>
        <SwipeableCarousel pageSize={2}>{carouselItems}</SwipeableCarousel>
        <button disabled={selectionIsEmpty} onClick={this.handleNextButtonClick} className="btn">
          Next
        </button>
      </div>
    );
  }
}

export default ProductSelection;
