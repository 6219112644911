import React from "react";
import { dateFnsFormats as dateFormats, analytics as analyticsConstants } from "rtr-constants";
import ActionLogger from "action-logger";
import { format as formatInLocalTimezone, isToday, isTomorrow, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import PropTypes from "prop-types";
import PropTypesHelper from "../../../helpers/PropTypesHelper";
import { exchangeGroupPropType } from "../../propTypes/exchange-group-prop-type";

export default class ExchangeIntro extends React.Component {
  static propTypes = {
    addressesEmpty: PropTypes.bool.isRequired,
    blockForCutoffs: PropTypes.bool,
    group: exchangeGroupPropType,
    newBagCutoffFromAddressChange: PropTypesHelper.dateString,
    newRentBeginFromAddressChange: PropTypesHelper.dateString,
    onEditAddress: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  componentDidMount() {
    const {
      group: { bagCutoffTime, newRentBegin },
    } = this.props;

    ActionLogger.logAction({
      action: "swap_modal_land",
      object_type: analyticsConstants.SELF_SERVICE_EXCHANGES,
      replacement_deadline_datetime: bagCutoffTime,
      replacement_arrival_datetime: newRentBegin,
    });
  }

  renderAddress() {
    const {
      addressesEmpty,
      group: { address },
    } = this.props;

    // if the addresses haven't loaded yet, hide the edit button as it would return an empty modal
    const addressChangeButtonClass = `btn-link ${addressesEmpty ? "hidden" : ""}`;

    return (
      <div className="exchange-intro__address-container">
        <section>
          <p>
            {address.firstName} {address.lastName}
          </p>
          {address.company && <p>{address.company}</p>}
          <p>{address.street1}</p>
          {address.street2 && <p>{address.street2}</p>}
          <p>
            {address.city}, {address.state || address.zoneCode} {address.postalCode || address.zip}
          </p>
          <button className={addressChangeButtonClass} onClick={this.props.onEditAddress}>
            Edit
          </button>
        </section>
      </div>
    );
  }

  renderExchangeDetails() {
    const {
      newRentBeginFromAddressChange,
      newBagCutoffFromAddressChange,
      group: { bagCutoffTime, newRentBegin },
    } = this.props;

    //If the user has changed address, use that cutoff, otherwise use the original one
    //Cutoffs come from the BE as UTC timestamps and should be displayed in local time
    const cutoffTime = parseISO(newBagCutoffFromAddressChange || bagCutoffTime || "");
    const cutoffDay = isToday(cutoffTime) ? "today" : "tomorrow";
    const cutoffString = formatInLocalTimezone(cutoffTime, dateFormats.hourCondensed) + " " + cutoffDay;

    //If the user has changed address, use that RBD, otherwise use the one from the original group
    //RBDs come from the BE as UTC timestamps and are displayed in Eastern
    //The BE does not adjust delivery time based on tz, i.e. the correct time is in Eastern regardless of tz of delivery
    const newRentStart = parseISO(newRentBeginFromAddressChange || newRentBegin || "");
    const newRentDay = isToday(newRentStart)
      ? "today"
      : isTomorrow(newRentStart)
      ? "tomorrow"
      : "on " + formatInTimeZone(newRentStart, "America/New_York", dateFormats.day);
    const deliveryTime = formatInTimeZone(newRentStart, "America/New_York", dateFormats.hourCondensed);

    return (
      <div className="exchange-intro__delivery-message">
        <p>Select your replacements by {cutoffString},</p>
        <p>
          and they&apos;ll arrive by {deliveryTime} {newRentDay}.
        </p>
      </div>
    );
  }

  render() {
    if (!this.props.group) {
      return null;
    }

    return (
      <div className="exchange-intro">
        <div className="exchange-intro__hanger">
          <div className="reb-POET_HangarIcon"></div>
        </div>
        <h1 className="exchange-intro__main-title">Get a Replacement</h1>
        {!this.props.blockForCutoffs && (
          <section className="exchange-intro__offer">
            <p>Just answer a few questions and we’ll</p>
            <p>show you similar styles to choose from.</p>
          </section>
        )}
        {this.renderExchangeDetails()}
        {this.renderAddress()}
        <button disabled={this.props.blockForCutoffs} onClick={this.props.onSubmit} className="btn">
          Get a Replacement
        </button>
      </div>
    );
  }
}
