import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { Cookies, withCookies } from "react-cookie";
import _ from "underscore";

import sharedActions from "actions/shared-actions";
import happinessSurveyActions from "actions/happiness-survey-actions";
import { redirectToRoute } from "actions/action-utils";
import exchangeActions from "actions/exchange-actions";
import userProblemsActions from "actions/user-problems-actions";
import HappinessSurveyTrigger from "components/source/happiness_survey/happiness-survey-trigger";
import ExchangeIntro from "./ExchangeIntro";
import ExchangeAddressChangeView from "./ExchangeAddressChangeView";
import ClosableModal from "components/source/shared/closable-modal";
import { analytics, HappinessSurveyTypes } from "rtr-constants";
import { Exchanges } from "routes";
import ActionLogger from "action-logger";
import { addAddress, loadAddressBook } from "actions/shipping-step-actions";
import { addressPropType, userDataPropType } from "components/propTypes";

const modalTypes = {
  INTRO: "EXCHANGE_INTRO",
  ADDRESS: "ADDRESS_CHANGES",
};

export class ExchangeButton extends React.Component {
  static propTypes = {
    addresses: PropTypes.arrayOf(addressPropType),
    banner: PropTypes.bool,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    dispatch: PropTypes.func.isRequired,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    fetchShippingAddresses: PropTypes.bool,
    groupId: PropTypes.string,
    happinessSurvey: PropTypes.object,
    optionalClass: PropTypes.string,
    selfServiceExchange: PropTypes.object,
    userData: userDataPropType,
  };

  static defaultProps = {
    fetchShippingAddresses: true,
  };

  modalName = () => {
    const groupId = this.groupId();
    return "ExchangeIntro-" + groupId + (this.props.banner ? "banner" : "link");
  };

  state = {
    trigger: false,
    activeModal: modalTypes.INTRO,
  };

  componentDidMount() {
    if (!this.props.addresses && this.props.fetchShippingAddresses) {
      this.props.dispatch(loadAddressBook());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selfServiceExchange } = nextProps;

    if (!selfServiceExchange) {
      return;
    }

    // if the isSameDayDeliveryEligible call was successful
    if (_.isBoolean(selfServiceExchange.isSameDayDeliveryEligible)) {
      // if newBagCutoff is null, then we know we are after cutoff
      const isAfterCutoff = _.isEmpty(selfServiceExchange.newBagCutoff);
      this.setState({ blockForCutoffs: isAfterCutoff });
    }
  }

  groupId = () => {
    if (this.props.banner) {
      const exchanges = this.props.selfServiceExchange?.exchanges;
      const incompleteExchanges = _.filter(exchanges, exchange => {
        return !exchange.hasCompletedExchange && exchange.eligible;
      });

      return incompleteExchanges?.[0]?.orderGroupSummary?.groupId;
    } else {
      return this.props.groupId;
    }
  };

  handleClickSubmit = () => {
    const { fullGroup } = this.state;

    this.setState({ items: fullGroup.bookings, trigger: true });

    ActionLogger.logAction({
      action: "swap_modal_complete",
      object_type: analytics.SELF_SERVICE_EXCHANGES,
    });

    const {
      cookies,
      selfServiceExchange: { newBagCutoff },
    } = this.props;

    const newAddressId = fullGroup?.address?.id;
    const newLPS = newBagCutoff || fullGroup.bagCutoffTime;

    // store updated address id in the cookies to retrieve if user refreshes page after happiness survey
    cookies.set("sse_address_id", newAddressId);
    cookies.set("sse_lps", newLPS);

    this.props.dispatch(exchangeActions.updateAddress(newAddressId));

    this.props.dispatch(happinessSurveyActions.setHappinessSurveyInfo(HappinessSurveyTypes.RESERVE, this.groupId()));
    this.props.dispatch(sharedActions.displayModal(HappinessSurveyTrigger.modalName));
  };

  isPickUpInStoreOrder() {
    const {
      addresses,
      selfServiceExchange: { exchangeGroups },
    } = this.props;

    const groupId = this.groupId();
    const fullGroup = exchangeGroups?.[groupId];
    if (fullGroup?.address?.company?.toLowerCase() === "rent the runway") {
      return true;
    }

    // if the address is not in their address book, its a store address
    return !_.any(addresses, address => {
      return address.id === fullGroup?.address?.addressId?.toString();
    });
  }

  // to convert store address to the same format as the addresses coming from godmother
  formatAddress(address) {
    const { addressId, city, company, firstName, lastName, phone, state, street1, street2, zip } = address;

    return {
      id: addressId,
      firstName: firstName,
      lastName: lastName,
      street1: street1,
      street2: street2,
      city: city,
      zoneCode: state,
      postalCode: zip,
      phone: phone,
      company: company,
      default: false,
    };
  }

  handleClickLaunch = () => {
    const {
      cookies,
      selfServiceExchange: { exchangeGroups },
      happinessSurvey,
      addresses,
    } = this.props;

    const completedSurveys = happinessSurvey?.completedSurveys || [];
    const groupId = this.groupId();
    const fullGroup = exchangeGroups?.[groupId];

    // standardize address object format to match this.props.addresses format
    // todo: change the address format coming in from backend in exchangeGroups object
    // check if the address format is already what we want (this is the case when the modal is opened,
    // then closed, then reopened)
    if (fullGroup?.address?.type !== "address") {
      if (this.isPickUpInStoreOrder()) {
        const address = this.formatAddress(fullGroup?.address);
        this.props.dispatch(addAddress({ address }));
      } else {
        const oldAddressId = fullGroup?.address?.addressId?.toString() || "";
        const address = _.findWhere(addresses, { id: oldAddressId });
        fullGroup.address = address;
      }
    }

    this.setState({ fullGroup });
    const source = this.props.banner ? "banner" : "order_history";
    const pixelData = {
      action: "enter_sse_funnel",
      object_type: analytics.SELF_SERVICE_EXCHANGES,
      source: source,
    };

    if (fullGroup.bagCreated) {
      ActionLogger.inferAction(pixelData);
      redirectToRoute(Exchanges.gridWithGroupId + groupId);
    } else if (this.hasCompletedHappinessSurvey(fullGroup)) {
      ActionLogger.inferAction(pixelData);

      // get the problem bookings for the current group only
      const groupBookings = fullGroup?.bookings?.map(booking => booking.bookingId);
      const problemBookings = completedSurveys
        ?.filter(survey => groupBookings?.includes(survey.bookingId.toString()))
        ?.map(booking => booking.bookingId);

      // createExchangeBag requires an address ID if it has been changed, null if we are using the existing address ID
      // first we check if we saved an address id to the cookie (set during handleClickSubmit), and if its missing,
      // use the address id from the group in the state -- mhorowitz
      const addressIdCookie = cookies.get("sse_address_id");
      const lpsCookie = cookies.get("sse_lps");

      const addressId = addressIdCookie || this.state.fullGroup?.address?.id || null;

      userProblemsActions.createExchangeBag(fullGroup, addressId, lpsCookie, problemBookings)(this.props.dispatch);
    } else {
      ActionLogger.logAction(pixelData);
      this.props.dispatch(sharedActions.displayModal(this.modalName()));
    }
  };

  handleModalClose = () => {
    this.props.dispatch(sharedActions.displayModal(false));

    // return modal to normal state on re-open
    this.setState({ activeModal: modalTypes.INTRO });
  };

  hasCompletedHappinessSurvey = fullGroup => {
    const completedSurveys = this.props.happinessSurvey?.completedSurveys;
    if (!completedSurveys) {
      return false;
    }

    const surveyBookingIds = _.map(completedSurveys, "bookingId");
    const groupBookingIds = _.map(fullGroup.bookings, booking => Number(booking.id));

    const completedSurveysInGroup = _.intersection(surveyBookingIds, groupBookingIds);

    return !_.isEmpty(completedSurveysInGroup);
  };

  renderLink = () => {
    return (
      <button onClick={this.handleClickLaunch} className={this.props.optionalClass || "btn-link"}>
        Get a Replacement
      </button>
    );
  };

  renderTrigger = () => {
    const { items, trigger } = this.state;
    const { dispatch, userData } = this.props;

    if (!trigger) {
      return null;
    }

    return <HappinessSurveyTrigger dispatch={dispatch} userData={userData} atHomeItems={items} />;
  };

  shouldDisplayAddressChangeModal() {
    const { activeModal } = this.state;
    const { addresses } = this.props;
    return activeModal === modalTypes.ADDRESS && !_.isEmpty(addresses);
  }

  updateAddress = addressId => {
    const { fullGroup } = this.state;
    const { dispatch, addresses } = this.props;

    const selectedAddress = _.findWhere(addresses, { id: addressId });
    const newFullGroup = Object.assign({}, fullGroup, { address: selectedAddress });

    exchangeActions.fetchUpdatedSameDayEligibility(selectedAddress.postalCode, newFullGroup.groupId)(dispatch);

    this.setState({ activeModal: modalTypes.INTRO, fullGroup: newFullGroup });
  };

  renderModalBody() {
    const { fullGroup } = this.state || {};
    const {
      dispatch,
      addresses,
      selfServiceExchange: { newRentBegin, newBagCutoff },
    } = this.props;
    const addressId = fullGroup?.address?.id;

    if (this.shouldDisplayAddressChangeModal()) {
      return (
        <ExchangeAddressChangeView
          addresses={addresses}
          group={fullGroup}
          useAddress={this.updateAddress}
          initialAddressId={addressId}
          dispatch={dispatch}
        />
      );
    }

    return (
      <ExchangeIntro
        onSubmit={this.handleClickSubmit}
        group={fullGroup}
        dispatch={dispatch}
        addressesEmpty={_.isEmpty(addresses)}
        blockForCutoffs={this.state.blockForCutoffs}
        newRentBeginFromAddressChange={newRentBegin}
        newBagCutoffFromAddressChange={newBagCutoff}
        onEditAddress={() => {
          this.setState({ activeModal: modalTypes.ADDRESS });
        }}
      />
    );
  }

  emptyRender() {
    // for the order history page:
    // we need to empty render the parent component for groupId() to successfully return a value
    return this.props.banner ? null : <span></span>;
  }

  render() {
    const optionalModalClass = this.shouldDisplayAddressChangeModal()
      ? "exchange-address-modal"
      : "exchange-intro-modal";

    const groupId = this.groupId();
    if (!groupId) {
      return this.emptyRender();
    }
    if (
      !this.props.selfServiceExchange?.groupEligibility ||
      !this.props.selfServiceExchange.groupEligibility[groupId]
    ) {
      return this.emptyRender();
    }

    return (
      <>
        {this.renderLink()}
        {this.renderTrigger()}
        <ClosableModal
          optionalClass={optionalModalClass}
          isOpen={this.props.displayedModal === this.modalName()}
          onRequestClose={this.handleModalClose}>
          {this.renderModalBody()}
        </ClosableModal>
      </>
    );
  }
}

export default compose(withCookies)(ExchangeButton);
