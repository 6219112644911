import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const AtomLinkButton = ({
  bold = true,
  children,
  className = "",
  disabled = false,
  id = "",
  innerClassName = "",
  name = "",
  onClick,
  size = "small",
  testId = "",
  type = "button",
  underline = true,
}) => (
  <button
    className={classNames("atom-link-button", className)}
    data-test-id={testId}
    disabled={disabled}
    id={id}
    name={name}
    onClick={onClick}
    type={type}>
    <span
      className={classNames(innerClassName, {
        [`universal-${size}`]: !bold,
        [`universal-${size}--semibold`]: bold,
        ["universal-underline"]: underline,
      })}>
      {children}
    </span>
  </button>
);

AtomLinkButton.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  innerClassName: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large", "xlarge"]),
  testId: PropTypes.string,
  type: PropTypes.oneOf(["submit", "button", "reset"]),
  underline: PropTypes.bool,
};

export default AtomLinkButton;

export const { propTypes } = AtomLinkButton;
