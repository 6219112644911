import _ from "underscore";
import ActionTypes from "../actions/action-types.js";
var types, fn;

types = [ActionTypes.UPDATE_USER, ActionTypes.UPDATE_USER_FAIL, ActionTypes.UPDATE_USER_DONE];

fn = function (state, action) {
  var userData = _.extend({}, state.userData),
    userProfile = _.extend({}, state.userData.userProfile);

  switch (action.type) {
    case ActionTypes.UPDATE_USER:
      userProfile.isLoading = true;
      break;

    case ActionTypes.UPDATE_USER_DONE:
      userProfile.isLoading = false;
      userProfile.isSuccessfulUpdate = true;
      userProfile = _.extend(userProfile, action.payload);
      break;

    case ActionTypes.UPDATE_USER_FAIL:
      userProfile.isLoading = false;
      userProfile.isSuccessfulUpdate = false;
      userProfile.error = "Unable to update user at this time. Please try again.";
      break;
  }

  userData.userProfile = userProfile;

  return _.extend({}, state, { userData: userData });
};

export default {
  types: types,
  fn: fn,
};
