import PropTypes from "prop-types";
import { membershipStatePropType, membershipTiersPropType } from "components/propTypes";

const exported = {
  membershipState: membershipStatePropType,
  membershipTiers: membershipTiersPropType,
  submitPlanChange: PropTypes.func.isRequired,
  dismissModal: PropTypes.func,
  displayModal: PropTypes.func,
  membershipBag: PropTypes.object,
  getMembershipTiers: PropTypes.func.isRequired,
  oopsModal: PropTypes.func.isRequired,
  getChangePlanPreview: PropTypes.func,
  immediateUpgradePlanPreview: PropTypes.object,
  immediateUpgradePlanPreviewLoading: PropTypes.bool,
  immediateUpgradePlanPreviewFail: PropTypes.bool,
  fetchLegalText: PropTypes.func,
  immediateUpgradeLegalText: PropTypes.object,
  fetchMembershipBag: PropTypes.func,
};

export default exported;

export const {
  membershipState,
  membershipTiers,
  submitPlanChange,
  dismissModal,
  displayModal,
  membershipBag,
  getMembershipTiers,
  oopsModal,
  getChangePlanPreview,
  immediateUpgradePlanPreview,
  immediateUpgradePlanPreviewLoading,
  immediateUpgradePlanPreviewFail,
  fetchMembershipBag,
} = exported;
