import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import PopperJS from "popper.js";
import classNames from "classnames";
import { TOOLTIP } from "rtr-constants";
import { tooltipPlacementPropType } from "components/propTypes/tooltip-placement-prop-type";
import { hasTouchEventSupport } from "helpers/device-helpers";
import { htmlElementPropType } from "components/propTypes";

// https://popper.js.org/popper-documentation.html
export default class MoleculeTooltip extends React.Component {
  static defaultProps = {
    eventsEnabled: true,
    placement: TOOLTIP.PLACEMENTS.AUTO,
  };

  static propTypes = {
    arrowAlignment: PropTypes.string,
    boundaryElement: htmlElementPropType,
    children: PropTypes.node,
    className: PropTypes.string,
    closeOnOutsideClick: PropTypes.bool,
    closeOnScroll: PropTypes.bool,
    eventsEnabled: PropTypes.bool,
    fluid: PropTypes.bool,
    isOpen: PropTypes.bool,
    mode: PropTypes.string,
    // https://popper.js.org/popper-documentation.html#modifiers
    modifiers: PropTypes.object,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    onToggle: PropTypes.func,
    onUpdate: PropTypes.func,
    placement: tooltipPlacementPropType,
    target: htmlElementPropType,
    timeout: PropTypes.number,
  };

  state = {
    isOpen: this.props.isOpen || false,
  };

  node = null;
  portal = null;
  $arrow = React.createRef();
  popper = null;
  $tooltip = null;

  destroySelf() {
    this.removeEventListeners();
    clearTimeout(this.tooltipTimeout);
    document?.body.removeChild(this.node);

    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
  }

  arrowClassname() {
    return this.props.arrowAlignment
      ? `molecule-tooltip__arrow--${this.props.arrowAlignment}`
      : "molecule-tooltip__arrow";
  }

  createSelf() {
    this.node = document?.createElement("div");
    document?.body.appendChild(this.node);

    this.create();
    this.addEventListeners();
  }

  componentDidMount() {
    this.createSelf();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.target && this.props.target) {
      this.addEventListeners();
    }

    // This is for the rare case that the target the tooltip attaches to is switched out for another.
    // The update method is not foolproof enough to handle the target change & repositioning, so refresh the popper instance
    if (this.popper && prevProps.target && this.props.target && prevProps.target !== this.props.target) {
      this.destroySelf();
      this.createSelf();
    }

    if (!this.popper) {
      this.create();
    }

    if (this.$arrow.current) {
      this.$arrow.current.setAttribute("x-arrow", "");
    }

    if (prevState.isOpen && !this.state.isOpen) {
      clearTimeout(this.tooltipTimeout);
    }

    this.renderPortal();
    this.popper?.scheduleUpdate();
  }

  componentWillUnmount() {
    this.destroySelf();
  }

  toggle = () => {
    this.setState(
      prevState => ({ isOpen: !prevState.isOpen }),
      () => {
        const { onToggle } = this.props;
        if (onToggle && typeof onToggle === "function") {
          onToggle(this.state.isOpen);
        }
      }
    );
  };

  open = () => {
    if (this.state.isOpen) {
      return;
    }

    this.setState({ isOpen: true });
  };

  close = () => {
    if (!this.state.isOpen) {
      return;
    }

    this.setState({ isOpen: false });

    const { onClose } = this.props;
    if (onClose && typeof onClose === "function") {
      onClose();
    }
  };

  create() {
    this.createPopper();
    this.renderPortal();
  }

  addEventListeners() {
    const {
      closeOnScroll,
      closeOnOutsideClick,
      target,
      timeout,
      mode = TOOLTIP.MODE.CLICK,
      eventsEnabled,
    } = this.props;

    if (!target || !eventsEnabled) {
      return;
    }

    const supportsTouchEvents = hasTouchEventSupport();

    if (mode === TOOLTIP.MODE.HOVER && !supportsTouchEvents) {
      if (timeout) {
        // memory leak
        target.addEventListener("mouseenter", () => {
          if (!this.state.isOpen) {
            this.open();
            this.tooltipTimeout = setTimeout(this.close, timeout);
          }
        });
      } else {
        target.addEventListener("mouseenter", this.toggle);
        target.addEventListener("mouseleave", this.toggle);
      }
    } else if (mode === TOOLTIP.MODE.CLICK || (mode === TOOLTIP.MODE.HOVER && supportsTouchEvents)) {
      // If hover is passed for desktop, use click when page is opened on mobile
      if (timeout) {
        // memory leak
        target.addEventListener(TOOLTIP.MODE.CLICK, () => {
          if (this.state.isOpen) {
            this.close();
          } else {
            this.open();
            this.tooltipTimeout = setTimeout(this.close, timeout);
          }
        });
      } else {
        target.addEventListener(TOOLTIP.MODE.CLICK, this.toggle);
      }
    }

    if (closeOnOutsideClick) {
      // memory leak
      document?.addEventListener("click", e => this.hideOnClickOutside(e));
    }

    if (closeOnScroll) {
      window?.addEventListener("scroll", this.close);
      window?.addEventListener("wheel", this.close);
    }
  }

  hideOnClickOutside(event) {
    const { target } = this.props;
    if (target !== event.target) {
      this.close();
    }
  }

  removeEventListeners() {
    const { closeOnScroll, closeOnOutsideClick, target, mode = TOOLTIP.MODE.CLICK, eventsEnabled } = this.props;

    if (!target || !eventsEnabled) {
      return;
    }

    if (mode === TOOLTIP.MODE.HOVER) {
      target.removeEventListener("mouseenter", this.toggle);
      target.removeEventListener("mouseleave", this.toggle);
    } else {
      target.removeEventListener(mode, this.toggle);
    }

    if (closeOnOutsideClick) {
      document?.removeEventListener("click", this.hideOnClickOutside);
    }

    if (closeOnScroll) {
      window?.removeEventListener("scroll", this.close);
    }
  }

  //Sometimes a top or bottom positioned tooltip body should be left aligned to a container element boundary (boundaryElement)
  alignWithContainer() {
    const { placement = TOOLTIP.PLACEMENTS.AUTO, boundaryElement = null } = this.props;
    if (!boundaryElement) {
      return {
        boundariesElement: window?.document?.body,
      };
    }

    return {
      priority:
        placement.includes("auto") || placement.includes("top") || placement.includes("bottom")
          ? ["left", "right"]
          : ["left", "right", "top", "bottom"],
      padding: 0,
      boundariesElement: boundaryElement,
    };
  }

  createPopper() {
    const { target, modifiers, placement, eventsEnabled } = this.props;

    if (!target || !this.node) {
      return;
    }

    this.popper = new PopperJS(target, this.node, {
      placement,
      eventsEnabled,
      onCreate: this.onCreate,
      onUpdate: this.onUpdate,
      modifiers: {
        preventOverflow: {
          ...this.alignWithContainer(),
        },
        ...modifiers,
      },
    });

    this.popper.disableEventListeners();
  }

  onCreate = data => {
    const { onCreate: localOnCreate } = this.props;

    if (localOnCreate && typeof localOnCreate === "function") {
      localOnCreate(data);
    }
  };

  onUpdate = data => {
    const { onUpdate: localOnUpdate } = this.props;

    if (localOnUpdate && typeof localOnUpdate === "function") {
      localOnUpdate(data);
    }
  };

  renderPortal = () => {
    this.node.className = classNames("molecule-tooltip", {
      "molecule-tooltip--visible": this.state.isOpen,
      "molecule-tooltip--hidden": !this.state.isOpen,
      "molecule-tooltip--max-width": !this.props.fluid,
      "molecule-tooltip--horizontal-bounds": this.props.boundaryElement,
      [this.props.className]: this.props.className,
    });
    this.node.dataset.testId = `${this.props.className}-${this.state.isOpen ? "visible" : "hidden"}`;

    this.portal = ReactDOM.createPortal(this.renderBody(), this.node);
  };

  renderBody = () => {
    const { children } = this.props;

    return (
      <div className="molecule-tooltip__inner">
        {children}
        <div className={this.arrowClassname()} ref={this.$arrow} />
      </div>
    );
  };

  render() {
    return this.portal;
  }
}
