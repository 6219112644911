import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  addressCards: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export const AtomAddressCardsList = ({ addressCards }) => (
  <div
    data-test-id="address-list"
    className="molecule-payment-profile-select__cards manage-payment-profile-cards"
    role="list">
    {addressCards?.length > 0 && addressCards}
  </div>
);

AtomAddressCardsList.propTypes = propTypes;

export default AtomAddressCardsList;
