import React from "react";
import _ from "underscore";
import Shortlist from "./shortlist";
import ShortlistForm from "./shortlist-form";
import { analytics } from "rtr-constants";
import productRatingsActions from "actions/product-ratings-actions";
import shortlistsActions from "actions/shortlists-actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ShortlistList extends React.Component {
  static propTypes = {
    addStyleToShortlist: PropTypes.func.isRequired,
    favorites: PropTypes.arrayOf(PropTypes.string),
    fetchShortlists: PropTypes.func.isRequired,
    logModalPixel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    productId: PropTypes.string.isRequired,
    removeProductRating: PropTypes.func.isRequired,
    removeStyleFromShortlist: PropTypes.func.isRequired,
    shortlists: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ).isRequired,
  };

  state = {
    showForm: false,
  };

  hideShortlistCreateForm = event => {
    event.preventDefault();
    this.toggleForm(false);
  };

  // When the user deselects "All Favorites" in the Shortlist Selection Modal
  onClickFavoriteShortlist = () => {
    this.props.removeProductRating(this.props.productId);
    this.props.onClose();
  };

  toggleForm = showForm => {
    if (showForm) {
      this.props.logModalPixel("new");
    } else {
      this.props.logModalPixel("cancel");
    }

    this.setState({
      showForm: showForm,
    });
  };

  render() {
    if (this.state.showForm) {
      return (
        <ShortlistForm
          fetchShortlists={this.props.fetchShortlists}
          hideShortlistCreateForm={this.hideShortlistCreateForm}
          productId={this.props.productId}
          toggleForm={this.toggleForm}
          logPixel={() => {
            this.props.logModalPixel(analytics.HEARTS_ACTIONS.CREATE);
          }}
        />
      );
    }

    return (
      <div className="shortlists-container">
        <h3>Manage My Hearts</h3>
        <ul className="shortlists">
          {/* TODO: ensure shortlist are not still loading */}
          {_.map(this.props.shortlists, (shortlist, i) => {
            return (
              <Shortlist
                addStyleToShortlist={this.props.addStyleToShortlist}
                favorites={this.props.favorites}
                key={shortlist.id + i}
                onClickFavoriteShortlist={this.onClickFavoriteShortlist}
                productId={this.props.productId}
                removeStyleFromShortlist={this.props.removeStyleFromShortlist}
                shortlist={shortlist}
              />
            );
          })}
        </ul>
        <div className="shortlist-list-buttons">
          <button className="shortlist-toggle-form btn-secondary" onClick={_.partial(this.toggleForm, true)}>
            New
          </button>
          <button className="shortlist-form-submit btn" onClick={this.props.onClose}>
            Done
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  favorites: state.favorites,
});

const mapDispatchToProps = dispatch => {
  return {
    // TODO MADDIE UPDATE THIS TO MATCH
    favoriteProduct: styleName => {
      dispatch(productRatingsActions.favoriteProduct(styleName));
    },
    removeProductRating: styleName => {
      dispatch(productRatingsActions.removeProductRating(styleName));
    },
    addStyleToShortlist: (shortlistId, styleName, onSuccessCallback, onFailureCallback) => {
      return dispatch(
        shortlistsActions.addStyleToShortlist(shortlistId, styleName, onSuccessCallback, onFailureCallback)
      );
    },
    removeStyleFromShortlist: (shortlistId, styleName, onSuccessCallback, onFailureCallback) => {
      return dispatch(
        shortlistsActions.removeStyleFromShortlist(shortlistId, styleName, onSuccessCallback, onFailureCallback)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortlistList);
