import styles from "./styles.module.scss";
import Price from "components/source/grid/price";
import { showDecimal } from "helpers/price-helper";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export default function UpsellHeader({ bonusPrice }) {
  const isMobileViewport = useSelector(state => state.browser?.isMobileViewport);

  return (
    <>
      <h3 className={`${styles["head"]} h3`}>Want a Bonus Style?</h3>
      <p className={`${styles.flex} ${styles["sub-head"]} ${isMobileViewport ? "" : "h4"}`}>
        Add any style of equal value for &thinsp;
        <Price
          amount={bonusPrice}
          currencyCode={"USD"}
          currencyCodeClassName="hidden @[275px]/label:inline"
          showDecimal={showDecimal(bonusPrice)}
        />
      </p>
    </>
  );
}

UpsellHeader.propTypes = {
  bonusPrice: PropTypes.number.isRequired,
};
