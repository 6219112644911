import React from "react";
import RadioInputPair from "components/source/shared/radio-input-pair";
import PropTypes from "prop-types";
import { childrenPropType } from "components/propTypes";
import { onEnterPress } from "helpers/a11y-helper";

const className = "atom-radio-card";

export default class AtomRadioCard extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    children: childrenPropType,
    defaultChecked: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    optionalCardStyle: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  static className = className;

  render() {
    const checkedClassName = this.props.checked ? `${className}--checked` : "";

    return (
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      <div
        className={`${className} ${checkedClassName}`}
        onClick={this.props.onChange}
        onKeyDown={onEnterPress(() => this.props.onChange)}>
        <div className={`${className}__input`}>
          <RadioInputPair
            checked={this.props.checked}
            defaultChecked={this.props.defaultChecked}
            id={this.props.id}
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.onChange}
          />
        </div>
        <div className={`${className}__display`} style={this.props.optionalCardStyle}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
