import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $ from "clients/RawClient";

const initialState = {
  reviewsCoverPhotos: [],
};

export const fetchReviewsCoverPhoto = createAsyncThunk("reviewsCoverPhotos/fetchReviewsCoverPhoto", async params => {
  try {
    const productId = params.productId;
    delete params.productId;

    const reviewsCoverPhoto = await $.ajax({
      type: "GET",
      url: `/api/products/${productId}/cover-photos`,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      data: { ...params },
    });

    return Promise.resolve(reviewsCoverPhoto.moments);
  } catch (e) {
    return Promise.reject([]);
  }
});

export const reviewCoverPhotoSlice = createSlice({
  name: "reviewsCoverPhotos",
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchReviewsCoverPhoto.fulfilled, (state, action) => {
      state.reviewsCoverPhotos = action.payload;
    });
  },
});

/**
 * This value isn't used anywhere.
 * It's only defined because ReduxApp expects reducers to have a "types" property
 */
export const types = ["reviewsCoverPhotos/fetchReviewsCoverPhoto/fulfilled"];

export default {
  types: types,
  fn: reviewCoverPhotoSlice.reducer,
};
