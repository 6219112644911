const exported = {
  feedbackTypes: {
    wornInquiry: "wornInquiry",
    wornHappinessLevel: "wornHappinessLevel",
    itemCondition: "itemCondition",
    overallFit: "overallFit",
    armsShouldersFit: "armsShouldersFit",
    chestFit: "chestFit",
    buttFit: "buttFit",
    waistFit: "waistFit",
    lengthFit: "lengthFit",
    negativeFeedback: "negativeFeedback",
    otherIssues: "otherIssues",
    damagedIssues: "damagedIssues",
    rating: "rating",
  },

  wornInquiry: {
    DIDNT_WEAR_IT: "DIDNT_WEAR_IT",
    WORE_IT: "WORE_IT", // Remove this once we launch new survey flow
    WORE_IT_ONCE: "1",
    WORE_IT_TWICE: "2",
    WORE_IT_THREE: "3",
    WORE_IT_FOUR: "4",
    WORE_IT_FIVE_THRU_TEN: "5-10",
    WORE_IT_ELEVEN_THRU_FIFTEEN: "11-15",
    WORE_IT_SIXTEEN_THRU_TWENTY: "16-20",
    WORE_IT_TWENTY_PLUS: "20+",
  },

  itemCondition: {
    ITEM_CONDITION_LIKE_NEW: "ITEM_CONDITION_LIKE_NEW",
    ITEM_CONDITION_GENTLY_WORN: "ITEM_CONDITION_GENTLY_WORN",
    ITEM_CONDITION_POOR: "ITEM_CONDITION_POOR",
  },

  wornHappinessLevel: {
    DIDNT_LOVE_IT: "DIDNT_LOVE_IT",
    JUST_OK: "JUST_OK",
    LIKED_IT: "LIKED_IT",
    LOVED_IT: "LOVED_IT",
    NOT_FOR_ME: "NOT_FOR_ME",
  },

  overallFit: {
    BIG_OVERALL: "BIG_OVERALL",
    JUST_RIGHT_OVERALL: "JUST_RIGHT_OVERALL",
    SMALL_OVERALL: "SMALL_OVERALL",
  },

  armsShouldersFit: {
    BIG_ARMS_SHOULDERS: "BIG_ARMS_SHOULDERS",
    JUST_RIGHT_ARMS_SHOULDERS: "JUST_RIGHT_ARMS_SHOULDERS",
    SMALL_ARMS_SHOULDERS: "SMALL_ARMS_SHOULDERS",
  },

  chestFit: {
    BIG_CHEST: "BIG_CHEST",
    JUST_RIGHT_CHEST: "JUST_RIGHT_CHEST",
    SMALL_CHEST: "SMALL_CHEST",
  },

  buttFit: {
    BIG_BUTT: "BIG_BUTT",
    JUST_RIGHT_BUTT: "JUST_RIGHT_BUTT",
    SMALL_BUTT: "SMALL_BUTT",
  },

  waistFit: {
    BIG_WAIST: "BIG_WAIST",
    JUST_RIGHT_WAIST: "JUST_RIGHT_WAIST",
    SMALL_WAIST: "SMALL_WAIST",
  },

  lengthFit: {
    LONG_LENGTH: "LONG_LENGTH",
    JUST_RIGHT_LENGTH: "JUST_RIGHT_LENGTH",
    SHORT_LENGTH: "SHORT_LENGTH",
  },

  negativeFeedback: {
    DIDNT_FIT: "DIDNT_FIT",
    NOT_MY_STYLE: "NOT_MY_STYLE",
    NOT_FLATTERING: "NOT_FLATTERING",
    DIDNT_ARRIVE_ON_TIME: "DIDNT_ARRIVE_ON_TIME",
    WORE_SOMETHING_ELSE: "WORE_SOMETHING_ELSE",
    OTHER_ISSUES: "OTHER_ISSUES",
    TOO_BIG: "TOO_BIG",
    TOO_SMALL: "TOO_SMALL",
  },

  negativeFeedbackOptionsWithCopy: {
    DIDNT_FIT: "Didn\u2019t fit",
    NOT_AS_PICTURED: "Not as pictured",
    NOT_MY_STYLE: "Not my style",
    ORDERED_MULTIPLE_SIZES_OPTIONS: "Ordered multiple options",
    DIDNT_ARRIVE_ON_TIME: "Didn\u2019t arrive on time",
    WEATHER: "Weather",
    POOR_CONDITION: "Poor condition",
    OTHER_ISSUES: "Other",
  },

  qualityIssueOptionsWithCopy: {
    RIPPED_TORN: "Ripped",
    ODOR: "Scent",
    ITEM_STAINED: "Stained",
    PILLING: "Pilling",
    WRINKLED: "Wrinkled",
    SHRUNKEN: "Shrunken",
    STRETCHED: "Stretched",
    FADED: "Faded",
    ITEM_BROKEN_ZIPPER: "Broken zipper/closure",
    ITEM_DAMAGED_OTHER: "Other",
  },

  otherIssues: {
    ARRIVAL_TIME: "ARRIVAL_TIME",
    MISSING_PIECE: "MISSING_PIECE",
    WORN: "WORN",
    ODOR: "ODOR",
    ITEM_DAMAGED: "ITEM_DAMAGED",
    MATERIAL: "MATERIAL",
  },

  damagedIssues: {
    ITEM_BROKEN_ZIPPER: "ITEM_BROKEN_ZIPPER",
    ITEM_FABRIC_PULL: "ITEM_FABRIC_PULL",
    ITEM_STAINED: "ITEM_STAINED",
    ITEM_DAMAGED_OTHER: "ITEM_DAMAGED_OTHER",
  },

  additionalFeedbackIssue: {
    QUALITY_FEEDBACK: "QUALITY_FEEDBACK",
    FIT_FEEDBACK: "FIT_FEEDBACK",
    QUALITY_ISSUE: "QUALITY_ISSUE",
    FIT_ISSUE: "FIT_ISSUE",
  },

  status: {
    INITIALIZED: "INITIALIZED",
    DISMISSED: "DISMISSED",
    COMPLETE: "COMPLETE",
  },

  pages: {
    DIDNT_WEAR: "DIDNT_WEAR",
    FINAL: "FINAL",
    DIDNT_FIT: "DIDNT_FIT",
    QUALITY_ISSUES: "QUALITY_ISSUES",
    RATE_IT: "RATE_IT",
    REVIEW: "REVIEW",
    START: "START",
  },

  heapEventScreens: {
    DIDNT_WEAR: "HS - Didn’t Wear",
    FINAL: "HS - Success",
    DIDNT_FIT: "HS - Didn’t Fit",
    QUALITY_ISSUES: "HS - Damaged",
    RATE_IT: "HS - Rate It",
    REVIEW: "HS - Review",
    START: "HS - Start",
  },

  rating: {
    "2": "2",
    "4": "4",
    "6": "6",
    "8": "8",
    "10": "10",
  },
};

export const wornInquiryInputOptions = [
  {
    text: "1",
    value: "WORE_IT_ONCE",
  },
  {
    text: "2",
    value: "WORE_IT_TWICE",
  },
  {
    text: "3-4",
    value: "WORE_IT_THREE_THRU_FOUR",
  },
  {
    text: "5+",
    value: "WORE_IT_FIVE_PLUS",
  },
];

export const itemConditionInputOptions = [
  {
    text: "Like new",
    value: exported.itemCondition.ITEM_CONDITION_LIKE_NEW,
  },
  {
    text: "Gently worn",
    value: exported.itemCondition.ITEM_CONDITION_GENTLY_WORN,
  },
  {
    text: "Poor",
    value: exported.itemCondition.ITEM_CONDITION_POOR,
  },
];

export const overallFitFeedBackInputOptions = [
  {
    text: "Runs small",
    value: exported.overallFit.SMALL_OVERALL,
  },
  {
    text: "True to size",
    value: exported.overallFit.JUST_RIGHT_OVERALL,
  },
  {
    text: "Runs big",
    value: exported.overallFit.BIG_OVERALL,
  },
];

export const optionalFitFeedBackInputOptions = {
  lengthFit: {
    options: [
      {
        text: "Runs short",
        value: exported.lengthFit.SHORT_LENGTH,
      },
      {
        text: "Just right",
        value: exported.lengthFit.JUST_RIGHT_LENGTH,
      },
      {
        text: "Runs long",
        value: exported.lengthFit.LONG_LENGTH,
      },
    ],
    title: "Length",
  },

  armsShouldersFit: {
    options: [
      {
        text: "Runs small",
        value: exported.armsShouldersFit.SMALL_ARMS_SHOULDERS,
      },
      {
        text: "Just right",
        value: exported.armsShouldersFit.JUST_RIGHT_ARMS_SHOULDERS,
      },
      {
        text: "Runs big",
        value: exported.armsShouldersFit.BIG_ARMS_SHOULDERS,
      },
    ],
    title: "Arms/shoulders",
  },

  chestFit: {
    options: [
      {
        text: "Runs small",
        value: exported.chestFit.SMALL_CHEST,
      },
      {
        text: "Just right",
        value: exported.chestFit.JUST_RIGHT_CHEST,
      },
      {
        text: "Runs big",
        value: exported.chestFit.BIG_CHEST,
      },
    ],
    title: "Chest",
  },

  waistFit: {
    options: [
      {
        text: "Runs small",
        value: exported.waistFit.SMALL_WAIST,
      },
      {
        text: "Just right",
        value: exported.waistFit.JUST_RIGHT_WAIST,
      },
      {
        text: "Runs big",
        value: exported.waistFit.BIG_WAIST,
      },
    ],
    title: "Waist",
  },

  buttFit: {
    options: [
      {
        text: "Runs small",
        value: exported.buttFit.SMALL_BUTT,
      },
      {
        text: "Just right",
        value: exported.buttFit.JUST_RIGHT_BUTT,
      },
      {
        text: "Runs big",
        value: exported.buttFit.BIG_BUTT,
      },
    ],
    title: "Hips",
  },
};

export const happinessSurveyCTACopy = {
  SUBMIT_SURVEY_AND_CONTINUE: "Submit & Continue",
  SUBMIT_LAST_SURVEY: "Finish",
  NEXT_SCREEN: "Next",
  FINISH_HAPPINESS_SURVEY: "Done",
};

export default exported;

export const {
  additionalFeedbackIssue,
  armsShouldersFit,
  buttFit,
  chestFit,
  damagedIssues,
  feedbackTypes,
  heapEventScreens,
  itemCondition,
  lengthFit,
  negativeFeedback,
  negativeFeedbackOptionsWithCopy,
  qualityIssueOptionsWithCopy,
  otherIssues,
  overallFit,
  pages,
  status,
  waistFit,
  wornHappinessLevel,
  wornInquiry,
} = exported;
