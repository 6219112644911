/** Based on the `deparam` method here: https://github.com/cowboy/jquery-bbq/blob/master/jquery.ba-bbq.js
 * params (the first argument) is the query string to be converted
 * coerce (the second argument) is a boolean flag telling this function to convert values it encounters to the correct type
 * e.g. given string "a[id]=1234"
 * coerce true:
 * this function would return { a: 1234 }
 * coerce false:
 * this function would return { a: "1234" }
 * Made  the following changes:
 * - removed $.each in favour of forEach
 * - removed $.isArray in favour of Array.isArray
 * - renamed decode to decodeURIComponent
 * - disabled prettier/eslint to preserve original structure
 * - extracted coerce to it's own function
 */
// prettier-ignore
// eslint-disable
export function queryStringDecode(params, coerce) {
  var obj = {};

  // Iterate over all name=value pairs.
  params.replace(/\+/g, ' ').split('&').forEach(function (v) {
    var param = v.split('='),
      key = decodeURIComponent(param[0]),
      val,
      cur = obj,
      i = 0,

      // If key is more complex than 'foo', like 'a[]' or 'a[b][c]', split it
      // into its component parts.
      keys = key.split(']['),
      keys_last = keys.length - 1;

    // If the first keys part contains [ and the last ends with ], then []
    // are correctly balanced.
    if (/\[/.test(keys[0]) && /\]$/.test(keys[keys_last])) {
      // Remove the trailing ] from the last keys part.
      keys[keys_last] = keys[keys_last].replace(/\]$/, '');

      // Split first keys part into two parts on the [ and add them back onto
      // the beginning of the keys array.
      keys = keys.shift().split('[').concat(keys);

      keys_last = keys.length - 1;
    } else {
      // Basic 'foo' style key.
      keys_last = 0;
    }

    // Are we dealing with a name=value pair, or just a name?
    if (param.length === 2) {
      val = decodeURIComponent(param[1]);

      // Coerce values to their native data type
      // Stop coerce if zip code because it was removing the intial 0's by converting string to integer
      if (!key.includes('zip_code') && coerce) {
        val = coerceStringToValue(val);
      }

      if (keys_last) {
        // Complex key, build deep object structure based on a few rules:
        // * The 'cur' pointer starts at the object top-level.
        // * [] = array push (n is set to array length), [n] = array if n is
        //   numeric, otherwise object.
        // * If at the last keys part, set the value.
        // * For each keys part, if the current level is undefined create an
        //   object or array based on the type of the next keys part.
        // * Move the 'cur' pointer to the next level.
        // * Rinse & repeat.
        for (; i <= keys_last; i++) {
          key = keys[i] === '' ? cur.length : keys[i];
          cur = cur[key] = i < keys_last
            ? cur[key] || (keys[i + 1] && isNaN(keys[i + 1]) ? {} : [])
            : val;
        }

      } else {
        // Simple key, even simpler rules, since only scalars and shallow
        // arrays are allowed.

        if (Array.isArray(obj[key])) {
          // val is already an array, so push on the next value.
          obj[key].push(val);

        } else if (typeof obj[key] !== "undefined") {
          // val isn't an array, but since a second value has been specified,
          // convert val into an array.
          obj[key] = [obj[key], val];

        } else {
          // val is a scalar.
          obj[key] = val;
        }
      }

    } else if (key) {
      // No value was defined, so set something meaningful.
      obj[key] = coerce
        ? void 0
        : '';
    }
  });

  return obj;
}

/**
 * Coerces a string to it's corresponding value
 * @param val The string to coerce
 * @returns {number|undefined|*} The equivalent value for the given string
 */
// prettier-ignore
// eslint-disable
export function coerceStringToValue(val) {
  // CONCO-405: Remove boolean coercion
  // too risky to try and hand touch everywhere we do boolean lookups
  // so continue to return booleans as a string
  const coerce_types = { /*'true': !0, 'false': !1,*/ 'null': null };
  return val && !isNaN(val) ? +val              // number
    : val === 'undefined' ? void 0         // undefined
      : typeof coerce_types[val] !== "undefined" ? coerce_types[val] // true, false, null
        : val;                                                // string
}
