import _ from "underscore";
import ActionTypes from "actions/action-types.js";
import { hasUnheldItems } from "helpers/checkout-helpers";

export const types = [
  ActionTypes.ADD_PAGE_ERROR,
  ActionTypes.CLEAR_PAGE_ERROR,
  ActionTypes.REMOVE_ORDER_ITEM_SUCCESS,
  ActionTypes.REMOVE_ORDER_ITEM_SUBMITTING,
  ActionTypes.REMOVE_ORDER_ITEM_FAILURE,
  ActionTypes.REFRESH_HOLDS_FAILURE,
  ActionTypes.REFRESH_HOLDS_SUBMITTING,
  ActionTypes.REFRESH_HOLDS_SUCCESS,
];

export const fn = function (state, action) {
  const { pageErrors: { errorTypes = [], cmsEntries = {} } = {} } = state;
  const pageErrors = {
    errorTypes: [...errorTypes],
    cmsEntries: Object.assign({}, cmsEntries),
  };
  const previewInvoiceState = state?.previewInvoice ?? {};
  let previewInvoice = _.extend({}, previewInvoiceState);

  // hasRebookStatusChanges:
  //   informs Components whether an invoice contains Items that are not
  //   available for rent/purchase.
  let hasRebookStatusChanges = false;

  // invoiceLastUpdatedAt:
  //   setting hasRebookingStatusChanges will likely prompt the rebooking
  //   error modal. In order for a user to close it, and keep it closed
  //   on any subsequent re-render, we set the last updated at timestamp.
  //   the rebooking modal keeps an internal timestamp to know whether it
  //   should be presented to the user.
  let invoiceLastUpdatedAt = state.invoiceLastUpdatedAt;

  switch (action.type) {
    case ActionTypes.ADD_PAGE_ERROR: {
      const { key, message } = action.payload;

      pageErrors.errorTypes.push(key);
      pageErrors.cmsEntries[key] = message;

      break;
    }

    case ActionTypes.CLEAR_PAGE_ERROR: {
      const { key } = action.payload;

      pageErrors.errorTypes = _.without(pageErrors.errorTypes, key);
      delete pageErrors.cmsEntries[key];

      // remove the key
      break;
    }

    case ActionTypes.REMOVE_ORDER_ITEM_SUBMITTING:
      // TODO: Handle this in a future high-level
      // checkout reducer, because it will affect
      // the UI of the page.
      break;

    case ActionTypes.REMOVE_ORDER_ITEM_SUCCESS:
      // TODO: Handle this in a future high-level
      // checkout reducer, because it will affect
      // the UI of the page.
      break;

    case ActionTypes.REMOVE_ORDER_ITEM_FAILURE:
      // TODO: Handle this in a future high-level
      // checkout reducer, because it will affect
      // the UI of the page.
      break;

    case ActionTypes.REFRESH_HOLDS_SUBMITTING:
      // TODO: Handle this in a future high-level
      // checkout reducer, because it will affect
      // the UI of the page.
      break;

    case ActionTypes.REFRESH_HOLDS_SUCCESS:
      previewInvoice = action.payload?.previewInvoice;
      hasRebookStatusChanges = hasUnheldItems(previewInvoice);
      invoiceLastUpdatedAt = new Date();
      break;

    case ActionTypes.REFRESH_HOLDS_FAILURE:
      // I think this probably needs to be
      // under a higher-level checkout state key.
      break;

    default:
      break;
  }

  return _.extend({}, state, {
    pageErrors,
    previewInvoice,
    hasRebookStatusChanges,
    invoiceLastUpdatedAt,
  });
};

export default {
  types: types,
  fn: fn,
};
