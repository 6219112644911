import React from "react";
import SearchBar from "components/source/navigation/search-bar";

// NW [EXPLANATION] 1/21/20: this component wraps SearchBar. We render 2 instances of search inputs
// in the nav - one for the desktop nav, and one for mobile web nav. These instances must have different IDs
// from each other, even though most of the behavior is shared. This component encapsulates the
// mobile-specific ID and other mobile-specific configs.
class MobileSearchBar extends React.PureComponent {
  render() {
    return (
      <SearchBar
        analyticsObjectType="mobile_nav"
        inputId="search-bar-input--mobile"
        inputFocusHidesNav={false}
        formClassNameAppend="mobile"
      />
    );
  }
}

export default MobileSearchBar;
