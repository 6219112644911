import React from "react";
import PropTypes from "prop-types";
import { childrenPropType } from "components/propTypes";
import Modal from "components/source/shared/modal";
import ModalCloseButton from "./modal-close-button";
import sharedActions from "../../../actions/shared-actions";

class ClosableModal extends React.Component {
  static propTypes = {
    additionalStyles: PropTypes.object,
    children: childrenPropType,
    closeButtonColor: PropTypes.oneOf(["black", "white"]),
    dispatch: PropTypes.func,
    hasBlurredBackground: PropTypes.bool,
    isCloseHidden: PropTypes.bool,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    optionalClass: PropTypes.string,
    optionalWrapperClass: PropTypes.string,
    overlayClassName: PropTypes.string,
    onClickModalOverlay: PropTypes.func,
    isMobileViewport: PropTypes.bool,
  };

  static defaultProps = {
    isCloseHidden: false,
    optionalWrapperClass: "",
    hasBlurredBackground: false,
    isMobileViewport: false,
  };

  close = () => {
    const { dispatch, onRequestClose } = this.props;
    if (dispatch) {
      dispatch(sharedActions.displayModal(false));
    }
    if (onRequestClose && typeof onRequestClose === "function") {
      onRequestClose();
    }
  };

  /**
   * By default, thread through onRequestClose and use the same behavior as clicking the modal close button.
   * If a specific method for the overlay is desired, specify it as a separate prop.
   */
  onOverlayClick = () => {
    const { onRequestClose, onClickModalOverlay } = this.props;

    if (onClickModalOverlay && typeof onClickModalOverlay === "function") {
      onClickModalOverlay();
    } else {
      onRequestClose();
    }
  };

  render() {
    const {
      additionalStyles,
      children,
      closeButtonColor,
      hasBlurredBackground,
      isCloseHidden,
      isOpen,
      optionalClass,
      optionalWrapperClass,
      overlayClassName,
      ...otherProps
    } = this.props;
    return (
      <Modal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        additionalStyles={additionalStyles}
        ariaHideApp={false}
        className={optionalClass}
        hasBlurredBackground={hasBlurredBackground}
        isOpen={isOpen}
        onRequestClose={this.onOverlayClick}
        overlayClassName={overlayClassName}>
        <>
          {!isCloseHidden && <ModalCloseButton color={closeButtonColor} onClick={this.close} />}
          <div className={`modal-wrapper ${optionalWrapperClass}`}>{children}</div>
        </>
      </Modal>
    );
  }
}

export default ClosableModal;
