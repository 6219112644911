// from R.js + Log.js
import { isSSR } from "../helpers/client-server-helper";
import { fireWithUserData, getUserEmail, getUserId } from "../components/source/hoc/with-user-data";
import { isStorefrontNext } from "../helpers/environment-helpers";
import { initSd, jsSession, setTabId } from "./reload-tolerant-session";
import { createRTRAnalyticsCookies } from "./analytics-cookies";
import analyticsIntegrationPipes from "./analytics-integrations";
import snakeCaseConverter from "lodash.snakecase";

export const knownKeys = {
  userId: "uid",
  isRtr: "isRTR",
  isMasquerading: "masq",
  pageId: "pageId",
  storefrontPlatform: "storefrontPlatform",
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////  Horrible Mandatory code for consistency initialization  ///////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const keysToSnakeCase = [
  "objectType",
  "styleName",
  "numStyles",
  "numDisplay",
  "frameIndex",
  "objectId",
  "prodType",
  "numReviews",
  "priceMin",
  "priceMax",
  "retailPrice",
];

function snakeizeIfNecessary(key) {
  if (keysToSnakeCase.indexOf(key) !== -1) {
    return key.replace(/([A-Z])/g, function ($1) {
      return "_" + $1.toLowerCase();
    });
  }

  return key;
}

/**
 * Class responsible for holding and sending analytics data to our analytics integrations.
 * Provides an easy to use API (via singleton) that can be called from anywhere. It holds various bits of app-wide data (like current user id)
 * That it enriches analytics events with before transmitting them to the backend
 */
class AnalyticsClient {
  // holds state for global data
  #state = {};
  #initialized = false;

  constructor(baseState = {}) {
    if (isSSR()) {
      return;
    }

    this._initState(baseState);
    initSd();

    setTabId();
    createRTRAnalyticsCookies();

    this.#initialized = true;
  }

  /**
   * Init the state object sent with analytics events
   * @param baseState The state object to use as the base for other events
   */
  _initState(baseState) {
    if (this.#initialized) {
      throw new Error("AnalyticsClient is already initialized");
    }
    this.#state = Object.assign(this.#state, baseState);
    this.set(knownKeys.pageId, new Date().getTime() + "" + Math.round(Math.random() * 0x1000));
    this.set(knownKeys.storefrontPlatform, isStorefrontNext() ? "Next" : "Ruby");
  }

  /**
   * Should be used for setting global state to be transmitted with every analytics event
   * @param key The key used to identify this data
   * @param value The value of this data
   */
  set(key, value) {
    this.#state[key] = value;
  }

  get(key) {
    return key in this.#state ? this.#state[key] : null;
  }

  // convenience setters for common data
  setUserInformation(userData) {
    const userEmail = getUserEmail(userData) || "";
    this.set(knownKeys.userId, getUserId(userData));
    this.set(knownKeys.isRtr, userEmail.indexOf("renttherunway.com") !== -1);
  }

  setIsMasquerading(isMasquerading) {
    // consistency with R.js
    this.set(knownKeys.isMasquerading, isMasquerading ? true : 0);
  }

  /**
   * Log an analytics event
   * @param eventData The data specific to this analytics event
   */
  log(eventData) {
    // Send current URL, Tab/Window ID, Client timestamp (as sequence ID)
    // User Agent, Client IP, SID, and UID will be picked up server side
    let data = {
      objectType: "",
      action: "",
      url: window.location.pathname,
      seq: new Date().getTime(),
      tabId: jsSession("tabId"),
      uid: window.userId,
    };

    // this is a mess.
    // we call snakeizeIfNecessary to (maybe) snake_case a key (if it's in the keysToSnakeCase list)
    // then, if we have a value in the event for the snake_case format and not the original format of the key
    // we set the original key to have the value of the snake_case key
    // then delete the snake_case key
    // otherwise, just assign the key to the value
    // I kept this from the original implementation because there's just no way to neatly do this.
    const normalizedEventData = Object.entries(eventData).reduce((acc, [key, value]) => {
      const snakeCaseKey = snakeizeIfNecessary(key);
      if (!acc[key] && acc[snakeCaseKey]) {
        acc[key] = acc[snakeCaseKey];
        acc[snakeCaseKey] = void 0;
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    // the actual data sent is a layering of:
    // - base data defined above
    // - current state (globals like user id)
    // - the specific event's data
    Object.assign(data, this.#state, normalizedEventData);

    // filter out undefined values
    data = Object.entries(data).reduce((acc, [key, value]) => {
      if (typeof value !== "undefined" && value !== null) {
        acc[keysToSnakeCase.includes(key) ? snakeCaseConverter(key) : key] = value;
      }
      return acc;
    }, {});

    // send the data to each analytics integration
    analyticsIntegrationPipes.forEach(pipe => pipe(data));
  }
}

// NOTE: There used to be a `PdpInferLog` functionality in the old log.js
// it's sync method depended on there having been data previously stored in localStorage, in which case it would log that data to pixel
// however, the code to actually write the data to localStorage is in a method (save, on InferLog) that must be called explicitly
// the call was removed in this commit:
// https://github.com/RentTheRunway/storefront/commit/28aa23459c6b58cd0b67ef3d6e45a80f0a2940d1 (July 2014)
// meaning that even though the PdpInferLog was still invoked, it never did anything
// the same code that used to invoke PDPInferLog also calls dispatch(PageViewActions.log(ActionTypes.PDP_PAGE_VIEW));
// over in `pdp.jsx`, `logPageLoad()`. This seems to achieve the same functionality and is actually used
// see https://backbonejs.org/#Model-save for more on backbone models

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////  Singleton initialization  /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
let baseState = {};
// init logic on the client
if (!isSSR()) {
  baseState = {
    pageType: window.page_type,
  };
}
const analyticsClient = Object.freeze(new AnalyticsClient(baseState));

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////  Legacy initialization  //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// sigh. Pixels are emitted immediately after this script is imported for the first time, so need to grab user info if available.
// in future, we would need to replicate this by either:
// a: storing user info in localstorage on a login and reading it from there on any page load (how do we bust this?)
// b: buffering pixels until the call to retrieve user info comes back (probably better. if it fails or the user is not logged in send the pixels as anon)
function legacySetUserInfo() {
  // if we got here and the userProfile is not set, the user is not logged in / anonymous
  if (window.RTR?.UX?.hasUserData?.()) {
    analyticsClient.setUserInformation({
      userProfile: {
        basicUserProfile: window.RTR.UX.user.basicUserProfile,
        id: window.RTR.UX.user.basicUserProfile.id,
      },
    });
    analyticsClient.setIsMasquerading(!!window.RTR.UX.isMasquerading());
  }
}

// For Now...
if (!isStorefrontNext()) {
  if (!isSSR() && window.RTR?.UX?.user?.basicUserProfile) {
    legacySetUserInfo();
  }

  fireWithUserData({}, () => {
    legacySetUserInfo();
  });
}

export default analyticsClient;
