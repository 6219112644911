import React from "react";
import PropTypes from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { getLocationHref } from "helpers/location-helpers";

export class EnvironmentBanner extends React.Component {
  static environments = {
    DEVELOPMENT: "development",
    PRODUCTION: "production",
  };
  static hiddenBannerEnvironments = [
    EnvironmentBanner.environments.DEVELOPMENT,
    EnvironmentBanner.environments.PRODUCTION,
  ];
  static cookieName = "environment_banner";

  static propTypes = {
    appContextEnvironment: PropTypes.string,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
  };

  state = {
    closed: false,
  };

  componentDidMount() {
    if (this.props.cookies.get(this.constructor.cookieName)) {
      this.setState({
        closed: true,
      });
    }
  }

  getProductionUrl() {
    return getLocationHref().replace(/^https?:\/\/[^.]+\.renttherunway.com/, "https://www.renttherunway.com");
  }

  closeEnvironmentBanner = () => {
    this.setState({
      closed: true,
    });
    this.props.cookies.set(this.constructor.cookieName, "closed", { path: "/" });
  };

  render() {
    if (
      process.env.IS_SSR ||
      this.state.closed ||
      !this.props.appContextEnvironment ||
      this.constructor.hiddenBannerEnvironments.includes(this.props.appContextEnvironment)
    ) {
      return null;
    }

    return (
      <div id="environment-banner" className={this.props.appContextEnvironment}>
        <div className="environment-banner-content">
          You are currently on the {this.props.appContextEnvironment} version of Rent the Runway.
          <a className="standalone" href={this.getProductionUrl()} id="this-page-on-production">
            Go to production
          </a>
          <button className="close-promo" onClick={this.closeEnvironmentBanner}>
            ×
          </button>
        </div>
      </div>
    );
  }
}

export default withCookies(EnvironmentBanner);
