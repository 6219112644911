import React from "react";
import PropTypes from "prop-types";
import MoleculeInlinePositionedTooltip from "components/source/molecules/tooltip/molecule-inline-positioned-tooltip";
import { tooltipPlacementPropType } from "components/propTypes/tooltip-placement-prop-type";
import { refPropType } from "components/propTypes";

export class RatingEducationTooltip extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    dismissTooltip: PropTypes.func.isRequired,
    isDismissed: PropTypes.bool,
    isTriggered: PropTypes.bool,
    logTooltipView: PropTypes.func.isRequired,
    placement: tooltipPlacementPropType,
    target: refPropType,
    timeoutMilliseconds: PropTypes.number,
  };

  static defaultProps = {
    timeoutMilliseconds: 4000,
  };

  state = {
    hasSetTimeout: false, // NW [EXPLANATION] 5/31/19: flag to ensure that we only set a timeout ONCE per instance
  };

  componentDidUpdate() {
    /* NW [EXPLANATION] 5/29/19: if the tooltip will be shown, set a timeout for it to go away.
     * Why is this here? When the tooltip is triggered from the Product Drawer open, sometimes
     * `this.props.isTriggered` is already true when componentDidMount runs.
     * When the tooltip is triggered from first Heart action, `this.props.isTriggered` changes
     * after the component has mounted. This lifecycle hook seems to ensure that the timeout happens
     * in both cases. */
    if (!this.state.hasSetTimeout && this.tooltipWillShow()) {
      this.props.logTooltipView();
      setTimeout(() => {
        this.props.dismissTooltip();
      }, this.props.timeoutMilliseconds);
      this.setState({
        hasSetTimeout: true,
      });
    }
  }

  tooltipWillShow = () => {
    const { isTriggered, isDismissed } = this.props;

    return isTriggered && !isDismissed;
  };

  render() {
    if (!this.tooltipWillShow()) {
      return null;
    }

    return (
      <MoleculeInlinePositionedTooltip
        className="rating-education-tooltip-container"
        isClosedByOutsideClick={true}
        onClose={this.props.dismissTooltip}
        placement={this.props.placement}
        target={this.props.target}>
        {this.props.children}
      </MoleculeInlinePositionedTooltip>
    );
  }
}
