import { CachedServicesClient } from "./CachedServicesClient";
import { isSSR } from "../helpers/client-server-helper";
import { ProductImageSize } from "helpers/product-image-helpers";
import { SFLogger } from "logger/logger";

const logger = SFLogger("disco-search-service-client");

const DEFAULT_HEADERS = {
  "Accept": "application/json",
  "Content-Type": "application/json",
};

// based on the response shape from the API
const ERROR_TEMPLATE = {
  items: [],
  count: 0,
  filters: {},
  source: "error",
};

const discoHost = (() => {
  if (typeof process !== "undefined" && process.env) {
    return isSSR() ? process.env.CONFIG__DISCO__HOST : process.env.NEXT_PUBLIC_CONFIG__DISCO__HOST;
  } else {
    return "";
  }
})();

const version = "/v0";

export class DiscoSearchServiceClient extends CachedServicesClient {
  static CONFIG_HOST = discoHost;

  async clearanceSearch(query, page = 0, limit = 60) {
    const queryParams = new URLSearchParams(query);

    queryParams.set("itemOffset", (page > 1 ? page - 1 : 0) * limit);
    queryParams.set("itemLimit", limit);
    queryParams.set("sortOptions", query.sortOptions ? query.sortOptions.toUpperCase() : "NEWEST");

    if (!queryParams.has("availableOnly")) {
      queryParams.set("availableOnly", true);
    }

    return this.get(`${version}/clearance/products/search?${queryParams}`, null, {
      headers: DEFAULT_HEADERS,
      bypassCache: true,
    })
      .then(async response => {
        return JSON.parse(await response.text());
      })
      .catch(e => {
        this.reportError(e);
        return { ...ERROR_TEMPLATE, message: e.message };
      });
  }

  async curationSearch(query, page = 0, limit = 60, lens = null) {
    const queryParams = new URLSearchParams(query);

    queryParams.set("itemOffset", (page > 1 ? page - 1 : 0) * limit);
    queryParams.set("itemLimit", limit);
    queryParams.set("sortOptions", query.sortOptions ? query.sortOptions.toUpperCase() : "NEWEST");

    let prefix = "explore";
    if (lens === "rtrupdate" || lens === "unlimited") {
      prefix = "membership";
    }

    // Only need the first curation as a path param (below)
    queryParams.delete("curationId");

    const path = `${version}/${prefix}/products/curation/${query.curationId[0]}?${queryParams}`;

    if (isSSR()) {
      logger.info(`curationSearch, path=${path}`);
    }

    return this.get(path, null, {
      headers: DEFAULT_HEADERS,
      bypassCache: true,
    })
      .then(async response => {
        return JSON.parse(await response.text());
      })
      .catch(e => {
        this.reportError(e);
        return { ...ERROR_TEMPLATE, message: e.message };
      });
  }

  async designerSearch(query, page = 0, limit = 60, lens = null) {
    const queryParams = new URLSearchParams(query);

    queryParams.set("itemOffset", (page > 1 ? page - 1 : 0) * limit);
    queryParams.set("itemLimit", limit);
    queryParams.set("sortOptions", query.sortOptions ? query.sortOptions.toUpperCase() : "NEWEST");

    if (!queryParams.has("availableOnly")) {
      queryParams.set("availableOnly", true);
    }

    // Only need the first designer as a path param (below)
    queryParams.delete("designerId");

    // The designer endpoint doesn't support curations
    queryParams.delete("curationId");

    let prefix = "explore";
    if (lens === "rtrupdate" || lens === "unlimited") {
      prefix = "membership";
    }
    return this.get(`${version}/${prefix}/products/designer/${query.designerId[0]}?${queryParams}`, null, {
      headers: DEFAULT_HEADERS,
      bypassCache: true,
    })
      .then(async response => {
        return JSON.parse(await response.text());
      })
      .catch(e => {
        this.reportError(e);
        return { ...ERROR_TEMPLATE, message: e.message };
      });
  }

  async categorySearch(query, page = 0, limit = 60, lens) {
    const queryParams = new URLSearchParams(query);

    queryParams.set("itemOffset", (page > 1 ? page - 1 : 0) * limit);

    queryParams.set("itemLimit", limit);
    queryParams.set("sortOptions", query.sortOptions ? query.sortOptions.toUpperCase() : "NEWEST");

    if (!queryParams.has("availableOnly")) {
      queryParams.set("availableOnly", true);
    }

    queryParams.delete("category");

    let prefix = "explore";
    if (lens === "rtrupdate" || lens === "unlimited") {
      prefix = "membership";
    }

    return this.get(`${version}/${prefix}/products/category/${query.category[0]}?${queryParams}`, null, {
      headers: DEFAULT_HEADERS,
      bypassCache: true,
    })
      .then(async response => {
        return JSON.parse(await response.text());
      })
      .catch(e => {
        this.reportError(e);
        return { ...ERROR_TEMPLATE, message: e.message };
      });
  }

  async viewAllSearch(query, page = 0, limit = 60) {
    const queryParams = new URLSearchParams(query);

    queryParams.set("itemOffset", (page > 1 ? page - 1 : 0) * limit);
    queryParams.set("itemLimit", limit);
    queryParams.set("sortOptions", query.sortOptions ? query.sortOptions.toUpperCase() : "NEWEST");

    if (!queryParams.has("availableOnly")) {
      queryParams.set("availableOnly", true);
    }

    const rootCategories = ["WomensApparel", "WomensAccessories"];
    const categories = queryParams.get("category")?.split(",");

    /**
     * We only send the root categories if there are no subcategories
     * This is because if root categories are included the api will return EVERYTHING
     */
    const subCategories = categories?.filter(cat => !rootCategories.includes(cat));
    if (subCategories?.length > 0) {
      queryParams.set(
        "category",
        categories.filter(cat => !rootCategories.includes(cat))
      );
    }

    const path = `${version}/membership/products/search?${queryParams}`;

    if (isSSR()) {
      logger.info(`viewAllSearch, path=${path}`);
    }

    return this.get(path, null, {
      headers: DEFAULT_HEADERS,
      bypassCache: true,
    })
      .then(async response => {
        return JSON.parse(await response.text());
      })
      .catch(e => {
        this.reportError(e);
        return { ...ERROR_TEMPLATE, message: e.message };
      });
  }

  async typeaheadSearch(term) {
    const version = "/v1";
    const path = `${version}/typeahead?searchPrompt=${term}`;

    return this.get(path, null, {
      headers: DEFAULT_HEADERS,
      bypassCache: true,
    })
      .then(async response => {
        return JSON.parse(await response.text());
      })
      .catch(e => {
        window?.Sentry?.captureException(`Error getting typeahead search data: ${e}`);
        console.error(`Error getting typeahead search data`, e);
        return { ...ERROR_TEMPLATE, message: e.message };
      });
  }

  async productsDataByStyleNames(styleNames) {
    let productsData;
    try {
      const res = await this.get(
        `${version}/products`,
        {
          ids: styleNames.join(","),
        },
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      productsData = JSON.parse(await res.text());
    } catch (e) {
      this.reportError(e);
      throw new Error("Error getting product data from disco:", e);
    }

    // make sure images URLs have the x1080 paths
    productsData.items.forEach(product => {
      product.images = product.images.map(imageUrl => {
        return imageUrl.replace(ProductImageSize.x270.path, ProductImageSize.x1080.path);
      });
    });

    return productsData;
  }

  async getProductsDataByPdpUrl(url) {
    let productsData;
    try {
      const res = await this.get(
        `${version}/products`,
        {
          urlPath: url,
        },
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      productsData = JSON.parse(await res.text());
    } catch (e) {
      this.reportError(e);
      return {};
    }

    // make sure images URLs have the x1080 paths
    productsData?.items?.forEach(product => {
      product.images = product?.images.map(imageUrl => {
        return imageUrl?.replace(ProductImageSize.x270.path, ProductImageSize.x1080.path);
      });
    });

    return productsData;
  }

  async recommendationSearch(query, page = 0, limit = 60) {
    const queryParams = new URLSearchParams(query);
    queryParams.set("itemOffset", (page > 1 ? page - 1 : 0) * limit);
    queryParams.set("itemLimit", limit);
    queryParams.set("sortOptions", query.sortOptions ? query.sortOptions.toUpperCase() : "NEWEST");

    const path = `v0/recommendation/catalog/filter?${queryParams}`;

    return this.get(path, null, {
      headers: DEFAULT_HEADERS,
      bypassCache: true,
    })
      .then(async response => {
        return JSON.parse(await response.text());
      })
      .catch(e => {
        this.reportError(e);
        return { ...ERROR_TEMPLATE, message: e.message };
      });
  }

  reportError = error => {
    if (window?.Sentry && window?.Sentry?.captureException) {
      window.Sentry.captureException(error);
    } else {
      console.error(error);
    }
  };
}
