// NOTE: these are just a few of the breakpoints. Please refer to _responsive_mixins.scss for more comprehensive list.
const breakpoints = {
  MOBILE_AND_TABLET_BREAKPOINT: 768,
  DESKTOP_BREAKPOINT: 769,
};

// NOTE: These correspond to the newer breakpoints defined in design-system/layout/_responsive-breakpoint-mixins.scss
const responsiveBreakpoints = {
  MOBILE_MAX_WIDTH: 480,
  SMALL_TABLET_MIN_WIDTH: 481,
  SMALL_TABLET_MAX_WIDTH: 768,
  TABLET_MIN_WIDTH: 769,
  TABLET_MAX_WIDTH: 1024,
  DESKTOP_MIN_WIDTH: 1025,
  DESKTOP_MAX_WIDTH: 1512,
  LARGE_DESKTOP_MIN_WIDTH: 1513,
};

const mediaQueries = {
  MOBILE_AND_TABLET_BREAKPOINT: `(max-width: ${breakpoints.MOBILE_AND_TABLET_BREAKPOINT}px)`,
  DESKTOP_BREAKPOINT: `(min-width: ${breakpoints.DESKTOP_BREAKPOINT}px)`,
};

export default {
  breakpoints: breakpoints,
  responsiveBreakpoints: responsiveBreakpoints,
  mediaQueries: mediaQueries,
};
