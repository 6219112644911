// eslint-disable-next-line no-restricted-imports
import Script from "next/script";
import { useRouter } from "next/router";

const THIRD_PARTY_TREATMENTS = {
  /**
   * @param {Array<string>} list - the list of allowed third parties
   * @param {string} me - the current third party script name or id
   * */
  allowed_third_parties: (list, me) => list.includes(me),
  /**
   * @param {Array<string>} list - the list of blocked third parties
   * @param {string} me - the current third party script name or id
   * */
  blocked_third_parties: (list, me) => !list.includes(me),
};

export const ThirdPartyScript = props => {
  const { name, id } = props;

  const router = useRouter();

  if (!name && !id) {
    console.error("No id or name passed for a third part script. Please supply one or both.");
  }

  if (router?.query) {
    let allowed = true;
    Object.entries(THIRD_PARTY_TREATMENTS).forEach(([queryName, queryFn]) => {
      if (!router.query[queryName]) {
        return;
      }
      const queryParamValue = router.query[queryName].split(",");
      allowed = allowed && queryFn(queryParamValue, name || id);
    });

    if (!allowed) {
      return <div data-test-id={`disabled-third-party-script-${name || id}`} data-disabled-third-party={name || id} />;
    }
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Script {...props} />;
};

// ideally, we could re-use the props exposed by next/script here, but they're only available in TypeScript
ThirdPartyScript.propTypes = {
  name: props => {
    if (!props.name && !props.id) {
      return new Error(`One of 'name' or 'id' must be provided`);
    }
  },
  id: props => {
    if (!props.name && !props.id) {
      return new Error(`One of 'name' or 'id' must be provided`);
    }
  },
};
