import { currentUserIdReplacementToken } from "rtr-constants";

import BaseServicesClient from "./BaseServicesClient";
import { isSSR } from "../helpers/client-server-helper";
import GladlyClient from "./vendor/GladlyClient";

// this data is not currently provided by a Service, but likely will be in the
// future. For now, a SF.Next API handles the request
export default class AuthServiceClient extends BaseServicesClient {
  static CONFIG_HOST = "/api/auth";

  async getMe() {
    return this.getJson(currentUserIdReplacementToken);
  }

  async createHelpCenterIdentity(email = "") {
    if (isSSR()) {
      const GLADLY_CLIENT = new GladlyClient(process.env.GLADLY_API_BASE_URL);
      return GLADLY_CLIENT.createHelpCenterIdentity(email);
    }

    return this.getJson("/help/" + currentUserIdReplacementToken);
  }
}
