import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { useHighlight } from "components/source/hoc/with-highlight";

import HighlightedBackgroundOverlay from "./overlay";
import HighlightedToolTip from "./tooltip";
import HighlightedComponentStyles from "./styles.module.scss";

const propTypes = {
  children: PropTypes.element,
};

const HighlightedComponentWrapper = props => {
  const { toolTipStyles, toolTipArrowStyles } = useHighlight();
  const isMobile = useSelector(state => state.browser?.isMobileViewport);
  const highlightedComponentId = useSelector(state => state.highlightedComponent?.componentId);
  const highlightedToolTipContent = useSelector(state => state.highlightedComponent?.toolTipContent);
  const highlightedComponentShouldDisplayTooltip = useSelector(
    state => state.highlightedComponent?.shouldDisplayTooltip
  );

  return (
    <>
      <div
        disabled={true}
        data-test-id="highlighted-component-wrapper"
        className={HighlightedComponentStyles["highlighted-component-wrapper"]}>
        {props.children}
      </div>
      {highlightedToolTipContent && Object.keys(toolTipStyles).length > 0 && highlightedComponentShouldDisplayTooltip && (
        <HighlightedToolTip
          position={toolTipStyles}
          additionalContainerClassName={
            isMobile ? HighlightedComponentStyles["highlighted-component-tool-tip-container-mobile"] : ""
          }
          additionalBodyClassName={
            isMobile ? HighlightedComponentStyles["highlighted-component-tool-tip-body-mobile"] : ""
          }
          arrowStyles={toolTipArrowStyles}>
          {highlightedToolTipContent}
        </HighlightedToolTip>
      )}
      {highlightedComponentId && <HighlightedBackgroundOverlay />}
    </>
  );
};

HighlightedComponentWrapper.propTypes = propTypes;
export default HighlightedComponentWrapper;
