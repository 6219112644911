import _ from "underscore";
import $ from "clients/RawClient";
import ActionTypes from "actions/action-types.js";
import { createAction } from "redux-actions";

import { HappinessSurvey } from "routes";
import { happinessSurveyValues, reserveExchangeSource } from "rtr-constants";
import MembershipHelpers from "helpers/membership-helpers";
import { selectFeatureFlagEnabled } from "../selectors/featureFlagSelectors";
import { clearReviewPhotos } from "actions/review-photo-actions";

const actions = {
  fetchHappinessSurveysSuccess: createAction(ActionTypes.FETCH_HAPPINESS_SURVEYS_SUCCESS),
  fetchHappinessSurveysCompletedSuccess: createAction(ActionTypes.FETCH_HAPPINESS_SURVEYS_COMPLETED_SUCCESS),
  setItemInProgress: createAction(ActionTypes.HAPPINESS_SURVEY_SET_ITEM_IN_PROGRESS),
  surveyAddFeedback: createAction(ActionTypes.HAPPINESS_SURVEY_ADD_FEEDBACK),
  surveyRemoveFeedback: createAction(ActionTypes.HAPPINESS_SURVEY_REMOVE_FEEDBACK),
  surveyRemoveAllFeedback: createAction(ActionTypes.HAPPINESS_SURVEY_REMOVE_ALL_FEEDBACK),
  surveyAddComment: createAction(ActionTypes.HAPPINESS_SURVEY_ADD_COMMENT),
  surveyAddCaption: createAction(ActionTypes.HAPPINESS_SURVEY_ADD_CAPTION),
  surveyAddReviewContent: createAction(ActionTypes.HAPPINESS_SURVEY_ADD_REVIEW_CONTENT),
  surveyAddRating: createAction(ActionTypes.HAPPINESS_SURVEY_ADD_RATING),
  surveyCompleted: createAction(ActionTypes.HAPPINESS_SURVEY_COMPLETED),
  surveySetInfo: createAction(ActionTypes.SET_HAPPINESS_SURVEY_INFO),
  happinessSurveyFetched: createAction(ActionTypes.HAPPINESS_SURVEY_FETCH),
  getHappinessSurveysByStyleBegin: createAction(ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_BEGIN),
  getHappinessSurveysByStyleSuccess: createAction(ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_SUCCESS),
  getHappinessSurveysByStyleFailure: createAction(ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_FAILURE),

  setHappinessSurveyInfo: function (type, reserveGroupId) {
    return dispatch => {
      dispatch(actions.surveySetInfo({ type, reserveGroupId }));
    };
  },

  fetchCompletedHappinessSurveys: function () {
    return dispatch => {
      $.get(HappinessSurvey.completedSurvey).then(surveys => {
        dispatch(actions.fetchHappinessSurveysCompletedSuccess(surveys));
      });
    };
  },

  /**
   * Fetch happiness surveys
   * @param {function()} [onSuccess=()=>{}] - function to be called after ajax succeeds
   * @param {boolean()} [reserve=false] - boolean to determine if we are fetching reserve happiness surveys
   * @param {boolean()} [checkout=false] - boolean to determine if we are fetching the happiness survey right after checkout
   * @returns {function()} - returns a wrapper around `dispatch` and the fetch
   */
  fetchHappinessSurveys: function (onSuccess = _.noop, reserve = false, checkout = false) {
    return function (dispatch, getState) {
      const state = getState();
      const membershipState = MembershipHelpers.getMembershipState(state);
      const selfServiceExchangeFFEnabled = selectFeatureFlagEnabled("self_service_exchange")(state);
      // When we are not fetching the happiness survey from checkout then we want to
      // prevent the happiness survey from disrupting their swap experience.
      const shouldNotFetchHappinessSurveyForSwapFlow = !checkout && MembershipHelpers.inSwapFlow(membershipState);
      const isReserveSurvey = selfServiceExchangeFFEnabled && reserve;

      if (shouldNotFetchHappinessSurveyForSwapFlow && !isReserveSurvey) {
        return;
      }

      $.get(HappinessSurvey.survey).then(function (surveys) {
        if (isReserveSurvey && _.isEmpty(surveys)) {
          dispatch(actions.fetchCompletedHappinessSurveys());
          return;
        }
        let dispatchedSurveys = surveys;
        if (!isReserveSurvey) {
          dispatchedSurveys = _.filter(surveys, survey => survey?.source !== reserveExchangeSource);
        } else {
          dispatchedSurveys = _.filter(surveys, survey => survey?.source === reserveExchangeSource);
        }
        dispatch(actions.fetchHappinessSurveysSuccess(dispatchedSurveys));
        // COMETS-4719
        if (isReserveSurvey) {
          const feedbacksToSubmit = _.filter(surveys, survey => _.isEmpty(survey?.surveyFeedbacks ?? []));
          _.each(feedbacksToSubmit, survey => {
            dispatch(actions.addFeedback(happinessSurveyValues.wornInquiry.DIDNT_WEAR_IT, survey.bookingId));
          });
        }
        onSuccess();
      });
    };
  },

  addFeedback: function (feedback, bookingId, exclusiveFeedbackTypeOption, feedbackValuesMap) {
    return function (dispatch) {
      dispatch(
        actions.surveyAddFeedback({
          feedback: feedback,
          bookingId: bookingId,
          exclusiveFeedbackTypeOption: exclusiveFeedbackTypeOption,
          feedbackValuesMap: feedbackValuesMap,
        })
      );
    };
  },

  removeFeedback: function (feedback, bookingId) {
    return function (dispatch) {
      dispatch(
        actions.surveyRemoveFeedback({
          feedback: feedback,
          bookingId: bookingId,
        })
      );
    };
  },

  removeAllFeedback: function (bookingId) {
    return function (dispatch) {
      dispatch(actions.surveyRemoveAllFeedback(bookingId));
    };
  },

  addSurveyComment: function (comment, bookingId) {
    return function (dispatch) {
      dispatch(
        actions.surveyAddComment({
          comment: comment,
          bookingId: bookingId,
        })
      );
    };
  },

  addSurveyCaption: function (caption, bookingId) {
    return function (dispatch) {
      dispatch(
        actions.surveyAddCaption({
          caption: caption,
          bookingId: bookingId,
        })
      );
    };
  },

  addSurveyReviewContent: function (reviewContent, bookingId) {
    return function (dispatch) {
      dispatch(
        actions.surveyAddReviewContent({
          reviewContent: reviewContent,
          bookingId: bookingId,
        })
      );
    };
  },

  addSurveyRating: function (rating, bookingId) {
    return function (dispatch) {
      dispatch(
        actions.surveyAddRating({
          rating: rating,
          bookingId: bookingId,
        })
      );
    };
  },

  completeSurvey: function (bookingId, onSuccess = _.noop(), options = {}) {
    let url = HappinessSurvey.survey;
    if (options.createReview === false) {
      url = `${url}?${new URLSearchParams({ createReview: false })}`;
    }

    return function (dispatch, getState) {
      const { happinessSurvey, reviewPhotos = [] } = getState();
      const surveys = happinessSurvey.itemSurveys || [];
      const photoIds = reviewPhotos.length > 0 ? reviewPhotos.map(photo => photo.photoId) : [];
      const currentSurvey = _.find(surveys, function (survey) {
        return survey.bookingId === bookingId;
      });
      const completedSurvey = {
        id: currentSurvey.id,
        bookingId: currentSurvey.bookingId,
        userId: currentSurvey.userId,
        status: happinessSurveyValues.status.COMPLETE,
        comment: currentSurvey.comment,
        caption: currentSurvey.caption,
        rating: currentSurvey.rating,
        photoIds,
        reviewContent: currentSurvey.reviewContent,
        surveyFeedbacks: currentSurvey.surveyFeedbacks,
        source: currentSurvey.source,
        version: currentSurvey.version,
      };

      $.patch({
        url: url,
        data: JSON.stringify(completedSurvey),
      }).then(function () {
        dispatch(
          actions.surveyCompleted({
            bookingId: bookingId,
          })
        );
        dispatch(clearReviewPhotos());
        onSuccess(getState, completedSurvey);
      });
    };
  },

  initializeHappinessSurvey: function (items, onSuccess = _.noop()) {
    $.post({
      url: HappinessSurvey.survey,
      data: JSON.stringify({
        items: items,
      }),
    }).then(function () {
      onSuccess();
    });
  },

  getHappinessSurveysByStyle: function (styleName, userId) {
    return function (dispatch) {
      if (!userId) {
        return;
      }

      dispatch(actions.getHappinessSurveysByStyleBegin());

      $.get(HappinessSurvey.surveyByStyle + styleName).then(
        data => {
          dispatch(actions.getHappinessSurveysByStyleSuccess(data));
        },
        error => {
          dispatch(actions.getHappinessSurveysByStyleFailure(error));
        }
      );
    };
  },
};

export default actions;

export const {
  fetchHappinessSurveysSuccess,
  fetchHappinessSurveysCompletedSuccess,
  setItemInProgress,
  surveyAddFeedback,
  surveyRemoveFeedback,
  surveyAddComment,
  surveyCompleted,
  surveySetInfo,
  happinessSurveyFetched,
  getHappinessSurveysByStyleBegin,
  getHappinessSurveysByStyleSuccess,
  getHappinessSurveysByStyleFailure,
  setHappinessSurveyInfo,
  fetchCompletedHappinessSurveys,
  fetchHappinessSurveys,
  addFeedback,
  removeFeedback,
  removeAllFeedback,
  addSurveyCaption,
  addSurveyComment,
  addSurveyRating,
  addSurveyReviewContent,
  completeSurvey,
  initializeHappinessSurvey,
  getHappinessSurveysByStyle,
} = actions;
