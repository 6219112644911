import { lsSetKeyValue, lsValueForKey } from "../or/application-helpers";

const HALF_HOUR_IN_MILLISECONDS = 30 * 60 * 1000;
const REVIEW_SUMMARY_LS_KEY = "revdata";

export function getUserReviewSummaryFromLocalStorage(uid) {
  const lsVal = lsValueForKey(REVIEW_SUMMARY_LS_KEY);

  if (!lsVal) return false;

  let lsObj;

  try {
    lsObj = JSON.parse(lsVal);

    if (
      typeof lsObj !== "object" ||
      lsObj.uid !== uid ||
      new Date().getTime() - lsObj.timestamp > HALF_HOUR_IN_MILLISECONDS
    ) {
      return false;
    }

    return lsObj;
  } catch {
    return false;
  }
}

export function saveUserReviewSummaryToLocalStorage(data, uid) {
  const dataToStore = { ...data, timestamp: new Date().getTime(), uid };
  lsSetKeyValue(REVIEW_SUMMARY_LS_KEY, JSON.stringify(dataToStore));
}
