import ActionTypes from "actions/action-types";
import _ from "underscore";

const initialSettings = {
  reviews: {},
  workingReviewsFilters: {},
  reviewsCoverPhotos: [],
};

function reviewsWithNewComment(existingReviews, payload) {
  const { reviewId } = payload;
  const review = existingReviews.find(r => r.moment?.reviewId === reviewId);
  const reviewIndex = _.indexOf(existingReviews, review);
  const newReview = { moment: { reviewId }, ...existingReviews[reviewIndex] };

  if (!newReview.moment.aggregateComments) {
    newReview.moment.aggregateComments = [];
  }

  const newComment = _.last(payload?.comment?.moment?.aggregateComments);

  const aggregateComments = newComment
    ? [...newReview.moment.aggregateComments, newComment]
    : newReview.moment.aggregateComments;

  newReview.moment = { ...newReview.moment, aggregateComments };

  return [].concat(_.first(existingReviews, reviewIndex), [newReview], _.rest(existingReviews, reviewIndex + 1));
}

function reviewUploadState(existingReviewUploadState, payload) {
  return {
    ...existingReviewUploadState,
    ...payload,
  };
}

const types = [
  ActionTypes.COMMENT_SUBMIT_SUCCESS,
  ActionTypes.UPDATE_WORKING_REVIEWS_FILTERS,
  ActionTypes.REVIEW_UPLOAD_CLEAR_PHOTOS,
  ActionTypes.REVIEW_UPLOAD_CLEAR_STATE,
  ActionTypes.CLEAR_REVIEWS_COVER_PHOTOS,
  ActionTypes.REVIEW_UPLOAD_DATA_LOADING,
  ActionTypes.REVIEW_UPLOAD_GET_PRODUCT_SUCCESS,
  ActionTypes.REVIEW_UPLOAD_GET_PENDING_REVIEW_SUCCESS,
  ActionTypes.REVIEW_UPLOAD_GET_RENTED_SIZES_SUCCESS,
  ActionTypes.REVIEW_UPLOAD_POPUP_SOURCE,
  ActionTypes.REVIEW_UPLOAD_SUBMIT_ERROR,
  ActionTypes.REVIEW_UPLOAD_SUBMIT_LOADING,
  ActionTypes.REVIEW_UPLOAD_SUBMIT_SUCCESS,
  ActionTypes.OPEN_SPECIFIC_REVIEW_PHOTO,
  ActionTypes.REVIEW_NUDGE_MODAL_DATA,
];

// eslint-disable-next-line
const fn = function (state = initialSettings, action) {
  // NOSONAR
  if (action.type === ActionTypes.UPDATE_WORKING_REVIEWS_FILTERS) {
    return {
      ...state,
      workingReviewsFilters: {
        ...state.workingReviewsFilters,
        ...action.payload,
      },
    };
  }

  if (action.type === ActionTypes.COMMENT_SUBMIT_SUCCESS) {
    const reviewData = reviewsWithNewComment(state.reviews?.data || [], action.payload);

    return { ...state, reviews: { ...state.reviews, data: reviewData } };
  }

  if (action.type === ActionTypes.CLEAR_REVIEWS_COVER_PHOTOS) {
    return {
      ...state,
      reviewsCoverPhotos: null,
    };
  }

  if (action.type === ActionTypes.REVIEW_UPLOAD_DATA_LOADING) {
    return {
      ...state,
      reviewUploadState: reviewUploadState(state.reviewUploadState || {}, {
        loading: action.payload,
      }),
    };
  }

  if (action.type === ActionTypes.OPEN_SPECIFIC_REVIEW_PHOTO) {
    return {
      ...state,
      openSpecificReviewPhoto: action.payload,
    };
  }

  if (action.type === ActionTypes.REVIEW_NUDGE_MODAL_DATA) {
    return {
      ...state,
      reviewNudgeModal: action.payload,
    };
  }

  if (action.type === ActionTypes.REVIEW_UPLOAD_GET_PRODUCT_SUCCESS) {
    let singleProduct;
    if (action.payload?.length) {
      [singleProduct] = action.payload;
    }

    return {
      ...state,
      reviewUploadState: reviewUploadState(state.reviewUploadState || {}, {
        product: singleProduct,
      }),
    };
  }

  if (action.type === ActionTypes.REVIEW_UPLOAD_GET_PENDING_REVIEW_SUCCESS) {
    const currentReviewPhotos = state.reviewUploadState?.reviewPhotos || [];
    const pendingReviewPhotos = action.payload?.photos || [];

    return {
      ...state,
      reviewUploadState: reviewUploadState(state.reviewUploadState || {}, {
        pendingReview: action.payload,
      }),
      reviewPhotos: currentReviewPhotos.concat(
        pendingReviewPhotos.map(photo => {
          return {
            photoId: photo.photoId,
            photoUrl: photo.urls?.imageOriginal,
          };
        })
      ),
    };
  }

  if (action.type === ActionTypes.REVIEW_UPLOAD_CLEAR_PHOTOS) {
    return {
      ...state,
      reviewPhotos: [],
    };
  }

  if (action.type === ActionTypes.REVIEW_UPLOAD_POPUP_SOURCE) {
    return {
      ...state,
      reviewUploadState: reviewUploadState(state.reviewUploadState || {}, {
        popupSource: action.payload,
      }),
    };
  }

  if (action.type === ActionTypes.REVIEW_UPLOAD_GET_RENTED_SIZES_SUCCESS) {
    return {
      ...state,
      reviewUploadState: reviewUploadState(state.reviewUploadState || {}, {
        rentedSizes: action.payload,
      }),
    };
  }

  if (action.type === ActionTypes.REVIEW_UPLOAD_CLEAR_STATE) {
    return {
      ...state,
      reviewUploadState: {},
    };
  }

  if (action.type === ActionTypes.REVIEW_UPLOAD_SUBMIT_LOADING) {
    return {
      ...state,
      reviewUploadState: reviewUploadState(state.reviewUploadState || {}, {
        isSubmitting: action.payload,
      }),
    };
  }

  if (action.type === ActionTypes.REVIEW_UPLOAD_SUBMIT_ERROR) {
    return {
      ...state,
      reviewUploadState: reviewUploadState(state.reviewUploadState || {}, {
        errorMessage: action.payload,
      }),
    };
  }

  if (action.type === ActionTypes.REVIEW_UPLOAD_SUBMIT_SUCCESS) {
    return {
      ...state,
      reviewUploadState: reviewUploadState(state.reviewUploadState || {}, {
        pendingReview: action.payload,
      }),
    };
  }

  return state;
};

export default {
  types: types,
  fn: fn,
};
