const COOKIES = {
  ALWAYS_SHOW_HAPPINESS_SURVEY: "show_hs",
  ANONYMOUS_USER_REFERENCE: "anon_checkout",
  CAS_V1_ACCESS_TOKEN: "cas_v1_token",
  CAS_V1_ENABLED: "cas_v1_enabled",
  CRM_DATA: "crm_data",
  EUWLI: "euwli",
  LAST_LOGIN_METHOD: "lastLoginMethod",
  LIMITED_ACCOUNT_COMPLETION: "limited-account-completion",
  LOG_AUTHN_COMPLETED: "log_authentication_completed",
  NEXT_AUTH_SESSION_TOKEN: "next-auth.session-token",
  MEMBERSHIP: "membership",
  MEMBERSHIP_LENS: "membership_lens",
  REFERRAL_CODE: "referral_code",
  RTR_CASTLE_REQUEST_TOKEN: "rtr_castle_request_token",
  RTR_ID: "RTR_ID",
  RTR_LOGGED_IN: "rtrLoggedIn",
  RTR_SESSION: "RTR_SESS",
  SEG: "seg",
  SEG_REFRESH: "seg_refresh",
  SECURE_NEXT_AUTH_SESSION_TOKEN: "__Secure-next-auth.session-token",
  SHARE_ID: "share_id",
};

export default COOKIES;
