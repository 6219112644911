import { Component } from "react";
import PropTypes from "prop-types";
import { childrenPropType, rtrAddressPropType } from "components/propTypes";
import { fnComponentClassName } from "helpers/component-helpers";

const DEFAULT_CLASS_NAME = "atom-address";

// there is some craziness to the addresses we support, so these helpers
// should bring some sanity to the equation
export function getName(address) {
  if (!address) {
    return;
  }

  const { name, company, firstName, lastName } = address;

  return name || company || `${firstName} ${lastName}`.trim();
}

export function getState(address) {
  if (!address) {
    return;
  }

  const { state, zoneCode } = address;

  return state || zoneCode;
}

export function getPostalCode(address) {
  if (!address) {
    return;
  }

  const { zipcode, zipCode, postalCode } = address;

  return postalCode || zipCode || zipcode;
}

// the propTypes are a bit of a mess here. I opted to add the complexity
// to the class (instead of some helper function) since _every_ time we
// use this component we would have to pass the props through that method
export default class AtomAddress extends Component {
  static propTypes = {
    address: rtrAddressPropType.isRequired,
    children: childrenPropType,
    className: PropTypes.string,
  };

  render() {
    const { address, className = DEFAULT_CLASS_NAME, children } = this.props;

    const fnClassName = fnComponentClassName(className);

    if (!address) {
      return null;
    }

    const { street1, street2, city } = address;

    const name = getName(address);
    const state = getState(address);
    const postalCode = getPostalCode(address);

    return (
      <div className={fnClassName("info")} data-heap-redact-text="true">
        <div className={fnClassName("name")}>{name}</div>
        <div className={fnClassName("street1")}>{street1}</div>
        <div className={fnClassName("street2")}>{street2}</div>
        <div className={fnClassName("city-state-zip")}>{`${city}, ${state} ${postalCode}`}</div>
        {children}
      </div>
    );
  }
}
