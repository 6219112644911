import filterDisplayNameMap from "constants/filterDisplayNameMap";
import { convertCamelCaseToSentenceCase } from "./seo-helpers/grid-title-meta-helper";

// Temporary measure. CSI are proposing API solitions to make this more dynamic
export const categoryMap = {
  "/clearance/products/dress": ["Dress"],
  "/clearance/products/dress/full": ["Floor"],
  "/clearance/products/clothing": ["WomensApparel"],
  "/clearance/products/clothing/dress/gown": ["Gowns"],
  "/clearance/products/clothing/top": ["Tops"],
  "/clearance/products/clothing/bottom": ["Bottoms"],
  "/clearance/products/clothing/jumpsuit_romper": ["JumpsuitsAndRompers"],
  "/clearance/products/clothing/jumpsuit": ["Jumpsuits"],
  "/clearance/products/clothing/jacket_coat": ["Jackets"],
  "/clearance/products/clothing/jacket": ["Jackets"],
  "/clearance/products/clothing/knit": ["Knits"],
  "/clearance/products/dress/sheath": ["Sheath"],
  "/clearance/products/clothing/bottom/pants": ["Pants"],
  "/clearance/products/clothing/top/blouse": ["Blouse"],
  "/clearance/products/clothing/bottom/skirt": ["Skirts"],
  "/clearance/products/clothing/bottom/skirt/mini": ["Mini"],
  "/clearance/products/accessory": ["WomensAccessories"],
  "/clearance/products/accessory/handbag": ["Handbags"],
  "/clearance/products/accessory/jewelry": ["Jewelry"],
  "/clearance/products/accessory/jewelry/earring/pierced": ["Earrings"],
  "/clearance/products/clothing/activewear": ["Active"],
  "/products/accessory/jewelry/earring/pierced": ["Earrings"],
  "/products/accessory/jewelry/earring/clip_on": ["Earrings"],
  "/products/accessory/jewelry/earring": ["Earrings"],
  "/products/accessory/handbag/crossbody_bag": ["Crossbody"],
  "/products/accessory/handbag": ["Handbags"],
  "/products/accessory/hat": ["Hats"],
  "/products/accessory/jewelry/bracelet": ["Bracelets"],
  "/products/accessory/jewelry/bridal_accessory": ["BridalAccessory"],
  "/products/accessory/jewelry/bridal_accessory/veil": ["Veil"],
  "/products/accessory/jewelry/necklace": ["Necklaces"],
  "/products/accessory/jewelry/ring": ["Rings"],
  "/products/accessory/jewelry/watch": ["Watches"],
  "/products/accessory/jewelry": ["Jewelry"],
  "/products/accessory/scarf": ["ScarvesWraps"],
  "/products/accessory/sunglasses": ["Sunglasses"],
  "/products/clothing/activewear": ["Active"],
  "/products/clothing/activewear/outfits": ["Active"],
  "/products/clothing/dress": ["Dress"],
  "/products/clothing/jacket_coat/jacket": ["Jackets"],
  "/products/clothing/jacket_coat/blazer": ["Blazer"],
  "/products/clothing/jacket_coat/cape": ["cape"],
  "/products/clothing/jacket_coat/fur_coat": ["fauxFur_1"],
  "/products/clothing/jacket_coat/wool_coat": ["Wool"],
  "/products/clothing/jacket_coat/vest": ["Vest"],
  "/products/clothing/jacket_coat": ["Jackets"],
  "/products/clothing/jeans": ["Jeans"],
  "/products/clothing/jumpsuit_romper/jumpsuit": ["Jumpsuits"],
  "/products/clothing/jumpsuit_romper/romper": ["Rompers"],
  "/products/clothing/jumpsuit_romper": ["JumpsuitsAndRompers"],
  "/products/clothing/knit": ["Knits"],
  "/products/clothing/bottom/pants": ["Pants"],
  "/products/clothing/bottom/pants/trousers": ["Trouser"],
  "/products/clothing/bottom/skirt/fit_flare": ["FullSkirt"],
  "/products/clothing/bottom/skirt/leather": ["LeatherSkirt"],
  "/products/clothing/bottom/skirt/maxi_skirt": ["Maxi"],
  "/products/clothing/bottom/skirt/midi": ["Midi"],
  "/products/clothing/bottom/skirt/mini": ["Mini"],
  "/products/clothing/bottom/skirt/pencil": ["Pencil"],
  "/products/clothing/bottom/skirt": ["Skirts"],
  "/products/clothing/bottom": ["Bottoms"],
  "/products/clothing/top": ["Tops"],
  "/products/clothing/top/blouse": ["Blouse"],
  "/products/clothing/top/tunic": ["Tunic"],
  "/products/clothing/top/cropped": ["CroppedTop"],
  "/products/clothing/top/tank": ["TankTop"],
  "/products/clothing/top/t_shirt": ["TShirt"],
  "/products/clothing/trousers": ["Trouser"],
};

// Filters to apply based on categories

// Are categories always derived from the path?
export function extractCategoriesFromUrl(url) {
  const path = url.split("?")[0];

  // Catch all cases for Accessory and Apparel routes
  if (path === "/products/all") {
    return ["WomensApparel", "WomensAccessories"];
  }

  if (path === "/products/clothing") {
    return ["WomensApparel"];
  }

  if (path === "/products/accessory") {
    return ["WomensAccessories"];
  }

  return categoryMap[path] || [];
}

/**
 * function to find the first path in categoryMap matching the given category name
 * @param {string} categoryName - The category name to search for.
 * @returns {string|null} - The first matching path, or null if not found.
 */
export function getCategoryPath(categoryName) {
  for (const [path, categories] of Object.entries(categoryMap)) {
    if (categories.includes(categoryName) && !path.includes("clearance")) {
      return path;
    }
  }

  // When there is no corresponding grid for a category redirect to a view all grid
  return null;
}
// function to create breadcrumbs for all PDPs except Clearance
export function createBreadCrumb() {
  function extractBreadCrumbFromCategory(category, mode = "") {
    let breadCrumb;

    if (category === "ALL") {
      breadCrumb = { category: "Browse", url: "/products" };
    } else if (category === "WomensApparel") {
      breadCrumb = { category: "Clothing", url: "/products/clothing" };
    } else if (category === "WomensAccessories") {
      breadCrumb = { category: "Accessories", url: "/products/accessory" };
    } else {
      breadCrumb = findUrlForCategory(category);
    }

    if (breadCrumb && mode === "BUYNOW") {
      breadCrumb.url = breadCrumb.url + "?buynow=true";
    }

    return breadCrumb;
  }

  function findUrlForCategory(category) {
    const keysHavingCategory = Object.keys(categoryMap).filter(k => categoryMap[k]?.includes(category));
    const filteredKeys = keysHavingCategory?.filter(k => !k.includes("clearance"));

    if (Array.isArray(filteredKeys) && filteredKeys?.length > 0) {
      return { category: returnDisplayName(category), url: filteredKeys[0] };
    }

    return;
  }

  return {
    extractBreadCrumbFromCategory,
    findUrlForCategory,
  };
}

// function to create breadcrumbs for all PDPs IN Clearance
export function createBreadCrumbForClearance() {
  function extractClearanceBreadCrumbFromCategory(category) {
    let breadCrumb;

    if (category === "ALL") {
      breadCrumb = { category: "Browse", url: "/clearance/products" };
    } else if (category === "WomensApparel") {
      breadCrumb = { category: "Clothing", url: "/clearance/products/clothing" };
    } else if (category === "WomensAccessories") {
      breadCrumb = { category: "Accessories", url: "/clearance/products/accessory" };
    } else {
      breadCrumb = findClearanceUrlForCategory(category, "CLEARANCE");
    }

    return breadCrumb;
  }

  function findClearanceUrlForCategory(category) {
    const keysHavingCategory = Object.keys(categoryMap).filter(k => categoryMap[k]?.includes(category));
    const filteredKeys = keysHavingCategory?.filter(k => k.includes("clearance"));

    if (Array.isArray(filteredKeys) && filteredKeys?.length > 0) {
      return { category: returnDisplayName(category), url: filteredKeys[0] };
    }

    return;
  }

  return {
    extractClearanceBreadCrumbFromCategory,
    findClearanceUrlForCategory,
  };
}

export function returnDisplayName(category) {
  return (
    filterDisplayNameMap.Category.values.find(o => o.id === category)?.value || convertCamelCaseToSentenceCase(category)
  );
}
