import BaseServicesClient from "../BaseServicesClient";
import HTTP_HEADERS from "rtr-constants/http-headers";
import { isValid } from "../../validation/validate";
import { DefaultEmailSchema } from "../../validation/schemas/identity";
import { ValidationError } from "../../exceptions";
import { isSSR } from "../../helpers/client-server-helper";

export default class GladlyClient extends BaseServicesClient {
  async getSearchResults(orgId, searchTerm) {
    return this.get(
      `/api/v1/orgs/${orgId}/answers-search?lng=en-us&q=${encodeURIComponent(searchTerm)}`,
      {},
      {
        headers: {
          "Accept": "application/json",
          "Accept-Encoding": "gzip, deflate, br, zstd",
          "Accept-Language": "en-US,en",
          "Content-Type": "text/plain",
        },
      }
    );
  }
  async createHelpCenterIdentity(email = "") {
    if (!isSSR()) {
      throw new Error("This function cannot be run client-side");
    }

    if (!isValid(email, DefaultEmailSchema)) {
      throw new ValidationError("'email' param is invalid");
    }

    return this.post(
      "/api/v1/user-identity-jwt",
      {},
      {
        body: JSON.stringify({
          identityType: "EMAIL",
          identity: email,
        }),
        headers: {
          [HTTP_HEADERS.AUTHORIZATION]: `Basic ${btoa(
            `${process.env.GLADLY_API_SERVICE_ACCOUNT}:${process.env.GLADLY_API_SERVICE_ACCOUNT_TOKEN}`
          )}`,
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  }
}
