import PropTypes from "prop-types";

const linkPropType = PropTypes.shape({
  copy: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
});

const linkSectionPropType = PropTypes.shape({
  sectionHeaderCopy: PropTypes.string,
  links: PropTypes.arrayOf(linkPropType).isRequired,
});

export const footerPropType = {
  footer: PropTypes.shape({
    appDownloadPrompt: PropTypes.shape({
      annotationCopy: PropTypes.string.isRequired,
      ctaCopy: PropTypes.string.isRequired,
    }),
    curatorsLink: PropTypes.shape({
      annotationCopy: PropTypes.string.isRequired,
      ctaCopy: PropTypes.string.isRequired,
    }),
    emailCaptureForm: PropTypes.shape({
      hedCopy: PropTypes.string,
      dekCopy: PropTypes.string,
      timeToShowInSeconds: PropTypes.number,
    }),
    legalLinkSection: PropTypes.arrayOf(linkPropType).isRequired,
    linkSections: PropTypes.arrayOf(linkSectionPropType).isRequired,
    socialLinkSection: PropTypes.shape({
      sectionHeaderCopy: PropTypes.string.isRequired,
      activeSocialMediaLinks: PropTypes.arrayOf(
        PropTypes.oneOf(["FACEBOOK", "TIKTOK", "INSTAGRAM", "PINTEREST", "TWITTER"])
      ),
      showTheShiftLink: PropTypes.bool.isRequired,
    }),
  }),
};
