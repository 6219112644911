import PropTypes from "prop-types";

export const membershipTiersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    basePrice: PropTypes.string,
    id: PropTypes.string,
    slotCount: PropTypes.number,
    membershipTierRevisionId: PropTypes.number,
    monthlyShipmentLimit: PropTypes.number,
    name: PropTypes.string,
    baseSlotUpgradePrice: PropTypes.string,
    inventoryEligibilities: PropTypes.arrayOf(PropTypes.string),
  })
);
