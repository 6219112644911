import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { optInEmail } from "actions/sailthru-marketing-actions";
import AnimatedTextInputWrapper from "components/source/shared/animated-text-input-wrapper";
import AtomPrimaryButton from "components/source/atoms/atom-primary-button";
import classnames from "classnames";
import RightArrow from "images/right-arrow.svg";
import Checkmark from "images/white-checkmark.svg";
import XIcon from "images/x-icon.svg";
import { renderImportedImage } from "helpers/storefront-next-transition-helpers";
import { isAnonymous, userDataPropType, withUserData } from "../hoc/with-user-data";
import { RENTAL_INTENT } from "rtr-constants";
import { getRentalIntent } from "helpers/rental-intent-helper";

export class MoleculeEmailCapture extends React.Component {
  static propTypes = {
    copy: PropTypes.shape({ hedCopy: PropTypes.string, dekCopy: PropTypes.string }),
    error: PropTypes.string,
    loading: PropTypes.bool,
    intent: PropTypes.oneOf(Object.keys(RENTAL_INTENT)),
    modal: PropTypes.bool,
    onSuccess: PropTypes.func,
    submitEmail: PropTypes.func.isRequired,
    success: PropTypes.bool,
    userData: userDataPropType,
  };

  static successMessage = "Done! You're all set.";

  static defaultProps = {
    copy: {}, //Should be defaulted in parent components but just in case
  };

  constructor(props) {
    super(props);

    this.state = {
      email: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.success && this.props.success) {
      this.setState({ email: null });
    }
  }

  handleChangeEmail = email => {
    this.setState({ email });
  };

  onClickSubmitEmail = e => {
    const { intent, onSuccess, submitEmail } = this.props;
    const { email } = this.state;

    e.preventDefault();

    submitEmail(email, intent, onSuccess);
  };

  renderErrorMessage() {
    return (
      this.props.error && (
        <div>
          {renderImportedImage(XIcon)} {this.props.error}
        </div>
      )
    );
  }

  renderSuccessMessage() {
    return (
      this.props.success && (
        <div>
          {renderImportedImage(Checkmark)} {MoleculeEmailCapture.successMessage}
        </div>
      )
    );
  }

  getLegalText() {
    const breakpoint = this.props.modal ? "" : <br />;

    return (
      <>
        *By entering your email address and clicking submit, you agree to receive marketing emails from Rent the Runway,{" "}
        {breakpoint}
        and acknowledge our <a href="/pages/termsofservice">Terms of Service</a> and{" "}
        <a href="/privacy">Privacy Policy</a>.
      </>
    );
  }

  renderLegalCopy() {
    return <p className="legal">{this.getLegalText()}</p>;
  }

  renderMoleculeEmailCapture() {
    const {
      copy: { hedCopy, dekCopy },
      loading,
    } = this.props;
    const additionalClassName = this.props.error ? "invalid-email" : "";

    const classNames = classnames("molecule-email-capture", {
      [additionalClassName]: additionalClassName,
    });

    const buttonContent = renderImportedImage(RightArrow);
    return (
      <div className={classNames}>
        <div className="form">
          <div className="annotation">
            <h3>{hedCopy}</h3>
            <p>{dekCopy}</p>
          </div>
          <form className="email-input" onSubmit={this.onClickSubmitEmail} noValidate={true}>
            <div>
              <AnimatedTextInputWrapper
                id="email"
                label="Email Address"
                type="email"
                value={this.state.email}
                onChangeCallback={this.handleChangeEmail}
                additionalClassName={additionalClassName}
                name={"email"}
              />
              <AtomPrimaryButton buttonText={buttonContent} ariaLabel="submit" disabled={loading} />
              <div className={`validation-message ${additionalClassName}`}>
                {this.renderErrorMessage()}
                {this.renderSuccessMessage()}
              </div>
            </div>
          </form>
        </div>
        {this.renderLegalCopy()}
      </div>
    );
  }

  render() {
    const {
      copy: { hedCopy },
      userData,
    } = this.props;

    if (
      hedCopy &&
      isAnonymous(userData) &&
      userData?.userProfileHasLoaded &&
      (userData?.userProfile === null || typeof userData?.userProfile === "undefined")
    ) {
      return this.renderMoleculeEmailCapture();
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  return {
    error: state.sailthruOptIn?.error,
    success: state.sailthruOptIn?.success,
    loading: state.sailthruOptIn?.loading,
    intent: getRentalIntent(state.userData),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitEmail: (email, intent, onSuccess) => dispatch(optInEmail(email, intent, onSuccess)),
  };
};

export default compose(withUserData, connect(mapStateToProps, mapDispatchToProps))(MoleculeEmailCapture);
