import $ from "clients/RawClient";
import { differenceInDays, format, parseISO } from "date-fns";
import _ from "underscore";
import startcase from "lodash.startcase";

import AuthActions from "actions/auth-actions";
import { dispatchAction, createAsyncAction, redirectToRoute } from "./action-utils";
import { easternTimeAdjust, handleExpiredCutoffs } from "helpers/exchange-helper";
import sharedActions from "./shared-actions";
import actionLogger from "action-logger";
import { Swaps, Exchanges } from "../routes";
import { dateFnsFormats } from "rtr-constants";
import {
  TOP_PICKS,
  VIEW_ALL,
  SIMILAR_DELIMITER,
  PAGE_LIMIT,
  DEFAULT_PRICE_MIN,
  ORDER_TYPE,
  getOrderType,
  canShip,
  swapsFeatureFlags,
  swapsBonusFeatureFlags,
  exchangeFeatureFlag,
  redirectCategory,
} from "helpers/swaps-helper";
import { skuInfo } from "helpers/sku-helpers";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";
import { HEAP_AUTH_TRIGGER_TYPES } from "helpers/heap-helpers";
import { selectFeatureFlagEnabled } from "selectors/featureFlagSelectors";
import { isMember } from "helpers/membership-helpers";

const exchangeActionConstants = {
  EXCHANGE_UPDATE_BAG_PRICE: "EXCHANGE_UPDATE_BAG_PRICE",
};

export const constants = {
  SWAPS_FETCH_PRODUCTS: "SWAPS_FETCH_PRODUCTS",
  SWAPS_FETCH_PRODUCTS_BEGIN: "SWAPS_FETCH_PRODUCTS_BEGIN",
  SWAPS_FETCH_PRODUCTS_SUCCESS: "SWAPS_FETCH_PRODUCTS_SUCCESS",
  SWAPS_FETCH_PRODUCTS_FAILURE: "SWAPS_FETCH_PRODUCTS_FAILURE",
  SWAPS_FETCH_USER_PROBLEM_GROUP: "SWAPS_FETCH_USER_PROBLEM_GROUP",
  SWAPS_FETCH_USER_PROBLEM_GROUP_BEGIN: "SWAPS_FETCH_USER_PROBLEM_GROUP_BEGIN",
  SWAPS_FETCH_USER_PROBLEM_GROUP_SUCCESS: "SWAPS_FETCH_USER_PROBLEM_GROUP_SUCCESS",
  SWAPS_FETCH_USER_PROBLEM_GROUP_FAILURE: "SWAPS_FETCH_USER_PROBLEM_GROUP_FAILURE",
  SWAPS_FETCH_CMS: "SWAPS_FETCH_CMS",
  SWAPS_FETCH_CMS_BEGIN: "SWAPS_FETCH_CMS_BEGIN",
  SWAPS_FETCH_CMS_SUCCESS: "SWAPS_FETCH_CMS_SUCCESS",
  SWAPS_FETCH_CMS_FAILURE: "SWAPS_FETCH_CMS_FAILURE",
  SWAPS_ADD_REPLACEMENT: "SWAPS_ADD_REPLACEMENT",
  SWAPS_ADD_REPLACEMENT_BEGIN: "SWAPS_ADD_REPLACEMENT_BEGIN",
  SWAPS_ADD_REPLACEMENT_SUCCESS: "SWAPS_ADD_REPLACEMENT_SUCCESS",
  SWAPS_ADD_REPLACEMENT_FAILURE: "SWAPS_ADD_REPLACEMENT_FAILURE",
  SWAPS_REMOVE_REPLACEMENT: "SWAPS_REMOVE_REPLACEMENT",
  SWAPS_REMOVE_REPLACEMENT_BEGIN: "SWAPS_REMOVE_REPLACEMENT_BEGIN",
  SWAPS_REMOVE_REPLACEMENT_SUCCESS: "SWAPS_REMOVE_REPLACEMENT_SUCCESS",
  SWAPS_REMOVE_REPLACEMENT_FAILURE: "SWAPS_REMOVE_REPLACEMENT_FAILURE",
  SWAPS_FETCH_REPLACEMENTS: "SWAPS_FETCH_REPLACEMENTS",
  SWAPS_FETCH_REPLACEMENTS_BEGIN: "SWAPS_FETCH_REPLACEMENTS_BEGIN",
  SWAPS_FETCH_REPLACEMENTS_SUCCESS: "SWAPS_FETCH_REPLACEMENTS_SUCCESS",
  SWAPS_FETCH_REPLACEMENTS_FAILURE: "SWAPS_FETCH_REPLACEMENTS_FAILURE",
  SWAPS_FETCH_PRODUCT_REVIEWS: "SWAPS_FETCH_PRODUCT_REVIEWS",
  SWAPS_FETCH_PRODUCT_REVIEWS_BEGIN: "SWAPS_FETCH_PRODUCT_REVIEWS_BEGIN",
  SWAPS_FETCH_PRODUCT_REVIEWS_SUCCESS: "SWAPS_FETCH_PRODUCT_REVIEWS_SUCCESS",
  SWAPS_FETCH_PRODUCT_REVIEWS_FAILURE: "SWAPS_FETCH_PRODUCT_REVIEWS_FAILURE",
  SWAPS_PERFORM_SWAP: "SWAPS_PERFORM_SWAP",
  SWAPS_PERFORM_SWAP_BEGIN: "SWAPS_PERFORM_SWAP_BEGIN",
  SWAPS_PERFORM_SWAP_SUCCESS: "SWAPS_PERFORM_SWAP_SUCCESS",
  SWAPS_PERFORM_SWAP_FAILURE: "SWAPS_PERFORM_SWAP_FAILURE",
  EXCHANGE_CREATE_BAG: "EXCHANGE_CREATE_BAG",
  EXCHANGE_CREATE_BAG_BEGIN: "EXCHANGE_CREATE_BAG_BEGIN",
  EXCHANGE_CREATE_BAG_SUCCESS: "EXCHANGE_CREATE_BAG_SUCCESS",
  EXCHANGE_CREATE_BAG_FAILURE: "EXCHANGE_CREATE_BAG_FAILURE",
  SWAPS_INITIAL_GRID_LOADING: "SWAPS_INITIAL_GRID_LOADING",
  SWAPS_SET_BASE_GRID: "SWAPS_SET_BASE_GRID",
  SWAPS_SET_MAIN_CATEGORY: "SWAPS_SET_MAIN_CATEGORY",
  SWAPS_SET_FILTER: "SWAPS_SET_FILTER",
  SWAPS_SET_GRID_PAGE: "SWAPS_SET_GRID_PAGE",
  SWAPS_REMOVE_SIMILAR_FILTER: "SWAPS_REMOVE_SIMILAR_FILTER",
  SWAPS_TOGGLE_BAG_EXPANSION: "SWAPS_TOGGLE_BAG_EXPANSION",
  SWAPS_TOGGLE_BANNER_EXPANSION: "SWAPS_TOGGLE_BANNER_EXPANSION",
  SWAPS_TOGGLE_FILTER_EXPANSION: "SWAPS_TOGGLE_FILTER_EXPANSION",
  SWAPS_TOGGLE_MOBILE_FILTER_EXPANSION: "SWAPS_TOGGLE_MOBILE_FILTER_EXPANSION",
  SWAPS_TOGGLE_EXIT_MODAL: "SWAPS_TOGGLE_EXIT_MODAL",
  SWAPS_TOGGLE_ERROR_MODAL: "SWAPS_TOGGLE_ERROR_MODAL",
  SWAPS_TOGGLE_SUCCESS_MODAL: "SWAPS_TOGGLE_SUCCESS_MODAL",
  SWAPS_TOGGLE_BTS_MODAL: "SWAPS_TOGGLE_BTS_MODAL",
  SWAPS_TOGGLE_PAYMENT_MODAL: "SWAPS_TOGGLE_PAYMENT_MODAL",
  SWAPS_TOGGLE_ADD_TO_BAG_ERROR_MODAL: "SWAPS_TOGGLE_ADD_TO_BAG_ERROR_MODAL",
};

const CLOTHING_MAP = {
  dress: "Dresses",
  top: "Tops",
  bottom: "Bottoms",
  jumpsuit_romper: "Jumpsuits & Rompers",
  jacket_coat: "Jackets & Coats",
};

const ACCESSORIES_MAP = {
  accessory: "All Accessories",
  jewelry: "Jewelry",
  handbag: "Handbags",
};

export const safelyParseJSON = json => {
  let parsed = null;
  try {
    parsed = JSON.parse(json);

    // eslint-disable-next-line no-empty
  } catch (e) {}
  return parsed;
};

const firstSimilarItem = baseCategories => {
  const keys = _.mapObject(baseCategories, (val, key) => key);
  return _.find(keys, x => x.includes(SIMILAR_DELIMITER));
};

const mostExpensiveProblemPrice = problemBookings => {
  const mostExpensiveProblem = _.max(problemBookings, x => x?.price?.maximum || 0);
  return mostExpensiveProblem?.price?.maximum || DEFAULT_PRICE_MIN;
};

export const buildCategories = styles => {
  const categories = Object.assign({}, CLOTHING_MAP, ACCESSORIES_MAP);

  _.map(styles, style => {
    //  If the style has been marked for clearance, certain product information is not returned,
    //  so use a more generic title here.
    let title = "Similar items";
    if (style?.designer && style?.category) {
      title = `Similar to ${style?.designer?.displayName} ${_.first(style?.colors)} ${style?.category?.id}`;
    }
    categories[SIMILAR_DELIMITER + style.styleName] = startcase(title);
  });
  return categories;
};

export const buildBaseFilters = (problemGroup, defaultCat, defaultSort, workingFilters = {}) => {
  const { problemBookings } = problemGroup;

  let category = {};

  if (defaultCat.includes(SIMILAR_DELIMITER)) {
    category = { productsSimilarTo: defaultCat.replace(SIMILAR_DELIMITER, "") };
  } else if (defaultCat === VIEW_ALL) {
    category = {};
  } else {
    category = { categories: defaultCat };
  }

  const epicMaternity = workingFilters?.epicMaternity ? { "products.epicMaternity": workingFilters.epicMaternity } : {};

  const sizeSkus = _.map(problemBookings, booking => {
    const { sku } = booking;
    const canonicalSizes = sku?.canonicalSizes || [];
    return canonicalSizes.length <= 2 ? canonicalSizes : [];
  });

  const sizeFilters = workingFilters?.canonicalSizes?.length
    ? workingFilters.canonicalSizes
    : _.uniq(_.flatten(sizeSkus));

  const maxPrice = mostExpensiveProblemPrice(problemGroup.problemBookings);
  const zip = problemGroup.zipCode;
  const rentStart = parseISO(problemGroup.rentStart || problemBookings[0].rentBegin || "");
  const rentEnd = parseISO(problemGroup.rentEnd || problemBookings[0].rentEnd || "");
  // ensure we always have a positive duration
  const duration = Math.abs(differenceInDays(rentEnd, rentStart)) + 1;

  if (problemGroup.orderType === ORDER_TYPE.UNLIMITED) {
    return {
      include: "skus.unlimitedAvailabilities,category,reviewSummary",
      sort: defaultSort,
      page: {
        offset: 0,
        limit: PAGE_LIMIT,
      },
      filter: {
        "carouselId": 0,
        "skus.unlimitedAvailabilities.beginDate": format(rentStart, dateFnsFormats.YYYY_MM_DD),
        "skus.unlimitedAvailabilities.zipCode": zip,
        "products.canonicalSizes": sizeFilters,
        ...epicMaternity,
      },
    };
  } else if (problemGroup.newGroup) {
    return {
      include: "skus.rentalAvailabilities,price,category,reviewSummary",
      sort: defaultSort,
      page: {
        offset: 0,
        limit: PAGE_LIMIT,
      },
      filter: {
        "skus.rentalAvailabilities.beginDate": format(rentStart, dateFnsFormats.YYYY_MM_DD),
        "skus.rentalAvailabilities.duration": duration,
        "skus.rentalAvailabilities.zipCode": zip,
        "price.maximum": parseFloat(maxPrice) * 2,
        "products.canonicalSizes": sizeFilters,
        ...epicMaternity,
        ...category,
      },
    };
  }

  return {
    include: "skus.rentalAvailabilities,price,category,reviewSummary",
    sort: defaultSort,
    page: {
      offset: 0,
      limit: PAGE_LIMIT,
    },
    filter: {
      "skus.rentalAvailabilities.beginDate": format(rentStart, dateFnsFormats.YYYY_MM_DD),
      "skus.rentalAvailabilities.duration": duration,
      "skus.rentalAvailabilities.zipCode": zip,
      "products.canonicalSizes": sizeFilters,
      ...epicMaternity,
      ...category,
    },
  };
};

const fetchProductsClient = params => {
  return $.get(Swaps.products, params);
};

export const fetchProducts = params => {
  return dispatch => {
    dispatchAction(dispatch, constants.SWAPS_FETCH_PRODUCTS_BEGIN);
    fetchProductsClient(params).then(
      products => {
        const similarCategory = params?.filter?.productsSimilarTo;
        const topPicksCategory = params?.filter?.carouselId;

        if (similarCategory) {
          actionLogger.logAction({
            node: "swaps",
            action: "view_similar_styles_grid",
            object_type: "problem_order",
            item_count: products.resultCount,
          });
        }

        if (topPicksCategory) {
          actionLogger.logAction({
            node: "swaps",
            action: "view_top_picks_grid",
            object_type: "problem_order",
            item_count: products.resultCount,
          });
        }
        dispatchAction(dispatch, constants.SWAPS_FETCH_PRODUCTS_SUCCESS, products);
      },
      err => {
        dispatchAction(dispatch, constants.SWAPS_FETCH_PRODUCTS_FAILURE, err);
      }
    );
  };
};

// a modified version of fetchProducts that will call redirectCategory for an empty similar-to category.
export const fetchProductsWithCallback = (params, categories, loadCategory, group, workingFilters) => {
  return dispatch => {
    dispatchAction(dispatch, constants.SWAPS_FETCH_PRODUCTS_BEGIN);
    fetchProductsClient(params).then(
      products => {
        const similarCategory = params?.filter?.productsSimilarTo;
        const topPicksCategory = params?.filter?.carouselId;

        if (similarCategory && products.resultsCount === 0) {
          redirectCategory(categories, loadCategory, group, workingFilters, fetchProductsWithCallback)(dispatch);
          dispatchAction(dispatch, constants.SWAPS_REMOVE_SIMILAR_FILTER);
          return;
        }

        if (similarCategory) {
          actionLogger.logAction({
            node: "swaps",
            action: "view_similar_styles_grid",
            object_type: "problem_order",
            item_count: products.resultCount,
          });
        }

        if (topPicksCategory) {
          actionLogger.logAction({
            node: "swaps",
            action: "view_top_picks_grid",
            object_type: "problem_order",
            item_count: products.resultCount,
          });
        }

        const similar = similarCategory ? loadCategory.replace(SIMILAR_DELIMITER, "") : null;
        dispatchAction(dispatch, constants.SWAPS_SET_MAIN_CATEGORY, { category: loadCategory, similar: similar });
        dispatchAction(dispatch, constants.SWAPS_FETCH_PRODUCTS_SUCCESS, products);
        dispatchAction(dispatch, constants.SWAPS_INITIAL_GRID_LOADING, false);
      },
      err => {
        dispatchAction(dispatch, constants.SWAPS_FETCH_PRODUCTS_FAILURE, err);
      }
    );
  };
};

const fetchReplacementsClient = () => {
  return $.get(Swaps.fetchReplacements);
};

export const fetchReplacements = createAsyncAction(constants.SWAPS_FETCH_REPLACEMENTS, fetchReplacementsClient);

const fetchUserProblemGroupClient = () => {
  return $.get(Swaps.problemGroup);
};
const fetchNonCachedProblemGroupClient = () => {
  return $.get(Swaps.nonCachedProblemGroup);
};

const fetchNewGroupClient = groupId => {
  if (groupId) {
    return $.get(`${Exchanges.exchangeBag}?groupId=${groupId}`);
  }
  return $.get(Exchanges.exchangeBag);
};

const deserializeBookings = group => {
  const problemGroup = {};

  const allBookings = _.map(group.bookings, booking => {
    const product = _.first(booking?.products || []);
    const { size } = skuInfo(booking.sku.id);
    const bookingId = booking.id;
    const holdId = booking.id;
    const issues = group.bookingIdToAnnotationsMap?.[bookingId] || [];
    return _.extend(booking, product, { bookingId, holdId, size, issues });
  });

  const problemItemBookings = group.problemItemBookings.map(booking => {
    return _.find(allBookings, b => b.bookingId === booking.toString());
  });
  const replacementBookings = group.replacementBookings.map(booking => {
    return _.find(allBookings, b => b.bookingId === booking.toString());
  });

  problemGroup.problemBookings = problemItemBookings;
  problemGroup.replacementBookings = replacementBookings;
  problemGroup.goodBookings = [];
  return problemGroup;
};

const buildNGProblemGroup = (group, dispatch) => {
  const problemGroup = {}; // Only returns the top priority problem group
  problemGroup.rentStart = format(easternTimeAdjust(group.rentBegin), dateFnsFormats.YYYY_MM_DD);
  problemGroup.rentEnd = format(easternTimeAdjust(group.rentEnd), dateFnsFormats.YYYY_MM_DD);
  problemGroup.lps = group.latestPossibleShipDate;
  problemGroup.userId = group.userId;
  problemGroup.orderId = group.orderId;

  problemGroup.groupId = group.id;
  problemGroup.address = _.first(group.addresses);
  problemGroup.zipCode = problemGroup.address.postalCode;

  problemGroup.newGroup = true;

  problemGroup.allIssues = _.flatten(_.values(group.bookingIdToAnnotationsMap));
  problemGroup.deliveryIssue = _.includes(problemGroup.allIssues, "LATE_DELIVERY") ? true : false;

  // temporily hardcoding the ordertype for new groups
  problemGroup.orderType = ORDER_TYPE["CLASSIC"];
  problemGroup.bonusSwap = false;

  const problemGroupWithBookings = Object.assign({}, problemGroup, deserializeBookings(group));
  dispatchAction(dispatch, exchangeActionConstants.EXCHANGE_UPDATE_BAG_PRICE, group.priceCharged);
  return problemGroupWithBookings;
};

const buildPOProblemGroup = (group, state) => {
  const problemGroup = {};
  const booking = _.first(group.bookings);
  problemGroup.rentStart = booking.rentBegin;
  problemGroup.rentEnd = booking.rentEnd;
  problemGroup.zipCode = booking.zipCode;

  problemGroup.groupId = group.id;
  problemGroup.address = group.address;
  problemGroup.userId = group.userId;

  // lps == last possible ship
  problemGroup.lps = group.shippingRestrictions.shippingCutoffTime;
  problemGroup.orderType = getOrderType(problemGroup, booking);
  problemGroup.bonusSwap = selectFeatureFlagEnabled(swapsBonusFeatureFlags[problemGroup.orderType])(state);

  const bookings = group.bookings.map(booking => {
    const product = _.first(booking.products) || {};
    const { id, size } = skuInfo(booking.sku.id);
    const combined = Object.assign({}, product, booking, { category: product.category, styleName: id, size });
    delete combined.products;
    return combined;
  });

  problemGroup.problemBookings = bookings.filter(x => x.problems.length > 0);
  problemGroup.goodBookings = bookings.filter(x => x.problems.length === 0);

  if (problemGroup.problemBookings.length > 0) {
    const firstBooking = _.first(problemGroup.problemBookings);
    const problems = firstBooking?.problems;
    const problem = _.first(problems);
    problemGroup.orderId = problem?.orderId;
  } else {
    // If there aren't any problem bookings, must return
    return false;
  }

  return problemGroup;
};

// This the action to populate problem group in redux for both PO's and NG's issues
export const fetchUserProblemGroup = (grid, newGroup, groupId) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatchAction(dispatch, constants.SWAPS_FETCH_USER_PROBLEM_GROUP_BEGIN);
    const problemGroupClient = grid ? fetchNonCachedProblemGroupClient : fetchUserProblemGroupClient;
    const client = newGroup ? fetchNewGroupClient : problemGroupClient;
    return client(groupId).then(
      groups => {
        if (_.isEmpty(groups)) {
          if (grid) {
            redirectToRoute("/");
          }
          dispatchAction(dispatch, constants.SWAPS_FETCH_USER_PROBLEM_GROUP_SUCCESS, null);
          return;
        }
        const group = _.first(groups);

        if (newGroup && !selectFeatureFlagEnabled(exchangeFeatureFlag)(state)) {
          redirectToRoute("/");
        }

        // This cleans the data from the two different API's
        const problemGroup = newGroup ? buildNGProblemGroup(group, dispatch) : buildPOProblemGroup(group, state);

        // Hard code all new groups to classic for now
        if (!newGroup && !selectFeatureFlagEnabled(swapsFeatureFlags[problemGroup.orderType])(state)) {
          return;
        }

        dispatchAction(dispatch, constants.SWAPS_FETCH_USER_PROBLEM_GROUP_SUCCESS, problemGroup);
        fetchSwapsCms()(dispatch);

        if (!grid) {
          return;
        }
        // Build Categories with the similar styles
        const baseCategories = buildCategories(problemGroup.problemBookings);
        baseCategories[VIEW_ALL] = "View All";

        let loadCategory = firstSimilarItem(baseCategories);
        if (problemGroup.orderType === ORDER_TYPE.UNLIMITED) {
          baseCategories[TOP_PICKS] = "My Top Picks";
          loadCategory = TOP_PICKS;
        }

        const loadSort = "contextualRecommended";
        const baseFilter = buildBaseFilters(problemGroup, loadCategory, loadSort);

        dispatchAction(dispatch, constants.SWAPS_SET_BASE_GRID, {
          baseFilter,
          baseCategories,
        });

        // Initial bag fetches / data dispatches
        if (!problemGroup.newGroup) {
          fetchReplacements()(dispatch);
        } else {
          dispatchAction(dispatch, constants.SWAPS_FETCH_REPLACEMENTS_SUCCESS, problemGroup.replacementBookings);
        }
      },
      err => dispatchAction(dispatch, constants.SWAPS_FETCH_USER_PROBLEM_GROUP_FAILURE, err)
    );
  };
};

export const fetchUserProblemGrid = (userProblems, workingFilters, userData = {}) => {
  return dispatch => {
    if (!userProblems) {
      return;
    }
    dispatchAction(dispatch, constants.SWAPS_INITIAL_GRID_LOADING, true);

    const { problemGroup } = userProblems;

    // Build Categories with the similar styles
    const baseCategories = buildCategories(problemGroup.problemBookings);
    baseCategories[VIEW_ALL] = "View All";

    const loadCategory = isMember(userData) ? VIEW_ALL : "dress";

    const loadSort = "contextualRecommended";
    const baseFilter = buildBaseFilters(problemGroup, loadCategory, loadSort, workingFilters);

    dispatchAction(dispatch, constants.SWAPS_SET_BASE_GRID, {
      baseFilter,
      baseCategories,
    });

    dispatchAction(dispatch, constants.SWAPS_SET_MAIN_CATEGORY, {
      category: loadCategory,
      similar: loadCategory.replace(SIMILAR_DELIMITER, ""),
    });
    toggleFilterExpansion("canonicalSizes", true)(dispatch);

    fetchProductsWithCallback(baseFilter, baseCategories, loadCategory, problemGroup, workingFilters)(dispatch);
  };
};

export const swapsFlowNextStep = (problemGroup, goalRoute, redirectRoute, additionalSteps = _.noop) => {
  return dispatch => {
    dispatchAction(dispatch, constants.SWAPS_FETCH_USER_PROBLEM_GROUP);
    const client = problemGroup?.newGroup ? fetchNewGroupClient : fetchNonCachedProblemGroupClient;
    client().then(
      groups => {
        if (_.isEmpty(groups)) {
          redirectToRoute(redirectRoute);
          return;
        }

        if (!problemGroup.newGroup) {
          const group = _.first(groups);
          if (!canShip(group.shippingRestrictions.shippingCutoffTime)) {
            additionalSteps();
            redirectToRoute(redirectRoute);
            return;
          }
        }

        additionalSteps();
        if (goalRoute) {
          redirectToRoute(goalRoute);
        }
      },
      err => {
        if (err.status === 401) {
          dispatch(
            AuthActions.showAuthModal({
              destination: goalRoute,
              triggeredBy: HEAP_AUTH_TRIGGER_TYPES.AUTH_PROMPT,
            })
          );
        }
      }
    );
  };
};

export const setMainGridCategory = (category, params) => {
  return dispatch => {
    if (params.filter.carouselId === 0) {
      delete params.filter.carouselId;
    }

    if (params.filter.categories) {
      delete params.filter.categories;
    }

    if (params.filter.productsSimilarTo) {
      delete params.filter.productsSimilarTo;
    }

    if (category.includes(SIMILAR_DELIMITER)) {
      params.filter.productsSimilarTo = category.replace(SIMILAR_DELIMITER, "");
      dispatchAction(dispatch, constants.SWAPS_SET_MAIN_CATEGORY, {
        category,
        similar: params.filter.productsSimilarTo,
      });
    } else if (category === TOP_PICKS) {
      params.filter.carouselId = 0;
      dispatchAction(dispatch, constants.SWAPS_SET_MAIN_CATEGORY, { category, similar: null });
    } else if (category === VIEW_ALL) {
      dispatchAction(dispatch, constants.SWAPS_SET_MAIN_CATEGORY, { category, similar: null });
    } else {
      params.filter.categories = category;
      dispatchAction(dispatch, constants.SWAPS_SET_MAIN_CATEGORY, { category, similar: null });
    }
    setSwapGridPage(1, params)(dispatch);
  };
};

export const clearFilters = (category, problemGroup, workingFilters) => {
  return dispatch => {
    const newFilters = buildBaseFilters(problemGroup, category, "contextualRecommended", workingFilters);
    setSwapGridPage(1, newFilters)(dispatch);
    dispatchAction(dispatch, constants.SWAPS_SET_FILTER, newFilters);
  };
};

export const toggleHeartFilter = (state, params) => {
  return dispatch => {
    if (state) {
      params.filter.hearts = state;
    } else {
      params.filter.hearts = state;
    }
    setSwapGridPage(1, params)(dispatch);
  };
};

export const setSizeFilterFromWorkingFilters = (workingFilters = {}, params) => {
  return dispatch => {
    const userFilters = params.filter;

    if (workingFilters.canonicalSizes) {
      userFilters["products.canonicalSizes"] = workingFilters.canonicalSizes;
    } else {
      if (userFilters?.["products.canonicalSizes"]) {
        delete userFilters["products.canonicalSizes"];
      }
    }

    if (workingFilters.epicMaternity) {
      userFilters["products.epicMaternity"] = workingFilters.epicMaternity;
    } else {
      if (userFilters?.["products.epicMaternity"]) {
        delete userFilters["products.epicMaternity"];
      }
    }

    params.filter = userFilters;

    setSwapGridPage(1, params)(dispatch);
    dispatchAction(dispatch, constants.SWAPS_SET_FILTER, params);
  };
};

export const setFilter = (filter, params) => {
  return dispatch => {
    const userFilters = params.filter;

    // Filters that can use facet filters are only product. filters
    const key = "products." + filter.filterGroupKey;

    if (filter.selected) {
      if (!userFilters[key]) {
        userFilters[key] = [];
      }
      if (!_.contains(userFilters[key], filter.name)) {
        userFilters[key].push(filter.name);
      }
    } else if (_.contains(userFilters[key], filter.name)) {
      userFilters[key] = _.filter(userFilters[key], name => name !== filter.name);
    }

    params.filter = userFilters;

    setSwapGridPage(1, params)(dispatch);
    dispatchAction(dispatch, constants.SWAPS_SET_FILTER, params);
  };
};

export const setSort = (sort, params) => {
  return dispatch => {
    if (sort) {
      params.sort = sort;
      setSwapGridPage(1, params)(dispatch);
      dispatchAction(dispatch, constants.SWAPS_SET_FILTER, params);
    }
  };
};

export const setSwapGridPage = (page, params) => {
  return dispatch => {
    dispatchAction(dispatch, constants.SWAPS_SET_GRID_PAGE, page);

    params.page.offset = (page - 1) * PAGE_LIMIT;
    fetchProducts(params)(dispatch);
  };
};

const fetchSwapsCmsClient = () => {
  return $.get(Swaps.cms);
};
export const fetchSwapsCms = createAsyncAction(constants.SWAPS_FETCH_CMS, fetchSwapsCmsClient);

export const toggleSwapBannerExpansion = state => {
  return dispatch => {
    dispatchAction(dispatch, constants.SWAPS_TOGGLE_BANNER_EXPANSION, state);
  };
};

export const toggleBagExpansion = state => {
  return dispatch => {
    dispatchAction(dispatch, constants.SWAPS_TOGGLE_BAG_EXPANSION, state);
  };
};

export const toggleFilterExpansion = (id, state) => {
  return dispatch => {
    dispatchAction(dispatch, constants.SWAPS_TOGGLE_FILTER_EXPANSION, { id, state });
  };
};

export const toggleMobileFilterExpansion = state => {
  return dispatch => {
    if (state) {
      dispatch(sharedActions.displayModal("filter-modal"));
    } else {
      dispatch(sharedActions.displayModal(false));
    }
    dispatchAction(dispatch, constants.SWAPS_TOGGLE_MOBILE_FILTER_EXPANSION, state);
  };
};

export const toggleExitModal = state => {
  return dispatch => {
    if (state) {
      dispatch(sharedActions.displayModal("SwapsContainerModal"));
      actionLogger.logAction({
        node: "swaps",
        action: "click_leave_flow",
        object_type: "problem_order",
      });
    } else {
      dispatch(sharedActions.displayModal(false));
    }
    dispatchAction(dispatch, constants.SWAPS_TOGGLE_EXIT_MODAL, state);
  };
};

export const toggleBTSModal = state => {
  return dispatch => {
    if (state) {
      dispatch(sharedActions.displayModal("SwapsContainerModal"));
      actionLogger.logAction({
        node: "swaps",
        action: "click_back_to_browse",
        object_type: "problem_order",
      });
    } else {
      dispatch(sharedActions.displayModal(false));
    }
    dispatchAction(dispatch, constants.SWAPS_TOGGLE_BTS_MODAL, state);
  };
};

export const toggleErrorModal = error => {
  return dispatch => {
    if (error) {
      actionLogger.logAction({
        node: "swaps",
        action: "view_error_message",
        object_type: "problem_order",
      });
      dispatch(sharedActions.displayModal("SwapsContainerModal"));
    } else {
      dispatch(sharedActions.displayModal(false));
    }
    dispatchAction(dispatch, constants.SWAPS_TOGGLE_ERROR_MODAL, error);
  };
};

export const toggleAddToBagErrorModal = error => {
  return dispatch => {
    if (error) {
      actionLogger.logAction({
        node: "swaps",
        action: "view_error_message",
        object_type: "problem_order",
      });
      dispatch(sharedActions.displayModal("SwapsContainerModal"));
    } else {
      dispatch(sharedActions.displayModal(false));
    }
    dispatchAction(dispatch, constants.SWAPS_TOGGLE_ADD_TO_BAG_ERROR_MODAL, error);
  };
};

export const toggleSuccessModal = state => {
  return dispatch => {
    if (state) {
      dispatch(sharedActions.displayModal("SwapsContainerModal"));
    } else {
      dispatch(sharedActions.displayModal(false));
    }
    dispatchAction(dispatch, constants.SWAPS_TOGGLE_SUCCESS_MODAL, state);
  };
};

export const togglePaymentModal = state => {
  return dispatch => {
    if (state) {
      dispatch(sharedActions.displayModal("SwapsContainerModal"));
    } else {
      dispatch(sharedActions.displayModal(false));
    }
    dispatchAction(dispatch, constants.SWAPS_TOGGLE_PAYMENT_MODAL, state);
  };
};

const addReplacementClient = (product, group, itemIsBonusReplacement) => {
  const params = {
    productId: product.id,
    size: product.size,
    sku: product.sku,
    startDate: group.rentStart,
    endDate: group.rentEnd,
    zip: group.zipCode,
    isBonus: itemIsBonusReplacement,
    orderId: group.orderId,
    orderType: group.orderType,
  };

  if (group.newGroup) {
    params.updateType = "ADD";
    params.groupId = group.groupId;
    return createAjaxAuthRetry({
      type: "PATCH",
      url: Exchanges.exchangeBag,
      data: params,
      headers: {
        Accept: "application/json",
      },
    });
  }

  return createAjaxAuthRetry({
    type: "POST",
    url: Swaps.addReplacement,
    data: params,
    headers: {
      Accept: "application/json",
    },
  });
};

export const addReplacement = (product, problemGroup, itemIsBonusReplacement, selfServiceExchange) => {
  return dispatch => {
    if (problemGroup.newGroup && selfServiceExchange) {
      handleExpiredCutoffs(selfServiceExchange, problemGroup, dispatch);
    }

    if (!problemGroup.newGroup && !canShip(problemGroup.lps)) {
      location.href = "/";
      return;
    }

    if (itemIsBonusReplacement) {
      // log pixel for adding bonus style
      actionLogger.logAction({
        node: "swaps",
        action: "bonus_replacement",
        object_type: "problem_order",
      });
    }

    dispatchAction(dispatch, constants.SWAPS_ADD_REPLACEMENT, product);

    return addReplacementClient(product, problemGroup, itemIsBonusReplacement)
      .then(({ data }) => {
        let replacements = [];
        if (problemGroup.newGroup) {
          const groups = data;
          const newProblemGroup = deserializeBookings(_.first(groups));
          replacements = newProblemGroup.replacementBookings;
          const bagPrice = groups?.[0]?.priceCharged;
          dispatchAction(dispatch, exchangeActionConstants.EXCHANGE_UPDATE_BAG_PRICE, bagPrice);
        } else {
          replacements = data;
        }

        dispatchAction(dispatch, constants.SWAPS_ADD_REPLACEMENT_SUCCESS, replacements);
        toggleBagExpansion(true)(dispatch);

        if (replacements.length < problemGroup.problemBookings.length) {
          setTimeout(() => {
            toggleBagExpansion(false)(dispatch);
          }, 2000);
        }
      })
      .catch(({ jqXHR }) => {
        dispatchAction(dispatch, constants.SWAPS_ADD_REPLACEMENT_FAILURE, jqXHR);
        toggleAddToBagErrorModal(true)(dispatch);
      });
  };
};

const removeReplacementClient = (holdId, group) => {
  const params = {
    holdId,
  };

  if (group.newGroup) {
    params.updateType = "REMOVE";
    params.groupId = group.groupId;
    return createAjaxAuthRetry({
      type: "PATCH",
      url: Exchanges.exchangeBag,
      data: params,
      headers: {
        Accept: "application/json",
      },
    });
  }

  return createAjaxAuthRetry({
    type: "POST",
    url: Swaps.removeReplacement,
    data: params,
    headers: {
      Accept: "application/json",
    },
  });
};

export const removeReplacement = (holdId, problemGroup, selfServiceExchange) => {
  return dispatch => {
    if (problemGroup.newGroup && selfServiceExchange) {
      handleExpiredCutoffs(selfServiceExchange, problemGroup, dispatch);
    }

    if (!problemGroup.newGroup && !canShip(problemGroup.lps)) {
      location.href = "/";
      return;
    }

    dispatchAction(dispatch, constants.SWAPS_REMOVE_REPLACEMENT, holdId);

    return removeReplacementClient(holdId, problemGroup)
      .then(({ data }) => {
        let replacements = [];
        if (problemGroup.newGroup) {
          const groups = data;
          const newProblemGroup = deserializeBookings(_.first(groups));
          replacements = newProblemGroup.replacementBookings;
          const bagPrice = groups?.[0]?.priceCharged;
          dispatchAction(dispatch, exchangeActionConstants.EXCHANGE_UPDATE_BAG_PRICE, bagPrice);
        } else {
          replacements = data;
        }
        dispatchAction(dispatch, constants.SWAPS_REMOVE_REPLACEMENT_SUCCESS, replacements);
      })
      .catch(({ jqXHR }) => {
        dispatchAction(dispatch, constants.SWAPS_REMOVE_REPLACEMENT_FAILURE, jqXHR);
        // If the remove failed it's most likely because the cookie expired as you removed
        if (!problemGroup.newGroup) {
          fetchReplacements()(dispatch);
        }
      });
  };
};

const buildProblemSwapRequests = (problemBookings, replacements) => {
  const numBonusSwaps = replacements.length - problemBookings.length;
  const bonusReplacements = _.last(replacements, numBonusSwaps);
  const bonusSwapIDs = _.map(bonusReplacements, replacement => replacement?.holdId);

  return _.map(problemBookings, (booking, index) => {
    const { holdId } = replacements[index];

    // handle bonus swaps by adding all bonus swaps as replacements to the last problem order
    if (orderHasBonusStyle(problemBookings, replacements) && index === problemBookings.length - 1) {
      const normalSwapId = [holdId],
        holdIds = normalSwapId.concat(bonusSwapIDs);

      return {
        problemBooking: booking.id,
        replacementHolds: holdIds,
      };
    }

    return {
      problemBooking: booking.id,
      replacementHolds: [holdId],
    };
  });
};

export const orderHasBonusStyle = (problemBookings, replacements) => {
  // Users are given one replacement for each PO, so if the order has more replacements than POs that implies there is
  // a bonus style in the swap order
  return replacements.length > problemBookings.length;
};

const performSwapClient = params => {
  return createAjaxAuthRetry({
    type: "POST",
    url: Swaps.perform,
    data: params,
    headers: {
      Accept: "application/json",
    },
  });
};

export const performSwap = (problemGroup, replacements) => {
  return dispatch => {
    if (!canShip(problemGroup.lps)) {
      location.href = "/";
      return;
    }

    const params = {
      orderId: problemGroup.orderId,
      groupId: problemGroup.groupId,
      orderType: problemGroup.orderType,
      problemSwapRequests: buildProblemSwapRequests(problemGroup.problemBookings, replacements),
      hasBonus: orderHasBonusStyle(problemGroup.problemBookings, replacements),
    };

    dispatchAction(dispatch, constants.SWAPS_PERFORM_SWAP_BEGIN, params);

    return performSwapClient(params)
      .then(({ data }) => {
        // If empty array of successes treat as failure
        if (data.successfulProblemSwapRequests.length < 1) {
          dispatchAction(dispatch, constants.SWAPS_PERFORM_SWAP_FAILURE, "Empty swaps successes");
          toggleErrorModal(true)(dispatch);
          return;
        }
        dispatchAction(dispatch, constants.SWAPS_PERFORM_SWAP_SUCCESS, data);

        // if had bonus, add bonus param to target href
        const bookingCounts = data.successfulProblemSwapRequests.reduce(
          (memo, curr) => {
            memo.problems.push(curr.problemBooking);
            memo.replacements = [...memo.replacements, ...curr.replacementHolds];

            return memo;
          },
          { problems: [], replacements: [] }
        );

        const hadBonusSwap = bookingCounts.replacements.length > bookingCounts.problems.length;

        const bonusSwapParam = hadBonusSwap ? "&bonusSwap=true" : "";

        redirectToRoute(Swaps.success + bonusSwapParam);
      })
      .catch(({ jqXHR }) => {
        dispatchAction(dispatch, constants.SWAPS_PERFORM_SWAP_FAILURE, jqXHR);
        const errorModalFunction = () => toggleErrorModal(true)(dispatch);

        // For the cases where a customer gets into a state
        // where they've already swapped but then are stuck in loop of error modals
        // Send in null for goal route because there isn't a goal route for modal to be popped
        swapsFlowNextStep(problemGroup, null, Swaps.success, errorModalFunction)(dispatch);
      });
  };
};

const createExchangeBagClient = params => {
  return createAjaxAuthRetry({
    type: "POST",
    url: Exchanges.exchangeBag,
    data: params,
    headers: {
      Accept: "application/json",
    },
  });
};

export const createExchangeBag = (group, newAddressId, newLPS, problemBookingIds) => {
  return dispatch => {
    // newAddressId should be null if its not passed in
    const addressId = newAddressId || group?.address?.addressId;

    const usableLPS = newLPS || group.bagCutoffTime;

    // the backend for bag create expects rentBegin and rentEnd to be empty strings
    const params = {
      groupId: group.groupId,
      orderId: group.orderId,
      problemItemBookings: problemBookingIds,
      latestPossibleShipDate: usableLPS,
      rentBegin: "",
      rentEnd: "",
      addressId: addressId,
    };

    dispatchAction(dispatch, constants.EXCHANGE_CREATE_BAG_BEGIN, group);

    return createExchangeBagClient(params)
      .then(({ data }) => {
        dispatchAction(dispatch, constants.EXCHANGE_CREATE_BAG_SUCCESS, data);
        redirectToRoute(Exchanges.gridWithGroupId + group.groupId);
      })
      .catch(({ jqXHR }) => {
        dispatchAction(dispatch, constants.EXCHANGE_CREATE_BAG_FAILURE, jqXHR);
      });
  };
};

const actions = {
  constants,
  buildCategories,
  buildBaseFilters,
  safelyParseJSON,
  fetchUserProblemGroup,
  fetchUserProblemGrid,
  swapsFlowNextStep,
  toggleMobileFilterExpansion,
  fetchSwapsCms,
  fetchProducts,
  fetchProductsWithCallback,
  fetchReplacements,
  toggleFilterExpansion,
  setMainGridCategory,
  clearFilters,
  setFilter,
  setSizeFilterFromWorkingFilters,
  setSort,
  setSwapGridPage,
  toggleSwapBannerExpansion,
  toggleExitModal,
  toggleErrorModal,
  toggleAddToBagErrorModal,
  toggleSuccessModal,
  toggleBTSModal,
  togglePaymentModal,
  toggleBagExpansion,
  toggleHeartFilter,
  orderHasBonusStyle,
  addReplacement,
  removeReplacement,
  performSwap,
  createExchangeBag,
};

export default actions;
