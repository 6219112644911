import PropTypes from "prop-types";
import Close from "images/close.svg";
import CloseWhite from "images/close_white.svg";
import { renderImportedImage } from "helpers/storefront-next-transition-helpers";

export default function ModalCloseButton({ color = "black", onClick }) {
  const image = color === "white" ? CloseWhite : Close;

  return (
    <button className="modal-close" data-test-id="modal-close" onClick={onClick} aria-label="close">
      {renderImportedImage(image)}
    </button>
  );
}

ModalCloseButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.oneOf(["black", "white"]),
};
