import React from "react";
import PropTypes from "prop-types";
import userProblemsActions from "../../../actions/user-problems-actions";
import sharedActions from "../../../actions/shared-actions";
import OrderDetailsModal from "./OrderDetailsModal";
import { ORDER_DETAILS_MODAL, contentTransform, canShip, hasEligibleProblemBookings } from "helpers/swaps-helper";
import ActionLogger from "action-logger";
import { browserPropType, userDataPropType, userProblemsPropType } from "../../propTypes";
import { isIdentified } from "../hoc/with-user-data";
import { onEnterPress } from "helpers/a11y-helper";
import { compose } from "redux";
import {
  flagsAndExperimentsPropType,
  withOnLoadFlagsAndExperiments,
  flagsAndExperimentNames,
} from "../hoc/with-flags-and-experiments";

// To be removed as part of https://renttherunway.jira.com/browse/RSV-989
export class SwapsBanner extends React.Component {
  static propTypes = {
    browser: browserPropType,
    children: PropTypes.node,
    dispatch: PropTypes.func.isRequired,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    userData: userDataPropType,
    userProblems: userProblemsPropType,
    flagsAndExperiments: flagsAndExperimentsPropType,
  };

  state = {
    fetchProblems: false,
  };

  componentDidUpdate(prevProps) {
    const { dispatch, userProblems = {}, userData } = prevProps;
    const { fetchProblems } = this.state;

    if (!fetchProblems && !Object.keys(userProblems).length && isIdentified(userData)) {
      dispatch(userProblemsActions.fetchUserProblemGroup());
      this.setState({ fetchProblems: true });
    }
  }

  isEligible() {
    if (!this.props.userProblems?.problemGroup) {
      return false;
    }

    // API Error Check
    const {
      userProblems: {
        problemGroup: { problemBookings },
        swapsContent,
      },
    } = this.props;
    if (!problemBookings || problemBookings.length < 1 || !swapsContent) {
      return false;
    }

    // Eligibility Check
    return hasEligibleProblemBookings(problemBookings);
  }

  getHideButton() {
    const {
      userProblems: {
        problemGroup: { lps },
      },
    } = this.props;
    let hideButton = false;

    // Time Disappear Check
    if (!canShip(lps)) {
      hideButton = true;
    }

    return hideButton;
  }

  getTitle() {
    if (!this.isEligible()) {
      return null;
    }
    const {
      userProblems: {
        problemGroup: { lps },
        problemGroup,
        swapsContent: { banner },
      },
    } = this.props;

    let { title } = banner;

    // Time Disappear Check
    if (!canShip(lps)) {
      title = contentTransform(banner.cutoffTitle, banner, problemGroup);
    }

    return title;
  }

  getMessage() {
    if (!this.isEligible()) {
      return null;
    }
    const {
      userProblems: {
        problemGroup: { lps },
        problemGroup,
        swapsContent: { banner },
      },
    } = this.props;
    const message = contentTransform(banner.message, banner, problemGroup);
    if (message === "") {
      return "";
    }

    // Time Disappear Check
    if (!canShip(lps)) {
      return contentTransform(banner.cutoffMessage, banner, problemGroup);
    }

    return message;
  }

  toggleExpanded = () => {
    const {
      dispatch,
      userProblems: { expanded },
    } = this.props;
    userProblemsActions.toggleSwapBannerExpansion(!expanded)(dispatch);
  };

  launchModal() {
    const { dispatch } = this.props;

    ActionLogger.logAction({
      node: "swaps",
      action: "enter_flow",
      object_type: "problem_order",
      retail_geo: false,
    });

    dispatch(sharedActions.displayModal(ORDER_DETAILS_MODAL));
  }

  renderModal() {
    return (
      <OrderDetailsModal
        browser={this.props.browser}
        displayedModal={this.props.displayedModal}
        dispatch={this.props.dispatch}
        userProblems={this.props.userProblems}
      />
    );
  }

  renderButton() {
    const {
      userProblems: { problemGroup },
    } = this.props;

    const findReplacementCTA = problemGroup.bonusSwap ? "Find Replacements" : "Find Replacement";

    return (
      <button className="btn-secondary swaps-banner__button" onClick={this.launchModal.bind(this)}>
        {findReplacementCTA}
      </button>
    );
  }

  renderExpanded() {
    if (this.props.flagsAndExperiments?.[flagsAndExperimentNames.RESERVE_913_PROBLEM_ORDER_FLOW]) {
      return (
        <div className="swaps-banner__container">
          <div className="swaps-banner__icon swaps-banner__icon--up"></div>
          <h3 className="swaps-banner__title body">{this.getTitle()}</h3>
          <div className="swaps-banner__message">
            We’re sorry for the inconvenience.{" "}
            <a
              href="http://www.renttherunway.com/help/faq?_=1&customerpromise=true&placement=banner"
              target="_blank"
              style={{ textDecoration: "underline" }}
              rel="noreferrer">
              Contact us
            </a>{" "}
            to get a replacement at no additional cost.
          </div>
        </div>
      );
    }

    return (
      <div className="swaps-banner__container">
        <div className="swaps-banner__icon swaps-banner__icon--up"></div>
        <h3 className="swaps-banner__title body">{this.getTitle()}</h3>
        <div className="swaps-banner__message">{this.getMessage()}</div>
        <div hidden={this.getHideButton()} className="swaps-banner--center-margin">
          {this.renderButton()}
        </div>
      </div>
    );
  }

  renderNonExpanded() {
    return (
      <div className="swaps-banner__collapse-container">
        <div className="swaps-banner--flex">
          <h4 className="swaps-banner__title swaps-banner__title--collapsed body">{this.getTitle()}</h4>
        </div>
        <div className="swaps-banner__icon swaps-banner__icon--down"></div>
      </div>
    );
  }

  render() {
    if (!this.getMessage()) {
      return this.props.children;
    }

    const {
      userProblems: { expanded },
    } = this.props;
    return (
      <div
        tabIndex={0}
        className="swaps-banner"
        role="button"
        onClick={this.toggleExpanded}
        onKeyDown={onEnterPress(this.toggleExpanded)}
        data-test-id="swaps-banner">
        {expanded && this.renderExpanded()}
        {!expanded && this.renderNonExpanded()}
        {this.renderModal()}
      </div>
    );
  }
}

export const SwapsBannerComponent = compose(
  withOnLoadFlagsAndExperiments(flagsAndExperimentNames.RESERVE_913_PROBLEM_ORDER_FLOW)
)(SwapsBanner);

export default SwapsBannerComponent;
