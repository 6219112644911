import { format } from "date-fns";
import _ from "underscore";
import MembershipHelpers from "helpers/membership-helpers";
import { isPausedWithItems, isFuturePausedWithItems } from "helpers/membership-plan-helpers";
import FormattingUtils, { pluralize } from "helpers/FormattingUtils";
import { parseISOWithoutTime } from "./date-helpers";
import { priceStringIntoFloat } from "helpers/invoice-helper";
import { retailPriceFormatted, usdPriceIntoInt } from "helpers/PricingHelper.js";
import Constants from "rtr-constants";
import ActionLogger from "action-logger";

const FREE_SPOT_PROMO_CONSTANT = "FREESPOT";

// 1/22/2020 ALAN CHEN - Do not use these Subscription Mod Plan Code constants outside of this file
// These codes should ideally be moved to the backend
const MIDDLE_TIER_REVISION_ID = 2;
const UNLIMITED_BASE_SLOT_UPGRADE = "UNLIMITED_BASE_SLOT_UPGRADE";
const MIDDLE_TIER_BASE_SLOT_UPGRADE = "MIDDLE_TIER_BASE_SLOT_UPGRADE";
const BASE_SLOT_UPGRADE = "BASE_SLOT_UPGRADE";

var MembershipUpgradesHelpers = {
  // NW [EXPLANATION] 11/30/18: get the number of slots the user will upgrade the membership to by adding another slot to bag
  getUpgradeUpsellSlotCount(membershipState = {}, membershipBag = {}) {
    return this.getUpgradePendingSlotCount(membershipState, membershipBag) + 1;
  },

  getUpgradeUpsellSlotCountCE(membershipState = {}, ceBagCount = {}) {
    const itemsInCEBag = ceBagCount?.members ?? 0;
    return this.getUpgradePendingSlotCountCE(membershipState, itemsInCEBag) + 1;
  },

  // NW [EXPLANATION] 11/30/18: get the number of upgrade items and slots already in the user's bag, but not yet submitted for membership upgrade, excluding one-time bonus items
  getUpgradesInBag(membershipState = {}, membershipBag = {}) {
    const numBaseSlots = MembershipHelpers.fetchNumBaseSlots(membershipState);
    const itemsInBag = MembershipHelpers.fetchItemsInBag(membershipBag);
    const numOpenSlots = MembershipHelpers.fetchNumOpenSlots(membershipState);
    const oneTimeBonusSlotsInBag = numOpenSlots - numBaseSlots;
    const nonBonusExtraItemsInBag = itemsInBag - numBaseSlots - oneTimeBonusSlotsInBag;

    return Math.max(nonBonusExtraItemsInBag, 0);
  },

  getNumBonusSlotsInMembershipState(membershipState) {
    return membershipState?.slots?.filter(slot => slot?.type === "BONUS" || slot?.type === "ONE_TIME_BONUS").length;
  },

  getUpgradesInCEBag(membershipState = {}, itemsInBag = 0) {
    if (!itemsInBag) {
      return 0;
    }

    const numBaseSlots = MembershipHelpers.fetchNumBaseSlots(membershipState);
    const numOpenSlots = MembershipHelpers.fetchNumOpenSlots(membershipState);

    const oneTimeBonusSlotsInBag = numOpenSlots - numBaseSlots;
    const nonBonusExtraItemsInBag = itemsInBag - numBaseSlots - oneTimeBonusSlotsInBag;

    return Math.max(nonBonusExtraItemsInBag, 0);
  },

  getFreeSlotsInBag(membershipBag = {}) {
    // for Unlimited Referrals 2.0 launch, we will be making the assumption that
    // a promo that contains "FREESLOT" will be the only way to get one free slot

    const appliedPromoCode = membershipBag?.promoCode || "";
    const freeslotPromoApplied = appliedPromoCode.includes(FREE_SPOT_PROMO_CONSTANT);

    return freeslotPromoApplied ? 1 : 0;
  },

  getPaidUpgradesInBag(membershipState = {}, membershipBag = {}) {
    const numTotalUpgradesInBag = this.getUpgradesInBag(membershipState, membershipBag);
    const numFreeSlotsInBag = this.getFreeSlotsInBag(membershipBag);

    return numTotalUpgradesInBag - numFreeSlotsInBag;
  },

  // NW [EXPLANATION] 9/12/18: get the number of base slots already in the membership, plus any upgrade slots added to bag but not submitted yet
  getUpgradePendingSlotCount(membershipState = {}, membershipBag = {}) {
    const numBaseSlots = MembershipHelpers.fetchNumBaseSlots(membershipState);
    const upgradesInBag = this.getUpgradesInBag(membershipState, membershipBag);
    return numBaseSlots + upgradesInBag;
  },

  getUpgradePendingSlotCountCE(membershipState = {}, itemsInCEBag) {
    const numBaseSlots = MembershipHelpers.fetchNumBaseSlots(membershipState);
    const upgradesInBag = this.getUpgradesInCEBag(membershipState, itemsInCEBag);
    return numBaseSlots + upgradesInBag;
  },

  getUpgradePendingMonthlyCost(membershipState = {}, membershipBag = {}) {
    const currentMembershipPrice = membershipState?.recurringPrice;
    const costOfUpgrades = this.getUpgradeExtraMonthlyCost(membershipState, membershipBag);
    return priceStringIntoFloat(currentMembershipPrice) + costOfUpgrades;
  },

  getUpgradePendingMonthlyCostCE(membershipState = {}, itemsInBag = 0) {
    const currentMembershipPrice = membershipState?.recurringPrice ?? "USD 0.00"; // the fallback here should never happen but hey in case it does!
    const costOfUpgrades = this.getUpgradeExtraMonthlyCostCE(membershipState, itemsInBag);
    return priceStringIntoFloat(currentMembershipPrice) + costOfUpgrades;
  },

  getUpgradeExtraMonthlyCostCE(membershipState, itemsInCEBag = 0) {
    if (!membershipState || !itemsInCEBag) return null;

    return (
      priceStringIntoFloat(membershipState.baseSlotUpgradePrice) *
      this.getUpgradesInCEBag(membershipState, itemsInCEBag)
    );
  },

  getUpgradeExtraMonthlyCost(membershipState, membershipBag) {
    if (!membershipState || !membershipBag) {
      return null;
    }

    const costPerUpgrade = membershipState?.baseSlotUpgradePrice;
    const upgradesInBag = this.getPaidUpgradesInBag(membershipState, membershipBag);
    return priceStringIntoFloat(costPerUpgrade) * upgradesInBag;
  },

  getUpgradeAnnotationAppendCE(membershipState = {}, userData = {}, ceBagCount, unlimitedAlwaysOnAddOnsFF = false) {
    if (this.isViewingUpgradePricing(membershipState, userData, ceBagCount, unlimitedAlwaysOnAddOnsFF)) {
      const itemsInCEBag = ceBagCount?.members ?? 0;
      const upsellItemNumber = this.getUpgradePendingSlotCountCE(membershipState, itemsInCEBag) + 1;
      const nthItemText = `${FormattingUtils.appendOrdinalSuffix(upsellItemNumber)} spot`;
      return nthItemText;
    }
  },

  getUpgradeAnnotationAppend(
    membershipState = {},
    membershipBag = {},
    userData = {},
    unlimitedAlwaysOnAddOnsFF = false
  ) {
    if (this.isViewingUpgradePricing(membershipState, userData, null, unlimitedAlwaysOnAddOnsFF)) {
      const upsellItemNumber = this.getUpgradeUpsellSlotCount(membershipState, membershipBag);
      const nthItemText = `${FormattingUtils.appendOrdinalSuffix(upsellItemNumber)} spot`;
      return nthItemText;
    }
  },

  isViewingUpgradePricing(membershipState = {}, userData = {}, ceBagCount = null, unlimitedAlwaysOnAddOnsFF = false) {
    const isSubscriptionMember = MembershipHelpers.isSubscriptionMember(userData);
    const isUnlimitedMember = MembershipHelpers.isUnlimitedMember(userData);
    const isSubscriptionLens = MembershipHelpers.isSubscriptionLens(userData);
    const subscriberHasNoShipmentAction = MembershipHelpers.hasNoShipmentAction(membershipState);

    if (!MembershipHelpers.isEligibleToPick(membershipState)) {
      return false;
    } else {
      if (unlimitedAlwaysOnAddOnsFF && isUnlimitedMember && subscriberHasNoShipmentAction) {
        return isSubscriptionLens;
      }
      return isSubscriptionMember && isSubscriptionLens && MembershipHelpers.isBagFullCe(membershipState, ceBagCount);
    }
  },

  hasBagFullnessChanged(previousProps, nextProps) {
    const bagWasPreviouslyFull = MembershipHelpers.isBagFull(
      previousProps.membershipState,
      previousProps.membershipBag
    );
    const newBagIsFull = MembershipHelpers.isBagFull(nextProps.membershipState, nextProps.membershipBag);

    return bagWasPreviouslyFull !== newBagIsFull;
  },

  // The above version of this function is using an old piece of state, "membershipBag", to look at bag count.
  // In that case, count will always be 0 because that object no longer holds bag count state since BagCe was introduced.
  // Below function uses a more up-to-date bag count source.
  // This new function is a stopgap since the changes needed to update the old functions would break scope of this ticket.
  // Larger clean up ticket to delete this and fix original functions & their dependencies: https://renttherunway.jira.com/browse/CE-1690
  hasBagFullnessChangedCe(previousProps, nextProps) {
    const bagWasPreviouslyFull = MembershipHelpers.isBagFullCe(previousProps.membershipState, previousProps.ceBagCount);
    const newBagIsFull = MembershipHelpers.isBagFullCe(nextProps.membershipState, nextProps.ceBagCount);

    return bagWasPreviouslyFull !== newBagIsFull;
  },

  shouldRefreshUpgradePricing(previousProps, nextProps) {
    return MembershipUpgradesHelpers.hasBagFullnessChanged(previousProps, nextProps);
  },

  // The above version of this function is using an old piece of state, "membershipBag", to look at bag count.
  // In that case, count will always be 0 because that object no longer holds bag count state since BagCe was introduced.
  // Below function uses a more up-to-date bag count source.
  // This new function is a stopgap since the changes needed to update the old functions would break scope of this ticket.
  // Larger clean up ticket to delete this and fix original functions & their dependencies: https://renttherunway.jira.com/browse/CE-1690
  shouldRefreshUpgradePricingCe(previousProps, nextProps) {
    return Boolean(
      previousProps.membershipState?.id &&
        nextProps.membershipState?.id &&
        MembershipUpgradesHelpers.hasBagFullnessChangedCe(previousProps, nextProps)
    );
  },

  getUpgradePricingTooltipContent(
    membershipState = {},
    userData = {},
    unlimitedAlwaysOnAddOnsFF = false,
    ceBagCount = null
  ) {
    if (this.isViewingUpgradePricing(membershipState, userData, ceBagCount, unlimitedAlwaysOnAddOnsFF)) {
      const upgradeItemCount = this.getUpgradeUpsellSlotCountCE(membershipState, ceBagCount);
      const upgradeSlotPrice = membershipState?.baseSlotUpgradePrice;
      const upgradeSlotPriceFormatted = retailPriceFormatted(upgradeSlotPrice);
      const upgradeRecurringPrice = this.getUpgradeRecurringPrice(membershipState, upgradeItemCount);
      const upgradeFuturePriceFormatted = retailPriceFormatted(upgradeRecurringPrice.toString());
      const indefiniteArticle = FormattingUtils.preNumericIndefiniteArticle(upgradeItemCount);

      return `Add this style to upgrade to ${indefiniteArticle} ${upgradeItemCount}-spot plan for ${upgradeSlotPriceFormatted}. Your plan renews at ${upgradeFuturePriceFormatted} next month.`;
    }
    return null;
  },

  logUpgradePricingTooltipClick(style, membershipState) {
    const membershipType = membershipState?.membershipType;

    ActionLogger.logAction({
      object_type: Constants.analytics.OBJECT_TYPE.ADD_ON,
      action: Constants.analytics.ADD_ON_ACTIONS.TOOLTIP,
      membership_type: membershipType,
      style,
      source: Constants.analytics.ACTION_LOCATIONS.PDP,
    });
  },

  /**
   * Log data to our internal pixels and to our third party event tracking system
   * @param {Object} membershipState - The MembershipState containing all relevant information for membership experience
   * @param {String} source - Where the user took action on the upgrate tile and is used for internal pixels
   */
  logUpgradeAddSlots(membershipState, source) {
    const membershipType = membershipState?.membershipType;
    ActionLogger.logAction({
      object_type: Constants.analytics.OBJECT_TYPE.ADD_ON,
      action: Constants.analytics.ADD_ON_ACTIONS.ADD_SLOTS,
      membership_type: membershipType,
      source: source,
    });
  },

  // the price the user will pay per-month with the specified number of items in their membership
  getUpgradeRecurringPrice(membershipState, prospectiveSlotCount) {
    const currentMembershipPrice = usdPriceIntoInt(membershipState?.recurringPrice);
    const upgradeSlotPrice = usdPriceIntoInt(membershipState?.baseSlotUpgradePrice);
    const paidSlotCountOnNextBillingDate = membershipState?.paidSlotCountOnNextBillingDate;
    const upgradeSlots = prospectiveSlotCount - paidSlotCountOnNextBillingDate;
    const upgradeSlotsCost = upgradeSlots * upgradeSlotPrice;

    return currentMembershipPrice + upgradeSlotsCost;
  },

  getFuturePlanChangeNotification(membershipState = {}) {
    if (
      isPausedWithItems(membershipState) ||
      isFuturePausedWithItems(membershipState) ||
      MembershipHelpers.isFixedSwaps(membershipState)
    ) {
      return null;
    }

    const baseSlotCount = membershipState?.baseSlotCount;
    const paidSlotCountOnNextBillingDate = membershipState?.paidSlotCountOnNextBillingDate;
    const slotsFromReward = membershipState?.slotsFromReward || [];
    const paidSlotCountInCurrentTerm = baseSlotCount - slotsFromReward.length;
    const nextBillingDate = MembershipHelpers.fetchNextPickDate(membershipState);
    if (paidSlotCountOnNextBillingDate && nextBillingDate) {
      const nextBillingDateFormatted = this.getNextBillingDateFormatted(nextBillingDate);

      if (paidSlotCountOnNextBillingDate !== paidSlotCountInCurrentTerm) {
        return {
          name: "planChange",
          text: `Your ${paidSlotCountOnNextBillingDate}-spot plan begins ${nextBillingDateFormatted}.`,
        };
      }
    }
    return null;
  },

  getRewardSlotExpirationDates(membershipState = {}) {
    if (_.isEmpty(membershipState?.slotsFromReward)) {
      return null;
    }

    const slotsFromReward = membershipState?.slotsFromReward || [];
    const expirationDate = _.first(slotsFromReward).endsOn;

    return expirationDate;
  },

  getNextBillingDateFormatted(nextBillingDate) {
    if (!nextBillingDate) {
      return null;
    }

    return format(parseISOWithoutTime(nextBillingDate), Constants.dateFnsFormats.M_DD);
  },

  getPlanChangeDetails(membershipState, membershipBag) {
    if (!membershipState || !membershipBag) {
      return null;
    }

    const numberAdditionalPaidSpots = MembershipUpgradesHelpers.getPaidUpgradesInBag(membershipState, membershipBag);
    const isPlural = numberAdditionalPaidSpots > 1;
    const spotsCopy = isPlural ? "spots" : "spot";
    const monthlyTotal = MembershipUpgradesHelpers.getUpgradePendingMonthlyCost(membershipState, membershipBag);
    const numberFreeSpots = this.getFreeSlotsInBag(membershipBag);
    const paidSlotCopy = `Your ${numberAdditionalPaidSpots} extra ${spotsCopy} will bring your bill to $${monthlyTotal}/month.`;

    if (numberFreeSpots && numberAdditionalPaidSpots) {
      const expiresCopy = numberFreeSpots > 1 ? "expire" : "expires";
      return `Your free extra ${pluralize("spot", numberFreeSpots)} ${expiresCopy} in 30 days. ${paidSlotCopy}`;
    }

    if (numberFreeSpots) {
      return `Enjoy your extra ${pluralize("spot", numberFreeSpots)} for the next 30 days!`;
    }

    return paidSlotCopy;
  },

  getPlanChangeDetailsCE(membershipState, itemsInBag = 0) {
    if (!membershipState) {
      return null;
    }

    let result = "";

    const numBonusSlots = this.getNumBonusSlotsInMembershipState(membershipState);
    // numUpgradesInBag does NOT include bonus slots
    const numUpgradesInBag = this.getUpgradesInCEBag(membershipState, itemsInBag);
    const monthlyTotal = MembershipUpgradesHelpers.getUpgradePendingMonthlyCostCE(membershipState, itemsInBag);

    if (numBonusSlots > 0) {
      const numBonusSpotsGreaterThanOne = numBonusSlots > 1;
      result += `Your free extra ${numBonusSpotsGreaterThanOne ? "spots" : "spot"} ${
        numBonusSpotsGreaterThanOne ? "expire" : "expires"
      } in 30 days. `;
    }

    return (
      result +
      `Your ${numUpgradesInBag} extra ${
        numUpgradesInBag > 1 ? "spots" : "spot"
      } will bring your bill to $${monthlyTotal}/month.`
    );
  },

  getFormattedUpgradeSlotPrice(membershipState) {
    return retailPriceFormatted(membershipState?.baseSlotUpgradePrice);
  },

  isMembershipUpgradedWithPaidSlots(membershipState) {
    const { slotsFromReward = [] } = membershipState;
    const hasFutureUpgrade = membershipState?.paidSlotCountOnNextBillingDate > Constants.membershipBaseSlots.MINIMUM;
    const hasCurrentUpgrade =
      membershipState.baseSlotCount - slotsFromReward.length > Constants.membershipBaseSlots.MINIMUM;

    return hasFutureUpgrade || hasCurrentUpgrade;
  },

  /**
   * Please do not use membershipTierRevisionId in a similar fashion. All of this logic should eventually be moved to the backend.
   * We want to determine which subscription mod plan code to use in our upgrade/downgrade membership action
   * Only middle tier is guaranteed have a membershipTierRevisionId until we backfill the other memberships
   * If a membershipTierRevisionId is not present then we will fallback to the membership type check
   * Relevant BE Ticket with additional context: https://renttherunway.jira.com/browse/QU-11225
   * @param {String} membershipType
   * @param {Number} membershipTierRevisionId - unique identifer to determine which historical membership the user is currently in
   */
  determineSubscriptionModPlanCode(membershipType, membershipTierRevisionId) {
    if (membershipTierRevisionId === MIDDLE_TIER_REVISION_ID) {
      return MIDDLE_TIER_BASE_SLOT_UPGRADE;
    }

    return MembershipHelpers.isUnlimitedMembershipType(membershipType)
      ? UNLIMITED_BASE_SLOT_UPGRADE
      : BASE_SLOT_UPGRADE;
  },

  getMembershipUpgradeActionAttributes(membershipType, paidSlotCount, effectiveOn, membershipTierRevisionId) {
    const subscriptionModPlanCode = this.determineSubscriptionModPlanCode(membershipType, membershipTierRevisionId);

    return {
      effectiveOn,
      paidSlotCount,
      subscriptionModPlanCode,
      subscriptionDiscountCategory: "UPGRADE",
      subscriptionDiscountReason: "BASE_SLOT_UPGRADE",
    };
  },
};

export default MembershipUpgradesHelpers;

export const {
  getUpgradeUpsellSlotCount,
  getUpgradeUpsellSlotCountCE,
  getUpgradesInBag,
  getFreeSlotsInBag,
  getPaidUpgradesInBag,
  getUpgradePendingSlotCount,
  getUpgradePendingMonthlyCost,
  getUpgradeExtraMonthlyCost,
  getUpgradeAnnotationAppend,
  getUpgradeAnnotationAppendCE,
  isViewingUpgradePricing,
  hasBagFullnessChanged,
  shouldRefreshUpgradePricing,
  shouldRefreshUpgradePricingCe,
  getUpgradePricingTooltipContent,
  logUpgradePricingTooltipClick,
  logUpgradeAddSlots,
  getUpgradeRecurringPrice,
  getFuturePlanChangeNotification,
  getRewardSlotExpirationDates,
  getNextBillingDateFormatted,
  getPlanChangeDetails,
  getPlanChangeDetailsCE,
  getFormattedUpgradeSlotPrice,
  isMembershipUpgradedWithPaidSlots,
  determineSubscriptionModPlanCode,
  getMembershipUpgradeActionAttributes,
} = MembershipUpgradesHelpers;
