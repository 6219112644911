import { ThirdPartyScript } from "./thirdPartyScript";
import { shallowEqual, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

const COOKIE_NAME = "RTR_ID";

const signifyd = () => {
  const publicEnv = useSelector(state => state.publicEnv, shallowEqual);
  const [cookies] = useCookies([]);

  if (publicEnv?.signifydSessionPrefix) {
    return (
      <ThirdPartyScript
        id="sig-api"
        data-order-session-id={`${publicEnv.signifydSessionPrefix + cookies[COOKIE_NAME]}`}
        strategy="lazyOnload"
        src="https://cdn-scripts.signifyd.com/api/script-tag.js"
      />
    );
  } else {
    return <></>;
  }
};

export default signifyd;
