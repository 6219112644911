import * as Castle from "@castleio/castle-js";
import Cookies from "universal-cookie";
import { SessionStorage } from "site/localStorage";

// Allows us to spy with Jest
// https://stackoverflow.com/questions/45111198/how-to-mock-functions-in-the-same-module-using-jest
import * as castleHelper from "./castle-helper";
import { COOKIES } from "rtr-constants";

const SESSION_STORAGE_NAMESPACE = "devices";
const SESSION_STORAGE_KEY = "deviceUserJWT";
const CASTLE_REQUEST_TOKEN_EXPIRATION_SECONDS = 120;
export const CASTLE_REQUEST_TOKEN_REFRESH_INTERVAL_MILLISECONDS = (CASTLE_REQUEST_TOKEN_EXPIRATION_SECONDS / 2) * 1000;

const cookies = new Cookies();

export const SessionStorageClient = new SessionStorage(SESSION_STORAGE_NAMESPACE);

let isSDKConfigured = false;

export function getIsSDKConfigured() {
  return Boolean(isSDKConfigured);
}
export function setIsSDKConfigured(status) {
  isSDKConfigured = Boolean(status);
}

// @see {https://docs.castle.io/docs/sdk-browser#creating-request-tokens}
export async function setCastleRequestTokenCookie() {
  // This allows us to not override the cookie as we are moving about pages and waiting for the loadCastleSDK function
  // to be called.
  if (!castleHelper.getIsSDKConfigured()) {
    console.debug("Skipping setting Castle SDK request token because the SDK is not configured");
    return;
  }

  try {
    const requestToken = await Castle.createRequestToken();
    if (requestToken) {
      // Castle request tokens expire after 120 seconds
      const date = new Date();
      date.setSeconds(date.getSeconds() + CASTLE_REQUEST_TOKEN_EXPIRATION_SECONDS);
      // @see {https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie#setname-value-options}
      cookies.set(COOKIES.RTR_CASTLE_REQUEST_TOKEN, requestToken, {
        expires: date,
        path: "/",
      });
    }

    return requestToken;
  } catch (e) {
    console.error("Unable to create request token", e);
    return null;
  }
}

export function getDeviceUserJWT() {
  return SessionStorageClient.get(SESSION_STORAGE_KEY);
}

export function setDeviceUserJWT(user) {
  if (user?.deviceUserJWT) {
    SessionStorageClient.set(SESSION_STORAGE_KEY, user.deviceUserJWT);
  }
}

export function deleteDeviceUserJWT() {
  SessionStorageClient.remove(SESSION_STORAGE_KEY);
}

export function logPageView() {
  if (!castleHelper.getIsSDKConfigured()) {
    console.debug("Skipping Castle page view event log because the SDK is not configured");
    return;
  }

  const deviceUserJWT = castleHelper.getDeviceUserJWT();
  if (deviceUserJWT) {
    Castle.page({ userJwt: deviceUserJWT });
  }
}

export async function logCustomEvent(data = {}) {
  if (!castleHelper.getIsSDKConfigured()) {
    console.debug(`Skipping Castle ${data?.name || "custom"} event log because the SDK is not configured`);
    return;
  }

  const deviceUserJWT = castleHelper.getDeviceUserJWT();
  if (deviceUserJWT) {
    try {
      const result = await Castle.custom({ userJwt: deviceUserJWT, ...data });
      if (!result) {
        console.error(`Castle.custom failed to save event ${data.name}`, result);
      }
    } catch (e) {
      console.error(`Castle.custom failed to save event ${data.name}`, e);
    }
  }
}

export async function loadCastleSDK(castlePk) {
  if (castleHelper.getIsSDKConfigured()) {
    console.debug("Skipping Castle SDK initialization because it's already configured");
    return;
  }

  Castle.configure({ pk: castlePk });
  castleHelper.setIsSDKConfigured(true);

  // Update the token as soon as the SDK is configured on page load
  await castleHelper.setCastleRequestTokenCookie();

  // Because of our heavy reliance on $.ajax, it's nearly impossible to add asynchronous middleware without rewriting
  // all of our API requests to use an abstract function since the underlying XMLHttpRequest API does not use
  // promises. Instead, just going to use an interval to eagerly update the cookie (about a minute before the last
  // token expires).
  // NOTE: explicitly passing an arrow function to setInterval because of the following:
  // https://developer.mozilla.org/en-US/docs/Web/API/setInterval#the_this_problem
  setInterval(() => castleHelper.setCastleRequestTokenCookie(), CASTLE_REQUEST_TOKEN_REFRESH_INTERVAL_MILLISECONDS);

  // fire page view event after configured
  castleHelper.logPageView();
}
