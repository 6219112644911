/**
 * This calls a next-auth route /api/auth/signout to clean the next-auth.session-token cookie
 * @returns {Promise<*>}
 * @see {https://next-auth.js.org/getting-started/client#signout}
 */
export async function cleanSession() {
  // Using a dynamic import for now because next-auth seems to try to use 'process.env'
  // browser-side when the Cypress tests run and load next-auth/react?
  // Not sure why.
  const { signOut } = await import("next-auth/react");
  return signOut();
}

/**
 * This will navigate the user to the SF Provider sign in page, and then return them to where they were after successful sign in.
 * @param {object} [options] options to be passed to NextAuth signIn
 * @returns {Promise<*>}
 * @see {https://next-auth.js.org/getting-started/client#signin}
 */
export async function signIn(options = {}) {
  // Using a dynamic import for now because next-auth seems to try to use 'process.env'
  // browser-side when the Cypress tests run and load next-auth/react?
  // Not sure why.
  const { signIn } = await import("next-auth/react");
  return signIn("storefront", options);
}
