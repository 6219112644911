import $ from "clients/RawClient";
import ActionTypes from "actions/action-types.js";
import { createAction } from "redux-actions";

var actions = {
  openProductDrawer: createAction(ActionTypes.PRODUCT_DRAWER_OPEN),
  closeProductDrawer: createAction(ActionTypes.PRODUCT_DRAWER_CLOSE),
  fetchFitSummarySuccess: createAction(ActionTypes.PRODUCT_DRAWER_FETCH_FIT_SUMMARY_SUCCESS),
  fetchFitSummaryError: createAction(ActionTypes.PRODUCT_DRAWER_FETCH_FIT_SUMMARY_ERROR),
  fetchCustomerReviewsSuccess: createAction(ActionTypes.PRODUCT_DRAWER_FETCH_CUSTOMER_REVIEWS_SUCCESS),
  fetchCustomerReviewsError: createAction(ActionTypes.PRODUCT_DRAWER_FETCH_CUSTOMER_REVIEWS_ERROR),

  getFitSummary: function (productId) {
    return function (dispatch) {
      $.ajax({
        url: `/product_reviews/${productId}/fit_summary`,
        type: "GET",
        headers: {
          Accept: "application/json",
        },
      }).then(
        function (data) {
          dispatch(actions.fetchFitSummarySuccess(data));
        },
        function (data) {
          dispatch(actions.fetchFitSummaryError(data));
        }
      );
    };
  },

  getCustomerReviews: function (productId) {
    return function (dispatch) {
      $.ajax({
        url: `/product_reviews/${productId}`,
        type: "GET",
        headers: {
          Accept: "application/json",
        },
      }).then(
        function (data) {
          dispatch(actions.fetchCustomerReviewsSuccess(data));
        },
        function (data) {
          dispatch(actions.fetchCustomerReviewsError(data));
        }
      );
    };
  },
};

export default actions;

export const {
  openProductDrawer,
  closeProductDrawer,
  fetchFitSummarySuccess,
  fetchFitSummaryError,
  fetchCustomerReviewsSuccess,
  fetchCustomerReviewsError,
  getFitSummary,
  getCustomerReviews,
} = actions;
