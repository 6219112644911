import _ from "underscore";
import ActionTypes from "../actions/action-types.js";

const types = [
  ActionTypes.FETCH_PRODUCTS_BY_STYLES_BEGIN,
  ActionTypes.FETCH_PRODUCTS_BY_STYLES_SUCCESS,
  ActionTypes.FETCH_PRODUCTS_BY_STYLES_FAILURE,
];

const orderConfirmationModalModification = (state, action) => {
  let viewedProducts;
  switch (action.type) {
    case ActionTypes.FETCH_PRODUCTS_BY_STYLES_BEGIN:
    case ActionTypes.FETCH_PRODUCTS_BY_STYLES_FAILURE:
      viewedProducts = [];
      break;
    case ActionTypes.FETCH_PRODUCTS_BY_STYLES_SUCCESS:
      viewedProducts = action.payload;
      break;
  }

  return viewedProducts;
};

const fn = (state, action) => {
  return _.extend({}, state, { viewedProducts: orderConfirmationModalModification(state.viewedProducts, action) });
};

export default {
  types: types,
  fn: fn,
};
