import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const propTypes = {
  onClick: PropTypes.func,
};
const Overlay = props => {
  const { onClick } = props;

  return (
    // reason: the overlay itself should close itself when clicked, so shouldn't be a button
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={styles["highlighted-component-overlay"]}
      onClick={onClick}
      data-test-id="highlighted-component-overlay"
    />
  );
};

Overlay.propTypes = propTypes;
export default Overlay;
