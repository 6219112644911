import React from "react";
import MobileNavToggle from "./mobile-nav-toggle";
import MobileSearchBar from "./mobile-search-bar";
import MoleculeSwapCounter from "components/source/molecules/molecule-swap-counter";
import { hasUserData, userDataPropType } from "components/source/hoc/with-user-data";
import SmartNav from "./smart-nav";
import SearchBar from "./search-bar";
import SemanticSearchMobileNav from "./semantic-search-mobile-nav";
import SemanticSearchDesktopNav from "./semantic-search-desktop-nav";
import SmartTopRight from "./smart-top-right";
import UserMenu from "./user-menu";
import UserMenuMobile from "./user-menu-mobile";
import {
  toggleMobileNav,
  toggleMobileNavAccountMenu,
  toggleSearchExpanded,
  toggleSneakyHeaderVisible,
} from "actions/nav-actions";
import { fetchMembershipState } from "actions/membership-state-actions";
import classNames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { navigationPropType, membershipStatePropType } from "components/propTypes";
import _ from "underscore";
import { compose } from "redux";
import FlagsAndExperimentsActions from "../../../actions/flags-and-experiments-actions";
import {
  withFlagsAndExperiments,
  flagsAndExperimentNames,
  flagsAndExperimentsPropType,
} from "../hoc/with-flags-and-experiments";
import { aiSearchV1Prompts, pageTypes } from "rtr-constants";
import { loadCMSContent } from "../../../actions/shared-actions";

// TODO: wrap in withBrowser and eliminate custom breakpoint logic here
class NavContainer extends React.Component {
  static RESTRICTED_PAGE_TYPES = [
    pageTypes.CHECKOUT,
    pageTypes.NEW_CHECKOUT,
    pageTypes.REPLACEMENT,
    pageTypes.SUBSCRIPTION_RETURNS,
  ];
  static RESTRICTED_USER_MENU_PAGE_TYPES = [pageTypes.NEW_CHECKOUT];
  static WIDE_NAV_BREAKPOINT = 1105; // NW [EXPLANATION] 11/23/21: This is equal to $max-width-tucked-nav in _nav-header-sept-issue.scss
  static TABLET_BREAKPOINT = 480;

  static propTypes = {
    fetchMembershipStateDispatch: PropTypes.func,
    fetchFlagTreatment: PropTypes.func.isRequired,
    flagsAndExperiments: flagsAndExperimentsPropType,
    isMobileAccountMenuExpanded: PropTypes.bool,
    isMobileNavExpanded: PropTypes.bool,
    isNavSearchExpanded: PropTypes.bool,
    membershipState: membershipStatePropType,
    navigation: navigationPropType,
    pageType: PropTypes.string,
    toggleMobileNav: PropTypes.func.isRequired,
    toggleMobileNavAccountMenu: PropTypes.func,
    toggleSearchExpanded: PropTypes.func,
    toggleSneakyHeaderVisible: PropTypes.func,
    userData: userDataPropType,
    fetchAiSearchV1Prompts: PropTypes.func,
    aiSearchV1Prompts: PropTypes.any,
    aiRotatingPrompts: PropTypes.array,
  };

  state = {
    viewportWidth: null,
    hasFetchedMembershipState: false,
  };

  componentDidMount() {
    const { userData, membershipState, fetchMembershipStateDispatch, fetchFlagTreatment } = this.props;
    fetchFlagTreatment();
    this._handleResize();
    this.props.fetchAiSearchV1Prompts();

    window.addEventListener("resize", this.handleResize);

    if (hasUserData(userData) && _.isEmpty(membershipState)) {
      fetchMembershipStateDispatch();
      this.setState({ hasFetchedMembershipState: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  _handleResize = () => {
    this.setState({
      viewportWidth: window.innerWidth,
    });
  };
  handleResize = _.debounce(this._handleResize, 500);

  componentDidUpdate() {
    const { userData, membershipState, fetchMembershipStateDispatch } = this.props;

    if (hasUserData(userData) && _.isEmpty(membershipState) && !this.state.hasFetchedMembershipState) {
      fetchMembershipStateDispatch();
      this.setState({ hasFetchedMembershipState: true });
    }
  }

  toggleMobileNavAccountMenu = () => {
    if (this.props.isMobileAccountMenuExpanded) {
      this.props.toggleMobileNav(true);
      this.props.toggleMobileNavAccountMenu(false);
    } else {
      this.props.toggleMobileNavAccountMenu(!this.props.isMobileAccountMenuExpanded);
    }
  };

  renderDesktopNavSearch() {
    if (this.props.flagsAndExperiments?.[flagsAndExperimentNames.DISCO_SEARCH_AI_MVP_STOREFRONT_UX]) {
      return (
        <SemanticSearchDesktopNav
          toggleSearchExpanded={this.props.toggleSearchExpanded}
          isExpanded={this.props.isNavSearchExpanded}
          aiSearchPrompts={this.props.aiSearchV1Prompts}
          aiRotatingPrompts={this.props.aiRotatingPrompts}
        />
      );
    }

    return (
      <div className="search-bar--desktop">
        <SearchBar toggleSearchExpanded={this.props.toggleSearchExpanded} />
      </div>
    );
  }

  renderMobileNavSearch() {
    if (this.props.flagsAndExperiments?.[flagsAndExperimentNames.DISCO_SEARCH_AI_MVP_STOREFRONT_UX]) {
      return (
        <SemanticSearchMobileNav
          aiSearchPrompts={this.props.aiSearchV1Prompts}
          aiRotatingPrompts={this.props.aiRotatingPrompts}
        />
      );
    }

    return <MobileSearchBar />;
  }

  isWideWidth() {
    return this.state.viewportWidth > this.constructor.WIDE_NAV_BREAKPOINT;
  }

  isTabletWidth() {
    return this.state.viewportWidth > this.constructor.TABLET_BREAKPOINT;
  }

  renderNav() {
    if (NavContainer.RESTRICTED_PAGE_TYPES.includes(this.props.pageType?.toUpperCase())) {
      return null;
    }

    const { navigation } = this.props;

    return (
      <>
        <ul id="nav">
          {!this.isTabletWidth() && <li id="nav-search-collapsed-wrapper">{this.renderMobileNavSearch()}</li>}
          <SmartNav
            isWideWidth={this.isWideWidth()}
            loggedExperiments={this.state.loggedExperiments}
            navigation={navigation}
            toggleSneakyHeaderVisible={this.props.toggleSneakyHeaderVisible}
            userData={this.props.userData}
          />
          <li id="user-menu-collapsed-wrapper">
            <UserMenuMobile isMobileAccountMenuExpanded={this.props.isMobileAccountMenuExpanded} />
          </li>
        </ul>
        {this.isTabletWidth() && this.renderDesktopNavSearch()}
      </>
    );
  }

  renderUserMenu() {
    const { membershipState } = this.props;

    if (NavContainer.RESTRICTED_USER_MENU_PAGE_TYPES.includes(this.props.pageType?.toUpperCase())) {
      return (
        <div className="new-checkout-nav-title">
          <div className="h2 header-page-name">Checkout</div>
          <div className="h5 secure-icon">Secure Payment</div>
        </div>
      );
    }

    return (
      <>
        <div className="header-swap-counter">{!_.isEmpty(membershipState) && <MoleculeSwapCounter />}</div>
        <UserMenu
          isWideWidth={this.isWideWidth()}
          toggleMobileNavAccountMenu={this.toggleMobileNavAccountMenu}
          isMobileNavExpanded={this.props.isMobileNavExpanded}
          isMobileAccountMenuExpanded={this.props.isMobileAccountMenuExpanded}
          toggleMobileNav={this.props.toggleMobileNav}
        />
        <div className="customer-offer-item">
          <SmartTopRight />
        </div>
      </>
    );
  }

  renderOverlay() {
    if (this.isWideWidth()) return null;

    const { isMobileNavExpanded, toggleMobileNav } = this.props;

    // NW [EXPLANATION] 9/29/21: users with screens wider than TABLET_BREAKPOINT and narrower than WIDE_NAV_BREAKPOINT will see the mobile nav, but there will be an overlay to close the expanded nav for tablets
    const isWiderThanTabletBreakpoint = this.isTabletWidth(); // && !this.isWideWidth(), already done above

    const overlayClassName = classNames("overlay overlay-header", {
      visible: isWiderThanTabletBreakpoint && isMobileNavExpanded,
    });
    return (
      <div
        className={overlayClassName}
        onClick={() => toggleMobileNav(false)}
        onKeyDown={() => toggleMobileNav(false)}
        role="button"
        tabIndex={0}></div>
    );
  }

  render() {
    return (
      <div className="header-links">
        <MobileNavToggle
          isMobileAccountMenuExpanded={this.props.isMobileAccountMenuExpanded}
          isMobileNavExpanded={this.props.isMobileNavExpanded}
          toggleMobileNav={this.props.toggleMobileNav}
          toggleMobileNavAccountMenu={this.props.toggleMobileNavAccountMenu}
        />
        {this.renderNav()}
        <a className="nav-logo__mobile" href="/">
          RTR
        </a>
        {this.renderOverlay()}
        {this.renderUserMenu()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const searchPrompts = state.cmsContent?.[aiSearchV1Prompts.STATE_KEY]?.aiPrompts;
  const rotatePrompts = state.cmsContent?.[aiSearchV1Prompts.STATE_KEY]?.aiRotatingPrompts;
  const isMobile = state.browser?.isMobileViewport;
  const navContent = isMobile ? state.siteNavigationMobile : state.siteNavigation;

  return {
    isMobileAccountMenuExpanded: state.isMobileAccountMenuExpanded,
    isMobileNavExpanded: state.isMobileNavExpanded,
    isNavSearchExpanded: state.isNavSearchExpanded,
    navigation: navContent,
    pageType: state.pageType,
    userData: state.userData,
    membershipState: state.membershipState,
    aiSearchV1Prompts: searchPrompts,
    aiRotatingPrompts: rotatePrompts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleMobileNav: isExpanded => dispatch(toggleMobileNav(isExpanded)),
    toggleMobileNavAccountMenu: isExpanded => dispatch(toggleMobileNavAccountMenu(isExpanded)),
    toggleSearchExpanded: isExpanded => dispatch(toggleSearchExpanded(isExpanded)),
    toggleSneakyHeaderVisible: isVisible => dispatch(toggleSneakyHeaderVisible(isVisible)),
    smartTopRightDispatch: dispatch,
    fetchMembershipStateDispatch: () => dispatch(fetchMembershipState()),
    fetchFlagTreatment: () => {
      dispatch(
        FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.DISCO_SEARCH_AI_MVP_STOREFRONT_UX)
      );
    },
    fetchAiSearchV1Prompts: () => dispatch(loadCMSContent(aiSearchV1Prompts.CMS_PATH, aiSearchV1Prompts.STATE_KEY)),
  };
};

const ConnectedNavContainer = compose(
  withFlagsAndExperiments(flagsAndExperimentNames.DISCO_SEARCH_AI_MVP_STOREFRONT_UX),
  connect(mapStateToProps, mapDispatchToProps)
)(NavContainer);

export { ConnectedNavContainer as default, NavContainer as UnconnectedNavContainer };
