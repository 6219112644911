const ctaText = {
  ADD_TO_BAG: "Add to Bag",
  ADDED_TO_BAG: "Added to Bag",
  ADDING: "Adding",
  START_NEW_SHIPMENT: "Start New Shipment",
};

export default ctaText;

export const { ADD_TO_BAG, ADDED_TO_BAG, ADDING, START_NEW_SHIPMENT } = ctaText;
