import { ThirdPartyScript } from "./thirdPartyScript";
import { shallowEqual, useSelector } from "react-redux";

import {
  scriptTagAttributeForCookieConsentManagement,
  classNameForCookieConsentManagement,
  ONETRUST_CONSENT_MANAGEMENT,
} from "helpers/onetrust-helper";
import { useFeatureFlags } from "../FeatureFlagsContext";

const tatari = () => {
  const publicEnv = useSelector(state => state.publicEnv, shallowEqual);
  const flags = useFeatureFlags();
  if (publicEnv?.tatariKey) {
    return (
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            function loadTatari() {
              window.dispatchEvent(new CustomEvent("tatariLoadEvent"));
            }
          `,
          }}
        />
        <ThirdPartyScript
          id="tatari-script"
          class={classNameForCookieConsentManagement([ONETRUST_CONSENT_MANAGEMENT["COOKIE_CATEGORIES"]["TARGETING"]])}
          type={scriptTagAttributeForCookieConsentManagement(flags)}
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            try {
              !function(){try{!function(t,n){n.version||(window.tatari=n,n.init=function(t,e){var i=function(t,e){n[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};"track pageview identify".split(" ").forEach(function(t){i(n,t)}),n._i=t,n.pageview()}),n.version="latest";var e=t.createElement("script");e.type="text/javascript",e.async=!0,e.onload=loadTatari,e.src="https://d2hrivdxn8ekm8.cloudfront.net/tracker-latest.min.js";var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(e,i)}(document,window.tatari||[])}catch(t){console.log(t)}}(); tatari.init("${publicEnv.tatariKey}");
            } catch (e) {
              if (window.Sentry && window.Sentry.captureException) { window.Sentry.captureException(e, {
                tags: {
                  thirdparty: true,
                  thirdparty_name: "tatari"
                }
              }); }
            }
          `,
          }}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default tatari;
