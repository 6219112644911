import $ from "clients/RawClient";
import { createAction } from "redux-actions";

import { analytics, CEBagErrors, EVENTS_CUSTOM_CASTLE } from "rtr-constants";
import ActionTypes from "actions/action-types";
import { markReturnPromise, markItemKept } from "actions/membership-state-actions";
import { fetchNotification } from "actions/subscription-notification-center-actions";
import { getUserId } from "components/source/hoc/with-user-data";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";
import * as BagActions from "actions/bag-actions";
import CEBagActions from "actions/ce-bag-actions";
import CECheckoutActions from "actions/ce-checkout-actions";
import { condenseFetchPersonalizedCarousels } from "helpers/carousel-helper";
import GTMHelper from "helpers/gtm";
import * as castleHelper from "../helpers/castle-helper";

const actions = {
  smBagRemoveUnavailableItem: createAction(ActionTypes.SM_BAG_REMOVE_UNAVAILABLE_ITEM),
  smBagIsLoading: createAction(ActionTypes.SM_BAG_IS_LOADING),
  smBagError: createAction(ActionTypes.SM_BAG_ERROR),
  smBagSuccess: createAction(ActionTypes.SM_BAG_SUCCESS),

  fetchSMBag: (updateCEBagCount = false, includePricing = false) => {
    return function (dispatch, getState) {
      const { userData: currentUserData } = getState();

      if (!getUserId(currentUserData)) {
        return null; // need to throw an error here for non-existing member
      }

      dispatch(actions.smBagIsLoading(true));
      $.get(`/membershipBags?includeProductDetail=true&includePricing=${includePricing}`).then(
        data => {
          dispatch(CECheckoutActions.checkoutError(null));
          dispatch(actions.smBagSuccess(data));
          if (data?.checkout?.passivePromoError) {
            dispatch(CECheckoutActions.promoError(data.checkout.passivePromoError));
          }

          if (updateCEBagCount) {
            return dispatch(CEBagActions.getCount());
          }
        },
        function (xhr, _statusText, errorThrown) {
          let error = errorThrown;
          if (xhr.status === 400) {
            error = xhr.responseText;
          }

          dispatch(actions.smBagError(error));
        }
      );
    };
  },

  addBagItemPromise(item, onSuccess = null, onFailure = null, analyticsData = {}) {
    return function (dispatch, getState) {
      dispatch(actions.smBagIsLoading(true));
      dispatch(CEBagActions.membershipAddToCartLoading(true));

      $.post("/bag/bagItem", { item }).then(
        res => {
          const state = getState();
          dispatch(CEBagActions.bagCount(res.count));
          dispatch(CEBagActions.membershipAddToCartLoading(false));
          castleHelper.logCustomEvent({
            name: EVENTS_CUSTOM_CASTLE.ADD_TO_BAG,
            properties: {
              product_id: analyticsData?.productId,
              retail_price: analyticsData?.retailPriceInteger,
            },
          });

          if (typeof onSuccess === "function") {
            onSuccess();
          }
          if (res.count?.members === state.smBag?.numOpenSlots) {
            // if we dont, then we have to open the bag, which will fetch the bag
            dispatch(BagActions.toggleBag(true));
            dispatch(BagActions.switchBagTab(BagActions.bagTabs.MEMBERSHIP_TAB));
            condenseFetchPersonalizedCarousels(dispatch, state);
          } else {
            dispatch(actions.fetchSMBag());
          }
        },
        error => {
          dispatch(actions.smBagError(error));
          dispatch(CEBagActions.membershipAddToCartLoading(false));

          if (typeof onFailure === "function") {
            onFailure(error);
          }
        }
      );
    };
  },

  removeFailedToRefreshItem: bagItemId => {
    return function (dispatch, getState) {
      const { smBag = {} } = getState();
      dispatch(actions.smBagRemoveUnavailableItem(bagItemId));
      if (smBag?.failedToRefreshItems?.length < 1) {
        dispatch(actions.fetchSMBag(true, true));
      }
    };
  },

  removeBagItemPromise: (bagItem, analyticsData = {}) => {
    return function (dispatch, getState) {
      const { bagItemId, bookingId } = bagItem;
      const { userData } = getState();

      const analyticsItem = {
        item_id: bagItem?.sku,
        item_name: bagItem?.displayName,
        affiliation: "Rent The Runway",
        currency: "USD",
        item_brand: bagItem?.designerName,
        discount: "N/A",
        price: "N/A",
        item_variant: bagItem?.styleName,
      };

      dispatch(actions.smBagIsLoading(true));

      return createAjaxAuthRetry({
        url: "/bag/bagItem",
        type: "DELETE",
        data: {
          id: bagItemId,
          holdId: bookingId,
        },
      }).then(
        () => {
          GTMHelper.removeFromCartEvent(
            analyticsItem,
            bagItem?.sku,
            userData?.userProfile?.id,
            userData?.userProfile?.email
          );
          castleHelper.logCustomEvent({
            name: EVENTS_CUSTOM_CASTLE.REMOVE_FROM_BAG,
            properties: {
              product_id: analyticsData?.productId,
            },
          });
          dispatch(actions.fetchSMBag(true, true));
        },
        error => {
          dispatch(actions.smBagError(error));
        }
      );
    };
  },

  setKeepPromise: bookingId => {
    return function (dispatch) {
      dispatch(actions.smBagIsLoading(true));
      if (!bookingId) {
        dispatch(actions.smBagError("There was an error swapping your item, please try again later."));
        return;
      }

      return dispatch(markItemKept(bookingId, analytics.LOCATION.BAG)).then(
        () => {
          dispatch(actions.fetchSMBag(false, true));
          dispatch(fetchNotification());
        },
        err => dispatch(actions.smBagError(err))
      );
    };
  },

  setReturnPromise: bookingId => {
    return function (dispatch) {
      dispatch(actions.smBagIsLoading(true));
      if (!bookingId) {
        dispatch(actions.smBagError("There was an error swapping your item, please try again later."));
        return;
      }

      return dispatch(markReturnPromise(bookingId, { pageType: analytics.LOCATION.BAG })).then(
        () => dispatch(actions.fetchSMBag(false, true)),
        err => dispatch(actions.smBagError(err))
      );
    };
  },

  setPaymentMethod(checkoutId, paymentMethodId) {
    return function (dispatch) {
      dispatch(actions.smBagIsLoading(true));

      return dispatch(CECheckoutActions.setPaymentMethod(checkoutId, paymentMethodId)).then(
        () => dispatch(actions.fetchSMBag(false, true)),
        err => dispatch(actions.smBagError(err))
      );
    };
  },

  applyPromoCode(checkoutId, promoCode) {
    return function (dispatch) {
      dispatch(actions.smBagIsLoading(true));

      return $.post("/checkout/promocode", { checkoutId, promoCode }).then(
        res => {
          dispatch(CECheckoutActions.checkoutLoadSuccess(res));

          if (res.passivePromoError) {
            dispatch(CECheckoutActions.promoError(res.passivePromoError));
          } else {
            castleHelper.logCustomEvent({
              name: EVENTS_CUSTOM_CASTLE.APPLY_PROMO_CODE,
              properties: {
                checkout_id: checkoutId,
              },
            });
          }
          dispatch(actions.fetchSMBag(false, true));
        },
        xhr => {
          const error = xhr.status === 400 ? xhr.responseText : CEBagErrors.PROMO_CODE_APPLY_GENERIC_ERROR(promoCode);
          dispatch(actions.smBagIsLoading(false));
          dispatch(CECheckoutActions.promoError(error));
        }
      );
    };
  },

  removePromoCode(checkoutId) {
    return function (dispatch) {
      dispatch(CECheckoutActions.checkoutLoading(true));
      dispatch(actions.smBagIsLoading(true));

      return $.ajax({ url: "/checkout/promocode", type: "DELETE", data: { checkoutId } })
        .then(
          res => {
            dispatch(CECheckoutActions.checkoutLoadSuccess(res));
            dispatch(actions.clearPromoErrors());
            dispatch(actions.fetchSMBag(false, true));
            castleHelper.logCustomEvent({
              name: EVENTS_CUSTOM_CASTLE.REMOVE_PROMO_CODE,
              properties: {
                checkout_id: checkoutId,
              },
            });
          },
          () => {
            dispatch(CECheckoutActions.promoError(CEBagErrors.PROMO_CODE_REMOVAL_GENERIC_ERROR));
            dispatch(actions.smBagIsLoading(false));
          }
        )
        .finally(() => {
          dispatch(CECheckoutActions.checkoutLoading(false));
        });
    };
  },

  clearPromoErrors() {
    return function (dispatch) {
      dispatch(actions.smBagError(null));
      dispatch(CECheckoutActions.clearPromoErrors());
    };
  },
};

export default actions;
