import React from "react";
import ClosableModal from "../shared/closable-modal";
import OrderDetailsView from "./OrderDetailsView";
import { ORDER_DETAILS_MODAL } from "helpers/swaps-helper";
import PropTypes from "prop-types";
import { browserPropType } from "components/propTypes";

class OrderDetailsModal extends React.Component {
  static propTypes = {
    browser: browserPropType,
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    dispatch: PropTypes.func.isRequired,
    userProblems: PropTypes.object,
  };

  render() {
    const { displayedModal, dispatch } = this.props;
    return (
      <ClosableModal
        displayedModal={displayedModal}
        isOpen={displayedModal === ORDER_DETAILS_MODAL}
        dispatch={dispatch}
        optionalClass="order-details-modal">
        <OrderDetailsView
          browser={this.props.browser}
          dispatch={this.props.dispatch}
          userProblems={this.props.userProblems}
        />
      </ClosableModal>
    );
  }
}

export default OrderDetailsModal;
