import React from "react";
import { compose } from "redux";
import { withCookies, Cookies } from "react-cookie";
import PropTypes from "prop-types";
import { bannerPropTypeShape } from "components/propTypes";
import ActionLogger from "action-logger";
import { SessionStorage } from "../../../site/localStorage";
import { paidPromotions } from "rtr-constants";
import { withLogging } from "../hoc/with-logging";

const sessionStorageClient = new SessionStorage("smartComponents");

export class BannerComponent extends React.Component {
  static propTypes = {
    ...bannerPropTypeShape,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    onHide: PropTypes.func,
    onNotApplicable: PropTypes.func,
    onShow: PropTypes.func,
  };

  static defaultProps = {
    details: null,
    link: null,
    linkCopy: null,
    mobileCopy: null,
    promoCode: null,
    onShow: () => {}, // NOSONAR
    onHide: () => {}, // NOSONAR
    onNotApplicable: () => {}, // NOSONAR
  };

  state = {
    detailsVisible: false,
    closed: true,
  };

  static getCookieName(props) {
    let name = "dismissed_" + props.bannerId.split("-").join("_") + "_promo";

    if (props.promoCode) {
      name += "__" + props.promoCode;
    }

    return name;
  }

  cookieName = () => {
    return this.constructor.getCookieName(this.props);
  };

  componentDidMount() {
    const closedState = this.props.cookies.get(this.cookieName());

    this.setState({ closed: closedState });
  }

  legacyClosedState = () => {
    const legacyCookieName = "dismissed_" + this.props.bannerId.split("-").join("_") + "_promo";

    return this.props.cookies.get(legacyCookieName);
  };

  close = () => {
    this.props.cookies.set(this.cookieName(), true, { path: "/" });

    const paidPromotionLinkCouponParameter = paidPromotions.URL_PARAMETER_PAID_PROMOTION;
    const paidPromotionLinkDiscountParameter = paidPromotions.URL_PARAMETER_PAID_PROMOTION_DISCOUNT;

    sessionStorageClient.remove(paidPromotionLinkCouponParameter);
    sessionStorageClient.remove(paidPromotionLinkDiscountParameter);

    this.setState({
      closed: true,
    });

    ActionLogger.logAction({
      object_type: "banner",
      action: "dismissed",
      banner_name: this.props.bannerId,
      promo_code: this.props.promoCode,
    });

    this.props.onHide(this.props.bannerId);
  };

  toggle = () => {
    // Logging the toggling of skinny banner details only
    // if the details are about to be shown (i.e. they're
    // currently not visible).
    if (!this.state.detailsVisible) {
      ActionLogger.logAction({
        object_type: "banner",
        action: "details",
        banner_name: this.props.bannerId,
        promo_code: this.props.promoCode,
      });
    }

    this.setState({
      detailsVisible: !this.state.detailsVisible,
    });
  };

  shouldRender = (props, state) => {
    if (state.closed || !props.desktopCopy) {
      return;
    }

    return true;
  };

  renderDetails = () => {
    if (!this.props.details) {
      return;
    }

    let detailsClassname = "details";

    if (this.state.detailsVisible) {
      detailsClassname += " open";
    }

    return <p className={detailsClassname}>{this.props.details}</p>;
  };

  renderDetailsButton = () => {
    if (!this.props.details) {
      return;
    }

    const detailsCopy = this.state.detailsVisible ? "Close" : "Details";

    return (
      <span role="link" className="details-button" onClick={this.toggle} onKeyDown={this.handleClick} tabIndex={0}>
        {detailsCopy}
      </span>
    );
  };

  renderLinkCopy = () => {
    if (this.props.link && this.props.linkCopy) {
      return (
        <a className="promo-link" href={this.props.link}>
          {this.props.linkCopy}
        </a>
      );
    }
  };

  promoCodeCopy = () => {
    return this.props.promoCode || "";
  };

  desktopCta = () => {
    if (!this.props.desktopCta) {
      return;
    }
    return (
      <span>
        <u>{this.props.desktopCta}</u>
      </span>
    );
  };

  mobileCta = () => {
    if (!this.props.mobileCta) {
      return;
    }
    return (
      <span>
        <u>{this.props.mobileCta}</u>
      </span>
    );
  };

  renderDesktopCopy = () => {
    if (this.props.link && !this.props.linkCopy) {
      return (
        <span className="mq__tablet-and-desktop">
          <a href={this.props.link}>
            {this.props.desktopCopy}
            <span>{this.promoCodeCopy()}</span>
            {this.desktopCta()}
          </a>
        </span>
      );
    }

    return (
      <span className="mq__tablet-and-desktop">
        {this.props.desktopCopy}
        <span>{this.promoCodeCopy()}</span>
      </span>
    );
  };

  renderMobileCopy = () => {
    const copy = this.props.mobileCopy || this.props.desktopCopy;

    if (this.props.link && !this.props.linkCopy) {
      return (
        <span className="mq__mobile">
          <a href={this.props.link}>
            {copy}
            <span>{this.promoCodeCopy()}</span>
            {this.mobileCta()}
          </a>
        </span>
      );
    }

    return (
      <span className="mq__mobile">
        {copy}
        <span>{this.promoCodeCopy()}</span>
      </span>
    );
  };

  render() {
    if (!this.shouldRender(this.props, this.state)) {
      this.props.onNotApplicable(this.props.bannerId);
      return null;
    }

    const styles = {
      backgroundColor: this.props.backgroundColor,
      color: this.props.color,
    };

    this.props.onShow(this.props.bannerId);

    return (
      <div className="skinny-banner-content" style={styles} id={this.props.bannerId}>
        <p className="promo-title">
          {this.renderDesktopCopy()}
          {this.renderMobileCopy()}
          {this.renderDetailsButton()}
          {this.renderLinkCopy()}
          <span role="button" className="close-promo" onClick={this.close} onKeyDown={this.handleClick} tabIndex={0}>
            ×
          </span>
        </p>
        {this.renderDetails()}
      </div>
    );
  }
}

export default compose(
  withCookies, // must come before withLogging, since cookies are used below
  withLogging({
    loggingData: props => ({
      object_type: "banner",
      action: "render",
      banner_name: props.bannerId,
      promo_code: props.promoCode,
      cookies_loaded: Boolean(props.cookies),
      is_dismissed: props.cookies?.get(BannerComponent.getCookieName(props)),
    }),
  })
)(BannerComponent);
