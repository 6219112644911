import $ from "clients/RawClient";
import { createAction } from "redux-actions";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import { safeNavigation } from "./action-utils.js";
import ActionLogger from "action-logger";
import ActionTypes from "./action-types.js";
import HoldPreferencesActions from "actions/hold-preferences-actions";
import ShippingStepActions from "actions/shipping-step-actions";
import NextUserActions from "actions/next-user-actions";
import { requestedFlagSelector, Flags } from "../components/source/hoc/with-feature-flags";
import { fireWithUserData, hasProfile, isIdentified } from "../components/source/hoc/with-user-data";
import {
  getPostAuthenticationDestination,
  getPostAuthenticationQueryParam,
  handlePostAuthenticationClientSideNavigation,
  isLogin,
  isRegistration,
  isStandaloneAuthenticationPage,
  refreshAccessToken,
} from "../helpers/auth-helpers";
import * as castleHelper from "helpers/castle-helper";
import { isStorefrontNext } from "helpers/environment-helpers";
import HeapHelpers from "helpers/heap-helpers";
import { getInitialState } from "../reducers/auth-reducer";
import { COOKIES, pageTypes, QUERY_PARAMS } from "rtr-constants";
import { authFormConstants } from "rtr-constants/auth";
import { getLocationPathname, navigateTo } from "../helpers/location-helpers";
import { getErrorMessage } from "../helpers/error-message-maps/auth";
import smartNavActions from "./smart-nav-actions";
import { fetchLegacyUserData } from "./user-actions";
import { updateUserData } from "./on-initialization-actions";
import { experimentsSelector } from "components/source/hoc/with-flags-and-experiments";
import Routes from "routes";
import { addSnackBar } from "./snack-bar-actions";
import { cleanSession, signIn } from "../helpers/session-helpers";
import GTMHelper from "helpers/gtm";

const { Views } = authFormConstants;
const cookies = new Cookies();

const actions = {
  // Sync actions
  updateAuthModal: createAction(ActionTypes.UPDATE_AUTH_MODAL),
  refreshAllPropsSuccess: createAction(ActionTypes.REFRESH_ALL_PROPS_SUCCESS),

  // Async actions
  /**
   * @param {string} targetUrl Either login or registration url
   * @param {{
   *  username: string, password: string, provider: string, [challenge_code]: string
   * }} data All of the required and optional data needed to successfully log in or register a user
   * @param {function} [onSuccess] Success callback
   * @param {function} [onFailure] Failure callback
   * @description Action to create an authentication attempt
   */
  createAuthenticationAttempt: (targetUrl, data, onSuccess, onFailure) => {
    return () => {
      $.ajax({
        type: "POST",
        url: targetUrl,
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify(data),
      }).then(
        authResponse => {
          if (typeof onSuccess === "function") {
            onSuccess(authResponse);
          }

          castleHelper.setDeviceUserJWT(authResponse);
        },
        jqXHR => {
          if (typeof onFailure === "function") {
            onFailure(jqXHR);
          }
        }
      );
    };
  },

  /**
   * @param {{ context: string, email: string }} data the params the API endpoint requires
   * @param {function} [onSuccess] Success callback
   * @param {function} [onFailure] Failure callback
   * @description Action to request a new challenge code to be sent to user's email
   */
  requestNewChallengeCode: (data, onSuccess, onFailure) => {
    return () => {
      $.ajax({
        type: "POST",
        url: "/account/resend_challenge",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify(data),
      }).then(
        () => {
          if (typeof onSuccess === "function") {
            onSuccess();
          }
        },
        error => {
          if (typeof onFailure === "function") {
            onFailure(error);
          }
        }
      );
    };
  },

  /**
   * @param {{ authorization: { code: string, id_token: string, state: string } } | { error: string }} data Apple can optionally return an
   * authorization or error property in the data object. An authorization object will contain the id_token needed for authorization.
   * @param {function} [onSuccess] Success callback
   * @param {function} [onFailure] Failure callback
   * @see https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
   * @description Action to create an authentication attempt with Apple provider.
   */
  createAppleAuthenticationAttempt: (data, onSuccess, onFailure) => {
    return () => {
      $.ajax({
        type: "POST",
        url: "/account/auth/apple/callback",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify(data),
      }).then(
        authResponse => {
          if (typeof onSuccess === "function") {
            onSuccess(authResponse);
          }

          castleHelper.setDeviceUserJWT(authResponse);
        },
        jqXHR => {
          if (typeof onFailure === "function") {
            onFailure(jqXHR);
          }
        }
      );
    };
  },

  /**
   * Action to handle the response from the Google One Tap API and authenticate the user
   * @param {{ credential: string }} response Response object containing the ID token returned from the One Tap prompt.
   * The response is passed to the callback function that was passed to the One Tap API via the google.accounts.id.initialize
   * method.
   * @see {https://developers.google.com/identity/gsi/web/reference/js-reference?hl=en#callback}
   */
  createGoogleOneTapAuthenticationAttempt: response => {
    return dispatch => {
      if (response?.credential) {
        // Decode the JWT received from Google and extract the email address.
        // Send the token and the email to your auth backend.
        const email = jwtDecode(response.credential)?.email;

        if (!email) {
          dispatch(
            actions.updateAuthModal({
              errorMessage: getErrorMessage(),
            })
          );

          return;
        }

        const postData = {
          email: email,
          password: response.credential,
          provider: "google",
          source: "one-tap",
        };

        $.ajax({
          type: "POST",
          url: "/account/login",
          headers: {
            Accept: "application/json",
          },
          data: postData,
        }).then(
          authResponse => {
            dispatch(actions.handlePostAuthenticationSideEffects(authResponse));
            dispatch(actions.closeAuthModal());

            castleHelper.setDeviceUserJWT(authResponse);
          },
          function () {
            dispatch(
              actions.updateAuthModal({
                errorMessage: getErrorMessage(),
              })
            );
          }
        );
      } else {
        dispatch(
          actions.updateAuthModal({
            errorMessage: getErrorMessage(),
          })
        );
      }
    };
  },

  refreshProps: function () {
    return function (dispatch) {
      $.ajax({
        url: window.location.href,
        type: "GET",
        headers: {
          Accept: "application/json",
        },
      }).then(function (data) {
        dispatch(actions.refreshAllPropsSuccess(data));
      });
    };
  },

  /**
   * Action to open the authentication modal
   * @param {{
   *  callback: function,
   *  destination: string,
   *  displayStyle: string,
   *  errorMessage: string,
   *  isFullScreen: boolean,
   *  isOpen: boolean,
   *  mainClass: string,
   *  source: string,
   *  subtitle: string,
   *  title: string,
   *  triggeredBy: string,
   *  view: "login" | "registration",
   * }} options params to show the modal and initialize it with any necessary state
   */
  showAuthModal: function (options = {}) {
    return async function (dispatch, getState) {
      const cookies = new Cookies();
      const isCasV1Enabled = cookies.get(COOKIES.CAS_V1_ENABLED) === "true";
      const state = getState();
      const { authModal, pageType, pageName, userData } = state;

      if (isCasV1Enabled) {
        HeapHelpers.trackAuthModalOpen(options.triggeredBy, getLocationPathname());

        ActionLogger?.logAction({
          object_type: "light_reg",
          action: "lightreg_popup",
          source: options.source || "none",
        });
        let callbackUrl;

        if (options.destination) {
          callbackUrl = options.destination;
        } else if (getPostAuthenticationDestination(authModal)) {
          callbackUrl = getPostAuthenticationDestination(authModal);
        }
        // we want to provide an undefined callbackUrl if neither of those values are present to preserve
        // navigation back to the current page

        const signInOptions = {
          callbackUrl,
        };

        // is there a valid refresh token to refresh the access token without a sign-in?
        if (isIdentified(userData)) {
          const refreshed = await refreshAccessToken();
          if (refreshed) {
            if (callbackUrl) {
              safeNavigation(callbackUrl);
            }
            return;
          }
        }

        await signIn(signInOptions);
        return;
      }

      // If the user is on the standalone authentication page, and they click the sign-in button, just reset the form to the login form
      // in-case they are on the registration form
      if (isStandaloneAuthenticationPage(pageName, pageType)) {
        dispatch(actions.updateAuthModal({ ...getInitialState(), view: Views.login }));
        return;
      }

      const view =
        hasProfile(state.userData) || cookies.get("euwli") || options?.view === Views.login
          ? Views.login
          : Views.registration;
      const modalOptions = {
        ...options,
        destination: options?.destination || getPostAuthenticationDestination(authModal),
        isOpen: true,
        view,
      };

      dispatch(actions.updateAuthModal(modalOptions));

      HeapHelpers.trackAuthModalOpen(options.triggeredBy, getLocationPathname());

      ActionLogger?.logAction({
        object_type: "light_reg",
        action: "lightreg_popup",
        source: options?.source || "none",
      });
    };
  },

  /**
   * Action to close the authentication modal
   */
  closeAuthModal: function () {
    return function (dispatch, getState) {
      const state = getState();
      const { authModal } = state;

      dispatch(actions.updateAuthModal(getInitialState()));

      ActionLogger.logAction({
        object_type: "light_reg",
        action: "close_lightreg",
      });

      HeapHelpers.trackAuthModalClose(isRegistration(authModal), true, authModal?.triggeredBy, getLocationPathname());
    };
  },

  /**
   * Action to handle all the side effects after user authenticates (either via RTR or a third party provider)
   * @param {object} [authResponse] Authentications / AuthenticationAttempt response object
   */
  handlePostAuthenticationSideEffects: function (authResponse) {
    return async function (dispatch, getState) {
      const state = getState();
      const { authModal, pageType, userData } = state;
      const authCallback = authModal?.callback;
      const destination = getPostAuthenticationDestination(authModal);
      const additionalParams = getPostAuthenticationQueryParam(authModal);
      const isDefaultShippingAddressFlagEnabled = requestedFlagSelector(Flags.DEFAULT_SHIPPING_ADDRESS)(state);

      let actionPixel;

      if (isLogin(authModal)) {
        actionPixel = "login";
      } else if (isRegistration(authModal)) {
        actionPixel = "signup_new";
        GTMHelper.pushToDataLayer({
          event: "registrationComplete",
          userId: authResponse?.profile?.userId,
        });
      }
      HeapHelpers.trackAuthComplete(authResponse?.provider, getLocationPathname());

      // Not sure where this comes from but afraid to do anything with it
      cookies.remove("campaign_referral", { path: "/" });

      if (isDefaultShippingAddressFlagEnabled) {
        // update persisted ZIP if logged-in user has a shipping address
        dispatch(
          ShippingStepActions.fetchDefaultShippingAddress(zipCode => {
            dispatch(actions.updatePersistedZip(zipCode));
          })
        );
      }

      const objectType = pageType === pageTypes.ACCOUNT ? "standalone_reg" : "light_reg";
      const pixelData = {
        object_type: objectType,
        action: actionPixel,
        pageType: pageType,
      };

      // Do not execute callback behavior if there is no callback (because the page is going to refresh)
      if (typeof authCallback === "function") {
        dispatch(updateUserData({ authDataHasLoaded: false, userProfileHasLoaded: false }));
        authCallback(authResponse);
        dispatch(actions.closeAuthModal());
        dispatch(smartNavActions.getSmartNav());

        fireWithUserData(userData, () => {
          const reduxState = getState();
          const { membershipState, userData: currentUserData } = reduxState;
          const launchDarklyExperiments = experimentsSelector(reduxState);
          ActionLogger?.logAction(pixelData);
          // Only set Heap data here if the page is not going to reload, otherwise we also call the same
          // helper on load of the subsequent page
          // @see {https://github.com/RentTheRunway/storefront/blob/master/assets/javascripts/helpers/heap-helpers.js#L163}
          HeapHelpers.setPropertiesOnPageLoad(membershipState, currentUserData, launchDarklyExperiments, true);
        });

        if (isStorefrontNext()) {
          await dispatch(NextUserActions.fetchAuthState());
        } else {
          dispatch(fetchLegacyUserData());
        }

        // Just in-case
        if (destination) handlePostAuthenticationClientSideNavigation(destination, additionalParams);
      } else {
        // Page is about to navigate so use .inferAction(...)
        ActionLogger.inferAction(pixelData);

        handlePostAuthenticationClientSideNavigation(destination, additionalParams);
      }
    };
  },

  /**
   * Action to handle updating the zip-code in the filters after a user authenticates
   * @param {string} zipCode User's zip-code from the default shipping address
   */
  updatePersistedZip: function (zipCode) {
    return function (dispatch, getState) {
      if (!zipCode) {
        return;
      }
      const { workingFilters } = getState();
      const newWorkingFilters = Object.assign({}, workingFilters, { zip_code: zipCode });
      dispatch(HoldPreferencesActions.writeHoldPreferences(newWorkingFilters));
    };
  },

  /**
   * Action to handle authentication response from RTR server when using oauth provider. This can be a successful authentication or an error.
   * @param {{
   *  errorMessage: string | null,
   *  oauthSuccess: boolean,
   * }} oauthComplete Payload from the oauthComplete custom event that is fired when the oauth process succeeds or errors
   */
  handleOauthComplete: ({ errorMessage, oauthSuccess, provider }) => {
    return function (dispatch) {
      if (oauthSuccess && !errorMessage) {
        dispatch(actions.handlePostAuthenticationSideEffects({ provider }));
        return;
      }

      if (errorMessage) {
        dispatch(actions.updateAuthModal({ errorMessage }));
      }
    };
  },

  logout: function ({
    actionData = null,
    destination = Routes.Home,
    isCasAuthenticationEnabled = false,
    searchParams = new URLSearchParams(),
  } = {}) {
    return async function (dispatch) {
      if (isCasAuthenticationEnabled) {
        try {
          // This call logs out of the CAS session. Using RawClient here in case the user's access token is expired while
          // trying to manually logout to handle refreshing the access token.
          await $.ajax({
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json; charset=utf-8",
            },
            type: "POST",
            url: "/api/auth/logout",
          });
          // This call updates the browser session cookie (there is some functionality under the hood that next-auth is
          // providing, and it seems like there isn't a great way to do this on the server)
          // https://github.com/nextauthjs/next-auth/discussions/5334
          await cleanSession();
        } catch (e) {
          dispatch(addSnackBar({ copyPrimary: "There was an error signing you out", duration: 5000 }));
          return;
        }
      }

      // Handle side effects
      const preferenceKeys = ["unlimitedMinAvailability", "rtrUpdateMinAvailability"];
      dispatch(HoldPreferencesActions.clearHoldPreferences(preferenceKeys));
      HeapHelpers.clearEventPropertiesAndIdentity();
      castleHelper.deleteDeviceUserJWT();
      if (actionData) {
        ActionLogger.inferAction(actionData);
      }

      // In CAS realm, we will call the POST route and then handle the navigation browser-side, whereas the old Ruby
      // layer relied on a GET page request with a redirect
      if (isCasAuthenticationEnabled) {
        const destinationUrl = new URL(destination, window.location.origin);
        destinationUrl.searchParams.set(QUERY_PARAMS.LOGOUT, "true");
        navigateTo(destinationUrl.toString());
      } else {
        const url = Routes.Account.Logout + "?" + searchParams.toString();
        const logoutUrl = new URL(url, window.location.origin);
        logoutUrl.searchParams.set(QUERY_PARAMS.DESTINATION, destination);
        navigateTo(logoutUrl.toString());
      }
    };
  },
};

export default actions;

export const {
  closeAuthModal,
  createAuthenticationAttempt,
  createAppleAuthenticationAttempt,
  createGoogleOneTapAuthenticationAttempt,
  handleOauthComplete,
  handlePostAuthenticationSideEffects,
  refreshAllPropsSuccess,
  refreshProps,
  requestNewChallengeCode,
  showAuthModal,
  updateAuthModal,
  updatePersistedZip,
  logout,
} = actions;
