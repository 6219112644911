import RawClient from "clients/RawClient";
import Cookies from "universal-cookie";

export async function refreshSegments(forceRefresh = false) {
  //The cookie's value is an empty string, so it's falsy
  //We want to check if it's present at all
  const segRefreshCookiePresent = typeof new Cookies().get("seg_refresh") === "string";
  if (segRefreshCookiePresent && !forceRefresh) {
    return;
  }

  return RawClient.post("/api/storefront/user/segments/refresh");
}
