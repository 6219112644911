import _ from "underscore";
import ActionTypes from "../actions/action-types.js";

const types = [
  ActionTypes.CLEAR_REVIEW_PHOTOS,
  ActionTypes.DELETE_REVIEW_PHOTO_SUCCESS,
  ActionTypes.ROTATE_REVIEW_PHOTO_SUCCESS,
  ActionTypes.SUBMIT_REVIEW_PHOTO_SUCCESS,
];

const reviewPhotoModification = function (state, action) {
  switch (action.type) {
    case ActionTypes.CLEAR_REVIEW_PHOTOS:
      return [];
    case ActionTypes.SUBMIT_REVIEW_PHOTO_SUCCESS:
      return [].concat(state || [], action.payload);
    case ActionTypes.DELETE_REVIEW_PHOTO_SUCCESS:
      return _.reject(state, function (photo) {
        return photo.photoId === action.payload;
      });
    case ActionTypes.ROTATE_REVIEW_PHOTO_SUCCESS:
      return _.map(state, function (photo) {
        if (action.payload === photo.photoId) {
          photo.photoUrl += "?" + new Date().getTime();
        }
        return photo;
      });
  }
  return state;
};

const fn = function (state, action) {
  const newReviewPhotos = reviewPhotoModification(state.reviewPhotos, action);
  return _.extend({}, state, { reviewPhotos: newReviewPhotos });
};

export default {
  types: types,
  fn: fn,
};
