import React from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withQueryParams } from "components/source/hoc/with-query-params";
import { createChangeTierMembershipPaymentOrder } from "actions/membership-charge-actions";
import { getMembershipTiers, getImmediateUpgradePlanPreview } from "actions/membership-tier-actions";
import MembershipHelpers from "helpers/membership-helpers";
import ProductsActions from "actions/products-actions";
import { closeProductDrawer } from "actions/product-drawer-actions";
import {
  membershipTriggeredModalsCMSKeys,
  immediateUpgradeMessages,
  membershipLens,
  dateFnsFormats,
  immediateUpgradeLegalText,
  FORCE_ADD_SHIPMENT_PARAM,
  AUTH_STATES,
} from "rtr-constants";
import { withFlagsAndExperiments, flagsAndExperimentNames } from "components/source/hoc/with-flags-and-experiments";
import { displayModal, loadCMSContent } from "actions/shared-actions";
import { addSnackBar } from "actions/snack-bar-actions";
import SMBagActions from "actions/sm-bag-actions";
import AddShipmentModalPropTypes from "./AddShipmentModalPropTypes";
import AddShipmentModal from "./AddShipmentModal";
import VerifyAuthState from "../../../shared/VerifyAuthState";

export class AddShipmentModalWrapperComponent extends React.Component {
  static propTypes = {
    displayedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    ...AddShipmentModalPropTypes,
    queryParams: PropTypes.shape({
      [FORCE_ADD_SHIPMENT_PARAM]: PropTypes.string,
    }),
  };

  static modalName = "ADD-SHIPMENT-MODAL";

  componentDidMount() {
    const {
      membershipTiers = [],
      getMembershipTiers = () => {},
      membershipState,
      queryParams,
      displayModal,
    } = this.props;

    // get tiers if eligible and don't have them already
    if (MembershipHelpers.isEligibleForImmediateSwapUpgrade(membershipState) && membershipTiers.length < 1) {
      getMembershipTiers();
    }

    if (queryParams?.[FORCE_ADD_SHIPMENT_PARAM]) {
      displayModal();
    }
  }

  render() {
    const {
      membershipState,
      membershipTiers,
      submitPlanChange,
      displayedModal,
      dismissModal,
      membershipBag,
      getMembershipTiers,
      oopsModal,
      getChangePlanPreview,
      immediateUpgradePlanPreview,
      immediateUpgradePlanPreviewLoading,
      immediateUpgradePlanPreviewFail,
      fetchLegalText,
      immediateUpgradeLegalText,
    } = this.props;

    const isOpen = displayedModal && displayedModal === this.constructor.modalName;
    // only render component if the modal is open to avoid
    // API calls in componentDidMount firing on home page load
    return isOpen ? (
      <VerifyAuthState
        authModalProps={{ callback: () => this.props.displayModal(this.constructor.modalName) }}
        isReadyToPromptForAuth={isOpen}
        requiredAuthState={AUTH_STATES.AUTHORIZED}>
        <AddShipmentModal
          membershipState={membershipState}
          membershipTiers={membershipTiers}
          submitPlanChange={submitPlanChange}
          dismissModal={dismissModal}
          membershipBag={membershipBag}
          getMembershipTiers={getMembershipTiers}
          oopsModal={oopsModal}
          getChangePlanPreview={getChangePlanPreview}
          immediateUpgradePlanPreview={immediateUpgradePlanPreview}
          immediateUpgradePlanPreviewLoading={immediateUpgradePlanPreviewLoading}
          immediateUpgradePlanPreviewFail={immediateUpgradePlanPreviewFail}
          fetchLegalText={fetchLegalText}
          immediateUpgradeLegalText={immediateUpgradeLegalText}
        />
      </VerifyAuthState>
    ) : null;
  }
}

export const mapStateToProps = state => {
  return {
    displayedModal: state.displayedModal,
    membershipState: state.membershipState,
    membershipTiers: state.membershipTiers,
    immediateUpgradePlanPreview: state.immediateUpgradePlanPreview,
    immediateUpgradePlanPreviewFail: state.immediateUpgradePlanPreviewFail,
    immediateUpgradePlanPreviewLoading: state.immediateUpgradePlanPreviewLoading,
    immediateUpgradeLegalText: state.cmsContent ? state.cmsContent[immediateUpgradeLegalText.STATE_KEY] : null,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    oopsModal: () => {
      dispatch(displayModal(membershipTriggeredModalsCMSKeys.oops));
    },
    dismissModal: () => {
      dispatch(displayModal(false));
    },
    displayModal: () => {
      dispatch(displayModal(AddShipmentModalWrapperComponent.modalName));
    },
    getMembershipTiers: () => {
      return dispatch(getMembershipTiers());
    },
    fetchLegalText: () => {
      dispatch(loadCMSContent(immediateUpgradeLegalText.CMS_PATH, immediateUpgradeLegalText.STATE_KEY));
    },
    getChangePlanPreview: (tierId, promoCode = null) => {
      dispatch(
        getImmediateUpgradePlanPreview({
          tierId,
          promoCode,
          effectiveOn: format(new Date(), dateFnsFormats.YYYY_MM_DD),
          includeChangeCharge: true,
        })
      );
    },
    submitPlanChange: ({ tierId, promoCode = null, inventoryEligibilityChange }) => {
      const onSuccess = () => {
        dispatch(
          addSnackBar({
            copyPrimary: immediateUpgradeMessages.success,
            duration: 5000,
          })
        );

        // RF: Immediately upgrade user should see any new inventory immediately w/o need for page refresh
        // also close drawer to hide brief "size unavailable" flash as product sku availability counts refresh
        if (inventoryEligibilityChange) {
          dispatch(ProductsActions.setMembershipLens(membershipLens.unlimited, false));
          dispatch(closeProductDrawer());
        }
        if (ownProps?.flagsAndExperiments?.[flagsAndExperimentNames.DRE_SF_DR_946_SIMPLIFY_MEMBERSHIP_BAG_ENABLED]) {
          dispatch(SMBagActions.fetchSMBag());
        }
      };
      dispatch(createChangeTierMembershipPaymentOrder(tierId, promoCode, onSuccess));
    },
  };
};

const ConnectedComponent = compose(
  withQueryParams(FORCE_ADD_SHIPMENT_PARAM),
  withFlagsAndExperiments(flagsAndExperimentNames.DRE_SF_DR_946_SIMPLIFY_MEMBERSHIP_BAG_ENABLED),
  connect(mapStateToProps, mapDispatchToProps)
)(AddShipmentModalWrapperComponent);

ConnectedComponent.modalName = AddShipmentModalWrapperComponent.modalName;

export default ConnectedComponent;
