import React, { useState } from "react";
// eslint-disable-next-line import/no-unresolved
import SearchBar from "components/source/navigation/search-bar";
import semanticSearchPlaceholderText from "../common/semantic-search-placeholder";
import PropTypes from "prop-types";

const SemanticSearchMobileNav = ({ aiSearchPrompts, aiRotatingPrompts }) => {
  const [placeHolderText, setPlaceHolderText] = useState(semanticSearchPlaceholderText(aiRotatingPrompts));

  React.useEffect(() => {
    setPlaceHolderText(semanticSearchPlaceholderText(aiRotatingPrompts));
  }, [aiRotatingPrompts]);

  return (
    <div className="semantic-search-mobile-nav">
      <SearchBar
        analyticsObjectType="mobile_nav"
        inputId="search-bar-input--mobile"
        inputFocusHidesNav={false}
        formClassNameAppend="mobile"
        placeholder={placeHolderText}
        aiSearchPrompts={aiSearchPrompts}
      />
    </div>
  );
};

SemanticSearchMobileNav.propTypes = {
  aiSearchPrompts: PropTypes.any,
  aiRotatingPrompts: PropTypes.array,
};

export default SemanticSearchMobileNav;
