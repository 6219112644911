import _ from "underscore";
import { SessionStorage } from "../site/localStorage";

let SessionClient;
let SessionKeys;

export function hasBrowserSupport() {
  try {
    localStorage.setItem("testKey", "testValue");
    const storageTest = localStorage.getItem("testKey");
    localStorage.removeItem("testKey");
    return _.isEqual(storageTest, "testValue");
  } catch (e) {
    return false;
  }
}

export function setItemWithCookie(key, value) {
  setCookie(key, value);
  setItem(key, value);
}

export function setItem(key, value) {
  // This returns undefined when successful
  if (SessionKeys.includes(key)) {
    return SessionClient.set(key, value);
  }
  return localStorage.setItem(key, value);
}

export function getItem(key) {
  // value will be null when the key doesn't exist
  if (SessionKeys.includes(key)) {
    return SessionClient.get(key);
  }
  return localStorage.getItem(key);
}

export function removeItem(key) {
  // removeItem() will return undefined whether or not the key exists
  localStorage.removeItem(key);
  SessionClient.remove(key);
}

function setCookie(name, value) {
  const maxAge = 315360000; // 10 years in seconds
  document.cookie = `${name}=${value}; max-age=${maxAge}; SameSite=Strict; Path=/`;
}

// use sessionNameSpace and sessionKeys to customize which keys you would like to
// store in session storage vs. local storage
export function getApi(sessionNameSpace = "", sessionKeys = []) {
  let api;
  SessionClient = new SessionStorage(sessionNameSpace);
  SessionKeys = sessionKeys;

  if (StorageHelper.hasBrowserSupport()) {
    api = {
      setItem,
      setItemWithCookie,
      getItem,
      removeItem,
    };
  } else {
    api = {
      setItem: function () {
        return false;
      },
      getItem: function () {
        return false;
      },
      removeItem: function () {
        return false;
      },
    };
  }

  return api;
}

const StorageHelper = {
  getApi,
  getItem,
  hasBrowserSupport,
  removeItem,
  setItem,
};

export default StorageHelper;
