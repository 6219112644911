import _ from "underscore";
import ActionTypes from "actions/action-types.js";

const types = [ActionTypes.FETCH_SUBSCRIPTION_SKUS_SUCCESS];

const fn = function (state, action) {
  switch (action.type) {
    case ActionTypes.FETCH_SUBSCRIPTION_SKUS_SUCCESS: {
      const skus = action.payload.skus || [];
      const newSubscriptionSkus = _.map(skus, sku => {
        return _.pick(sku, "size", "rtrUpdateAvailabilities", "unlimitedAvailabilities");
      });
      return _.extend({}, state, { subscriptionSkus: newSubscriptionSkus });
    }

    default:
      return state;
  }
};

export default {
  types: types,
  fn: fn,
};
