import qs from "qs";
import { ASSORTMENTS, HIDDEN_FILTERS } from "rtr-constants";
import womensAccessoryFilters from "../../../assets/javascripts/constants/womensAccessoryFilters";
import staticDiscoFilterOptions from "../../../assets/javascripts/constants/staticDiscoFilters";
import mixedAssormentFilters from "../../../assets/javascripts/constants/mixedAssortmentFilters";
import { discoArrayToProductImageStruct } from "helpers/product-image-helpers";

const DEFAULT_SORT = {
  clearance: "newest", // currently being set by swat-storefront-swat-787-clearance-grid-default-sort-segmentation (removal ticket: SWAT-843)
  category: "newest",
  designer: "newest",
  curation: "curation", // "Recommended" in the UI
};

const SORT_MAPPING = {
  clearance: {
    recommended: "recommended",
    newest: "newest",
    priceDesc: "highest_price",
    priceAsc: "lowest_price",
    highest_price: "highest_price",
    lowest_price: "lowest_price",
    highest_msrp: "highest_msrp",
    lowest_msrp: "lowest_msrp",
    highest_discount: "highest_discount",
    newest_clearance: "newest_clearance",
  },
  curation: {
    curation: "curation",
    newest: "newest",
    highest_msrp: "highest_msrp",
    lowest_msrp: "lowest_msrp",
    highest_price: "highest_price",
    lowest_price: "lowest_price",
  },
  designer: {
    recommended: "newest", // old links containing sort=recommended should map to sort=newest
    newest: "newest",
    highest_msrp: "highest_msrp",
    lowest_msrp: "lowest_msrp",
    highest_price: "highest_price",
    lowest_price: "lowest_price",
  },
  category: {
    newest: "newest",
    highest_msrp: "highest_msrp",
    lowest_msrp: "lowest_msrp",
    highest_price: "highest_price",
    lowest_price: "lowest_price",
  },
  view_all: {
    newest: "newest",
    highest_msrp: "highest_msrp",
    lowest_msrp: "lowest_msrp",
    highest_price: "highest_price",
    lowest_price: "lowest_price",
  },
};

export const DEFAULT_FILTERS = {
  "/c/floral-dresses": {
    print: ["floral"],
  },
  "/c/off-the-shoulder-dresses": {
    neckline: ["offtheshoulder"],
  },
  "/c/sequin-dresses": {
    embellishment: ["sequins"],
  },
  "/c/summer-dresses": {
    seasonality: ["springsummer"],
  },
};

function coerceStringToArray(value) {
  if (!value) return;
  return typeof value === "string" ? [value] : value;
}

function coerceValueToArray(value) {
  return value && !Array.isArray(value) ? [String.valueOf(value)] : value;
}

// adding this to handle empty string values being passed and breaking the page
function returnStringIfNotEmpty(value) {
  if (value && typeof value === "string") return value;
}

export function coerceStringToBoolean(str) {
  return str?.toLowerCase() === "true";
}

export const setFilter = (key, value) => {
  return filters => {
    if (value !== null && value !== void 0) {
      filters[key] = value;
    }
    return filters;
  };
};

export const setFilterIfMissing = (key, value) => {
  return filters => {
    return !filters[key] ? setFilter(key, value)(filters) : filters;
  };
};

/**
 * Can be used as part of a call chain (compose) to update an object representing grid filter values.
 * Arrays will be appended to, and other values will be set if missing.
 * @param {object} newFilters - object containing Filters to be set or appended to existing arrays
 * @returns {function(object)} - function that receives existing filters (to be updated with newFilters)
 */
export const appendFilters = newFilters => {
  return filters => {
    return Object.entries(newFilters || {}).reduce((acc, [key, value]) => {
      if (Array.isArray(value) && Array.isArray(acc[key])) {
        acc[key] = [...new Set([...acc[key], ...value])];
      } else if (!acc[key]) {
        acc[key] = value;
      }
      return acc;
    }, filters);
  };
};

export const generateQueryString = filters => {
  return qs.stringify(filters, { arrayFormat: "comma", encode: false });
};

// remove these when reserve grids are on next
export const generateRubyStyleUrl = baseUrlWithPath => {
  return filters => {
    const queryUrl = qs.stringify(filters, {
      encode: false,
      arrayFormat: "brackets",
    });

    if (!baseUrlWithPath && !queryUrl) return;
    return (baseUrlWithPath || "") + "?" + (queryUrl || "");
  };
};

// remove these when reserve grids are on next
export const exchangeKeyValueInJson = obj => Object.fromEntries(Object.entries(obj || {}).map(([k, v]) => [v, k]));

/**
 * Using qs as Next.js query destructuring doesn't support complex objects e.g. retailPrice[maximum]=5
 *
 * Note: URL encoded commas are NOT handled as string delimiters, see: https://github.com/ljharb/qs/issues/410
 * e.g. canonicalSizes=16%2C18 -> canonicalSizes: ["16,18"]. To solve this we are using decodeURIComponent
 * to decode the escaped url into comma delimited URL which can be parsed qs
 *
 * Note 2: Need to use `req.url` over `resolvedUrl` as comma delimited lists are URL encoded in the
 * resolvedURL value which breaks our URL parsing
 */
export const parseQueryString = url => {
  if (url.split("?")[1]) {
    return qs.parse(decodeURIComponent(url.split("?")[1]), { comma: true });
  }
  return {};
};

export const mapFilters = (gridType, options = {}) => {
  return filters => {
    return hasLegacyFilters(filters)
      ? mapLegacyFiltersToGridQuery(filters, gridType, options)
      : mapFiltersToGridQuery(filters, gridType, options);
  };
};

export const addUserId = userId => {
  return filters => {
    if (filters && typeof filters === "object" && (!!filters?.hearts || !!filters?.previouslyRentedStyles)) {
      if (userId) {
        filters.userId = userId;
      } else {
        delete filters.hearts;
        delete filters.previouslyRentedStyles;
      }
    }
    return filters;
  };
};

// After parsing the query string with `qs` we need to ensure that certain values
// are lists. e.g. size=0 needs to be converted to `size=["0"]`, qs doesn't know that
// size is supposed to be a list so it works for the case with commas, e.g. size=0,2 => size: ["0", "2"]
// but for a single (non-delimited) string it parses it as a string: size=0 => size: "0"
export function mapFiltersToGridQuery(queryFilters, gridType, options = {}) {
  return createAdapter({
    buyPrice: data => data.buyPrice,
    buynow: data => data.buynow,
    category: data => coerceStringToArray(data.category),
    color: data => coerceStringToArray(data.color),
    curation: data => coerceStringToArray(data.curation),
    designer: data => coerceStringToArray(data.designer),
    embellishment: data => coerceStringToArray(data.embellishment),
    formality: data => coerceStringToArray(data.formality),
    hearts: data => coerceStringToArray(data.hearts),
    length: data => coerceStringToArray(data.length),
    maternity: data => coerceStringToArray(data.maternity),
    neckline: data => coerceStringToArray(data.neckline),
    page: data => data.page,
    previouslyRentedStyles: data => coerceStringToArray(data.previouslyRentedStyles),
    print: data => coerceStringToArray(data.print),
    seasonality: data => coerceStringToArray(data.seasonality),
    searchText: data => returnStringIfNotEmpty(data.searchText),
    shortlists: data => coerceStringToArray(data.shortlists),
    size: data => coerceStringToArray(data.size),
    sleeve: data => coerceStringToArray(data.sleeve),
    sort: data => SORT_MAPPING[gridType][data.sort] || options.defaultSort || DEFAULT_SORT[gridType],
    stylePersona: data => coerceStringToArray(data.stylePersona),
    useCaseEvent: data => coerceStringToArray(data.useCaseEvent),
    zipCode: data => data.zipCode,
  })(queryFilters);
}

/**
 * We need to be able to support old query params, e.g:
 *  /clearance/products?filters[zip_code]=21042&filters[canonicalSizes][]=0&filters[canonicalSizes][]=2
 * and new query params (a.k.a gridQuery)
 *  /clearance/products?zipCode=21042&size=0,2
 */
export function mapLegacyFiltersToGridQuery(queryFilters, gridType, options = {}) {
  return createAdapter({
    availableOnly: data => data.availableOnly, // when navigating from a ruby to next grid we tack on availableOnly
    buyPrice: {
      max: data => data.filters?.price?.maximum,
      min: data => data.filters?.price?.minimum,
    },
    buynow: data => data.buynow,
    category: data => coerceStringToArray(data.filters?.epicCategories),
    color: data => coerceStringToArray(data.filters?.colors),
    curation: data => coerceStringToArray(data.filters?.curations),
    designer: data => coerceStringToArray(data.filters?.designer),
    embellishment: data => coerceStringToArray(data.filters?.epicEmbellishments || data.embellishment),
    formality: data => coerceStringToArray(data.filters?.formality),
    hearts: data => coerceStringToArray(data.filters?.hearts),
    length: data => coerceStringToArray(data.filters?.epicLength || data.filters?.length),
    maternity: data => coerceStringToArray(data.filters?.epicMaternity),
    neckline: data => coerceStringToArray(data.filters?.epicNeckline || data.filters?.neckline),
    page: data => data.page,
    print: data => coerceStringToArray(data.filters?.epicPrint),
    seasonality: data => coerceStringToArray(data.filters?.epicSeasonality),
    searchText: data => returnStringIfNotEmpty(data.filters?.searchText),
    size: data => coerceStringToArray(data.filters?.canonicalSizes || data.filters?.canonicalsizes),
    sleeve: data => coerceStringToArray(data.filters?.epicSleeve || data.filters?.sleeve),
    sort: data => SORT_MAPPING[gridType][data.sort] || options.defaultSort || DEFAULT_SORT[gridType],
    stylePersona: data => coerceStringToArray(data.filters?.epicStylePersonas),
    useCaseEvent: data => coerceStringToArray(data.filters?.epicUseCaseEvents),
    // When we redirect we tack on `zipCode` (new format) to the query param and redirect
    zipCode: data => data.filters?.zip_code || data.zipCode,
  })(queryFilters);
}

// remove these when reserve grids are on next
export function mapGridQueryToLegacyFilters(gridType) {
  return queryFilters =>
    createAdapter({
      filters: {
        price: {
          maximum: data => data.buyPrice?.max,
          minimum: data => data.buyPrice?.min,
        },
        epicCategories: data => coerceStringToArray(data.category),
        colors: data => coerceStringToArray(data.color),
        curations: data => coerceStringToArray(data.curation),
        designer: data => coerceStringToArray(data.designer),
        epicEmbellishments: data => coerceStringToArray(data.embellishment),
        formality: data => coerceStringToArray(data.formality),
        hearts: data => coerceStringToArray(data.hearts),
        epicLength: data => coerceStringToArray(data.length),
        epicMaternity: data => coerceStringToArray(data.maternity),
        epicNeckline: data => coerceStringToArray(data.neckline),
        zip_code: data => data.zipCode,
        epicPrint: data => coerceStringToArray(data.print),
        epicSeasonality: data => coerceStringToArray(data.seasonality),
        searchText: data => returnStringIfNotEmpty(data.searchText),
        canonicalSizes: data => coerceStringToArray(data.size),
        epicSleeve: data => coerceStringToArray(data.sleeve),
        epicStylePersonas: data => coerceStringToArray(data.stylePersona),
        epicUseCaseEvents: data => coerceStringToArray(data.useCaseEvent),
      },
      buynow: data => data.buynow,
      page: data => data.page,
      sort: data => exchangeKeyValueInJson(SORT_MAPPING[gridType])?.[data.sort] || DEFAULT_SORT[gridType],
    })(queryFilters);
}

export function hasLegacyFilters(queryFilters) {
  return Object.keys(queryFilters).some(key => ["filters"].includes(key));
}

/**
 * Note that disco expects arrays for most productCriteria params, so we call coerceStringToArray
 * which ensures param strings like `color=red` and `color=red,green`
 * are passed as { productCriteria: { color: ["red"] }} and { productCriteria: { color: ["red", "green"] }} respectively,
 * Without this, values like { productCriteria: { color: "red" }} are passed to disco which results in a 400
 *
 * An alternative is to use `color[]=red` however this means a different key is required for single values.
 * i.e. `color[]` vs `color`
 */
export function mapGridQueryToDiscoQuery(queryFilters, gridType) {
  return createAdapter({
    "availableOnly": data => data.availableOnly,
    "canonicalSizes": data => data.size,
    "category": data => data.category,
    "color": data => data.color,
    "curationId": data => data.curation,
    "depotIds": data => (data.depotId ? [data.depotId] : null),
    "designerId": data => data.designer,
    "embellishment": data => data.embellishment,
    "hearts": data => data.hearts,
    "includeEarlyAccess": data => data.includeEarlyAccess,
    "length": data => data.length,
    "maternity": data => data.maternity,
    "neckline": data => data.neckline,
    "previouslyRentedStyles": data => data.previouslyRentedStyles,
    "priceBuy.max": data => data.buyPrice?.max,
    "priceBuy.min": data => data.buyPrice?.min,
    "print": data => data.print,
    "programEligibility": data => data.programEligibility,
    "searchText": data => returnStringIfNotEmpty(data.searchText),
    "seasonality": data => data.seasonality,
    "shortlistId": data => data.shortlists,
    "sleeve": data => data.sleeve,
    "sortOptions": data => SORT_MAPPING[gridType][data.sort] || DEFAULT_SORT[gridType],
    "stylePersona": data => data.stylePersona,
    "useCaseEvent": data => data.useCaseEvent,
    "userId": data => data.userId, // to pass the userId to disco in SSR
    "zipCode": data => data.zipCode,
  })(queryFilters);
}

// Takes a parsed query object and converts to object compatible with workingFilters object
// that lives in redux
export function mapGridQueryToWorkingFilters(query) {
  return createAdapter({
    availableOnly: data => data.availableOnly,
    canonicalSizes: data => data.size,
    colors: data => data.color,
    curation: data => data.curation,
    depotId: data => data.depotId,
    designer: data => data.designer,
    epicCategories: data => data.category,
    epicEmbellishments: data => data.embellishment,
    epicLength: data => data.length,
    epicMaternity: data => data.maternity,
    epicNeckline: data => data.neckline,
    epicPrint: data => data.print,
    epicSeasonality: data => data.seasonality,
    epicSleeve: data => data.sleeve,
    epicStylePersonas: data => data.stylePersona,
    epicUseCaseEvents: data => data.useCaseEvent,
    hearts: data => coerceValueToArray(data.hearts),
    includeEarlyAccess: data => data.includeEarlyAccess,
    previouslyRentedStyles: data => coerceValueToArray(data.previouslyRentedStyles),
    productCollections: data => data.shortlists,
    programEligibility: data => data.programEligibility,
    price: {
      maximum: data => data.buyPrice?.max,
      minimum: data => data.buyPrice?.min,
    },
    searchText: data => returnStringIfNotEmpty(data.searchText),
    zip_code: data => data.zipCode,
  })(query);
}

// These are the filter values that will be added to the URL if the user
// interacts with the zipCode modal, canonicalSizes modal, or the filter UI.
export function mapWorkingFiltersToGridQuery(filters) {
  return createAdapter({
    availableOnly: data => data.availableOnly,
    buyPrice: {
      max: data => data.price?.maximum,
      min: data => data.buyPrice?.min,
    },
    category: data => data.epicCategories,
    color: data => coerceStringToArray(data.colors),
    curation: data => data.curation,
    depotId: data => data.depotId,
    designer: data => coerceStringToArray(data.designer),
    embellishment: data => data.epicEmbellishments,
    formality: data => coerceStringToArray(data.formality),
    hearts: data => data.hearts?.[0],
    includeEarlyAccess: data => data.includeEarlyAccess,
    length: data => coerceStringToArray(data.epicLength),
    maternity: data => data.epicMaternity,
    neckline: data => data.epicNeckline,
    previouslyRentedStyles: data => data.previouslyRentedStyles?.[0],
    print: data => data.epicPrint,
    programEligibility: data => data.programEligibility,
    searchText: data => returnStringIfNotEmpty(data.searchText),
    seasonality: data => coerceStringToArray(data.epicSeasonality),
    shortlists: data => data.productCollections,
    size: data => data.canonicalSizes,
    sleeve: data => data.epicSleeve,
    stylePersona: data => coerceStringToArray(data.epicStylePersonas),
    useCaseEvent: data => data.epicUseCaseEvents,
    zipCode: data => data.zip_code,
  })(filters);
}

const isEmpty = value => {
  return (
    value === null ||
    value === "" ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0)
  );
};

export const removeEmptyProperties = obj => {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => !isEmpty(value)));
};

export function removeFilterWhen(filterNames, predicateFn) {
  return filters => {
    let newFilters = JSON.parse(JSON.stringify(filters));
    if (predicateFn()) {
      if (Array.isArray(filterNames)) {
        filterNames.forEach(filterName => (newFilters = removeFilter(filterName)(newFilters)));
        return newFilters;
      } else if (typeof filterNames === "string") {
        return removeFilter(filterNames)(newFilters);
      }
    }
    return filters;
  };
}

export function removeFilter(filterName) {
  return filters => {
    const { [filterName]: _, ...rest } = filters;
    return rest;
  };
}

export const sortFilterValuesAlphabetically = obj => {
  const filterOptions = obj;
  const filterKeys = Object.keys(obj);

  filterKeys.forEach(filterKey => {
    filterOptions[filterKey].values = obj[filterKey].values.sort((optionA, optionB) => {
      const textA = optionA.displayName;
      const textB = optionB.displayName;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  });
  return filterOptions;
};

export const removeFiltersForNextGridType = (gridType, filters) => {
  return Object.fromEntries(Object.entries(filters || []).filter((key, _) => !HIDDEN_FILTERS[gridType]?.includes(key)));
};

// This looks a bit crazy but it's just a functional adapter pattern that removes
// a whole bunch of imperative brittle mapping code that used to live in this file.
//
// e.g. const adapter = createAdapter({
//        a: data => data.firstName.toUpperCase()
//        b: data => data.lastName.toUpperCase()
//        c: {
//          d: data => data.country.toUpperCase()
//        }
//      })
//
//      adapter({ firstName: "Uncle", lastName: "Bob", country: "us"})
//      => { a: "UNCLE", b: "BOB", c: { d: "US" }}
//
export const createAdapter = transformations => {
  const reducer = input => {
    return data => {
      return Object.keys(input).reduce((acc, key) => {
        if (typeof input[key] === "function") {
          const value = input[key](data);
          if (Array.isArray(value)) {
            if (value.length > 0) {
              acc[key] = value;
            }
          } else if (value !== null && value !== void 0) {
            acc[key] = value;
          }
        } else if (typeof input[key] === "object" && Object.keys(input[key]).length > 0) {
          const value = reducer(input[key])(data);
          if (Object.keys(value).length > 0) {
            acc[key] = value;
          }
        }
        return acc;
      }, {});
    };
  };
  return reducer(transformations);
};

// Determine filter options based on assortment
export const getFilterOptions = assortment => {
  if (assortment.includes(ASSORTMENTS.WOMENS_ACCESSORIES) && !assortment.includes(ASSORTMENTS.WOMENS_APPAREL)) {
    return womensAccessoryFilters;
  } else if (assortment.includes(ASSORTMENTS.WOMENS_ACCESSORIES) && assortment.includes(ASSORTMENTS.WOMENS_APPAREL)) {
    return mixedAssormentFilters;
  }
  return staticDiscoFilterOptions;
};

export const mapDiscoProductToCarouselProduct = discoProduct => {
  const retailPrice = discoProduct.price.find(price => price.id === "msrp");
  const rentalPrice = discoProduct.price.find(price => price.id === "rental");

  function formatImagesUrlInProduct(images) {
    return images.map(imgUrl => imgUrl.replace("270x", "1080x"));
  }

  return {
    category: {
      id: discoProduct.category,
    },
    designer: {
      displayName: discoProduct.designerDisplayName,
    },
    displayName: discoProduct.displayName,
    id: discoProduct.productId,
    price: {
      adjustedMaximum: rentalPrice?.maxValue || 0,
      adjustedMinimum: rentalPrice?.minValue || 0,
      base: rentalPrice?.minValue || 0,
      adjusted: rentalPrice?.value || 0,
      discounts: null,
      merchandiseCategory: "RENTAL",
      minimum: rentalPrice?.value || 0,
    },
    prices: [
      {
        adjustedMaximum: rentalPrice?.maxValue || 0,
        adjustedMinimum: rentalPrice?.minValue || 0,
        base: rentalPrice?.minValue || 0,
        adjusted: rentalPrice?.value || 0,
        discounts: null,
        merchandiseCategory: "RENTAL",
        minimum: rentalPrice?.value || 0,
      },
    ],
    retailPrice: retailPrice.value,
    images: discoArrayToProductImageStruct(formatImagesUrlInProduct(discoProduct.images)),
    urlHistory: [discoProduct.pdpUrl],
  };
};
