import React from "react";

import AtomAddressCard from "components/source/atoms/atom-address-card.jsx";
import AtomAddressCardsList from "components/source/atoms/atom-address-cards-list";

import AtomFullWidthButton from "components/source/atoms/atom-full-width-button";
import AtomPrimaryButton from "components/source/atoms/atom-primary-button";
import AtomSecondaryButton from "components/source/atoms/atom-secondary-button";
import AtomHighlightTextButton from "components/source/atoms/atom-highlight-text-button";

import {
  addressesSortedByDefaultAddress,
  addressesSortedBySelectedFirst,
  addressesSortedById,
} from "../../../helpers/address-helpers";
import PropTypes from "prop-types";

import { addressPropType } from "../../propTypes/address-prop-type";
import { idPropType } from "../../propTypes/id-prop-type";

const propTypes = {
  addressBookTitle: PropTypes.string,
  addressBookDek: PropTypes.string,
  addresses: PropTypes.arrayOf(addressPropType).isRequired,
  hideNewAddressButton: PropTypes.bool,
  hideUseSelectedAddressButton: PropTypes.bool,
  initialAddressId: idPropType,
  isSubmitting: PropTypes.bool,
  onAddressSelect: PropTypes.func.isRequired,
  onBackButtonFlowCallback: PropTypes.func,
  selectedAddressId: idPropType,
  sortBySelectedFirst: PropTypes.bool,
  triggerAddressForm: PropTypes.func,
  useAddress: PropTypes.func,
  useSelectedAddressButtonText: PropTypes.string,
};

export const MoleculeAddressSelect = props => {
  const [selectedAddressId, setSelectedAddressId] = React.useState();

  // initialize the selected address according to this order:
  // 1.The props selectedAddress from a previously selected address
  // 2.The props initial Address
  // 3.The default address's id
  // 4.The newest address id
  React.useEffect(() => {
    const { initialAddressId, selectedAddressId, addresses } = props;
    let finalAddressId = selectedAddressId;
    if (!finalAddressId) {
      if (initialAddressId) finalAddressId = initialAddressId;
      else {
        const sortedAddresses = addressesSortedByDefaultAddress(addresses);
        if (sortedAddresses[0]?.default) finalAddressId = sortedAddresses[0].id;
        else finalAddressId = addressesSortedById(addresses)[0].id;
      }
    }
    setSelectedAddressId(finalAddressId);
  }, []);

  const addressSelectHandler = addressId => {
    props.onAddressSelect(addressId);
    setSelectedAddressId(addressId);
  };

  const onUseSelectedAddress = () => {
    props.useAddress(selectedAddressId);
  };

  const buttonText = props.isSubmitting ? "Saving..." : props.useSelectedAddressButtonText || "Use this address";

  const renderCards = () => {
    const sortedAddresses =
      props.sortBySelectedFirst && selectedAddressId
        ? addressesSortedBySelectedFirst(selectedAddressId, props.addresses)
        : addressesSortedByDefaultAddress(props.addresses);

    const finalSelectedAddressId = selectedAddressId ?? sortedAddresses[0]?.id;
    return sortedAddresses.map(address => {
      const { id } = address;
      return (
        <AtomAddressCard
          key={id}
          id={id}
          address={address}
          isChecked={finalSelectedAddressId === id}
          onChange={addressSelectHandler}
        />
      );
    });
  };

  return (
    <div className="molecule-address-select__wrapper">
      {props.addressBookTitle && <div className="molecule-address-select__header">{props.addressBookTitle}</div>}
      {props.addressBookDek && <div className="molecule-address-select__dek">{props.addressBookDek}</div>}
      <div className="molecule-address-select__cards">
        <AtomAddressCardsList addressCards={renderCards()} />
      </div>
      {!props.onBackButtonFlowCallback && !props.hideUseSelectedAddressButton ? (
        <div className="molecule-address-select__add">
          <AtomFullWidthButton buttonText={buttonText} onClick={onUseSelectedAddress} disabled={props.isSubmitting} />
        </div>
      ) : (
        <div className="generic-subform__buttons">
          <AtomSecondaryButton buttonText="Back" onClick={props.onBackButtonFlowCallback} />
          <AtomPrimaryButton buttonText={buttonText} onClick={onUseSelectedAddress} disabled={props.isSubmitting} />
        </div>
      )}
      {!props.hideNewAddressButton && (
        <div className="molecule-address-select__add-new">
          <AtomHighlightTextButton
            buttonText="or Add New Address"
            onClick={() => props.triggerAddressForm()}
            disabled={props.isSubmitting}
          />
        </div>
      )}
    </div>
  );
};

MoleculeAddressSelect.propTypes = propTypes;

export default MoleculeAddressSelect;
