import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  totalChildren: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  updatePage: PropTypes.func.isRequired,
};

class CarouselProgressionDots extends React.Component {
  updateCarouselPosition(desiredIndex) {
    const currentPlace = Math.ceil(this.props.currentIndex / this.props.pageSize);

    if (desiredIndex === currentPlace) {
      return;
    }
    // We need to calculate the difference between the current index and the index
    // to which we need to scroll. The updatePage function takes a directional number
    // as its param. For example, -1 will be one "swipe" left. +3 will be three "swipes" right.
    const displacement = desiredIndex - currentPlace;

    this.props.updatePage(displacement);
  }

  renderDots() {
    const { totalChildren, pageSize, currentIndex } = this.props;

    const numberOfDots = Math.ceil(totalChildren / pageSize);
    const selectedDot = Math.ceil(currentIndex / pageSize);

    return Array.from(Array(numberOfDots)).map((_value, i) => {
      const extraClass = i === selectedDot ? "carousel-progression__dot--selected" : "";

      return (
        <button
          className={`carousel-progression__dot ${extraClass}`}
          data-test-id="carousel-progression-dot"
          key={i}
          onClick={() => {
            this.updateCarouselPosition(i);
          }}
        />
      );
    });
  }

  render() {
    // If the total number of children is smaller than the page size, then
    // there would only be one dot. We shouldn't display these in that case!
    if (this.props.totalChildren <= this.props.pageSize) {
      return null;
    }

    return <div className="carousel-progression">{this.renderDots()}</div>;
  }
}

CarouselProgressionDots.propTypes = propTypes;

export default CarouselProgressionDots;
