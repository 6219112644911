import React from "react";
import { compose } from "redux";
import { Cookies, withCookies } from "react-cookie";
import { pageTypes, ONETRUST_CONSENT_MANAGEMENT } from "rtr-constants";
import PropTypes from "prop-types";
import * as TrafficSource from "helpers/traffic-source";
import ActionLogger from "action-logger";
import { isIPad, isIPhone } from "helpers/device-helpers";
import { isCookieCategoryActivated } from "helpers/cookie-consent-helper";
import { withQueryParams } from "../hoc/with-query-params";
import { featureFlagsPropType, Flags, withFeatureFlags } from "../hoc/with-feature-flags";
import { userDataPropType } from "../../propTypes";
import { getUserId, withUserData } from "../hoc/with-user-data";
import { connect } from "react-redux";

export class BranchIOIntegrationComponent extends React.Component {
  static INTERSTITIAL_PAGE_COUNT = 3;
  static INTERSTITIAL_VIEW_NAME = "interstitial";
  static PAGEVIEW_CACHE_KEY = "pagesViewed";
  static EVENT_NAMES = {
    DID_CLICK_JOURNEY_CLOSE: "didClickJourneyClose",
    DID_CLICK_JOURNEY_CTA: "didClickJourneyCTA",
    DID_SHOW_JOURNEY: "didShowJourney",
    WILL_NOT_SHOW_JOURNEY: "willNotShowJourney",
  };

  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    featureFlags: featureFlagsPropType.isRequired,
    pageName: PropTypes.string,
    queryParams: PropTypes.shape({
      logout: PropTypes.string,
      appDownload: PropTypes.string,
      requestedViaApp: PropTypes.string,
    }),
    userData: userDataPropType,
    userId: PropTypes.string,
  };

  componentDidMount() {
    const { featureFlags } = this.props;

    if (featureFlags[Flags.BRANCH_IO_INTEGRATION] && !process.env.IS_SSR) {
      if (featureFlags[Flags.ONETRUST_CONSENT_MANAGEMENT]) {
        window.addEventListener("oneTrustLoadEvent", this.initializeBranch, { once: true });
      } else {
        this.initializeBranch();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId && !prevProps.userId) {
      this.branch?.setIdentity(this.props.userId);
    }
  }

  initializeBranch = () => {
    this.addBranchEventListeners();
    this.triggerAppInterstitial();
  };

  addBranchEventListeners() {
    const { featureFlags, userData } = this.props;
    // If user opts out of targeted marketing technologies, we will disable Branch's tracking features
    // but maintain its sms and deep link features.
    let trackingDisabled;
    if (
      featureFlags[Flags.ONETRUST_CONSENT_MANAGEMENT] &&
      !isCookieCategoryActivated(ONETRUST_CONSENT_MANAGEMENT.COOKIE_CATEGORIES.TARGETING)
    ) {
      trackingDisabled = true;
    }
    // for cases where a Journey already appears on the page (like the top
    // banner, which appears until a user closes it), forced-open journeys
    // using the javascript sdk will not display. This code ensures that
    // for the scenarios where a forced-open Journey _will_ be shown, no
    // other Journeys open automatically
    let noJourneys;

    if (isIPad() || !this.isEligibleForBranchView() || this.getAppInterstitialView()) {
      noJourneys = true;
    }

    const branchInitOptions = {
      no_journeys: noJourneys,
      tracking_disabled: trackingDisabled,
    };

    // NW [EXPLANATION] 10/7/21: RTR.VENDORS.BRANCH and window.branch are populated in lib/views/partials/_branch_io_sdk.erb
    this.branch = window.branch;
    this.branch?.init(RTR?.VENDORS?.BRANCH, branchInitOptions);

    const { EVENT_NAMES } = this.constructor;
    try {
      this.branch?.setBranchViewData({
        data: {
          referred_browser_id: this.props.cookies.get("RTR_ID"),
          referred_uid: this.props.userId,
        },
      });

      if (this.props.queryParams.logout === true) {
        this.branch?.logout();
      }

      const userId = getUserId(userData);

      const listener = function (event, data) {
        ActionLogger.logAction({
          uid: userId,
          object_type: "branch",
          action: event,
          view_id: data?.banner_id,
        });
      };

      this.branch?.addListener(EVENT_NAMES.DID_SHOW_JOURNEY, listener);
      this.branch?.addListener(EVENT_NAMES.WILL_NOT_SHOW_JOURNEY, listener);
      this.branch?.addListener(EVENT_NAMES.DID_CLICK_JOURNEY_CTA, listener);
      this.branch?.addListener(EVENT_NAMES.DID_CLICK_JOURNEY_CLOSE, listener);
    } catch (e) {
      window.Sentry?.captureException(e);
    }
  }

  pagesViewed() {
    const pagesViewed = this.props.cookies.get(this.constructor.PAGEVIEW_CACHE_KEY);
    if (!pagesViewed) {
      return 0;
    }
    return parseInt(pagesViewed, 10);
  }

  getAppInterstitialView() {
    const { INTERSTITIAL_PAGE_COUNT, INTERSTITIAL_VIEW_NAME } = this.constructor;
    const { featureFlags } = this.props;

    if (
      this.pagesViewed() !== INTERSTITIAL_PAGE_COUNT ||
      !featureFlags[Flags.BRANCH_APP_INTERSTITIAL] ||
      !this.isEligibleForBranchView()
    ) {
      return;
    }

    const name = INTERSTITIAL_VIEW_NAME;

    const branchBanner = RTR?.config?.branch_io_banners?.find(banner => banner?.name === name);

    // if it's clearly bad data, return undefined
    if (!branchBanner || !branchBanner.view_id) {
      return;
    }

    return branchBanner;
  }

  isEligibleForBranchView() {
    const isIneligiblePage = [
      "PAID-LANDING", // do not interrupt paid landing pages
      pageTypes.REFERRAL, // do not interrupt referral traffic
      pageTypes.CHECKOUT, // do not interrupt checkout traffic
      pageTypes.NEW_CHECKOUT,
      pageTypes.BLOG, // We think that this decreases click-through rate
      pageTypes.ABOUT_US, // Users cannot see this content on the app
    ].includes(this.props.pageName);

    if (
      isIneligiblePage ||
      !isIPhone() || // do not show to users who could not download the app
      TrafficSource.isPaidSession() || // do not interrupt paid traffic
      this.props.queryParams.requestedViaApp || // do not interrupt in-app traffic
      this.props.queryParams.appDownload === false // do not interrupt if explicitly set not to
    ) {
      return false;
    }

    return true;
  }

  triggerAppInterstitial() {
    const interstitialView = this.getAppInterstitialView();

    if (!interstitialView?.view_id) {
      return;
    }

    this.branch?.track("pageview", null, { branch_view_id: interstitialView.view_id });
  }

  render() {
    return <div className="branch-journeys-top" data-test-id="branch-io-integration"></div>;
  }
}

function mapStateToProps(state) {
  return {
    authModal: state.authModal,
  };
}

export default compose(
  connect(mapStateToProps),
  withFeatureFlags(Flags.BRANCH_APP_INTERSTITIAL, Flags.BRANCH_IO_INTEGRATION, Flags.ONETRUST_CONSENT_MANAGEMENT),
  withQueryParams("logout", "requestedViaApp", "appDownload"),
  withUserData,
  withCookies
)(BranchIOIntegrationComponent);
