import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
class AtomPill extends React.Component {
  static propTypes = {
    additionalClassName: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    inactive: PropTypes.bool,
    bold: PropTypes.bool,
    large: PropTypes.bool,
    id: PropTypes.string,
  };

  render() {
    const className = classNames("atom-pill", {
      inactive: this.props.inactive,
      bold: this.props.bold,
      large: this.props.large,
      [this.props.additionalClassName]: this.props.additionalClassName,
    });

    return (
      <div data-test-id="atom-pill" className={className} id={this.props.id}>
        {this.props.children}
      </div>
    );
  }
}

export default AtomPill;
