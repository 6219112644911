import styles from "./styles.module.scss";
import RtrImage from "components/source/shared/rtr-image";
import { useSelector } from "react-redux";

const VISUAL_NAV_CONFIG_DESKTOP = [
  {
    ctaUrl: "/shop/fall_wedding_dresses/products?upsell_source=modal_visualnav",
    imgUrl: "https://pc-ap.rtrcdn.com/productimages/front/1080x/4b/SA233.jpg?auto=webp&optimize=medium&width=1080",
    title: "Wedding Guest",
  },
  {
    ctaUrl: "/c/cocktail-dresses?upsell_source=modal_visualnav",
    imgUrl: "https://pc-ap.rtrcdn.com/productimages/front/1080x/7b/TA151.jpg?auto=webp&optimize=medium&width=1080",
    title: "Cocktail Dresses",
  },
  {
    ctaUrl: "/shop/black_tie_wedding_dresses/products?upsell_source=modal_visualnav",
    imgUrl: "https://pc-ap.rtrcdn.com/productimages/front/1080x/ce/VGL29.jpg?auto=webp&optimize=medium&width=1080",
    title: "Black Tie",
  },
  {
    ctaUrl: "/shop/formal_affair/products?upsell_source=modal_visualnav",
    imgUrl: "https://pc-ap.rtrcdn.com/productimages/editorial/1080x/c4/DTP118.jpg?auto=webp&optimize=medium&width=1080",
    title: "Formal Affair",
  },
];

const VISUAL_NAV_CONFIG_MWEB = [
  {
    ctaUrl: "/c/cocktail-dresses?upsell_source=modal_visualnav",
    imgUrl: "https://pc-ap.rtrcdn.com/productimages/front/1080x/7b/TA151.jpg?auto=webp&optimize=medium&width=1080",
    title: "Cocktail Dresses",
  },
  {
    ctaUrl: "/shop/formal_affair/products?upsell_source=modal_visualnav",
    imgUrl: "https://pc-ap.rtrcdn.com/productimages/editorial/1080x/c4/DTP118.jpg?auto=webp&optimize=medium&width=1080",
    title: "Formal Affair",
  },
  {
    ctaUrl: "/shop/black_tie_wedding_dresses/products?upsell_source=modal_visualnav",
    imgUrl: "https://pc-ap.rtrcdn.com/productimages/front/1080x/ce/VGL29.jpg?auto=webp&optimize=medium&width=1080",
    title: "Black Tie",
  },
];

export default function UpsellBonusStyleVisualNav() {
  const isMobileViewport = useSelector(state => state.browser?.isMobileViewport);
  const VISUAL_NAV = isMobileViewport ? VISUAL_NAV_CONFIG_MWEB : VISUAL_NAV_CONFIG_DESKTOP;

  return (
    <div className={styles["upsell-nav"]} data-test-id="upsell-nav">
      {VISUAL_NAV.map((config, index) => {
        return (
          <a href={config.ctaUrl} key={index} className={styles["upsell-nav-item"]}>
            <RtrImage className={`${styles["upsell-nav-item-img"]}`} src={config.imgUrl} />
            <div className={styles.overlay} />
            <p className={styles["upsell-nav-item-title"]}>{config.title}</p>
          </a>
        );
      })}
    </div>
  );
}
