import { ThirdPartyScript } from "./thirdPartyScript";
import { shallowEqual, useSelector } from "react-redux";

// Heap analytics start for renttherunway.com
const heap = () => {
  const publicEnv = useSelector(state => state.publicEnv, shallowEqual);
  if (publicEnv?.heapAppId) {
    return (
      <>
        <ThirdPartyScript
          id="heap-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              try {
                window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
                var heapAppId = "${publicEnv.heapAppId}";
                heap.load(heapAppId, { secureCookie: true });
                window.dispatchEvent(new CustomEvent("heapLoaded"));
              } catch (e) {
                if (window.Sentry && window.Sentry.captureException) { window.Sentry.captureException(e, {
                  tags: {
                    thirdparty: true,
                    thirdparty_name: "heap"
                  }
                }); }
              }
            `,
          }}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default heap;
