// This is the intersection of our modal styles, for Storefront, and the default
// inline styles provided by `react-modal` v1.0.0. Our modal styles take
// priority. The default inline styles are included here because `react-modal`
// does not include them if the `className` property is used.

const CustomModalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(51,51,51,0.8)",
    zIndex: "1000",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
  },
  content: {
    position: "absolute",
    top: "auto",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "0",
    outline: "none",
    padding: "0 25px",
    maxWidth: "100%",
    overflowY: "visible",
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
    boxSizing: "border-box",
    MozTransform: "translateX(-50%)",
    MsTransform: "translateX(-50%)",
    WebkitTransform: "translateX(-50%)",
    transform: "translateX(-50%)",
  },
};

export default CustomModalStyles;

export const { overlay, content } = CustomModalStyles;
