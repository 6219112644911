import { HEAP_DRAWER_TRIGGERS } from "helpers/heap-helpers";
import { useEffect, useRef } from "react";

const useDrawerDrag = (drawerRef, drawerHeight, setDrawerHeight, handleClose, browser) => {
  const startY = useRef(0);
  const startX = useRef(0);
  const endY = useRef(0);
  const endX = useRef(0);

  const isDragging = useRef(false);
  const isFullScreen = useRef(false);
  const animationFinished = useRef(false);

  const setImagePointerEvents = bool => {
    // This is only relevant for non-touch gestures i.e. dragging up using your mouse
    // Click dragging is not as sophisticated as touch dragging and can sometimes fire click events at the end of a drag.
    // To prevent, manually disable pointer events until the animation is finished
    const clickableArea = drawerRef?.current;
    if (clickableArea) {
      if (bool) {
        clickableArea.style.removeProperty("pointer-events");
      } else {
        clickableArea.style.pointerEvents = "none";
      }
    }
  };

  const handleStart = e => {
    if (isFullScreen.current) return;
    startX.current = e.touches ? e.touches[0].clientX : e.clientX;
    startY.current = e.touches ? e.touches[0].clientY : e.clientY;
    isDragging.current = true;
  };

  const handleMove = e => {
    if (isFullScreen.current || !isDragging.current) return;

    const currentY = e.touches ? e.touches[0].clientY : e.clientY;
    const currentX = e.touches ? e.touches[0].clientX : e.clientX;
    const diffY = startY.current - currentY;
    const diffX = startX.current - currentX;

    if (Math.abs(diffY) > Math.abs(diffX) && Math.abs(diffY) >= 5) {
      e.preventDefault();

      if (!e.touches) {
        setImagePointerEvents(false);
      }

      if (diffY > 0) {
        setFullScreen();
      } else {
        setCloseScreen();
      }
    }
  };

  const handleEnd = e => {
    if (isFullScreen.current) return;

    const clientY = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;
    const clientX = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
    endY.current = clientY;
    endX.current = clientX;

    isDragging.current = false;

    const diffY = startY.current - endY.current;
    const diffX = startX.current - endX.current;

    if (Math.abs(diffY) > Math.abs(diffX) && Math.abs(diffY) >= 5) {
      e.preventDefault();

      if (diffY > 0) {
        setFullScreen();
      } else if (diffY < 0) {
        setCloseScreen();
      }
    }
  };

  const handleTransitionEnd = () => {
    if (drawerHeight === 0) {
      handleClose(HEAP_DRAWER_TRIGGERS.DRAG);
    }

    if (isFullScreen.current) {
      animationFinished.current = true;
      setImagePointerEvents(true);
    }
  };

  const removeTouchEventListeners = () => {
    const drawerElement = drawerRef?.current;
    if (drawerElement) {
      drawerElement.removeEventListener("mousedown", handleStart);
      drawerElement.removeEventListener("mousemove", handleMove);
      drawerElement.removeEventListener("mouseup", handleEnd);
      drawerElement.removeEventListener("touchstart", handleStart);
      drawerElement.removeEventListener("touchmove", handleMove);
      drawerElement.removeEventListener("touchend", handleEnd);
    }
  };

  const setFullScreen = () => {
    removeTouchEventListeners();
    isFullScreen.current = true;
    setDrawerHeight(window.innerHeight);
  };

  const setCloseScreen = () => {
    removeTouchEventListeners();
    setDrawerHeight(0);
    isFullScreen.current = false;
  };

  useEffect(() => {
    if (!browser?.isMobileViewport) return;

    const drawerElement = drawerRef?.current;
    if (drawerElement && !isFullScreen.current) {
      drawerElement.addEventListener("mousedown", handleStart, { passive: true });
      drawerElement.addEventListener("mousemove", handleMove, { passive: false });
      drawerElement.addEventListener("mouseup", handleEnd, { passive: false });
      drawerElement.addEventListener("touchstart", handleStart, { passive: true });
      drawerElement.addEventListener("touchmove", handleMove, { passive: false });
      drawerElement.addEventListener("touchend", handleEnd, { passive: false });
      drawerElement.addEventListener("transitionend", handleTransitionEnd, { passive: false });
    }
  }, [browser, drawerHeight]);

  useEffect(() => {
    return () => {
      removeTouchEventListeners();
      const drawerElement = drawerRef?.current;
      if (drawerElement) {
        drawerElement.removeEventListener("transitionend", handleTransitionEnd);
      }
    };
  }, []);

  return { isFullScreen: isFullScreen.current, animationFinished: animationFinished.current };
};

export default useDrawerDrag;
