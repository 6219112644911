const ctaPauseStates = {
  futurePauseWithItems: "futurePauseWithItems",
  futurePauseWithoutItems: "futurePauseWithoutItems",
  futureUnPause: "futureUnPause",
  futureUnCancel: "futureUnCancel",
  unPause: "unPause",
  extendPause: "extendPause",
};

export default ctaPauseStates;

export const { futurePauseWithItems, futurePauseWithoutItems, futureUnPause, unPause, extendPause } = ctaPauseStates;
