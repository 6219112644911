import ActionLogger from "action-logger";
import classnames from "classnames";
import { analytics, dateFnsFormats, orderTracking, specialSkus } from "rtr-constants";
import { redirectToRoute } from "actions/action-utils";
import { format } from "date-fns";
import { getLocalDate } from "helpers/date-helpers";

export const directToOrderDetails = (tabName, orderId) => {
  if (tabName === "tracking") {
    const logData = {
      object_type: analytics.OBJECT_TYPE.ORDER_TRACKING_SKINNY_BANNER_LINK,
      action: analytics.ACTION_TYPE.CLICK,
      orderId: orderId,
    };
    ActionLogger.logAction(logData);
  }
  redirectToRoute(`/user/orders/${orderId}?focus=${tabName}`);
};

export const formatDate = dateString => format(getLocalDate(new Date(dateString)), dateFnsFormats.shortMonthAndDay);

export const formatDateWithTime = (dateString, timeString, utcOffset, createdDateTime) => {
  // Shipments for the courier 'Breakaway' don't include localDate and localTime so we fallback on createdDateTime
  const eventTime = dateString ? `${dateString}T${timeString}${utcOffset ?? "Z"}` : createdDateTime;
  return format(new Date(eventTime), `${dateFnsFormats.hoursAndMinutes} 'on' ${dateFnsFormats.shortMonthAndDay}`);
};

export const formatEstimatedDeliveryDateAndTime = (dateString, timeString) => {
  const estimatedDeliveryDateAndTime = `${dateString}T${timeString}`;
  return format(
    new Date(estimatedDeliveryDateAndTime),
    `${dateFnsFormats.hour} 'on' ${dateFnsFormats.shortMonthAndDay}`
  );
};

export const isMembershipSku = sku =>
  sku.includes(specialSkus.membershipStyles.UNLIMITED) || sku.includes(specialSkus.membershipStyles.UPDATE);

export const isMembershipOnlyGroup = skus => skus.length === 1 && isMembershipSku(skus[0]);

export const carrierName = tracking => {
  const { shipmentEvents, outForDeliveryEvents } = tracking;
  return shipmentEvents?.[0]?.carrierName || outForDeliveryEvents?.[0]?.carrierName;
};

export const courierShipment = tracking => {
  const carrier = carrierName(tracking);

  if (carrier === "UPS" || carrier === "FedEx") {
    return false;
  }

  return true;
};

export const renderStatusHeader = tracking => {
  const displayException = orderHasException(tracking);
  const headerClassName = classnames("subscription-item-card-header", { "red-dot-alert": displayException });
  return (
    <div className={headerClassName}>
      <header>{statusText(tracking)}</header>
    </div>
  );
};

// Returns true if the latest convey shipment event is an exception with a revised delivery date
export const orderHasException = tracking => {
  const { statuses } = orderTracking;
  return tracking.deliveryStatus === statuses.DELAYED;
};

export const formattedRevisedDeliveryDate = tracking =>
  format(getLocalDate(new Date(tracking.estimatedDeliveryDate)), dateFnsFormats.MM_DD);

export const statusText = tracking => {
  const { statusDisplayStrings } = orderTracking;
  const { statuses } = orderTracking;
  switch (tracking.deliveryStatus) {
    case statuses.AT_HOME:
      return statusDisplayStrings.AT_HOME;
    case statuses.DELAYED:
      return `${statusDisplayStrings.DELAYED} - Arriving ${formattedRevisedDeliveryDate(tracking)}`;
    case statuses.IN_THE_WORKS:
      return statusDisplayStrings.IN_THE_WORKS;
    case statuses.IN_TRANSIT:
      return statusDisplayStrings.IN_TRANSIT;
    case statuses.SHIPPED:
      return statusDisplayStrings.SHIPPED;
    case statuses.OUT_FOR_DELIVERY:
      return statusDisplayStrings.OUT_FOR_DELIVERY;
  }
};
