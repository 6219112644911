import React, { useEffect, useState } from "react";
import { CookiesProvider as ReactCookiesProvider, Cookies } from "react-cookie";
import { childrenPropType } from "../propTypes";

export function CookieProvider({ children }) {
  const [cookie, setCookie] = useState("");

  useEffect(() => {
    setCookie(document.cookie);
  }, []); // componentDidMount

  return <ReactCookiesProvider cookies={new Cookies(cookie)}>{children}</ReactCookiesProvider>;
}

CookieProvider.propTypes = {
  children: childrenPropType.isRequired,
};

// the default export is pattern matching other providers
export default {
  Provider: CookieProvider,
};
