import React from "react";

import ProductImage from "../shared/product-image";
import {
  getProductImageAltText,
  getProductImageUrlsByOrientation,
  ProductImageSize,
} from "../../../helpers/product-image-helpers";
import { productPropType } from "../../propTypes";

const LineItemCompact = ({ product }) => {
  const { images, designer, displayName, size } = product || {};

  if (!displayName) {
    return null;
  }

  return (
    <div className="swaps-line-item-compact">
      <div className="swaps-line-item-compact__product-area">
        <ProductImage
          altText={getProductImageAltText(displayName, designer?.displayName)}
          imageGroup={getProductImageUrlsByOrientation(images, [])}
          imageSize={ProductImageSize.x70}
        />
        <div className="swaps-line-item-compact__product-info">
          <div className="swaps-line-item-compact__designer-label">{designer?.displayName}</div>
          <div>{displayName}</div>
          <div>Size {size}</div>
        </div>
      </div>
    </div>
  );
};

LineItemCompact.propTypes = {
  product: productPropType,
};

export default LineItemCompact;

export const { propTypes } = LineItemCompact;
