import PropTypes from "prop-types";

export const membershipBagPropType = PropTypes.shape({
  crossShipReturnByDateForToday: PropTypes.string,
  errors: PropTypes.object,
  group: PropTypes.object,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  orderInvoice: PropTypes.object,
  paymentProfiles: PropTypes.array,
  street: PropTypes.string,
  zipcode: PropTypes.string,
});
