import { isSSR } from "../helpers/client-server-helper";
import { CachedServicesClient } from "./CachedServicesClient";

export const Locations = {
  footer: "footer",
  pageContent: "page-content",
  pageMetadata: "page-metadata",
  smartComponent: "smart-component",
  storefrontJson: "storefront-json",
};

export default class CmsServiceClient extends CachedServicesClient {
  static CONFIG_HOST = isSSR()
    ? process.env.CONFIG__CMS__HOST
    : `${window.location.origin}${process.env.NEXT_PUBLIC_CONFIG__CMS__HOST}`;

  async getAllContent(path) {
    // TODO: use this.getJson
    return this.get("component/content", { url: path }, { cache: "default" })
      .then(async response => {
        const rawCmsContent = await response.text();

        return { ...JSON.parse(rawCmsContent), fromCache: response.fromCache };
      })
      .catch(_parseError => {
        console.error(`Caught an error parsing json for path: ${path}`, _parseError);
      });
  }

  async getComponentViews(url, userId) {
    const encodedUrl = encodeURIComponent(url);
    const options = {
      bypassCache: true,
    };

    return this.get(`/component/views?url=${encodedUrl}&userId=${userId}`, null, options)
      .then(async response => {
        const rawCmsContent = await response.text();

        return JSON.parse(rawCmsContent)[0].views;
      })
      .catch(async error => {
        console.error(`Caught an error fetching json for component view URL: ${url}`, error);
        return [];
      });
  }

  // this is specifically fetching the CMS entry for siteNavigation which
  // is required on all pages but isn't included in any of the CMS bundles
  async getSiteNav() {
    const path = "/smart_nav_menu";

    /**
     * sbenedict 3/13/24 - the Nav is a smart component and can be segmented via the CMS by user cohort
     * We SSR/cache the default smart nav view (typically the anonymous/non-subscriber view).
     * Once the page is loaded, we make a second request for the user-specific entry.
     */
    return this.getContent(path, "smart-component").then(response => {
      try {
        const sections = response?.views?.find(v => v.name === "default")?.sections || [];
        return { sections, fromCache: response.fromCache, isSmart: false };
      } catch (_parseError) {
        console.error(`Caught an error parsing json for path: ${path}`, _parseError);
      }
    });
  }

  // this is specifically fetching the CMS entry for siteNavigation on mobile web which
  // is required on all pages but isn't included in any of the CMS bundles
  async getSiteNavMobile() {
    const path = "/smart_nav_menu_mobile";

    /**
     * sbenedict 3/13/24 - the Nav is a smart component and can be segmented via the CMS by user cohort
     * We SSR/cache the default smart nav view (typically the anonymous/non-subscriber view).
     * Once the page is loaded, we make a second request for the user-specific entry.
     */
    return this.getContent(path, "smart-component").then(response => {
      const sections = response?.views?.find(v => v.name === "default")?.sections || [];
      return { sections, fromCache: response.fromCache, isSmart: false };
    });
  }

  async getContent(path, cmsLocation, parse = true) {
    const cmsContent = await this.getAllContent(path);

    if (!cmsContent) {
      return { fromCache: false };
    }

    const result = this.constructor.getContentAtLocation(cmsContent, cmsLocation, parse);
    if (!result) {
      return { fromCache: cmsContent.fromCache };
    }
    if (typeof result === "object" && !Array.isArray(result)) {
      result.fromCache = cmsContent.fromCache;
      return result;
    } else {
      return { content: result, fromCache: cmsContent.fromCache };
    }
  }

  async getContentBulk(paths, cmsLocation = Locations.storefrontJson) {
    if (!paths?.length) {
      return [];
    }

    const pathArgs = paths.map(path => `url=${path}`).join("&");
    /* NW [EXPLANATION] 8/15/23: query params are being appended here because the `qs` library is configured to use "brackets" formatting for array params.
     * This CMS path requires "repeat" format for array params. Seems like it's not worth propagating the configuration upstream right now
     * but can be done in the future. */
    const requestUrl = `component?${pathArgs}&containerId=${cmsLocation}`;
    return this.get(requestUrl, null, { cache: "default" }).then(async response => {
      const rawCmsContent = await response.text();

      try {
        return { content: JSON.parse(rawCmsContent), fromCache: response.fromCache };
      } catch (_parseError) {
        console.error(`Caught an error parsing json for URL: ${requestUrl}`, _parseError);
        return [];
      }
    });
  }

  static getContentAtLocation(cmsContent, cmsLocation, parse = true) {
    try {
      const locationContent = cmsContent[cmsLocation];

      if (!parse || !locationContent) return locationContent;

      return JSON.parse(locationContent);
    } catch (_parseError) {
      console.error(`Caught an error parsing json for cmsLocation: ${cmsLocation}`, _parseError);
    }
  }
}
