import React, { useState } from "react";
import PropTypes from "prop-types";
import SearchBar from "components/source/navigation/search-bar";
import semanticSearchPlaceholderText from "../common/semantic-search-placeholder";

const SemanticSearchDesktopNav = ({ toggleSearchExpanded, isExpanded, aiSearchPrompts, aiRotatingPrompts }) => {
  const [placeHolderText, setPlaceHolderText] = useState(semanticSearchPlaceholderText(aiRotatingPrompts));

  React.useEffect(() => {
    setPlaceHolderText(semanticSearchPlaceholderText(aiRotatingPrompts));
  }, [aiRotatingPrompts]);

  const expandedClass = isExpanded ? "expanded" : "";

  return (
    <div className={`semantic-search-desktop-nav ${expandedClass}`}>
      <div className={`search-header`}></div>
      <SearchBar
        analyticsObjectType="desktop_nav"
        inputId="search-bar-input"
        toggleSearchExpanded={toggleSearchExpanded}
        placeholder={placeHolderText}
        aiSearchPrompts={aiSearchPrompts}
      />
    </div>
  );
};

SemanticSearchDesktopNav.propTypes = {
  toggleSearchExpanded: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  aiSearchPrompts: PropTypes.any,
  aiRotatingPrompts: PropTypes.array,
};

export default SemanticSearchDesktopNav;
