import ActionTypes from "../actions/action-types.js";

export const types = [
  ActionTypes.ACTIVE_ORDERS_FAILURE,
  ActionTypes.ACTIVE_ORDERS_LOADING,
  ActionTypes.ACTIVE_ORDERS_SUCCESS,
];

export function fn(state, action) {
  let { activeOrders, activeOrdersError, activeOrdersInclude, activeOrdersLoading } = state;

  switch (action.type) {
    case ActionTypes.ACTIVE_ORDERS_FAILURE:
      activeOrdersError = true;
      break;

    case ActionTypes.ACTIVE_ORDERS_LOADING:
      activeOrdersLoading = action.payload;
      break;

    case ActionTypes.ACTIVE_ORDERS_SUCCESS:
      ({ activeOrders, include: activeOrdersInclude } = action.payload || {});
      activeOrdersError = null;
      break;

    default:
      return state;
  }

  return {
    ...state,
    activeOrders,
    activeOrdersError,
    activeOrdersInclude,
    activeOrdersLoading,
  };
}

export default {
  types: types,
  fn: fn,
};
