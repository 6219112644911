import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  type: PropTypes.string,
};

const Icon = ({ type }) => {
  switch (type) {
    case "designer":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="50" height="50" data-test-id="designer-icon">
          <defs>
            <clipPath id="b">
              <rect width="16" height="16" style={{ fill: "none", strokeWidth: 0 }} />
            </clipPath>
          </defs>
          <g style={{ clipPath: "url(#b)" }}>
            <path
              d="m4,9.5c-.27614,0-.5.22386-.5.5,0,.2761.22386.5.5.5h1c.27614,0,.5-.2239.5-.5,0-.27614-.22386-.5-.5-.5h-1Z"
              style={{ strokeWidth: 0 }}
            />
            <path
              d="m.5,10c0-.37617.18001-.69273.41917-.93507.23704-.24018.55525-.43355.90899-.58515.70927-.30398,1.65425-.47978,2.67184-.47978s1.96257.1758,2.67184.47978c.35374.1516.67195.34497.90899.58515.23915.24233.41916.55889.41917.93507v1h6c.2761,0,.5.2239.5.5v3.9929c0,.2762-.2239.5-.5.5H4.48664c-1.17555,0-2.13346-.2711-2.81369-.653-.33902-.1904-.62001-.4145-.82211-.6574-.19611-.2357-.35084-.5303-.35084-.8521v-3.8304Zm1.13092-.23264c-.11475.11628-.13084.19422-.13092.23236.00008.03818.01617.11668.13092.23298.11718.1187.31153.2486.59116.3684.55749.2389,1.36251.3989,2.27792.3989s1.72043-.16,2.27792-.3989c.27963-.1198.47398-.2497.59116-.3684.11506-.1166.13092-.1947.13092-.2327,0-.03801-.01586-.11605-.13092-.23264-.11718-.11873-.31154-.24859-.59117-.36844-.55749-.23893-1.36251-.39892-2.27791-.39892s-1.72042.15999-2.27791.39892c-.27963.11985-.47399.24971-.59117.36844Zm3.36908,2.21834v.0143h9v2.9929h-2.5v-1.4929h-1v1.4929h-2v-1.4929h-1v1.4929h-2v-1.4929h-1v1.4929h-.01336c-1.02602,0-1.81143-.2371-2.32413-.525-.25744-.1445-.43532-.2956-.54297-.425-.05342-.0642-.08507-.1177-.10238-.157-.01602-.0364-.01716-.0555-.01716-.0555v-2.4692c.10472.0574.21462.1104.32817.159.70926.304,1.65424.4798,2.67183.4798.16892,0,.33583-.0048.5-.0143Z"
              style={{ fillRule: "evenodd", strokeWidth: 0 }}
            />
            <path
              d="m5.56751.5c-.27614,0-.5.22386-.5.5v1.01668l-3.3817,1.49684c-.12552.05556-.22267.16027-.26869.28959-.04602.12931-.03685.27186.02536.39422l1.64969,3.24497c.33395-.06065.69009-.10137,1.06135-.11892l-1.58055-3.10896,3.19692-1.41505c.18093-.08009.29762-.25934.29762-.45721v-.84216h4.52819v.84216c0,.19787.1166.37712.2976.45721l3.1969,1.41505-1.9981,3.93033c-.0808.15883-.0709.34863.0259.49822.0745.1151.2314.32294.399.54491.0584.07725.118.15622.1759.23358.2433.32518.4694.63884.5764.83154.0396.0713.0791.1434.1183.216h1.1282c-.123-.2441-.2499-.48128-.3723-.70161-.1462-.26315-.4163-.63283-.65-.94507-.0747-.09987-.1455-.19362-.2101-.27917-.0601-.0796-.1148-.15209-.1624-.2158l2.0997-4.13002c.0622-.12236.0714-.26491.0253-.39422-.046-.12932-.1431-.23403-.2686-.28959l-3.3817-1.49684v-1.01668c0-.27614-.2239-.5-.5-.5h-5.52819Z"
              style={{ strokeWidth: 0 }}
            />
          </g>
        </svg>
      );

    case "curation":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="50" height="50" data-test-id="curation-icon">
          <path
            d="m1,1h9v9H1V1Zm1,1v7h7V2H2Zm10.5,2v8.5H4v-1h7.5v-7.5h1Zm2.5,2.5v8.5H6.5v-1h7.5v-7.5h1Z"
            style={{ fillRule: "evenodd", strokeWidth: 0 }}
          />
        </svg>
      );

    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="50" height="50" data-test-id="category-icon">
          <rect x="2.5" y="2.5" width="4" height="4" style={{ fill: "none", stroke: "#000" }} />
          <rect x="2.5" y="9.5" width="4" height="4" style={{ fill: "none", stroke: "#000" }} />
          <rect x="9.5" y="2.5" width="4" height="4" style={{ fill: "none", stroke: "#000" }} />
          <rect x="9.5" y="9.5" width="4" height="4" style={{ fill: "none", stroke: "#000" }} />
        </svg>
      );
  }
};

Icon.propTypes = propTypes;

export default Icon;
