import $ from "clients/RawClient";
import { createAction } from "redux-actions";
import ActionTypes from "./action-types.js";
import sharedActions from "./shared-actions";
import { createAjaxAuthRetry } from "../helpers/ajax-helpers";
import { membershipTriggeredModalsCMSKeys } from "rtr-constants";
import * as membershipStateActions from "./membership-state-actions";
import subscriptionNotificationCenterActions from "./subscription-notification-center-actions";
import HeapHelpers from "helpers/heap-helpers";

const sendMembershipPaymentInfoToHeap = (state, type) => {
  const isTierChange = type === "plan_upgrade";
  const planPreview = (isTierChange ? state.immediateUpgradePlanPreview : state.membershipChangePlanPreview) ?? {};
  const membershipTierId = planPreview.id;
  const previewInvoice = isTierChange
    ? planPreview.membershipTierChangeCharges?.[0]
    : planPreview.membershipInvoiceCharges?.[0];

  HeapHelpers.trackCheckoutAttempt(previewInvoice, planPreview.promoCode, {
    purchaseType: type,
    purchaseTypeDetail: membershipTierId,
  });
};

const actions = {
  executeMembershipChargeSuccess: createAction(ActionTypes.MEMBERSHIP_CHARGE_EXECUTE_SUCCESS),
  executeMembershipChargeFailure: createAction(ActionTypes.MEMBERSHIP_CHARGE_EXECUTE_FAILURE),
  executeMembershipChargePending: createAction(ActionTypes.MEMBERSHIP_CHARGE_EXECUTE_PENDING),

  executeMembershipCharge(modalNameFail, promoCode = null) {
    return (dispatch, getState) => {
      const executeMembershipChargeSettings = {
        url: `/membershipCharge`,
        type: "POST",
        data: {
          ...(promoCode && { promoCode }),
        },
      };

      dispatch(actions.executeMembershipChargePending(true));

      return $.ajax(executeMembershipChargeSettings)
        .then(
          () => {
            sendMembershipPaymentInfoToHeap(getState(), "subscription_recur");
            dispatch(actions.executeMembershipChargeSuccess());
            dispatch(membershipStateActions.fetchMembershipState());
            dispatch(subscriptionNotificationCenterActions.fetchNotification());
            dispatch(sharedActions.displayModal());
          },
          () => {
            dispatch(actions.executeMembershipChargeFailure());
            dispatch(sharedActions.displayModal(modalNameFail));
          }
        )
        .finally(() => {
          dispatch(actions.executeMembershipChargePending(false));
        });
    };
  },

  getMembershipChargeAmountSuccess: createAction(ActionTypes.MEMBERSHIP_CHARGE_GET_AMOUNT_SUCCESS),
  getMembershipChargeAmountFailure: createAction(ActionTypes.MEMBERSHIP_CHARGE_GET_AMOUNT_FAILURE),
  getMembershipChargeAmountPending: createAction(ActionTypes.MEMBERSHIP_CHARGE_GET_AMOUNT_PENDING),

  getMembershipChargeAmount(modalNameSuccess, modalNameFail) {
    return dispatch => {
      const getMembershipChargeAmountSettings = {
        url: `/membershipCharge`,
        type: "GET",
      };

      dispatch(actions.getMembershipChargeAmountPending({ modalName: modalNameSuccess }));
      dispatch(sharedActions.displayModal(modalNameSuccess));

      $.ajax(getMembershipChargeAmountSettings).then(
        price => {
          const payload = { price, modalName: modalNameSuccess };
          dispatch(actions.getMembershipChargeAmountSuccess(payload));
        },
        error => {
          dispatch(actions.getMembershipChargeAmountFailure(error));
          dispatch(sharedActions.displayModal(modalNameFail));
        }
      );
    };
  },

  getMembershipChangeTierChargeAmountSuccess: createAction(
    ActionTypes.MEMBERSHIP_CHARGE_GET_CHANGE_TIER_AMOUNT_SUCCESS
  ),
  getMembershipChangeTierChargeAmount(tierId, modalToBeUpdated) {
    return dispatch => {
      return $.ajax({
        url: `/membershipChangeTierCharge/${tierId}`,
        type: "GET",
      }).then(response => {
        const payload = {
          ...response,
          modalName: modalToBeUpdated,
        };
        dispatch(actions.getMembershipChangeTierChargeAmountSuccess(payload));
      });
    };
  },

  createChangeTierMembershipPaymentOrderPending: createAction(
    ActionTypes.MEMBERSHIP_CREATE_CHANGE_TIER_PAYMENT_ORDER_PENDING
  ),
  createChangeTierMembershipPaymentOrder(tierId, promoCode = null, onSuccess, onFailure) {
    return (dispatch, getState) => {
      dispatch(actions.createChangeTierMembershipPaymentOrderPending(true));
      return createAjaxAuthRetry({
        url: `/membershipChangeTierCharge/${tierId}`,
        type: "POST",
        headers: {
          Accept: "application/json",
        },
        data: {
          ...(promoCode && { promoCode }),
        },
      })
        .then(() => {
          dispatch(membershipStateActions.fetchMembershipState());
          dispatch(subscriptionNotificationCenterActions.fetchNotification());
          sendMembershipPaymentInfoToHeap(getState(), "plan_upgrade");

          if (typeof onSuccess === "function") {
            onSuccess();
          }
        })
        .catch(() => {
          dispatch(sharedActions.displayModal(membershipTriggeredModalsCMSKeys.invalidBilling));

          if (typeof onFailure === "function") {
            onFailure();
          }
        })
        .finally(() => {
          dispatch(actions.createChangeTierMembershipPaymentOrderPending(false));
        });
    };
  },
};

export default actions;

export const {
  executeMembershipChargeSuccess,
  executeMembershipChargeFailure,
  executeMembershipChargePending,
  executeMembershipCharge,
  getMembershipChargeAmountSuccess,
  getMembershipChargeAmountFailure,
  getMembershipChargeAmountPending,
  getMembershipChargeAmount,
  getMembershipChangeTierChargeAmountSuccess,
  getMembershipChangeTierChargeAmount,
  createChangeTierMembershipPaymentOrder,
} = actions;
