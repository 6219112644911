import PropTypes from "prop-types";
import { idPropType } from "./id-prop-type";

export const orderPropType = PropTypes.shape({
  type: PropTypes.string,
  id: idPropType,
  orderType: PropTypes.string,
  paymentMethodIds: idPropType,
  promoCode: PropTypes.string,
  source: PropTypes.string,
  status: PropTypes.string,
  invoices: PropTypes.arrayOf(PropTypes.object),
  currentInvoice: PropTypes.object,
  orderGroups: PropTypes.arrayOf(PropTypes.object),
  orderGroupItems: PropTypes.arrayOf(PropTypes.object),
  previewInvoice: PropTypes.object,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  sailthruMarketing: PropTypes.object,
  user: PropTypes.object,
});

export const ordersPropType = PropTypes.arrayOf(orderPropType);
