import { getQueryParam } from "../helpers/location-helpers";
import { clearDefaultShippingZip } from "../helpers/dfc-ux-helper";
import ViewedProductsHelpers from "../helpers/viewed-products-helpers";

export function cleanUpLocalStorageOnLogout() {
  if (getQueryParam("logout") === "true") {
    clearDefaultShippingZip();
    ViewedProductsHelpers.clearViewedProducts();
  }
}
