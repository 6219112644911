import _ from "underscore";
import { differenceInDays, subHours, isAfter, isSameDay, format, isDate, addDays, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";

import { dateFnsFormats } from "rtr-constants";
import SizeHelper from "helpers/form-field-size-helper";
import { easternTimeAdjust } from "helpers/exchange-helper";
import { buildBaseFilters, fetchProductsWithCallback } from "actions/user-problems-actions";
import { ProductImageSize } from "./product-image-helpers";
export const modalTypes = {
  EXIT: "EXIT",
  ERROR: "ERROR",
  ADD_TO_BAG_ERROR: "ADD_TO_BAG_ERROR",
  SUCCESS: "SUCCESS",
  BTS: "BTS",
  PAYMENT: "PAYMENT",
  EXCHANGE_SUCCESS: "EXCHANGE_SUCCESS",
};

export const TOP_PICKS = "top_picks";
export const VIEW_ALL = "view_all";
export const LOADING_GIF_URL = "https://sf-p.rtrcdn.com/images/loading.gif";
export const SIMILAR_DELIMITER = "similar_";
export const DEFAULT_PRICE_MIN = 30;
export const ORDER_DETAILS_MODAL = "OrderDetailsModal";
export const SWAPS_CONTAINER_MODAL = "SwapsContainerModal";
export const PAGE_LIMIT = 18;
export const MOBILE_ROW_SIZE = 2;
export const DESKTOP_ROW_SIZE = 3;
export const AVAILABILITIES = {
  unlimited: "unlimitedAvailabilities",
  classic: "rentalAvailabilities",
};

export const swapsFeatureFlags = {
  RTR_UPDATE: "desktop_swaps_update",
  RENTAL: "desktop_swaps_reserve",
};

export const swapsBonusFeatureFlags = {
  RENTAL: "reserve_swaps_plus_one",
};

export const exchangeFeatureFlag = "self_service_exchange";

export const ORDER_TYPE = {
  UPDATE: "RTR_UPDATE",
  UNLIMITED: "QUEUE",
  CLASSIC: "RENTAL",
};

export const fullImageUrl = (images, size, key) => {
  const keyVal = key && images[key] ? key : Object.keys(images)[0];
  const partial = size ? images[keyVal][size] : images[keyVal][ProductImageSize.x183.path];
  return "https://pc-as.renttherunway.com/" + partial;
};

/**
 * Only 4- and 8-day rentals are currently eligible for the PO swap flow.
 * Subscription PO swaps no longer work with membership platform.
 * Purchases (Clearance/Bulk/KIF) are simply refunded by CX.
 * sbenedict 6/29/2022
 */
export const hasEligibleProblemBookings = problemBookings => {
  if (!problemBookings || !Array.isArray(problemBookings)) {
    return false;
  }

  return problemBookings.some(b => Boolean(b.rentEnd));
};

function isDST(d) {
  // https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();
}

export const canShip = lastShip => {
  const now = new Date();
  let lps = new Date(lastShip);

  if (isDST(lps)) {
    lps = subHours(lps, 1);
  }
  return isAfter(lps, now);
};

export const getShipDate = lastShip => {
  let lastShipDate = lastShip;
  if (!isDate(lastShip)) {
    lastShipDate = parseISO(lastShip || "");
  }
  return isSameDay(lastShipDate, new Date()) ? "Today" : format(lastShipDate, dateFnsFormats.MMM_Do);
};

export const getShipDateFormatted = lastShip => {
  let lastShipDate = lastShip;
  if (!isDate(lastShip)) {
    lastShipDate = parseISO(lastShip || "");
  }
  const date = getShipDate(lastShipDate);
  const lpsTime = format(lastShipDate, dateFnsFormats.hour) + " EST";
  return lpsTime + (date === "Today" ? " " : " on ") + date;
};

export const getShipDateShort = lastShip => {
  let lastShipDate = lastShip;
  if (!isDate(lastShip)) {
    lastShipDate = parseISO(lastShip || "");
  }
  const date = getShipDate(lastShipDate);
  const lpsTime = format(lastShipDate, dateFnsFormats.hoursAndMinutes) + " EST";
  return date === "Today" ? lpsTime : date;
};

const numToStringArray = [
  "no",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];

export const contentTransform = (message, content, problemGroup, replacementsRemaining) => {
  const { problemBookings, lps } = problemGroup;

  let lastShip = toZonedTime(parseISO(lps || ""), "America/New_York");

  if (isDST(lastShip)) {
    lastShip = subHours(lastShip, 1);
  }

  const day = getShipDate(lastShip);
  const lpsTime = format(lastShip, dateFnsFormats.hoursAndMinutes) + " EST";
  const lpsDate = day + " at " + lpsTime;
  const lpsDateBy = day + " by " + lpsTime;
  const lpsDateReverse = getShipDateFormatted(lastShip);
  const lpsShort = getShipDateShort(lastShip);
  const rentStart = format(parseISO(problemGroup.rentStart || problemBookings[0].rentBegin || ""), dateFnsFormats.day);
  const secondDay = format(addDays(parseISO(problemBookings[0].rentBegin || ""), 1), dateFnsFormats.day);
  const remainingReplacementNoun =
    replacementsRemaining && replacementsRemaining > 1 ? content.replacementMultiple : content.replacementSingle;

  let transformMessage = message;
  transformMessage = transformMessage.replace(
    "$ITEM",
    problemBookings.length > 1 ? content.itemMultiple : content.itemSingle
  );
  transformMessage = transformMessage.replace(
    "$PRONOUN",
    problemBookings.length > 1 ? content.pronounMultiple : content.pronounSingle
  );
  transformMessage = transformMessage.replace(
    "$VERB",
    problemBookings.length > 1 ? content.verbMultiple : content.verbSingle
  );
  transformMessage = transformMessage.replace("$TIME", lpsTime);
  transformMessage = transformMessage.replace("$DATE_SHORT", lpsShort);
  transformMessage = transformMessage.replace("$LPS", day);
  transformMessage = transformMessage.replace("$DATETIME_REVERSE", lpsDateReverse);
  transformMessage = transformMessage.replace("$DATETIMEBY", lpsDateBy);
  transformMessage = transformMessage.replace("$DATETIME", lpsDate);
  transformMessage = transformMessage.replace("$RENTAL_START_DATE", rentStart);
  transformMessage = transformMessage.replace("$SECOND_DAY", secondDay);
  transformMessage = transformMessage.replace("$REMAINING_REPLACEMENTS_NOUN", remainingReplacementNoun);
  transformMessage = transformMessage.replace(
    "$REMAINING_REPLACEMENTS",
    replacementsRemaining ? numToStringArray[replacementsRemaining] : "no"
  );
  transformMessage = transformMessage.replace(
    "$REPLACEMENT",
    problemBookings.length > 1 ? content.replacementMultiple : content.replacementSingle
  );
  return transformMessage;
};

export const exchangeContentTransform = (message, exchangeGroup, replacementsRemaining, newBagCutoff) => {
  const { bagCutoffTime } = exchangeGroup;

  const usableBagCutoff = newBagCutoff ? newBagCutoff : bagCutoffTime;

  const cutoff = easternTimeAdjust(usableBagCutoff);
  const cutoffTime = format(cutoff, "h:mm a").toLowerCase() + " EST";
  const remainingReplacementNoun = replacementsRemaining && replacementsRemaining > 1 ? "replacements" : "replacement";

  let transformMessage = message;
  transformMessage = transformMessage.replace("$TIME", cutoffTime);
  transformMessage = transformMessage.replace("$REMAINING_REPLACEMENTS_NOUN", remainingReplacementNoun);
  transformMessage = transformMessage.replace("$REMAINING_REPLACEMENTS", replacementsRemaining);
  return transformMessage;
};

export const getOrderType = (problemGroup, booking) => {
  const { rentStart, rentEnd } = problemGroup;

  const currentFufillments = booking?.currentFulfillments ?? [{}];
  const currentFufillment = _.first(currentFufillments);
  const orderType = currentFufillment?.orderType;

  if (!rentEnd) {
    return ORDER_TYPE.UNLIMITED;
  } else if (differenceInDays(parseISO(rentEnd), parseISO(rentStart || "")) <= 8) {
    return ORDER_TYPE.CLASSIC;
  } else if (orderType === ORDER_TYPE.UPDATE) {
    return ORDER_TYPE.UPDATE;
  } else {
    return ORDER_TYPE.UNLIMITED;
  }
};

export const redirectCategory = (categories, loadCategory, group, workingFilters) => {
  return dispatch => {
    if (!categories) {
      return;
    }

    const keys = _.mapObject(categories, (val, key) => key);
    const similarItemCategories = _.filter(keys, x => x.includes(SIMILAR_DELIMITER));
    const categoryIndex = _.indexOf(similarItemCategories, loadCategory);

    // build out params for new category
    let newCategory, newParams;
    if (categoryIndex === similarItemCategories.length - 1) {
      // case where there are no other similar item categories, and we move to product categories
      const firstItem = group?.problemBookings?.[0] ?? {};
      if (!SizeHelper.isOneSizeFitsAll(firstItem)) {
        // toggle to VIEW ALL
        newCategory = VIEW_ALL;
        newParams = buildBaseFilters(group, newCategory, "contextualRecommended", workingFilters);
      } else {
        // toggle to VIEW ALL ACCESSORIES
        newCategory = "accessory";
        newParams = buildBaseFilters(group, newCategory, "contextualRecommended", workingFilters);
        newParams.filter.categories = newCategory;
      }
      delete newParams.filter.productsSimilarTo;
    } else {
      // case where there are more similar item categories that haven't been tried yet
      newCategory = similarItemCategories[categoryIndex + 1];
      newParams = buildBaseFilters(group, newCategory, "contextualRecommended", workingFilters);
    }

    fetchProductsWithCallback(newParams, categories, newCategory, group, workingFilters)(dispatch);
  };
};
