import { Fragment } from "react";

const CmsAttributesHelper = {
  constructAttributeClasses: function (className, attributesObject) {
    // This function creates a string of classes based on the values passed through
    // in the hedAttributes object from the CMS
    if (!attributesObject || !Object.keys(attributesObject).length) {
      return className;
    }

    const attributesClasses = Object.values(attributesObject).map(a => `${className}--${a}`);

    return [className, ...attributesClasses].join(" ");
  },

  LINK_REGEX: /\$LINK\[\d+\]/g,
  /**
   * If links are specified, transform the text to include them.
   * This will return a React Fragment that can be rendered directly.
   * If no links are present, return the string and fallback to rendering with dangerouslySetInnerHTML.
   */
  getTextWithLinks: (text, links) => {
    if (!text || !links?.length) {
      return text;
    }

    return text.split(CmsAttributesHelper.LINK_REGEX).map((el, i) => {
      const link = links[i];
      const linkMarkup = link && <a href={link.url}>{link.text}</a>;

      return linkMarkup ? (
        <Fragment key={i}>
          {el}
          {linkMarkup}
        </Fragment>
      ) : (
        <Fragment key={el}>{el}</Fragment>
      );
    });
  },
};

export default CmsAttributesHelper;

export const { constructAttributeClasses, getTextWithLinks } = CmsAttributesHelper;
