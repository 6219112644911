import { useDispatch, useSelector } from "react-redux";
import ClosableModal from "components/source/shared/closable-modal";
import { displayModal } from "actions/shared-actions";
import { navigateTo } from "helpers/location-helpers";
import RtrImage from "../rtr-image";

import { ShoppableImages, analytics } from "rtr-constants";

import styles from "./styles.module.scss";

import UserProductRatingsContainer from "components/source/user_product_ratings/user-product-ratings-container";

const ShoppableImagesModal = () => {
  const dispatch = useDispatch();
  const displayedModal = useSelector(state => state.displayedModal);
  const shoppable = useSelector(state => state.shoppableImages);
  const isMobileViewport = useSelector(state => state.browser?.isMobileViewport);

  const closeModal = () => {
    dispatch(displayModal());
  };

  if (!shoppable) return null;

  const additionalStyles = {
    content: {
      bottom: "none",
      inset: "none",
      height: "none",
      overflow: "none",
      padding: "0",
      transform: "none",
      left: "none",
      right: "none",
      top: "none",
      margin: "0",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,0.3)",
    },
  };

  if (isMobileViewport) {
    additionalStyles.content.borderTopRightRadius = "10px";
    additionalStyles.content.borderTopLeftRadius = "10px";
  }

  return (
    <ClosableModal
      isOpen={displayedModal === ShoppableImages.modalName}
      onClickModalOverlay={closeModal}
      onRequestClose={closeModal}
      optionalClass={styles["modal"]}
      overlayClassName={styles["overlay"]}
      additionalStyles={additionalStyles}>
      <div className={styles["header"]}>
        <div className="heart-button-holder"></div>
        <p className={styles["header-text"]}>Shop the Look</p>
      </div>
      <div className={styles["mini-grid"]}>
        {shoppable.products.map(product => (
          <div className={styles["mini-grid-cell"]} key={product.productId}>
            <UserProductRatingsContainer location={analytics.ACTION_LOCATIONS.SHOPPABLE_IMAGES} product={product}>
              <a href={product.link}>
                <div className={styles["image-holder"]}>
                  <RtrImage src={product.imageURL} alt={product.styleName} />
                </div>
              </a>
              <a href={product.link} className={styles["text-holder"]}>
                <p className={styles["designer-name"]}>{product.designerName}</p>
                <p className={styles["style-name"]}>{product.styleName}</p>
              </a>
            </UserProductRatingsContainer>
          </div>
        ))}
      </div>
      <div className={styles["footer"]}>
        <button onClick={closeModal}>Close</button>
        <button onClick={() => navigateTo(shoppable.similarStylesLink)} className={styles["cta"]}>
          Similar Styles
        </button>
      </div>
    </ClosableModal>
  );
};

export default ShoppableImagesModal;
