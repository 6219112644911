function tryCatchLog(callback) {
  if (typeof callback === "function") {
    try {
      callback();
    } catch (e) {
      console.error(e);
      window?.Sentry?.captureException(e);
    }
  }
}

export default tryCatchLog;
