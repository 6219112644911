import { ThirdPartyScript } from "./thirdPartyScript";
import { shallowEqual, useSelector } from "react-redux";

const branchIo = () => {
  const publicEnv = useSelector(state => state.publicEnv, shallowEqual);
  if (publicEnv?.branchKey) {
    return (
      <ThirdPartyScript
        id="branch-io-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          try {
            (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode banner closeBanner creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode".split(" "), 0);
          } catch (e) {
            if (window.Sentry && window.Sentry.captureException) { window.Sentry.captureException(e, {
              tags: {
                thirdparty: true,
                thirdparty_name: "branch"
              }
            }); }
          }

          var RTR = window.RTR || {};
              RTR.VENDORS = RTR.VENDORS || {};
              RTR.VENDORS.BRANCH = "${publicEnv.branchKey}";
        `,
        }}
      />
    );
  } else {
    return <></>;
  }
};

export default branchIo;
