import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";

import userProblemsActions from "actions/user-problems-actions";
import { redirectToRoute } from "actions/action-utils";
import { contentTransform, getOrderType, ORDER_TYPE } from "helpers/swaps-helper";
import ActionLogger from "action-logger";
import FormattingUtils from "../../../helpers/FormattingUtils";
import { browserPropType, userProblemsPropType } from "components/propTypes";
import LineItemCompact from "./LineItemCompact";
import {
  getProductImageAltText,
  getProductImageUrlsByOrientation,
  ProductImageSize,
} from "../../../helpers/product-image-helpers";
import ProductImage from "../shared/product-image";

class OrderDetailsView extends React.Component {
  static propTypes = {
    browser: browserPropType.isRequired,
    dispatch: PropTypes.func.isRequired,
    userProblems: userProblemsPropType.isRequired,
  };

  handleChooseReplacementClick = () => {
    const {
      dispatch,
      userProblems: { problemGroup },
    } = this.props;
    const logPixel = () => {
      ActionLogger.inferAction({
        node: "swaps",
        object_type: "problem_order",
        action: "choose_replacements",
      });
    };
    userProblemsActions.swapsFlowNextStep(problemGroup, "/swaps", "/", logPixel)(dispatch);
  };

  isUnlimited() {
    const {
      userProblems: { problemGroup },
    } = this.props;
    const { problemBookings } = problemGroup;

    return getOrderType(problemGroup, _.first(problemBookings)) === ORDER_TYPE.UNLIMITED;
  }

  handleContactUs = () => {
    const {
      userProblems: { swapsContent },
    } = this.props;
    const { modal } = swapsContent;

    ActionLogger.inferAction({
      node: "swaps",
      object_type: "problem_order",
      action: "click_contact_us",
    });
    redirectToRoute(modal.cxLink);
  };

  renderProduct(product) {
    return (
      <div className="order-details-view__product">
        <ProductImage
          altText={getProductImageAltText(product?.displayName, product?.designer?.displayName)}
          imageGroup={getProductImageUrlsByOrientation(product?.images, [])}
          imageSize={ProductImageSize.x70}
        />
        <div>{product.displayName}</div>
        <div>Size {product.size}</div>
      </div>
    );
  }

  renderProductCompact(p) {
    return <LineItemCompact product={p} />;
  }

  renderProductGroup(bookings = [], isBottom = false) {
    const { browser } = this.props;
    const groupClass = isBottom
      ? "order-details-view__product-group order-details-view__product-group--bottom"
      : "order-details-view__product-group";

    if (browser.isMobileViewport) {
      return <div>{bookings.map(this.renderProductCompact)}</div>;
    }

    return <div className={groupClass}>{bookings.map(this.renderProduct)}</div>;
  }

  renderGood(goodTitle, goodMessage, goodBookings = []) {
    if (goodBookings.length < 1) return null;

    return (
      <div>
        <div className="order-details-view__bottom-title">{goodTitle}</div>
        <hr />
        <div className="order-details-view__message">{goodMessage}</div>
        <div className="order-details-view__product-container">{this.renderProductGroup(goodBookings, true)}</div>
      </div>
    );
  }

  render() {
    const { userProblems } = this.props;
    if (!userProblems || !userProblems.problemGroup) return null;
    const { problemGroup, swapsContent } = userProblems;
    const { modal } = swapsContent;
    const {
      problemTitle: originalProblemTitle,
      subTitle: originalSubTitle,
      problemMessage,
      goodTitle: originalGoodTitle,
      goodMessage,
      cxMessage,
    } = modal;

    const { problemBookings, goodBookings } = problemGroup;

    // Handle copy changes for retail geo
    let problemOrderCopy = problemMessage;
    let goodItemCopy = goodMessage;

    // Filling in CMS with info.
    const problemTitle = contentTransform(originalProblemTitle, modal, problemGroup);
    const subTitle = contentTransform(originalSubTitle, modal, problemGroup);
    problemOrderCopy = contentTransform(problemOrderCopy, modal, problemGroup);
    const goodTitle = contentTransform(originalGoodTitle, modal, problemGroup);
    goodItemCopy = contentTransform(goodItemCopy, modal, problemGroup);

    const lightSub = subTitle.substring(0, subTitle.indexOf("|"));
    const boldSub = subTitle.substring(subTitle.indexOf("|") + 1);

    const chooseReplacementsCTA = problemGroup.bonusSwap
      ? "Choose Replacements"
      : FormattingUtils.pluralize("Choose Replacement", problemBookings.length);

    return (
      <div className="swap-modal order-details-view">
        <div className="order-details-view__title">
          <h3>{problemTitle}</h3>
          <span className="order-details-view__sub-title">
            {lightSub}
            <span className="order-details-view--bold">{boldSub}</span>
          </span>
        </div>
        <hr />
        <div className="order-details-view__message">{problemOrderCopy}</div>
        <div className="order-details-view__product-container">{this.renderProductGroup(problemBookings)}</div>
        <div className="order-details-view--center">
          <div className="full-width">
            <button className="btn" onClick={this.handleChooseReplacementClick}>
              {chooseReplacementsCTA}
            </button>
            <p className="sub-cta-copy">{contentTransform("by $TIME", modal, problemGroup)}</p>
          </div>
        </div>
        {this.renderGood(goodTitle, goodItemCopy, goodBookings)}
        <div className="order-details-view__cx">
          <a className="order-details-view__cx-link" onClick={this.handleContactUs}>
            {cxMessage}
          </a>
        </div>
      </div>
    );
  }
}

export default OrderDetailsView;
